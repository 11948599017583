﻿angular.module('projectModule').controller("InstallationZonnbController", ['$scope','$controller','ntabuilding','ntaValidation','ntaEntityData','$routeParams','ntaDependencyValidation','ZonneboilerFactory', 'ntaSharedLogic',
function                                                                   ($scope,  $controller,  ntabuilding,  ntaValidation,  ntaEntityData,  $routeParams,  ntaDependencyValidation,  ZonneboilerFactory,   ntaSharedLogic) {

    const base = $controller('NTABaseController', { $scope: $scope })
    const vm = angular.extend(this, base);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    vm.ntaValidation = ntaValidation;
    vm.installId = $routeParams.id3;

    const zonneboilerLogic = new ZonneboilerFactory(vm.installId, ntaDependencyValidation);
    vm.getZonneboilerLogic = zonneboilerLogic;

    vm.propertiesZB = zonneboilerLogic.propertiesZB;
    vm.propertiesBV = zonneboilerLogic.propertiesBV;
    vm.propertiesZC = zonneboilerLogic.propertiesZC;

    vm.zonnbData = zonneboilerLogic.zonnbData;
    vm.systeem = zonneboilerLogic.systeem;
    vm.boilervat = zonneboilerLogic.boilervat;
    vm.collector = zonneboilerLogic.collector;

    vm.isHidden = zonneboilerLogic.isHidden;
    vm.isReadOnly = zonneboilerLogic.isReadOnly;
    vm.isDisabled = zonneboilerLogic.isReadOnly;

    vm.getCodedValues = zonneboilerLogic.getCodedValues;
    vm.getPropData = zonneboilerLogic.getPropData;

    vm.getSelectionTable = zonneboilerLogic.getSelectionTable;
    vm.saveValueSelectionTable = zonneboilerLogic.saveValueSelectionTable;

    vm.getForm = getForm;
    vm.saveValue = saveValue;

    vm.preventBackspace = ntaSharedLogic.preventBackspace;

    $scope.endFormValidation = function () {
        zonneboilerLogic.endFormValidation();
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INITALIZIATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    angular.element(function () { //alle touched properties valideren na het laden van form
        zonneboilerLogic.startFormValidation();
        vm.initializeUnitSpacing();
    });

    vm.setGeopend('ZONNB_OPEN', vm.zonnbData);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function saveValue(prop, entdata) {
        zonneboilerLogic.saveValue(prop, entdata);
        vm.initializeUnitSpacing();
    }

    function getForm() {
        return zonneboilerLogic.form_zonnb;
    }

}]);
