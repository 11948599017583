﻿angular.module('indexModule').directive("subFolders", ['indextree', function (indextree) {
    return {
        templateUrl: '/src/app/project/subfolderstemplate.html?v=' + indextree.commit
    };
}]);

angular.module('indexModule').directive("subfoldersPrullenbak",
    ['indextree', function (indextree) {
        return {
            templateUrl: '/src/app/project/subfoldersPrullenbak.html?v=' + indextree.commit
        };
}]);

angular.module('indexModule').controller("IndexTreeController",
        ['indextree', 'progressCircle', '$scope', '$timeout', 'time', '$location', '$filter', '$mdDialog', '$log', 'ntaVersions', '$element', 'ntaAlert', '$http', 'HubConnection',
function (indextree,   progressCircle,   $scope,   $timeout,   time,   $location,   $filter,   $mdDialog,   $log,   ntaVersions,   $element,   ntaAlert,   $http,   HubConnection) {
    'use strict';
    const vm = this;

    vm.getFolderNameWidth = function (id) {
        var list = $element.find('#folderitem' + id);
        if (list[0]) {
            return list[0].offsetWidth - 114;
        } else return 64;
    };

    vm.calcTypes = indextree.buildingTypes
        .filter(bt => indextree.modules.includes(bt.FilterValue1))
        .map(bt => bt.Id);

    vm.IsDeveloper = function (isdev) {
        return indextree.isdev;
    };

    vm.commit = function () {
        return indextree.commit;
    };

    vm.folders = function (parentId, isDeleted = false) {
        isDeleted = Boolean(isDeleted);
        return indextree.foldersById.get(parentId)
            .Subfolders.filter(f => Boolean(f.DeleteDate) === isDeleted);
    };

    vm.deletedBerekeningen = function () {
        return [];
    }

    function forceUpdateProjectName() {
        //-- Forceer blur voor projectnaam om deze op te slaan, voordat de nieuwe pagina getoond wordt.
        const projectNameElement = document.getElementById('projectnaam');
        if (projectNameElement) {
            const projectNameAngularElement = angular.element(projectNameElement);
            if (projectNameAngularElement) {
                projectNameAngularElement.blur();
            }
        }
    }

    vm.prullenbakClicked = async function () {
        indextree.isPrullenbakOpen = !indextree.isPrullenbakOpen || indextree.activeFolderId !== -2;
        await vm.openPrullenbak();
    };

    vm.isPrullenbakOpen = function () {
        return indextree.isPrullenbakOpen;
    };

    vm.openPrullenbak = async function () {
        await fetchPrullenbak();
        setFolderActive(-2);
        $location.path("/Folders/-2/Berekeningen/");
    };

    async function fetchPrullenbak() {
        let prullenbakFolder = indextree.foldersById.get(-2);
        if (prullenbakFolder.Project.Berekeningen.length === prullenbakFolder.Project.AantalBerekeningen) {
            return prullenbakFolder;
        }
        try {
            progressCircle.setShowProgressValue(true, 'berekeningenlijst ophalen...', false);
            try {
                let updatedFolder = await retryAsync(async () => await loadDeletedCalculationsFromServer(), 5, 100, 250);
                prullenbakFolder.Project.Berekeningen = $filter('orderBy')(prullenbakFolder.Project.Berekeningen, "Name");
                prullenbakFolder.Project = updatedFolder.Project;
                prullenbakFolder.Open = true;
                prullenbakFolder.Active = true;
            } finally {
                progressCircle.setShowProgressValue(false);
            }
        } catch (err) {
            const alert = $mdDialog.alert()
                .title('Kan berekeningen in prullenbak niet laden')
                .textContent('Het is niet gelukt om de lijst met berekeningen in de prullenbak te laden. Probeer het zo nog een keer.')
                .ok('Sluiten');
            await $mdDialog.show(alert);
        }
    }

    vm.maakPrullenbakLeeg = showPrullenbakLegenDialog;

    vm.activeFolder = function () {
        return indextree.foldersById.get(indextree.activeFolderId);
    };

    vm.addFolder = async function (parentId, event) {
        progressCircle.setShowProgressValue(true, 'toevoegen projectmap');

        const parentFolder = indextree.foldersById.get(parentId);
        if (parentFolder) parentFolder.Open = true;

        const newId = await indextree.createFolder(parentId);

        $timeout(function () {

            setFolderActive(newId);
            $('#folderitem' + vm.activeFolder().FolderId).parent().trigger('click');

            vm.editFolder(newId, event);
        }, 100);

        progressCircle.setShowProgressValue(false);
    };

    vm.editFolder = function (folderId, event) {
        if (!folderId) {
            return;
        }
        $("#foldername" + folderId).removeClass("showname");
        $("#folderinput" + folderId).addClass("editname");
        $("#folderinput" + folderId).focus();
        $("#folderinput" + folderId).select();

        event.stopImmediatePropagation();
        event.preventDefault();
    };

    const _menuItems = [
        {
            text: 'nieuwe map',
            icon: 'add_circle_outline',
            onClick: (folder, event) => vm.addFolder(folder.FolderId, event),
        },
        {
            text: 'naam wijzigen',
            icon: 'create',
            onClick: (folder, event) => vm.editFolder(folder.FolderId, event),
        },
        {
            text: 'kopiëren',
            icon: 'file_copy',
            onClick: (folder, event) => copyFolder(folder.FolderId, event),
        },
        {
            text: 'kopiëren voor oplevering',
            icon: 'file_copy',
            isVisible: folder => canCopyFolder(folder, 'Delivery'),
            onClick: (folder, event) => copyFolder(folder.FolderId, event, 'Delivery'),
        },
        {
            text: 'kopiëren voor vervangen',
            icon: 'file_copy',
            isVisible: folder => canCopyFolder(folder, 'Replacement'),
            onClick: (folder, event) => copyFolder(folder.FolderId, event, 'Replacement'),
        },
        {
            text: 'kopiëren voor herlabelen',
            icon: 'file_copy',
            isVisible: folder => canCopyFolder(folder, 'Relabeling'),
            onClick: (folder, event) => copyFolder(folder.FolderId, event, 'Relabeling'),
        },
        {
            text: 'verplaatsen',
            icon: 'exit_to_app',
            onClick: folder => vm.moveFolder(folder),
        },
        {
            text: 'verwijderen',
            icon: 'delete_outline',
            isVisible: (folder) => canDeleteFolder(folder),
            onClick: (folder, event) => vm.deleteFolder(folder.FolderId, event),
        },
        {
            getText: (folder) => vm.hasMwaBerekeningen(folder) ? 'afmelden maatwerkadvies' : 'afmelden',
            icon: 'assignment_turned_in',
            isVisible: (folder) => vm.afmeldenMogelijk(folder.FolderId),
            onClick: (folder, event) => vm.afmeldenClick(folder.FolderId, event),
        },
        {
            text: 'energielabel',
            icon: 'class',
            isVisible: (folder) => vm.hasAfgemeldeBerekeningen(folder),
            onClick: (folder, event) => vm.downloadLabelClick(folder, event),
        },
        {
            getText: (folder) => vm.hasAfgemeldeBerekeningen(folder) ? 'exporteren zonder energielabels' : 'exporteren naar bestand',
            icon: 'archive',
            isVisible: (folder) => vm.exportMogelijk(folder.FolderId, false),
            onClick: (folder, event) => vm.exportClick(folder.FolderId, event, false),
        },
        {
            text: 'exporteren met energielabels',
            icon: 'archive',
            isVisible: (folder) => vm.exportMogelijk(folder.FolderId, true),
            onClick: (folder, event) => vm.exportClick(folder.FolderId, event, true),
        },
        {
            text: 'bestand(en) importeren',
            icon: 'unarchive',
            onClick: (folder, event) => vm.importClick(folder.FolderId, event),
        },
        {
            text: 'rapportages maken',
            icon: 'description',
            isVisible: folder => vm.rapportageMogelijk(folder) && indextree.licencetype !== "TRIAL",
            onClick: folder => vm.createPDFRapport(folder),
        },
        //{   // Tijdelijk uitgekomment voor release 1 juli'24. Wordt vanaf aug'24 verder opgepakt 
        //    text: 'overzichten exporteren (excel)',
        //    icon: 'filter_frames',
        //    isVisible: folder => vm.rapportageMogelijk(folder),
        //    onClick: folder => vm.createResultsRapport(folder),
        //},
    ];

    vm.getMenuItems = function (folder) {
        return _menuItems
            .filter(item => !item.isVisible || item.isVisible(folder));
    };

    vm.menuItemClick = function (item, folder, event) {
        item.onClick(folder, event);
    };

    function canCopyFolder(folder, purpose) {
        let predicate;
        if (purpose === 'Delivery') { // kopiëren voor oplevering
            predicate = b => b.Afgemeld && !indextree.isMaatwerkadvies(b) && b.Results.AFM_AANLEIDING === 'AFM_AANL_AANVR';
        } else if (purpose === 'Replacement') { // kopiëren voor vervangen
            predicate = b => b.Afgemeld && !indextree.isMaatwerkadvies(b);
        } else if (purpose === 'Relabeling') { // kopiëren voor herlabelen
            // berekening afgemeld EN A24 = oplevering nieuw gebouw / bestaande bouw EN ge3 - 1
            predicate = b => b.Afgemeld
                && !indextree.isMaatwerkadvies(b)
                && ['AFM_AANL_OPLVNB', 'AFM_AANL_BESTB'].includes(b.Results.AFM_AANLEIDING)
                && b.NTAVersionId >= 100
        } else {
            if (purpose) $log.warn('Onverwachte reden tot kopiëren: ‘', purpose, '’ bij het kopiëren van ', folder);
            predicate = () => true; // kopiëren
        }

        const project = folder.Project;
        if (purpose = 'Relabeling') {
            // indien voor alle berekeningen geldt: afgemeld EN A24=oplevering nieuw gebouw / bestaande bouw EN ge3-1
            const result = project.Berekeningen.every(predicate);
            return result && vm.folders(folder.FolderId).every(f => canCopyFolder(f, purpose));
        } else {
            const result = project.Berekeningen.some(predicate);
            return result || vm.folders(folder.FolderId).some(f => canCopyFolder(f, purpose));
        }
    }

    async function copyFolder(folderId, event, purpose) {
        const folder = indextree.foldersById.get(folderId);
        if (!folder) {
            return;
        }

        const connectionId = await progressCircle.startConnection();
        progressCircle.setShowProgressValue(true, 'kopiëren projectmap', !!connectionId);
        try {
            const copyId = await indextree.copyFolder(folderId, purpose, connectionId);

            const parent = indextree.foldersById.get(folder.ParentId);
            if (parent) {
                parent.Open = true;
            }

            setFolderActive(copyId);

            const folderElement = await indextree.waitForElement('#folderclick' + copyId, 10000);
            if (folderElement) {
                folderElement.click();
            }
        } finally {
            progressCircle.setShowProgressValue(false);
        }
    }


    vm.moveFolder = async function (fld) {
        if (!fld) {
            return;
        }

        const activeFolder = indextree.folders.find(x => x.Active);
        activeFolder.Active = false;

        fld.Active = true;
        fld.ActiveMove = true;

        $mdDialog.show({
            templateUrl: '/src/app/project/moveItem.html?v=' + indextree.commit,
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        });
    };

    vm.deleteFolder = async function (folderId, event) {
        const folder = indextree.foldersById.get(folderId);
        if (!folder) {
            return;
        }

        const oldParentId = folder.ParentId;

        const confirm = $mdDialog.confirm()
            .title('Verwijderen projectmap')
            .textContent(`U staat op het punt om projectmap "${folder.Name}" te verwijderen. Weet u het zeker?`)
            .ariaLabel('Verwijderen')
            .targetEvent(event)
            .ok('Ja')
            .cancel('Nee');
        try {
            await $mdDialog.show(confirm);
        } catch (err) {
            // geannuleerd
            return;
        }

        progressCircle.setShowProgressValue(true, 'verwijderen projectmap');
        try {
            await indextree.deleteFolder(folderId);
        } catch (err) {
            progressCircle.setShowProgressValue(false);
            throw err;
        }
        $timeout(function () {
            if (folderId === vm.activeFolder()?.FolderId) {
                if (oldParentId !== -1) {
                    setFolderActive(oldParentId);
                } else {
                    if (indextree.folders.length > 0) {
                        setFolderActive(indextree.folders[0].FolderId);
                    } else {
                        indextree.activeFolderId = undefined;
                    }
                }
                if (vm.activeFolder()) {
                    $('#folderitem' + vm.activeFolder().FolderId).parent().trigger('click');
                } else {
                    $location.path("/Folders/-1/Berekeningen/");
                }
            }

            progressCircle.setShowProgressValue(false);
        }, 100);
    };

    vm.subFolders = async function (fld, ev) {
        forceUpdateProjectName();
        //laat de onderliggende mappen zien of klapt hem juist weer dicht
        const folder = fld;
        if ($("#foldername" + folder.FolderId).hasClass("showname")) {
            // Als we minder berekeningen hebben dan het opgegeven aantal, halen we de map op van de server, met alle berekeningen.
            if (folder.Project.Berekeningen.length < folder.Project.AantalBerekeningen) {
                await fetchFolderCalculations(folder, ev);
            }
            if (folder.Active) {
                folder.Open = !folder.Open;
            } else {
                folder.Open = true;
            }
            setFolderActive(folder.FolderId);
        }
    };


    async function fetchFolderCalculations(folder, ev) {
        forceUpdateProjectName();
        try {
            progressCircle.setShowProgressValue(true, 'berekeningenlijst ophalen...', false);
            try {
                const folderId = folder.FolderId;
                const updatedFolder = await retryAsync(async () => await loadFolderFromServer(folderId), 5, 100, 250);
                folder.Project = updatedFolder.Project;
            } finally {
                progressCircle.setShowProgressValue(false);
            }
        } catch (err) {
            const alert = $mdDialog.alert()
                .title('Kan berekeningen niet laden')
                .textContent('Het is niet gelukt om de lijst met berekeningen in deze map te laden. Probeer het zo nog een keer.')
                .targetEvent(ev)
                .ok('Sluiten');
            await $mdDialog.show(alert);
        }
    }

    async function loadFolderFromServer(folderId) {
        const response = await fetch('/Projects/GetFolder?folderId=' + folderId);
        const reply = await response.json();
        if (reply.error) {
            throw new Error(reply.error);
        } else {
            return reply;
        }
    }

    async function loadDeletedCalculationsFromServer() {
        const response = await fetch('/Trash/GetPrullenbakFolder');
        const reply = await response.json();
        if (reply.error) {
            throw new Error(reply.error);
        } else {
            return reply;
        }
    }

    async function retryAsync(asyncProc, maxAttempts, ...waitIntervalsMS) {
        let waitMS = 250; // standaard zoveel ms wachten tussen pogingen
        let result;
        let lastError;
        for (let attempt = 0; attempt < maxAttempts; attempt++) {
            try {

                result = await asyncProc();
                return result;

            } catch (err) {
                console.warn(err);
                if (typeof waitIntervalsMS[attempt] === 'number') {
                    waitMS = waitIntervalsMS[attempt];
                }
                await time.delay(waitMS);
                lastError = err;
            }
        }
        if (lastError) throw lastError;
    }

    vm.berekeningen = function (folderId) {
        const project = indextree.foldersById.get(folderId).Project;
        if (project.ProjectId !== -1) {
            return project.Berekeningen;
        }
        return null;
    };

    vm.berekeningAccessible = function (bld) {
        if (bld) {
            return vm.calcTypes.includes(bld.Results.GEB_TYPEGEB);
        }
        return false;
    };

    vm.prullenbakCount = function () {
        return indextree.deletedFolders.length;
    }

    vm.buildingInFolderCount = function (folderId) {
        return indextree.buildingInFolderCount(folderId);
    };

    vm.folderActive = function (folderId) {
        const folder = indextree.foldersById.get(folderId);
        return folder && folder.Active || false;
    };

    vm.folderOpen = function (folderId) {
        return indextree.foldersById.get(folderId).Open;
    };

    vm.saveFolderName = function (folderId) {
        indextree.editFolder(folderId)
            .then(function () {
                $timeout(function () {
                    $("#folderinput" + folderId).removeClass("editname");
                    $("#foldername" + folderId).addClass("showname");
                }, 100);
            });
    };

    vm.openMenu = async function ($mdMenu, ev, folder) {
        ev.stopImmediatePropagation();
        ev.preventDefault();

        try {
            if (folder && folder.Project.Berekeningen.length < folder.Project.AantalBerekeningen) {
                await fetchFolderCalculations(folder, ev);
            }
        } finally {
            $mdMenu.open(ev);
        }
    };

    vm.keypressFolderName = function (fldId, ev) {
        if (ev.which === 13) {
            vm.saveFolderName(fldId);
            ev.stopImmediatePropagation();
        } else {
            if (ev.which === 32) {
                ev.stopImmediatePropagation();
            }
        }
    };

    vm.exportClick = async function (folderId, ev, includingLabels) {
        if (!folderId) {
            return;
        }

        progressCircle.setShowProgressValue(true, 'exporteren projectmap', true);
        try {
            await indextree.exportFolder(folderId, includingLabels);
        } finally {
            progressCircle.setShowProgressValue(false);
        }
    };

    vm.importClick = function (folderId, ev) {
        if (!folderId) {
            return;
        }

        $mdDialog.show({
            locals: { folderId },
            controller: ImportDialogController,
            templateUrl: '/src/app/project/importfile.html?v=' + indextree.commit,
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        });
    };

    function ImportDialogController($scope, $mdDialog, folderId) {
        $scope.hide = function () {
            $mdDialog.hide();
        };
        $scope.cancel = function () {
            $mdDialog.cancel();
        };
        $scope.noFilesChosen = function () {
            return document.getElementById('filesToImport').files.length === 0;
        }
        $scope.uploadFile = async function () {
            const files = document.getElementById('filesToImport').files;
            if (files && files.length > 0) {
                $mdDialog.hide();

                const progressText = 'bestand' + (files.length > 1 ? 'en' : '') + ' importeren...';
                progressCircle.setProgressValue(0);
                progressCircle.setShowProgressValue(true, progressText, true);
                try {
                    const connection = new HubConnection('/hubs/progress', {
                        progressText,
                    });
                    await connection.whenConnected();
                    const runId = uuid.v4();
                    const runPromise = connection.runWithId('RepeatLastEvent', runId);

                    await indextree.importFiles(files, folderId, runId);

                    // ImportFiles gaat de daadwerkelijke events triggeren op de server waardoor de runPromise afgerond wordt.
                    const data = await runPromise;
                    if (data.errorFiles && data.errorFiles.length) {
                        let title = 'Import is mislukt';
                        let htmlContent = '<p>De volgende bestanden zijn NIET succesvol geïmporteerd:<p>'
                            + '<ul>'
                            + data.errorFiles.map(filename => '<li>' + encodeHtml(filename) + '</li>').join('')
                            + '</ul>';

                        if (data.importedFiles && data.importedFiles.length) {
                            title = 'Import is gedeeltelijk gelukt';
                            htmlContent = '<p>De volgende bestanden zijn met succes geïmporteerd:<p>'
                                + '<ul>'
                                + data.importedFiles.map(filename => '<li>' + encodeHtml(filename) + '</li>').join('')
                                + '</ul>'
                                + '<hr>'
                                + htmlContent;
                        }

                        await $mdDialog.show($mdDialog.alert({
                            title,
                            htmlContent,
                            ok: 'Sluiten',
                        }));

                        if (!data.importedFiles || data.importedFiles.length === 0) {
                            return;
                        }
                    }

                    progressCircle.setShowProgressValue(true, 'projectenoverzicht laden', false);
                    if (data.folder) {
                        if (data.folder.FolderId !== indextree.activeFolderId) {
                            location.href = `/Folders/${data.folder.FolderId}/Berekeningen`;
                        } else {
                            location.reload();
                        }
                    } else {
                        progressCircle.setShowProgressValue(false);
                    }
                } catch (err) {
                    $log.error(err, `during import of ${files.length} files into folder ${folderId}`);
                    progressCircle.setShowProgressValue(false);
                    const message = typeof err === 'string' ? err + '\n\n' : '';
                    return $mdDialog.show($mdDialog.alert({
                        title: "Import is mislukt",
                        textContent: message + 'Controleer of u de juiste bestanden gekozen had, probeer het nog een keer, of neem contact op met de helpdesk.',
                        ok: "Sluiten",
                    }));
                }
            }
        };
    }

    function encodeHtml(text) {
        const span = document.createElement('span');
        span.innerText = text;
        return span.innerHTML;
    }

    vm.rapportageMogelijk = function (folder) {
        return indextree.licencetype !== "TRIAL"
            && !folder.DeleteDate;
    };

    vm.createPDFRapport = async function (folder) {
        if (!folder) return;

        // Controleer eerst of het wel mag
        let warningCode;
        const buildings = folder.Project.Berekeningen.filter(building => !building.DeleteDate);
        if (buildings.some(indextree.hasMeldingen)) {
            warningCode = "[W082]";
        } else if (buildings.some(indextree.isCalcNeeded)) {
            warningCode = "[W083]";
        }
        if (warningCode) {
            await indextree.warning(warningCode);
        } else {
            progressCircle.setShowProgressValue(true, 'rapportage', true);
            try {
                await indextree.createRapportFolder(folder);
            } finally {
                progressCircle.setShowProgressValue(false);
            }
        }
    };
    vm.createResultsRapport = async function (folder) {
        if (!folder) return;

        // Controleer eerst of het wel mag
        let warningCode;
        const buildings = folder.Project.Berekeningen.filter(building => !building.DeleteDate);
        if (buildings.some(indextree.hasMeldingen)) {
            warningCode = "[W082]";
        } else if (buildings.some(indextree.isCalcNeeded)) {
            warningCode = "[W083]";
        }
        if (warningCode) {
            await indextree.warning(warningCode);
        } else {
            progressCircle.setShowProgressValue(true, 'rapportage', true);
            try {
                await indextree.createMWARapportFolder(folder);
            } finally {
                progressCircle.setShowProgressValue(false);
            }
        }
    };

    function canDeleteFolder(folder) {
        const hasLockedBuilding = folder.Project.Berekeningen.some(building => building.Locked || !vm.calcTypes.includes(building.Results.GEB_TYPEGEB));
        return !hasLockedBuilding
            && vm.folders(folder.FolderId).every(f => canDeleteFolder(f));
    }

    vm.afmeldenMogelijk = function (folderId) {
        //check of er een berekening is binnen module en niet afgemeld en of de licentie 'ACTIVE' is
        const project = indextree.foldersById.get(folderId).Project;
        const berekening = project.Berekeningen.find(function (x) { return !x.Afgemeld && !x.Locked && vm.calcTypes.includes(x.Results.GEB_TYPEGEB); });
        return berekening && indextree.licencetype !== "TRIAL";
    };

    vm.exportMogelijk = function (folderId, metLabel) {
        //check of de licentie 'ACTIVE' is
        let isMogelijk = indextree.licencetype !== "TRIAL";

        if (isMogelijk && metLabel) {
            //check of er geexporteerd kan worden met energielabels (ofwel of er afgemeld is en de energielabels zijnopgeslagen. Dit is pas vanaf versie >=50)
            return hasAfgemeldEnJuisteVersieBerekeningen(folderId);
        }

        return isMogelijk;
    };

    function hasAfgemeldEnJuisteVersieBerekeningen(folderId) {
        //check of er geexporteerd kan worden met energielabels (ofwel of er afgemeld is en de energielabels zijnopgeslagen. Dit is pas vanaf versie >=50
        const project = indextree.foldersById.get(folderId).Project;
        const result = project.Berekeningen.some(b => b.Afgemeld && b.NTAVersionId >= 50 && !indextree.isMaatwerkadvies(b));
        return result || vm.folders(folderId).some(f => hasAfgemeldEnJuisteVersieBerekeningen(f.FolderId));
    }

    vm.afmeldenClick = async function (folderId) {
        if (!folderId) {
            return;
        }
        if (event && event.currentTarget && event.currentTarget.classList) {
            if (event.currentTarget.classList.contains('noclick')) {
                return;
            }
        }

        try {
            const project = indextree.foldersById.get(folderId).Project;
            const berekeningen = project.Berekeningen;
            const calcUnits = berekeningen.map(x => x.Results.RZFORM_CALCUNIT);
            const types = berekeningen.map(x => x.Results.GEB_TYPEGEB);
            const sameType = types.reduce((a, b) => (a === b) ? a : false);
            const advies = berekeningen.map(x => x.Results.SETTINGS_MAATADVIES);
            const mwaAdvies = advies.filter(x => x && x.toLowerCase() === "true");
            let warningCode;
            if (!sameType) {
                warningCode = '[W038]'; //In deze map bevinden zich berekeningen van verschillende type gebouwen die niet tegelijk afgemeld kunnen worden. Zorg dat in de map alleen berekeningen zitten met een zelfde gebouw type.
            } else if (!project.Name) {
                warningCode = '[W052]'; //Er is geen project omschrijving ingevoerd.
            } else if (calcUnits.includes('RZUNIT_PROJECT')) {
                warningCode = '[W088]'; //Het is niet mogelijk om meerdere berekeningen met projectwoningen gezamenlijk af te melden. Kies de knop 'afmelden' op de tegel van iedere berekening met projectwoningen.
            } else if (mwaAdvies.length > 0 && mwaAdvies.length !== advies.length) {
                warningCode = '[W129]'; //In deze map bevinden zich ook berekeningen zonder maatwerkadvies. Zorg dat in de map alleen berekeningen met maatwerkadvies zitten.
            }
            if (warningCode) {
                indextree.warning(warningCode);
                return;
            }

            if (await indextree.canStartRegistration(project.ProjectId)) {
                const ntaVersionId = await ntaVersions.canOpenWithVersionIdAsync(berekeningen, true);
                if (ntaVersionId) {
                    progressCircle.setShowProgressValue(true, 'afmeldgegevens laden...', false);
                    window.location.href = "/Folder/" + folderId + "/Afmelden";
                }
            }
        } catch (err) {
            progressCircle.setShowProgressValue(false);
            $log.error(err, 'bij controles voor afmelden FolderId', folderId);

            await ntaAlert.showError();
        }
    };

    vm.hasAfgemeldeBerekeningen = hasAfgemeldeBerekeningen;
    function hasAfgemeldeBerekeningen(folder) {
        // check of er berekeningen zijn in deze folder en/of de onderliggende subfolders die afgemeld zijn.
        const project = folder.Project;
        const result = project.Berekeningen.some(function (x) { return x.Afgemeld === true && !indextree.isMaatwerkadvies(x); });
        return result || vm.folders(folder.FolderId).some(f => hasAfgemeldeBerekeningen(f));
    };

    vm.hasMwaBerekeningen = hasMwaBerekeningen;
    function hasMwaBerekeningen(folder) {
        // check of er maatwerkadvies berekeningen zijn in deze folder en/of de onderliggende subfolders.
        const project = folder.Project;
        const result = project.Berekeningen.some(function (x) { return indextree.isMaatwerkadvies(x); });
        return result || vm.folders(folder.FolderId).some(f => hasMwaBerekeningen(f));
    };

    vm.downloadLabelClick = async function (folder) {
        if (!folder || !folder.Project) {
            return;
        }
        progressCircle.setShowProgressValue(true, 'downloaden energielabel(s)', false);
        //const connectionId = await progressCircle.startConnection();
        //progressCircle.setShowProgressValue(true, 'downloaden energielabel(s)', !!connectionId);
        try {
            await indextree.downloadLabelProject(folder.Project.ProjectId);
        } finally {
            progressCircle.setShowProgressValue(false);
        }

    };

    function setFolderActive(folderId) {
        const folder = indextree.foldersById.get(folderId);
        folder.Active = true;

        const prevActiveFolder = vm.activeFolder();
        if (prevActiveFolder && indextree.activeFolderId !== folderId) {
            prevActiveFolder.Active = false;
        }

        indextree.activeFolderId = folder.FolderId;

        setTimeout(async () => {
            const buildingElement = await indextree.waitForElement('.activebuilding', 2500);
            if (buildingElement) {
                buildingElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }, 100);

        return folder;
    }

    function showPrullenbakLegenDialog(ev) {
        $mdDialog.show({
            controller: PrullenbakLegenDialogController,
            locals: { aantalMaanden: "12" },
            templateUrl: '/src/app/project/prullenbaklegen.html?v=' + indextree.commit,
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        });

    }

    function PrullenbakLegenDialogController($scope, $mdDialog) {
        $scope.closeDialog = function () {
            $mdDialog.hide();
        }
        $scope.hide = function () {
            $mdDialog.hide();
        };
        $scope.cancel = function () {
            $mdDialog.cancel();
        };
        $scope.aantalMaanden = "12";
        $scope.leegPrullenbak = async function () {
            $mdDialog.hide();
            const connectionId = await progressCircle.startConnection();
            progressCircle.setProgressValue(0);
            progressCircle.setShowProgressValue(true, 'prullenbak leeg maken', !!connectionId);
            try {
                await $http.post("Trash/DeleteBerekeningenPermanent?maxAantalMaanden=" + $scope.aantalMaanden);
                location.reload();
                progressCircle.setShowProgressValue(false);
            } catch (err) {
                $log.error('Oeps: prullenbak legen is mislukt', err);
                progressCircle.setShowProgressValue(false);
                return $mdDialog.show($mdDialog.alert({
                    title: "Prullenbak leegmaken is mislukt",
                    textContent: 'Probeer het zo nog een keer, of neem contact op met de helpdesk.',
                    ok: "Sluiten",
                }));
            }
        };

    }


    angular.element(document).ready(function () {
        // Blijf proberen tot er een map aangeklikt kan worden
        const interval = setInterval(function () {
            const folder = vm.activeFolder();
            const jqElement = folder && $('#folderitem' + folder.FolderId) || [];
            if (jqElement.length > 0) {
                clearInterval(interval);
                progressCircle.setShowProgressValue(false);
                jqElement
                    .first()
                    .trigger('click')
                    .focus();
            }
        }, 100);
    });

}]);
