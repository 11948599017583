﻿angular.module('projectModule')
    .controller("BuildingLuchtdoorlatenController",
        ['$scope', '$controller', 'ntaValidation', 'ntaEntityData', 'ntaSharedLogic', 'ntaDependencyValidation', 'ntaMeasure', 'LuchtdoorlatenFactory',
function ($scope,   $controller,   ntaValidation,   ntaEntityData,   ntaSharedLogic,   ntaDependencyValidation,   ntaMeasure,   LuchtdoorlatenFactory) {
    'use strict';
    const base = $controller('NTABaseController', { $scope: $scope })
    const vm = angular.extend(this, base);

    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///         INTERFACE              ////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////

    vm.ntaValidation = ntaValidation;

    const ldLogic = new LuchtdoorlatenFactory(ntaDependencyValidation);
    vm.ldLogic = ldLogic;


    /* Properties ********************************************************************************/
    vm.infiltratiePropertiesTop = ldLogic.infiltratiePropertiesTop;
    vm.infiltratiePropertiesBottom = ldLogic.infiltratiePropertiesBottom;
    vm.infiltratieUnitsProperties = ldLogic.infiltratieUnitsProperties;

    vm.vleidingenPropertiesTop = ldLogic.vleidingenPropertiesTop;
    vm.leidingenProperties = ldLogic.leidingenProperties;
    vm.vleidingenPropertiesBottom = ldLogic.vleidingenPropertiesBottom;
    vm.zomernachtProperties = ldLogic.zomernachtProperties;

    /* Data **************************************************************************************/
    vm.infiltratieData = ldLogic.infiltratieData;
    vm.getInfiltratieUnitDatas = ldLogic.getInfiltratieUnitDatas;
    vm.vleidingenData = ldLogic.vleidingenData;
    vm.zomernachtData = ldLogic.zomernachtData;

    vm.units = ldLogic.units;
    vm.unitrzs = ldLogic.unitrzs;
    vm.unitRekenzonesPerUnit = ldLogic.unitRekenzonesPerUnit;
    vm.leidingenByUnitRz = ldLogic.leidingenByUnitRz;

    vm.getTitle = getTitle;
    vm.getSubtitle = getSubtitle;
    vm.getForm = getForm;
    vm.saveValue = saveValue;
    vm.getPlaceholder = getPlaceholder;

    vm.isHidden = ldLogic.isHidden;
    vm.isReadOnly = ldLogic.isReadOnly;
    vm.isDisabled = ldLogic.isReadOnly;
    vm.getCodedValues = ldLogic.getCodedValues;

    vm.getUnitOmschrijving = getUnitOmschrijving;
    vm.getRekenzoneOmschrijving = getRekenzoneOmschrijving;
    vm.getInfiltratieUnitOmschrijving = getInfiltratieUnitOmschrijving;
    vm.getHeaderInfiltratieUnitOmschrijving = getHeaderInfiltratieUnitOmschrijving;

    vm.isInfiltratieParticipating = ldLogic.isInfiltratieParticipating;
    vm.conditionInfilC = ldLogic.conditionInfilC;
    vm.isVerticaleLeidingenParticipating = ldLogic.isVerticaleLeidingenParticipating;
    vm.verticaleLeidingenIsHidden = ldLogic.verticaleLeidingenIsHidden;
    vm.isZomernachtParticipating = ldLogic.isZomernachtParticipating;
    vm.zomernachtIsHidden = ldLogic.zomernachtIsHidden;

    vm.addLeiding = addLeiding;
    vm.copyLeiding = copyLeiding;
    vm.deleteLeiding = deleteLeiding;

    //-- voor de ui-sortable
    vm.unitRzLeidingenList = {}; //-- object dat per unitRz een array van leidingen bijhoudt


    $scope.endFormValidation = ldLogic.endFormValidation;

    const _subtitleByPropId = new Map(Object.entries({
        'INFIL_REN_JAAR': 'Wijzig infiltratiewaarde op basis van renovatiejaar',
        'INFIL_KOSTEN_TOT': 'Kosten',
        'VLEIDING_KOSTEN_TOT': 'Kosten',
    }));

    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///         INITIALIZATION         ////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////
    ntaMeasure.setBtwPropIds(vm);

    angular.element(function () { //alle touched properties valideren na het laden van form
        ldLogic.startFormValidation();
        vm.initializeUnitSpacing();
    });

    vm.setGeopend('INFIL_OPEN', vm.infiltratieData);
    updateLists();


    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///         IMPLEMENTATION         ////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////

    function getTitle() {
        return {
            'MEASURE-INFILTRATIE': 'Maatregel: infiltratie (renovatiejaar)',
            'MEASURE-VERT_LEIDINGEN': 'Maatregel: verticale leidingen',
        }[ldLogic.measureTypeId] || 'Luchtdoorlaten';
    } //-- end: getTitle --------------------------------------------------------------------------

    function getSubtitle(prop) {
        return _subtitleByPropId.get(prop.Id) || '';
    } //-- end: getSubtitle -----------------------------------------------------------------------

    function saveValue(prop, entdata) {
        ldLogic.saveValue(prop, entdata);
        base.initializeUnitSpacing();
    } //-- end: saveValue -------------------------------------------------------------------------

    function getForm() {
        return ldLogic.form_luchtdoorlaten;
    } //-- end: getForm ---------------------------------------------------------------------------

    function getPlaceholder(prop, entdata) {
        return base.getPlaceholder(prop, entdata, ldLogic);
    } //-- end: getPlaceholder --------------------------------------------------------------------

    function updateLists() {
        //-- onderstaande lijst zijn voor de ng-model binding van de sortabletabellen. Dit moeten namelijk assignable expressions zijn. Als ze een functie zijn doordat ze
        //-- iedere keer opnieuw opgehaald worden in de de factory gaat dat niet goed. Daarom expliciet in de controller bij houden.
        updateUnitRzLeidingenList()
    } //-- end: updateLists -----------------------------------------------------------------------

    function updateUnitRzLeidingenList() {
        vm.unitRzLeidingenList = {};
        for (const unitrz of vm.unitrzs) {
            vm.unitRzLeidingenList[unitrz.EntityDataId] = vm.leidingenByUnitRz(unitrz);
        }
    } //-- end: updateUnitRzList ------------------------------------------------------------------

    function getInfiltratieUnitOmschrijving(entdata) {
        const unit = entdata && ntaEntityData.getFirstParent(entdata, 'UNIT');
        if (!unit) {
            return 'gebouw';
        } else {
            return getUnitOmschrijving(unit);
        }
    } //-- end: getInfiltratieUnitOmschrijving ----------------------------------------------------

    function getUnitOmschrijving(entdata) {
        return entdata && entdata.PropertyDatas["UNIT_OMSCHR"].Value || '';
    } //-- end: getUnitOmschrijving ---------------------------------------------------------------

    function getRekenzoneOmschrijving(unitRzEntdata) {
        const rekenzone = unitRzEntdata && ntaEntityData.getFirstParent(unitRzEntdata, "RZ");
        return rekenzone && rekenzone.PropertyDatas['RZ_OMSCHR'].Value || '';
    } //-- end: getRekenzoneOmschrijving ----------------------------------------------------------

    function getHeaderInfiltratieUnitOmschrijving() {
        const invoerInfiltratie = ldLogic.getInvoerInfiltratie();
        if (invoerInfiltratie === 'INFIL_MWG' || (invoerInfiltratie === 'INFIL_GMW' && ntaSharedLogic.perGebouw())) {
            return 'gebouw';
        } else if (invoerInfiltratie === 'INFIL_MWA') {
            return 'appartementen';
        } else if (invoerInfiltratie === 'INFIL_MWU') {
            return 'units';
        } else if (invoerInfiltratie === 'INFIL_MWW') {
            return 'woningen';
        } else { // invoerInfiltratie === 'INFIL_GMW'
            return 'gebouwdelen';
        }
    } //-- end: getHeaderInfiltratieUnitOmschrijving ----------------------------------------------

    function addLeiding(unitRekenzone, leiding) {
        if (!leiding || !unitRekenzone) {
            return;
        }
        ntaEntityData.create('VLEIDINGL', leiding ? leiding.Order : -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": unitRekenzone.EntityDataId, "ParentEntityId": "UNIT-RZ" }], []);
        updateLists();
    } //-- end: addLeiding ------------------------------------------------------------------------

    function copyLeiding(unitRekenzone, leiding) {
        if (!leiding || !unitRekenzone) {
            return;
        }
        ntaEntityData.copy(leiding.EntityDataId);
        updateLists();
    } //-- end: copyLeiding -----------------------------------------------------------------------

    function deleteLeiding(unitRekenzone, leiding) {
        if (!leiding || vm.leidingenByUnitRz(unitRekenzone).length <= 1) { //kan de laatste leiding niet weggooien
            return;
        }

        ntaEntityData.delete(leiding.EntityDataId);
        updateLists();
    } //-- end: deleteLeiding ---------------------------------------------------------------------

    vm.sortableOptionsLeidingen = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            const unitrzleidingen = vm.unitRzLeidingenList[ui.item.attr("unitrzId")];
            ntaEntityData.SaveReOrder(unitrzleidingen);
        }
    } //-- end: vm.sortableOptionsLeidingen -------------------------------------------------------

}]);