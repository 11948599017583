﻿angular.module('projectModule')
    .controller('GebruikersprofielController',
        ['$scope', '$controller', 'ntaData', 'ntaEntityData', 'ntaSharedLogic', 'ntaValidation', 'ntaDependencyValidation', 'ntaRounding', 'GebruikersprofielFactory', 'ntaDiagram', 'time', 'ListCache',
function ($scope,   $controller,   ntaData,   ntaEntityData,   ntaSharedLogic,   ntaValidation,   ntaDependencyValidation,   ntaRounding,   GebruikersprofielFactory,   ntaDiagram,   time,   ListCache) {
    'use strict';
    const ctrl = this;
    const base = $controller('NTABaseController', { $scope: $scope })
    angular.extend(ctrl, base);

    //== Imports ==================================================================================
    ctrl.ntaValidation = ntaValidation;


    //== Instance data ============================================================================
    const logic = new GebruikersprofielFactory(ntaDependencyValidation);

    const _subtitleByPropId = new Map(Object.entries({
        'GEBR-PROFIEL_INTWARMTE':       'Interne warmteproductie en elektriciteitsgebruik',
        'GEBR-PROFIEL_INTWARMTE_PERS':  'Interne warmteproductie en elektriciteitsgebruik',
        'GEBR-PROFIEL_INTWARMTE_APP':   'Interne warmteproductie en elektriciteitsgebruik',
        'GEBR-PROFIEL_BEZF_TABEL':      'Interne warmteproductie en elektriciteitsgebruik',
        'GEBR-PROFIEL_WARMTEPROD_TABEL':'Interne warmteproductie en elektriciteitsgebruik',
        'GEBR-PROFIEL_VERW_SETPOINT':   'Verwarming',
        'GEBR-PROFIEL_VERW_FMATIG':     'Verwarming',
        'GEBR-PROFIEL_VERW_RZ_TABEL':   'Verwarming',
        'GEBR-PROFIEL_KOEL_SETPOINT':   'Koeling',
        'GEBR-PROFIEL_KOEL_RZ_TABEL':   'Koeling',
        'GEBR-PROFIEL_VENT_CORR_INV':   'Ventilatie',
        'GEBR-PROFIEL_TAPW_BEH':        'Warm tapwater',
        'GEBR-PROFIEL_TAPW_RZ_TABEL':   'Warm tapwater',
        'GEBR-PROFIEL_VERL_INV_BRUPJ':  'Verlichting',
        'GEBR-PROFIEL_VERL_RZ_TABEL':   'Verlichting',
    }));
    const _propsBySubtitle = new Map(); // <-- hierin houden we, per subtitel, een array met alle bijbehorende properties bij
    _subtitleByPropId.forEach((subtitle, propId) => {
        const prop = ntaData.properties[propId];
        if (prop) {
            const props = _propsBySubtitle.get(subtitle) || [];
            if (props.length === 0) {
                _propsBySubtitle.set(subtitle, props)
            }
            props.push(prop);
        }
    });
    // Zorg dat de properties in volgorde van weergave staan
    _propsBySubtitle.forEach(props => props.sort((a, b) => a.Order - b.Order));

    const _textByPropId = new Map(Object.entries({
        'GEBR-PROFIEL_KOEL_SETPOINT': 'Onderstaande invoer is relevant indien bij de varianten een koelsysteem is toegevoegd.',
    }));

    const _tablePropsById = new Map(Object.entries({
        'GEBR-PROFIEL_RZ_TABEL':            { rowEntityId: 'GEBR-RZ',            keyPropId: 'RZ_OMSCHR' },
        'GEBR-PROFIEL_VERW_RZ_TABEL':       { rowEntityId: 'GEBR-RZ-VERW',       keyPropId: 'RZ_OMSCHR' },
        'GEBR-PROFIEL_KOEL_RZ_TABEL':       { rowEntityId: 'GEBR-RZ-KOEL',       keyPropId: 'RZ_OMSCHR' },
        'GEBR-PROFIEL_VENT_TABEL':          { rowEntityId: 'GEBR-VENT',          keyPropId: 'INSTALL_NAAM' },
        'GEBR-PROFIEL_TAPW_RZ_TABEL':       { rowEntityId: 'GEBR-RZ-TAPW',       keyPropId: 'RZ_OMSCHR' },
        'GEBR-PROFIEL_BEZF_TABEL':          { rowEntityId: 'GEBR-RZ-BEZF',       keyPropId: 'RZ_OMSCHR',    totalPropIds: ['GEBR-RZ-BEZF_NGEBGEB-E'] },
        'GEBR-PROFIEL_WARMTEPROD_TABEL':    { rowEntityId: 'GEBR-RZ-WARMTEPROD', keyPropId: 'RZ_OMSCHR',    totalPropIds: ['GEBR-RZ-WARMTEPROD_NGEBGEB-E'] },
        'GEBR-PROFIEL_VERL_RZ_TABEL':       { rowEntityId: 'GEBR-RZ-VERL',       keyPropId: 'RZ_OMSCHR' },
    }));

    const _subTablePropsById = new Map(Object.entries({
        'GEBR-RZ-WARMTEPROD_GF': { rowEntityId: 'GEBR-RZ-WARMTEPROD-GF', rowParentEntityId: 'GEBR-RZ-WARMTEPROD', keyPropId: 'UNIT-RZ-GFID' },
    }));

    const _tableInfos = new Map();

    const _listCache = new ListCache();


    //== Exports ==================================================================================
    ctrl.logic = logic;
    ctrl.properties = logic.properties;
    ctrl.entdata = logic.entdataGebruikersprofiel;
    ctrl.isReadOnly = logic.isReadOnly;
    ctrl.getCodedValues = logic.getCodedValues;
    ctrl.isItemChecked = logic.isItemChecked;
    ctrl.toggleItemChecked = logic.toggleItemChecked;
    ctrl.onValidateCallback = logic.onValidateCallback;
    ctrl.canShowDiagramWindow = ntaDiagram.canShowDiagramWindow;
    ctrl.showDiagramWindow = ntaDiagram.showDiagramWindow;

    ctrl.getForm = () => logic.form;
    ctrl.getPropData = logic.getPropData;
    ctrl.getPropertyType = prop => prop && prop.PropertyType;

    Object.assign(ctrl, {
        isHidden,
        getSubtitle,
        getPropText,
        getName,
        isTableProp,
        isSubTableProp,
        getTableInfo,
        getSubTableInfo,
        getClass,
        getStyle,
        saveValue,
    });

    $scope.endFormValidation = logic.endFormValidation;


    //== Initialization ===========================================================================

    angular.element(async function () { //alle touched properties valideren na het laden van form
        await time.delay(500); // gun AngularJS wat tijd om het formulier te renderen
        logic.startFormValidation();
        logic.setGeopend();
    });


    //== Implementation ===========================================================================

    function isHidden(prop, entdata = logic.entdataGebruikersprofiel) {
        if (typeof prop === 'string') prop = ntaData.properties[prop];
        const propdata = prop && entdata && logic.getPropData(prop, entdata);
        const wasVisible = !!propdata && propdata.Visible;

        const visible = !logic.isHidden(prop, entdata);

        // Als dit veld onzichtbaar is geworden, dan controleren of er een (sub)tabel bij hoort;
        //  en zo ja, dan isHidden uitvoeren op de rijen (en kolommen) van die (sub)tabel, zodat
        //  eventuele daarbij behorende meldingen verborgen worden.
        if (wasVisible && !visible) {
            if (isTableProp(prop)) {
                const info = getTableInfo(prop, entdata);
                for (const row of info.getRows()) {
                    for (const prop of info.properties) {
                        isHidden(prop, row);
                    }
                }
            } else if (isSubTableProp(prop)) {
                const info = getSubTableInfo(prop, entdata);
                for (const row of info.getRows()) {
                    for (const prop of info.properties) {
                        isHidden(prop, row);
                    }
                }
            }
        }

        return !visible;
    } //-- end: isHidden --------------------------------------------------------------------------

    function getSubtitle(prop, entdata) {
        const subtitle = _subtitleByPropId.get(prop.Id) || '';

        // Controleer of deze subtitel niet al eerder is weergegeven (veralgemenisering van [MW-AF])
        for (const prevProp of _propsBySubtitle.get(subtitle) || []) {
            if (prevProp === prop) {
                break; // we hoeven alleen te kijken naar eerdere properties
            } else {
                const prevPropdata = prevProp.getData(entdata);
                if (prevPropdata && prevPropdata.Visible) {
                    return ''; // dan is deze subtitel al eerder weergegeven, en hoeft dat niet nog een keer te gebeuren.
                }
            }
        }

        return subtitle;
    } //-- end: getSubtitle -----------------------------------------------------------------------

    function getPropText(prop) {
        let text = _textByPropId.get(prop.Id) || '';
        switch (prop.Id) {
            case 'GEBR-PROFIEL_KOEL_SETPOINT': {
                // [MW-R] toon indien geen koeling in de basisberekening aanwezig is
                const koelingAanwezig = !!ntaEntityData.getFirstWithEntityId('KOEL');
                if (koelingAanwezig) {
                    text = ''; // dus niet weergeven als er wel koeling aanwezig is
                }
                break;
            }
        }
        return text;
    } //-- end: getPropText -----------------------------------------------------------------------

    function getName(prop, includingUnit = true) {
        switch (prop.Id) {
            case 'RZ_OMSCHR':
                return 'rekenzone';

            case 'INSTALL_NAAM':
                return 'ventilatiesysteem';

            default: {
                return base.getName(prop, includingUnit);
            }
        }
    } //-- end: getName ---------------------------------------------------------------------------

    function isTableProp(prop) {
        return _tablePropsById.has(prop.Id);
    } //-- end: isTableProp -----------------------------------------------------------------------

    function isSubTableProp(prop) {
        return _subTablePropsById.has(prop.Id);
    } //-- end: isTableProp -----------------------------------------------------------------------

    function getTableInfo(prop, entdata, tablePropsById = _tablePropsById) {
        const key = prop.Id + ':' + entdata.EntityDataId;
        let info = _tableInfos.get(key);
        if (!info) {
            const { rowEntityId, keyPropId, totalPropIds } = tablePropsById.get(prop.Id) || {};

            const properties = (ntaData.properties[rowEntityId] || []).slice();
            if (keyPropId) {
                properties.unshift(ntaData.properties[keyPropId]);
            }

            const rows = rowEntityId && ntaEntityData.getChildren(entdata, rowEntityId) || [];

            const totalPropIdSet = new Set(totalPropIds || []);

            info = {
                title: prop.Name,
                properties,
                getRows: () => rows,
                getColCount: () => properties.reduce((sum, prop) => sum + (isHidden(prop, null) ? 0 : 1), 0),
                hasTotal: () => totalPropIds && rows.length > 1 && totalPropIds.some(propId => !isHidden(propId, null)),
                getTotalColumns: () => {
                    const cols = properties
                        .filter(prop => prop.Id === keyPropId || !isHidden(prop, null))
                        .map(prop => {
                            let value = '';
                            if (totalPropIdSet.has(prop.Id)) {
                                const sum = rows.reduce((sum, row) => sum + (isHidden(prop, row) ? 0 : ntaSharedLogic.parseFloat(prop.getValue(row), 0)), 0);
                                value = ntaRounding.roundAndAddZerosNewValue(prop, sum);
                            } else if (prop.Id === keyPropId) {
                                value = 'Totaal';
                            }
                            return { prop, value };
                        });
                    return _listCache.useCacheIfUnchanged(key + '.totalColums', cols, (a, b) => a.prop === b.prop && a.value === b.value);
                },
            };
            _tableInfos.set(key, info);
        }
        return info;
    } //-- end: getTableInfo ----------------------------------------------------------------------

    function getSubTableInfo(prop, entdata) {
        //voor header is entdata GEBR-PROFIEL -> dan eerst child ophalen
        if (entdata.EntityId === "GEBR-PROFIEL") {
            const { rowParentEntityId } = _subTablePropsById.get(prop.Id) || {};
            entdata = ntaEntityData.getFirstChild(entdata, rowParentEntityId);
        }
        return getTableInfo(prop, entdata, _subTablePropsById);
    } //-- end: getSubTableInfo -------------------------------------------------------------------

    function getClass(prop) {
        switch (prop.PropertyType) {
            case 1: // number
                return 'columnCenter';
            case 3: // boolean
                return 'ntacheckbox columnCenter';
        }
        return '';
    } //-- end: getClass --------------------------------------------------------------------------

    function getStyle(info, row, col) {
        return {
            'border-bottom': (info.getRows().slice(-1)[0] === row || row === null) ? '0px' : '1px solid #ccc',
            'border-left': (info.properties.slice(0)[0] === col && row !== null) ? '1px solid #ccc' : '0px',
            'width': (info.properties.slice(0)[0] === col) ? 'auto' : '100px',
            'max-width': '100px'
        }
    }//-- end: getStyle --------------------------------------------------------------------------

    function saveValue(prop, entdata = logic.entdataGebruikersprofiel) {
        logic.saveValue(prop, entdata);
        base.initializeUnitSpacing();
    } //-- end: saveValue -------------------------------------------------------------------------

}]);
