angular.module('indexModule').directive("subFoldersMove", ['indextree', function (indextree) {
    return {
        templateUrl: '/src/app/project/subfoldersmove.html?v=' + indextree.commit
    };
}]);

angular.module('indexModule')
    .controller("IndexMoveController",
        ['indextree', '$element', '$mdDialog',
function (indextree,   $element,   $mdDialog, ) {
    'use strict';
    const vm = this;

    let _activeFolderId = indextree.folders.find(x => x.Active).FolderId;
    const _openFolders = new Map(indextree.folders.map(({ FolderId, Open }) => [FolderId, Open]));

    function isFolderOpen(folderId) {
        return _openFolders.get(folderId) || false;
    }

    function setOpenFolder(folderId, open) {
        _openFolders.set(folderId, !!open);
    }

    function toggleOpenFolder(folderId) {
        setOpenFolder(folderId, !isFolderOpen(folderId));
    }

    vm.folders = function (parentId, isDeleted) {
        isDeleted = Boolean(isDeleted);
        return indextree.foldersById.get(parentId)
            .Subfolders.filter(f => Boolean(f.DeleteDate) === isDeleted);
    };

    vm.folderClick = function (folderId) {
        if ($("#foldername" + folderId).hasClass("showname")) {

            if (folderId === -1 && vm.buildingId) {
                return;
            }

            if (folderId === _activeFolderId) {
                toggleOpenFolder(folderId);
            } else {
                setOpenFolder(folderId, true);
            }

            _activeFolderId = folderId;
        }
    };

    vm.folderActive_Move = function (folderId) {
        return folderId === _activeFolderId;
    };

    vm.folderOpen_Move = function (folderId) {
        return isFolderOpen(folderId);
    };

    vm.buildingInFolderCount = function (folderId) {
        return indextree.buildingInFolderCount(folderId);
    };

    vm.getFolderNameWidth_Move = function (id) {
        const element = document.getElementById('folderitem' + id)
        if (element) {
            return element.offsetWidth;
        } else return 64;
    };

    vm.moveItem = async function () {
        $mdDialog.cancel();
        const sourceFolder = indextree.folders.find(x => x.Active);
        if (vm.buildingId) {
            await indextree.moveBerekening(vm.buildingId, sourceFolder.FolderId, _activeFolderId);
        } else {
            await indextree.moveFolder(sourceFolder.FolderId, _activeFolderId);
        }
        openParentFolder(_activeFolderId);
        sourceFolder.Open = true;
        $('#folderitem' + sourceFolder.FolderId).parent().trigger('click');
    };

    function openParentFolder(folderId) {
        const folder = indextree.foldersById.get(folderId);
        if (folder) {
            folder.Open = true;
            if (folder.ParentId > 0) openParentFolder(folder.ParentId);
        }
    }

    vm.closeMoveItemDialog = function () {
        $mdDialog.cancel();
    };

    indextree.waitForElement('#folderclickmove' + _activeFolderId, 2500)
        .then(activeFolderElement => {
            if (activeFolderElement) {
                setTimeout(() => { activeFolderElement.scrollIntoView({ behavior: 'smooth', block: 'center' }) }, 500);
            }
        });

}]);
