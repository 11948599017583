﻿angular.module('projectModule')
    .factory("TapwaterFactory",
        ['ntabuilding', 'ntaValidation', 'ntaEntityData', '$mdDialog', 'ntaMeldingen', 'ntaRounding', 'time', 'ntaSharedLogic', 'ntaSelectionTable', 'ListCache',
function (ntabuilding,   ntaValidation,   ntaEntityData,   $mdDialog,   ntaMeldingen,   ntaRounding,   time,   ntaSharedLogic,   ntaSelectionTable,   ListCache) {
    function TapwaterLogic(installId, ntaDependencyValidation) {
        'use strict';
        const tapwaterLogic = this; // Object dat aan de controller gegeven zal worden

        const _listCache = new ListCache();

        tapwaterLogic.installId = installId;
        tapwaterLogic.selectionTable = [];
        tapwaterLogic.selectionTableRegios = [];
        tapwaterLogic.selectionTableBron = [];
        tapwaterLogic.selectionTableBVat = [];
        tapwaterLogic.selectionTableDWTW = [];
        tapwaterLogic.bouwjaar = ntaSharedLogic.getBouwjaar();

        tapwaterLogic.w031Open = false;

        function orderElements(a, b) {
            return a.Order - b.Order;
        } //-- end: orderElements ------------------------------------------------------------------------//

        //-- Properties
        tapwaterLogic.propOpmerkingen = ntabuilding.properties['TAPW_OPM'];
        tapwaterLogic.tapwOpwekkerProperties = ntabuilding.properties['TAPW-OPWEK'];
        tapwaterLogic.tapwVoorraadvatProperties = ntabuilding.properties['TAPW-VAT'];
        tapwaterLogic.tapwDistributieProperties = ntabuilding.properties['TAPW-DISTR'];
        tapwaterLogic.tapwAfgifteProperties = ntabuilding.properties['TAPW-AFG'];
        tapwaterLogic.tapwDouchewarmteterugwinningProperties = ntabuilding.properties['TAPW-DOUCHE'];
        tapwaterLogic.tapwDistributieBinnenProperties = ntabuilding.properties['TAPW-DISTR-BIN'];
        tapwaterLogic.tapwDistributieBuitenProperties = ntabuilding.properties['TAPW-DISTR-BUI'];
        tapwaterLogic.tapwDistributieEigenschappenLeidingenProperties = ntabuilding.properties['TAPW-DISTR-EIG'];
        tapwaterLogic.tapwDistributiePompProperties = ntabuilding.properties['TAPW-DISTR-POMP'];
        tapwaterLogic.tapwAfgifteLeidingenProperties = ntabuilding.properties['TAPW-AFG-LEI'];
        tapwaterLogic.tapwDouchesAangeslotenOpWTWProperties = ntabuilding.properties['TAPW-DOUCHE-AANG'];

        //-- table13_0
        function createTable13_0() {
            const pef = 'primaire energiefactor';
            const gf = 'geen forfaitair';
            const gemeensch = 'gemeenschappelijk';

            const table13_0 = [
                { Id: 'TAPW-OPWEK_TYPE_1',  prio:  2, indirect: 'optioneel', key1: 'WP',  key2: 'product', key3: 'EL',   key4: 'combi', key5: '',     key6: '', key7: '', key8: 'beide',       key_hotfill: true,  key_ovv: true,  Value: 'warmtepomp - elektrisch' },
                { Id: 'TAPW-OPWEK_TYPE_2',  prio:  5, indirect: '',          key1: 'BWP', key2: 'product', key3: 'EL',   key4: '',      key5: '',     key6: '', key7: '', key8: 'individueel', key_hotfill: true,  key_ovv: false, Value: 'booster - elektrisch' },
                { Id: 'TAPW-OPWEK_TYPE_3',  prio:  7, indirect: 'optioneel', key1: 'WP',  key2: '',        key3: 'GAS',  key4: '',      key5: 'hide', key6: '', key7: '', key8: 'beide',       key_hotfill: true,  key_ovv: true,  Value: 'warmtepomp - gas' },
                { Id: 'TAPW-OPWEK_TYPE_4',  prio:  9, indirect: 'optioneel', key1: 'EW',  key2: 'product', key3: pef,    key4: 'combi', key5: 'hide', key6: '', key7: '', key8: 'beide',       key_hotfill: false, key_ovv: true,  Value: 'externe warmtelevering' },
                { Id: 'TAPW-OPWEK_TYPE_5',  prio: 11, indirect: '',          key1: 'EB',  key2: 'product', key3: 'EL',   key4: '',      key5: 'hide', key6: '', key7: '', key8: 'beide',       key_hotfill: true,  key_ovv: true,  Value: 'boiler - elektrisch' },
                { Id: 'TAPW-OPWEK_TYPE_6',  prio: 10, indirect: '',          key1: 'EB',  key2: 'product', key3: 'EL',   key4: '',      key5: 'hide', key6: '', key7: '', key8: 'individueel', key_hotfill: false, key_ovv: false, Value: 'kokendwater toestel - elektrisch' },
                { Id: 'TAPW-OPWEK_TYPE_7',  prio: 15, indirect: '',          key1: 'EB',  key2: 'product', key3: 'EL',   key4: '',      key5: 'hide', key6: '', key7: gf, key8: 'beide',       key_hotfill: false, key_ovv: false, Value: 'thermische batterij - elektrisch' },
                { Id: 'TAPW-OPWEK_TYPE_8',  prio: 12, indirect: '',          key1: 'DS',  key2: '',        key3: 'EL',   key4: '',      key5: 'hide', key6: '', key7: '', key8: 'individueel', key_hotfill: false, key_ovv: false, Value: 'doorstroomtoestel - elektrisch' },
                { Id: 'TAPW-OPWEK_TYPE_9',  prio:  6, indirect: '',          key1: 'CIS', key2: 'product', key3: 'EL',   key4: '',      key5: 'hide', key6: '', key7: gf, key8: 'individueel', key_hotfill: false, key_ovv: false, Value: 'close-in shower' },
                { Id: 'TAPW-OPWEK_TYPE_10', prio:  3, indirect: 'verplicht', key1: 'BM',  key2: '',        key3: 'BM',   key4: 'combi', key5: '',     key6: '', key7: '', key8: 'beide',       key_hotfill: true,  key_ovv: true,  Value: 'CV-ketel - biomassa' },
                { Id: 'TAPW-OPWEK_TYPE_11', prio: 13, indirect: 'verplicht', key1: 'CVE', key2: '',        key3: 'EL',   key4: 'combi', key5: 'hide', key6: '', key7: '', key8: 'beide',       key_hotfill: true,  key_ovv: true,  Value: 'CV-ketel - elektrisch' },
                { Id: 'TAPW-OPWEK_TYPE_12', prio: 18, indirect: 'optioneel', key1: 'CVG', key2: 'product', key3: 'GAS',  key4: 'combi', key5: '',     key6: 'Cwgen', key7: '', key8: 'beide',  key_hotfill: true,  key_ovv: true,  Value: 'CV-ketel - gas' },
                { Id: 'TAPW-OPWEK_TYPE_13', prio: 17, indirect: 'verplicht', key1: 'CVO', key2: '',        key3: 'OLIE', key4: 'combi', key5: '',     key6: '', key7: '', key8: 'beide',       key_hotfill: true,  key_ovv: true,  Value: 'CV-ketel - olie' },
                { Id: 'TAPW-OPWEK_TYPE_14', prio: 20, indirect: '',          key1: 'GG',  key2: 'product', key3: 'GAS',  key4: '',      key5: '',     key6: 'Cwgen', key7: '', key8: 'beide',  key_hotfill: true,  key_ovv: true,  Value: 'geiser - gas' },
                { Id: 'TAPW-OPWEK_TYPE_15', prio: 16, indirect: '',          key1: 'GBK', key2: 'product', key3: 'GAS',  key4: '',      key5: 'hide', key6: '', key7: '', key8: 'beide',       key_hotfill: true,  key_ovv: true,  Value: 'gasboiler ≤ 150 kW' },
                { Id: 'TAPW-OPWEK_TYPE_16', prio: 19, indirect: '',          key1: 'GBG', key2: 'product', key3: 'GAS',  key4: '',      key5: 'hide', key6: '', key7: '', key8: 'beide',       key_hotfill: true,  key_ovv: true,  Value: 'gasboiler > 150 kW' },
                { Id: 'TAPW-OPWEK_TYPE_17', prio:  4, indirect: 'verplicht', key1: 'WKK', key2: '',        key3: 'GAS',  key4: 'combi', key5: '',     key6: '', key7: '', key8: 'beide',       key_hotfill: true,  key_ovv: false, Value: 'WKK' },
                { Id: 'TAPW-OPWEK_TYPE_18', prio:  8, indirect: '',          key1: 'ZB',  key2: '',        key3: 'EL',   key4: '',      key5: 'hide', key6: '', key7: '', key8: 'beide',       key_hotfill: false, key_ovv: false, Value: 'zonneboiler met geïntegreerde elektrische naverwarming' },
                { Id: 'TAPW-OPWEK_TYPE_19', prio: 14, indirect: '',          key1: 'ZB',  key2: '',        key3: 'GAS',  key4: '',      key5: '',     key6: '', key7: '', key8: 'beide',       key_hotfill: false, key_ovv: false, Value: 'zonneboiler met geïntegreerde gasgestookte naverwarming' },
                { Id: 'TAPW-OPWEK_TYPE_20', prio: 21, indirect: '',          key1: 'ONB', key2: '',        key3: 'GAS',  key4: '',      key5: 'hide', key6: '', key7: '', key8: gemeensch,     key_hotfill: false, key_ovv: false, Value: 'onbekende gemeenschappelijke opwekker' },
                { Id: 'TAPW-OPWEK_TYPE_21', prio: 22, indirect: '',          key1: 'WUV', key2: '',        key3: '',     key4: '',      key5: '',     key6: '', key7: '', key8: gemeensch,     key_hotfill: false, key_ovv: false, Value: 'warmte uit verwarmingssysteem' },
                { Id: 'TAPW-OPWEK_TYPE_22', prio: 24, indirect: 'verplicht', key1: '',    key2: '',        key3: 'EL',   key4: '',      key5: 'hide', key6: '', key7: '', key8: 'beide',       key_hotfill: false, key_ovv: false, Value: 'elektrisch element' },
                { Id: 'TAPW-OPWEK_TYPE_23', prio: 23, indirect: 'optioneel', key1: 'EB',  key2: '',        key3: 'EL',   key4: '',      key5: 'hide', key6: '', key7: '', key8: 'individueel', key_hotfill: false, key_ovv: false, Value: 'hotfill boiler (met/zonder elektrisch element)' },
                { Id: 'TAPW-OPWEK_TYPE_24', prio:  1, indirect: '',          key1: 'OVV', key2: '',        key3: '',     key4: '',      key5: '',     key6: '', key7: '', key8: gemeensch,     key_hotfill: false, key_ovv: false, Value: 'opwekker van verwarmingssysteem' },
            ];

            const opwekkerCodedValues = ntabuilding.properties["TAPW-OPWEK_TYPE"].Domain.Codes;
            const codedValuesById = new Map(opwekkerCodedValues.map(cv => [cv.Id, cv]));

            return table13_0
                .filter(row => {
                    const codedValue = codedValuesById.get(row.Id);
                    if (codedValue) {
                        row.Value = codedValue.Value;
                        row.Order = codedValue.Order;
                    }
                    return codedValue;
                })
                .sort(orderElements);

        } //-- end: createTable13_0 ---------------------------------------------------------------

        tapwaterLogic.opwekkerTypes = createTable13_0();

        // Als geen opwekkerNr is opgegeven, dan geven we alle opwekkertypes die geldig zijn voor de volgende (tbv de button ‘toevoegen’)
        tapwaterLogic.getOpwekkerTypes = function (opwekkerNr = tapwaterLogic.tapwOpwekkersData.length + 1) {
            let types = tapwaterLogic.opwekkerTypes;

            // Conditie [TOAQ] als kolom # TO04 >1 EN TO05 van de eerste opwekker = 'opwekker uit verwarmingssysteem' filter bij de 2e opwekker op table13_0 kolom key_ovv = ja.
            //                 als kolom # TO04 >1 EN TO05 van de eerste opwekker ≠ 'opwekker uit verwarmingssysteem' toon alleen waarden uit kolom 'opwekker' van table13_0 waar kolom 'indirect' ≠ leeg
            if (opwekkerNr > 1) {
                const [opwekker1] = tapwaterLogic.tapwOpwekkersData;
                const opwekker1type = opwekker1.PropertyDatas['TAPW-OPWEK_TYPE'].Value;
                if (opwekker1type === 'TAPW-OPWEK_TYPE_24') { // opwekker uit verwarmingssysteem
                    types = types.filter(x => x.key_ovv); // dan zijn er maximaal 2 opwekkers
                } else {
                    types = types.filter(x => x.indirect !== '');

                    // Conditie [TODG] Als button getoond
                    //   EN TO05 eerste opwekker ≠ opwekker van verwarmingssysteem
                    //   EN TO06 van eerste opwekker = verborgen / geen indirect verwarmde warm watervoorraadvaten /  warmtepomp met geintegreerd warmwatervoorraadvat
                    // toon voor kolom 2 als enige keuze 'hotfill boiler elektrisch'
                    const isButtonVisible = () => tapwaterLogic.getMaxOpwekkers() > 1;
                    const heeftGeenIndirecteVaten = () => {
                        const propOpwekkerIndirect = ntabuilding.properties['TAPW-OPWEK_INDIR']; // TO06
                        const opwekker1indirect = propOpwekkerIndirect.getValue(opwekker1);
                        return tapwaterLogic.isHidden(propOpwekkerIndirect, opwekker1)
                            || new Set(['TAPW-OPWEK_INDIR_GEEN', 'TAPW-OPWEK_INDIR_GEINT']).has(opwekker1indirect);
                    };
                    if (opwekkerNr === 2 && isButtonVisible() && heeftGeenIndirecteVaten()) {
                        types = types.filter(x => x.Id === 'TAPW-OPWEK_TYPE_23'); // hotfill boiler (met/zonder elektrisch element)
                    }
                }
            }

            // Conditie [TOCT] verberg in de eerste kolom de opwekkers: 'elektrische element' en 'hotfill boiler (met/zonder elektrisch element)'
            //  verberg in de derde en latere kolommen de opwekker: 'hotfill boiler (met/zonder elektrisch element)'
            //  verberg in de tweede en latere kolommen de opwekker: 'opwekker van verwarmingssysteem'
            if (opwekkerNr === 1) {
                types = types.filter(x => x.Id !== 'TAPW-OPWEK_TYPE_22');   // elektrisch element
            }
            if (opwekkerNr !== 2) {
                types = types.filter(x => x.Id !== 'TAPW-OPWEK_TYPE_23');   // hotfill boiler (met/zonder elektrisch element)
            }
            if (opwekkerNr >= 2) {
                types = types.filter(x => x.Id !== 'TAPW-OPWEK_TYPE_24');   // opwekker van verwarmingssysteem
            }

            // Conditie [TODE] verberg keuze 'opwekker van verwarmingssysteem' als bij alle verwarmingssystemen in de berekening HO42 = niet-gemeenschappelijke installatie
            const allesNietGemeenschappelijk = ntaEntityData.getListWithEntityId('VERW')
                .map(verw => ntaEntityData.getFirstChild(verw, 'VERW-OPWEK')) // alleen de 1e opwekker van elk verwarmingssysteem
                .every(prefOpwekker => prefOpwekker.PropertyDatas['VERW-OPWEK_GEM'].Value === 'VERW-OPWEK_GEM_NIET'); // HO42 = niet-gemeenschappelijke installatie
            if (allesNietGemeenschappelijk) {
                types = types.filter(x => x.Id !== 'TAPW-OPWEK_TYPE_24'); // opwekker van verwarmingssysteem
            }

            return types;
        }; //-- end: getOpwekkerTypes -------------------------------------------------------------

        tapwaterLogic.getMaxOpwekkers = function () {
            const [opwekker1, opwekker2] = tapwaterLogic.tapwOpwekkersData;
            const opwekker1typeValue = opwekker1.PropertyDatas['TAPW-OPWEK_TYPE'].Value; // TO05 in de eerste kolom
            const opwekker1type = tapwaterLogic.opwekkerTypes.find(x => x.Id === opwekker1typeValue);

            let maxOpwekkers = 1;

            //-- Conditie [TOBU] Als key1 van opwekker gekozen in TO05 = 'WUV' verberg alle velden beginnend met TO met uitzondering van TO09, TO10 en TO58
            if (opwekker1type && opwekker1type.key1 === 'WUV') {
                maxOpwekkers = 1;

            } else {

                //-- Conditie [TOA] toon button als:
                const opwekker1invoer = getPropdataValueIfValid(opwekker1, 'TAPW-OPWEK_INV'); // TO07
                const opwekker1indirect = getPropdataValueIfValid(opwekker1, 'TAPW-OPWEK_INDIR'); // TO06
                const isOpwekker1indirect = ['TAPW-OPWEK_INDIR_WEL', 'TAPW-OPWEK_INDIR_LOS'].includes(opwekker1indirect);
                const opwekker1gemeenschappelijk = getPropdataValueIfValid(opwekker1, 'TAPW-OPWEK_GEM'); // TO09

                // 1. TO07 = forfaitair / productspecifiek / eigen waarde opwekkingsrendement EN TO06 = indirect verwarmde warm watervoorraadvat(en) / warmtepomp met losse voorraadvat(en)
                if (['TAPW-OPWEK_INV_FORF', 'TAPW-OPWEK_INV_PROD', 'TAPW-OPWEK_INV_EIG_REND'].includes(opwekker1invoer) && isOpwekker1indirect) { // TO06
                    maxOpwekkers = Number.MAX_SAFE_INTEGER;
                }

                // 3. als gt3.2 EN de opwekker in kolom 1 gekozen bij TO05 in table13_0 by key_hotfill = ja EN TO09 = niet-gemeenschapellijke installatie
                if (ntabuilding.ntaVersionId >= 300 &&  opwekker1type && opwekker1type.key_hotfill && opwekker1gemeenschappelijk === 'TAPW-OPWEK_GEM_NIET') {
                    maxOpwekkers = Number.MAX_SAFE_INTEGER;
                }

                // 2. TO07 van de eerste opwekker = eigen waarde opwekkingsrendement en fractie / eigen waarde opwekkingsrendement, fractie en hulpenergie
                //    EN aantal kolommen is 1 (dus maximaal 2 kolommen)
                //    EN TO06 = indirect verwarmde warm watervoorraadvat(en) / warmtepomp met losse voorraadvat(en)
                if (['TAPW-OPWEK_INV_EIG_REND_FRAC', 'TAPW-OPWEK_INV_EIG_REND_FRAC_HULP'].includes(opwekker1invoer) && isOpwekker1indirect) {
                    maxOpwekkers = 2;
                }

                // Conditie [TODA] als TO05 in de eerste kolom is 'opwekker van verwarmingssysteem' toon automatisch een 2e kolom (geen button)
                if (opwekker1typeValue === 'TAPW-OPWEK_TYPE_24') { // opwekker van verwarmingssysteem
                    maxOpwekkers = 2;
                }

                //-- Conditie [TOCS] verberg button als in de 2e kolom bij TO05 = 'hotfill boiler (met/zonder elektrisch element)';
                //    in deze situatie maximaal 2 opwekkers, eventuele overige opwekkers verwijderen.
                if (opwekker2 && opwekker2.PropertyDatas['TAPW-OPWEK_TYPE'].Value === 'TAPW-OPWEK_TYPE_23') { // TO05
                    maxOpwekkers = 2;
                }

            }

            return maxOpwekkers;
        } //-- end: getMaxOpwekkers -------------------------------------------------------------------

        function getPropdataValueIfValid(entdata, propId) {
            const propdata = entdata && entdata.PropertyDatas[propId];
            if (!propdata)
                return;

            const control = tapwaterLogic.form_installationtapwater['ntainput' + propdata.PropertyDataId];
            if (control && control.$invalid)
                return;

            return propdata.Value;
        } //-- end: getPropdataValueIfValid -----------------------------------------------------------

        tapwaterLogic.voorraadvatTypes = tapwaterLogic.tapwVoorraadvatProperties["TAPW-VAT_INV"].Domain.Codes;

        //-- Tapwater id
        tapwaterLogic.tapwData = ntaEntityData.getFirstChild(tapwaterLogic.installId, 'TAPW');
        tapwaterLogic.tapwDataId = tapwaterLogic.tapwData.EntityDataId;

        tapwaterLogic.setTapwOpwekkers = function () {
            tapwaterLogic.tapwOpwekkersData = ntaEntityData.getChildren(tapwaterLogic.tapwDataId, 'TAPW-OPWEK');
            tapwaterLogic.tapwOpwekkersIds = tapwaterLogic.tapwOpwekkersData.map(ed => ed.EntityDataId);
        };

        //-- Opwekking entiteiten
        tapwaterLogic.setTapwOpwekkers();

        tapwaterLogic.getTapwOpwekkersIds = function () {
            return tapwaterLogic.tapwOpwekkersIds;
        };

        tapwaterLogic.opwekkerNr = function (opwekker) {
            return tapwaterLogic.tapwOpwekkersData.indexOf(opwekker) + 1;
        } //-- end: opwekkerNr ------------------------------------------------------------------------//

        tapwaterLogic.addOpwekker = function (typeId) {
            const tapw = tapwaterLogic.tapwData;
            //-- conditie [TOCV]
            if (typeId && tapwaterLogic.tapwOpwekkersData.length === 1 && ntaSharedLogic.checkHotfillBoiler(tapw, typeId)) {
                return; // dan niet toevoegen.
            }

            const newId = ntaEntityData.create("TAPW-OPWEK", -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": tapw.EntityDataId, "ParentEntityId": tapw.EntityId }], []);
            const opwekker = ntaEntityData.get(newId);
            if (typeId) {
                ntaEntityData.saveprop(opwekker.PropertyDatas["TAPW-OPWEK_TYPE"], typeId);
            }

            tapwaterLogic.setTapwOpwekkers();

            tapwaterLogic.setDefaultValuesOpwekker(opwekker);
            tapwaterLogic.checkPrioOpwekker(opwekker);

            tapwaterLogic.setEnergieFractie();
            tapwaterLogic.startValidationEnergiefractie();

            return opwekker;
        } //-- end: addOpwekker -------------------------------------------------------------------

        tapwaterLogic.startValidationEnergiefractie = function () {
            const opwekker = tapwaterLogic.tapwOpwekkersData[0];

            const propdataEnergiefractieNon = opwekker.PropertyDatas['TAPW-OPWEK_ENER_NON'];
            const propdataEnergiefractie = opwekker.PropertyDatas['TAPW-OPWEK_ENER'];

            tapwaterLogic.validate(null, propdataEnergiefractieNon);
            tapwaterLogic.validate(null, propdataEnergiefractie);
        }; //-- end: startValidationEnergiefractie ------------------------------------------------

        tapwaterLogic.pompNr = function (pomp) {
            return tapwaterLogic.tapwDistributiePompData.indexOf(pomp) + 1;
        };

        tapwaterLogic.pompNaam = function (pomp) {
            let nummer = tapwaterLogic.pompNr(pomp);
            let naam = 'pomp ' + nummer;
            return naam;
        };

        var getOpenVerbrandingCodedValues = function (opwekkerEntdata) {
            const rekenzones = ntaEntityData.findEntities(tapwaterLogic.tapwData, 'TAPW-UNIT!.^UNIT.UNIT-RZ.^RZ', 'TAPW-UNIT-RZ!.^UNIT-RZ.^RZ');

            const rzCodedValues = rekenzones
                .map(rekenzone => ({
                    "Id": rekenzone.EntityDataId,
                    "Value": 'toestel in zone ' + rekenzone.PropertyDatas['RZ_OMSCHR'].Value,
                }));

            let shouldAddBuiten = true;

            // Conditie [TOCE]
            const propPositieOpwekker = ntabuilding.properties['TAPW-OPWEK_POS'];
            if (!tapwaterLogic.isHidden(propPositieOpwekker, opwekkerEntdata) && propPositieOpwekker.getValue(opwekkerEntdata) === "TAPW-OPWEK_POS_BIN") {
                shouldAddBuiten = false;
            }

            if (shouldAddBuiten) {
                const prop = ntabuilding.properties['TAPW-OPWEK_VERBR_OPS'];
                rzCodedValues.push(...ntaValidation.codedValues(prop));
            }

            // Haal een evt. gecachete versie van de lijst voor deze opwekker op. Als de huidige lijst niet gewijzigd is, dan geven we de oude lijst terug, zodat Angular kan zien dat er niets gewijzigd is.
            return _listCache.useCacheIfUnchanged(opwekkerEntdata.EntityDataId, rzCodedValues, (a, b) => a.Id === b.Id);
        };

        function getVerwarmingssystemen() {
            const verwarmingsinstallaties = ntaEntityData.getListWithEntityId('INSTALLATIE')
                .filter(i => i.PropertyDatas['INSTALL_TYPE'].Value === "INST_VERW");
            verwarmingsinstallaties.forEach((installatie, index) => {
                installatie.Id = ntaEntityData.getFirstChild(installatie, 'VERW').EntityDataId;
                const propdataName = installatie.PropertyDatas['INSTALL_NAAM'];
                installatie.Value = propdataName && propdataName.Value || 'verwarmingssysteem ' + (index + 1);
            });

            return verwarmingsinstallaties;
        } //-- end: getVerwarmingssystemen ------------------------------------------------------------------------//

        function getVentsystemen() {
            const ventilatieinstallaties = ntaEntityData.getListWithEntityId('INSTALLATIE')
                .filter(i => i.PropertyDatas['INSTALL_TYPE'].Value === "INST_VENT");
            ventilatieinstallaties.forEach((installatie, index) => {
                installatie.Id = ntaEntityData.getFirstChild(installatie, 'VENT').EntityDataId;
                const propdataName = installatie.PropertyDatas['INSTALL_NAAM'];
                installatie.Value = propdataName && propdataName.Value || 'ventilatiesysteem ' + (index + 1);
            });

            return ventilatieinstallaties;
        } //-- end: getVentsystemen ------------------------------------------------------------------------//

        const _verwarmingSystemen = getVerwarmingssystemen();
        const _ventilatieSystemen = getVentsystemen();

        var getKoelsystemen = function (opwekkerEntdata) {
            const koelingsinstallaties = ntaEntityData.getListWithEntityId('INSTALLATIE')
                .filter(i => i.PropertyDatas['INSTALL_TYPE'].Value === "INST_KOEL");
            koelingsinstallaties.forEach((installatie, index) => {
                installatie.Id = ntaEntityData.getFirstChild(installatie, 'KOEL').EntityDataId;
                const propdataName = installatie.PropertyDatas['INSTALL_NAAM'];
                installatie.Value = propdataName && propdataName.Value || 'koelsysteem ' + (index + 1);
            });

            /// Indien TO07 ≠ forfaitair/productspecifiek voeg aan de mogelijke keuzes de keuze: 'boosterwarmtepomp zonder koelfunctie' toe
            const propInvoer = ntabuilding.properties['TAPW-OPWEK_INV'];
            if (!['TAPW-OPWEK_INV_PROD', 'TAPW-OPWEK_INV_FORF'].includes(propInvoer.getValue(opwekkerEntdata))) {
                koelingsinstallaties.push(...ntabuilding.properties['TAPW-OPWEK_BRON_BOOS_KOEL'].Domain.Codes);
            } else {
                /// In alle andere gevallen voeg altijd de keuze 'toestel niet gebruikt voor koeling toe'
                koelingsinstallaties.push(...ntabuilding.properties['TAPW-OPWEK_WP_KOELSYS'].Domain.Codes);
            }


            return koelingsinstallaties;
        } //-- end: getKoelsystemen ------------------------------------------------------------------------//

        var getOpstelplaatsen = function (prop, type) {
            const rekenzones = ntaEntityData.findEntities(tapwaterLogic.tapwData, 'TAPW-UNIT!.^UNIT.UNIT-RZ.^RZ', 'TAPW-UNIT-RZ!.^UNIT-RZ.^RZ');

            for (const rz of rekenzones) {
                const rzName = rz.PropertyDatas['RZ_OMSCHR'].Value;
                rz.Id = rz.EntityDataId;
                rz.Value = type + ' in zone: ' + rzName;
            }
            const opstelplaatsen = ntaValidation.codedValues(prop);
            return rekenzones.concat(opstelplaatsen);
        } //-- end: getOpstelplaatsen ------------------------------------------------------------------------//

        var determineFabricagejaar = function (WKK) {
            let jaar = tapwaterLogic.bouwjaar;
            let defaultValue = '';

            if (WKK) {

                if (jaar < 2007) {
                    defaultValue = 'TAPW-OPWEK_FABR_WKK_OUD';
                }
                else if (jaar >= 2007 || jaar < 2015) {
                    defaultValue = 'TAPW-OPWEK_FABR_WKK_20072014';
                }
                else if (jaar >= 2015) {
                    defaultValue = 'TAPW-OPWEK_FABR_WKK_NIEUW';
                }

            } else {
                if (jaar < 2015) {
                    defaultValue = 'TAPW-OPWEK_FABR_V';
                }
                else if (jaar >= 2015) {
                    defaultValue = 'TAPW-OPWEK_FABR_L';
                }
            }

            return defaultValue;
        } //-- end: determineFabricagejaar ------------------------------------------------------------------------//

        var getTapwVatRelatieIds = function () {
            //-- Tapwater-voorraadvaten relaties
            return ntaEntityData.getChildIds(tapwaterLogic.tapwDataId, 'TAPW-VAT');
        } //-- end: getTapwVatRelatieIds ------------------------------------------------------------------------//

        tapwaterLogic.setTapwVaten = function () {
            tapwaterLogic.tapwVatenIds = getTapwVatRelatieIds();
            tapwaterLogic.tapwVatenData = ntaEntityData.getList(tapwaterLogic.tapwVatenIds).sort(orderElements);
        };

        //-- Voorraadvat entiteiten
        tapwaterLogic.setTapwVaten();

        tapwaterLogic.setDefaultValuesVaten = function (vat = null) {
            if (vat) {
                vat.filteredItemsBVat = [];
            } else {
                tapwaterLogic.tapwVatenData.forEach(tapwaterLogic.setDefaultValuesVaten);
            }
        };
        tapwaterLogic.setDefaultValuesVaten();

        tapwaterLogic.vatNr = function (vat) {
            return 1 + tapwaterLogic.tapwVatenData.findIndex(vatEntdata => vatEntdata.EntityDataId === vat.EntityDataId);
        } //-- end: vatNr ------------------------------------------------------------------------//

        //-- Distributie
        tapwaterLogic.tapwDistributieData = ntaEntityData.getFirstChild(tapwaterLogic.tapwDataId, 'TAPW-DISTR');

        //-- Distributie binnen
        tapwaterLogic.tapwDistributieBinnenData = ntaEntityData.getFirstChild(tapwaterLogic.tapwDistributieData, 'TAPW-DISTR-BIN');

        //-- Distributie buiten
        tapwaterLogic.tapwDistributieBuitenData = ntaEntityData.getFirstChild(tapwaterLogic.tapwDistributieData, 'TAPW-DISTR-BUI');

        //-- Distributie leidingen
        var getDistributieLeidingenEigenschappen = function () {
            tapwaterLogic.tapwDistributieEigenschappenLeidingenBinnenData = ntaEntityData.getFirstChild(tapwaterLogic.tapwDistributieBinnenData, 'TAPW-DISTR-EIG');
            tapwaterLogic.tapwDistributieEigenschappenLeidingenBuitenData = ntaEntityData.getFirstChild(tapwaterLogic.tapwDistributieBuitenData, 'TAPW-DISTR-EIG');

            const leidingen = [];

            leidingen.push(tapwaterLogic.tapwDistributieEigenschappenLeidingenBinnenData);
            leidingen.push(tapwaterLogic.tapwDistributieEigenschappenLeidingenBuitenData);

            return leidingen;
        } //-- end: getDistributieLeidingenEigenschappen ------------------------------------------------------------------------//

        tapwaterLogic.tapwDistributieEigenschappenLeidingenData = getDistributieLeidingenEigenschappen();

        tapwaterLogic.tapwDistributiePompData = ntaEntityData.getChildren(tapwaterLogic.tapwDistributieData, 'TAPW-DISTR-POMP');

        //-- Afgifte (bij tapwater is er maar 1 afgifte)
        tapwaterLogic.tapwAfgifteData = ntaEntityData.getFirstChild(tapwaterLogic.tapwDataId, 'TAPW-AFG');

        // Douchewaterwarmteterugwinning entiteiten
        tapwaterLogic.setTapwDouche = function () {
            tapwaterLogic.tapwDoucheData = ntaEntityData.getChildren(tapwaterLogic.tapwDataId, 'TAPW-DOUCHE');
        };
        tapwaterLogic.setTapwDouche();

        tapwaterLogic.dependencyValidator = ntaDependencyValidation;

        // Get units
        function getSelectedUnits() {
            const [tapwUnitEntityId, unitEntityId] = ntaSharedLogic.isUtiliteit()
                ? ['TAPW-UNIT-RZ', 'UNIT-RZ']
                : ['TAPW-UNIT', 'UNIT'];

            tapwaterLogic.tapwUnits = ntaEntityData.getChildren(tapwaterLogic.tapwData, tapwUnitEntityId).filter(ed => ed.Relevant);

            const unitIds = tapwaterLogic.tapwUnits.map(tapwUnit => ntaEntityData.getParentIds(tapwUnit, unitEntityId)[0]);
            tapwaterLogic.units = ntaEntityData.getList(unitIds);
        }
        getSelectedUnits();

        // Afgifteleiding per unit
        const _unitLeidingen = {};

        // Afgifte leidingen
        function loadLeidingenPerAppartement() {
            const afgifte = tapwaterLogic.tapwAfgifteData;

            // We moeten alle leidingen van niet-gekoppelde units ook bijhouden
            const irrelevanteLeidingen = [];

            // Haal alle leidingen op die aan deze afgifte entiteit zijn gekoppeld
            const afgifteleidingRels = ntaEntityData.getChildRelations(afgifte, 'TAPW-AFG-LEI');
            fixAfgifteleidingrelaties(afgifteleidingRels); // controleer meteen ook of de relaties goed staan
            const afgifteLeidingIds = new Set(afgifteleidingRels.map(rel => rel.Child));

            // We lopen alle units af, ook die NIET aan dit tapwatersysteem gekoppeld zijn
            for (const unit of ntaEntityData.getListWithEntityId('UNIT')) {
                // Haal alle leidingen op die aan deze unit entiteit zijn gekoppeld
                const unitLeidingRels = ntaEntityData.getChildRelations(unit, 'TAPW-AFG-LEI');
                fixAfgifteleidingrelaties(unitLeidingRels); // controleer meteen ook of de relaties goed staan
                const unitLeidingIds = unitLeidingRels.map(rel => rel.Child);

                // Zoek de leiding die in beide lijsten voorkomt
                const leidingId = unitLeidingIds.find(id => afgifteLeidingIds.has(id));
                let leiding = ntaEntityData.get(leidingId);

                // Controleer of deze unit wel gekoppeld is aan dit tapwatersysteem
                if (!tapwaterLogic.units.includes(unit)) {
                    if (leiding) {
                        irrelevanteLeidingen.push(leiding);
                    }
                } else {
                    // Maak een nieuwe leiding aan als deze nog niet bestond
                    if (!leiding && ntabuilding.canSave()) {
                        const parentRels = [
                            { "OnCopy": 1, "OnDelete": 1, "Parent": unit.EntityDataId, "ParentEntityId": unit.EntityId },
                            { "OnCopy": 1, "OnDelete": 1, "Parent": afgifte.EntityDataId, "ParentEntityId": afgifte.EntityId },
                        ];
                        const newId = ntaEntityData.create("TAPW-AFG-LEI", -1, parentRels, [], []);
                        leiding = ntaEntityData.get(newId);
                    }
                    // Koppel leiding aan unit
                    _unitLeidingen[unit.EntityDataId] = leiding;
                }
            }

            // Markeer de leidingen van niet-gekoppelde units als zijnde niet relevant
            ntaEntityData.setEntityRelevancy(irrelevanteLeidingen, false);
            ntaEntityData.setEntityVisibility(irrelevanteLeidingen, false);
            // zorg ook dat alle meldingen hierover verdwijnen
            const propdatas = irrelevanteLeidingen.flatMap(leiding => leiding.PropertyDatas);
            for (const propdata of propdatas) {
                const prop = ntabuilding.properties[propdata.PropertyId];
                ntaValidation.IsValid(tapwaterLogic.form_installationtapwater, prop, propdata, true);
            }
        }
        loadLeidingenPerAppartement();

        function fixAfgifteleidingrelaties(relations) {
            const relsToUpdate = [];
            for (const relation of relations) {
                if (!relation.OnCopy) {
                    relation.OnCopy = true; //cascade
                    relsToUpdate.push(relation);
                }
            }
            // Relaties kunnen niet ge-updated worden, dus verwijderen we ze, en maken we ze meteen ook weer aan.
            // Eerst alle verwijderingen, daarna alle toevoegingen, zodat de server ze kan groeperen in twee batches.
            for (const rel of relsToUpdate) {
                ntaEntityData.deleteRelation(rel.EntityRelationDataId);
            }
            for (const rel of relsToUpdate) {
                ntaEntityData.createRelation(rel.Parent, rel.Child, rel.OnDelete, rel.OnCopy);
            }
        }

        tapwaterLogic.getUnitLeiding = getUnitLeiding;
        function getUnitLeiding(unit) {
            return _unitLeidingen[unit.EntityDataId];
        }


        tapwaterLogic.doucheNr = function (douche) {
            return tapwaterLogic.tapwDoucheData.indexOf(douche) + 1;
        } //-- end: doucheNr ------------------------------------------------------------------------//


        // Maakt nieuw object voor een gegeven entiteit. Dit nieuwe object kan tijdelijk worden gebruikt en staat niet in de data van ntabuilding (PropertyDatas zijn nog wel dezelfde).
        var createTempEntityObject = function (entity) {
            return { PropertyDatas: entity.PropertyDatas, EntityDataId: entity.EntityDataId, EntityId: entity.EntityId };
        } //-- end: createTempEntityObject ------------------------------------------------------------------------//

        var getAangeslotenDouchesOpDoucheWTWPerAppartement = function (doucheWTW) {
            if (!doucheWTW) {
                return;
            }

            const tempUnits = [];
            doucheWTW.tempUnits = tempUnits;

            // Haal alle douches aangesloten op douche-wtw op die aan deze douche-wtw entiteit zijn gekoppeld
            const doucheWTWAansluitingIds = new Set(ntaEntityData.getChildIds(doucheWTW, 'TAPW-DOUCHE-AANG'));

            for (const unit of tapwaterLogic.units) {
                // Haal alle douches aangesloten op douche-wtw op die aan deze unit entiteit zijn gekoppeld
                const unitAansluitingIds = ntaEntityData.getChildIds(unit, 'TAPW-DOUCHE-AANG');
                // Zoek de douche aangesloten die in beide lijsten voorkomt
                const aansluitingId = unitAansluitingIds.find(id => doucheWTWAansluitingIds.has(id));
                const doucheAansluiting = ntaEntityData.get(aansluitingId);

                // Maak tempUnit
                const tempUnit = createTempEntityObject(unit);
                tempUnits.push(tempUnit);

                // Koppel douches aan unit of maak een nieuwe douche aan als deze nog niet bestond
                if (doucheAansluiting) {
                    tempUnit.douche = doucheAansluiting;
                } else if (ntabuilding.canSave()) {
                    const newId = ntaEntityData.create("TAPW-DOUCHE-AANG", -1, [
                        { "OnCopy": 1, "OnDelete": 1, "Parent": unit.EntityDataId, "ParentEntityId": unit.EntityId },
                        { "OnCopy": 1, "OnDelete": 1, "Parent": doucheWTW.EntityDataId, "ParentEntityId": "TAPW-DOUCHE" }
                    ], [], []);
                    const doucheAansluiting = ntaEntityData.get(newId);
                    tempUnit.douche = doucheAansluiting;
                    setDefaultAangeslotenDouche(doucheAansluiting, tempUnit);
                }
            }
        };

        tapwaterLogic.setDefaultDouche = function () {
            tapwaterLogic.tapwDoucheData.forEach(function (doucheWTW, index) {
                const propdataDouchesOpType = doucheWTW.PropertyDatas['TAPW-DOUCHE_AANT_TYPE'];

                if (!propdataDouchesOpType.Touched) {
                    const tapw = ntaEntityData.getFirstParent(doucheWTW, 'TAPW');
                    const andereDoucheWTWs = ntaEntityData.getChildren(tapw, 'TAPW-DOUCHE').filter(ed => ed !== doucheWTW);
                    const aantalAangesloten = andereDoucheWTWs.reduce(function (som, andereDWTW) {
                        const aantal = parseInt(andereDWTW.PropertyDatas['TAPW-DOUCHE_AANT_TYPE'].Value) || 0;
                        return som + aantal;
                    }, 0);
                    propdataDouchesOpType.Value = getTotaalAantalDouches() - aantalAangesloten;
                }

                doucheWTW.filteredItemsDWTW = [];

                getAangeslotenDouchesOpDoucheWTWPerAppartement(doucheWTW);
            });
        } //-- end: setDefaultDouche ------------------------------------------------------------------------//


        var setDefaultAangeslotenDouche = function (douche, tempUnit) {
            const propdataAangeslotenDouche = douche.PropertyDatas['TAPW-DOUCHE-AANG_AANT'];
            if (!propdataAangeslotenDouche.Touched) {
                let aantal = 1;
                if (!ntaSharedLogic.isUtiliteit()) {
                    const unit = tapwaterLogic.units.find(function (x) { return x.EntityDataId === tempUnit.EntityDataId; });
                    const tapwUnit = tapwaterLogic.tapwUnits.find(function (x) { return ntaSharedLogic.hasRelation(unit, x); });

                    const aantalBadruimten = Math.max(0, parseInt(tapwUnit.PropertyDatas['TAPW-UNIT_BADRUIMTEN'].Value) || 0); // badr

                    // Nu moeten we, voor de unit gekoppeld aan dit tapwatersysteem, achterhalen hoeveel douches er al toegekend zijn aan andere doucheWTWs.
                    const doucheWTW = ntaEntityData.getFirstParent(douche, 'TAPW-DOUCHE');
                    const tapw = ntaEntityData.getFirstParent(tapwUnit, 'TAPW');

                    const andereDoucheWTWs = ntaEntityData.getChildren(tapw, 'TAPW-DOUCHE').filter(ed => ed !== doucheWTW);
                    const aansluitingenOpUnit = andereDoucheWTWs
                        .flatMap(andereDWTW => ntaEntityData.getChildren(andereDWTW, 'TAPW-DOUCHE-AANG'))
                        .filter(aangesloten => ntaSharedLogic.hasRelation(unit, aangesloten));
                    const aantalAangeslotenOpUnit = aansluitingenOpUnit
                        .reduce((som, aangesloten) => som + (parseInt(aangesloten.PropertyDatas['TAPW-DOUCHE-AANG_AANT'].Value) || 0), 0);

                    aantal = Math.max(0, aantalBadruimten - aantalAangeslotenOpUnit);
                }
                propdataAangeslotenDouche.Value = aantal.toString();
            }
        };

        tapwaterLogic.setDefaultDouche();

        tapwaterLogic.setDescriptionInstallation = function (opwekker) {
            //-- ik moet eerst weten of dit de eerste opwekker is.
            if (tapwaterLogic.opwekkerNr(opwekker) === 1) {
                const propdataInstallDescr = ntaEntityData.get(tapwaterLogic.installId).PropertyDatas['INSTALL_OMSCHR'];
                if (propdataInstallDescr) {
                    let descr = "";
                    const propType = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_TYPE'];
                    const codeType = propType.getCode(opwekker);
                    if (codeType) {
                        const propToestel = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_TOES'];
                        const toestelProductId = parseInt(propToestel.getValue(opwekker));
                        const product = toestelProductId && tapwaterLogic.selectionTable.find(p => p.Id === toestelProductId);
                        if (tapwaterLogic.isHidden(propToestel, opwekker) || !product) {
                            //-- beschrijving is type opwekker
                            descr = propType.getCode(opwekker).Value;
                        } else {
                            //-- beschrijving is de keuze uit selectietabel
                            descr = product.Value;
                        }
                    }
                    ntaEntityData.saveprop(propdataInstallDescr, descr);
                }
            }
        } //-- end: setDescriptionInstallation ------------------------------------------------------------------------//


        var CheckPropdataInList = function (prop, entdata, list) {
            //-- ik gebruik hier de getPropData, omdat ik de juiste propdata.Value van de relaties met externe systemen wil hebben (VERW, KOEL, VENT)
            const propdata = tapwaterLogic.getPropData(prop, entdata);
            let listItem = null;
            let valueChanged = false;
            if (propdata && list) {
                if (list.length === 0) {
                    //-- bij 0 items in de lijst moet de propdata.value op op default gezet worden gezet worden
                    if (propdata.Value !== prop.DefaultValue) {
                        propdata.Value = prop.DefaultValue;
                        valueChanged = true;
                    }
                } else if (list.length === 1 && (!prop.Domain || prop.Domain && prop.Domain.DomainType !== 4 && prop.Domain.DomainType !== 5)) {
                    //-- bij 1 item in de lijst moet de propdata.value gelijk worden aan de waarde uit de lijst
                    if (propdata.Value !== list[0].Id) {
                        propdata.Value = list[0].Id;
                        valueChanged = true;
                    }
                } else if (list.length === 1 && prop.Domain && (prop.Domain.DomainType === 4 || prop.Domain.DomainType === 5) && list.Singleresult) {
                    //-- 1 list item voor selectietabel
                    let sameValue = propdata.Value === list[0].Id.toString() || propdata.Value === list[0].Id; //-- geen idee waarom Value soms een int is en soms een string. komen we wel achter bij refactoren.
                    if (!sameValue) {
                        propdata.Value = list[0].Id.toString();
                        valueChanged = true;
                    }
                }

                if (propdata.Value && list.length > 1) {
                    //-- als propdata.Value ongelijk is aan null moet ik kijken of het item in de lijst zit
                    listItem = _getListItem(prop, entdata, list);
                    if (!listItem && propdata.Value !== prop.DefaultValue) {
                        propdata.Value = prop.DefaultValue;
                        valueChanged = true;
                    } else {
                        //-- als ik prop domaintype 4 of 5 ben moet autocompletslecteditems atlijd zetten
                        if (prop.Domain && (prop.Domain.DomainType === 4 || prop.Domain.DomainType === 5)) {
                            _setAutocompleteSelectedItem(prop, entdata, listItem);
                        }
                    }
                }
            }
            //-- en nu de selectedItems zetten voor de property als het domaintype 4 of 5 is. Voor de md-autocomplete moet dit
            if (valueChanged) {
                _setAutocompleteSelectedItem(prop, entdata, listItem);
            }
            tapwaterLogic.validate(prop, propdata);
            if (valueChanged) {
                ntaEntityData.saveprop(propdata);
            }
        } //-- end: CheckPropdataInList ------------------------------------------------------------------------//

        var _getListItem = function (prop, entdata, list = null) {
            if (typeof prop === 'string') prop = ntabuilding.properties[prop];

            if (prop && prop.Domain && prop.Domain.DomainType > 1 && entdata) {
                let tmpList = null;
                let tmpId;
                const propData = tapwaterLogic.getPropData(prop, entdata);
                switch (prop.Id) {
                    case 'TAPW-OPWEK_TOES':
                        tmpId = parseInt(propData.Value);
                        tmpList = entdata.filteredItems;
                        break;
                    case 'TAPW-OPWEK_REGIO':
                        tmpId = propData.Value; //-- geen parseInt want Value is een txt en geen productnummer
                        tmpList = entdata.filteredItemsRegios;
                        break;
                    case 'TAPW-OPWEK_BRON_POMP':
                        tmpId = propData.Value; //-- geen parseInt want Value is een txt en geen productnummer
                        tmpList = entdata.filteredItemsBron;
                        break;
                    case 'TAPW-OPWEK_OPSTEL':
                        tmpId = propData.Value;
                        tmpList = getOpstelplaatsen(prop, "gasboiler");
                        break;
                    case 'TAPW-VAT_TYPE':
                        tmpId = parseInt(propData.Value);
                        tmpList = entdata.filteredItemsBVat;
                        break;
                    case 'TAPW-VAT_OPSTEL':
                        tmpId = propData.Value;
                        tmpList = getOpstelplaatsen(prop, "voorraadvat(en)");
                        break;
                    case 'TAPW-DOUCHE_WTW_TOES':
                        tmpId = parseInt(propData.Value);
                        tmpList = entdata.filteredItemsDWTW;
                        break;
                    case 'TAPW-OPWEK_BRON_BOOS':
                        tmpList = _verwarmingSystemen;
                        tmpId = getRelatedSystemId(propData, tmpList, 'VERW');
                        break;
                    case 'TAPW-OPWEK_BRON_BOOS_KOEL':
                        tmpList = getKoelsystemen(entdata);
                        tmpId = getRelatedSystemId(propData, tmpList, 'KOEL');
                        break;
                    case 'TAPW-OPWEK_WP_VENTSYS':
                        tmpList = _ventilatieSystemen;
                        tmpId = getRelatedSystemId(propData, tmpList, 'VENT');
                        break;
                    case 'TAPW-OPWEK_WARMTE_UIT_VERW':
                    case 'TAPW-OPWEK_VERW-OPWEK':
                        tmpList = _verwarmingSystemen;
                        tmpId = getRelatedSystemId(propData, tmpList, 'VERW');
                        break;
                    default:
                        tmpId = propData.Value;
                        tmpList = list; // de gefilterde getCodedValue list. Op deze list moet ik checken
                        if (!tmpList) {
                            //-- anders ongefilterde codedValue list gebruiken
                            tmpList = prop.Domain.Codes;
                        }
                        break;
                }

                let listValue = null;
                if (propData.Value && tmpList && tmpList.length > 0) {
                    listValue = tmpList.find(function (x) { return x.Id === tmpId; });
                }
                return listValue;
            } else {
                return null;
            }

        } //-- end: _getListItem ------------------------------------------------------------------------//

        function getRelatedSystemId(propData, listItems, systemType) {
            // De entiteit van deze propData heeft een relatie met een systeem van type systemType; we moeten daarvan de EntityDataId teruggeven.
            if (Array.isArray(listItems)) {
                // Wel eerst controleren of het betreffende systeem wel voorkomt in deze lijst.
                const child = ntaEntityData.getFirstChild(propData, systemType);
                if (child && listItems.find(item => item.Id === child.EntityDataId)) {
                    propData.Value = child.EntityDataId;
                }
            }
            return propData.Value;
        }


        var _setAutocompleteSelectedItem = function (prop, entdata, selectedItem = null) {
            // dit moet alleen voor velden van het domain type 4 of 5.
            if (prop.Domain.DomainType === 4 || prop.Domain.DomainType === 5) {
                if (!selectedItem) {
                    selectedItem = _getListItem(prop, entdata);
                }

                switch (prop.Id) {
                    case 'TAPW-OPWEK_TOES':
                        if (selectedItem) {
                            entdata.selectedItem = selectedItem;
                            entdata.searchText = selectedItem.Value;
                        } else {
                            entdata.selectedItem = null;
                            entdata.searchText = "";
                        }
                        break;
                    case 'TAPW-OPWEK_REGIO':
                        if (selectedItem) {
                            entdata.selectedItemRegio = selectedItem;
                            entdata.searchTextRegio = selectedItem.Value;
                        } else {
                            entdata.selectedItemRegio = null;
                            entdata.searchTextRegio = "";
                        }
                        break;
                    case 'TAPW-OPWEK_BRON_POMP':
                        if (selectedItem) {
                            entdata.selectedItemBron = selectedItem;
                            entdata.searchTextBron = selectedItem.Value;
                        } else {
                            entdata.selectedItemBron = null;
                            entdata.searchTextBron = "";
                        }
                        break;
                    case 'TAPW-VAT_TYPE':
                        if (selectedItem) {
                            entdata.selectedItem = selectedItem;
                            entdata.searchText = selectedItem.Value;
                        } else {
                            entdata.selectedItem = null;
                            entdata.searchText = "";
                        }
                        break;
                    case 'TAPW-DOUCHE_WTW_TOES':
                        if (selectedItem) {
                            entdata.selectedItem = selectedItem;
                            entdata.searchText = selectedItem.Value;
                        } else {
                            entdata.selectedItem = null;
                            entdata.searchText = "";
                        }
                        break;
                    default:
                        break;
                }
            }
        } //-- end: _setAutocompleteSelectedItem ------------------------------------------------------------------------//

        ///////////////////////////////SELECTIONTABLE//////////////////////////////////////////////////////////////////////////////////////////////
        var initSelectionTables = function () {
            tapwaterLogic.getSelectionTableServer('W');
            tapwaterLogic.getSelectionTableServer('BVAT');
            tapwaterLogic.getSelectionTableServer('DWTW');
        } //-- end: initSelectionTable ------------------------------------------------------------------------//

        tapwaterLogic.getSelectionTable = function (prop, entdata, searchText = "") {
            if (tapwaterLogic.selectionTable.length > 0) {
                var propInv = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_INV'];
                var propBrn = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_BRON_POMP'];
                var propReg = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_REGIO'];

                var propdata = entdata.PropertyDatas[prop.Id];
                var propdataInvoer = entdata.PropertyDatas[propInv.Id];
                var res = [];
                if (prop.Domain.DomainType === 4) { //-- selection table value

                    res = tapwaterLogic.selectionTable;

                    var propPos = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_POS'];
                    var propIndirVat = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_INDIR'];
                    var propBijlageR = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_EMISSIE'];
                    var propFabjrWKK = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_FABR_WKK'];
                    var propFabjr = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_FABR'];
                    var propCWklasse = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_CW'];

                    var propdataType = entdata.PropertyDatas['TAPW-OPWEK_TYPE'];
                    var propdataFunctie = entdata.PropertyDatas['TAPW-OPWEK_FUNC'];
                    var propdataPositie = entdata.PropertyDatas[propPos.Id];
                    var propdataBron = entdata.PropertyDatas[propBrn.Id];
                    var propdataRegio = entdata.PropertyDatas[propReg.Id];
                    var propdataIndirVat = entdata.PropertyDatas[propIndirVat.Id];
                    var propdataBijlageR = entdata.PropertyDatas[propBijlageR.Id];
                    var propdataFabricagejaar = entdata.PropertyDatas[propFabjr.Id];
                    var propdataFabricagejaarWKK = entdata.PropertyDatas[propFabjrWKK.Id];
                    var propdataCWKlasse = entdata.PropertyDatas[propCWklasse.Id];

                    //-- filter op opwekker
                    let opwekType = tapwaterLogic.opwekkerTypes.find(function (x) { return x.Id === propdataType.Value; });
                    if (opwekType) {
                        res = res.filter(function (x) { return x.Opwekker === opwekType.Value; });
                    }
                    //-- filter op product voor alleen tapwater (solo)
                    if (propdataFunctie.Value && propdataFunctie.Value === 'TAPW-OPWEK_FUNC_SOLO') {
                        res = res.filter(function (x) { return x.Solo; });
                    }
                    // Filter op product voor verwarming en tapwater
                    else if (propdataFunctie.Value === 'TAPW-OPWEK_FUNC_COMBI') {
                        res = res.filter(function (x) { return !x.Solo; });
                    }

                    //-- nu moet ik weten of ik forfaitair ben of niet.
                    switch (propdataInvoer.Value) {
                        case "TAPW-OPWEK_INV_PROD":
                            res = res.filter(function (x) { return x.Certificate === 1; });
                            break;
                        case "TAPW-OPWEK_INV_FORF":
                            res = res.filter(function (x) { return x.Certificate === 0; });
                            break;
                        default:
                            break;
                    }

                    // Als bij Settings is aangevinkt dat alleen de actuele verklaringen gekozen mogen,
                    // moeten de verlopen verklaringen er uit gefilterd worden.
                    if (ntaSharedLogic.showOnlyValidProducts()) {
                        res = res.filter(r => r.ExpiryDate == null || Date.parse(r.ExpiryDate) > Date.now());
                    }

                    //-- filter of product wel of geen indirecte voorraadvaten heeft
                    if (!tapwaterLogic.isHidden(propIndirVat, entdata) && opwekType && opwekType.key1 === 'CVG') {
                        if (propdataIndirVat.Value && propdataIndirVat.Value === 'TAPW-OPWEK_INDIR_WEL') {
                            res = res.filter(function (x) { return x.Indirect; });
                        } else {
                            res = res.filter(function (x) { return !x.Indirect; });
                        }
                    }
                    //-- filter of product wel of geen indirecte voorraadvaten heeft
                    if (!tapwaterLogic.isHidden(propBijlageR, entdata)) {
                        if (propdataBijlageR.Value && (propdataBijlageR.Value === 'TAPW-OPWEK_EMISSIE_R' || propdataBijlageR.Value === 'TAPW-OPWEK_EMISSIE_E')) {
                            res = res.filter(function (x) { return x.BijlageR; });
                        } else {
                            res = res.filter(function (x) { return !x.BijlageR; });
                        }
                    }
                    //-- filter of product specifiek is voor binnen thermische schil, buiten thermische schil of beide.
                    if (!tapwaterLogic.isHidden(propPos, entdata) && propdataPositie.Value && opwekType && opwekType.key1 !== 'GBK') {
                        var sValuePos = propPos.Domain.Codes.find(function (x) { return x.Id === propdataPositie.Value; }).Value;
                        res = res.filter(function (x) { return x.Positie === sValuePos || x.Positie === null; });
                    }
                    //-- filter warmtepompen op basis van de gekozen bron.
                    if (!tapwaterLogic.isHidden(propBrn, entdata) && propdataBron.Value) {
                        let bronCodedValues = tapwaterLogic.tapwOpwekkerProperties["TAPW-OPWEK_BRON_POMP"].Domain.Codes;
                        let bronTxtValue = '';
                        if (bronCodedValues.find(function (x) { return x.Id === propdataBron.Value; })) {
                            bronTxtValue = bronCodedValues.find(function (x) { return x.Id === propdataBron.Value; }).Value;
                        }
                        res = res.filter(function (x) { return x.Bron === bronTxtValue.trim() || x.Bron === null; });
                    }
                    //-- filter voor welke regio het product geldt.
                    if (!tapwaterLogic.isHidden(propReg, entdata)) {
                        res = res.filter(function (x) { return x.Regio === propdataRegio.Value; });
                    }
                    //-- filter op fabricage jaar
                    if (opwekType && opwekType.key1 === 'WKK') {
                        const code = propFabjrWKK.Domain.Codes.find(codedValue => codedValue.Id === propdataFabricagejaarWKK.Value);
                        if (code) {
                            res = res.filter(x => x.Fabricagejaar === code.Value);
                        }
                    }

                    tapwaterLogic.isHidden(propFabjr, entdata); // zorgen dat Relevant de juiste waarde heeft.
                    if (propdataFabricagejaar.Relevant) {
                        const code = propFabjr.Domain.Codes.find(codedValue => codedValue.Id === propdataFabricagejaar.Value);
                        if (code) {
                            res = res.filter(function (x) { return x.Fabricagejaar === code.Value || x.Fabricagejaar === null; });
                        }
                    }

                    if (!tapwaterLogic.isHidden(propCWklasse, entdata)) {
                        let sCW = propdataCWKlasse.Value;
                        if (sCW === 'TAPW-OPWEK_CW_5') { sCW = 'TAPW-OPWEK_CW_4'; }
                        res = res.filter(function (x) { return x.CW === sCW; });
                    }

                    if (opwekType && opwekType.Id === "TAPW-OPWEK_TYPE_17" && res.length === 5) {
                        //-- ik ben nu wkk. Hier ga ik de volgorden van de producten even handmatig aanpassen. Dit gebeurd automatisch op alfabetische volgorde, maar dan
                        //-- staat 1000 kW boven 2 kW.Dat is niet wenselijk. Ik plaats dus de eerste naar de laatste.
                        const firstelement = res.shift();
                        res.push(firstelement);
                    }

                    const propGemeenschappelijk = ntabuilding.properties['TAPW-OPWEK_GEM']; // TO09
                    const isGemeenschappelijk = propGemeenschappelijk.getValue(tapwaterLogic.tapwOpwekkersData[0]) === 'TAPW-OPWEK_GEM_WEL'
                        && !tapwaterLogic.isHidden(propGemeenschappelijk, tapwaterLogic.tapwOpwekkersData[0]);

                    if (ntabuilding.ntaVersionId >= 200) { // Uniec 3.2 en hoger
                        //-- Als ge3.2 EN TO07 = forfaitair EN TO09 = gemeenschappelijke installatie DAN verberg alle ‘warmtepomp - voldoet aan tabel 9.28’
                        const isInvoerForfaitair = entdata.PropertyDatas['TAPW-OPWEK_INV'].Value === 'TAPW-OPWEK_INV_FORF';
                        if (isInvoerForfaitair && isGemeenschappelijk) {
                            res = res.filter(x => x.Value !== 'warmtepomp - voldoet aan tabel 9.28');
                        }
                    }

                    //-- Filter op kolom W_gemeenschappelijk adhv TO09. Indien gem. installatie toon rijen met 'gem. installatie' en leeg; indien niet-gem. installatie toon rijen met 'niet-gem. installatie' en leeg.
                    res = res.filter(x => x.IsGemeenschappelijk === null || x.IsGemeenschappelijk === isGemeenschappelijk);

                    //-- zelfs nu kunnen er nog dubbele omschrijvingen in zitten omdat er voor verwarming wel onderscheid is, maar voor tapwater niet. Hier dus de dubbele namen uithalen
                    res = ntaEntityData.distinct(res, x => x.Value);

                    if (res.length === 1) {
                        res.Singleresult = true;
                    }

                    if (res.length > 1 && searchText && searchText.length > 0) {
                        res = res.filter(function (x) { return x.Value && x.Value.toLowerCase().indexOf(searchText.toLowerCase()) !== -1; });
                    }


                } else if (prop.Domain.DomainType === 5) { //-- identiek namen uit de selectietabel voor een bepaalde filter kolom.
                    if (prop.Id === propReg.Id) {
                        res = tapwaterLogic.selectionTableRegios;
                    } else if (prop.Id === propBrn.Id) {
                        res = tapwaterLogic.selectionTableBron;
                    }

                    //-- filter op opwekker
                    let opwekkerTypeValue = entdata.PropertyDatas['TAPW-OPWEK_TYPE'].Value;
                    let opwekType = tapwaterLogic.opwekkerTypes.find(function (x) { return x.Id === opwekkerTypeValue; });
                    if (opwekType) {
                        res = res.filter(function (x) { return x.Opwekker === opwekType.Value; });
                    }

                    //-- filter op forfaitair of product specief
                    switch (propdataInvoer.Value) {
                        case "TAPW-OPWEK_INV_PROD":
                            res = res.filter(function (x) { return x.Certificate === 1; });
                            break;
                        case "TAPW-OPWEK_INV_FORF":
                            res = res.filter(function (x) { return x.Certificate === 0; });
                            break;
                        default: {
                            //-- res ontdubbelen
                            let uniqueres = [];
                            res.forEach(function (item) {
                                var i = uniqueres.findIndex(x => x.Id === item.Id);
                                if (i <= -1) {
                                    uniqueres.push({
                                        Id: item.Id,
                                        Value: item.Value,
                                        Certificate: item.Certificate,
                                        Opwekker: item.Opwekker,
                                        IsValid: item.IsValid,
                                    });
                                }
                            });
                            res = uniqueres;
                            break;
                        }
                    }

                    // Als bij Settings is aangevinkt dat alleen de actuele verklaringen gekozen mogen,
                    // moeten de verlopen verklaringen er uit gefilterd worden.
                    if (ntaSharedLogic.showOnlyValidProducts()) {
                        res = res.filter(r => r.ExpiryDate == null || Date.parse(r.ExpiryDate) > Date.now());
                    }

                    if (tapwaterLogic.opwekkerNr(entdata) > 1) {
                        res = res.filter(function (x) { return x.Id !== 'ventilatieretourlucht'; });
                    }

                    if (res.length === 1) {
                        res.Singleresult = true;
                    }

                    if (res.length > 1 && searchText && searchText.length > 0) {
                        res = res.filter(function (x) { return x.Value && x.Value.toLowerCase().indexOf(searchText.toLowerCase()) !== -1; });
                    }
                } //-- end domainType = 5

                switch (prop.Id) {
                    case 'TAPW-OPWEK_TOES':
                        entdata.filteredItems = res;
                        break;
                    case 'TAPW-OPWEK_BRON_POMP':
                        entdata.filteredItemsBron = res;
                        break;
                    case 'TAPW-OPWEK_REGIO':
                        entdata.filteredItemsRegios = res;
                        break;
                    default:
                        break;
                }

                CheckPropdataInList(prop, entdata, res);
                ValidateSelectionTable(res, propdata); //-- conditie [TOAX]
                return res;
            }
        } //-- end: getSelectionTable ------------------------------------------------------------------------//

        tapwaterLogic.getSelectionTableBVat = function (prop, entdata, searchText = "") {
            if (tapwaterLogic.selectionTableBVat.length > 0) {
                if (prop.Domain.DomainType === 4) { //selection table value
                    var res = tapwaterLogic.selectionTableBVat;

                    // Als bij Settings is aangevinkt dat alleen de actuele verklaringen gekozen mogen,
                    // moeten de verlopen verklaringen er uit gefilterd worden.
                    if (ntaSharedLogic.showOnlyValidProducts()) {
                        res = res.filter(r => r.ExpiryDate == null || Date.parse(r.ExpiryDate) > Date.now());
                    }

                    if (res.length === 1) {
                        res.Singleresult = true;
                    }
                    if (res.length > 1 && searchText && searchText.length > 0) {
                        res = res.filter(function (x) { return x.Value && x.Value.toLowerCase().indexOf(searchText.toLowerCase()) !== -1; });
                    }

                    entdata.filteredItemsBVat = res;

                    var propdata = entdata.PropertyDatas[prop.Id];
                    CheckPropdataInList(prop, entdata, res);
                    ValidateSelectionTable(res, propdata); //-- conditie [TOAX]
                    return res;
                }
            }
        } //-- end: getSelectionTableBVat ------------------------------------------------------------------------//

        tapwaterLogic.getSelectionTableDWTW = function (prop, entdata, searchText = "") {
            if (tapwaterLogic.selectionTableDWTW.length > 0) {
                if (prop.Domain.DomainType === 4) { //selection table value
                    let res = tapwaterLogic.selectionTableDWTW;

                    let propInvoer = tapwaterLogic.tapwDouchewarmteterugwinningProperties['TAPW-DOUCHE_INV'];
                    let propdataInvoer = entdata.PropertyDatas[propInvoer.Id];


                    if (propdataInvoer && propdataInvoer.Value) {
                        //-- filter op forfaitair of product specief
                        if (propdataInvoer.Value === 'TAPW-DOUCHE_FORF') {
                            res = res.filter(function (x) { return x.Certificate === 0; });
                        } else if (propdataInvoer.Value === 'TAPW-DOUCHE_INV_PROD') {
                            let propType = tapwaterLogic.tapwDouchewarmteterugwinningProperties['TAPW-DOUCHE_TYPE'];
                            let propdataType = entdata.PropertyDatas[propType.Id];
                            if (propdataType && propdataType.Value && propdataType.Value !== 'TAPW-DOUCHE_TYPE_ONB') {
                                var sValueType = propType.Domain.Codes.find(function (x) { return x.Id === propdataType.Value; }).Value;
                                res = res.filter(function (x) { return x.Type === sValueType; });
                            } else {
                                res = res.filter(function (x) { return x.Type !== null; });
                                res = res.filter(function (x) { return x.Certificate === 1; });
                            }
                        }
                    }

                    // Als bij Settings is aangevinkt dat alleen de actuele verklaringen gekozen mogen,
                    // moeten de verlopen verklaringen er uit gefilterd worden.
                    if (ntaSharedLogic.showOnlyValidProducts()) {
                        res = res.filter(r => r.ExpiryDate == null || Date.parse(r.ExpiryDate) > Date.now());
                    }

                    if (res.length === 1) {
                        res.Singleresult = true;
                    }

                    if (res.length > 1 && searchText && searchText.length > 0) {
                        res = res.filter(function (x) { return x.Value && x.Value.toLowerCase().indexOf(searchText.toLowerCase()) !== -1; });
                    }
                    entdata.filteredItemsDWTW = res;

                    let propdata = entdata.PropertyDatas[prop.Id];
                    CheckPropdataInList(prop, entdata, res);
                    ValidateSelectionTable(res, propdata); //-- conditie [TOAX]

                    return res;
                }
            }
        } //-- end: getSelectionTableDWTW ------------------------------------------------------------------------//

        tapwaterLogic.getSelectionTableServer = function (modelType) {
            const products = ntaSelectionTable.getProducts(modelType, ntaSharedLogic.isUtiliteit());

            if (modelType === 'W') {
                tapwaterLogic.selectionTableRegios = products.filter(function (x) { return x.Regio !== null; });
                let uniqueList = [];
                tapwaterLogic.selectionTableRegios.forEach(function (regio, index) {
                    let unique = true;
                    uniqueList.forEach(function (uniqueRegio, index2) {
                        if (regio.Regio === uniqueRegio.Value) {
                            unique = false;
                        }
                    });
                    if (unique) {
                        var newRegio = {
                            Id: regio.Regio,
                            Value: regio.Regio,
                            Certificate: regio.Certificate,
                            Opwekker: regio.Opwekker,
                            IsValid: true, //-- regio is altijd valid
                        };
                        uniqueList.push(newRegio);
                    }
                });
                tapwaterLogic.selectionTableRegios = uniqueList.sort((a, b) => a.Value > b.Value ? 1 : -1);

                tapwaterLogic.selectionTableBron = products.filter(function (x) { return x.Bron !== null; });
                let bronCodedValues = tapwaterLogic.tapwOpwekkerProperties["TAPW-OPWEK_BRON_POMP"].Domain.Codes;

                uniqueList = [];
                tapwaterLogic.selectionTableBron.forEach(function (bron, index) {
                    let unique = true;
                    uniqueList.forEach(function (uniqueBron, index2) {
                        if (bron.Bron === uniqueBron.Value && bron.Opwekker === uniqueBron.Opwekker && bron.Certificate === uniqueBron.Certificate) {
                            unique = false;
                        }
                    });
                    if (unique) {
                        let id = bron.Bron;
                        if (bronCodedValues.find(function (x) { return x.Value === bron.Bron; })) {
                            id = bronCodedValues.find(function (x) { return x.Value === bron.Bron; }).Id;
                        }
                        var newBron = {
                            Id: id,
                            Value: bron.Bron,
                            Certificate: bron.Certificate,
                            Opwekker: bron.Opwekker,
                            IsValid: true, // bron is ook altijd valid
                        };
                        uniqueList.push(newBron);
                    }
                });
                tapwaterLogic.selectionTableBron = uniqueList.sort((a, b) => a.Value > b.Value ? 1 : -1);

                tapwaterLogic.selectionTable = products.sort(
                    function (a, b) {
                        if (a.Value === b.Value) { //als Value gelijk is dan sorteren ip Id
                            return a.Id > b.Id ? 1 : -1;
                        }
                        return a.Value > b.Value ? 1 : -1;
                    }); //ook sorteren op Id ivm volgordelijkheid na distinct;

                //-- nu van alle opwekkers de selectedItem en de searchText zetten voor product en regio
                tapwaterLogic.tapwOpwekkersData.forEach(function (opwekker, index) {
                    var propProduct = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_TOES'];
                    var propRegio = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_REGIO'];
                    var propBron = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_BRON_POMP'];
                    tapwaterLogic.getSelectionTable(propProduct, opwekker);
                    tapwaterLogic.getSelectionTable(propRegio, opwekker);
                    tapwaterLogic.getSelectionTable(propBron, opwekker);
                    _setAutocompleteSelectedItem(propProduct, opwekker);
                    _setAutocompleteSelectedItem(propRegio, opwekker);
                    _setAutocompleteSelectedItem(propBron, opwekker);
                    _setSelectionTableValues(opwekker, 'W');
                });
            }
            if (modelType === 'BVAT') {
                tapwaterLogic.selectionTableBVat = products;
                tapwaterLogic.tapwVatenData.forEach(function (vat, index) {
                    var propProduct = tapwaterLogic.tapwVoorraadvatProperties['TAPW-VAT_TYPE'];
                    tapwaterLogic.getSelectionTableBVat(propProduct, vat);
                    _setAutocompleteSelectedItem(propProduct, vat);
                    _setSelectionTableValues(vat, 'BVAT');
                });
            }
            if (modelType === 'DWTW') {
                tapwaterLogic.selectionTableDWTW = products;
                tapwaterLogic.tapwDoucheData.forEach(function (dwtw, index) {
                    var propProduct = tapwaterLogic.tapwDouchewarmteterugwinningProperties['TAPW-DOUCHE_WTW_TOES'];
                    tapwaterLogic.getSelectionTableDWTW(propProduct, dwtw);
                    _setAutocompleteSelectedItem(propProduct, dwtw);
                    _setSelectionTableValues(dwtw, 'DWTW');
                });
            }
        } //-- end: getSelectionTableServer ------------------------------------------------------------------------//

        function ValidateSelectionTable (res, propdata) {
            //-- conditie [TOAX]
            if (propdata && res) {
                const selectionTableValid = res.length !== 0 || !propdata.Visible || !propdata.Relevant;
                ntaSharedLogic.setMelding("[E042]", propdata, tapwaterLogic.form_installationtapwater, selectionTableValid);
            }
        } //-- end: ValidateSelectionTable --------------------------------------------------------

        var _setSelectionTableValues = function (entdata, modelType) {
            //-- product is een product uit de selectie tabel
            if (!entdata) {
                return;
            }

            switch (modelType) {
                case 'W':
                    {
                        const productWTOES = _getListItem('TAPW-OPWEK_TOES', entdata);

                        ntaSharedLogic.saveProductValueToPropData(tapwaterLogic, productWTOES, 'P', entdata, 'TAPW-OPWEK_VERM_NON');
                        ntaSharedLogic.saveProductValueToPropData(tapwaterLogic, productWTOES, 'fPprendw', entdata, 'TAPW-OPWEK_FPREN_NON');
                        ntaSharedLogic.saveProductValueToPropData(tapwaterLogic, productWTOES, 'KCO2deldw', entdata, 'TAPW-OPWEK_KCO_NON');
                        ntaSharedLogic.saveProductValueToPropData(tapwaterLogic, productWTOES, 'Value', entdata, 'TAPW-OPWEK_TOES_NAAM'); // veld voor informatie of toetsel een keukengeiser is of niet. Is nodig voor TAPW-OPWEK_VERBR -> openVerbrandingstoestelInput.type
                        if (ntabuilding.properties['TAPW-OPWEK_LVOLSTR_NON']) { // vanaf DbVersion 40
                            ntaSharedLogic.saveProductValueToPropData(tapwaterLogic, productWTOES, 'qvehpmin', entdata, 'TAPW-OPWEK_LVOLSTR_NON');
                        }

                        // Moet onder bepaalde condities uit de selectietabel komen
                        const typeOpwekkerValue = entdata.PropertyDatas['TAPW-OPWEK_TYPE'].Value;
                        const typeOpwekker = tapwaterLogic.opwekkerTypes.find(function (x) { return x.Id === typeOpwekkerValue; });

                        // Bij sommige checks is forf, prod of eigw relevant
                        const opwekkerInvoerValue = entdata.PropertyDatas['TAPW-OPWEK_INV'].Value;

                        if (productWTOES && typeOpwekker && typeOpwekker.key1 === 'EW') { // Primaire energiefractie alleen als opwekkertype EW
                            ntaSharedLogic.saveProductValueToPropData(tapwaterLogic, productWTOES, 'fPdelDw', entdata, 'TAPW-OPWEK_PRIM_NON');

                            //-- type verklaring alleen updaten bij product of forfaitair. Bij eigen keuzen moet de gebruiker dit zetten
                            let verkl7125 = '';
                            if (opwekkerInvoerValue === 'TAPW-OPWEK_INV_PROD' || opwekkerInvoerValue === 'TAPW-OPWEK_INV_FORF') {
                                if (productWTOES.NEN7125Verklaring === 'gemeten') {
                                    verkl7125 = 'NEN7125VERKL_ALLGEMETEN';
                                }
                                else if (productWTOES.NEN7125Verklaring === 'berekend') {
                                    verkl7125 = 'NEN7125VERKL_BEREKEND';
                                }
                                const propNEN7125Verkl = ntabuilding.properties['TAPW-OPWEK_NEN7125VERKL'];
                                tapwaterLogic.saveValue(propNEN7125Verkl, entdata, verkl7125);
                            }
                        }
                        if (typeOpwekker && typeOpwekker.key1 === 'WKK') {
                            const productH = productWTOES && ntaSelectionTable.getProducts('H', ntaSharedLogic.isUtiliteit()).find(p => p.Id === productWTOES.Id);

                            ntaSharedLogic.saveProductValueToPropData(tapwaterLogic, productH, 'WKK_E', entdata, 'TAPW-OPWEK_OMZET_NON');
                        }

                        break;
                    }
                case 'BVAT':
                    {
                        const productBVAT = _getListItem('TAPW-VAT_TYPE', entdata);

                        ntaSharedLogic.saveProductValueToPropData(tapwaterLogic, productBVAT, 'Vsto', entdata, 'TAPW-VAT_VOL_NON');
                        ntaSharedLogic.saveProductValueToPropData(tapwaterLogic, productBVAT, 'Hsto_ls', entdata, 'TAPW-VAT_TRANS_NON');
                        break;
                    }
                case 'DWTW':
                    {
                        const productDWTW = _getListItem('TAPW-DOUCHE_WTW_TOES', entdata);

                        //--TODO rendement is de waarde uit cw1, cw2, cw3 of cw4, afhankelijk van hoogste waarde van TO12 van de opwekkers. Voor de quick ff standaard rendement bij CW4 laten zien.
                        ntaSharedLogic.saveProductValueToPropData(tapwaterLogic, productDWTW, 'Cw4', entdata, 'TAPW-DOUCHE_REND_NON');
                        break;
                    }
            }
        } //-- end: _setSelectionTableValues ------------------------------------------------------------------------//

        tapwaterLogic.clearSelectionTableValue = function (prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = prop.getData(entdata);
            propdata.Value = '';
            _setAutocompleteSelectedItem(prop, entdata);
            tapwaterLogic.getSelectionTable(prop, entdata);
            tapwaterLogic.saveSelectionTableValue(prop, null, entdata, null);
        };

        tapwaterLogic.saveSelectionTableValue = function (prop, item, entdata, value) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = prop.getData(entdata);

            let shouldSave = false;
            if (propdata && !propdata.Touched) {
                propdata.Touched = true;
                shouldSave = true;
            }

            if (item && item.Id) {
                if (propdata.Value !== item.Id) {
                    propdata.Value = item.Id;
                    shouldSave = true;
                }
            } else {
                if (propdata.Value !== null) {
                    propdata.Value = null;
                    shouldSave = true;
                }
            }

            tapwaterLogic.validate(prop, propdata);
            if (shouldSave) {
                ntaEntityData.saveprop(propdata);
            }

            switch (prop.Id) {
                case 'TAPW-OPWEK_TOES':
                case 'TAPW-OPWEK_REGIO':
                case 'TAPW-OPWEK_BRON_POMP':
                    {
                        const propToestel = ntabuilding.properties['TAPW-OPWEK_TOES'];
                        if (prop.Id === 'KOEL-OPWEK_TOES') {
                            const typeOpwekkerValue = entdata.PropertyDatas['TAPW-OPWEK_TYPE'].Value;
                            const typeOpwekker = tapwaterLogic.opwekkerTypes.find(function (x) { return x.Id === typeOpwekkerValue; });
                            if (typeOpwekker.key2 === 'EW') {
                                //-- als ik warmtelevering derden ben dan moet ik NEN7125 verklaring (= NTA8800 bijlage P) van het product opslaan
                                const productTOES = _getListItem(propToestel, entdata);
                                if (productTOES) {
                                    const propNEN7125Verkl = ntabuilding.properties['TAPW-OPWEK_NEN7125VERKL'];
                                    let newValue;
                                    if (productTOES.NEN7125Verklaring === 'gemeten') {
                                        newValue = 'NEN7125VERKL_ALLGEMETEN';
                                    }
                                    else if (productTOES.NEN7125Verklaring === 'berekend') {
                                        newValue = 'NEN7125VERKL_BEREKEND';
                                    }
                                    tapwaterLogic.saveValue(propNEN7125Verkl, entdata, newValue);
                                }
                            }
                        }

                        //-- nieuwe keuzelijst voor toestel samenstellen
                        tapwaterLogic.getSelectionTable(propToestel, entdata, "");
                        tapwaterLogic.setDescriptionInstallation(entdata);
                        _setSelectionTableValues(entdata, 'W');
                        break;
                    }
                case 'TAPW-VAT_TYPE':
                    _setSelectionTableValues(entdata, 'BVAT');
                    break;
                case 'TAPW-DOUCHE_WTW_TOES':
                    _setSelectionTableValues(entdata, 'DWTW');
                    break;
                default:
                    break;
            }
        } //-- end: saveSelectionTableValue ------------------------------------------------------------------------//

        ////////////////////////////////////////////////////////////////////////// FORM /////////////////////////////////////////////////////////////////////////////////////////////////////////
        tapwaterLogic.startFormValidation = function () {
            const invalidPropdatas = [];
            for (const entdata of getAllEntDatas()) {
                for (const propdata of entdata.PropertyDatas) {
                    try {
                        const isValid = tapwaterLogic.validate(null, propdata);
                        if (isValid === false) {
                            invalidPropdatas.push(propdata);
                        }
                    } catch (err) {
                        console.log(err.message, err);
                    }
                }
            }
            return invalidPropdatas;
        } //-- end: startFormValidation ------------------------------------------------------------------------//

        tapwaterLogic.endFormValidation = function () {
            for (const entdata of getAllEntDatas()) {
                for (const propdata of entdata.PropertyDatas) {
                    if (!propdata.Touched) {
                        propdata.Touched = true;
                        ntaEntityData.saveprop(propdata);
                    }
                }
            }
            return tapwaterLogic.startFormValidation();
        } //-- end: endFormValidation ------------------------------------------------------------------------//

        function getAllEntDatas() { // Geeft alle entdatas in één array terug
            return tapwaterLogic.tapwOpwekkersData
                .concat(tapwaterLogic.tapwVatenData)
                .concat(tapwaterLogic.tapwDistributieData, tapwaterLogic.tapwDistributieBinnenData, tapwaterLogic.tapwDistributieBuitenData)
                .concat(tapwaterLogic.tapwDistributieEigenschappenLeidingenData)
                .concat(tapwaterLogic.tapwDistributiePompData)
                .concat(tapwaterLogic.tapwAfgifteData)
                .concat(tapwaterLogic.tapwDoucheData)
                .concat(tapwaterLogic.units.map(unit => getUnitLeiding(unit)).filter(leiding => leiding)) // units[].leiding; leiding bestaat blijkbaar niet altijd
                .concat(tapwaterLogic.tapwDoucheData.flatMap(doucheWTW => doucheWTW.tempUnits).map(tempUnit => tempUnit.douche)) // tapwDoucheData[].tempUnits[].douche
                .concat(ntaSharedLogic.getCostEntdata(installId))
                .filter(entdata => entdata) // filter alle nulls en undefineds eruit
            ;
        }

        tapwaterLogic.validate = function (prop, propdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop && propdata) {
                prop = ntabuilding.properties[propdata.PropertyId];
            }
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            if (propdata.PropertyId !== prop.Id && ntaSharedLogic.isUnitSpecificPropId(propdata.PropertyId)) {
                // Dan hebben we de unit-specifieke propertyData te pakken, maar ntaValidation.IsValid heeft
                // de ‘algemene’ propdata nodig; dus we vervangen propdata even (maar we geven die nu wel de unit-specifieke
                // Value).
                propdata = ntaSharedLogic.getNonUnitSpecificPropdata(propdata);
            }

            const entdata = ntaEntityData.get(propdata.EntityDataId);
            ntaSharedLogic.useUnitSpecificValue(prop, entdata);
            let valid = ntaValidation.IsValid(tapwaterLogic.form_installationtapwater, prop, propdata, tapwaterLogic.isHidden(prop, entdata), tapwaterLogic.isReadOnly(prop, entdata));  //Client Validatie

            const contrl = tapwaterLogic.form_installationtapwater && tapwaterLogic.form_installationtapwater['ntainput' + propdata.PropertyDataId];

            if (prop.Id === 'TAPW-OPWEK_GEM') { // Condities [TOAN] en [TOBF]
                const opwekkertype = tapwaterLogic.opwekkerTypes.find(x => x.Id === tapwaterLogic.tapwOpwekkersData[0].PropertyDatas['TAPW-OPWEK_TYPE'].Value);
                const vasteWaardes = {
                    'individueel': 'TAPW-OPWEK_GEM_NIET',
                    'gemeenschappelijk': 'TAPW-OPWEK_GEM_WEL',
                };
                const vasteWaarde = opwekkertype && vasteWaardes[opwekkertype.key8];
                if (vasteWaarde) {
                    //-- saveValue aanroepen, dan worden ook de afhankelijkheden (validateDependencies) van TAPW-OPWEK_GEM gezet
                    tapwaterLogic.saveValue(prop, entdata, vasteWaarde);
                }
            }
            else if (prop.Id === 'TAPW-DOUCHE-AANG_AANT') { // Conditie [TWO]

                let somValid = true;

                const aangeslotenDouche = entdata;
                const unit = tapwaterLogic.units.find(unit => ntaSharedLogic.hasRelation(unit, aangeslotenDouche));
                const aangeslotenDouchesPropdatas = tapwaterLogic.tapwDoucheData
                    .map(doucheWTW => doucheWTW.tempUnits.find(tempUnit => tempUnit.EntityDataId === unit.EntityDataId))
                    .map(tempUnit => tempUnit.douche.PropertyDatas['TAPW-DOUCHE-AANG_AANT']);
                const totaalAantalAangeslotenDouches = aangeslotenDouchesPropdatas.reduce(function (som, propdata) {
                    const aantalAangeslotenDouches = parseInt(propdata.Value);
                    if (!isNaN(aantalAangeslotenDouches)) {
                        som += aantalAangeslotenDouches;
                    }
                    return som;
                }, 0);

                if (ntaSharedLogic.isUtiliteit()) {

                    // Check aantal douches
                    if (aangeslotenDouche.Relevant) {
                        const unitRz = tapwaterLogic.units.find(function (x) { return ntaSharedLogic.hasRelation(x, aangeslotenDouche); });

                        const tempUnit = tapwaterLogic.tapwDoucheData[0].tempUnits.find(function (x) { return x.EntityDataId === unitRz.EntityDataId; });
                        const aantalDouchesInUnit = parseInt(tempUnit.douche.PropertyDatas['TAPW-DOUCHE-AANG_DIU'].Value);

                        if (!isNaN(aantalDouchesInUnit) && totaalAantalAangeslotenDouches > aantalDouchesInUnit) {
                            // Toon E053
                            somValid = false;
                        }
                    }

                    // altijd checken of contrl er is -> kan hidden zijn (ng-if) -> message wel altijd wel/niet laten zien.
                    if (contrl) contrl.$setValidity("[E053]", somValid);
                    ntaMeldingen.melding("[E053]", propdata.PropertyDataId, somValid);

                } else { // woningbouw

                    // Check aantal badruimten
                    const tapwUnit = tapwaterLogic.tapwUnits.find(tapwUnit => ntaSharedLogic.hasRelation(unit, tapwUnit));
                    const badr = Math.max(0, parseInt(tapwUnit.PropertyDatas['TAPW-UNIT_BADRUIMTEN'].Value) || 0);

                    if (totaalAantalAangeslotenDouches > badr) {
                        // Toon E059
                        somValid = false;
                    }

                    // Maak meldingen aan voor alle propdatas TAPW-DOUCHE-AANG_AANT
                    for (const douchePropdata of aangeslotenDouchesPropdatas) {
                        const contrl = tapwaterLogic.form_installationtapwater && tapwaterLogic.form_installationtapwater['ntainput' + douchePropdata.PropertyDataId];
                        // altijd checken of contrl er is -> kan hidden zijn (ng-if) -> message wel altijd wel/niet laten zien.
                        if (contrl) contrl.$setValidity("[E059]", somValid);
                        ntaMeldingen.melding("[E059]", douchePropdata.PropertyDataId, somValid);
                    }

                    // Verwijder evt. onterechte meldingen uit oude code (daarin werden ze per abuis bij de verkeerde propertydata gezet)
                    const propdatas = tapwaterLogic.tapwDoucheData.map(doucheWTW => doucheWTW.PropertyDatas['TAPW-DOUCHE_AANT_TYPE']);
                    for (const propdata of propdatas) {
                        ntaMeldingen.melding("[E059]", propdata.PropertyDataId, true);
                    }
                }

                return valid && somValid;
            }
            else if (prop.Id === 'TAPW-OPWEK_ENER_NON') { // Conditie [TOBD]
                let energiefractieValid = true;

                if (!tapwaterLogic.isHidden(prop, entdata) && propdata.Relevant) {
                    tapwaterLogic.setEnergieFractie();

                    if (tapwaterLogic.tapwOpwekkersData.length === 1 && ntaSharedLogic.parseFloat(propdata.Value) < 1) {
                        energiefractieValid = false;
                    }
                }

                if (contrl) { // altijd checken of contrl er is -> kan hidden zijn (ng-if) -> message wel altijd wel/niet laten zien.
                    contrl.$setValidity("[E057]", energiefractieValid);
                }
                ntaMeldingen.melding("[E057]", propdata.PropertyDataId, energiefractieValid);

                return valid && energiefractieValid;
            }
            else if (prop.Id === 'TAPW-OPWEK_ENER') { // Conditie [TOBC]
                let energiefractieValid = true;

                if (!tapwaterLogic.isHidden(prop, entdata) && tapwaterLogic.tapwOpwekkersData.length === 1 && propdata.Value && ntaSharedLogic.parseFloat(propdata.Value) < 1) {
                    energiefractieValid = false;
                }

                if (contrl) { // altijd checken of contrl er is -> kan hidden zijn (ng-if) -> message wel altijd wel/niet laten zien.
                    contrl.$setValidity("[E056]", energiefractieValid);
                }
                ntaMeldingen.melding("[E056]", propdata.PropertyDataId, energiefractieValid);

                return valid && energiefractieValid;
            }
            else if (prop.Id === 'TAPW-DOUCHE_AANT_TYPE') { // Conditie [TWK]
                let aantalAangeslotenDouchesValid = true;

                // Conditie [TWH] en conditie [TWL] (niet zichtbaar, dus geen foutmelding)
                if (conditieTWH(entdata) || ntaSharedLogic.perGebouwEnAppartementOfUnit() || ntaSharedLogic.voorProjectwoningen()) {
                    ntaMeldingen.melding("[E048]", propdata.PropertyDataId, aantalAangeslotenDouchesValid);
                    return valid && aantalAangeslotenDouchesValid;
                }

                const somAantalDouches = tapwaterLogic.tapwDoucheData.reduce((som, doucheWTW) => som + (parseInt(doucheWTW.PropertyDatas['TAPW-DOUCHE_AANT_TYPE'].Value) || 0), 0);

                const totaalAantalDouches = getTotaalAantalDouches();

                if (somAantalDouches > totaalAantalDouches) {
                    aantalAangeslotenDouchesValid = false;
                }

                if (contrl) { // altijd checken of contrl er is -> kan hidden zijn (ng-if) -> message wel altijd wel/niet laten zien.
                    contrl.$setValidity("[E048]", aantalAangeslotenDouchesValid);
                }
                ntaMeldingen.melding("[E048]", propdata.PropertyDataId, aantalAangeslotenDouchesValid);

                return valid && aantalAangeslotenDouchesValid;
            }
            else if (prop.Id === 'TAPW-OPWEK_WARMTE_UIT_VERW') { // TO58
                const errors = {
                    '[E065]': false,
                    '[E064]': false,
                    '[E066]': false,
                    '[E110]': false,
                    '[E126]': false,
                };

                if (propdata.Relevant) {
                    let verwSysEntityDataId = null;
                    const verwSysRel = ntaSharedLogic.getRelData(entdata, 'VERW');
                    if (verwSysRel) {
                        verwSysEntityDataId = verwSysRel.Child;
                    }

                    const verwDistrData = !!verwSysEntityDataId && ntaEntityData.getFirstChild(verwSysEntityDataId, 'VERW-DISTR');
                    const verwInstallatieData = !!verwSysEntityDataId && ntaEntityData.getFirstParent(verwSysEntityDataId, 'INSTALLATIE');
                    const verwLogic = verwInstallatieData && ntaDependencyValidation.createVerwarmingLogic(verwInstallatieData);

                    // Conditie [TOBW] Indien HD02 van het gekozen verwarmingssysteem = 'geen watergedragen distributiesysteem aanwezig' toon [E065]
                    errors['[E065]'] = !!verwDistrData && verwDistrData.PropertyDatas['VERW-DISTR_TYPE'].Value === 'VERW-DISTR_TYPE_E';

                    // Conditie [TOBX] indien HD03 van het gekozen verwarmingssysteem < 65° toon [E064]
                    errors['[E064]'] = ntabuilding.ntaVersionId < 200 && !!verwDistrData && voldoetAanConditieTOBXenZ(verwDistrData, verwLogic, 65);
                    errors['[E110]'] = ntabuilding.ntaVersionId >= 200 && !!verwDistrData && voldoetAanConditieTOBXenZ(verwDistrData, verwLogic, 70);

                    // Conditie [TOBY] indien het gekozen verwarmingssysteem maar 1 opwekker heeft en key1 van table9_0 van deze opwekker = 'EW' toon [E066]
                    errors['[E066]'] = !!verwSysEntityDataId && voldoetAanConditieTOBY(verwSysEntityDataId, verwLogic);

                    //Conditie [TODF] indien versie is gt3-2 EN HD43 (VERW-DISTR_REG_AANVTEMP) = stooklijngeregelde aanvoertemperatuur toon [E126]
                    errors['[E126]'] = ntabuilding.ntaVersionId >= 300 && !!verwDistrData && verwDistrData.PropertyDatas['VERW-DISTR_REG_AANVTEMP'].Value === 'VERW-DISTR_REG_AANVTEMP_STOOKLIJN';
                }

                // Alle fouten weergeven dan wel verbergen
                for (const code of Object.keys(errors)) {
                    const isValid = errors[code] === false;
                    if (contrl) contrl.$setValidity(code, isValid);
                    ntaMeldingen.melding(code, propdata.PropertyDataId, isValid);
                    valid = valid && isValid;
                }
            }
            else if (prop.Id === 'TAPW-OPWEK_VERW-OPWEK') { // TO76
                const errors = {
                    '[E122]': false,
                    '[E123]': false,
                };

                if (propdata.Relevant) {
                    const verwSys = ntaEntityData.getFirstChild(entdata, 'VERW');

                    // Conditie [TOCX] Indien HD02 van het gekozen verwarmingssysteem = 'geen watergedragen distributiesysteem aanwezig' toon [E122]
                    const verwDistr = verwSys && ntaEntityData.getFirstChild(verwSys, 'VERW-DISTR');
                    errors['[E122]'] = !!verwDistr && verwDistr.PropertyDatas['VERW-DISTR_TYPE'].Value === 'VERW-DISTR_TYPE_E';

                    // Conditie [TOCY] controleer of bij het geselecteerde verwarmingssysteem HO42 = gemeenschappelijke installatie; zo niet toon [E123]
                    const verwOpwekker = verwSys && ntaEntityData.getFirstChild(verwSys, 'VERW-OPWEK');
                    errors['[E123]'] = !!verwOpwekker && verwOpwekker.PropertyDatas['VERW-OPWEK_GEM'].Value !== 'VERW-OPWEK_GEM_WEL';
                }

                // Alle fouten weergeven dan wel verbergen
                for (const code of Object.keys(errors)) {
                    const isValid = errors[code] === false;
                    if (contrl) contrl.$setValidity(code, isValid);
                    ntaMeldingen.melding(code, propdata.PropertyDataId, isValid);
                    valid = valid && isValid;
                }
            }
            else if (prop.Id === 'TAPW-OPWEK_INV' || prop.Id === 'TAPW-OPWEK_TYPE' || prop.Id === 'TAPW-OPWEK_BRON_POMP' || prop.Id === 'TAPW-OPWEK_FUNC' || prop.Id === 'TAPW-OPWEK_TOES') {

                const validE085 = voldoetAanConditieTOCI(entdata);
                const propdataOpwekBronPomp = entdata.PropertyDatas['TAPW-OPWEK_BRON_POMP']; //melding laten zien bij TO19 TAPW-OPWEK_BRON_POMP
                ntaSharedLogic.setMelding("[E085]", propdataOpwekBronPomp, tapwaterLogic.form_installationtapwater, validE085);

                const validE086 = voldoetAanConditieTOCJ(entdata);
                const propdataOpwekToestel = entdata.PropertyDatas['TAPW-OPWEK_TOES']; //melding laten zien bij TO22
                ntaSharedLogic.setMelding("[E086]", propdataOpwekToestel, tapwaterLogic.form_installationtapwater, validE086);

                const validE089 = voldoetAanAanvoerTempVerwarming(entdata);
                ntaSharedLogic.setMelding("[E089]", propdataOpwekToestel, tapwaterLogic.form_installationtapwater, validE089);

                valid = valid && validE085 && validE086 && validE089;

                if (prop.Id === 'TAPW-OPWEK_TYPE') { // TO05
                    valid = checkConditionTODC(propdata) && valid;
                }
            }
            else if (prop.Id === 'TAPW-OPWEK_WP_KOELSYS') {
                /// als de value === TAPW-OPWEK_WP_KOELSYS_GEEN dan is de propdata niet revelant en hoeft er niet gecheckt te worden.
                const showE109 = propdata.Relevant && !geenKoppelingMetAnderTapwSys(entdata);
                ntaSharedLogic.setMelding("[E109]", propdata, tapwaterLogic.form_installationtapwater, !showE109);

                //-- als showE109 true is hebben we al een melding. Daarom showE105 alleen als showE109=false
                const showE105 = propdata.Relevant && !showE109 && voldoetAanConditieTOCO(entdata);
                ntaSharedLogic.setMelding("[E105]", propdata, tapwaterLogic.form_installationtapwater, !showE105);
            }
            else if (prop.Id === 'TAPW-OPWEK_BRON_BOOS_KOEL') {
                const showE074 = !geenKoppelingMetAnderTapwSys(entdata);
                ntaSharedLogic.setMelding("[E074]", propdata, tapwaterLogic.form_installationtapwater, !showE074);
            }
            else if (prop.Id === 'TAPW-OPWEK_WP_VENTSYS') {
                // Conditie [TOCH]
                // toon [E088] indien het geselecteerde ventilatiesysteem ongelijk is aan:
                //  1) V03 = C.natuurlijke toevoer en mechanische afvoer
                //  2) V03 / V09 = Dc.mechanische toe - en afvoer - centraal EN V24 = 0 <<-- eigenlijk alleen Dc-D.1 waarbij wtw dus niet relevant is.
                // Conditie [TOCM]
                // toon [E00101] indien er bij een ander tapwatersysteem ook al gekozen is voor dit ventilatiesysteem

                let validTapwVentKoppelingE088 = true;
                let validTapwVentKoppelingE101 = true;

                const ventSysteem = ntaEntityData.getFirstChild(entdata, 'VENT');
                //-- het kan zijn dat er nog geen ventSysteem gekopppeld is, en dan deze check overslaan
                if (ventSysteem && !tapwaterLogic.isHidden(prop, entdata)) {
                    const ventWtw = ntaEntityData.getFirstChild(ventSysteem, 'WARMTETERUG');
                    const rendementWtw = ntaSharedLogic.parseFloat(ventWtw.PropertyDatas['WARMTETERUG_REND'].Value);
                    const ventSys = ventSysteem.PropertyDatas['VENT_SYS'].Value; //V03 = prop van VENT

                    const ventAanSysteem = ntaEntityData.getFirstChild(ventSysteem, 'VENTAAN');
                    const ventAanWtw = ntaEntityData.getFirstChild(ventAanSysteem, 'WARMTETERUG');
                    const rendementWtwAan = ntaSharedLogic.parseFloat(ventAanWtw.PropertyDatas['WARMTETERUG_REND'].Value);
                    const ventAanSys = ventAanSysteem.PropertyDatas['VENTAAN_SYS'].Value; //V09 = prop van VENTAAN


                    validTapwVentKoppelingE088 = ventSys === 'VENTSYS_NATMECH'
                        || (ventSys === 'VENTSYS_MECHC' && (!ventWtw.Relevant || rendementWtw === 0))
                        || (ventAanSysteem.Relevant && ventAanSys === 'VENTSYS_MECHC' && (!ventAanWtw.Relevant || rendementWtwAan === 0));

                    // komt dit ventilatiesysteem ook nog voor bij ANDERE tapwateropwekkers en controleer of de tapwopwekker een relatie heeft met het geselecteerde ventsysteem
                    validTapwVentKoppelingE101 = ntaEntityData.getListWithEntityId("TAPW-OPWEK")
                        .filter(ed => ed.EntityDataId !== entdata.EntityDataId)
                        .every(ed => ntaEntityData.getFirstChild(ed, 'VENT') !== ventSysteem);
                }

                ntaSharedLogic.setMelding("[E088]", propdata, tapwaterLogic.form_installationtapwater, validTapwVentKoppelingE088);
                ntaSharedLogic.setMelding("[E101]", propdata, tapwaterLogic.form_installationtapwater, validTapwVentKoppelingE101);

                valid = valid && validTapwVentKoppelingE088 && validTapwVentKoppelingE101;
            }
            return valid;
        } //-- end: validate ------------------------------------------------------------------------//

        function checkConditionTODC(propdata) {
            let isValid = true;
            // Conditie [TODC] als TO05 van de eerste opwekker is 'opwekker uit verwarmingssysteem'
            //                 EN bij de 2e opwekker is gekozen voor 'warmtepomp - elektrisch'
            //                 EN bij het gekoppelde verwarmingssysteem geldt bij de eerste opwekker HO05 = 'warmtepomp - elektrisch'
            //                 toon [E124]
            const [opwekker1, opwekker2] = tapwaterLogic.tapwOpwekkersData;
            const propdataOpwekker1Type = opwekker1.PropertyDatas['TAPW-OPWEK_TYPE'];   // TO05 1e opwekker
            if (propdataOpwekker1Type.Value === 'TAPW-OPWEK_TYPE_24') {                 // opwekker uit verwarmingssysteem
                const opwekker2type = opwekker2 && opwekker2.PropertyDatas['TAPW-OPWEK_TYPE'].Value; // TO05 2e opwekker
                if (opwekker2type === 'TAPW-OPWEK_TYPE_1') {                            // warmtepomp - elektrisch
                    const verwPrefOpwekker = ntaEntityData.findEntity(opwekker1, 'VERW.VERW-OPWEK');
                    if (verwPrefOpwekker && verwPrefOpwekker.PropertyDatas['VERW-OPWEK_TYPE'].Value === 'VERW-OPWEK_TYPE_A') {  // HO05 = 'warmtepomp - elektrisch'
                        isValid = false;
                    }
                }
            }
            ntaSharedLogic.setMelding('[E124]', propdataOpwekker1Type, tapwaterLogic.form_installationtapwater, isValid);

            // Bij andere propdatas dan TO05 van de eerste opwekker, altijd true teruggeven
            return isValid || propdata !== propdataOpwekker1Type;
        } //-- end: checkConditionTODC ------------------------------------------------------------

        function getTotaalAantalDouches() {
            if (ntaSharedLogic.isUtiliteit()) {
                // TW09
                const doucheWTW = tapwaterLogic.tapwDoucheData[0];
                return doucheWTW && parseInt(doucheWTW.PropertyDatas['TAPW-DOUCHE_AANT_TOT'].Value) || 0;
            } else {
                // badr
                const tapwUnit = tapwaterLogic.tapwUnits[0];
                return tapwUnit && parseInt(tapwUnit.PropertyDatas['TAPW-UNIT_BADRUIMTEN'].Value) || 0;
            }
        }

        function getSubValueWadhvAanvoerTempVerwarming(entdata) {
            const productWTOES = _getListItem('TAPW-OPWEK_TOES', entdata);

            if (productWTOES && productWTOES['W_valueId']) {
                //-- aanvoertemperatuur van verw systeem bepalen omdat subValue temperatuur afhankelijk kan zijn.
                let aanvTemp = 0;
                //-- verwarmingsysteem ophalen
                const verwsys = ntaEntityData.getFirstChild(entdata.EntityDataId, 'VERW');
                if (verwsys) {
                    const distrVerw = ntaEntityData.getFirstChild(verwsys, 'VERW-DISTR');
                    let propAanvTemp = ntabuilding.properties['VERW-DISTR_ONTW'];
                    if (distrVerw) {
                        let propdataAanvTemp = propAanvTemp.getData(distrVerw);
                        if (!propdataAanvTemp.Relevant) {
                            //- als deze niet relevant is moet ik die van het H_Model hebben. Dan is het verwarmingstoestel productspecifiek
                            propdataAanvTemp = distrVerw.PropertyDatas['VERW-DISTR_ONTW_H_MODEL'];
                            aanvTemp = ntaSharedLogic.parseFloat(propdataAanvTemp.Value);
                        } else {
                            //-- als het systeem forf of eigw is, is de value in propdata een codedValue waar ik de calcValue van op moet halen.
                            aanvTemp = ntaSharedLogic.parseFloat(ntaValidation.codedValues(propAanvTemp).find(c => c.Id === propdataAanvTemp.Value).CalcValue);
                        }
                        const subValueId = productWTOES['W_valueId'];
                        const subValuesW = ntaSelectionTable.getProducts('W', subValueId).sort((a, b) => a.AanvoerTemp - b.AanvoerTemp);
                        let subValueW = subValuesW.find(p => p.AanvoerTemp === aanvTemp);
                        //-- wanneer ik forfaitair benben moet ik de laagste of hoogste dichtsbijzijnde waarde pakken als ik niks gevonden heb in mijn subValuesW lijstje,
                        //-- in de andere gevallen is er geen subvalue beschikbaar voor dit product.
                        const propdataValueInvoer = entdata.PropertyDatas['TAPW-OPWEK_INV'].Value;
                        if (subValueW === undefined && propdataValueInvoer === 'TAPW-OPWEK_INV_FORF') {
                            //-- nog een keer proberen, maar dan kijken of je de minimale of maximale waarde moet gebruiken uit de range
                            const subValueMin = subValuesW[0];
                            const subValueMax = subValuesW[subValuesW.length - 1];
                            subValueW = Math.abs(subValueMax.AanvoerTemp - aanvTemp) < Math.abs(subValueMin.AanvoerTemp - aanvTemp) ? subValueMax : subValueMin;
                        }

                        return (subValueW === undefined) ? null : subValueW;
                    }
                }
            }

            return null;
        } //getSubValueWadhvAanvoerTempVerwarming

        // Voorwaarde uit Uitlezen Selectietabel
        function voldoetAanAanvoerTempVerwarming(entdata) {
            //-- VO 2021-08-09: Deze check komt ook voor in het rekenhart omdat hij behandeld is als error afhankelijk van rekenwaarden,
            //-- maar eigenlijk kan deze check in de client omdat ik check op aanv Temp Verw die in de invoer gezet wordt.

            const propdataValueOpwekInvoer = entdata.PropertyDatas['TAPW-OPWEK_INV'].Value;
            const propdataValueOpwekType = entdata.PropertyDatas['TAPW-OPWEK_TYPE'].Value;
            const opwekkertype = tapwaterLogic.opwekkerTypes.find(x => x.Id === propdataValueOpwekType);
            if (opwekkertype && opwekkertype.key1 === 'BWP' && propdataValueOpwekInvoer === 'TAPW-OPWEK_INV_PROD') {
                //-- Bij keuze BWP controleren of de geselecteerde kwaliteitsverklaring aanvoertemperarturen bevat die in de range vallen
                //-- van de aanvoertemperatuur die geselecteerd is bij het gekoppelde verwarmingssysteem. Succes!!
                const subValueW = getSubValueWadhvAanvoerTempVerwarming(entdata);
                //-- als er een subValueW gevonden is die past bij de aanvoertemperatuur wordt er voldaan. Anders ligt de aanvoertemp van verw
                //-- buiten de range en voldoet ie niet.
                return subValueW !== null;

            }
            return true;
        } //voldoetAanAanvoerTempVerwarming

        function geenKoppelingMetAnderTapwSys(entdata) {
            //-- deze functie geldt voor zowel conditie [TOCP] als [TOCG]. Alleen moet er een andere foutmeling [E109] of [E074] getoond worden. Zie validatie.
            //-- [TOCG] toon [E074] indien een koelsysteem wordt geselecteerd dat ook al op een ander warm tapwater formulier is geselecteerd bij veld TO46
            //-- [TOCP] controleer of er niet al een ander warm tapwater systeem gekoppeld is aan het gekozen koelsysteem. Indien dit het geval is toon [E109]
            const koelSysteem = ntaEntityData.getFirstChild(entdata, 'KOEL');

            return ntaEntityData.getListWithEntityId("TAPW-OPWEK")
                .filter(ed => ed.EntityDataId !== entdata.EntityDataId)
                .every(ed => ntaEntityData.getFirstChild(ed, 'KOEL') !== koelSysteem);
        };//-- end:  geenKoppelingMetAnderTapwSys -------------------------------------------------------//

        // Conditie [TOBX] indien HD03 van het gekozen verwarmingssysteem < 65 toon [E064]
        //Conditie [TOBZ] indien HD03 van het gekozen verwarmingssysteem < 70 toon [E110]
        function voldoetAanConditieTOBXenZ(verwDistrData, verwarmingLogic, temp) {
            let bToon1 = false;
            let bToon2 = false;
            let bToon3 = false;

            let ontwerpaanvoertemperatuur = NaN;
            if (verwarmingLogic) {
                const propOntwAanvTemp = ntabuilding.properties['VERW-DISTR_ONTW'];
                const propOntwAanvTempHModel = ntabuilding.properties['VERW-DISTR_ONTW_H_MODEL'];

                if (!verwarmingLogic.isHidden(propOntwAanvTemp, verwDistrData)) {
                    const ontwAanvTempCode = propOntwAanvTemp.getCode(verwDistrData);
                    if (ontwAanvTempCode) {
                        //-- _L, OPP_K en OPP_L hebben temperatuur onder 55 dus dan ook de melding tonen
                        bToon2 = ontwAanvTempCode.Id === 'VERW-DISTR_ONTW_L' || ontwAanvTempCode.Id === 'VERW-DISTR_ONTW_OPP_K' || ontwAanvTempCode.Id === 'VERW-DISTR_ONTW_OPP_L';
                        if (ontwAanvTempCode.FilterValue2 !== 'onbekend') {
                            ontwerpaanvoertemperatuur = parseInt(ontwAanvTempCode.FilterValue2) || NaN;
                        } else if (ontwAanvTempCode.Id === 'VERW-DISTR_ONTW_GE32_M') { //// onbekend -> v3.2
                            bToon3 = IsVerwDistrOntwTemp_LagerDan65(verwDistrData);
                        }
                    }
                } else if (!verwarmingLogic.isHidden(propOntwAanvTempHModel, verwDistrData)) {
                    const ontwerpaanvoertempHmodel = propOntwAanvTempHModel.getValue(verwDistrData);
                    if (ontwerpaanvoertempHmodel === 'VERW-DISTR_ONTW_GE32_M') { // onbekend -> v3.2
                        bToon3 = IsVerwDistrOntwTemp_LagerDan65(verwDistrData);
                    }
                }

            }

            bToon1 = !isNaN(ontwerpaanvoertemperatuur) && ontwerpaanvoertemperatuur < temp;

            return bToon1 || bToon2 || bToon3;
        }

        function IsVerwDistrOntwTemp_LagerDan65(verwDistrData) {
            const propDistrAfg = ntabuilding.properties['VERW-DISTR_AFGIFTE']; //HD40
            const propDistrMaxAanvTmp = ntabuilding.properties['VERW-DISTR_MAX_AANVTEMP_WP']; //HD41
            const propdataDistrAfg = propDistrAfg.getData(verwDistrData);
            const propdataDistrMaxAanvTmp = propDistrMaxAanvTmp.getData(verwDistrData);
            let distCalcValue = propdataDistrMaxAanvTmp.Relevant ? propDistrMaxAanvTmp.getCode(verwDistrData).CalcValue : propdataDistrAfg.Relevant ? propDistrAfg.getCode(verwDistrData).CalcValue : "";
            //Bij VERW-DISTR_ONTW_GE32_D en _F is de temp lager dan 65
            return distCalcValue === 'VERW-DISTR_ONTW_GE32_D' || distCalcValue === 'VERW-DISTR_ONTW_GE32_F';
        }

        // Conditie [TOBY] indien het gekozen verwarmingssysteem maar 1 opwekker heeft en key2 van table9_0 van deze opwekker = 'EW' toon [E066]
        function voldoetAanConditieTOBY(verwSysDataId, verwarmingLogic) {
            const opwekkers = ntaEntityData.getChildren(verwSysDataId, 'VERW-OPWEK');
            if (opwekkers.length !== 1)
                return false;
            const prefOpwekker = opwekkers[0];
            const opwekkertype = verwarmingLogic && verwarmingLogic.opwekkerTypes.find(x => x.Id === prefOpwekker.PropertyDatas['VERW-OPWEK_TYPE'].Value);
            return !!opwekkertype && opwekkertype.key2 === 'EW';
        }

        //Conditie [TOCI]
        // if TO07=eigen waarde opwekkingsrendement / eigen waarde opwekkingsrendement en fractie / eigen waarde opwekkingsrendement, fractie en hulpenergie
        // AND TO05 = warmtepomp - elektrisch AND TO19 = ventilatieretourlucht AND TO21 = verwarming en warm tapwater show[E085]
        function voldoetAanConditieTOCI(entdataTapwOpw) {

            const opwekInv = entdataTapwOpw.PropertyDatas['TAPW-OPWEK_INV'].Value; //TO07
            const opwekType = entdataTapwOpw.PropertyDatas['TAPW-OPWEK_TYPE'].Value; //TO05
            const opwekBronPomp = entdataTapwOpw.PropertyDatas['TAPW-OPWEK_BRON_POMP'].Value; //TO19
            const opwekFunc = entdataTapwOpw.PropertyDatas['TAPW-OPWEK_FUNC'].Value; //TO21

            if (opwekInv === 'TAPW-OPWEK_INV_EIG_REND' || opwekInv === 'TAPW-OPWEK_INV_EIG_REND_FRAC' || opwekInv === 'TAPW-OPWEK_INV_EIG_REND_FRAC_HULP' ) {
                if (opwekType === 'TAPW-OPWEK_TYPE_1' && opwekBronPomp === 'VERW-OPWEK_POMP_VERE' && opwekFunc === 'TAPW-OPWEK_FUNC_COMBI') {
                    return false;
                }
            }
            return true;
        } //voldoetAanConditieTOCI

        /// Conditie [TOCJ]
        /// als TO05=warmtepomp - elektrisch EN TO07=productspecifiek EN TO21=verwarming en warm tapwater EN TO19=ventilatieretourlucht EN HO06 van het gekoppelde verwarmingssysteem = productspecifiek: controleer of bij
        /// het gekoppelde verwarmingssysteem(check via het in TO52 gekozen ventilatiesysteem de aangesloten rekenzones) of TO22 en HO20 hetzelfde selectietabel ID hebben; zo niet toon[E086]
        function voldoetAanConditieTOCJ(entdataTapwOpw) {
            const propdataOpwekType = entdataTapwOpw.PropertyDatas['TAPW-OPWEK_TYPE'].Value;    //TO05
            const propdataOpwekInv = entdataTapwOpw.PropertyDatas['TAPW-OPWEK_INV'].Value;      //TO07
            const propdataOpwekFunc = entdataTapwOpw.PropertyDatas['TAPW-OPWEK_FUNC'].Value;    //TO21
            const propdataOpwekBronPomp = entdataTapwOpw.PropertyDatas['TAPW-OPWEK_BRON_POMP'].Value;  //TO19

            if (propdataOpwekType === 'TAPW-OPWEK_TYPE_1' && propdataOpwekInv === 'TAPW-OPWEK_INV_PROD' && propdataOpwekFunc === 'TAPW-OPWEK_FUNC_COMBI' && propdataOpwekBronPomp === 'VERW-OPWEK_POMP_VERE') {
                const ventSysteem = ntaEntityData.getFirstChild(entdataTapwOpw, 'VENT');
                if (ventSysteem) {
                    /// toestel Id opzoeken van het tapwatertoestel
                    const toestelTapwId = parseInt(entdataTapwOpw.PropertyDatas['TAPW-OPWEK_TOES'].Value);
                    /// hier de rekenzones van ophalen van dit ventilatiesysteem
                    let hasMatch = false;
                    let checkRelevant = false;
                    const rekenzonesId = ntaEntityData.getChildIds(ventSysteem, 'RZ');
                    for (const rzId of rekenzonesId) {
                        /// van de rekenzone het verwarmingsysteem ophalen.
                        const verwsysteem = ntaEntityData.getFirstParent(rzId, 'VERW');
                        const opwekverwarming = ntaEntityData.getFirstChild(verwsysteem, 'VERW-OPWEK');
                        if (opwekverwarming) {
                            const toestelProductspecifiek = opwekverwarming.PropertyDatas['VERW-OPWEK_INVOER'].Value === 'VERW-OPWEK_INVOER_PROD';
                            if (toestelProductspecifiek) {
                                checkRelevant = true;
                                const toestelVerwId = parseInt(opwekverwarming.PropertyDatas['VERW-OPWEK_TOEW'].Value);
                                /// controleren of de selectietabel Id van het verwarmingstoestel gelijk is aan het toestel Id van tapwater
                                hasMatch = hasMatch || (toestelProductspecifiek && toestelVerwId === toestelTapwId); //-- als beide NaN zijn geldt ook NaN === NaN -> false
                            }
                        }
                        if (hasMatch) {
                            break;
                        }
                    }
                    //-- alleen wanneer 1 of meerdere verwarmingssystemen productspecifiek is, is de check relevant en moet ik de match controleren, anders blijft valid gewoon true.
                    if (checkRelevant) {
                        return hasMatch;
                    }
                }
            }
            return true;
        } //voldoetAanConditieTOCJ

        function voldoetAanConditieTODB() {
            // [TODB] verberg als TO05 in de eerste kolom is 'opwekker van verwarmingssysteem'
            const [opwekker1] = tapwaterLogic.tapwOpwekkersData;
            const opwekker1Type = opwekker1.PropertyDatas['TAPW-OPWEK_TYPE'].Value;   // TO05 1e opwekker
            return opwekker1Type === 'TAPW-OPWEK_TYPE_24'; // opwekker van verwarmingssysteem
        } //-- voldoetAanConditieTODB -------------------------------------------------------------

        /// Conditie [TOCO]
        /// toon [E105] indien het gekozen toestel in KO11 bij het gelinkte koelsysteem ongelijk is aan het toestel in TO22
        function voldoetAanConditieTOCO(entdataTapwOpw) {
            const propOpwekToes = ntabuilding.properties['TAPW-OPWEK_TOES'];    //TO22

            /// als er uberhaupt geen producten zijn, hoef ik deze niet te checken
            const product = tapwaterLogic.getProduct(propOpwekToes, entdataTapwOpw);
            if (product && product.CombiMetKoeling) {
                /// dan is er spraken van een tapwwater-koeling combi en moet ik kijken of het geselecteerd koelsysteem het zelfde product gekozen is
                const koelSysteem = ntaEntityData.getFirstChild(entdataTapwOpw, 'KOEL');
                const koelOpwekker = ntaEntityData.getFirstChild(koelSysteem, 'KOEL-OPWEK');
                const productOpwekkerKoel = !!koelOpwekker && koelOpwekker.PropertyDatas["KOEL-OPWEK_TOEW"].Value || "";

                return parseInt(productOpwekkerKoel) !== product.Id;
            }

            return false;
        }//-- end: voldoetAanConditieTOCO ------------------------------------------------------------//

        ///////////////////////////////////////////////////////////////////////////////// PROPERTIES ////////////////////////////////////////////////////////////////////////////////////////////////////
        tapwaterLogic.saveValue = function (prop, entdata, newValue) {
            return ntaSharedLogic.saveValue(prop, entdata, newValue, tapwaterLogic);
        } //-- end: saveValue ------------------------------------------------------------------------//

        tapwaterLogic.isReadOnly = function (prop, entdata) {
            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return false;
            }

            if (['TAPW-OPWEK_BEHOEFTE', 'TAPW-OPWEK_GELEV',                                   // TO43, TO44, TO38
                'TAPW-OPWEK_REND_NON', 'TAPW-OPWEK_COP_NON', 'TAPW-OPWEK_COP_BWP_NON',        // TO27, TO28, TO62
                'TAPW-OPWEK_OMZET_NON', 'TAPW-OPWEK_REND_WARM_NON', 'TAPW-OPWEK_PRIM_NON',    // TO60, TO39, TO31
                'TAPW-OPWEK_ENER_NON', 'TAPW-OPWEK_HULP_NON', 'TAPW-OPWEK_VERM_NON',          // TO33, TO35, TO45
                'TAPW-OPWEK_FPREN_NON', 'TAPW-OPWEK_KCO_NON',                                 // TO42, TO47, TO49
                'TAPW-OPWEK_PLS_NON', 'TAPW-OPWEK_HERNIEUW_NON', 'TAPW-OPWEK_LVOLSTR_NON',    // TO65, TO66, TO69
                'TAPW-OPWEK_CWMIXEDAIR_NON', 'TAPW-OPWEK_FWBUITENL_NON',                      // TO73, TO75
                'MEASURE-COSTS_TOTAAL'
                ].includes(prop.Id)) {
                return true;
            }
            else if (prop.Id === 'TAPW-VAT_VOL_NON' || prop.Id === 'TAPW-VAT_TRANS_NON') { // TV08, TV12
                return true;
            }
            else if (prop.Id === 'TAPW-DISTR-BIN_LENG_NON' || prop.Id === 'TAPW-DISTR-BUI_LENG_NON' || prop.Id === 'TAPW-DISTR_CIRC_VERM_NON' || prop.Id === 'TAPW-DISTR_CIRC_EEI_NON' || prop.Id === 'TAPW-DISTR-EIG_RUIMTE') { // TD06, TD13, TD27, TD29, TD19
                return true;
            }
            else if (prop.Id === 'TAPW-DOUCHE_REND_NON') { // TW12
                return true;
            }
            else if (prop.Id === 'TAPW-DISTR-EIG_DEK') { // TD22
                if (ntaSharedLogic.hasRelation(tapwaterLogic.tapwDistributieBinnenData, entdata)) {
                    if (binnenDekkingShouldBeHidden()) {
                        return true;
                    }
                }
                else if (ntaSharedLogic.hasRelation(tapwaterLogic.tapwDistributieBuitenData, entdata)) {
                    if (buitenDekkingShouldBeHidden()) {
                        return true;
                    }
                }
            }
            else if (prop.Id === 'TAPW-DISTR-EIG_LAB_CON') { // TD23
                if (ntaSharedLogic.hasRelation(tapwaterLogic.tapwDistributieBinnenData, entdata)) {
                    if (binnenDekkingShouldBeHidden()) {
                        return true;
                    }
                }
                else if (ntaSharedLogic.hasRelation(tapwaterLogic.tapwDistributieBuitenData, entdata)) {
                    if (buitenDekkingShouldBeHidden()) {
                        return true;
                    }
                }
            }
            else if (prop.Id === 'TAPW-DISTR-EIG_LAB_ISO') { // TD24
                if (ntaSharedLogic.hasRelation(tapwaterLogic.tapwDistributieBinnenData, entdata)) {
                    if (binnenLabdaIsolatieShouldBeHidden()) {
                        return true;
                    }
                }
                else if (ntaSharedLogic.hasRelation(tapwaterLogic.tapwDistributieBuitenData, entdata)) {
                    if (buitenLabdaIsolatieShouldBeHidden()) {
                        return true;
                    }
                }
            }
            else if (prop.Id === 'TAPW-DISTR-EIG_LAB_LEI') { //TD25
                if (ntaSharedLogic.hasRelation(tapwaterLogic.tapwDistributieBinnenData, entdata)) { // Als binnen hidden is en buiten niet
                    return !showBinnenLabdaLeiding();
                }
                else if (ntaSharedLogic.hasRelation(tapwaterLogic.tapwDistributieBuitenData, entdata)) { // Als buiten hidden is en binnen niet
                    return !showBuitenLabdaLeiding();
                }
            }
            else if (prop.Id === 'TAPW-AFG-LEI_LEN_BAD') { // TA08
                let unit = tapwaterLogic.units.find(function (x) { return ntaSharedLogic.hasRelation(x, entdata); });
                let tapwUnit = tapwaterLogic.tapwUnits.find(function (x) { return ntaSharedLogic.hasRelation(unit, x); });

                if (!ntaSharedLogic.isUtiliteit()) {
                    let propdata = entdata.PropertyDatas['TAPW-AFG-LEI_LEN_BAD'];
                    let badrValue = tapwUnit.PropertyDatas['TAPW-UNIT_BADRUIMTEN'].Value;
                    let badr = parseInt(badrValue);

                    if (!badr || badr <= 0) {
                        if (propdata.Value !== 'n.v.t.') {
                            propdata.Value = 'n.v.t.';
                            ntaEntityData.saveprop(propdata);
                        }
                        return true;
                    }
                    else {
                        if (propdata.Value === 'n.v.t.') {
                            propdata.Value = '';
                            ntaEntityData.saveprop(propdata);
                        }
                        return false;
                    }
                }
            }
            else if (prop.Id === 'TAPW-AFG-LEI_LEN_AANR' || prop.Id === 'TAPW-AFG-LEI_DIA_AANR') { // TA09, TA10
                let unit = tapwaterLogic.units.find(function (x) { return ntaSharedLogic.hasRelation(x, entdata); });
                let tapwUnit = tapwaterLogic.tapwUnits.find(function (x) { return ntaSharedLogic.hasRelation(unit, x); });

                if (!ntaSharedLogic.isUtiliteit()) {
                    let propdata = entdata.PropertyDatas[prop.Id];
                    let keukValue = tapwUnit.PropertyDatas['TAPW-UNIT_KEUKENS'].Value;
                    let keuk = parseInt(keukValue);

                    if (!keuk || keuk <= 0) {
                        if (propdata.Value !== 'n.v.t.') {
                            propdata.Value = 'n.v.t.';
                            ntaEntityData.saveprop(propdata);
                        }
                        return true;
                    }
                    else {
                        if (propdata.Value === 'n.v.t.') {
                            propdata.Value = '';
                            ntaEntityData.saveprop(propdata);
                        }
                        return false;
                    }
                }
            }


            return false;
        } //-- end: isReadOnly ------------------------------------------------------------------------//


        tapwaterLogic.getCodedValues = function (prop, entdata) {
            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            const opwekkerType = entdata.EntityId === 'TAPW-OPWEK'
                && tapwaterLogic.opwekkerTypes.find(x => x.Id === entdata.PropertyDatas['TAPW-OPWEK_TYPE'].Value);

            let typesList = [];

            switch (prop.Id) {
                case 'TAPW-OPWEK_TYPE': { // TO05
                    const opwekkerNr = tapwaterLogic.opwekkerNr(entdata);
                    typesList = tapwaterLogic.getOpwekkerTypes(opwekkerNr); // <- getOpwekkerTypes bevat een hele zwik condities (die zowel voor TO03 als voor TO05 gelden)
                    break;
                }
                case 'TAPW-OPWEK_INDIR':
                    {   //-- [TO06]
                        typesList = prop.Domain.Codes;

                        //-- Conditie [TOAM]
                        if (opwekkerType && opwekkerType.indirect === 'verplicht') {
                            typesList = typesList.filter(x => x.Id !== 'TAPW-OPWEK_INDIR_GEEN');
                        }

                        //-- Conditie [TOBN]
                        if (opwekkerType && opwekkerType.key1 === 'WP') {
                            typesList = typesList.filter(x => x.Id === 'TAPW-OPWEK_INDIR_GEINT' || x.Id === 'TAPW-OPWEK_INDIR_LOS');
                        } else {
                            typesList = typesList.filter(x => x.Id !== 'TAPW-OPWEK_INDIR_GEINT' && x.Id !== 'TAPW-OPWEK_INDIR_LOS');
                        }

                        //-- Conditie [TOBP]
                        if (!conditieTOBP(entdata)) {
                            typesList = typesList.filter(x => x.Id !== 'TAPW-OPWEK_INDIR_GEEN')     // geen indirect verwarmde warm watervoorraadvat(en)
                                                 .filter(x => x.Id !== 'TAPW-OPWEK_INDIR_GEINT');   // warmtepomp met geïntegreerd voorraadvat
                        }

                        break;
                    }
                case 'TAPW-OPWEK_INV': // TO07
                    typesList = ntaValidation.codedValues(prop);

                    // Conditie TOC
                    if (opwekkerType && opwekkerType.key2 !== 'product') {
                        typesList = typesList.filter(function (x) { return x.Id !== 'TAPW-OPWEK_INV_PROD'; });
                    }

                    // Conditie TOE
                    if (opwekkerType && opwekkerType.key7 === 'geen forfaitair') {
                        typesList = typesList.filter(function (x) { return x.Id !== 'TAPW-OPWEK_INV_FORF'; });
                    }

                    // Conditie TOAO
                    if (opwekkerType && opwekkerType.key1 === 'ONB') {
                        typesList = typesList.filter(function (x) { return x.Id !== 'TAPW-OPWEK_INV_EIG_REND' && x.Id !== 'TAPW-OPWEK_INV_EIG_REND_FRAC' && x.Id !== 'TAPW-OPWEK_INV_EIG_REND_FRAC_HULP'; });
                    }

                    // Conditie [TOAP] en [TOCK]
                    const showTOAP = tapwaterLogic.opwekkerNr(entdata) === 1;
                    const hideTOCK = (opwekkerType && opwekkerType.key1 === 'BWP') || heeftHotfillBoiler()
                    if (!showTOAP || hideTOCK) {
                        typesList = typesList.filter(x => x.Id !== 'TAPW-OPWEK_INV_EIG_REND_FRAC' && x.Id !== 'TAPW-OPWEK_INV_EIG_REND_FRAC_HULP');
                    }

                    // Conditie [TODB]
                    if (voldoetAanConditieTODB()) {
                        typesList = typesList.filter(x => x.Id !== 'TAPW-OPWEK_INV_PROD')               // productspecifiek
                                             .filter(x => x.Id !== 'TAPW-OPWEK_INV_FORF')               // forfaitair
                                             .filter(x => x.Id !== 'TAPW-OPWEK_INV_EIG_REND_FRAC')      // eigen waarde opwekkingsrendement en fractie
                                             .filter(x => x.Id !== 'TAPW-OPWEK_INV_EIG_REND_FRAC_HULP');// eigen waarde opwekkingsrendement, fractie en hulpenergie
                    }

                    break;
                case 'TAPW-OPWEK_GEM': { // TO09
                    typesList = ntaValidation.codedValues(prop);

                    if (opwekkerType && opwekkerType.key8 === 'individueel' || heeftHotfillBoiler()) { // Conditie TOAN
                        typesList = typesList.filter(function (x) { return x.Id !== 'TAPW-OPWEK_GEM_WEL'; });
                    }
                    else if (opwekkerType && opwekkerType.key8 === 'gemeenschappelijk') { // Conditie TOBF
                        typesList = typesList.filter(function (x) { return x.Id !== 'TAPW-OPWEK_GEM_NIET'; });
                    }

                    break;
                }
                case 'TAPW-OPWEK_EMISSIE': // TO17
                    const versie = ntabuilding.ntaVersionId >= 300 ? 'gt3.2' : 'le3.2';
                    typesList = ntaValidation.codedValues(prop).filter(x => x.FilterValue1 === versie);

                    break;
                case 'TAPW-OPWEK_BRON_BOOS': // TO13
                    typesList = _verwarmingSystemen;
                    break;
                case 'TAPW-OPWEK_WP_KOELSYS':       //TO71
                case 'TAPW-OPWEK_BRON_BOOS_KOEL':   // TO46
                    typesList = getKoelsystemen(entdata);
                    break;
                case 'TAPW-OPWEK_WP_VENTSYS': // TO52
                    typesList = _ventilatieSystemen;
                    break;
                case 'TAPW-OPWEK_WARMTE_UIT_VERW': // TO58
                case 'TAPW-OPWEK_VERW-OPWEK': // TO76
                    typesList = _verwarmingSystemen;
                    break;
                case 'TAPW-OPWEK_OPSTEL': // TO53
                    typesList = getOpstelplaatsen(prop, "gasboiler");
                    break;
                case 'TAPW-OPWEK_VERBR_OPS': { // TO55
                    typesList = getOpenVerbrandingCodedValues(entdata);
                    break;
                }

                case 'TAPW-VAT_FABR': // TV09
                    {
                        typesList = ntaValidation.codedValues(prop);

                        // Conditie TVD en TVE
                        let volumeVoorraadvatValue = entdata.PropertyDatas['TAPW-VAT_VOL_A'].Value;
                        if (volumeVoorraadvatValue) {
                            let volumeVoorraadvat = parseInt(volumeVoorraadvatValue);
                            if (volumeVoorraadvat <= 500) {
                                typesList = typesList.filter(function (x) { return x.Id !== 'TAPW-VAT_FABR_VOOR2018'; });
                            }
                            else {
                                typesList = typesList.filter(function (x) { return x.Id !== 'TAPW-VAT_FABR_VOOR2016' && x.Id !== 'TAPW-VAT_FABR_2016EN2017'; });
                            }
                        }

                        break;
                    }
                case 'TAPW-VAT_AANSL': { // TV13
                    typesList = ntaValidation.codedValues(prop);

                    // Conditie [TVL] en [TVK]
                    const opwekkerTypeValue = tapwaterLogic.tapwOpwekkersData[0].PropertyDatas['TAPW-OPWEK_TYPE'].Value;
                    const opwekkerType = tapwaterLogic.opwekkerTypes.find(x => x.Id === opwekkerTypeValue);
                    if (opwekkerType) {
                        if (opwekkerType.key1 === 'EB') {
                            typesList = typesList.filter(x => x.Id !== 'TAPW-VAT_AANSL_1' && x.Id !== 'TAPW-VAT_AANSL_2' && x.Id !== 'TAPW-VAT_AANSL_3' && x.Id !== 'TAPW-VAT_AANSL_4');
                        } else {
                            typesList = typesList.filter(x => x.Id !== 'TAPW-VAT_AANSL_5' && x.Id !== 'TAPW-VAT_AANSL_6');
                        }
                    }

                    // Conditie [TVN] toon alleen als in kolom 2 bij TO05 = hotfill boiler (met/zonder elektrisch element)
                    if ( ! heeftHotfillBoiler()) {
                        typesList = typesList.filter(x => x.Id !== 'TAPW-VAT_AANSL_7') // hotfill boiler
                    };

                    break;
                }
                case 'TAPW-VAT_OPSTEL': { // TV15
                    typesList = getOpstelplaatsen(prop, "voorraadvat(en)");
                    break;
                }
                case 'TAPW-DISTR_CIRC': { // TD02
                    typesList = ntaValidation.codedValues(prop);

                    const opwekkerTypeValue = tapwaterLogic.tapwOpwekkersData[0].PropertyDatas['TAPW-OPWEK_TYPE'].Value;
                    const opwekkerType = tapwaterLogic.opwekkerTypes.find(x => x.Id === opwekkerTypeValue);
                    if (opwekkerType) {
                        if (opwekkerType.key1 === 'WUV') { // Conditie TDAC
                            typesList = typesList.filter(function (x) { return x.Id === 'TAPW-DISTR_CIRC_CV'; });
                        }
                        else { // Conditie TDAB
                            typesList = typesList.filter(function (x) { return x.Id === 'TAPW-DISTR_CIRC_GEEN' || x.Id === 'TAPW-DISTR_CIRC_WARM'; });
                        }
                    }
                    // [TAI] verberg als versie gt3.2
                    if (ntabuilding.ntaVersionId >= 300) {
                        typesList = typesList.filter(x => x.Id !== 'TAPW-DISTR_CIRC_CV');
                    }

                    break;
                }
                case 'TAPW-DISTR-BIN_INV': // TD05
                    typesList = tapwaterLogic.tapwDistributieBinnenProperties["TAPW-DISTR-BIN_INV"].Domain.Codes;
                    // Conditie [TDAD]
                    if (!ntaSharedLogic.isUtiliteit() && ntaSharedLogic.isDetailOpname() && !ntaSharedLogic.isGemeenschappelijkInstallatie(tapwaterLogic.tapwData)) {
                        typesList = typesList.filter(function (x) { return x.FilterValue1 === 'TDAD'; });
                    }
                    // Conditie [TDF]
                    else if (ntaSharedLogic.isUtiliteit() && ntaSharedLogic.isBasisOpname()) {
                        typesList = typesList.filter(function (x) { return x.FilterValue2 === 'TDF'; });
                    }
                    // Conditie [TDAE]
                    else if ((!ntaSharedLogic.isUtiliteit() && ntaSharedLogic.isDetailOpname() && ntaSharedLogic.isGemeenschappelijkInstallatie(tapwaterLogic.tapwData)) ||
                        (ntaSharedLogic.isUtiliteit() && ntaSharedLogic.isDetailOpname())) {
                        typesList = typesList.filter(function (x) { return x.FilterValue3 === 'TDAE'; });
                    }
                    break;
                case 'TAPW-DISTR-BIN_ISO_CIRC': // TD09
                    {
                        typesList = tapwaterLogic.tapwDistributieBinnenProperties["TAPW-DISTR-BIN_ISO_CIRC"].Domain.Codes;

                        let invoerCirculatieLeiding = tapwaterLogic.tapwDistributieBinnenData.PropertyDatas['TAPW-DISTR-BIN_INV'];

                        if (invoerCirculatieLeiding.Relevant && (invoerCirculatieLeiding.Value === 'TAPW-DISTR-BIN_INV_1' || invoerCirculatieLeiding.Value === 'TAPW-DISTR-BIN_INV_5' || invoerCirculatieLeiding.Value === 'TAPW-DISTR-BIN_INV_7')) {
                            typesList = typesList.filter(function (x) { return x.FilterValue2 === 'N'; });
                        }
                        else {
                            typesList = typesList.filter(function (x) { return x.FilterValue2 === 'F'; });
                        }

                        break;
                    }
                case 'TAPW-DISTR-BUI_INV': // TD12
                    typesList = ntaValidation.codedValues(prop);

                    // Conditie TDF
                    if (!ntaSharedLogic.isBasisOpname()) {
                        typesList = typesList.filter(function (x) { return x.Id !== 'TAPW-DISTR-BUI_INV_1' && x.Id !== 'TAPW-DISTR-BUI_INV_2'; });
                    }

                    // Conditie TDG
                    if (!ntaSharedLogic.isDetailOpname()) {
                        typesList = typesList.filter(function (x) { return x.Id !== 'TAPW-DISTR-BUI_INV_3' && x.Id !== 'TAPW-DISTR-BUI_INV_4' && x.Id !== 'TAPW-DISTR-BUI_INV_5' && x.Id !== 'TAPW-DISTR-BUI_INV_6'; });
                    }

                    break;
                case 'TAPW-DISTR-BUI_ISO_CIRC': // TD16
                    {
                        typesList = tapwaterLogic.tapwDistributieBuitenProperties["TAPW-DISTR-BUI_ISO_CIRC"].Domain.Codes;

                        let invoerCirculatieLeidingBuiten = tapwaterLogic.tapwDistributieBuitenData.PropertyDatas['TAPW-DISTR-BUI_INV'].Value;

                        if (invoerCirculatieLeidingBuiten && (invoerCirculatieLeidingBuiten === 'TAPW-DISTR-BUI_INV_3' || invoerCirculatieLeidingBuiten === 'TAPW-DISTR-BUI_INV_5')) {
                            typesList = typesList.filter(function (x) { return x.FilterValue2 === 'N'; });
                        }
                        else {
                            typesList = typesList.filter(function (x) { return x.FilterValue2 === 'F'; });
                        }

                        break;
                    }
                case 'TAPW-DOUCHE_TYPE': // TW07
                    {
                        typesList = ntaValidation.codedValues(prop);

                        // Conditie TWE en TWF
                        let wijzeVanAnsluiten = entdata.PropertyDatas['TAPW-DOUCHE_WIJZE'].Value;
                        if (wijzeVanAnsluiten && wijzeVanAnsluiten === 'TAPW-DOUCHE_WIJZE_COL') {
                            typesList = typesList.filter(function (x) { return x.Id !== 'TAPW-DOUCHE_TYPE_PIJP' && x.Id !== 'TAPW-DOUCHE_TYPE_BAK' && x.Id !== 'TAPW-DOUCHE_TYPE_GOOT' && x.Id !== 'TAPW-DOUCHE_TYPE_STORT' && x.Id !== 'TAPW-DOUCHE_TYPE_CIRC'; });
                        }
                        else {
                            typesList = typesList.filter(function (x) { return x.Id !== 'TAPW-DOUCHE_TYPE_MULTI'; });
                        }

                        break;
                    }
                default:
                    typesList = ntaValidation.codedValues(prop);
                    break;
            }

            CheckPropdataInList(prop, entdata, typesList);
            return typesList;
        } //-- end: getCodedValues ------------------------------------------------------------------------//

        tapwaterLogic.getPropData = function (prop, entdata, unitSpecificIfRelevant = true) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = ntaSharedLogic.getPropData(prop, entdata, unitSpecificIfRelevant);
            switch (prop.Id) {
                case 'TAPW-DISTR_POMP_OMSCHR': {
                    propdata.Value = tapwaterLogic.pompNaam(entdata);
                    break;
                }
                case 'TAPW-OPWEK_BRON_BOOS': {
                    //-- nu relatie ophalen met verwarmingsysteem.
                    const systeem = ntaEntityData.getFirstChild(entdata, 'VERW');
                    propdata.Value = systeem && systeem.EntityDataId || null;
                    break;
                }
                case 'TAPW-OPWEK_BRON_BOOS_KOEL': {
                    // Als waarschuwing W031 op het scherm staat, mag er nog niet naar een relatie worden gezocht.
                    if (tapwaterLogic.w031Open || propdata.Value === 'TAPW-OPWEK_BRON_BOOS_KOEL_GEEN' ) {
                        break;
                    }
                    //-- nu relatie ophalen met koelsysteem.
                    const systeem = ntaEntityData.getFirstChild(entdata, 'KOEL');
                    propdata.Value = systeem && systeem.EntityDataId || null;
                    break;
                }
                case 'TAPW-OPWEK_WP_KOELSYS': {
                    if (propdata.Value === 'TAPW-OPWEK_WP_KOELSYS_GEEN') {
                        break;
                    }
                    //-- anders relatie ophalen met koelsysteem.
                    const systeem = ntaEntityData.getFirstChild(entdata, 'KOEL');
                    propdata.Value = systeem && systeem.EntityDataId || null;
                    break;
                }
                case 'TAPW-OPWEK_WP_VENTSYS': {
                    //-- nu relatie ophalen met ventilatiesysteem.
                    const systeem = ntaEntityData.getFirstChild(entdata, 'VENT');
                    propdata.Value = systeem && systeem.EntityDataId || null;
                    break;
                }
                case 'TAPW-OPWEK_WARMTE_UIT_VERW': {
                    //-- nu relatie ophalen met verwarmingsysteem.
                    const systeem = ntaEntityData.getFirstChild(entdata, 'VERW');
                    propdata.Value = systeem && systeem.EntityDataId || null;
                    break;
                }
                case 'TAPW-OPWEK_VERBR_OPS': {
                    const zone = ntaEntityData.getFirstChild(entdata, 'RZ');
                    if (zone) {
                        propdata.Value = zone.EntityDataId;
                    }
                    break;
                }
                case 'TAPW-OPWEK_OPSTEL': {
                    const zone = ntaEntityData.getFirstParent(entdata, 'RZ');
                    if (zone) {
                        propdata.Value = zone.EntityDataId;
                    }
                    break;
                }
                case 'TAPW-VAT_OPSTEL': {
                    const zone = ntaEntityData.getFirstParent(entdata, 'RZ');
                    if (zone) {
                        propdata.Value = zone.EntityDataId;
                    }
                    break;
                }
            }

            return propdata;
        } //-- end: getPropData ------------------------------------------------------------------------//


        tapwaterLogic.getProduct = function (prop, entdata) {
            if (!prop || !entdata) {
                return;
            }
            let product = _getListItem(prop, entdata);
            if (product) {
                return product;
            }
            return;
        } //-- end: getProduct  ------------------------------------------------------------------------//

        tapwaterLogic.getProductWithoutFilter = function (prop, entdata) {
            if (!prop || !entdata) {
                return;
            }
            var propData = entdata.PropertyDatas[prop.Id];
            let tmpId = parseInt(propData.Value);
            let product = tapwaterLogic.selectionTable.find(function (x) { return x.Id === tmpId; });
            if (product) {
                return product;
            }
            return;
        } //-- end: getProduct  ------------------------------------------------------------------------//


        tapwaterLogic.propertyHeaderIsHidden = function (prop, entdataContainer) {
            if (!prop || !entdataContainer) {
                return;
            }

            if (prop.Id === 'TAPW-DISTR-EIG_DEK') { // TD22
                if (dekkingIsHidden()) {
                    return true;
                }
            }
            else if (prop.Id === 'TAPW-DISTR-EIG_LAB_CON') { // TD23
                if (dekkingIsHidden()) {
                    return true;
                }
            }
            else if (prop.Id === 'TAPW-DISTR-EIG_LAB_ISO') { // TD24
                if (labdaIsolatieIsHidden()) {
                    return true;
                }
            }
            else if (prop.Id === 'TAPW-DISTR-EIG_LAB_LEI') { // HD25
                if (labdaLeidingIsHidden()) {
                    return true;
                }
            }
            else if (prop.Id === 'TAPW-DISTR_CIRC_VERM_NON') { // TD27
                return !conditieTDU();
            }
            else if (prop.Id === 'TAPW-DISTR_CIRC_VERM') { // TD28
                return !conditieTDV();
            }
            else if (prop.Id === 'TAPW-DISTR_CIRC_EEI_NON') { // TD29
                return !conditieTDU();
            }
            else if (prop.Id === 'TAPW-DISTR_CIRC_EEI') { // TD30
                return !conditieTDX();
            }
            else if (prop.Id === 'TAPW-AFG-LEI_LEN_BAD') { // TA08
                if (tapwaterLogic.leidinggegevensIsHidden()) {
                    return true;
                }
                return aantalBadruimtenIsZero(); // Conditie TAF
            }
            else if (prop.Id === 'TAPW-AFG-LEI_LEN_AANR' || prop.Id === 'TAPW-AFG-LEI_DIA_AANR') { // TA09, TA10
                if (tapwaterLogic.leidinggegevensIsHidden()) {
                    return true;
                }
                if (prop.Id === 'TAPW-AFG-LEI_DIA_AANR' && !conditieTAI()) { // Conditie TAI
                    return true;
                }
                return aantalKeukensIsZero(); // Conditie TAG
            }
            else if (prop.Id === 'TAPW-DOUCHE-AANG_DIU') { // TW16
                if (!ntaSharedLogic.isUtiliteit()) {
                    return true;
                }

                // Conditie TWR
                if (entdataContainer.EntityDataId === tapwaterLogic.tapwDoucheData[0].EntityDataId) {
                    return false;
                }
                return true;
            }

            return false;
        } //-- end: propertyHeaderIsHidden ------------------------------------------------------------------------//

        tapwaterLogic.isHidden = function (prop, entdata) {
            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            const propdata = prop.getData(entdata);

            //onderstaande variable komen steeds voor bij de check voor isHidden, vandaar hierboven gedeclareerd
            let opwekkerTypeValue, opwekkerInvoerValue, opwekkerType;
            if (entdata.EntityId === 'TAPW-OPWEK') {
                opwekkerTypeValue = entdata.PropertyDatas['TAPW-OPWEK_TYPE'].Value;
                opwekkerInvoerValue = entdata.PropertyDatas['TAPW-OPWEK_INV'].Value;
                if (opwekkerTypeValue) {
                    opwekkerType = tapwaterLogic.opwekkerTypes.find(function (x) { return x.Id === opwekkerTypeValue; });
                }
            }

            //onderstaande variabele komt steeds terug bij voorraadvaten
            let vatInvoerValue;
            if (entdata.EntityId === 'TAPW-VAT') {
                vatInvoerValue = entdata.PropertyDatas['TAPW-VAT_INV'].Value;
            }

            let showit = false;
            let relevant = null;

            switch (prop.Id) {
                case 'TAPW-OPWEK_INDIR': { // TO06
                    showit = true;

                    // Conditie [TOAV]
                    if (opwekkerType && opwekkerType.indirect === '' ||
                        opwekkerType && opwekkerType.key1 === 'WP' && opwekkerInvoerValue === 'TAPW-OPWEK_INV_PROD') {
                        showit = false;
                    }

                    // Conditie [TOCU] verberg indien TO05 = elektrisch element OF hotfill boiler (met/zonder elektrisch element)
                    if (new Set(['TAPW-OPWEK_TYPE_22', 'TAPW-OPWEK_TYPE_23']).has(entdata.PropertyDatas['TAPW-OPWEK_TYPE'].Value)) {
                        showit = false;
                    }

                    relevant = true;

                    if (!showit) {
                        // Rekenen met deze opties als het veld hidden is
                        if (opwekkerType.key1 === 'WP') {
                            ntaEntityData.saveprop(propdata, 'TAPW-OPWEK_INDIR_GEINT');
                        } else {
                            ntaEntityData.saveprop(propdata, 'TAPW-OPWEK_INDIR_GEEN');
                        }
                    }
                    break;
                }
                case 'TAPW-OPWEK_GEM': { // TO09
                    showit = true;

                    // Conditie [TOBA]
                    if (opwekkerTypeValue === "TAPW-OPWEK_TYPE_4") { //TO05='externe warmtelevering'
                        prop.Name = "installatie met centrale of individuele aflevering";
                        prop.Domain.Codes.find(x => x.Id === "TAPW-OPWEK_GEM_WEL").Value = "installatie met centrale aflevering";
                        prop.Domain.Codes.find(x => x.Id === "TAPW-OPWEK_GEM_NIET").Value = "installatie met individuele aflevering";
                    } else {
                        prop.Name = "gemeenschappelijke of niet-gemeenschappelijke installatie";
                        prop.Domain.Codes.find(x => x.Id === "TAPW-OPWEK_GEM_WEL").Value = "gemeenschappelijke installatie";
                        prop.Domain.Codes.find(x => x.Id === "TAPW-OPWEK_GEM_NIET").Value = "niet-gemeenschappelijke installatie";
                    }

                    // Conditie [TOAP]
                    if (tapwaterLogic.opwekkerNr(entdata) > 1) {
                        showit = false;
                    }

                    break;
                }
                case 'TAPW-OPWEK_A': { // TO10

                    // Conditie TOAP
                    if (tapwaterLogic.opwekkerNr(entdata) > 1) {
                        showit = false;
                        relevant = showit;
                        break;
                    }

                    // Conditie TOI
                    const gemeenschappelijk = entdata.PropertyDatas['TAPW-OPWEK_GEM'].Value;
                    const propGemeenschappelijkSysteem = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_GEM'];
                    if (gemeenschappelijk === 'TAPW-OPWEK_GEM_WEL' && !tapwaterLogic.isHidden(propGemeenschappelijkSysteem, entdata)) {
                        showit = true;
                    }

                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_FUNC': { // TO21
                    // Conditie [TOAH]
                    showit = !!opwekkerType && opwekkerType.key4 === 'combi';
                    if (!showit) { //indien veld verborgen 'Solotoestel' doorgeven
                        tapwaterLogic.saveValue(prop, entdata, "TAPW-OPWEK_FUNC_SOLO");
                    }
                    relevant = true; // altijd true
                    break;
                }
                case 'TAPW-OPWEK_EMISSIE': { // TO17
                    // Conditie [TOJ]
                    if (opwekkerType && opwekkerType.key1 === 'BM') {
                        showit = true;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_POS': { // TO16
                    // Conditie [TOL]
                    let indirVatValue = entdata.PropertyDatas['TAPW-OPWEK_INDIR'].Value;
                    let combiValue = entdata.PropertyDatas['TAPW-OPWEK_FUNC'].Value;

                    if (opwekkerType && opwekkerInvoerValue) {
                        let bSitA = opwekkerInvoerValue === 'TAPW-OPWEK_INV_FORF' && (opwekkerType.key1 === 'BM');
                        let bSitB = opwekkerInvoerValue === 'TAPW-OPWEK_INV_FORF' && opwekkerType.key1 === 'ZB' && opwekkerType.key3 === 'GAS';
                        let bSitC = opwekkerInvoerValue === 'TAPW-OPWEK_INV_FORF' && indirVatValue === 'TAPW-OPWEK_INDIR_WEL' && (opwekkerType.key1 === 'CVG' || opwekkerType.key1 === 'CVO');
                        let bSitD = ['TAPW-OPWEK_INV_FORF', 'TAPW-OPWEK_INV_PROD'].includes(opwekkerInvoerValue) && opwekkerType.key1 === 'CVG' && combiValue === 'TAPW-OPWEK_FUNC_COMBI';

                        if (bSitA || bSitB || bSitC || bSitD) {
                            showit = true;
                        }
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_BRON_POMP': { // TO19
                    // Conditie [TOM]
                    showit = opwekkerType && opwekkerType.key1 === 'WP';
                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_REGIO': { // TO20
                    // Conditie [TON]
                    if (opwekkerType && opwekkerType.key1 === 'EW' && opwekkerInvoerValue === 'TAPW-OPWEK_INV_PROD') {
                        showit = true;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_VERM': { // TO23
                    // Conditie [TOP]
                    const [opwekker1] = tapwaterLogic.tapwOpwekkersData;
                    const heeftOpwekkerVanVerwarmingssysteem = opwekker1.PropertyDatas['TAPW-OPWEK_TYPE'].Value === 'TAPW-OPWEK_TYPE_24'; // TO05 = opwekker van verwarmingssysteem
                    if (!heeftHotfillBoiler() && !heeftOpwekkerVanVerwarmingssysteem) {
                        const bMeerdereOpwekkers = tapwaterLogic.tapwOpwekkersData.length > 1;
                        const bProduct = opwekkerInvoerValue === 'TAPW-OPWEK_INV_PROD';
                        const bForfOfProduct = opwekkerInvoerValue === 'TAPW-OPWEK_INV_PROD' || opwekkerInvoerValue === 'TAPW-OPWEK_INV_FORF';
                        let bGeenVermogenSelectietabel = true;
                        const bEigenRendement = opwekkerInvoerValue === 'TAPW-OPWEK_INV_EIG_REND';
                        const bronWarmtepomp = _getListItem('TAPW-OPWEK_BRON_POMP', entdata); //TO19
                        if (bForfOfProduct) {
                            // nu moet ik het item uit de selectietabel ophalen als dat al is ingevuld.
                            const toestelItem = _getListItem('TAPW-OPWEK_TOES', entdata);
                            if (toestelItem) {
                                bGeenVermogenSelectietabel = toestelItem.P === null;
                            }
                        }

                        if ((bMeerdereOpwekkers && bForfOfProduct && bGeenVermogenSelectietabel) ||
                            (bMeerdereOpwekkers && bEigenRendement) ||
                            (opwekkerType && opwekkerType.key1 === 'WP' && bronWarmtepomp && bronWarmtepomp.Value === 'ventilatieretourlucht' && !bProduct)) {
                            showit = true;
                        }
                    }

                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_VERM_NON': { // TO45
                    // Conditie [TOAU]
                    const [opwekker1] = tapwaterLogic.tapwOpwekkersData;
                    const heeftOpwekkerVanVerwarmingssysteem = opwekker1.PropertyDatas['TAPW-OPWEK_TYPE'].Value === 'TAPW-OPWEK_TYPE_24'; // TO05 = opwekker van verwarmingssysteem
                    if (!heeftHotfillBoiler() && !heeftOpwekkerVanVerwarmingssysteem) {
                        const bMeerdereOpwekkers = tapwaterLogic.tapwOpwekkersData.length > 1;
                        const bProduct = opwekkerInvoerValue === 'TAPW-OPWEK_INV_PROD';
                        let bVermogenSelectietabel = false;
                        const bronWarmtepomp = _getListItem('TAPW-OPWEK_BRON_POMP', entdata); //TO19
                        if (bProduct) {
                            // nu moet ik het item uit de selectietabel ophalen als dat al is ingevuld.
                            const toestelItem = _getListItem('TAPW-OPWEK_TOES', entdata);
                            if (toestelItem) {
                                bVermogenSelectietabel = toestelItem.P !== null;
                            }
                        }
                        if ((bMeerdereOpwekkers && bProduct && bVermogenSelectietabel) ||
                            (opwekkerType && opwekkerType.key1 === 'WP' && bronWarmtepomp && bronWarmtepomp.Value === 'ventilatieretourlucht' && bProduct)) {
                            showit = true;
                        }
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_TOES': { // TO22
                    // Conditie [TOAE]
                    if (opwekkerType) {
                        showit = opwekkerInvoerValue === 'TAPW-OPWEK_INV_PROD'
                            || (opwekkerInvoerValue === 'TAPW-OPWEK_INV_FORF' && opwekkerType.key5 === '');

                        // forfaitaire toestellen krijgen hun rendement uit de selectietabel, en moeten dus altijd relevant zijn
                        relevant = showit
                            || (opwekkerInvoerValue === 'TAPW-OPWEK_INV_FORF' && opwekkerType.key5 !== '');
                    }
                    break;
                }
                case 'TAPW-OPWEK_CW': { // TO12
                    // Conditie TOQ
                    let propIndirect = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_INDIR'];
                    let indirect = entdata.PropertyDatas['TAPW-OPWEK_INDIR'].Value;
                    if (opwekkerType && opwekkerType.key6 === 'Cwgen' && opwekkerInvoerValue && opwekkerInvoerValue === 'TAPW-OPWEK_INV_FORF' && (tapwaterLogic.isHidden(propIndirect, entdata) || !tapwaterLogic.isHidden(propIndirect, entdata) && indirect === 'TAPW-OPWEK_INDIR_GEEN')) {
                        showit = true;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_BRON_BOOS': { // TO13
                    // Conditie TOG
                    if (opwekkerType && opwekkerType.key1 === 'BWP') {
                        showit = true;
                    }
                    relevant = showit;
                    //-- als deze property niet meer relevant is, moet de eventuele relatie verwijderd worden.
                    if (!relevant) {
                        deleteKoppelingOpwekkerInstallSysteem(entdata, 'VERW', prop);
                    }
                    break;
                }
                case 'TAPW-OPWEK_BRON_BOOS_KOEL': { // TO46
                    // Conditie TOBB
                    const toestel = _getListItem('TAPW-OPWEK_TOES', entdata);

                    const sitA = !!opwekkerType && opwekkerType.key1 === 'BWP';
                    const sitB = opwekkerInvoerValue === 'TAPW-OPWEK_INV_PROD' && !!toestel && !!toestel.CCode; // toestel met W_code en C_code
                    const sitC = !['TAPW-OPWEK_INV_PROD', 'TAPW-OPWEK_INV_FORF'].includes(opwekkerInvoerValue);

                    showit = sitA && (sitB || sitC);

                    relevant = showit && propdata.Value !== 'TAPW-OPWEK_BRON_BOOS_KOEL_GEEN';

                    //-- als deze property niet meer relevant is, moet de eventuele relatie verwijderd worden.
                    if (!relevant) {
                        deleteKoppelingOpwekkerInstallSysteem(entdata, 'KOEL', prop);
                    }
                    break;
                }
                case 'TAPW-OPWEK_WP_KOELSYS': { // TO71
                    // Conditie [TOCN]
                    const propToestel = ntabuilding.properties['TAPW-OPWEK_TOES'];
                    const toestel = _getListItem(propToestel, entdata);

                    showit = !!toestel && toestel.CombiMetKoeling; // combi  met koeling is anders dan toestel met W_code en C_code. Combi met koeling is voor WPU toestellen

                    relevant = showit && propdata.Value !== 'TAPW-OPWEK_WP_KOELSYS_GEEN';

                    //-- als deze property niet meer relevant is, moet de eventuele relatie verwijderd worden.
                    if (!relevant) {
                        deleteKoppelingOpwekkerInstallSysteem(entdata, 'KOEL', prop);
                    }

                    break;
                }
                case 'TAPW-OPWEK_WP_VENTSYS': { // TO52
                    // Conditie TOBM
                    if (opwekkerType && opwekkerType.key1 === 'WP') {
                        const bronWarmtepomp = _getListItem('TAPW-OPWEK_BRON_POMP', entdata);
                        if (bronWarmtepomp && bronWarmtepomp.Value === 'ventilatieretourlucht') {
                            showit = true;
                        }
                        if (ntabuilding.ntaVersionId >= 200 && bronWarmtepomp && bronWarmtepomp.Value === 'combinatie buitenlucht en ventilatieretourlucht') {
                            showit = true;
                        }
                    }
                    relevant = showit;
                    //-- als deze property niet meer relevant is, moet de eventuele relatie verwijderd worden.
                    if (!relevant) {
                        deleteKoppelingOpwekkerInstallSysteem(entdata, 'VENT');
                    }

                    break;
                }
                case 'TAPW-OPWEK_WARMTE_UIT_VERW': { // TO58
                    // Conditie [TOBV]
                    showit = opwekkerType && opwekkerType.key1 === 'WUV';
                    relevant = showit;

                    //-- als deze property niet meer relevant is, moet de eventuele relatie verwijderd worden.
                    if (!relevant) {
                        deleteKoppelingOpwekkerInstallSysteem(entdata, 'VERW', prop);
                    }
                    break;
                }
                case 'TAPW-OPWEK_VERW-OPWEK': { // TO76
                    // Conditie [TOCW] Toon alleen als key 1 van table13_0 op de rij van opwekker gekozen in TO05 = 'OVV'
                    showit = opwekkerType && opwekkerType.key1 === 'OVV';

                    //-- als deze property niet meer relevant is, moet de eventuele relatie verwijderd worden.
                    if (!showit) {
                        deleteKoppelingOpwekkerInstallSysteem(entdata, 'VERW', prop);
                    } else {
                        //-- als deze property wel relevant is, moet de eventuele relatie hersteld worden.
                        //-- time.delayAction omdat de relevantie eerst opgeslagen moet zijn.
                        time.delayActions(() => saveKoppelingOpwekkerInstallSysteem(propdata, entdata, 'VERW'));
                    }
                    break;
                }
                case 'TAPW-OPWEK_FABR_GAS': { // TO14
                    showit = conditieTOH(opwekkerType, entdata);
                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_VOL': { // TO15
                    showit = conditieTOH(opwekkerType, entdata);
                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_OPSTEL': { // TO53
                    showit = conditieTOH(opwekkerType, entdata);
                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_FABR': { // TO54
                    // Conditie [TOBO]
                    const isForfaitair = opwekkerInvoerValue === 'TAPW-OPWEK_INV_FORF';
                    const isCVgas = !!opwekkerType && opwekkerType.key1 === 'CVG';
                    const isWPElek = !!opwekkerType && opwekkerType.key1 === 'WP' && opwekkerType.key3 === 'EL';
                    const isVerwarmingEnTapwater = entdata.PropertyDatas['TAPW-OPWEK_FUNC'].Value === 'TAPW-OPWEK_FUNC_COMBI';

                    const bSitA = isForfaitair && isCVgas && isVerwarmingEnTapwater;
                    const bSitB = isForfaitair && isWPElek && isVerwarmingEnTapwater && ntabuilding.ntaVersionId < 200;

                    relevant = bSitA || bSitB;
                    showit = relevant;

                    if (relevant) {
                        if (tapwaterLogic.bouwjaar >= 2015) {
                            const inOfNa2015 = 'TAPW-OPWEK_FABR_L'; // 'fabricagejaar toestel 2015 en nieuwer'
                            if (propdata.Value !== inOfNa2015) {
                                propdata.Relevant = relevant;
                                tapwaterLogic.saveValue(prop, entdata, inOfNa2015);
                            }
                            showit = false;
                        }
                    }
                    break;
                }

                case 'TAPW-OPWEK_FABR_WKK': { // TO18
                    // Conditie TOAJ
                    if (opwekkerType && opwekkerType.key1 === 'WKK' && opwekkerInvoerValue === 'TAPW-OPWEK_INV_FORF') {
                        if (tapwaterLogic.bouwjaar < 2015) {
                            showit = true;
                            relevant = true;
                        }
                        else {
                            // Verborgen maar blijft relevant
                            if (propdata.Value !== 'TAPW-OPWEK_FABR_WKK_NIEUW') {
                                propdata.Value = 'TAPW-OPWEK_FABR_WKK_NIEUW';
                                ntaEntityData.saveprop(propdata);
                                showit = false;
                                relevant = true;
                            }

                            showit = false;
                            relevant = true;
                        }
                    }
                    else {
                        showit = false;
                        relevant = false;
                    }
                    break;
                }
                case 'TAPW-OPWEK_WAAK': { // TO24
                    // Conditie TOR
                    const propIndirectVerwarmdeVoorraadvaten = ntabuilding.properties['TAPW-OPWEK_INDIR'];
                    const indirectVerwarmdeVoorraadvaten = propIndirectVerwarmdeVoorraadvaten.getValue(entdata);
                    const functieOpwekker = entdata.PropertyDatas['TAPW-OPWEK_FUNC'].Value;

                    const toestel = _getListItem('TAPW-OPWEK_TOES', entdata);
                    const toestelValue = toestel && toestel.Value;

                    if (indirectVerwarmdeVoorraadvaten === 'TAPW-OPWEK_INDIR_WEL'
                        && !tapwaterLogic.isHidden(propIndirectVerwarmdeVoorraadvaten, entdata)
                        && opwekkerInvoerValue === 'TAPW-OPWEK_INV_FORF'
                        && functieOpwekker === 'TAPW-OPWEK_FUNC_SOLO') {

                        if (opwekkerTypeValue === 'TAPW-OPWEK_TYPE_13' ||
                            opwekkerTypeValue === 'TAPW-OPWEK_TYPE_12' && (toestelValue === 'VR ketel' || toestelValue === 'conventionele ketel') ||
                            opwekkerTypeValue === 'TAPW-OPWEK_TYPE_19' && (toestelValue === 'naverwarmer - VR ketel' || toestelValue === 'naverwarmer - conventionele ketel')) {
                            showit = true;
                        }
                    }

                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_BEHOEFTE': { // TO43
                    // Conditie TOAY
                    if (tapwaterLogic.opwekkerNr(entdata) === 1) {
                        showit = true;
                    }
                    relevant = showit;
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'TAPW-OPWEK_GELEV': { // TO44
                    showit = true;
                    // Conditie TOAW
                    if (opwekkerType && (opwekkerType.key1 === 'EW' || opwekkerType.key1 === 'ONB') || tapwaterLogic.tapwOpwekkersData.length === 1) {
                        showit = false;
                    }
                    relevant = showit;
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'TAPW-OPWEK_LVOLSTR': { // TO68
                    // Conditie TOAR
                    let propBronWarmtepomp = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_BRON_POMP'];
                    let bronWarmtepomp = _getListItem(propBronWarmtepomp, entdata);

                    if (opwekkerInvoerValue && opwekkerInvoerValue !== 'TAPW-OPWEK_INV_FORF' && opwekkerInvoerValue !== 'TAPW-OPWEK_INV_PROD' && !tapwaterLogic.isHidden(propBronWarmtepomp, entdata) && bronWarmtepomp && bronWarmtepomp.Value === 'ventilatieretourlucht') {
                        showit = true;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_LVOLSTR_NON': { // TO69
                    // Conditie TOAS
                    let propBronWarmtepomp = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_BRON_POMP'];
                    let bronWarmtepomp = _getListItem(propBronWarmtepomp, entdata);

                    if (opwekkerInvoerValue && opwekkerInvoerValue === 'TAPW-OPWEK_INV_PROD' && !tapwaterLogic.isHidden(propBronWarmtepomp, entdata)
                        && bronWarmtepomp && bronWarmtepomp.Value === 'ventilatieretourlucht') {
                        showit = true;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_FWBUITENL': { // TO74
                    // Conditie [TOBQ] toon versie=ge3-2 AND TO05 = 'warmtepomp - elektrisch' AND TO19 = 'combinatie buitenlucht en ventilatieretourlucht' AND TO07 ≠ forfaitair/productspecifiek
                    const propBronWarmtepomp = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_BRON_POMP'];
                    const bronWarmtepomp = propBronWarmtepomp.getData(entdata);

                    showit =
                        opwekkerTypeValue === 'TAPW-OPWEK_TYPE_1' &&
                        bronWarmtepomp && bronWarmtepomp.Value === 'VERW-OPWEK_POMP_BUVE' &&
                        opwekkerInvoerValue !== 'TAPW-OPWEK_INV_FORF' &&
                        opwekkerInvoerValue !== 'TAPW-OPWEK_INV_PROD';

                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_CWMIXEDAIR_NON': // TO73
                case 'TAPW-OPWEK_FWBUITENL_NON': { // TO75
                    // Conditie [TOBR] toon versie=ge3-2 AND TO05 = 'warmtepomp - elektrisch' AND TO19 = 'combinatie buitenlucht en ventilatieretourlucht' AND TO07 = forfaitair/productspecifiek
                    const propBronWarmtepomp = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_BRON_POMP'];
                    const bronWarmtepomp = propBronWarmtepomp.getData(entdata);

                    showit =
                        opwekkerTypeValue === 'TAPW-OPWEK_TYPE_1' &&
                        bronWarmtepomp && bronWarmtepomp.Value === 'VERW-OPWEK_POMP_BUVE' &&
                        (opwekkerInvoerValue === 'TAPW-OPWEK_INV_FORF' ||
                            opwekkerInvoerValue === 'TAPW-OPWEK_INV_PROD');

                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_REND_NON': { // TO27
                    // Conditie TOT
                    showit = (opwekkerInvoerValue === 'TAPW-OPWEK_INV_FORF' || opwekkerInvoerValue === 'TAPW-OPWEK_INV_PROD')
                        && opwekkerType && (opwekkerType.key3 === 'GAS' || opwekkerType.key3 === 'BM');
                    relevant = showit;
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'TAPW-OPWEK_COP_NON': { // TO28
                    // Conditie TOU
                    showit = (opwekkerInvoerValue === 'TAPW-OPWEK_INV_FORF' || opwekkerInvoerValue === 'TAPW-OPWEK_INV_PROD')
                        && opwekkerType && opwekkerType.key3 === 'EL' && opwekkerType.key1 !== 'BWP';
                    relevant = showit;
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'TAPW-OPWEK_REND': { // TO29
                    // Conditie TOV
                    if (opwekkerInvoerValue && opwekkerInvoerValue !== 'TAPW-OPWEK_INV_FORF' && opwekkerInvoerValue !== 'TAPW-OPWEK_INV_PROD' && opwekkerType && (opwekkerType.key3 === 'GAS' || opwekkerType.key3 === 'BM')) {
                        showit = true;
                    }
                    relevant = showit;
                    // Bij externe warmtelevering is ηWgen (etaWgen) altijd 1.
                    if (!relevant && opwekkerType && opwekkerType.key1 === 'EW') {
                        if (propdata.Value !== '1,000') {
                            propdata.Value = '1,000';
                            ntaEntityData.saveprop(propdata);
                        }
                        relevant = true;
                    }
                    break;
                }
                case 'TAPW-OPWEK_COP':   // TO30
                case 'TAPW-OPWEK_FPRAC': { // TO70
                    // Conditie TOW
                    showit = opwekkerInvoerValue !== 'TAPW-OPWEK_INV_FORF' && opwekkerInvoerValue !== 'TAPW-OPWEK_INV_PROD' && opwekkerType && opwekkerType.key3 === 'EL' && opwekkerType.key1 !== 'BWP';
                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_COP_BWP_NON': { // TO62
                    // Conditie TOCC
                    showit = opwekkerType && opwekkerType.key1 === 'BWP' && ['TAPW-OPWEK_INV_PROD', 'TAPW-OPWEK_INV_FORF'].includes(opwekkerInvoerValue);
                    relevant = showit;
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'TAPW-OPWEK_COP_BWP': { // TO63
                    // Conditie TOCD
                    showit = opwekkerType && opwekkerType.key1 === 'BWP' && !['TAPW-OPWEK_INV_PROD', 'TAPW-OPWEK_INV_FORF'].includes(opwekkerInvoerValue);
                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_OMZET_NON': { // TO60
                    // Conditie TOCA
                    showit = opwekkerType && opwekkerType.key1 === 'WKK' && ['TAPW-OPWEK_INV_PROD', 'TAPW-OPWEK_INV_FORF'].includes(opwekkerInvoerValue);
                    relevant = showit;
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'TAPW-OPWEK_OMZET': { // TO61
                    // Conditie TOCB
                    showit = opwekkerType && opwekkerType.key1 === 'WKK' && !['TAPW-OPWEK_INV_PROD', 'TAPW-OPWEK_INV_FORF'].includes(opwekkerInvoerValue);
                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_REND_WARM_NON': { // TO39
                    // Conditie [TOAG]
                    showit = opwekkerInvoerValue === 'TAPW-OPWEK_INV_FORF' && opwekkerType && opwekkerType.key1 === 'BWP';
                    relevant = showit;
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'TAPW-OPWEK_REND_WARM': { // TO40
                    // Conditie [TOAT]
                    showit = false;
                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_PLS': { // TO64
                    // Conditie [TOCD]
                    showit = opwekkerType && opwekkerType.key1 === 'BWP' && !['TAPW-OPWEK_INV_PROD', 'TAPW-OPWEK_INV_FORF'].includes(opwekkerInvoerValue);
                    break;
                }
                case 'TAPW-OPWEK_PLS_NON': { // TO65
                    // Conditie [TOCF]
                    showit = opwekkerType && opwekkerType.key1 === 'BWP' && opwekkerInvoerValue === 'TAPW-OPWEK_INV_PROD';
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'TAPW-OPWEK_PRIM_NON': { // TO31
                    // Conditie TOX
                    if (opwekkerInvoerValue && (opwekkerInvoerValue === 'TAPW-OPWEK_INV_FORF' || opwekkerInvoerValue === 'TAPW-OPWEK_INV_PROD') && opwekkerType && opwekkerType.key3 === 'primaire energiefactor') {
                        showit = true;
                    }
                    relevant = showit;
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    // De Usage van HO31 is 3, dus als hij een waarde van de rekenkern moet ontvangen, moet hij toch niet relevant zijn.
                    if (relevant) {
                        if (opwekkerType && opwekkerType.key1 !== 'EW') {
                            relevant = false;
                        }
                    }
                    break;
                }
                case 'TAPW-OPWEK_PRIM': { // TO32
                    // Conditie TOY
                    if (opwekkerInvoerValue && opwekkerInvoerValue !== 'TAPW-OPWEK_INV_FORF' && opwekkerInvoerValue !== 'TAPW-OPWEK_INV_PROD' && opwekkerType && opwekkerType.key3 === 'primaire energiefactor') {
                        showit = true;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_FPREN_NON': // TO47
                case 'TAPW-OPWEK_KCO_NON': { // TO49
                    // Conditie TOBG
                    if (opwekkerType && opwekkerType.key1 === 'EW' && opwekkerInvoerValue && (opwekkerInvoerValue === 'TAPW-OPWEK_INV_PROD' || opwekkerInvoerValue === 'TAPW-OPWEK_INV_FORF')) {
                        showit = true;
                    }
                    relevant = showit;
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'TAPW-OPWEK_FPREN': // TO48
                case 'TAPW-OPWEK_KCO': { // TO48
                    // Conditie TOBH
                    if (opwekkerType && opwekkerType.key1 === 'EW' && opwekkerInvoerValue && (opwekkerInvoerValue !== 'TAPW-OPWEK_INV_PROD' && opwekkerInvoerValue !== 'TAPW-OPWEK_INV_FORF')) {
                        showit = true;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_NEN7125VERKL': { // TO51
                    // Conditie TOBL
                    if (opwekkerType && opwekkerType.key1 === 'EW') {
                        //-- alleen zichtbaar bij eigen waarde invoer
                        if (opwekkerInvoerValue && (opwekkerInvoerValue !== 'TAPW-OPWEK_INV_PROD' && opwekkerInvoerValue !== 'TAPW-OPWEK_INV_FORF')) {
                            showit = true;
                        }
                        //-- ongeacht of deze property zichtbaar is of niet is hij alleen relevant als er sprake is van externe warmtelevering
                        relevant = true;
                    }
                    break;
                }
                case 'TAPW-OPWEK_ENER_NON': { // TO33
                    // Conditie [TOZ]
                    showit = ['TAPW-OPWEK_INV_PROD', 'TAPW-OPWEK_INV_FORF', 'TAPW-OPWEK_INV_EIG_REND'].includes(opwekkerInvoerValue);
                    relevant = showit;
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]

                    if (relevant) {
                        // Zie ook setEnergieFractie()
                        const opwekker1 = tapwaterLogic.tapwOpwekkersData[0];
                        const opwekker2 = tapwaterLogic.tapwOpwekkersData[1];

                        const invoerOpwekker1 = opwekker1 && opwekker1.PropertyDatas['TAPW-OPWEK_INV'].Value;

                        relevant = tapwaterLogic.tapwOpwekkersData.length === 1 // dan is de energiefractie 1, en moet deze naar de server gestuurd worden. Relevant laten dus.
                            || (tapwaterLogic.tapwOpwekkersData.length === 2 // dan is de energiefractie 1 - {energiefractieOpwekker1}, en moet deze naar de server gestuurd worden. Relevant laten dus.
                                && ['TAPW-OPWEK_INV_EIG_REND_FRAC', 'TAPW-OPWEK_INV_EIG_REND_FRAC_HULP'].includes(invoerOpwekker1)
                                && entdata === opwekker2);
                    }
                    break;
                }
                case 'TAPW-OPWEK_ENER': { // TO34
                    // Conditie TOAA
                    if (opwekkerInvoerValue && (opwekkerInvoerValue === 'TAPW-OPWEK_INV_EIG_REND_FRAC' || opwekkerInvoerValue === 'TAPW-OPWEK_INV_EIG_REND_FRAC_HULP')) {
                        showit = true;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_HULP_NON': { // TO35
                    // Conditie [TOAC]
                    const toac = !opwekkerType || (opwekkerType.key1 !== 'EW' && opwekkerType.key1 !== 'BWP');

                    // Conditie [TOAB]
                    const toab = ['TAPW-OPWEK_INV_FORF', 'TAPW-OPWEK_INV_PROD', 'TAPW-OPWEK_INV_EIG_REND', 'TAPW-OPWEK_INV_EIG_REND_FRAC'].includes(opwekkerInvoerValue);

                    showit = toab && toac;
                    relevant = showit;
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]

                    if (relevant) {
                        if (['TAPW-OPWEK_INV_FORF', 'TAPW-OPWEK_INV_EIG_REND', 'TAPW-OPWEK_INV_EIG_REND_FRAC'].includes(opwekkerInvoerValue)) {
                            relevant = false;
                        } else if (opwekkerInvoerValue === 'TAPW-OPWEK_INV_PROD') {
                            const unitSpecificPropdatas = ntaSharedLogic.getUnitSpecificResultPropdatas(prop, entdata);
                            ntaEntityData.saveprops([propdata, ...unitSpecificPropdatas], ntaRounding.roundAndAddZerosNewValue(prop, 0));
                        }
                    }
                    break;
                }
                case 'TAPW-OPWEK_HULP': { // TO36
                    showit = false;
                    let toac = true;
                    let toad = false;

                    // Conditie TOAC
                    if (opwekkerType && (opwekkerType.key1 === 'EW' || opwekkerType.key1 === 'BWP')) {
                        toac = false;
                    }

                    // Conditie TOAD
                    if (opwekkerInvoerValue && opwekkerInvoerValue === 'TAPW-OPWEK_INV_EIG_REND_FRAC_HULP') {
                        toad = true;
                    }

                    if (toad && toac) {
                        showit = true;
                    }

                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_VERBR': { // TO55
                    // Conditie TOBQ
                    showit = false;

                    let propToestel = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_TOES'];
                    let product = tapwaterLogic.getProductWithoutFilter(propToestel, entdata);
                    let propPositie = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_POS'];
                    let positie = entdata.PropertyDatas['TAPW-OPWEK_POS'].Value;

                    let propOpstelplaats = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_OPSTEL'];
                    let opstelplaats = entdata.PropertyDatas['TAPW-OPWEK_OPSTEL'].Value;

                    if (product && product.OpenVerbToestel === 'mogelijk' && !tapwaterLogic.isHidden(propToestel, entdata) && (tapwaterLogic.isHidden(propPositie, entdata) || positie === 'TAPW-OPWEK_POS_BIN') ||
                        opwekkerType && opwekkerType.key1 === 'GBK' && opwekkerInvoerValue && opwekkerInvoerValue === 'TAPW-OPWEK_INV_FORF' && !tapwaterLogic.isHidden(propOpstelplaats, entdata) && opstelplaats !== 'TAPW-OPWEK_OPSTEL_BUI') {
                        showit = true;
                    }

                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_VERBR_OPS': { // TO56
                    // Conditie TOBR
                    const propOpenVerbrandingstoestel = ntabuilding.properties['TAPW-OPWEK_VERBR'];
                    const openVerbrandingstoestel = propOpenVerbrandingstoestel.getValue(entdata);

                    showit = openVerbrandingstoestel === 'TAPW-OPWEK_VERBR_WEL' && !tapwaterLogic.isHidden(propOpenVerbrandingstoestel, entdata) && opwekkerType && opwekkerType.key1 !== 'GBK';
                    /// deze is wel relevant als er al koppeling is met de opstelplaats voor de opwekker
                    relevant = showit || conditieTOH(opwekkerType, entdata);
                    break;
                }
                case 'TAPW-OPWEK_VERBR_NOM': { // TO57
                    // Conditie TOBS
                    const propOpenVerbrandingstoestel = ntabuilding.properties['TAPW-OPWEK_VERBR'];
                    const openVerbrandingstoestel = propOpenVerbrandingstoestel.getValue(entdata);
                    const propVerbrandingstoestelPositie = ntabuilding.properties['TAPW-OPWEK_VERBR_OPS'];
                    const openVerbrandingstoestelPositie = propVerbrandingstoestelPositie.getValue(entdata);

                    showit = openVerbrandingstoestel === 'TAPW-OPWEK_VERBR_WEL' && !tapwaterLogic.isHidden(propOpenVerbrandingstoestel, entdata)
                        && (tapwaterLogic.isHidden(propVerbrandingstoestelPositie, entdata) || openVerbrandingstoestelPositie !== 'TAPW-OPWEK_VERBR_OPS_BUI');
                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_HERNIEUW_NON': { // TO66
                    // Conditie [TOAF] Als versie le3 - 1 toon als TO19 getoond is en gelijk is aan 'combinatie buitenlucht en ventilatieretourlucht ' OF 'ventilatieretourlucht' EN TO07 = 'forfaitair' / 'productspecifiek'
                    // Als versie ge3 - 2 toon als TO19 getoond is
                    //    - en gelijk is aan 'combinatie buitenlucht en ventilatieretourlucht'
                    //    - en gelijk is aan 'ventilatieretourlucht' EN TO07 = 'forfaitair' / 'productspecifiek'

                    const propBronWarmtePomp = ntabuilding.properties['TAPW-OPWEK_BRON_POMP'];
                    const bronWarmtePomp = propBronWarmtePomp.getValue(entdata);
                    const propInvoerOpwekker = ntabuilding.properties['TAPW-OPWEK_INV'];
                    const invoerOpwekker = propInvoerOpwekker.getValue(entdata);
                    if (ntabuilding.ntaVersionId >= 200) {
                        showit = !tapwaterLogic.isHidden(propBronWarmtePomp, entdata) && (bronWarmtePomp === 'VERW-OPWEK_POMP_BUVE' || (bronWarmtePomp === 'VERW-OPWEK_POMP_VERE'
                            && (invoerOpwekker === 'TAPW-OPWEK_INV_FORF' || invoerOpwekker === 'TAPW-OPWEK_INV_PROD')));

                    } else {
                        showit = !tapwaterLogic.isHidden(propBronWarmtePomp, entdata) && (bronWarmtePomp === 'VERW-OPWEK_POMP_BUVE' || bronWarmtePomp === 'VERW-OPWEK_POMP_VERE')
                            && (invoerOpwekker === 'TAPW-OPWEK_INV_FORF' || invoerOpwekker === 'TAPW-OPWEK_INV_PROD');
                    }
                    relevant = showit;
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'TAPW-OPWEK_HERNIEUW': { // TO67
                    // Conditie [TOAI] Als versie le3 - 1 toon als TO19 getoond is en gelijk is aan 'combinatie buitenlucht en ventilatieretourlucht ' OF 'ventilatieretourlucht' EN TO07≠'forfaitair' / 'productspecifiek'
                    // als versie ge3 - 2 toon als TO19 getoond is en gelijk is aan 'ventilatieretourlucht' EN TO07≠'forfaitair' / 'productspecifiek
                    const propBronWarmtePomp = ntabuilding.properties['TAPW-OPWEK_BRON_POMP'];
                    const bronWarmtePomp = propBronWarmtePomp.getValue(entdata);
                    const propInvoerOpwekker = ntabuilding.properties['TAPW-OPWEK_INV'];
                    const invoerOpwekker = propInvoerOpwekker.getValue(entdata);
                    if (ntabuilding.ntaVersionId >= 200) {
                        showit = !tapwaterLogic.isHidden(propBronWarmtePomp, entdata) && bronWarmtePomp === 'VERW-OPWEK_POMP_VERE'
                            && invoerOpwekker !== 'TAPW-OPWEK_INV_FORF' && invoerOpwekker !== 'TAPW-OPWEK_INV_PROD';
                    } else {
                        showit = !tapwaterLogic.isHidden(propBronWarmtePomp, entdata) && (bronWarmtePomp === 'VERW-OPWEK_POMP_BUVE' || bronWarmtePomp === 'VERW-OPWEK_POMP_VERE')
                            && (invoerOpwekker !== 'TAPW-OPWEK_INV_FORF' && invoerOpwekker !== 'TAPW-OPWEK_INV_PROD');
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-VAT_TYPE': { // TV05
                    // Conditie TVI
                    if (vatInvoerValue && vatInvoerValue === 'TAPW-VAT_INV_PROD') {
                        showit = true;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-VAT_VOL_A': { // TV06
                    // Conditie TVC
                    if (vatInvoerValue && vatInvoerValue === 'TAPW-VAT_INV_FORF') {
                        showit = true;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-VAT_VOL_B': { // TV07
                    // Conditie TVJ
                    if (vatInvoerValue && vatInvoerValue === 'TAPW-VAT_INV_EIG') {
                        showit = true;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-VAT_VOL_NON': { // TV08
                    // Conditie TVI
                    if (vatInvoerValue && vatInvoerValue === 'TAPW-VAT_INV_PROD') {
                        showit = true;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-VAT_FABR': { // TV09
                    // Conditie TVC
                    if (vatInvoerValue && vatInvoerValue === 'TAPW-VAT_INV_FORF') {
                        showit = true;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-VAT_ENERGIE': { // TV10
                    // Conditie TVF
                    let fabricagejaarBoilervat = entdata.PropertyDatas['TAPW-VAT_FABR'].Value;
                    let volumeVoorraadvatValue = entdata.PropertyDatas['TAPW-VAT_VOL_A'].Value;
                    let propFabricagejaarBoilervat = tapwaterLogic.tapwVoorraadvatProperties['TAPW-VAT_FABR'];
                    let propVolumeVoorraadvatValue = tapwaterLogic.tapwVoorraadvatProperties['TAPW-VAT_VOL_A'];

                    if (vatInvoerValue && vatInvoerValue === 'TAPW-VAT_INV_FORF' && fabricagejaarBoilervat && fabricagejaarBoilervat !== 'TAPW-VAT_FABR_VOOR2016' && fabricagejaarBoilervat !== 'TAPW-VAT_FABR_ONB' && !tapwaterLogic.isHidden(propFabricagejaarBoilervat, entdata) && volumeVoorraadvatValue && !tapwaterLogic.isHidden(propVolumeVoorraadvatValue, entdata)) {

                        let volumeVoorraadvat = parseInt(volumeVoorraadvatValue);
                        if (volumeVoorraadvat <= 500) {
                            showit = true;
                        }
                    }

                    relevant = showit;
                    break;
                }
                case 'TAPW-VAT_TRANS': { // TV11
                    // Conditie TVJ
                    if (vatInvoerValue && vatInvoerValue === 'TAPW-VAT_INV_EIG') {
                        showit = true;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-VAT_TRANS_NON': { // TV12
                    // Conditie TVG
                    if (vatInvoerValue && vatInvoerValue === 'TAPW-VAT_INV_PROD') {
                        showit = true;
                    }
                    relevant = showit;
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'TAPW-VAT_AANSL': { // TV13
                    // Conditie TVC
                    if (vatInvoerValue && vatInvoerValue === 'TAPW-VAT_INV_FORF') {
                        showit = true;
                    }

                    // Conditie [TVM]
                    // Bij het niet relevant zijn van TAPW-VAT_AANSL wordt er fsto;dis;ls = 2 doorgegeven aan de rekenkern
                    if (ntabuilding.ntaVersionId >= 200) {
                        const type = tapwaterLogic.tapwOpwekkersData[0].PropertyDatas['TAPW-OPWEK_TYPE'].Value;
                        const opwekkertype = tapwaterLogic.opwekkerTypes.find(x => x.Id === type);
                        if (opwekkertype.key1 === 'EB') {
                            showit = false;
                        }
                    }

                    relevant = showit;
                    break;
                }
                case 'TAPW-VAT_OPSTEL': { // TV15
                    // Conditie [TVH]
                    const tvh = vatInvoerValue !== 'TAPW-VAT_INV_FORF';

                    // Conditie [TWB]
                    const twb = tapwaterLogic.vatNr(entdata) === 1;

                    showit = tvh && twb;
                    break;
                }
                case 'TAPW-DISTR_CIRC': { // TD02
                    showit = true;

                    // Conditie [TDAI] verberg als gt3.2 EN key1 van table13_0 behorend bij opwekker gekozen in TO05 = 'WUV'
                    if (ntabuilding.ntaVersionId >= 300) {
                        const opwekkertypeValue = tapwaterLogic.tapwOpwekkersData[0].PropertyDatas['TAPW-OPWEK_TYPE'].Value;
                        const opwekkertype = tapwaterLogic.opwekkerTypes.find(x => x.Id === opwekkertypeValue);
                        if (opwekkertype && opwekkertype.key1 === 'WUV') {
                            showit = false;
                        }
                    }
                    break;
                }
                case 'TAPW-DISTR-BIN_INV': { // TD05
                    // Conditie TDC
                    const tdc = !tapwaterLogic.leidingenIsHidden();

                    /// Conditie [TDE] Laat alleen zien als
                    ///                 * G12 = 'detailopname' EN G04≠'utiliteitsbouw / unit in utiliteitsgebouw'
                    ///              OF * G04 = 'utiliteitsbouw / unit in utiliteitsgebouw'
                    /// Indien verborgen reken met 'leidinggegevens onbekend'. <-- dit wordt in de koppellaag gezet
                    const tde = (!ntaSharedLogic.isUtiliteit() && ntaSharedLogic.isDetailOpname()) ||
                        (ntaSharedLogic.isUtiliteit());

                    showit = tde && tdc;
                    relevant = showit;
                    break;
                }
                case 'TAPW-DISTR-BIN_LENG_NON': { // TD06
                    // Conditie TDH
                    const propInvoerCirculatieLeiding = ntabuilding.properties['TAPW-DISTR-BIN_INV'];
                    const invoerCirculatieLeiding = propInvoerCirculatieLeiding.getValue(entdata);
                    const tdh = !['TAPW-DISTR-BIN_INV_4', 'TAPW-DISTR-BIN_INV_7', 'TAPW-DISTR-BIN_INV_8'].includes(invoerCirculatieLeiding)
                        && !tapwaterLogic.isHidden(propInvoerCirculatieLeiding, entdata);

                    showit = tdh;
                    relevant = showit;
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'TAPW-DISTR_MAXLEN': { // TD34
                    // Conditie TDAJ
                    // als le3-2: laat zien als TD05='leidinglengte bekend' OF TD05='leidinglengte bekend - leidinggegevens onbekend' OF TD05='leidinglengte bekend - leidinggegevens bekend'
                    // als gt3-2: laat zien als TD05='leidinglengte bekend' OF TD05='leidinglengte bekend - leidinggegevens onbekend' OF TD05='leidinglengte bekend - leidinggegevens bekend' OF
                    //                          TD12='leidinglengte bekend' OF TD12='leidinglengte bekend - overige leidinggegevens bekend' OF TD12='leidinglengte bekend - overige leidinggegevens onbekend'

                    const propInvoerCirculatieLeidingBin = ntabuilding.properties['TAPW-DISTR-BIN_INV'];
                    const propInvoerCirculatieLeidingBui = ntabuilding.properties['TAPW-DISTR-BUI_INV'];
                    const invoerCirculatieLeidingBin = propInvoerCirculatieLeidingBin.getValue(tapwaterLogic.tapwDistributieBinnenData);
                    const invoerCirculatieLeidingBui = propInvoerCirculatieLeidingBui.getValue(tapwaterLogic.tapwDistributieBuitenData);

                    const TDAJ_le32 = !tapwaterLogic.isHidden(propInvoerCirculatieLeidingBin, tapwaterLogic.tapwDistributieBinnenData) && ['TAPW-DISTR-BIN_INV_4', 'TAPW-DISTR-BIN_INV_7', 'TAPW-DISTR-BIN_INV_8'].includes(invoerCirculatieLeidingBin);
                    const TDAJ_gt32 = TDAJ_le32 || (!tapwaterLogic.isHidden(propInvoerCirculatieLeidingBui, tapwaterLogic.tapwDistributieBuitenData) && ['TAPW-DISTR-BUI_INV_1', 'TAPW-DISTR-BUI_INV_3', 'TAPW-DISTR-BUI_INV_4'].includes(invoerCirculatieLeidingBui));

                    showit = ntabuilding.ntaVersionId < 300 ? TDAJ_le32 : TDAJ_gt32;
                    relevant = showit;
                    break;
                }
                case 'TAPW-DISTR-BIN_MAXLENG': // TD34 (tot versie 61)
                case 'TAPW-DISTR-BIN_LENG': { // TD07
                    // Conditie TDI
                    const propInvoerCirculatieLeiding = ntabuilding.properties['TAPW-DISTR-BIN_INV'];
                    const invoerCirculatieLeiding = propInvoerCirculatieLeiding.getValue(entdata);
                    const tdi = ['TAPW-DISTR-BIN_INV_4', 'TAPW-DISTR-BIN_INV_7', 'TAPW-DISTR-BIN_INV_8'].includes(invoerCirculatieLeiding)
                        && !tapwaterLogic.isHidden(propInvoerCirculatieLeiding, entdata);

                    showit = tdi;
                    relevant = showit;
                    break;
                }
                case 'TAPW-DISTR-BIN_DIA': { // TD08
                    // Conditie [TDB]
                    const propInvoerCirculatieLeiding = ntabuilding.properties['TAPW-DISTR-BIN_INV'];
                    const invoerCirculatieLeiding = propInvoerCirculatieLeiding.getValue(entdata);
                    showit = tapwaterLogic.isHidden(propInvoerCirculatieLeiding, entdata) || ['TAPW-DISTR-BIN_INV_2', 'TAPW-DISTR-BIN_INV_3', 'TAPW-DISTR-BIN_INV_4', 'TAPW-DISTR-BIN_INV_6', 'TAPW-DISTR-BIN_INV_8'].includes(invoerCirculatieLeiding);
                    relevant = showit;
                    break;
                }
                case 'TAPW-DISTR-BIN_ISO_KLEP': { // TD10
                    showit = true;
                    // Conditie TDK
                    let isolatieCirculatieLeidingValue = entdata.PropertyDatas['TAPW-DISTR-BIN_ISO_CIRC'].Value;
                    if (isolatieCirculatieLeidingValue) {
                        let isolatieCirculatieLeiding = tapwaterLogic.tapwDistributieBinnenProperties["TAPW-DISTR-BIN_ISO_CIRC"].Domain.Codes.find(function (x) { return x.Id === isolatieCirculatieLeidingValue; });
                        if (isolatieCirculatieLeiding.Value === 'niet-geïsoleerd') {
                            showit = false;
                        }
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-DISTR-BUI_INV': { // TD12
                    showit = true;
                    // TDC
                    if (tapwaterLogic.leidingenIsHidden()) {
                        showit = false;
                    }

                    relevant = showit;
                    break;
                }
                case 'TAPW-DISTR-BUI_LENG_NON': { // TD13
                    // Conditie TDM
                    let invoerCirculatieLeiding = entdata.PropertyDatas['TAPW-DISTR-BUI_INV'].Value;
                    if (invoerCirculatieLeiding && (invoerCirculatieLeiding === 'TAPW-DISTR-BUI_INV_2' || invoerCirculatieLeiding === 'TAPW-DISTR-BUI_INV_5' || invoerCirculatieLeiding === 'TAPW-DISTR-BUI_INV_6')) {
                        showit = true;
                    }
                    relevant = false; //-- waarden komen uit rekenhart
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'TAPW-DISTR-BUI_LENG': { // TD14
                    // Conditie TDN
                    let invoerCirculatieLeiding = entdata.PropertyDatas['TAPW-DISTR-BUI_INV'].Value;
                    if (invoerCirculatieLeiding && (invoerCirculatieLeiding === 'TAPW-DISTR-BUI_INV_1' || invoerCirculatieLeiding === 'TAPW-DISTR-BUI_INV_3' || invoerCirculatieLeiding === 'TAPW-DISTR-BUI_INV_4')) {
                        showit = true;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-DISTR-BUI_DIA': { // TD15
                    // Conditie TDO
                    const invoerCirculatieLeiding = entdata.PropertyDatas['TAPW-DISTR-BUI_INV'].Value;
                    showit = ['TAPW-DISTR-BUI_INV_1', 'TAPW-DISTR-BUI_INV_2', 'TAPW-DISTR-BUI_INV_4', 'TAPW-DISTR-BUI_INV_6'].includes(invoerCirculatieLeiding);
                    relevant = showit;
                    break;
                }
                case 'TAPW-DISTR-BUI_ISO_CIRC': { // TD16
                    showit = true;
                    // Conditie TDP
                    const invoerCirculatieLeiding = entdata.PropertyDatas['TAPW-DISTR-BUI_INV'].Value;
                    showit = invoerCirculatieLeiding !== 'TAPW-DISTR-BUI_INV_7';
                    relevant = showit;
                    break;
                }
                case 'TAPW-DISTR-BUI_ISO_KLEP': { // KD17
                    showit = false;
                    let tdp = true;
                    let tdq = true;

                    // Conditie TDP
                    let invoerCirculatieLeiding = entdata.PropertyDatas['TAPW-DISTR-BUI_INV'].Value;
                    if (invoerCirculatieLeiding && invoerCirculatieLeiding === 'TAPW-DISTR-BUI_INV_7') {
                        tdp = false;
                    }

                    // Conditie TDQ
                    let isolatieCirculatieLeidingValue = entdata.PropertyDatas['TAPW-DISTR-BUI_ISO_CIRC'].Value;
                    if (isolatieCirculatieLeidingValue) {
                        let isolatieCirculatieLeiding = tapwaterLogic.tapwDistributieBuitenProperties["TAPW-DISTR-BUI_ISO_CIRC"].Domain.Codes.find(function (x) { return x.Id === isolatieCirculatieLeidingValue; });
                        if (isolatieCirculatieLeiding.Value === 'niet-geïsoleerd') {
                            tdq = false;
                        }
                    }

                    if (tdp && tdq) {
                        showit = true;
                    }

                    relevant = showit;
                    break;
                }
                case 'TAPW-DISTR-EIG_DEK': // TD22
                case 'TAPW-DISTR-EIG_LAB_CON': { // TD23
                    // Conditie TDS
                    showit = !dekkingIsHidden();
                    relevant = dekkingIsRelevant(entdata);
                    break;
                }
                case 'TAPW-DISTR-EIG_LAB_ISO': { // TD24
                    // Conditie TDT
                    showit = !labdaIsolatieIsHidden();
                    relevant = labdaIsolatieIsRelevant(entdata);
                    break;
                }
                case 'TAPW-DISTR-EIG_LAB_LEI': //TD25
                    showit = !labdaLeidingIsHidden();
                    relevant = labdaLeidingIsRelevant(entdata); //-- relevantie afhankelijk van binnen of buiten, vandaar checken met entdata
                    break;
                case 'TAPW-DISTR_CIRC_INV': { // TD26
                    showit = true;

                    // Conditie TDC
                    if (tapwaterLogic.leidingenIsHidden()) {
                        showit = false;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-DISTR_CIRC_VERM_NON': { // TD27
                    // Conditie TDU
                    showit = conditieTDU() && !tapwaterLogic.leidingenIsHidden();
                    relevant = showit;
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'TAPW-DISTR_CIRC_VERM': { // TD28
                    // Conditie TDV
                    showit = conditieTDV() && !tapwaterLogic.leidingenIsHidden();
                    relevant = showit;
                    break;
                }
                case 'TAPW-DISTR_CIRC_EEI_NON': { // TD29
                    // Conditie TDU
                    showit = conditieTDU() && !tapwaterLogic.leidingenIsHidden();
                    relevant = showit;
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'TAPW-DISTR_CIRC_EEI': { // TD30
                    // Conditie TDX
                    showit = conditieTDX() && !tapwaterLogic.leidingenIsHidden();
                    relevant = showit;
                    break;
                }
                case 'TAPW-DISTR_CIRC_REG': { // TD31
                    showit = true;

                    // Conditie TDC
                    if (tapwaterLogic.leidingenIsHidden()) {
                        showit = false;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-DISTR_AANT_LAGEN': { // TD32
                    showit = true;

                    // Conditie TDC
                    if (tapwaterLogic.leidingenIsHidden()) {
                        showit = false;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-DISTR_AANT_AFLSETS': { // TD33
                    showit = false;

                    // Conditie [TDZ] toon als key1 van table13_0 behorend bij opwekker gekozen in TO05 = 'EW' / 'WUV' OF als TD02='circulatieleiding voor warm tapwater / circulatieleiding met cv-water'
                    const type = tapwaterLogic.tapwOpwekkersData[0].PropertyDatas['TAPW-OPWEK_TYPE'].Value;
                    const opwekkertype = tapwaterLogic.opwekkerTypes.find(x => x.Id === type);
                    const hasExternalHeatSource = opwekkertype && ['EW', 'WUV'].includes(opwekkertype.key1) || false;

                    const propdataCirc = entdata.PropertyDatas['TAPW-DISTR_CIRC'];
                    const hasCirculatieleiding = propdataCirc.Relevant && propdataCirc.Value !== "TAPW-DISTR_CIRC_GEEN";

                    if (hasExternalHeatSource || hasCirculatieleiding) {
                        showit = true;
                        if (!propdata.Touched) { //default value
                            const value = hasExternalHeatSource ? '1' : '0';
                            ntaEntityData.saveprop(propdata, value);
                            propdata.Touched = true;
                        }
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-DISTR_AANT_WOONF': { // TD38
                    // [TDAG] toon indien gt3.2 EN TD08 OF TD15 is 'onbekende diameter'
                    showit = ntabuilding.ntaVersionId >= 300
                        && !ntaSharedLogic.isUtiliteit()
                        && ntaEntityData.findEntities(entdata, 'TAPW-DISTR-BIN', 'TAPW-DISTR-BUI')
                            .some(ed => ed.Relevant && ['TAPW-DISTR-BIN_DIA', 'TAPW-DISTR-BUI_DIA'].map(propId => ed.PropertyDatas[propId])
                                                        .some(pd => pd && pd.Relevant && pd.Value === 'TAPW-DISTR-BIN_DIA_ONB'));
                    break;
                }
                case 'TAPW-DISTR_SPORT': { // TD35
                    // Conditie TDY
                    if (ntaSharedLogic.isUtiliteit()) {

                        // Zoek naar de gebruiksfunctie sport
                        let bSport = false;
                        for (let index = 0; index < tapwaterLogic.units.length; index++) {
                            let gebruiksfuncties = ntaEntityData.getChildren(tapwaterLogic.units[index], 'UNIT-RZ-GF');

                            for (let index2 = 0; index2 < gebruiksfuncties.length; index2++) {
                                let gebruiksfunctie = gebruiksfuncties[index2].PropertyDatas['UNIT-RZ-GFID'].Value;

                                if (gebruiksfunctie && gebruiksfunctie === 'GF_SPORT') {
                                    bSport = true;
                                    break;
                                }
                            }
                            if (bSport) {
                                break;
                            }
                        }

                        let invoerCirculatieleiding = tapwaterLogic.tapwDistributieBinnenData.PropertyDatas['TAPW-DISTR-BIN_INV'].Value;

                        if (ntaSharedLogic.AgTotaal() > 1000 && bSport && invoerCirculatieleiding && (invoerCirculatieleiding === 'TAPW-DISTR-BIN_INV_3' || invoerCirculatieleiding === 'TAPW-DISTR-BIN_INV_5' || invoerCirculatieleiding === 'TAPW-DISTR-BIN_INV_6')) {
                            showit = true;
                        }
                    }

                    relevant = showit;
                    break;
                }
                case 'TAPW-AFG_LEI_BAD': { // TA02
                    showit = true;

                    if (ntaSharedLogic.isUtiliteit() || ntaSharedLogic.perGebouwEnAppartement() || ntaSharedLogic.voorProjectwoningen() || aantalBadruimtenIsZero()) { // Conditie [TAC], conditie [TAA]
                        showit = false;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-AFG_LEI_AANR': { // TA03
                    showit = true;

                    if (ntaSharedLogic.isUtiliteit() || ntaSharedLogic.perGebouwEnAppartement() || ntaSharedLogic.voorProjectwoningen() || aantalKeukensIsZero()) { // Conditie [TAC], conditie [TAB]
                        showit = false;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-AFG_DIA_LEI': { // TA04
                    showit = !ntaSharedLogic.isUtiliteit() && conditieTAI() && !ntaSharedLogic.perGebouwEnAppartement() && !ntaSharedLogic.voorProjectwoningen() && !aantalKeukensIsZero(); // Conditie [TAC], conditie [TAB], conditie [TAI]
                    relevant = showit;
                    break;
                }
                case 'TAPW-AFG_LENG_UIT': { // TA05
                    showit = true;

                    if (!ntaSharedLogic.isUtiliteit()) {
                        showit = false;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-AFG-LEI_LEN_BAD': { // TA08
                    showit = true;
                    relevant = true;

                    if (tapwaterLogic.leidinggegevensIsHidden()) {
                        showit = false;
                        relevant = false;
                    }
                    else if (aantalBadruimtenIsZero()) {
                        showit = false;
                        relevant = false;
                    }
                    else if (tapwaterLogic.isReadOnly(prop, entdata)) {
                        showit = true;
                        relevant = false;
                    }
                    break;
                }
                case 'TAPW-AFG-LEI_LEN_AANR': { // TA09
                    showit = !conditieTAG();

                    if (showit && tapwaterLogic.isReadOnly(prop, entdata)) {
                        relevant = false;
                    }
                    break;
                }
                case 'TAPW-AFG-LEI_DIA_AANR': { // TA10
                    showit = conditieTAI() && !conditieTAG();

                     if (showit && tapwaterLogic.isReadOnly(prop, entdata)) {
                        relevant = false;
                    }
                    break;
                }
                case 'TAPW-DOUCHE_AANG': { // TW04
                    const u = ntaSharedLogic.isUtiliteit();

                    // Conditie [TWB]
                    const twb = tapwaterLogic.doucheNr(entdata) === 1;

                    showit = u && twb;
                    relevant = showit;
                    break;
                }
                case 'TAPW-DOUCHE_WIJZE': { // TW05
                    showit = true;
                    relevant = true;

                    // Conditie TWJ
                    const propTypeDoucheWTW = ntabuilding.properties['TAPW-DOUCHE_TYPE'];
                    const typeDoucheWTW = propTypeDoucheWTW.getValue(entdata);
                    if (!tapwaterLogic.isHidden(propTypeDoucheWTW, entdata) && typeDoucheWTW === 'TAPW-DOUCHE_TYPE_CIRC') {
                        propdata.Value = 'TAPW-DOUCHE_WIJZE_BEIDE';

                        showit = false;
                        relevant = true;
                    }
                    break;
                }
                case 'TAPW-DOUCHE_TYPE': { // TW07
                    showit = true;

                    // Conditie TWD
                    let invoerDoucheWTW = entdata.PropertyDatas['TAPW-DOUCHE_INV'].Value;
                    if (invoerDoucheWTW && (invoerDoucheWTW === 'TAPW-DOUCHE_FORF' || invoerDoucheWTW === 'TAPW-DOUCHE_EIG')) {
                        showit = false;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-DOUCHE_WTW_TOES': { // TW08
                    // Conditie TWG
                    showit = !conditieTWG(prop, propdata, entdata);
                    relevant = showit;
                    break;
                }
                case 'TAPW-DOUCHE_AANT_TOT': { // TW09
                    let u = true;
                    let twb = true;
                    let twh = true;
                    let twl = true;

                    if (!ntaSharedLogic.isUtiliteit()) {
                        u = false;
                    }

                    // Conditie [TWB]
                    if (tapwaterLogic.doucheNr(entdata) !== 1) {
                        twb = false;
                    }

                    // Conditie [TWH]
                    if (conditieTWH(entdata)) {
                        twh = false;
                    }

                    // Conditie [TWL]
                    if (ntaSharedLogic.perGebouwEnAppartementOfUnit() || ntaSharedLogic.voorProjectwoningen()) {
                        twl = false;
                    }

                    if (u && twb && twh && twl) {
                        showit = true;
                    }

                    relevant = showit;
                    break;
                }
                case 'TAPW-DOUCHE_AANT_TYPE': { // TW10
                    showit = true;

                    // Conditie [TWH] en conditie [TWL]
                    if (conditieTWH(entdata) || ntaSharedLogic.perGebouwEnAppartementOfUnit() || ntaSharedLogic.voorProjectwoningen()) {
                        showit = false;
                    }
                    relevant = true; //-- altijd relevant want bij gebouw en appartement wordt het totaal van de douches aangesloten op de dwtw hierin opgeslagen om doorgegeven te worden aan de rekenkern
//                    relevant = showit;
                    break;
                }
                case 'TAPW-DOUCHE_REND': { // TW11
                    // Conditie TWI
                    let invoerDoucheWTW = entdata.PropertyDatas['TAPW-DOUCHE_INV'].Value;
                    if (invoerDoucheWTW && invoerDoucheWTW === 'TAPW-DOUCHE_EIG') {
                        showit = true;
                    }

                    relevant = showit;
                    break;
                }
                case 'TAPW-DOUCHE_REND_NON': { // TW12
                    // Conditie TWG
                    showit = !conditieTWG(prop, propdata, entdata);
                    relevant = showit;
                    break;
                }
                case 'TAPW-DOUCHE-AANG_DIU': { // TW16
                    if (!ntaSharedLogic.isUtiliteit()) {
                        showit = false;
                        relevant = showit;
                        break;
                    }

                    // Conditie TWR
                    let doucheWTW = tapwaterLogic.tapwDoucheData.find(function (x) { return x.EntityId === 'TAPW-DOUCHE' && ntaSharedLogic.hasRelation(x, entdata); });
                    if (doucheWTW.EntityDataId === tapwaterLogic.tapwDoucheData[0].EntityDataId) {
                        showit = true;
                    }
                    relevant = showit;
                    break;
                }
                case 'TAPW-OPWEK_TOES_NAAM': // veld voor informatie of toetsel een keukengeiser is of niet. Is nodig voor TAPW-OPWEK_VERBR -> openVerbrandingstoestelInput.type
                case 'TAPW-OPWEK_NEN16147':
                case 'TAPW-OPWEK_LEGIOPREV': {
                    showit = false;
                    relevant = true;
                    break;
                }
                case 'MEASURE-COSTS_INV':
                case 'MEASURE-COSTS_PER_M2': {
                    showit = false;
                    break;
                }
                case 'MEASURE-COSTS_TOTAAL': {
                    showit = true; // als de entiteit zichtbaar is, dan moet het totale kostenaantal altijd weergegeven worden
                    break;
                }

                default:
                    showit = true;
                    break;
            }

            // Conditie [TOBU]
            if (entdata.EntityId === 'TAPW-OPWEK' && opwekkerType && opwekkerType.key1 === 'WUV') {
                // alleen deze velden blijven zichtbaar, de rest niet: TO09, TO10 en TO58 en natuurlijk ook TO05 (anders kun je geen ander opwekkertype kiezen).
                if (!new Set(['TAPW-OPWEK_GEM', 'TAPW-OPWEK_A', 'TAPW-OPWEK_WARMTE_UIT_VERW', 'TAPW-OPWEK_TYPE']).has(prop.Id)) {
                    showit = false;
                    relevant = false;
                }
            }

            // Conditie [TOCZ] Als key1 van opwekker gekozen in TO05 = 'OVV' verberg alle velden beginnend met TO met uitzondering van TO09, TO10 en TO76
            if (entdata.EntityId === 'TAPW-OPWEK' && opwekkerType && opwekkerType.key1 === 'OVV') {
                if (!new Set(['TAPW-OPWEK_GEM', 'TAPW-OPWEK_A', 'TAPW-OPWEK_VERW-OPWEK', 'TAPW-OPWEK_TYPE']).has(prop.Id)) { // TO09, TO10, TO76, TO05
                    showit = false;
                    relevant = false;
                }
            }

            if (relevant === null) relevant = showit;

            const propdatas = [propdata, ...ntaSharedLogic.getUnitSpecificResultPropdatas(prop, entdata)];
            ntaEntityData.setPropdataStatus(propdatas, relevant, showit);

            return !showit;
        } //-- end: isHidden ------------------------------------------------------------------------//

        function conditieTAG() {
            // [TAG] verberg kolom indien op de warmtapwater tegel de som van het aantal keukens = 0
            const hidden = tapwaterLogic.leidinggegevensIsHidden() || aantalKeukensIsZero();
            return hidden;
        } //-- end: conditieTAG -------------------------------------------------------------------

        function conditieTAI() {
            // [TAI] verberg als versie gt3.2
            const visible = ntabuilding.ntaVersionId < 300;
            return visible;
        } //-- end: conditieTAI -------------------------------------------------------------------

        var conditieTOH = function (opwekkerType, entdata) {
            let condition = false;
            const opwekkerInvoerValue = entdata.PropertyDatas['TAPW-OPWEK_INV'].Value;
            if (opwekkerType && opwekkerType.key1 === 'GBK' && opwekkerInvoerValue && opwekkerInvoerValue === 'TAPW-OPWEK_INV_FORF') {
                condition = true;
            }
            return condition;
        } //-- end: conditieTOH --------------------------------------------------------------------------------//

        var conditieTWG = function (prop, propdata, entdata) {
            // Conditie TWG
            let invoerDoucheWTW = entdata.PropertyDatas['TAPW-DOUCHE_INV'].Value;
            if (invoerDoucheWTW && invoerDoucheWTW === 'TAPW-DOUCHE_EIG') {
                return true;
            }

            return false;
        } //-- end: conditieTWG ------------------------------------------------------------------------//

        var conditieTWH = function (entdata) {
            // Conditie TWH
            let aangeslotenDouches = entdata.PropertyDatas['TAPW-DOUCHE_AANG'].Value;
            return aangeslotenDouches && aangeslotenDouches === 'TAPW-DOUCHE_AANG_ONB';
        } //-- end: conditieTWH ------------------------------------------------------------------------//

        var conditieTDU = function () {
            // Conditie TDU
            let circulatiepompInvoer = tapwaterLogic.tapwDistributieData.PropertyDatas['TAPW-DISTR_CIRC_INV'].Value;
            if (circulatiepompInvoer && circulatiepompInvoer === 'TAPW-DISTR_CIRC_INV_4') {
                return true; // show
            }
            return false; // hide
        };

        var conditieTDV = function () {
            // Conditie TDV
            let circulatiepompInvoer = tapwaterLogic.tapwDistributieData.PropertyDatas['TAPW-DISTR_CIRC_INV'].Value;
            if (circulatiepompInvoer && (circulatiepompInvoer === 'TAPW-DISTR_CIRC_INV_3' || circulatiepompInvoer === 'TAPW-DISTR_CIRC_INV_4')) {
                return false; // hide
            }
            return true; // show
        };

        var conditieTDX = function () {
            // Conditie TDX
            let circulatiepompInvoer = tapwaterLogic.tapwDistributieData.PropertyDatas['TAPW-DISTR_CIRC_INV'].Value;
            if (circulatiepompInvoer && (circulatiepompInvoer === 'TAPW-DISTR_CIRC_INV_2' || circulatiepompInvoer === 'TAPW-DISTR_CIRC_INV_4')) {
                return false;
            }
            return true;
        };

        function conditieTOBP(opwekker) {
            // [TOBP] toon deze keuze alleen bij de 1e opwekker. Als opwekker 1 = opwekker van verwarmingsysteem toon deze keuze ook bij opwekker 2
            const opwekkerNr = tapwaterLogic.opwekkerNr(opwekker);
            switch (opwekkerNr) {
                case 1:
                    return true;
                case 2:
                    return tapwaterLogic.tapwOpwekkersData[0].PropertyDatas['TAPW-OPWEK_TYPE'].Value === 'TAPW-OPWEK_TYPE_24'; // opwekker van verwarmingssysteem
                default:
                    return false;
            }
        } //-- end: conditieTOBP ------------------------------------------------------------------

        function heeftHotfillBoiler() {
            const opwekker2 = tapwaterLogic.tapwOpwekkersData[1];
            return !!opwekker2 && opwekker2.PropertyDatas['TAPW-OPWEK_TYPE'].Value === 'TAPW-OPWEK_TYPE_23'; // TO05 = hotfill boiler (met/zonder elektrisch element)
        } //-- end: heeftHotfillBoiler ------------------------------------------------------------

        tapwaterLogic.leidinggegevensIsHidden = function () {
            const isHidden = !ntaSharedLogic.perGebouwEnAppartement() && !ntaSharedLogic.voorProjectwoningen();

            const leidingen = tapwaterLogic.units.map(unit => getUnitLeiding(unit)).filter(leiding => leiding);
            ntaEntityData.setEntityRelevancy(leidingen, !isHidden);
            ntaEntityData.setEntityVisibility(leidingen, !isHidden);

            return isHidden;
        };

        tapwaterLogic.aangeslotenDouchesOpWTWIsHidden = function (doucheWTW) {
            let isHidden = true;
            // Conditie [TWH] en [TWM]
            if (!conditieTWH(doucheWTW) && (ntaSharedLogic.perGebouwEnAppartementOfUnit() || ntaSharedLogic.voorProjectwoningen())) {
                isHidden = false;
            }

            const douches = doucheWTW.tempUnits.map(tempUnit => tempUnit.douche).filter(douche => douche);
            ntaEntityData.setEntityRelevancy(douches, !isHidden);
            ntaEntityData.setEntityVisibility(douches, !isHidden);

            return isHidden;
        };

        tapwaterLogic.eigenschappenLeidingenRowIsHidden = function (entdata) {
            if (ntaSharedLogic.hasRelation(tapwaterLogic.tapwDistributieBinnenData, entdata)) {
                return binnenEigenschappenDistributieleidingenIsHidden();
            }
            else if (ntaSharedLogic.hasRelation(tapwaterLogic.tapwDistributieBuitenData, entdata)) {
                return buitenEigenschappenDistributieleidingenIsHidden();
            }

            return true;
        } //-- end: eigenschappenLeidingenRowIsHidden ------------------------------------------------------------------------//

        var binnenEigenschappenDistributieleidingenIsHidden = function () {
            let isHidden = true;

            const propdataInvoerCirculatieLeiding = tapwaterLogic.tapwDistributieBinnenData.PropertyDatas['TAPW-DISTR-BIN_INV'];
            if (propdataInvoerCirculatieLeiding.Relevant) {
                const showLeidingenByInvoer = ['TAPW-DISTR-BIN_INV_1', 'TAPW-DISTR-BIN_INV_5', 'TAPW-DISTR-BIN_INV_7'];
                isHidden = !(showLeidingenByInvoer.includes(propdataInvoerCirculatieLeiding.Value));
            }

            return isHidden;
        } //-- end: binnenEigenschappenDistributieleidingenIsHidden ------------------------------------------------------------------------//

        var buitenEigenschappenDistributieleidingenIsHidden = function () {
            let isHidden = true;

            const propdataInvoerCirculatieLeidingBuiten = tapwaterLogic.tapwDistributieBuitenData.PropertyDatas['TAPW-DISTR-BUI_INV'];
            if (propdataInvoerCirculatieLeidingBuiten.Relevant) {
                const showLeidingenByInvoer = ['TAPW-DISTR-BUI_INV_3', 'TAPW-DISTR-BUI_INV_5'];
                isHidden = !(showLeidingenByInvoer.includes(propdataInvoerCirculatieLeidingBuiten.Value));
            }

            return isHidden;
        } //-- end: buitenEigenschappenDistributieleidingenIsHidden ------------------------------------------------------------------------//


        // Conditie TAF, omgekeerd conditie TAA
        var aantalBadruimtenIsZero = function () {
            let som = 0;

            tapwaterLogic.tapwUnits.forEach(function (tapwUnit, index) {
                let badrValue = tapwUnit.PropertyDatas['TAPW-UNIT_BADRUIMTEN'].Value;
                let badr = parseInt(badrValue);

                if (badr < 0 || isNaN(badr)) {
                    badr = 0;
                }

                som += badr;
            });

            if (som <= 0) {
                return true;
            }

            return false;
        };

        // Conditie TAG, omgekeerd conditie TAB
        var aantalKeukensIsZero = function () {
            let som = 0;

            tapwaterLogic.tapwUnits.forEach(function (tapwUnit, index) {
                let keukValue = tapwUnit.PropertyDatas['TAPW-UNIT_KEUKENS'].Value;
                let keuk = parseInt(keukValue);

                if (keuk < 0 || isNaN(keuk)) {
                    keuk = 0;
                }

                som += keuk;
            });

            if (som <= 0) {
                return true;
            }

            return false;
        };

        var binnenLeidingIsOnGeisoleerd = function () {
            const isolatieLeidingen = tapwaterLogic.tapwDistributieBinnenData.PropertyDatas['TAPW-DISTR-BIN_ISO_CIRC'].Value;
            return isolatieLeidingen === 'TAPW-DISTR-BIN_ISO_CIRC_1' || isolatieLeidingen === 'TAPW-DISTR-BIN_ISO_CIRC_4';
        } //-- end: binnenLeidingIsOnGeisoleerd ------------------------------------------------------------------------//

        var buitenLeidingIsOngeisoleerd = function () { // Conditie DI
            const isolatieLeidingen = tapwaterLogic.tapwDistributieBuitenData.PropertyDatas['TAPW-DISTR-BUI_ISO_CIRC'].Value;
            return isolatieLeidingen === 'TAPW-DISTR-BIN_ISO_CIRC_1' || isolatieLeidingen === 'TAPW-DISTR-BIN_ISO_CIRC_4';
        } //-- end: buitenLeidingIsOngeisoleerd ------------------------------------------------------------------------//

        tapwaterLogic.eigenschappenDistributieLeidingenTabelIsHidden = function () {
            let isHidden = true;

            // Conditie TDC
            if (tapwaterLogic.leidingenIsHidden()) {
                isHidden = true;

                // Relevantie van eigenschappen leidingen nog zetten
                ntaEntityData.setEntityRelevancy(tapwaterLogic.tapwDistributieEigenschappenLeidingenBinnenData, !isHidden);
                ntaEntityData.setEntityRelevancy(tapwaterLogic.tapwDistributieEigenschappenLeidingenBuitenData, !isHidden);
                ntaEntityData.setEntityVisibility(tapwaterLogic.tapwDistributieEigenschappenLeidingenBinnenData, !isHidden);
                ntaEntityData.setEntityVisibility(tapwaterLogic.tapwDistributieEigenschappenLeidingenBuitenData, !isHidden);
            }
            // Conditie TDR
            else {
                // Beide methoden moet een keer zijn aangeroepen, want de relevantie van de entiteiten wordt ook hierbinnen afgehandeld
                const binnenIsHidden = binnenEigenschappenDistributieleidingenIsHidden();
                const buitenIsHidden = buitenEigenschappenDistributieleidingenIsHidden();

                ntaEntityData.setEntityRelevancy(tapwaterLogic.tapwDistributieEigenschappenLeidingenBinnenData, !binnenIsHidden);
                ntaEntityData.setEntityRelevancy(tapwaterLogic.tapwDistributieEigenschappenLeidingenBuitenData, !buitenIsHidden);
                ntaEntityData.setEntityVisibility(tapwaterLogic.tapwDistributieEigenschappenLeidingenBinnenData, !binnenIsHidden);
                ntaEntityData.setEntityVisibility(tapwaterLogic.tapwDistributieEigenschappenLeidingenBuitenData, !buitenIsHidden);

                isHidden = binnenIsHidden && buitenIsHidden;
            }

            return isHidden;
        } //-- end: eigenschappenDistributieLeidingenTabelIsHidden ------------------------------------------------------------------------//

        tapwaterLogic.leidingenIsHidden = function () {
            //-- Conditie [TDC]
            const propdataCirc = tapwaterLogic.tapwDistributieData.PropertyDatas['TAPW-DISTR_CIRC'];
            const isHidden = !propdataCirc.Relevant || propdataCirc.Value === 'TAPW-DISTR_CIRC_GEEN';

            ntaEntityData.setEntityRelevancy(tapwaterLogic.tapwDistributieBinnenData, !isHidden);
            ntaEntityData.setEntityRelevancy(tapwaterLogic.tapwDistributieBuitenData, !isHidden);
            ntaEntityData.setEntityVisibility(tapwaterLogic.tapwDistributieBinnenData, !isHidden);
            ntaEntityData.setEntityVisibility(tapwaterLogic.tapwDistributieBuitenData, !isHidden);

            return isHidden;
        } //-- end: leidingenIsHidden ------------------------------------------------------------------------//

        var dekkingIsRelevant = function (entdata) {
            if (!entdata) {
                return false;
            }

            if (ntaSharedLogic.hasRelation(tapwaterLogic.tapwDistributieBinnenData, entdata)) { // Als binnen hidden is en buiten niet
                if (binnenDekkingShouldBeHidden()) {
                    return false;
                }
            }
            else if (ntaSharedLogic.hasRelation(tapwaterLogic.tapwDistributieBuitenData, entdata)) { // Als buiten hidden is en binnen niet
                if (buitenDekkingShouldBeHidden()) {
                    return false;
                }
            }

            return true;
        };

        var dekkingIsHidden = function () {
            //-- Conditie TDS
            if (binnenDekkingShouldBeHidden() && buitenDekkingShouldBeHidden()) {
                return true;
            }

            return false;
        } //-- end: dekkingIsHidden ------------------------------------------------------------------------//

        var binnenDekkingShouldBeHidden = function () {
            //-- Conditie TDS
            const binnenIsolatieLeidingen = tapwaterLogic.tapwDistributieBinnenData.PropertyDatas['TAPW-DISTR-BIN_ISO_CIRC'].Value;
            return binnenIsolatieLeidingen !== 'TAPW-DISTR-BIN_ISO_CIRC_2';
        } //-- end: binnenDekkingShouldBeHidden ------------------------------------------------------------------------//

        var buitenDekkingShouldBeHidden = function () {
            //-- Conditie TDS
            const buitenIsolatieLeidingen = tapwaterLogic.tapwDistributieBuitenData.PropertyDatas['TAPW-DISTR-BUI_ISO_CIRC'].Value;
            return buitenIsolatieLeidingen !== 'TAPW-DISTR-BIN_ISO_CIRC_2';
        } //-- end: buitenDekkingShouldBeHidden ------------------------------------------------------------------------//


        // Conditie DAJ
        var labdaLeidingIsHidden = function () {
            //-- labdaLeiding moet getoond worden als row1 (binnen) of row2 (buiten) niet-geisoleerde leidingen heeft
            let showit = showBinnenLabdaLeiding() || showBuitenLabdaLeiding();
            return !showit;
        };

        var showBinnenLabdaLeiding = function () {
            //-- labdaLeiding moet getoond worden als HD09 (isolatie van leiding binnen verwarmde zone) == niet-geisoleerd
            return !binnenEigenschappenDistributieleidingenIsHidden() && binnenLeidingIsOnGeisoleerd();
        };

        var showBuitenLabdaLeiding = function () {
            //-- labdaLeiding moet getoond worden als HD09 (isolatie van leiding binnen verwarmde zone) == niet-geisoleerd
            return !buitenEigenschappenDistributieleidingenIsHidden() && buitenLeidingIsOngeisoleerd();
        };

        var labdaLeidingIsRelevant = function (entdata) {
            if (!entdata) {
                return false;
            }

            let relevant = false;
            if (ntaSharedLogic.hasRelation(tapwaterLogic.tapwDistributieBinnenData, entdata)) { // Als binnen hidden is en buiten niet
                relevant = showBinnenLabdaLeiding();
            }
            else if (ntaSharedLogic.hasRelation(tapwaterLogic.tapwDistributieBuitenData, entdata)) { // Als buiten hidden is en binnen niet
                //-- buiten is LamdaLeiding relevant als er een relatie is en
                relevant = showBuitenLabdaLeiding();
            }

            return relevant;
        };



        var labdaIsolatieIsRelevant = function (entdata) {
            if (!entdata) {
                return false;
            }

            if (ntaSharedLogic.hasRelation(tapwaterLogic.tapwDistributieBinnenData, entdata)) { // Als binnen hidden is en buiten niet
                if (binnenLabdaIsolatieShouldBeHidden()) {
                    return false;
                }
            }
            else if (ntaSharedLogic.hasRelation(tapwaterLogic.tapwDistributieBuitenData, entdata)) { // Als buiten hidden is en binnen niet
                if (buitenLabdaIsolatieShouldBeHidden()) {
                    return false;
                }
            }

            return true;
        };

        var labdaIsolatieIsHidden = function () {
            //-- Conditie TDT
            if (binnenLabdaIsolatieShouldBeHidden() && buitenLabdaIsolatieShouldBeHidden()) {
                return true;
            }

            return false;
        } //-- end: labdaIsolatieIsHidden ------------------------------------------------------------------------//

        var binnenLabdaIsolatieShouldBeHidden = function () {
            //-- Conditie TDT
            const binnenIsolatieLeidingen = tapwaterLogic.tapwDistributieBinnenData.PropertyDatas['TAPW-DISTR-BIN_ISO_CIRC'].Value;
            if (binnenIsolatieLeidingen !== 'TAPW-DISTR-BIN_ISO_CIRC_1' && !binnenEigenschappenDistributieleidingenIsHidden()) {
                return false;
            }

            return true;
        } //-- end: binnenLabdaIsolatieShouldBeHidden ------------------------------------------------------------------------//

        var buitenLabdaIsolatieShouldBeHidden = function () {
            //-- Conditie TDT
            const buitenIsolatieLeidingen = tapwaterLogic.tapwDistributieBuitenData.PropertyDatas['TAPW-DISTR-BUI_ISO_CIRC'].Value;
            if (buitenIsolatieLeidingen !== 'TAPW-DISTR-BIN_ISO_CIRC_1' && !buitenEigenschappenDistributieleidingenIsHidden()) {
                return false;
            }

            return true;
        } //-- end: buitenLabdaIsolatieShouldBeHidden ------------------------------------------------------------------------//

        tapwaterLogic.minCountVoorraadvaten = function () {
            let minCount = 0;

            //-- Conditie [TVA] toon de velden TV01 - TV12 in de volgende situaties:

            // 5. als in kolom 2 bij TO05 = hotfill boiler (met/zonder elektrisch element)
            const bSit5 = heeftHotfillBoiler();

            let opwekkerNr = 1;
            for (const opwekker of tapwaterLogic.tapwOpwekkersData) {
                let minCountOpwekker = 0;

                // Situatie 5 gaat al over de tweede kolom, dus daar moeten we situaties 1 t/m 4 niet ook nog eens checken.
                if (bSit5 && opwekkerNr === 2) continue;

                const opwekkerTypeValue = opwekker.PropertyDatas['TAPW-OPWEK_TYPE'].Value;
                const opwekkerType = tapwaterLogic.opwekkerTypes.find(x => x.Id === opwekkerTypeValue);
                if (opwekkerType) {
                    const propIndirVat = ntabuilding.properties['TAPW-OPWEK_INDIR']; // TO06

                    // 1. als TO06 is indirect gestookte warm watervoorraadvat(en) behalve als TO17 = 'voldoet aan NTA 8800 bijlage R / thermisch vermogen ≤ 500 kW en voldoet aan Bijlage R'. Kijk hierbij naar alle opwekkers.
                    let bSit1 = false;
                    const indirVat = propIndirVat.getValue(opwekker);
                    if (indirVat === 'TAPW-OPWEK_INDIR_WEL' && !tapwaterLogic.isHidden(propIndirVat, opwekker)) { //
                        bSit1 = true;

                        //-- wanneer er sprake is van een biomassatoestel ook nog controleren op emissie van dit toestel
                        const propEmissie = ntabuilding.properties['TAPW-OPWEK_EMISSIE']; // TO17
                        if (!tapwaterLogic.isHidden(propEmissie, opwekker)) {
                            const emissieBiomassatoestel = propEmissie.getValue(opwekker);
                            if (emissieBiomassatoestel === 'TAPW-OPWEK_EMISSIE_R' || emissieBiomassatoestel === 'TAPW-OPWEK_EMISSIE_E') {
                                bSit1 = false;
                            }
                        }
                    }

                    // 2. als key1 uit table13_0 op de rij van opwekker TO05 = 'EB'
                    const bSit2 = opwekkerType.key1 === 'EB';

                    // 3. als key1 uit table13_0 = 'WP' EN TO07 ≠ 'productspecifiek'  EN TO06 = 'warmtepomp met losse voorraadvat(en)
                    let bSit3 = false;
                    // 4. als key1 uit table13_0 = 'WP' EN TO07 = 'productspecifiek' EN W_model kolom 'COP_incl_vat_ls' = 'nee'
                    let bSit4 = false;
                    if (opwekkerType.key1 === 'WP') {
                        const invoerOpwekker = opwekker.PropertyDatas['TAPW-OPWEK_INV'].Value; // TO07
                        const indirectVerwarmdeVoorraadvaten = propIndirVat.getValue(opwekker); // TO06
                        if (invoerOpwekker !== 'TAPW-OPWEK_INV_PROD' && indirectVerwarmdeVoorraadvaten === 'TAPW-OPWEK_INDIR_LOS') {
                            bSit3 = true;
                        }

                        if (invoerOpwekker === 'TAPW-OPWEK_INV_PROD') {
                            const productWTOES = _getListItem('TAPW-OPWEK_TOES', opwekker);
                            if (productWTOES && productWTOES.COP_incl_vat_ls === false) {
                                bSit4 = true;
                            }
                        }
                    }

                    // Er moet minimaal 1 kolom zijn als bij conditie [TVA] sprake is van situatie 1 t/m 4 OF situatie 5.
                    // Als er sprake is van situatie 1 t/m 4 EN situatie 5 geldt een minimum van 2 kolommen.
                    if ((bSit1 || bSit2 || bSit3 || bSit4) && bSit5) {
                        minCountOpwekker = 2;
                    } else if (bSit1 || bSit2 || bSit3 || bSit4 || bSit5) {
                        minCountOpwekker = 1;
                    }
                }

                if (minCountOpwekker > minCount) {
                    minCount = minCountOpwekker;
                }
                opwekkerNr++;
            }

            while (tapwaterLogic.tapwVatenData.length < minCount) {
                tapwaterLogic.addVoorraadvat();
            }

            const relevant = minCount > 0;
            ntaEntityData.setEntityRelevancy(tapwaterLogic.tapwVatenData, relevant);
            ntaEntityData.setEntityVisibility(tapwaterLogic.tapwVatenData, relevant);

            return minCount;
        } //-- end: minCountVoorraadvaten ---------------------------------------------------------

        tapwaterLogic.addVoorraadvat = function (typeId) {
            const newId = ntaEntityData.create("TAPW-VAT", -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": tapwaterLogic.tapwDataId, "ParentEntityId": "TAPW" }], []);
            const newVat = ntaEntityData.get(newId);

            if (typeId) {
                const propdata = newVat.PropertyDatas["TAPW-VAT_INV"];
                ntaEntityData.saveprop(propdata, typeId);
            }

            tapwaterLogic.setTapwVaten();
            tapwaterLogic.setDefaultValuesVaten(newVat);
        } //-- end: addVoorraadvat ----------------------------------------------------------------

        tapwaterLogic.distributiepompTabelIsHidden = function () {
            // Conditie TDC
            let hidden = tapwaterLogic.leidingenIsHidden();

            //[MW-B]
            const propDistributiePompInv = ntabuilding.properties['TAPW-DISTR_CIRC_INV'];
            if (propDistributiePompInv.getValue(tapwaterLogic.tapwDistributieData) === 'TAPW-DISTR_CIRC_INV_4') { //pompvermogen onbekend, EEI onbekend
                hidden = hidden || ntaSharedLogic.isEditingMeasure();
            }

            //BM: uitgezet, want de pompen worden visisble of hidden via pompIsHidden
            //ntaEntityData.setEntityRelevancy(tapwaterLogic.tapwDistributiePompData, !hidden);
            //ntaEntityData.setEntityVisibility(tapwaterLogic.tapwDistributiePompData, !hidden);

            return hidden;
        };

        tapwaterLogic.allowMultiplePumps = function () {
            let propDistributiePomp = tapwaterLogic.tapwDistributieProperties['TAPW-DISTR_CIRC_INV'];
            let propdataDistributiepomp = tapwaterLogic.tapwDistributieData.PropertyDatas['TAPW-DISTR_CIRC_INV'];
            if (!tapwaterLogic.isHidden(propDistributiePomp, tapwaterLogic.tapwDistributieData) && propdataDistributiepomp.Value && propdataDistributiepomp.Value === 'TAPW-DISTR_CIRC_INV_4') {
                return false;
            }
            return true;
        };

        tapwaterLogic.pompIsHidden = function (pomp) {
            let showit = true;
            if (!tapwaterLogic.allowMultiplePumps()) {
                if (tapwaterLogic.pompNr(pomp) > 1) {
                    showit = false;
                }
            }
            ntaEntityData.setEntityRelevancy(pomp, showit);
            ntaEntityData.setEntityVisibility(pomp, showit);
            return !showit;
        };

        tapwaterLogic.checkPrioOpwekker = function (opwekker, newOpwekkertype = undefined) {
            let valid = true;

            //-- Conditie [TOB]
            const opwekkerPrio = getOpwekkerPrio(opwekker, newOpwekkertype);
            if (!opwekkerPrio) return valid;

            const opwekkers = tapwaterLogic.tapwOpwekkersData;
            const opwekkerIndex = opwekkers.indexOf(opwekker);

            const propdatasToClear = [];
            for (let i = 0; i < opwekkers.length; i++) {
                const prio = getOpwekkerPrio(opwekkers[i]);
                if (prio) {
                    if (i < opwekkerIndex) {        // een vorige opwekker
                        const warn = opwekkerPrio < prio;
                        if (warn) {
                            propdatasToClear.push(opwekker.PropertyDatas['TAPW-OPWEK_TYPE']);
                            valid = false;
                            break;
                        }
                    } else if (opwekkerIndex < i) { // een volgende opwekker
                        const warn = prio < opwekkerPrio;
                        if (warn) {
                            propdatasToClear.push(...opwekkers.slice(i).map(opw => opw.PropertyDatas['TAPW-OPWEK_TYPE']));
                            break;
                        }
                    }
                }
            }
            if (propdatasToClear.length) {
                ntaMeldingen.warning('[W021]'); // Onjuiste invoer
                ntaEntityData.saveprops(propdatasToClear, '');
            }

            return valid;
        } //-- end: checkPrioOpwekker ------------------------------------------------------------------------//

        function getOpwekkerPrio(opwekker, opwekkertypeValue = opwekker.PropertyDatas['TAPW-OPWEK_TYPE'].Value) {
            const opwekkertype = tapwaterLogic.opwekkerTypes.find(x => x.Id === opwekkertypeValue);
            return opwekkertype && opwekkertype.prio;
        } //-- end: getOpwekkerPrio ---------------------------------------------------------------

        tapwaterLogic.setEnergieFractie = function () {
            const propEnergiefractie = ntabuilding.properties['TAPW-OPWEK_ENER_NON'];

            if (tapwaterLogic.tapwOpwekkersData.length === 1) {

                const opwekker = tapwaterLogic.tapwOpwekkersData[0];
                const invoerOpwekker = opwekker.PropertyDatas['TAPW-OPWEK_INV'].Value;

                if (invoerOpwekker === 'TAPW-OPWEK_INV_FORF' || invoerOpwekker === 'TAPW-OPWEK_INV_PROD' || invoerOpwekker === 'TAPW-OPWEK_INV_EIG_REND') {
                    const energiefractieOpwekker = ntaRounding.roundAndAddZerosNewValue(propEnergiefractie, 1);
                    tapwaterLogic.saveValue(propEnergiefractie, opwekker, energiefractieOpwekker);
                }

            } else if (tapwaterLogic.tapwOpwekkersData.length === 2) {

                const opwekker1 = tapwaterLogic.tapwOpwekkersData[0];
                const invoerOpwekker1 = opwekker1.PropertyDatas['TAPW-OPWEK_INV'].Value;

                if (invoerOpwekker1 === 'TAPW-OPWEK_INV_EIG_REND_FRAC' || invoerOpwekker1 === 'TAPW-OPWEK_INV_EIG_REND_FRAC_HULP') {
                    const opwekker2 = tapwaterLogic.tapwOpwekkersData[1];

                    const energieFractieOpwekker1 = opwekker1.PropertyDatas['TAPW-OPWEK_ENER'].Value;
                    let numEnergieFractieOpwekker1 = ntaSharedLogic.parseFloat(energieFractieOpwekker1);

                    if (!isNaN(numEnergieFractieOpwekker1)) {
                        const numEnergieFractieOpwekker2 = 1 - numEnergieFractieOpwekker1;
                        const energieFractieOpwekker2 = ntaRounding.roundAndAddZerosNewValue(propEnergiefractie, numEnergieFractieOpwekker2);
                        tapwaterLogic.saveValue(propEnergiefractie, opwekker2, energieFractieOpwekker2);
                    }
                }

            }
        };

        function saveKoppelingOpwekkerInstallSysteem(propdata, entdata, systemtype) {
            // Als propdata niet relevant is, hoeft er niets te gebeuren
            //kaartje T9iwkMcQ: Bij het wijzigen van de aanlevertemp, wordt "TAPW-OPWEK_WARMTE_UIT_VERW" gevalideerd.
            // Als deze een value heeft, werd de huidige relatie tussen TAP-OPWEK en VERW verwijderd. Ook als "TAPW-OPWEK_WARMTE_UIT_VERW" helemaal niet relevant was.
            if (!propdata.Relevant) {
                return;
            }

            // eventuele koppeling zoeken met een installatiesysteem
            const relation = ntaEntityData.getChildRelations(entdata, systemtype)[0];

            // Als er al een relatie met dit installatiesysteem bestaat, hoeft er niets te gebeuren
            if (relation && relation.Child === propdata.Value) {
                return;
            }

            // oude koppeling verwijderen
            if (relation) {
                // Controleer eerst of de propdata.Value wel verwijst naar een correcte entdata; anders laten we de bestaande relatie ongemoeid.
                const teKoppelenEntdata = ntaEntityData.get(propdata.Value);
                if (!teKoppelenEntdata || teKoppelenEntdata.EntityId !== systemtype) {
                    tapwaterLogic.saveValue(propdata.PropertyId, entdata, relation && relation.Child || null);
                    return;
                }

                ntaEntityData.deleteRelation(relation.EntityRelationDataId);
            }

            // koppeling toevoegen
            if (systemtype === 'KOEL') {
                //-- voor het koelsysteem dat geselecteerd is moet KOEL-OPWEK_TYPE op KOEL-OPWEK_TYPE_11 gezet worden (KOEL-OPWEK_TYPE_11=boosterwarmtepomp) (Conditie KOAQ)
                const koelEntityData = ntaEntityData.get(propdata.Value);
                if (koelEntityData) {
                    // koppeling toevoegen
                    ntaEntityData.createRelation(entdata.EntityDataId, propdata.Value, false, false);

                    // alleen als er sprake is van een booster systeem moet conditie [KOAQ] uitgevoerd worden op de koelopwekkers
                    const koelOpwekEntityData = ntaEntityData.getFirstChild(koelEntityData, 'KOEL-OPWEK');
                    if (koelOpwekEntityData && propdata.PropertyId === 'TAPW-OPWEK_BRON_BOOS_KOEL') {
                        const koelOpwekInvoerPropdata = koelOpwekEntityData.PropertyDatas['KOEL-OPWEK_INVOER'];
                        const koelOpwekTypePropdata = koelOpwekEntityData.PropertyDatas['KOEL-OPWEK_TYPE'];
                        if (koelOpwekInvoerPropdata.Value !== 'VERW-OPWEK_INVOER_PROD' || koelOpwekTypePropdata.Value !== 'KOEL-OPWEK_TYPE_11') {
                            // Maak alle velden van de koelingopwekker leeg
                            koelOpwekEntityData.PropertyDatas.forEach(function (koelingPropdata, index) {
                                let newValue = ''; // maak alle properties leeg, behalve...
                                switch (koelingPropdata.PropertyId) {
                                    case koelOpwekInvoerPropdata.PropertyId: // invoer = productspecifiek
                                        newValue = 'VERW-OPWEK_INVOER_PROD';
                                        break;
                                    case koelOpwekTypePropdata.PropertyId: // opwekkertype = boosterwarmtepomp
                                        newValue = 'KOEL-OPWEK_TYPE_11';
                                        break;
                                }
                                ntaEntityData.saveprop(koelingPropdata, newValue);
                            });
                        }
                    }
                }
            } else {
                ntaEntityData.createRelation(entdata.EntityDataId, propdata.Value, false, false);
            }
        } //-- end: saveKoppelingOpwekkerInstallSysteem ------------------------------------------------------------------------//

        function deleteKoppelingOpwekkerInstallSysteem(entdata, systemtype, prop = null) {
            // eventuele koppeling zoeken met een installatiesysteem
            const relation = ntaEntityData.getChildRelations(entdata, systemtype)[0];

            // koppeling verwijderen
            if (relation) {
                //-- voor verwarming controleren of deze misschien niet voor de andere property wordt gebruikt [TAPW-OPWEK_WARMTE_UIT_VERW, TAPW-OPWEK_BRON_BOOS]
                let deleteRelation = true;
                if (prop && systemtype === 'VERW') {
                    //-- verwijder alleen de relatie als geen van de andere propdatas relevant is
                    const otherPropIdsRelationVerw = ['TAPW-OPWEK_WARMTE_UIT_VERW', 'TAPW-OPWEK_BRON_BOOS', 'TAPW-OPWEK_VERW-OPWEK']
                        .filter(propId => propId !== prop.Id);
                    deleteRelation = ! otherPropIdsRelationVerw.some(propId => entdata.PropertyDatas[propId] && entdata.PropertyDatas[propId].Relevant);
                } else if (prop && systemtype === 'KOEL') {
                    //-- verwijder alleen de relatie als beide niet relevant zijn
                    const propRelationKoel = ['TAPW-OPWEK_BRON_BOOS_KOEL', 'TAPW-OPWEK_WP_KOELSYS'];
                    const index = (propRelationKoel.indexOf(prop.Id) + 1) % 2;
                    deleteRelation = !entdata.PropertyDatas[propRelationKoel[index]].Relevant;
                }

                if (deleteRelation) {
                    ntaEntityData.deleteRelation(relation.EntityRelationDataId);
                }
            }
        } //-- end: deleteKoppelingOpwekkerInstallSysteem -----------------------------------------

        var saveKoppelingOpstelplaats = function (propdata, entdata) {
            const relation = ntaEntityData.getParentRelations(entdata, 'RZ')[0];

            if (relation && relation.Parent === propdata.Value) {
                return;
            }
            // oude koppeling verwijderen
            if (relation) {
                ntaEntityData.deleteRelation(relation.EntityRelationDataId);
            }
            // koppeling toevoegen
            if (propdata.Value !== "TAPW-VAT_OPSTEL_BUI" && propdata.Value !== "TAPW-OPWEK_OPSTEL_BUI") { //voorraadvat(en) buiten verwarmde zone  -> geen relatie opslaan
                ntaEntityData.createRelation(propdata.Value, entdata.EntityDataId, false, false);
            }
        } //-- end: saveKoppelingOpstelplaats ------------------------------------------------------------------------//

        function determineAgTotaal() {
            const gebouwType = ntaSharedLogic.getGebouwType();
            const prop = ntabuilding.properties['TAPW-OPWEK_A'];

            //-- Initial value TO10
            if (gebouwType === 'TGEB_APPGEB' || gebouwType === 'TGEB_UTILIT' && ntaSharedLogic.perGebouwEnUnit() || ntaSharedLogic.voorProjectwoningen()) {
                //-- VO 2021-10-13: default is de waarde voor totaal Ag gemeenschappelijk de totale Ag van het gebouw incl gem. ruimten
                return ntaRounding.roundAndAddZerosNewValue(prop, ntaSharedLogic.AgTotaal());
            }

            return '';
        } //-- end: determineAgTotaal -------------------------------------------------------------

        function determineFirstVerwSys(propdata) {
            const installation = ntaEntityData.getListWithEntityId('INSTALLATIE')
                .find(ed => ed.PropertyDatas['INSTALL_TYPE'].Value === 'INST_VERW');
            const verw = ntaEntityData.getFirstChild(installation, 'VERW');

            // We moeten zometeen (als de waarde eenmaal is toegekend) ook de relatie opslaan.
            const entdata = ntaEntityData.get(propdata.EntityDataId);
            time.delayActions(() => saveKoppelingOpwekkerInstallSysteem(propdata, entdata, 'VERW'));

            return verw.EntityDataId;
        } //-- end: determineFirstVerwSys ---------------------------------------------------------

        tapwaterLogic.setDefaultValuesOpwekker = function (opwekker = null) {
            if (!opwekker) {
                tapwaterLogic.tapwOpwekkersData.forEach(tapwaterLogic.setDefaultValuesOpwekker);
            } else {
                const propIdsWithValueGetters = {
                    'TAPW-OPWEK_FABR_WKK':      () => determineFabricagejaar(true),
                    'TAPW-OPWEK_FABR':          () => determineFabricagejaar(false),
                    'TAPW-OPWEK_A':             determineAgTotaal,
                    'TAPW-OPWEK_VERW-OPWEK':    determineFirstVerwSys,
                };
                for (const [propId, getValue] of Object.entries(propIdsWithValueGetters)) {
                    const propdata = opwekker.PropertyDatas[propId];
                    if (propdata && !propdata.Touched) {
                        ntaEntityData.saveprop(propdata, getValue(propdata));
                    }
                }

                opwekker.filteredItems = [];
                opwekker.filteredItemsRegios = [];
                opwekker.filteredItemsBron = [];
            }
        } //-- end: setDefaultValuesOpwekker ------------------------------------------------------
        tapwaterLogic.setDefaultValuesOpwekker();

        tapwaterLogic.setGeopend = function () {
            const propdataOpen = tapwaterLogic.tapwData.PropertyDatas['TAPW_OPEN'];
            ntaEntityData.saveprop(propdataOpen, 'true');
        }; //-- end: setGeopend -------------------------------------------------------------------

        // CRUD
        tapwaterLogic.addPompProgress = false;
        tapwaterLogic.addPomp = function (pomp) {
            tapwaterLogic.addPompProgress = true;
            let order = -1;
            if (pomp) {
                order = pomp.Order;
            }
            let newId = ntaEntityData.create('TAPW-DISTR-POMP', order, [{ "OnCopy": 1, "OnDelete": 1, "Parent": tapwaterLogic.tapwDistributieData.EntityDataId, "ParentEntityId": "TAPW-DISTR" }], [], []);
            tapwaterLogic.tapwDistributiePompData = ntaEntityData.getChildren(tapwaterLogic.tapwDistributieData, 'TAPW-DISTR-POMP');
            tapwaterLogic.addPompProgress = false;
        };

        tapwaterLogic.copyPomp = function (pomp) {
            if (!pomp) {
                return;
            }
            ntaEntityData.copy(pomp.EntityDataId);
            tapwaterLogic.tapwDistributiePompData = ntaEntityData.getChildren(tapwaterLogic.tapwDistributieData, 'TAPW-DISTR-POMP');
        };

        tapwaterLogic.deletePomp = function (pomp) {
            if (!pomp) {
                return;
            }

            if (tapwaterLogic.tapwDistributiePompData.length <= 1) {
                return;
            }

            ntaEntityData.delete(pomp.EntityDataId, true);
            tapwaterLogic.tapwDistributiePompData = ntaEntityData.getChildren(tapwaterLogic.tapwDistributieData, 'TAPW-DISTR-POMP');
        };

        var saveKoppelingOpwekkerEnRekenzone = function (propdata, opwekkerEntdata) {
            // koppeling zoeken met rekenzone (er kan maar één relatie zijn tussen TAPW-OPWEK <<-->> RZ zijn)
            const relation = ntaEntityData.getChildRelations(opwekkerEntdata, 'RZ')[0];

            // Als er al een relatie met deze rekenzone bestaat, hoeft er niets te gebeuren
            if (relation && relation.Child === propdata.Value) {
                return;
            }

            // oude koppeling verwijderen
            if (relation) {
                ntaEntityData.deleteRelation(relation.EntityRelationDataId);
            }

            // koppeling toevoegen
            if (propdata.Value !== 'TAPW-OPWEK_VERBR_OPS_BUI') {
                ntaEntityData.createRelation(opwekkerEntdata.EntityDataId, propdata.Value, false, false);
            }
        } //-- end: saveKoppelingOpwekkerEnRekenzone ------------------------------------------------------------------------//


        // Ontvangt afhankelijk veld dat gevalideerd moet worden
        tapwaterLogic.validateDependencies = function (prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = prop.getData(entdata);

            //-- VO 2022-11-03: onderstaande code waarbij de prop.Id in een if-statement zitten, komt uit de saveValue van tapwater,
            //-- maar de opslaan routine moet gelijk worden aan de reeds gerefactorde factories (bv verwarming) omdat het anders niet
            //-- goed gaat met de unitspecifiekeresultaten voor de projectwoningen. Daarom deze onderstaande code (die eigenlijk al thuis
            //-- hoorde in de validateDependencies) even hier in geplakt. Nu kan ik de saveValue van de sharedLogic aanroepen en alles
            //-- eenduidig afhandeld. Later moet deze code met if (prop.id === .....) code omgezet worden naar een switch en samengevoegd
            //-- worden met de propertys die verderop in deze functie al in een switch zitten.
            if (prop.Id === 'TAPW-DISTR-BIN_ISO_CIRC') {
                let propdataBuiten = tapwaterLogic.tapwDistributieBuitenData.PropertyDatas['TAPW-DISTR-BUI_ISO_CIRC'];

                if (!propdataBuiten.Touched || !propdataBuiten.Value) {
                    propdataBuiten.Value = propdata.Value;
                    tapwaterLogic.validate(null, propdataBuiten);
                    ntaEntityData.saveprop(propdataBuiten);
                }
            }
            if (prop.Id === 'TAPW-OPWEK_BRON_BOOS') {
                if (propdata.Value) {
                    saveKoppelingOpwekkerInstallSysteem(propdata, entdata, 'VERW');
                }
            }
            if (prop.Id === 'TAPW-OPWEK_BRON_BOOS_KOEL') {
                if (propdata.Value) {
                    saveKoppelingOpwekkerInstallSysteem(propdata, entdata, 'KOEL');
                }
            }
            if (prop.Id === 'TAPW-OPWEK_WP_KOELSYS') {
                if (propdata.Value) {
                    saveKoppelingOpwekkerInstallSysteem(propdata, entdata, 'KOEL');
                }
            }
            if (prop.Id === 'TAPW-OPWEK_WP_VENTSYS') {
                if (propdata.Value) {
                    saveKoppelingOpwekkerInstallSysteem(propdata, entdata, 'VENT');
                }
            }
            if (prop.Id === 'TAPW-OPWEK_WARMTE_UIT_VERW') {
                if (propdata.Value) {
                    saveKoppelingOpwekkerInstallSysteem(propdata, entdata, 'VERW');
                }
            }
            if (prop.Id === 'TAPW-OPWEK_VERW-OPWEK') {
                if (propdata.Relevant && propdata.Value) {
                    saveKoppelingOpwekkerInstallSysteem(propdata, entdata, 'VERW');
                } else {
                    deleteKoppelingOpwekkerInstallSysteem(entdata, 'VERW', prop);
                }
            }
            if (prop.Id === 'TAPW-OPWEK_TYPE') { // TO05
                tapwaterLogic.checkPrioOpwekker(entdata);

                const propToestel = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_TOES'];
                tapwaterLogic.getSelectionTable(propToestel, entdata, "");
                tapwaterLogic.setDescriptionInstallation(entdata);
                _setSelectionTableValues(entdata, 'W');
                tapwaterLogic.validate(null, entdata.PropertyDatas['TAPW-OPWEK_GEM']);

                tapwaterLogic.isHidden(ntabuilding.properties['TAPW-OPWEK_BRON_POMP'], entdata);
                tapwaterLogic.getSelectionTable(ntabuilding.properties['TAPW-OPWEK_BRON_POMP'], entdata);

                // Conditie [TODA] als TO05 in de eerste kolom is 'opwekker van verwarmingssysteem' toon automatisch een 2e kolom (geen button)
                if (propdata.Value === 'TAPW-OPWEK_TYPE_24' && tapwaterLogic.opwekkerNr(entdata) === 1) { // opwekker van verwarmingssysteem
                    while (tapwaterLogic.tapwOpwekkersData.length < 2) {
                        tapwaterLogic.addOpwekker();
                    }
                }

                // Conditie [TWP]
                if (propdata.Value === 'TAPW-OPWEK_TYPE_9') {
                    const warning = ntabuilding.warnings.find(w => w.Id === "[W027]").Value;

                    let confirm = $mdDialog.confirm()
                        .title('Wijzigen invoer douche-wtw')
                        .textContent(warning)
                        .ariaLabel('Wijzigen invoer douche-wtw')
                        .targetEvent(event)
                        .ok('Ja')
                        .cancel('Nee');

                    $mdDialog.show(confirm).then(function () {
                        for (const tapwDoucheData of tapwaterLogic.tapwDoucheData) {
                            ntaEntityData.delete(tapwDoucheData.EntityDataId);
                        }
                        tapwaterLogic.setTapwDouche();
                        time.delayActions(() => {
                            tapwaterLogic.validate(null, entdata.PropertyDatas['TAPW-OPWEK_WARMTE_UIT_VERW']);
                        });
                    }, function () {
                        ntaEntityData.saveprop(propdata, '');
                    });
                }

                time.delayActions(() => {
                    tapwaterLogic.validate(null, entdata.PropertyDatas['TAPW-OPWEK_WARMTE_UIT_VERW']);
                    tapwaterLogic.validate(null, entdata.PropertyDatas['TAPW-OPWEK_VERW-OPWEK']);
                });
            }
            //-- onderstaand properties hebben allemaal invloed op de mogelijk te selectere producten uit de selectietabel
            if (prop.Id === 'TAPW-OPWEK_INV' || prop.Id === 'TAPW-OPWEK_INDIR' || prop.Id === 'TAPW-OPWEK_FUNC' || prop.Id === 'TAPW-OPWEK_POS' || prop.Id === 'TAPW-OPWEK_CW' ||  prop.Id === 'TAPW-OPWEK_GEM' ||
                prop.Id === 'TAPW-OPWEK_EMISSIE' || prop.Id === 'TAPW-OPWEK_FABR_WKK' || prop.Id === 'TAPW-OPWEK_BRON_POMP' || prop.Id === 'TAPW-OPWEK_REGIO') { // TO07 - TO06 - TO21 - TO16 - TO17 - TO18 - TO19 - TO20 - TO22
                let propToestel = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_TOES'];
                tapwaterLogic.getSelectionTable(propToestel, entdata, "");
                _setSelectionTableValues(entdata, 'W');
            }

            if (prop.Id === 'TAPW-OPWEK_TYPE' || prop.Id === 'TAPW-OPWEK_INV') {
                //-- conditie [TOAH deel 2]
                let propFunctie = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_FUNC'];
                let propdataFunctie = entdata.PropertyDatas[propFunctie.Id];
                if (tapwaterLogic.isHidden(propFunctie, entdata) && propdataFunctie.Value === 'TAPW-OPWEK_FUNC_COMBI') {
                    propdataFunctie.Value = 'TAPW-OPWEK_FUNC_SOLO';
                    ntaEntityData.saveprop(propdataFunctie);
                }

                tapwaterLogic.setEnergieFractie();

                // Foutmelding geen toestellen controleren
                time.delayActions(function () {
                    const propToestel = tapwaterLogic.tapwOpwekkerProperties['TAPW-OPWEK_TOES'];
                    tapwaterLogic.getSelectionTable(propToestel, entdata);
                });
            }

            if (prop.Id === 'TAPW-OPWEK_INV') {
                tapwaterLogic.validate(null, entdata.PropertyDatas['TAPW-OPWEK_ENER']);
                tapwaterLogic.validate(null, entdata.PropertyDatas['TAPW-OPWEK_ENER_NON']);
            }

            if (prop.Id === 'TAPW-OPWEK_TOES') { // TO22
                tapwaterLogic.setEnergieFractie();
                tapwaterLogic.setDescriptionInstallation(entdata);
            }
            if (prop.Id === 'TAPW-OPWEK_ENER') { // TO33
                tapwaterLogic.setEnergieFractie();
            }
            if (prop.Id === 'TAPW-OPWEK_OPSTEL') {
                if (propdata.Value) {
                    saveKoppelingOpstelplaats(propdata, entdata);
                }
            }
            if (prop.Id === 'TAPW-OPWEK_VERBR_OPS') { // TO55
                if (propdata.Value) {
                    saveKoppelingOpwekkerEnRekenzone(propdata, entdata);
                }
            }

            if (prop.Id === 'TAPW-DISTR-BIN_INV' || prop.Id === 'TAPW-DISTR-BIN_ISO_CIRC') { // Zet dekking en labda constructie op n.v.t.
                let propBinnenDekking = tapwaterLogic.tapwDistributieEigenschappenLeidingenProperties['TAPW-DISTR-EIG_DEK'];
                let propBinnenLabdaConstructie = tapwaterLogic.tapwDistributieEigenschappenLeidingenProperties['TAPW-DISTR-EIG_LAB_CON'];
                let propBinnenLabdaIsolatie = tapwaterLogic.tapwDistributieEigenschappenLeidingenProperties['TAPW-DISTR-EIG_LAB_ISO'];
                let propBinnenLabdaLeiding = tapwaterLogic.tapwDistributieEigenschappenLeidingenProperties['TAPW-DISTR-EIG_LAB_LEI'];
                let propdataBinnenDekking = tapwaterLogic.tapwDistributieEigenschappenLeidingenBinnenData.PropertyDatas[propBinnenDekking.Id];
                let propdataBinnenLabdaConstructie = tapwaterLogic.tapwDistributieEigenschappenLeidingenBinnenData.PropertyDatas[propBinnenLabdaConstructie.Id];
                let propdataBinnenLabdaIsolatie = tapwaterLogic.tapwDistributieEigenschappenLeidingenBinnenData.PropertyDatas[propBinnenLabdaIsolatie.Id];
                let propdataBinnenLabdaLeiding = tapwaterLogic.tapwDistributieEigenschappenLeidingenBinnenData.PropertyDatas[propBinnenLabdaLeiding.Id];

                if (binnenDekkingShouldBeHidden()) { //-- Als de rij van binnen niet zichtbaar hoort te zijn nvt invullen
                    propdataBinnenDekking.Value = 'n.v.t.';
                    propdataBinnenLabdaConstructie.Value = 'n.v.t.';
                    tapwaterLogic.validate(propBinnenDekking, propdataBinnenDekking);
                    tapwaterLogic.validate(propBinnenLabdaConstructie, propdataBinnenLabdaConstructie);
                    ntaEntityData.saveprop(propdataBinnenDekking);
                    ntaEntityData.saveprop(propdataBinnenLabdaConstructie);
                }
                else { //-- Reset
                    if (propdataBinnenDekking.Value === 'n.v.t.') {
                        propdataBinnenDekking.Value = '';
                        ntaEntityData.saveprop(propdataBinnenDekking);
                    }
                    if (propdataBinnenLabdaConstructie.Value === 'n.v.t.') {
                        propdataBinnenLabdaConstructie.Value = '';
                        ntaEntityData.saveprop(propdataBinnenLabdaConstructie);
                    }
                }

                if (binnenLeidingIsOnGeisoleerd()) { // Als de rij van binnen niet zichtbaar hoort te zijn nvt invullen
                    if (propdataBinnenLabdaIsolatie.Value !== 'n.v.t.') {
                        propdataBinnenLabdaIsolatie.Value = 'n.v.t.';
                        tapwaterLogic.validate(propBinnenLabdaIsolatie, propdataBinnenLabdaIsolatie);
                        ntaEntityData.saveprop(propdataBinnenLabdaIsolatie);
                    }
                    propdataBinnenLabdaLeiding.Value = '';
                    ntaEntityData.saveprop(propdataBinnenLabdaLeiding);
                }
                else {
                    if (propdataBinnenLabdaLeiding.Value !== 'n.v.t.') {
                        propdataBinnenLabdaLeiding.Value = 'n.v.t.';
                        tapwaterLogic.validate(propBinnenLabdaLeiding, propdataBinnenLabdaLeiding);
                        ntaEntityData.saveprop(propdataBinnenLabdaLeiding);
                    }
                    propdataBinnenLabdaIsolatie.Value = '';
                    ntaEntityData.saveprop(propdataBinnenLabdaIsolatie);
                }
            }

            if (prop.Id === 'TAPW-DISTR-BUI_INV' || prop.Id === 'TAPW-DISTR-BUI_ISO_CIRC') { // Zet dekking en labda constructie op n.v.t.
                let propBuitenDekking = tapwaterLogic.tapwDistributieEigenschappenLeidingenProperties['TAPW-DISTR-EIG_DEK'];
                let propBuitenLabdaConstructie = tapwaterLogic.tapwDistributieEigenschappenLeidingenProperties['TAPW-DISTR-EIG_LAB_CON'];
                let propBuitenLabdaIsolatie = tapwaterLogic.tapwDistributieEigenschappenLeidingenProperties['TAPW-DISTR-EIG_LAB_ISO'];
                let propBuitenLabdaLeiding = tapwaterLogic.tapwDistributieEigenschappenLeidingenProperties['TAPW-DISTR-EIG_LAB_LEI'];
                let propdataBuitenDekking = tapwaterLogic.tapwDistributieEigenschappenLeidingenBuitenData.PropertyDatas[propBuitenDekking.Id];
                let propdataBuitenLabdaConstructie = tapwaterLogic.tapwDistributieEigenschappenLeidingenBuitenData.PropertyDatas[propBuitenLabdaConstructie.Id];
                let propdataBuitenLabdaIsolatie = tapwaterLogic.tapwDistributieEigenschappenLeidingenBuitenData.PropertyDatas[propBuitenLabdaIsolatie.Id];
                let propdataBuitenLabdaLeiding = tapwaterLogic.tapwDistributieEigenschappenLeidingenBuitenData.PropertyDatas[propBuitenLabdaLeiding.Id];

                if (buitenDekkingShouldBeHidden()) { // Als de rij van buiten niet zichtbaar hoort te zijn nvt invullen
                    propdataBuitenDekking.Value = 'n.v.t.';
                    propdataBuitenLabdaConstructie.Value = 'n.v.t.';
                    tapwaterLogic.validate(propBuitenDekking, propdataBuitenDekking);
                    tapwaterLogic.validate(propBuitenLabdaConstructie, propdataBuitenLabdaConstructie);
                    ntaEntityData.saveprop(propdataBuitenDekking);
                    ntaEntityData.saveprop(propdataBuitenLabdaConstructie);
                }
                else { //-- Reset
                    if (propdataBuitenDekking.Value === 'n.v.t.') {
                        propdataBuitenDekking.Value = '';
                        ntaEntityData.saveprop(propdataBuitenDekking);
                    }

                    if (propdataBuitenLabdaConstructie.Value === 'n.v.t.') {
                        propdataBuitenLabdaConstructie.Value = '';
                        ntaEntityData.saveprop(propdataBuitenLabdaConstructie);
                    }
                }

                if (buitenLeidingIsOngeisoleerd()) { // Als de rij van buiten niet zichtbaar hoort te zijn nvt invullen
                    if (propdataBuitenLabdaIsolatie.Value !== 'n.v.t.') {
                        propdataBuitenLabdaIsolatie.Value = 'n.v.t.';
                        tapwaterLogic.validate(propBuitenLabdaIsolatie, propdataBuitenLabdaIsolatie);
                        ntaEntityData.saveprop(propdataBuitenLabdaIsolatie);
                    }
                    propdataBuitenLabdaLeiding.Value = '';
                    ntaEntityData.saveprop(propdataBuitenLabdaLeiding);
                }
                else {
                    if (propdataBuitenLabdaLeiding.Value !== 'n.v.t.') {
                        propdataBuitenLabdaLeiding.Value = 'n.v.t.';
                        tapwaterLogic.validate(propBuitenLabdaLeiding, propdataBuitenLabdaLeiding);
                        ntaEntityData.saveprop(propdataBuitenLabdaLeiding);
                    }
                    propdataBuitenLabdaIsolatie.Value = '';
                    ntaEntityData.saveprop(propdataBuitenLabdaIsolatie);
                }

            }
            if (prop.Id === 'TAPW-VAT_INV') {
                _setSelectionTableValues(entdata, 'BVAT');
            }

            if (prop.Id === 'TAPW-VAT_OPSTEL') {
                if (propdata.Value) {
                    saveKoppelingOpstelplaats(propdata, entdata);
                }
            }

            if (prop.Id === 'TAPW-VAT_AANSL') { // TV13
                if (propdata.Value === 'TAPW-VAT_AANSL_7') { // hotfill boiler
                    //-- Conditie [TVO] sta deze keuze maar bij 1 vat toe. Als deze keuze wordt gemaakt
                    // terwijl bij een ander vat in hetzelfde tapwatersysteem is gekozen voor hotfill boiler,
                    // maak dan het veld bij het ander vat leeg.
                    const otherHotfillVats = ntaEntityData.findEntities(entdata, '^TAPW.TAPW-VAT')
                        .filter(vat => vat !== entdata)
                        .filter(vat => prop.getValue(vat) === 'TAPW-VAT_AANSL_7'); // TV13 = hotfill boiler
                    for (const vat of otherHotfillVats) {
                        tapwaterLogic.saveValue(prop, vat, prop.DefaultValue);
                    }
                }
            }

            if (prop.Id === 'TAPW-DOUCHE_AANG') {
                //-- Conditie [TWC]
                if (tapwaterLogic.tapwDoucheData.length > 1) {
                    if (propdata.Value === 'TAPW-DOUCHE_AANG_ONB') {
                        const warning = ntabuilding.warnings.find(function (x) { return x.Id === "[W017]"; }).Value;

                        const confirm = $mdDialog.confirm()
                            .title('Wijzigen invoer douche-wtw')
                            .textContent(warning)
                            .ariaLabel('Wijzigen invoer douche-wtw')
                            .targetEvent(event)
                            .ok('Ja')
                            .cancel('Nee');

                        $mdDialog.show(confirm).then(function () {
                            for (const tapwDoucheData of tapwaterLogic.tapwDoucheData.slice(1)) {
                                ntaEntityData.delete(tapwDoucheData.EntityDataId);
                            }
                            tapwaterLogic.setTapwDouche();
                        }, function () {
                            ntaEntityData.saveprop(propdata, 'TAPW-DOUCHE_AANG_BEK');
                        });
                    }

                }
            }
            if (prop.Id === 'TAPW-DOUCHE_INV' || prop.Id === 'TAPW-DOUCHE_TYPE') {
                let propDWTW = tapwaterLogic.tapwDouchewarmteterugwinningProperties['TAPW-DOUCHE_WTW_TOES'];
                tapwaterLogic.getSelectionTableDWTW(propDWTW, entdata, "");
                _setSelectionTableValues(entdata, 'DWTW');
            }

            if (prop.Id === 'TAPW-DOUCHE_AANT_TOT') {
                tapwaterLogic.tapwDoucheData.forEach(function (doucheWTW, index) {
                    doucheWTW.tempUnits.forEach(function (tempUnit, index2) {
                        let propAangeslotenDouches = tapwaterLogic.tapwDouchesAangeslotenOpWTWProperties['TAPW-DOUCHE-AANG_AANT'];
                        let propdataAangeslotenDouches = tempUnit.douche.PropertyDatas[propAangeslotenDouches.Id];
                        tapwaterLogic.validate(propAangeslotenDouches, propdataAangeslotenDouches);
                    });
                });
            }
            if (prop.Id === 'TAPW-DOUCHE_AANT_TOT' || prop.Id === 'TAPW-DOUCHE_AANT_TYPE') {
                tapwaterLogic.tapwDoucheData.forEach(function (doucheWTW, index) {
                    // Valideer volgens conditie TWK
                    const propAantalAangeslotenDouches = ntabuilding.properties['TAPW-DOUCHE_AANT_TYPE'];
                    const propdataAantalAangeslotenDouches = doucheWTW.PropertyDatas[propAantalAangeslotenDouches.Id];
                    tapwaterLogic.validate(propAantalAangeslotenDouches, propdataAantalAangeslotenDouches);
                });
            }
            if (prop.Id === 'TAPW-DOUCHE-AANG_AANT') { // TODO: klopt dit nog?
                //-- wanneer het aantal douchewtw's aangesloten op DWTW wijzigt, moet dit aantal verdisconteerd worden in het gezamelijke aantal douches op deze wtw in de entity TAPW-DOUCHE
                //-- dit aantal wordt namelijk bij de rekenkoppeling meegegeven aan de rekenkern. De rekenkern kan namelijk nogn iet omgaan met rekenen per unit. Dan moet dit ws weer anders.
                const doucheWTW = ntaEntityData.getFirstParent(entdata, 'TAPW-DOUCHE');
                if (doucheWTW) {
                    let aantalPerDWTW = 0;
                    let propdataAantalDoucheWTW = doucheWTW.PropertyDatas['TAPW-DOUCHE_AANT_TYPE'];
                    doucheWTW.tempUnits.forEach(function (tempUnit, index) {
                        let propdataAangeslotenDouches = tempUnit.douche.PropertyDatas['TAPW-DOUCHE-AANG_AANT'];
                        aantalPerDWTW += parseInt(propdataAangeslotenDouches.Value);
                    });
                    //-- niet naar de saveValue ander kom ik in een kringetje
                    ntaEntityData.saveprop(propdataAantalDoucheWTW, aantalPerDWTW);
                }
            }
            if (prop.Id === 'TAPW-DOUCHE-AANG_DIU') {
                //-- Deze prop is alleen voor Ubouw. Bij Wbouw weten we het aantal douches ivm #badk.
                //-- wanneer het aantal douches in de Unit wijzigt, moet dit aantal verdisconteerd worden in het gezamelijke aantal douches in de entity TAPW-DOUCHE
                const doucheWTW = ntaEntityData.getFirstParent(entdata, 'TAPW-DOUCHE');
                if (doucheWTW) {
                    let aantalPerDWTW = 0;
                    let propdataAantalDouchesSysteem = doucheWTW.PropertyDatas['TAPW-DOUCHE_AANT_TOT'];
                    doucheWTW.tempUnits.forEach(function (tempUnit, index) {
                        let propdataAangeslotenDouches = tempUnit.douche.PropertyDatas['TAPW-DOUCHE-AANG_DIU'];
                        aantalPerDWTW += parseInt(propdataAangeslotenDouches.Value);
                    });
                    //-- niet naar de saveValue ander kom ik in een kringetje
                    ntaEntityData.saveprop(propdataAantalDouchesSysteem, aantalPerDWTW);
                }
            }

            //-- VO 2022-11-03: vanaf hier is de code zoals hij eigenlijk hoort in de validateDependencies met een switch. Moet bij het daadwerkelijk refactoren van
            //-- tapwater samengevoegd worden met bovenstaande code.
            switch (prop.Id) {
                case 'TAPW-OPWEK_TYPE': {
                    const propdata = entdata.PropertyDatas['TAPW-OPWEK_GEM'];
                    tapwaterLogic.validate(ntabuilding.properties['TAPW-OPWEK_GEM'], propdata);

                    //-- als de bron van de WP zichtbaar is, moeten daar de nieuwe bronnen van opgehaald worden.
                    const propBron = ntabuilding.properties['TAPW-OPWEK_BRON_POMP'];
                    if (!tapwaterLogic.isHidden(propBron, entdata)) {
                        tapwaterLogic.getSelectionTable(propBron, entdata);
                    }
                    break;
                }
                case 'TAPW-OPWEK_TOES':
                case 'TAPW-OPWEK_BRON_POMP':
                case 'TAPW-OPWEK_REGIO': {
                    let hidden = tapwaterLogic.isHidden(prop, entdata);
                    tapwaterLogic.getSelectionTable(prop, entdata);
                    break;
                }
                case 'TAPW-OPWEK_WARMTE_UIT_VERW':
                case 'TAPW-OPWEK_VERW-OPWEK': {
                    const propdata = prop.getData(entdata);
                    tapwaterLogic.validate(prop, propdata);
                    break;
                }
                default: {
                    const checkValue = prop.getData(entdata).Value;

                    let hidden = tapwaterLogic.isHidden(prop, entdata);
                    if (ntaValidation.hasCodedValues(prop)) {
                        tapwaterLogic.getCodedValues(prop, entdata);
                    }

                    if (checkValue !== prop.getData(entdata).Value) {
                        tapwaterLogic.saveValue(prop, entdata);
                    }

                    break;
                }
            }
        };

        initSelectionTables();
    }

    return TapwaterLogic;
}]);
