﻿angular.module('projectModule')
    .service('ntaAlert',
        ['$log', '$mdDialog', 'progressCircle',
function ($log,   $mdDialog,   progressCircle) {
    'use strict';
    const self = this;

    /// ntaAlert bevat meerdere alerts, en voorkomt dat een alert van hetzelfde type meerdere keren
    ///  achtereen weergegeven wordt.

    /// -- Imports --------------------------------------------------------------------------------

    // (geen imports)


    /// -- Instance variables ---------------------------------------------------------------------

    const _alertDefinitions = {
        'error': {
            createAlert: () => $mdDialog.alert()
                .title('Oeps...')
                .htmlContent(`
                    <div>Er is iets niet goed gegaan. Het ontwikkelteam is op de hoogte gesteld.</div>
                    <div>Kunt u niet verder door dit probleem? Neem dan contact op met onze helpdesk.</div>
                    <div>&nbsp;</div>
                    <div>Excuses voor het ongemak.</div>
                `)
                .ok('OK'),
        },
        'noEdit': {
            createAlert: () => $mdDialog.alert()
                .title('Berekening is vergrendeld')
                .htmlContent(`
                    <div>De berekening is vergrendeld of afgemeld.</div>
                    <div>Wijzigingen worden niet opgeslagen.</div>
                `)
                .ok('OK'),
        },
        'maintenance': {
            createAlert: () => $mdDialog.alert()
                .title('Onderhoud')
                .htmlContent(`
                    <div>Er wordt momenteel onderhoud gepleegd aan Uniec 3. Wacht 15 à 30 minuten alvorens op OK te klikken.</div>
                    <div>&nbsp;</div>
                    <div>Kunt u niet verder door dit probleem? Neem dan contact op met onze helpdesk.</div>
                    <div>&nbsp;</div>
                    <div>Excuses voor het ongemak.</div>`)
                .ok('OK'),
        },
        'login': {
            createAlert: () => $mdDialog.alert()
                .title('U bent uitgelogd')
                .htmlContent(`
                    <div>Om verder te werken moet u opnieuw inloggen.</div>
                    <div>Klik op OK om door te gaan naar de inlogpagina.</div>
                `)
                .ok('OK'),
            finally: (result, url = location.href) => {
                if (url) {
                    window.location = '/Account/Login?returnUrl=' + encodeURIComponent(url);
                } else {
                    location.reload();
                }
            },
        },
        'networkError': {
            createAlert: () => $mdDialog.alert()
                .title('Verbindingsprobleem')
                .htmlContent(`
                    <div>Uniec 3 kan de server niet bereiken. Controleer uw internetverbinding.</div>
                `)
                .ok('Opnieuw proberen'),
            finally: (result, url) => {
                if (url) {
                    window.location = url;
                } else {
                    location.reload();
                }
            },
        }
    };


    /// -- Exports --------------------------------------------------------------------------------

    self.showError = createShowAlert('error');
    self.showNoEdit = createShowAlert('noEdit');
    self.showMaintenance = createShowAlert('maintenance');
    self.showLogin = createShowAlert('login');
    self.showNetworkError = createShowAlert('networkError');


    /// -- Initialization -------------------------------------------------------------------------

    // (geen initialisatie nodig)


    /// -- Implementation -------------------------------------------------------------------------

    function createShowAlert(type) {
        return (...args) => showSingleAlert(type, ...args);
    }

    function showSingleAlert(type, ...args) {
        const def = _alertDefinitions[type];
        if (!def) {
            return $log.error(new Error(`Onbekend type alert ‘${type}’!`));
        }
        if (!def.promise) {
            if (!def.alert) {
                def.alert = def.createAlert(type);
            }
            progressCircle.setShowProgressValue(false);
            def.promise = $mdDialog.show(def.alert);
            if (typeof def.finally === 'function') {
                const complete = result => def.finally(result, ...args);
                def.promise.then(complete);
                def.promise.catch(complete);
            }
            def.promise.finally(() => def.promise = null);
        }
        return def.promise;
    }

}]);