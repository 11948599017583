﻿angular.module('projectModule')
    .service('ntaSharedLogic',
        ['NtaSharedLogicFactory', 'ntaEntityData', 'ntabuilding', 'resultsMenuData',
function (NtaSharedLogicFactory,   ntaEntityData,   ntabuilding,   resultsMenuData) {
    'use strict';
    const vm = this;

    angular.extend(vm, new NtaSharedLogicFactory(ntaEntityData));

    ntabuilding.ntaSharedLogic = vm;
    resultsMenuData.isTOjuliVisible = () => !ntabuilding.ntaSharedLogic.isVakantiewoning()  && (vm.isTOjuli() || vm.isUnitTOjuli());

}]);