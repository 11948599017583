﻿angular.module('projectModule')
    .controller("InstallationVerlichtingController",
        ['$scope', '$controller', 'ntabuilding', 'ntaValidation', 'ntaSharedLogic', 'ntaEntityData', '$routeParams', 'ntaDependencyValidation', 'ntaMeasure', 'VerlichtingFactory',
function ($scope,   $controller,   ntabuilding,   ntaValidation,   ntaSharedLogic,   ntaEntityData,   $routeParams,   ntaDependencyValidation,   ntaMeasure,   VerlichtingFactory) {

    const base = $controller('NTABaseController', { $scope: $scope })
    const vm = angular.extend(this, base);

   /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    vm.ntaValidation = ntaValidation;
    vm.installId = $routeParams.id3;

    const verlichtingLogic = new VerlichtingFactory(vm.installId, ntaDependencyValidation);
    vm.installId = verlichtingLogic.installId; // in een maatregel was installId undefined; de logic heeft ’m intussen opgespoord (of aangemaakt)
    vm.getVerlichtingLogic = () => verlichtingLogic;

    /* Properties ******************************************************************************************************************************************************/
    vm.propertiesVL = verlichtingLogic.propertiesVL;
    vm.propertiesVZ = verlichtingLogic.propertiesVZ;

    /* Data ************************************************************************************************************************************************************/
    vm.verl = verlichtingLogic.verlData;
    vm.units = verlichtingLogic.units;
    vm.gruimtes = verlichtingLogic.gruimtes;

    /* Functies ********************************************************************************************************************************************************/
    vm.getTitle = () => ntaSharedLogic.isEditingMeasure() ? 'Maatregel: Verlichting' : 'Verlichting';
    vm.isHidden = verlichtingLogic.isHidden;
    vm.isReadOnly = verlichtingLogic.isReadOnly;
    vm.isDisabled = verlichtingLogic.isReadOnly;
    vm.propertyHeaderIsHidden = verlichtingLogic.propertyHeaderIsHidden;

    vm.getCodedValues = verlichtingLogic.getCodedValues;
    vm.getUnitRekenzones = verlichtingLogic.getUnitRekenzones;

    vm.getForm = () => verlichtingLogic.form_verl;
    vm.saveValue = saveValue;

    vm.getUnitOmschrijving = getUnitOmschrijving;
    vm.getRekenzoneOmschrijving = getRekenzoneOmschrijving;
    vm.getGruimteOmschrijving = getGruimteOmschrijving;

    vm.openMenu = openMenu;
    vm.addVerlzone = addVerlzone;
    vm.copyVerlzone = copyVerlzone;
    vm.deleteVerlzone = deleteVerlzone;

    $scope.endFormValidation = function () {
        verlichtingLogic.endFormValidation();
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INITALIZIATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ntaMeasure.setBtwPropIds(vm);

    angular.element(function () { //alle touched properties valideren na het laden van form
        verlichtingLogic.startFormValidation();
        base.initializeUnitSpacing();
        setTimeout(base.initializeUnitSpacing, 500);
    });

    vm.setGeopend('VERL_OPEN', vm.verl);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function saveValue(prop, entdata) {
        verlichtingLogic.saveValue(prop, entdata);
        vm.initializeUnitSpacing();
    } //-- end: saveValue ------------------------------------------------------------------------//

    function getUnitOmschrijving(entdata) {
        if (!entdata) {
            return;
        }
        return entdata.PropertyDatas["UNIT_OMSCHR"].Value;
    } //-- end: getUnitOmschrijving ------------------------------------------------------------------------//

    function getGruimteOmschrijving(entdata) {
        if (!entdata) {
            return;
        }
        return entdata.PropertyDatas["GRUIMTE_OMSCHR"].Value;
    } //-- end: getGruimteOmschrijving ------------------------------------------------------------------------//

    function getRekenzoneOmschrijving(entdata) {
        if (!entdata) {
            return;
        }
        var rekenzone = ntaEntityData.getFirstParent(entdata, "RZ");
        if (!rekenzone) {
            return '';
        }
        return rekenzone.PropertyDatas['RZ_OMSCHR'].Value;
    } //-- end: getRekenzoneOmschrijving ------------------------------------------------------------------------//


    ///******************************************* CRUD akties ******************************************************************///
    function openMenu($mdMenu, ev) {
        $mdMenu.open(ev);

        ev.stopImmediatePropagation();
        ev.preventDefault();
    } //-- end: openMenu ------------------------------------------------------------------------//

    function addVerlzone(verlzone, entdata) {
        if (!verlzone || !entdata) {
            return;
        }

        let newId = ntaEntityData.create('VERLZONE', verlzone ? verlzone.Order : -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": entdata.EntityDataId, "ParentEntityId": "UNIT-RZ" }], [], [])
        verlichtingLogic.buildverlzonesTabel();

        let newVerlzone = ntaEntityData.get(newId);
        verlichtingLogic.setDefaultVerlzone(newVerlzone);
    } //-- end: addVerlzone ------------------------------------------------------------------------//

    function copyVerlzone(verlzone, entdata) {
        if (!verlzone || !entdata) {
            return;
        }
        ntaEntityData.copy(verlzone.EntityDataId);
        verlichtingLogic.buildverlzonesTabel();
    } //-- end: copyVerlzone ------------------------------------------------------------------------//

    function deleteVerlzone(verlzone, entdata) {
        if (!verlzone || entdata.verlzones.length <= 1) { //kan de laatste verlzone niet weggooien
            return;
        }
        ntaEntityData.delete(verlzone.EntityDataId);
        verlichtingLogic.buildverlzonesTabel();
    } //-- end: deleteVerlzone ------------------------------------------------------------------------//

    vm.sortableOptionsUnitRzVerlzones = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            for (const unit of vm.units) {
                for (const unitRekenzone of vm.getUnitRekenzones(unit)) {
                    const verlzones = unitRekenzone.verlzones;
                    if (verlzones.some(verlzone => verlzone.EntityDataId === ui.item.sortable.model.EntityDataId)) {
                        ntaEntityData.SaveReOrder(verlzones); // Reorder verlzones
                    }
                }
            }
        }
    } //-- end: sortableOptionsUnitRzVerlzones ------------------------------------------------------------------------//

    vm.sortableOptionsGruimteVerlzones = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            for (const gruimte of vm.gruimtes) {
                const verlzones = gruimte.verlzones;
                if (verlzones.some(verlzone => verlzone.EntityDataId === ui.item.sortable.model.EntityDataId)) {
                    ntaEntityData.SaveReOrder(verlzones); // Reorder verlzones
                }
            }
        }
    } //-- end: sortableOptionsGruimteVerlzones ------------------------------------------------------------------------//
}]);
