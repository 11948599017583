(function (window, angular, undefined) {
    'use strict';
    angular.module('ntaComponents', ['progressModule', 'settingsModule', 'ntaAppInfoModule'])

        .directive('ntaProgressCircle', ['progressCircle', '$interval', 'ntaAppInfo', function (progressCircle, $interval, ntaAppInfo) {
            return {
                restrict: 'E',
                replace: true,
                templateUrl: '/src/app/components/progresscircle.html' + '?v=' + encodeURIComponent(ntaAppInfo.commit),
                controller: ['$scope',
                    function ($scope) {
                        $scope.showProgress = function () {
                            return progressCircle.getShowProgress();
                        };
                        $scope.progressDescription = function () {
                            return progressCircle.getProgressDescription();
                        };
                        $scope.showProgressValue = function () {
                            return progressCircle.getShowProgressValue();
                        };

                        $interval(function () {
                            $scope.progressValue = function () {
                                return progressCircle.getProgressValue();
                            };
                        }, 100);
                    }
                ]
            };
        }])

        .directive('ntaSettingsMenu', ['settingsMenuData', 'ntaData', function (settingsMenuData, ntaData, $timeout) {
            return {
                restrict: 'A',
                templateUrl: '/src/app/components/settingsmenu.html?v=' + ntaData.commit,
                controller: ['$scope',
                    function ($scope) {
                        $scope.settingData = function () {
                            return settingsMenuData.settings || [];
                        };

                        $scope.settingsCount = function () {
                            return settingsMenuData.settings && settingsMenuData.settings.length || 0;
                        };
                    }
                ],
            };
        }])

        .directive('ntaResultsMenu', ['resultsMenuData', 'ntaData', function (resultsMenuData, ntaData, $timeout) {
            return {
                restrict: 'A',
                templateUrl: '/src/app/components/resultsmenu.html?v=' + ntaData.commit,
                controller: ['$scope',
                    function ($scope) {
                    }
                ],
            };
        }])

        .directive('fileModel', ['$parse', function ($parse) {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    var model = $parse(attrs.fileModel);
                    var modelSetter = model.assign;

                    element.bind('change', function () {
                        scope.$apply(function () {
                            modelSetter(scope, element[0].files);
                        });
                    });
                }
            };
        }])

        .directive('info', ['$log', '$mdDialog', 'ntaData', 'progressCircle', function ($log, $mdDialog, ntaData, progressCircle) {
            return {
                restrict: 'E',
                scope: {
                    'linkId': '=',
                },
                templateUrl: '/src/app/components/info-dialog-button.html',
                link: function (scope) {
                    let _dialogWindow;
                    scope.showInfo = async function (linkId) {
                        if (!scope.linkId) {
                            return;
                        }
                        try {
                            progressCircle.setShowProgressValue(true, "Laden...");
                            let data;
                            try {
                                const response = await fetch(`/CMS/GetNTAInformation?LinkId=${linkId}&NTAVersionId=${ntaData.ntaVersion.ntaVersionId}`);
                                if (!response.ok) throw response;
                                const reply = await response.json();
                                data = reply;
                            } finally {
                                progressCircle.setShowProgressValue(false, "");
                            }

                            await $mdDialog.show({
                                controller: DialogController,
                                templateUrl: '/src/app/templates/information.html',
                                parent: angular.element(document.body),
                                clickOutsideToClose: true,
                                locals: {
                                    data: data
                                }
                            });
                        } catch (err) {
                            if (err) $log.error(err);
                        }
                    };

                    function DialogController($scope, $mdDialog, data, $sce) {
                        window.addEventListener('beforeunload', event => {
                            closeWindow();
                        });

                        $scope.data = {
                            title: $sce.trustAsHtml(data.Title),
                            information: $sce.trustAsHtml(data.Information)
                        };
                        $scope.cancel = function () {
                            $mdDialog.cancel();
                        };

                        $scope.openNewWindow = function () {
                            if (!_dialogWindow || _dialogWindow.closed) {
                                const information = $sce.trustAsHtml(data.Information);

                                $scope.cancel();
                                _dialogWindow = window.open('', '_blank', 'popup:width=650,height=650');
                                _dialogWindow.document.write(`
                                    <html>
    	                                <head>
        	                                <link rel="stylesheet" type="text/css" href="/src/site.css"/>
                                            <style> ul,ol { list-style-position: inside; }</style>
                                        </head>
                                        <body>${information}</body>
                                    </html>
                                `);
                            }
                            _dialogWindow.focus();
                        };

                        function closeWindow () {
                            if (_dialogWindow) {
                                _dialogWindow.close();
                            }
                        }
                    }
                }
            };
        }])

        .directive('calculator', ['$mdDialog', 'ntaData', function ($mdDialog, ntaData) {
            return {
                restrict: 'E',
                scope: {
                    'ctrl': '=',
                    'prop': '=',
                    'entdata': '='
                },
                transclude: false,
                templateUrl: '/src/app/components/calculator-button.html?v=' + ntaData.commit,
                link: function (scope, element, attrs) {

                    scope.showCalculator = async function (event) {

                        if (!scope.prop || !scope.entdata) {
                            return;
                        }

                        try {
                            await $mdDialog.show({
                                templateUrl: '/src/app/components/calculator.html?v=' + ntaData.commit,
                                autoWrap: false,
                                targetEvent: event,
                                scope: scope,
                                preserveScope: true,
                                hasBackdrop: true,
                                clickOutsideToClose: true,
                                escapeToClose: true,
                                focusOnOpen: true,
                                controller: CalculatorController,
                                multiple: false,
                                onComplete: () => {
                                    const calcScreen = document.getElementById('calcScreen');
                                    scope.calcScreen = calcScreen;

                                    calcScreen.addEventListener('keydown', scope.calcKeyDown);

                                    calcScreen.focus();
                                },
                            });
                        } catch (err) {
                            // annuleren kunnen we negeren
                        } finally {
                            scope.ctrl.initializeUnitSpacing();

                            const propdata = scope.prop.getData(scope.entdata);
                            const input = propdata && document.getElementsByName('ntainput' + propdata.PropertyDataId)[0]
                            if (input) {
                                input.focus();
                                input.select();
                            }
                        }
                    };

                    function CalculatorController($scope, $mdDialog) {

                        let historyId = 0;
                        let result;

                        scope.calcToForm = function () {
                            const propdata = scope.entdata.PropertyDatas[scope.prop.Id];
                            if (propdata) {
                                propdata.Value = result;
                                scope.ctrl.saveValue(scope.prop, scope.entdata);
                            }

                            $mdDialog.hide();
                        };

                        scope.canSendToForm = function () {
                            return scope.calcScreen
                                && scope.calcScreen.value === ''
                                && !!result;
                        };

                        scope.calcEval = function () {
                            if (calcScreen.value === '') {
                                calcScreen.value = $("#calcScreen").attr('placeholder');
                                if (result) {
                                    scope.calcToForm();
                                }
                            }
                            if (calcScreen.value.indexOf("^") !== -1) {
                                scope.calcExponent();
                            }
                            if (calcScreen.value.indexOf("%") !== -1) {
                                scope.calcPct();
                            }

                            try {
                                result = eval(calcScreen.value);
                            } catch (err) {
                                result = NaN;
                            }
                            if (isNaN(result)) {
                                calcScreen.value = "ERROR";
                            } else {
                                historyId++;
                                $("#history").append("<li class='list-group-item' id='history-" + historyId + "'>" + calcScreen.value + "</li>");
                                result = Math.round(result * 10000000000) / 10000000000; // voorkom precisieproblemen zoals 0.2 - 0.88 = -0.6799999999999999, of 0.1 * 0.2 = 0.020000000000000004
                                while (String(result).split('').length > 14) {
                                    result = String(result).slice(0, -1);
                                }
                                $("#calcScreen").val('').attr('placeholder', result);
                                $("#history-" + historyId).append(" = " + result);
                                scope.histDisable = false;
                            }
                        };

                        scope.calcExponent = function () {
                            const arr = calcScreen.value.split("^");
                            if (!arr[1]) {
                                calcScreen.value = "ERROR";
                            } else {
                                calcScreen.value = "Math.pow(" + arr[0] + "," + arr[1] + ")";
                            }
                        };

                        scope.calcPct = function () {
                            let arr, sym;
                            if (calcScreen.value.indexOf("+") !== -1) {
                                arr = calcScreen.value.split("+");
                                sym = "+";
                            } else if (calcScreen.value.indexOf("-") !== -1) {
                                arr = calcScreen.value.split("-");
                                sym = "-";
                            } else if (calcScreen.value.indexOf("*") !== -1) {
                                arr = calcScreen.value.split("*");
                                sym = "*";
                            } else if (calcScreen.value.indexOf("/") !== -1) {
                                arr = calcScreen.value.split("/");
                                sym = "/";
                            } else {
                                calcScreen.value = "ERROR";
                                return;
                            }
                            arr[1] = arr[1].slice(0, arr[1].length - 1);
                            calcScreen.value = arr[0] + sym + (arr[1] * (arr[0] / 100));
                        };


                        scope.calcAddInput = function (char) {
                            if (calcScreen.value === '' && (char === '+' || char === '-' || char === '*' || char === '/' || char === '^')) {
                                calcScreen.value = $("#calcScreen").attr('placeholder');
                            }
                            if (calcScreen.value.length < 14) {
                                if (calcScreen.value !== 'ERROR') {
                                    calcScreen.value += char;
                                }
                            }
                        };

                        scope.noResult = function () {
                            return isNaN(result);
                        };

                        scope.calcClear = function () {
                            $("#calcScreen").val('').attr('placeholder', 0);
                        };

                        scope.calcBackspace = function () {
                            if (calcScreen.value === 'ERROR') {
                                scope.calcClear();
                            } else {
                                calcScreen.value = calcScreen.value.slice(0, calcScreen.value.length - 1);
                            }
                        };

                        scope.calcInputNegative = function () {
                            if (calcScreen.value === '' && $("#calcScreen").attr('placeholder') !== '0') {
                                calcScreen.value = $("#calcScreen").attr('placeholder');
                            }
                            if (calcScreen.value.length < 14) {
                                var arr = calcScreen.value.split('');
                                if (arr[0] === '-') {
                                    arr.shift();
                                } else {
                                    arr.unshift('-');
                                }
                                calcScreen.value = arr.join('');
                            }
                        };

                        scope.calcKeyDown = function (event) {
                            let key = event.keyCode;
                            // console.log('key: '+key);
                            if (calcScreen.value === 'ERROR') {
                                if (key === 8) {
                                    key = 46;
                                }
                                if (key !== 46) {
                                    event.preventDefault();
                                }
                            }
                            var shift = event.shiftKey;
                            if (calcScreen.value === '' && (key === 107 || key === 109 || key === 106 || key === 111 ||
                                (shift && key === 54) || (shift && key === 56) || (shift && key === 187) || key === 189)) {
                                calcScreen.value = $("#calcScreen").attr('placeholder');
                            }
                            if (key > 64 && key < 96 || key === 188) {
                                event.preventDefault();
                            }
                            if (key === 46) {
                                calcScreen.value = '';
                            }
                            if (key === 13) { //Enter key
                                scope.calcEval();
                                event.preventDefault();
                            } else {
                                $("#calcScreen").attr('placeholder', 0);
                            }
                        };
                    }

                },
            };
        }])

        .directive('infoUrl', ['$window', 'ntaData', function ($window, ntaData) {
            return {
                restrict: 'E',
                scope: {
                    'ctrl': '=',
                    'prop': '=',
                    'entdata': '='
                },
                transclude: false,
                templateUrl: '/src/app/components/info-url-button.html?v=' + ntaData.commit,
                link: function (scope, element, attrs) {
                    scope.showInfoUrl = function (scope) {
                        $window.open(scope.prop.InfoUrl, '_blank');
                    };
                }
            };
        }])

        .directive('pagingBar', ['$compile', function ($compile) {
            return {
                restrict: 'E',
                scope: {
                    'pager': '=',
                },
                replace: false,
                transclude: false,
                link: function (scope, element, attrs) {
                    const pager = scope.pager;
                    let _timeout; // deze gebruiken we om te voorkomen dat te vaak achtereen moeten refreshen


                    scope.setPageNr = pageNr => {
                        pager.pageNr = pageNr;
                    };

                    // In plaats van nog een watcher aan te maken, gaan we naar de events van de pager luisteren.
                    pager.on('pageNrChanged', planRefresh);
                    pager.on('pageCountChanged', planRefresh);
                    pager.on('pageValidChanged', planRefresh);

                    // Initiële rendering
                    refresh();

                    function planRefresh() {
                        clearTimeout(_timeout);
                        _timeout = setTimeout(refresh, 100);
                    }

                    function refresh() {
                        _timeout = null;
                        const html = `<div>
                                <label class="left">Pagina: <label>`
                            + pager.pages.map(page => {
                                const classes = page === pager.currentPage
                                    ? 'ui-state-active'
                                    : page.isValid === false ? 'invalid' : '';
                                return `<a class="ui-button ${classes}" ng-click="setPageNr(${page.nr})">${page.nr + 1}</a>`;
                            }).join('')
                            + `<label class="right">
                                    <input type="number" class="number rows-per-page" min="5" placeholder="rijen per pagina" ng-model="pager.rowsPerPage" ng-model-options="{ debounce: 500 }">
                                    rijen per tabel
                                </label>
                            </div>`;
                        element.html(html).show();
                        $compile(element.contents())(scope);
                    }
                }
            };
        }])

        .directive('propdataVertical', ['ntaData', function (ntaData) {
            return {
                restrict: 'E',
                scope: {
                    'ctrl': '=',
                    'prop': '=',
                    'entdata': '=',
                },
                transclude: false,
                templateUrl: '/src/app/components/propdata-vertical.html?v=' + ntaData.commit,
                link: function (scope, element, attrs) {

                    const ctrl = scope.ctrl;
                    scope.onValidateCallback = ctrl.onValidateCallback || function () { return true; };

                    if (!scope.propdata) {
                        if (typeof ctrl.getPropData === 'function') {
                            scope.propdata = ctrl.getPropData(scope.prop, scope.entdata);
                        } else if (scope.prop && scope.entdata) {
                            scope.propdata = prop.getData(entdata);
                        }
                    }

                    let displayType;
                    const hasCodedValues = ctrl.hasCodedValues || ctrl.ntaValidation && ctrl.ntaValidation.hasCodedValues;
                    const hasCodedValuesFromTable = ctrl.hasCodedValuesFromTable || ctrl.ntaValidation && ctrl.ntaValidation.hasCodedValuesFromTable;
                    const hasSelectionTableValues = ctrl.hasSelectionTableValues || ctrl.ntaValidation && ctrl.ntaValidation.hasSelectionTableValues;
                    const propertyType = ctrl.getPropertyType && ctrl.getPropertyType(scope.prop, scope.entdata) || scope.prop && scope.prop.PropertyType;
                    if (hasCodedValues && hasCodedValues(scope.prop, scope.entdata) && propertyType !== 6) {
                        displayType = 'select';
                    } else if (hasCodedValuesFromTable && hasCodedValuesFromTable(scope.prop, scope.entdata)) {
                        displayType = 'autocomplete';
                    } else if (hasSelectionTableValues && hasSelectionTableValues(scope.prop, scope.entdata)) {
                        displayType = 'selectiontable';
                    } else {
                        displayType = ['', 'number', 'text', 'boolean', 'date', 'memo', 'array'][propertyType];
                    }
                    if (propertyType === 6 && ctrl.getCodedValues(scope.prop).length === 0) {
                        displayType = 'emptyArray';
                    }
                    scope.displayType = displayType;

                    scope.datepickerFormat = ctrl.datepickerFormat && ctrl.datepickerFormat();
                }
            };
        }])

        .directive('resultsEpV1', ['ntaData', 'ntaSharedLogic', function (ntaData, ntaSharedLogic) {
            return {
                restrict: 'E',
                scope: {
                    'ctrl': '=',
                },
                transclude: false,
                templateUrl: '/src/app/components/results-ep.html?v=' + ntaData.commit,
                link: function (scope, element, attrs) {

                    const resultsLogic = scope.ctrl.resultsLogic;
                    resultsLogic.variantId = scope.ctrl.variantId;

                    Object.assign(scope, resultsLogic);
                    scope.berekeningen = resultsLogic.getBerekeningen();

                    scope.isNieuwbouw = ntaSharedLogic.isNieuwbouw;
                    scope.isUtiliteit = ntaSharedLogic.isUtiliteit;
                    scope.isProjectwoningen = ntaSharedLogic.voorProjectwoningen;

                    scope.getFullName = function (name) {
                        if (name === 'Oppervlakten') { //conditie [Z]
                            return name;
                        }
                        return ntaSharedLogic.showCosts() && resultsLogic.variantId ? name + ' volgens ISSO 82.2 / 75.2 - Maatwerkadvies' : name + ' volgens NTA 8800';
                    };

                    scope.showChooser = () => scope.berekeningen.length > 1;

                }
            };
        }])

        .directive('measureCosts', ['ntaData', 'ntaSharedLogic', function (ntaData, ntaSharedLogic) {
            return {
                restrict: 'E',
                scope: {
                    'ctrl': '=',
                    'installationId': '=',
                },
                transclude: false,
                templateUrl: '/src/app/components/measure-costs.html?v=' + ntaData.commit,
                link: function (scope, element, attrs) {

                    // [HVAC-A] toon indien bij instellingen gekozen voor 'maatwerk advies'; voeg deze velden toe aan alle installatie formulieren die onderdeel uitmaken van het HVAC systeem
                    scope.shouldShowCosts = () => {
                        const entdata = scope.getCostEntdata();
                        return !!entdata && entdata.Relevant;
                    };

                    scope.getCostEntdata = () => ntaSharedLogic.getCostEntdata(scope.installationId || scope.ctrl.installId);

                    scope.costProperties = ntaData.properties['MEASURE-COSTS'];

                    scope.ctrl.initializeUnitSpacing();

                },
            };
        }])

        ;
})(window, window.angular);
