﻿angular.module('projectModule')
    .factory('PvFactory',
        ['ntaData', 'ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaMeldingen', 'ntaSharedLogic', 'ntaRounding', 'ntaSelectionTable', '$log',
function (ntaData,   ntabuilding,   ntaValidation,   ntaEntityData,   ntaMeldingen,   ntaSharedLogic,   ntaRounding,   ntaSelectionTable,   $log) {
    'use strict';

    function PvLogic(installId, ntaDependencyValidation) {
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        const pvLogic = this;

        const _installationId = installId;
        const _appVersion = ntabuilding.appVersionId;

        const _entityId = _appVersion === 1 ? 'PV-PVT' : 'PV';
        const _properties = ntabuilding.properties[_entityId];
        const _propertiesPV_VELD = ntabuilding.properties['PV-VELD'];           /// AppVersion = 2

        let _activeSystem = null;
        const _pvsysData = ntaEntityData.getFirstChild(_installationId, _entityId);

        const _selectionTable = [];
        const _units = getUnits(); //-- alle units die er zijn                  /// AppVersion = 2
        const _zonneboilersystemen = getZonneboilersysCodedValues();            /// AppVersion = 1

        pvLogic.properties = _properties;
        pvLogic.propertiesPvVeld = _propertiesPV_VELD;

        pvLogic.pvData = _pvsysData;
        pvLogic.systemen = systemen;                                            /// AppVersion = 1
        pvLogic.systeemNr = systeemNr;                                          /// AppVersion = 1
        pvLogic.pvVeldenByUnit = pvVeldenByUnit;                                /// AppVersion = 2
        pvLogic.pvVeldenByGebouw = pvVeldenByGebouw;                            /// AppVersion = 2
        pvLogic.pvVeldenByGebouwdeel = pvVeldenByGebouwdeel;                    /// AppVersion = 2
        pvLogic.alleUnits = _units;                                             /// AppVersion = 2

        pvLogic.activeSystem = _activeSystem;
        pvLogic.setActiveSystem = setActiveSystem;

        pvLogic.isHidden = isHidden;
        pvLogic.isReadOnly = isReadOnly;

        pvLogic.validate = validate;
        pvLogic.validateDependencies = validateDependencies;

        pvLogic.startFormValidation = startFormValidation;
        pvLogic.endFormValidation = endFormValidation;

        pvLogic.saveValue = saveValue;
        pvLogic.getCodedValues = getCodedValues;

        pvLogic.getListItem = getListItem;
        pvLogic.getSelectionTable = getSelectionTable;
        pvLogic.saveValueSelectionTable = saveValueSelectionTable;
        pvLogic.saveValueAutocomplete = saveValueSelectionTable;
        pvLogic.setSelectedItemSelectionTable = setSelectedItemSelectionTable;
        pvLogic.dependencyValidator = ntaDependencyValidation;
        pvLogic.loadProductsFromServer = loadProductsFromServer;
        pvLogic.isHiddenPvVeldHeader = isHiddenPvVeldHeader;
        pvLogic.hasBelemmering = hasBelemmering;

        pvLogic.pvBelemmeringByPvVeld = pvBelemmeringByPvVeld;
        pvLogic.createVeld = createVeld;
        pvLogic.checkPVVelden = checkPVVelden;

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         INITIALIZATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        loadProductsFromServer();


        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        function pvAlleVelden() {
            return ntaEntityData.getChildren(_pvsysData, "PV-VELD");
        } //-- end: pvRelevanteVelden ------------------------------------------------------------------------//

        function pvVeldenByGebouw() {
            /// alle pv-velden ophalen die geen relatie hebben met een unit
            return ntaEntityData.getChildren(_pvsysData, "PV-VELD").filter(veld => veld.PropertyDatas['PV-VELD_TYPEINVOER'].Value === 'PVINVOER_GEB');
         } //-- end: pvVeldenByGebouw--------------------------------------------------------------------------------//

        function pvVeldenByGebouwdeel() {
                /// alle pv-velden ophalen die wel een realtie hebben met units
            return ntaEntityData.getChildren(_pvsysData, "PV-VELD").filter(veld => veld.PropertyDatas['PV-VELD_TYPEINVOER'].Value === 'PVINVOER_GEBDEEL');
         } //-- end: pvVeldenByGebouwdeel --------------------------------------------------------------------------------//

        function pvVeldenByUnit(unit) {
            //-- VO 2021-09-08: alleen de PV-VELD retourneren die van invoerType 'per app/unit/woning' zijn
            if (unit.EntityDataId !== 0) {
                //-- dan is er sprake van een echte unit en moeten de velden van het type 'per app/unit' opgehaald worden die een relatie hebben met deze unit
                return pvAlleVelden().filter(veld => ['PVINVOER_APP', 'PVINVOER_UNIT', 'PVINVOER_WONING'].includes(veld.PropertyDatas['PV-VELD_TYPEINVOER'].Value) && ntaSharedLogic.hasRelation(unit, veld));
            }
            //-- anders gewoon een lege array teruggeven.
            return [];
        } //-- end: pvVeldenPerUnits --------------------------------------------------------------------------------//

        function getUnits() {
            return ntaEntityData.getListWithEntityId("UNIT");
         } //-- end: pvVelden ------------------------------------------------------------------------//

        function gebouwdeelUnits() {
            //-- VO 2021-09-08: een lijst met alle units die PV-VELDen hebben van het type 'per gebouwdeel';
            let result = [];
            for (var gebdeelVeld of pvVeldenByGebouwdeel()) {
                result = result.concat(ntaEntityData.getParents(gebdeelVeld, 'UNIT'));
            }
            return result;
        } //-- end: gebouwdeelUnits --------------------------------------------------------------------------------//

        function systemen() {
            return _appVersion > 1 ? [_pvsysData] : ntaEntityData.getListWithEntityId("PV-PVT");
        } //-- end: systemen ------------------------------------------------------------------------//

        function systeemNr(entdata) {
            return systemen().indexOf(entdata) + 1;
        } //-- end: systeemNr ------------------------------------------------------------------------//

        function setActiveSystem(systeem) {
            _activeSystem = systeem;
        } //-- end: setActiveSystem ------------------------------------------------------------------------//

         function pvBelemmeringByPvVeld(veld) {
            return ntaEntityData.getFirstChild(veld, 'BELEMMERING');
         } //-- end: pvBelemmeringByPvVeld ------------------------------------------------------------------------//

        function propNameWattPiek() {
            return _appVersion > 1 ? 'PV_WATTPIEK' : 'PV-PVT_WATTPIEK';
        } //-- end: propNameWattPiek --------------------------------------------------------------------------------//

        function propNamePrdForf() {
            return _appVersion > 1 ? 'PV_WPPRDTFORF' : 'PV-PVT_WPPRDTFORF';
        } //-- end: propNamePrdForf --------------------------------------------------------------------------------//

        function propNamePrd() {
            return _appVersion > 1 ? 'PV_WPPRDT' : 'PV-PVT_WPPRDT';
        } //-- end: propNamePrd --------------------------------------------------------------------------------//

        function propNameWPM2() {
            return _appVersion > 1 ? 'PV_WPM2' : 'PV-PVT_WPM2'
        }//-- end: propNameWPM2 --------------------------------------------------------------------------------//

        function propNameProductType(sys) {
            const propdataWattPiek = sys.PropertyDatas[propNameWattPiek()];

            return propdataWattPiek.Value === "PVWATTPIEK_FORFAIT" ? propNamePrdForf() : propNamePrd();
        } //-- end: propNameProductType --------------------------------------------------------------------------------//

        function getInvoerWattPiekValue(sys) {
            if (['PV-PVT', 'PV'].includes(sys.EntityId)) {
                //-- nu is sys of PV-PVT (appversion=1) of PV (appversion=2 of hoger)
                return sys.PropertyDatas[propNameWattPiek()].Value
            }

            return null;
        } //-- end: getInvoerWattPiekValue --------------------------------------------------------------------------------//

        function getListItem(prop, entdata, list = null) {
            let listItem = null;

            if (prop && prop.Domain && prop.Domain.DomainType > 1 && entdata) {
                const propValue = entdata.PropertyDatas[prop.Id].Value;
                let itemId = propValue;
                switch (prop.Id) {
                    case 'PV-PVT_WPPRDT':
                    case 'PV-PVT_WPPRDTFORF':
                        list = entdata.filteredItems;
                        itemId = parseInt(itemId);
                        break;
                    case 'PV_WPPRDT':
                    case 'PV_WPPRDTFORF':
                        list = entdata.filteredItems;
                        itemId = parseInt(itemId);
                        break;
                }
                if (!list) {
                    //-- anders ongefilterde codedValue list gebruiken
                    list = prop.Domain.Codes;
                }
                if (list && list.length > 0) {
                    listItem = list.find(item => item.Id === itemId);
                }
            }

            return listItem;
        } //-- end: getListItem ------------------------------------------------------------------------//

        function setSelectedItemSelectionTable(prop, entdata, selectedItem = null) {
            // dit moet alleen voor velden van het domain type 4 of 5.
            if (prop.Domain.DomainType === 4 || prop.Domain.DomainType === 5) {
                if (!selectedItem) {
                    selectedItem = getListItem(prop, entdata);
                }

                switch (prop.Id) {
                    case 'PV-PVT_WPPRDT':
                    case 'PV-PVT_WPPRDTFORF':
                        {
                            if (selectedItem) {
                                entdata.selectedItem = selectedItem;
                                entdata.searchText = selectedItem.Value;
                            } else {
                                entdata.selectedItem = null;
                                entdata.searchText = "";
                            }
                            break;
                        }
                    case 'PV_WPPRDT':
                    case 'PV_WPPRDTFORF':
                        {
                            if (selectedItem) {
                                entdata.selectedItem = selectedItem;
                                entdata.searchText = selectedItem.Value;
                            } else {
                                entdata.selectedItem = null;
                                entdata.searchText = "";
                            }
                            break;
                        }
                    default:
                        break;
                }
            }
        } //-- end: setSelectedItemSelectionTable ------------------------------------------------------------------------//

        function loadProductsFromServer() {
            const products = ntaSelectionTable.getProducts('PV', ntaSharedLogic.isUtiliteit());

            _selectionTable.splice(0);
            _selectionTable.push(...products);

            for (const sys of systemen()) {
                const propMethode = pvLogic.properties[propNameProductType(sys)]
                if (propMethode) {
                    getSelectionTable(propMethode, sys);
                    setSelectedItemSelectionTable(propMethode, sys);
                    setValuesDependingOnSelectionTable(sys);
                }
            }
        } //-- end: loadProductsFromServer ------------------------------------------------------------------------//

         function getSelectionTable(prop, entdata, searchText = "") {
            if (prop.Domain.DomainType === 4) { //coded value
                let res = _selectionTable;

                //nu moet ik weten of ik forfaitair ben of niet.
                const propWattPiekMethod = pvLogic.properties[propNameWattPiek()];
                const wattPiekMethod = propWattPiekMethod.getValue(entdata);
                switch (wattPiekMethod) {
                    case "PVWATTPIEK_PRDTM2":
                    case "PVWATTPIEK_PRDTPNL":
                        res = res.filter(x => x.Certificate === 1);
                        break;
                    case "PVWATTPIEK_FORFAIT":
                        res = res.filter(x => x.Certificate === 0);
                        break;
                }

                // Als bij Settings is aangevinkt dat alleen de actuele verklaringen gekozen mogen,
                // moeten de verlopen verklaringen er uit gefilterd worden.
                if (ntaSharedLogic.showOnlyValidProducts()) {
                    res = res.filter(r => r.ExpiryDate == null || Date.parse(r.ExpiryDate) > Date.now());
                }

                if (res.length === 1) {
                    res.Singleresult = true;
                }

                // Tot slot filteren op de zoektekst
                if (searchText) {
                    res = res.filter(x => x.Value.toLowerCase().includes(searchText.toLowerCase()));
                }

                entdata.filteredItems = res;
                ntaSharedLogic.checkPropdataInList(prop, entdata, res, pvLogic);
                return res;
            }
        } //-- end: getSelectionTable ------------------------------------------------------------------------//

        function setValuesDependingOnSelectionTable(entdata) {
            if (!entdata) {
                return;
            }

            const propProduct = pvLogic.properties[propNameProductType(entdata)];
            if (!isHidden(propProduct, entdata)) {
                const product = getListItem(propProduct, entdata);

                const [propWpM2, propWpPnl, propVeroudering] = (
                    _appVersion > 1 ? ['PV_WPM2_NON', 'PV_WPPNL_NON', 'PV_VEROUDERING_NON']
                        : ['PV-PVT_WPM2', 'PV-PVT_WPPNL', 'PV-PVT_VEROUDERING_NON']
                    ).map(propId => _properties[propId]);

                //-- _NON m2 en pnl wil ik altijd hebben uit de selectietabel omdat dat handig is voor vullers en GEN XML
                //-- Uniec 3.1 of nieuwer: toon waarde van Kpk-n gedeeld door Apv uit de 2 kolommen van tabel PV_model (gebruik het op 2 decimalen rekenkundig afgeronde resultaat ook in de rekenkern)
                if (_appVersion >= 100) {
                    ntaSharedLogic.saveProductValueToPropData(pvLogic, product, 'Kpkn', entdata, propWpM2, Kpkn => Kpkn / product.Apv);
                } else {
                    ntaSharedLogic.saveProductValueToPropData(pvLogic, product, 'KpkA', entdata, propWpM2);
                }
                ntaSharedLogic.saveProductValueToPropData(pvLogic, product, 'Kpkn', entdata, propWpPnl);
                if (!isHidden(propVeroudering, entdata)) { ntaSharedLogic.saveProductValueToPropData(pvLogic, product, 'Veroudering', entdata, propVeroudering); }

                setPVTotalArea(entdata, product);
            }
        } //-- end: setValuesDependingOnSelectionTable ------------------------------------------------------------------------//

        function validate(prop, propdata, entdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            let valid = ntaValidation.IsValid(pvLogic.form_pvsys, prop, propdata, isHidden(prop, entdata), isReadOnly(prop, entdata));  //Client Validatie;

            switch (prop.Id) {
                //-- VO 2021-08-16: onderstaande check voor AppVersion = 1 oude wijze van PV(T) systmenen
                case 'PV-PVT_TYPESYS':
                case 'PV-PVT_ZONNBSYS':
                    {
                        //-- conditie [AE]
                        let propValueType = entdata.PropertyDatas['PV-PVT_TYPESYS'].Value;

                        let zonnbEntityDataId = null;
                        let zonnbRel = ntaSharedLogic.getRelData(entdata, 'ZONNBSYS');
                        if (zonnbRel) {
                            zonnbEntityDataId = zonnbRel.Parent;
                        }

                        let prodatatypesys = entdata.PropertyDatas['PV-PVT_TYPESYS'];

                        let E071value = true;
                        if (zonnbEntityDataId && propValueType !== "PVTYPESYS_PV") { //-- voer check uit als er een zonneboiler is en type systeem is geen PV

                            const collector = ntaEntityData.getFirstChild(zonnbEntityDataId, 'ZONNECOL');

                            let propCollectorSys = ntabuilding.properties['ZONNC_PVT'];
                            let propValueCollectorSys = propCollectorSys.getValue(collector);

                            E071value = propValueType === "PVTYPESYS_PVTAFGD" && propValueCollectorSys === "ZONNC_PVT_AFG" || propValueType === "PVTYPESYS_PVTONAFGD" && propValueCollectorSys === "ZONNC_PVT_ONAFG";
                        }
                        ntaSharedLogic.setMelding('[E071]', prodatatypesys, pvLogic.form_pvsys, E071value);
                        valid = E071value;

                        let E072value = true;
                        let propZonnbSys = pvLogic.properties['PV-PVT_ZONNBSYS'];
                        let propdataZonnbSys = propZonnbSys.getData(entdata);
                        if (propValueType === 'PVTYPESYS_PVTONAFGD' || propValueType === 'PVTYPESYS_PVTAFGD') {
                            E072value = _zonneboilersystemen.length > 0;
                        }
                        ntaSharedLogic.setMelding('[E072]', propdataZonnbSys, pvLogic.form_pvsys, E072value);
                        valid = valid && E072value;

                        break;
                    }
                default:
            }

            return valid;
        } //-- end: validate ------------------------------------------------------------------------//

        function saveValue(prop, entdata, newValue) {
            ntaSharedLogic.saveValue(prop, entdata, newValue, pvLogic);
        } //-- end: saveValue ------------------------------------------------------------------------//

        function saveValueSelectionTable(prop, item, entdata) {
            ntaSharedLogic.saveValueSelectionTable(prop, item, entdata, pvLogic)
        } //-- end: saveValueSelectionTable ------------------------------------------------------------------------//

        function setDescriptionInstallation(entdata) {
            //-- ik moet eerst weten of dit het eerste systeem is. Vanaf AppVersion = 2 is er altijd maar één systeem
            if (systeemNr(entdata) === 1) {
                let descr = 'Er is nog geen keuze gemaakt voor het systeem';

                let makeDecription = true;
                if (_appVersion === 1) {
                    const propType = pvLogic.properties['PV-PVT_TYPESYS'];
                    makeDecription = (propType && propType.getValue(entdata) !== null) || !propType;
                }

                if (makeDecription) {
                    const propInvoer = pvLogic.properties[propNameWattPiek()];
                    const propValueInvoer = propInvoer.getValue(entdata);
                    switch (propValueInvoer) {
                        case 'PVWATTPIEK_FORFAIT':
                            {
                                const propPrdForf = pvLogic.properties[propNamePrdForf()];
                                const propValuePrdForf = parseInt(propPrdForf.getValue(entdata));
                                const product = _selectionTable.find(function (x) { return x.Id === propValuePrdForf; });
                                descr = product ? product.Value : "";
                                break;
                            }
                        case 'PVWATTPIEK_PRDTM2':
                        case 'PVWATTPIEK_PRDTPNL':
                            {
                                const propPrd = pvLogic.properties[propNamePrd()];
                                const propValuePrd = parseInt(propPrd.getValue(entdata));
                                const product = _selectionTable.find(function (x) { return x.Id === propValuePrd; });
                                descr = product ? product.Value : "";
                                break;
                            }
                        case 'PVWATTPIEK_EIGWM2':
                            {
                                const propM2 = pvLogic.properties[propNameWPM2()];
                                const propValueM2 = propM2.getValue(entdata);
                                descr = "Eigen waarde ingevoerd van " + propValueM2 + " Wp/m²"
                                break;
                            }
                        //-- VO 2021-08-16: onderstaande cases komen in AppVersion=2 niet meer voor
                        case 'PVWATTPIEK_EIGWPNL':
                            {
                                const propPNL = pvLogic.properties['PV-PVT_WPPNL'];
                                const propValuePNL = propPNL.getValue(entdata);
                                descr = "Eigen waarde ingevoerd van " + propValuePNL + " Wp/paneel"
                                break;
                            }
                        case 'PVWATTPIEK_EIGWTOT':
                            {
                                const propTot = pvLogic.properties['PV-PVT_WPTOT'];
                                const propValueTot = propTot.getValue(entdata);
                                descr = "Eigen waarde ingevoerd van totaal " + propValueTot + " W"
                                break;
                            }
                        default:
                    }
                }

                const propdataInstallDescr = ntaEntityData.get(_installationId).PropertyDatas['INSTALL_OMSCHR'];
                ntaEntityData.saveprop(propdataInstallDescr, descr);
            }
        } //-- end: setDescriptionInstallation ------------------------------------------------------------------------//

        function isHiddenPvVeldHeader(prop) {
            if (!prop || _pvsysData.BuildingId !== ntabuilding.buildingId) {
                return false;
            }

            let showit = true;
            const invoerWattpiek = getInvoerWattPiekValue(_pvsysData);
            switch (prop.Id) {
                case "PV-VELD_AANTALPNL":
                    showit = invoerWattpiek === "PVWATTPIEK_PRDTPNL";
                    break;
                case "PV-VELD_AANTALM2":
                    showit = invoerWattpiek === "PVWATTPIEK_FORFAIT" || invoerWattpiek === "PVWATTPIEK_EIGWM2" || invoerWattpiek === "PVWATTPIEK_PRDTM2";
                    break;
                case "PV-VELD_TYPEINVOER":
                    showit = false;
                    break;
                default:
            }

            return !showit;
        } //-- end: isHiddenPvVeldHeader --------------------------------------------------------------------------------//

        function isHidden(prop, entdata) {
            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return false;
            }

            if (typeof prop === 'string') prop = ntabuilding.properties[prop];
            const propdata = entdata.PropertyDatas[prop.Id];
            const sysData = entdata.EntityId === 'PV-VELD' ? _pvsysData : entdata;
            const invoerWattpiek = getInvoerWattPiekValue(sysData);

            let showit = true;
            let relevant = null;

            switch (prop.Id) {
                case "PV_OPEN":
                case "PV-VELD_TYPEINVOER":
                    showit = false;
                    break;
                case "PV_WATTPIEK":
                case "PV-VELD_ORIE":
                case "PV-VELD_BOUWINTRG":
                case "PV-VELD_BELEM":
                    showit = true;
                    break;
                case 'PV_GEM': { // EPn05
                    //-- Conditie [NB]
                    let hide = false;

                    const propdataInvoer = entdata.PropertyDatas['PV_INVOER'];   // EPn03
                    const appOfUnit = ntaSharedLogic.isAppartementOfUtiliteitVoorBestaandeBouw(); // G04
                    hide = ['PVINVOER_APP', 'PVINVOER_UNIT', 'PVINVOER_WONING'].includes(propdataInvoer.Value) || appOfUnit;

                    showit = !hide;
                    break;
                }
                case 'PV_AG_GEM': { //EPn06
                    //-- Conditie [NC]
                    const propdataGemeenschappelijk = entdata.PropertyDatas['PV_GEM'];   // EPn05
                    const propdataInvoer = entdata.PropertyDatas['PV_INVOER'];           // EPn03
                    const appartementOfUnit = ntaSharedLogic.isAppartementOfUtiliteitVoorBestaandeBouw();

                    const sitA = propdataGemeenschappelijk.Relevant && propdataGemeenschappelijk.Value === 'PVGEM_WEL';
                    const sitB = appartementOfUnit && ['PVINVOER_GEB', 'PVINVOER_GEBDEEL'].includes(propdataInvoer.Value);

                    showit = sitA || sitB;
                    break;
                }
                case "PV_WPPRDT":
                    showit = invoerWattpiek === "PVWATTPIEK_PRDTM2" || invoerWattpiek === "PVWATTPIEK_PRDTPNL";
                    break;
                case "PV_WPPRDTFORF":
                    showit = invoerWattpiek === "PVWATTPIEK_FORFAIT";
                    break;
                case "PV_WPM2_NON":
                    showit = invoerWattpiek === "PVWATTPIEK_PRDTM2" || invoerWattpiek === "PVWATTPIEK_FORFAIT";
                    relevant = showit || invoerWattpiek === 'PVWATTPIEK_PRDTPNL';
                    break;
                case "PV_WPM2":
                    showit = invoerWattpiek === "PVWATTPIEK_EIGWM2";
                    break;
                case "PV_WPPNL_NON":
                    showit = invoerWattpiek === "PVWATTPIEK_PRDTPNL";
                    break;
                case "PV_VEROUDERING_NON": // EPn12
                    //-- Conditie [AC] toon wanneer EP06 = 'productspecifiek Wp/m² ' of 'productspecifiek Wp/paneel' of 'forfaitair'
                    showit = ['PVWATTPIEK_PRDTM2', 'PVWATTPIEK_PRDTPNL', 'PVWATTPIEK_FORFAIT'].includes(invoerWattpiek);
                    break;
                case "PV_VEROUDERING": // EPn13
                    //-- Conditie [AD] toon wanneer EP06 ≠ 'productspecifiek Wp/m² ' of 'productspecifiek Wp/paneel' of 'forfaitair'
                    showit = !['PVWATTPIEK_PRDTM2', 'PVWATTPIEK_PRDTPNL', 'PVWATTPIEK_FORFAIT'].includes(invoerWattpiek);
                    break;
                case "PV-VELD_AANTALPNL":
                    showit = invoerWattpiek === "PVWATTPIEK_PRDTPNL";
                    break;
                case "PV-VELD_AANTALM2":
                    showit = invoerWattpiek === "PVWATTPIEK_FORFAIT" || invoerWattpiek === "PVWATTPIEK_EIGWM2" || invoerWattpiek === "PVWATTPIEK_PRDTM2";
                    break;
                case "PV-VELD_HELLING":
                    {
                        showit = true; //-- altijd zichtbaar.
                        const propdataOrie = entdata.PropertyDatas['PV-VELD_ORIE'];
                        relevant = !(propdataOrie.Value === "PVORIE_HOR");
                        break;
                    }
                //#region Onderstaande cases alleen nodig vooor AppVersion=1
                case "PV-PVT_TYPESYS":
                case "PV-PVT_WATTPIEK":
                case "PV-PVT_ORIE":
                case "PV-PVT_BOUWINTRG":
                case "PV-PVT_BELEM":
                    showit = true;
                    break;
                case 'PV-PVT_GEM': // EP23
                    // Conditie AA
                    showit = false;
                    if (pvLogic.systeemNr(entdata) === 1 && ntaSharedLogic.isAppartementOfUtiliteitVoorBestaandeBouw()) {
                        showit = true;
                    }
                    break;
                case 'PV-PVT_AG_GEM': //EP24
                    {
                        // Conditie AB
                        showit = false;
                        let gemeenschappelijk = entdata.PropertyDatas['PV-PVT_GEM'].Value;
                        if (pvLogic.systeemNr(entdata) === 1 && gemeenschappelijk && gemeenschappelijk === 'PV-PVT_GEM_WEL') {
                            showit = true;
                        }
                        break;
                    }
                case "PV-PVT_VERMRANGE":
                    //-- EP15 ik weet vooralsnog niet wat ik hier mee moet
                    showit = false;
                    break;
                case "PV-PVT_WPM2":
                    showit = invoerWattpiek === "PVWATTPIEK_EIGWM2" || invoerWattpiek === "PVWATTPIEK_PRDTM2" || invoerWattpiek === "PVWATTPIEK_FORFAIT";
                    relevant = showit || invoerWattpiek === 'PVWATTPIEK_PRDTPNL';
                    break;
                case "PV-PVT_WPPNL":
                    showit = invoerWattpiek === "PVWATTPIEK_EIGWPNL" || invoerWattpiek === "PVWATTPIEK_PRDTPNL";
                    break;
                case "PV-PVT_WPTOT":
                    showit = invoerWattpiek === "PVWATTPIEK_EIGWTOT";
                    break;
                case "PV-PVT_WPPRDT":
                    showit = invoerWattpiek === "PVWATTPIEK_PRDTM2" || invoerWattpiek === "PVWATTPIEK_PRDTPNL";
                    break;
                case "PV-PVT_WPPRDTFORF":
                    showit = invoerWattpiek === "PVWATTPIEK_FORFAIT";
                    break;
                case "PV-PVT_AANTAL":
                    showit = invoerWattpiek === "PVWATTPIEK_EIGWPNL" || invoerWattpiek === "PVWATTPIEK_PRDTPNL";
                    break;
                case "PV-PVT_OPP":
                    showit = invoerWattpiek === "PVWATTPIEK_FORFAIT" || invoerWattpiek === "PVWATTPIEK_EIGWM2" || invoerWattpiek === "PVWATTPIEK_PRDTM2";
                    relevant = showit || invoerWattpiek === 'PVWATTPIEK_PRDTPNL';
                    break;
                case "PV-PVT_HELLING":
                    {
                        const propdataOrie = entdata.PropertyDatas['PV-PVT_ORIE'];
                        showit = !(propdataOrie.Value === "PVORIE_HOR");
                        break;
                    }
                case "PV-PVT_VEROUDERING_NON": // EP12
                    // Conditie [AC] toon wanneer EP06 = 'productspecifiek Wp/m² ' of 'productspecifiek Wp/paneel' of 'forfaitair'
                    showit = ['PVWATTPIEK_PRDTM2', 'PVWATTPIEK_PRDTPNL', 'PVWATTPIEK_FORFAIT'].includes(invoerWattpiek);
                    break;
                case "PV-PVT_VEROUDERING": // EP25
                    // Conditie [AD] toon wanneer EP06 ≠ 'productspecifiek Wp/m² ' of 'productspecifiek Wp/paneel' of 'forfaitair'
                    showit = !['PVWATTPIEK_PRDTM2', 'PVWATTPIEK_PRDTPNL', 'PVWATTPIEK_FORFAIT'].includes(invoerWattpiek);
                    break;
                case "PV-PVT_ZONNBSYS": { //--- EP26
                    //-- Conditie [AD]  verberg als EP04 = 'PV'
                    showit = entdata.PropertyDatas["PV-PVT_TYPESYS"].Value !== "PVTYPESYS_PV";

                    let E072value = true;
                    if (showit) {
                        E072value = _zonneboilersystemen.length > 0;
                    }
                    ntaSharedLogic.setMelding('[E072]', propdata, pvLogic.form_pvsys, E072value);
                    break;
                }
                case 'MEASURE-COSTS_PER_SYS': {
                    showit = false;
                    break;
                }
                case 'MEASURE-COSTS_PER_M2': {
                    const propInv = ntabuilding.properties['MEASURE-COSTS_INV'];
                    showit = propInv.getValue(entdata) === 'MEASURE_KOSTEN_PER_M2'; //[PV-B]
                    break;
                }
                case 'MEASURE-COSTS_TOTAAL': {
                    // Bereken hier de totale kosten; weet ff niet waar het anders zou moeten
                    ntaSharedLogic.calculatePVCosts(_installationId, ntaData.shadow);
                    break;
                }

                //#endregion
                default: {
                    //-- de properties van belemmering controleren. Controle op gelijke wijze als bij zonnecollectoren
                    if (ntabuilding.properties['BELEMMERING'].includes(prop) && entdata) {
                        if (entdata.EntityId !== 'BELEMMERING') {
                            showit = hasBelemmering(entdata);
                        } else {
                            const parent = ntaEntityData.getFirstParent(entdata);
                            const propdataBelemmering = parent && (parent.PropertyDatas['PV-VELD_BELEM']);
                            if (propdataBelemmering) {
                                showit = propdataBelemmering.Visible
                                    && ntaSharedLogic.showBelemmeringsProperty(prop, entdata, propdataBelemmering.Value, true);
                            }
                        }
                    }
                }
            }

            /// standaard is relevant gelijk aan visible
            if (relevant === null) relevant = showit;

            ntaEntityData.setPropdataStatus(propdata, relevant, showit);
            return !showit;
        } //-- end: isHidden ------------------------------------------------------------------------//

        function isReadOnly(prop, entdata) {
            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return false;
            }
            if (!entdata.Relevant) {
                /// pv-veld niet relevant is, dan is prop altijd readonly en zie je n.v.t. op het form
                return true;
            }

            const propdataWattPiek = entdata.PropertyDatas['PV-PVT_WATTPIEK'];
            switch (prop.Id) {
                case "PV_VEROUDERING_NON":
                case "PV_WPM2_NON":
                case "PV_WPPNL_NON":
                    return true;
                case "PV-VELD_HELLING":
                    {
                        const propdataOrie = entdata.PropertyDatas['PV-VELD_ORIE'];
                        return propdataOrie.Value === "PVORIE_HOR";
                    }
                //#region Onderstaande cases alleen nodig vooor AppVersion=1
                case "PV-PVT_VEROUDERING_NON":
                    return true;
                case "PV-PVT_WPM2":
                    return propdataWattPiek.Value === "PVWATTPIEK_PRDTM2" || propdataWattPiek.Value === "PVWATTPIEK_FORFAIT";
                case "PV-PVT_WPPNL":
                    return propdataWattPiek.Value === "PVWATTPIEK_PRDTPNL";
                case 'MEASURE-COSTS_TOTAAL': {
                    const propInv = ntabuilding.properties['MEASURE-COSTS_INV'];
                    return propInv.getValue(entdata) === 'MEASURE_KOSTEN_PER_M2'; //[PV-B]
                }

                //#endregion
                default:
                    return false;
            }
        } //-- end: isReadOnly ------------------------------------------------------------------------//

        function getCodedValues(prop, entdata) {
            let typesList = [];

            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return typesList;
            }

            switch (prop.Id) {
                case "PV_INVOER": {
                    // Condities [NF], [NG], [NS]
                    const ids = new Set();
                    if (ntaSharedLogic.voorProjectwoningen()) {
                        ids.add('PVINVOER_WONING');
                    } else if (ntaSharedLogic.perGebouw()) {
                        ids.add('PVINVOER_GEB');
                    } else {
                        if (ntaSharedLogic.isUtiliteit()) {
                            ids.add('PVINVOER_UNIT');
                        } else {
                            ids.add('PVINVOER_APP');
                        }
                        ids.add('PVINVOER_GEB');
                        ids.add('PVINVOER_GEBDEEL');
                    }
                    typesList = ntaValidation.codedValues(prop)
                        .filter(cv => ids.has(cv.Id));
                    break;
                }
                case "PV-VELD_BELEM": {
                    const filterValue = "SYS";
                    typesList = ntaValidation.codedValues(prop, filterValue, true);
                    break;
                }

                //#region Onderstaande cases alleen nodig vooor AppVersion=1
                case "PV-PVT_WATTPIEK": {
                    let  filterValue = "";
                    const propdataValue = entdata.PropertyDatas["PV-PVT_TYPESYS"].Value;
                    if (propdataValue !== "PVTYPESYS_PV") {
                        filterValue = "PV";
                    }

                    typesList = ntaValidation.codedValues(prop, filterValue, true);
                    break;
                }
                case "PV-PVT_BELEM": {
                    const filterValue = "SYS";
                    typesList = ntaValidation.codedValues(prop, filterValue, true);
                    break;
                }
                case "PV-PVT_ZONNBSYS":
                    typesList = _zonneboilersystemen;
                    break;
                //#endregion

                case "MEASURE-COSTS_INV": {
                    typesList = ntaValidation.codedValues(prop)
                        .filter(cv => cv.Id !== 'MEASURE_KOSTEN_PER_M2' || (!ntaData.original.get(_pvsysData.EntityDataId) && isHidden('PV_AG_GEM', _pvsysData)));
                    break;
                }
                default:
                    typesList = ntaValidation.codedValues(prop);
                    break;
            }

            ntaSharedLogic.checkPropdataInList(prop, entdata, typesList, pvLogic);
            return typesList;
        } //-- end: getCodedValues ------------------------------------------------------------------------//

        function checkPVVelden() {
            const prop = _properties['PV_INVOER']; //-- als hij hier niet inzit heb ik temaken met app versie 1 en is deze check niet relevant.
            const entdata = _pvsysData;

            if (!prop || !entdata) {
                return;
            }

            const propdata = entdata.PropertyDatas[prop.Id];

            switch (propdata.Value) {
                case 'PVINVOER_APP':
                case 'PVINVOER_UNIT':
                case 'PVINVOER_WONING': {
                    //-- er moet op zijn minst 1 PV-VELD van dit type zijn per app/unit, anders moet ik er eentje aanmaken.
                    for (const unit of _units) {
                        const pvVelden = pvVeldenByUnit(unit);
                        if (pvVelden.length === 0) {
                            //-- voor deze unit een veld aanmaken en koppelen.
                            createVeld(null, unit);
                        } else {
                            const propInvoerPvVeld = ntabuilding.properties['PV-VELD_TYPEINVOER'];
                            for (const pvVeld of pvVelden) {
                                pvLogic.saveValue(propInvoerPvVeld, pvVeld, propdata.Value);
                            }
                        }
                    }
                    break;
                }
                case 'PVINVOER_GEB': {
                    if (pvVeldenByGebouw().length === 0) {
                        createVeld(null, null);
                    }
                    break;
                }
                case 'PVINVOER_GEBDEEL': {
                    if (pvVeldenByGebouwdeel().length === 0) {
                        const veld = createVeld(null, null);
                        //-- VO 2021-09-08: als er nog geen pv velden van het type 'per gebouwdeel' waren, moeten alle units aan dit
                        //-- veld gekoppeld worden. Normaal wordt het veld alleen gekoppeld aan de units die al een relatie hebben,
                        //-- maar de aller eerste keer is dat nog niet het geval.
                        for (const unit of _units) {
                            ntaEntityData.createRelation(unit.EntityDataId, veld.EntityDataId, 0, 0); //geen cascade -> als UNIT wordt gekopieerd wil je niet de PV-VELD meekopieren alleen de relatie
                        }
                    }
                    break;
                }
            }
        }

        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            let propdata = entdata.PropertyDatas[prop.Id];

            switch (prop.Id) {
                case "PV_INVOER": {
                    checkPVVelden();
                    setRelevancyPvVelden(propdata.Value);
                    break;
                }
                case "PV_WPPRDT":
                case "PV_WPPRDTFORF":
                    {
                        setValuesDependingOnSelectionTable(entdata);
                        setDescriptionInstallation(_pvsysData);
                        break;
                    }
                case "PV_WPM2":
                    {
                        setDescriptionInstallation(_pvsysData);
                        break;
                    }
                case "PV_WATTPIEK":
                    {
                        const invoer = entdata.PropertyDatas['PV_WATTPIEK'].Value;
                        const propId = invoer === "PVWATTPIEK_FORFAIT" ? "PV_WPPRDTFORF" : "PV_WPPRDT";
                        const propMethode = pvLogic.properties[propId];
                        if (propMethode) {
                            getSelectionTable(propMethode, entdata);
                            setSelectedItemSelectionTable(propMethode, entdata);
                            setValuesDependingOnSelectionTable(entdata);
                            setDescriptionInstallation(_pvsysData);
                        }
                        break;
                    }
                case "PV-VELD_ORIE":
                    {
                        //--- als prop.Id=PV-VELD_ORIE en de nieuwe waarde is PVORIE_HOR dan moet PV-VELD_HELLING op 0 gezet worden.
                        if (propdata.Value === "PVORIE_HOR") {
                            const propHellingshoek = pvLogic.propertiesPvVeld["PV-VELD_HELLING"];
                            const propdataHellingshoek = entdata.PropertyDatas[propHellingshoek.Id];
                            const zeroHoek = ntaRounding.roundAndAddZerosNewValue(propHellingshoek, 0);
                            if (propdataHellingshoek.Value !== zeroHoek) {
                                propdataHellingshoek.Value = zeroHoek;
                                ntaMeldingen.melding("[D003]", propdataHellingshoek.PropertyDataId, true);
                                ntaEntityData.saveprop(propdataHellingshoek);
                            }
                        }
                        break;
                    }
                //#region Onderstaande cases alleen nodig vooor AppVersion=1
                case "PV-PVT_ORIE":
                    {
                        //--- als prop.Id=PV-PVT_ORIE en de nieuwe waarde is PVORIE_HOR dan moet PV-PVT_HELLING op 0 gezet worden.
                        if (propdata.Value === "PVORIE_HOR") {
                            const propHellingshoek = pvLogic.properties["PV-PVT_HELLING"];
                            const propdataHellingshoek = entdata.PropertyDatas[propHellingshoek.Id];
                            const zeroHoek = ntaRounding.roundAndAddZerosNewValue(propHellingshoek, 0);
                            if (propdataHellingshoek.Value !== zeroHoek) {
                                propdataHellingshoek.Value = zeroHoek;
                                ntaMeldingen.melding("[D003]", propdataHellingshoek.PropertyDataId, true);
                                ntaEntityData.saveprop(propdataHellingshoek);
                            }
                        }
                        break;
                    }
                case "PV-PVT_WPPRDT":
                case "PV-PVT_WPPRDTFORF":
                    {
                        setValuesDependingOnSelectionTable(entdata);
                        setDescriptionInstallation(entdata);
                        break;
                    }
                case "PV-PVT_WPM2":
                case "PV-PVT_WPPNL":
                case "PV-PVT_WPTOT":
                    {
                        setDescriptionInstallation(entdata);
                        break;
                    }
                case "PV-PVT_WATTPIEK":
                    {
                        const invoer = entdata.PropertyDatas['PV-PVT_WATTPIEK'].Value;
                        const propId = invoer === "PVWATTPIEK_FORFAIT" ? "PV-PVT_WPPRDTFORF" : "PV-PVT_WPPRDT";
                        const propMethode = pvLogic.properties[propId];
                        if (propMethode) {
                            getSelectionTable(propMethode, entdata);
                            setSelectedItemSelectionTable(propMethode, entdata);
                            setValuesDependingOnSelectionTable(entdata);
                            setDescriptionInstallation(entdata);
                        }
                        break;
                    }
                case "PV-PVT_AANTAL": {
                    setPVTotalArea(entdata);
                    break;
                }
                case "PV-PVT_TYPESYS":
                    {
                        validate(prop, propdata, entdata);
                        break;
                    }
                case "PV-PVT_ZONNBSYS":// TO55
                    {
                        if (propdata.Value) {
                            saveKoppelingmetZonneBoiler(propdata, entdata);
                        }
                        break;
                    }
                case "PV-PVT_BELEM":
                    {
                        //-- VO 2021-10-21: als de waarde links, rechts of beide is dan is de entity BELEMMERING relevant, anders niet en hoeft er ook geen validatie plaats te vinden.
                        const relevant = !['BELEMTYPE_MIN', 'BELEMTYPE_OVERIG'].includes(propdata.Value);
                        const belemmeringED = ntaEntityData.getFirstChild(entdata, "BELEMMERING");
                        ntaEntityData.setEntityRelevancy(belemmeringED, relevant);
                        ntaEntityData.setEntityVisibility(belemmeringED, relevant);

                        break;
                    }
                //#endregion
                default:
                    isHidden(prop, entdata); //-- hier wordt relevant aangepast indien nodig
                    if (ntaValidation.hasCodedValues(prop)) {
                        getCodedValues(prop, entdata); //-- hier wordt een keuze value aangepast indien nodig
                    }
                    break;
            }
        } //-- end: validateDependencies ------------------------------------------------------------------------//

        function createVeld(veld, unit) {
            const typeInvoer = _pvsysData.PropertyDatas['PV_INVOER'].Value;
            const parentRels = [
                { "OnCopy": 1, "OnDelete": 1, "Parent": _pvsysData.EntityDataId, "ParentEntityId": "PV" },
            ];
            const propdatas = [{ "PropertyId": "PV-VELD_TYPEINVOER", "Value": typeInvoer }];

            switch (typeInvoer) {
                case 'PVINVOER_APP':
                case 'PVINVOER_UNIT':
                case 'PVINVOER_WONING': {
                    parentRels.push({ "OnCopy": 1, "OnDelete": 1, "Parent": unit.EntityDataId, "ParentEntityId": unit.EntityId })
                    break;
                }
                case 'PVINVOER_GEBDEEL': {
                    //-- VO 2021-09-08: voor alle aangevinkte gebouwdeel units moet een realtie aangemaakt worden. Hier geldt dat OnCopy en OnDelete
                    //-- 0 moet zijn, omdat je bij een kopie of verwijderen niet het Veld weg wilt gooien. Hierboven bij 'per app/unit' is dat wel
                    //-- het geval.
                    for (var gdunit of gebouwdeelUnits()) {
                        parentRels.push({ "OnCopy": 0, "OnDelete": 0, "Parent": gdunit.EntityDataId, "ParentEntityId": gdunit.EntityId })
                    }
                    break;
                }
            }

            const newId = ntaEntityData.create("PV-VELD", veld ? veld.Order : -1, parentRels, [], propdatas);
            ntaEntityData.create('BELEMMERING', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newId, "ParentEntityId": "PV-VELD" }], [], []);

            return ntaEntityData.get(newId);
        } //-- end: createVeld --------------------------------------------------------------------------------//

        function setRelevancyPvVelden(invoerCode) {
            // zorg dat alleen de PvVelden relevant zijn van de huidige invoerCode. De andere PvVelden kunnen op irrelevant.

            const relevantePvVelden = [];
            const irrelevantePvVelden = [];
            for (const pvVeld of pvAlleVelden()) {
                if (pvVeld.PropertyDatas['PV-VELD_TYPEINVOER'].Value === invoerCode) {
                    relevantePvVelden.push(pvVeld);
                } else {
                    irrelevantePvVelden.push(pvVeld);
                }
            }

            ntaEntityData.setEntityRelevancy(relevantePvVelden, true);
            ntaEntityData.setEntityVisibility(relevantePvVelden, true);

            ntaEntityData.setEntityRelevancy(irrelevantePvVelden, false);
            ntaEntityData.setEntityVisibility(irrelevantePvVelden, false);
        } //-- end: setRelevancyPvVelden --------------------------------------------------------------------------------//

        function hasBelemmering(entdata) {
            let result = false;

            if (!entdata) {
                return result;
            }

            if (!entdata.Relevant)
                return result;

            const belemmering = ntaEntityData.getFirstChild(entdata, 'BELEMMERING');
            if (belemmering) {
                const belemmeringtype = entdata.PropertyDatas['PV-VELD_BELEM'].Value;
                result = !['BELEMTYPE_MIN', 'BELEMTYPE_VOLLEDIG', 'BELEMTYPE_OVERIG', 'n.v.t.'].includes(belemmeringtype);

                ntaEntityData.setEntityRelevancy(belemmering, result);
                ntaEntityData.setEntityVisibility(belemmering, result);
            }

            return result;
        } //-- end: hasBelemmering ------------------------------------------------------------------------//

        function startFormValidation() {
            //-- VO 2021-08-16: Vanaf AppVersion 2 en hoger is er geen PVT op dit formulier en dus ook geen koppeling met ZONNB
            if (_appVersion === 1) {
                //-- VO 2020-10-07: Code voor trello kaartje https://trello.com/c/eGcoEgLf
                const prop = pvLogic.properties["PV-PVT_ZONNBSYS"];
                for (const sys of systemen()) {
                    const propdata = prop.getData(sys);
                    if (!ntaSharedLogic.getRelData(sys, 'ZONNBSYS')) {
                        propdata.Value = null;
                    } else {
                        propdata.Value = "n.v.t.";
                    }
                }
            }
            return ntaSharedLogic.startFormValidation(getAllEntDatas(), pvLogic);
        } //-- end: startFormValidation ------------------------------------------------------------------------//

        function endFormValidation() {
            ntaSharedLogic.endFormValidation(getAllEntDatas(), pvLogic);
        } //-- end: endFormValidation ------------------------------------------------------------------------//

        function getAllEntDatas() { // Geeft alle entdatas in één array terug
            return []
                .concat(systemen())
                .concat(pvAlleVelden())
                .concat(ntaSharedLogic.getCostEntdata(_installationId))
                .filter(entdata => entdata) // filter alle nulls en undefineds eruit
                ;
        } //-- end: getAllEntDatas ------------------------------------------------------------------------//

        //#region onderstaande functies alleen nodig bij AppVersion=1
        function setPVTotalArea(entdata, product = null) {
            if (_appVersion === 1) {
                if (entdata.PropertyDatas[propNameWattPiek()].Value !== 'PVWATTPIEK_PRDTPNL') // productspecifiek Wp/paneel
                    return;

                product = product || getListItem(ntabuilding.properties['PV-PVT_WPPRDT'], entdata);
                if (!product || !product.Apv) {
                    saveValue('PV-PVT_OPP', entdata, null);
                    return;
                }

                const areaPerPanel = product.Apv;
                const numberOfPanels = ntaSharedLogic.parseFloat(entdata.PropertyDatas['PV-PVT_AANTAL'].Value, 1);
                const totalArea = areaPerPanel * numberOfPanels;
                saveValue('PV-PVT_OPP', entdata, totalArea);

                return totalArea;
            }
        } //-- end: setPVTotalArea ------------------------------------------//

        function getZonneboilersysCodedValues() {
            let zbCodedValues = [];

            let zbsys = ntaEntityData.getListWithEntityId("ZONNBSYS");
            for (let index = 0; index < zbsys.length; index++) {
                let zbsysKeuze = {};
                zbsysKeuze.Id = zbsys[index].EntityDataId;
                zbsysKeuze.Value = 'Zonneboiler ' + (index + 1);
                zbCodedValues.push(zbsysKeuze);
            }
            return zbCodedValues;
        } //-- end: getZonneboilersysCodedValues ------------------------------------------------------------------------//

        function saveKoppelingmetZonneBoiler(propdata, entdata) {
            // koppeling zoeken met zonneboiler (er kan maar één relatie zijn tussen PV-PVT <<-->> ZONNBSYS zijn)
            const relation = ntaEntityData.getParentRelations(entdata, 'ZONNBSYS')[0];

            // Als er al een relatie met dit zonneboilersysteem bestaat, hoeft er niets te gebeuren
            if (relation && relation.Parent === propdata.Value) {
                return;
            }

            // oude koppeling verwijderen
            if (relation) {
                ntaEntityData.deleteRelation(relation.EntityRelationDataId);
            }

            // koppeling toevoegen
            ntaEntityData.createRelation(propdata.Value, entdata.EntityDataId, false, false);
        } //-- end: saveKoppelingOpwekkerEnRekenzone ----------------------------------------------------------------------//
        //#endregion
    }

    return PvLogic;
}]);
