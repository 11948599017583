angular.module('indexModule').controller("NavigatieController", ['indextree', function (indextree) {
    'use strict';
    const vm = this;

    vm.clickPrullenbak = function () {
        let element = document.getElementById("folderitem-2");
        if (element) {
            indextree.isPrullenbakOpen = false; // Zet standaard op false zodat bij element.click() deze waarde op true wordt gezet. Zo kunnen we garanderen dat bij het klikken van de navigatie de prullenbak altijd open is.
            element.click();
            element.scrollIntoView();
        }
    };
}])