﻿angular.module('projectModule')
    .factory("AttributesFactory",
        ['$log', '$mdDialog', 'ntaData', 'projecttree', 'ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaMeldingen', 'settingsMenuData', 'ntaSharedLogic', 'time',
function ($log,   $mdDialog,   ntaData,   projecttree,   ntabuilding,   ntaValidation,   ntaEntityData,   ntaMeldingen,   settingsMenuData,   ntaSharedLogic,   time) {
    function AlgemeneGegevensLogic(ntaDependencyValidation) {
        var attrLogic = this;

        //Data
        attrLogic.properties = ntabuilding.properties['GEB'];
        attrLogic.buildingattributes = ntaEntityData.getFirstWithEntityId('GEB');

        //Initialize
        var init = function () {
            //Lege datum op vandaag zetten. -> best is op client ivm localization
            var date = attrLogic.buildingattributes.PropertyDatas['GEB_DATE'].Value;
            if (!date || date.length === 0) {
                attrLogic.buildingattributes.PropertyDatas['GEB_DATE'].Value = new Date();
                attrLogic.saveValue(ntabuilding.properties["GEB_DATE"]);
            }
        };

        //Properties
        attrLogic.saveValue = function (prop) {
            if (!prop || !attrLogic.buildingattributes) {
                return;
            }
            var propdata = attrLogic.buildingattributes.PropertyDatas[prop.Id];
            propdata.Touched = true; // opslaan veld is aangeraakt

            if (propdata.Value === undefined) { // niks doen -> undefined komt van een waarschuwing
                return;
            }

            attrLogic.validate(prop, propdata);
            if (ntaEntityData.saveprop(propdata)) { //Server validatie en evt. opslaan
                //recalculate

                if (propdata.PropertyId === "GEB_OMSCHR") {
                    //juiste gebouwnaam zetten in projecttree.berekeningen
                    projecttree.berekeningen.find(function (x) { return x.GebouwId === ntabuilding.buildingId; }).Name = propdata.Value;
                }
                if (propdata.PropertyId === "GEB_TYPEGEB") {
                    //juiste icoontje zetten in projecttree.berekeningen
                    projecttree.berekeningen.find(function (x) { return x.GebouwId === ntabuilding.buildingId; }).GebouwIcon =
                        prop.Domain.Codes.find(x => x.Id === propdata.Value).ImageUrl;

                    if (propdata.Value !== 'TGEB_UTILIT' && propdata.Value !== 'TGEB_APPGEB') {
                        let gemeenschappelijkeRuimtes = ntaEntityData.getListWithEntityId('GRUIMTE');
                        gemeenschappelijkeRuimtes.forEach(function (ruimte, index) {
                            ntaEntityData.delete(ruimte.EntityDataId);
                        });

                        //ntaDependencyValidation.checkChangedField(prop, entdata, attrLogic);

                        //let ldLogic = new ntaDependencyValidation.LuchtdoorlatenFactory();
                        //let propInvoerInfiltratie = ldLogic.infiltratieProperties['INFIL_INVOER'];
                        //ldLogic.getCodedValues(ldLogic.infiltratieData, propInvoerInfiltratie);
                    }
                    else if (propdata.Value === 'TGEB_UTILIT') {
                        let rzform = ntaEntityData.getFirstWithEntityId('RZFORM');
                        let propdataEnergieprestatieBerekenen = rzform.PropertyDatas['RZFORM_CALCUNIT'];
                        ntaEntityData.saveprop(propdataEnergieprestatieBerekenen, 'RZUNIT_GEBUNIT');
                    }
                    else if (propdata.Value === 'TGEB_APPGEB') {
                        let rzform = ntaEntityData.getFirstWithEntityId('RZFORM');
                        let propdataEnergieprestatieBerekenen = rzform.PropertyDatas['RZFORM_CALCUNIT'];
                        ntaEntityData.saveprop(propdataEnergieprestatieBerekenen, 'RZUNIT_GEBAPP');
                    }
                }
                if (propdata.PropertyId === 'GEB_SRTBW') {
                    time.delayActions(function () {
                        let bouwjaarpropdata = attrLogic.buildingattributes.PropertyDatas['GEB_BWJR'];
                        let renovatiejaarpropdata = attrLogic.buildingattributes.PropertyDatas['GEB_RENOVJR'];
                        let opleverjaarpropdata = attrLogic.buildingattributes.PropertyDatas['GEB_OPLVJR'];
                        let bouwjaarprop = attrLogic.properties['GEB_BWJR'];
                        let renovatiejaarprop = attrLogic.properties['GEB_RENOVJR'];
                        let opleverjaarprop = attrLogic.properties['GEB_OPLVJR'];

                        attrLogic.validate(bouwjaarprop, bouwjaarpropdata);
                        attrLogic.validate(renovatiejaarprop, renovatiejaarpropdata);
                        attrLogic.validate(opleverjaarprop, opleverjaarpropdata);
                    });
                }
                if (prop.Id === 'GEB_OPN') {

                    // Conditie N, waarschuwing geven als er gewisseld wordt van detail naar basis
                    if (propdata.Value === 'OPN_BASIS') {
                        let warning = ntabuilding.warnings.find(function (x) { return x.Id === "[W028]"; });
                        warning.Value = ntabuilding.ntaVersionId < 300 ? warning.Value: warning.Value.replace('zonwering, ', ''); //https://trello.com/c/cu0t1iCO/1313-zonwering-ui

                        let confirm = $mdDialog.confirm()
                            .title(warning.FilterValue1)
                            .textContent(warning.Value)
                            .ariaLabel(warning.FilterValue1)
                            .targetEvent(event)
                            .ok('Ja')
                            .cancel('Nee');

                        $mdDialog.show(confirm).then(
                            function () {
                                // Doorgaan
                                let settingThermischeBruggenForfaitair = settingsMenuData.getSetting('SETTINGS_THBRUG');

                                if (propdata.Value === 'OPN_BASIS') {
                                    if (settingThermischeBruggenForfaitair.Value === "False") {
                                        settingThermischeBruggenForfaitair.Status = false;
                                        settingThermischeBruggenForfaitair.Value = "True";
                                        ntaEntityData.saveprop(settingThermischeBruggenForfaitair);
                                    }
                                    //-- als er een koelsysteem is geldt conditie KOAG. Dit is een melding.
                                    let koelSyss = ntaEntityData.getListWithEntityId('KOEL');
                                    if (ntabuilding.ntaVersionId < 300 && koelSyss.length > 0) {  
                                        ntaMeldingen.warning("[W011]");
                                    }
                                }
                            },
                            function () {
                                // Detailopname opnieuw opslaan
                                propdata.Value = 'OPN_DETAIL';
                                attrLogic.saveValue(prop);
                            });
                    }

                    if (propdata.Value === 'OPN_DETAIL') {
                        let settingThermischeBruggenForfaitair = settingsMenuData.getSetting('SETTINGS_THBRUG');
                        if (settingThermischeBruggenForfaitair.Value === "True") {
                            settingThermischeBruggenForfaitair.Status = true;
                            settingThermischeBruggenForfaitair.Value = "False";
                            ntaEntityData.saveprop(settingThermischeBruggenForfaitair);
                        }
                    }
                }

                ntaDependencyValidation.checkChangedField(prop, attrLogic.buildingattributes, attrLogic);
            }
        };

        attrLogic.saveValueAutocomplete = function (prop, item, value) {
            if (!prop || !attrLogic.buildingattributes) {
                return;
            }
            var propdata = attrLogic.buildingattributes.PropertyDatas[prop.Id];
            propdata.Touched = true;

            if (item && item.Id) {
                propdata.Value = item.Id;
            } else {
                propdata.Value = value;
            }
            attrLogic.validate(prop, propdata);
            if (ntaEntityData.saveprop(propdata)) { //Server validatie en evt. opslaan
                //recalculate
            }
        };

        attrLogic.validate = function (prop, propdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            const isHidden = attrLogic.isHidden(prop);
            ntaValidation.IsValid(attrLogic.form_attrib, prop, propdata, isHidden);  //Client Validatie
            const contrl = attrLogic.form_attrib && attrLogic.form_attrib['ntainput' + propdata.PropertyDataId];
            if (prop.Id === "GEB_OMSCHR") { // check uniek naam
                if (!propdata.Touched) {
                    return true; // niet valideren
                }

                // Conditie [A]
                //alle gebouw omschrijvingen er uit halen en dan checken of de value ervan hetzelfde is als propdata.Value
                const isUnique = !ntaData.projecttree.Gebouwberekeningen.filter(g => g.GebouwId !== propdata.BuildingId).some(g => g.Name === propdata.Value);
                if (contrl) { // altijd checken of contrl er is -> kan hidden zijn (ng-if) -> message wel altijd wel/niet laten zien.
                    contrl.$setValidity("[E001]", isUnique);
                }
                ntaMeldingen.melding("[E001]", propdata.PropertyDataId, isUnique);
            }
            //-- VO 2021-01-07: Warning W002 (Conditie [D]) even uitgezet tot dat we kunnen rekenen per unit/appartement. Over twee weken dus weer aan. Niet vergeten
            //-- VO 2021-04-06: blijft voorlopig ook uitstaan omdat we de melding irritant vinden.
            //if (prop.Id === "GEB_TYPEGEB" && propdata.Value === "TGEB_APP") { // check voor appartement (voor bestaande bouw) met nieuwbouw
            //    const soortBouw = attrLogic.buildingattributes.PropertyDatas['GEB_SRTBW'].Value;
            //    if (soortBouw === "NIEUWB") {
            //        ntaMeldingen.warning("[W002]");
            //    }
            //}
            //if (prop.Id === "GEB_SRTBW" && propdata.Value === "NIEUWB") { // check voor appartement (voor bestaande bouw) met nieuwbouw
            //    const gebouwtype = attrLogic.buildingattributes.PropertyDatas['GEB_TYPEGEB'].Value;
            //    if (gebouwtype === "TGEB_APP") {
            //        ntaMeldingen.warning("[W002]");
            //    }
            //}
            //-- VO 2021-04-01: Warning W010 (Conditie [J]) even uitgezet tot dat we kunnen rekenen per unit/appartement. Over twee weken dus weer aan. Niet vergeten
            //-- VO 2021-04-06: blijft voorlopig ook uitstaan omdat we de melding irritant vinden.
            //if (prop.Id === "GEB_TYPEGEB" && propdata.Value === "TGEB_UTILUNIT") { // check voor utiliteit (voor bestaande bouw) met nieuwbouw
            //    const soortBouw = attrLogic.buildingattributes.PropertyDatas['GEB_SRTBW'].Value;
            //    if (soortBouw === "NIEUWB") {
            //        ntaMeldingen.warning("[W010]");
            //    }
            //}
            //if (prop.Id === "GEB_SRTBW" && propdata.Value === "NIEUWB") { // check voor utiliteit (voor bestaande bouw) met nieuwbouw
            //    const gebouwtype = attrLogic.buildingattributes.PropertyDatas['GEB_TYPEGEB'].Value;
            //    if (gebouwtype === "TGEB_UTILUNIT") {
            //        ntaMeldingen.warning("[W010]");
            //    }
            //}
            if (prop.Id === "GEB_RENOVJR" || prop.Id === "GEB_BWJR") {
                const bwjrprop = attrLogic.buildingattributes.PropertyDatas['GEB_BWJR'];
                const renojrprop = attrLogic.buildingattributes.PropertyDatas['GEB_RENOVJR'];
                const contrlbwjr = attrLogic.form_attrib && attrLogic.form_attrib['ntainput' + bwjrprop.PropertyDataId];
                const contrlrenojr = attrLogic.form_attrib && attrLogic.form_attrib['ntainput' + renojrprop.PropertyDataId];

                //zet eerst beide op true
                var isValid = true;
                if (contrlbwjr) {
                    contrlbwjr.$setValidity("[E038]", isValid);
                }
                ntaMeldingen.melding("[E038]", bwjrprop.PropertyDataId, isValid);
                if (contrlrenojr) {
                    contrlrenojr.$setValidity("[E038]", isValid);
                }
                ntaMeldingen.melding("[E038]", renojrprop.PropertyDataId, isValid);

                //check
                if (renojrprop.Value && bwjrprop.Value) {
                    const renovatiejaarprop = ntabuilding.properties['GEB_RENOVJR'];
                    if (attrLogic.isHidden(renovatiejaarprop)) {
                        isValid = true;
                    }
                    else {
                        isValid = parseInt(renojrprop.Value) > parseInt(bwjrprop.Value); // conditie [K] renovatiejaar G07 is eerder dan bouwjaar G06 -> [E038]
                    }
                }

                if (contrl) { // altijd checken of contrl er is -> kan hidden zijn (ng-if) -> message wel altijd wel/niet laten zien.
                    contrl.$setValidity("[E038]", isValid);
                }
                ntaMeldingen.melding("[E038]", propdata.PropertyDataId, isValid);
            }
        };

        attrLogic.startFormValidation = function () {
            attrLogic.properties.forEach(function (prop, index) {
                var propdata = attrLogic.buildingattributes.PropertyDatas[prop.Id];
                attrLogic.validate(prop, propdata);
            });
        }

        attrLogic.endFormValidation = function () {
            attrLogic.buildingattributes.PropertyDatas.forEach(function (propdata, index) {
                if (!propdata.Touched) {
                    propdata.Touched = true;
                    ntaEntityData.saveprop(propdata);
                }
            });
            attrLogic.startFormValidation();
        };

        attrLogic.isHidden = function (prop) {
            if (!prop || !attrLogic.buildingattributes) {
                return;
            }

            let isHidden = false;
            const propdata = attrLogic.buildingattributes.PropertyDatas[prop.Id];

            if (prop.Id === "GEB_BWJR") { // indien Bestaande bouw -> toon bouwjaar
                isHidden = !ntaSharedLogic.isBestaandeBouw(attrLogic.buildingattributes);
            }
            else if (prop.Id === "GEB_OPLVJR") { // indien Nieuwbouw -> toon jaar van oplevering
                isHidden = !ntaSharedLogic.isNieuwbouw(attrLogic.buildingattributes);
            }
            else if (prop.Id === "GEB_RENOVJR") { // indien Bestaande bouw -> toon renovatiejaar

                if (!ntaSharedLogic.isBestaandeBouw(attrLogic.buildingattributes)) {
                    isHidden = true;
                }
                else {
                    const bouwsoort = attrLogic.buildingattributes.PropertyDatas['GEB_SRTBW'].Value;
                    if (bouwsoort !== "BESTBG") {
                        isHidden = true;
                    }
                }
            }
            else if (prop.Id === 'GEB_OPEN') {
                isHidden = true;
            }
            else if (prop.Id === 'GEB_HASMELD') {
                isHidden = true;
            }
            else if (prop.Id === 'GEB_CALCNEEDED') {
                isHidden = true;
            }

            ntaEntityData.setPropdataRelevancy(propdata, !isHidden, attrLogic.form_attrib);
            ntaEntityData.setPropdataVisibility(propdata, !isHidden);

            return isHidden;
        };

        attrLogic.getCodedValues = function (prop) {
            if (!prop || !attrLogic.buildingattributes) {
                return;
            }

            if (prop.Id === "GEB_OPN") { //check voor GEB_OPN: detailopname bij GEB_SRTBW: Nieuwbouw
                var value3 = attrLogic.buildingattributes.PropertyDatas['GEB_SRTBW'].Value;
                return ntaValidation.codedValues(prop, value3, true);
            }

            if (prop.Id === "GEB_TYPEGEB") {
                let values = ntaValidation.codedValues(prop, value3, true);
                let value = attrLogic.buildingattributes.PropertyDatas['GEB_TYPEGEB'].Value; //Zie conditie [O]
                let modules = ntaData.modules;
                if (!ntaData.modules.includes("nonResidential") || (value !== "TGEB_UTILIT" && value !== "TGEB_UTILUNIT")) {
                    return values.filter(function (x) { return x.Id !== "TGEB_UTILIT" && x.Id !== "TGEB_UTILUNIT";});
                }
                if (!ntaData.modules.includes("residential") || value === "TGEB_UTILIT" || value === "TGEB_UTILUNIT") {
                    return values.filter(function (x) { return x.Id === "TGEB_UTILIT" || x.Id === "TGEB_UTILUNIT"; });
                }
                return values;
            }

            return ntaValidation.codedValues(prop);
        };

        attrLogic.setGeopend = function () {
            const propdataOpen = attrLogic.buildingattributes.PropertyDatas['GEB_OPEN'];
            ntaEntityData.saveprop(propdataOpen, 'true');
        };

        // Ontvangt afhankelijk veld dat gevalideerd moet worden
        attrLogic.validateDependencies = function (prop, entdata) {
            if (!prop || !entdata) {
                return;
            }
            switch (prop.Id) {
                default: {
                    const checkValue = prop.getData(entdata).Value;

                    let hidden = attrLogic.isHidden(prop);
                    if (ntaValidation.hasCodedValues(prop)) {
                        attrLogic.getCodedValues(prop);
                    }

                    if (checkValue !== prop.getData(entdata).Value) {
                        attrLogic.saveValue(prop);
                    }
                }
            }
        };

        init();
    }

    return AlgemeneGegevensLogic;
}]);
