﻿angular.module('projectModule')
    .factory('EventSource',
        ['$log',
function ($log) {
    'use strict';
    return function EventSource(defaultSender) {
        const self = this;

        const _eventHandlers = new Map();

        self.on = on;
        self.off = off;
        self.trigger = trigger;

        // on: hiermee registreer je een event handler voor events met de opgegeven eventName (zoals ‘forgetUpdates’).
        function on(eventName, eventHandler) {
            const eventNames = Array.isArray(eventName) ? eventName : [eventName];
            for (const eventName of eventNames) {
                const handlers = _eventHandlers.get(eventName) || _eventHandlers.set(eventName, new Set()).get(eventName);
                handlers.add(eventHandler);
            }
        }

        // off: hiermee verwijder je een event handler voor events met de opgegeven eventName (zoals ‘forgetUpdates’). Als er geen eventHandler is opgegeven, worden alle event handlers voor de opgegeven eventName verwijderd.
        function off(eventName, eventHandler = null) {
            if (eventName) {
                const eventNames = Array.isArray(eventName) ? eventName : [eventName];
                for (const eventName of eventNames) {
                    const handlers = eventHandler && _eventHandlers.get(eventName) || new Set();
                    if (eventHandler) {
                        handlers.delete(eventHandler);
                    } else {
                        handlers.clear();
                    }
                    if (handlers.size === 0) {
                        _eventHandlers.delete(eventName);
                    }
                }
            } else {
                if (eventHandler) {
                    for (const handlers of _eventHandlers.values()) {
                        handlers.delete(eventHandler);
                    }
                } else {
                    _eventHandlers.clear();
                }
            }
        }

        // trigger: hiermee worden alle geregistreerde event-handlers voor de opgegeven eventName uitgevoerd. Fouten worden gelogd maar verder genegeerd.
        function trigger(eventName, ...args) {
            let sender = defaultSender || self;
            if (typeof eventName === 'object' && (typeof args[0] === 'string' || Array.isArray(args[0]) && args[0].every(item => typeof item === 'string'))) {
                [sender, eventName] = [eventName, args.shift()];
            }

            if (typeof args[0] === 'object' && !!args[0]) {
                args[0] = Object.assign({ eventName }, args[0]);
            }

            //console.debug(eventName, 'from', sender, ':', ...args);

            const handlers = _eventHandlers.get(eventName) || new Set();
            const results = [];
            for (const handler of handlers) {
                try {
                    const result = handler.apply(sender, args);
                    if (result !== undefined) {
                        results.push(result);
                    }
                } catch (err) {
                    $log.error(err, 'tijdens', eventName, 'door', handler, 'met args [', args, ']');
                }
            }
            return results;
        }
    }
}]);
