﻿angular.module('projectModule')
    .controller("InstallationTapwaterController",
        ['$scope', '$controller', 'ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaSharedLogic', '$routeParams', '$mdDialog', 'progressCircle', 'ntaMeldingen', '$timeout', 'ntaDependencyValidation', 'ntaMeasure', 'Pager', 'ListCache', 'TapwaterFactory',
function ($scope,   $controller,   ntabuilding,   ntaValidation,   ntaEntityData,   ntaSharedLogic,   $routeParams,   $mdDialog,   progressCircle,   ntaMeldingen,   $timeout,   ntaDependencyValidation,   ntaMeasure,   Pager,   ListCache,   TapwaterFactory) {
    const vm = this;
    const base = $controller('NTABaseController', { $scope: $scope })
    angular.extend(vm, base);

    vm.ntaValidation = ntaValidation;
    vm.installId = $routeParams.id3;

    let loadingDone = false;

    const tapwaterLogic = new TapwaterFactory(vm.installId, ntaDependencyValidation);
    vm.getTapwaterLogic = function () {
        return tapwaterLogic;
    };

    vm.preventBackspace = ntaSharedLogic.preventBackspace;

    const _listCache = new ListCache();

    //-- Properties
    vm.propOpmerkingen = tapwaterLogic.propOpmerkingen;
    vm.tapwOpwekkerProperties = tapwaterLogic.tapwOpwekkerProperties;
    vm.tapwVoorraadvatProperties = tapwaterLogic.tapwVoorraadvatProperties;
    vm.tapwDistributieProperties = tapwaterLogic.tapwDistributieProperties;
    vm.tapwAfgifteProperties = tapwaterLogic.tapwAfgifteProperties;
    vm.tapwDouchewarmteterugwinningProperties = tapwaterLogic.tapwDouchewarmteterugwinningProperties;
    vm.tapwDistributieBinnenProperties = tapwaterLogic.tapwDistributieBinnenProperties;
    vm.tapwDistributieBuitenProperties = tapwaterLogic.tapwDistributieBuitenProperties;
    vm.tapwDistributieEigenschappenLeidingenProperties = tapwaterLogic.tapwDistributieEigenschappenLeidingenProperties;
    vm.tapwDistributiePompProperties = tapwaterLogic.tapwDistributiePompProperties;
    vm.tapwAfgifteLeidingenProperties = tapwaterLogic.tapwAfgifteLeidingenProperties;
    vm.tapwDouchesAangeslotenOpWTWProperties = tapwaterLogic.tapwDouchesAangeslotenOpWTWProperties;

    //-- table13_0
    vm.opwekkerTypes = tapwaterLogic.opwekkerTypes;
    vm.voorraadvatTypes = tapwaterLogic.voorraadvatTypes;

    vm.getOpwekkerTypes = tapwaterLogic.getOpwekkerTypes;

    //-- Tapwater id
    vm.tapwDataId = tapwaterLogic.tapwDataId;
    vm.tapwData = tapwaterLogic.tapwData;

    const getTapwOpwekkersIds = tapwaterLogic.getTapwOpwekkersIds;
    const setTapwOpwekkers = tapwaterLogic.setTapwOpwekkers;
    const startValidationEnergiefractie = tapwaterLogic.startValidationEnergiefractie;

    vm.getTitle = () => ntaSharedLogic.isEditingMeasure() ? 'Maatregel: wijzigen warm tapwater' : 'Warm tapwater';

    //-- Opwekking entiteiten
    vm.tapwOpwekkersData = function () {
        return tapwaterLogic.tapwOpwekkersData;
    };

    vm.opwekkerNr = tapwaterLogic.opwekkerNr;

    vm.opwekkerNaam = function (opwekker) {
        let nummer = vm.opwekkerNr(opwekker);
        let naam = 'Opwekker ' + nummer;

        return naam;
    } //-- end: opwekkerNaam ------------------------------------------------------------------------//

    var setTapwVaten = tapwaterLogic.setTapwVaten;

    //-- Voorraadvat entiteiten
    vm.tapwVatenData = function () {
        return tapwaterLogic.tapwVatenData;
    };

    var setDefaultValuesVaten = tapwaterLogic.setDefaultValuesVaten;

    vm.vatNr = tapwaterLogic.vatNr;

    vm.voorraadvatNaam = function (voorraadvat) {
        let nummer = vm.vatNr(voorraadvat);
        let naam = 'Voorraadvat ' + nummer;

        return naam;
    } //-- end: voorraadvatNaam ------------------------------------------------------------------------//

    //-- Distributie
    vm.tapwDistributieData = function () {
        return tapwaterLogic.tapwDistributieData;
    };

    //-- Distributie binnen
    vm.tapwDistributieBinnenData = function () {
        return tapwaterLogic.tapwDistributieBinnenData;
    };

    //-- Distributie buiten
    vm.tapwDistributieBuitenData = function () {
        return tapwaterLogic.tapwDistributieBuitenData;
    };

    //-- Distributie leidingen
    vm.tapwDistributieEigenschappenLeidingenBinnenData = function () {
        return tapwaterLogic.tapwDistributieEigenschappenLeidingenBinnenData;
    };
    vm.tapwDistributieEigenschappenLeidingenBuitenData = function () {
        return tapwaterLogic.tapwDistributieEigenschappenLeidingenBuitenData;
    };
    vm.tapwDistributieEigenschappenLeidingenData = function () {
        return tapwaterLogic.tapwDistributieEigenschappenLeidingenData;
    };

    // Distributiepomp
    vm.tapwDistributiePompData = function () {
        return tapwaterLogic.tapwDistributiePompData;
    };

    //-- Afgifte (bij tapwater is er maar 1 afgifte)
    vm.tapwAfgifteData = function () {
        return tapwaterLogic.tapwAfgifteData;
    };

    var setTapwDouche = tapwaterLogic.setTapwDouche;

    // Get units
    vm.units = function () {
        return tapwaterLogic.units;
    };

    // Douchewaterwarmteterugwinning entiteiten
    vm.tapwDoucheData = function () {
        return tapwaterLogic.tapwDoucheData;
    };

    vm.doucheNr = tapwaterLogic.doucheNr;

    vm.doucheNaam = function (douche) {
        let nummer = vm.doucheNr(douche);
        let naam = 'Douche-wtw ' + nummer;

        return naam;
    } //-- end: doucheNaam ------------------------------------------------------------------------//


    var setDefaultDouche = tapwaterLogic.setDefaultDouche;

    ///////////////////////////////// PAGING //////////////////////////////////////////////////////
    vm.pager = new Pager({ storageName: 'tapwAfgifteLeidingen', visible: shouldShowPagingBar() });
    vm.pager.on('validationRequested', validatePages);

    function shouldShowPagingBar() {
        return ntaSharedLogic.perGebouwEnAppartementOfUnit()
            || ntaSharedLogic.voorProjectwoningen();
    } //-- end: shouldShowPagingBar -------------------------------------------------------------//

    function validatePages(event) {
        const invalidPropdatas = tapwaterLogic.startFormValidation();
        const invalidEntdatas = invalidPropdatas
            .map(propdata => propdata && ntaEntityData.get(propdata.EntityDataId))
            .filter(entdata => entdata)
            .distinct();
        for (const page of event.pages) {
            page.isValid = !page.rows.some(row => invalidEntdatas.some(entdata => entdata === row.leiding));
        }
    } //-- end: validatePages -------------------------------------------------------------------//

    /////////////////////////////////SELECTIONTABLE//////////////////////////////////////////////////////////////////////////////////////////////

    vm.getSelectionTable = tapwaterLogic.getSelectionTable;
    vm.getSelectionTableBVat = tapwaterLogic.getSelectionTableBVat;
    vm.getSelectionTableDWTW = tapwaterLogic.getSelectionTableDWTW;
    vm.saveSelectionTableValue = tapwaterLogic.saveSelectionTableValue;

    ////////////////////////////////////////////////////////////////////////// FORM /////////////////////////////////////////////////////////////////////////////////////////////////////////
    vm.getForm = function () {
        return tapwaterLogic.form_installationtapwater;
    } //-- end: getForm ------------------------------------------------------------------------//

    $scope.endFormValidation = tapwaterLogic.endFormValidation;

    ntaMeasure.setBtwPropIds(vm);

    angular.element(function () { //alle touched properties valideren na het laden van form
        tapwaterLogic.startFormValidation();
        base.initializeUnitSpacing();
    }); //-- end: angular.element ------------------------------------------------------------------------//

    /////////////////////////////////////////////////////////////////////////////////// PROPERTIES ////////////////////////////////////////////////////////////////////////////////////////////////////
    vm.saveValue = async function (prop, entdata) {
        if (!entdata || !prop) {
            return;
        }

        let shouldSave = true;
        if (prop.Id === 'TAPW-OPWEK_BRON_BOOS_KOEL') {
            const propdata = prop.getData(entdata);
            const propValue = propdata.Value;
            // Voorkom dat de waarschuwing elke keer verschijnt bij het openen van het formulier
            if (loadingDone && propValue !== 'TAPW-OPWEK_BRON_BOOS_KOEL_GEEN') {
                shouldSave = false;
                // Conditie [TOBJ]
                const warning = ntabuilding.warnings.find(function (x) { return x.Id === "[W031]"; });
                const dialog = $mdDialog.confirm()
                    .title(warning.FilterValue1)
                    .textContent(warning.Value)
                    .targetEvent(event)
                    .ok('Doorgaan')
                    .cancel('Annuleren');
                tapwaterLogic.w031Open = true; // Geef aan dat waarschuwing W031 op het scherm staat en nog beantwoord moet worden.
                try {

                    await $mdDialog.show(dialog);

                    propdata.Value = propValue;
                    tapwaterLogic.saveValue(prop, entdata);
                } catch (cancel) { // geannuleerd
                    propdata.Value = ''; // Leegmaken, maar niet expliciet opslaan. Anders wordt alsnog een boosterwarmtepomp ingevuld bij het koelsysteem.

                    const propToestel = ntabuilding.properties['TAPW-OPWEK_TOES'];
                    tapwaterLogic.clearSelectionTableValue(propToestel, entdata); // Keuze toestel leegmaken
                } finally {
                    tapwaterLogic.w031Open = false;
                }
            }
        }

        if (shouldSave) {
            tapwaterLogic.saveValue(prop, entdata);
        }
        base.initializeUnitSpacing();
    };

    vm.onValidateCallback = function (prop, entdata, value) {
        if (prop.Id === 'TAPW-OPWEK_TYPE') { // TO05

            //-- Conditie [TOCV] indien WN/WB EN in kolom 2 bij TO05 = hotfill boiler (met/zonder elektrisch element)
            //    EN aan het warm tapwatersysteem (installatietegels) zijn uitsluitend keuken(s) gekoppeld: toon [W144]
            //-- [W144] vervolgactie: indien gekozen in TO05 zet veld terug op de oorspronkelijke keuze; indien 2 kolom met 'hotfill boiler' geselecteerd in de button de hele 2e kolom niet toevoegen
            if (tapwaterLogic.opwekkerNr(entdata) === 2 && value == 'TAPW-OPWEK_TYPE_23') { // hotfill boiler (met/zonder elektrisch element)
                const entdataTapw = ntaEntityData.getFirstParent(entdata, 'TAPW');
                // checkHotfillBoiler geeft een promise terug die resolvet als de waarschuwingspopup gesloten is
                const promise = entdataTapw && ntaSharedLogic.checkHotfillBoiler(entdataTapw, value);
                if (promise) {
                    // dan moeten we zo de oorspronkelijke waarde herstellen
                    const originalValue = prop.getValue(entdata);
                    promise.finally(() => tapwaterLogic.saveValue(prop, entdata, originalValue));
                    return false; // intussen moeten we deze waarde weigeren
                }
            }

            return tapwaterLogic.checkPrioOpwekker(entdata, value);
        }

        return true;
    };

    vm.isSelectionTableDisabled = function (prop, entdata) {
        if (!prop || !entdata) {
            return true;
        }

        // de selectie tabel mag niet gekozen worden als er maar 1 item in de filterlijst zit tenzij propdata.Value == null
        const propdataValue = prop.getValue(entdata);
        if (propdataValue) {
            switch (prop.Id) {
                case 'TAPW-OPWEK_TOES':
                    return entdata.filteredItems.Singleresult;
                case 'TAPW-OPWEK_REGIO':
                    return entdata.filteredItemsRegios.Singleresult;
                case 'TAPW-OPWEK_BRON_POMP':
                    return entdata.filteredItemsBron.Singleresult;
                case 'TAPW-DOUCHE_WTW_TOES':
                    return entdata.filteredItemsDWTW.Singleresult;
                case 'TAPW-VAT_TYPE':
                    return entdata.filteredItemsBVat.Singleresult;
            }
        }

        return false;
    } //-- end: isSelectionTableDisabled ------------------------------------------------------------------------//

    vm.isReadOnly = tapwaterLogic.isReadOnly;
    vm.getCodedValues = tapwaterLogic.getCodedValues;

    vm.getName = function (prop) {
        let name = base.getName(prop);
        if (name) {
            if (prop.Id === 'TAPW-DISTR_AANT_AFLSETS') {
                // conditie [TDAF]
                const gebouwtype = ntaSharedLogic.getGebouwType();
                if (gebouwtype === 'TGEB_APPGEB' || gebouwtype === 'TGEB_UTILIT') {
                    // 'aantal individuele afleversets'
                } else if (gebouwtype === 'TGEB_APP') {
                    name += ' per appartement';
                } else if (gebouwtype === 'TGEB_UTILUNIT') {
                    name += ' per unit';
                } else {
                    name += ' per woning';
                }

            } else if (prop.Id === 'TAPW-AFG-LEI_APP' && ntaSharedLogic.voorProjectwoningen()) {
                name = 'woningen'; // [TAH]

            } else if (prop.EntityId === "TAPW-DISTR-EIG") {
                const index = name.lastIndexOf('[');
                if (index > -1) {
                    name = name.substring(0, index) + '<br>' + name.substring(index);
                }
            }
        }
        return name;
    } //-- end: getName ---------------------------------------------------------------------------

    // Woon unit
    vm.getUnitOmschrijving = function (entdata) {
        if (!entdata) {
            return;
        }

        if (entdata.EntityId === 'UNIT-RZ') {
            let unit = ntaEntityData.getFirstParent(entdata, 'UNIT');
            let rz = ntaEntityData.getFirstParent(entdata, 'RZ');
            return unit.PropertyDatas["UNIT_OMSCHR"].Value + '; ' + rz.PropertyDatas["RZ_OMSCHR"].Value;
        }

        return entdata.PropertyDatas["UNIT_OMSCHR"].Value;
    };

    vm.getCodedValueName = function (item, entdata = null) {
        // Conditie [TOBA]
        if (item && entdata && entdata.EntityId === 'TAPW-OPWEK') {
            const opwekkerTypeValue = entdata.PropertyDatas['TAPW-OPWEK_TYPE'].Value;
            if (opwekkerTypeValue === 'TAPW-OPWEK_TYPE_4') {
                if (item.Id === 'TAPW-OPWEK_GEM_NIET') {
                    return 'installatie met individuele aflevering';
                } else if (item.Id === 'TAPW-OPWEK_GEM_WEL') {
                    return 'installatie met centrale aflevering';
                }
            }
        }

        return base.getCodedValueName(item, entdata);
    } //-- end: getCodedValueName ------------------------------------------------------------------------//

    vm.getPropData = tapwaterLogic.getPropData;
    vm.getProduct = tapwaterLogic.getProduct;

    vm.getPropertyType = function (prop) {
        if (!prop) {
            return;
        }
        return prop.PropertyType;
    } //-- end: getPropertyType ------------------------------------------------------------------------//

    vm.propertyHeaderIsHidden = tapwaterLogic.propertyHeaderIsHidden;
    vm.isHidden = tapwaterLogic.isHidden;
    vm.voorraadvatenIsHidden = () => tapwaterLogic.minCountVoorraadvaten() === 0;
    vm.leidinggegevensIsHidden = tapwaterLogic.leidinggegevensIsHidden;
    vm.aangeslotenDouchesOpWTWIsHidden = tapwaterLogic.aangeslotenDouchesOpWTWIsHidden;
    vm.eigenschappenLeidingenRowIsHidden = tapwaterLogic.eigenschappenLeidingenRowIsHidden;
    vm.eigenschappenDistributieLeidingenTabelIsHidden = tapwaterLogic.eigenschappenDistributieLeidingenTabelIsHidden;

    vm.distributiepompTabelIsHidden = tapwaterLogic.distributiepompTabelIsHidden;
    vm.allowMultiplePumps = tapwaterLogic.allowMultiplePumps;
    vm.pompIsHidden = tapwaterLogic.pompIsHidden;

    vm.opwekkerButtonIsHidden = function () {
        const maxOpwekkers = tapwaterLogic.getMaxOpwekkers();

        const visible = vm.tapwOpwekkersData().length < maxOpwekkers;
        if (!visible) {
            deleteOpwekkersOverLimit(maxOpwekkers);
        }

        return !visible;
    }; //-- end: opwekkerButtonIsHidden -----------------------------------------------------------

    // Conditie [TOBI]
    function deleteOpwekkersOverLimit(limit) {
        const opwekkersToDelete = vm.tapwOpwekkersData().slice(limit);
        if (opwekkersToDelete.length > 0) {
            for (const opwekker of opwekkersToDelete) {
                ntaEntityData.delete(opwekker.EntityDataId);
            }
            setTapwOpwekkers();
            tapwaterLogic.setEnergieFractie();
        }
    } //-- end: deleteOpwekkersOverLimit ----------------------------------------------------------

    vm.copyOpwekkerIsHidden = function () {
        return vm.opwekkerButtonIsHidden();
    };

    // geeft een lijst van objecten die zowel elke unit- als de bijbehorende afgifteleiding-entiteit bevatten. Units die geen leiding hebben, worden overgeslagen
    vm.getUnitLeidingen = function () {
        const unitLeidingen = vm.units()
            .map(unit => ({
                unit,
                leiding: tapwaterLogic.getUnitLeiding(unit),
            }))
            .filter(vm => vm.leiding);
        return _listCache.useCacheIfUnchanged('unitLeidingen', unitLeidingen, (a, b) => a.unit === b.unit && a.leiding === b.leiding);
    };

    vm.leidingenIsHidden = tapwaterLogic.leidingenIsHidden;

    vm.doucheButtonIsHidden = function () {
        if (vm.tapwDoucheData().length > 0) {
            let propAangelostenDouches = vm.tapwDouchewarmteterugwinningProperties['TAPW-DOUCHE_AANG'];
            let aangeslotenDouches = vm.tapwDoucheData()[0].PropertyDatas['TAPW-DOUCHE_AANG'].Value;
            if (aangeslotenDouches && aangeslotenDouches === 'TAPW-DOUCHE_AANG_ONB' && !vm.isHidden(propAangelostenDouches, vm.tapwDoucheData()[0])) {
                return true;
            }
        }

        let typeOpwekker = vm.tapwOpwekkersData()[0].PropertyDatas['TAPW-OPWEK_TYPE'].Value;
        if (typeOpwekker && typeOpwekker === 'TAPW-OPWEK_TYPE_9') {
            return true;
        }

        return false;
    } //-- end: doucheButtonIsHidden ------------------------------------------------------------------------//

    vm.copyDoucheWTWIsHidden = function () {
        return vm.doucheButtonIsHidden();
    };

    vm.doucheWTWIsHidden = function () {
        let typeOpwekker = vm.tapwOpwekkersData()[0].PropertyDatas['TAPW-OPWEK_TYPE'].Value;
        if (typeOpwekker && typeOpwekker === 'TAPW-OPWEK_TYPE_9') {
            return true;
        }

        return false;
    };

    var setEnergieFractie = tapwaterLogic.setEnergieFractie;

    ///******************************************* CRUD akties ******************************************************************///
    vm.openMenu = function ($mdMenu, ev) {
        $mdMenu.open(ev);

        ev.stopImmediatePropagation();
        ev.preventDefault();
    } //-- end: openMenu ------------------------------------------------------------------------//

    vm.addOpwekker = tapwaterLogic.addOpwekker;

    vm.copyOpwekker = function (Id) {
        if (!Id) {
            return;
        }

        progressCircle.setShowProgressValue(true, 'kopiëren opwekker');
        const newEntdatas = ntaEntityData.copy(Id);

        const newOpwekker = newEntdatas.find(x => x.EntityId === 'TAPW-OPWEK');

        //-- ook de javascript objecten die in de client zijn toegevoegd zoals selectedItem enz. van het product mee kopieren
        const entdata = ntaEntityData.get(Id);
        for (const newEntdata of newEntdatas) {
            if (entdata.EntityId === newEntdata.EntityId) {
                for (const member in entdata) {
                    if (!newEntdata.hasOwnProperty(member) && member !== "$$hashKey") {
                        newEntdata[member] = entdata[member];
                    }
                }
            }
        }
        progressCircle.setShowProgressValue(false);
        setTapwOpwekkers();

        tapwaterLogic.checkPrioOpwekker(newOpwekker);

        setEnergieFractie();
        startValidationEnergiefractie();
    } //-- end: copyOpwekker ------------------------------------------------------------------------//

    vm.deleteOpwekker = async function (id) {
        if (!id) {
            return;
        }

        let minCount = 1;

        // Conditie [TODA] als TO05 in de eerste kolom is 'opwekker van verwarmingssysteem' toon automatisch een 2e kolom (geen button)
        const [opwekker1] = tapwaterLogic.tapwOpwekkersData;
        const opwekker1type = opwekker1.PropertyDatas['TAPW-OPWEK_TYPE'].Value;
        if (opwekker1type === 'TAPW-OPWEK_TYPE_24') { // opwekker van verwarmingssysteem
            minCount = 2;
        }

        const opwekkerCount = getTapwOpwekkersIds().length;
        if (opwekkerCount <= minCount) {
            ntaMeldingen.warning("[W018]"); // Verwijderen niet toegestaan — Het is niet mogelijk om deze opwekker te verwijderen. Er dient minimaal één opwekker aanwezig te zijn.
            return;
        }

        var confirm = $mdDialog.confirm()
            .title('Verwijderen opwekker')
            .textContent('U staat op het punt om deze opwekker te verwijderen. Weet u het zeker?')
            .ariaLabel('Verwijderen')
            .ok('Ja')
            .cancel('Nee');

        try {
            await $mdDialog.show(confirm);

            ntaEntityData.delete(id);

            setTapwOpwekkers();
            setEnergieFractie();
            base.initializeUnitSpacing();

            startValidationEnergiefractie();
        } catch (err) {
            // nee
        }
    } //-- end: deleteOpwekker ------------------------------------------------------------------------//

    vm.addVoorraadvat = tapwaterLogic.addVoorraadvat;

    vm.copyVoorraadvat = function (Id) {
        if (!Id) {
            return;
        }

        progressCircle.setShowProgressValue(true, 'kopiëren voorraadvat');
        const newEntdatas = ntaEntityData.copy(Id)

        //-- ook de javascript objecten die in de client zijn toegevoegd zoals selectedItem enz. van het product mee kopieren
        const entdata = ntaEntityData.get(Id);
        for (const newEntdata of newEntdatas) {
            if (entdata.EntityId === newEntdata.EntityId) {
                for (const member in entdata) {
                    if (!newEntdata.hasOwnProperty(member) && member !== "$$hashKey") {
                        newEntdata[member] = entdata[member];
                    }
                }
            }
        }

        progressCircle.setShowProgressValue(false);
        setTapwVaten();
    } //-- end: copyVoorraadvat ------------------------------------------------------------------------//

    vm.deleteVoorraadvat = function (id) {
        if (!id) {
            return;
        }

        // TV02 description:
        // Er moet minimaal 1 kolom zijn als bij conditie [TVA] sprake is van situatie 1 t/m 4 OF situatie 5.
        // Als er sprake is van situatie 1 t/m 4 EN situatie 5 geldt een minimum van 2 kolommen.
        // If the user tries to delete de verplichte 1e kolom show [W023].
        // If the user tries to delete de verplichte 2e kolom show [W143].

        const minCount = tapwaterLogic.minCountVoorraadvaten();
        if (tapwaterLogic.tapwVatenIds.length <= minCount) {
            ntaMeldingen.warning(minCount == 2 ? '[W143]' : '[W023]', 'Verwijderen niet toegestaan');
            return;
        }

        var confirm = $mdDialog.confirm()
            .title('Verwijderen voorraadvat')
            .textContent('U staat op het punt om dit voorraadvat te verwijderen. Weet u het zeker?')
            .ariaLabel('Verwijderen')
            .ok('Ja')
            .cancel('Nee');

        $mdDialog.show(confirm).then(function () {
            ntaEntityData.delete(id);
            setTapwVaten();

            base.initializeUnitSpacing();
        });
    } //-- end: deleteVoorraadvat ------------------------------------------------------------------------//

    vm.addDouche = function (typeId) {
        ntaEntityData.create("TAPW-DOUCHE", -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": vm.tapwDataId, "ParentEntityId": "TAPW" }], []);
        setTapwDouche();
        setDefaultDouche();
    } //-- end: addDouche ------------------------------------------------------------------------//

    vm.copyDouche = function (Id) {
        if (!Id) {
            return;
        }

        progressCircle.setShowProgressValue(true, 'kopiëren douche wtw');
        const newEntdatas = ntaEntityData.copy(Id);

        //-- ook de javascript objecten die in de client zijn toegevoegd zoals selectedItem enz. van het product mee kopieren
        const entdata = ntaEntityData.get(Id);
        for (const newEntdata of newEntdatas) {
            if (entdata.EntityId === newEntdata.EntityId) {
                for (const member in entdata) {
                    if (!newEntdata.hasOwnProperty(member) && member !== "$$hashKey") {
                        newEntdata[member] = entdata[member];
                    }
                }
            }
        }

        progressCircle.setShowProgressValue(false);
        setTapwDouche();
    } //-- end: copyDouche ------------------------------------------------------------------------//

    vm.deleteDouche = function (Id) {
        if (!Id) {
            return;
        }

        var confirm = $mdDialog.confirm()
            .title('Verwijderen douche WTW')
            .textContent('U staat op het punt om deze douche WTW te verwijderen. Weet u het zeker?')
            .ariaLabel('Verwijderen')
            .targetEvent(event)
            .ok('Ja')
            .cancel('Nee');

        $mdDialog.show(confirm).then(function () {
            ntaEntityData.delete(Id);

            setTapwDouche();
            base.initializeUnitSpacing();
        });
    } //-- end: deleteDouche ------------------------------------------------------------------------//

    tapwaterLogic.setGeopend();

    vm.addPompProgress = function () {
        return tapwaterLogic.addPompProgress;
    };
    vm.addPomp = tapwaterLogic.addPomp;
    vm.copyPomp = tapwaterLogic.copyPomp;
    vm.deletePomp = tapwaterLogic.deletePomp;

    // Dynamic styling
    vm.center = function (prop) {
        if (prop.Id === 'VENTILATOREIG_DEB') {
            return '';
        }

        return 'columnCenter';
    } //-- end: center ------------------------------------------------------------------------//


    angular.element(document).ready(function () {
        $timeout(function () {
            loadingDone = true;
        }, 1000);
    });
}]);
