﻿angular.module('projectModule')
    .factory("VerlichtingFactory",
        ['ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaSharedLogic',
function (ntabuilding,   ntaValidation,   ntaEntityData,   ntaSharedLogic) {
    'use strict'

    return function VerlichtingLogic(installId, ntaDependencyValidation) {
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        const verlichtingLogic = this;

        const _isEditingMeasure = ntaSharedLogic.isEditingMeasure("MEASURE-VERL");

        // in een maatregel krijgen we geen installatie-ID mee
        if (_isEditingMeasure && !ntaEntityData.get(installId)) {
            // zoek eerst een evt. bestaande installatie verlichting
            const verl = ntaEntityData.getFirstWithEntityId('VERL');
            const installation = verl && ntaEntityData.getFirstParent(verl, 'INSTALLATIE');
            installId = installation && installation.EntityDataId;
            if (!ntaEntityData.get(installId)) {
                // als er nog geen was, dan maken we er eentje aan
                installId = ntaEntityData.create('INSTALLATIE', -1, [], [], [{ "PropertyId": 'INSTALL_TYPE', "Value": 'INST_VERL' }]);
                if (verl) {
                    ntaEntityData.createRelation(installId, verl.EntityDataId, true, true);
                } else {
                    ntaEntityData.create('VERL', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": installId, "ParentEntityId": "INSTALLATIE" }], []);
                }
            }
        }

        verlichtingLogic.installId = installId;
        const _installId = installId;

        /* Properties **********************************************************************************************/
        const _propertiesVL = ntabuilding.properties['VERL'];
        const _propertiesVZ = ntabuilding.properties['VERLZONE'].filter(p => !['VERLZ_WL', 'VERLZ_WP'].includes(p.Id));

        /* data ****************************************************************************************************/
        const _verlData = ntaEntityData.getFirstChild(_installId, 'VERL');
        const _units = _installId ? ntaEntityData.getListWithEntityId('UNIT') : [];
        const _gruimtes = _installId ? ntaEntityData.getListWithEntityId('GRUIMTE') : [];

        verlichtingLogic.propertiesVL = _propertiesVL;
        verlichtingLogic.propertiesVZ = _propertiesVZ;
        verlichtingLogic.verlData = _verlData;
        verlichtingLogic.units = _units;
        verlichtingLogic.gruimtes = _gruimtes;

        verlichtingLogic.saveValue = saveValue;
        verlichtingLogic.isHidden = isHidden;
        verlichtingLogic.isReadOnly = isReadOnly;
        verlichtingLogic.getCodedValues = getCodedValues;

        verlichtingLogic.propertyHeaderIsHidden = propertyHeaderIsHidden;

        verlichtingLogic.validate = validate;
        verlichtingLogic.validateDependencies = validateDependencies;

        verlichtingLogic.startFormValidation = startFormValidation;
        verlichtingLogic.endFormValidation = endFormValidation;

        verlichtingLogic.buildverlzonesTabel = buildverlzonesTabel;
        verlichtingLogic.setDefaultVerlzone = setDefaultVerlzone;

        verlichtingLogic.getListItem = getListItem;
        verlichtingLogic.dependencyValidator = ntaDependencyValidation;

        verlichtingLogic.getUnitRekenzones = getUnitRekenzones;

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         INITALIZIATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        const _unitRekenzonesPerUnit = {};
        buildverlzonesTabel();
        setDefaultVerlzones();


        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        function getListItem(prop, entdata, list = null) {
            let listItem = null;

            if (prop && prop.Domain && prop.Domain.DomainType > 1 && entdata) {
                var tmpList = null;
                var tmpId;
                var propData = entdata.PropertyDatas[prop.Id];
                switch (prop.Id) {
                    default:
                        tmpId = propData.Value;
                        tmpList = list; // de gefilterde getCodedValue list. Op deze list moet ik checken
                        if (!tmpList) {
                            //-- anders ongefilterde codedValue list gebruiken
                            tmpList = prop.Domain.Codes;
                        }
                        break;
                }

                if (propData.Value && tmpList && tmpList.length > 0) {
                    listItem = tmpList.find(function (x) { return x.Id === tmpId; });
                }
            }
            return listItem;
        } //-- end: getListItem -------------------------------------------------------------------------------------------/

        //-- VerlichtingLogic heeft geen selectietabel waarden, dus alle functie voor selectietabellen zijn hier niet opgenomen.

        function validate(prop, propdata, entdata) {
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            let hidden = isHidden(prop, entdata);
            let readonly = isReadOnly(prop, entdata);
            let valid =  ntaValidation.IsValid(verlichtingLogic.form_verl, prop, propdata, hidden, readonly);
            switch (prop.Id) {
                case 'VERLZ_A': {
                    // Conditie [F] en [P]
                    const parent = ntaEntityData.getFirstParent(entdata); // UNIT-RZ of GRUIMTE
                    const f7 = formuleF7(parent);
                    const verlValid = f7 === formuleF8(parent);
                    if (parent.EntityId === "UNIT-RZ") { //LG07 = Z13 -> UNIT-RZ

                        const unitRz = parent;
                        const rz = ntaEntityData.getFirstParent(unitRz, 'RZ');
                        const unit = ntaEntityData.getFirstParent(unitRz, 'UNIT');

                        //-- controleren op rekenzone. Kan zijn dat bij aanmaken UNIT-RZ en nog geen koppeling gemaakt is met welke rekenzone
                        const rekenzonenaam = rz && rz.PropertyDatas['RZ_OMSCHR'].Value || '?';
                        ntaSharedLogic.updateErrorReplaceObject(propdata, "rekenzone", rekenzonenaam);
                        ntaSharedLogic.updateErrorReplaceObject(propdata, "unit", unit.PropertyDatas['UNIT_OMSCHR'].Value);
                        ntaSharedLogic.updateErrorReplaceObject(propdata, "formuleF7", f7.toFixed(2).replace('.', ','));

                        ntaSharedLogic.setMelding('[E002]', propdata, verlichtingLogic.form_verl, true); //eerst op true, want de waarde uit formuleF7 kan zijn gewijzigd.
                        ntaSharedLogic.setMelding('[E002]', propdata, verlichtingLogic.form_verl, verlValid);

                    } else if (parent.EntityId === 'GRUIMTE') {

                        const propGruimteOmschr = ntabuilding.properties['GRUIMTE_OMSCHR'];
                        const gruimtenaam = propGruimteOmschr.getValue(parent);
                        ntaSharedLogic.updateErrorReplaceObject(propdata, "gruimte", gruimtenaam);
                        ntaSharedLogic.updateErrorReplaceObject(propdata, "formuleF7", f7.toFixed(2).replace('.', ','));

                        ntaSharedLogic.setMelding('[E060]', propdata, verlichtingLogic.form_verl, true); //eerst op true, want de waarde uit formuleF7 kan zijn gewijzigd.
                        ntaSharedLogic.setMelding('[E060]', propdata, verlichtingLogic.form_verl, verlValid);
                    }
                    valid &= verlValid;
                    break;
                }
            }

            return valid;
        } //-- end: validate ------------------------------------------------------------------------//

        function validateOppervlaktes() {
            _units.forEach(function (unit, index) {
                getUnitRekenzones(unit).forEach(function (unitRekenzone, index2) {
                    unitRekenzone.verlzones.forEach(function (verlzone, index3) {
                        let prop = ntabuilding.properties['VERLZ_A'];
                        let propdata = prop.getData(verlzone);
                        validate(prop, propdata, verlzone);
                    });
                });
            });
            _gruimtes.forEach(function (gruimte, index) {
                gruimte.verlzones.forEach(function (verlzone, index2) {
                    let prop = ntabuilding.properties['VERLZ_A'];
                    let propdata = prop.getData(verlzone);
                    validate(prop, propdata, verlzone);
                });
            });
        } //-- end: validateOppervlaktes ------------------------------------------------------------------------//

        function saveValue(prop, entdata, newValue) {
            ntaSharedLogic.saveValue(prop, entdata, newValue, verlichtingLogic);
        } //-- end: saveValue ------------------------------------------------------------------------//

        function propertyHeaderIsHidden(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            let showIt = true;
            switch (prop.Id) {
                case 'VERLZ_PL':
                case 'VERLZ_VOORSCHK':
                    {   //-- LG10 Conditie [K]
                        showIt = isNominaalLampVermogen();
                        break;
                    }
                case 'VERLZ_PN_NON':
                    {
                        //-- deze is nooit zichtbaar en alleen relevant voor rekenkern als er sprake is van nominaal lampvermogen
                        showIt = false;
                        break;
                    }
                case 'VERLZ_PN':
                    {   //-- LG16 Conditie [M]
                        showIt = isEigenWaardeVerlichtingsVermogen();
                        break;
                    }
                case 'VERLZ_PPC':
                case 'VERLZ_PEM':
                    {   //-- LG17 en LG18 Conditie [N]
                        showIt = isEigenWaardeParasitairVermogen();
                        break;
                    }
                case 'VERLZ_FD':
                    {   //-- LG15 Conditie [H]
                        showIt = daglichtRegelingAanwezig();
                        break;
                    }
                case 'VERLZ_FD_NON': { // LG22
                    showIt = isDaglichtRegelingForfaitair(); // Conditie [T]
                    break;
                }
                case 'VERLZ_DAGLREG': { // LG23
                    showIt = isDaglichtRegelingForfaitair(); // Conditie [T]
                    break;
                }
                case 'VERLZ_F_AFZ':
                    {   //-- LG19 Conditie [O]
                        showIt = !isForfaitairVerlichtingsVermogen();
                        break;
                    }
                case 'VERLZ_KAG30':
                    {
                        showIt = _units.some(unit => getUnitRekenzones(unit).some(unitrz => unitrz.verlzones.some(verlzone => isFunctionKantoorInVerlzone(verlzone))));
                        break;
                    }
                case 'VERLZ_NWW':
                    {   //-- LG13 Conditie [R]
                        showIt = ntabuilding.ntaVersionId < 200;
                        break;
                    }
                case 'VERLZ_TYPE':
                    {   // LG24 Conditie [U] toon als versie gt3.2 EN LG02 = forfaitair verlichtingsvermogen
                        showIt = ntabuilding.ntaVersionId >= 300 && isForfaitairVerlichtingsVermogen();
                        break;
                        }

                default:
            }
            return !showIt;
        } //-- end: propertyHeaderIsHidden ------------------------------------------------------------------------//

        function isHidden(prop, entdata) {
            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return false;
            }

            const propdata = entdata.PropertyDatas[prop.Id];

            let showIt = true;
            let relevant = null;

            switch (prop.Id) {
                case "VERL_DAGLREG":
                case 'VERLZ_F_AFZ':
                    {    //-- LG04 en LG19 Conditie [B] verbergen als conditie B waar is
                        showIt = !isForfaitairVerlichtingsVermogen();
                        break;
                    }
                case 'VERLZ_TYPE': { // LG24
                    // [U] toon als versie gt3.2 EN LG02 = forfaitair verlichtingsvermogen
                    showIt = ntabuilding.ntaVersionId >= 300 && isForfaitairVerlichtingsVermogen();
                    break;
                }
                case 'VERLZ_PL':
                case 'VERLZ_VOORSCHK':
                    {   //-- LG10 en LG20 Conditie [K]
                        showIt = isNominaalLampVermogen();
                        break;
                    }
                case 'VERLZ_PN_NON':
                    {
                        //-- deze is nooit zichtbaar en alleen relevant voor rekenkern als er sprake is van nominaal lampvermogen of forfaitair verlichtingsvermogen
                        showIt = false;
                        relevant = isNominaalLampVermogen() || isForfaitairVerlichtingsVermogen();
                        break;
                    }
                case 'VERLZ_PN':
                    {   //-- LG16 Conditie [M]
                        showIt = isEigenWaardeVerlichtingsVermogen();
                        break;
                    }
                case 'VERLZ_PPC':
                case 'VERLZ_PEM':
                    {   //-- LG17 en LG18 Conditie [N]
                        showIt = isEigenWaardeParasitairVermogen();
                        break;
                    }
                case 'VERLZ_FD': { //-- LG15 Conditie [H]
                    showIt = daglichtRegelingAanwezig();
                    break;
                }
                case 'VERLZ_FD_NON': { // LG22
                    showIt = isDaglichtRegelingForfaitair(); // Conditie [T]
                    break;
                }
                case 'VERLZ_DAGLREG': { // LG23
                    showIt = isDaglichtRegelingForfaitair(); // Conditie [T]
                    break;
                }
                case 'VERLZ_WL':
                case 'VERLZ_WP':
                    {
                        showIt = false; //-- deze zijn alleen nodig voor de generieke XML, en worden niet gebruikt in de UI
                        break;
                    }
                case 'VERL_OPEN':
                    {
                        showIt = false;
                        break;
                    }
                case 'VERLZ_KAG30':
                    {
                        showIt = _units.some(unit => getUnitRekenzones(unit).some(unitrz => unitrz.verlzones.some(verlzone => isFunctionKantoorInVerlzone(verlzone))));
                        break;
                    }
                case 'VERLZ_NWW':
                    {   //-- LG13 Conditie [R]
                        showIt = ntabuilding.ntaVersionId < 200;
                        break;
                    }
                case 'MEASURE-COSTS_PER_SYS':
                case 'MEASURE-COSTS_INV':
                case 'MEASURE-COSTS_PER_M2': {
                    showIt = false;
                    break;
                }

            }

            // standaard is relevant gelijk aan visible
            if (relevant === null) relevant = showIt;

            const propdatas = [propdata, ...ntaSharedLogic.getUnitSpecificResultPropdatas(prop, entdata)];
            ntaEntityData.setPropdataStatus(propdatas, relevant, showIt);

            return !showIt;
        } //-- end: isHidden ------------------------------------------------------------------------//


        /// Conditites /////////////////////////////////////////////////////////////////////////////////////////////
        function isNominaalLampVermogen() {
            //-- Conditie [K] begint met Toon...
            const invoerVerlVerm = _verlData.PropertyDatas['VERL_VERM_INV'].Value;
            let showIt = invoerVerlVerm === 'VERL_VERM_EW_NOM';

            return showIt; //-- bij showIt True is de conditie geldig.
        } //-- end: isNominaalLampVermogen ------------------------------------------------------------------------//

        function isEigenWaardeVerlichtingsVermogen() {
            //-- Conditie [M] begint met Show....
            const invoerVerlVerm = _verlData.PropertyDatas['VERL_VERM_INV'].Value;
            let showIt = invoerVerlVerm === 'VERL_VERM_EW';

            return showIt; //-- bij showIt True is de conditie geldig.
        } //-- end: isEigenWaardeVerlichtingsVermogen ------------------------------------------------------------------------//

        function isForfaitairVerlichtingsVermogen() {
            //-- Conditie [B] en conditie [O] zijn het zelfde
            const invoerVerlVerm = _verlData.PropertyDatas['VERL_VERM_INV'].Value;
            const showIt = invoerVerlVerm === 'VERL_VERM_FORF';

            return showIt;
        } //-- end: isForfaitairVerlichtingsVermogen ------------------------------------------------------------------------//

        function isFunctionKantoorInVerlzone(verlzone) {
            //-- LG21 Conditie [Q] (toon als tenminste 1 van de regels in tabel Z08 behorende bij deze rekenzone Z13=LG07 in kolom Z14 een kantoorfunctie is)
            //-- Indien in de betreffende rekenzone geen kantoorfunctie aanwezig is maar in andere rekenzones wel, vul dit veld met n.v.t. en grijs dit uit en reken met 'geen kantoor > 30 m²'
            const unitrz = ntaEntityData.getFirstParent(verlzone);//-- dit is of UNIT-RZ of GRUIMTE
            if (unitrz && unitrz.EntityId === "UNIT-RZ") {
                const rz = ntaEntityData.getFirstParent(unitrz, 'RZ');
                const unitrzs = ntaEntityData.getChildren(rz, 'UNIT-RZ');
                return unitrzs.some(unitrz => ntaEntityData.getChildren(unitrz, 'UNIT-RZ-GF').some(unitrzgf => unitrzgf.PropertyDatas['UNIT-RZ-GFID'].Value === 'GF_KANT'));
            }
            return false;
        }

        function isEigenWaardeParasitairVermogen() {
            //-- Conditie [N] begint met Show...
            let propInvoerParaVerm = ntabuilding.properties['VERL_PARVERM_INV'];
            let propValueInvoerParaVerm = propInvoerParaVerm.getValue(_verlData);
            let showIt = propValueInvoerParaVerm === 'VERL_VERMP_EW';

            return showIt; //-- bij showIt True is de conditie geldig.
        } //-- end: isEigenWaardeParasitairVermogen ------------------------------------------------------------------------//

        function daglichtRegelingAanwezig() {
            //-- Conditie [H] begint met Show...
            const propdataDaglichtRegeling = _verlData.PropertyDatas['VERL_DAGLREG'];
            const showIt = propdataDaglichtRegeling.Relevant && propdataDaglichtRegeling.Value === 'VERL_DAGREG_AANW';

            return showIt; //-- bij showIt True is de conditie geldig.
        } //-- end: daglichtRegelingAanwezig ------------------------------------------------------------------------//

        function isDaglichtRegelingForfaitair() {
            //-- Conditie [T] begint met show...
            const propdataDaglichtRegeling = _verlData.PropertyDatas['VERL_DAGLREG'];
            return propdataDaglichtRegeling.Relevant && propdataDaglichtRegeling.Value === 'VERL_DAGREG_FORF';
        } //-- end: isDaglichtRegelingForfaitair ------------------------------------------------//

        function formuleF7(entdata) {
            let somAverl = 0;
            if (entdata.EntityId === 'UNIT-RZ') {
                somAverl = ntaSharedLogic.AgSumOfUnitRZs([entdata.EntityDataId], false, false); //<<-- VO: deze laatste op false zetten als rekenkern oppverlak * nGetal doet.
            } else if (entdata.EntityId === 'GRUIMTE') {
                const agGruimte = entdata.PropertyDatas["GRUIMTE_AG"].Value;
                somAverl = ntaSharedLogic.parseFloat(agGruimte, 0);
            }
            return Math.round(somAverl * 100) / 100;
        } //-- end: formuleF7 ------------------------------------------------------------------------//

        function formuleF8(entdata) {
            const verlzones = ntaEntityData.getChildren(entdata, 'VERLZONE');
            const somAverl = verlzones.reduce((som, verlzone) => som + ntaSharedLogic.parseFloat(verlzone.PropertyDatas['VERLZ_A'].Value, 0), 0);
            return Math.round(somAverl * 100) / 100;
        } //-- end: formuleF8 ------------------------------------------------------------------------//

        function buildverlzonesTabel() {
            //-- Haal unit-rekenzones en verlzones daarbinnen op.
            _units.forEach(function (unit) {
                const unitRekenzones = ntaEntityData.getChildren(unit, 'UNIT-RZ');

                unitRekenzones.forEach(function (unitRekenzone) {
                    const verlzones = ntaEntityData.getChildren(unitRekenzone, 'VERLZONE');

                    // Als er geen verlzones zijn, moet er één worden aangemaakt
                    if (verlzones.length <= 0) {
                        const newId = ntaEntityData.create('VERLZONE', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": unitRekenzone.EntityDataId, "ParentEntityId": "UNIT-RZ" }], [], []);
                        verlzones.push(ntaEntityData.get(newId));
                    }

                    unitRekenzone.verlzones = verlzones;
                });

                setUnitRekenzones(unit, unitRekenzones);
            });

            _gruimtes.forEach(function (gruimte) {
                const verlzones = ntaEntityData.getChildren(gruimte, 'VERLZONE');

                // Als er geen verlzones zijn, moet er één worden aangemaakt
                if (verlzones.length < 1) {
                    const newId = ntaEntityData.create('VERLZONE', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": gruimte.EntityDataId, "ParentEntityId": "GRUIMTE" }], [], []);
                    verlzones.push(ntaEntityData.get(newId));
                }

                gruimte.verlzones = verlzones;
            });

            validateOppervlaktes();
        } //-- end: buildverlzonesTabel ------------------------------------------------------------------------//


        function getUnitRekenzones(unit) {
            return _unitRekenzonesPerUnit[unit.EntityDataId];
        }
        function setUnitRekenzones(unit, unitRekenzones) {
            _unitRekenzonesPerUnit[unit.EntityDataId] = unitRekenzones;
        }

        function setDefaultVerlzones() {
            _units.forEach(function (unit) {
                getUnitRekenzones(unit).forEach(function (unitRekenzone) {
                    unitRekenzone.verlzones.forEach(function (verlzone) {
                        setDefaultVerlzone(verlzone);
                    });
                });
            });
            _gruimtes.forEach(function (gruimte) {
                gruimte.verlzones.forEach(function (verlzone) {
                    setDefaultVerlzone(verlzone);
                });
            });
        } //-- end: setDefaultVerlzones ------------------------------------------------------------------------//

        function setDefaultVerlzone(verlzone) {
            const propAverl = ntabuilding.properties['VERLZ_A'];
            const propdataAverl = propAverl.getData(verlzone);

            if (!propdataAverl.Touched) {
                const parent = ntaEntityData.getFirstParent(verlzone);//-- dit is of UNIT-RZ of GRUIMTE
                const opp = formuleF8(parent);
                const maxopp = formuleF7(parent);
                const newValue = maxopp - opp;
                saveValue(propAverl, verlzone, newValue);
            }
        } //-- end: setDefaultVerlzone ------------------------------------------------------------------------//


        function isReadOnly(prop, entdata) {
            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return false;
            }
            let bReadOnly = false;

            switch (prop.Id) {
                case 'VERLZ_FD_NON': { // LG22
                    bReadOnly = true;
                    break;
                }
                case 'VERLZ_KAG30': {
                    bReadOnly = !isFunctionKantoorInVerlzone(entdata);
                    const nvt = 'VERLZ_KAG_KANT_NVT';
                    if (bReadOnly) {
                        saveValue(prop, entdata, nvt);
                    } else if (prop.getValue(entdata) === nvt) {
                        saveValue(prop, entdata, prop.DefaultValue);
                    }
                    break;
                }
            }
            return bReadOnly;
        }

        function getCodedValues(prop, entdata) {
            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return [];
            }

            let typesList = ntaValidation.codedValues(prop);

            switch (prop.Id) {
                case 'VERL_DAGLREG': {
                    const cvEigenWaarde = typesList.find(cv => cv.Id === 'VERL_DAGREG_AANW');
                    if (ntabuilding.ntaVersionId < 100) {
                        // [S] verberg voor versie 3.0
                        typesList = typesList.filter(cv => cv.Id !== 'VERL_DAGREG_FORF');

                        // Oude naam gebruiken; nieuwe naam onthouden
                        if (!cvEigenWaarde.originalValue)
                            cvEigenWaarde.originalValue = cvEigenWaarde.Value;
                        cvEigenWaarde.Value = 'daglichtregeling aanwezig';

                    } else if (cvEigenWaarde.originalValue) {
                        cvEigenWaarde.Value = cvEigenWaarde.originalValue;
                    }
                    break;
                }
                case 'VERLZ_NWW': {
                    let filter = null;
                    const propVoorschakeling = ntabuilding.properties['VERLZ_VOORSCHK'];
                    const propValueVoorschakeling = propVoorschakeling.getValue(entdata);
                    if (propValueVoorschakeling === 'VERLZ_VRSCH_LED') {
                        filter = 'Led';
                    }
                    if (propValueVoorschakeling === 'VERLZ_VRSCH_FLT5' || propValueVoorschakeling === 'VERLZ_VRSCH_FLT8H' || propValueVoorschakeling === 'VERLZ_VRSCH_FLT8C' ||
                        propValueVoorschakeling === 'VERLZ_VRSCH_FLT12' || propValueVoorschakeling === 'VERLZ_VRSCH_FLCOMPACT') {
                        filter = 'Fluor';
                    }
                    if (filter && propVoorschakeling.getData(entdata).Relevant) {
                        typesList = typesList.filter(function (x) { return x.FilterValue1 === filter || x.FilterValue1 === null; });
                    }
                    break;
                }
                case 'VERLZ_KAG30': {
                    if (!isFunctionKantoorInVerlzone(entdata)) {
                        typesList = typesList.filter(function (x) { return x.Id === 'VERLZ_KAG_KANT_NVT'; });
                        break;
                    }

                    typesList = typesList.filter(function (x) { return x.Id !== 'VERLZ_KAG_KANT_NVT'; });
                    break;
                }
                default: {
                    typesList = ntaValidation.codedValues(prop);
                    break;
                }
            }

            ntaSharedLogic.checkPropdataInList(prop, entdata, typesList, verlichtingLogic);
            return typesList;
        } //-- end: getCodedValues ------------------------------------------------------------------------//

        function validateDependencies(prop, entdata) {
            //-- Ontvangt afhankelijk veld dat gevalideerd moet worden
            if (!prop || !entdata) {
                return;
            }

            switch (prop.Id) {
                case 'VERLZ_A': {
                    validateOppervlaktes();
                    break;
                }
            }
        } //-- end: validateDependencies ------------------------------------------------------------------------//

        function startFormValidation() {
            ntaSharedLogic.startFormValidation(getAllEntDatas(), verlichtingLogic);
        } //-- end: startFormValidation ------------------------------------------------------------------------//

        function endFormValidation() {
            ntaSharedLogic.endFormValidation(getAllEntDatas(), verlichtingLogic);
        } //-- end: endFormValidation ------------------------------------------------------------------------//

        function getAllEntDatas() { // Geeft alle entdatas in één array terug
            return []
                .concat(_verlData)
                .concat(_units.flatMap(unit => getUnitRekenzones(unit)).flatMap(unitRz => unitRz.verlzones))
                .concat(_gruimtes.flatMap(gruimte => gruimte.verlzones))
                .concat(ntaSharedLogic.getCostEntdata(_installId))
                .filter(entdata => entdata) // filter alle nulls en undefineds eruit
                ;
        } //-- end: getAllEntDatas ------------------------------------------------------------------------//

        function checkMeasureCosts() {
            // *als* we in een maatregel zitten, dan controleren dat er een MEASURE-COST entiteit bestaat voor elke installatie.
            if (_isEditingMeasure) {
                const installatie = ntaEntityData.get(_installId);
                const costs = ntaEntityData.getFirstChild(installatie, 'MEASURE-COSTS');
                if (!costs) {
                    const parentRels = [
                        { OnCopy: 1, OnDelete: 1, Parent: installatie.EntityDataId, ParentEntityId: installatie.EntityId }
                    ];
                    ntaEntityData.create('MEASURE-COSTS', -1, parentRels, [], []);
                }
            }
        }

        checkMeasureCosts();
    }

}]);
