﻿angular.module('projectModule')
    .controller('FitgrafiekController',
        ['$scope', '$controller', '$log', '$mdDialog', 'ntaRounding',
function ($scope,   $controller,   $log,   $mdDialog,   ntaRounding ) {
    'use strict';
    const ctrl = this;

    //== Instance data ============================================================================
    const _carriers = ['All', 'GAS', 'E_LEV', 'OLIE', 'BIOM', 'W', 'K'];
    let _verbruikData = [];
    let _climateData = [];
    const _chartdata = new Map();
    let _projectName = '';
    let _buildingName = '';
    let _buildingId = 0;

    // verbrId | typeCarrier | VerbrMJ (maand) | verbr | verbrFitted (resultaat FG03) | verbrNietRepres | verbrInStookseizoen | verbrBtnStookseizoen
    //-> Guid   | GAS          | januari                | 64    | 67                            | true              | false                 | false
    const _propertiesCarrier = [
        { Id: 'typeCarrier',            PropertyType: 2,    Name: '',                               jaarName: 'energiedrager' },
        { Id: 'verbrMJ',                PropertyType: 2,    Name: '' },
        { Id: 'maandTemp',              PropertyType: 1,    Name: 'gem. maand temperatuur [°C]'},
        { Id: 'verbrFitted',            PropertyType: 1,    Name: 'gem. dagverbruik - berekend',    jaarName: 'berekend verbruik' },
        { Id: 'verbr',                  PropertyType: 1,    Name: 'gem. dagverbruik - werkelijk',   jaarName: 'werkelijk verbruik' },
        { Id: 'afw',                    PropertyType: 1,    Name: 'afwijking [%]',                  jaarName: 'afwijking [%]' },
        { Id: 'verbrNietRepres',        PropertyType: 3,    Name: 'niet representatief' },
        { Id: 'verbrInStookseizoen',    PropertyType: 3,    Name: 'trendlijn winter' },
        { Id: 'verbrBtnStookseizoen',   PropertyType: 3,    Name: 'trendlijn zomer' },
    ]

    const carrierYasLabels = new Map([
        ['GAS', 'Gas - gem. dagverbruik per maand [m³/dag]'],
        ['E_LEV', 'Electriciteit - gem. dagverbruik per maand [kWh/dag]'],
        ['OLIE', 'Olie - gem. dagverbruik per maand [liter/dag]'],
        ['BIOM', 'Biomassa - gem. dagverbruik per maand [kWh/dag]'],
        ['W', 'Warmte - gem. dagverbruik per maand [MJ/dag]'],
        ['K', 'Koude - gem. dagverbruik per maand [MJ/dag]'],
    ]);
    //== Exports ==================================================================================
    ctrl.getForm = () => ctrl.formFitGrafiek;

    Object.assign(ctrl, {
        initialize,
        getVerbruiken,
        getCarrierName,
        getPropName,
        showTableCarrier,
        getPropData,
        isHidden,
        saveValue,
        getGraphData,
        getGraphOptions,
        getTitle,
    });

    //== Initialization ===========================================================================
    ctrl.carriers = _carriers;
    ctrl.propertiesCarrier = _propertiesCarrier;

    window.addEventListener('message', onDiagramMessage);

    ctrl.options = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1.2,
        legend: {
            display: true,
            position: 'top',
            align: 'center',
            labels: {
                boxWidth: 8,
                filter: function (item, chart) {
                    // Logic to remove a particular legend item goes here
                    return !item.text.includes('berekend') && !item.text.includes('werkelijk') ;
                }
            }
        },
        scales: {
            xAxes: [{
                type: 'linear',
                position: 'bottom',
                scaleLabel: {
                    display: true,
                    labelString: 'Gemiddelde maandtemperatuur [°C]',
                }
            }],
            yAxes: [{
                id: 'y-axis_GAS',
                type: 'linear',
                scaleLabel: {
                    display: true,
                    labelString: 'Gasverbruik per maand [m³]',
                },
                ticks: {
                    beginAtZero: true,
                }
            }]
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += tooltipItem.yLabel;
                    return label;
                },
                title: function (tooltipItems, data) {
                    if (tooltipItems.length === 0) return;
                    var klimaatdata = _climateData.find(x => x.Te === tooltipItems[0].xLabel);
                    var month = moment().month(klimaatdata && klimaatdata.Month - 1).format('MMMM');
                    var title = month +': ' + tooltipItems[0].xLabel.toFixed(1) + ' °C';
                    return title;
                },
            },
            filter: function (tooltipItem) {
                return tooltipItem.datasetIndex === 0 || tooltipItem.datasetIndex === 1;
            },
            intersect: true,
            mode: 'nearest',
        }
    };

    ctrl.datasetOverride = [
        {
            label: "berekend",
            pointBackgroundColor: '#73BE26',
            type: 'line',
            showLine: false,
            pointRadius: 3,
        },
        {
            label: "werkelijk",
            pointBackgroundColor: '#FFAB40',
            type: 'line',
            showLine: false,
            pointRadius: 4,
        },
        {
            label: "Berekend verbruik",
            borderWidth: 2,
            borderColor: '#73BE26',
            fill: false,
            type: 'line',
            showLine: true,
            pointRadius: 0,
            pointHitRadius: 0,
        },
        {
            label: "berekend",
            borderWidth: 2,
            borderColor: '#73BE26',
            fill: false,
            type: 'line',
            showLine: true,
            pointRadius: 0,
            pointHitRadius: 0,
        },
        {
            label: "Werkelijk verbruik",
            borderWidth: 3,
            borderColor: '#FFAB40',
            fill: false,
            type: 'line',
            showLine: true,
            pointRadius: 0,
            pointHitRadius: 0,
        },
        {
            label: "werkelijk",
            borderWidth: 3,
            borderColor: '#FFAB40',
            fill: false,
            type: 'line',
            showLine: true,
            pointRadius: 0,
            pointHitRadius: 0,
        }
    ];

    angular.element(function () {
        const message = createMessage();
        message.action = 'requestData';
        window.opener.postMessage(message, window.origin)
    });

    //== Implementation ===========================================================================
    function initialize(buildingId) {
        _buildingId = buildingId;
    } //-- end: initialize ------------------------------------------------------------------------

    function createMessage() {
        return { buildingId: _buildingId, action: '', data: [] };
    } //-- end: createMessage----------------------------------------------------------------------

    function onDiagramMessage(event) {
        if (event.origin !== window.origin || event.data.buildingId !== _buildingId) {
            $log.warn('SECURITY: message from unexpected origin', event.origin, '; expected origin:', window.origin);
            return;
        }

        switch (event.data.action) {
            case 'allData':
                _projectName = event.data.data.projectName;
                _buildingName = event.data.data.buildingName;
                _verbruikData = event.data.data.verbruikdata;
                _climateData = event.data.data.klimaatdata;
                updateGraphData();
                document.title = _buildingName + ' – Uniec 3';
                break;
            case 'closeWindow':
                window.close();
                break;
            case 'updateData': {
                const i = _verbruikData.findIndex(x => x.verbrId === event.data.data.verbrId && x.typeCarrier === event.data.data.typeCarrier); //verbrId en typeCarrier maakt data uniek
                _verbruikData[i] = event.data.data;
                updateCarrierGraphData(event.data.data.typeCarrier);
                break;
            }
            default: {
                $log.warning(`Unexpected event ‘${event.data.action}’`, event.data);
                break;
            }
        }

        $scope.$digest();
    } //-- end: onDiagramMessage ------------------------------------------------------------------

    function getPropData(prop, row) {
        let value = row[prop.Id];
        switch (prop.Id) {
            case 'maandTemp': {
                const year = parseInt(row['verbrMJ'].split(" ").pop());
                const month = row['verbrMaand'];
                const klimaatData = _climateData.find(x => x.Year === year && x.Month === month);
                value = klimaatData ? ntaRounding.roundAndAddZerosNewValue(null, klimaatData.Te, 'R015') : '--';
                break;
            }
            case 'typeCarrier': {
                value = getCarrierName(value);
                break;
            }
            case 'verbrFitted':
            case 'verbr': {
                value = value + (isJaarFitten() ? ' ' + getJaarUnit(row.typeCarrier) : '');
                break;
            }
        }

        return {
            Id: row['verbrId'] + '_' + row['typeCarrier'] + '_' + prop.Id,
            Value: value
        }
    } //-- end: getPropData -----------------------------------------------------------------------

    function isHidden(prop) {
        switch (prop.Id) {
            case 'verbrBtnStookseizoen':
            case 'verbrInStookseizoen':
            case 'verbrNietRepres':
            case 'verbrMJ':
            case 'maandTemp': {
                return isJaarFitten();
            }
            case 'typeCarrier': {
                return !isJaarFitten();
            }
        }
        return false;
    } //-- end: isHidden ---------------------------------------------------------------------

    function saveValue(prop, row) {
        if (row[prop.Id] === "1") return;

        //[FG-A]
        row[prop.Id] = '1';
        const clickProps = ['verbrNietRepres', 'verbrInStookseizoen', 'verbrBtnStookseizoen'].filter(x => x !== prop.Id);
        for (const clickProp of clickProps) {
            row[clickProp] = '0';
        }

        const message = createMessage();
        message.action = 'choiceUpdated';
        message.data = row;
        window.opener.postMessage(message, window.origin)

        updateCarrierGraphData(row.typeCarrier);
    } //-- end: saveValue -------------------------------------------------------------------------

    function showTableCarrier(carrier) {
        return _verbruikData.length > 0 && ((isJaarFitten() && carrier == "All") || ((!isJaarFitten() && _verbruikData.filter(x => x.typeCarrier === carrier).length > 0)));
    } //-- end: showTableCarrier -----------------------------------------------------------------

    function isJaarFitten() {
        return _verbruikData.length > 0 && _verbruikData[0]["verbrMaand"] === 0; // 0 = fitten per jaar
    }

    function getTitle() {
        return _projectName + "  -  " + _buildingName;
    } //-- end: getTitle -------------------------------------------------------------------------

    function getCarrierName(carrier) {
        switch (carrier) {
            case 'All':     return 'Jaarverbruik';
            case 'GAS':     return 'gasverbruik';
            case 'E_LEV':   return 'electriciteitsverbruik';
            case 'OLIE':    return 'olieverbruik';
            case 'BIOM':    return 'biomassaverbruik';
            case 'W':       return 'warmteverbruik';
            case 'K':       return 'koudeverbruik';
        }
        return '';
    } //-- end: getCarrierName ------------------------------------------------------------------

    function getPropName(prop, carrier) {
        return (isJaarFitten() ? prop.jaarName : prop.Name) + ' ' + (carrier !== 'All' && (prop.Id === 'verbrFitted' || prop.Id === 'verbr') ? getMaandUnit(carrier) : '');
    } //-- end: showTableCarrier -------------------------------------------------------------

    function getMaandUnit(carrier) {
        const units = new Map([
            ['GAS', '[m³aeq/dag]'], ['E_LEV', '[kWh/dag]'], ['OLIE', '[liter/dag]'], ['BIOM', '[kWh/dag]'], ['W', '[MJ/dag]'], ['K', '[MJ/dag]']
        ]);
        return units.get(carrier);
    }

    function getJaarUnit(carrier) {
        const units = new Map([
            ['GAS', 'm³aeq'], ['E_LEV', 'kWh'], ['OLIE', 'liter'], ['BIOM', 'kWh'], ['W', 'GJ'], ['K', 'GJ']
        ]);
        return units.get(carrier);
    }

    function getVerbruiken(carrier) {
        return (isJaarFitten() && _verbruikData) || _verbruikData.filter(x => x.typeCarrier === carrier);
    } //-- end: getVerbruiken ----------------------------------------------------------------

    function updateGraphData() {
        for (const carrier of _carriers) {
            updateCarrierGraphData(carrier);
        }
    } //-- end: updateGraphData --------------------------------------------------------------

    function updateCarrierGraphData(carrier) {
        if (isJaarFitten()) return;

        const data = {
            fitted: { nietRepresentatief: [], binnenStookseizoen: [], buitenStookseizoen: [] },
            werkelijk: { nietRepresentatief: [], binnenStookseizoen: [], buitenStookseizoen: [] },
        };

        const carrierVerbruikData = getVerbruiken(carrier);
        for (const verbruikData of carrierVerbruikData) {
            const climateMaandData = _climateData.find(x => x.Month === verbruikData.verbrMaand);
            const verbrFittedValue = Number.parseFloat(String(verbruikData.verbrFitted).replace(',', '.'));
            const verbrValue = Number.parseFloat(String(verbruikData.verbr).replace(',', '.'));
            const teValue = Number.parseFloat(String(climateMaandData.Te).replace(',', '.'));
            if (verbruikData.verbrNietRepres === "1") {
                data.fitted.nietRepresentatief.push({ x: teValue, y: verbrFittedValue });
                data.werkelijk.nietRepresentatief.push({ x: teValue, y: verbrValue });
            }
            if (verbruikData.verbrInStookseizoen === "1") {
                data.fitted.binnenStookseizoen.push({ x: teValue, y: verbrFittedValue });
                data.werkelijk.binnenStookseizoen.push({ x: teValue, y: verbrValue });
            }
            if (verbruikData.verbrBtnStookseizoen === "1") {
                data.fitted.buitenStookseizoen.push({ x: teValue, y: verbrFittedValue });
                data.werkelijk.buitenStookseizoen.push({ x: teValue, y: verbrValue });
            }
        }

        const carrierFittedData = [...data.fitted.nietRepresentatief, ...data.fitted.binnenStookseizoen, ...data.fitted.buitenStookseizoen];
        const carrierWerkelijkData = [...data.werkelijk.nietRepresentatief, ...data.werkelijk.binnenStookseizoen, ...data.werkelijk.buitenStookseizoen];
        const carrierLineData = [calcRegrLine(data.fitted.binnenStookseizoen), calcRegrLine(data.fitted.buitenStookseizoen), calcRegrLine(data.werkelijk.binnenStookseizoen), calcRegrLine(data.werkelijk.buitenStookseizoen)];

        _chartdata.set(carrier, [carrierFittedData, carrierWerkelijkData, ...carrierLineData]);
    } //-- end: updateCarrierGraphData ------------------------------------------------------

    function calcRegrLine(points) {
        if (points.length === 0) return [];

        let totX = 0;
        let totY = 0;
        let minX = Number.MAX_VALUE;
        let minY = Number.MAX_VALUE;
        let maxX = -Number.MAX_VALUE;
        let maxY = -Number.MAX_VALUE;
        let gemX = 0;
        let gemY = 0;
        let Sxx = 0;
        let Syy = 0;
        let Sxy = 0;
        let Sxyi = 0;

        for (const point of points) {
            totX = totX + point.x;
            totY = totY + point.y;
            minX = Math.min(minX, point.x);
            minY = Math.min(minY, point.y);
            maxX = Math.max(maxX, point.x);
            maxY = Math.max(maxY, point.y);
        }
        gemX = totX / points.length;
        gemY = totY / points.length;

        for (const point of points) {
            Sxx = Sxx + ((point.x - gemX) * (point.x - gemX));
            Syy = Syy + ((point.y - gemY) * (point.y - gemY));
            Sxy = Sxy + ((point.x - gemX) * (point.y - gemY));
            Sxyi = Sxyi + (point.y * (point.x - gemX));
        }

        let a = Sxyi / Sxx;
        let b = gemY - (a * gemX);

        let Coord1Y = (a * minX) + b;
        let Coord2Y = (a * maxX) + b;

        return [
            { x: minX, y: Coord1Y },
            { x: maxX, y: Coord2Y }
        ];

    } //-- end: calcRegrLine ----------------------------------------------------------------

    function getGraphData(carrier) {
        return _chartdata.get(carrier);
    } //-- end: getGraphData ----------------------------------------------------------------

    function getGraphOptions(carrier) {
        ctrl.options.scales.yAxes[0].scaleLabel.labelString = carrierYasLabels.get(carrier);
        return ctrl.options;
    } //-- end: getGraphOptions -------------------------------------------------------------


}]);
