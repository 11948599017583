﻿angular.module('projectModule')
    .service('ntaValidation',
        ['$log', '$location', 'ntaData', 'ntaMeldingen', 'ntaRounding', 'baseNtaEntityData',
function ($log,   $location,   ntaData,   ntaMeldingen,   ntaRounding,   ntaEntityData) {
    'use strict';

    this.IsValid = function (form, prop, propdata, hidden = false, readonly = false, checkTouched = true) {
        if (!prop && propdata) {
            prop = ntaData.properties[propdata.PropertyId];
        }
        if (!prop || !propdata) {
            return false;
        }

        //reset bools
        let validate = true; // moet je valideren?
        let valid = true; // is item valid?

        var D001 = true; // alle messages eerst op true ! -> geen meldingen !
        var D002 = true;
        var D003 = true;
        var D004 = true;
        var D005 = true;
        var D007 = true;
        var D008 = true;
        var D009 = true;
        var D010 = true;
        var D011 = true;

        const entdata = ntaEntityData.get(propdata.EntityDataId);
        if (!entdata) {
            return false;
        }

        const contrl = form && form['ntainput' + propdata.PropertyDataId];
        if (contrl) {
            readonly |= contrl.$$attr.disabled;
        }
        if (readonly || !propdata.Relevant || !entdata.Relevant) {
            validate = false; // alles op true -> geen melding !
        }

        if (checkTouched && !propdata.Touched) { //velden die nog niet mogen worden gevalideerd !
            validate = false; // alles op true -> geen melding !
        }

        if (hidden) {
            validate = false; // alles op true -> geen melding !
        }

        //required? -> check of waarde aanwezig is
        if (validate && (propdata.Value === null || propdata.Value === "")) {
            if (prop.Required) {
                D001 = false;
                valid = false;
                validate = false; //rest niet meer valideren
            }
        }

        // Afronding ongeacht er wel of niet gevalideerd wordt
        if (prop.PropertyType === 1) {
            ntaRounding.roundAndAddZeros(prop, propdata);
        }

        if (validate && propdata.Value && propdata.Value !== "n.v.t." ) { // nog geen fout of value = null of n.v.t. -> ook niet valideren
            if (prop.PropertyType === 1) { //nummer
                //var flvalue = parseFloat(propdata.Value);
                var flvalue = parseFloat(propdata.Value.replace(/,/, '.'));
                if (isNaN(flvalue)) { // check voor NaN
                    D002 = false;
                    valid = false;
                } else {
                    flvalue = ntaRounding.ntaround(flvalue, prop.Rounding);
                    propdata.Value = flvalue.toString().replace('.', ",");

                    if (prop.Rounding === 'R020') {
                        if (propdata.Value.length === 1) {
                            propdata.Value = propdata.Value + ',' + '0';
                        }
                        else if (propdata.Value[0] === '0' && propdata.Value.includes(',')) {
                            propdata.Value = propdata.Value + '0';
                        }
                    }
                    else {
                        //0-en aanvullen obc decimals
                        var len = propdata.Value.length;
                        var indx = propdata.Value.indexOf(",");
                        indx = indx === -1 ? len : indx + 1; //geen komma -> index = lengte, wel komma -> index + 1 omdat de komma niet moet worden meegeteld...
                        var decimals = ntaRounding.getDecimals(flvalue, prop.Rounding);
                        var numzero = decimals - (len - indx);
                        if (len === indx && numzero > 0) {
                            propdata.Value = propdata.Value + ",";
                        }
                        for (var i = 0; i < numzero; i++) {
                            propdata.Value = propdata.Value + "0";
                        }
                    }

                    if (prop.Domain !== null) { //valideren
                        if (prop.Domain.DomainType === 1) { //range -> voorlopig geen ondersteuning voor meerdere ranges

                            var Min = Number.MAX_VALUE * -1;
                            var Max = Number.MAX_VALUE;
                            if (prop.Domain.Ranges[0].Min !== null) { // null is min value
                                Min = prop.Domain.Ranges[0].Min;
                            }
                            if (prop.Domain.Ranges[0].Max !== null) { // null is max value
                                Max = prop.Domain.Ranges[0].Max;
                            }

                            if (prop.Domain.Ranges[0].MinExcl) { // range exclusief min -> bv 0 - 1000, false indien waarde 0 of klenier is
                                if (flvalue <= Min) {
                                    valid = false;
                                }
                            } else { // range inclusief min -> bv 0 - 1000, false indien waarde klenier is dan 0
                                if (flvalue < Min) {
                                    valid = false;
                                }
                            }
                            if (prop.Domain.Ranges[0].MaxExcl) { // range exclusief max -> bv 0 - 1000, false indien waarde 1000 of groter is
                                if (flvalue >= Max) {
                                    valid = false;
                                }
                            } else { // range inclusief max -> bv 0 - 1000, false indien waarde groter is dan 1000
                                if (flvalue > Max) {
                                    valid = false;
                                }
                            }

                            if (!valid) {
                                if (prop.Domain.Ranges[0].Min === null || prop.Domain.Ranges[0].Max === null) {
                                    if (prop.Domain.Ranges[0].Max === null) {
                                        if (prop.Domain.Ranges[0].MinExcl) {
                                            D011 = false; // Waarde moet groter zijn dan {{prop.Domain.Ranges[0].Min}}
                                        } else {
                                            D010 = false; // Waarde moet groter zijn dan of gelijk zijn aan {{prop.Domain.Ranges[0].Min}}
                                        }
                                    }
                                    if (prop.Domain.Ranges[0].Min === null) {
                                        if (prop.Domain.Ranges[0].MaxExcl) {
                                            D006 = false; // Waarde moet kleiner zijn dan {{prop.Domain.Ranges[0].Max}}
                                        } else {
                                            D005 = false; // Waarde moet kleiner zijn dan of gelijk zijn aan {{prop.Domain.Ranges[0].Max}}
                                        }
                                    }
                                } else {
                                    if (prop.Domain.Ranges[0].MinExcl) {
                                        D004 = false; // Waarde moet groter dan {{prop.Domain.Ranges[0].Min}} en maximaal {{prop.Domain.Ranges[0].Max}} zijn
                                    }
                                    else {
                                        D003 = false; // valt binnen een specifieke range. Kan worden weergegeven in ntaMeldingen.melding
                                    }
                                }
                            }

                        } else if (prop.Domain.DomainType === 2) { //een getal, maar wel een coded domein?  -> dit mag niet !!!
                            D007 = false;
                            valid = false;
                        }
                    }
                }
            } else if (prop.PropertyType === 2) { //tekst
                if (prop.Domain !== null) { //valideren
                    if (prop.Domain.DomainType === 1) { //tekst, maar wel een range domein?  -> dit mag niet !!!
                        D008 = false;
                        valid = false;
                    }

                    //Niet meer checken of coded value in domain zit. Kan eigenlijk niet invalid zijn met md-select en geeft invalid als er waarden aan de coded values worden toegevoegd -> zoals bij begrenzing AOR en AOS !
                    else if (/*prop.Domain.DomainType === 2 ||*/ prop.Domain.DomainType === 3) { // coded value of coded value in tabel
                        var valExists = false;
                        for (var j = 0; j < prop.Domain.Codes.length; j++) {
                            if (prop.Domain.Codes[j].Id === propdata.Value) {
                                valExists = true;
                                break;
                            }
                        }
                        if (!valExists) { // waarde niet één van de coded values
                            D009 = false;
                            valid = false;
                        }
                    }
                }
            }
        }
        if (contrl) { // velden die aanwezig zijn
            contrl.$setValidity("[D001]", D001);
            contrl.$setValidity("[D002]", D002);
            contrl.$setValidity("[D003]", D003);
            contrl.$setValidity("[D004]", D004);
            contrl.$setValidity("[D005]", D005);
            contrl.$setValidity("[D007]", D007);
            contrl.$setValidity("[D008]", D008);
            contrl.$setValidity("[D009]", D009);
            contrl.$setValidity("[D010]", D010);
            contrl.$setValidity("[D011]", D011);
        }
        if (entdata.EntityId !== "AFMELDINFO" && entdata.EntityId !== "AFMELDOBJECT" && entdata.EntityId !== "AFMELDLOCATIE") { // voor het afmelden meoten geen meldingen worden aangemaakt.
            ntaMeldingen.melding("[D001]", propdata.PropertyDataId, D001);
            ntaMeldingen.melding("[D002]", propdata.PropertyDataId, D002);
            ntaMeldingen.melding("[D007]", propdata.PropertyDataId, D007);
            ntaMeldingen.melding("[D008]", propdata.PropertyDataId, D008);
            ntaMeldingen.melding("[D009]", propdata.PropertyDataId, D009);
            if (prop.Domain && prop.Domain.Ranges[0]) {
                ntaMeldingen.melding("[D003]", propdata.PropertyDataId, D003, 'Waarde moet minimaal ' + prop.Domain.Ranges[0].Min + ' en maximaal ' + prop.Domain.Ranges[0].Max + ' zijn');
                ntaMeldingen.melding("[D004]", propdata.PropertyDataId, D004, 'Waarde moet groter dan ' + prop.Domain.Ranges[0].Min + ' en maximaal ' + prop.Domain.Ranges[0].Max + ' zijn');
                ntaMeldingen.melding("[D010]", propdata.PropertyDataId, D010, 'Waarde moet groter zijn dan of gelijk zijn aan ' + prop.Domain.Ranges[0].Min);
                ntaMeldingen.melding("[D011]", propdata.PropertyDataId, D011, 'Waarde moet groter zijn dan ' + prop.Domain.Ranges[0].Min);
                ntaMeldingen.melding("[D005]", propdata.PropertyDataId, D005, 'Waarde moet kleiner gelijk ' + prop.Domain.Ranges[0].Max + ' zijn');
            } else {
                ntaMeldingen.melding("[D003]", propdata.PropertyDataId, D003);
                ntaMeldingen.melding("[D003]", propdata.PropertyDataId, D004);
                ntaMeldingen.melding("[D010]", propdata.PropertyDataId, D010);
                ntaMeldingen.melding("[D011]", propdata.PropertyDataId, D011);
                ntaMeldingen.melding("[D005]", propdata.PropertyDataId, D005);
            }
        }

        return valid;
    };

    this.setValid = function (form, prop, propdata) {

        if (!prop || !propdata) {
            return false;
        }

        if (form) {
            var contrl = form['ntainput' + propdata.PropertyDataId];

            if (contrl) { // velden die aanwezig zijn
                contrl.$setValidity("[D001]", true);
                contrl.$setValidity("[D002]", true);
                contrl.$setValidity("[D003]", true);
                contrl.$setValidity("[D004]", true);
                contrl.$setValidity("[D005]", true);
                contrl.$setValidity("[D007]", true);
                contrl.$setValidity("[D008]", true);
                contrl.$setValidity("[D009]", true);
                contrl.$setValidity("[D010]", true);
                contrl.$setValidity("[D011]", true);
            }
        }

        ntaMeldingen.melding("[D001]", propdata.PropertyDataId, true);
        ntaMeldingen.melding("[D002]", propdata.PropertyDataId, true);
        ntaMeldingen.melding("[D003]", propdata.PropertyDataId, true);
        ntaMeldingen.melding("[D004]", propdata.PropertyDataId, true);
        ntaMeldingen.melding("[D005]", propdata.PropertyDataId, true);
        ntaMeldingen.melding("[D007]", propdata.PropertyDataId, true);
        ntaMeldingen.melding("[D008]", propdata.PropertyDataId, true);
        ntaMeldingen.melding("[D009]", propdata.PropertyDataId, true);
        ntaMeldingen.melding("[D010]", propdata.PropertyDataId, true);
        ntaMeldingen.melding("[D011]", propdata.PropertyDataId, true);
    };

    this.hasCodedValues = function (prop) {
        return prop && prop.Domain && prop.Domain.DomainType === 2 || false;
    };

    this.hasCodedValuesFromTable = function (prop) {
        return prop && prop.Domain && prop.Domain.DomainType === 3 || false;
    };

    this.hasSelectionTableValues = function (prop) {
        //producten gebruiken uit de selecton table. In eigen controller code schrijven om de juiste producten op te halen
        return prop && prop.Domain && (prop.Domain.DomainType === 4 || prop.Domain.DomainType === 5) || false;
    };

    this.codedValues = function (prop, fv1 = "", fv1Excl = false, fv2 = "", fv2Excl = false, fv3 = "", fv3Excl = false, fv4 = "", fv4Excl = false, searchText = "") {
        if (typeof prop === 'string') {
            prop = ntaData.properties[prop];
        }
        if (!prop) {
            return;
        }
        if (prop.Domain !== null) { //heeft domein
            if (prop.Domain.DomainType === 2 || prop.Domain.DomainType === 3) { //coded value
                var res = prop.Domain.Codes;
                if (fv1 && fv1.length > 0) {
                    if (fv1Excl) {
                        res = res.filter(function (x) { return x.FilterValue1 !== fv1 && x.FilterValue1 !== "*"; });
                    } else {
                        res = res.filter(function (x) { return x.FilterValue1 === fv1 || x.FilterValue1 === "*"; });
                    }
                }
                if (fv2 && fv2.length > 0) {
                    if (fv2Excl) {
                        res = res.filter(function (x) { return x.FilterValue2 !== fv2 && x.FilterValue2 !== "*"; });
                    } else {
                        res = res.filter(function (x) { return x.FilterValue2 === fv2 || x.FilterValue2 === "*"; });
                    }
                }
                if (fv3 && fv3.length > 0) {
                    if (fv3Excl) {
                        res = res.filter(function (x) { return x.FilterValue3 !== fv3 && x.FilterValue3 !== "*"; });
                    } else {
                        res = res.filter(function (x) { return x.FilterValue3 === fv3 || x.FilterValue3 === "*"; });
                    }
                }
                if (fv4 && fv4.length > 0) {
                    if (fv4Excl) {
                        res = res.filter(function (x) { return x.FilterValue4 !== fv4 && x.FilterValue4 !== "*"; });
                    } else {
                        res = res.filter(function (x) { return x.FilterValue4 === fv4 || x.FilterValue4 === "*"; });
                    }
                }

                if (searchText && searchText.length > 0) {
                    res = res.filter(function (x) { return x.Value && x.Value.toLowerCase().indexOf(searchText.toLowerCase()) !== -1; });
                }

                if (res.length === 1) {
                    res.Singleresult = true;
                }
                return res;
            }
        }
        return [];
    };

}]);


angular.module('projectModule').directive("ntaErrormessages", ['ntaData', function (ntaData) {
    return {
        restrict: "E",
        replace: true,
        transclude: true,
        scope: {
            'ctrl': '=',
            'prop': '=',
            'entdata': '='
        },
        template: function () { //template opbouwen met strings ivm het toevoegen van waarden bv {{prop.Domain.Ranges[0].Min}}. Dit werkt niet met ng-repeat
            let html = '<div ng-messages="getErrorInput().$error" class="md-input-messages-animation md-auto-hide ng-inactive" style="max-width: 240px;">'; // style="margin-top: -20px;"
            for (const item of ntaData.errors) {
                html += `<div style="color:rgb(221,44,0); font-size:11px; font-weight:normal; text-align:left;" ng-message="${item.Id}">${item.Value}</div>`;
            }
            return html;
        },

        link: function (scope, elem, attrs) {
            scope.getErrorInput = function () {
                if (!scope.entdata || !scope.prop) {
                    return;
                }
                const propdata = scope.entdata.PropertyDatas[scope.prop.Id];
                if (!propdata) {
                    return;
                }
                const form = scope.ctrl.getForm();
                if (!form) {
                    return;
                }

                const input = form['ntainput' + propdata.PropertyDataId];
                if (!input) {
                    return;
                }
                if (propdata.Touched) {
                    input.$touched = true;
                    input.$untouched = false;
                }
                return input;
            };
        }
    };
}]);

angular.module('projectModule').directive("ntaOnvalidate", ['$log', '$timeout', function ($log, $timeout) {
    return {
        require: 'ngModel',
        scope: {
            callback: '&'
        },
        link: function (scope, elm, attrs, contrl) {
            // Voor een warning wordt validate gebruikt, omdat de warning komt voordat er wordt opgeslagen
            // Als de keuze op niet opslaan valt, kan dit nog.
            // Voorbeeld Gebouwtype. Bij het wijzigen van gebouwtype veranderd er veel invoer. Voordat dit gebeurd moet de gebruiker bevestigen.
            contrl.$validators.validate = function (value) {
                return scope.callback({ value: value });
            };
        }
    };
}]);