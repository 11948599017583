﻿angular.module('projectModule')
    .controller("MeasuresController",
        ['$scope', '$controller', '$routeParams', '$location', 'ntaEntityData', 'ntaValidation', 'ntaMeldingen', 'ntaDependencyValidation', 'MeasuresFactory',
function           ($scope, $controller, $routeParams, $location, ntaEntityData, ntaValidation,   ntaMeldingen, ntaDependencyValidation,   MeasuresFactory) {
    'use strict';
    const vm = this;
    const base = $controller('NTABaseController', { $scope: $scope })
    angular.extend(vm, base);

    const measuresLogic = new MeasuresFactory(ntaDependencyValidation);
    vm.getMeasuresLogic = function () {
        return measuresLogic;
    };

    vm.ntaValidation = ntaValidation;
    vm.properties = measuresLogic.properties;
    vm.measures = measuresLogic.measures;
    vm.getTileDetails = measuresLogic.getTileDetails;

    vm.getMeasureName = measuresLogic.getMeasureName;
    vm.editMeasureNaam = editMeasureNaam;
    vm.saveMeasureNaam = saveMeasureNaam;

    vm.getAllowedMeasuretypes = getAllowedMeasuretypes;


    /////////////////////////////////////////////////////////////////////////////////////////// CRUD akties /////////////////////////////////////////////////////////////////////////////////////////////////////

    vm.addMeasure = addMeasure;
    vm.copyClick = measuresLogic.copyClick;
    vm.deleteClick = measuresLogic.deleteClick;

    function editMeasureNaam(measureId, event) {
        if (!measureId) {
            return;
        }
        $("#measurename" + measureId).removeClass("showname");
        $("#measurenameinput" + measureId).addClass("editname");
        $("#measurenameinput" + measureId).focus();
        $("#measurenameinput" + measureId).select();

        event.stopImmediatePropagation();
        event.preventDefault();
    } //-- end: editInstallatieNaam --------------------------------------------------------------------------------//

    function saveMeasureNaam(entdata, event) {
        const propdata = entdata.PropertyDatas["MEASURE_NAAM"];
        ntaEntityData.saveprop(propdata);

        $("#measurename" + entdata.EntityDataId).addClass("showname");
        $("#measurenameinput" + entdata.EntityDataId).removeClass("editname");

        /// nu pas de varianten orderen op naam.
        measuresLogic.sortMeasuresByName();

        event.stopImmediatePropagation();
        event.preventDefault();
    } //-- end: saveMeasureNaam -------------------------------------------------------------//

    function getAllowedMeasuretypes() {
        return measuresLogic.measureTypes;
    } //-- end: getAllowedInstallationtypes --------------------------------------------------------------------------------//


    function addMeasure(measuretypeId) {

        //-- VO 2023-06-22: controleer bij het toevoegen van de eerste maatregel of er geen meldingen zijn EN CalcNeeded = false (er moeten rekenresultaten zijn)
        if (measuresLogic.measures().length == 0) {
            var addAllowed = ntaMeldingen.getMeldingen().length === 0 && !ntaEntityData.getCalculationNeeded(ntaEntityData.BuildingId);
            if (!addAllowed) {
                ntaMeldingen.warning("[W109]");
                return null;
            }
        }

        return measuresLogic.addMeasure(measuretypeId);
    }

    ///////////////////////////////////////////////////////////////////////////////////////// Overig ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    vm.measureIconImg = function (entdataId) {
        let iconImg = "";
        const entdata = ntaEntityData.get(entdataId);
        if (entdata && entdata.EntityId === 'MEASURE' && entdata.BuildingId !== -1) {
            const measureType = entdata.PropertyDatas["MEASURE_TYPE"].Value;
            const codedValue = measuresLogic.measureTypes.find(type => type.Id === measureType);
            if (codedValue && codedValue.ImageUrl) {
                iconImg = codedValue.ImageUrl;
            }
        }
        return iconImg;
    }; //-- measureIconImg

    vm.openMenu = function ($mdMenu, ev) {
        $mdMenu.open(ev);

        ev.stopImmediatePropagation();
        ev.preventDefault();
    } //-- end: openMenu ------------------------------------------------------------------------//

    vm.showMenu = function (entdata) {
        return true;
    };

    vm.navToMeasureInTree = function (measure) {
        if (!$("#measurename" + measure.EntityDataId).hasClass("showname")) {
            return;
        }
        const path = `/Project/${$routeParams.id}/Berekening/${measure.BuildingId}/Maatregelen/${measure.EntityDataId}`;
        $location.path(path);
    } //-- end: navToMeasureInTree ------------------------------------------------------------------------//


    ///////////////////////////////////////////////////////////////////////////////////////////////////////// FORM ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    vm.getForm = function () {
        return measuresLogic.form_measure;
    };

    var startFormValidation = function () {
        measuresLogic.startFormValidation();
    } //-- end: startFormValidation ------------------------------------------------------------------------//

    $scope.endFormValidation = function () {
        measuresLogic.endFormValidation();
    };

    angular.element(function () { //alle touched properties valideren na het laden van form
        startFormValidation();
        base.initializeUnitSpacing();
    }); //-- end: endFormValidation ------------------------------------------------------------------------//



}]);