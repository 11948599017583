﻿angular.module('projectModule')
    .factory('BevochtigingFactory',
        ['ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaSharedLogic',
function (ntabuilding,   ntaValidation,   ntaEntityData,   ntaSharedLogic) {
    'use strict';

    function BevochtigingLogic(installId, ntaDependencyValidation) {
        const bevochtigingLogic = this; // Object dat aan de controller gegeven zal worden

        bevochtigingLogic.ntaValidation = ntaValidation;
        bevochtigingLogic.installId = installId;

        //-- welke bevsys entitie hoort bij deze installatie
        bevochtigingLogic.bevdata = ntaEntityData.getFirstChild(installId, 'BEV');

        bevochtigingLogic.properties = ntabuilding.properties['BEV'];

        bevochtigingLogic.startFormValidation = function () {
            return ntaSharedLogic.startFormValidation(getAllEntDatas(), bevochtigingLogic);
        }; //-- end: startFormValidation ----------------------------------------------------------

        bevochtigingLogic.endFormValidation = function () {
            return ntaSharedLogic.endFormValidation(getAllEntDatas(), bevochtigingLogic);
        }; //-- end: endFormValidation ------------------------------------------------------------

        function getAllEntDatas() { // Geeft alle entdatas in één array terug
            return []
                .concat(bevochtigingLogic.bevdata)
                .concat(ntaSharedLogic.getCostEntdata(installId))
                .filter(entdata => entdata) // filter alle nulls en undefineds eruit
                ;
        } //-- end: getAllEntDatas ----------------------------------------------------------------

        bevochtigingLogic.isHidden = function (prop, entdata = bevochtigingLogic.bevdata) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = entdata.PropertyDatas[prop.Id];
            let showit = true;
            let relevant = null;

            switch (prop.Id) {
                case 'BEV_OPEN': {
                    showit = false;
                    break;
                }
                case 'MEASURE-COSTS_INV':
                case 'MEASURE-COSTS_PER_M2': {
                    showit = false;
                    break;
                }
                default: {
                    showit = true;
                    relevant = showit;
                    break;
                }
            }

            if (relevant === null) relevant = showit;

            ntaEntityData.setPropdataRelevancy(propdata, relevant, bevochtigingLogic.form_installationbevochtiging);
            ntaEntityData.setPropdataVisibility(propdata, showit);

            return !showit;
        } //-- end: isHidden ------------------------------------------------------------------------//

        bevochtigingLogic.isReadOnly = function (prop) {
            if (!prop) {
                return false;
            }

            switch (prop.Id) {
                //-- geen condities voor bevochtiging
                case 'MEASURE-COSTS_TOTAAL': {
                    return true;
                }
            }

            return false;
        } //-- end: isReadOnly ------------------------------------------------------------------------//

        bevochtigingLogic.getCodedValues = function (prop) {
            if (!prop) {
                return;
            }

            return ntaValidation.codedValues(prop);
        };

        bevochtigingLogic.saveValue = function (prop) {
            if (!prop || !bevochtigingLogic.form_installationbevochtiging) {
                return;
            }
            var propdata = bevochtigingLogic.bevdata.PropertyDatas[prop.Id];
            propdata.Touched = true; // opslaan veld is aangeraakt

            if (propdata.Value === undefined) { // niks doen -> undefined komt van een waarschuwing
                return;
            }
            const hidden = bevochtigingLogic.isHidden(prop);
            ntaValidation.IsValid(bevochtigingLogic.form_installationbevochtiging, prop, propdata, hidden);  //Client Validatie
            ntaEntityData.saveprop(propdata); //Server validatie en evt. opslaan

            ntaDependencyValidation.checkChangedField(prop, bevochtigingLogic.bevdata, bevochtigingLogic);
        };

        bevochtigingLogic.setGeopend = function () {
            const propdataOpen = bevochtigingLogic.bevdata.PropertyDatas['BEV_OPEN'];
            ntaEntityData.saveprop(propdataOpen, 'true');
        };

        // Ontvangt afhankelijk veld dat gevalideerd moet worden
        bevochtigingLogic.validateDependencies = function (prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            switch (prop.Id) {
                default: {
                    const checkValue = prop.getData(entdata).Value;

                    let hidden = bevochtigingLogic.isHidden(prop);
                    if (ntaValidation.hasCodedValues(prop)) {
                        bevochtigingLogic.getCodedValues(prop);
                    }

                    if (checkValue !== prop.getData(entdata).Value) {
                        bevochtigingLogic.saveValue(prop);
                    }

                    break;
                }
            }
        };
    }


    return BevochtigingLogic;
}]);
