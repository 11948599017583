﻿var belemmeringModule = angular.module("belemmeringModule", ["ngAnimate", "ngMaterial"])
    .directive('ntaBelemmeringInvoer', ntaBelemmeringInvoer)
    .controller('ntaBelemmeringController', ntaBelemmeringController)
    .config(['$mdThemingProvider', function ($mdThemingProvider) {
        $mdThemingProvider.theme('default')
            .primaryPalette('light-green')
            .accentPalette('orange');
    }]);

ntaBelemmeringInvoer.$inject = ['ntabuilding'];
ntaBelemmeringController.$inject = ['ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaSharedLogic', '$scope', '$sce', '$attrs', '$mdDialog', '$controller'];

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ntaBelemmeringInvoer(ntabuilding) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: '/src/app/components/belemmeringinvoer.html?v=' + ntabuilding.commit,
        controller: ntaBelemmeringController,
        controllerAs: 'vm',
        bindToController: true,
        scope: {
            parententitydataid: '@',
            propertyid: '@',
            parentformobject: '='
        }
    };
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ntaBelemmeringController(ntabuilding, ntaValidation, ntaEntityData, ntaSharedLogic, $scope, $sce, $attrs, $mdDialog, $controller) {
    'use strict';
    const base = $controller('NTABaseController', { $scope: $scope })
    const vm = angular.extend(this, base);

    vm.ntaValidation = ntaValidation;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    vm.parententitydataid = $attrs.parententitydataid;
    vm.propertyid = $attrs.propertyid;
    vm.parentformobject = $attrs.parentformobject;
    vm.alertIsVisible = false;

    vm.belemmeringEntData = null;

    const _belemmeringPropsAll = ntabuilding.properties['BELEMMERING'];
    const _panels = [];
    _panels.push({ id: 0, label: "", show: true, rightColumn: false });
    _panels.push({ id: 1, label: "", show: false, rightColumn: true });


    vm.getForm = getForm;
    vm.validate = validate;
    vm.isHidden = isHidden;
    vm.showBelemmingInput = showBelemmingInput;
    vm.inputpanels = inputpanels;
    vm.properties = properties;
    vm.getPropData = getPropData;
    vm.saveValue = saveValue;
    vm.getCodedValues = getCodedValues;
    vm.isReadOnly = isReadOnly;
    vm.isDisabled = vm.isReadOnly;
    vm.columnsNextToImage = columnsNextToImage;
    vm.getBelemmeringType = getBelemmeringType;
    vm.saveValue = saveValue;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    angular.element(function () { //alle touched properties valideren na het laden van form
        const belemmData = getBelemmeringEntData();
        if (belemmData) {
            _belemmeringPropsAll.forEach(function (prop, index) {
                const propdata = prop.getData(belemmData);
                vm.validate(prop, propdata);
            });
        }
    });

    $scope.endFormValidation = function () {
        const belemmData = getBelemmeringEntData();
        if (belemmData) {
            belemmData.PropertyDatas.forEach(function (propdata, index) {
                if (!propdata.Touched) {
                    propdata.Touched = true;
                    ntaEntityData.saveprop(propdata);
                }
                const prop = ntabuilding.properties[propdata.PropertyId];
                vm.validate(prop, propdata);
            });
        }
    };

    function getForm() {
        return vm.parentformobject;
    } //-- end: getForm ------------------------------------------------------------------------//

    function getBelemmeringType() {
        //-- BELEMTYPE_CONST_BELEM, BELEMTYPE_CONST_OVERST, BELEMTYPE_ZIJ_RECHTS, BELEMTYPE_ZIJ_LINKS, BELEMTYPE_ZIJ_BEIDE ENZ
        const parent = ntaEntityData.get(vm.parententitydataid);
        const propdata = parent && parent.PropertyDatas[vm.propertyid];
        return propdata ? propdata.Value : null;
    } //-- end: getBelemmeringType ------------------------------------------------------------------------//

    function getBelemmeringEntData() {
        vm.belemmeringEntData = ntaEntityData.getFirstChild(vm.parententitydataid, 'BELEMMERING');
        return vm.belemmeringEntData;
    } //-- end: getBelemmeringEntData ------------------------------------------------------------------------//

    // voor foutmeldingen !!!
    function validate(prop, propdata) {
        if (!ntabuilding.canSave()) return;
        if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
            return;
        }
        return ntaValidation.IsValid(vm.parentformobject, prop, propdata, isHidden(prop));
    } //-- end: validate ------------------------------------------------------------------------//

    function isHidden(prop) {
        //-- gebruik in de module het zelfde checkmechanisme als in de factorys
        let showit = ntaSharedLogic.showBelemmeringsProperty(prop, getBelemmeringEntData(), getBelemmeringType(), isInstallatieBelemmering());

        ntaEntityData.setPropdataStatus(prop.getData(getBelemmeringEntData()), showit, showit);
        return !showit;
    } //-- end: isHidden --------------------------------------------------------------------------------//

    function isInstallatieBelemmering() {
        const parentType = ntaEntityData.get(vm.parententitydataid).EntityId;
        return parentType === 'PV-PVT' || parentType === "ZONNECOL" || parentType === "UNIT-ZONNC" || parentType === 'VERW-OPWEK' || parentType === 'PV-VELD';
    } //-- end: isInstallatieBelemmering ------------------------------------------------------------------------//

    function getCalcHoek() {
        const entData = getBelemmeringEntData();
        const belemmeringType = getBelemmeringType();

        let B = 0; //-- breedte
        let A = 0; //-- afstand
        let H = 0; //-- hoogte
        let V = 1; //-- verhouding van de de deling
        let result = NaN;
        let hoekdata;
        let newValue;
        let radTodeg = Math.PI / 180;

        if (belemmeringType && entData) {
            switch (belemmeringType) {
                case "BELEMTYPE_CONST_BELEM":
                    {
                        A = ntaSharedLogic.parseFloat(entData.PropertyDatas['BELEMM_HOR_A_BELEM'].Value);
                        H = ntaSharedLogic.parseFloat(entData.PropertyDatas['BELEMM_HOOGTE_BELEM'].Value);
                        if (!isNaN(A) && !isNaN(B)) {
                            if (A !== 0) {
                                V = parseFloat(H.toFixed(2)) / parseFloat(A.toFixed(2));
                                result = Math.atan(V) / radTodeg;
                                result = Math.round(result);
                            }
                        }
                        newValue = !isNaN(result) ? result.toString() : '';
                        hoekdata = entData.PropertyDatas['BELEMM_HOEK_BELEM'];
                        ntaEntityData.saveprop(hoekdata, newValue);
                        if (V <= 0.36) {
                            return "BELEMM";
                        }

                        break;
                    }
                case "BELEMTYPE_CO_EN_ZIJ":
                case "BELEMTYPE_CONST_OVERST":
                    {
                        V = 0; ///-- als A en H geen invoer heeft dan ook geen melding laten zien
                        A = ntaSharedLogic.parseFloat(entData.PropertyDatas['BELEMM_HOR_A_OVERST'].Value);
                        H = ntaSharedLogic.parseFloat(entData.PropertyDatas['BELEMM_HOOGTE_OVERST'].Value);
                        if (!isNaN(A) && !isNaN(B)) {
                            if (A !== 0) {
                                V = parseFloat(H.toFixed(2)) / parseFloat(A.toFixed(2));
                                result = Math.atan(V) / radTodeg;
                                result = Math.round(result);
                            }
                        }
                        newValue = !isNaN(result) ? result.toString() : '';
                        hoekdata = entData.PropertyDatas['BELEMM_HOEK_OVERST'];
                        ntaEntityData.saveprop(hoekdata, newValue);

                        if (V >= 1.0) {
                            if (belemmeringType === "BELEMTYPE_CO_EN_ZIJ") {
                                return "CONST&ZIJ";
                            } else {
                                return "CONST";
                            }
                        }
                        break;
                    }
                case "BELEMTYPE_ZIJ_LINKS":
                    {
                        A = ntaSharedLogic.parseFloat(entData.PropertyDatas['BELEMM_HOR_A_LINKS'].Value);
                        B = ntaSharedLogic.parseFloat(entData.PropertyDatas['BELEMM_HOR_B_LINKS'].Value);
                        if (!isNaN(A) && !isNaN(B)) {
                            if (B !== 0) {
                                V = parseFloat(A.toFixed(2)) / parseFloat(B.toFixed(2));
                                result = Math.atan(V) / radTodeg;
                                result = Math.round(result);
                            }
                        }
                        newValue = !isNaN(result) ? result.toString() : '';
                        hoekdata = entData.PropertyDatas['BELEMM_HOEK_LINKS'];
                        ntaEntityData.saveprop(hoekdata, newValue);
                        if (V >= 3.73) {
                            return "ZIJL";
                        }
                        break;
                    }
                case "BELEMTYPE_ZIJ_RECHTS":
                    {
                        A = ntaSharedLogic.parseFloat(entData.PropertyDatas['BELEMM_HOR_A_RECHTS'].Value);
                        B = ntaSharedLogic.parseFloat(entData.PropertyDatas['BELEMM_HOR_B_RECHTS'].Value);
                        if (!isNaN(A) && !isNaN(B)) {
                            if (B !== 0) {
                                V = parseFloat(A.toFixed(2)) / parseFloat(B.toFixed(2));
                                result = Math.atan(V) / radTodeg;
                                result = Math.round(result);
                            }
                        }
                        newValue = !isNaN(result) ? result.toString() : '';
                        hoekdata = entData.PropertyDatas['BELEMM_HOEK_RECHTS'];
                        ntaEntityData.saveprop(hoekdata, newValue);

                        if (V >= 3.73) {
                            return "ZIJR";
                        }

                        break;
                    }
                case "BELEMTYPE_ZIJ_BEIDE":
                    {
                        //-- VO 2021-10-21: ik kan pas een hoek berekenen als beide waarden zijn ingevuld. Op een value=null kan ik geen replace doen
                        A = ntaSharedLogic.parseFloat(entData.PropertyDatas['BELEMM_HOR_A_LINKS'].Value);
                        B = ntaSharedLogic.parseFloat(entData.PropertyDatas['BELEMM_HOR_B_LINKS'].Value);
                        if (!isNaN(A) && !isNaN(B)) {
                            if (B !== 0) {
                                V = parseFloat(A.toFixed(2)) / parseFloat(B.toFixed(2));
                                result = Math.atan(parseFloat(A.toFixed(2)) / parseFloat(B.toFixed(2))) / radTodeg;
                                result = Math.round(result);
                            }
                        }
                        newValue = !isNaN(result) ? result.toString() : '';
                        hoekdata = entData.PropertyDatas['BELEMM_HOEK_LINKS'];
                        ntaEntityData.saveprop(hoekdata, newValue);
                        const bLerror = V >= 3.73;

                        result = NaN;
                        A = ntaSharedLogic.parseFloat(entData.PropertyDatas['BELEMM_HOR_A_RECHTS'].Value);
                        B = ntaSharedLogic.parseFloat(entData.PropertyDatas['BELEMM_HOR_B_RECHTS'].Value);
                        if (!isNaN(A) && !isNaN(B)) {
                            if (B !== 0) {
                                V = parseFloat(A.toFixed(2)) / parseFloat(B.toFixed(2));
                                result = Math.atan(parseFloat(A.toFixed(2)) / parseFloat(B.toFixed(2))) / radTodeg;
                                result = Math.round(result);
                            }
                        }
                        newValue = !isNaN(result) ? result.toString() : '';
                        hoekdata = entData.PropertyDatas['BELEMM_HOEK_RECHTS'];
                        ntaEntityData.saveprop(hoekdata, newValue);
                        const bRerror = V >= 3.73;

                        if (bRerror) {
                            return "BZIJR";
                        }
                        if (bLerror) {
                            return "BZIJL";
                        }

                        break;
                    }
            }
        }
        return "";
    } //-- end: getCalcHoek ------------------------------------------------------------------------//

    function showBelemmingInput() {
        vm.imgUrlBelemming = "";
        let show = false;

        const belemmeringType = getBelemmeringType();
        if (belemmeringType) {
            switch (belemmeringType) {
                case "BELEMTYPE_CONST_BELEM":
                    vm.imgUrlBelemming = "images/obstruction.jpg";
                    break;
                case "BELEMTYPE_CONST_OVERST":
                case "BELEMTYPE_CO_EN_ZIJ":
                    vm.imgUrlBelemming = "images/overhang.jpg";
                    break;
                case "BELEMTYPE_ZIJ_LINKS":
                    if (isInstallatieBelemmering()) {
                        vm.imgUrlBelemming = "images/PV_side_obstruction_left.jpg";
                    } else {
                        vm.imgUrlBelemming = "images/side_obstruction_left.jpg";
                    }
                    break;
                case "BELEMTYPE_ZIJ_RECHTS":
                    if (isInstallatieBelemmering()) {
                        vm.imgUrlBelemming = "images/PV_side_obstruction_right.jpg";
                    } else {
                        vm.imgUrlBelemming = "images/side_obstruction_right.jpg";
                    }
                    break;
                case "BELEMTYPE_ZIJ_BEIDE":
                    if (isInstallatieBelemmering()) {
                        vm.imgUrlBelemming = "images/PV_side_obstruction_both.jpg";
                    } else {
                        vm.imgUrlBelemming = "images/side_obstruction_both.jpg";
                    }
                    break;
            }

            show = belemmeringType === "BELEMTYPE_CONST_BELEM" ||
                belemmeringType === "BELEMTYPE_CONST_OVERST" ||
                belemmeringType === "BELEMTYPE_CO_EN_ZIJ" ||
                belemmeringType === "BELEMTYPE_ZIJ_BEIDE" ||
                belemmeringType === "BELEMTYPE_ZIJ_LINKS" ||
                belemmeringType === "BELEMTYPE_ZIJ_RECHTS";
        }
        if (vm.belemmeringEntData) {
            ntaEntityData.setEntityRelevancy(vm.belemmeringEntData, show);
            ntaEntityData.setEntityVisibility(vm.belemmeringEntData, show);
        }
        return show;
    } //-- end: showBelemmingInput ------------------------------------------------------------------------//

    function inputpanels() {
        const belemmeringType = getBelemmeringType();

        if (belemmeringType) {
            switch (belemmeringType) {
                case "BELEMTYPE_ZIJ_LINKS":
                    _panels[0].label = "Zijbelemmering links";
                    break;
                case "BELEMTYPE_ZIJ_BEIDE":
                    _panels[0].label = "Zijbelemmering rechts";
                    _panels[1].label = "Zijbelemmering links";
                    break;
                case "BELEMTYPE_ZIJ_RECHTS":
                    _panels[0].label = "Zijbelemmering rechts";
                    break;
                case "BELEMTYPE_CONST_BELEM":
                    _panels[0].label = "Constante belemmering";
                    break;
                case "BELEMTYPE_CONST_OVERST":
                    _panels[0].label = "Constante overstek";
                    break;
                case "BELEMTYPE_CO_EN_ZIJ":
                    _panels[0].label = "Constante overstek & (zij)belemmering";
                    break;
            }
            _panels[1].show = belemmeringType === "BELEMTYPE_ZIJ_BEIDE";
        }
        return _panels;
    } //-- end: inputpanels ------------------------------------------------------------------------//

    function properties(rightColumn) {
        let belemmeringtype = getBelemmeringType();
        if (belemmeringtype === "BELEMTYPE_ZIJ_BEIDE") {
            belemmeringtype = rightColumn ? "BELEMTYPE_ZIJ_LINKS" : "BELEMTYPE_ZIJ_RECHTS";
        }
        const propIds = ntaSharedLogic.getBelemmeringPropertyIds(belemmeringtype);
        return _belemmeringPropsAll.filter(prop => propIds.has(prop.Id));
    } //-- end: properties ------------------------------------------------------------------------//

    function getPropData(prop) {
        if (!prop) {
            return;
        }
        const belemmData = getBelemmeringEntData();
        if (belemmData) {
            return base.getPropData(prop, belemmData);
        } else {
            return prop.DefaultValue;
        }
    } //-- end: getPropData ------------------------------------------------------------------------//

    function saveValue(prop) {
        if (!prop) {
            return;
        }

        const belemmData = getBelemmeringEntData();
        if (belemmData) {
            let sError = "";
            const propdata = prop.getData(belemmData);
            propdata.Touched = true; // opslaan veld is aangeraakt
            vm.validate(prop, propdata);
            if (prop.Id === "BELEMM_HOR_A_BELEM" || prop.Id === "BELEMM_HOR_A_OVERST" || prop.Id === "BELEMM_HOR_A_RECHTS" ||
                prop.Id === "BELEMM_HOR_A_LINKS" || prop.Id === "BELEMM_HOOGTE_BELEM" || prop.Id === "BELEMM_HOOGTE_OVERST" ||
                prop.Id === "BELEMM_HOR_B_RECHTS" || prop.Id === "BELEMM_HOR_B_LINKS") {

                let err = getCalcHoek();
                switch (err) {
                    case "BELEMM":
                        sError = ntabuilding.errors.find(function (x) { return x.Id === "[E017]"; }).Value;
                        break;
                    case "CONST":
                        sError = ntabuilding.errors.find(function (x) { return x.Id === "[E018]"; }).Value;
                        break;
                    case "ZIJL":
                    case "ZIJR":
                        sError = ntabuilding.errors.find(function (x) { return x.Id === "[E019]"; }).Value;
                        break;
                    case "BZIJR":
                        sError = ntabuilding.errors.find(function (x) { return x.Id === "[E021]"; }).Value;
                        break;
                    case "BZIJL":
                        sError = ntabuilding.errors.find(function (x) { return x.Id === "[E020]"; }).Value;
                        break;
                    case "CONST&ZIJ":
                        sError = ntabuilding.errors.find(function (x) { return x.Id === "[E100]"; }).Value;
                        break;
                    default:
                        // in alle andere gevallen de waarden opslaan, zonder dialog vraag
                        ntaEntityData.saveprop(propdata);
                        break;
                }

                if (err !== "") {
                    if (!vm.alertIsVisible) { // Voorkomt dat de foutmelding dubbel wordt getoond
                        vm.alertIsVisible = true;

                        let confirm = $mdDialog.confirm()
                            .title('Beschaduwing aanpassen')
                            .textContent(sError)
                            .ok('OK')
                            .cancel('annuleren');


                        $mdDialog.show(confirm)
                            .then(function () {
                                //afhankelijk van error de juiste beschaduwing zetten en de huidige belemming properties resetten.
                                const entdataParent = ntaEntityData.get(vm.parententitydataid);
                                const propdataParent = entdataParent && entdataParent.PropertyDatas[vm.propertyid];
                                if (propdataParent) {
                                    let newValue = propdataParent.Value;
                                    switch (err) {
                                        case "BELEMM":
                                        case "CONST":
                                        case "ZIJL":
                                        case "ZIJR":
                                        case "BIJRL":
                                        case "CONST&ZIJ":
                                            newValue = "BELEMTYPE_MIN";
                                            break;
                                        case "BZIJR":
                                            newValue = "BELEMTYPE_ZIJ_LINKS";
                                            break;
                                        case "BZIJL":
                                            newValue = "BELEMTYPE_ZIJ_RECHTS";
                                            break;
                                        default:
                                            break;
                                    }
                                    ntaEntityData.saveprop(propdataParent, newValue);
                                    //-- nu nog alle properties af van de huidige belemmering entitie om die te resetten.

                                    // Reset properties links of rechts
                                    switch (propdataParent.Value) {
                                        case "BELEMTYPE_ZIJ_LINKS":
                                            resetBelemmeringRechts();
                                            base.initializeUnitSpacing();
                                            break;
                                        case "BELEMTYPE_ZIJ_RECHTS":
                                            resetBelemmeringLinks();
                                            base.initializeUnitSpacing();
                                            break;
                                        case "BELEMTYPE_MIN":
                                            resetBelemmeringLinks();
                                            resetBelemmeringRechts();
                                            resetBelemmeringOverstek();
                                            reseltBelemmeringConstant();
                                            base.initializeUnitSpacing();
                                            break;
                                    }

                                }
                            }, function () { //annuleren
                                ntaEntityData.saveprop(propdata, ""); //reset    
                                vm.validate(prop, propdata);
                                const input = document.getElementById("ntainput" + propdata.PropertyDataId);
                                input.select();
                            }).finally(function () {
                                confirm = undefined;
                                vm.alertIsVisible = false;
                            });
                    }
                }
            } else {
                ntaEntityData.saveprop(propdata);
            }
        }
    } //-- end: saveValue ------------------------------------------------------------------------//

    function resetBelemmeringRechts() { // Inhoud wissen omdat deze fout was
        let belemHoekRechts = vm.belemmeringEntData.PropertyDatas['BELEMM_HOEK_RECHTS'];
        let belemHoogteRechts = vm.belemmeringEntData.PropertyDatas['BELEMM_HOOGTE_RECHTS'];
        let belemHorARechts = vm.belemmeringEntData.PropertyDatas['BELEMM_HOR_A_RECHTS'];
        let belemHorBRechts = vm.belemmeringEntData.PropertyDatas['BELEMM_HOR_B_RECHTS'];

        const newValue = '';

        ntaEntityData.saveprop(belemHoekRechts, newValue);
        ntaEntityData.saveprop(belemHoogteRechts, newValue);
        ntaEntityData.saveprop(belemHorARechts, newValue);
        ntaEntityData.saveprop(belemHorBRechts, newValue);
    } //-- end: resetBelemmeringRechts ------------------------------------------------------------------------//

    function resetBelemmeringLinks() { // Inhoud wissen omdat deze fout was
        let belemHoekLinks = vm.belemmeringEntData.PropertyDatas['BELEMM_HOEK_LINKS'];
        let belemHoogteLinks = vm.belemmeringEntData.PropertyDatas['BELEMM_HOOGTE_LINKS'];
        let belemHorALinks = vm.belemmeringEntData.PropertyDatas['BELEMM_HOR_A_LINKS'];
        let belemHorBLinks = vm.belemmeringEntData.PropertyDatas['BELEMM_HOR_B_LINKS'];

        const newValue = '';

        ntaEntityData.saveprop(belemHoekLinks, newValue);
        ntaEntityData.saveprop(belemHoogteLinks, newValue);
        ntaEntityData.saveprop(belemHorALinks, newValue);
        ntaEntityData.saveprop(belemHorBLinks, newValue);
    } //-- end: resetBelemmeringLinks ------------------------------------------------------------------------//

    function resetBelemmeringOverstek() {
        let belemHoekOverstek = vm.belemmeringEntData.PropertyDatas['BELEMM_HOEK_OVERST'];
        let belemHoogteOverstek = vm.belemmeringEntData.PropertyDatas['BELEMM_HOOGTE_OVERST'];
        let belemHorAOverstek = vm.belemmeringEntData.PropertyDatas['BELEMM_HOR_A_OVERST'];

        const newValue = '';

        ntaEntityData.saveprop(belemHoekOverstek, newValue);
        ntaEntityData.saveprop(belemHoogteOverstek, newValue);
        ntaEntityData.saveprop(belemHorAOverstek, newValue);
    } //-- end: resetBelemmeringOverstek ------------------------------------------------------------------------//

    function reseltBelemmeringConstant() {
        let belemHoekConstant = vm.belemmeringEntData.PropertyDatas['BELEMM_HOEK_BELEM'];
        let belemHoogteConstant = vm.belemmeringEntData.PropertyDatas['BELEMM_HOOGTE_BELEM'];
        let belemHorAConstant = vm.belemmeringEntData.PropertyDatas['BELEMM_HOR_A_BELEM'];

        const newValue = '';

        ntaEntityData.saveprop(belemHoekConstant, newValue);
        ntaEntityData.saveprop(belemHoogteConstant, newValue);
        ntaEntityData.saveprop(belemHorAConstant, newValue);
    } //-- end: reseltBelemmeringConstant ------------------------------------------------------------------------//

    function getCodedValues(prop) {
        if (!prop) {
            return;
        }

        return ntaValidation.codedValues(prop);
    } //-- end: getCodedValues ------------------------------------------------------------------------//

    function isReadOnly(prop) {
        if (!prop) {
            return true;
        }

        return prop.Id === 'BELEMM_HOEK_BELEM' || prop.Id === 'BELEMM_HOEK_OVERST' || prop.Id === 'BELEMM_HOEK_LINKS' || prop.Id === 'BELEMM_HOEK_RECHTS';
    } //-- end: isReadOnly ------------------------------------------------------------------------//


    function columnsNextToImage() {
        //-- Geef formulieren op waar kolommen onder het plaatje moeten komen
        if (vm.parentformobject.$name === 'ctrl.form_pvsys') {
            return false;
        }

        return true;
    } //-- end: columnsNextToImage ------------------------------------------------------------------------//

}
