﻿/////// BASE CONTROLLER //////////////////////////////////////////////////////////////////////////////////////////////////////
angular.module('projectModule')
    .controller("NTABaseController",
        ['$scope', '$log',  'ntaData', 'ntabuilding', 'ntaMeldingen', 'ntaValidation', 'ntaEntityData', 'settingsMenuData', 'ntaSharedLogic',
function ($scope,   $log,    ntaData,   ntabuilding,   ntaMeldingen,   ntaValidation,   ntaEntityData,   settingsMenuData,   ntaSharedLogic,) {
    'use strict';
    const vm = this;

    /// INTERFACE ////////////////////////////////////////////////////////////
    vm.getName = getName;
    vm.getUnit = getUnit;
    vm.openMenu = openMenu;
    vm.isOpmerking = isOpmerking;
    vm.setGeopend = setGeopend;
    vm.enableTabConfirm = enableTabConfirm;
    vm.getMsgTxt = ntaSharedLogic.getMsgTxt;
    vm.parseFloat = ntaSharedLogic.parseFloat;
    vm.getRelData = ntaSharedLogic.getRelData;
    vm.getPropData = ntaSharedLogic.getPropData;
    vm.getPlaceholder = getPlaceholder;
    vm.initializeUnitSpacing = initializeUnitSpacing;
    vm.getPropertyType = getPropertyType;
    vm.getPropDataAutocomplete = getPropDataAutocomplete;
    vm.getCodedValueName = getCodedValueName;
    vm.getRekenzoneName = getRekenzoneName;
    vm.showWarningPopup = showWarningPopup;
    vm.preventDefault = preventDefault;
    vm.commit = () => ntaData.commit;

    vm.nameSuffixByPropId = new Map();

    /// INITALIZIATION ///////////////////////////////////////////////////////
    setStatusSettings();
    enableTabConfirm();

    angular.element(function () {
        //-- deze timeout omdat eerst angular.element in de overervende controller uitgevoerd moet worden
        setTimeout(() => {
            //-- VO 2021-09-29: alle functies die uitgevoerd moeten worden nadat het formulier van de controller geladen is
            initializeUnitSpacing();
            showWarningPopup();
        })
    });

    /// IMPLEMENTATION ///////////////////////////////////////////////////////

    function getName(prop, includingUnit = true) {
        let name = prop.Name || "";

        if (name && vm.nameSuffixByPropId.has(prop.Id)) {
            name += ' ' + vm.nameSuffixByPropId.get(prop.Id);
        }

        if (name && includingUnit) {
            const unit = getUnit(prop);
            if (unit && !(unit.length > 3 && name.includes(unit))) {
                name += " [" + unit + "]";
            }
        }
        return name;
    } //-- end: getName ------------------------------------------------------------------------//

    function getUnit(prop) {
        return prop && prop.Unit || "";
    } //-- end: getUnit ------------------------------------------------------------------------//

    function getPropertyType(prop) {
        return prop && prop.PropertyType;
    } //-- end: getPropertyType ------------------------------------------------------------------------//

    function getPropDataAutocomplete(prop, entdata) {
        if (!prop) {
            return;
        }
        if (system) {
            var propData = entdata.PropertyDatas[prop.Id];
            if (propData) {
                if (propData.Value) {
                    var cvs = ntaValidation.codedValues(prop);
                    if (cvs && cvs.length > 2) { // is coded value
                        var cvData = cvs.find(function (x) { return x.Id === propData.Value; });
                        var aRes = { Value: cvData };
                        return aRes;
                    }
                }
            }
            return;
        } else {
            return prop.DefaultValue;

        }
    } //-- end: getPropDataAutocomplete ------------------------------------------------------------------------//

    function getPlaceholder(prop, entdata, logic) {
        let placeholderStr = "";
        const bReadOnly = logic.isReadOnly(prop, entdata);
        switch (prop.PropertyType) {
            case 1:
                {
                    placeholderStr = bReadOnly ? "" : "- vul in -";
                    break;
                }
            case 2:
                {
                    if (prop.Domain && prop.Domain.DomainType > 1) {
                        placeholderStr = bReadOnly ? "n.v.t." : "- maak uw keuze -";
                    } else {
                        if (prop.Id.includes("OMSCH") && prop.Name !== 'omschrijving') {
                            placeholderStr = bReadOnly ? "n.v.t." : "- geef omschrijving -";;
                        }
                    }
                    break;
                }
            default:
        }
        return placeholderStr;
    } //-- end: getPlaceholder ------------------------------------------------------------------------//

    function isOpmerking(prop) {
        return !!prop && /_OPM$/.test(prop.Id);
    }

    function initializeUnitSpacing() {
        angular.element(document).ready(function () {
            $('.propertyUnit').hide();

            $('.ntainputfield').on('keypress', function (e) {
                resizespan($(this), 12);
                $(this).siblings('.propertyUnit').show();

            });

            $('.ntainputfield').on('keyup', function (e) {
                if (e.keyCode === 8 || e.keyCode === 46) {
                    resizespan($(this), 8);
                    $(this).siblings('.propertyUnit').show();
                }
            });

            function resizespan(input, offset) {
                var div = input.siblings('.invisibleDiv');
                var unit = input.siblings('.propertyUnit');
                var nospace = ["%", "°C", "°"];
                div.text(input.val());
                if (nospace.includes(unit.text())) {
                    unit.css('left', div.width() + offset);
                }
                else {
                    unit.css('left', div.width() + offset + 2);
                }
            }

            $('.ntainputfield').on('blur', function () {
                resizespan($(this), 8);
                if ($(this).val().length === 0) {
                    $(this).siblings('.propertyUnit').hide();
                }
            });

            $('.ntainputfield').on('focus', function () {
                resizespan($(this), 8);
                $(this).siblings('.propertyUnit').show();
            });

            setTimeout(function () {
                $('.ntainputfield').each(function () {
                    resizespan($(this), 8);
                    if ($(this).val().length > 0) {
                        $(this).siblings('.propertyUnit').show();
                        if ($(this).attr('readonly') === 'readonly') {
                            $(this).siblings('.propertyUnit').css('color', 'rgba(0,0,0,0.54)');
                        }
                    }
                });
            }, 0);
        });
    } //-- end: initializeUnitSpacing ------------------------------------------------------------------------//

    function showWarningPopup() {
        if (ntabuilding && ntabuilding.buildingWarnings && ntabuilding.buildingWarnings.length > 0) {
            ntaMeldingen.warning(ntabuilding.buildingWarnings);
            //-- de buildingWarnings hoeven  maar één keer getoond te worden. Daarna niet meer.
            ntabuilding.buildingWarnings = [];
        }
    } //-- end: showWarningPopup  -----------------------------------------------------------------------------//

    function enableTabConfirm() {
        angular.element(document).ready(function () {
            $('md-option').on('keydown', function (e) {
                if (e.which === 9) {
                    $(this).click();
                }
            });
        });
    } //-- end: enableTabConfirm ------------------------------------------------------------------------//

    function openMenu($mdMenu, ev) {
        $mdMenu.open(ev);

        ev.stopImmediatePropagation();
        ev.preventDefault();
    } //-- end: openMenu ------------------------------------------------------------------------//

    function preventDefault(event) {
        event.stopImmediatePropagation();
        event.preventDefault();
    } //-- end: preventDefault --------------------------------------------------------------------

    function getCodedValueName(item) {
        return item && item.Value || '';
    } //-- end: getCodedValueName ------------------------------------------------------------------------//

    function getRekenzoneName(rz) {
        let name = "";
        if (rz && rz.EntityId === 'RZ') {
            name = rz.PropertyDatas["RZ_OMSCHR"].Value;
        }
        return name;
    } //-- end: getRekenzoneName ------------------------------------------------------------------------//

    function setGeopend(propId, entdata) {
        const propdataOpen = entdata && entdata.PropertyDatas[propId];
        ntaEntityData.saveprop(propdataOpen, 'true');
    } //-- end: setGeopend ------------------------------------------------------------------------//


    function setStatusSettings() {
        settingsMenuData.settings.forEach(function (setting, index) {

            if (setting.PropertyId === 'SETTINGS_THBRUG') {
                const opname = ntaEntityData.getFirstWithEntityId('GEB').PropertyDatas['GEB_OPN'].Value;
                const settingThermischeBruggenForfaitair = settingsMenuData.getSetting('SETTINGS_THBRUG');
                if (opname === 'OPN_BASIS') {
                    settingThermischeBruggenForfaitair.Status = false;
                }
                else {
                    settingThermischeBruggenForfaitair.Status = true;
                }
            }
            else {
                setting.Status = true;
            }
        });
    } //-- end: setStatusSettings ------------------------------------------------------------------------//

    function deleteAORandAOS() {
        // Conditie [AB], verwijder AOR en AOS als 'thermische brug forfaitair'
        if (settingsMenuData.getSetting('SETTINGS_THBRUG').Value === "True") {
            let calczones = ntaEntityData.getListWithEntityId('RZ');
            for (var index = 0; index < calczones.length; index++) {
                let rz = calczones[index].PropertyDatas['RZ_TYPEZ'].Value;
                if (rz && (rz === 'AOR' || rz === 'AOS')) {
                    //-- voor dat ik iets ga deleten moet ik weten waar ik sta in de boom en als ik op de betreffende AOR of zijn vlakken sta, moet ik even ergens anders heen.
                    //-- vooralsnog ga ik altijd ff naar indeling gebouw, omdat ik niet kan achterhalen waar ik zit.
                    ntaSharedLogic.navToIndelingGebouw(null, ntabuilding.projectId, ntabuilding.buildingId);
                    ntaEntityData.delete(calczones[index].EntityDataId);
                }
            }
        }
    } //-- end: deleteAORandAOS ------------------------------------------------------------------------//


    $scope.$watch(function () {
        return settingsMenuData.getSetting('SETTINGS_THBRUG');
    },
        function (newValue, oldValue, scope) {
            if (newValue.Value !== oldValue.Value) {
                deleteAORandAOS();
            }
        },
        true
    );

    setTimeout(function () {
        vm.initializeUnitSpacing();
    });

    window.onbeforeunload = function (event) { //verlaten pagina
        if (!ntabuilding.canSave()) return;
        if ($scope && typeof $scope.endFormValidation === 'function') {
            $scope.endFormValidation();
        }
    };

    vm.$onDestroy = function () { // verlaten form
        if (!ntabuilding.canSave()) return;
        if ($scope && typeof $scope.endFormValidation === 'function') {
            $scope.endFormValidation();
        }
    };

    $scope.$on('callEndFormValidation', function (event, args) {
        if (!ntabuilding.canSave()) return;
        if ($scope && typeof $scope.endFormValidation === 'function') {
            $scope.endFormValidation();
        }
    });

    // TODO: is hier een betere plaats voor?
    $scope.$on('$locationChangeSuccess', function (event, newUrl, oldUrl, newState, oldState) {
        setTimeout(() => updateNavigationLinks(event, newUrl), 0);
        setTimeout(() => scrollToFirstError(event), 1500);
    });
    $scope.$watch('$viewContentLoaded', function () {
        setTimeout(() => updateNavigationLinks(null, location.href), 0);
        setTimeout(() => scrollToFirstError(), 1500);
    });

    function updateNavigationLinks(event, newUrl) {
        const url = new URL(newUrl, location.href);
        const newPath = url.pathname;

        // eerst de bestaande selectedNav verwijderen
        [].forEach.call(document.querySelectorAll(".selectedNav"), link => link.classList.remove("selectedNav"));

        // zoek alle links op die wijzen naar het opgegeven pad; als er geen is, probeer het dan zonder '/' slash aan het eind
        let links = document.querySelectorAll("a[href='" + newPath + "']");
        if (links.length === 0 && newPath.endsWith('/')) {
            links = document.querySelectorAll("a[href='" + newPath.slice(0, -1) + "']");
        }
        if (links.length === 0) {
            // als we de link niet kunnen vinden, zoek dan naar alle links die *beginnen* met het opgegeven pad.
            // het kan voorkomen dat een link op de pagina inclusief id is, maar de URL alleen het formulier bevat.
            links = document.querySelectorAll("a[href^='" + newPath + "']");
            if (links.length === 0 && newPath.endsWith('/')) {
                links = document.querySelectorAll("a[href^='" + newPath.slice(0, -1) + "']");
            }
            if (links.length > 0) {
                $log.warn(`Menu-items met url '${newPath}' niet gevonden, wel '${[].map.call(links, link => link.pathname).join("', '")}'.`);
                if (links.length > 2) {
                    // dan hebben we waarschijnlijk _alle_ links. Selecteer er nu helemaal geen, maar ga naar de eerste.
                    const link = links[0];
                    setTimeout(() => link.click(), 0);
                    return;
                }
            }
        }
        if (links.length > 0) {
            // dan alle gevonden links markeren, en zorgen dat er minstens één zichtbaar is
            let linkIsVisible = false;
            [].forEach.call(links, link => {
                link.classList.add("selectedNav");
                linkIsVisible |= isElementPartiallyInViewport(link);
            });
            if (!linkIsVisible) {
                links[0].scrollIntoView();
            }
        } else if (newUrl.indexOf("Energieprestatie") === -1 && newUrl.indexOf("TOjuli") === -1 && newUrl.indexOf("Overzicht") === -1) {
            $log.error(`Menu-items met url '${newPath}' niet gevonden.`, event);
        }
    } //-- end: updateNavigationLinks ------------------------------------------------------------------------//

    function isElementPartiallyInViewport(el) {
        //-- https://stackoverflow.com/a/26039199/3092116
        const rect = el.getBoundingClientRect();
        //-- DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
        const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
        const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

        //-- http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
        const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
        const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

        return (vertInView && horInView);
    } //-- end: isElementPartiallyInViewport ------------------------------------------------------------------------//

    function scrollToFirstError(event) {
        let invalidInput = document.querySelector('input.ng-invalid');
        let errorPageButton = document.querySelector('paging-bar a.ui-button.invalid');
        if (errorPageButton) {
            const table = errorPageButton.closest('table') || errorPageButton;
            if (invalidInput) {
                const inputTop = getOffsetTop(invalidInput);
                const tableTop = getOffsetTop(table) + table.offsetHeight;
                if (inputTop < tableTop) {
                    errorPageButton = null;
                }
            }
            if (errorPageButton) {
                errorPageButton.click();
                invalidInput = table;
            }
        }
        if (invalidInput && !isElementPartiallyInViewport(invalidInput)) {
            invalidInput.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }
    } //-- end: scrollToFirstError --------------------------------------------------------------//

    function getOffsetTop(element) {
        let distance = 0;
        while (element && element.offsetParent) {
            distance += element.offsetTop;
            element = element.offsetParent;
        }
        return distance;
    } //-- end: getOffsetTop --------------------------------------------------------------------//

}]);
