﻿angular.module('projectModule')
    .service('baseNtaEntityData',
        ['$rootScope', '$log', 'time', '$mdSidenav', 'ntaData', 'NtaEntityDataFactory', 'ntaStorage', 'ntaLocal', 'ntaMeldingen', 'ntaDeltas', 'ntaAlert',
function ($rootScope,   $log,   time,   $mdSidenav,   ntaData,   NtaEntityDataFactory,   ntaStorage,   ntaLocal,   ntaMeldingen,   ntaDeltas,   ntaAlert) {
    'use strict';

    // baseNtaEntityData wordt bijna volledig geïmplementeerd door de NtaEntityDataFactory,
    //  maar kijkt altijd naar de BuildingData in ntaData.current.
    // Code die met een andere BuildingData (zoals ntaData.original) moet werken, kan daarvoor
    //  een eigen NtaEntityDataFactory aanmaken.

    const base = new NtaEntityDataFactory(() => ntaData.current);
    const self = angular.extend(this, base);

    Array.prototype.distinct = function (getKey = item => item) {
        return self.distinct(this, getKey);
    };

    Array.prototype.intersection = function (array) {
        const set = new Set(array);
        const intersection = [];
        for (const item of this) {
            if (set.has(item)) {
                intersection.push(item);
            }
        }
        return intersection;
    };

    Set.prototype.intersection = function (collection) {
        const intersection = new Set();
        for (const item of collection) {
            if (this.has(item)) {
                intersection.add(item);
            }
        }
        return intersection;
    };

    // De storage-events handelen we wel centraal af; anders worden ze meerdere keren verwerkt.
    ntaStorage.on('calculationResults', updateResults);
    ntaStorage.on('CalculationDisconnected', onCalculationDisconnected);

    // Rekenresultaten en meldingen bijwerken
    async function updateResults(results) {
        if (!results) return;

        // Resultaten en meldingen altijd verwerken in berekening, niet in schaduwkopie.
        const buildingData = ntaData.original;

        if (Array.isArray(results.Verwerkt)) {
            // Als de berekening is uitgevoerd, aangeven dat we klaar zijn met rekenen
            const calculationId = ntaData.getBuildingCalculationId(results.BuildingId);
            if (calculationId && results.Verwerkt.includes(calculationId)) {
                ntaData.setBuildingCalculationId(results.BuildingId, null);
            }

            // Alle verwerkte resultaten verwijderen uit localForage.
            await ntaLocal.forgetUpdates(results.BuildingId, results.Verwerkt);
        }

        if (results.Resultaten) {
            // Sneller doorzoekbare set van resultaten maken:
            // van { Resultaten: [ <resultaatPropertyData, zijnde { "EntityDataId": <entityDataId>, "PropertyId": <propertyId>, "Value": <waarde>, etc... }>, <resultaatPropertyData>, ... ] }
            // naar { "<entityDataId>": { "<propertyId>": <resultaatPropertyData>, "<propertyId>": <resultaatPropertyData>, ... }, ... }
            // zodat we de resultaatproperties zo kunnen opvragen: resultEntityProperties[<entityDataId>][<propertyId>]
            const resultEntityProperties = {};
            for (const resultPropData of results.Resultaten) {
                let resultEntityProps = resultEntityProperties[resultPropData.EntityDataId];
                if (!resultEntityProps) {
                    resultEntityProperties[resultPropData.EntityDataId] = resultEntityProps = {};
                }
                resultEntityProps[resultPropData.PropertyId] = resultPropData;
            }
            // Alle buildingData.entdatas aflopen om ze bij te werken. Teller bijhouden zodat we kunnen stoppen zodra we alles hebben afgehandeld
            let resultCount = results.Resultaten.length;
            for (const entdata of buildingData.entdatas) {
                if (entdata.BuildingId !== results.BuildingId) continue;

                const resultEntityProps = resultEntityProperties[entdata.EntityDataId];
                if (!resultEntityProps) continue;

                for (const propertyData of entdata.PropertyDatas) {
                    const resultProp = resultEntityProps[propertyData.PropertyId];
                    if (!resultProp) continue;

                    propertyData.Value = resultProp.Value;

                    resultCount--;
                    if (resultCount <= 0) break;
                }
                if (resultCount <= 0) break;
            }

            // Zorg dat alle eenheden ook weer goed gepositioneerd worden
            const controller = $('.propertyUnit').controller();
            if (controller && typeof controller.initializeUnitSpacing === 'function') {
                setTimeout(controller.initializeUnitSpacing, 100);
            }
        }


        if (Array.isArray(results.Meldingen)) {
            const meldingen = results.Meldingen;

            const lookup = new Set();
            const getLookupKey = function (entdata) {
                return (entdata.PropertyDatas["MELD_SHADOWID"] && entdata.PropertyDatas["MELD_SHADOWID"].Value || null)
                    + '/' + entdata.PropertyDatas["MELD_INPUTID"].Value
                    + '/' + entdata.PropertyDatas["MELD_ERRORID"].Value;
            };

            // alle PropertyDatas ook cachen als property
            for (const entdata of meldingen) {
                for (const propdata of entdata.PropertyDatas) {
                    entdata.PropertyDatas[propdata.PropertyId] = propdata;
                }
                lookup.add(getLookupKey(entdata));
            }

            // eerst alle rekenmeldingen uit onze bestaande data filteren
            for (const meldingED of buildingData.getListWithEntityId("MELDING", results.BuildingId, true)) {
                const propdataErrorId = meldingED.PropertyDatas["MELD_ERRORID"];
                const isCalcError = propdataErrorId && typeof propdataErrorId.Value === "string" && propdataErrorId.Value.startsWith("R");
                const hasNewError = lookup.has(getLookupKey(meldingED)); // controleer of deze melding ook voorkomt in de nieuwe meldingen
                if (isCalcError || hasNewError) {
                    buildingData.removeEntdata(meldingED.EntityDataId);
                }
            }

            const ntaEntityDataByShadowId = new Map(); // <-- deze wordt gebruikt door getOrCreateNtaEntityDataForShadowId verderop, maar moet al bestaan voordat die functie aangeroepen wordt
            if (meldingen.length > 0) {
                const ntaEntityDataOriginal = getOrCreateNtaEntityDataForShadowId(null);

                for (const melding of meldingen) {
                    buildingData.addEntdata(melding);
                    // Zorg dat elke melding een MELD_URL en een MELD_FORMID heeft (tenzij we in een versie zitten waar die props niet voorkomen).
                    const propdataUrl = melding.PropertyDatas['MELD_URL'];
                    const propdataFormId = melding.PropertyDatas['MELD_FORMID'];
                    if (propdataUrl && propdataFormId && (!propdataUrl.Value || !propdataFormId.Value)) {
                        const inputId = melding.PropertyDatas['MELD_INPUTID'].Value;
                        const shadowId = melding.PropertyDatas['MELD_SHADOWID'] && melding.PropertyDatas['MELD_SHADOWID'].Value || null;
                        const ntaEntityData = getOrCreateNtaEntityDataForShadowId(shadowId);
                        const [url, formId] = ntaMeldingen.getInputUrlAndFormId(inputId, shadowId, ntaEntityData);
                        ntaEntityDataOriginal.saveprop(propdataUrl, url);
                        ntaEntityDataOriginal.saveprop(propdataFormId, formId);
                    }
                }

                $mdSidenav('right').open();
                time.delayActions(() => ntaEntityDataOriginal.setCalculationNeeded(results.BuildingId, true));
            }

            function getOrCreateNtaEntityDataForShadowId(shadowId) {
                let ntaEntityData = ntaEntityDataByShadowId.get(shadowId);
                if (!ntaEntityData) {
                    const buildingData = ntaDeltas.getShadowBuildingData(shadowId);
                    ntaEntityData = new NtaEntityDataFactory(() => buildingData);
                    ntaEntityDataByShadowId.set(shadowId, ntaEntityData);
                }
                return ntaEntityData;
            } //-- end: getOrCreateNtaEntityDataForShadowId -------------------
        }

        //doorgeven dat de resultaten zijn verwerkt
        $rootScope.$broadcast('updateResults', results);

    } //-- end: updateResults ---------------------------------------------------------------------

    async function onCalculationDisconnected(buildingId, calculationId) {
        // Dit geeft aan dat de SignalR-verbinding ff uit de lucht was toen de resultaten teruggestuurd zijn;
        //  dus gaan we zelf alle resultaten opvragen en deze verwerken. Maar alleen als er niet intussen
        //  een nieuwe berekening gestart is!
        if (ntaData.buildingId === buildingId && ntaData.getBuildingCalculationId(buildingId) === calculationId && ntaData.canSaveBuilding(buildingId)) {
            try {
                const resultSets = await ntaStorage.getCalculationResults(buildingId, calculationId);
                // we krijgen de resultaten per variant terug
                for (const results of resultSets) {
                    updateResults(results);
                }
            } catch (err) {
                $log.error('Building', buildingId, ': calculation disconnected (', calculationId, '): ', err);
                await ntaAlert.showNetworkError(); // herlaadt de pagina als de dialoog gesloten is.
            }
        }
    } //-- end: onCalculationDisconnected ---------------------------------------------------------

}]); //== end: baseNtaEntityData ==================================================================


angular.module('projectModule')
    .service('ntaEntityData',
        ['baseNtaEntityData', 'ntabuilding', 'ntaData', 'ntaValidation',
function (baseNtaEntityData,   ntabuilding,   ntaData,   ntaValidation) {
    'use strict';

    angular.extend(this, baseNtaEntityData);

    // We overschrijven validatePropdatas volledig, omdat deze intern aangeroepen wordt binnen baseNtaEntityData.
    baseNtaEntityData.validatePropdatas = function (propdatas) {
        for (const propdata of propdatas.filter(pd => pd.BuildingId === ntabuilding.buildingId)) {
            const prop = ntaData.properties[propdata.PropertyId];
            ntaValidation.IsValid(null, prop, propdata);
        }
    };

    // Zorg dat ntaEntityData.create geen buildingId als eerste parameter nodig heeft,
    //  en ook geen expliciete buildingId voor getFirstWithEntityId of getListWithEntityId.
    this.configureForSingleBuilding(() => ntabuilding.buildingId);

}]); //== end: ntaEntityData ======================================================================


angular.module('projectModule')
    .service('ntaEntityDataOrg',
        ['NtaEntityDataFactory', 'ntabuilding', 'ntaData', 'ntaValidation',
function (NtaEntityDataFactory,   ntabuilding,   ntaData,   ntaValidation) {
    'use strict';

    const base = new NtaEntityDataFactory();
    const self = angular.extend(this, base);

    // We overschrijven validatePropdatas volledig, omdat deze intern aangeroepen wordt binnen base.
    base.validatePropdatas = function (propdatas) {
        for (const propdata of propdatas.filter(pd => pd.BuildingId === ntabuilding.buildingId)) {
            const prop = ntaData.properties[propdata.PropertyId];
            ntaValidation.IsValid(null, prop, propdata);
        }
    };

    // Zorg dat ntaEntityDataOrg.create geen buildingId als eerste parameter nodig heeft,
    //  en ook geen expliciete buildingId voor getFirstWithEntityId of getListWithEntityId.
    this.configureForSingleBuilding(() => ntabuilding.buildingId);

}]); //== end: ntaEntityDataOrg ===================================================================
