﻿angular.module('projectModule')
    .factory('GebruikersprofielFactory',
        ['$log', 'ntaData', 'ntabuilding', 'ntaValidation', 'ntaEntityDataOrg', 'ntaSharedLogic', 'ntaMeldingen', 'ntaCalcValidation', 'ListCache',
function ($log,   ntaData,   ntabuilding,   ntaValidation,   ntaEntityDataOrg,   ntaSharedLogic,   ntaMeldingen,   ntaCalcValidation,   ListCache) {
    'use strict';

    //== Static data ==============================================================================

    const _invoerPropIdsMetEigenWaarde = new Set([
        'GEBR-PROFIEL_INTWARMTE',
        'GEBR-PROFIEL_INTWARMTE_APP',
        'GEBR-PROFIEL_VERW_SETPOINT',
        'GEBR-PROFIEL_VERW_VERLAGING',
        'GEBR-PROFIEL_VERW_FMATIG',
        'GEBR-PROFIEL_KOEL_SETPOINT',
        'GEBR-PROFIEL_TAPW_BEH',
        'GEBR-PROFIEL_VERL_INV_BRUPJ',
    ]);

    const _table13_1 = new Map(Object.entries({
        GF_BIJEENKIND:  2.8,
        GF_BIJEENOVER:  2.8,
        GF_CELF:        4.2,
        GF_GEZONDBED:  15.3,
        GF_GEZONDOVER:  2.8,
        GF_KANT:        1.4,
        GF_LOGIES:     12.5,
        GF_ONDERW:      1.4,
        GF_SPORT:      12.5,
        GF_WINKEL:      1.4,
    }));

    const _table14_1 = new Map(Object.entries({
        'GF_BIJEENKIND':    { tD: 2200, tN:  300 },
        'GF_BIJEENOVER':    { tD: 2200, tN:  300 },
        'GF_CELF':          { tD: 4000, tN: 1000 },
        'GF_GEZONDBED':     { tD: 4000, tN: 1000 },
        'GF_GEZONDOVER':    { tD: 2200, tN:  300 },
        'GF_KANT':          { tD: 2200, tN:  300 },
        'GF_LOGIES':        { tD: 4000, tN: 1000 },
        'GF_ONDERW':        { tD: 1600, tN:  300 },
        'GF_SPORT':         { tD: 2200, tN:  800 },
        'GF_WINKEL':        { tD: 2700, tN:  400 },
    }));

    // Per profielveld voor verwarming, wordt hieronder opgegeven wat de in te vullen waarde is voor de RZ-specifieke velden bij elk gekozen profiel.
    const _verwarmingForfaitair = new Map(Object.entries({
        'GEBR-PROFIEL_VERW_SETPOINT': {                 // MW27 <- op basis van de keuze in dit veld (of in MW04 GBR-PROFIEL_STD)...
            'GEBR-RZ-VERW_THINTSET_NON': {              // MW33 <- ...wordt in dit veld onderstaande waarde ingevuld
                'GF_WONING': {
                    'PROFIEL_BEWUST':       18,
                    'PROFIEL_STD':          20,
                    'PROFIEL_NIET_BEWUST':  22,
                },
                'GF_GEZONDBED': {
                    'PROFIEL_BEWUST':       20,
                    'PROFIEL_STD':          22,
                    'PROFIEL_NIET_BEWUST':  24,
                },
                'GF_SPORT': {
                    'PROFIEL_BEWUST':       14,
                    'PROFIEL_STD':          16,
                    'PROFIEL_NIET_BEWUST':  18,
                },
                '': { // overige gebruiksfuncties
                    'PROFIEL_BEWUST':       19,
                    'PROFIEL_STD':          21,
                    'PROFIEL_NIET_BEWUST':  23,
                },
            },
            'GEBR-RZ-VERW_THINTSET_LDAY_NON': {         // MW35
                'GF_SPORT': {
                    'PROFIEL_BEWUST':       12,
                    'PROFIEL_STD':          14,
                    'PROFIEL_NIET_BEWUST':  16,
                },
                '': { // overige gebruiksfuncties (dus ook GF_WONING)
                    'PROFIEL_BEWUST':       14,
                    'PROFIEL_STD':          16,
                    'PROFIEL_NIET_BEWUST':  18,
                },
            },
            'GEBR-RZ-VERW_THINTSET_LWKND_NON': {        // MW39
                'GF_SPORT': {
                    'PROFIEL_BEWUST':       12,
                    'PROFIEL_STD':          14,
                    'PROFIEL_NIET_BEWUST':  16,
                },
                '': { // overige gebruiksfuncties (dus ook GF_WONING)
                    'PROFIEL_BEWUST':       14,
                    'PROFIEL_STD':          16,
                    'PROFIEL_NIET_BEWUST':  18,
                },
            },
        },
        'GEBR-PROFIEL_VERW_VERLAGING': {                // MW28
            'GEBR-RZ-VERW_TRED_DAY_NON': {              // MW37
                'GF_WONING': {
                    'PROFIEL_BEWUST':       12,
                    'PROFIEL_STD':          10,
                    'PROFIEL_NIET_BEWUST':   8,
                },
                'GF_CELF': {
                    'PROFIEL_BEWUST':       10,
                    'PROFIEL_STD':           8,
                    'PROFIEL_NIET_BEWUST':   6,
                },
                'GF_GEZONDBED': {
                    'PROFIEL_BEWUST':       10,
                    'PROFIEL_STD':           8,
                    'PROFIEL_NIET_BEWUST':   6,
                },
                'GF_LOGIES': {
                    'PROFIEL_BEWUST':       15,
                    'PROFIEL_STD':          13,
                    'PROFIEL_NIET_BEWUST':  11,
                },
                'GF_WINKEL': {
                    'PROFIEL_BEWUST':       15,
                    'PROFIEL_STD':          13,
                    'PROFIEL_NIET_BEWUST':  11,
                },
                '': { // overige gebruiksfuncties
                    'PROFIEL_BEWUST':       16,
                    'PROFIEL_STD':          14,
                    'PROFIEL_NIET_BEWUST':  12,
                },
            },
            'GEBR-RZ-VERW_TRED_WKND_NON': {             // MW41
                'GF_WONING': {
                    'PROFIEL_BEWUST':        8,
                    'PROFIEL_STD':           0,
                    'PROFIEL_NIET_BEWUST':   0,
                },
                'GF_CELF': {
                    'PROFIEL_BEWUST':       24,
                    'PROFIEL_STD':           0,
                    'PROFIEL_NIET_BEWUST':   0,
                },
                'GF_GEZONDBED': {
                    'PROFIEL_BEWUST':       24,
                    'PROFIEL_STD':           0,
                    'PROFIEL_NIET_BEWUST':   0,
                },
                'GF_LOGIES': {
                    'PROFIEL_BEWUST':       48,
                    'PROFIEL_STD':          24,
                    'PROFIEL_NIET_BEWUST':   0,
                },
                'GF_WINKEL': {
                    'PROFIEL_BEWUST':       48,
                    'PROFIEL_STD':          24,
                    'PROFIEL_NIET_BEWUST':   0,
                },
                '': { // overige gebruiksfuncties
                    'PROFIEL_BEWUST':       72,
                    'PROFIEL_STD':          48,
                    'PROFIEL_NIET_BEWUST':  24,
                },
            },
        },
        'GEBR-PROFIEL_VERW_FMATIG': {                   // MW30
            'GEBR-RZ-VERW_FMOD_SP_NON': {               // MW43
                'GF_WONING': {
                    'PROFIEL_BEWUST':        0.8,
                    'PROFIEL_STD':           0.6,
                    'PROFIEL_NIET_BEWUST':   0,
                },
            },
        },
    }));

    return function GebruikersprofielLogic(ntaDependencyValidation) {
        const self = this;

        //== Imports ==============================================================================
        self.ntaValidation = ntaValidation;
        self.dependencyValidator = ntaDependencyValidation;

        //== Instance data ========================================================================

        const _propertyId = 'GEBR-PROFIEL';
        const _entdataGebruikersprofiel = ntaEntityDataOrg.getFirstWithEntityId(_propertyId)
            || ntaSharedLogic.showCosts() && ntaEntityDataOrg.get(ntaEntityDataOrg.create(_propertyId)) || null;
        const _entdataEnergierekening = ntaEntityDataOrg.getFirstWithEntityId('ENER-REK')
            || ntaSharedLogic.showCosts() && ntaEntityDataOrg.get(ntaEntityDataOrg.create('ENER-REK')) || null;

        const _listCache = new ListCache();


        //== Exports ==============================================================================
        self.entdataGebruikersprofiel = _entdataGebruikersprofiel;
        self.properties = ntaData.properties[_propertyId];

        // al deze methods exporteren zodat ze publiek beschikbaar zijn
        Object.assign(self, {
            getPropData,
            isHidden,
            isReadOnly,
            hasCodedValues,
            getCodedValues,
            getListItem,
            saveValue,
            validate,
            validateDependencies,
            startFormValidation,
            endFormValidation,
            setGeopend,
            onValidateCallback,
        });

        //== Initialization =======================================================================

        //check of entiteiten meer dan 1x vorkomen (en evt. overbodige verwijderen)
        for (const entiteit of [_entdataGebruikersprofiel, _entdataEnergierekening]) {
            if (entiteit) {
                const entityList = ntaEntityDataOrg.getListWithEntityId(entiteit.EntityId);
                if (entityList.length > 1) {
                    for (const entdata of entityList.filter(ed => ed !== entiteit)) {
                        ntaEntityDataOrg.delete(entdata.EntityDataId);
                    }
                }
            }
        }

        if (_entdataGebruikersprofiel) {
            for (const zone of ntaEntityDataOrg.getListWithEntityId('RZ')) {
                ntaDependencyValidation.checkGebruikersprofielZone(zone);
            }
            const parentRels = [
                { OnDelete: true, OnCopy: true, Parent: _entdataGebruikersprofiel.EntityDataId, ParentEntityId: _entdataGebruikersprofiel.EntityId },
            ];
            for (const ventSysteem of ntaEntityDataOrg.getListWithEntityId('VENT').filter(ventED => ntaEntityDataOrg.getChildren(ventED, 'GEBR-VENT').length === 0)) {
                parentRels[1] = { OnDelete: true, OnCopy: true, Parent: ventSysteem.EntityDataId, ParentEntityId: ventSysteem.EntityId };
                const fPrac = ntaCalcValidation.getFPracVentForfaitair(ventSysteem.PropertyDatas['VENT_SYS'].Value);
                ntaEntityDataOrg.create('GEBR-VENT', -1, parentRels, [], [{ PropertyId: 'GEBR-VENT_FPRAC_NON', Value: fPrac }]);
            }
        }

        //== Implementation =======================================================================

        function getWarmtebehoefteForfaitair(profiel) {
            return {
                'PROFIEL_BEWUST':       409,
                'PROFIEL_STD':          545,
                'PROFIEL_NIET_BEWUST':  681,
            }[profiel];
        } //-- end: getWarmtebehoefteForfaitair ---------------------------------------------------

        function getSetpointKoelingForfaitair(invoerSetpointKoeling) {
            return {
                'PROFIEL_BEWUST':       26,
                'PROFIEL_STD':          24,
                'PROFIEL_NIET_BEWUST':  22,
            }[invoerSetpointKoeling];
        } //-- end: getSetpointKoelingForfaitair --------------------------------------------------

        function getProfielfactor(profiel) {
            return {
                'PROFIEL_BEWUST':       0.8,
                'PROFIEL_STD':          1.0,
                'PROFIEL_NIET_BEWUST':  1.2,
            }[profiel];
        } //-- end: getProfielfactor --------------------------------------------------------------

        function getProfielWpp(profiel) {
            return {
                'PROFIEL_BEWUST': 152,
                'PROFIEL_STD': 180,
                'PROFIEL_NIET_BEWUST': 208,
            }[profiel];
        } //-- end: getProfielfactor --------------------------------------------------------------

        function getPropData(prop, entdata = _entdataGebruikersprofiel) {
            if (!prop || !entdata) {
                return;
            }

            switch (prop.Id) {
                case 'RZ_OMSCHR': {
                    entdata = ntaEntityDataOrg.getFirstParent(entdata, 'RZ');
                    break;
                }
                case 'UNIT-RZ-GFID': {
                    entdata = ntaEntityDataOrg.getFirstParent(entdata, 'UNIT-RZ-GF');
                    break;
                }
                case 'INSTALL_NAAM': {
                    entdata = ntaEntityDataOrg.findEntity(entdata, '^VENT.^INSTALLATIE');
                    break;
                }
            }

            const propdata = ntaSharedLogic.getPropData(prop, entdata);
            return propdata;
        } //-- end: getPropData -------------------------------------------------------------------

        function isReadOnly(prop) {
            if (!prop)
                return true;

            switch (prop.Id) {
                case 'GEBR-PROFIEL_OPEN':
                case 'RZ_OMSCHR':
                case 'UNIT-RZ-GFID':
                case 'INSTALL_NAAM':
                case 'GEBR-RZ_AANTAL_NORM':
                case 'GEBR-RZ-BEZF_NGEBGEB-E':
                case 'GEBR-RZ-WARMTEPROD_NGEBGEB-E':
                    return true;

                default:
                    if (prop.Id.endsWith('_NON')) {
                        return true;
                    }
                    break;
            }

            return false;
        } //-- end: isReadOnly --------------------------------------------------------------------

        function isHidden(prop, entdata = _entdataGebruikersprofiel) {
            if (typeof prop === 'string') prop = ntaData.properties[prop];
            if (!prop) {
                return true;
            }

            // LET OP: als entdata === null, dan gaat het om de hele kolom
            const propdata = entdata && getPropData(prop, entdata);
            if (entdata && !propdata) {
                return true;
            }

            let visible = true;
            let relevant = null; // null = zelfde als visible

            if (!ntaSharedLogic.showCosts()) {
                visible = false;
            } else {
                switch (prop.Id) {
                    case 'UNIT-RZ-GFID': {
                        /// bij woningbouw is UNIT-RZ-GFID niet zichtbaar. Altijd woningbouw. Dus visible false. Anders komt er een spookmelding bij Indeling gebouw.
                        visible = !ntaSharedLogic.isUtiliteit() ? false : true;
                        relevant = true;
                        break;
                    }
                    case 'GEBR-PROFIEL_OPEN': {
                        visible = false;
                        break;
                    }
                    case 'GEBR-PROFIEL_STD': {              // MW04
                        // [MW-A] toon als MW03 = overal hetzelfde gebruikersprofiel toepassen
                        visible = conditionA();
                        break;
                    }
                    case 'GEBR-PROFIEL_RZ_TABEL':           // MW05
                    case 'GEBR-RZ_AANTAL_NORM':             // MW76
                    case 'GEBR-RZ_AANTAL_WERKELIJK': {      // MW06
                        visible = !ntaSharedLogic.isUtiliteit();
                        break;
                    }
                    case 'GEBR-PROFIEL_INTWARMTE': {        // MW08
                        visible = !ntaSharedLogic.isUtiliteit() && conditionZ();
                        break;
                    }
                    case 'GEBR-PROFIEL_INTWARMTE_PERS': {   // MW09
                        visible = ntaSharedLogic.isUtiliteit() && conditionZ();
                        break;
                    }
                    case 'GEBR-PROFIEL_INTWARMTE_APP': {    // MW10
                        visible = ntaSharedLogic.isUtiliteit() && conditionZ();
                        break;
                    }
                    case 'GEBR-RZ-BEZF_CORRF':              // MW14
                    case 'GEBR-RZ-BEZF_FEGEBR': {           // MW15
                        visible = !ntaSharedLogic.isUtiliteit() && conditionAR();
                        break;
                    }
                    case 'GEBR-RZ-BEZF_WPPP_NON': {         // MW84
                        visible = !ntaSharedLogic.isUtiliteit() && conditionAP();
                        break;
                    }
                    case 'GEBR-RZ-BEZF_WPPP': {             // MW85
                        visible = !ntaSharedLogic.isUtiliteit() && conditionAQ();
                        break;
                    }
                    case 'GEBR-RZ-BEZF_NGEBGEB-E': {        // MW86, MW87
                        visible = !ntaSharedLogic.isUtiliteit() && conditionAS();
                        break;
                    }
                    case 'GEBR-PROFIEL_BEZF_TABEL': {       // MW12
                        visible = !ntaSharedLogic.isUtiliteit();
                        break;
                    }
                    case 'GEBR-PROFIEL_WARMTEPROD_TABEL': { // MW16
                        visible = ntaSharedLogic.isUtiliteit() && conditionB();
                        break;
                    }
                    case 'GEBR-RZ-WARMTEPROD_FEGEBR': {     // MW22
                        visible = ntaSharedLogic.isUtiliteit() && conditionG();
                        break;
                    }
                    case 'GEBR-RZ-WARMTEPROD_WLAPP': {      // MW23
                        visible = ntaSharedLogic.isUtiliteit() && conditionH();
                        break;
                    }
                    case 'GEBR-RZ-WARMTEPROD_NGEBGEB-E': {  // MW88, MW89
                        visible = ntaSharedLogic.isUtiliteit() && conditionAS();
                        break;
                    }
                    case 'GEBR-RZ-WARMTEPROD_GF':               // MW18
                    case 'GEBR-RZ-WARMTEPROD-GF_GEMPERS':       // MW19
                    case 'GEBR-RZ-WARMTEPROD-GF_CFBEZETTIJD': { // MW20
                        visible = ntaSharedLogic.isUtiliteit() && conditionF();
                        break;
                    }
                    case 'GEBR-RZ-WARMTEPROD-GF_GEMWPROD': {    // MW21
                        visible = ntaSharedLogic.isUtiliteit() && conditionI();
                        break;
                    }
                    case 'GEBR-PROFIEL_VERW_FMATIG': {      // MW30
                        visible = !conditionAM(entdata);
                        break;
                    }
                    case 'GEBR-PROFIEL_VERW_SETPOINT':      // MW27
                    case 'GEBR-PROFIEL_VERW_VERLAGING':     // MW28
                    case 'GEBR-PROFIEL_KOEL_SETPOINT':      // MW46
                    case 'GEBR-PROFIEL_TAPW_BEH':           // MW57
                        visible = conditionZ();
                        break;
                    case 'GEBR-RZ-VERW_THINTSET_NON':       // MW33
                    case 'GEBR-RZ-VERW_THINTSET_LDAY_NON':  // MW35
                    case 'GEBR-RZ-VERW_THINTSET_LWKND_NON': // MW39
                        visible = conditionJ();
                        break;
                    case 'GEBR-RZ-VERW_THINTSET':           // MW34
                    case 'GEBR-RZ-VERW_THINTSET_LDAY':      // MW36
                    case 'GEBR-RZ-VERW_THINTSET_LWKND':     // MW40
                        visible = conditionK();
                        break;
                    case 'GEBR-RZ-VERW_TRED_DAY_NON':       // MW37
                    case 'GEBR-RZ-VERW_TRED_WKND_NON': {    // MW41
                        visible = conditionL();
                        break;
                    }
                    case 'GEBR-RZ-VERW_TRED_DAY':           // MW38
                    case 'GEBR-RZ-VERW_TRED_WKND': {        // MW42
                        visible = conditionM();
                        break;
                    }
                    case 'GEBR-RZ-VERW_FMOD_SP_NON': {      // MW43
                        visible = !ntaSharedLogic.isUtiliteit() && conditionP();
                        relevant = false; //- wordt altijd door rekenkern bepaald
                        break;
                    }
                    case 'GEBR-RZ-VERW_FMOD_SP': {          // MW44
                        visible = conditionQ();
                        break;
                    }
                    case 'GEBR-RZ-KOEL_THINTSET_NON': {     // MW49
                        visible = conditionS();
                        break;
                    }
                    case 'GEBR-RZ-KOEL_THINTSET': {         // MW50
                        visible = conditionT();
                        break;
                    }
                    case 'GEBR-VENT_FPRAC_NON': {           // MW55
                        visible = conditionAG();
                        break;
                    }
                    case 'GEBR-RZ-TAPW_QWND_SPECP_NON': {   // MW58
                        visible = !ntaSharedLogic.isUtiliteit() && conditionU();
                        break;
                    }
                    case 'GEBR-RZ-TAPW_QWND_SPECP': {       // MW59
                        visible = !ntaSharedLogic.isUtiliteit() && conditionV();
                        break;
                    }
                    case 'GEBR-RZ-TAPW_QWND_SPEC': {        // MW60
                        visible = ntaSharedLogic.isUtiliteit() && conditionW();
                        break;
                    }
                    case 'GEBR-PROFIEL_VERL_INV_BRUPJ': {   // MW63
                        visible = ntaSharedLogic.isUtiliteit() && conditionZ();
                        break;
                    }
                    case 'GEBR-PROFIEL_VERL_RZ_TABEL': {    // MW65
                        visible = ntaSharedLogic.isUtiliteit();
                        break;
                    }
                    case 'GEBR-RZ-VERL_BRUDAG_NON':         // MW68
                    case 'GEBR-RZ-VERL_BRUAVNA_NON': {      // MW70
                        visible = ntaSharedLogic.isUtiliteit() && conditionAA();
                        break;
                    }
                    case 'GEBR-RZ-VERL_BRUDAG':             // MW69
                    case 'GEBR-RZ-VERL_BRUAVNA': {          // MW71
                        visible = ntaSharedLogic.isUtiliteit() && conditionAB();
                        break;
                    }
                    case 'GEBR-VENT_FPRAC': {               // MW77
                        visible = conditionAH();
                        break;
                    }
                    case 'GEBR-VENT_INBLAASTEMP': {         // MW78
                        visible = conditionAI();
                        break;
                    }
                    case 'GEBR-RZ-TAPW_QWND_SPEC_NON': {    // MW83
                        visible = ntaSharedLogic.isUtiliteit() && conditionU();
                        break;
                    }
                }
            }

            if (propdata) {
                if (relevant === null) relevant = visible;
                ntaEntityDataOrg.setPropdataStatus(propdata, relevant, visible);
            }
            return !visible;
        } //-- end: isHidden ----------------------------------------------------------------------

        function getProfiel(profielPropId) {
            /// Deze functie geeft het gekozen profiel van de opgegeven property terug, tenzij
            ///  wanneer overal hetzelfde gebruikersprofiel toegepast moet worden; dan geeft-ie
            ///  het gekozen standaard profiel terug.
            const stdProfielPropId = 'GEBR-PROFIEL_STD'; // MW04
            const propId = isHidden(profielPropId) ? stdProfielPropId : profielPropId;
            const propdata = _entdataGebruikersprofiel.PropertyDatas[propId];
            return propdata && propdata.Value || null;
        } //-- end: getProfiel --------------------------------------------------------------------

        function isEigenWaarde(profiel) {
            return profiel === 'PROFIEL_EIGEN_WAARDE';
        } //-- end: isEigenWaarde -----------------------------------------------------------------

        function conditionA() {
            // [MW-A] toon als MW03 = overal hetzelfde gebruikersprofiel toepassen
            return _entdataGebruikersprofiel.PropertyDatas['GEBR-PROFIEL_INV'].Value === 'PROFIEL_OVERAL_ZELFDE';
        }//-- end: conditionA --------------------------------------------------------------------------------//

        function conditionB() {
            // [MW-B] indien le3-2: toon tabel als MW09= invoer aantal personen - 80 W pp / invoer aantal personen - eigen waarde pp
            //                      EN/OF MW10 ≠ gebruikersprofiel energiebewust/standaard/niet-energiebewust
            //        indien ge3-3: verberg tabel als MW09 = gebruikersprofiel energiebewust/standaard/niet-energiebewust
            //                      EN MW10 = gebruikersprofiel energiebewust/standaard/niet-energiebewust
            //                      EN in dialogue box ngg e-verbruik ≠ 'berekenen a.d.h.v. interne warmteproductie'
            const gebruikersprofielen = new Set(['PROFIEL_BEWUST', 'PROFIEL_STD', 'PROFIEL_NIET_BEWUST']);
            const intWarmteApp = getProfiel('GEBR-PROFIEL_INTWARMTE_APP'); // MW10

            let visible;
            if (ntaData.ntaVersion.ntaVersionId >= 300) {
                const intWarmtePers = getProfiel('GEBR-PROFIEL_INTWARMTE_PERS'); // MW09
                const methodeNGGE = ntaEntityDataOrg.getFirstWithEntityId('NGEBGEB-E')?.PropertyDatas['NGEBGEB-E_METHODE']?.Value;
                const hidden = gebruikersprofielen.has(intWarmtePers)
                    && gebruikersprofielen.has(intWarmteApp)
                    && methodeNGGE !== 'NGEBGEB-E_INTERNE_WARMTE';
                visible = !hidden;
            } else {
                visible = conditionF() || (intWarmteApp && !gebruikersprofielen.has(intWarmteApp));
            }
            return visible;
        } //-- end: conditionB --------------------------------------------------------------------

        function conditionF() {
            // [MW-F] toon als MW09= invoer aantal personen - 80 W pp / invoer aantal personen - eigen waarde pp
            const intWarmtePers = getProfiel('GEBR-PROFIEL_INTWARMTE_PERS'); // MW09
            return intWarmtePers === 'PROFIEL_AANT_PERS_80W' || intWarmtePers === 'PROFIEL_AANT_PERS_EIG';
        } //-- end: conditionF --------------------------------------------------------------------

        function conditionG() {
            //[MW-G] toon als MW10=warmtelast o.b.v. energiegebruik
            const intWarmteApp = getProfiel('GEBR-PROFIEL_INTWARMTE_APP'); // MW10
            return intWarmteApp === 'PROFIEL_WARMTE_OBV_GEBR';
        } //-- end: conditionG --------------------------------------------------------------------

        function conditionH() {
            //[MW-H] toon als MW10=eigen waarde
            return isEigenWaarde(getProfiel('GEBR-PROFIEL_INTWARMTE_APP')); // MW10
        } //-- end: conditionH --------------------------------------------------------------------

        function conditionI() {
            // [MW-I] toon als MW09= invoer aantal personen - eigen waarde pp
            const intWarmtePers = getProfiel('GEBR-PROFIEL_INTWARMTE_PERS'); // MW09
            return intWarmtePers === 'PROFIEL_AANT_PERS_EIG';
        } //-- end: conditionF --------------------------------------------------------------------

        function conditionJ() {
            // [MW-J] verberg als MW04=gebruikersprofiel met eigen waarde OF MW27 = eigen waarde
            return !conditionK();
        } //-- end: conditionJ --------------------------------------------------------------------

        function conditionK() {
            // [MW-K] toon als MW04=gebruikersprofiel met eigen waarde OF als MW27 = eigen waarde
            const visible = isEigenWaarde(getProfiel('GEBR-PROFIEL_VERW_SETPOINT')); // MW27
            return visible;
        } //-- end: conditionK --------------------------------------------------------------------

        function conditionL() {
            // [MW-L] verberg als MW04=gebruikersprofiel met eigen waarde OF MW28 = eigen waarde
            return !conditionM();
        } //-- end: conditionL --------------------------------------------------------------------

        function conditionM() {
            // [MW-M] toon als MW04=gebruikersprofiel met eigen waarde OF als MW28 = eigen waarde
            const visible = isEigenWaarde(getProfiel('GEBR-PROFIEL_VERW_VERLAGING')); // MW28
            return visible;
        } //-- end: conditionM --------------------------------------------------------------------

        function conditionP() {
            // [MW-P] verberg als MW30 = fractie matig verwarmd eigen invoer
            const hidden = isEigenWaarde(getProfiel('GEBR-PROFIEL_VERW_FMATIG')); // MW30
            return !hidden;
        } //-- end: conditionP --------------------------------------------------------------------

        function conditionQ() {
            // [MW-Q] WN/WB: toon als MW30 = fractie matig verwarmd eigen invoer / UN/UB: toon als MW30 = fractie matig verwarmd invoeren
            const invoerFractieMatig = getProfiel('GEBR-PROFIEL_VERW_FMATIG'); // MW30
            const visible = ntaSharedLogic.isUtiliteit()
                ? invoerFractieMatig === 'PROFIEL_FMATIG_INVOER'    // fractie matig verwarmd invoeren
                : isEigenWaarde(invoerFractieMatig);                // fractie matig verwarmd eigen invoer
            return visible;
        } //-- end: conditionQ --------------------------------------------------------------------

        function conditionS() {
            // [MW-S] verberg als MW04=gebruikersprofiel met eigen waarden OF als MW46 = eigen waarde
            return !conditionT();
        } //-- end: conditionS --------------------------------------------------------------------

        function conditionT() {
            // [MW-T] toon als MW04=gebruikersprofiel met eigen waarden OF als MW46 = eigen waarde
            const visible = isEigenWaarde(getProfiel('GEBR-PROFIEL_KOEL_SETPOINT')); // MW46
            return visible;
        } //-- end: conditionT --------------------------------------------------------------------

        function conditionU() {
            // [MW-U] toon als MW04 is zichtbaar OF als MW57 ≠ eigen waarde
            return !isHidden('GEBR-PROFIEL_STD') || !conditionV();
        } //-- end: conditionU --------------------------------------------------------------------

        function conditionV() {
            // [MW-V] toon als MW57 = eigen waarde
            const visible = isEigenWaarde(getProfiel('GEBR-PROFIEL_TAPW_BEH')); // MW57
            return visible;
        } //-- end: conditionV --------------------------------------------------------------------

        function conditionW() {
            // [MW-W] toon indien MW57 = eigen waarde  -> zelfde als conditionV
            return conditionV();
        } //-- end: conditionV --------------------------------------------------------------------

        function conditionZ() {
            // [MW-Z] verberg als MW04 getoond
            const hidden = !isHidden('GEBR-PROFIEL_STD'); // MW04
            return !hidden;
        } //-- end: conditionZ --------------------------------------------------------------------

        function conditionAA() {
            // [MW-AA] verberg als MW63=eigen waarde
            return !conditionAB();
        } //-- end: conditionAA -------------------------------------------------------------------

        function conditionAB() {
            // [MW-AB] toon als MW63=eigen waarde
            return isEigenWaarde(getProfiel('GEBR-PROFIEL_VERL_INV_BRUPJ')); // MW63
        } //-- end: conditionAB -------------------------------------------------------------------

        function conditionAE() {
            // [MW-AE] als de keuze 'warmte obv gebruik' wordt gemaakt en als NIET voldaan wordt aan
            //  * ER02 = 'energierekening per maand gebruiken voor fitten' EN
            //  * ER05 = 'e-levering'EN 'e-opwekking' is aangevinkt
            // toon dan warning [W103] en zet oude waarde terug
            const propDoel = ntaData.properties['ENER-REK_DOEL']; // ER02
            const propdataValueDoel = propDoel.getValue(_entdataEnergierekening);
            const propVerbr = ntaData.properties['ENER-REK_VERBRAANW']; // ER05
            const valuesSet = new Set(String(propVerbr.getValue(_entdataEnergierekening)).split('|'));

            ///-- controleer of E_LEV en E_OPWEK zichtbaar zijn anders hoeven ze niet gecheckt te worden.
            ///-- E_LEV is altijd zichtbaar en E_OPWEK als hij in de dragerMap zit.
            const dragerMap = ntaSharedLogic.getDragerAanwezigMap();
            return propdataValueDoel == 'ENER-REK_DOEL1' &&
                valuesSet.has('ENER-REK_VERBRAANW_E_LEV') &&
                (dragerMap.get('E').verbruiken ? valuesSet.has('ENER-REK_VERBRAANW_E_OPWEK') : true);
        } //-- end: conditionAE -------------------------------------------------------------------

        function conditionAG() {
            // [MW-AG] toon als MW54 = forfaitair
            return _entdataGebruikersprofiel.PropertyDatas['GEBR-PROFIEL_VENT_CORR_INV'].Value === 'INVOER_FORFAITAIR'; // MW54
        } //-- end: conditionAG -------------------------------------------------------------------

        function conditionAH() {
            // [MW-AH] toon als MW54 = eigen waarde
            return _entdataGebruikersprofiel.PropertyDatas['GEBR-PROFIEL_VENT_CORR_INV'].Value === 'INVOER_EIGEN_WAARDE'; // MW54
        } //-- end: conditionAH -------------------------------------------------------------------

        function conditionAI() {
            // [MW-AI] toon als in tenminste 1 van de ventilatiesystemen V67 = verwarmingsbatterij in luchtbehandelingskast EN/OF V68 = 'koelbatterij in luchtbehandelingskast'
            return ntaEntityDataOrg.findEntities(_entdataGebruikersprofiel, 'GEBR-VENT.^VENT.VENTDIS')
                .some(vent => {
                    const propdataLBKVerw = vent.PropertyDatas['VENTDIS_CVERW']; // V67 luchtbehandelingskast - verwarmingsbatterij
                    const propdataLBKKoel = vent.PropertyDatas['VENTDIS_CKOEL']; // V68 luchtbehandelingskast - koelbatterij
                    return (propdataLBKVerw && propdataLBKVerw.Relevant && propdataLBKVerw.Value === 'VENTDIS_CVERW_WEL')
                        || (propdataLBKKoel && propdataLBKKoel.Relevant && propdataLBKKoel.Value === 'VENTDIS_CKOEL_WEL');
                });
        } //-- end: conditionAI -------------------------------------------------------------------

        function conditionAM() {
            // [MW-AM] verberg als WN/WB AND MW04 wordt getoond (en reken met de waarde die bij MW04 wordt ingevoerd)
            const woningbouw = !ntaSharedLogic.isUtiliteit();
            const standProfielZichtbaar = conditionA();

            return woningbouw && standProfielZichtbaar;
        } //-- end: conditionAM -------------------------------------------------------------------

        function conditionAP() {
            // [MW-AP]  toon als MW08 of MW04 = gebruikersprofiel energiebewust /gebruikersprofiel standaard / gebruikersprofiel niet-energiebewust
            const checkwaarden = ["PROFIEL_BEWUST", "PROFIEL_STD", "PROFIEL_NIET_BEWUST"];
            return checkwaarden.includes(getProfiel('GEBR-PROFIEL_INTWARMTE')); // MW08
        } //-- end: conditionAP -------------------------------------------------------------------

        function conditionAQ() {
            // [MW-AQ]  toon als MW08 = wamtelast o.b.v. energiegebruik
            return getProfiel('GEBR-PROFIEL_INTWARMTE') === 'PROFIEL_EIGEN_WAARDE'; // MW08
        } //-- end: conditionAQ -------------------------------------------------------------------

        function conditionAR() {
            // [MW-AR]  toon als MW08 = eigen waarde
            return getProfiel('GEBR-PROFIEL_INTWARMTE') === 'PROFIEL_WARMTE_OBV_GEBR'; // MW08
        } //-- end: conditionAR -------------------------------------------------------------------

        function conditionAS() {
            // [MW-AS] toon indien ge3-3 indien op dialogue box ngg e-verbruik is gekozen voor 'berekenen a.d.h.v. interne warmteproductie'
            return ntaData.ntaVersion.ntaVersionId >= 303
                && ntaEntityDataOrg.getFirstWithEntityId('NGEBGEB-E')?.PropertyDatas['NGEBGEB-E_METHODE']?.Value === 'NGEBGEB-E_INTERNE_WARMTE';
        } //-- end: conditionAS -------------------------------------------------------------------

        function hasCodedValues(prop) {
            return ntaValidation.hasCodedValues(prop);
        } //-- end: hasCodedValues ----------------------------------------------------------------

        function getCodedValues(prop, entdata) {
            if (!prop || !entdata) {
                return [];
            }

            let codedValues = ntaValidation.codedValues(prop);

            switch (prop.Id) {
                case 'GEBR-PROFIEL_STD':                // MW04
                case 'GEBR-PROFIEL_INTWARMTE':          // MW08
                case 'GEBR-PROFIEL_VERW_SETPOINT':      // MW27
                case 'GEBR-PROFIEL_VERW_VERLAGING':     // MW28
                case 'GEBR-PROFIEL_VERW_FMATIG':        // MW30
                case 'GEBR-PROFIEL_KOEL_SETPOINT':      // MW46
                case 'GEBR-PROFIEL_TAPW_BEH':           // MW57
                case 'GEBR-PROFIEL_INTWARMTE_PERS':     // MW09
                case 'GEBR-PROFIEL_VERL_INV_BRUPJ': {   // MW63
                    if (prop.Id === 'GEBR-PROFIEL_VERW_FMATIG' && ntaSharedLogic.isUtiliteit()) {
                        codedValues = codedValues.filter(cv => cv.FilterValue1 === prop.Id);
                    } else {
                        codedValues = codedValues.filter(cv => {
                            // Als FilterValue1 een waarde heeft, dan moet die CodedValue alleen beschikbaar zijn voor die PropertyId
                            let include = !cv.FilterValue1 || cv.FilterValue1 === prop.Id;
                            if (include) {
                                switch (cv.Id) {
                                    case 'PROFIEL_EIGEN_WAARDE':
                                        include = _invoerPropIdsMetEigenWaarde.has(prop.Id);
                                        break;
                                    case 'PROFIEL_FMATIG_INVOER':
                                    case 'PROFIEL_FMATIG_GEEN_INVOER':
                                        include = ntaSharedLogic.isUtiliteit();
                                        break;
                                }
                            }
                            return include;
                        });
                    }
                    break;
                }
                case 'GEBR-PROFIEL_INTWARMTE_APP': {    // MW10
                    // Als FilterValue1 een waarde heeft, dan moet die CodedValue alleen beschikbaar zijn voor die PropertyId
                    codedValues = codedValues.filter(cv => !cv.FilterValue1 || cv.FilterValue1 === prop.Id || cv.FilterValue1 === 'GEBR-PROFIEL_INTWARMTE');
                    break;
                }
                case 'GEBR-VENT_INBLAASTEMP': { // MW78
                    // [MW-AJ] toon bij een ventilatiesysteem met V67 = geen verwarmingsbatterij in luchtbehandelingskast EN V68 = geen koelbatterij in luchtbehandelingskast
                    // [MW-AK] toon bij een ventilatiesysteem met V67 = verwarmingsbatterij in luchtbehandelingskast EN/OF V68 = koelbatterij in luchtbehandelingskast
                    const ventDis = ntaEntityDataOrg.findEntity(entdata, '^VENT.VENTDIS');
                    const propdataLbkMetVerwBatterij = ventDis && ventDis.PropertyDatas['VENTDIS_CVERW'];
                    const propdataLbkMetKoelbatterij = ventDis && ventDis.PropertyDatas['VENTDIS_CKOEL'];
                    const lbkMetBattAanwezig = (propdataLbkMetVerwBatterij && propdataLbkMetVerwBatterij.Relevant && propdataLbkMetVerwBatterij.Value === 'VENTDIS_CVERW_WEL')
                        || (propdataLbkMetKoelbatterij && propdataLbkMetKoelbatterij.Relevant && propdataLbkMetKoelbatterij.Value === 'VENTDIS_CKOEL_WEL');
                    codedValues = codedValues.filter(cv => lbkMetBattAanwezig !== (cv.Id === 'INBLAASTEMP_NVT'));
                    break;
                }
            }

            const propdata = getPropData(prop, entdata);

            // Zorg dat we alleen een nieuwe lijst teruggeven als deze gewijzigde waardes heeft,
            //  anders denkt AngularJS steeds dat het om een compleet nieuwe lijst gaat, en triggert
            //  deze oneindige digests.
            return _listCache.useCacheIfUnchanged(propdata.PropertyDataId, codedValues, (a, b) => a.Id === b.Id && a.Value === b.Value);
        } //-- end: getCodedValues ----------------------------------------------------------------

        function saveValue(propOrId, entdata, newValue) {
            const prop = typeof propOrId === 'string' ? ntaData.properties[propOrId] : propOrId;
            switch (prop && prop.Id) {
            }

            return ntaSharedLogic.saveValue(prop, entdata, newValue, self, ntaEntityDataOrg);
        } //-- end: saveValue ---------------------------------------------------------------------

        function onValidateCallback(prop, entdata, valueToSave) {
            let valid = true;

            switch (prop && prop.Id || prop) {
                case 'GEBR-PROFIEL_INTWARMTE':          // MW08
                case 'GEBR-PROFIEL_INTWARMTE_APP': {    // MW10
                    if (valueToSave === 'PROFIEL_WARMTE_OBV_GEBR') {
                        const oldValue = prop.getValue(entdata); // oude waarde bewaren

                        // [MW-AO] toon W126 als deze keuze wordt gemaakt
                        ntaMeldingen.warning('[W126]')
                            .then(() => {
                                // en oorspronkelijke waarde terugzetten
                                saveValue(prop, entdata, oldValue);
                            })

                        valid = false; // validatie moet deze keuze weigeren
                    }
                    break;
                }
            }

            return valid;
        } //-- end: onValidateCallback ---------------------------------------------------------------------

        function getListItem(prop, entdata, list = null) {
            let listItem = null;

            if (entdata && prop && (list || prop.Domain && prop.Domain.DomainType > 1)) {
                let itemId;
                const propData = prop.getData(entdata);
                switch (prop.Id) {
                    default:
                        itemId = propData.Value;
                        if (!list) {
                            //-- anders ongefilterde codedValue list gebruiken
                            list = prop.Domain.Codes;
                        }
                        break;
                }

                if (list && list.length > 0) {
                    listItem = list.find(item => item.Id === itemId);
                }
            }

            return listItem;
        } //-- end: getListItem ------------------------------------------------------------------------//

        function validate(prop, propdata, entdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            const hidden = isHidden(prop, entdata);
            const readOnly = isReadOnly(prop, entdata);

            if (!hidden && hasCodedValues(prop)) {
                const codedValues = getCodedValues(prop, entdata);
                ntaSharedLogic.checkPropdataInList(prop, entdata, codedValues, self);
            }

            let valid = ntaValidation.IsValid(self.form, prop, propdata, hidden, readOnly);

            switch (prop.Id) {

            }

            return valid;
        } //-- end: validate ----------------------------------------------------------------------

        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = prop.getData(entdata);
            const checkValue = propdata.Value;
            let performDefaultChecks = true;

            let gebrFuncWeightsByRekenzone;
            if (_verwarmingForfaitair.has(prop.Id) || new Set(['GEBR-PROFIEL_INV', 'GEBR-PROFIEL_STD']).has(prop.Id)) {
                // Bij utiliteitsbouw moeten de waardes (temperaturen en uren) oppervlaktegewogen
                //  gemiddeld worden over de gebruiksfuncties. We stellen dus voor elke rekenzone
                //  een lijst op van het aandeel van elke gebruiksfunctieId.
                gebrFuncWeightsByRekenzone = ntaSharedLogic.getGebruiksfunctieWeightsByRekenzone();

                // Voor verwarming zijn er drie profielvelden die gekozen kunnen worden, en zes velden die op basis daarvan ingevuld kunnen/moeten worden.
                for (const [profilePropId, gebrFuncProfilesByPropId] of _verwarmingForfaitair) {
                    if (new Set(['GEBR-PROFIEL_INV', 'GEBR-PROFIEL_STD', profilePropId]).has(prop.Id)) {
                        const profileCode = getProfiel(profilePropId);
                        if (!isEigenWaarde(profileCode)) { // [MW-J] [MW-L] [MW-P]
                            const rzVerwEntdatas = ntaEntityDataOrg.getChildren(_entdataGebruikersprofiel, 'GEBR-RZ-VERW')
                            for (const [propId, profilesByGebrFuncId] of Object.entries(gebrFuncProfilesByPropId)) {
                                for (const rzVerwEntdata of rzVerwEntdatas) {
                                    const rz = ntaEntityDataOrg.getFirstParent(rzVerwEntdata, 'RZ');
                                    if (!rz) continue;

                                    let aggregateValue = '';
                                    for (const [gebruiksfunctieId, areaFactor] of gebrFuncWeightsByRekenzone.get(rz.EntityDataId) || new Map()) {
                                        // Kijk of we profielen hebben voor de gebruiksfunctie, of anders voor '' (= overige gebruiksfuncties); anders wordt het een leeg object.
                                        const valuesByProfile = profilesByGebrFuncId[gebruiksfunctieId] || profilesByGebrFuncId[''] || Object.create(null);
                                        if (profileCode) {
                                            const value = valuesByProfile[profileCode] * areaFactor;
                                            if (aggregateValue === '') {
                                                aggregateValue = value;
                                            } else {
                                                aggregateValue += value;
                                            }
                                        }
                                    }
                                    saveValue(propId, rzVerwEntdata, aggregateValue);
                                }
                            }
                        }
                    }
                }
            }

            if (new Set(['GEBR-PROFIEL_INV', 'GEBR-PROFIEL_STD', 'GEBR-PROFIEL_KOEL_SETPOINT']).has(prop.Id)) {
                const profiel = getProfiel('GEBR-PROFIEL_KOEL_SETPOINT'); // MW46
                if (!isEigenWaarde(profiel)) { // [MW-S]
                    const setpointKoeling = getSetpointKoelingForfaitair(profiel);
                    for (const entdataRzKoel of ntaEntityDataOrg.getChildren(_entdataGebruikersprofiel, 'GEBR-RZ-KOEL')) {
                        saveValue('GEBR-RZ-KOEL_THINTSET_NON', entdataRzKoel, setpointKoeling); // MW49
                    }
                }
            }

            if (new Set(['GEBR-PROFIEL_INV', 'GEBR-PROFIEL_STD', 'GEBR-PROFIEL_TAPW_BEH']).has(prop.Id)) {
                const profiel = getProfiel('GEBR-PROFIEL_TAPW_BEH'); // MW57
                if (!isEigenWaarde(profiel)) { // [MW-U]
                    const tapwaterbehoefte = getWarmtebehoefteForfaitair(profiel);
                    const profielfactor = getProfielfactor(profiel);
                    if (!gebrFuncWeightsByRekenzone) gebrFuncWeightsByRekenzone = ntaSharedLogic.getGebruiksfunctieWeightsByRekenzone();
                    for (const entdataRzTapw of ntaEntityDataOrg.getChildren(_entdataGebruikersprofiel, 'GEBR-RZ-TAPW')) {
                        saveValue('GEBR-RZ-TAPW_QWND_SPECP_NON', entdataRzTapw, tapwaterbehoefte); // MW58

                        // oppervlaktegewogen QWndspec
                        const rz = ntaEntityDataOrg.getFirstParent(entdataRzTapw, 'RZ');
                        if (rz) {
                            let avgQWndspec = 0;
                            for (const [gebruiksfunctieId, areaFactor] of gebrFuncWeightsByRekenzone.get(rz.EntityDataId) || new Map()) {
                                avgQWndspec += _table13_1.get(gebruiksfunctieId) * areaFactor;
                            }
                            saveValue('GEBR-RZ-TAPW_QWND_SPEC_NON', entdataRzTapw, avgQWndspec * profielfactor); // MW83
                        }
                    }
                }
            }

            if (new Set(['GEBR-PROFIEL_INV', 'GEBR-PROFIEL_STD', 'GEBR-PROFIEL_VERL_INV_BRUPJ']).has(prop.Id)) {
                const profiel = getProfiel('GEBR-PROFIEL_VERL_INV_BRUPJ'); // MW63
                if (!isEigenWaarde(profiel)) { // [MW-AA]
                    const profielfactor = getProfielfactor(profiel);
                    if (!gebrFuncWeightsByRekenzone) gebrFuncWeightsByRekenzone = ntaSharedLogic.getGebruiksfunctieWeightsByRekenzone();
                    for (const entdataRzVerl of ntaEntityDataOrg.getChildren(_entdataGebruikersprofiel, 'GEBR-RZ-VERL')) {
                        // oppervlaktegewogen branduren
                        const rz = ntaEntityDataOrg.getFirstParent(entdataRzVerl, 'RZ');
                        if (rz) {
                            let brandurenDag = 0;
                            let brandurenNacht = 0;
                            for (const [gebruiksfunctieId, areaFactor] of gebrFuncWeightsByRekenzone.get(rz.EntityDataId) || new Map()) {
                                const { tD, tN } = _table14_1.get(gebruiksfunctieId) || {};
                                brandurenDag += tD * areaFactor;
                                brandurenNacht += tN * areaFactor;
                            }
                            saveValue('GEBR-RZ-VERL_BRUDAG_NON', entdataRzVerl, brandurenDag * profielfactor);     // MW68
                            saveValue('GEBR-RZ-VERL_BRUAVNA_NON', entdataRzVerl, brandurenNacht * profielfactor);  // MW70
                        }
                    }
                }
            }

            if (prop.Id === 'GEBR-PROFIEL_INTWARMTE' || prop.Id === 'GEBR-PROFIEL_INTWARMTE_APP') {
                const intwValue = prop.getValue(_entdataGebruikersprofiel);
                if (intwValue === 'PROFIEL_WARMTE_OBV_GEBR' && _entdataEnergierekening != null && !conditionAE()) {
                    saveValue(prop, _entdataGebruikersprofiel, ''); // keuze mag niet, waarde leegmaken
                }
            }

            if (new Set(['GEBR-PROFIEL_INV', 'GEBR-PROFIEL_STD', 'GEBR-PROFIEL_INTWARMTE']).has(prop.Id)) {
                const profiel = getProfiel('GEBR-PROFIEL_INTWARMTE'); // MW08
                if (conditionAP()) { // waarde van WPPP_NON vullen

                }
                if (!isEigenWaarde(profiel)) { // [MW-AA]
                    const profielWpp = getProfielWpp(profiel);
                    for (const entdataRzBezf of ntaEntityDataOrg.getChildren(_entdataGebruikersprofiel, 'GEBR-RZ-BEZF')) {
                        saveValue('GEBR-RZ-BEZF_WPPP_NON', entdataRzBezf, profielWpp); // MW84
                    }
                }
            }

            if (performDefaultChecks) {
                isHidden(prop, entdata);
                if (ntaValidation.hasCodedValues(prop)) {
                    getCodedValues(prop);
                }
                if (propdata.Value !== checkValue) {
                    saveValue(prop, entdata);
                }
            }
        } //-- end: validateDependencies ----------------------------------------------------------

        function startFormValidation() {
            return ntaSharedLogic.startFormValidation(getAllEntDatas(), self);
        } //-- end: startFormValidation -----------------------------------------------------------

        function endFormValidation() {
            return ntaSharedLogic.endFormValidation(getAllEntDatas(), self);
        } //-- end: endFormValidation -------------------------------------------------------------

        function getAllEntDatas() {
            return [
                _entdataGebruikersprofiel,
                ...ntaEntityDataOrg.getDescendants(_entdataGebruikersprofiel, ed => ed.EntityId.startsWith("GEBR-")), //resultaat-entiteiten er uit filteren
            ].filter(ed => ed);
        } //-- end: getAllEntDatas ----------------------------------------------------------------

        function setGeopend() {
            const propdataOpen = _entdataGebruikersprofiel.PropertyDatas['GEBR-PROFIEL_OPEN'];
            ntaEntityDataOrg.saveprop(propdataOpen, 'true');
        } //-- end: setGeopend --------------------------------------------------------------------

    };
}]);
