﻿angular.module('projectModule')
    .service('ntaVariant',
        ['$log', 'ntaEntityDataOrg', 'ntaData', 'ntaSharedLogic', 'ntaResults', 'ListCache', 'ntaRounding',
function ($log,   ntaEntityDataOrg,   ntaData,   ntaSharedLogic,   ntaResults,   ListCache,   ntaRounding) {
    'use strict';
    const self = this;

    /// == Imports ================================================================================

    /// == Instance variables =====================================================================

    const _currency = new Intl.NumberFormat('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const _collator = new Intl.Collator('nl-NL', { sensitivity: 'base', numeric: true });
    const _listCache = new ListCache();

    /// == Exports ================================================================================

    Object.assign(self, {
        // methods
        create,
        copy,
        deleteVariant,
        getList,
        getName,
        getDefaultName,
        getTileDetails,
        getEnergielabel,
        getTotaleKosten,
        getTerugverdientijd,
        getNettoContanteWaarde,
        getMeasures,
        conditieOVB,
        conditieOVC,
        sortVariantsByName,
    });


    /// == Initialization =========================================================================

    /// == Implementation =========================================================================
    function create() {
        const variantId = ntaEntityDataOrg.create('VARIANT', -1, [], [], [
            { PropertyId: 'VARIANT_NAAM', Value: getDefaultName() },
        ]);
        const variant = ntaEntityDataOrg.get(variantId);

        ntaResults.updateForParents(null, variant);

        if (ntaData.ntaVersion.ntaVersionId < 303) {
            //Bij het aanmaken van een variant éénmalig de gegevens voor
            //niet-gebouwgebonden electriciteitsgebruik overnemen van de BASIS berekening
            //Zie Trello-kaartje https://trello.com/c/2j88wJtT
            const unitList = ntaEntityDataOrg.getListWithEntityId("UNIT");
            unitList.push(ntaEntityDataOrg.getFirstWithEntityId("GEB"));
            const propGebruikInv = ntaData.properties['RESULT-ELEKTR_NIETGEBGEB_GEBR'];
            const propGebruik = ntaData.properties['RESULT-ELEKTR_NIETGEBGEB'];
            for (const unit of unitList) {
                const resultatenVariant = ntaResults.getForParents(unit, variant, false);
                const gebruikVariant = resultatenVariant.resultEntdatasByEntityId.get('RESULT-ENERGIEGEBRUIK')[0];

                const resultatenBasis = ntaResults.getForParents(unit, ntaEntityDataOrg.getFirstWithEntityId("BASIS"), false);
                const gebruikBasis = resultatenBasis.resultEntdatasByEntityId.get('RESULT-ENERGIEGEBRUIK')[0];

                const propdataInv = propGebruikInv.getData(gebruikVariant);
                propdataInv.Value = propGebruikInv.getValue(gebruikBasis);
                propdataInv.Touched = true;
                ntaEntityDataOrg.saveprop(propdataInv);

                const propdata = propGebruik.getData(gebruikVariant);
                propdata.Value = propGebruik.getValue(gebruikBasis);
                propdata.Touched = true; // ivm defaultvalue. Zie buildingresults-factory.js function isGebGebInstallatieEInvoer
                ntaEntityDataOrg.saveprop(propdata);
            }
        }

        sortVariantsByName();

        return variant;
    } //-- end: create ----------------------------------------------------------------------------

    function copy(id) {
        var newEntdatas = ntaEntityDataOrg.copy(id);

        sortVariantsByName();

        const [newVariant] = newEntdatas;
        ntaResults.updateForParents(null, newVariant);

        return newEntdatas;
    } //-- end: copy ------------------------------------------------------------------------------

    function deleteVariant(id) {
        ntaEntityDataOrg.delete(id);

        return sortVariantsByName();
    } //-- end: delete ----------------------------------------------------------------------------

    function getList() {
        return ntaEntityDataOrg.getListWithEntityId('VARIANT');
    } //-- end: getList ---------------------------------------------------------------------------

    function orderVariants(a, b) {
        return _collator.compare(getName(a), getName(b))
            || a.Order - b.Order;
    } //-- end: orderVariants ---------------------------------------------------------------------

    function sortVariantsByName() {
        const variants = ntaEntityDataOrg.getListWithEntityId('VARIANT').sort(orderVariants);

        /// opslaan op de server
        ntaEntityDataOrg.SaveReOrder(variants);

        return variants;
    } //-- end: orderVariantsByName ---------------------------------------------------------------

    function getName(variant) {
        return variant.PropertyDatas['VARIANT_NAAM'].Value;
    } //-- end: getName ---------------------------------------------------------------------------

    function getDefaultName(entdata) {
        const variantNames = new Set(
            getList()
                .filter(ed => ed !== entdata)
                .map(ed => ed.PropertyDatas['VARIANT_NAAM'].Value)
        );
        let counter = 1;
        let name;
        do {
            name = 'variant' + ' ' + counter++;
        } while (variantNames.has(name));

        return name;
    } //-- end: getDefaultName --------------------------------------------------------------------

    function getMeasures(variant) {
        return ntaEntityDataOrg.findEntities(variant, 'VARIANT-MEASURE.^MEASURE', '^MEASURE');
    } //-- end: getMeasures -----------------------------------------------------------------------

    function getTileDetails(variant) {
        const measures = getMeasures(variant);

        const lines = measures.map(m => ({
            name: '- ' + m.PropertyDatas["MEASURE_NAAM"].Value,
            value: '',
        }));
        if (measures.length === 0) {
            lines.push({
                name: '  (geen)',
            });
        }

        return _listCache.useCacheIfUnchanged(variant.EntityDataId, lines, (a, b) => a.name === b.name && a.value === b.value);
    } //--end: getTileDetails ---------------------------------------------------------------------

    function getEnergielabel(variant) {
        // Prestatie van GEB
        const geb = ntaSharedLogic.getGebEntdata();
        const { resultEntdatasByEntityId } = ntaResults.getForParents(geb, variant);
        const prestatie = (resultEntdatasByEntityId.get('PRESTATIE') || [])[0];
        return prestatie ? prestatie.PropertyDatas['EP_ENERGIELABEL'].Value : "";
    } //--end: getEnergielabel ---------------------------------------------------------------------

    function getTotaleKosten(variant) {
        const prop = ntaData.properties['VARIANT_KOSTEN_TOT'];
        const value = prop && prop.getValue(variant);
        return value !== null
            ? prop.Unit + ' ' + _currency.format(ntaSharedLogic.parseFloat(value))
            : prop.Unit + ' --';
    } //--end: getTotaleKosten ---------------------------------------------------------------------

    function getTerugverdientijd(variant) {
        const prop = ntaData.properties['VARIANT_TVT'];
        const propdata = prop && prop.getData(variant);
        ntaRounding.roundAndAddZeros(prop, propdata);
        // [OV-F] en [RO-AT] indien de uitkomst = 0,0 dan toon ∞
        return propdata.Value ? ntaSharedLogic.parseFloat(propdata.Value, 0) <= 0 ? '<span class="largefont">∞</span>' : propdata.Value + ' ' + prop.Unit : '--';
    } //--end: getTerugverdientijd ----------------------------------------------------------------

    function getNettoContanteWaarde(variant) {
        const prop = ntaData.properties['VARIANT_NCW'];
        const value = prop && prop.getValue(variant);
        return value !== null
            ? prop.Unit + ' ' + _currency.format(ntaSharedLogic.parseFloat(value))
            : prop.Unit + ' --';
    } //--end: getNettoContanteWaarde -------------------------------------------------------------

    function conditieOVB() {
        //[OV-B] verberg indien Z23 = 'per gebouw en per appartement/unit' OF 'voor projectwoningen'
        return ntaSharedLogic.getGebouwType() !== 'TGEB_APPGEB' && !ntaSharedLogic.perGebouwEnUnit() && !ntaSharedLogic.voorProjectwoningen();
    }//--end: conditieOVB ---------------------------------------------------------------------

    function conditieOVC() {
        //[OV-C] toon indien bij instellingen is gekozen voor 'maatwerk advies'
        return ntaSharedLogic.showCosts();
    }//--end: conditieOVC ---------------------------------------------------------------------

}]);
