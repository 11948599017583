﻿angular.module('projectModule')
    .factory('MeasureHvacFactory',
        ['$log', 'ntaData', 'ntabuilding', 'ntaValidation', 'ntaEntityDataOrg', 'ntaSharedLogic', 'ntaDeltas', 'ntaRounding', 'InstallationDataFactory',
function ($log,   ntaData,   ntabuilding,   ntaValidation,   ntaEntityDataOrg,   ntaSharedLogic,   ntaDeltas,   ntaRounding,   InstallationDataFactory) {
    'use strict';

    return function MeasureHvacLogic(measureId, ntaDependencyValidation) {
        const self = this;

        //== Imports ==============================================================================
        self.ntaValidation = ntaValidation;
        self.dependencyValidator = ntaDependencyValidation;

        //== Instance data ========================================================================
        const _instData = new InstallationDataFactory(ntaEntityDataOrg);

        const _measureId = measureId;
        const _entdataMeasure = ntaEntityDataOrg.get(_measureId);
        const _entdataMeasureHvac = ntaEntityDataOrg.getFirstChild(_entdataMeasure, 'MEASURE-HVAC');

        // [RC-E] indien bij maatregelen gekozen voor 'Rc vloer' toon 'vloer'; indien 'Rc vloer boven buitenlucht' toon 'vloer boven buitenlucht', idem voor gevel / dak / kelderwand / bodem
        const _measureType = ntaData.properties['MEASURE_TYPE'].getCode(_entdataMeasure);

        //== Exports ==============================================================================
        self.entdataMeasureHvac = _entdataMeasureHvac;
        self.properties = ntaData.properties[_entdataMeasureHvac.EntityId];
        self.measureType = _measureType;

        // al deze methods exporteren zodat ze publiek beschikbaar zijn
        Object.assign(self, {
            canHaveSubmeasures: true,

            // specifieke methods voor maatregelen
            getTileDetails,
            getTotaleKosten,
            getSubmeasures,

            // standaard methods tbv validatie
            saveValue,
            validate,
            validateDependencies,
            startFormValidation,
            endFormValidation,
            setGeopend,
        });

        //== Initialization =======================================================================

        // (geen initialisatie nodig)


        //== Implementation =======================================================================

        function getTileDetails(render) {
            const lines = [];

            if (ntaSharedLogic.showCosts()) {
                lines.push({ name: 'kosten per identiek systeem:' });

                const buildingData = getShadowBuildingData(['INSTALLATIE', 'MEASURE-COSTS']);
                const installations = getInstallations(buildingData);

                const costsPerType = new Map();
                for (const installation of installations) {
                    const measureCosts = buildingData.getFirstChild(installation, 'MEASURE-COSTS');
                    // [HVAC-B] toon waarde uit HVAC03; ...
                    const amount = ntaSharedLogic.parseFloat(measureCosts && measureCosts.PropertyDatas['MEASURE-COSTS_PER_SYS'].Value, 0); // HVAC03

                    const type = installation.PropertyDatas['INSTALL_TYPE'].Value;
                    const existingCostForType = costsPerType.get(type) || 0;
                    costsPerType.set(type, existingCostForType + amount);
                }

                const typeNames = new Map(ntaData.properties['INSTALL_TYPE'].Domain.Codes.map(code => [code.Id, code.Value]));
                for (const [type, total] of costsPerType) {
                    if (!total) continue; // [HVAC-B] ... verberg rij als geen tegel van dit systeem aanwezig is of er op het formulier geen kosten zijn ingevuld (leeg of 0)

                    const name = String(typeNames.get(type)).toLowerCase();
                    lines.push({ name: '  ' + name, value: render.propValue('MEASURE-COSTS_PER_SYS', total) });
                }

                if (lines.length === 1) {
                    lines.push({ name: '  (geen kosten opgegeven)' });
                }

                lines.push({});
                // [HVAC-C] sommeer HVAC04 van alle installatie formulieren onder deze HVAC installatie
                lines.push({ name: 'totale kosten', value: render.propValue('MEASURE-HVAC_TOT_KOSTEN', getTotaleKosten(buildingData)) });
            }

            return lines;
        } //-- end: getTileDetails ----------------------------------------------------------------

        function getTotaleKosten(buildingData = getShadowBuildingData(['INSTALLATIE', 'MEASURE-COSTS'])) {
            const installations = getInstallations(buildingData);

            let totalCosts = 0;
            for (const installation of installations) {
                const measureCosts = buildingData.getFirstChild(installation, 'MEASURE-COSTS')
                const amountPerSystem = ntaSharedLogic.calculateInstallationCosts(installation, measureCosts);

                if (!isNaN(amountPerSystem)) {
                    totalCosts += amountPerSystem;
                }
            }

            // dan het totaal opslaan. Dit moet altijd in de basisberekening gebeuren.
            const propTotal = ntaData.properties['MEASURE-HVAC_TOT_KOSTEN'];
            const propdataTotal = propTotal.getData(_entdataMeasureHvac);
            if (propdataTotal) {
                const formattedAmount = ntaRounding.roundAndAddZerosNewValue(propTotal, totalCosts);
                ntaEntityDataOrg.saveprop(propdataTotal, formattedAmount);
            }

            return totalCosts;
        } //-- end: getTotaleKosten ---------------------------------------------------------------

        function getSubmeasures() {
            const projectId = ntaData.projectdata.ProjectId;
            const buildingId = _entdataMeasure.BuildingId;
            const baseUrl = `/Project/${projectId}/Berekening/${buildingId}/Maatregelen/${_measureId}`;

            const propType = ntaData.properties['INSTALL_TYPE'];

            const installations = getInstallations();
            const submeasures = installations
                .map(ed => ({
                    id: ed.EntityDataId,
                    name: _instData.getName(ed, true),
                    icon: propType && propType.getCode(ed) && propType.getCode(ed).ImageUrl || '',
                    url: `${baseUrl}/${propType.getValue(ed).toLowerCase()}/${ed.EntityDataId}`,
                }));
            return submeasures;
        } //-- end: getSubmeasures ----------------------------------------------------------------

        function getShadowBuildingData(entityIds = null) {
            return ntaDeltas.getShadowBuildingData(_entdataMeasure.EntityDataId, entityIds);
        } //-- end: getShadowBuildingData ---------------------------------------------------------

        function getInstallations(buildingData = getShadowBuildingData(['INSTALLATIE'])) {
            const propType = ntaData.properties['INSTALL_TYPE'];

            return buildingData.getListWithEntityId('INSTALLATIE')
                .filter(ed => !_instData.nonMeasureInstallTypes.has(propType.getValue(ed)))
                .sort(_instData.orderInstallations);
        } //-- end: getInstallations --------------------------------------------------------------

        function saveValue(prop, entdata, newValue) {
            return ntaSharedLogic.saveValue(prop, entdata, newValue, self);
        } //-- end: saveValue ---------------------------------------------------------------------

        function validate(prop, propdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            const hidden = true; //isHidden(prop);
            const readOnly = true; //isReadOnly(prop);

            let valid = ntaValidation.IsValid(self.form, prop, propdata, hidden, readOnly);

            const contrl = self.form && self.form['ntainput' + propdata.PropertyDataId];

            switch (prop.Id) {
                default: {
                    break;
                }
            }

            return valid;
        } //-- end: validate ---------------------------------------------------------------------

        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = prop.getData(entdata);
            const checkValue = propdata.Value;

            let performDefaultChecks = false;

            switch (prop.Id) {
                default:
                    break;
            }

            if (performDefaultChecks) {
                isHidden(prop, entdata);
                if (ntaValidation.hasCodedValues(prop)) {
                    getCodedValues(prop);
                }
                if (propdata.Value !== checkValue) {
                    saveValue(prop, entdata);
                }
            }
        } //-- end: validateDependencies ----------------------------------------------------------

        function startFormValidation() {
            return ntaSharedLogic.startFormValidation(getAllEntDatas(), self);
        } //-- end: startFormValidation -----------------------------------------------------------

        function endFormValidation() {
            if (![null, _entdataMeasure.EntityDataId].includes(ntaData.current.shadowId)) return [];

            return ntaSharedLogic.endFormValidation(getAllEntDatas(), self);
        } //-- end: endFormValidation -------------------------------------------------------------

        function getAllEntDatas() {
            return [_entdataMeasureHvac];
        } //-- end: getAllEntDatas ----------------------------------------------------------------

        function setGeopend() {
            const propdataOpen = _entdataMeasure.PropertyDatas['MEASURE_OPEN'];
            ntaEntityDataOrg.saveprop(propdataOpen, 'true');
        } //-- end: setGeopend --------------------------------------------------------------------

    };
}]);
