﻿angular.module('projectModule')
    .factory("VentilatieFactory",
        ['ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaRounding', 'ntaSharedLogic', 'ntaSelectionTable', 'ntaMeldingen',
function (ntabuilding,   ntaValidation,   ntaEntityData,   ntaRounding,   ntaSharedLogic,   ntaSelectionTable,   ntaMeldingen) {
    'use strict';

    function VentilatieLogic(installId, ntaDependencyValidation) {
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        const ventilatieLogic = this;

        ventilatieLogic.installId = installId;
        const _installationId = installId;

        /* Properties **********************************************************************************************/
        const _ventProperties = ntabuilding.properties['VENT'];
        const _ventAanvullendProperties = ntabuilding.properties['VENTAAN'];
        const _warmteterugwinningProperties = ntabuilding.properties['WARMTETERUG'];
        const _voorverwarmingProperties = ntabuilding.properties['VOORWARM'];
        const _ventilatorenProperties = ntabuilding.properties['VENTILATOR'];
        const _ventilatoreigenschappenProperties = ntabuilding.properties['VENTILATOREIG'];
        const _ventVerblijfgebiedProperties = ntabuilding.properties['VENT-VERB'];
        const _ventToevoerkanaalNaarWTWProperties = ntabuilding.properties['WARMTE-TOEV-KAN'];
        const _ventilatiedebietProperties = ntabuilding.properties['VENTDEB'];
        const _ventcapaciteitenProperties = ntabuilding.properties['VENTCAP'];
        const _ventzwembadruimteProperties = ntabuilding.properties['VENTZBR'];
        const _ventilatiedistributieProperties = ntabuilding.properties['VENTDIS'];

        /* data ****************************************************************************************************/
        let _ventDataId = 0;
        let _ventData;
        let _ventAanvullendData = [];
        let _warmteterugwinningData = [];
        let _voorverwarmingData = [];
        let _ventilatorenData = [];
        let _ventilatiedebietData = [];
        let _ventilatiedistributieData = [];

        let _warmteterugwinningAanvullendData = [];
        let _ventilatorenAanvullendData = [];

        if (_installationId) {
            _ventData = ntaEntityData.getFirstChild(_installationId, 'VENT');
            _ventDataId = _ventData.EntityDataId;

            _ventAanvullendData = ntaEntityData.getFirstChild(_ventData, 'VENTAAN');

            _warmteterugwinningData = ntaEntityData.getFirstChild(_ventData, 'WARMTETERUG');
            _warmteterugwinningAanvullendData = ntaEntityData.getFirstChild(_ventAanvullendData, 'WARMTETERUG');

            _voorverwarmingData = ntaEntityData.getFirstChild(_ventData, 'VOORWARM');

            _ventilatorenData = ntaEntityData.getFirstChild(_ventData, 'VENTILATOR');
            _ventilatorenAanvullendData = ntaEntityData.getFirstChild(_ventAanvullendData, 'VENTILATOR');

            _ventilatiedebietData = ntaEntityData.getFirstChild(_ventData, 'VENTDEB');

            _ventilatiedistributieData = ntaEntityData.getFirstChild(_ventData, 'VENTDIS');
        }

        const _units = getAangeslotenUnits();
        const _zbrRekenzones = ntaEntityData.getChildren(_ventData, 'RZ')
            .filter(rz => ntaEntityData.findEntities(rz, "UNIT-RZ.UNIT-RZ-GF").some(gf => gf.PropertyDatas['UNIT-RZ-GFID'].Value === 'GF_SPORT'));
        const _unitRekenzonesPerUnit = {};
        const _ventcapPerUnitRz = {};
        const _ventzbrPerRz = {};

        /* Tabellen **********************************************************************************************/
        const _warmteterugwinningTypes = createTable11_18();
        const _bypassTypes = createTableBypass();

        const _selectionTable = {
            'VE': [],
            'VE_AAN': [],
            'ZRV': [],
        };

        const _productTypes = [
            { modelType: 'VE', entityId: 'VENT', productIdPropertyId: 'VENT_SYSVAR', invoerPropertyId: 'VENT_INVOER', valueSpecifiek: 'VENT_PROD', valueForfaitair: 'VENT_FORF' },
            { modelType: 'VE_AAN', entityId: 'VENTAAN', productIdPropertyId: 'VENTAAN_SYSVAR', invoerPropertyId: 'VENTAAN_INVOER', valueSpecifiek: 'VENT_PROD', valueForfaitair: 'VENT_FORF' },
            { modelType: 'ZRV', entityId: 'VOORWARM', productIdPropertyId: 'VOORWARM_ROO', invoerPropertyId: null, valueSpecifiek: null, valueForfaitair: null, },
        ];

        const _ventilatieKolom = { isAanvullendSysteem: false, entityId: 'VENT', entdata: _ventData, voorverwarming: _voorverwarmingData, warmteterugwinning: _warmteterugwinningData, ventilatoren: _ventilatorenData };
        const _ventilatieAanvullendKolom = { isAanvullendSysteem: true, entityId: 'VENTAAN', entdata: _ventAanvullendData, voorverwarming: _voorverwarmingData, warmteterugwinning: _warmteterugwinningAanvullendData, ventilatoren: _ventilatorenAanvullendData };
        const _ventilatiekolommen = [_ventilatieKolom, _ventilatieAanvullendKolom];

        ventilatieLogic.ventData = _ventData;
        ventilatieLogic.ventAanvullendData = _ventAanvullendData;
        ventilatieLogic.warmteterugwinningData = _warmteterugwinningData;
        ventilatieLogic.voorverwarmingData = _voorverwarmingData;
        ventilatieLogic.ventilatorenData = _ventilatorenData;
        ventilatieLogic.ventilatiedebietData = _ventilatiedebietData;
        ventilatieLogic.ventilatiedistributieData = _ventilatiedistributieData;
        ventilatieLogic.zbrRekenzones = _zbrRekenzones;
        ventilatieLogic.units = _units;
        ventilatieLogic.warmteterugwinningaanvullendData = _warmteterugwinningAanvullendData;
        ventilatieLogic.ventilatorenaanvullendData = _ventilatorenAanvullendData;

        ventilatieLogic.ventilatiekolommen = _ventilatiekolommen;

        ventilatieLogic.propertyHeaderIsHidden = propertyHeaderIsHidden;
        ventilatieLogic.distributieIsHidden = distributieIsHidden;
        ventilatieLogic.voorverwarmingIsHidden = voorverwarmingIsHidden;
        ventilatieLogic.ventilatiedebietenIsHidden = ventilatiedebietenIsHidden;
        ventilatieLogic.ventilatorEigenschappenUnitIsHidden = ventilatorEigenschappenUnitIsHidden;
        ventilatieLogic.ventilatorEigenschappenIsHidden = ventilatorEigenschappenIsHidden;
        ventilatieLogic.ventilatorenIsHidden = ventilatorenIsHidden;
        ventilatieLogic.warmteterugwinningIsHidden = warmteterugwinningIsHidden;
        ventilatieLogic.toevoerkanaalTabelIsHidden = toevoerkanaalTabelIsHidden;
        ventilatieLogic.verblijfsgebiedTabelIsHidden = verblijfsgebiedTabelIsHidden;
        ventilatieLogic.kolomIsHidden = kolomIsHidden;
        ventilatieLogic.isLbkAanwezig = isLbkAanwezig;

        ventilatieLogic.ventProperties = _ventProperties;
        ventilatieLogic.ventaanvullendProperties = _ventAanvullendProperties;
        ventilatieLogic.warmteterugwinningProperties = _warmteterugwinningProperties;
        ventilatieLogic.voorverwarmingProperties = _voorverwarmingProperties;
        ventilatieLogic.ventilatorenProperties = _ventilatorenProperties;
        ventilatieLogic.ventilatoreigenschappenProperties = _ventilatoreigenschappenProperties;
        ventilatieLogic.ventVerblijfgebiedProperties = _ventVerblijfgebiedProperties;
        ventilatieLogic.ventToevoerkanaalNaarWTWProperties = _ventToevoerkanaalNaarWTWProperties;
        ventilatieLogic.ventilatiedebietProperties = _ventilatiedebietProperties;
        ventilatieLogic.ventcapaciteitenProperties = _ventcapaciteitenProperties;
        ventilatieLogic.ventzwembadruimteProperties = _ventzwembadruimteProperties;
        ventilatieLogic.ventilatiedistributieProperties = _ventilatiedistributieProperties;

        ventilatieLogic.getUnitRekenzones = getUnitRekenzones;
        ventilatieLogic.getVentilatiecapaciteit = getVentilatiecapaciteit;
        ventilatieLogic.getVentilatiezwembadruimte = getVentilatiezwembadruimte;
        ventilatieLogic.setVentilatorenEigenschappenPerUnit = setVentilatorenEigenschappenPerUnit;

        ventilatieLogic.isHidden = isHidden;
        ventilatieLogic.isReadOnly = isReadOnly;

        ventilatieLogic.validate = validate;
        ventilatieLogic.validateDependencies = validateDependencies;

        ventilatieLogic.startFormValidation = startFormValidation;
        ventilatieLogic.endFormValidation = endFormValidation;

        ventilatieLogic.saveValue = saveValue;
        ventilatieLogic.getCodedValues = getCodedValues;

        ventilatieLogic.getListItem = getListItem;
        ventilatieLogic.getSelectionTable = getSelectionTable;
        ventilatieLogic.saveValueSelectionTable = saveValueSelectionTable;
        ventilatieLogic.setSelectedItemSelectionTable = setSelectedItemSelectionTable;
        ventilatieLogic.dependencyValidator = ntaDependencyValidation;


        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         INITALIZIATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        buildVentcapaciteitenTabel();
        if (ntabuilding.ntaVersionId >= 200) {
            buildVentZwembadruimteTabel();
        }

        setVerblijfsgebiedVentPerUnit(_ventData);
        setVerblijfsgebiedVentPerUnit(_ventAanvullendData);
        setToevoerkanaalNaarWTWLengtePerUnit(_warmteterugwinningData);
        setToevoerkanaalNaarWTWLengtePerUnit(_warmteterugwinningAanvullendData);

        setDefaultValueInvoerVentilatiesysteem();

        setVentilatorenEigenschappenPerUnit(_ventilatorenData);
        setVentilatorenEigenschappenPerUnit(_ventilatorenAanvullendData);

        setDefaultValueFabricagejaar();

        //-- eerst bovenstaande defaults zetten voordat de selectietabel geladen wordt. Laden gebruikt bij checken bovenstaande zaken. Deze volgorde is dus belangrijk
        loadProductsFromServer('VE', _ventData);
        loadProductsFromServer('VE_AAN', _ventAanvullendData);
        loadProductsFromServer('ZRV', _voorverwarmingData);

        checkUnits();

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        function getListItem(prop, entdata, list = null) {
            let listItem = null;

            if (prop && prop.Domain && prop.Domain.DomainType > 1 && entdata) {
                var tmpList = null;
                var tmpId;
                var propData = entdata.PropertyDatas[prop.Id];
                switch (prop.Id) {
                    case 'VENT_SYSVAR':
                    case 'VENTAAN_SYSVAR':
                    case 'VOORWARM_ROO':
                        tmpId = parseInt(propData.Value);
                        tmpList = entdata.filteredItems;
                        break;
                    default:
                        tmpId = propData.Value;
                        tmpList = list; // de gefilterde getCodedValue list. Op deze list moet ik checken
                        if (!tmpList) {
                            //-- anders ongefilterde codedValue list gebruiken
                            tmpList = prop.Domain.Codes;
                        }
                        break;
                }

                if (propData.Value && tmpList && tmpList.length > 0) {
                    listItem = tmpList.find(function (x) { return x.Id === tmpId; });
                }
            }
            return listItem;
        } //-- end: getListItem ------------------------------------------------------------------------//

        function setSelectedItemSelectionTable(prop, entdata, selectedItem = null) {
            // dit moet alleen voor velden van het domain type 4 of 5.
            if (prop.Domain.DomainType === 4 || prop.Domain.DomainType === 5) {
                if (!selectedItem) {
                    selectedItem = getListItem(prop, entdata);
                }

                switch (prop.Id) {
                    case 'VENT_SYSVAR':
                    case 'VENTAAN_SYSVAR':
                    case 'VOORWARM_ROO':
                        if (selectedItem) {
                            entdata.selectedItem = selectedItem;
                            entdata.searchText = selectedItem.Value;
                        } else {
                            entdata.selectedItem = null;
                            entdata.searchText = "";
                        }
                        break;
                    default:
                        break;
                }
            }
        } //-- end: setSelectedItemSelectionTable ------------------------------------------------------------------------//

        function loadProductsFromServer(modelType, entdata) {
            const productTypeInfo = _productTypes.find(pt => pt.modelType === modelType);

            if (modelType !== 'VE_AAN') {
                const products = ntaSelectionTable.getProducts(modelType, ntaSharedLogic.isUtiliteit());
                if (modelType === 'VE' && ntabuilding.appVersionId < 3) { // vóór versie 3.1 moeten we fregfan_v1 gebruiken
                    _selectionTable[modelType] = products.map(product => {
                        const newProduct = Object.assign({}, product); // maak een kopie van elk product
                        newProduct.fregfan = newProduct.fregfan_v1; // vervang fregfan door fregfan_v1 (die voor versie 3.0)
                        delete newProduct.fregfan_v1; // die hebben we niet meer nodig
                        return newProduct;
                    });
                } else {
                    _selectionTable[modelType] = products;
                }
            } else {
                //-- producten voor aanvullende systemen zijn gelijk aan de gewonen systemen
                _selectionTable[modelType] = _selectionTable['VE'];
            }

            const prop = ntabuilding.properties[productTypeInfo.productIdPropertyId];
            if (prop) {
                getSelectionTable(prop, entdata);
                setSelectedItemSelectionTable(prop, entdata);
                setValuesDependingOnSelectionTable();
            }
        } //-- end: loadProductsFromServer ------------------------------------------------------------------------//

        function getSelectionTable(prop, entdata, searchText = "") {
            const productTypeInfo = _productTypes.find(pt => pt.productIdPropertyId === prop.Id);
            const table = _selectionTable[productTypeInfo.modelType];
            if (table && prop.Domain.DomainType === 4) { //selection table value
                let res = table;

                // Eerst filteren op productspecifiek/forfaitair, indien relevant
                if (productTypeInfo.invoerPropertyId) {
                    const propInvoer = ntabuilding.properties[productTypeInfo.invoerPropertyId];
                    const invoerValue = propInvoer.getValue(entdata);
                    if (invoerValue === productTypeInfo.valueSpecifiek) {
                        res = res.filter(item => item.Certificate === 1);
                    } else {
                        //--bij VE/VE_AAN moet de forfaitaire producten ook mee bij eigen waarden
                        res = res.filter(item => item.Certificate === 0);
                    }
                }

                // Als bij Settings is aangevinkt dat alleen de actuele verklaringen gekozen mogen,
                // moeten de verlopen verklaringen er uit gefilterd worden.
                if (ntaSharedLogic.showOnlyValidProducts()) {
                    res = res.filter(r => r.ExpiryDate == null || Date.parse(r.ExpiryDate) > Date.now());
                }

                switch (productTypeInfo.modelType) {
                    case 'VE':
                    case 'VE_AAN': {
                        const propSystem = productTypeInfo.modelType === 'VE' ? _ventProperties['VENT_SYS'] : _ventAanvullendProperties['VENTAAN_SYS'];
                        const propValueSystem = propSystem.getValue(entdata);
                        // nu moet ik nog filteren op gekozen systeem A, B, C, D of E
                        let sSys = "";
                        switch (propValueSystem) {
                            case "VENTSYS_NAT":
                                sSys = "A";
                                break;
                            case "VENTSYS_MECH":
                                sSys = "B";
                                break;
                            case "VENTSYS_NATMECH":
                                sSys = "C";
                                break;
                            case "VENTSYS_MECHC":
                                sSys = "Dc";
                                break;
                            case "VENTSYS_MECHD":
                                sSys = "Dd";
                                break;
                            case "VENTSYS_GEC":
                                sSys = "E";
                                break;
                        }
                        res = res.filter(function (x) { return x.Systeem === sSys; });

                        // nu moet ik nog filteren op GG of NGG
                        var sWoningtype = ntaSharedLogic.isNietGrondGebonden() ? "NGG" : "GG";
                        res = res.filter(function (x) { return x.Woningtype === sWoningtype || x.Woningtype === null; });

                        break;
                    }
                }

                if (res.length === 1) {
                    res.Singleresult = true;
                }

                // Tot slot filteren op de zoektekst
                if (searchText && searchText.length > 0) {
                    res = res.filter(function (x) { return x.Value && x.Value.toLowerCase().indexOf(searchText.toLowerCase()) !== -1; });
                }

                entdata.filteredItems = res;
                ntaSharedLogic.checkPropdataInList(prop, entdata, res, ventilatieLogic);
                return res;
            }
            return [];
        } //-- end: getSelectionTable ------------------------------------------------------------------------//

        function setValuesDependingOnSelectionTable() {

            let propfCtrl = _ventProperties['VENT_FCTRL'];
            let propInvoer = _ventProperties['VENT_INVOER'];
            let propSysvar = _ventProperties['VENT_SYSVAR'];
            let propFregfan = _ventilatorenProperties['VENTILATOR_FREGB'];
            let propWTWType = _warmteterugwinningProperties['WARMTETERUG_TYPE'];
            let propWTWeta = _warmteterugwinningProperties['WARMTETERUG_REND'];
            let propBypass = _warmteterugwinningProperties['WARMTETERUG_BYP'];
            let propBypassA = _warmteterugwinningProperties['WAMRTETERUG_BYPA'];

            if (_selectionTable.VE && _selectionTable.VE.length > 0) {

                const product = getListItem(propSysvar, _ventData);

                let propValueInvoer = propInvoer.getValue(_ventData);
                if (propValueInvoer !== 'VENT_EIG') {
                    if (!isHidden(propfCtrl, _ventData)) { ntaSharedLogic.saveProductValueToPropData(ventilatieLogic, product, 'fCtrl', _ventData, propfCtrl.Id); }
                }

                if (!isHidden(propFregfan, _ventilatorenData)) { ntaSharedLogic.saveProductValueToPropData(ventilatieLogic, product, 'fregfan', _ventilatorenData, propFregfan.Id); }
                ntaSharedLogic.saveProductValueToPropData(ventilatieLogic, product, 'Pnom1', _ventilatorenData, 'VENTILATOR_PNOM1');
                ntaSharedLogic.saveProductValueToPropData(ventilatieLogic, product, 'Pnom2', _ventilatorenData, 'VENTILATOR_PNOM2');
                ntaSharedLogic.saveProductValueToPropData(ventilatieLogic, product, 'Pnom3', _ventilatorenData, 'VENTILATOR_PNOM3');
                ntaSharedLogic.saveProductValueToPropData(ventilatieLogic, product, 'Variant', _ventData, 'VENT_VARIANT', (value, prop) => {
                    const codedValue = prop.Domain.Codes.find(cv => cv.Value === value.trim());
                    return codedValue && codedValue.Id || value;
                });


                if (product && product.Certificate === 1) {
                    let propValueWTWType = propWTWType.getValue(_warmteterugwinningData);
                    let newWTWType = propValueWTWType === 'WARMTETYPE_GEEN' ? 'WARMTETYPE_REN1' : propValueWTWType;
                    if (!isHidden(propWTWType, _warmteterugwinningData)) { saveValue(propWTWType, _warmteterugwinningData, newWTWType); }

                    if (!isHidden(propWTWeta, _warmteterugwinningData)) { ntaSharedLogic.saveProductValueToPropData(ventilatieLogic, product, 'eta_hr', _warmteterugwinningData, propWTWeta.Id); }
                    if (!isHidden(propBypassA, _warmteterugwinningData)) {
                        ntaSharedLogic.saveProductValueToPropData(ventilatieLogic, product, 'eta_bypass', _warmteterugwinningData, propBypassA.Id);
                        //-- als waarde uit selectietabel voor eta_bypass gezet wordt, moet de waarde voor bypass op Eigenwaarde gezet worden.
                        //-- ook de wijze van bypass moet gezet worden naar eigen waarde, zodat de juiste bypass mee gaat naar de rekenkern
                        //-- dit doen we alleen voor de 3.1 projecten. De 3.0 projecten houden de verkeerde resultaten
                        if (ntabuilding.appVersionId >= 100) {
                            saveValue(propBypass, _warmteterugwinningData, 'WERMTEBYP_EIG');
                        }
                    }
                }
            }

            let propSys = _ventProperties['VENT_SYS']
            let propValueSys = propSys.getValue(_ventData);
            if (_selectionTable.VE_AAN && _selectionTable.VE_AAN.length > 0 && propValueSys === 'VENTSYS_GEC') {
                //-- alleen als kolom 1 een E-systeem is, is de aanvullende data relevant.
                propfCtrl = _ventAanvullendProperties['VENTAAN_FCTRL'];
                propInvoer = _ventAanvullendProperties['VENTAAN_INVOER'];
                propSysvar = _ventAanvullendProperties['VENTAAN_SYSVAR'];


                let product = getListItem(propSysvar, _ventAanvullendData);

                let propValueInvoer = propInvoer.getValue(_ventAanvullendData);
                if (propValueInvoer !== 'VENT_EIG') {
                    if (!isHidden(propfCtrl, _ventAanvullendData)) { ntaSharedLogic.saveProductValueToPropData(ventilatieLogic, product, 'fCtrl', _ventAanvullendData, propfCtrl.Id); }
                }

                if (!isHidden(propFregfan, _ventilatorenAanvullendData)) { ntaSharedLogic.saveProductValueToPropData(ventilatieLogic, product, 'fregfan', _ventilatorenAanvullendData, propFregfan.Id); }
                ntaSharedLogic.saveProductValueToPropData(ventilatieLogic, product, 'Pnom1', _ventilatorenAanvullendData, 'VENTILATOR_PNOM1');
                ntaSharedLogic.saveProductValueToPropData(ventilatieLogic, product, 'Pnom2', _ventilatorenAanvullendData, 'VENTILATOR_PNOM2');
                ntaSharedLogic.saveProductValueToPropData(ventilatieLogic, product, 'Pnom3', _ventilatorenAanvullendData, 'VENTILATOR_PNOM3');
                ntaSharedLogic.saveProductValueToPropData(ventilatieLogic, product, 'Variant', _ventAanvullendData, 'VENTAAN_VARIANT', (value, prop) => {
                    const codedValue = prop.Domain.Codes.find(cv => cv.Value === value.trim());
                    return codedValue && codedValue.Id || value;
                });

                if (product && product.Certificate === 1) {
                    let propValueWTWType = propWTWType.getValue(_warmteterugwinningAanvullendData);
                    let newWTWType = propValueWTWType === 'WARMTETYPE_GEEN' ? 'WARMTETYPE_REN1' : propValueWTWType;
                    if (!isHidden(propWTWType, _warmteterugwinningAanvullendData)) { saveValue(propWTWType, _warmteterugwinningAanvullendData, newWTWType); }

                    if (!isHidden(propWTWeta, _warmteterugwinningAanvullendData)) { ntaSharedLogic.saveProductValueToPropData(ventilatieLogic, product, 'eta_hr', _warmteterugwinningAanvullendData, propWTWeta.Id); }
                    if (!isHidden(propBypassA, _warmteterugwinningAanvullendData)) {
                        ntaSharedLogic.saveProductValueToPropData(ventilatieLogic, product, 'eta_bypass', _warmteterugwinningAanvullendData, propBypassA.Id);

                        //-- als waarde uit selectietabel voor eta_bypass gezet wordt, moet de waarde voor bypass op Eigenwaarde gezet worden.
                        //-- ook de wijze van bypass moet gezet worden naar eigen waarde, zodat de juiste bypass mee gaat naar de rekenkern
                        //-- dit doen we alleen voor de 3.1 projecten. De 3.0 projecten houden de verkeerde resultaten
                        if (ntabuilding.appVersionId >= 100) {
                            saveValue(propBypass, _warmteterugwinningAanvullendData, 'WERMTEBYP_EIG');
                        }
                    }
                }
            }
            if (_selectionTable.ZRV && _selectionTable.ZRV.length > 0) {
                let propToevoer = _voorverwarmingProperties['VOORWARM_NT'];
                let propValueToevoer = propToevoer.getValue(_voorverwarmingData);
                if (propValueToevoer === 'VOORWARMNT_PROD') {
                    let propMaxV = _voorverwarmingProperties["VOORWARM_MAXV"];
                    let propMaxT = _voorverwarmingProperties["VOORWARM_MAXT"];
                    let propBuit = _voorverwarmingProperties["VOORWARM_BUIT"];
                    let propMaxI = _voorverwarmingProperties["VOORWARM_MAXI"];

                    let propRooster = _voorverwarmingProperties['VOORWARM_ROO'];
                    let product = getListItem(propRooster, _voorverwarmingData);

                    if (!isHidden(propMaxV, _voorverwarmingData)) { ntaSharedLogic.saveProductValueToPropData(ventilatieLogic, product, 'PelvvMax', _voorverwarmingData, propMaxV.Id); }
                    if (!isHidden(propMaxT, _voorverwarmingData)) { ntaSharedLogic.saveProductValueToPropData(ventilatieLogic, product, 'deltaTprehRoostersMax', _voorverwarmingData, propMaxT.Id); }
                    if (!isHidden(propBuit, _voorverwarmingData)) { ntaSharedLogic.saveProductValueToPropData(ventilatieLogic, product, 'Taan', _voorverwarmingData, propBuit.Id); }
                    if (!isHidden(propMaxI, _voorverwarmingData)) { ntaSharedLogic.saveProductValueToPropData(ventilatieLogic, product, 'TsuplElvvMax', _voorverwarmingData, propMaxI.Id); }
                }

            }
        } //-- end: setValuesDependingOnSelectionTable ------------------------------------------------------------------------//

        function validate(prop, propdata, entdata) {
            if (!ntabuilding.canSave()) return;

            if (typeof prop === 'string') {
                prop = ntabuilding.properties[prop];
                if (!propdata && entdata) propdata = prop.getData(entdata);
            }
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            let hidden = isHidden(prop, entdata);
            let readonly = isReadOnly(prop, entdata);
            let valid = ntaValidation.IsValid(ventilatieLogic.form_installationventilatie, prop, propdata, hidden, readonly);

            switch (prop.Id) {
                case "VENT_SYSVAR":
                case "VENTAAN_SYSVAR":
                    {
                        let entdata = _ventData;
                        let propSysId = 'VENT_SYS';
                        let propInvoerId = 'VENT_INVOER';
                        if (prop.Id === "VENTAAN_SYSVAR") {
                            entdata = _ventAanvullendData;
                            propSysId = 'VENTAAN_SYS';
                            propInvoerId = 'VENTAAN_INVOER';
                        }

                        let ventilatiesysteem = entdata.PropertyDatas[propSysId].Value;
                        let invoer = entdata.PropertyDatas[propInvoerId].Value;
                        let valid = true;

                        if (ventilatiesysteem && invoer === 'VENT_PROD') {
                            let producten = entdata.filteredItems;

                            if (producten && producten.length <= 0) { // Toon E013
                                valid = false;
                            }
                        }

                        ntaSharedLogic.setMelding("[E013]", propdata, ventilatieLogic.form_installationventilatie, valid)

                        break;
                    }

                default:
            }


            return valid;
        } //-- end: validate ------------------------------------------------------------------------//

        function saveValue(prop, entdata, newValue) {
            ntaSharedLogic.saveValue(prop, entdata, newValue, ventilatieLogic);
        } //-- end: saveValue ------------------------------------------------------------------------//

        function saveValueSelectionTable(prop, item, entdata) {
            ntaSharedLogic.saveValueSelectionTable(prop, item, entdata, ventilatieLogic);
        } //-- end: saveValueSelectionTable ------------------------------------------------------------------------//

        function setDescriptionInstallation(entdata) {
            //-- ik moet eerst weten of het hier om de gewone ventData gaat en niet de aanvullende
            if (entdata.EntityId === 'VENT') {
                const propdataInstallDescr = ntaEntityData.get(_installationId).PropertyDatas['INSTALL_OMSCHR'];
                if (propdataInstallDescr) {
                    let descr = "";
                    const propVariant = _ventProperties['VENT_SYSVAR'];
                    const systeemvariant = parseInt(propVariant.getValue(entdata));
                    const product = systeemvariant && _selectionTable.VE.find(p => p.Id === systeemvariant);
                    if (product) {
                        descr = product.Value;
                    }
                    ntaEntityData.saveprop(propdataInstallDescr, descr);
                }
            }
        } //-- end: setDescriptionInstallation ------------------------------------------------------------------------//

        function propertyHeaderIsHidden(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            let showIt = true;
            switch (prop.Id) {
                case 'VENTILATOREIG_DEB': // V40
                    {
                        showIt = conditieRD(entdata);
                        break;
                    }
                case 'VENTILATOREIG_JAAR': // V42
                    {
                        // Conditie FH
                        showIt = conditieFH(entdata) && ntaSharedLogic.getBouwjaar() < 2005;
                        break;
                    }
                case 'VENTILATOREIG_U': // V43
                case 'VENTILATOREIG_I': // V44
                case 'VENTILATOREIG_E': // V45
                    {
                        let propValueInvoerVentilatorVermogen = entdata.PropertyDatas['VENTILATOR_INV'].Value;
                        showIt = propValueInvoerVentilatorVermogen === 'VENTILATORFABR_E'
                        break;
                    }
                case 'VENTILATOREIG_PAS': // V46
                case 'VENTILATOREIG_PNA': // V47
                    {
                        let propValueInvoerVentilatorVermogen = entdata.PropertyDatas['VENTILATOR_INV'].Value;
                        showIt = propValueInvoerVentilatorVermogen === 'VENTILATORFABR_D' || propValueInvoerVentilatorVermogen === 'VENTILATORFABR_E';

                        if (prop.Id === 'VENTILATOREIG_PNA') {
                            showIt = showIt && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                        }

                        break;
                    }
                case 'VENTILATOREIG_PNB': // V48
                    {
                        let propValueInvoerVentilatorVermogen = entdata.PropertyDatas['VENTILATOR_INV'].Value;
                        showIt = propValueInvoerVentilatorVermogen === 'VENTILATORFABR_B' || propValueInvoerVentilatorVermogen === 'VENTILATORFABR_C';
                        break;
                    }
                case 'VENTILATOREIG_FREG': {
                    showIt = true; //-- property is altijd zichtbaar. Hier alleen uitzoeken of hij relevant is of niet.

                    let invoerVentilatorVermogen = entdata.PropertyDatas['VENTILATOR_INV'].Value;
                    const relevant = invoerVentilatorVermogen === 'VENTILATORFABR_C';

                    //[MW-C] verberg veld als formulier wordt gebruikt bij HVAC maatregel EN waarde uit rekenkern komt (kan ook eigen invoer zijn)
                    if (!relevant) { // is nominaal ventilator vermogen - fregfan eigen waarde (VENTILATORFABR_C)
                        showIt = showIt && !ntaSharedLogic.isEditingMeasure();
                    }

                    break;
                }
                default:
            }

            return !showIt;
        } //-- end: propertyHeaderIsHidden ------------------------------------------------------------------------//

        function isHidden(prop, entdata) {
            if (typeof prop === 'string') {
                prop = ntabuilding.properties[prop];
            }
            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return true;
            }

            const propdata = entdata.PropertyDatas[prop.Id];

            let showit = true;
            let relevant = null;

            switch (prop.Id) {
                case 'VENT_OPEN': {
                    showit = false;
                    break;
                }
                case 'WARMTETERUG_FABR':// V27
                    if (!ntaSharedLogic.isUtiliteit()) {
                        // Conditie WF
                        let propBypass = _warmteterugwinningProperties['WARMTETERUG_BYP'];
                        let propValueBypass = propBypass.getValue(entdata);

                        let sitA = !isHidden(propBypass, entdata) && (propValueBypass === 'WARMTEBYP_BEK' || propValueBypass === 'WARMTEBYP_ONB');
                        let sitB = ntaSharedLogic.getBouwjaar() < 2010;

                        showit = sitA && sitB;

                        if (!showit && sitA) {
                            /* Als V26='bypass bekend - bypassaandeel onbekend' of 'bypass onbekend' (ID 3 en 4 in table-bypass) AND G06≥2010 dan gebruik waarde ' fabricagejaar 2010 en nieuwer' en verberg veld*/
                            let newFabrJaar = 'WARMTEFABR_NIEUW';
                            relevant = true;
                            saveValue(prop, entdata, newFabrJaar);
                        }
                    } else {
                        showit = false;
                    }
                    break;
                case 'VENT_VERB':  // V04
                case 'VENTAAN_VERB': { // V10
                    // conditie [SB] en [SW]
                    showit = conditieSB() && conditieSW();
                    break;
                }
                case 'VENT_VARIANT': // V07
                    {
                        // conditie ST
                        let invoerVentilatiesysteem = _ventData.PropertyDatas['VENT_INVOER'].Value;

                        showit = invoerVentilatiesysteem === 'VENT_PROD';
                        relevant = true;

                        break;
                    }
                case 'VENT_GEM': { // V83 <-- VENT_GEM is property voor gemeenschappelijke of niet-gemeenschappelijke installatie (is identiek aan VENT_LBK, alleen de teksten zijn anders)
                    // Condities [SY] en [SAB]
                    const ventilatiesysteemtype = entdata.PropertyDatas['VENT_SYS'].Value;
                    // Vóór NTAVersionId 34 bestond VENT_LBK niet, en moest VENT_GEM altijd weergegeven worden.
                    showit = conditieSY(entdata) && (ntabuilding.ntaVersionId < 34 || ventilatiesysteemtype === 'VENTSYS_NATMECH');
                    break;
                }
                case 'VENT_LBK': { // V83 <-- VENT_LBK is property voor luchtbehandelingskast aanwezig wel of niet (is identiek aan VENT_GEM, alleen de teksten zijn anders)
                    // Condities [SY] en [SAB]
                    const ventilatiesysteemtype = entdata.PropertyDatas['VENT_SYS'].Value;
                    showit = conditieSY(entdata) && ventilatiesysteemtype !== 'VENTSYS_NATMECH';
                    break;
                }
                case 'VENT_OPP_GEM': { // V84
                    // Condities [SAE] en [SAF] toon veld als V83='gemeenschappelijke installatie' of 'luchtbehandelingskast aanwezig'  EN G04='appartement (voor bestaande bouw)' OF 'unit in utiliteitsgebouw (voor bestaande bouw)'
                    showit = !isHidden('VENT_GEM', entdata) && isLbkAanwezig() && ntaSharedLogic.isAppartementOfUtiliteitVoorBestaandeBouw();
                    break;
                }
                case 'VENT_OPP_LBK': { // V84
                    // Condities [SAE] en [SAF] toon veld als V83='gemeenschappelijke installatie' of 'luchtbehandelingskast aanwezig'  EN G04='appartement (voor bestaande bouw)' OF 'unit in utiliteitsgebouw (voor bestaande bouw)'
                    showit = !isHidden('VENT_LBK', entdata) && isLbkAanwezig() && ntaSharedLogic.isAppartementOfUtiliteitVoorBestaandeBouw();
                    break;
                }
                case 'VENT_PKOEL': {    // V72
                    // conditie [SAH] verberg als V03 = 'A. natuurlijke toe- en afvoer'
                    showit = _ventData.PropertyDatas['VENT_SYS'].Value !== 'VENTSYS_NAT';
                    break;
                }
                case 'VENTAAN_VARIANT':  // V13
                    {
                        let invoerVentilatiesysteem = _ventAanvullendData.PropertyDatas['VENTAAN_INVOER'].Value;

                        showit = invoerVentilatiesysteem === 'VENT_PROD';
                        relevant = true;

                        break;
                    }
                case 'VENT_SYSVAR':  // V06
                    {
                        //let invoerVentilatiesysteem = _ventData.PropertyDatas['VENT_INVOER'].Value;

                        //showit = invoerVentilatiesysteem !== 'VENT_EIG';
                        showit = true; //-- altijd zichtbaar ook bij eigen waarden. Dan moet er sowieso een variant keuze gemaakt worden obv forfaitaire producten ivm wtw
                        break;
                    }
                case 'VENT-VERB_OMSCHR':// V77
                    {
                        relevant = false;
                        break;
                    }
                case 'VENTAAN_SYSVAR': // V12
                    {
                        //let invoerVentilatiesysteem = _ventAanvullendData.PropertyDatas['VENTAAN_INVOER'].Value;

                        //showit = invoerVentilatiesysteem !== 'VENT_EIG';
                        showit = true;
                        break;
                    }
                case 'VOORWARM_NT': // V15
                    {
                        let hidden = voorverwarmingIsHidden(_ventilatieKolom) && voorverwarmingIsHidden(_ventilatieAanvullendKolom);
                        showit = !hidden;
                        break;
                    }
                case 'VOORWARM_ROO': // V16
                    {
                        let propNatuurlijkeToevoer = _voorverwarmingProperties['VOORWARM_NT'];
                        let propValueNatuurlijkeToevoer = propNatuurlijkeToevoer.getValue(_voorverwarmingData);

                        showit = !isHidden(propNatuurlijkeToevoer, entdata) && propValueNatuurlijkeToevoer === 'VOORWARMNT_PROD'
                        break;
                    }
                case 'VOORWARM_AAN': // V17
                    {
                        let natuurlijkeToevoer = _voorverwarmingData.PropertyDatas['VOORWARM_NT'].Value;

                        showit = !!natuurlijkeToevoer && natuurlijkeToevoer !== 'VOORWARMNT_GEEN'; // Conditie VC
                        break;
                    }
                case 'VOORWARM_MAXV':
                case 'VOORWARM_MAXT':
                case 'VOORWARM_MAXI':
                case 'VOORWARM_BUIT':
                    {
                        let natuurlijkeToevoer = _voorverwarmingData.PropertyDatas['VOORWARM_NT'].Value;
                        showit = natuurlijkeToevoer === 'VOORWARMNT_PROD' || natuurlijkeToevoer === 'VOORWARMNT_EIG';
                        break;
                    }
                case 'WARMTETERUG_TYPE': // V23
                    {
                        let ventilatiekolom = _ventilatiekolommen.find(vk => vk.warmteterugwinning.EntityDataId === entdata.EntityDataId);
                        if (ventilatiekolom) {
                            showit = !warmteterugwinningIsHidden(ventilatiekolom) && conditieWB(ventilatiekolom);
                        }

                        relevant = true;
                        break;
                    }
                case 'WARMTETERUG_DISS': //-- V25
                    {
                        // Conditie WD
                        let propWarmteterugwinningType = _warmteterugwinningProperties['WARMTETERUG_TYPE'];
                        let propValueWarmteterwinningType = propWarmteterugwinningType.getValue(entdata);
                        showit = !isHidden(propWarmteterugwinningType, entdata) && propValueWarmteterwinningType === 'WARMTETYPE_REN2';
                        break;
                    }
                case 'WARMTETERUG_BYP': //-- V26
                    // Conditie WN
                    {
                        showit = conditieWN(entdata);

                        // property kan ook relevant zijn als hij hidden is, zoals bijv. bij productspecifiek. Dit geldt alleen voor de 3.1 projecten.
                        // Dit zat al jaren fout en daarom laten we de resultaten voor de 3.0 project zoals ze waren en mag dit voor die projecten
                        // niet aangepast worden.
                        if (ntabuilding.appVersionId >= 100) {
                            const ventilatiekolom = _ventilatiekolommen.find(vk => vk.warmteterugwinning.EntityDataId === entdata.EntityDataId);
                            if (ventilatiekolom) {
                                relevant = !warmteterugwinningIsHidden(ventilatiekolom);
                            }
                        }

                        break;
                    }
                case 'WAMRTETERUG_BYPA': //-- V28
                    // Conditie WH
                    showit = conditieWH(entdata);
                    break;
                case 'WARMTETERUG_WTW': //-- V85
                    {
                        // Conditie [WO]
                        showit = false;
                        const propBypassAandeel = ntabuilding.properties['WAMRTETERUG_BYPA'];
                        let propValueBypassAandeel = propBypassAandeel.getValue(entdata);
                        propValueBypassAandeel = ntaSharedLogic.parseFloat(propValueBypassAandeel, 0);

                        const ventilatiekolom = _ventilatiekolommen.find(vk => vk.warmteterugwinning.EntityDataId === entdata.EntityDataId);
                        let invoerVentilatiesysteem;
                        if (ventilatiekolom.entityId === "VENT") {
                            const propInvoerVentilatiesysteem = ntabuilding.properties['VENT_INVOER'];
                            invoerVentilatiesysteem = propInvoerVentilatiesysteem.getValue(ventilatiekolom.entdata);
                        }
                        else {
                            const propInvoerVentilatiesysteem = ntabuilding.properties['VENTAAN_INVOER'];
                            invoerVentilatiesysteem = propInvoerVentilatiesysteem.getValue(ventilatiekolom.entdata);
                        }

                        const propTypeWarmteterugwinning = ntabuilding.properties['WARMTETERUG_TYPE'];
                        const typeWarmteterugwinning = propTypeWarmteterugwinning.getValue(entdata);

                        if (propValueBypassAandeel === 1 && !isHidden(propBypassAandeel, entdata) && (invoerVentilatiesysteem === 'VENT_PROD' ||
                            (!isHidden(propTypeWarmteterugwinning, entdata) && (typeWarmteterugwinning === 'WARMTETYPE_REN1' || typeWarmteterugwinning === 'WARMTETYPE_REN2' || typeWarmteterugwinning === 'WARMTETYPE_REN3')))) {
                            showit = true;
                        }

                        //-- als veld verborgen is "geen koudeterugwinning via WTW" doorgeven voor VERW-OPWEK_GEM
                        if (!showit) {
                            saveValue(prop, entdata, 'WARMTETERUG_WTW_NIET');
                        }

                        relevant = true; //-- altijd relevant
                        break;
                    }
                case 'WARMTETERUG_TOELI': //-- V29
                    showit = conditieWH(entdata);
                    break;
                case 'WARMTETERUG_REND': //-- V24
                    {
                        let ventilatiekolom = _ventilatiekolommen.find(vk => vk.warmteterugwinning.EntityDataId === entdata.EntityDataId);
                        if (ventilatiekolom) {
                            showit = !warmteterugwinningIsHidden(ventilatiekolom);
                        }

                        if (showit) {
                            // Conditie WL
                            let propWarmteterugwinningType = _warmteterugwinningProperties['WARMTETERUG_TYPE'];
                            let propValueWarmteterwinningType = propWarmteterugwinningType.getValue(entdata);
                            let hideIt = !!propValueWarmteterwinningType && propValueWarmteterwinningType === 'WARMTETYPE_GEEN' && !isHidden(propWarmteterugwinningType, entdata);
                            showit = !hideIt;
                        }
                        break;
                    }
                case 'WARMTETERUG_TOEL': //-- V30
                    {
                        // Conditie [WJ]
                        const toevoerkanaal = entdata.PropertyDatas['WARMTETERUG_TOELI'].Relevant ? entdata.PropertyDatas['WARMTETERUG_TOELI'].Value : '';
                        const sitA = toevoerkanaal === 'WARMTETOE_A' || toevoerkanaal === 'WARMTETOE_C' || toevoerkanaal === 'WARMTETOE_E';

                        // Conditie [SZ]
                        const gebouwType = ntaSharedLogic.getGebouwType();
                        const sit1 = gebouwType !== 'TGEB_APPGEB' && gebouwType !== 'TGEB_UTILIT' && !ntaSharedLogic.voorProjectwoningen();

                        const sit2 = !sit1 && ntaSharedLogic.perGebouw();

                        const sit3 = !sit1 && ntaSharedLogic.perGebouwEnAppartementOfUnit() && isLbkAanwezig();

                        const sitB = sit1 || sit2 || sit3;

                        showit = sitA && sitB;

                        break;
                    }
                case 'WARMTETERUG_TOEI': //-- V31
                    showit = conditieWK(entdata);
                    break;
                case 'WARMTETERUG_COE': //-- V32
                    showit = conditieWK(entdata);
                    break;
                case 'VENTILATOR_AAN': //-- V34
                    showit = conditieFA(entdata);
                    break;
                case 'VENTILATOR_INV': //-- V35
                    // conditie [FB] is omgekeerde van conditie [FA]
                    showit = !conditieFA(entdata);
                    break;
                case 'VENTILATOR_FABR': //-- V36
                    // Conditie FD
                    {
                        let ventilatiekolom = _ventilatiekolommen.find(vk => vk.ventilatoren.EntityDataId === entdata.EntityDataId);
                        if (ventilatiekolom) {
                            let propFabricagejaar = _warmteterugwinningProperties['WARMTETERUG_FABR'];
                            showit = isHidden(propFabricagejaar, ventilatiekolom.warmteterugwinning);
                        }

                        relevant = showit;

                        if (showit) {
                            // Conditie FC
                            let propInvoerVentilatorVermogen = _ventilatorenProperties['VENTILATOR_INV'];
                            let propValueInvoerVentilatorVermogen = propInvoerVentilatorVermogen.getValue(entdata);
                            showit = !isHidden(propInvoerVentilatorVermogen, entdata) && propValueInvoerVentilatorVermogen === 'VENTILATORFABR_A';
                            if (showit) {
                                if (ntaSharedLogic.getBouwjaar() >= 2010) {
                                    showit = false;
                                    let newVentilatorFabr = 'WARMTEFABR_NIEUW';
                                    saveValue(prop, entdata, newVentilatorFabr);
                                    relevant = true;
                                }
                            }
                        }
                        break;
                    }
                case 'VENTILATOR_TYPE': //-- V37
                    {
                        // Conditie FO
                        getCodedValues(prop, entdata); // update van de lijst mogelijke keuzes.
                        let propInvoerVentilatorVermogen = _ventilatorenProperties['VENTILATOR_INV'];
                        let propValueInvoerVentilatorVermogen = propInvoerVentilatorVermogen.getValue(entdata);
                        showit = !isHidden(propInvoerVentilatorVermogen, entdata) && propValueInvoerVentilatorVermogen === 'VENTILATORFABR_A';
                        if (showit) {
                            showit = ntaSharedLogic.getBouwjaar() < 2007;
                            if (!showit) {
                                let newVentilatorType = 'VENTILATORTYPE_GEL';
                                saveValue(prop, entdata, newVentilatorType);
                                relevant = true;
                            }
                        }
                        break;
                    }
                case 'VENTILATOREIG_DEB':
                    {   //-- V40
                        // Conditie RD
                        showit = conditieRD(entdata);
                        break;
                    }
                case 'VENTILATOREIG_JAAR': { //-- V42
                    // Conditie FH
                    const ventilatoren = ntaEntityData.getFirstParent(entdata, 'VENTILATOR');
                    if (ventilatoren) {
                        relevant = conditieFH(ventilatoren); //-- conditie FH verwacht een entdata van type VENTILATOR, hier komt type VENTILATOREIIG binnen, dus ff de parent zoeken.
                        showit = false;
                        if (relevant) {
                            //-- nu moet ik weten of mijn bouwjaar >= 2005, want dan is de prop ook hidden, maar wel relevant
                            showit = ntaSharedLogic.getBouwjaar() < 2005;
                            if (!showit) {
                                let newVentilatorJaar = 'VENTILATORJ_GR';
                                saveValue(prop, entdata, newVentilatorJaar);
                            }
                        }
                    }
                    break;
                }
                case 'VENTILATOREIG_U': // V43
                case 'VENTILATOREIG_I': // V44
                case 'VENTILATOREIG_E': { // V45
                    const ventilatoren = ntaEntityData.getFirstParent(entdata, 'VENTILATOR');
                    if (ventilatoren) {
                        let invoerVentilatorVermogen = ventilatoren.PropertyDatas['VENTILATOR_INV'].Value;
                        showit = invoerVentilatorVermogen === 'VENTILATORFABR_E';
                    }
                    break;
                }
                case 'VENTILATOREIG_PAS':
                case 'VENTILATOREIG_PNA': { //-- V46, V47
                    const ventilatoren = ntaEntityData.getFirstParent(entdata, 'VENTILATOR');
                    if (ventilatoren) {
                        let invoerVentilatorVermogen = ventilatoren.PropertyDatas['VENTILATOR_INV'].Value;
                        showit = invoerVentilatorVermogen === 'VENTILATORFABR_D' || invoerVentilatorVermogen === 'VENTILATORFABR_E';
                    }

                    if (prop.Id === 'VENTILATOREIG_PNA') {
                        showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    }
                    break;
                }
                case 'VENTILATOREIG_PNB': { //-- V48
                    const ventilatoren = ntaEntityData.getFirstParent(entdata, 'VENTILATOR');
                    if (ventilatoren) {
                        let invoerVentilatorVermogen = ventilatoren.PropertyDatas['VENTILATOR_INV'].Value;
                        showit = invoerVentilatorVermogen === 'VENTILATORFABR_B' || invoerVentilatorVermogen === 'VENTILATORFABR_C';
                    }
                    break;
                }
                case 'VENTILATOREIG_FREG': {
                    showit = true; //-- property is altijd zichtbaar. Hier alleen uitzoeken of hij relevant is of niet.

                    const ventilatoren = ntaEntityData.getFirstParent(entdata, 'VENTILATOR');
                    if (ventilatoren) {
                        let invoerVentilatorVermogen = ventilatoren.PropertyDatas['VENTILATOR_INV'].Value;
                        relevant = invoerVentilatorVermogen === 'VENTILATORFABR_C';
                    }

                    //[MW-C] verberg veld als formulier wordt gebruikt bij HVAC maatregel EN waarde uit rekenkern komt (kan ook eigen invoer zijn)
                    if (!relevant) { // is nominaal ventilator vermogen - fregfan eigen waarde (VENTILATORFABR_C)
                        showit = showit && !ntaSharedLogic.isEditingMeasure();
                    }

                    break;
                }
                case 'VENTILATOR_VOL': //-- V50
                    showit = conditieFE(entdata);
                    break;
                case 'VENTILATOR_FRENDONB': //-- ?? staat niet in formulieren.xlsx
                    showit = false; //-- deze property nooit laten zien. Wanneer hij relevant is moet de selectietabel de waarde voor frend;onb vullen. dit gebeurt in conditieFE
                    relevant = !conditieFE(entdata);
                    break;
                case 'VENTILATOR_PNC': //-- V52
                    showit = conditieFA(entdata) && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                case 'VENTILATOR_FREGB': //-- V53
                    showit = conditieFA(entdata) && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                case 'VENTDEB_CAP': //-- V55
                    showit = !werkelijkGeinstalleerdeVentilatiecapaciteitIsHidden();
                    if (showit) { //Zie Trello-kaartjes qzRPfBgX en UpzEqBi1
                        ntaEntityData.setEntityRelevancy(entdata, showit);
                        ntaEntityData.setEntityVisibility(entdata, showit);
                    };
                    break;
                case 'VENTDEB_CAPTAB': //-- Tabel V56
                    showit = !werkelijkGeinstalleerdeVentilatiecapaciteitTabelIsHidden();
                    if (showit) { //Zie Trello-kaartjes qzRPfBgX en UpzEqBi1
                        ntaEntityData.setEntityRelevancy(entdata, showit);
                        ntaEntityData.setEntityVisibility(entdata, showit);
                    };
                    break;
                case 'VENTCAP_ND': //-- V59
                    // Conditie DE
                    showit = conditieDE();
                    break;
                case 'VENTCAP_NV': //-- V60
                    {
                        // Conditie DF
                        let propNatuurlijkeToevoegVoorverwarmd = _voorverwarmingProperties['VOORWARM_NT'];
                        let propValueNatuurlijkeToevoerVoorverwarmd = propNatuurlijkeToevoegVoorverwarmd.getValue(_voorverwarmingData);
                        showit = !isHidden(propNatuurlijkeToevoegVoorverwarmd, _voorverwarmingData) && propValueNatuurlijkeToevoerVoorverwarmd !== 'VOORWARMNT_GEEN';
                        break;
                    }
                case 'VENTCAP_NAOS': //-- V61
                    showit = conditieDG();
                    break;
                case 'VENTCAP_MD': //-- V62
                    showit = conditieDH();
                    break;
                case 'VENTCAP_MV': //-- V63
                    showit = conditieDI();
                    break;
                case 'VENTDEB_ZBR': {
                    showit = conditieDN();
                    break;
                }
                case 'VENTDEB_ZBRTAB':
                    showit = conditieDO();
                    break;
                case 'VENTZBR_AANW':
                    showit = conditieDO();
                    break;
                case 'VENTZBR_AG':
                    showit = conditieDO();
                    break;
                case 'VENTDIS_C': //-- V66
                case 'VENTDIS_CVERW': //-- V67
                case 'VENTDIS_CKOEL': //-- V68
                case 'VENTDIS_LBK': //-- V69
                    {
                        showit = conditieRA();
                        break;
                    }
                case 'VENTDIS_REC': //-- V70
                    showit = conditieRG();
                    break;
                case 'VENTDIS_DEB': //-- V71
                    showit = conditieRC();
                    break;
                case 'VENTILATOR_PNOM1':
                case 'VENTILATOR_PNOM2':
                case 'VENTILATOR_PNOM3': {
                    // Pnom 1, 2 en 3 zijn alleen relevant als Pnom vanuit de rekenkern komt, dus VENTILATOR_PNC is niet hidden
                    let propPnom = _ventilatorenProperties['VENTILATOR_PNC'];

                    showit = false;
                    relevant = !isHidden(propPnom, entdata);
                    break;
                }
                case 'MEASURE-COSTS_INV':
                case 'MEASURE-COSTS_PER_M2': {
                    showit = false;
                    break;
                }
            }

            // standaard is relevant gelijk aan visible
            if (relevant === null) relevant = showit;

            const propdatas = [propdata, ...ntaSharedLogic.getUnitSpecificResultPropdatas(prop, entdata)];
            ntaEntityData.setPropdataStatus(propdatas, relevant, showit);

            return !showit;
        } //-- end: isHidden ------------------------------------------------------------------------//

        function isLbkAanwezig() { // V83
            return (!isHidden('VENT_GEM', _ventData) && _ventData.PropertyDatas['VENT_GEM'].Value === 'VENT_GEM_WEL')
                || (!isHidden('VENT_LBK', _ventData) && _ventData.PropertyDatas['VENT_LBK'].Value === 'VENT_LBK_WEL');
        }

        /* Condities ************************************************************************************************/
        function conditieDN() {
            //Conditie [DN] toon veld als versie = ge3-2 EN minimaal 1 van de gebruiksfunties Z14 in een van de rekenzones aangesloten op het ventilatiesysteem = sportfunctie (ID9 van table_uti)
            const unitRzGfs = ntaEntityData.findEntities(_ventData, "RZ.UNIT-RZ.UNIT-RZ-GF");
            return unitRzGfs.some(gf => gf.PropertyDatas['UNIT-RZ-GFID'].Value === 'GF_SPORT');
        }


        function conditieDO() {
            //Conditie [DO] toon tabel als V86 (VENTDEB_ZBR) = sportfunctie met zwembadruimte aanwezig
            const zbrProp = _ventilatiedebietProperties['VENTDEB_ZBR'];
            const propdata = zbrProp.getData(_ventilatiedebietData);

            if (!propdata.Relevant || propdata.Value !== 'VENTDEB_ZBR_AANW') { // als ZBR niet aanwezig is, alle props van de zbrs niet relvant maken
                const ventzbrs = ntaEntityData.getChildren(_ventilatiedebietData, "VENTZBR");
                const zbrPropdatas = ventzbrs.flatMap(ventzbr => ventzbr.PropertyDatas);
                ntaEntityData.setPropdataStatus(zbrPropdatas, false, false);
            }

            return propdata.Relevant && propdata.Value === 'VENTDEB_ZBR_AANW';
        }

        function conditieSY(entdata) {
            // [SY] toon veld indien V03 = B, C of Dc EN G04=appartementengebouw/appartement (voor bestaande bouw)/utiliteitsgebouw/unit in utiliteitsgebouw
            const ventilatiesysteemtype = entdata.PropertyDatas['VENT_SYS'].Value;
            const gebouwtype = ntaSharedLogic.getGebouwType();
            return ['VENTSYS_MECH', 'VENTSYS_NATMECH', 'VENTSYS_MECHC'].includes(ventilatiesysteemtype)
                && ['TGEB_APPGEB', 'TGEB_APP', 'TGEB_UTILIT', 'TGEB_UTILUNIT'].includes(gebouwtype);
        } //-- end: conditieSY ------------------------------------------------------------------------//

        function conditieWK(entdata) {
            //-- toon als V29 = 'toevoerkanaal geïsoleerd - type isolatie bekend - lengte bekend' of 'toevoerkanaal geïsoleerd - type isolatie bekend - lengte onbekend'
            const propdataToevoerkanaal = entdata.PropertyDatas['WARMTETERUG_TOELI']
            const toevoerkanaal = propdataToevoerkanaal.Value;

            const showIt = propdataToevoerkanaal.Visible && (toevoerkanaal === 'WARMTETOE_E' || toevoerkanaal === 'WARMTETOE_F');

            return showIt; //-- bij showIt True is de conditie geldig.
        } //-- end: conditieWK ------------------------------------------------------------------------//

        function conditieWB(ventilatiekolom) {
            //-- conditiewB begint met Toon....
            let showIt = false;

            let propInvoerId = 'VENT_INVOER';
            if (ventilatiekolom.isAanvullendSysteem) {
                propInvoerId = 'VENTAAN_INVOER';
            }
            let propValueInvoer = ventilatiekolom.entdata.PropertyDatas[propInvoerId].Value;

            showIt = propValueInvoer === 'VENT_EIG' || propValueInvoer === 'VENT_FORF';

            // indien product -> het wtw.systeem moet staan op OverigEN13141 -> rendement uit selectietabel wordt daarmee overgenomen.
            if (propValueInvoer === 'VENT_PROD') {
                if (ventilatiekolom.warmteterugwinning.PropertyDatas['WARMTETERUG_TYPE'].Value !== 'WARMTETYPE_REN1') {
                    let propWTWType = _warmteterugwinningProperties['WARMTETERUG_TYPE'];
                    saveValue(propWTWType, ventilatiekolom.warmteterugwinning, 'WARMTETYPE_REN1');
                }
            }

            return showIt; //-- bij showIt True is de conditie geldig.
        } //-- end: conditieWB ------------------------------------------------------------------------//

        function conditieWH(entdata) {
            //-- conditieWH begint met Toon....
            let showIt = false;

            let propRendement = _warmteterugwinningProperties['WARMTETERUG_REND'];
            let propValueRendement = propRendement.getValue(entdata);
            propValueRendement = ntaSharedLogic.parseFloat(propValueRendement, 0);

            showIt = !isHidden(propRendement, entdata) && propValueRendement > 0;

            return showIt; //-- bij showIt True is de conditie geldig.
        } //-- end: conditieWH ------------------------------------------------------------------------//

        function conditieWN(entdata) {
            //-- Conditie WN is een combinatie van WB en WH. Conditie WN begint met Toon....
            let showIt = false;

            let ventilatiekolom = _ventilatiekolommen.find(vk => vk.warmteterugwinning.EntityDataId === entdata.EntityDataId)
            if (ventilatiekolom) {
                showIt = conditieWB(ventilatiekolom) && conditieWH(entdata);
            }

            return showIt;  //-- bij showIt True is de conditie geldig.
        } //-- end: conditieWN ------------------------------------------------------------------------//

        function conditieFA(entdataVentilator) {
            //-- Conditie [FA], omgekeerd conditie [FB]. Conditie begint met Toon......
            const isHoofdsys = !ntaSharedLogic.hasRelation(_ventAanvullendData, entdataVentilator);
            const entdataVent = isHoofdsys ? _ventData : _ventAanvullendData;
            const propIdSysVar = isHoofdsys ? 'VENT_SYSVAR' : 'VENTAAN_SYSVAR';
            const productId = parseInt(entdataVent.PropertyDatas[propIdSysVar].Value);

            let showIt = false;
            if (productId) {
                const product = _selectionTable.VE.find(ve => ve.Id === productId);

                showIt = Boolean(product)
                    && (product.Pnom1 !== null || product.Pnom2 !== null || product.Pnom3 !== null);
            }

            return showIt; //-- bij showIt True is de conditie geldig.
        } //-- end: conditieFA ------------------------------------------------------------------------//

        function conditieFE(entdata) {
            //-- Conditie [FE] begint met Toon.....
            let showIt = false;

            let ventilatiekolom = _ventilatiekolommen.find(vk => vk.ventilatoren.EntityDataId === entdata.EntityDataId)

            if (ventilatiekolom) {
                let propSysVarId = 'VENT_SYSVAR';
                let properties = _ventProperties;
                if (ventilatiekolom.isAanvullendSysteem) {
                    propSysVarId = 'VENTAAN_SYSVAR';
                    properties = _ventAanvullendProperties;
                }

                let propSysVar = properties[propSysVarId];
                let propValueVariant = propSysVar.getValue(ventilatiekolom.entdata);

                let propRendementWTW = _warmteterugwinningProperties['WARMTETERUG_REND'];
                if (!isHidden(propRendementWTW, ventilatiekolom.warmteterugwinning)) {
                    let propValueRendementWTW = propRendementWTW.getValue(ventilatiekolom.warmteterugwinning);
                    propValueRendementWTW = ntaSharedLogic.parseFloat(propValueRendementWTW);

                    propValueVariant = propValueVariant ? parseInt(propValueVariant) : 0;

                    let product = _selectionTable.VE.find(function (x) { return x.Id === propValueVariant; });
                    let bFrend_onb = false;
                    if (product) {
                        bFrend_onb = product.frend_onb === null;
                        if (!bFrend_onb) {
                            ///-- de selectietabel heeft een waarde voor frend;onb. Deze waarde overnemen en in
                            ///-- property "VENTILATOR_FRENDONB" zetten, zodat de rekenkern deze waarde gebruikt indien nodig
                            let propFrendOnb = _ventilatorenProperties['VENTILATOR_FRENDONB'];
                            saveValue(propFrendOnb, ventilatiekolom.ventilatoren, product.frend_onb);
                        }
                    }

                    showIt = bFrend_onb && propValueRendementWTW > 0;
                }
            }

            return showIt;  //-- bij showIt True is de conditie geldig.
        } //-- end: conditieFE ------------------------------------------------------------------------//

        function conditieFH(entdata) {
            //-- als de conditie waar is geef hij true terug bij niet waar false; let op met hidden
            let result = false;
            const ventilatoren_0 = ntaEntityData.get(entdata.EntityDataId);
            if (ventilatoren_0) {
                const invoerVentilatorVermogen = ventilatoren_0.PropertyDatas['VENTILATOR_INV'].Value;
                result = (invoerVentilatorVermogen === 'VENTILATORFABR_D' || invoerVentilatorVermogen === 'VENTILATORFABR_E');
            }
            return result;
        } //-- end: conditieFH ------------------------------------------------------------------------//

        function conditieDE() {
            //-- conditieDE begint met Toon...
            let showIt = false;

            let propValueVentsys = _ventData.PropertyDatas['VENT_SYS'].Value;
            let propValueVentsysAanvullend = _ventAanvullendData.PropertyDatas['VENTAAN_SYS'].Value;
            let aanvullendSysteemZichtbaar = propValueVentsys === 'VENTSYS_GEC';
            showIt = propValueVentsys === 'VENTSYS_NAT' || propValueVentsys === 'VENTSYS_NATMECH';
            if (aanvullendSysteemZichtbaar && !showIt) {
                showIt = propValueVentsysAanvullend === 'VENTSYS_NAT' || propValueVentsysAanvullend === 'VENTSYS_NATMECH';
            }

            return showIt; //-- bij showIt True is de conditie geldig.
        } //-- end: conditieDE ------------------------------------------------------------------------//

        function conditieDG() {
            //-- Conditie DG begint met Toon...
            let showIt = false;

            let propValueVentsys = _ventData.PropertyDatas['VENT_SYS'].Value;
            let propValueVentsysAanvullend = _ventAanvullendData.PropertyDatas['VENTAAN_SYS'].Value;
            let aanvullendSysteemZichtbaar = propValueVentsys === 'VENTSYS_GEC';
            let sitA = propValueVentsys === 'VENTSYS_NAT' || propValueVentsys === 'VENTSYS_NATMECH';
            if (aanvullendSysteemZichtbaar && !sitA) {
                sitA = propValueVentsysAanvullend === 'VENTSYS_NAT' || propValueVentsysAanvullend === 'VENTSYS_NATMECH';
            }

            //-- Controleer of een rekenzone van het type AOS is
            let rekenzones = ntaEntityData.getListWithEntityId('RZ');

            let hasAOS = false;
            let index;
            for (index = 0; index < rekenzones.length; index++) {
                let zoneType = rekenzones[index].PropertyDatas['RZ_TYPEZ'].Value;

                if (zoneType === 'AOS') {
                    hasAOS = true;
                    break;
                }
            }

            let sitB = hasAOS;

            showIt = sitA && sitB;

            return showIt;   //-- bij showIt True is de conditie geldig.
        } //-- end: conditieDG ------------------------------------------------------------------------//

        function conditieDH() {
            //-- ConditieDH begint met Toon...
            let showit = false;

            // V03-V09 en V24 controleren
            let bSitA = false;

            // V03 controleren
            let ventsys = _ventData.PropertyDatas['VENT_SYS'].Value;
            let validSys = (ventsys === 'VENTSYS_MECH' || ventsys === 'VENTSYS_MECHC' || ventsys === 'VENTSYS_MECHD' || ventsys === 'VENTSYS_GEC') ;

            // V09 controleren
            let aanvullendSysteemZichtbaar = ventsys === 'VENTSYS_GEC';
            let ventsysAanvullend = _ventAanvullendData.PropertyDatas['VENTAAN_SYS'].Value;
            let validSysAanvullend = aanvullendSysteemZichtbaar && (ventsysAanvullend === 'VENTSYS_MECH' || ventsysAanvullend === 'VENTSYS_MECHC' || ventsysAanvullend === 'VENTSYS_MECHD' || ventsysAanvullend === 'VENTSYS_GEC');

            // V24, systeem
            let valueRendement = 0;
            let propRendement = _warmteterugwinningProperties['WARMTETERUG_REND'];
            if (!isHidden(propRendement, _warmteterugwinningData) && !warmteterugwinningIsHidden(_ventilatieKolom)) {
                let rendement = propRendement.getValue(_warmteterugwinningData);
                if (rendement) {
                    valueRendement = ntaSharedLogic.parseFloat(rendement);
                }
            }
            let valueRendementAanv = 0;
            if (aanvullendSysteemZichtbaar) {
                // V24, aanvullend systeem
                if (!isHidden(propRendement, _warmteterugwinningAanvullendData) && !warmteterugwinningIsHidden(_ventilatieAanvullendKolom) && !kolomIsHidden(_ventilatieAanvullendKolom)) {
                    let rendementAanvullend = propRendement.getValue(_warmteterugwinningAanvullendData);
                    if (rendementAanvullend) {
                        valueRendementAanv = ntaSharedLogic.parseFloat(rendementAanvullend);
                    }
                }
            }

            bSitA = validSys && valueRendement === 0;
            if (!bSitA && aanvullendSysteemZichtbaar) {
                bSitA = validSysAanvullend && valueRendementAanv === 0;
            }

            // V67 controleren
            let bSitB = false;
            let propVerwbat = _ventilatiedistributieProperties['VENTDIS_CVERW'];
            let propValueVerwbat =  propVerwbat.getValue(_ventilatiedistributieData);

            let batVerwHidden = isHidden(propVerwbat, _ventilatiedistributieData);
            if ((!batVerwHidden && propValueVerwbat === 'VENTDIS_CVERW_GEEN') || (batVerwHidden)) {
                bSitB = true;
            }

            //// V68 controleren
            //let bSitC = false;
            //let propKoelbat = _ventilatiedistributieProperties['VENTDIS_CKOEL'];
            //let propValueKoelbat = propKoelbat.getValue(_ventilatiedistributieData);

            //if (isHidden(propKoelbat, _ventilatiedistributieData) || (!!propValueKoelbat && propValueKoelbat === 'VENTDIS_CKOEL_GEEN')) {
            //    bSitC = true;
            //}

            showit = bSitA && bSitB/* && bSitC*/;

            return showit; //-- bij showIt True is de conditie geldig.
        } //-- end: conditieDH ------------------------------------------------------------------------//

        function conditieDI() {
            //-- Conditie [DI] begint met Toon.....
            let showit = false;

            // V03-V09 en V24 controleren
            let bSitA = false;
            let propdataVentsys = _ventData.PropertyDatas['VENT_SYS'];
            let propdataVentsysAanv = _ventAanvullendData.PropertyDatas['VENTAAN_SYS'];
            let validSys = false;
            if (propdataVentsys) {
                validSys = propdataVentsys.Value === 'VENTSYS_MECH' || propdataVentsys.Value === 'VENTSYS_MECHC' || propdataVentsys.Value === 'VENTSYS_MECHD' || propdataVentsys.Value === 'VENTSYS_GEC';
            }
            if (!validSys && !kolomIsHidden(_ventilatieAanvullendKolom)) {
                if (propdataVentsysAanv) {
                    validSys = propdataVentsysAanv.Value === 'VENTSYS_MECH' || propdataVentsysAanv.Value === 'VENTSYS_MECHC' || propdataVentsysAanv.Value === 'VENTSYS_MECHD' || propdataVentsysAanv.Value === 'VENTSYS_GEC';
                }
            }

            let valueRendement = 0;
            let propRendement = _warmteterugwinningProperties['WARMTETERUG_REND'];
            if (!warmteterugwinningIsHidden(_ventilatieKolom) && !isHidden(propRendement, _warmteterugwinningData)) {
                let propdataRendement = _warmteterugwinningData.PropertyDatas[propRendement.Id];
                if (propdataRendement && propdataRendement.Value) {
                    valueRendement = ntaSharedLogic.parseFloat(propdataRendement.Value);
                }
            }
            let valueRendementAanv = 0;
            if (!warmteterugwinningIsHidden(_ventilatieAanvullendKolom) && !isHidden(propRendement, _warmteterugwinningAanvullendData) && !kolomIsHidden(_ventilatieAanvullendKolom)) {
                let propdataRendementAanv = _warmteterugwinningAanvullendData.PropertyDatas[propRendement.Id];
                if (propdataRendementAanv && propdataRendementAanv.Value) {
                    valueRendementAanv = ntaSharedLogic.parseFloat(propdataRendementAanv.Value);
                }
            }
            bSitA = validSys && (valueRendement > 0 || valueRendementAanv > 0);

            // V67 controleren
            let bSitB = false;
            let propVerwbat = _ventilatiedistributieProperties['VENTDIS_CVERW'];
            let propValueVerwbat = propVerwbat.getValue(_ventilatiedistributieData);

            if (!isHidden(propVerwbat, _ventilatiedistributieData) && propValueVerwbat === 'VENTDIS_CVERW_WEL') {
                bSitB = true;
            }

            // V68 controleren
            let bSitC = false;
            let propKoelbat = _ventilatiedistributieProperties['VENTDIS_CKOEL'];
            let propValueKoelbat = propKoelbat.getValue(_ventilatiedistributieData);

            if (!isHidden(propKoelbat, _ventilatiedistributieData) && propValueKoelbat === 'VENTDIS_CKOEL_WEL') {
                bSitC = true;
            }

            showit = bSitA || bSitB || bSitC;

            return showit; //-- bij showIt True is de conditie geldig.
        } //-- end: conditieDI ------------------------------------------------------------------------//

        function conditieRA() {
            // Conditie RA begint met Toon...
            let showIt = false;

            let propValueVentsys = _ventData.PropertyDatas['VENT_SYS'].Value;
            let propValueVentsysAanvullend = _ventAanvullendData.PropertyDatas['VENTAAN_SYS'].Value;
            let aanvullendSysteemZichtbaar = propValueVentsys === 'VENTSYS_GEC';
            let sitA = propValueVentsys === 'VENTSYS_MECH' || propValueVentsys === 'VENTSYS_MECHC';
            if (aanvullendSysteemZichtbaar && !sitA) {
                sitA = propValueVentsysAanvullend === 'VENTSYS_MECH' || propValueVentsysAanvullend === 'VENTSYS_MECHC';
            }

            const sitB = isLbkAanwezig();

            showIt = sitA && sitB;
            return showIt;  //-- bij showIt True is de conditie geldig.
        } //-- end: conditieRA ------------------------------------------------------------------------//

        function conditieRC() {
            //--- Conditie RC begint met Toon...
            let showIt = false;

            let propValueVentsys = _ventData.PropertyDatas['VENT_SYS'].Value;
            let propValueVentsysAanvullend = _ventAanvullendData.PropertyDatas['VENTAAN_SYS'].Value;
            let aanvullendSysteemZichtbaar = propValueVentsys === 'VENTSYS_GEC';
            let sitA = propValueVentsys === 'VENTSYS_MECH' || propValueVentsys === 'VENTSYS_MECHC';
            if (aanvullendSysteemZichtbaar && !sitA) {
                sitA = propValueVentsysAanvullend === 'VENTSYS_MECH' || propValueVentsysAanvullend === 'VENTSYS_MECHC';
            }

            let sitB = isLbkAanwezig();

            let sitC = false;
            if (!werkelijkGeinstalleerdeVentilatiecapaciteitIsHidden()) {
                let propVentcap = _ventilatiedebietProperties['VENTDEB_CAP']; // V55
                let propValueVentcap = propVentcap.getValue(_ventilatiedebietData);
                sitC = propValueVentcap === 'VENTDEBCAP_BEK';
            }

            showIt = sitA && sitB && sitC;

            return showIt; //-- bij showIt True is de conditie geldig.
        } //-- end: conditieRC ------------------------------------------------------------------------//

        function conditieRD(entdata) {
            //-- conditie RD begint met Toon....

            let propInvVentVermogen = _ventilatorenProperties['VENTILATOR_INV'];
            let propDebietRegeling = _ventilatiedistributieProperties['VENTDIS_DEB'];
            let propValueDebietRegeling = propDebietRegeling.getValue(_ventilatiedistributieData);
            let propValueInvVentVermogen = null;

            if (entdata.EntityId === 'VENTILATOREIG') {
                const ventilatoren = ntaEntityData.getFirstParent(entdata, 'VENTILATOR');
                if (ventilatoren) {
                    propValueInvVentVermogen = propInvVentVermogen.getValue(ventilatoren);
                }
            }
            if (entdata.EntityId === 'VENTILATOR') {
                propValueInvVentVermogen = propInvVentVermogen.getValue(entdata);
            }

            let bSitA = propValueInvVentVermogen === 'VENTILATORFABR_B' || propValueInvVentVermogen === 'VENTILATORFABR_D' || propValueInvVentVermogen === 'VENTILATORFABR_E';
            let bSitB = !isHidden(propDebietRegeling, _ventilatiedistributieData) && !!propValueDebietRegeling && propValueDebietRegeling !== 'VENTDIS_DEB_GEEN';

            return (bSitA && bSitB); //-- als de conditie klopt is het antwoord true
        } //-- end: conditieRD ------------------------------------------------------------------------//

        function conditieRG() {
            //-- Conditie RG begint met Toon....
            let showIt = false;

            let propValueVentsys = _ventData.PropertyDatas['VENT_SYS'].Value;
            let propValueVentsysAanvullend = _ventAanvullendData.PropertyDatas['VENTAAN_SYS'].Value;
            let aanvullendSysteemZichtbaar = propValueVentsys === 'VENTSYS_GEC';
            let sitA = propValueVentsys === 'VENTSYS_MECHC';
            if (aanvullendSysteemZichtbaar && !sitA) {
                sitA = propValueVentsysAanvullend === 'VENTSYS_MECHC';
            }
            let propVentcap = _ventilatiedebietProperties['VENTDEB_CAP']; // V55
            let propValueVentcap = propVentcap.getValue(_ventilatiedebietData);
            let sitB = propValueVentcap === 'VENTDEBCAP_BEK';

            if (!ntaSharedLogic.isUtiliteit() && sitB) {
                //-- bij woningbouw en ventilatiecapaciteit bekend moet ik ook nog checken of er een LBK aanwezig is
                sitB = isLbkAanwezig();
            }

            showIt = sitA && sitB;

            return showIt; //-- bij showIt True is de conditie geldig.
        } //-- end: conditieRG ------------------------------------------------------------------------//

        function werkelijkGeinstalleerdeVentilatiecapaciteitIsHidden() {
            //-- Conditie [DA] begint met Verberg...
            let hideIt = false;

            //conditie [DA] Als versie = ge3-2 verberg als in V89 alle rekenzones gekoppeld aan dit ventilatiesysteem zijn aangevinkt
            if (ntabuilding.appVersionId >= 200) {
                const ventzbrs = ntaEntityData.getChildren(_ventilatiedebietData, "VENTZBR");
                const prop = _ventzwembadruimteProperties['VENTZBR_AANW']
                return conditieDN() && conditieDO() && ventzbrs.every(ventzbr => !prop.getData(ventzbr).Relevant || prop.getData(ventzbr).Value === "True"); //conditieDO() en conditieDN() omdat zwembadruimtes uberhaupt aanwezig moeten zijn
            }

            // conditie [DA] alleen bij versie 3.0; vanaf 3.1 altijd weergeven
            if (ntabuilding.appVersionId >= 100) {
                return hideIt;
            }


            // Voorverwarming controleren
            if (voorverwarmingInDeelToevoerroosters()) {
                return hideIt;
            }

            // Systeem controleren
            let propSysteemInvoer = _ventProperties['VENT_INVOER'];
            let propSysteemvariant = _ventProperties['VENT_SYSVAR'];
            let propValueSysteemInvoer = propSysteemInvoer.getValue(_ventData);
            let propValueSysteemvariant = propSysteemvariant.getValue(_ventData);

            if (!ntaSharedLogic.isUtiliteit() && propValueSysteemvariant) {

                let selection = _selectionTable.VE.find(function (x) { return x.Id === parseInt(propValueSysteemvariant); });

                if (selection && selection.Vraaggestuurd) { // Vraaggestuurd is ja
                    hideIt = true;
                }
            }

            if (!hideIt) {
                // Ook nog het eventuele aanvullende systeem controleren
                let propAanvullendSysteemvariant = _ventAanvullendProperties['VENTAAN_SYSVAR'];
                let propValueAanvullendSysteemvariant = propAanvullendSysteemvariant.getValue(_ventAanvullendData);

                if (!ntaSharedLogic.isUtiliteit() && propValueAanvullendSysteemvariant && propValueSysteemInvoer === 'VENTSYS_GEC') {
                    let selection = _selectionTable.VE.find(function (x) { return x.Id === parseInt(propValueAanvullendSysteemvariant); });

                    if (selection && selection.Vraaggestuurd) { // Vraaggestuurd is ja
                        hideIt = true;
                    }
                }
            }

            return hideIt;
        } //-- end: werkelijkGeinstalleerdeVentilatiecapaciteitIsHidden ------------------------------------------------------------------------//

        function werkelijkGeinstalleerdeVentilatiecapaciteitTabelIsHidden() {
            //-- Conditie [DJ] begint met Toon....
            let showIt = false;

            // Werkelijk geinstalleerde ventilatiecapaciteiten controleren
            const propVentcap = _ventilatiedebietProperties['VENTDEB_CAP'];
            const propValueVentcap = propVentcap.getValue(_ventilatiedebietData);

            // Voorverwarming controleren
            const propAandeelVoorverwarming = _voorverwarmingProperties['VOORWARM_AAN'];
            const propValueAandeelVoorverwarming = propAandeelVoorverwarming.getValue(_voorverwarmingData);

            showIt = (!isHidden(propVentcap, _ventilatiedebietData) && propValueVentcap === 'VENTDEBCAP_BEK') ||
                     (!isHidden(propAandeelVoorverwarming, _voorverwarmingData) && propValueAandeelVoorverwarming === 'VOORWARMAAN_DEEL' && (!voorverwarmingIsHidden(_ventilatieKolom) || !voorverwarmingIsHidden(_ventilatieAanvullendKolom)));

            // Relevantie entiteiten ventcap
            const entdatas = _units.flatMap(unit => getUnitRekenzones(unit)).flatMap(unitRekenzone => getVentilatiecapaciteit(unitRekenzone)).filter(ed => ed);
            ntaEntityData.setEntityRelevancy(entdatas, showIt);
            ntaEntityData.setEntityVisibility(entdatas, showIt);
            return !showIt;
        } //-- end: werkelijkGeinstalleerdeVentilatiecapaciteitTabelIsHidden ------------------------------------------------------------------------//

        function kolomIsHidden(ventilatiekolom) {
            //-- Conditie [SB] en [SC]
            let isHidden = true;

            if (!ventilatiekolom) {
                return isHidden;
            }

            if (ventilatiekolom.isAanvullendSysteem) {
                const showKolom = conditieSB(); //-- alleen als kolom 1 een E-systeem is moet de kolom zichtbaar zijn.

                // Relevantie voor het aanvullende systeem zetten
                ntaEntityData.setEntityRelevancy(_ventAanvullendData, showKolom);
                ntaEntityData.setEntityVisibility(_ventAanvullendData, showKolom);

                // Relevantie voor de andere entiteiten van het aanvullende systeem zetten.
                // Dit is alleen nodig als de kolom hidden is. Als de kolom wel zichtbaar is, zullen de specifieke IsHidden methodes de entiteiten zichtbaar maken.
                // Als alles zichtbaar wordt gemaakt bij het zichtbaar zijn van de kolom treedt er een conflict met de IsHidden methodes op en kunnen entiteiten onterecht relevant en visible worden.
                if (!showKolom) {
                    const entdatasToUpdate = _ventAanvullendData.tempUnits.map(tempUnit => tempUnit.verblijfsgebied)
                        .concat(ventilatiekolom.warmteterugwinning)
                        .concat((ventilatiekolom.warmteterugwinning.tempUnits || []).map(tempUnit => tempUnit.toevoerkanaal))
                        .filter(ed => ed); // eventuele nulls eruit filteren
                    ntaEntityData.setEntityRelevancy(entdatasToUpdate, showKolom);
                    ntaEntityData.setEntityVisibility(entdatasToUpdate, showKolom);

                    ntaEntityData.setEntityRelevancy(ventilatiekolom.ventilatoren, showKolom);
                    setRelevancyVentilatorEigenschappen(ventilatiekolom, showKolom);
                }

                isHidden = !showKolom;

            } else {
                //-- Het ventilatiesysteem in de eerste kolom kan nooit verborgen zijn
                isHidden = false;
            }

            return isHidden;
        } //-- end: kolomIsHidden ------------------------------------------------------------------------//

        function verblijfsgebiedTabelIsHidden(ventilatiekolom) {
            //-- Conditie [SB] en [SX]

            if (!ventilatiekolom) {
                return true;
            }

            //-- Als de ventilatiekolom hidden is, moet de hele tabel hidden zijn
            const visible = !kolomIsHidden(ventilatiekolom)
                && conditieSB() && conditieSX();

            const verblijfsgebieden = ventilatiekolom.entdata.tempUnits
                .map(tempUnit => tempUnit.verblijfsgebied)
                .filter(gebied => gebied);
            ntaEntityData.setEntityRelevancy(verblijfsgebieden, visible);
            ntaEntityData.setEntityVisibility(verblijfsgebieden, visible);

            return !visible;
        } //-- end: verblijfsgebiedTabelIsHidden ------------------------------------------------------------------------//

        function toevoerkanaalTabelIsHidden(ventilatiekolom) {
            //--- Conditie [SAA] begint met Toon...
            if (!ventilatiekolom) {
                return true;
            }

            let showIt = false;

            if (!(kolomIsHidden(ventilatiekolom) || warmteterugwinningIsHidden(ventilatiekolom))) {
                const sitA = ["TGEB_APPGEB", "TGEB_UTILIT"].includes(ntaSharedLogic.getGebouwType()) && ntaSharedLogic.perGebouwEnAppartementOfUnit()
                    || ntaSharedLogic.voorProjectwoningen();

                const wtwToevLeidIso = ventilatiekolom.warmteterugwinning.PropertyDatas['WARMTETERUG_TOELI'].Relevant ? ventilatiekolom.warmteterugwinning.PropertyDatas['WARMTETERUG_TOELI'].Value : '';
                const sitB = ['WARMTETOE_A', 'WARMTETOE_C', 'WARMTETOE_E'].includes(wtwToevLeidIso);

                //-- controleren of er geen luchtbehandelingskast aanwezig is. Voorheen heette dit "niet-gemeenschappelijk installatie"
                const sitC = !isLbkAanwezig();

                const sitD = _ventData.PropertyDatas['VENT_SYS'].Value === 'VENTSYS_GEC';

                showIt = sitA && sitB && (sitC || sitD);
            }

            const toevoerkanalen = ventilatiekolom.warmteterugwinning.tempUnits.map(tempUnit => tempUnit.toevoerkanaal);
            ntaEntityData.setEntityRelevancy(toevoerkanalen, showIt);
            ntaEntityData.setEntityVisibility(toevoerkanalen, showIt);

            return !showIt;
        } //-- end: toevoerkanaalTabelIsHidden ------------------------------------------------------------------------//

        function voorverwarmingIsHidden(ventilatiekolom) {
            // Conditie VA begint met Toon...
            if (!ventilatiekolom) {
                return true;
            }

            let na2010 = ntaSharedLogic.getBouwjaar() >= 2010;

            let propSysId = ventilatiekolom.isAanvullendSysteem ? 'VENTAAN_SYS' : 'VENT_SYS';
            let propValueSys = ventilatiekolom.entdata.PropertyDatas[propSysId].Value;

            //-- Alleen tonen als het systeem A of C is en het bouwjaar na 2010 is
            let showIt = (propValueSys === 'VENTSYS_NAT' || propValueSys === 'VENTSYS_NATMECH') && na2010;

            //-- Voorwaarde is wel dat de ventilatiekolom zichtbaar moet zijn, wat in het geval van aanvullende kolom ff gecheckt moet worden
            if (ventilatiekolom.isAanvullendSysteem) {
                showIt = showIt && _ventData.PropertyDatas['VENT_SYS'].Value === 'VENTSYS_GEC';
            }

            //-- voorverwarming geldt OF voor het gewone systeem OF voor het aanvullende systeem. Als hij voor géén van beide geldt is relevant=false, ander true
            let relevant = showIt;
            if (!relevant) {
                //-- als voorverwarming niet relevant is voor deze ventilatiekolom, kan hij nog relevant zijn voor de andere ventilatiekolom
                let andereVentilatiekolom = _ventilatiekolommen.find(function (x) { return x.isAanvullendSysteem !== ventilatiekolom.isAanvullendSysteem; });
                propSysId = andereVentilatiekolom.isAanvullendSysteem ? 'VENTAAN_SYS' : 'VENT_SYS';
                propValueSys = andereVentilatiekolom.entdata.PropertyDatas[propSysId].Value;
                relevant = !kolomIsHidden(andereVentilatiekolom) && (propValueSys === 'VENTSYS_NAT' || propValueSys === 'VENTSYS_NATMECH') && na2010;
            }

            ntaEntityData.setEntityRelevancy(ventilatiekolom.voorverwarming, relevant);
            ntaEntityData.setEntityVisibility(ventilatiekolom.voorverwarming, relevant);

            return !showIt;
        } //-- end: voorverwarmingIsHidden ------------------------------------------------------------------------//

        function warmteterugwinningIsHidden(ventilatiekolom) {
            //-- Conditie WA begint met Toon...
            if (!ventilatiekolom) {
                return true;
            }

            // Als het systeem hidden is, moet de hele kolom hidden zijn
            let isHidden = kolomIsHidden(ventilatiekolom);
            let showIt = !isHidden;

            if (showIt) {
                let propSysId = ventilatiekolom.isAanvullendSysteem ? 'VENTAAN_SYS' : 'VENT_SYS';
                let propSysVarId = ventilatiekolom.isAanvullendSysteem ? 'VENTAAN_SYSVAR' : 'VENT_SYSVAR';

                let propValueSys = ventilatiekolom.entdata.PropertyDatas[propSysId].Value;
                let propValueSysVar = ventilatiekolom.entdata.PropertyDatas[propSysVarId].Value;

                let product = null;
                if (propValueSysVar) {
                    const productTypeInfo = _productTypes.find(pt => pt.productIdPropertyId === propSysVarId);
                    const table = productTypeInfo ? _selectionTable[productTypeInfo.modelType] : null;
                    if (table && table.length > 0) {
                        propValueSysVar = parseInt(propValueSysVar);
                        product = table.find(function (x) { return x.Id === propValueSysVar; });
                    }
                }

                let sitA = propValueSys === 'VENTSYS_MECHC' || propValueSys === 'VENTSYS_MECHD' || propValueSys === 'VENTSYS_GEC';
                let sitB = !!product && product.Variant.trim() !== 'D.1';

                showIt = sitA && sitB;
            }

            //-- VO 2021-04-08: Als warmteterugwinning niet zichtbaar en niet relevant is, geldt dit ook voor de toevoerkanalen. Als WTW wel
            //-- zichtbaar is, zorgt het formulier voor een check of de toevoerkananalen relevant zijn.
            if (!showIt) {
                const toevoerkanalen = ventilatiekolom.warmteterugwinning.tempUnits.map(tempUnit => tempUnit.toevoerkanaal);
                ntaEntityData.setEntityRelevancy(toevoerkanalen, showIt);
                ntaEntityData.setEntityVisibility(toevoerkanalen, showIt);
            }

            ntaEntityData.setEntityRelevancy(ventilatiekolom.warmteterugwinning, showIt);
            ntaEntityData.setEntityVisibility(ventilatiekolom.warmteterugwinning, showIt);

            return !showIt;
        } //-- end: warmteterugwinningIsHidden ------------------------------------------------------------------------//

        function ventilatorenIsHidden(ventilatiekolom) {
            //-- Conditie FN begint met velden verbergen....
            if (!ventilatiekolom) {
                return true;
            }

            let isHidden = false;

            // Als het systeem hidden is, moet de hele kolom hidden zijn
            if (kolomIsHidden(ventilatiekolom)) {
                isHidden = true;
            } else {
                let propSysId = ventilatiekolom.isAanvullendSysteem ? 'VENTAAN_SYS' : 'VENT_SYS';

                let propValueVentSys = ventilatiekolom.entdata.PropertyDatas[propSysId].Value;
                isHidden = propValueVentSys === 'VENTSYS_NAT';
            }
            ntaEntityData.setEntityRelevancy(ventilatiekolom.ventilatoren, !isHidden);
            ntaEntityData.setEntityVisibility(ventilatiekolom.ventilatoren, !isHidden);
            if (isHidden) {
                setRelevancyVentilatorEigenschappen(ventilatiekolom, false);
            }

            return isHidden;
        } //-- end: ventilatorenIsHidden ------------------------------------------------------------------------//

        function ventilatorEigenschappenIsHidden(ventilatiekolom) {
            //-- moet de tabel om eigenschappen voor ventilatoren zichtbaar zijn of niet.

            //-- Conditie [FG] begint met Toon...
            if (!ventilatiekolom) {
                return true;
            }

            let showIt = false;

            // Als het systeem hidden is, moet de hele kolom hidden zijn
            if (!kolomIsHidden(ventilatiekolom) && !ventilatorenIsHidden(ventilatiekolom)) {
                let propInvoerVentilatorVermogen = _ventilatorenProperties['VENTILATOR_INV'];
                let propValueInvoerVentilatorVermogen = propInvoerVentilatorVermogen.getValue(ventilatiekolom.ventilatoren);
                showIt = !isHidden(propInvoerVentilatorVermogen, ventilatiekolom.ventilatoren) && !!propValueInvoerVentilatorVermogen && propValueInvoerVentilatorVermogen !== 'VENTILATORFABR_A'
            }

            setRelevancyVentilatorEigenschappen(ventilatiekolom, showIt);

            return !showIt;
        } //-- end: ventilatorEigenschappenIsHidden ------------------------------------------------------------------------//

        function ventilatorEigenschappenUnitIsHidden(tempUnit, ventilatiekolom) {
            //-- VO 2021-05-04: de eigenschappentabel voor ventilatoren is zichtbaar, maar nu moet er per unit gecheckt worden of hij getoond mag worden of
            //-- niet. Wanneer er sprake is van een systeem voor een geheel gebouw is alleen de eerste unit zichtbaar. Wanneer het systeem per unit is
            //-- decentrale ventilatie, of niet-gemeenschappelijk of geen-luchtbehandelingskast dan moeten alle units zichtbaar zijn. Bij rekenen per gebouw is
            //-- er altijd maar één unit. Iedere unit kan meerdere ventilatoreigenschappen entiteiten hebben. (VENTILATOREIG)
            if (!tempUnit || !ventilatiekolom) {
                return true;
            }

            const hideIt = isLbkAanwezig() && ventilatiekolom.ventilatoren.tempUnits[0].EntityDataId !== tempUnit.EntityDataId;

            if (tempUnit.ventilatorEigenschappen) {
                tempUnit.ventilatorEigenschappen.forEach(function (ventilatoreigenschap, index) {
                    ntaEntityData.setEntityRelevancy(ventilatoreigenschap, !hideIt);
                    ntaEntityData.setEntityVisibility(ventilatoreigenschap, !hideIt);
                });
            }

            return hideIt;
        } //-- end: ventilatorEigenschappenUnitIsHidden ------------------------------------------------------------------------//

        function ventilatiedebietenIsHidden() {
            //-- Conditie DK
            let isHidden = werkelijkGeinstalleerdeVentilatiecapaciteitIsHidden() && werkelijkGeinstalleerdeVentilatiecapaciteitTabelIsHidden() && !conditieDN();

            ntaEntityData.setEntityRelevancy(_ventilatiedebietData, !isHidden);
            ntaEntityData.setEntityVisibility(_ventilatiedebietData, !isHidden);

            return isHidden;
        } //-- end: ventilatiedebietenIsHidden ------------------------------------------------------------------------//

        function distributieIsHidden() {
            //-- Conditie RE
            let ventilatiesysteem = _ventData.PropertyDatas['VENT_SYS'].Value;
            let isHidden = ventilatiesysteem === 'VENTSYS_NAT'; // V03 = A

            ntaEntityData.setEntityRelevancy(_ventilatiedistributieData, !isHidden);
            ntaEntityData.setEntityVisibility(_ventilatiedistributieData, !isHidden);

            return isHidden;
        } //-- end: distributieIsHidden ------------------------------------------------------------------------//

        function validateWarmteterugwinningProp(propName, entdata) {
            let prop = _warmteterugwinningProperties[propName];
            let propdata = prop.getData(entdata);
            if (propdata.Touched) {
                //-- Valideren om foutmelding direct te kunnen tonen
                validate(prop, propdata, entdata);
            }
        } //-- end: validateWarmteterugwinningProp ------------------------------------------------------------------------//

        function voorverwarmingInDeelToevoerroosters() {
            //-- Voorverwarming controleren
            let propAandeelVoorverwarming = _voorverwarmingProperties['VOORWARM_AAN'];
            let propValueAandeelVoorverwarming = propAandeelVoorverwarming.getValue(_voorverwarmingData);

            //-- V17 is 'voorverwarming in deel toevoerroosters'
            return ((propValueAandeelVoorverwarming === 'VOORWARMAAN_DEEL') &&
                (!voorverwarmingIsHidden(_ventilatieKolom) || !voorverwarmingIsHidden(_ventilatieAanvullendKolom)) &&
                (!isHidden(propAandeelVoorverwarming, _voorverwarmingData)));
        } //-- end: voorverwarmingInDeelToevoerroosters ------------------------------------------------------------------------//

        function setRelevancyVentilatorEigenschappen(ventilatiekolom, relevant) {
            //-- VO 2021-05-04: als de eigenschappen niet relevant zijn, kunnen alle eigenschappen meteen op false. Anders meot er gecontroleerd woreden
            //-- of de ventilatoreigenschap (VENTILATOREIG) wel zichtbaar moet zijn voor de unit.
            if (relevant) {
                for (const unit of _units) {
                    ventilatorEigenschappenUnitIsHidden(unit, ventilatiekolom);
                }
            } else {
                const ventilatoreigenschappen = (ventilatiekolom.ventilatoren.tempUnits || []).flatMap(tempUnit => tempUnit.ventilatorEigenschappen || []);
                ntaEntityData.setEntityRelevancy(ventilatoreigenschappen, relevant);
                ntaEntityData.setEntityVisibility(ventilatoreigenschappen, relevant);
            }
        } //-- end: setRelevancyVentilatorEigenschappen ------------------------------------------------------------------------//

        function getAangeslotenUnits() {
            /// VENT -> RZ -> UNIT-RZ <- UNIT
            const units = ntaEntityData.getChildren(_ventData, 'RZ')
                .flatMap(rzEntdata => ntaEntityData.getChildren(rzEntdata, 'UNIT-RZ'))
                .map(unitRzEntdata => ntaEntityData.getFirstParent(unitRzEntdata, 'UNIT'));
            return ntaEntityData.distinct(units)
                .sort(ntaSharedLogic.orderElements);
        } //-- end: getAangeslotenUnits ------------------------------------------------------------------------//

        function getUnitRekenzones(unit) {
            return unit && _unitRekenzonesPerUnit[unit.EntityDataId] || [];
        }

        function getVentilatiecapaciteit(unitRz) {
            return unitRz && _ventcapPerUnitRz[unitRz.EntityDataId] || [];
        }

        function getVentilatiezwembadruimte(rz) {
            return rz && _ventzbrPerRz[rz.EntityDataId] || [];
        }

        function buildVentcapaciteitenTabel() {
            // Voor elke aangesloten unitRekenzone hebben we een VENTCAP-entiteit nodig
            const unitRekenzones = ntaEntityData.getChildren(_ventData, 'RZ')
                .flatMap(rz => ntaEntityData.getChildren(rz, 'UNIT-RZ'));

            const ventcapIds = new Set(ntaEntityData.getChildIds(_ventData, 'VENTCAP'));

            for (const unitRz of unitRekenzones) {
                const unitRzVentcapIds = ntaEntityData.getChildIds(unitRz, 'VENTCAP');
                const ventcapId = unitRzVentcapIds.find(ventcapId => ventcapIds.has(ventcapId));
                let ventcap = ntaEntityData.get(ventcapId);
                if (!ventcap) {
                    const ventcapId = ntaEntityData.create('VENTCAP', -1, [
                        { "OnCopy": 1, "OnDelete": 1, "Parent": _ventDataId, "ParentEntityId": "VENT" },
                        { "OnCopy": 1, "OnDelete": 1, "Parent": unitRz.EntityDataId, "ParentEntityId": "UNIT-RZ" }
                    ], [], null);
                    ventcap = ntaEntityData.get(ventcapId);
                }
                _ventcapPerUnitRz[unitRz.EntityDataId] = [ventcap];
            }

            const unitRekenzoneIds = new Set(unitRekenzones.map(unitRz => unitRz.EntityDataId));
            for (const unit of _units) {
                _unitRekenzonesPerUnit[unit.EntityDataId] = ntaEntityData.getChildren(unit, 'UNIT-RZ').filter(unitRz => unitRekenzoneIds.has(unitRz.EntityDataId));
            }

            // Verwijder meldingen voor alle VENTCAP-entiteiten onder dit ventilatiesysteem die horen bij een UNIT-RZ die niet meer gekoppeld is aan dit ventilatiesysteem.
            for (const ventcapId of ventcapIds) {
                const isGekoppeld = ntaEntityData.getParentIds(ventcapId, 'UNIT-RZ')
                    .some(unitRzId => unitRekenzoneIds.has(unitRzId));
                if (!isGekoppeld) {
                    const ventcap = ntaEntityData.get(ventcapId);
                    for (const propdata of ventcap && ventcap.PropertyDatas || []) {
                        ntaValidation.IsValid(ventilatieLogic.form_installationventilatie, null, propdata, true);
                    }
                }
            }

        } //-- end: buildVentcapaciteitenTabel ------------------------------------------------------------------------//

        function buildVentZwembadruimteTabel() {
            // Voor elke aangesloten rekenzone hebben we een VENTZBR-entiteit nodig
            const ventzbrs = new Set(ntaEntityData.getChildren(_ventilatiedebietData, 'VENTZBR'));

            const rekenzones = ntaEntityData.getChildren(_ventData, 'RZ');

            for (const rz of rekenzones) {
                const rzVentzbrs = ntaEntityData.getChildren(rz, 'VENTZBR');
                let ventzbr = rzVentzbrs.find(ventzbr => ventzbrs.has(ventzbr));
                if (!ventzbr) {
                    const ventzbrId = ntaEntityData.create('VENTZBR', -1, [
                        { "OnCopy": 1, "OnDelete": 1, "Parent": _ventilatiedebietData.EntityDataId, "ParentEntityId": _ventilatiedebietData.EntityId },
                        { "OnCopy": 1, "OnDelete": 1, "Parent": rz.EntityDataId, "ParentEntityId": rz.EntityId },
                    ], [], null);
                    ventzbr = ntaEntityData.get(ventzbrId);
                }
                _ventzbrPerRz[rz.EntityDataId] = [ventzbr];
            }

            const rekenzoneIds = new Set(rekenzones.map(rz => rz.EntityDataId));

            // Voor alle VENTZBR-entiteiten onder dit ventilatiesysteem die horen bij een RZ die niet meer gekoppeld is aan dit ventilatiesysteem:
            // Verwijder meldingen
            // Maak properties niet relevant

            for (const ventzbr of ventzbrs) {
                const isGekoppeld = ntaEntityData.getParentIds(ventzbr, 'RZ')
                    .some(rzId => rekenzoneIds.has(rzId));
                if (!isGekoppeld) {
                    const propdatas = ventzbr && ventzbr.PropertyDatas || [];
                    ntaEntityData.setPropdataStatus(propdatas, false, false);
                    for (const propdata of propdatas) {
                        ntaValidation.IsValid(ventilatieLogic.form_installationventilatie, null, propdata, true);
                    }
                }
            }

        } //-- end: buildVentzwembadruimteTabel ------------------------------------------------------------------------//

        function createTempEntityObject(entity) {
            //-- Maakt nieuw object voor een gegeven entiteit. Dit nieuwe object kan tijdelijk worden gebruikt en staat niet in de data van ntabuilding (PropertyDatas zijn nog wel dezelfde).
            return {
                PropertyDatas: entity.PropertyDatas,
                EntityDataId: entity.EntityDataId,
                EntityId: entity.EntityId,
                Order: entity.Order
            };
        } //-- end: createTempEntityObject -----------------------------------------------------------------------//

        function conditieSB() {
            // [SB] veld alleen tonen als V05=E. gecombineerde systemen
            return _ventData.PropertyDatas['VENT_SYS'].Value === 'VENTSYS_GEC';
        } //-- end: conditieSB --------------------------------------------------------------------

        function conditieSX() {
            // [SX] toon als G04=appartementengebouw / utiliteitsgebouw EN Z23=per gebouw en per appartement/unit. Toon ook als Z23='voor projectwoningen'
            return ntaSharedLogic.perGebouwEnAppartementOfUnit() || ntaSharedLogic.voorProjectwoningen();
        } //-- end: conditieSX --------------------------------------------------------------------

        function conditieSW() {
            // [SW] verberg als G04=appartementengebouw / utiliteitsgebouw EN Z23=per gebouw en per appartement/unit. Verberg ook als Z23 = ' voor projectwoningen'
            return !conditieSX();
        } //-- end: conditieSW --------------------------------------------------------------------

        function setVerblijfsgebiedVentPerUnit(systeem) {
            const tempUnits = [];
            systeem.tempUnits = tempUnits;

            // Condities [SB] en [SX]
            const kolom = _ventilatiekolommen.find(vk => vk.entdata === systeem);
            const relevant = !kolomIsHidden(kolom) && conditieSB() && conditieSX();

            //-- Haal de Id op van alle verblijfsgebieden die aan deze ventilatiesysteem-entiteit zijn gekoppeld
            const verblijfsgebiedIdsVent = new Set(ntaEntityData.getChildIds(systeem, 'VENT-VERB'));

            //-- Koppel een verblijfsgebied aan elke unit of maak een nieuw verblijfsgebied aan als dit nog niet bestaat
            for (const unit of _units) {
                //-- Haal de Id op van alle verblijfsgebieden die aan deze unit-entiteit zijn gekoppeld
                const verblijfsgebiedIdsUnit = ntaEntityData.getChildIds(unit, 'VENT-VERB');

                //-- Zoek het verblijfsgebied dat in beide lijsten voorkomt
                const verblijfsgebiedId = verblijfsgebiedIdsUnit.find(vgid => verblijfsgebiedIdsVent.has(vgid));
                let verblijfsgebied = ntaEntityData.get(verblijfsgebiedId);

                //-- maak een nieuw verblijfsgebied aan als dit nog niet bestond
                if (!verblijfsgebied) {
                    const newId = ntaEntityData.create("VENT-VERB", -1, [
                        { "OnCopy": 1, "OnDelete": 1, "Parent": unit.EntityDataId, "ParentEntityId": "UNIT" },
                        { "OnCopy": 1, "OnDelete": 1, "Parent": systeem.EntityDataId, "ParentEntityId": systeem.EntityId }
                    ], [], []);
                    verblijfsgebied = ntaEntityData.get(newId);
                }
                ntaEntityData.setEntityRelevancy(verblijfsgebied, relevant);
                ntaEntityData.setEntityVisibility(verblijfsgebied, relevant);

                //-- Maak tempUnit
                const tempUnit = createTempEntityObject(unit);
                tempUnits.push(tempUnit);

                //-- Koppel verblijfsgebied aan unit
                tempUnit.verblijfsgebied = verblijfsgebied;
            }

            //niet gekoppelde verblijfsgebieden op niet relevant zetten.
            const gekoppeldeIds = new Set(tempUnits.map(x => x.verblijfsgebied.EntityDataId));
            const nietGekoppeldeGebieden = [...verblijfsgebiedIdsVent]
                .filter(id => !gekoppeldeIds.has(id))
                .map(id => ntaEntityData.get(id));
            ntaEntityData.setEntityRelevancy(nietGekoppeldeGebieden, false);
            ntaEntityData.setEntityVisibility(nietGekoppeldeGebieden, false);
        } //-- end: setVerblijfsgebiedVentPerUnit ------------------------------------------------------------------------//

        function setToevoerkanaalNaarWTWLengtePerUnit(warmteterugwinning) {

            const tempUnits = [];
            warmteterugwinning.tempUnits = tempUnits;

            //-- Haal alle toevoerkanalen op die aan deze warmteterugwinning entiteit zijn gekoppeld
            const ventToevoerkanaalIds = new Set(ntaEntityData.getChildIds(warmteterugwinning, 'WARMTE-TOEV-KAN'));
            for (const unit of _units) {
                //-- Haal alle toevoerkanalen op die aan deze unit entiteit zijn gekoppeld
                const unitToevoerkanaalIds = ntaEntityData.getChildIds(unit, 'WARMTE-TOEV-KAN');

                //-- Zoek het toevoerkanaal die in beide lijsten voorkomt
                const toevoerkanaalId = unitToevoerkanaalIds.find(id => ventToevoerkanaalIds.has(id));
                let toevoerkanaal = ntaEntityData.get(toevoerkanaalId);

                //-- Maak een nieuw toevoerkanaal aan als deze nog niet bestond
                if (!toevoerkanaal) {
                    const parentRels = [
                        { "OnCopy": 1, "OnDelete": 1, "Parent": unit.EntityDataId, "ParentEntityId": unit.EntityId },
                        { "OnCopy": 1, "OnDelete": 1, "Parent": warmteterugwinning.EntityDataId, "ParentEntityId": warmteterugwinning.EntityId }
                    ];
                    const newId = ntaEntityData.create("WARMTE-TOEV-KAN", -1, parentRels, [], []);
                    toevoerkanaal = ntaEntityData.get(newId);
                }


                //-- Maak tempUnit
                const tempUnit = createTempEntityObject(unit);
                tempUnits.push(tempUnit);

                //-- Koppel toevoerkanaal aan unit
                tempUnit.toevoerkanaal = toevoerkanaal;
            }

            //-- nu nog de toevoerkanalen van andere units die horen bij deze wtw op relevant=false zetten
            const allUnits = ntaEntityData.getListWithEntityId('UNIT');
            allUnits.forEach(function (unit, index) {
                //-- Als een unit niet in de lijst voorkomt van tempUnits gekoppeld aan een ventilator, moet deze unit geen ventilatoreigenschappen hebben samen met deze ventilator
                if (!warmteterugwinning.tempUnits.find(function (x) { return x.EntityDataId === unit.EntityDataId; })) {
                    //-- Haal alle toevoerkanalen op die aan deze unit entiteit zijn gekoppeld
                    const unitToevoerkanaalIds = ntaEntityData.getChildIds(unit, 'WARMTE-TOEV-KAN');
                    //-- Zoek het toevoerkanaal die in beide lijsten voorkomt
                    const toevoerkanaalId = unitToevoerkanaalIds.find(id => ventToevoerkanaalIds.has(id));
                    let toevoerkanaal = ntaEntityData.get(toevoerkanaalId);
                    if (toevoerkanaal) {
                        //-- relevantie van dit kanaal op false zetten
                        ntaEntityData.setEntityRelevancy(toevoerkanaal, false);
                        ntaEntityData.setEntityVisibility(toevoerkanaal, false);
                    }
                }
            });

        } //-- end: setToevoerkanaalNaarWTWLengtePerUnit ------------------------------------------------------------------------//

        function setVentilatorenEigenschappenPerUnit(ventilator) {
            const tempUnits = [];
            ventilator.tempUnits = tempUnits;

            _units.forEach(function (unit, index) {
                const ventilatoreigenschappen = ntaEntityData.getChildren(ventilator, 'VENTILATOREIG').filter(ed => ntaSharedLogic.hasRelation(unit, ed));

                //-- Maak tempUnit
                const tempUnit = createTempEntityObject(unit);
                ventilator.tempUnits.push(tempUnit);

                //-- Koppel ventilatoreigenschappen aan tempUnit of maak een nieuwe als er nog geen bestond
                if (ventilatoreigenschappen.length === 0) {
                    const newId = ntaEntityData.create("VENTILATOREIG", -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": tempUnit.EntityDataId, "ParentEntityId": "UNIT" }, { "OnCopy": 1, "OnDelete": 1, "Parent": ventilator.EntityDataId, "ParentEntityId": ventilator.EntityId }], [], []);
                    ventilatoreigenschappen.push(ntaEntityData.get(newId));
                }
                tempUnit.ventilatorEigenschappen = ventilatoreigenschappen;
            });

            //-- nu nog de overbodige ventilatoren eigenschappen verwijderen
            const allUnits = ntaEntityData.getListWithEntityId('UNIT');

            for (const unit of allUnits) {
                // Als een unit niet in de lijst voorkomt van tempUnits gekoppeld aan een ventilator, moet deze unit geen ventilatoreigenschappen hebben samen met deze ventilator
                if (!ventilator.tempUnits.find(function (x) { return x.EntityDataId === unit.EntityDataId; })) {
                    const ventilatoreigenschappen = ntaEntityData.getChildren(ventilator, 'VENTILATOREIG').filter(ed => ntaSharedLogic.hasRelation(unit, ed));

                    // Verwijder ventilatoreigenschappen als deze bestaan
                    for (const ventilatoreigenschap of ventilatoreigenschappen) {
                        ntaEntityData.delete(ventilatoreigenschap.EntityDataId);
                    }
                }
            }
        } //-- end: setVentilatorenEigenschappenPerUnit ------------------------------------------------------------------------//

        function setDefaultValueInvoerVentilatiesysteem() {
            let propDataVentInvoer = _ventData.PropertyDatas['VENT_INVOER'];
            let propDataVentaanvullendInvoer = _ventAanvullendData.PropertyDatas['VENTAAN_INVOER'];

            if (!propDataVentInvoer.Touched) {
                if (!ntaSharedLogic.isUtiliteit()) {
                    propDataVentInvoer.Value = 'VENT_PROD';
                }
                else {
                    propDataVentInvoer.Value = 'VENT_FORF';
                }
            }
            if (!propDataVentaanvullendInvoer.Touched) {
                if (!ntaSharedLogic.isUtiliteit()) {
                    propDataVentaanvullendInvoer.Value = 'VENT_PROD';
                }
                else {
                    propDataVentaanvullendInvoer.Value = 'VENT_FORF';
                }
            }
        } //-- end: setDefaultValueInvoerVentilatiesysteem ------------------------------------------------------------------------//

        function determineFabricagejaar() {
            let jaar = ntaSharedLogic.getBouwjaar();
            let defaultValue = '';

            if (jaar < 1981) {
                defaultValue = 'WARMTEFABR_OUD';
            }
            else if (jaar < 1986) {
                defaultValue = 'WARMTEFABR_1981';
            }
            else if (jaar < 1991) {
                defaultValue = 'WARMTEFABR_1986';
            }
            else if (jaar < 1999) {
                defaultValue = 'WARMTEFABR_1991';
            }
            else if (jaar < 2007) {
                defaultValue = 'WARMTEFABR_1999';
            }
            else if (jaar < 2010) {
                defaultValue = 'WARMTEFABR_2007';
            }
            else if (jaar >= 2010) {
                defaultValue = 'WARMTEFABR_NIEUW';
            }
            else {
                defaultValue = 'WARMTEFABR_ONB';
            }

            return defaultValue;
        } //-- end: determineFabricagejaar ------------------------------------------------------------------------//

        function setDefaultValueFabricagejaar() {
            let propDataWarmteFabricagejaar = _warmteterugwinningData.PropertyDatas['WARMTETERUG_FABR'];
            let propDataWarmteAanvullendFabricagejaar = _warmteterugwinningAanvullendData.PropertyDatas['WARMTETERUG_FABR'];
            let propDataVentilatorFabricagejaar = _ventilatorenData.PropertyDatas['VENTILATOR_FABR'];
            let propDataVentilatoraanvullendFabricagejaar = _ventilatorenAanvullendData.PropertyDatas['VENTILATOR_FABR'];

            if (!propDataWarmteFabricagejaar.Touched) {
                propDataWarmteFabricagejaar.Value = determineFabricagejaar();
            }
            if (!propDataWarmteAanvullendFabricagejaar.Touched) {
                propDataWarmteAanvullendFabricagejaar.Value = determineFabricagejaar();
            }
            if (!propDataVentilatorFabricagejaar.Touched) {
                propDataVentilatorFabricagejaar.Value = determineFabricagejaar();
            }
            if (!propDataVentilatoraanvullendFabricagejaar.Touched) {
                propDataVentilatoraanvullendFabricagejaar.Value = determineFabricagejaar();
            }
        } //-- end: setDefaultValueFabricagejaar ------------------------------------------------------------------------//

        function setDefaultRendementWarmteterugwinning(ventilatiekolom) {
            let propRendement = _warmteterugwinningProperties['WARMTETERUG_REND'];
            let propdataRendement = propRendement.getData(ventilatiekolom.warmteterugwinning);

            let propInvoerId = ventilatiekolom.isAanvullendSysteem ? 'VENTAAN_INVOER' : 'VENT_INVOER'
            let propValueInvoer = ventilatiekolom.entdata.PropertyDatas[propInvoerId].Value;

            if (propValueInvoer === null) {
                //-- er is nog geen type invoer gekozen
                return;
            }

            let newEta_hr;
            if (propValueInvoer === 'VENT_PROD') {
                //-- set eta_hr op basis van product
                let propVariantId = ventilatiekolom.isAanvullendSysteem ? 'VENTAAN_SYSVAR' : 'VENT_SYSVAR';
                let propValueVariant = ventilatiekolom.entdata.PropertyDatas[propVariantId].Value;
                if (propValueVariant) {
                    const productTypeInfo = _productTypes.find(pt => pt.productIdPropertyId === propVariantId);
                    const table = productTypeInfo ? _selectionTable[productTypeInfo.modelType] : null;
                    if (table && table.length > 0) {
                        propValueVariant = parseInt(propValueVariant);
                        let product = table.find(function (x) { return x.Id === propValueVariant; });
                        if (product) {
                            newEta_hr = product.eta_hr ? product.eta_hr : 0;
                            newEta_hr = ntaRounding.roundAndAddZerosNewValue(propRendement, newEta_hr);
                            saveValue(propRendement, ventilatiekolom.warmteterugwinning, newEta_hr);

                            return;
                        }
                    }

                } else {
                    return;
                }
            }

            // set eta_hr op basis van table11_18
            let propValueWTWType = ventilatiekolom.warmteterugwinning.PropertyDatas['WARMTETERUG_TYPE'].Value;

            if (propValueWTWType) {
                newEta_hr = '';
                if (propValueWTWType && propValueWTWType !== 'WARMTETYPE_REN1' && propValueWTWType !== 'WARMTETYPE_REN2' && propValueWTWType !== 'WARMTETYPE_REN3') {
                    newEta_hr = _warmteterugwinningTypes.find(function (x) { return x.Id === propValueWTWType; }).eta_hr;
                    newEta_hr = ntaRounding.roundAndAddZerosNewValue(propRendement, newEta_hr);
                    saveValue(propRendement, ventilatiekolom.warmteterugwinning, newEta_hr);
                    return;
                }
                if (!propdataRendement.Touched) {
                    saveValue(propRendement, ventilatiekolom.warmteterugwinning, newEta_hr);
                    return;
                }
            }
        } //-- end: setDefaultRendementWarmteterugwinning ------------------------------------------------------------------------//

        function setDefaultBypassAandeel(ventilatiekolom) {
            let propBypass = _warmteterugwinningProperties['WARMTETERUG_BYP'];
            let propBypassAandeel = _warmteterugwinningProperties['WAMRTETERUG_BYPA'];

            let propValueBypass = propBypass.getValue(ventilatiekolom.warmteterugwinning);

            if (propValueBypass === null) {
                return;
            }

            let newEta_bypass
            //-- de taalfout WERMTEBYP_EIG staat ook zo in de meta data als CodedValueId. Deze fout aanpassen zou betekenen dat er een global upgrader geschreven moet worden
            //-- en daar zien we even vanaf.
            if (propValueBypass && propValueBypass !== 'WERMTEBYP_EIG') {
                if (isHidden(propBypass, ventilatiekolom.warmteterugwinning)) { // VE_model
                    //-- als bypass niet zichtbaar is moet het aandeel uit de selectietabel komen
                    let propVariantId = ventilatiekolom.isAanvullendSysteem ? 'VENTAAN_SYSVAR' : 'VENT_SYSVAR';
                    let propValueVariant = ventilatiekolom.entdata.PropertyDatas[propVariantId].Value;
                    if (propValueVariant) {
                        const productTypeInfo = _productTypes.find(pt => pt.productIdPropertyId === propVariantId);
                        const table = productTypeInfo ? _selectionTable[productTypeInfo.modelType] : null;
                        if (table && table.length > 0) {
                            propValueVariant = parseInt(propValueVariant);
                            let product = table.find(function (x) { return x.Id === propValueVariant; });
                            if (product) {
                                newEta_bypass = product.eta_bypass ? product.eta_bypass : 0;
                                newEta_bypass = ntaRounding.roundAndAddZerosNewValue(propBypassAandeel, newEta_bypass);
                                saveValue(propBypassAandeel, ventilatiekolom.warmteterugwinning, newEta_bypass);

                                //-- ook de wijze van bypass moet gezet worden naar eigen waarde, zodat de juiste bypass mee gaat naar de rekenkern. Dit
                                //-- geldt alleen voor de 3.1 projecten. Projecten van 3.0 moeten met de oude resultaten blijven rekenen, ondanks dat de TOjuli
                                //-- dan niet klopt.
                                if (ntabuilding.appVersionId >= 100) {
                                    saveValue(propBypass, ventilatiekolom.warmteterugwinning, 'WERMTEBYP_EIG')
                                }

                                return;
                            }
                        }

                    } else {
                        return;
                    }
                } else {
                    if (ntaSharedLogic.isUtiliteit()) { // EtaBypassU
                        newEta_bypass = _bypassTypes.find(function (x) { return x.Id === propValueBypass; }).EtaBypassU;
                    }
                    else {
                        let propValueFabricagejaar = ventilatiekolom.warmteterugwinning.PropertyDatas['WARMTETERUG_FABR'].Value;

                        if (propValueFabricagejaar === 'WARMTEFABR_NIEUW') { // EtaBypassW>=2010
                            newEta_bypass = _bypassTypes.find(function (x) { return x.Id === propValueBypass; }).EtaBypassWGR2010;
                        }
                        else { // EtaBypassW<2010
                            newEta_bypass = _bypassTypes.find(function (x) { return x.Id === propValueBypass; }).EtaBypassWKL2010;
                        }
                    }
                    newEta_bypass = ntaRounding.roundAndAddZerosNewValue(propBypassAandeel, newEta_bypass);
                    saveValue(propBypassAandeel, ventilatiekolom.warmteterugwinning, newEta_bypass);
                }
            }
        } //-- end: setDefaultBypassAandeel ------------------------------------------------------------------------//

        function isReadOnly(prop, entdata) {
            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return false;
            }

            switch (prop.Id) {
                case 'VENT_VARIANT':
                case 'VENTAAN_VARIANT':
                case 'VENTILATOR_PNC':
                case 'VENTILATOR_FREGB':
                case 'VENTILATOR_PNOM1':
                case 'VENTILATOR_PNOM2':
                case 'VENTILATOR_PNOM3':
                case 'VENTILATOREIG_PNA':
                case 'MEASURE-COSTS_TOTAAL':
                    {
                        return true;
                    }
                case 'VENT_FCTRL':
                case 'VENTAAN_FCTRL':
                    {
                        let propInvoerId = entdata.EntityId === 'VENT' ? 'VENT_INVOER' : 'VENTAAN_INVOER';
                        let propValueInvoer = entdata.PropertyDatas[propInvoerId].Value;
                        return propValueInvoer !== 'VENT_EIG';
                    }
                case 'VOORWARM_MAXV':
                case 'VOORWARM_MAXT':
                case 'VOORWARM_BUIT':
                case 'VOORWARM_MAXI':
                    {
                        let propValueVoorwNatToev = entdata.PropertyDatas['VOORWARM_NT'].Value;
                        return propValueVoorwNatToev === 'VOORWARMNT_PROD'
                    }
                case 'WARMTETERUG_REND':
                    {
                        let propWTWType = _warmteterugwinningProperties['WARMTETERUG_TYPE'];
                        if (isHidden(propWTWType, entdata)) {
                            //-- als veld niet zichtbaar is, igv product specifiek altijd readonly
                            return true;
                        }

                        let propValueWTWType = propWTWType.getValue(entdata);
                        return !(propValueWTWType === 'WARMTETYPE_REN1' || propValueWTWType === 'WARMTETYPE_REN2' || propValueWTWType === 'WARMTETYPE_REN3'); //Conditie [WC]
                    }
                case 'WAMRTETERUG_BYPA':
                    {
                        // Conditie WI
                        let propBypass = _warmteterugwinningProperties['WARMTETERUG_BYP'];
                        let propValueBypass = propBypass.getValue(entdata);
                        return isHidden(propBypass, entdata) || propValueBypass !== 'WERMTEBYP_EIG';
                    }
                case 'VENTILATOREIG_FREG':
                    {
                        const ventilatoren = ntaEntityData.getFirstParent(entdata, 'VENTILATOR');
                        return ventilatoren ? ventilatoren.PropertyDatas['VENTILATOR_INV'].Value !== 'VENTILATORFABR_C' : false;
                    }
                case 'VENTCAP_ND':
                case 'VENTCAP_NV':
                case 'VENTCAP_NAOS':
                case 'VENTCAP_MD':
                case 'VENTCAP_MV':
                    {
                        //Conditie [DP] als versie = ge3-2 EN V89 voor de betreffende rekenzone is aangevinkt dan regel uitgrijzen en geef bij invulvelden 'n.v.t.' aan (Niet aan te passen door gebruiker)
                        if (ntabuilding.ntaVersionId >= 200) {
                            const zbrProp = _ventzwembadruimteProperties['VENTZBR_AANW'];
                            const unitRz = ntaEntityData.getFirstParent(entdata, 'UNIT-RZ');
                            const rz = ntaEntityData.getFirstParent(unitRz, 'RZ');
                            const ventZbr = ntaEntityData.getFirstChild(rz, 'VENTZBR');
                            const propdata = zbrProp.getData(ventZbr);
                            return propdata.Relevant && propdata.Value === 'True';
                        } else return false;
                    }
                case 'VENTZBR_AG':
                    {
                        const zbrProp = _ventzwembadruimteProperties['VENTZBR_AANW'];
                        const propdata = zbrProp.getData(entdata);
                        return propdata.Relevant && propdata.Value === 'False';
                    }
                default:
                    return false;
            }
        } //-- end: isReadOnly ------------------------------------------------------------------------//

        function getCodedValues(prop, entdata) {
            let typesList = [];

            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return typesList;
            }

            switch (prop.Id) {
                case 'VENT_PKOEL': { // V72
                    typesList = ntaValidation.codedValues(prop);

                    const productSysteemId = parseInt(_ventData.PropertyDatas['VENT_SYSVAR'].Value);
                    const product = _selectionTable.VE.find(p => p.Id === productSysteemId);

                    /// conditie [RH] verberg als waarde in kolom 'passieve_koeling' van VE_model behorend bij het model gekozen in V07 = 'ja'
                    if (product && product.PassieveKoeling) {
                        typesList = typesList.filter(cv => cv.Id !== 'VENTDIS_PKOEL_GEEN');
                    }
                    /// [RI] indien ge3-3: verberg als certificate van VE_model ≠ F EN waarde in kolom 'passieve_koeling' 
                    /// van VE_model behorend bij het model gekozen in V07 = 'nee' of 'leeg'(keuze 'geen passieve koelregeling' 
                    /// tonen en vastzetten)
                    if (ntabuilding.appVersionId >= 300 && product?.Certificate && !product?.PassieveKoeling) {
                        typesList = typesList.filter(cv => cv.Id == 'VENTDIS_PKOEL_GEEN');
                    }

                    break;
                }
                case 'VENTDIS_LBK':// V69
                    {
                        typesList = ntaValidation.codedValues(prop, 'D', false);
                        break;
                    }
                case 'VENT_INVOER': // V05
                case 'VENTAAN_INVOER': // V11
                    {
                        let propSysId = entdata.EntityId === 'VENT' ? 'VENT_SYS' : 'VENTAAN_SYS';
                        let propValueSys = entdata.PropertyDatas[propSysId].Value;

                        typesList = ntaValidation.codedValues(prop);
                        if (propValueSys === 'VENTSYS_NAT' || propValueSys === 'VENTSYS_MECH') {
                            typesList = ntaValidation.codedValues(prop, 'AB', true);
                        }
                        break;
                    }
                case 'VENTDEB_CAP': // V55
                    {
                        typesList = ntaValidation.codedValues(prop);

                        // Conditie DL
                        let propAandeelVoorverwarming = _voorverwarmingProperties['VOORWARM_AAN'];
                        let propValueAandeelVoorverwarming = propAandeelVoorverwarming.getValue(_voorverwarmingData);

                        if (propValueAandeelVoorverwarming === 'VOORWARMAAN_DEEL' && (!voorverwarmingIsHidden(_ventilatieKolom) || !voorverwarmingIsHidden(_ventilatieAanvullendKolom)) && !isHidden(propAandeelVoorverwarming, _voorverwarmingData)) {
                            //-- V17 is 'voorverwarming in deel toevoerroosters'
                            typesList = typesList.filter(function (x) { return x.Id !== 'VENTDEBCAP_ONB'; });
                        }

                        break;
                    }
                case 'WARMTETERUG_TYPE': { // V23
                    typesList = _warmteterugwinningTypes;

                    // Conditie [WM]
                    let selection;
                    if (ntaSharedLogic.hasRelation(_ventData, entdata)) {
                        const productSysteemId = parseInt(_ventData.PropertyDatas['VENT_SYSVAR'].Value);
                        selection = _selectionTable.VE.find(x => x.Id === productSysteemId);

                    } else if (ntaSharedLogic.hasRelation(_ventAanvullendData, entdata)) {
                        const productSysteemId = parseInt(_ventAanvullendData.PropertyDatas['VENTAAN_SYSVAR'].Value);
                        selection = _selectionTable.VE_AAN.find(x => x.Id === productSysteemId);

                    }
                    if (!selection || !['D.1', 'D.4a', 'D.4b', 'D.5a'].includes(selection.Variant.trim())) {
                        typesList = typesList.filter(x => x.Id !== 'WARMTETYPE_GEEN');
                    }
                    break;
                }
                case 'WARMTETERUG_BYP': { // V26
                    typesList = _bypassTypes;
                    break;
                }
                case 'WARMTETERUG_WTW': { // V85
                    typesList = ntaValidation.codedValues(prop);
                    // Conditie [WP]
                    if (ntaSharedLogic.hasRelation(_ventData, entdata)) {
                        const systeemvariant = _ventData.PropertyDatas['VENT_SYSVAR'].Value;
                        const selection = _selectionTable.VE.find(function (x) { return x.Id === parseInt(systeemvariant); });

                        if (selection && selection.KoudeTerugwinning) {
                            typesList = typesList.filter(function (x) { return x.Id !== 'WARMTETERUG_WTW_NIET'; });
                        }
                    }
                    else if (ntaSharedLogic.hasRelation(_ventAanvullendData, entdata)) {
                        const systeemvariant = _ventAanvullendData.PropertyDatas['VENTAAN_SYSVAR'].Value;
                        const selection = _selectionTable.VE_AAN.find(function (x) { return x.Id === parseInt(systeemvariant); });

                        if (selection && selection.KoudeTerugwinning) {
                            typesList = typesList.filter(function (x) { return x.Id !== 'WARMTETERUG_WTW_NIET'; });
                        }
                    }
                    break;
                }
                case 'VENTAAN_SYS': {
                    typesList = ntaValidation.codedValues(prop);
                    //-- bij deze property moet het item  verwijderd worden.
                    typesList = typesList.filter(function (x) { return x.Id !== 'VENTSYS_GEC'; });
                    break;
                }
                case 'VENTILATOR_TYPE': { // V37
                    typesList = ntaValidation.codedValues(prop);
                    // Conditie [FR]
                    let propFabricagejaar = _ventilatorenProperties['VENTILATOR_FABR'];
                    let propValuepropFabricagejaar = propFabricagejaar.getValue(entdata);
                    if (propValuepropFabricagejaar === 'WARMTEFABR_2007' || propValuepropFabricagejaar === 'WARMTEFABR_NIEUW') {
                        typesList = typesList.filter(function (x) { return x.Id !== 'VENTILATORTYPE_WIS' && x.Id !== 'VENTILATORTYPE_ONBEK'; });
                    }
                    break;
                }
                default: {
                    typesList = ntaValidation.codedValues(prop);
                    break;
                }
            }

            ntaSharedLogic.checkPropdataInList(prop, entdata, typesList, ventilatieLogic);
            return typesList;
        } //-- end: getCodedValues ------------------------------------------------------------------------//

        function validateDependencies(prop, entdata) {
            //-- Ontvangt via de dependencyValidator (die aangeroepen wordt na de saveValue) het veld waarvoor interne afhankelijkheden gelden
            if (!prop || !entdata) {
                return;
            }

            switch (prop.Id) {
                case 'VENT_SYSVAR':
                case 'VENTAAN_SYSVAR':
                    {
                        let ventilatiekolom = _ventilatieKolom;
                        if (entdata.Entity === 'VENTAAN') {
                            ventilatiekolom = _ventilatieAanvullendKolom;
                        }
                        setValuesDependingOnSelectionTable();
                        setDescriptionInstallation(ventilatiekolom.entdata);
                        setDefaultBypassAandeel(ventilatiekolom);

                        const propKoudeterugwinning = ntabuilding.properties['WARMTETERUG_WTW'];
                        isHidden(propKoudeterugwinning, ventilatiekolom.warmteterugwinning);
                        break;
                    }
                case 'VENT_SYS':
                case 'VENT_INVOER':
                case 'VENTAAN_SYS':
                case 'VENTAAN_INVOER':
                    {
                        let propSysId = 'VENT_SYS';
                        let propSysVarId = 'VENT_SYSVAR';
                        let properties = _ventProperties;
                        let ventilatiekolom = _ventilatieKolom;
                        if (entdata.EntityId === 'VENTAAN') {
                            propSysId = 'VENTAAN_SYS';
                            propSysVarId = 'VENTAAN_SYSVAR';
                            properties = _ventAanvullendProperties;
                            ventilatiekolom = _ventilatieAanvullendKolom;
                        }

                        let propSys = properties[propSysId];
                        let propValueSys = propSys.getValue(entdata);
                        let propSysVar = properties[propSysVarId];

                        getSelectionTable(propSysVar, entdata); //-- deze filtert de juiste waarden voor de selectietabel en valideert of de value van het product nog voor komt in de lijst. Anders wordt het product op null gezet.
                        setValuesDependingOnSelectionTable();

                        setDefaultRendementWarmteterugwinning(ventilatiekolom);

                        //-- nog checks specifiek bij het wijzigen van het type systeem
                        if (prop.Id === 'VENT_SYS' || prop.Id === 'VENTAAN_SYS') {
                            let propValueSysteem = _ventData.PropertyDatas['VENT_SYS'].Value;
                            let propValueSysteemAanvullend = _ventAanvullendData.PropertyDatas['VENTAAN_SYS'].Value;
                            if (propValueSysteem && propValueSysteem !== 'VENTSYS_NAT' && propValueSysteem !== 'VENTSYS_NATMECH') {
                                if (propValueSysteem === 'VENTSYS_GEC' && propValueSysteemAanvullend && propValueSysteemAanvullend !== 'VENTSYS_NAT' && propValueSysteemAanvullend !== 'VENTSYS_NATMECH') {
                                    let newNatuurlijkeToevoerValue = 'VOORWARMNT_GEEN';
                                    let propNatuurlijkeToevoer = _voorverwarmingProperties['VOORWARM_NT'];
                                    saveValue(propNatuurlijkeToevoer, _voorverwarmingData, newNatuurlijkeToevoerValue);
                                }
                            }
                            //-- alleen bij VENT_SYS controleren of VENT_GEM en VENT_LBK nog wel relevant zijn, want bij de dependencyvalidator (checkField) moet het aantal systemen opnieuw bepaald worden.
                            //-- Als ik bv van centraal Dc naar decentraal ga Dd moet mijn aantal systemen op de installatietegels aangepast worden.
                            if (prop.Id === 'VENT_SYS') {
                                isHidden('VENT_GEM', _ventData);
                                isHidden('VENT_LBK', _ventData);
                            }

                            setVentilatorenEigenschappenPerUnit(_ventilatorenData);
                            setVentilatorenEigenschappenPerUnit(_ventilatorenAanvullendData);

                            if (propValueSys === 'VENTSYS_NAT') {
                                //-- Conditie SQ
                                ntaMeldingen.warning("[W007]");
                            }
                        }
                        break;
                    }
                case 'VENT_GEM':
                case 'VENT_LBK':
                    {
                        setVentilatorenEigenschappenPerUnit(_ventilatorenData);
                        setVentilatorenEigenschappenPerUnit(_ventilatorenAanvullendData);
                        break;
                    }
                case 'VOORWARM_NT':
                    {
                        const propRooster = _voorverwarmingProperties['VOORWARM_ROO'];
                        getSelectionTable(propRooster, _voorverwarmingData);
                        setValuesDependingOnSelectionTable();
                        const propdata = prop.getData(entdata);
                        validate(prop, propdata, entdata);
                        break;
                    }
                case 'WARMTETERUG_TYPE':
                    {
                        if (ntaSharedLogic.hasRelation(_ventData, entdata)) {
                            setDefaultRendementWarmteterugwinning(_ventilatieKolom);
                        }
                        else if (ntaSharedLogic.hasRelation(_ventAanvullendData, entdata)) {
                            setDefaultRendementWarmteterugwinning(_ventilatieAanvullendKolom);
                        }

                        break;
                    }
                case 'WARMTETERUG_BYP':
                case 'WARMTETERUG_FABR':
                    {
                        if (ntaSharedLogic.hasRelation(_ventData, entdata)) {
                            setDefaultBypassAandeel(_ventilatieKolom);
                        }
                        else if (ntaSharedLogic.hasRelation(_ventAanvullendData, entdata)) {
                            setDefaultBypassAandeel(_ventilatieAanvullendKolom);
                        }
                        break;
                    }
                case 'WARMTETERUG_REND':
                    {
                        //-- Valideer alle velden die afhankelijk zijn van V24
                        validateWarmteterugwinningProp('WARMTETERUG_BYP', entdata);
                        validateWarmteterugwinningProp('WAMRTETERUG_BYPA', entdata);
                        validateWarmteterugwinningProp('WARMTETERUG_TOELI', entdata);
                        break;
                    }
                case 'WAMRTETERUG_BYPA':
                    {
                        const propKoudeterugwinning = ntabuilding.properties['WARMTETERUG_WTW'];
                        isHidden(propKoudeterugwinning, entdata);
                        break;
                    }
                case 'VENTZBR_AANW':
                    {
                        //Zwembadruimte-oppervlakte op n.v.t zetten indien niet aangeklikt
                        const zbrAanw_value = prop.getData(entdata).Value;
                        const propZbrAg = _ventzwembadruimteProperties['VENTZBR_AG'];
                        const propdataZbrAg = propZbrAg.getData(entdata);
                        validate(propZbrAg, propdataZbrAg, entdata);
                        if (zbrAanw_value === 'False') {
                            saveValue(propZbrAg, entdata, "n.v.t.");
                        } else {
                            saveValue(propZbrAg, entdata, "");
                        };

                        //Conditie [DP] als versie = ge3-2 EN V89 voor de betreffende rekenzone is aangevinkt dan regel uitgrijzen en geef bij invulvelden 'n.v.t.' aan (Niet aan te passen door gebruiker)
                        const capProps = [_ventcapaciteitenProperties['VENTCAP_ND'], _ventcapaciteitenProperties['VENTCAP_NV'], _ventcapaciteitenProperties['VENTCAP_NAOS'], _ventcapaciteitenProperties['VENTCAP_MD'], _ventcapaciteitenProperties['VENTCAP_MV'] ];
                        const ventzbrRz = ntaEntityData.getFirstParent(entdata, "RZ");
                        for (const ventcap of ntaEntityData.getChildren(_ventData, 'VENTCAP')) {
                            const ventcapRz = ntaEntityData.getFirstParent(ntaEntityData.getFirstParent(ventcap, "UNIT-RZ"), "RZ");
                            if (ventcapRz.EntityDataId === ventzbrRz.EntityDataId) {
                                for (const capProp of capProps) {
                                    if (capProp.getData(ventcap).Relevant) {
                                        let value = "";
                                        if (zbrAanw_value === "True") value = "n.v.t.";
                                        saveValue(capProp, ventcap, value);
                                    }
                                }
                            }
                        }

                        break;
                    }
            }
        } //-- end: validateDependencies ------------------------------------------------------------------------//

        function startFormValidation() {
            return ntaSharedLogic.startFormValidation(getAllEntDatas(), ventilatieLogic);
        } //-- end: startFormValidation ------------------------------------------------------------------------//

        function endFormValidation() {
            return ntaSharedLogic.endFormValidation(getAllEntDatas(), ventilatieLogic);
        } //-- end: endFormValidation ------------------------------------------------------------------------//

        function getAllEntDatas() { // Geeft alle entdatas in één array terug
            return []
                .concat(_ventData, _ventAanvullendData)
                .concat(_warmteterugwinningData, _warmteterugwinningAanvullendData)
                .concat(_ventilatorenData, _ventilatorenAanvullendData)
                .concat(_voorverwarmingData)
                .concat(_ventilatiedebietData)
                .concat(_ventilatiedistributieData)

                .concat(_ventData.tempUnits.map(unit => unit.verblijfsgebied).filter(verblijfsgebied => verblijfsgebied))
                .concat(_ventAanvullendData.tempUnits.map(unit => unit.verblijfsgebied).filter(verblijfsgebied => verblijfsgebied))
                .concat(_warmteterugwinningData.tempUnits.map(unit => unit.toevoerkanaal).filter(toevoerkanaal => toevoerkanaal))
                .concat(_warmteterugwinningAanvullendData.tempUnits.map(unit => unit.toevoerkanaal).filter(toevoerkanaal => toevoerkanaal))

                .concat(_ventilatorenData.tempUnits.flatMap(unit => unit.ventilatorEigenschappen))
                .concat(_ventilatorenAanvullendData.tempUnits.flatMap(unit => unit.ventilatorEigenschappen))
                .concat(_units.flatMap(unit => getUnitRekenzones(unit)).flatMap(unitRz => getVentilatiecapaciteit(unitRz)).filter(ed => ed))
                .concat(ntaEntityData.getChildren(_ventData, 'RZ').flatMap(rz => getVentilatiezwembadruimte(rz)).filter(ed => ed))

                .concat(ntaSharedLogic.getCostEntdata(_installationId))

                .filter(entdata => entdata) // filter alle nulls en undefineds eruit
                ;
        } //-- end: getAllEntDatas ------------------------------------------------------------------------//

        /* Tabellen ************************************************************************************************/
        function createTable11_18() {
            const table11_18 = [
                { Id: 'WARMTETYPE_GEEN', Value: '', eta_hr: '0', },
                { Id: 'WARMTETYPE_KOUD', Value: '', eta_hr: '0,4', },
                { Id: 'WARMTETYPE_PLABUI', Value: '', eta_hr: '0,65', },
                { Id: 'WARMTETYPE_KRUIS', Value: '', eta_hr: '0,55', },
                { Id: 'WARMTETYPE_TWEL', Value: '', eta_hr: '0,6', },
                { Id: 'WARMTETYPE_WARME', Value: '', eta_hr: '0,6', },
                { Id: 'WARMTETYPE_LANG', Value: '', eta_hr: '0,7', },
                { Id: 'WARMTETYPE_ENTH', Value: '', eta_hr: '0,75', },
                { Id: 'WARMTETYPE_ALUM', Value: '', eta_hr: '0,75', },
                { Id: 'WARMTETYPE_KUNS', Value: '', eta_hr: '0,8', },
                { Id: 'WARMTETYPE_REN1', Value: '', eta_hr: '', },
                { Id: 'WARMTETYPE_REN2', Value: '', eta_hr: '', },
                { Id: 'WARMTETYPE_REN3', Value: '', eta_hr: '', },
                { Id: 'WARMTETYPE_ONB', Value: '', eta_hr: '0', },
            ];

            let warmteterugwinningTypeCodedValues = _warmteterugwinningProperties["WARMTETERUG_TYPE"].Domain.Codes;

            table11_18.forEach(function (row, index) {
                var codedValue = warmteterugwinningTypeCodedValues.find(function (x) { return x.Id === row.Id; });
                if (typeof codedValue !== 'undefined') {
                    row.Value = codedValue.Value;
                }
            });

            return table11_18;
        } //-- end: createTable11_18 ------------------------------------------------------------------------//

        function createTableBypass() {
            const tableBypass = [
                { Id: 'WARMTEBYP_GEEN', Value: '', EtaBypassWKL2010: '0', EtaBypassWGR2010: '0', EtaBypassU: '0', },
                { Id: 'WARMTEBYP_HON', Value: '', EtaBypassWKL2010: '1', EtaBypassWGR2010: '1', EtaBypassU: '1', },
                { Id: 'WARMTEBYP_BEK', Value: '', EtaBypassWKL2010: '0,7', EtaBypassWGR2010: '1', EtaBypassU: '0', },
                { Id: 'WARMTEBYP_ONB', Value: '', EtaBypassWKL2010: '0', EtaBypassWGR2010: '1', EtaBypassU: '0', },
                { Id: 'WERMTEBYP_EIG', Value: '', EtaBypassWKL2010: '', EtaBypassWGR2010: '', EtaBypassU: '', },
            ];
            let bypassCodedValues = _warmteterugwinningProperties["WARMTETERUG_BYP"].Domain.Codes;

            tableBypass.forEach(function (row, index) {
                var codedValue = bypassCodedValues.find(function (x) { return x.Id === row.Id; });
                if (typeof codedValue !== 'undefined') {
                    row.Value = codedValue.Value;
                }
            });

            return tableBypass;
        } //-- end: createTableBypass ------------------------------------------------------------------------//

        function checkUnits() {
            verblijfsgebiedTabelIsHidden(_ventilatieKolom);
            verblijfsgebiedTabelIsHidden(_ventilatieAanvullendKolom);

            const ventverbs = ntaEntityData.getChildren(_ventData, "VENT-VERB")
                .concat(ntaEntityData.getChildren(_ventAanvullendData, "VENT-VERB"));
            for (const verblijfsgebied of ventverbs) {
                validate('VENT-VERB_GEBIED', null, verblijfsgebied);
            }

            // Warmteterugwinning
            warmteterugwinningIsHidden(_ventilatieKolom);
            toevoerkanaalTabelIsHidden(_ventilatieKolom);
            warmteterugwinningIsHidden(_ventilatieAanvullendKolom);
            toevoerkanaalTabelIsHidden(_ventilatieKolom);

            for (const tempUnit of _warmteterugwinningData.tempUnits.concat(_warmteterugwinningAanvullendData.tempUnits)) {
                validate('WARMTE-TOEV-KAN_LEN', null, tempUnit.toevoerkanaal);
            }

            // Ventilatoren
            ventilatorEigenschappenIsHidden(_ventilatieKolom);
            ventilatorEigenschappenIsHidden(_ventilatieAanvullendKolom);
            for (const tempUnit of _ventilatorenData.tempUnits.concat(_ventilatorenAanvullendData.tempUnits)) {
                for (const ventilatoreigenschap of tempUnit.ventilatorEigenschappen) {
                    for (const propdata of ventilatoreigenschap.PropertyDatas) {
                        validate(propdata.PropertyId, propdata, ventilatoreigenschap);
                    }
                }
            }

            // Debieten
            validate('VENTDEB_CAPTAB', null, _ventilatiedebietData);
        } //-- end: checkUnits ------------------------------------------------------------------------//
    }

    return VentilatieLogic;
}]);
