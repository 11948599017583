﻿angular.module('projectModule').controller("BuildingConstructiesController", ['$scope', '$controller', '$timeout', '$routeParams', 'ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaMeldingen', 'ntaDependencyValidation', 'ConstructiesFactory',
function                                                                      ($scope,   $controller,   $timeout,   $routeParams,   ntabuilding,   ntaValidation,   ntaEntityData,   ntaMeldingen,   ntaDependencyValidation,   ConstructiesFactory) {
    'use strict';
    const base = $controller('NTABaseController', { $scope: $scope })
    const vm = angular.extend(this, base);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    vm.ntaValidation = ntaValidation;
    vm.vlakId = $routeParams.id3;

    const conLogic = new ConstructiesFactory(vm.vlakId, ntaDependencyValidation);
    vm.getConLogic = conLogic;

    const _vlakName = conLogic.vlakName;
    const _vlakType = conLogic.vlakType;
    const _vlakA = conLogic.vlakA;
    const _vlakH = conLogic.vlakH;
    const _vlakBegr = conLogic.vlakBegr;
    const _vlakBegrOmschr = conLogic.vlakBegrOmschr;

    const _errorE078 = "De lineaire warmteverliezen worden nauwkeurig berekend, maar er zijn geen lengten en psi-waarden ingevoerd. Voer de lineaire warmteverliezen in bij de constructies van deze rekenzone of kies bij instellingen voor 'thermische bruggen forfaitair'."

    const _rowVisibility = new Map(); // voor elke constructie, of de extra rij met beschaduwing en/of zomernachtventilatie open- of dichtgeklapt is


    /* Properties *************************************************************************************************************************************************************/
    vm.propertiesD = conLogic.propertiesD;
    vm.propertiesT = conLogic.propertiesT;
    vm.propertiesL = conLogic.propertiesL;
    vm.propertiesP = conLogic.propertiesP;
    vm.propertiesWG = conLogic.propertiesWG;
    vm.propertiesKenmW = conLogic.propertiesKenmW;
    vm.propertiesKenmV = conLogic.propertiesKenmV;
    vm.propertiesKenmKRVENT = conLogic.propertiesKenmKRVENT;
    vm.propertiesKenmKRVENT = conLogic.propertiesKenmKRVENT;
    vm.propertiesKenmWWGVL = conLogic.propertiesKenmWWGVL;
    vm.propertiesKenmWWKLDR = conLogic.propertiesKenmWWKLDR;
    vm.propertiesKenmZOMNAC = conLogic.propertiesKenmZOMNAC;
    vm.propertiesKenmBELEMMERING = conLogic.propertiesKenmBELEMMERING;


    /* Data *************************************************************************************************************************************************************/
    vm.vlak = conLogic.vlak;
    vm.constrDs = conLogic.constrDs;
    vm.constrTs = conLogic.constrTs;
    vm.constrLs = conLogic.constrLs;
    vm.constrPs = conLogic.constrPs;
    vm.constrWGs = conLogic.constrWGs;
    vm.constrKenmW = conLogic.constrKenmW;
    vm.constrKenmV = conLogic.constrKenmV;
    vm.constrKenmKRVENT = conLogic.constrKenmKRVENT;
    vm.constrKenmWWGVL = conLogic.constrKenmWWGVL;
    vm.constrKenmWWKLDR = conLogic.constrKenmWWKLDR;

    vm.constrKenmZOMNACbyConstr = conLogic.constrKenmZOMNACbyConstr;
    vm.constrKenmBELEMMERINGbyConstr = conLogic.constrKenmBELEMMERINGbyConstr;

    //-- voor de ui-sortable
    vm.constrDsList = [];
    vm.constrTsList = [];
    vm.constrLsList = [];
    vm.constrPsList = [];
    vm.constrWGsList = [];
    vm.constrKenmWList = [];
    vm.constrKenmVList = [];
    vm.constrKenmKRVENTList = [];
    vm.constrKenmWWGVLList = [];
    vm.constrKenmWWKLDRList = [];
    vm.constrZomernachtList = [];
    vm.constrBelemmeringList = [];

    // Get constructie error om foutmeldingen aan te koppelen
    vm.errorE078 = errorE078;
    vm.constructieError = conLogic.constructieError;
    vm.propError = conLogic.propError;
    vm.propOpmerkingen = conLogic.propOpmerkingen;


    vm.getForm = getForm;
    vm.saveValue = saveValue;
    vm.getPlaceholder = getPlaceholder;

    vm.isHidden = conLogic.isHidden;
    vm.isReadOnly = conLogic.isReadOnly;
    vm.isDisabled = conLogic.isReadOnly;
    vm.validate = conLogic.validate;
    vm.getRelData = conLogic.getRelData;
    vm.saveRelation = conLogic.saveRelation;
    vm.getPropData = conLogic.getPropData;
    vm.getCodedValues = conLogic.getCodedValues;

    vm.getConstructionTitle = getConstructionTitle;
    vm.getLibConstructies = conLogic.getLibConstructies;

    vm.hasBelemmering = conLogic.hasBelemmering;
    vm.hasZomerNachtVent = conLogic.hasZomerNachtVent;
    vm.checkConditionD = conLogic.checkConditionD;
    vm.checkConditionO = conLogic.checkConditionO;
    vm.checkConditionQ = conLogic.checkConditionQ;
    vm.checkConditionR = conLogic.checkConditionR;
    vm.checkConditionT = conLogic.checkConditionT;
    vm.isHiddenKenmKR = conLogic.isHiddenKenmKR;
    vm.isKruipruimte = conLogic.isKruipruimte;
    vm.isOnverwKelder = conLogic.isOnverwKelder;
    vm.isConstrVloer = conLogic.isConstrVloer;

    vm.toggleRow = toggleRowVisible;
    vm.isRowVisible = isRowVisible;
    vm.getRowIcon = getRowIcon;
    vm.getKenmKRName = getKenmKRName;
    vm.getConstrDName = getConstrDName;
    vm.vlakBegrenzingAanwezig = vlakBegrenzingAanwezig;

    vm.addConstrProgress = false;

    vm.addConstr = addConstr;
    vm.copyConstr = copyConstr;
    vm.deleteConstr = deleteConstr;

    $scope.endFormValidation = function () {
        conLogic.endFormValidation();
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INITALIZIATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    angular.element(function () { //alle touched properties valideren na het laden van form
        conLogic.startFormValidation();
        vm.initializeUnitSpacing();
        //-- coditie G kijkt of oppervlakten nog kloppen en zet dat op het formulier. Dit gebeurd ook in updateLists maar dan is er nog geen formulier vandaar ook nog hier
        conLogic.checkConditionG();

        openErrorRows();
    });

    if (vm.constructieError) {
        vm.setGeopend('CONSTRERROR_OPEN', vm.constructieError);
    }
    updateLists();

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function saveValue(prop, entdata) {
        conLogic.saveValue(prop, entdata);

        if (prop.Id === 'CONSTRT_BESCH') {
            if (conLogic.hasBelemmering(entdata)) {
                toggleRowVisible(entdata, true);
            }
        } else if (prop.Id === 'CONSTRT_ZNVENT') {
            if (conLogic.hasZomerNachtVent(entdata)) {
                toggleRowVisible(entdata, true);
            }
        }

        vm.initializeUnitSpacing();
    } //-- end: saveValue ------------------------------------------------------------------------//

    function getForm() {
        return conLogic.form_bldconstr;
    } //-- end: getForm ------------------------------------------------------------------------//

    function getPlaceholder(prop, entdata) {
        return base.getPlaceholder(prop, entdata, conLogic);
    } //-- end: getPlaceholder ------------------------------------------------------------------------//

    function errorE078() {
        //-- hier kijk ik alleen of er E078 meldingen zijn. Als die er is, zet ik hem ook op dit formulier
        if (!conLogic.isBovenMaaiveld && !conLogic.isOnderMaaiveld) {
            const unitRz = ntaEntityData.getFirstParent(vm.vlakId, 'UNIT-RZ');
            ///--- als er geen unitRz gevonden is voor dit vlak dan is het vast een vlak van een AOR/AOS of GRUIMTE.
            if (unitRz) {
                const errorsE078 = ntaMeldingen.getMeldingen()
                    .filter(melding => melding.PropertyDatas['MELD_ERRORID'].Value === '[E078]');
                if (errorsE078.length > 0) {
                    //-- zoek de CONSTRERRORs die horen bij deze UNIT-RZ
                    const constrErrors = ntaEntityData.getListWithEntityId('CONSTRERROR')
                        .filter(constrError => ntaEntityData.isRelation(unitRz, ntaEntityData.getFirstParent(constrError, "BEGR")));
                    const expectedInputIds = new Set(constrErrors.map(constrError => constrError.PropertyDatas['CONSTRERROR_LINCONSTR'].PropertyDataId));

                    //-- nu moet ik er achter komen of er een error078 is die hoort bij deze unitRz
                    if (errorsE078.some(error => expectedInputIds.has(error.PropertyDatas['MELD_INPUTID'].Value))) {
                        return _errorE078;
                    }
                }
            }
        }

        return '';
    } //-- end: errorE078 ------------------------------------------------------------//


    function updateLists() {
        //-- onderstaande lijst zijn voor de ng-model binding van de sortabletabellen. Dit moeten namelijk assignable expressions zijn. Als ze een functie zijn doordat ze
        //-- iedere keer opnieuw opgehaald worden in de de factory gaat dat niet goed. Daarom expliciet in de controller bij houden.
        updateConstrDsList();
        updateConstrTsList();
        updateConstrLsList();
        updateConstrPsList();
        updateConstrWGsList();
        updateConstrZomernachtList();
        updateConstrBelemmeringList();


    } //-- end: updateLists ------------------------------------------------------------------------//

    function updateConstrDsList() {
        vm.constrDsList = vm.constrDs();
        conLogic.checkConditionG();
    } //-- end: updateConstrDsList ------------------------------------------------------------------------//

    function updateConstrTsList() {
        vm.constrTsList = vm.constrTs();
        conLogic.checkConditionG();
    } //-- end: updateConstrTsList ------------------------------------------------------------------------//

    function updateConstrLsList() {
        vm.constrLsList = vm.constrLs();
    } //-- end: updateConstrLsList ------------------------------------------------------------------------//

    function updateConstrPsList() {
        vm.constrPsList = vm.constrPs();
    } //-- end: updateConstrPsList ------------------------------------------------------------------------//

    function updateConstrWGsList() {
        vm.constrWGsList = vm.constrWGs();
    } //-- end: updateConstrWGsList ------------------------------------------------------------------------//

    function updateConstrZomernachtList() {
        vm.constrZomernachtList = {};
        vm.constrTs().forEach(function (constrT, index) {
            vm.constrZomernachtList[constrT.EntityDataId] = vm.constrKenmZOMNACbyConstr(constrT);
        });
    } //-- end: updateConstrZomernachtList ------------------------------------------------------------------------//

    function updateConstrBelemmeringList() {
        vm.constrBelemmeringList = {};
        vm.constrTs().forEach(function (constrT, index) {
            vm.constrBelemmeringList[constrT.EntityDataId] = vm.constrKenmBELEMMERINGbyConstr(constrT);
        });
    } //-- end: updateConstrBelemmeringList ------------------------------------------------------------------------//


    function vlakBegrenzingAanwezig() {
        return !!_vlakType && !!_vlakBegr;
    } //-- end: vlakBegrenzingAanwezig ------------------------------------------------------------------------//

    function getRowIcon(constrEntdata) {
        return isRowVisible(constrEntdata)
            ? 'expand_less'
            : 'expand_more';
    } //-- end: getRowIcon ----------------------------------------------------------------------//

    function isRowVisible(constrEntdata) {
        return !!_rowVisibility.get(constrEntdata)
            || !!getRowErrorPropdata(constrEntdata);
    } //-- end: isRowVisible --------------------------------------------------------------------//

    function openErrorRows() {
        for (const constrT of vm.constrTs()) {
            if (getRowErrorPropdata(constrT)) {
                toggleRowVisible(constrT, true);
            }
        }
    } //-- end: openErrorRows ------------------------------------------------------------------------//

    function getRowErrorPropdata(entdata) {
        if (!entdata) return null;

        const zomernachtventilatie = vm.constrZomernachtList[entdata.EntityDataId];
        const belemmering = vm.constrBelemmeringList[entdata.EntityDataId];

        const errorPropdata =
            zomernachtventilatie && zomernachtventilatie.PropertyDatas.find(propdata => {
                const prop = ntabuilding.properties[propdata.PropertyId];
                return !vm.isReadOnly(prop, zomernachtventilatie)
                    && !vm.validate(prop, propdata, zomernachtventilatie)
                    && propdata;
            })
            || belemmering && belemmering.PropertyDatas.find(propdata => {
                const prop = ntabuilding.properties[propdata.PropertyId];
                return !vm.isReadOnly(prop, belemmering)
                    && !vm.validate(prop, propdata, belemmering)
                    && propdata;
            });
        return errorPropdata || null;
    } //-- end: getRowErrorPropdata -------------------------------------------------------------//

    function toggleRowVisible(constrEntdata, show = null) {
        if (!constrEntdata) return;

        const wasVisible = isRowVisible(constrEntdata);

        const errorPropdata = getRowErrorPropdata(constrEntdata);
        if (errorPropdata) {
            show = true;
        } else if (show === null) {
            show = !wasVisible;
        } else {
            show = !!show;
        }
        _rowVisibility.set(constrEntdata, show);

        if (errorPropdata) {
            const form = getForm();
            const control = form && form['ntainput' + errorPropdata.PropertyDataId];
            if (control && control.$$element) {
                control.$$element.focus();
            }
        }
        if (show) {
            setTimeout(() => base.initializeUnitSpacing(), 500);
        }
    } //-- end: toggleRowVisible ----------------------------------------------------------------//

    function createSpanForSpecificCharacters(str) {
        //-- Voegt een span toe aan een teken en sanitized de rest
        if (str.includes('≤')) {
            //-- Split string op teken
            let stringArray = str.split('≤');

            //-- Maak nieuwe string met span voor het teken
            let newStr = '';
            let index;
            for (index = 0; index < stringArray.length; index++) {
                if (index !== 0) {
                    //-- Voeg teken toe met span
                    newStr = newStr + '<span class="smallersign">≤</span>';
                }

                //-- Voeg deel van de string toe en sanitize
                newStr = newStr + stringArray[index];
            }
            return newStr;
        }

        // Stuur sanitized string onveranderd terug
        return str;
    } //-- end: createSpanForSpecificCharacters ------------------------------------------------------------------------//

    function getConstructionTitle() {
        let title = '';
        title += _vlakName === null ? "" : _vlakName;
        title += _vlakBegrOmschr === null ? "" : " - " + _vlakBegrOmschr;
        title += _vlakA === null ? "" : " - " + _vlakA + " m²";
        title += _vlakH === null || _vlakH === "" || _vlakH === "n.v.t." ? "" : " - " + _vlakH  + "°";

        title = createSpanForSpecificCharacters(title);

        return title;
    } //-- end: getConstructionTitle ------------------------------------------------------------------------//

    function getConstrDName() {
        let constrDName = 'dichte constructie';
        if (conLogic.isConstrVloer()) {
            constrDName = "vloerconstructie";
        }
        return constrDName;
    } //-- end: getConstrDName ------------------------------------------------------------------------//

    function getKenmKRName() {
        let name = "";

        if (vm.isKruipruimte) {
            name = "kruipruimte";
        } else {
            if (vm.isOnverwKelder) {
                name = "onverwarmde kelder";
            }
        }
        return name;
    } //-- end: getKenmKRName ------------------------------------------------------------------------//

    function addConstr(entdata) {
        let type = null;
        if (typeof entdata === 'string') {
            type = entdata;
            entdata = null;
        }
        vm.addConstrProgress = true;
        conLogic.createConstr(entdata, type);
        updateLists();
        type = entdata ? entdata.EntityId : type;
        if (type === 'CONSTRL') {
            ntaDependencyValidation.validateLineaireConstructies();
        }
        vm.addConstrProgress = false;
    } //-- end: addConstr ------------------------------------------------------------------------//


    function copyConstr(entdata) {
        if (!entdata) {
            return;
        }

        let newEntdatas = ntaEntityData.copy(entdata.EntityDataId);
        let constr = newEntdatas.find(function (x) { return x.EntityId === entdata.EntityId; });

        //--- Propdatas op touched zetten
        constr.PropertyDatas.forEach(function (propdata, index) {
            propdata.Touched = true;
            ntaEntityData.saveprop(propdata);
        });
        updateLists();
    } //-- end:    copyConstr ------------------------------------------------------------------------//

    function deleteConstr(entdata) {
        if (!entdata) {
            return;
        }

        const type = entdata.EntityId;
        if (vm.constrLs().length <= 1 & (conLogic.isBovenMaaiveld || conLogic.isOnderMaaiveld)) { //-- kan het laatste vlak niet weggooien bij vloeren die verplicht een lineaire constructie moeten hebben
            ntaMeldingen.warning("[W033]", 'Verwijderen niet toegestaan');
            return;
        }

        ntaEntityData.delete(entdata.EntityDataId);
        updateLists();

        if (type === 'CONSTRL') {
            ntaDependencyValidation.validateLineaireConstructies();
        }
    } //-- end: deleteConstr ------------------------------------------------------------------------//

    vm.sortableConstrD = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            ntaEntityData.SaveReOrder(vm.constrDsList);
        }
    };

    vm.sortableConstrT = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            ntaEntityData.SaveReOrder(vm.constrTsList);
        }
    };

    vm.sortableConstrL = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            ntaEntityData.SaveReOrder(vm.constrLsList);
        }
    };

    vm.sortableConstrP = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            ntaEntityData.SaveReOrder(vm.constrPsList);
        }
    };

    vm.sortableConstrWG = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            ntaEntityData.SaveReOrder(vm.constrWGsList);
        }
    };
}]);

