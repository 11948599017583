﻿angular.module('projectModule')
    .controller("InstallationPvControllerV2",
        ['$scope', '$controller', 'ntaValidation', 'ntaEntityData', 'ntabuilding', '$routeParams', 'ntaDependencyValidation', 'PvFactory', 'ntaSharedLogic', 'ntaMeasure', 'Pager',
function ($scope,   $controller,   ntaValidation,   ntaEntityData,   ntabuilding,   $routeParams,   ntaDependencyValidation,   PvFactory,   ntaSharedLogic,   ntaMeasure,   Pager) {
    'use strict';
    const base = $controller('NTABaseController', { $scope: $scope });
    const vm = angular.extend(this, base);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    let _typeInvoer = null;

    vm.ntaValidation = ntaValidation;
    vm.installId = $routeParams.id3;

    const pvLogic = new PvFactory(vm.installId, ntaDependencyValidation);

    const _units = pvLogic.alleUnits;               //-- alle units uit de berekening
    const _dummyUnits = [{ "EntityDataId": 0 }];    //-- geeft aan dat de pvVelden op gebouwniveau zijn of in de factory pas gekoppeld worden aan een unit omdat ze per gebouwdeel gelden.
    const _pvVeldBelemmeringenVisible = new Map();  //-- per pvVeld, of de bijbehorende belemmeringen zichtbaar zijn

    vm.units = _units;
    vm.unitsVeldenTabel = [];           //-- units die weergegven worden in de pvVelden Tabel op formulier

    vm.getPvLogic = pvLogic;
    vm.properties = pvLogic.properties;
    vm.propertiesPvVeld = pvLogic.propertiesPvVeld;
    vm.pvData = pvLogic.pvData;

    //-- voor de ui-sortable
    vm.pvVeldenPerUnitList = {};        //-- object dat per unit een array van pvveld bijhoudt van het typeInvoer 'per app/unit' bij 'per gebouw' en 'per gebouwdeel' worden de velden in een unit gestopt met id 0

    vm.getName = getName;
    vm.getPropData = getPropData;
    vm.getCodedValues = pvLogic.getCodedValues

    vm.isHidden = pvLogic.isHidden;
    vm.isReadOnly = pvLogic.isReadOnly;
    vm.isDisabled = pvLogic.isReadOnly;

    vm.getSelectionTable = pvLogic.getSelectionTable;
    vm.saveValueSelectionTable = pvLogic.saveValueSelectionTable;
    vm.isHiddenPvVeldHeader = pvLogic.isHiddenPvVeldHeader;
    vm.hasBelemmering = pvLogic.hasBelemmering;

    vm.getForm = getForm;
    vm.saveValue = saveValue;
    vm.getPlaceholder = getPlaceholder;
    vm.center = center;
    vm.belemmeringenIcon = belemmeringenIcon;
    vm.belemmeringenVisible = belemmeringenVisible;
    vm.toggleBelemmeringen = toggleBelemmeringen;

    vm.pvVelden = pvVelden;

    vm.addVeld = addVeld;
    vm.copyVeld = copyVeld;
    vm.deleteVeld = deleteVeld;

    vm.invoerUnitEntiteit = invoerUnitEntiteit;
    vm.invoerPerUnitEntiteit = invoerPerUnitEntiteit;
    vm.invoerPerGebouwdeel = invoerPerGebouwdeel;
    vm.getUnitOmschrijving = getUnitOmschrijving;
    vm.hasRelevantPvVelden = hasRelevantPvVelden;
    vm.saveUnitKoppeling = saveUnitKoppeling;
    vm.preventBackspace = ntaSharedLogic.preventBackspace;

    vm.getTitle = () => ntaSharedLogic.isEditingMeasure() ? 'Maatregel: wijzigen PV' : 'PV-systemen'

    $scope.endFormValidation = function () {
        pvLogic.endFormValidation();
    };

    //-- paging
    vm.pager = new Pager({ storageName: 'pv2', visible: shouldShowPagingBar() });
    vm.pager.on('validationRequested', validatePages);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INITIALIZATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ntaMeasure.setBtwPropIds(vm);

    updateLists(); //initieel lijsten aanmaken, anders bestaan de velden niet voordat er wordt gevalideerd.

    angular.element(function () { //alle touched properties valideren na het laden van form
        pvLogic.startFormValidation();
        vm.initializeUnitSpacing();
    });

    vm.setGeopend('PV_OPEN', vm.pvData);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function invoerPerUnitEntiteit() {
        return invoerPerAppartement() || invoerPerUnit() || invoerPerWoning();
    } //-- end: invoerPerUnitEntiteit --------------------------------------------------------------------------------//

    function invoerPerAppartement() {
        return vm.pvData.PropertyDatas['PV_INVOER'].Value === 'PVINVOER_APP';
    } //-- end: invoerPerAppartement --------------------------------------------------------------------------------//

    function invoerPerUnit() {
        return vm.pvData.PropertyDatas['PV_INVOER'].Value === 'PVINVOER_UNIT';
    } //-- end: invoerPerUnit --------------------------------------------------------------------------------//

    function invoerPerWoning() {
        return vm.pvData.PropertyDatas['PV_INVOER'].Value === 'PVINVOER_WONING';
    } //-- end: invoerPerUnit --------------------------------------------------------------------------------//

    function invoerPerGebouw() {
        return vm.pvData.PropertyDatas['PV_INVOER'].Value === 'PVINVOER_GEB';
    } //-- end: invoerPerGebouw --------------------------------------------------------------------------------//

    function invoerPerGebouwdeel() {
        return (vm.pvData.PropertyDatas['PV_INVOER'].Value === 'PVINVOER_GEBDEEL') && (!ntaSharedLogic.isAppartementOfUtiliteitVoorBestaandeBouw());
    } //-- end: invoerPerGebouwdeel --------------------------------------------------------------------------------//

    function invoerUnitEntiteit() {
        return {
            'PVINVOER_APP': 'appartementen',
            'PVINVOER_UNIT': 'units',
            'PVINVOER_WONING': 'woningen',
        }[vm.pvData.PropertyDatas['PV_INVOER'].Value] || 'omschrijving';
    } //-- end: invoerUnitEntiteit --------------------------------------------------------------//

    function updateLists() {
        //-- onderstaande lijst zijn voor de ng-model binding van de sortabletabellen. Dit moeten namelijk assignable expressions zijn. Als ze een functie zijn doordat ze
        //-- iedere keer opnieuw opgehaald worden in de de factory gaat dat niet goed. Daarom expliciet in de controller bij houden.
        updatePvUnitsList();
        updatePvVeldenPerUnitList();

        _typeInvoer = vm.pvData.PropertyDatas['PV_INVOER'].Value
    } //-- end: updateLists ------------------------------------------------------------------------//

    function updatePvUnitsList() {
        //-- VO 2021-08-30: als ik per app/unit ben wil ik een lijst met units hebben, anders is mijn unit entitdatayId gelijk aan 0
        if (vm.invoerPerUnitEntiteit()) {
            vm.unitsVeldenTabel = _units;
        } else {
            vm.unitsVeldenTabel = _dummyUnits;  //-- geeft aan dat de pvVelden op gebouwniveau zijn of in de factory pas gekoppeld worden aan een unit omdat ze per gebouwdeel gelden.
        }
    } //-- end: updatePvUnitsList ------------------------------------------------------------------------//

    function updatePvVeldenPerUnitList() {
        vm.pvVeldenPerUnitList = {};
        if (vm.invoerPerUnitEntiteit()) {
            //-- pvvelden lijst vullen per unit
            for (const unit of _units) {
                vm.pvVeldenPerUnitList[unit.EntityDataId] = pvLogic.pvVeldenByUnit(unit);
            }
        } else if (invoerPerGebouw()) {
            vm.pvVeldenPerUnitList[0] = pvLogic.pvVeldenByGebouw();
        } else {
            vm.pvVeldenPerUnitList[0] = pvLogic.pvVeldenByGebouwdeel();
        }
    } //-- end: updatePvVeldenPerUnitList ------------------------------------------------------------------------//

    function validatePages(event) {
        const invalidPropdatas = pvLogic.startFormValidation();
        const invalidEntdatas = invalidPropdatas
            .map(propdata => propdata && ntaEntityData.get(propdata.EntityDataId))
            .filter(entdata => entdata)
            .distinct();
        for (const page of event.pages) {
            const unitsOnPage = page.rows;
            page.isValid = !unitsOnPage.some(unit => invalidEntdatas.some(entdata => entdataBelongsToUnit(entdata, unit)));
        }
        vm.pager.visible = shouldShowPagingBar();
    } //-- end: validatePages -------------------------------------------------------------------//

    function shouldShowPagingBar() {
        return vm.pvData.PropertyDatas['PV_INVOER'].Value === 'PVINVOER_APP';
    } //-- end: shouldShowPagingBar -------------------------------------------------------------//


    function entdataBelongsToUnit(entdata, unit) {
        if (entdata === unit) return true;
        if (ntaEntityData.isRelation(unit, entdata)) return true;
        // TODO: welke andere entiteiten komen hier zoal terug op het formulier?
        return false;
    } //-- end: entdataBelongsToUnit ------------------------------------------------------------//

    function getForm() {
        return pvLogic.form_pvsys;
    } //-- end: getForm --------------------------------------------------------------------------------//

    function saveValue(prop, entdata) {
        pvLogic.saveValue(prop, entdata);
        vm.initializeUnitSpacing();

        if (prop.Id === 'PV-VELD_BELEM') {
            const propValue = prop.getValue(entdata);
            if (['BELEMTYPE_ZIJ_BEIDE', 'BELEMTYPE_ZIJ_LINKS', 'BELEMTYPE_ZIJ_RECHTS'].includes(propValue)) {
                toggleBelemmeringen(entdata, true);
            }
        }
        if (['PV_INVOER', 'PV_WATTPIEK'].includes(prop.Id)) {
            updateLists();
        }
    } //-- end: saveValue --------------------------------------------------------------------------------//

    function getName(prop) {
        let name = base.getName(prop);

        if (['PV-VELD_AANTALPNL', 'PV-VELD_AANTALM2'].includes(prop.Id)) {
            let perEenheid;
            if (invoerPerWoning()) {
                perEenheid = 'per woning';
            } else if (invoerPerAppartement()) {
                perEenheid = 'per appartement';
            } else if (invoerPerUnit()) {
                perEenheid = 'per unit';
            } else if (invoerPerGebouw()) {
                perEenheid = '';
            } else {
                perEenheid = 'per gebouwdeel';
            }
            name = name.replace('{{per eenheid}}', perEenheid);
        }

        return name;
    } //-- end: getName --------------------------------------------------------------------------------//

    function getPropData(prop, entdata) {
        // TODO: dit is een beetje lelijke hack om `updateLists` uit te voeren zodra PV_INVOER van waarde is veranderd. Graag anders oplossen.
        if (_typeInvoer !== vm.pvData.PropertyDatas['PV_INVOER'].Value) {
            updateLists();
        }

        if (entdata.EntityId === "PV-VELD" && !entdata.Relevant && invoerPerUnitEntiteit()) {
            //-- in dit geval wil ik de text n.v.t. zien, zonder dat mijn data overschreven wordt. Die wil ik namelijk weer
            //-- terug hebben als ik de unit wel weer aanvink en het pv-veld relevant wordt.
            return {
                Value: 'n.v.t.',
                PropertyDataId: '0',
            };
        } else {
            return base.getPropData(prop, entdata);
        }
    } //-- end: getPropdata ------------------------------------------------------------------------//

    function getPlaceholder(prop, entdata) {
        return base.getPlaceholder(prop, entdata, pvLogic);
    } //-- end: getPlaceholder ------------------------------------------------------------------------//

    function pvVelden(unit) {
        switch (_typeInvoer) {
            case 'PVINVOER_APP':
            case 'PVINVOER_UNIT':
            case 'PVINVOER_WONING':
                return pvLogic.pvVeldenByUnit(unit);
            case 'PVINVOER_GEB':
                return pvLogic.pvVeldenByGebouw();
            case 'PVINVOER_GEBDEEL':
                return pvLogic.pvVeldenByGebouwdeel()
            default:
                return [];
        }
    } //-- end: pvVelden --------------------------------------------------------------------------------//

    function hasRelevantPvVelden(unit) {
        //-- heeft deze unit relaties met een PV-VELD van het huidige invoertype EN van dit systeem, en zo ja is dit veld dan relevant
        return ntaEntityData.getChildren(unit, "PV-VELD")
            .filter(veld => veld.PropertyDatas['PV-VELD_TYPEINVOER'].Value === _typeInvoer && ntaSharedLogic.hasRelation(vm.pvData, veld))
            .some(pvVeld => pvVeld.Relevant);
    } //-- end: hasRelevantPvVelden --------------------------------------------------------------------------------//

    function saveUnitKoppeling(unit) {
        //-- VO 2021-09-06: afhankelijk van de invoer (per app/unit of per gebouwdeel) moet de koppeling anders gelegd worden.
        //-- bij "per app/unit" moeten alleen de PV-VELD die gekoppeld zitten aan deze unit op (ir)relevant gezet worden
        //-- bij "per gebouwdeel" moeten alle PV-Velden een koppeling krijgen of ontkoppeld worden.
        const currentRelevancy = hasRelevantPvVelden(unit);
        if (invoerPerUnitEntiteit()) {
            //-- relevantie zetten
            ntaEntityData.setEntityRelevancy(pvLogic.pvVeldenByUnit(unit), !currentRelevancy);
            //-- nu de validatie van deze pv-velden aanroepen omdat validaties van de properties op het formulier gewist moeten worden indien pv-veld niet meer relevant is, dus relevant waren.
            if (currentRelevancy) {
                ntaSharedLogic.startFormValidation(pvLogic.pvVeldenByUnit(unit), pvLogic);
            }
        }
        if (invoerPerGebouwdeel()) {
            if (currentRelevancy) {
                //-- koppelingen tussen deze unit en alle velden van het type 'per gebouwdeel' weggooien
                const pvVelden = pvLogic.pvVeldenByGebouwdeel().filter(veld => ntaSharedLogic.hasRelation(unit, veld));
                const oldRelations = ntaEntityData.getChildRelations(unit, 'PV-VELD');
                for (const oldRelation of oldRelations) {
                    //-- VO 2021-09-08: controleer of de child van deze relatie voorkomt in de lijst met gebouwdeelvelden.
                    if (pvVelden.filter(veld => veld.EntityDataId === oldRelation.Child).length > 0) {
                        ntaEntityData.deleteRelation(oldRelation.EntityRelationDataId);
                    }
                }
            } else {
                //-- koppelingen tussen deze unit en alle velden aanmaken
                for (var veld of pvLogic.pvVeldenByGebouwdeel()) {
                    ntaEntityData.createRelation(unit.EntityDataId, veld.EntityDataId, 0, 0); //geen cascade -> als UNIT wordt gekopieerd wil je niet de PV-VELD meekopieren alleen de relatie
                }

            }
        }
    } //-- end: saveUnitKoppeling --------------------------------------------------------------------------------//

    function getUnitOmschrijving(unit) {
        if (!unit) {
            return;
        }
        if (unit.EntityDataId === 0) {
            return ntaEntityData.getFirstWithEntityId('GEB').PropertyDatas['GEB_OMSCHR'].Value;
        } else {
            const omschr = unit.PropertyDatas["UNIT_OMSCHR"].Value;
            let aantal = '';
            if (!ntaSharedLogic.isAppartementOfUtiliteitVoorBestaandeBouw()) {
                const propName = ntaSharedLogic.isUtiliteit() ? 'UNIT_AANTU' : 'UNIT_AANTA';
                aantal = " (" + unit.PropertyDatas[propName].Value + "x)";
            }
            return omschr + aantal;
        }
    } //-- end: getUnitOmschrijving ------------------------------------------------------------------------//

    function center(prop) {
        if (['PV-VELD_HELLING', 'PV-VELD_AANTALPNL', 'PV-VELD_AANTALM2'].includes(prop.Id)) {
            return 'columnCenter';
        }

        return '';
    } //-- end: center ------------------------------------------------------------------------//

    function addVeld(pvVeld, unit) {
        //-- VO 2021-09-08: afhankelijk van het invoertype van het systeem moet de parentrelaties bepaald worden. Ofwel,
        //-- moet het veld gekoppeld worden aan 1 unit (per/app), of aan alle units (per gebouwdeel) of aan geen units (per gebouw)
        pvLogic.createVeld(pvVeld, unit);

        updateLists();
    }; //-- end: addVeld --------------------------------------------------------------------------------//

    function copyVeld(pvVeld, unit) {
        if (!pvVeld) {
            return;
        }
        ntaEntityData.copy(pvVeld.EntityDataId);

        updateLists();
    } //-- end: copyVeld ------------------------------------------------------------------------//

    function deleteVeld(pvVeld, unit) {
        if (!pvVeld || pvVelden(unit).length === 1) {
            return;
        }

        ntaEntityData.delete(pvVeld.EntityDataId)

        updateLists();
    } //-- end: deleteVeld ------------------------------------------------------------------------//

    function belemmeringenIcon(pvVeld) {
        return belemmeringenVisible(pvVeld)
            ? 'expand_less'
            : 'expand_more';
    } //-- end: belemmeringenIcon ---------------------------------------------------------------//

    function belemmeringenVisible(pvVeld) {
        return !!_pvVeldBelemmeringenVisible.get(pvVeld)
            || !!hasBelemmeringenError(pvVeld);
    } //-- end: belemmeringenVisible ---------------------------------------------------------------//

    function toggleBelemmeringen(entdata, show = null) {
        if (!entdata) return;

        const wasVisible = belemmeringenVisible(entdata);

        const errorPropdata = hasBelemmeringenError(entdata);
        if (errorPropdata) {
            show = true;
        } else if (show === null) {
            show = !wasVisible;
        } else {
            show = !!show;
        }
        _pvVeldBelemmeringenVisible.set(entdata, show);

        if (errorPropdata) {
            const form = getForm();
            const control = form && form['ntainput' + errorPropdata.PropertyDataId];
            if (control && control.$$element) {
                control.$$element.focus();
            }
        }
        if (show !== wasVisible && show) {
            setTimeout(() => base.initializeUnitSpacing(), 500);
        }
    } //-- end: toggleBelemmeringen ------------------------------------------------------------------------//

    function hasBelemmeringenError(entdata) {
        if (!entdata) return true;

        const belemmering = pvLogic.pvBelemmeringByPvVeld(entdata);

        const errorPropdata =
            belemmering && belemmering.PropertyDatas.find(propdata => {
                const prop = ntabuilding.properties[propdata.PropertyId];
                return !pvLogic.isReadOnly(prop, belemmering)
                    && !pvLogic.validate(prop, propdata, belemmering)
                    && propdata;
            });

        return errorPropdata || false;
    } //-- end: hasBelemmeringenError ------------------------------------------------------------------------//

    vm.sortableOptionsPvVelden = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            const pvVeldenPerUnit = vm.pvVeldenPerUnitList[ui.item.attr("unitId")];
            ntaEntityData.SaveReOrder(pvVeldenPerUnit);
        }
    } //-- end: sortableOptionsVentilatoren ------------------------------------------------------------------------//

}]);
