﻿angular.module('projectModule').service('ntaLocal', ['$log', function ($log) {
    'use strict';
    const self = this;

    /// ntaLocal biedt de mogelijkheid om elke wijziging in de client op te slaan, zodat deze later
    ///  alsnog naar de server gestuurd kan worden.
    /// Dit wordt mbv localforage opgeslagen (die slaat het achter de schermen op in een IndexedDB,
    ///  of als dat niet ondersteund wordt, in localStorage).
    /// Voor een gebouw wordt dan onder de key ‘building_{buildingId}’ (zie de functie getBuildingKey)
    ///  een array opgeslagen van alle updateData-objecten.

    /// -- Imports --------------------------------------------------------------------------------

    // localforage wordt geïmporteerd in main.js
    // uuid wordt geladen in Views\Shared\__Layout.cshtml


    /// -- Instance variables ---------------------------------------------------------------------

    const MAX_ATTEMPTS = 5;         // hoe vaak een wijziging maximaal aangeboden moet worden
    const MAX_HOURS_OLD = 7 * 24;   // na hoeveel uur een wijziging weggegooid wordt

    const _collatorNL = new Intl.Collator('nl');

    const _eventHandlers = new Map();

    /// -- Exports --------------------------------------------------------------------------------

    self.getClientId = getClientId;
    self.rememberUpdate = rememberUpdate;
    self.forgetUpdates = forgetUpdates;
    self.hasUpdates = hasUpdates;
    self.getUpdates = getUpdates;
    self.getUpdatesForNextAttempt = getUpdatesForNextAttempt;
    self.clearExpiredUpdates = clearExpiredUpdates;
    self.on = on;
    self.off = off;


    /// -- Initialization -------------------------------------------------------------------------

    /// We vragen of de IndexedDB bewaard kan blijven
    if (navigator.storage && navigator.storage.persist) {
        navigator.storage.persist().then(function (persistent) {
            if (persistent)
                $log.info("Storage will not be cleared except by explicit user action. 😀");
            else
                $log.warn("Storage MAY be cleared by the UA under storage pressure. 🤨");
        });
    } else {
        $log.warn("Storage can NOT be made persistent. ☹");
    }


    /// -- Implementation -------------------------------------------------------------------------

    // on: hiermee registreer je een event handler voor events met de opgegeven eventName (zoals ‘forgetUpdates’).
    function on(eventName, eventHandler) {
        const handlers = _eventHandlers.get(eventName) || _eventHandlers.set(eventName, new Set()).get(eventName);
        handlers.add(eventHandler);
    }

    // off: hiermee verwijder je een event handler voor events met de opgegeven eventName (zoals ‘forgetUpdates’). Als er geen eventHandler is opgegeven, worden alle event handlers voor de opgegeven eventName verwijderd.
    function off(eventName, eventHandler = null) {
        const handlers = eventHandler && _eventHandlers.get(eventName) || new Set();
        if (eventHandler) {
            handlers.delete(eventHandler);
        }
        if (handlers.size === 0) {
            _eventHandlers.delete(eventName);
        }
    }

    // trigger: hiermee worden alle geregistreerde event-handlers voor de opgegeven eventName uitgevoerd. Fouten worden gelogd maar verder genegeerd.
    function trigger(eventName, ...args) {
        const handlers = _eventHandlers.get(eventName) || new Set();
        for (const handler of handlers) {
            try {
                handler.apply(self, args);
            } catch (err) {
                $log.error(err, 'tijdens', eventName, 'door', handler, 'met args [', args, ']');
            }
        }
    }

    // Geeft de huidige Client Id terug
    async function getClientId() {
        try {
            const key = 'uniec3_clientId';
            let clientId = await localforage.getItem(key);
            if (!clientId) {
                clientId = uuid.v4();
                await localforage.setItem(key, clientId);
            }
            return clientId;
        } catch (err) {
            $log.warn(`Fout in ${getClientId.name}:`, err);
        }
    }

    // Slaat de opgegeven wijziging op in de client, bij de buildingId.
    async function rememberUpdate(updateData) {
        try {
            const key = assembleKey(updateData.bldId, updateData.id);
            await localforage.setItem(key, updateData);

            //trigger(rememberUpdate.name, updateData);
        } catch (err) {
            $log.warn(`Fout in ${rememberUpdate.name}:`, err, 'bij onthouden van', updateData);
        }
    }

    // Verwijdert de opgegeven wijzigingen uit de client.
    async function forgetUpdates(buildingId, processedIds = null) {
        try {
            const keys = processedIds && processedIds.map(id => assembleKey(buildingId, id))
                || await getBuildingKeys(buildingId);
            for (const key of keys) {
                await localforage.removeItem(key);
            }

            // Geef een seintje dat deze updates verwijderd zijn
            const ids = keys.map(key => {
                const match = /^building_(\d+)_([0-9a-f-]+)$/.exec(key);
                return match && match[2];
            }).filter(id => id);
            trigger(forgetUpdates.name, buildingId, ids);

        } catch (err) {
            $log.warn(`Fout in ${forgetUpdates.name}:`, err, 'bij vergeten van', processedIds, 'van building', buildingId);
        }
    }

    // Of er wijzigingen in de client klaarstaan voor de opgegeven berekening.
    async function hasUpdates(buildingId) {
        let result = false;
        await localforage.iterate((value, key, index) => {
            result = isKeyForBuilding(key, buildingId);
            return result || undefined; // bij elke waarde anders dan ‘undefined’ stopt de iteratie
        });
        return result;
    }

    // Haalt alle wijzigingen voor de opgegeven berekening op die in de client zijn opgeslagen.
    async function getUpdates(buildingId) {
        try {
            const updates = [];
            await localforage.iterate((value, key, index) => {
                if (isKeyForBuilding(key, buildingId)) {
                    updates.push(value);
                }
            });
            // Sorteer de updates op tijdstip; de oudste het eerst
            return updates.sort((a, b) => _collatorNL.compare(a.time, b.time));
        } catch (err) {
            $log.warn(`Fout in ${getUpdates.name}:`, err, 'van building', buildingId);
            return [];
        }
    }

    // Haalt de gevraagde wijzigingen op, of alle wijzigingen voor de opgegeven berekening.
    // Van elke geretourneerde wijziging wordt de `attempt` met 1 opgehoogd.
    // Deze functie geeft een array van twee arrays terug:
    //  0. in de eerste array alle wijzigingen die nog een keer geprobeerd moeten worden,
    //  1. in de tweede array alle wijzigingen die weggegooid mogen worden.
    async function getUpdatesForNextAttempt(buildingId, updateDatas = null, increment = 1) {
        const toRetry = [], toForget = [];
        try {
            if (!updateDatas) {
                // Er zijn geen updateDatas meegegeven; dan moeten we _alle_ opgeslagen wijzigingen ophalen, bijwerken en uitsplitsen.
                updateDatas = await getUpdates(buildingId);
            }
            for (const updateData of updateDatas) {
                updateData.attempt += increment;

                // Sla het bijgewerkte object weer op
                const key = assembleKey(buildingId, updateData.id);
                await localforage.setItem(key, updateData);

                // Deel het in bij de juiste array
                (isUpdateExpired(updateData) ? toForget : toRetry).push(updateData);
            }
        } catch (err) {
            $log.warn(`Fout in ${getUpdatesForNextAttempt.name}:`, err, 'van building', buildingId);
        }

        return [toRetry, toForget];
    }

    // Geeft aan of de opgegeven wijziging verlopen is en verwijderd mag worden.
    function isUpdateExpired(updateData) {
        return updateData.attempt >= MAX_ATTEMPTS
            || updateData.time < hoursAgoUTC(MAX_HOURS_OLD);
    }

    function hoursAgoUTC(hours) {
        const d = new Date();
        d.setHours(d.getHours() - hours);
        return d.toISOString();
    }

    // Verwijder alle verlopen wijzigingen uit de client voor de opgegeven berekening,
    // of als er geen buildingId is opgegeven, voor alle berekeningen waarvan wijzigingen in de client zijn opgeslagen.
    async function clearExpiredUpdates(buildingId = null) {
        try {
            if (buildingId) {
                const updates = await getUpdates(buildingId);
                const expiredUpdates = updates.filter(u => isUpdateExpired(u));
                if (expiredUpdates.length > 0) {
                    $log.warn(clearExpiredUpdates.name, ': van building', buildingId, 'worden de volgende updates weggegooid:', expiredUpdates);
                    await forgetUpdates(buildingId, expiredUpdates.map(u => u.id));
                }
            } else {
                // Geen buildingId meegegeven, verwerk alle buildingIds in de client
                const keys = await localforage.keys();
                for (const key of keys) {
                    // (zie assembleKey hieronder voor hoe de key bepaald wordt)
                    const match = /^building_(\d+)_/.exec(key);
                    buildingId = parseInt(match && match[1]);
                    if (buildingId) {
                        await clearExpiredUpdates(buildingId);
                    }
                }
            }
        } catch (err) {
            $log.warn(`Fout in ${clearExpiredUpdates.name}:`, err, 'van building', buildingId);
        }
    }

    // Stel de ‘key’ samen waarmee de wijzigingen in localforage opgeslagen worden
    function assembleKey(buildingId, updateId) {
        return `building_${buildingId}_${updateId}`;
    }

    // Test of de opgegeven key bij het opgegeven gebouw hoort
    function isKeyForBuilding(key, buildingId) {
        return key.startsWith(`building_${buildingId}_`);
    }

    // Geeft de keys van alle opgeslagen updates die horen bij het opgegeven gebouw
    async function getBuildingKeys(buildingId) {
        const allKeys = await localforage.keys();
        return allKeys.filter(key => isKeyForBuilding(key, buildingId));
    }

}]);