﻿angular.module('projectModule')
    .factory("VerwarmingFactory",
        ['ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaMeldingen', 'ntaRounding', 'ntaSharedLogic', 'ntaSelectionTable', 'time', '$log', 'ListCache',
function (ntabuilding,   ntaValidation,   ntaEntityData,   ntaMeldingen,   ntaRounding,   ntaSharedLogic,   ntaSelectionTable,   time,   $log,   ListCache) {
    function VerwarmingLogic(installId, ntaDependencyValidation) {
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        const verwarmingLogic = this;

        verwarmingLogic.installId = installId;
        const _installationId = installId;
        const _bouwjaar = ntaSharedLogic.getBouwjaar();

        /* Properties **********************************************************************************************/
        const _propOpmerking = ntabuilding.properties['VERW_OPM'];
        const _verwOpwekkerProperties = ntabuilding.properties['VERW-OPWEK'];
        const _verwDistributieProperties = ntabuilding.properties['VERW-DISTR'];
        const _verwDistributieBinnenProperties = ntabuilding.properties['VERW-DISTR-BIN'];
        const _verwDistributieBuitenProperties = ntabuilding.properties['VERW-DISTR-BUI'];
        const _verwDistributieEigenschappenLeidingenProperties = ntabuilding.properties['VERW-DISTR-EIG'];
        const _verwDistributiePompProperties = ntabuilding.properties['VERW-DISTR-POMP'];
        const _verwAfgifteProperties = ntabuilding.properties['VERW-AFG'];
        const _verwAfgifteVentilatorenProperties = ntabuilding.properties['VERW-AFG-VENT'];
        const _verwVatProperties = ntabuilding.properties['VERW-VAT'];
        const _propVatAanwezig = ntabuilding.properties['VERW_VAT_AANW'];

        /* Data *************************************************************************************************************************************************************/
        const _verwData = ntaEntityData.getFirstChild(_installationId, 'VERW');
        const _verwDataId = _verwData.EntityDataId;

        const _verwDistributieData = ntaEntityData.getFirstChild(_verwData, 'VERW-DISTR');

        const _verwDistributieBinnenData = ntaEntityData.getFirstChild(_verwDistributieData, 'VERW-DISTR-BIN');
        const _verwDistributieEigenschappenLeidingenBinnenData = ntaEntityData.getFirstChild(_verwDistributieBinnenData);

        const _verwDistributieBuitenData = ntaEntityData.getFirstChild(_verwDistributieData, 'VERW-DISTR-BUI');
        const _verwDistributieEigenschappenLeidingenBuitenData = ntaEntityData.getFirstChild(_verwDistributieBuitenData);

        const _verwDistributieEigenschappenLeidingenData = [_verwDistributieEigenschappenLeidingenBinnenData, _verwDistributieEigenschappenLeidingenBuitenData];

        const _verwRekenzones = getRekenzones();

        /* Tabellen **********************************************************************************************/
        const _opwekkerTypes = createTable9_0();
        const _ontwerpAanvoertemperaturen = createTable9_14().sort(ntaSharedLogic.orderElements);
        const _stralingsverwarmingTypes = ntabuilding.ntaVersionId < 300 ? createTable9_8().sort(ntaSharedLogic.orderElements) : [];
        const _ruimteTemperatuurRegelingTypes = createTable9_3_1().sort(ntaSharedLogic.orderElements);
        const _oppervlakteTemperatuurTypes = ntabuilding.ntaVersionId < 300 ? createTable9_3_5().sort(ntaSharedLogic.orderElements) : [];
        const _afgifteVentilatorTypes = createTable9_11_12_13().sort(ntaSharedLogic.orderElements);

        const _listCache = new ListCache();

        const _selectionTable = {
            'H': [],
            'REGIO': [],
            'BRON': [],
            'LOC': [],
            'BVAT': [],
        };

        const _productTypes = [
            { modelType: 'H',     entityId: 'VERW-OPWEK', productIdPropertyId: 'VERW-OPWEK_TOEW',   invoerPropertyId: 'VERW-OPWEK_INVOER', valueSpecifiek: 'VERW-OPWEK_INVOER_PROD', valueForfaitair: 'VERW-OPWEK_INVOER_FORF' },
            { modelType: 'REGIO', entityId: 'VERW-OPWEK', productIdPropertyId: 'VERW-OPWEK_REGIO',  invoerPropertyId: 'VERW-OPWEK_INVOER', valueSpecifiek: 'VERW-OPWEK_INVOER_PROD', valueForfaitair: 'VERW-OPWEK_INVOER_FORF' },
            { modelType: 'BRON',  entityId: 'VERW-OPWEK', productIdPropertyId: 'VERW-OPWEK_POMP',   invoerPropertyId: 'VERW-OPWEK_INVOER', valueSpecifiek: 'VERW-OPWEK_INVOER_PROD', valueForfaitair: 'VERW-OPWEK_INVOER_FORF' },
            { modelType: 'LOC',   entityId: 'VERW-OPWEK', productIdPropertyId: 'VERW-OPWEK_HT_LOC', allOpwekkers: true },
            { modelType: 'BVAT', entityId: 'VERW-VAT', productIdPropertyId: 'VERW-VAT_TYPE', allOpwekkers: true },
        ];

        let _aanvoerTempList = [];
        const _subValuesProducts = [];

        verwarmingLogic.selectionTable = _selectionTable;
        verwarmingLogic.subValuesProducts = _subValuesProducts;

        verwarmingLogic.propOpmerkingen = _propOpmerking;
        verwarmingLogic.verwOpwekkerProperties = _verwOpwekkerProperties;
        verwarmingLogic.verwDistributieProperties = _verwDistributieProperties;
        verwarmingLogic.verwDistributiePompProperties = _verwDistributiePompProperties;
        verwarmingLogic.verwDistributieBinnenProperties = _verwDistributieBinnenProperties;
        verwarmingLogic.verwDistributieBuitenProperties = _verwDistributieBuitenProperties;
        verwarmingLogic.verwDistributieEigenschappenLeidingenProperties = _verwDistributieEigenschappenLeidingenProperties;
        verwarmingLogic.verwAfgifteProperties = _verwAfgifteProperties;
        verwarmingLogic.verwAfgifteVentilatorenProperties = _verwAfgifteVentilatorenProperties;
        verwarmingLogic.verwVatProperties = _verwVatProperties;
        verwarmingLogic.propVatAanwezig = _propVatAanwezig;

        verwarmingLogic.verwData = _verwData;
        verwarmingLogic.verwDataId = _verwDataId;
        verwarmingLogic.verwOpwekkersData = verwOpwekkersData;
        verwarmingLogic.verwDistributieData = _verwDistributieData;
        verwarmingLogic.verwDistributiePompData = verwDistributiePompData;
        verwarmingLogic.verwDistributieBinnenData = _verwDistributieBinnenData;
        verwarmingLogic.verwDistributieBuitenData = _verwDistributieBuitenData;
        verwarmingLogic.verwDistributieEigenschappenLeidingenData = _verwDistributieEigenschappenLeidingenData;
        verwarmingLogic.verwAfgifteData = verwAfgifteData;
        verwarmingLogic.rekenzones = _verwRekenzones;
        verwarmingLogic.getAfgifteRekenzones = getAfgifteRekenzones;
        verwarmingLogic.getAfgifteVentilatoren = getAfgifteVentilatoren;
        verwarmingLogic.verwVatData = verwVatData;

        verwarmingLogic.pompNr = pompNr;
        verwarmingLogic.afgifteNr = afgifteNr;
        verwarmingLogic.opwekkerNr = opwekkerNr;
        verwarmingLogic.vatNr = vatNr;

        verwarmingLogic.opwekkerTypes = _opwekkerTypes;
        verwarmingLogic.afgifteTypes = afgifteTypes;
        verwarmingLogic.buffervatTypes = buffervatTypes;

        verwarmingLogic.setEnergiefracties = setEnergiefracties;
        verwarmingLogic.checkPrioOpwekker = checkPrioOpwekker;
        verwarmingLogic.checkGemeenschappelijkOpwekker = checkGemeenschappelijkOpwekker;
        verwarmingLogic.allowMultiplePumps = allowMultiplePumps;

        verwarmingLogic.pompIsHidden = pompIsHidden;
        verwarmingLogic.afgifteTabelIsHidden = afgifteTabelIsHidden;
        verwarmingLogic.ventilatorIsHidden = ventilatorIsHidden;
        verwarmingLogic.leidingenIsHidden = leidingenIsHidden;
        verwarmingLogic.distributiepompTabelIsHidden = distributiepompTabelIsHidden;
        verwarmingLogic.eigenschappenLeidingenRowIsHidden = eigenschappenLeidingenRowIsHidden;
        verwarmingLogic.eigenschappenDistributieleidingenIsHidden = eigenschappenDistributieleidingenIsHidden;
        verwarmingLogic.propertyHeaderIsHidden = propertyHeaderIsHidden;
        verwarmingLogic.conditieOCY = conditieOCY;
        verwarmingLogic.buffervatenIsHidden = buffervatenIsHidden;
        verwarmingLogic.buffervatenAanwezig = buffervatenAanwezig;

        verwarmingLogic.koppelAlleZonesAanLaatsteAfgifte = koppelAlleZonesAanLaatsteAfgifte;
        verwarmingLogic.alleZonesGekoppeldAanAfgiftes = alleZonesGekoppeldAanAfgiftes;
        verwarmingLogic.alleAfgiftesGekoppeldAanZone = alleAfgiftesGekoppeldAanZone;
        verwarmingLogic.startValidationEnergiefractie = startValidationEnergiefractie;

        verwarmingLogic.isHidden = isHidden;
        verwarmingLogic.isReadOnly = isReadOnly;

        verwarmingLogic.validate = validate;
        verwarmingLogic.validateDependencies = validateDependencies;

        verwarmingLogic.startFormValidation = startFormValidation;
        verwarmingLogic.endFormValidation = endFormValidation;

        verwarmingLogic.saveValue = saveValue;
        verwarmingLogic.getCodedValues = getCodedValues;

        verwarmingLogic.getListItem = getListItem;
        verwarmingLogic.getSelectionTable = getSelectionTable;
        verwarmingLogic.saveValueSelectionTable = saveValueSelectionTable;
        verwarmingLogic.setSelectedItemSelectionTable = setSelectedItemSelectionTable;
        verwarmingLogic.dependencyValidator = ntaDependencyValidation;



        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         INITIALIZATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        loadProductsFromServer();
        for (const afgifte of verwAfgifteData()) {
            let rekenzones = getAfgifteRekenzones(afgifte);

            // Als het afgiftesysteem is gekoppeld aan een rekenzone waar het koelsysteem zelf niet aan gekoppeld is, dan moeten we die loskoppelen
            const fouteRekenzoneIds = new Set(rekenzones.filter(rz => !verwarmingLogic.rekenzones.includes(rz)).map(rz => rz.EntityDataId));
            if (fouteRekenzoneIds.size > 0) {
                const relations = ntaEntityData.getParentRelations(afgifte, 'RZ').filter(rel => fouteRekenzoneIds.has(rel.Parent));
                for (const relation of relations) {
                    ntaEntityData.deleteRelation(relation.EntityRelationDataId);
                }
                rekenzones = rekenzones.filter(rz => !fouteRekenzoneIds.has(rz.EntityDataId));
            }

            for (const rekenzone of rekenzones) {
                getAfgifteVentilatoren(afgifte, rekenzone);
            }
        }
        koppelAlleZonesAanLaatsteAfgifte();
        deleteOudeRelatiesAfgifteZone();

        setDefaultValueFabricagejaar();
        setDefaultValuesOpwekkers();


        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        function verwOpwekkersData() {
            return ntaEntityData.getChildren(_verwData, 'VERW-OPWEK', true);
        } //-- end: verwOpwekkersData ------------------------------------------------------------------------//

        function verwDistributiePompData() {
            return ntaEntityData.getChildren(_verwDistributieData, 'VERW-DISTR-POMP', true);
        } //-- end: verwDistributiePompData ------------------------------------------------------------------------//

        function verwAfgifteData() {
            return ntaEntityData.getChildren(_verwData, 'VERW-AFG', true);
        } //-- end: verwAfgifteData ------------------------------------------------------------------------//

        function verwVatData() {
            let vaten = ntaEntityData.getChildren(_verwData, 'VERW-VAT', true);
            if (ntabuilding.ntaVersionId >= 300 && vaten.length === 0) {
                ntaEntityData.create("VERW-VAT", -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": _verwDataId, "ParentEntityId": "VERW" }], []);
                vaten = ntaEntityData.getChildren(_verwData, 'VERW-VAT', true);
            }
            return vaten;
        } //-- end: verwVatData ------------------------------------------------------------------------//

        function getListItem(prop, entdata, list = null) {
            let listItem = null;

            if (typeof prop === 'string') prop = ntabuilding.properties[prop];

            if (prop && prop.Domain && prop.Domain.DomainType > 1 && entdata) {
                let tmpList = null;
                let tmpId;
                let propData = entdata.PropertyDatas[prop.Id];
                switch (prop.Id) {
                    case 'VERW-VAT_TYPE':
                    case 'VERW-OPWEK_TOEW':
                        tmpId = parseInt(propData.Value);
                        tmpList = entdata.filteredItems;
                        break;
                    case 'VERW-OPWEK_REGIO':
                        tmpId = propData.Value; //-- geen parseInt want Value is een txt en geen productnummer
                        tmpList = entdata.filteredItemsRegios;
                        break;
                    case 'VERW-OPWEK_POMP':
                        tmpId = propData.Value; //-- geen parseInt want Value is een txt en geen productnummer
                        tmpList = entdata.filteredItemsBron;
                        break;
                    case 'VERW-OPWEK_HT_LOC':
                        tmpId = parseInt(propData.Value);
                        tmpList = entdata.filteredItemsLoc;
                        break;
                    default:
                        tmpId = propData.Value;
                        tmpList = list; //-- de gefilterde getCodedValue list. Op deze list moet ik checken
                        if (!tmpList) {
                            //-- anders ongefilterde codedValue list gebruiken
                            tmpList = prop.Domain.Codes;
                        }
                        break;
                }

                if (propData.Value && tmpList && tmpList.length > 0) {
                    listItem = tmpList.find(function (x) { return x.Id === tmpId; });
                }

                if (!listItem) {
                    //-- ik kan nu kijken of er eventureel een alternatief beschikbaalr is.
                    let alternative = '';
                    if (prop.Domain && prop.Domain.DomainType === 4) {
                        const product = getProductWithoutFilter(prop, entdata);
                        if (product && list) {
                            listItem = list.find(function (x) { return x.Value === product.Value; });

                            //-- Stel alternatief toestel in
                            if (listItem) {
                                alternative = listItem.Id.toString();
                            }
                        }
                    } else if (prop.Id === 'VERW-DISTR_ONTW') {
                        const aanvoertemperatuur = _ontwerpAanvoertemperaturen.find(function (x) { return x.Id === propData.Value; });
                        if (aanvoertemperatuur) {
                            listItem = list.find(function (x) { return x.Value === aanvoertemperatuur.Value; });

                            //-- Stel alternatieve temperatuur in
                            if (listItem) {
                                alternative = listItem.Id;
                            }
                        }
                    }

                    if (alternative !== '') {
                        saveValue(prop, entdata, alternative);
                    }
                }
            }

            return listItem;
        } //-- end: getListItem ------------------------------------------------------------------------//

        function getProductWithoutFilter(prop, entdata) {
            const propId = typeof prop === 'string' ? prop : prop.Id;

            let prd = null;
            if (!propId || !entdata) {
                return prd;
            }

            const productTypeInfo = _productTypes.find(pt => pt.productIdPropertyId === propId);
            const table = _selectionTable[productTypeInfo.modelType];
            if (table && table.length > 0) {
                const propData = entdata.PropertyDatas[propId];
                const prdId = parseInt(propData.Value);
                const product = table.find(x => x.Id === prdId);
                if (product) {
                    prd = product;
                }
            }

            return prd;
        } //-- end: getProductWithoutFilter  ------------------------------------------------------------------------//

        function setSelectedItemSelectionTable(prop, entdata, selectedItem = null) {
            //-- dit moet alleen voor velden van het domain type 4 of 5.
            if (prop.Domain.DomainType === 4 || prop.Domain.DomainType === 5) {
                if (!selectedItem) {
                    selectedItem = getListItem(prop, entdata);
                }

                switch (prop.Id) {
                    case 'VERW-OPWEK_TOEW':
                        if (selectedItem) {
                            entdata.selectedItem = selectedItem;
                            entdata.searchText = selectedItem.Value;
                            setAanvoerTempList(entdata);
                        } else {
                            entdata.selectedItem = null;
                            entdata.searchText = "";
                        }
                        break;
                    case 'VERW-OPWEK_REGIO':
                        if (selectedItem) {
                            entdata.selectedItemRegio = selectedItem;
                            entdata.searchTextRegio = selectedItem.Value;
                        } else {
                            entdata.selectedItemRegio = null;
                            entdata.searchTextRegio = "";
                        }
                        break;
                    case 'VERW-OPWEK_POMP':
                        if (selectedItem) {
                            entdata.selectedItemBron = selectedItem;
                            entdata.searchTextBron = selectedItem.Value;
                        } else {
                            entdata.selectedItemBron = null;
                            entdata.searchTextBron = "";
                        }
                        break;
                    case 'VERW-OPWEK_HT_LOC':
                        if (selectedItem) {
                            entdata.selectedItemLoc = selectedItem;
                            entdata.searchTextLoc = selectedItem.Value;
                        } else {
                            entdata.selectedItemLoc = null;
                            entdata.searchTextLoc = "";
                        }
                        break;
                    case 'VERW-VAT_TYPE':
                        if (selectedItem) {
                            entdata.selectedItem = selectedItem;
                            entdata.searchText = selectedItem.Value;
                        } else {
                            entdata.selectedItem = null;
                            entdata.searchText = "";
                        }
                        break;
                }
            }
        } //-- end: setSelectedItemSelectionTable ------------------------------------------------------------------------//

        function loadProductsFromServer() {
            const productsH = ntaSelectionTable.getProducts('H', ntaSharedLogic.isUtiliteit());
            const productsBVAT = ntaSelectionTable.getProducts('BVAT', ntaSharedLogic.isUtiliteit());

            for (const type of _productTypes) {
                switch (type.modelType) {
                    case "H": {
                        _selectionTable[type.modelType] = productsH || [];
                        break;
                    }
                    case "REGIO": {
                        //-- Alle unieke regio's verzamelen en sorteren
                        _selectionTable[type.modelType] = productsH.filter(p => p.Regio !== null)
                            .distinct(p => p.Regio)
                            .map(p => ({
                                Id: p.Regio,
                                Value: p.Regio,
                                Certificate: p.Certificate,
                                Opwekker: p.Opwekker,
                                IsValid: true,
                            }))
                            .sort(ntaSharedLogic.orderByValue);
                        break;
                    }
                    case "BRON": {
                        const bronCodeIds = new Map();
                        ntabuilding.properties["VERW-OPWEK_POMP"].Domain.Codes.forEach(cv => bronCodeIds.set(cv.Value, cv.Id));
                        //-- Alle unieke bronnen verzamelen en sorteren
                        _selectionTable[type.modelType] = productsH.filter(p => p.Bron !== null)
                            .distinct(p => `${p.Bron}|${p.Opwekker}|${p.Certificate}`)
                            .map(p => ({
                                Id: bronCodeIds.get(p.Bron),
                                Value: p.Bron,
                                Certificate: p.Certificate,
                                Opwekker: p.Opwekker,
                                IsValid: true,
                            }))
                            .sort(ntaSharedLogic.orderByValue);
                        break;
                    }
                    case "LOC": {
                        if (ntabuilding.ntaVersionId >= 200) {
                            var bronLocaties = ntaSelectionTable.getProducts('Bron', ntaSharedLogic.isUtiliteit());
                            _selectionTable[type.modelType] = bronLocaties.slice()
                                .sort(ntaSharedLogic.orderByValue);
                        }
                        break;
                    }
                    case 'BVAT': {
                        _selectionTable[type.modelType] = productsBVAT || [];
                        break;
                    }
                }

                let data = verwOpwekkersData();
                if (type.modelType === 'BVAT') data = verwVatData();

                for (const entdata of data) {
                    const prop = ntabuilding.properties[type.productIdPropertyId];
                    if (prop) {
                        getSelectionTable(prop, entdata);
                        setSelectedItemSelectionTable(prop, entdata);
                        setValuesDependingOnSelectionTable(entdata);
                    }
                }
            }
        } //-- end: loadProductsFromServer ------------------------------------------------------------------------//

        function getSelectionTable(prop, entdata, searchText = "") {
            const productTypeInfo = _productTypes.find(pt => pt.productIdPropertyId === prop.Id);
            const table = _selectionTable[productTypeInfo.modelType];
            if (table && table.length > 0) {
                if (prop.Domain.DomainType === 4 || prop.Domain.DomainType === 5) { //selection table value
                    let res = table;

                    //-- Eerst filteren op productspecifiek/forfaitair, indien relevant
                    if (productTypeInfo.invoerPropertyId) {
                        const propInvoer = ntabuilding.properties[productTypeInfo.invoerPropertyId];
                        const invoerValue = propInvoer.getValue(entdata);
                        if (invoerValue === productTypeInfo.valueSpecifiek) {
                            res = res.filter(item => item.Certificate === 1);
                        } else if (invoerValue === productTypeInfo.valueForfaitair) {
                            res = res.filter(item => item.Certificate === 0);
                        } else if (productTypeInfo.modelType === 'H') {
                            res = [];
                        }
                        //-- bij eigen waarde gaan alle voor regio en bronnen mee. Voor producten niet.
                    }

                    if (productTypeInfo.modelType !== 'BVAT') {
                        //-- filter op opwekker
                        const opwekType = getTypeOpwekker(entdata);
                        if (!productTypeInfo.allOpwekkers && opwekType) {
                        res = res.filter(x => x.Opwekker === opwekType.Value);
                        }

                        if (productTypeInfo.modelType === 'H') {
                            const propInv = _verwOpwekkerProperties['VERW-OPWEK_INVOER'];
                            const propFun = _verwOpwekkerProperties['VERW-OPWEK_FUNCTIE'];
                            const propBrn = _verwOpwekkerProperties['VERW-OPWEK_POMP'];
                            const propReg = _verwOpwekkerProperties['VERW-OPWEK_REGIO'];
                            const propPos = _verwOpwekkerProperties['VERW-OPWEK_POS'];
                            const propVrm = _verwOpwekkerProperties['VERW-OPWEK_VERM'];
                            const propFabWKK = _verwOpwekkerProperties['VERW-OPWEK_FABR'];
                            const propFabTst = _verwOpwekkerProperties['VERW-OPWEK_FABR_TOE'];
                            const propBijlageR = _verwOpwekkerProperties['VERW-OPWEK_EM'];
                            const propCWKlasse = _verwOpwekkerProperties['VERW-OPWEK_CW'];

                            const propdataInvoer = propInv.getData(entdata);
                            const propdataFunctie = propFun.getData(entdata);
                            const propdataPositie = propPos.getData(entdata);
                            const propdataBron = propBrn.getData(entdata);
                            const propdataRegio = propReg.getData(entdata);
                            const propdataVermogen = propVrm.getData(entdata);
                            const propdataFabrJaarWKK = propFabWKK.getData(entdata);
                            const propdataFabrJaarToestel = propFabTst.getData(entdata);
                            const propdataBijlageR = propBijlageR.getData(entdata);
                            const propdataCWKlasse = propCWKlasse.getData(entdata);

                            //-- filter op product voor alleen verwarming (solo) of voor verwarming en tapwater
                            if (propdataFunctie.Value === 'VERW-OPWEK_FUNCTIE_V') {
                                res = res.filter(function (x) { return x.Solo; });
                            }
                            //-- filter op product voor verwarming en tapwater
                            else if (propdataFunctie.Value === 'VERW-OPWEK_FUNCTIE_VT') {
                                res = res.filter(function (x) { return !x.Solo; });
                            }
                            //-- filter warmtepompen op basis van de gekozen bron.
                            if (!isHidden(propBrn, entdata) && propdataBron.Value) {
                                const bronCodedValues = propBrn.Domain.Codes;
                                let bronTxtValue = '';
                                if (bronCodedValues.find(function (x) { return x.Id === propdataBron.Value; })) {
                                    bronTxtValue = bronCodedValues.find(function (x) { return x.Id === propdataBron.Value; }).Value;
                                }
                                res = res.filter(function (x) { return x.Bron === bronTxtValue; });
                            }
                            //-- filter voor welke regio het product geldt.
                            if (!isHidden(propReg, entdata)) {
                                res = res.filter(function (x) { return x.Regio === propdataRegio.Value; });
                            }
                            //-- filter op gewenst vermogen
                            if (!isHidden(propVrm, entdata)) {
                                const vermogen = ntaSharedLogic.parseFloat(propdataVermogen.Value);
                                if (!isNaN(vermogen)) {
                                    const minVerm = vermogen - 1;
                                    const maxVerm = vermogen + 1;
                                    res = res.filter(function (x) { return x.P > minVerm && x.P < maxVerm; });
                                }
                            }
                            //-- filter op fabricagejaar igv WWK toestellen
                            if (!isHidden(propFabWKK, entdata)) {
                                const code = propFabWKK.Domain.Codes.find(code => code.Id === propdataFabrJaarWKK.Value);
                                const filterTekst = code && code.Value || '';
                                res = res.filter(function (x) { return x.Fabricagejaar === filterTekst; });
                            } else if (opwekType && opwekType.key2 === 'WKK' && propdataInvoer.Value === 'VERW-OPWEK_INVOER_FORF' && _bouwjaar >= 2015) {
                                res = res.filter(function (x) { return x.Fabricagejaar === 'fabricagejaar WKK 2015 en nieuwer'; });
                            }
                            //-- filter op fabricagejaar igv andere toestellen
                            isHidden(propFabTst, entdata); //-- zorgen dat Relevant de juiste waarde heeft.
                            if (propdataFabrJaarToestel.Relevant && propdataFabrJaarToestel.Value) {
                                const code = propFabTst.Domain.Codes.find(codedValue => codedValue.Id === propdataFabrJaarToestel.Value);
                                if (code) {
                                    res = res.filter(function (x) { return x.Fabricagejaar === code.Value || x.Fabricagejaar === null; });
                                }
                            }
                            //-- filter op CW-klasse
                            if (!isHidden(propCWKlasse, entdata)) {
                                let sCW = propdataCWKlasse.Value;
                                if (sCW === 'TAPW-OPWEK_CW_5') { sCW = 'TAPW-OPWEK_CW_4'; } //--  als klasse onbekend is met CWklasse 4 rekenene.
                                res = res.filter(function (x) { return x.CW === sCW || x.CW === null; });
                            }
                            //-- filter op bijlage R bij biomassa toestellen.
                            if (!isHidden(propBijlageR, entdata)) {
                                if (propdataBijlageR.Value === 'VERW-OPWEK_EM_B' || propdataBijlageR.Value === 'VERW-OPWEK_EM_E') {
                                    res = res.filter(function (x) { return x.BijlageR; });
                                } else {
                                    res = res.filter(function (x) { return !x.BijlageR; });
                                }
                            }
                            //--  when HO06 = forfaitair
                            if (propdataInvoer.Value === 'VERW-OPWEK_INVOER_FORF') {
                                const eersteOpwekkerData = verwOpwekkersData()[0];
                                const propdataGemeenschappelijk = eersteOpwekkerData.PropertyDatas['VERW-OPWEK_GEM'];

                                //-- AND HO05 = 'CV-ketel - gas'/'CV-ketel - olie'/'warmtepomp - gas (motor en absorptie)'
                                if (opwekType && ['VERW-OPWEK_TYPE_H', 'VERW-OPWEK_TYPE_T', 'VERW-OPWEK_TYPE_B', 'VERW-OPWEK_TYPE_U', 'VERW-OPWEK_TYPE_A'].includes(opwekType.Id)) {
                                    //-- only show values where column 'H_gemeenschappelijk' = HO42
                                    if (propdataGemeenschappelijk.Value === 'VERW-OPWEK_GEM_WEL') {
                                        res = res.filter(function (x) { return x.Gemeenschappelijk === 'gemeenschappelijke installatie'; });
                                    }
                                    else {
                                        res = res.filter(function (x) { return x.Gemeenschappelijk === 'niet-gemeenschappelijke installatie'; });
                                    }
                                }

                                //-- AND HO05 = 'CV-ketel - gas'/'CV-ketel - olie'
                                if (opwekType && ['VERW-OPWEK_TYPE_H', 'VERW-OPWEK_TYPE_T'].includes(opwekType.Id)) {
                                    //-- AND HO42 = 'niet-gemeenschappelijke installatie'
                                    if (propdataGemeenschappelijk.Value === 'VERW-OPWEK_GEM_NIET') {
                                        const systeemData = ntaEntityData.getFirstParent(entdata, 'VERW');
                                        //-- AND som van alle op dit verwarmingssysteem aangesloten rekenzones / aantal identieke systemen(op verwarmingstegel) > 500
                                        if (systeemData && ntaSharedLogic.isGroteInstallatie(systeemData)) {
                                            res = res.filter(function (x) { return x.Positie === 'buiten thermische zone'; });
                                        }
                                    }
                                }
                            }
                            //-- filter of product specifiek is voor binnen thermische schil, buiten thermische schil of beide.
                            if (!isHidden(propPos, entdata) && propdataPositie.Value) {
                                const sValuePos = propPos.Domain.Codes.find(function (x) { return x.Id === propdataPositie.Value; }).Value;
                                res = res.filter(function (x) { return x.Positie === sValuePos || x.Positie === null; });
                            }

                            if (opwekType && opwekType.Id === "VERW-OPWEK_TYPE_P" && res.length === 5) {
                                //-- ik ben nu wkk. Hier ga ik de volgorde van de producten even handmatig aanpassen. Dit gebeurt automatisch op alfabetische volgorde, maar dan
                                //-- staat 1000 kW boven 2 kW.Dat is niet wenselijk. Ik plaats dus de eerste naar de laatste.
                                const firstelement = res.shift();
                                res.push(firstelement);
                            }

                        } else if (productTypeInfo.modelType === 'LOC') {
                            //-- HO58: ‘...waarbij de kolom 'H_bron' overeenkomt met keuze van H_bron in veld H011’
                            const warmtepompbron = getListItem('VERW-OPWEK_POMP', entdata);
                            const brontype = warmtepompbron && warmtepompbron.Value;
                            if (brontype) {
                                res = res.filter(item => item.H_bron === brontype);
                            }
                        }
                    }

                    //-- regio en bron kan dubbele waarden bevatten, dus res ontdubbelen
                    res = ntaEntityData.distinct(res, x => x.Id);


                    // Als bij Settings is aangevinkt dat alleen de actuele verklaringen gekozen mogen,
                    // moeten de verlopen verklaringen er uit gefilterd worden.
                    if (ntaSharedLogic.showOnlyValidProducts()) {
                        res = res.filter(r => r.ExpiryDate == null || Date.parse(r.ExpiryDate) > Date.now());
                    }
                    if (res.length === 1) {
                        res.Singleresult = true;
                    }

                    //-- Tot slot filteren op de zoektekst
                    if (searchText && searchText.length > 0) {
                        searchText = searchText.toLowerCase();
                        res = res.filter(x => x.Value && x.Value.toLowerCase().includes(searchText));
                    }

                    //-- omdat alle drie de properties waar een selectitabel item voor geldt in dezelfde entitie zitten, moet filteredItems op deze manier
                    switch (productTypeInfo.modelType) {
                        case 'BVAT':
                        case 'H':
                            { entdata.filteredItems = res; break; }
                        case 'BRON': { entdata.filteredItemsBron = res; break; }
                        case 'REGIO': { entdata.filteredItemsRegios = res; break; }
                        case 'LOC': { entdata.filteredItemsLoc = res; break; }
                    }

                    ntaSharedLogic.checkPropdataInList(prop, entdata, res, verwarmingLogic);
                    return res;
                }
            }
            return [];
        } //-- end: getSelectionTable ------------------------------------------------------------------------//

        function setValuesDependingOnSelectionTable(entdata) {
            //-- product is een product uit de selectietabel
            if (!entdata) {
                return;
            }

            switch (entdata.EntityId) {
                case 'VERW-OPWEK': {
                    if (_selectionTable.H.length > 0) {
                        const product = getListItem('VERW-OPWEK_TOEW', entdata);

                        ntaSharedLogic.saveProductValueToPropData(verwarmingLogic, product, 'P', entdata, 'VERW-OPWEK_TOE_NON');
                        ntaSharedLogic.saveProductValueToPropData(verwarmingLogic, product, 'fPprendh', entdata, 'VERW-OPWEK_FPREN_NON');
                        ntaSharedLogic.saveProductValueToPropData(verwarmingLogic, product, 'KCO2deldh', entdata, 'VERW-OPWEK_KCO_NON');

                        //-- Moet onder bepaalde condities uit de selectietabel komen
                        const opwekType = getTypeOpwekker(entdata);
                        if (product && opwekType && opwekType.key2 === 'EW') { // Primaire energiefractie alleen als opwekkertype EW
                            ntaSharedLogic.saveProductValueToPropData(verwarmingLogic, product, 'fPdeldh', entdata, 'VERW-OPWEK_PRI_NON');

                            //-- type verklaring alleen updaten bij product of forfaitair. Bij eigen keuzen moet de gebruiker dit zetten
                            const propNEN7125Verkl = ntabuilding.properties['VERW-OPWEK_NEN7125VERKL'];
                            if (isHidden(propNEN7125Verkl, entdata)) {
                                let verkl7125 = '';
                                if (product.NEN7125Verklaring === 'gemeten') {
                                    verkl7125 = 'NEN7125VERKL_ALLGEMETEN';
                                }
                                else if (product.NEN7125Verklaring === 'berekend') {
                                    verkl7125 = 'NEN7125VERKL_BEREKEND';
                                }
                                saveValue(propNEN7125Verkl, entdata, verkl7125);
                            }
                        }
                    }

                    if (!isHidden('VERW-OPWEK_HT_INV', entdata)) {
                        switch (entdata.PropertyDatas['VERW-OPWEK_HT_INV'].Value) {
                            case 'VERW-OPWEK_HT_INV_LOC': {
                                const product = getListItem('VERW-OPWEK_HT_LOC', entdata);
                                ntaSharedLogic.saveProductValueToPropData(verwarmingLogic, product, 'fPdelbron', entdata, 'VERW-OPWEK_HT_PRI_NON');
                                ntaSharedLogic.saveProductValueToPropData(verwarmingLogic, product, 'fPrendhhpinbron', entdata, 'VERW-OPWEK_HT_HER_NON');
                                ntaSharedLogic.saveProductValueToPropData(verwarmingLogic, product, 'KCO2delbron', entdata, 'VERW-OPWEK_HT_KCO2_NON');
                                break;
                            }
                            case 'VERW-OPWEK_HT_INV_FORF': {
                                saveValue('VERW-OPWEK_HT_PRI_NON', entdata, '0,9');
                                saveValue('VERW-OPWEK_HT_HER_NON', entdata, '0,00');
                                saveValue('VERW-OPWEK_HT_KCO2_NON', entdata, '0,170');
                                break;
                            }
                        }
                    }
                    break;
                }
                case 'VERW-VAT': {
                    const propVatType = ntabuilding.properties['VERW-VAT_TYPE'];
                    const productBVAT = getListItem(propVatType, entdata);

                    ntaSharedLogic.saveProductValueToPropData(verwarmingLogic, productBVAT, 'Vsto', entdata, 'VERW-VAT_VOL_NON');
                    ntaSharedLogic.saveProductValueToPropData(verwarmingLogic, productBVAT, 'Hsto_ls', entdata, 'VERW-VAT_TRANS_NON');

                    break;
                }
            }
        } //-- end: setValuesDependingOnSelectionTable ------------------------------------------------------------------------//

        function getAfgifteRekenzones(afgifte) {
            return ntaEntityData.getParents(afgifte, 'RZ');
        } //-- end: afgifteRekenzones ------------------------------------------------------------------------//


        function getAfgifteVentilatoren(afgifte, rekenzone = null) {
            //-- als rekenzone null is, dan worden alle ventilatoren van het afgiftesysteem opgehaald
            const rekenzoneVentilatorIds = rekenzone && new Set(ntaEntityData.getChildIds(rekenzone, 'VERW-AFG-VENT'));
            const rekenzoneAfgifteventilatoren = ntaEntityData.getChildren(afgifte, 'VERW-AFG-VENT')
                .filter(afgVent => !rekenzone || rekenzoneVentilatorIds.has(afgVent.EntityDataId));

            /// Er moet altijd minstens één VERW-AFG-VENT zijn voor elke combinatie van afgifte en rekenzone.
            if (rekenzoneAfgifteventilatoren.length === 0 && rekenzone) {
                const afgifteventilatoren = getAfgifteVentilatoren(afgifte, null);

                /// Het kan zijn dat dit een oude berekening is, die geen koppeling heeft tussen VERW-AFG-VENT en RZ.
                ///  In dat geval moeten alle VERW-AFG-VENTs aan de eerste rekenzone gekoppeld worden.
                const ventilatorenZonderRZ = afgifteventilatoren.filter(afgVent => !ntaEntityData.getFirstParent(afgVent, 'RZ'));
                if (ventilatorenZonderRZ.length > 0) {
                    const firstRekenzone = getAfgifteRekenzones(afgifte)[0];
                    if (firstRekenzone) {
                        for (const ventilator of ventilatorenZonderRZ) {
                            ntaEntityData.createRelation(firstRekenzone.EntityDataId, ventilator.EntityDataId, 1, 1);
                            ventilator.PropertyDatas.forEach(propdata => propdata.Touched = true);
                        }
                        return getAfgifteVentilatoren(afgifte, rekenzone);
                    }
                }

                /// Anders moeten we een nieuwe afgifteventilator maken, en deze koppelen aan dit afgiftesysteem en deze rekenzone.
                const ventilatorId = ntaEntityData.create('VERW-AFG-VENT', -1, [
                    { "OnCopy": 1, "OnDelete": 1, "Parent": afgifte.EntityDataId, "ParentEntityId": afgifte.EntityId },
                    { "OnCopy": 1, "OnDelete": 1, "Parent": rekenzone.EntityDataId, "ParentEntityId": rekenzone.EntityId },
                ], [], null);
                const newVentilator = ntaEntityData.get(ventilatorId);
                rekenzoneAfgifteventilatoren.push(newVentilator);
                newVentilator.PropertyDatas.forEach(propdata => propdata.Touched = true);
            }

            const key = afgifte.EntityDataId + ':' + (rekenzone && rekenzone.EntityDataId || '');
            return _listCache.useCacheIfUnchanged(key, rekenzoneAfgifteventilatoren);
        } //-- end: getafgifteVentilatoren ------------------------------------------------------------------------//

        function getRekenzones() {
            return ntaEntityData.getChildren(_verwDataId, 'RZ')
                .filter(rz => rz.PropertyDatas['RZ_TYPEZ'].Value === 'RZ');
        } //-- end: getRekenzones ------------------------------------------------------------------------//

        function opwekkerNr(opwekker) {
            return verwOpwekkersData().indexOf(opwekker) + 1;
        } //-- end: opwekkerNr ------------------------------------------------------------------------//

        function pompNr(pomp) {
            return verwDistributiePompData().indexOf(pomp) + 1;
        } //-- end: pompNr ------------------------------------------------------------------------//

        function afgifteNr(afgifte) {
            return verwAfgifteData().indexOf(afgifte) + 1;
        } //-- end: afgifteNr ------------------------------------------------------------------------//

        function vatNr(buffervat) {
            return verwVatData().indexOf(buffervat) + 1;
        } //-- end: vatNr ------------------------------------------------------------------------//

        function afgifteTypes() {
            const versie = ntabuilding.ntaVersionId >= 300 ? 'gt3.2' : 'le3.2'
            let types = _verwAfgifteProperties["VERW-AFG_TYPE_AFG"].Domain.Codes
                .filter(x => x.FilterValue2 === versie);
            for (const opwekker of verwOpwekkersData()) {
                const opwekkertype = getTypeOpwekker(opwekker);
                if (opwekkertype) {
                    const afgiftetypes = new Set(opwekkertype.key5.split(','));
                    types = types.filter(x => afgiftetypes.has(x.FilterValue1));
                }
            }
            return types;
        } //-- end: afgifteTypes ------------------------------------------------------------------

        function buffervatTypes() {
            //-- conditie [HBC]
            return _verwVatProperties["VERW-VAT_INV"].Domain.Codes.filter(x => x.Id !== 'VERW-VAT_INV_PROD');
        } //-- end: buffervatTypes ----------------------------------------------------------------

        function validate(prop, propdata, entdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !entdata || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            if (propdata.PropertyId !== prop.Id && ntaSharedLogic.isUnitSpecificPropId(propdata.PropertyId)) {
                // Dan hebben we de unit-specifieke propertyData te pakken, maar ntaValidation.IsValid heeft
                // de ‘algemene’ propdata nodig; dus we vervangen propdata even (maar we geven die nu wel de unit-specifieke
                // Value).
                propdata = ntaSharedLogic.getNonUnitSpecificPropdata(propdata);
            }

            const hidden = isHidden(prop, entdata);
            const readonly = isReadOnly(prop, entdata);
            ntaSharedLogic.useUnitSpecificValue(prop, entdata);
            let valid = ntaValidation.IsValid(verwarmingLogic.form_installationverwarming, prop, propdata, hidden, readonly);

            switch (prop.Id) {
                case 'VERW-OPWEK_TOEW':
                    {
                        let opwekkerType = entdata.PropertyDatas['VERW-OPWEK_TYPE'].Value;
                        let invoer = entdata.PropertyDatas['VERW-OPWEK_INVOER'].Value;
                        let selectionTableValid = true;

                        if (opwekkerType && invoer === 'VERW-OPWEK_INVOER_PROD' || invoer === 'VERW-OPWEK_INVOER_FORF') {
                            let producten = entdata.filteredItems;

                            if (producten && producten.length <= 0) { //-- Toon E055
                                selectionTableValid = false;
                            }
                        }
                        ntaSharedLogic.setMelding("[E055]", propdata, verwarmingLogic.form_installationverwarming, selectionTableValid);

                        let verwentapwgelijkbijretourlucht = checkConditieOCM(entdata);

                        valid = valid && selectionTableValid && verwentapwgelijkbijretourlucht;
                        //-- VO 2021-02-01: deze melding mag alleen gezet worden via de vuller 'Verwarming.cs'. Ander zitten ze elkaar in het vaarwater. Deze melding wordt bij het
                        //-- opslaan van een willekeurige property verwijderd, want er moet altijd gerekend kunnen worden.
                        //if (valid) {
                        //    ntaSharedLogic.setMelding("[E040]", propdata, null, true);
                        //}

                        break;
                    }
                case 'VERW-OPWEK_ENER_NON':
                    {
                        //-- VO 2021-02-01: deze melding mag alleen gezet worden via de vuller 'Verwarming.cs'. Ander zitten ze elkaar in het vaarwater. Deze melding wordt bij het
                        //-- opslaan van een willekeurige property verwijderd, want er moet altijd gerekend kunnen worden.
                        //let fractieValid = true;
                        //if (!isHidden(prop, entdata) && !propdata.Relevant) {
                        //    if (verwOpwekkersData().length === 1 && propdata.Value !== null && ntaSharedLogic.parseFloat(propdata.Value) < 1) {
                        //        fractieValid = false;
                        //    }
                        //}
                        //ntaSharedLogic.setMelding("[E054]", propdata, verwarmingLogic.form_installationverwarming, fractieValid);

                        //valid = valid || fractieValid;

                        break;
                    }
                case 'VERW-OPWEK_ENER':
                    {
                        let fractieValid = true;
                        if (!isHidden(prop, entdata) && verwOpwekkersData().length === 1 && propdata.Value !== null && ntaSharedLogic.parseFloat(propdata.Value) < 1) {
                            fractieValid = false;
                        }
                        ntaSharedLogic.setMelding("[E039]", propdata, verwarmingLogic.form_installationverwarming, fractieValid);

                        valid = valid || fractieValid;
                        break;
                    }
                case 'VERW-OPWEK_TYPE':
                case 'VERW-OPWEK_INVOER':
                case 'VERW-OPWEK_POMP':
                    {
                        valid = checkConditieOCL(entdata) && valid;
                        valid = checkConditieOCM(entdata) && valid;
                        break;
                    }

                default:
            }
            return valid;
        } //-- end: validate ------------------------------------------------------------------------//

        /// Conditie [OCL]
        /// if HO06=eigen waarde opwekkingsrendement / eigen waarde opwekkingsrendement en fractie / eigen waarde opwekkingsrendement,
        /// fractie en hulpenergie AND HO05 = warmtepomp - elektrisch AND HO11 = ventilatieretourlucht show[E084]
        function checkConditieOCL(entdataVerwOpw) {
            let valid = true;

            const propOpwekType = ntabuilding.properties['VERW-OPWEK_TYPE']; //HO05
            const propdataOpwekType = propOpwekType.getValue(entdataVerwOpw);

            const propOpwekInv = ntabuilding.properties['VERW-OPWEK_INVOER']; //HO06
            const propdataOpwekInv = propOpwekInv.getValue(entdataVerwOpw);

            const propOpwekBronPomp = ntabuilding.properties['VERW-OPWEK_POMP']; //HO11
            const propdataOpwekBronPomp = propOpwekBronPomp.getData(entdataVerwOpw);

            if ((propdataOpwekInv === 'VERW-OPWEK_INVOER_EIG_A' || propdataOpwekInv === 'VERW-OPWEK_INVOER_EIG_B' || propdataOpwekInv === 'VERW-OPWEK_INVOER_EIG_C') &&
                (propdataOpwekType === 'VERW-OPWEK_TYPE_A') &&
                (propdataOpwekBronPomp.Value === 'VERW-OPWEK_POMP_VERE'))
            {
                    valid = false;
            }

            ntaSharedLogic.setMelding("[E084]", propdataOpwekBronPomp, verwarmingLogic.form_installationverwarming, valid);

            return valid;
        } //checkConditieOCL

        /// Conditie [OCM]
        /// als HO05=warmtepomp - elektrisch AND HO06=productspecifiek EN HO41=verwarming en warm tapwater EN HO11=ventilatieretourlucht EN TO07 van het gekoppelde warm
        /// tapwatersysteem(check via het in TO52 gekozen ventilatiesysteem de aangesloten rekenzones) = productspecifiek : controleer of
        /// bij het gekoppelde warm tapwatersysteem(check via het in TO52 gekozen ventilatiesysteem de aangesloten rekenzones) of TO22 en HO20 hetzelfde selectietabel ID hebben; zo niet toon[E087]
        function checkConditieOCM(entdataVerwOpw) {
            let valid = true;

            const propdataOpwekType = entdataVerwOpw.PropertyDatas['VERW-OPWEK_TYPE'].Value; //HO05
            const propdataOpwekInv = entdataVerwOpw.PropertyDatas['VERW-OPWEK_INVOER'].Value; //HO06
            const propdataOpwekBronPomp = entdataVerwOpw.PropertyDatas['VERW-OPWEK_POMP'].Value; //HO11
            const propdataOpwekFunc = entdataVerwOpw.PropertyDatas['VERW-OPWEK_FUNCTIE'].Value; //HO41
            const propdataToestelVerw = entdataVerwOpw.PropertyDatas['VERW-OPWEK_TOEW'];

            if (propdataOpwekType === 'VERW-OPWEK_TYPE_A' && propdataOpwekInv === 'VERW-OPWEK_INVOER_PROD' && propdataOpwekFunc === 'VERW-OPWEK_FUNCTIE_VT' && propdataOpwekBronPomp === 'VERW-OPWEK_POMP_VERE') {
                /// toestel Id opzoeken van het verwarmingstoestel
                const toestelVerwId = parseInt(propdataToestelVerw.Value);

                let hasMatch = false;
                let checkRelevant = false;
                /// de rekenzone verzamelen die aan dit verwarmingsysteem 'hangen'
                const verwSysteem = ntaEntityData.getFirstParent(entdataVerwOpw, 'VERW');
                const rekenzonesIdsVerw = ntaEntityData.getChildIds(verwSysteem, 'RZ');
                for (const rzId of rekenzonesIdsVerw) {
                    /// zoek het ventilatiesysteem dat deze rekenzone bediend.
                    const ventSysteem = ntaEntityData.getFirstParent(rzId, 'VENT');
                    if (ventSysteem) {
                        /// controleer of dit ventsysteem een relatie heeft met een opwekker van een tapwatersysteem
                        const tapwOpwekker = ntaEntityData.getFirstParent(ventSysteem, 'TAPW-OPWEK');
                        if (tapwOpwekker) {
                            const toestelProductspecifiek = tapwOpwekker.PropertyDatas['TAPW-OPWEK_INV'].Value === 'TAPW-OPWEK_INV_PROD';
                            if (toestelProductspecifiek) {
                                checkRelevant = true;
                                const toestelTapwId = parseInt(tapwOpwekker.PropertyDatas['TAPW-OPWEK_TOES'].Value);

                                /// controleren of de selectietabel Id van het verwarmingstoestel gelijk is aan het toestel Id van tapwater
                                hasMatch = hasMatch || (toestelVerwId === toestelTapwId); //-- als beide NaN zijn geldt ook NaN === NaN -> false
                            }
                        }
                    }
                    if (hasMatch) {
                        break;
                    }
                }
                //-- alleen wanneer 1 of meerdere tapwatersystemen productspecifiek is, is de check relevant, anders blijft valid gewoon true.
                if (checkRelevant) {
                    valid = hasMatch;
                }
            }

            ntaSharedLogic.setMelding("[E087]", propdataToestelVerw, verwarmingLogic.form_installationverwarming, valid);

            return valid;
        } //checkConditieOCM

        function makeAanvoerTempList() {
            if (_selectionTable.H.length === 0) {
                return;
            }

            _aanvoerTempList = [];
            let iCntWP_Productspecifiek = 0;
            //-- deze aanvoertemperatuurlijst wordt alleen getoond als ik property VERW-DISTR_ONTW_H_MODEL ben
            //-- en bevat de temperaturen die specifiek meegegeven zijn in de subvalues van het producten die overeenkomen
            for (const opwekker of verwOpwekkersData()) {
                //-- alleen temperaturen toevoegen van opwekker die WP en productspecifiek zijn.
                const opwekType = getTypeOpwekker(opwekker);
                if (opwekType && opwekType.key2 === 'WP') {
                    const invoerOpwekker = opwekker.PropertyDatas['VERW-OPWEK_INVOER'].Value;
                    if (invoerOpwekker === 'VERW-OPWEK_INVOER_PROD') {
                        const productIdOpwekker = opwekker.PropertyDatas['VERW-OPWEK_TOEW'].Value;
                        const productOpwekker = _selectionTable.H.find(function (x) { return x.Id === parseInt(productIdOpwekker); });
                        if (productOpwekker) {
                            const subValues = _subValuesProducts.filter(function (x) { return x.H_Id === productOpwekker.H_valueId; });
                            if (subValues) {
                                const tempsOpw = subValues.map(function (x, index) {
                                    return { Id: x.AanvoerTemp, Value: x.AanvoerTemp + '°C', Order: index, ImageUrl: "", FilterValue1: "", FilterValue2: "", FilterValue3: "", FilterValue4: "" };
                                });
                                _aanvoerTempList.push(...tempsOpw);

                                iCntWP_Productspecifiek++;
                            }
                        }
                    }
                }
            }
            //-- als er meerdere toestellen zijn die productspeciefieke warmtepompen hebben moet ik een lijst maken van alleen de aanvoertemperaturen die dubbel aanwezig zijn.
            if (iCntWP_Productspecifiek > 1) {
                const wpAanvoerTemperaturen = [];
                _aanvoerTempList.forEach(function (temp, index) {
                    if (wpAanvoerTemperaturen.filter(function (x) { return x.Id === temp.Id; }).length === 0) {
                        // maak lijst van voorkomende Id's
                        const cntAanvTempValues = _aanvoerTempList.filter(function (x) { return x.Id === temp.Id; }).length;
                        if (cntAanvTempValues > 1) {
                            wpAanvoerTemperaturen.push(temp);
                        }
                    }
                });
                _aanvoerTempList = wpAanvoerTemperaturen;
            }

            /// voeg aan de lijst ook nog 'Onbekend' toe wanneer vanaf versie 3.2
            if (ntabuilding.ntaVersionId >= 200 && _aanvoerTempList.length > 0) {
                const onbekend = ntabuilding.properties['VERW-DISTR_ONTW'].Domain.Codes.find(x => x.Id === 'VERW-DISTR_ONTW_GE32_M') || null;
                _aanvoerTempList.push(onbekend);
            }

        } //-- end: makeAanvoerTempList ------------------------------------------------------------------------//

        function setAanvoerTempList(opwekker) {
            if (opwekker.selectedItem) {
                const items = ntaSelectionTable.getProducts('H', opwekker.selectedItem.H_valueId);
                if (items && items.length > 0) {
                    // check of subValues van deze H_value al voorkomen in de subValuesProducts
                    const exist = _subValuesProducts.some(x => x.H_Id === opwekker.selectedItem.H_valueId);
                    if (!exist) {
                        _subValuesProducts.push(...items);
                        makeAanvoerTempList();
                    }
                }
            }
        } //-- end: setAanvoerTempList ------------------------------------------------------------------------//

        function saveValue(prop, entdata, newValue) {
            ntaSharedLogic.saveValue(prop, entdata, newValue, verwarmingLogic);
        } //-- end: saveValue ------------------------------------------------------------------------//

        function saveValueSelectionTable(prop, item, entdata) {
            ntaSharedLogic.saveValueSelectionTable(prop, item, entdata, verwarmingLogic);
        } //-- end: saveValueSelectionTable ------------------------------------------------------------------------//

        function setDescriptionInstallation(opwekker) {
            //-- ik moet eerst weten of dit de eerste opwekker is.
            if (opwekkerNr(opwekker) === 1) {
                let propdataInstallDescr = ntaEntityData.get(_installationId).PropertyDatas['INSTALL_OMSCHR'];
                if (propdataInstallDescr) {
                    let descr = "";
                    const propType = _verwOpwekkerProperties['VERW-OPWEK_TYPE'];
                    const codeType = propType.getCode(opwekker);
                    if (codeType) {
                        const propToestel = _verwOpwekkerProperties['VERW-OPWEK_TOEW'];
                        const toestelProductId = parseInt(propToestel.getValue(opwekker));
                        const product = toestelProductId && _selectionTable.H.find(p => p.Id === toestelProductId);
                        if (isHidden(propToestel, opwekker) || !product) {
                            //-- beschrijving is het systeemtype
                            descr = codeType.Value;
                        } else {
                            //-- beschrijving is de keuze uit selectietabel
                            descr = product.Value;
                        }
                    }
                    ntaEntityData.saveprop(propdataInstallDescr, descr);
                }
            }
        } //-- end: setDescriptionInstallation ------------------------------------------------------------------------//

        function alleAfgiftesHebbenOppervlakteverwarming() {
            return verwAfgifteData().every(afgifte => afgifte.PropertyDatas['VERW-AFG_TYPE_AFG'].Value === 'VERW-AFG_TYPE_AFG_OPP');
        }

        function propertyHeaderIsHidden(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            let showit = true;
            switch (prop.Id) {
                case 'VERW-DISTR-EIG_DEK': //-- HD22
                case 'VERW-DISTR-EIG_LAB_CON': //-- HD23
                    {
                        showit = !dekkingIsHidden();
                        break;
                    }
                case 'VERW-DISTR-EIG_LAB_ISO': //-- HD24
                    {
                        showit = !labdaIsolatieIsHidden();
                        break;
                    }
                case 'VERW-DISTR-EIG_LAB_LEI': //-- HD25
                    {
                        showit = !labdaLeidingIsHidden();
                        break;
                    }
                case 'VERW-DISTR_POMP_VER_NON': //-- HD27
                case 'VERW-DISTR_POMP_EEI_NON': //-- HD29
                    {
                        showit = distributiepompAanwezigOpPerceel() && conditieDQ();
                        break;
                    }
                case 'VERW-DISTR_POMP_VER':
                    {
                        showit = distributiepompAanwezigOpPerceel() && !conditieDX(); //- conditie [DX] begint met Hide
                        break;
                    }
                case 'VERW-DISTR_POMP_EEI': //-- HD30':
                    {
                        showit = distributiepompAanwezigOpPerceel() && conditieDZ();
                        break;
                    }
                case 'VERW-AFG-VENT_SRT': //-- HA24
                    {
                        showit = getAfgifteVentilatoren(entdata)
                            .some(ventilator => !invoerVentilatorEigenWaarde(ventilator) && !geenVentilatorenAanwezig(ventilator));
                        break;
                    }
                case 'VERW-AFG-VENT_P': //-- HA25
                    {
                        showit = !geenVentilatorenAanwezigVoorHeleAfgifte(entdata);
                        break;
                    }
                case 'VERW-AFG-VENT_N': //-- HA26
                    {
                        showit = !nVentShouldBeHiddenVoorHeleAfgifte(entdata)
                        break;
                    }
                default:
            }

            return !showit;
        } //-- end: propertyHeaderIsHidden ------------------------------------------------------------------------//

        function isHidden(prop, entdata) {
            if (typeof prop === 'string') prop = ntabuilding.properties[prop];
            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return true;
            }

            //-- onderstaande variable komen steeds voor bij de check voor isHidden, vandaar hierboven gedeclareerd
            if (entdata.EntityId === 'VERW-OPWEK') {
                var opwekkerType = getTypeOpwekker(entdata);

                var propInvoerOpwekker = _verwOpwekkerProperties['VERW-OPWEK_INVOER'];
                var propValueInvoerOpwekker =  propInvoerOpwekker.getValue(entdata);
            }

            const propdata = prop.getData(entdata);
            let showit = true;
            let relevant = null;
            let bSitA = false; bSitB = false; bSitC = false; bSitD = false;

            //-- nu per property kijken wat de conditie is
            switch (prop.Id) {
                case 'VERW-OPWEK_FUNCTIE': { // HO41
                    showit = !!opwekkerType && opwekkerType.key1 === 'combi';
                    if (!showit) {
                        //-- indien veld verborgen 'Solotoestel' doorgeven
                        saveValue(prop, entdata, 'VERW-OPWEK_FUNCTIE_V');
                    }
                    relevant = true; // altijd true
                    break;
                }
                case 'VERW-OPWEK_GEM': { // HO42
                    // Conditie [OCO]
                    showit = opwekkerNr(entdata) === 1; // alleen zichtbaar voor de eerste opwekker
                    relevant = true; // veld is altijd relevant (maar moet altijd identiek zijn aan eerste opwekker, zie validateDependencies)
                    break;
                }
                case 'VERW-OPWEK_A': { //-- HO08
                    //-- Conditie [OBF]
                    showit = false;
                    if (opwekkerNr(entdata) === 1) {
                        const gemeenschappelijk = entdata.PropertyDatas['VERW-OPWEK_GEM'].Value;
                        showit = gemeenschappelijk === 'VERW-OPWEK_GEM_WEL';
                    }
                    break;
                }
                case 'VERW-OPWEK_CW': //--HO51
                    {   //-- Conditie [OCB]
                        const propdataFunctie = entdata.PropertyDatas['VERW-OPWEK_FUNCTIE'];

                        bSitA = propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_FORF';
                        bSitB = !!opwekkerType && opwekkerType.key1 === 'combi' && opwekkerType.key2 === 'GAS';
                        bSitC = propdataFunctie.Value === 'VERW-OPWEK_FUNCTIE_VT';

                        showit = bSitA && bSitB && bSitC;

                        relevant = true; //-- moet wel relevant zijn ivm validatie (-> required)
                        break;
                    }
                case 'VERW-OPWEK_POS': //-- HO09
                    {   //-- Conditie [OAQ]
                        const systeem = ntaEntityData.getFirstParent(entdata, 'VERW');
                        if (systeem) {
                            const eersteOpwekkerData = verwOpwekkersData()[0];
                            const gemeenschappelijk = eersteOpwekkerData.PropertyDatas['VERW-OPWEK_GEM'].Value;

                            const bGroteInstallatie = ntaSharedLogic.isGroteInstallatie(_verwData);
                            bSitA = ["VERW-OPWEK_INVOER_FORF"].includes(propValueInvoerOpwekker) && !!opwekkerType && opwekkerType.Id === 'VERW-OPWEK_TYPE_F';
                            bSitB = ["VERW-OPWEK_INVOER_FORF", "VERW-OPWEK_INVOER_PROD"].includes(propValueInvoerOpwekker) && !!opwekkerType && (opwekkerType.Id === 'VERW-OPWEK_TYPE_H' || opwekkerType.Id === 'VERW-OPWEK_TYPE_T') &&
                                    gemeenschappelijk === 'VERW-OPWEK_GEM_NIET' && !bGroteInstallatie;

                        }
                        showit = bSitB || bSitA;
                        break;
                    }
                case 'VERW-OPWEK_EM': //-- HO10
                    {   //-- Conditie [OAH]
                        showit = !!opwekkerType && opwekkerType.key2 === 'BM';
                        break;
                    }
                case 'VERW-OPWEK_POMP': //-- HO11
                    {   //-- Conditie [OB]
                        showit = !!opwekkerType && opwekkerType.key2 === 'WP';
                        break;
                    }
                case 'VERW-OPWEK_TBRON': //-- HO12
                    {   //-- Conditie OAT
                        let propBronWarmtePompOAT = _verwOpwekkerProperties['VERW-OPWEK_POMP'];
                        let propValueBronWarmtePompOAT = propBronWarmtePompOAT.getValue(entdata);

                        showit = !ntaSharedLogic.isUtiliteit() && propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_FORF' && !isHidden(propBronWarmtePompOAT, entdata) &&
                            propValueBronWarmtePompOAT === 'VERW-OPWEK_POMP_GRWA';
                        break;
                    }
                case 'VERW-OPWEK_REGB': //-- HO13
                    {   //-- Conditie [OAU]
                        let propBronWarmtePompOAU = _verwOpwekkerProperties['VERW-OPWEK_POMP'];
                        let propValueBronWarmtePompOAU = propBronWarmtePompOAU.getValue(entdata);

                        showit = !ntaSharedLogic.isUtiliteit() && propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_FORF' && !isHidden(propBronWarmtePompOAU, entdata) &&
                            (propValueBronWarmtePompOAU === 'VERW-OPWEK_POMP_BOVW' || propValueBronWarmtePompOAU === 'VERW-OPWEK_POMP_BOSB' || propValueBronWarmtePompOAU === 'VERW-OPWEK_POMP_BOVB');
                        break;
                    }
                case 'VERW-OPWEK_REGOP': //-- HO14
                case 'VERW-OPWEK_REGOR': //-- HO15
                case 'VERW-OPWEK_REGBES': //-- HO17
                    {
                        showit = conditieOAV(entdata);
                        break;
                    }
                case 'VERW-OPWEK_REGH': //-- HO16
                    {
                        showit = conditieOAV(entdata);
                        relevant = true;
                        if (showit) {
                            //-- Conditie [OAW]
                            let propOrientatie = _verwOpwekkerProperties['VERW-OPWEK_REGOR'];
                            let propValueOrientatie = propOrientatie.getValue(entdata);
                            if (propValueOrientatie === 'VERW-OPWEK_REGOR_HOR') {
                                //-- stuur helling is 0 naar rekenhart en hide propertie
                                saveValue(propOrientatie, entdata, '0');

                                showit = false;
                            }
                        } else {
                            relevant = false;
                        }
                        break;
                    }
                case 'VERW-OPWEK_HT_INV': { //-- HO57
                    showit = conditieOCQ(entdata);
                    break;
                }
                case 'VERW-OPWEK_HT_LOC': { //-- HO58
                    showit = conditieOCR(entdata);
                    break;
                }
                case 'VERW-OPWEK_HT_PRI': { //-- HO59
                    showit = conditieOCS(entdata);
                    break;
                }
                case 'VERW-OPWEK_HT_PRI_NON': { //-- HO60
                    showit = conditieOCU(entdata) && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'VERW-OPWEK_HT_HER': { //-- HO61
                    showit = conditieOCS(entdata);
                    break;
                }
                case 'VERW-OPWEK_HT_HER_NON': { //-- HO62
                    showit = conditieOCU(entdata) && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'VERW-OPWEK_HT_KCO2': { //-- HO64
                    showit = conditieOCS(entdata);
                    break;
                }
                case 'VERW-OPWEK_HT_KCO2_NON': { //-- HO65
                    showit = conditieOCU(entdata) && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'VERW-OPWEK_HT_BRONJAAR': { //-- HO63
                    showit = conditieOCT(entdata);
                    break;
                }
                case 'VERW-OPWEK_REGIO': //-- HO18
                    {   //-- Conditie [OC]
                        showit = (!!opwekkerType && opwekkerType.Id === 'VERW-OPWEK_TYPE_C' && propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_PROD')
                        break;
                    }
                case 'VERW-OPWEK_VERM': //-- HO19
                    {   //-- Conditie [OD]
                        showit = (!!opwekkerType && opwekkerType.key2 === 'WP' && propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_PROD')
                        break;
                    }
                case 'VERW-OPWEK_TOEW': //-- HO20
                    {   //-- Conditie [OE]
                        bSitA = propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_FORF' && !!opwekkerType && opwekkerType.key8 !== 'hide' && opwekkerType.key2 !== 'EW';
                        bSitB = propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_PROD' && !!opwekkerType && opwekkerType.key8 !== 'hide';

                        showit = bSitA || bSitB;
                        break;
                    }
                case 'VERW-OPWEK_TOE':  //-- HO23
                    {   //-- conditie [OBG]
                        showit = false;

                        // Product opvragen
                        const entdataProductId = parseInt(entdata.PropertyDatas['VERW-OPWEK_TOEW'].Value);
                        const entdataProduct = _selectionTable.H.find(function (x) { return x.Id === entdataProductId; });

                        // H_P empty of 0
                        const isVermogenEmpty = !entdataProduct || !entdataProduct.P;

                        //-- TONEN OPTIE 1: Meer dan 2 opwekkers en H_P empty of 0
                        bSitA = verwOpwekkersData().length > 2 && isVermogenEmpty;

                        //-- Eerste opwekker en zijn product opvragen
                        const eersteOpwekkerData = verwOpwekkersData()[0];
                        const eersteOpwekkerProductId = parseInt(eersteOpwekkerData.PropertyDatas['VERW-OPWEK_TOEW'].Value);
                        const eersteOpwekkerProduct = _selectionTable.H.find(function (x) { return x.Id === eersteOpwekkerProductId; });
                        const eersteOpwekkerInvoer = eersteOpwekkerData.PropertyDatas['VERW-OPWEK_INVOER'].Value;

                        //-- TONEN OPTIE 2: 2 opwekkers, geen fHgen
                        bSitB = verwOpwekkersData().length === 2 && isVermogenEmpty
                            && (!eersteOpwekkerProduct || checkGeenFHGen(eersteOpwekkerData))
                            && (eersteOpwekkerInvoer !== 'VERW-OPWEK_INVOER_EIG_B' && eersteOpwekkerInvoer !== 'VERW-OPWEK_INVOER_EIG_C')

                        //-- Gemeenschappelijke installatie
                        const gemeenschappelijk = eersteOpwekkerData.PropertyDatas['VERW-OPWEK_GEM'].Value;
                        const isGemeenschappelijk = gemeenschappelijk === 'VERW-OPWEK_GEM_WEL';

                        // PHauxgenVspec
                        const isPHauxgenVspecGt0 = !!opwekkerType && opwekkerType.PHauxgenVspec > 0;

                        //-- TONEN OPTIE 3:
                        bSitC = isGemeenschappelijk && isPHauxgenVspecGt0 && propValueInvoerOpwekker !== 'VERW-OPWEK_INVOER_EIG_C' && isVermogenEmpty

                        //-- Methode 1 of 2
                        const isHulpenergieMeth2 = opwekkerType && opwekkerType.hulpenergie === 'methode 2';

                        //-- TONEN OPTIE 4: als alle onderstaande condities kloppen
                        bSitD = (!isGemeenschappelijk
                            && isHulpenergieMeth2
                            && isPHauxgenVspecGt0
                            && propValueInvoerOpwekker !== 'VERW-OPWEK_INVOER_EIG_C'
                            && isVermogenEmpty
                            && (!entdataProduct || checkGeenWHAUX(entdata)))

                        showit = bSitA || bSitB || bSitC || bSitD;
                        break;
                    }
                case 'VERW-OPWEK_TOE_NON':  //-- HO24 waarde uit rekenkern
                    {   //-- conditie [OBD]
                        showit = false;

                        //-- Product opvragen
                        const entdataProductId = parseInt(entdata.PropertyDatas['VERW-OPWEK_TOEW'].Value);
                        const entdataProduct = _selectionTable.H.find(function (x) { return x.Id === entdataProductId; });

                        // H_P empty of 0
                        const isVermogenEmpty = !entdataProduct || !entdataProduct.P;
                        if (isVermogenEmpty) {
                            break; // dan sowieso niet tonen
                        }

                        //-- TONEN OPTIE 1: Meer dan 2 opwekkers
                        if (verwOpwekkersData().length > 2) {
                            showit = true;
                            break;
                        }

                        //-- Eerste opwekker en zijn product opvragen
                        let eersteOpwekkerData = verwOpwekkersData()[0];
                        let eersteOpwekkerProductId = parseInt(eersteOpwekkerData.PropertyDatas['VERW-OPWEK_TOEW'].Value);
                        let eersteOpwekkerProduct = _selectionTable.H.find(function (x) { return x.Id === eersteOpwekkerProductId; });
                        let eersteOpwekkerInvoer = eersteOpwekkerData.PropertyDatas['VERW-OPWEK_INVOER'].Value;

                        //-- TONEN OPTIE 2: 2 opwekkers, geen fHgen
                        if (verwOpwekkersData().length === 2
                            && (!eersteOpwekkerProduct || checkGeenFHGen(eersteOpwekkerData))
                            && (eersteOpwekkerInvoer !== 'VERW-OPWEK_INVOER_EIG_B' && eersteOpwekkerInvoer !== 'VERW-OPWEK_INVOER_EIG_C')) {
                            showit = true;
                            break;
                        }

                        //-- PHauxgenVspec
                        const isPHauxgenVspecGt0 = !!opwekkerType && opwekkerType.PHauxgenVspec > 0;

                        //-- Methode 1 of 2
                        const isHulpenergieMeth2 = !!opwekkerType && opwekkerType.hulpenergie === 'methode 2';

                        //-- TONEN OPTIE 3:
                        if (isHulpenergieMeth2 && isPHauxgenVspecGt0 && propValueInvoerOpwekker !== 'VERW-OPWEK_INVOER_EIG_C') {
                            showit = true;
                            break;
                        }

                        const propGemeenschappelijk = ntabuilding.properties['VERW-OPWEK_GEM'];
                        const gemeenschappelijk = propGemeenschappelijk.getValue(eersteOpwekkerData);
                        const isGemeenschappelijk = gemeenschappelijk === 'VERW-OPWEK_GEM_WEL';

                        //-- TONEN OPTIE 4:
                        if (!isHulpenergieMeth2 && isGemeenschappelijk && isPHauxgenVspecGt0 && propValueInvoerOpwekker !== 'VERW-OPWEK_INVOER_EIG_C') {
                            showit = true;
                            break;
                        }

                        break;
                    }
                case 'VERW-OPWEK_FABR': //-- HO21
                    {   //-- alleen zichtbaar bij WKK en forfaitair - conditie [OG]
                        showit = propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_FORF' && !!opwekkerType && opwekkerType.key2 === 'WKK' && _bouwjaar < 2015;

                        if (propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_FORF' && opwekkerType && opwekkerType.key2 === 'WKK' && _bouwjaar >= 2015) {
                            saveValue(prop, entdata, 'VERW-OPWEK_FABR_C');
                            relevant = true;
                        }
                        break;
                    }
                case 'VERW-OPWEK_FABR_TOE': //-- HO25
                    {   //-- alleen zichtbaar bij WKK en forfaitair - conditie [OM]
                        const propFabrWKK = _verwOpwekkerProperties['VERW-OPWEK_FABR'];
                        const propdataFabrWKK = propFabrWKK.getData(entdata);
                        const isFabricagejaarWKKZichtbaar = !isHidden(propFabrWKK, entdata);
                        const isNietGemeenschappelijk = entdata.PropertyDatas['VERW-OPWEK_GEM'].Value === 'VERW-OPWEK_GEM_NIET';
                        const isHulpenergieMethode1 = !!opwekkerType && opwekkerType.hulpenergie === 'methode 1';

                        showit = false;
                        //-- wanneer VERW-OPWEK_FABR (fabricagejaar bij WKK NIET relevant is, is de fabricagejaar van het toestel WEL relevant en omgekeerd.)
                        relevant = !propdataFabrWKK.Relevant
                            && (ntabuilding.ntaVersionId < 200 || entdata.PropertyDatas['VERW-OPWEK_TYPE'].Value !== 'VERW-OPWEK_TYPE_A'); // warmtepomp-elektrisch

                        //-- als het fabricagejaar voor toestellen relevant is omdat het geen WKK is dan is het nodig om verder te checken.
                        if (relevant) {
                            const conditionA = propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_FORF' && isHulpenergieMethode1 && isNietGemeenschappelijk;
                            const conditionB = (propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_EIG_A' || propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_EIG_B') && isHulpenergieMethode1 && isNietGemeenschappelijk;
                            relevant = false; //-- alleen bij onderstaande checks wordt ie true
                            if (!isFabricagejaarWKKZichtbaar && _bouwjaar < 2015 && (conditionA || conditionB)) {
                                showit = true;
                                relevant = true;
                            } else if (!isFabricagejaarWKKZichtbaar && (conditionA || conditionB) && _bouwjaar >= 2015) {
                                relevant = true;
                                saveValue(prop, entdata, 'VERW-OPWEK_FABR_TOE_L'); // 'fabricagejaar toestel 2015 en nieuwer'
                            }
                        }
                        break;
                    }
                case 'VERW-OPWEK_TOE_BIJ': //-- HO26
                    {   //-- Conditie [OAI]
                        const geb = ntaEntityData.getFirstWithEntityId('GEB');
                        const bouwsoort = geb.PropertyDatas['GEB_SRTBW'].Value;
                        showit = opwekkerNr(entdata) === 1 && verwOpwekkersData().length > 1 && bouwsoort === 'BESTB';
                        break;
                    }
                case 'VERW-OPWEK_WAAK': //-- HO27
                    {   //-- Conditie [ON]
                        let propToestel = _verwOpwekkerProperties['VERW-OPWEK_TOEW'];
                        let product = getProductWithoutFilter(propToestel, entdata);

                        showit = !!product && product.Waakvlam === 'mogelijk';
                        break;
                    }
                case 'VERW-OPWEK_TOE_AAN': //-- HO28
                    {   //-- Conditie [OAB]
                        showit = !!opwekkerType && opwekkerType.key6 === 'lokaal';
                        break;
                    }
                case 'VERW-OPWEK_GEL_WARMTE_NON': //-- HO30
                    {   //-- Conditie [OBU]
                        let hide = !!opwekkerType && (opwekkerType.key2 === 'EW' || opwekkerType.key2 === 'ONB');
                        relevant = false; // Krijgt altijd waarde uit de rekenkern. Niet relevant, zodat het niet fout wordt gerekend bij het valideren zonder formulier.
                        showit = !hide && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                        break;
                    }
                case 'VERW-OPWEK_WARMTE_NON': //-- HO29
                    {   //-- Conditie [OAC]
                        showit = opwekkerNr(entdata) === 1 && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                        relevant = false; // Krijgt altijd waarde uit de rekenkern. Niet relevant, zodat het niet fout wordt gerekend bij het valideren zonder formulier.
                        break;
                    }
                case 'VERW-OPWEK_REND_NON': //-- HO31
                    {   //-- Conditie [OAE]
                        showit = !!opwekkerType && opwekkerType.rendement === 'rendement' && (propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_FORF' || propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_PROD');

                        //-- De Usage van HO31 is 3, dus als hij een waarde van de rekenkern moet ontvangen, moet hij toch niet relevant zijn.
                        if (showit) {
                            relevant = (propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_PROD' && opwekkerType.key2 === 'GAS' && opwekkerNr(entdata) > 1)
                                || (propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_PROD' && opwekkerType.key2 === 'EW')
                                || (propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_FORF' && opwekkerType.key2 === 'WKK')
                                || (propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_FORF' && !['WP', 'WKK', 'EW', 'EL', 'BM'].includes(opwekkerType.key2))
                                || (propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_FORF' && opwekkerType.key2 === 'BM');
                        } else {
                            relevant = false; //expliciet relevant toekenen, voordat mogelijk showit wordt gewijzigd doordat we in een maatregel werken. [MW-A]
                        }

                        showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                        break;
                    }
                case 'VERW-OPWEK_REND': //-- HO32
                    {   //-- Conditie [OAF]
                        showit = !!opwekkerType && opwekkerType.rendement === 'rendement' &&
                            (propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_EIG_A' ||
                                propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_EIG_B' ||
                                propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_EIG_C');
                        break;
                    }
                case 'VERW-OPWEK_OMZET_NON': //-- HO47
                    {   //-- Conditie [OBX]
                        showit = !!opwekkerType && opwekkerType.key2 === 'WKK' &&
                            (propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_FORF' ||
                                propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_PROD');
                        relevant = showit;
                        showit = showit && !ntaSharedLogic.isEditingMeasure(); // [MW-A]
                        break;
                    }
                case 'VERW-OPWEK_OMZET': //-- HO48
                    {   //-- Conditie [OBY]
                        showit = !!opwekkerType && opwekkerType.key2 === 'WKK' && propValueInvoerOpwekker &&
                            (propValueInvoerOpwekker !== 'VERW-OPWEK_INVOER_FORF' &&
                                propValueInvoerOpwekker !== 'VERW-OPWEK_INVOER_PROD');
                        break;
                    }
                case 'VERW-OPWEK_NEN7125VERKL': { //-- HO49
                    {   //-- Conditie [OBZ]
                        if (opwekkerType && opwekkerType.key2 === 'EW') {
                            //-- alleen zichtbaar bij eigen waarde invoer
                            bSitA = propValueInvoerOpwekker && (propValueInvoerOpwekker !== 'VERW-OPWEK_INVOER_PROD' && propValueInvoerOpwekker !== 'VERW-OPWEK_INVOER_FORF');
                            //-- ongeacht of deze property zichtbaar is of niet is hij alleen relevant als er sprake is van externe warmtelevering
                            relevant = true;
                        }
                        showit = bSitA;
                        break;
                    }
                }
                case 'VERW-OPWEK_COP_NON': //-- HO33
                    {   //-- Conditie [OAN]
                        showit = !!opwekkerType && opwekkerType.rendement === 'COP' &&
                            (propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_FORF' || propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_PROD');

                        //-- De Usage van HO33 is 3, dus als hij een waarde van de rekenkern moet ontvangen, moet hij toch niet relevant zijn.
                        if (showit) {
                            //-- Onder deze voorwaarden moet de waarde naar de rekenkern gaan; anders moet de rekenkern juist de waarde teruggeven.
                            relevant = (propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_FORF' && !!opwekkerType && (opwekkerType.key2 === 'WP' || opwekkerType.key2 === 'EL'));
                        } else {
                            relevant = false; //expliciet relevant toekenen, voordat mogelijk showit wordt gewijzigd doordat we in een maatregel werken. [MW-A]
                        }

                        showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                        break;
                    }
                case 'VERW-OPWEK_COP': //-- HO34
                    {   //-- Conditie [OAY]
                        showit = !!opwekkerType && opwekkerType.rendement === 'COP' &&
                            (propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_EIG_A' ||
                                propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_EIG_B' ||
                                propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_EIG_C');
                        break;
                    }
                case 'VERW-OPWEK_PRI_NON': //-- HO35
                    {   //-- Conditie [OBB]
                        showit = !!opwekkerType && opwekkerType.rendement === 'primaire energiefactor' &&
                            (propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_FORF' ||
                                propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_PROD');

                        if (showit) {
                            ntaRounding.roundAndAddZeros(prop, propdata);
                        }

                        //-- De Usage van HO35 is 3, dus als hij een waarde van de rekenkern moet ontvangen, moet hij toch niet relevant zijn.
                        if (showit) {
                            if (opwekkerType && opwekkerType.key2 !== 'EW') {
                                relevant = false;
                            } else {
                                relevant = true; //expliciet relevant toekenen, voordat mogelijk showit wordt gewijzigd doordat we in een maatregel werken. [MW-A]
                            }
                        } else {
                            relevant = false; //expliciet relevant toekenen, voordat mogelijk showit wordt gewijzigd doordat we in een maatregel werken. [MW-A]
                        }

                        showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                        break;
                    }
                case 'VERW-OPWEK_PRI': //-- HO36
                    {   //-- Conditie [OBC]
                        showit = !!opwekkerType && opwekkerType.rendement === 'primaire energiefactor' &&
                            (propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_EIG_A' ||
                                propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_EIG_B' ||
                                propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_EIG_C');
                        break;
                    }
                case 'VERW-OPWEK_ENER_NON': //-- HO37
                    {   //-- Conditie [OT]
                        showit = (propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_FORF' || propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_PROD' || propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_EIG_A');

                        //-- De Usage van HO37 is 3, dus als hij een waarde van de rekenkern moet ontvangen, moet hij toch niet relevant zijn.
                        if (showit) {
                            const opwekkers = verwOpwekkersData();
                            const invoerOpwekker1 = opwekkers[0].PropertyDatas['VERW-OPWEK_INVOER'].Value;

                            relevant = (opwekkers.length === 1 && ['VERW-OPWEK_INVOER_FORF', 'VERW-OPWEK_INVOER_EIG_A'].includes(invoerOpwekker1))
                                    || (opwekkers.length === 1 && (invoerOpwekker1 === 'VERW-OPWEK_INVOER_PROD' && checkGeenFHGen(opwekkers[0])))
                                    || (opwekkers.length === 2 && ['VERW-OPWEK_INVOER_EIG_B', 'VERW-OPWEK_INVOER_EIG_C'].includes(invoerOpwekker1));
                        } else {
                            relevant = false; //expliciet relevant toekenen, voordat mogelijk showit wordt gewijzigd doordat we in een maatregel werken. [MW-A]
                        }
                        showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                        break;
                    }
                case 'VERW-OPWEK_ENER': //-- HO38
                    {   //-- Conditie [OAG]
                        showit = propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_EIG_B' || propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_EIG_C';
                        break;
                    }
                case 'VERW-OPWEK_HULP_NON': //-- HO39
                    {   //-- Conditie [OBA] en [OAZ]
                        showit = !!opwekkerType && opwekkerType.key2 !== 'EW' && propValueInvoerOpwekker && propValueInvoerOpwekker !== 'VERW-OPWEK_INVOER_EIG_C';
                        relevant = false;
                        showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                        prop.Name = conditieOCP(entdata);
                        break;
                    }
                case 'VERW-OPWEK_HULP': //-- HO40
                    {   //-- Conditie [OY]
                        showit = propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_EIG_C';

                        prop.Name = conditieOCP(entdata);
                        break;
                    }
                case 'VERW-OPWEK_HULP_BIO': //-- HO50
                    {   //-- Conditie [OCA]
                        showit = !!opwekkerType && (opwekkerType.key2 === 'BM' || (opwekkerType.key2 === 'GAS' && opwekkerType.key5.split(",").includes('str') && opwekkerType.key6 === 'lokaal'));
                        break;
                    }
                case 'VERW-OPWEK_HERNIEUW_NON': // HO55
                    {   //-- Conditie [OCK]
                        const forfOrProd = (propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_PROD' || propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_FORF');
                        const propBronWP = ntabuilding.properties['VERW-OPWEK_POMP'];
                        const propValueBronWP = propBronWP.getValue(entdata);
                        showit = !isHidden(propBronWP, entdata) && ['VERW-OPWEK_POMP_VERE', 'VERW-OPWEK_POMP_BUVE'].includes(propValueBronWP) && forfOrProd;
                        relevant = false; //-- heeft usage 2 en wordt door koppeling opgehaald uit selectietabel.
                        showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                        break;
                    }
                case 'VERW-OPWEK_HERNIEUW': // HO56
                    {   //-- Conditie [OCF]
                        const notForfOrProd = !(propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_PROD' || propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_FORF');
                        const propBronWP = ntabuilding.properties['VERW-OPWEK_POMP'];
                        const propValueBronWP = propBronWP.getValue(entdata);
                        showit = !isHidden(propBronWP, entdata) && ['VERW-OPWEK_POMP_VERE', 'VERW-OPWEK_POMP_BUVE'].includes(propValueBronWP) && notForfOrProd;
                        break;
                    }
                case 'VERW-OPWEK_FPREN_NON': // HO43
                case 'VERW-OPWEK_KCO_NON':  // HO45
                    {   // Conditie [OBV]
                        showit = !!opwekkerType && opwekkerType.key2 === 'EW' &&
                            (propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_PROD' ||
                                propValueInvoerOpwekker === 'VERW-OPWEK_INVOER_FORF');
                        showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                        break;
                    }
                case 'VERW-OPWEK_FPREN': // HO44
                case 'VERW-OPWEK_KCO':  // HO46
                    {   //-- Conditie [OBW]
                        showit = !!opwekkerType && opwekkerType.key2 === 'EW' && propValueInvoerOpwekker &&
                            (propValueInvoerOpwekker !== 'VERW-OPWEK_INVOER_PROD' &&
                                propValueInvoerOpwekker !== 'VERW-OPWEK_INVOER_FORF');
                        break;
                    }
                case 'VERW-OPWEK_VERBR':  // HO52
                    {   //-- Conditie [OCC]
                        const propToestel = ntabuilding.properties['VERW-OPWEK_TOEW'];
                        const product = getProductWithoutFilter(propToestel, entdata);
                        const propPositie = ntabuilding.properties['VERW-OPWEK_POS'];
                        const positie = propPositie.getValue(entdata);

                        showit = !!product && product.OpenVerbToestel === 'mogelijk' && !isHidden(propToestel, entdata) && (isHidden(propPositie, entdata) || positie === 'VERW-OPWEK_POS_BIN');
                        break;
                    }
                case 'VERW-OPWEK_VERBR_OPS': //-- HO53
                    {   //-- Conditie [OCD]
                        const propOpenVerbrandingstoestel = ntabuilding.properties['VERW-OPWEK_VERBR'];
                        const openVerbrandingstoestel = propOpenVerbrandingstoestel.getValue(entdata);

                        showit = openVerbrandingstoestel === 'VERW-OPWEK_VERBR_WEL' && !isHidden(propOpenVerbrandingstoestel, entdata);
                        break;
                    }
                case 'VERW-OPWEK_VERBR_NOM': // HO54
                    {   //-- Conditie [OCE]
                        const propOpenVerbrandingstoestel = ntabuilding.properties['VERW-OPWEK_VERBR'];
                        const openVerbrandingstoestel = propOpenVerbrandingstoestel.getValue(entdata);
                        const openVerbrandingstoestelPositie = entdata.PropertyDatas['VERW-OPWEK_VERBR_OPS'].Value;

                        showit = openVerbrandingstoestel === 'VERW-OPWEK_VERBR_WEL' && !isHidden(propOpenVerbrandingstoestel, entdata) && openVerbrandingstoestelPositie !== 'VERW-OPWEK_VERBR_OPS_BUI';
                        break;
                    }
                case 'VERW-DISTR_FUNCTIE_LEID': //-- HD42
                    {
                        //-- Conditie [OCX] toon veld alleen indien gt3.2 EN HD02 ≠ geen watergedragen distributiesysteem EN HO42 = gemeenschappelijke installatie (installatie met centrale aflevering')
                        const typeDistributieSysteem = _verwDistributieData.PropertyDatas['VERW-DISTR_TYPE'].Value;
                        const gemeenschappelijkeInstallatie = verwOpwekkersData()[0].PropertyDatas['VERW-OPWEK_GEM'].Value;
                        showit = ntabuilding.ntaVersionId >= 300 && typeDistributieSysteem !== 'VERW-DISTR_TYPE_E' && gemeenschappelijkeInstallatie === 'VERW-OPWEK_GEM_WEL';
                        break;
                    }
                case 'VERW-DISTR_ONTW_H_MODEL': //-- HD03
                    {   //-- Conditie [DAD]
                        showit = false;
                        if (!leidingenIsHidden()) {
                            //-- ik moet WEL de H_model prop (deze is gekoppeld aan de aanvoertemplist en niet aan tabel9_14) als éen van mijn opwekker een WP productspecifiek is
                            showit = verwOpwekkersData().some(opw => opw.PropertyDatas['VERW-OPWEK_INVOER'].Value === 'VERW-OPWEK_INVOER_PROD'
                                                                    && (getTypeOpwekker(opw) || {}).key2 === 'WP');
                        }
                        break;
                    }
                case 'VERW-DISTR_ONTW': //-- HD03
                    {   //-- Conditie [DA]
                        //-- ik moet de gewone prop laten zien wanneer prop VERW-DISTR_ONTW_H_MODEL hidden is
                        const propHmodel = _verwDistributieProperties['VERW-DISTR_ONTW_H_MODEL'];
                        showit = isHidden(propHmodel, entdata) && !leidingenIsHidden();
                        break;
                    }
                case 'VERW-DISTR_WAT': //-- HD04
                    {   //-- Conditie [DA]
                        showit = !leidingenIsHidden();
                        break;
                    }
                case 'VERW-DISTR_AFGIFTE': //-- HD40
                    {
                        //-- conditie [DAQ] toon veld vanaf versie 3.2 EN veld HD03 (VERW-DISTR_ONTW) of (VERW-DISTR_ONTW_MODEL_H) = onbekend
                        showit = false;
                        if (ntabuilding.ntaVersionId >= 200 && !leidingenIsHidden()) {
                            const propdataOntwAanvTemp = getPropdataOntwerpaanvoertemperatuur();
                            showit = propdataOntwAanvTemp && propdataOntwAanvTemp.Value === 'VERW-DISTR_ONTW_GE32_M';
                        }
                        break;
                    }
                case 'VERW-DISTR_MAX_AANVTEMP_WP': //-- HD41
                    {
                        //-- conditie [DAR] toon veld vanaf versie 3.2 EN veld HD03 (VERW-DISTR_ONTW) of (VERW-DISTR_ONTW_MODEL_H) = onbekend EN 1 van de opwekkers is een WP
                        showit = false;
                        if (ntabuilding.ntaVersionId >= 200 && entdata.PropertyDatas['VERW-DISTR_AFGIFTE'].Relevant) {
                            showit = entdata.PropertyDatas['VERW-DISTR_AFGIFTE'].Value === 'VERW-DISTR_AFG_OVERIG' && verwOpwekkersData().some(ed => (getTypeOpwekker(ed) || {}).key2 === 'WP');
                        }
                        break;
                    }
                case 'VERW-DISTR_REG_AANVTEMP': //-- HD43
                    {
                        //-- conditie [OCZ] toon indien versie gt3.2 EN HD42=distributieleidingen voor verwarming en warm tapwater
                        showit = false;
                        const functieDistrLeidingen = _verwDistributieData.PropertyDatas['VERW-DISTR_FUNCTIE_LEID'];
                        if (ntabuilding.ntaVersionId >= 300 && functieDistrLeidingen.Relevant) {
                            showit = functieDistrLeidingen.Value === 'VERW-DISTR_FUNCTIE_LEID_VERW_TAPW';
                        }
                        break;
                    }
                case 'VERW-DISTR-BIN_INV': //-- HD06
                    {   //-- Conditie [DL]
                        bSitA = ntaSharedLogic.isUtiliteit();
                        bSitB = !bSitA && ntaSharedLogic.isDetailOpname();

                        showit = conditieOCY() && (bSitA || bSitB);
                        break;
                    }
                case 'VERW-DISTR-BIN_LEN_NON': //-- HD07
                    {
                        showit = conditieOCY() && binnenLeidinglengteNietNauwkeurig(entdata) /*Conditie [DT]*/ && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                        relevant = false; //-- waarden komen uit rekenhart
                        break;
                    }
                case 'VERW-DISTR-BIN_LEN': //-- HD08
                    {
                        showit = conditieOCY() && binnenLeidinglengteNauwkeurig(entdata) /*Conditie[DE]*/;
                        break;
                    }
                case 'VERW-DISTR-BIN_ISO_LEI': //-- HD09
                    {
                        showit = conditieOCY();
                        break;
                    }
                case 'VERW-DISTR-BIN_ISO_KLE': //-- HD10
                    {
                        showit = conditieOCY() && !binnenLeidingIsOnGeisoleerd() /*Conditie[DV]*/;
                        break;
                    }
                case 'VERW-DISTR-BIN_ON': //-- HD11
                    {
                        showit = conditieOCY() && binnenLeidingIsOnGeisoleerd() /*Conditie[DH]*/;
                        break;
                    }
                case 'VERW-DISTR-BUI_INV': //-- HD06
                    {   //-- Conditie [DA]
                        showit = !leidingenIsHidden();
                        break;
                    }

                case 'VERW-DISTR-BUI_LEN_NON': //-- HD14
                    {   //-- Conditie [DU]
                        showit = buitenLeidinglengteOnbekend(entdata) && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                        relevant = false; //-- waarden komen uit rekenhart
                        break;
                    }
                case 'VERW-DISTR-BUI_LEN': //-- HD15
                    {   //-- Conditie [DK]
                        showit = buitenLeidinglengteBekend(entdata);
                        break;
                    }
                case 'VERW-DISTR-BUI_ISO_LEI': //-- HD16
                    {   //-- Conditie [DAD]
                        showit = !leidingenIsHidden() && !geenLeidingenBuitenVerwarmdeZone(entdata);
                        break;
                    }
                case 'VERW-DISTR-BUI_ISO_KLE': //-- HD17
                    {
                        showit = !leidingenIsHidden() && !geenLeidingenBuitenVerwarmdeZone(entdata) && !buitenLeidingIsOngeisoleerd(prop, entdata);
                        break;
                    }
                case 'VERW-DISTR-EIG_DEK': // HD22
                case 'VERW-DISTR-EIG_LAB_CON': // HD23
                    {
                        showit = !dekkingIsHidden();
                        relevant = dekkingIsRelevant(entdata);
                        break;
                    }
                case 'VERW-DISTR-EIG_LAB_ISO': // HD24
                    {
                        showit = !labdaIsolatieIsHidden();
                        relevant = labdaIsolatieIsRelevant(entdata);
                        break;
                    }
                case 'VERW-DISTR-EIG_LAB_LEI': //HD25
                    {
                        showit = !labdaLeidingIsHidden();
                        relevant = labdaLeidingIsRelevant(entdata); //-- relevantie afhankelijk van binnen of buiten, vandaar checken met entdata
                        break;
                    }
                case 'VERW-DISTR_POMP_INV': //-- HD26
                    {
                        showit = distributiepompIsVisible();

                        break;
                    }
                case 'VERW-DISTR_MAXLEN': //-- HD34
                    {   //-- Conditie [DAT]
                        showit = conditieDAT();
                        break;
                    }
                case 'VERW-DISTR_AAN_LAGEN': //-- HD31
                    {
                        showit = conditieDAK();
                        break;
                    }
                case 'VERW-DISTR_AANV_POMP': // HD35
                    {   //-- Conditie [DAF]
                        const gemeenschappelijk = verwOpwekkersData()[0].PropertyDatas['VERW-OPWEK_GEM'].Value;
                        const typeDistributieSysteem = _verwDistributieData.PropertyDatas['VERW-DISTR_TYPE'].Value;

                        showit = opwekkersAllHasMethod1() && gemeenschappelijk === 'VERW-OPWEK_GEM_NIET' && typeDistributieSysteem !== 'VERW-DISTR_TYPE_E';

                        break;
                    }
                case 'VERW-DISTR_POMP_VER_NON': //-- HD27
                case 'VERW-DISTR_POMP_EEI_NON': //-- HD29
                    {
                        showit = !propertyHeaderIsHidden(prop, entdata) && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                        break;
                    }
                case 'VERW-DISTR_POMP_VER': //-- HD28
                case 'VERW-DISTR_POMP_EEI': //-- HD30
                    {
                        showit = !propertyHeaderIsHidden(prop, entdata);
                        break;
                    }
                case 'VERW-DISTR_METER': //-- HD33
                    {   //-- Conditie [DAA]
                        let gemeenschappelijkInstallatie = verwOpwekkersData()[0].PropertyDatas['VERW-OPWEK_GEM'].Value;
                        let bGemeenschappelijkeInstall = !!gemeenschappelijkInstallatie && gemeenschappelijkInstallatie === 'VERW-OPWEK_GEM_WEL';

                        let bAnyExterneWarmte = false;
                        for (let opwekker of verwOpwekkersData()) {
                            let opwekkerTypeValue = opwekker.PropertyDatas['VERW-OPWEK_TYPE'].Value;
                            if (opwekkerTypeValue && opwekkerTypeValue === 'VERW-OPWEK_TYPE_C') {
                                bAnyExterneWarmte = true;
                                break;
                            }
                        }

                        let bDistributiepompOpPerceel = distributiepompAanwezigOpPerceel();

                        showit = bDistributiepompOpPerceel && (bGemeenschappelijkeInstall || bAnyExterneWarmte);
                        break;
                    }
                case 'VERW_VAT_AANW': //-- HB16
                    {
                        showit = !buffervatenIsHidden();
                        if (showit) {
                            // als vaten zichtbaar zijn, dan bepaalt function buffervatenAanwezig of ze zichtbaar en relevant zijn.
                            // time.delayAction omdat Relevant eerst opgeslagen moet zijn.
                            time.delayActions(buffervatenAanwezig);
                        }
                        break;
                    }
                case 'VERW-VAT_TRANS_NON': //--HB12
                case 'VERW-VAT_VOL_NON': //--HB08
                case 'VERW-VAT_TYPE': //--HB05
                    {
                        //-- conditie [HBD]
                        showit = conditieHBD_E_F(entdata, 'VERW-VAT_INV_PROD');
                        break;
                    }
                case 'VERW-VAT_VOL_A': //--HB06
                    {
                        //-- conditie [HBE]
                        showit = conditieHBD_E_F(entdata, 'VERW-VAT_INV_FORF');
                        break;
                    }
                case 'VERW-VAT_FABR': //--HB09
                    {
                        //-- conditie [HBK]
                        showit = conditieHBK(entdata);
                        break;
                    }
                case 'VERW-VAT_TRANS': //-- HB11
                case 'VERW-VAT_VOL_B': //--HB07
                    {
                        //-- conditie [HBF]
                        showit = conditieHBD_E_F(entdata, 'VERW-VAT_INV_EIG');
                        break;
                    }
                case 'VERW-VAT_ENERGIE': //--HB10
                    {
                        //-- conditie [HBI] toon alleen als HB04 = 'forfaitair' EN HB06 =< 500
                        const propdataVatVolA = entdata.PropertyDatas['VERW-VAT_VOL_A'];

                        showit = conditieHBD_E_F(entdata, 'VERW-VAT_INV_FORF') &&
                            propdataVatVolA.Value && parseFloat(propdataVatVolA.Value) <= 500;
                        break;
                    }
                case 'VERW-VAT_OPSTEL': //--HB15
                    {
                        //-- conditie [HBE] en [HBJ] toon alleen in de eerste kolom
                        showit = vatNr(entdata) === 1;

                        break;
                    }
                case 'VERW-AFG_VERT':
                    {   //--[AAF] toon in versie gt3.2 als HA07 = luchtverwarming
                        const typeAfgifteSysteem_AAF = entdata.PropertyDatas['VERW-AFG_TYPE_AFG'].Value;
                        showit = ntabuilding.ntaVersionId < 300 ||
                            (typeAfgifteSysteem_AAF === 'VERW-AFG_TYPE_AFG_LVNE' || typeAfgifteSysteem_AAF === 'VERW-AFG_TYPE_AFG_LVE' || typeAfgifteSysteem_AAF === 'VERW-AFG_TYPE_AFG_LV');

                        break;
                    }
                case 'VERW-AFG_TYPE_STR': //-- HA09
                    {   //-- Conditie [AF]
                        const typeAfgifteSysteem_AF = entdata.PropertyDatas['VERW-AFG_TYPE_AFG'].Value;
                        const bSitA = typeAfgifteSysteem_AF === 'VERW-AFG_TYPE_AFG_STR';

                        const vertrekhoogte_AF = entdata.PropertyDatas['VERW-AFG_VERT'].Value;
                        const bSitB = vertrekhoogte_AF === 'VERW-AFG_VERT_B' || vertrekhoogte_AF === 'VERW-AFG_VERT_C' || vertrekhoogte_AF === 'VERW-AFG_VERT_D';

                        let bSitC = false;
                        if (vertrekhoogte_AF === 'VERW-AFG_VERT_A') {
                            const geenVentilatoren = geenVentilatorenAanwezigVoorHeleAfgifte(entdata);
                            if (geenVentilatoren) {
                                if (_verwRekenzones.length > 1) {
                                    //-- Gekoppelde ventilatiesystemen opvragen
                                    const ventilatieData = getVentilatieData();

                                    let alleZonesNatuurlijk = true;
                                    let alleZonesNietNatuurlijk = true;
                                    let alleZonesIngevuld = true;

                                    //-- Controleren of er zowel A als niet A ventilatiesystemen aanwezig zijn
                                    for (const ventData of ventilatieData) {
                                        let typeSysteem = ventData.PropertyDatas['VENT_SYS'].Value;

                                        if (!typeSysteem) {
                                            alleZonesIngevuld = false;
                                            alleZonesNietNatuurlijk = false;
                                            alleZonesNatuurlijk = false;
                                        }
                                        else if (typeSysteem === 'VENTSYS_NAT') {
                                            alleZonesNietNatuurlijk = false;
                                        }
                                        else if (typeSysteem !== 'VENTSYS_NAT') {
                                            alleZonesNatuurlijk = false;
                                        }
                                    }
                                    bSitC = alleZonesIngevuld && !alleZonesNatuurlijk && !alleZonesNietNatuurlijk;
                                }
                            }
                        }
                        showit = bSitA && (bSitB || bSitC);

                        //-- Conditie [AN]
                        let typeafg = entdata.PropertyDatas['VERW-AFG_TYPE_AFG'].Value;
                        if (!showit && typeafg === "VERW-AFG_TYPE_AFG_STR" ) { //-- alleen bij stralingsverwarming
                            let key1Str = "";
                            let key3Str = "";
                            let key4Str = "";
                            let key5Str = "";
                            //-- HA23 = 'geen ventilatoren aanwezig' of leeg
                            const geenVentilatoren = geenVentilatorenAanwezigVoorHeleAfgifte(entdata);
                            if (!geenVentilatoren) {
                                key3Str = "ventilator";
                            } else {
                                //-- alle aangesloten rekenzone in V03 ≠ 'A. natuurlijke toe- en afvoer' of leeg
                                const ventilatieData = getVentilatieData();
                                let alleZonesNietNatuurlijk = true;
                                let alleZonesWelNatuurlijk = true;
                                for (const ventData of ventilatieData) {
                                    const typeSysteem = ventData.PropertyDatas['VENT_SYS'].Value;
                                    if (typeSysteem === 'VENTSYS_NAT') {
                                        alleZonesNietNatuurlijk = false;
                                    } else {
                                        alleZonesWelNatuurlijk = false;
                                    }
                                }
                                if (alleZonesNietNatuurlijk) {
                                    key3Str = "mechanisch";
                                } else {
                                    //-- indien in table9_0 key6='lokaal' conform keuze in veld HO05
                                    const opwekkerTypeValue = verwOpwekkersData()[0].PropertyDatas['VERW-OPWEK_TYPE'].Value; //-- preferente toestel
                                    if (opwekkerTypeValue === "VERW-OPWEK_TYPE_E" || opwekkerTypeValue === "VERW-OPWEK_TYPE_I" || opwekkerTypeValue === "VERW-OPWEK_TYPE_J" || opwekkerTypeValue === "VERW-OPWEK_TYPE_K" || opwekkerTypeValue === "VERW-OPWEK_TYPE_N") {
                                        key3Str = "lokaal";
                                    } else if (alleZonesWelNatuurlijk) {
                                        key3Str = "natuurlijk";
                                    }
                                }
                            }

                            //-- HA08='<4'
                            const vertrekhoogte_AM = entdata.PropertyDatas['VERW-AFG_VERT'].Value;
                            if (vertrekhoogte_AM === 'VERW-AFG_VERT_A') {
                                key1Str = "<4";
                            }

                            //-- HD02
                            const typeDistributie = _verwDistributieData.PropertyDatas['VERW-DISTR_TYPE'].Value;
                            const codedValueDistrType = _verwDistributieProperties['VERW-DISTR_TYPE'].Domain.Codes.find(function (x) { return x.Id === typeDistributie; });
                            if (codedValueDistrType) {
                                key4Str = codedValueDistrType.Value;
                            }

                            //-- HD03
                            const ontwDistributie = _verwDistributieData.PropertyDatas['VERW-DISTR_ONTW'];
                            let ontwAanvoerTemp;
                            if (ontwDistributie.Relevant) {
                                ontwAanvoerTemp = _ontwerpAanvoertemperaturen.find(function (x) { return x.Id === ontwDistributie.Value });
                            } else {
                                //TODO: als VERW-DISTR_ONTW_H_MODEL
                                //let codedValuesDistrOntw = _verwDistributieProperties['VERW-DISTR_ONTW'].Domain.Codes;
                                //let ontwDistributieValue = _verwDistributieData.PropertyDatas['VERW-DISTR_ONTW_H_MODEL'].Value;
                                //ontwAanvoerTemp = codedValuesDistrOntw.find(function (x) { return x.FilterValue2 === ontwDistributie.Value; }).FilterValue2;
                            }

                            if (key4Str === "eenpijpssysteem" || key4Str === "eenpijps- of tweepijpssysteem onbekend") {
                                if (ontwAanvoerTemp) {
                                    key5Str = ontwAanvoerTemp["tabel9_3_eenpijps"];
                                }
                            }
                            if (key4Str === "tweepijpssysteem" || key4Str === "eenpijpssysteem gerenoveerd") {
                                if (ontwAanvoerTemp) {
                                    key5Str = ontwAanvoerTemp["tabel9_3_tweepijps"];
                                }
                            }

                            const typeStr = _stralingsverwarmingTypes.filter(function (x) {
                                if (key3Str === "natuurlijk") {
                                    return x.key3 === key3Str && x.key1 === key1Str && x.key4 === key4Str && x.key5 === key5Str;
                                } else if (key3Str === "lokaal") {
                                    return x.key3 === key3Str && x.key1 === key1Str && x.key4 === key4Str;
                                } else {
                                    return x.key3 === key3Str && x.key1 === key1Str;
                                }
                            });

                            //-- zetten value
                            if (typeStr.length > 0) {
                                saveValue(prop, entdata, typeStr[0].Id);
                            }
                            relevant = true; //-- toch relevant -> als conditie AN geldig is
                        }
                        break;
                    }
                case 'VERW-AFG_TYPE_LUC': //-- HA10
                    {   //-- Conditie [AK]
                        let typeAfgifteSysteem_AK = entdata.PropertyDatas['VERW-AFG_TYPE_AFG'].Value;
                        showit = ntaSharedLogic.isUtiliteit() && typeAfgifteSysteem_AK === 'VERW-AFG_TYPE_AFG_LVNE';
                        break;
                    }
                case 'VERW-AFG_TYPE_OPP': //-- HA11
                    {
                        //-- Conditie [AB]
                        let typeAfgifteSysteem_AB = entdata.PropertyDatas['VERW-AFG_TYPE_AFG'].Value;
                        showit = typeAfgifteSysteem_AB === 'VERW-AFG_TYPE_AFG_OPP';
                        break;
                    }
                case 'VERW-AFG_ISO_OPP': //-- HA12
                    {
                        //-- Conditie [AC]
                        let typeAfgifteSysteem_AC = entdata.PropertyDatas['VERW-AFG_TYPE_AFG'].Value;
                        let typeOppervlaktVerw_AC = entdata.PropertyDatas['VERW-AFG_TYPE_OPP'].Value;
                        let typeOppervlakteverwarming = null;
                        if (typeOppervlaktVerw_AC) {
                            typeOppervlakteverwarming = _oppervlakteTemperatuurTypes.find(function (x) { return x.AreaheatingId === typeOppervlaktVerw_AC; });
                        }

                        showit = typeAfgifteSysteem_AC === 'VERW-AFG_TYPE_AFG_OPP' && !!typeOppervlakteverwarming && typeOppervlakteverwarming.key2 === 'horizontaal';
                        break;
                    }
                case 'VERW-AFG_PL': //-- HA13
                    {
                        //-- Conditie [AM]
                        let typeAfgiftSys_AM = entdata.PropertyDatas['VERW-AFG_TYPE_AFG'].Value;
                        let vertrekhoogte_AM = entdata.PropertyDatas['VERW-AFG_VERT'].Value;

                        //-- lokale variabale opwekkerType maken voor de eerste opwekker
                        let opwekkerType = getTypeOpwekker(verwOpwekkersData()[0]);

                        bSitA = typeAfgiftSys_AM === 'VERW-AFG_TYPE_AFG_STR' && vertrekhoogte_AM === 'VERW-AFG_VERT_A' && !!opwekkerType && opwekkerType.key6 !== 'lokaal';
                        bSitB = typeAfgiftSys_AM === 'VERW-AFG_TYPE_AFG_LVE' && vertrekhoogte_AM === 'VERW-AFG_VERT_A' && !!opwekkerType && opwekkerType.key6 !== 'lokaal';

                        showit = bSitA || bSitB;
                        break;
                    }
                case 'VERW-AFG_TYPE_RUIM': //-- HA15
                    {   //-- Conditie [AP]
                        if (ntabuilding.ntaVersionId >= 300) {
                            showit = true;
                            break;
                        }

                        let bHide_AP = false;
                        const ruimtetemperatuurRegeling_AP = entdata.PropertyDatas['VERW-AFG_RUIM'].Value;
                        bSitA = ruimtetemperatuurRegeling_AP === 'VERW-AFG_RUIM_EIG';

                        const propTypeLuchtverwarming = _verwAfgifteProperties['VERW-AFG_TYPE_LUC'];
                        const typeLuchtverwarming = propTypeLuchtverwarming.getValue(entdata);
                        bSitB = !isHidden(propTypeLuchtverwarming, entdata) && typeLuchtverwarming === 'VERW-AFG_TYPE_LUC_RE';

                        const propTypeAfgifteSysteem = _verwAfgifteProperties['VERW-AFG_TYPE_AFG'];
                        const typeAfgifteSysteem_AP = propTypeAfgifteSysteem.getValue(entdata);
                        bSitC = typeAfgifteSysteem_AP === 'VERW-AFG_TYPE_AFG_STR' && !!ruimtetemperatuurRegeling_AP && ruimtetemperatuurRegeling_AP !== 'VERW-AFG_RUIM_FOR';
                        bSitD = typeAfgifteSysteem_AP === 'VERW-AFG_TYPE_AFG_OPP' && !!ruimtetemperatuurRegeling_AP && ruimtetemperatuurRegeling_AP !== 'VERW-AFG_RUIM_FOR';

                        bHide_AP = bSitA || bSitB || bSitC || bSitD;
                        showit = !bHide_AP;

                        //-- VO 2020-02-24: in sommige gevallen is deze property hidden, maar kan er toch een waarde voor deze property bepaald worden. Dit moet niet de eerste uit de lijst zijn
                        //-- maar is afhankelijk van HA07, HA014 en HA08 dit wordt geregeld in de getCodedValues. Bij opslaan in de saveValue worden de juiste ctrl en roomaut gezet
                        if (!showit) {
                            const ruimtetemperatuurRegelingWaarden = getCodedValues(prop, entdata);
                            saveValue(prop, entdata, ruimtetemperatuurRegelingWaarden[0].Id);
                        }
                        break;
                    }
                case 'VERW-AFG_TTR_NON': //-- HA16
                    {
                        //-- Conditie [AX]
                        let ruimtetemperatuurRegeling_AX = entdata.PropertyDatas['VERW-AFG_RUIM'].Value;
                        showit = !!ruimtetemperatuurRegeling_AX && ruimtetemperatuurRegeling_AX !== 'VERW-AFG_RUIM_EIG';
                        break;
                    }
                case 'VERW-AFG_TTR': //-- HA17
                    {
                        //-- Conditie [AD]
                        let ruimtetemperatuurRegeling_AD = entdata.PropertyDatas['VERW-AFG_RUIM'].Value;
                        showit = ruimtetemperatuurRegeling_AD === 'VERW-AFG_RUIM_EIG';
                        break;
                    }
                case 'VERW-AFG_TAR_NON': //-- HA18
                    {
                        //-- Conditie [AL]
                        let propTypeRuimtetemperatuurRegeling = _verwAfgifteProperties['VERW-AFG_TYPE_RUIM'];
                        let typeRuimtetemperatuurRegelingValue = propTypeRuimtetemperatuurRegeling.getValue(entdata);

                        showit = false;
                        if (typeRuimtetemperatuurRegelingValue) {
                            let typeRuimtetemperatuurRegeling = _ruimteTemperatuurRegelingTypes.find(function (x) { return x.Id === typeRuimtetemperatuurRegelingValue; });
                            showit = typeRuimtetemperatuurRegeling.delta_roomaut !== '';
                            relevant = true;
                        }
                        break;
                    }
                case 'VERW-AFG_TAR': //-- HA19
                    {
                        //-- Conditie [AO]
                        let ruimtetemperatuurRegeling_AO = entdata.PropertyDatas['VERW-AFG_RUIM'].Value;
                        let typeAfgifteSysteem_AO = entdata.PropertyDatas['VERW-AFG_TYPE_AFG'].Value;
                        bSitA = ruimtetemperatuurRegeling_AO === 'VERW-AFG_RUIM_EIG' && typeAfgifteSysteem_AO === 'VERW-AFG_TYPE_AFG_LVNE' && !ntaSharedLogic.isUtiliteit();
                        bSitB = ruimtetemperatuurRegeling_AO === 'VERW-AFG_RUIM_EIG' && (typeAfgifteSysteem_AO === 'VERW-AFG_TYPE_AFG_STR' || typeAfgifteSysteem_AO === 'VERW-AFG_TYPE_AFG_OPP');

                        showit = bSitA || bSitB;
                        break;
                    }
                case 'VERW-AFG_AAN_RAD': //-- HA20
                    {   //-- Conditie [AJ]
                        bSitA = opwekkersAtLeastOneHasMethod2();

                        let typeAfgifteSysteem_AJ = entdata.PropertyDatas['VERW-AFG_TYPE_AFG'].Value;
                        bSitB = typeAfgifteSysteem_AJ === 'VERW-AFG_TYPE_AFG_STR' || typeAfgifteSysteem_AJ === 'VERW-AFG_TYPE_AFG_RAD' || typeAfgifteSysteem_AJ === 'VERW-AFG_TYPE_AFG_VENTRADCONV';

                        let typeDistributieSysteem_AJ = _verwDistributieData.PropertyDatas['VERW-DISTR_TYPE'].Value;
                        bSitC = typeDistributieSysteem_AJ === 'VERW-DISTR_TYPE_A' || typeDistributieSysteem_AJ === 'VERW-DISTR_TYPE_B' || typeDistributieSysteem_AJ === 'VERW-DISTR_TYPE_D';

                        showit = bSitA && bSitB && bSitC;

                        if (!showit) {
                            let gemeenschappelijk = verwOpwekkersData()[0].PropertyDatas['VERW-OPWEK_GEM'].Value;
                            let bGemeenschappelijk = gemeenschappelijk === 'VERW-OPWEK_GEM_WEL';
                            let bSitD = opwekkersAtLeastOneHasMethod1();
                            showit = bGemeenschappelijk && bSitD && bSitB && bSitC;
                        }
                        break;
                    }
                case 'VERW-AFG_AAN_WIS': //-- HA21
                    {   //-- Conditie [AE]
                        bSitA = opwekkersAtLeastOneHasMethod2();

                        let typeAfgifteSysteem_AE = entdata.PropertyDatas['VERW-AFG_TYPE_AFG'].Value;
                        bSitB = typeAfgifteSysteem_AE === 'VERW-AFG_TYPE_AFG_LVNE' || typeAfgifteSysteem_AE === 'VERW-AFG_TYPE_AFG_LVE' || typeAfgifteSysteem_AE === 'VERW-AFG_TYPE_AFG_LV';

                        let typeDistributieSysteem_AE = _verwDistributieData.PropertyDatas['VERW-DISTR_TYPE'].Value;
                        bSitC = typeDistributieSysteem_AE === 'VERW-DISTR_TYPE_A' || typeDistributieSysteem_AE === 'VERW-DISTR_TYPE_B' || typeDistributieSysteem_AE === 'VERW-DISTR_TYPE_D';

                        showit = bSitA && bSitB && bSitC;

                        if (!showit) {
                            let gemeenschappelijk = verwOpwekkersData()[0].PropertyDatas['VERW-OPWEK_GEM'].Value;
                            let bGemeenschappelijk = gemeenschappelijk === 'VERW-OPWEK_GEM_WEL';
                            let bSitD = opwekkersAtLeastOneHasMethod1();
                            showit = bGemeenschappelijk && bSitD && bSitB && bSitC;
                        }
                        break;
                    }
                case 'VERW-AFG-VENT_INV':
                    {
                        const afgifte = ntaEntityData.getFirstParent(entdata, 'VERW-AFG');
                        const rekenzone = ntaEntityData.getFirstParent(entdata, 'RZ');
                        showit = ntaEntityData.isRelation(rekenzone, afgifte);
                        break;
                    }
                case 'VERW-AFG-VENT_SRT':  //-- HA24
                    {
                        const afgifte = ntaEntityData.getFirstParent(entdata, 'VERW-AFG');
                        const rekenzone = ntaEntityData.getFirstParent(entdata, 'RZ');

                        showit = !geenVentilatorenAanwezigVoorHeleAfgifte(afgifte, null) && !invoerVentilatorEigenWaardeVoorHeleAfgifte(afgifte, null);
                        relevant = !geenVentilatorenAanwezig(entdata) && !invoerVentilatorEigenWaarde(entdata);

                        break;
                    }
                case 'VERW-AFG-VENT_P':  //-- HA25
                    {
                        const afgifte = ntaEntityData.getFirstParent(entdata, 'VERW-AFG');
                        const rekenzone = ntaEntityData.getFirstParent(entdata, 'RZ');

                        showit = !geenVentilatorenAanwezigVoorHeleAfgifte(afgifte, null);
                        relevant = !geenVentilatorenAanwezig(entdata);

                        if (relevant) {
                            if (invoerVentilatorForfaitair(entdata)) {
                                const soortVentilator = _afgifteVentilatorTypes.find(x => x.Id === entdata.PropertyDatas['VERW-AFG-VENT_SRT'].Value);
                                if (soortVentilator && (soortVentilator.key2 === 'lokale direct gestookte luchtverwarming - gas' || soortVentilator.key1 === 'centraal')) {
                                    /// in deze situatie moet de waarde uit de rekenkern gelezen worden
                                    relevant = false;
                                }
                            }
                        }
                        break;
                    }
                case 'VERW-AFG-VENT_N': //-- HA26
                    {
                        const afgifte = ntaEntityData.getFirstParent(entdata, 'VERW-AFG');
                        const rekenzone = ntaEntityData.getFirstParent(entdata, 'RZ');

                        showit = !nVentShouldBeHiddenVoorHeleAfgifte(afgifte, null);
                        relevant = !nVentShouldBeHidden(entdata);
                        break;
                    }
                case 'MEASURE-COSTS_INV':
                case 'MEASURE-COSTS_PER_M2': {
                    showit = false;
                    break;
                }
            }

            // standaard is relevant gelijk aan visible
            if (relevant === null) relevant = showit;

            const propdatas = [propdata, ...ntaSharedLogic.getUnitSpecificResultPropdatas(prop, entdata)];
            ntaEntityData.setPropdataStatus(propdatas, relevant, showit);

            return !showit;
        } //-- end: isHidden ------------------------------------------------------------------------//

        function conditieOCP(entdata) {
            //--Conditie [OCP]: naam property is 'hulpenergie alle lokale opwekkers' als HO28 is getoond. In alle overige gevallen is de naam 'hulpenergie per toestel'
            return isHidden(ntabuilding.properties['VERW-OPWEK_TOE_AAN'], entdata) ? "hulpenergie per toestel" : "hulpenergie alle lokale opwekkers";
        } //-- end: conditieOCP --------------------------------------------------------------------------------//

        function conditieOAV(entdata) {
            //-- Conditie [OAV] begint met Toon..
            let propRegenratieBron = _verwOpwekkerProperties['VERW-OPWEK_REGB'];
            let propValueRegeneratieBron =  propRegenratieBron.getValue(entdata);
            let showit = !isHidden(propRegenratieBron, entdata) && propValueRegeneratieBron === 'VERW-OPWEK_REGB_WEL';

            return showit; //-- bij showit True is de conditie geldig.
        } //-- end: conditieOAV ------------------------------------------------------------------------//

        function conditieOCQ(entdata) {
            //--Conditie [OCQ]: toon indien HO11 = 'hoge brontemperatuur: ≥ 20°C en < 40°C' OF 'hoge brontemperatuur: ≥ 40°C'
            const propWarmtepompbron = ntabuilding.properties['VERW-OPWEK_POMP'];
            let showit = !isHidden(propWarmtepompbron, entdata);
            if (showit) {
                const warmtepompbron = getListItem(propWarmtepompbron, entdata);
                showit = !!warmtepompbron && ['hoge brontemperatuur: ≥ 20°C en < 40°C', 'hoge brontemperatuur: ≥ 40°C'].includes(warmtepompbron.Value);
            }
            return showit;
        } //-- end: conditieOCQ -----------------------------------------------------------------//

        function conditieOCR(entdata) {
            //--Conditie [OCR] toon indien HO57 = locatiespecifiek
            const propInvoerHTBron = ntabuilding.properties['VERW-OPWEK_HT_INV'];
            return !isHidden(propInvoerHTBron, entdata)
                && propInvoerHTBron.getValue(entdata) === 'VERW-OPWEK_HT_INV_LOC';
        } //-- end: conditieOCR -----------------------------------------------------------------//

        function conditieOCS(entdata) {
            //--Conditie [OCS] toon indien HO57 = eigen waarde primaire en hernieuwbare energiefactor bron
            const propInvoerHTBron = ntabuilding.properties['VERW-OPWEK_HT_INV'];
            return !isHidden(propInvoerHTBron, entdata)
                && propInvoerHTBron.getValue(entdata) === 'VERW-OPWEK_HT_INV_EIG';
        } //-- end: conditieOCS -----------------------------------------------------------------//

        function conditieOCU(entdata) {
            //--Conditie [OCU] toon indien HO57 = locatiespecifiek OF forfaitair
            const propInvoerHTBron = ntabuilding.properties['VERW-OPWEK_HT_INV'];
            return !isHidden(propInvoerHTBron, entdata)
                && ['VERW-OPWEK_HT_INV_LOC', 'VERW-OPWEK_HT_INV_FORF'].includes(propInvoerHTBron.getValue(entdata));
        } //-- end: conditieOCU -----------------------------------------------------------------//

        function conditieOCT(entdata) {
            //--Conditie [OCT] toon indien HO11 = 'hoge brontemperatuur: ≥ 15°C en < 20°C'
            const propWarmtepompbron = ntabuilding.properties['VERW-OPWEK_POMP'];
            let showit = !isHidden(propWarmtepompbron, entdata);
            if (showit) {
                const warmtepompbron = getListItem(propWarmtepompbron, entdata);
                showit = !!warmtepompbron && warmtepompbron.Value === 'hoge brontemperatuur: ≥ 15°C en < 20°C';
            }
            return showit;
        } //-- end: conditieOCT -----------------------------------------------------------------//

        function conditieDAT() {
            // conditie [DAT]:
            // als le3.2 toon when HD06='leidinglengte bekend' OR HD06='leidinglengte bekend - leidinggegevens onbekend' OR HD06='leidinglengte bekend - leidinggegevens bekend'
            // als gt3.2 toon when HD36 wordt getoond EN
            // en er sprake is van HD06 = 'leidinglengte bekend' OR HD06 = 'leidinglengte bekend - leidinggegevens onbekend' OR HD06 = 'leidinglengte bekend - leidinggegevens bekend' OR
            //                     HD13 = 'leidinglengte bekend' OR HD13 = 'leidinglengte bekend - overige leidinggegevens onbekend' OR HD13 = 'leidinglengte bekend - overige leidinggegevens bekend'


            if (ntabuilding.ntaVersionId < 300) {
                //le3.2
                return binnenLeidinglengteNauwkeurig(_verwDistributieBinnenData);
            } else {
                //gt3.2
                return !distributiepompTabelIsHidden() && (binnenLeidinglengteNauwkeurig(_verwDistributieBinnenData) || buitenLeidinglengteNauwkeurig(_verwDistributieBuitenData));
            }
        } //-- end: conditieDAT -----------------------------------------------------------------//

        function conditieHBD_E_F(entdata, value) {
            const propdataVatInvoer = entdata.PropertyDatas['VERW-VAT_INV'];
            return propdataVatInvoer.Relevant && propdataVatInvoer.Value === value;
        } //-- end: conditieHBD_E_F -------------------------------------------------------------//

        function conditieHBK(entdata) {
            //-- [HBK] toon indien HB10 (VERW-VAT_ENERGIE) = energielabel buffervat onbekend OF HB06 (VERW-VAT_VOL_A) > 500
            const propdataVatEnergie = entdata.PropertyDatas['VERW-VAT_ENERGIE'];
            const propdataVatVolA = entdata.PropertyDatas['VERW-VAT_VOL_A'];
            return propdataVatEnergie.Relevant && propdataVatEnergie.Value === 'VERW-VAT_ENERGIE_ONB'
                || propdataVatVolA.Relevant && parseFloat(propdataVatVolA.Value) > 500;
        } //-- end: conditieHBK -----------------------------------------------------------------//

        function binnenLeidinglengteNauwkeurig(entdata) {
            const propInvoerBinnenLeidingen = ntabuilding.properties['VERW-DISTR-BIN_INV'];
            return !isHidden(propInvoerBinnenLeidingen, entdata)
                && ['VERW-DISTR-BIN_INV_D', 'VERW-DISTR-BIN_INV_G', 'VERW-DISTR-BIN_INV_H'].includes(propInvoerBinnenLeidingen.getValue(entdata));

        } //-- end: binnenLeidinglengteNauwkeurig ------------------------------------------------------------------------//

        function buitenLeidinglengteNauwkeurig(entdata) {
            const propInvoerBuitenLeidingen = ntabuilding.properties['VERW-DISTR-BUI_INV'];
            return !isHidden(propInvoerBuitenLeidingen, entdata)
                && ['VERW-DISTR-BUI_INV_A', 'VERW-DISTR-BUI_INV_C', 'VERW-DISTR-BUI_INV_D'].includes(propInvoerBuitenLeidingen.getValue(entdata));
        } //-- end: buitenLeidinglengteNauwkeurig ------------------------------------------------------------------------//

        function binnenLeidinglengteNietNauwkeurig(entdata) {
            //-- Conditie [DT]
            const propInvoerLeidingen = ntabuilding.properties['VERW-DISTR-BIN_INV'];
            return !isHidden(propInvoerLeidingen, entdata)
                && !['VERW-DISTR-BIN_INV_D', 'VERW-DISTR-BIN_INV_G', 'VERW-DISTR-BIN_INV_H'].includes(propInvoerLeidingen.getValue(entdata));
        } //-- end: binnenLeidinglengteOnbekend ------------------------------------------------------------------------//

        function buitenLeidinglengteOnbekend(entdata) {
            //-- Conditie [DU]
            let invoerLeidingen = entdata.PropertyDatas['VERW-DISTR-BUI_INV'].Value;
            return invoerLeidingen === 'VERW-DISTR-BUI_INV_B' || invoerLeidingen === 'VERW-DISTR-BUI_INV_E' || invoerLeidingen === 'VERW-DISTR-BUI_INV_F';
        } //-- end: buitenLeidinglengteOnbekend ------------------------------------------------------------------------//

        function buitenLeidinglengteBekend(entdata) {
            //-- Conditie [DK]
            let invoerLeidingen = entdata.PropertyDatas['VERW-DISTR-BUI_INV'].Value;
            return invoerLeidingen === 'VERW-DISTR-BUI_INV_A' || invoerLeidingen === 'VERW-DISTR-BUI_INV_C' || invoerLeidingen === 'VERW-DISTR-BUI_INV_D';
        } //-- end: buitenLeidinglengteBekend ------------------------------------------------------------------------//

        function geenLeidingenBuitenVerwarmdeZone(entdata) {
            //-- Conditie [DAB]
            let invoerLeidingen = entdata.PropertyDatas['VERW-DISTR-BUI_INV'].Value;
            return invoerLeidingen === 'VERW-DISTR-BUI_INV_G';
        } //-- end: geenLeidingenBuitenVerwarmdeZone ------------------------------------------------------------------------//

        function binnenLeidingIsOnGeisoleerd() {
            //-- Conditie [DI] Binnen
            let isolatieLeidingen = _verwDistributieBinnenData.PropertyDatas['VERW-DISTR-BIN_ISO_LEI'].Value;
            return isolatieLeidingen === 'VERW-DISTR-_ISO_LEI_G';
        } //-- end: binnenLeidingIsOnGeisoleerd ------------------------------------------------------------------------//

        function buitenLeidingIsOngeisoleerd() {
            //-- Conditie [DI] Buiten
            let isolatieLeidingen = _verwDistributieBuitenData.PropertyDatas['VERW-DISTR-BUI_ISO_LEI'].Value;
            return isolatieLeidingen === 'VERW-DISTR-_ISO_LEI_G';
        } //-- end: buitenLeidingIsOngeisoleerd ------------------------------------------------------------------------//

        function dekkingIsRelevant(entdata) {
            if (!entdata) {
                return false;
            }

            if (ntaSharedLogic.hasRelation(_verwDistributieBinnenData, entdata)) {
                //-- Als binnen hidden is en buiten niet
                if (binnenDekkingShouldBeHidden()) {
                    return false;
                }
            } else {
                if (ntaSharedLogic.hasRelation(_verwDistributieBuitenData, entdata)) {
                    //-- Als buiten hidden is en binnen niet
                    if (buitenDekkingShouldBeHidden()) {
                        return false;
                    }
                }
            }

            return true;
        } //-- end: dekkingIsRelevant ------------------------------------------------------------------------//

        function dekkingIsHidden() {
            //-- Conditie [DF]
            return binnenDekkingShouldBeHidden() && buitenDekkingShouldBeHidden();
        } //-- end: dekkingIsHidden ------------------------------------------------------------------------//

        function binnenDekkingShouldBeHidden() {
            //-- Conditie [DF] begint met Toon... //Binnen
            let binnenIsolatieLeidingen = _verwDistributieBinnenData.PropertyDatas['VERW-DISTR-BIN_ISO_LEI'].Value;
            let showit = !binnenEigenschappenDistributieleidingenIsHidden() && binnenIsolatieLeidingen === 'VERW-DISTR-_ISO_LEI_E';
            return !showit;
        } //-- end: binnenDekkingShouldBeHidden ------------------------------------------------------------------------//

        function buitenDekkingShouldBeHidden() {
            // Conditie [DF] begint met Toon... //Buiten
            let buitenIsolatieLeidingen = _verwDistributieBuitenData.PropertyDatas['VERW-DISTR-BUI_ISO_LEI'].Value;
            let showit = !buitenEigenschappenDistributieleidingenIsHidden() && buitenIsolatieLeidingen === 'VERW-DISTR-_ISO_LEI_E';
            return !showit;
        } //-- end: buitenDekkingShouldBeHidden ------------------------------------------------------------------------//

        function labdaIsolatieIsRelevant(entdata) {
            if (!entdata) {
                return false;
            }

            if (ntaSharedLogic.hasRelation(_verwDistributieBinnenData, entdata)) {
                //-- Als binnen hidden is en buiten niet
                if (binnenLabdaIsolatieShouldBeHidden()) {
                    return false;
                }
            } else {
                if (ntaSharedLogic.hasRelation(_verwDistributieBuitenData, entdata)) {
                    //-- Als buiten hidden is en binnen niet
                    if (buitenLabdaIsolatieShouldBeHidden()) {
                        return false;
                    }
                }
            }

            return true;
        } //-- end: labdaIsolatieIsRelevant ------------------------------------------------------------------------//

        function labdaLeidingIsHidden() {
            //-- Conditie [DAJ] labdaLeiding moet getoond worden als row1 (binnen) of row2 (buiten) niet-geisoleerde leidingen heeft
            let showit = showBinnenLabdaLeiding() || showBuitenLabdaLeiding();
            return !showit;
        } //-- end: labdaLeidingIsHidden ------------------------------------------------------------------------//

        function showBinnenLabdaLeiding() {
            //-- labdaLeiding moet getoond worden als HD09 (isolatie van leiding binnen verwarmde zone) == niet-geisoleerd
            return !binnenEigenschappenDistributieleidingenIsHidden() && binnenLeidingIsOnGeisoleerd();
        } //-- end: showBinnenLabdaLeiding ------------------------------------------------------------------------//

        function showBuitenLabdaLeiding() {
            //-- labdaLeiding moet getoond worden als HD09 (isolatie van leiding binnen verwarmde zone) == niet-geisoleerd
            return !buitenEigenschappenDistributieleidingenIsHidden() && buitenLeidingIsOngeisoleerd();
        } //-- end: showBuitenLabdaLeiding ------------------------------------------------------------------------//

        function labdaLeidingIsRelevant(entdata) {
            if (!entdata) {
                return false;
            }

            let relevant = false;
            if (ntaSharedLogic.hasRelation(_verwDistributieBinnenData, entdata)) {
                //-- Als binnen hidden is en buiten niet
                relevant = showBinnenLabdaLeiding();
            } else {
                if (ntaSharedLogic.hasRelation(_verwDistributieBuitenData, entdata)) {
                    //-- buiten is LamdaLeiding relevant als er een relatie is en Als buiten hidden is en binnen niet
                    relevant = showBuitenLabdaLeiding();
                }
            }

            return relevant;
        } //-- end: labdaLeidingIsRelevant ------------------------------------------------------------------------//

        function labdaIsolatieIsHidden() {
            //-- Conditie [DG]
            return binnenLabdaIsolatieShouldBeHidden() && buitenLabdaIsolatieShouldBeHidden();
        } //-- end: labdaIsolatieIsHidden ------------------------------------------------------------------------//

        function binnenLabdaIsolatieShouldBeHidden() {
            //-- Conditie [DG] Binnen
            let binnenIsolatieLeidingen = _verwDistributieBinnenData.PropertyDatas['VERW-DISTR-BIN_ISO_LEI'].Value;
            if (!binnenEigenschappenDistributieleidingenIsHidden() && binnenIsolatieLeidingen && binnenIsolatieLeidingen !== 'VERW-DISTR-_ISO_LEI_G') {
                return false;
            }
            return true;
        } //-- end: binnenLabdaIsolatieShouldBeHidden ------------------------------------------------------//

        function buitenLabdaIsolatieShouldBeHidden() {
            //-- Conditie [DG] Buiten
            let buitenIsolatieLeidingen = _verwDistributieBuitenData.PropertyDatas['VERW-DISTR-BUI_ISO_LEI'].Value;
            if (!buitenEigenschappenDistributieleidingenIsHidden() && buitenIsolatieLeidingen && buitenIsolatieLeidingen !== 'VERW-DISTR-_ISO_LEI_G') {
                return false;
            }
            return true;
        } //-- end: buitenLabdaIsolatieShouldBeHidden ------------------------------------------------------------------------//

        function distributiepompAanwezigOpPerceel() {
            const propDistributiepompInvoer = ntabuilding.properties['VERW-DISTR_POMP_INV'];
            const distributiepompInvoer = propDistributiepompInvoer.getValue(_verwDistributieData);
            //-- alleen als er invoer is, is deze check relevant
            return distributiepompInvoer !== 'VERW-DISTR_POMP_INV_E' && !isHidden(propDistributiepompInvoer, _verwDistributieData);
        } //-- end: distributiepompIsHidden ------------------------------------------------------------------------//

        function distributiepompIsVisible() {
            //-- Conditie [DO]
            //-- 1. HD35 = 'anvullende distributiepomp aanwezig'
            let bSitA = false;
            const propAanvullendePomp = ntabuilding.properties['VERW-DISTR_AANV_POMP']; // HD35
            if (!isHidden(propAanvullendePomp, _verwDistributieData)) {
                bSitA = propAanvullendePomp.getValue(_verwDistributieData) === 'VERW-DISTR_AANV_POMP_WEL';
            }

            //-- HD02 != 'geen watergedragen distributie aanwezig' AND value in column 'hulpenergie' in table9_0 on same row as 'type opwekker' chosen in HO05='methode 2'
            let bSitB = false;
            const propDistributieType = ntabuilding.properties['VERW-DISTR_TYPE']; // HD02
            let bAndersDanGeenWatergedragenDistr = propDistributieType.getValue(_verwDistributieData) !== 'VERW-DISTR_TYPE_E';
            bSitB = opwekkersAtLeastOneHasMethod2() && bAndersDanGeenWatergedragenDistr;

            //-- value in column 'hulpenergie' in table9_0 on same row as 'type opwekker' chosen in HO05='methode 1' AND HO42='gemeenschappelijke installatie' AND HD02≠'geen watergedragen distributiesysteem aanwezig'
            const propGemeenschappelijk = ntabuilding.properties['VERW-OPWEK_GEM'];
            let bSitC = opwekkersAllHasMethod1() && propGemeenschappelijk.getValue(verwOpwekkersData()[0]) === 'VERW-OPWEK_GEM_WEL' && bAndersDanGeenWatergedragenDistr;

            return bSitA || bSitB || bSitC;
        } //-- end: distributiepompIsVisible ------------------------------------------------------------------------//

        function conditieDAK() {
            //-- Conditie [DAK] (is bijna identiek aan conditie DO)
            return distributiepompIsVisible()
                // 2. HD26 != 'geen distributiepomp op perceel aanwezig'
                && distributiepompAanwezigOpPerceel();
        } //-- end: conditieDAK ------------------------------------------------------------------------//


        function opwekkersAtLeastOneHasMethod2() {
            for (const verwOpwekker of verwOpwekkersData()) {
                const opwekType = getTypeOpwekker(verwOpwekker);
                if (opwekType && opwekType.hulpenergie === 'methode 2') {
                    return true;
                }
            }
            return false;
        } //-- end: opwekkersAtLeastOneHasMethod2 ------------------------------------------------------------------------//

        function opwekkersAllHasMethod2() {
            let allMeth2 = true;
            for (const verwOpwekker of verwOpwekkersData()) {
                const opwekType = getTypeOpwekker(verwOpwekker);
                if (opwekType) {
                    allMeth2 = allMeth2true && opwekType.hulpenergie === 'methode 2';
                }
            }
            return allMeth2;
        } //-- end: opwekkersAllHasMethod2 ------------------------------------------------------------------------//

        function opwekkersAtLeastOneHasMethod1() {
            for (const verwOpwekker of verwOpwekkersData()) {
                const opwekType = getTypeOpwekker(verwOpwekker);
                if (opwekType && opwekType.hulpenergie === 'methode 1') {
                    return true;
                }
            }
            return false;
        } //-- end: opwekkersAtLeastOneHasMethod1 ------------------------------------------------------------------------//

        function opwekkersAllHasMethod1() {
            let allMeth1 = true;
            for (const verwOpwekker of verwOpwekkersData()) {
                const opwekType = getTypeOpwekker(verwOpwekker);
                if (opwekType) {
                    allMeth1 = allMeth1 && opwekType.hulpenergie === 'methode 1';
                }
            }
            return allMeth1;
        } //-- end: opwekkersAllHasMethod1 ------------------------------------------------------------------------//

        function conditieDQ() {
            //-- Conditie [DQ]
            let distributiepompInvoer = _verwDistributieData.PropertyDatas['VERW-DISTR_POMP_INV'].Value;
            if (distributiepompInvoer && distributiepompInvoer === 'VERW-DISTR_POMP_INV_D') {
                return true; // show
            }
            return false; // hide
        } //-- end: conditieDQ ------------------------------------------------------------------------//

        function conditieDX() {
            //-- Conditie [DX] begint met Hide
            let hideIt = false;
            let distributiepompInvoer = _verwDistributieData.PropertyDatas['VERW-DISTR_POMP_INV'].Value;
            hideIt = (distributiepompInvoer === null || distributiepompInvoer === 'VERW-DISTR_POMP_INV_C' || distributiepompInvoer === 'VERW-DISTR_POMP_INV_D')

            return hideIt; //-- als hideIt = true is de conditie geldig
        } //-- end: conditieDX ------------------------------------------------------------------------//

        function conditieDZ() {
            //-- Conditie [DZ]
            let distributiepompInvoer = _verwDistributieData.PropertyDatas['VERW-DISTR_POMP_INV'].Value;
            if (distributiepompInvoer && (distributiepompInvoer === 'VERW-DISTR_POMP_INV_B' || distributiepompInvoer === 'VERW-DISTR_POMP_INV_D')) {
                return false; // hide
            }
            return true; // show
        } //-- end: conditieDZ ------------------------------------------------------------------------//

        function conditieOCY() {
            //-- Conditie [OCY] indien versie gt3.2 EN HD42 = 'distributieleidingen uitsluitend voor verwarming' (of HD42 is verborgen)
            //   verberg de velden HD05 t/m HD11 en verplaats de velden HD12 t/m HD17 naar de positie van linker kolom
            // (vanaf versie 3.3 is er namelijk voor verwarming geen invoer meer voor distributieleidingen binnen de schil, maar nog wel voor tapwater).

            const propdataLeidingfuncties = _verwDistributieData.PropertyDatas['VERW-DISTR_FUNCTIE_LEID']; // HD42
            const typeDistributiesysteem = _verwDistributieData.PropertyDatas['VERW-DISTR_TYPE'].Value;

            const visible = (ntabuilding.ntaVersionId < 300
                || (propdataLeidingfuncties.Relevant && propdataLeidingfuncties.Value !== 'VERW-DISTR_FUNCTIE_LEID_VERW'))
                && typeDistributiesysteem !== 'VERW-DISTR_TYPE_E'; // Conditie [DA]

            ntaEntityData.setEntityRelevancy(_verwDistributieBinnenData, visible);
            ntaEntityData.setEntityVisibility(_verwDistributieBinnenData, visible);

            return visible;
        } //-- end: conditieOCY ------------------------------------------------------------------------//


        function leidingenIsHidden() {
            //-- Conditie [DA]
            const typeDistributiesysteem = _verwDistributieData.PropertyDatas['VERW-DISTR_TYPE'].Value;
            const isHidden = typeDistributiesysteem === 'VERW-DISTR_TYPE_E';

            ntaEntityData.setEntityRelevancy(_verwDistributieBinnenData, !isHidden && conditieOCY())
            ntaEntityData.setEntityVisibility(_verwDistributieBinnenData, !isHidden && conditieOCY());
            ntaEntityData.setEntityRelevancy(_verwDistributieBuitenData, !isHidden);
            ntaEntityData.setEntityVisibility(_verwDistributieBuitenData, !isHidden);

            return isHidden;
        } //-- end: leidingenIsHidden ------------------------------------------------------------------------//

        function eigenschappenDistributieleidingenIsHidden() {
            let showLeidingen = !leidingenIsHidden();
            //-- Conditie [DC]
            let showEigLeidBinnen = !binnenEigenschappenDistributieleidingenIsHidden();
            let showEigLeidBuiten = !buitenEigenschappenDistributieleidingenIsHidden();

            let showit = showLeidingen && (showEigLeidBinnen || showEigLeidBuiten);

            return !showit;
        } //-- end: eigenschappenDistributieleidingenIsHidden ------------------------------------------------------------//

        function eigenschappenLeidingenRowIsHidden(entdata) {
            if (ntaSharedLogic.hasRelation(_verwDistributieBinnenData, entdata)) {
                return binnenEigenschappenDistributieleidingenIsHidden();
            } else {
                if (ntaSharedLogic.hasRelation(_verwDistributieBuitenData, entdata)) {
                    return buitenEigenschappenDistributieleidingenIsHidden();
                }
            }
            return true;
        } //-- end: eigenschappenLeidingenRowIsHidden ---------------------------------------------------------------------//

        function binnenEigenschappenDistributieleidingenIsHidden() {
            let showit = !leidingenIsHidden();

            ///-- als leidingen uberhaubt niet zichtbaar zijn omdat type "geen watergedragen systeem is" dan zijn eigenschappen bij voorbat
            ///-- niet zichtbaar en relevant. Dit voorkomt spookmeldingen
            if (showit) {
                const propdataBinnenInvoerLeidingen = _verwDistributieBinnenData.PropertyDatas['VERW-DISTR-BIN_INV'];
                const showLeidingenByInvoer = ['VERW-DISTR-BIN_INV_A', 'VERW-DISTR-BIN_INV_F', 'VERW-DISTR-BIN_INV_H'];
                showit = propdataBinnenInvoerLeidingen.Relevant && showLeidingenByInvoer.includes(propdataBinnenInvoerLeidingen.Value);
            }

            ntaEntityData.setEntityRelevancy(_verwDistributieEigenschappenLeidingenBinnenData, showit);
            ntaEntityData.setEntityVisibility(_verwDistributieEigenschappenLeidingenBinnenData, showit);

            return !showit;
        } //-- end: binnenEigenschappenDistributieleidingenIsHidden ------------------------------------------------------//

        function buitenEigenschappenDistributieleidingenIsHidden() {
            let showit = !leidingenIsHidden();

            ///-- als leidingen uberhaubt niet zichtbaar zijn omdat type "geen watergedragen systeem is" dan zijn eigenschappen bij voorbat
            ///-- niet zichtbaar en relevant. Dit voorkomt spookmeldingen
            if (showit) {
                const propdataBuitenInvoerLeidingen = _verwDistributieBuitenData.PropertyDatas['VERW-DISTR-BUI_INV'];
                const showLeidingenByInvoer = ['VERW-DISTR-BUI_INV_D', 'VERW-DISTR-BUI_INV_F'];

                showit = propdataBuitenInvoerLeidingen.Relevant && showLeidingenByInvoer.includes(propdataBuitenInvoerLeidingen.Value);
            }

            ntaEntityData.setEntityRelevancy(_verwDistributieEigenschappenLeidingenBuitenData, showit);
            ntaEntityData.setEntityVisibility(_verwDistributieEigenschappenLeidingenBuitenData, showit);

            return !showit;
        } //-- end: buitenEigenschappenDistributieleidingenIsHidden ------------------------------------------------------//

        function distributiepompTabelIsHidden() {
            //--- Conditie [DAI]
            const propDistributiePomp = ntabuilding.properties['VERW-DISTR_POMP_INV'];
            let showit = !isHidden(propDistributiePomp, _verwDistributieData) && distributiepompAanwezigOpPerceel();

            //[MW-B]
            if (propDistributiePomp.getValue(_verwDistributieData) === 'VERW-DISTR_POMP_INV_D') { //pompvermogen onbekend, EEI onbekend
                showit = showit && !ntaSharedLogic.isEditingMeasure();
            }

            //BM: uitgezet, want de pompen worden visisble of hidden via pompIsHidden
            //ntaEntityData.setEntityRelevancy(verwDistributiePompData(), showit);
            //ntaEntityData.setEntityVisibility(verwDistributiePompData(), showit);

            return !showit;
        } //-- end: distributiepompTabelIsHidden ------------------------------------------------------------------------//

        function allowMultiplePumps() {
            let propPompInvoer = _verwDistributieProperties['VERW-DISTR_POMP_INV'];
            let propValuePompInvoer = propPompInvoer.getValue(_verwDistributieData);
            if (!isHidden(propPompInvoer, _verwDistributieData) && propValuePompInvoer === 'VERW-DISTR_POMP_INV_D') {
                return false;
            }
            return true;
        } //-- end: allowMultiplePumps ------------------------------------------------------------------------//

        function pompIsHidden(pomp) {
            let showit = true;
            if (!allowMultiplePumps()) {
                if (pompNr(pomp) > 1) {
                    showit = false;
                }
            }
            ntaEntityData.setEntityRelevancy(pomp, showit);
            ntaEntityData.setEntityVisibility(pomp, showit);
            return !showit;
        } //-- end: pompIsHidden ------------------------------------------------------------------------//

        function invoerVentilatorEigenWaarde(afgifteventilator) {
            //-- Conditie [AY]
            return afgifteventilator.PropertyDatas['VERW-AFG-VENT_INV'].Value === 'VERW-AFG-VENT_INV_EIG';
        } //-- end: invoerVentilatorEigenWaarde ------------------------------------------------------------------//

        function invoerVentilatorEigenWaardeVoorHeleAfgifte(afgifte, rekenzone = null) {
            return getAfgifteVentilatoren(afgifte, rekenzone)
                .every(ventilator => invoerVentilatorEigenWaarde(ventilator));
        } //-- end: invoerVentilatorEigenWaardeVoorHeleAfgifte ------------------------------------------------------------------------//

        function invoerVentilatorForfaitair(afgifteventilator) {
            //-- Conditie [AY]
            return afgifteventilator.PropertyDatas['VERW-AFG-VENT_INV'].Value === 'VERW-AFG-VENT_INV_FOR';
        } //-- end: invoerVentilatorForfaitair ------------------------------------------------------------------//

        function invoerVentilatorForfaitairVoorHeleAfgifte(afgifte, rekenzone = null) {
            return getAfgifteVentilatoren(afgifte, rekenzone)
                .every(ventilator => invoerVentilatorForfaitair(ventilator));
        } //-- end: invoerVentilatorForfaitairVoorHeleAfgifte ------------------------------------------------------------------------//

        function alleAfgifteSystemenOppervlakteverwarming() {
            return verwAfgifteData()
                .every(afgifte => afgifte.PropertyDatas['VERW-AFG_TYPE_AFG'].Value === 'VERW-AFG_TYPE_AFG_OPP');
        } //-- end: alleAfgifteSystemenOppervlakteverwarming ------------------------------------------------------------------------//

        function geenVentilatorenAanwezig(afgifteventilator) {
            //-- Conditie [AG]
            const invoerVentilator = afgifteventilator.PropertyDatas['VERW-AFG-VENT_INV'].Value;
            //-- als er nog geen waarde is ingevuld heb ik ook geen ventilator en is return true;
            return !invoerVentilator || invoerVentilator === 'VERW-AFG-VENT_INV_GEEN';
        } //-- end: geenVentilatorenAanwezig ---------------------------------------------------------------------//

        function geenVentilatorenAanwezigVoorHeleAfgifte(afgifte, rekenzone = null) {
            return getAfgifteVentilatoren(afgifte, rekenzone)
                .every(ventilator => geenVentilatorenAanwezig(ventilator));
        } //-- end: geenVentilatorenAanwezigVoorHeleAfgifte ------------------------------------------------------------------------//

        function getVentilatieData() {
            const ventilatieData = _verwRekenzones.flatMap(rz => ntaEntityData.getParents(rz, 'VENT'));
            return ntaEntityData.distinct(ventilatieData);
        } //-- end: getVentilatieData ------------------------------------------------------------------------//

        function nVentShouldBeHidden(afgifteventilator) {
            //-- Conditie [AG]
            if (geenVentilatorenAanwezig(afgifteventilator)) {
                return true;
            }

            //-- Conditie [AA]
            const invoerVentilator = afgifteventilator.PropertyDatas['VERW-AFG-VENT_INV'].Value;
            const soortVentilatorValue = afgifteventilator.PropertyDatas['VERW-AFG-VENT_SRT'].Value;
            const soortVentilator = _afgifteVentilatorTypes.find(function (x) { return x.Id === soortVentilatorValue; });
            return invoerVentilator === 'VERW-AFG-VENT_INV_FOR' && !!soortVentilator && soortVentilator.key1 === 'centraal';
        } //-- end: nVentShouldBeHidden ------------------------------------------------------------------------//

        function nVentShouldBeHiddenVoorHeleAfgifte(afgifte, rekenzone = null) {
            return getAfgifteVentilatoren(afgifte, rekenzone)
                .every(ventilator => nVentShouldBeHidden(ventilator));
        } //-- end: nVentShouldBeHiddenVoorHeleAfgifte ------------------------------------------------------------------------//

        function ventilatorIsHidden(ventilator, afgifte, rekenzone) {
            const firstVentilator = getAfgifteVentilatoren(afgifte, rekenzone)[0];
            const showit = ventilator === firstVentilator
                //-- als de eerste ventilator van het type "geen ventilatoren" is, dan hoeft de ventilator niet zichtbaar te zijn.
                || firstVentilator.PropertyDatas['VERW-AFG-VENT_INV'].Value !== 'VERW-AFG-VENT_INV_GEEN';

            ntaEntityData.setEntityRelevancy(ventilator, showit);
            ntaEntityData.setEntityVisibility(ventilator, showit);
            return !showit;
        } //-- end: ventilatorIsHidden ------------------------------------------------------------------------//

        function checkPrioOpwekker(opwekker) {
            //-- Conditie [OU]
            let opwekkerNummer = opwekkerNr(opwekker);

            //-- Value opwekker type
            const opwekType = getTypeOpwekker(opwekker);
            if (!opwekType) {
                return;
            }

            for (let checkOpwekker of verwOpwekkersData()) {
                if (opwekker.EntityDataId === checkOpwekker.EntityDataId) {
                    continue;
                }
                let checkOpwekType = getTypeOpwekker(checkOpwekker);
                if (checkOpwekType) {
                    let checkOpwekkerNummer = opwekkerNr(checkOpwekker);
                    let bOnjuist = false;
                    if (checkOpwekkerNummer < opwekkerNummer) {
                        //-- controle van opwekker die vóór de meegezonden opwekker
                        bOnjuist = checkOpwekType.prio > opwekType.prio;
                    } else {
                        bOnjuist = opwekType.prio > checkOpwekType.prio;
                    }
                    if (bOnjuist) {
                        //-- foutmelding geven, de waarden weer op null zetten en return
                        ntaMeldingen.warning("[W006]", 'Onjuiste invoer');
                        let propType = _verwOpwekkerProperties['VERW-OPWEK_TYPE'];
                        saveValue(propType, opwekker, null);
                        return;
                    }
                }
            }
        } //-- end: checkPrioOpwekker ------------------------------------------------------------------------//

        function checkGemeenschappelijkOpwekker(entdata) {
            // Conditie [OCO]
            // Gecontrleerd moet worden of deze opwekker dezelfde waarde heeft voor VERW-OPWEK_GEM als het preferente toestel.
            const prop = _verwOpwekkerProperties["VERW-OPWEK_GEM"];
            const opwekkers = verwOpwekkersData();

            if (opwekkers.indexOf(entdata) === 0) {
                const value = prop.getValue(entdata);
                for (const opwekker of opwekkers.slice(1)) {
                   saveValue(prop, opwekker, value);
                }
            } else {
                const preferentValue = opwekkers[0].PropertyDatas[prop.Id].Value;
                saveValue(prop, entdata, preferentValue);
            }
        }//-- end: checkGemeenschappelijkOpwekker --------------------------------------------------------------------------------//


        function buffervatenIsHidden() {
            if (ntabuilding.ntaVersionId < 300)
                return true;

            //-- Conditie [HBA] toon indien:
            //-- 1. HD42 = distributieleidingen voor verwarming en warmtapwater EN HD06 = leidinglengte onbekend - leidinggegevens bekend, OF, leidinglengte bekend - leidinggegevens bekend, of
            //-- 1. HD42 = distributieleidingen voor verwarming en warmtapwater EN HD13 = leidinglengte onbekend - overige leidinggegevens bekend, OF, leidinglengte bekend - overige leidinggegevens bekend

            const distrBinInv = _verwDistributieBinnenData.PropertyDatas['VERW-DISTR-BIN_INV'];
            const distrBuiInv = _verwDistributieBuitenData.PropertyDatas['VERW-DISTR-BUI_INV'];

            const showit = (_verwDistributieBinnenData.Relevant && distrBinInv.Relevant && (distrBinInv.Value === "VERW-DISTR-BIN_INV_F" || distrBinInv.Value === "VERW-DISTR-BIN_INV_H")) ||
                           (_verwDistributieBuitenData.Relevant && distrBuiInv.Relevant && (distrBuiInv.Value === "VERW-DISTR-BUI_INV_F" || distrBuiInv.Value === "VERW-DISTR-BUI_INV_D"));

            if (!showit) { // als vaten zichtbaar zijn, dan bepaalt function buffervatenAanwezig of ze zichtbaar en relevant zijn.
                const vaten = verwVatData();
                ntaEntityData.setEntityRelevancy(vaten, showit);
                ntaEntityData.setEntityVisibility(vaten, showit);
            }

            return !showit;
        } //-- end: buffervatenIsHidden -----------------------------------------------------------------------------------//

        function buffervatenAanwezig() {
            //-- Conditie [HBB] toon indien HB16 = buffervat(en) aanwezig (conditie geldt voor de hele kolom)
            const verwVatAanwezig = _verwData.PropertyDatas['VERW_VAT_AANW'];
            const aanwezig = !!verwVatAanwezig && verwVatAanwezig.Relevant && verwVatAanwezig.Value === 'VERW_VAT_AANW_Y';

            const vaten = verwVatData();
            ntaEntityData.setEntityRelevancy(vaten, aanwezig);
            ntaEntityData.setEntityVisibility(vaten, aanwezig);

            return aanwezig;
        } //-- end: buffervatenAanwezig -----------------------------------------------------------------------------------//

        function afgifteTabelIsHidden() {
            //-- Conditie [AI]
            return verwAfgifteData().length < 2;
        } //-- end: afgifteTabelIsHidden ------------------------------------------------------------------------//

        function alleZonesGekoppeldAanAfgiftes() {
            //-- Conditie [AS]
            ntaMeldingen.melding("[E032]", _verwDataId, true);

            const afgiftesystemen = verwAfgifteData();
            const relatieAlles = _verwRekenzones.every(rz => afgiftesystemen.some(afgifte => ntaSharedLogic.hasRelation(rz, afgifte)));

            if (!relatieAlles) {
                ntaMeldingen.melding("[E032]", _verwDataId, false);
                return;
            }
        } //-- end: alleZonesGekoppeldAanAfgiftes ------------------------------------------------------------------------//

        function alleAfgiftesGekoppeldAanZone() {
            //-- Conditie [AW]
            ntaMeldingen.melding("[E033]", _verwDataId, true);

            const relatieAlles = verwAfgifteData().every(afgifte => _verwRekenzones.some(rz => ntaSharedLogic.hasRelation(rz, afgifte)));

            if (!relatieAlles) {
                ntaMeldingen.melding("[E033]", _verwDataId, false);
                return;
            }
        } //-- end: alleAfgiftesGekoppeldAanZone ------------------------------------------------------------------------//

        function deleteOudeRelatiesAfgifteZone() {
            const rekenzoneIds = new Set(_verwRekenzones.map(rz => rz.EntityDataId));

            for (const afgifte of verwAfgifteData()) {
                const oldRelations = ntaEntityData.getParentRelations(afgifte, 'RZ')
                    .filter(rel => !rekenzoneIds.has(rel.Parent));

                for (const oldRelation of oldRelations) {
                    ntaEntityData.deleteRelation(oldRelation.EntityRelationDataId);
                }
            }
        } //-- end: deleteOudeRelatiesAfgifteZone ------------------------------------------------------------------------//

        function determineAgTotaal() {
            const gebouwType = ntaSharedLogic.getGebouwType();
            const prop = ntabuilding.properties['VERW-OPWEK_A'];

            if (gebouwType === 'TGEB_APPGEB' || gebouwType === 'TGEB_UTILIT' && ntaSharedLogic.perGebouwEnUnit() || ntaSharedLogic.voorProjectwoningen()) {
                return ntaRounding.roundAndAddZerosNewValue(prop, ntaSharedLogic.AgSumRZbySystem(_verwData, true));
            }

            return '';
        } //-- end: determineAgTotaal ------------------------------------------------------------------------//

        function setDefaultValueFabricagejaar() {
            verwOpwekkersData().forEach(function (opwekker, index) {
                let propWKK = _verwOpwekkerProperties['VERW-OPWEK_FABR'];
                let propTOE = _verwOpwekkerProperties['VERW-OPWEK_FABR_TOE'];
                let propDataWKK = propWKK.getData(opwekker);
                let propDataTOE = propTOE.getData(opwekker);

                let newValue = null;
                if (!propDataWKK.Touched) {
                    if (_bouwjaar < 2007) {
                        newValue = 'VERW-OPWEK_FABR_A';
                    }
                    else if (_bouwjaar > 2006 && _bouwjaar < 2015) {
                        newValue = 'VERW-OPWEK_FABR_B';
                    }
                    else if (_bouwjaar > 2014) {
                        newValue = 'VERW-OPWEK_FABR_C';
                    }
                    saveValue(propWKK, opwekker, newValue);
                }

                newValue = null;
                if (!propDataTOE.Touched) {
                    if (_bouwjaar < 2015) {
                        newValue = 'VERW-OPWEK_FABR_TOE_V';
                    }
                    else if (_bouwjaar > 2014) {
                        newValue = 'VERW-OPWEK_FABR_TOE_L';
                    }
                    saveValue(propTOE, opwekker, newValue);
                }
            });
        } //-- end: setDefaultValueFabricagejaar ------------------------------------------------------------------------//

        function setDefaultValuesOpwekkers() {
            for (const opwekker of verwOpwekkersData()) {
                let propdataAgTotaal = opwekker.PropertyDatas['VERW-OPWEK_A'];

                if (!propdataAgTotaal.Touched) {
                    propdataAgTotaal.Value = determineAgTotaal();
                }
            }
        } //-- end: setDefaultValuesOpwekkers ------------------------------------------------------------------------//

        function checkGeenFHGen(opwekker) {
            const toestelId = parseInt(opwekker.PropertyDatas['VERW-OPWEK_TOEW'].Value);
            const product = _selectionTable.H.find(prod => prod.Id === toestelId);

            if (product && product.H_valueId) {
                const subValues = _subValuesProducts.filter(x => x.H_Id === product.H_valueId);
                const tenminsteEenFractie = subValues.some(x => x.Fractie);
                return !tenminsteEenFractie;
            }
            return false;
        } //-- end: checkGeenFHGEN ------------------------------------------------------------------------//

        function checkGeenWHAUX(opwekker) {
            let toestel = parseInt(opwekker.PropertyDatas['VERW-OPWEK_TOEW'].Value);
            let product = _selectionTable.H.find(function (x) { return x.Id === toestel; });

            if (product && product.H_valueId) {
                const subValues = _subValuesProducts.filter(function (x) { return x.H_Id === product.H_valueId; });
                const tenminsteEenHulpenergie = subValues.some(function (x) { return x.HulpEnergie; });

                if (!tenminsteEenHulpenergie) {
                    return true;
                }
            }

            return false;
        } //-- end: checkGeenWHAUX ------------------------------------------------------------------------//

        function setEnergiefracties() {
            //-- Zet HO37 energiefractie aan de hand van het document 'Uitlezen selectietabel.docx'
            /* 4 opties bij 1 opwekker
                - Als forfaitair: 1 conform deze functie
                - Als eigen waarde: HO37 niet nodig, HO38 wordt gebruikt
                - Als productspecifiek en tenminste 1 fH;gen;gpref > 0: interpolatie, dit wordt in de rekenkernkoppeling afgehandeld
                - Als productspecifiek en geen fH;gen;gpref > 0: 1 conform deze functie

               3 opties bij 2 opwekkers
                - Als opwekker 1 productspecifiek en tenminste 1 fH;gen;gpref > 0 bij opwekker 1: interpolatie, dit wordt in de koppeling afgehandeld
                - Als opwekker 1 eigen waarde opwekkingsrendement en fractie / eigen waarde opwekkingsrendement, fractie en hulpenergie: HO38 voor opwekker 1, HO37 voor opwekker 2 = 1 - (HO38 van opwekker 1)
                - Als opwekker 1 forfaitair en opwekker 2 productspecifiek en tenminste 1 fH;gen;gpref > 0: forfaitair bepalen obv vermogen; dit wordt in de rekenkern afgehandeld

               Alle andere gevallen: forfaitair bepalen obv vermogen, wordt in de rekenkern afgehandeld. */
            const opwekkers = verwOpwekkersData();
            if (opwekkers.length > 0) {
                const [opwekker1, opwekker2] = opwekkers;
                const invoerOpwekker1 = opwekker1.PropertyDatas['VERW-OPWEK_INVOER'].Value;
                const propEnergiefractie = ntabuilding.properties['VERW-OPWEK_ENER_NON']; // HO37
                if (opwekkers.length === 1) {
                    if (invoerOpwekker1 === 'VERW-OPWEK_INVOER_FORF' || invoerOpwekker1 === 'VERW-OPWEK_INVOER_EIG_A') {
                        //-- Als forfaitair
                        saveValue(propEnergiefractie, opwekker1, 1.0);
                    } else if (invoerOpwekker1 === 'VERW-OPWEK_INVOER_PROD') {
                        //-- Als productspecifiek en geen fH;gen;gpref > 0
                        if (checkGeenFHGen(opwekker1)) {
                            saveValue(propEnergiefractie, opwekker1, 1.0);
                        }
                    }
                } else if (opwekkers.length === 2) {
                    if (invoerOpwekker1 === 'VERW-OPWEK_INVOER_EIG_B' || invoerOpwekker1 === 'VERW-OPWEK_INVOER_EIG_C') {
                        const energiefractieOpwekker1 = ntaSharedLogic.parseFloat(opwekker1.PropertyDatas['VERW-OPWEK_ENER'].Value);
                        if (!isNaN(energiefractieOpwekker1)) {
                            saveValue(propEnergiefractie, opwekker2, 1.0 - energiefractieOpwekker1);
                        }
                    }
                }
            }
        } //-- end: setEnergiefracties ------------------------------------------------------------------------//

        function getPropdataOntwerpaanvoertemperatuur() {
            const propIdOntwTemp = 'VERW-DISTR_ONTW';
            const propIdOntwHmodel = 'VERW-DISTR_ONTW_H_MODEL';
            const relevantPropId = !isHidden(propIdOntwHmodel, _verwDistributieData) ? propIdOntwHmodel
                : !isHidden(propIdOntwTemp, _verwDistributieData) ? propIdOntwTemp : '';
            return relevantPropId && _verwDistributieData.PropertyDatas[relevantPropId] || null;
        } //-- end: getPropdataOntwerpaanvoertemperatuur ----------------------------------------//

        function setRendementEnCOP(opwekker) {
            //--- Zet HO31, HO33, HO35 aan de hand van het document 'Uitlezen selectietabel.docx'
            const invoerOpwekker = opwekker.PropertyDatas['VERW-OPWEK_INVOER'].Value;
            const propdataOntwAanvTemp = getPropdataOntwerpaanvoertemperatuur();

            /// ik ga eerst eventuele E111 meldingen weghalen van de ontwerpaanvoer properties van dit verwarmingsysteem. Wanneer propdataOntwAanvTemp
            /// gelijk is aan null dan wordt aan het eind de eventuele melding niet "uitgezet" (lees: weggehaald).
            if (!propdataOntwAanvTemp) {
                const E111Meldingen = ntaEntityData.getListWithEntityId('MELDING').filter(m => m.PropertyDatas['MELD_ERRORID'].Value === '[E111]');
                const propdataIds = [_verwDistributieData.PropertyDatas['VERW-DISTR_ONTW'].PropertyDataId, _verwDistributieData.PropertyDatas['VERW-DISTR_ONTW_H_MODEL'].PropertyDataId];
                for (const melding of E111Meldingen) {
                    if (propdataIds.includes(melding.PropertyDatas['MELD_INPUTID'].Value)) {
                        /// dan hoort de melding bij dit distributiesysteem en mag hij weg.
                        ntaEntityData.delete(melding.EntityDataId);
                    }
                }
            }
            let E111 = false;

            if (invoerOpwekker === 'VERW-OPWEK_INVOER_FORF' || invoerOpwekker === 'VERW-OPWEK_INVOER_PROD') {
                const opwekType = getTypeOpwekker(opwekker);
                const toestel = parseInt(opwekker.PropertyDatas['VERW-OPWEK_TOEW'].Value);
                const product = _selectionTable.H.find(x => x.Id === toestel);

                let afgifteIngevoerd = false;

                let aanvoertemperatuurId = propdataOntwAanvTemp && propdataOntwAanvTemp.Value;
                if (aanvoertemperatuurId === "VERW-DISTR_ONTW_GE32_M")
                {
                    /// vanaf 3.2 geldt voor 'onbekend' (VERW-DISTR_ONTW_GE32_M) dat waarden voor temperatuur aan gebruiker wordt gevraagd
                    /// haal de CalcValue op van de gekozen CodedValueId van de property VERW-DISTR_MAX_AANVTEMP_WP
                    if (_verwDistributieData.PropertyDatas["VERW-DISTR_AFGIFTE"].Value === "VERW-DISTR_AFG_OVERIG") {
                        ///-- dan de invoer van MAX_AANVTEMP_WP bekijken en daar de calcvalue van ophalen
                        const maxAfgifteCalcValue = _verwDistributieProperties["VERW-DISTR_MAX_AANVTEMP_WP"].getCode(_verwDistributieData);
                        aanvoertemperatuurId = maxAfgifteCalcValue ? maxAfgifteCalcValue.CalcValue : 'VERW-DISTR_ONTW_GE32_I'; //-- _I is meest overig id.33

                        ///-- als de value voor max aanvtemp wp nog null is, hoeft de E111 melding check nog niet uitgevoerd te worden
                        afgifteIngevoerd = _verwDistributieData.PropertyDatas["VERW-DISTR_MAX_AANVTEMP_WP"].Value !== null;
                    } else {
                       //-- de calcvalue van AFGIFTE ophalen
                        const afgifteCodedValue = _verwDistributieProperties["VERW-DISTR_AFGIFTE"].getCode(_verwDistributieData);
                        aanvoertemperatuurId = afgifteCodedValue ? afgifteCodedValue.CalcValue : 'VERW-DISTR_ONTW_GE32_I'; //-- _I is meest overig id.33

                        ///-- als de value voor afgifte nog null is, hoeft de E111 melding check nog niet uitgevoerd te worden
                        afgifteIngevoerd = _verwDistributieData.PropertyDatas["VERW-DISTR_AFGIFTE"].Value !== null;
                    }
                }

                if (opwekType) {
                    //-- afhandeling warmtepomp is gelijk voor forfaitair en productspecifiek
                    if (opwekType.key2 === 'WP') {

                        let newValueCOP = '';

                        if (product) {
                            const subvalues = ntaSelectionTable.getProducts('H', product.H_valueId);
                            let subvalue = subvalues[0];
                            if (invoerOpwekker === 'VERW-OPWEK_INVOER_FORF') {
                                if (aanvoertemperatuurId) {
                                    const bronWarmtepomp = opwekker.PropertyDatas['VERW-OPWEK_POMP'].Value;
                                    if (bronWarmtepomp !== 'VERW-OPWEK_POMP_BUBI') {
                                        /// bij een niet-preferente forfaitaire WP kan de aanvoertemp toch een integer zijn als het preferente toestel productspecifiek is.
                                        /// dus zowel op integer checken als op codedvalue
                                        let aanvoertemperatuur = parseInt(aanvoertemperatuurId);
                                        if (isNaN(aanvoertemperatuur)) {
                                            /// dan zou aanvoertemperatuur een codedValue kunnen zijn omdat er gekozen is voor onbekend en moet de temperatuur opgezocht
                                            /// worden in tabel 9.14
                                            const aanvoertemperatuurCodedValue = _ontwerpAanvoertemperaturen.find(x => x.Id === aanvoertemperatuurId);
                                            aanvoertemperatuur = aanvoertemperatuurCodedValue && aanvoertemperatuurCodedValue.tabel9_14 || "";
                                        }
                                        subvalue = subvalues.find(x => x.AanvoerTemp === aanvoertemperatuur.toString()); /// toString omdat het ook een integer kan zijn
                                    } else if (subvalues.length > 1) {
                                        //-- VO 2021-05-10: Als er nog geen aanvoertemp is ingevuld bij VERW-DISTR_ONTW hoeft deze melding niet te verschijnen.
                                        $log.error(`Gebouw ${ntabuilding.buildingId}, product ${product.Id}: meerdere aanvoertemperaturen voor H_${product.H_valueId} (bron ${bronWarmtepomp}, aanvoertemperatuur ${aanvoertemperatuurId}):`, subvalues);
                                    }
                                }
                                if (subvalue) {
                                    newValueCOP = subvalue.COPH;
                                }

                            } else if (afgifteIngevoerd && invoerOpwekker === 'VERW-OPWEK_INVOER_PROD' && !leidingenIsHidden()) {
                                /// bij productspecief is er een verklaring en moet de COP door interpolatie door de rekenkern bepaald worden.
                                /// hier moet alleen gekeken worden of de gekozen aanvoertemperatuur op de kwaliteitsverklaring staat voor melding [E111]
                                /// wanneer dit er geen sprake is van distributie hoeft deze melding niet gecontroleerd te worden.
                                let aanvoertemperatuur = parseInt(aanvoertemperatuurId);
                                if (isNaN(aanvoertemperatuur)) {
                                    /// dan zou aanvoertemperatuur een codedValue kunnen zijn omdat er gekozen is voor onbekend en moet de temperatuur opgezocht
                                    /// worden in tabel 9.14
                                    const aanvoertemperatuurCodedValue = _ontwerpAanvoertemperaturen.find(x => x.Id === aanvoertemperatuurId);
                                    aanvoertemperatuur = aanvoertemperatuurCodedValue && aanvoertemperatuurCodedValue.tabel9_14 || "";
                                }
                                subvalue = subvalues.find(x => x.AanvoerTemp === aanvoertemperatuur.toString()); /// toString omdat het ook een integer kan zijn

                                E111 = !subvalue;  /// als er geen subvalue gevonden is, staat de temperatuur niet op de verklaring en moet de melding komen.
                            }
                        }

                        const propCOP = ntabuilding.properties['VERW-OPWEK_COP_NON'];
                        isHidden(propCOP, opwekker); // hier moet de relevantie even goed gezet worden
                        saveValue(propCOP, opwekker, newValueCOP);

                    } else
                        if (invoerOpwekker === 'VERW-OPWEK_INVOER_FORF') {
                            //-- forfaitair product
                            switch (opwekType.key2) {
                                case 'WKK': {
                                    let newValueRendement = '';
                                    let newValueElektrRendement = '';
                                    const propOpwekkingsRendement = _verwOpwekkerProperties['VERW-OPWEK_REND_NON'];
                                    const propElektrischRendement = _verwOpwekkerProperties['VERW-OPWEK_OMZET_NON'];

                                    if (product && aanvoertemperatuurId) {
                                        const aanvoertemperatuurCodedValue = _ontwerpAanvoertemperaturen.find(x => x.Id === aanvoertemperatuurId);
                                        if (aanvoertemperatuurCodedValue.HT_LT === 'LT') {
                                            newValueRendement = product.WKK_TH_LT.toString();
                                        } else if (aanvoertemperatuurCodedValue.HT_LT === 'HT') {
                                            newValueRendement = product.WKK_TH_HT.toString();
                                        }
                                        newValueElektrRendement = product.WKK_E.toString();
                                    }
                                    saveValue(propOpwekkingsRendement, opwekker, newValueRendement);
                                    saveValue(propElektrischRendement, opwekker, newValueElektrRendement);
                                    break;
                                }
                                case 'EW': {
                                    break;
                                }
                                case 'EL': {
                                    let newValueCOP = '';
                                    const propCOP = ntabuilding.properties['VERW-OPWEK_COP_NON'];
                                    if (product) {
                                        const list = ntaSelectionTable.getProducts('H', product.H_valueId);
                                        if (list.length > 0) {
                                            const subvalue = list.find(x => x.H_Id === product.H_valueId);
                                            if (subvalue) {
                                                newValueCOP= subvalue.COPH.toString();
                                            }
                                        }
                                    }
                                    isHidden(propCOP, opwekker); // hier moet de relevantie even goed gezet worden
                                    saveValue(propCOP, opwekker, newValueCOP);
                                    break;
                                }
                                case 'BM': {
                                    let newValueRend = '';
                                    const propOpwekkingsRendement = _verwOpwekkerProperties['VERW-OPWEK_REND_NON'];
                                    if (product && product.F_nLT1) {
                                        newValueRend = product.F_nLT1.toString();
                                    }
                                    saveValue(propOpwekkingsRendement, opwekker, newValueRend);
                                    break;
                                }
                                default: {
                                    setOpwekkingRendementObvAanvoerTemperatuur(product, opwekker);
                                    break;
                                }
                            }
                        } else {
                            //-- productspecifiek product
                            if (opwekType.key2 === 'GAS' && opwekkerNr(opwekker) > 1) {
                                setOpwekkingRendementObvAanvoerTemperatuur(product, opwekker);
                            }
                        }
                }
            }
            // Indien de onbekende aanvoertemperatuur niet in H_model staat, toon [E111]. alleen voor het preferente toestel
            if (opwekker === verwOpwekkersData()[0]) {
                ntaSharedLogic.setMelding("[E111]", propdataOntwAanvTemp, verwarmingLogic.form_installationverwarming, !E111);
            }
        } //-- end: setRendementEnCOP ------------------------------------------------------------------------//

        function setAllRendementEnCOP() {
            verwOpwekkersData().forEach(function (opwekker, index) {
                setRendementEnCOP(opwekker);
            });
        } //-- end: setAllRendementEnCOP ------------------------------------------------------------------------//

        function setOpwekkingRendementObvAanvoerTemperatuur(product, opwekker) {
            let newValueRendement = '';

            if (product) {
                //-- Haal ontwerpaanvoertemperatuur uit VERW-DISTR_ONTW of VERW-DISTR_ONTW_H_MODEL
                let ontwerpAanvoerTemperatuur = null;

                const propdata = getPropdataOntwerpaanvoertemperatuur();
                if (propdata) {
                    let aanvoertemperatuurId = propdata.Value;

                    if (propdata.PropertyId === 'VERW-DISTR_ONTW') {
                        /// vanaf 3.2 geldt voor 'onbekend' (VERW-DISTR_ONTW_GE32_M) dat waarden voor temperatuur aan gebruiker wordt gevraagd
                        /// haal de CalcValue op van de gekozen CodedValueId van de property VERW-DISTR_AFGIFTE
                        if (aanvoertemperatuurId === "VERW-DISTR_ONTW_GE32_M") {
                            const afgProp = ntabuilding.properties['VERW-DISTR_AFGIFTE'];
                            const afgCode = afgProp && afgProp.getCode(_verwDistributieData);
                            aanvoertemperatuurId = afgCode && afgCode.CalcValue;
                        }
                        ontwerpAanvoerTemperatuur = _ontwerpAanvoertemperaturen.find(x => x.Id === aanvoertemperatuurId);

                    } else if (propdata.PropertyId === 'VERW-DISTR_ONTW_H_MODEL') {
                        const aanvoertemperatuurList = _ontwerpAanvoertemperaturen.filter(x => x.FilterValue2 === aanvoertemperatuurId);
                        const key2Value = alleAfgifteSystemenOppervlakteverwarming() ? 'oppervlakteverwarming' : '';
                        ontwerpAanvoerTemperatuur = aanvoertemperatuurList.find(x => x.key2 === key2Value);
                        if (!ontwerpAanvoerTemperatuur && aanvoertemperatuurList.length === 0) {
                            ontwerpAanvoerTemperatuur = _ontwerpAanvoertemperaturen.find(x => x.Id === aanvoertemperatuurId);
                        }
                    }
                }

                // Bepaal opwekkingsrendement aan de hand van de aanvoertemperatuur
                if (product.Certificate === 1) {
                    newValueRendement = ontwerpAanvoerTemperatuur ? ontwerpAanvoerTemperatuur.HT_LT === 'LT' ? product.nHgenLTbij : product.nHgenHTbij : '';
                } else if (!ontwerpAanvoerTemperatuur) {
                    newValueRendement = product.F_nLT1;
                } else if (opwekkerNr(opwekker) === 1) {
                    newValueRendement = ontwerpAanvoerTemperatuur.HT_LT === 'LT' ? product.F_nLT1 : product.F_nHT1;
                } else {
                    newValueRendement = ontwerpAanvoerTemperatuur.HT_LT === 'LT' ? product.F_nLT2 : product.F_nHT2;
                }
            }

            saveValue('VERW-OPWEK_REND_NON', opwekker, newValueRendement.toString());
        } //-- end: setOpwekkingRendementObvAanvoerTemperatuur ------------------------------------------------------------------------//

        function koppelAlleZonesAanLaatsteAfgifte() {
            if (afgifteTabelIsHidden()) {
                //-- Koppel alle zones aan laatste afgifte
                const afgifte = verwAfgifteData()[0];
                if (afgifte) {
                    for (const rekenzone of _verwRekenzones) {
                        if (!ntaSharedLogic.hasRelation(rekenzone, afgifte)) {
                            //-- koppeling toevoegen
                            ntaEntityData.createRelation(rekenzone.EntityDataId, afgifte.EntityDataId, false, false);
                        }
                    }
                }
            }

            alleZonesGekoppeldAanAfgiftes();
            alleAfgiftesGekoppeldAanZone();
        } //-- end: koppelAlleZonesAanLaatsteAfgifte ------------------------------------------------------------------------//

        function saveKoppelingOpwekkerEnRekenzone(propdata, entdata) {
            //-- koppeling zoeken met rekenzone (er kan maar één relatie zijn tussen VERW-OPWEK <<-->> RZ zijn)
            const relation = ntaEntityData.getChildRelations(entdata, 'RZ')[0];

            //-- Als er al een relatie met deze rekenzone bestaat, hoeft er niets te gebeuren
            if (relation && relation.Child === propdata.Value) {
                return;
            }

            //-- oude koppeling verwijderen
            if (relation) {
                ntaEntityData.deleteRelation(relation.EntityRelationDataId);
            }

            //-- koppeling toevoegen
            if (propdata.Value !== 'VERW-OPWEK_VERBR_OPS_BUI') {
                ntaEntityData.createRelation(entdata.EntityDataId, propdata.Value, false, false);
            }
        } //-- end: saveKoppelingOpwekkerEnRekenzone ---------------------------------------------------------------------//

        function saveKoppelingOpstelPlaatsEnRekenzone(propdata, entdata) {
            //-- koppeling zoeken met rekenzone (er kan maar één relatie zijn tussen VERW-VAT <<-->> RZ)
            const relation = ntaEntityData.getParentRelations(entdata, 'RZ')[0];

            //-- Als er al een relatie met deze rekenzone bestaat, hoeft er niets te gebeuren
            if (relation && relation.Parent === propdata.Value) {
                return;
            }

            //-- oude koppeling verwijderen
            if (relation) {
                ntaEntityData.deleteRelation(relation.EntityRelationDataId);
            }

            //-- koppeling toevoegen
            if (propdata.Value !== 'VERW-VAT_OPSTEL_BUI') {
                ntaEntityData.createRelation(propdata.Value, entdata.EntityDataId, false, false);
            }
        } //-- end: saveKoppelingOpstelPlaatsEnRekenzone ---------------------------------------------------------------------//

        function isReadOnly(prop, entdata) {
            let bReadOnly = false;

            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return bReadOnly;
            }

            switch (prop.Id) {
                case 'VERW-OPWEK_HT_PRI_NON':
                case 'VERW-OPWEK_HT_HER_NON':
                case 'VERW-OPWEK_HT_KCO2_NON':
                case 'VERW-OPWEK_TOE_NON':
                case 'VERW-OPWEK_WARMTE_NON':
                case 'VERW-OPWEK_GEL_WARMTE_NON':
                case 'VERW-OPWEK_REND_NON':
                case 'VERW-OPWEK_COP_NON':
                case 'VERW-OPWEK_PRI_NON':
                case 'VERW-OPWEK_ENER_NON':
                case 'VERW-OPWEK_HULP_NON':
                case 'VERW-OPWEK_HERNIEUW_NON':
                case 'VERW-OPWEK_FPREN_NON':
                case 'VERW-OPWEK_KCO_NON':
                case 'VERW-OPWEK_OMZET_NON':
                case 'VERW-DISTR-BIN_LEN_NON':
                case 'VERW-DISTR-BUI_LEN_NON':
                case 'VERW-DISTR-EIG_RUIMTE':
                case 'VERW-DISTR_POMP_VER_NON':
                case 'VERW-DISTR_POMP_EEI_NON':
                case 'VERW-DISTR_POMP_OMSCHR':
                case 'VERW-VAT_VOL_NON':
                case 'VERW-VAT_TRANS_NON':
                case 'VERW-AFG_TTR_NON':
                case 'VERW-AFG_TAR_NON':
                case 'MEASURE-COSTS_TOTAAL': {
                    bReadOnly = true;
                    break;
                }
                case 'VERW-DISTR-EIG_DEK':
                case 'VERW-DISTR-EIG_LAB_CON':
                    {
                        if (ntaSharedLogic.hasRelation(_verwDistributieBinnenData, entdata)) { //-- Als binnen hidden is en buiten niet
                            bReadOnly = binnenDekkingShouldBeHidden();
                        } else if (ntaSharedLogic.hasRelation(_verwDistributieBuitenData, entdata)) { //-- Als buiten hidden is en binnen niet
                            bReadOnly = buitenDekkingShouldBeHidden();
                        }
                        break;
                    }
                case 'VERW-DISTR-EIG_LAB_ISO':
                    {
                        if (ntaSharedLogic.hasRelation(_verwDistributieBinnenData, entdata)) { //-- Als binnen hidden is en buiten niet
                            bReadOnly = binnenLabdaIsolatieShouldBeHidden();
                        } else if (ntaSharedLogic.hasRelation(_verwDistributieBuitenData, entdata)) { //-- Als buiten hidden is en binnen niet
                            bReadOnly = buitenLabdaIsolatieShouldBeHidden();
                        }
                        break;
                    }
                case 'VERW-DISTR-EIG_LAB_LEI':
                    {
                        if (ntaSharedLogic.hasRelation(_verwDistributieBinnenData, entdata)) { //-- Als binnen hidden is en buiten niet
                            bReadOnly = !showBinnenLabdaLeiding();
                        }
                        else if (ntaSharedLogic.hasRelation(_verwDistributieBuitenData, entdata)) { //-- Als buiten hidden is en binnen niet
                            bReadOnly = !showBuitenLabdaLeiding();
                        }
                        break;
                    }
                case 'VERW-AFG-VENT_SRT':
                    {
                        bReadOnly = geenVentilatorenAanwezig(entdata) || invoerVentilatorEigenWaarde(entdata);
                        //-- Doordat deze property voorkomt in een tabel, kan het zijn dat hij voor de ene rij wel editable moet zijn maar voor de andere niet.
                        //-- Wanneer hij niet relevant is, moet ik in de tabel n.v.t laten zien
                        const nvt = 'VERW-AFG-VENT_SRT_NVT';
                        if (bReadOnly) {
                            saveValue(prop, entdata, nvt);
                        } else if (prop.getValue(entdata) === nvt) {
                            saveValue(prop, entdata, prop.DefaultValue);
                        }
                        break;
                    }
                case 'VERW-AFG-VENT_P':
                    {
                        bReadOnly = geenVentilatorenAanwezig(entdata) || invoerVentilatorForfaitair(entdata);
                        //-- Doordat deze property voorkomt in een tabel, kan het zijn dat hij voor de ene rij wel editable moet zijn maar voor de andere niet.
                        //-- Wanneer hij niet relevant is, moet ik in de tabel n.v.t laten zien
                        const nvt = 'n.v.t.';
                        if (geenVentilatorenAanwezig(entdata)) {
                            saveValue(prop, entdata, nvt);
                        } else if (!bReadOnly && prop.getValue(entdata) === nvt) {
                            saveValue(prop, entdata, prop.DefaultValue);
                        }
                        break;
                    }
                case 'VERW-AFG-VENT_N':
                    {
                        bReadOnly = nVentShouldBeHidden(entdata);
                        //-- Doordat deze property voorkomt in een tabel, kan het zijn dat hij voor de ene rij wel editable moet zijn maar voor de andere niet.
                        //-- Wanneer hij niet relevant is, moet ik in de tabel n.v.t laten zien
                        const nvt = 'n.v.t.';
                        if (bReadOnly) {
                            saveValue(prop, entdata, nvt);
                        } else if (prop.getValue(entdata) === nvt) {
                            saveValue(prop, entdata, prop.DefaultValue);
                        }
                        break;
                    }
            }

            return bReadOnly;
        } //-- end: isReadOnly ------------------------------------------------------------------------//

        function getCodedValues(prop, entdata) {
            let typesList = [];

            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return typesList;
            }

            switch (prop.Id) {
                case 'VERW-OPWEK_TYPE': //-- HO05
                    {
                        typesList = _opwekkerTypes;

                        if (opwekkerNr(entdata) === 1) {
                            //-- Conditie [OAJ]
                            typesList = typesList.filter(function (x) { return x.key7 !== 'bijstook'; });
                        }
                        else {
                            //-- Conditie [OAK]
                            typesList = typesList.filter(function (x) { return x.key6 === 'centraal' || x.key6 === ''; });
                        }

                        //-- Conditie [OAM]
                        if (opwekkerNr(entdata) > 1) {
                            typesList = typesList.filter(function (x) { return x.key2 !== 'ONB'; });
                        }

                        //-- Conditie [OCJ]
                        for (const verwOpwekker of verwOpwekkersData()) {
                            if (!verwOpwekker || verwOpwekker === entdata) { break; }

                            const opwekType = getTypeOpwekker(verwOpwekker);
                            if (!opwekType) { continue; }

                            if (opwekType.key2 === 'EW') {
                                typesList = typesList.filter(function (x) { return x.key2 !== 'EW'; });
                                break;
                            }
                        }
                        break;
                    }
                case 'VERW-OPWEK_INVOER': //-- HO06
                    {
                        let opwekType = getTypeOpwekker(entdata);
                        if (!opwekType) { break; }

                        typesList = ntaValidation.codedValues(prop);

                        if (opwekType.key3 !== 'product') {
                            typesList = typesList.filter(function (x) { return x.FilterValue1 !== 'product'; }); //-- Conditie [OA]
                        }

                        if (opwekType.key2 === 'ONB') {
                            typesList = typesList.filter(function (x) { return x.FilterValue2 !== 'ONB'; });  //-- Conditie [OBI]
                        }

                        if (opwekkerNr(entdata) > 1) {
                            typesList = typesList.filter(function (x) { return x.FilterValue3 !== '1'; }); //-- Conditie [OBE]
                            if (opwekType.key2 === 'WP') {
                                typesList = typesList.filter(function (x) { return x.FilterValue1 !== 'product'; }); //-- Conditie [OCI]
                            }

                        }

                        if (opwekType.Id === "VERW-OPWEK_TYPE_B" || opwekType.Id === "VERW-OPWEK_TYPE_U") {
                            //-- Conditie [OCG]  ->warmtepomp - gasabsorptie / warmtepomp - gasmotor
                            const gebType = ntaSharedLogic.getGebouwType();
                            const calcUnit = ntaSharedLogic.getCalcUnit();
                            if ((gebType !== "TGEB_APP" && gebType !== "TGEB_APPGEB" && gebType !== "TGEB_UTILUNIT") && !(gebType === "TGEB_UTILIT" && calcUnit === "RZUNIT_GEB") ) {
                                typesList = typesList.filter(function (x) { return x.Id !== 'VERW-OPWEK_INVOER_FORF'; });
                            }
                        }

                        break;
                    }
                case 'VERW-OPWEK_FABR': //-- HO21
                    {
                        typesList = ntaValidation.codedValues(prop);

                        //-- Conditie [OBL]
                        const opwekType = getTypeOpwekker(entdata);
                        if (_bouwjaar >= 2007 || opwekType && opwekType.Id === 'VERW-OPWEK_TYPE_O') {
                            typesList = typesList.filter(function (x) { return x.Id !== 'VERW-OPWEK_FABR_A'; });
                        }

                        break;
                    }
                case 'VERW-OPWEK_EM': //-- HO10
                    {
                        const versie = ntabuilding.ntaVersionId >= 300 ? 'gt3.2' : 'le3.2';
                        typesList = ntaValidation.codedValues(prop).filter(x => x.FilterValue1 === versie);

                        break;
                    }
                case 'VERW-OPWEK_REGBES': //-- HO17
                    {
                        const value1 = "SYS";
                        typesList = ntaValidation.codedValues(prop, value1, true);
                        break;
                    }
                case 'VERW-OPWEK_GEM': { //-- HO42
                    typesList = ntaValidation.codedValues(prop);

                    const opwekType = getTypeOpwekker(entdata);
                    if (opwekType) {
                        const opwekkerInvoerValue = entdata.PropertyDatas['VERW-OPWEK_INVOER'].Value;
                        if (opwekType.key9 === 'individueel') {
                            typesList = typesList.filter(function (x) { return x.Id !== 'VERW-OPWEK_GEM_WEL'; }); //-- Conditie [OBO]
                        }
                        else if (opwekType.key9 === 'gemeenschappelijk' || (opwekType.Id === "VERW-OPWEK_TYPE_B" || opwekType.Id === "VERW-OPWEK_TYPE_U") && opwekkerInvoerValue === "VERW-OPWEK_INVOER_FORF") { //-- Conditie [OBP]
                            typesList = typesList.filter(function (x) { return x.Id !== 'VERW-OPWEK_GEM_NIET'; });
                        }
                    }

                    break;
                }
                case 'VERW-OPWEK_VERBR_OPS':
                    {
                        typesList = getOpenVerbrandingCodedValues(entdata);
                        break;
                    }
                case 'VERW-DISTR_TYPE': //-- HD02
                    {
                        //-- Condities [OBT] en [DAL]
                        typesList = ntaValidation.codedValues(prop);
                        const prefOpwekker = verwOpwekkersData()[0];
                        const propBronWarmtepomp = _verwOpwekkerProperties['VERW-OPWEK_POMP'];
                        const bronWarmtepomp = propBronWarmtepomp.getValue(prefOpwekker);
                        const opwekType = getTypeOpwekker(prefOpwekker);

                        // [OBT]
                        const alleenGeenWaterDistributie = (opwekType && opwekType.key6 === 'lokaal')                       //-- key6 van opwekker 1 = 'lokaal'
                            || (bronWarmtepomp === 'VERW-OPWEK_POMP_BUBI' && !isHidden(propBronWarmtepomp, prefOpwekker))   //-- buitenlucht (afgifte binnenlucht)
                            || (opwekType && opwekType.Id === 'VERW-OPWEK_TYPE_L')                                          //-- centrale diect gestookte luchtvewarmer - gas
                            || (opwekType && opwekType.Id === 'VERW-OPWEK_TYPE_M');                                         //-- centrale diect gestookte luchtvewarmer - elektrisch
                        if (alleenGeenWaterDistributie) {
                            //-- laat alleen "Geen Watergedragen distributie aanwezig" zien
                            typesList = typesList.filter(x => x.Id === 'VERW-DISTR_TYPE_E')
                        } else {
                            // [DAL] als deze conditie geldig is, hoeft er niets gefilterd te worden en zijn alle keuzes mogelijk
                            const toonGeenWaterDistributie = (bronWarmtepomp === 'VERW-OPWEK_POMP_BUBI' && !isHidden(propBronWarmtepomp, prefOpwekker)) //-- Warmtepomp
                                || (opwekType && opwekType.Id === 'VERW-OPWEK_TYPE_L')                                                                  //-- centrale diect gestookte luchtvewarmer - gas
                                || (opwekType && opwekType.Id === 'VERW-OPWEK_TYPE_M');                                                                 //-- centrale diect gestookte luchtvewarmer - elektrisch

                            if (!toonGeenWaterDistributie) {
                                //-- laat alles zien behalve "Geen Watergedragen distributie aanwezig"
                                typesList = typesList.filter(x => x.Id !== 'VERW-DISTR_TYPE_E')
                            }
                        }

                        if (ntabuilding.ntaVersionId >= 300) {
                            typesList = typesList.filter(x => x.Id !== 'VERW-DISTR_TYPE_B')
                            break;
                        }

                        break;
                    }
                case 'VERW-DISTR_ONTW': //-- HD03
                    {
                        const opwekType = getTypeOpwekker(verwOpwekkersData()[0]);
                        const invoerOpwekker = verwOpwekkersData()[0].PropertyDatas['VERW-OPWEK_INVOER'].Value; // HO06

                        //-- filter eerst de juiste versie
                        const versie = ntabuilding.ntaVersionId < 200 ? 'le3-1' : 'ge3-2';
                        typesList = _ontwerpAanvoertemperaturen.filter(x => x.FilterValue3 === versie);

                        if (opwekType && invoerOpwekker) {
                            if (ntabuilding.ntaVersionId < 200) {
                                if (opwekType.key2 === 'WP') {
                                    //-- Conditie [DAD]
                                    if (invoerOpwekker === 'VERW-OPWEK_INVOER_FORF') {
                                        if (alleAfgiftesHebbenOppervlakteverwarming()) {
                                            typesList = typesList.filter(x => x.key1 === 'Wp_forfaitair' && x.key2 === 'oppervlakteverwarming');
                                        } else {

                                            typesList = typesList.filter(x => x.key1 === 'Wp_forfaitair' && x.key2 === '');
                                        }
                                    }
                                    if (invoerOpwekker === 'VERW-OPWEK_INVOER_EIG_A' || invoerOpwekker === 'VERW-OPWEK_INVOER_EIG_B' || invoerOpwekker === 'VERW-OPWEK_INVOER_EIG_C') {
                                        if (alleAfgiftesHebbenOppervlakteverwarming()) {
                                            typesList = typesList.filter(x => x.key3 === 'noWP' && x.key2 === 'oppervlakteverwarming');
                                        } else {
                                            typesList = typesList.filter(x => x.key3 === 'noWP' && x.key2 === '');
                                        }
                                    }
                                    //-- als invoerOpwekker product specifiek is voor WP, wordt er op een andere manier een ontwerpTemperaturen lijst gemaakt obv waarden uit selectietabel.
                                } else {
                                    //-- Conditie [DAC]
                                    if (alleAfgiftesHebbenOppervlakteverwarming()) {

                                        typesList = typesList.filter(x => x.key2 === 'oppervlakteverwarming' && x.key3 === 'noWP');
                                    } else {

                                        typesList = typesList.filter(x => x.key2 === '' && x.key3 === 'noWP');
                                    }
                                }
                            } else {
                                ///-- vanaf 3.2 moet ik kijken naar alle opwekkers en als een van de opwekkers een WP is en forfaitair
                                ///-- berekend wordt moeten de aanvoertemperaturen gereduceerd worden
                                for (const opwekker of verwOpwekkersData()) {
                                    const opwekType = _opwekkerTypes.find(t => t.Id === opwekker.PropertyDatas['VERW-OPWEK_TYPE'].Value);
                                    if (opwekType && opwekType.key2 === 'WP' && opwekker.PropertyDatas["VERW-OPWEK_INVOER"].Value === 'VERW-OPWEK_INVOER_FORF') {
                                        const welTabel928 = isToestelWPvolgensTabel928(opwekker);
                                        const key1s = welTabel928
                                            ? new Set(['Wp_forfaitair_9.28', 'Wp_forfaitair_9.28_bronlt40'])
                                            : new Set(['Wp_forfaitair', 'Wp_forfaitair_9.28', 'Wp_forfaitair_9.28_bronlt40']);

                                        typesList = typesList.filter(x => key1s.has(x.key1));

                                        //-- alleen wanneer wp niet voldoet aan tabel 9.28 EN aanvoertemp(HO11) is hoger dan 40 de wp_forfaitair_9.28_bronlt40 er uitfilteren
                                        const valueHO11 = ntabuilding.properties['VERW-OPWEK_POMP'].getValue(opwekker);
                                        const valueHO11HTge40 = valueHO11 === 'VERW-OPWEK_POMP_HT40';
                                        if (!welTabel928 && valueHO11HTge40) {
                                            typesList = typesList.filter(x => x.key1 !== 'Wp_forfaitair_9.28_bronlt40');
                                        }

                                        // Indien HO20 is verborgen, toon van table9_14 de rijen met key1= Wp_forfaitair_9.28/Wp_forfaitair_9.28_bronlt40.
                                        if (!opwekker.PropertyDatas['VERW-OPWEK_TOEW'].Visible)
                                            typesList = typesList.filter(x => x.key1 === 'Wp_forfaitair_9.28' || x.key1 === 'Wp_forfaitair_9.28_bronlt40');
                                    }
                                }
                            }
                        }
                        //-- Geen invoer, niet genoeg condities om alles te filteren, dus lege lijst
                        if (!invoerOpwekker) {
                            typesList = [];
                        }
                        break;
                    }
                case 'VERW-DISTR_ONTW_H_MODEL': //-- HD03
                    {
                        typesList = _aanvoerTempList;
                        break;
                    }
                case 'VERW-DISTR_WAT': //-- HD04
                    {
                        if (ntabuilding.ntaVersionId >= 300) {
                            typesList = ntaValidation.codedValues(prop, undefined, undefined, 'gt3.2');
                            break;
                        }


                        let typeDistributieSysteem = _verwDistributieData.PropertyDatas['VERW-DISTR_TYPE'].Value;

                        if (typeDistributieSysteem === null) {
                            break;
                        }

                        let ontwerpWatCodedValues = _verwDistributieProperties["VERW-DISTR_TYPE"].Domain.Codes;
                        let naamTypeDistributieSysteem = ontwerpWatCodedValues.find(function (x) { return x.Id === typeDistributieSysteem; }).Value;

                        typesList = ntaValidation.codedValues(prop, naamTypeDistributieSysteem);
                        break;
                    }
                case 'VERW-DISTR-BIN_INV': //-- HD06
                    {
                        typesList = ntaValidation.codedValues(prop);
                        // Conditie [DAM]
                        if (!ntaSharedLogic.isUtiliteit() && ntaSharedLogic.isDetailOpname() && !ntaSharedLogic.isGemeenschappelijkInstallatie(_verwData)) {
                            typesList = typesList.filter(function (x) { return x.FilterValue1 === 'DAM'; });
                        }
                        // Conditie [DS]
                        else if (ntaSharedLogic.isUtiliteit() && ntaSharedLogic.isBasisOpname()) {
                            typesList = typesList.filter(function (x) { return x.FilterValue2 === 'DS'; });
                        }
                        // Conditie [DAN]
                        else if ((!ntaSharedLogic.isUtiliteit() && ntaSharedLogic.isDetailOpname() && ntaSharedLogic.isGemeenschappelijkInstallatie(_verwData)) ||
                            (ntaSharedLogic.isUtiliteit() && ntaSharedLogic.isDetailOpname())) {
                            typesList = typesList.filter(function (x) { return x.FilterValue3 === 'DAN'; });
                        }

                        break;
                    }
                case 'VERW-DISTR-BUI_INV': //-- HD13
                    {
                        //-- alles uit de lijst exclusief de koelingwaarde.
                        typesList = ntaValidation.codedValues(prop, 'K', true);
                        //-- nu nog filteren op basis of detailmethode.
                        if (ntaSharedLogic.isBasisOpname()) { // Conditie DS
                            typesList = typesList.filter(function (x) { return x.FilterValue2 === 'B' || x.FilterValue2 === null; });
                        }
                        else if (ntaSharedLogic.isDetailOpname()) { // Conditie DM
                            typesList = typesList.filter(function (x) { return x.FilterValue2 === 'D' || x.FilterValue2 === null; });
                        }
                        else {
                            typesList = [];
                        }
                        break;
                    }
                case 'VERW-DISTR-BIN_ISO_LEI': //-- HD09
                    {
                        let propInvoerBin = ntabuilding.properties['VERW-DISTR-BIN_INV'];
                        let propValueInvoerBin = propInvoerBin.getValue(_verwDistributieBinnenData);

                        if (propValueInvoerBin === 'VERW-DISTR-BIN_INV_B' ||
                            propValueInvoerBin === 'VERW-DISTR-BIN_INV_C' ||
                            propValueInvoerBin === 'VERW-DISTR-BIN_INV_D' ||
                            propValueInvoerBin === 'VERW-DISTR-BIN_INV_E' ||
                            propValueInvoerBin === 'VERW-DISTR-BIN_INV_G' ||
                            isHidden(propInvoerBin, _verwDistributieBinnenData)) {

                            //-- filteren op F
                            //-- als G06 >= 1995 filter key2 '<1995' als G06 < 1995 filter key2 '>=1995'
                            if (_bouwjaar >= 1995) {
                                typesList = ntaValidation.codedValues(prop, 'N', true, '<1995', true);
                            } else {
                                typesList = ntaValidation.codedValues(prop, 'N', true, '>=1995', true);
                            }
                        }
                        else {
                            //-- Filteren op N
                            //-- als G06 >= 1995 filter key2 '<1995' als G06 < 1995 filter key2 '>=1995'
                            if (_bouwjaar >= 1995) {
                                typesList = ntaValidation.codedValues(prop, 'F', true, '<1995', true);
                            } else {
                                typesList = ntaValidation.codedValues(prop, 'F', true, '>=1995', true);
                            }
                        }
                        break;
                    }
                case 'VERW-DISTR-BUI_ISO_LEI': //-- HD16
                    {
                        const invoerBui = _verwDistributieBuitenData.PropertyDatas['VERW-DISTR-BUI_INV'].Value;

                        if (invoerBui) {
                            if (invoerBui === 'VERW-DISTR-BUI_INV_D' || invoerBui === 'VERW-DISTR-BUI_INV_F') {
                                if (_bouwjaar >= 1995) {
                                    typesList = ntaValidation.codedValues(prop, 'F', true, '<1995', true);
                                } else {
                                    typesList = ntaValidation.codedValues(prop, 'F', true, '>=1995', true);
                                }
                            }
                            else if (invoerBui === 'VERW-DISTR-BUI_INV_A' || invoerBui === 'VERW-DISTR-BUI_INV_B' || invoerBui === 'VERW-DISTR-BUI_INV_C' || invoerBui === 'VERW-DISTR-BUI_INV_E') {
                                if (_bouwjaar >= 1995) {
                                    typesList = ntaValidation.codedValues(prop, 'N', true, '<1995', true);
                                } else {
                                    typesList = ntaValidation.codedValues(prop, 'N', true, '>=1995', true);
                                }
                            }
                        }
                        break;
                    }
                case 'VERW-DISTR_POMP_INV': //-- HD26
                    {
                        const opwekType = getTypeOpwekker(verwOpwekkersData()[0]);
                        if (opwekType) {
                            typesList = ntaValidation.codedValues(prop);
                            typesList = typesList.filter(function (x) { return x.FilterValue1 === opwekType.key2 || x.FilterValue1 === null; });
                        }
                        break;
                    }
                case 'VERW-DISTR_MAX_AANVTEMP_WP': //--HD41
                    {
                        typesList = ntaValidation.codedValues(prop);

                        /// bij voldoet aan tabel9.28 alleen T928 filteren.
                        const opwekkersWP = verwOpwekkersData().filter(x => (getTypeOpwekker(x) || {}).key2 === 'WP');

                        if (opwekkersWP.some(x => isToestelWPvolgensTabel928(x))) {
                            typesList = typesList.filter(x => x.FilterValue1 === 'T928');
                        }
                        break;
                    }
                case 'VERW-VAT_INV': //--HB04
                    {
                        //-- conditie [HBC]
                        typesList = buffervatTypes();
                        break;
                    }
                case 'VERW-VAT_FABR': //--HB09
                    {
                        typesList = ntaValidation.codedValues(prop);
                        break;
                    }
                case 'VERW-VAT_OPSTEL': // HB15

                    const codedValuesRekenzones = _verwRekenzones.map(function (rz) {
                        return {
                            Id: rz.EntityDataId,
                            Value: 'boilervat in zone: ' + rz.PropertyDatas['RZ_OMSCHR'].Value,
                            Order: rz.Order,
                            ImageUrl: '',
                        };
                    });

                    typesList = _listCache.useCacheIfUnchanged(entdata.EntityDataId + ':' + prop.Id, codedValuesRekenzones.concat(ntaValidation.codedValues(prop)), (a, b) => a.Id === b.Id);
                    break;

                case 'VERW-AFG_TYPE_AFG': //-- HA07
                    {
                        typesList = afgifteTypes();

                        break;
                    }
                case 'VERW-AFG_VERT': //-- HA08
                    {
                        typesList = ntaValidation.codedValues(prop);
                        const versie = ntabuilding.ntaVersionId >= 300 ? 'gt3.2' : 'le3.2'
                        typesList = typesList.filter(x => x.FilterValue1 === versie);

                        break;
                    }
                case 'VERW-AFG_TYPE_STR': //-- HA09
                    {
                        const vertrekhoogteHA09 = entdata.PropertyDatas['VERW-AFG_VERT'].Value;

                        if (!vertrekhoogteHA09) {
                            break;
                        }

                        let hoogteHA09 = '';

                        if (vertrekhoogteHA09 === 'VERW-AFG_VERT_A') {
                            hoogteHA09 = '<4';
                        }
                        else if (vertrekhoogteHA09 === 'VERW-AFG_VERT_B') {
                            hoogteHA09 = '4-6';
                        }
                        else if (vertrekhoogteHA09 === 'VERW-AFG_VERT_C') {
                            hoogteHA09 = '6-8';
                        }
                        else if (vertrekhoogteHA09 === 'VERW-AFG_VERT_D') {
                            hoogteHA09 = '>8';
                        }

                        //-- Filter key1
                        typesList = _stralingsverwarmingTypes.filter(function (x) { return x.key1.includes(hoogteHA09); });

                        //-- Filter key3
                        typesList = typesList.filter(function (x) { return x.key3 === ''; });

                        //-- Filter key2
                        let propOntwerpAanvoerTemperatuur = _verwDistributieProperties['VERW-DISTR_ONTW'];
                        let propOntwerpAanvoerTemperatuurHModel = _verwDistributieProperties['VERW-DISTR_ONTW_H_MODEL'];

                        if (!isHidden(propOntwerpAanvoerTemperatuur, _verwDistributieData)) {
                            const temp = propOntwerpAanvoerTemperatuur.getValue(_verwDistributieData);

                            if (temp) {
                                const ht_lt = _ontwerpAanvoertemperaturen.find(function (x) { return x.Id === temp; }).HT_LT;

                                if (ht_lt === 'LT') {
                                    typesList = typesList.filter(function (x) { return x.key2 !== 'HT'; });
                                }
                                else if (ht_lt === 'HT') {
                                    typesList = typesList.filter(function (x) { return x.key2 !== 'LT'; });
                                }
                                else {
                                    typesList = typesList.filter(function (x) { return x.key2 !== 'LT' && x.key2 !== 'HT'; });
                                }
                            }
                            else {
                                typesList = typesList.filter(function (x) { return x.key2 !== 'LT' && x.key2 !== 'HT'; });
                            }
                        } else {
                            if (!isHidden(propOntwerpAanvoerTemperatuurHModel, _verwDistributieData)) {
                                //-- Conditie [AAB], zoek de codedvalue uit table9_14 die overeenkomt met de aanvoertemperatuur uit het H_model en filter op HT_LT
                                let aanvoertemperatuur = propOntwerpAanvoerTemperatuurHModel.getValue(_verwDistributieData);
                                if (aanvoertemperatuur) {
                                    let aanvoertemperatuurList = _ontwerpAanvoertemperaturen.filter(function (x) { return x.FilterValue2 === aanvoertemperatuur; });

                                    let key2Value = alleAfgifteSystemenOppervlakteverwarming() ? 'oppervlakteverwarming' : '';
                                    let aanvoertemperatuurModel = aanvoertemperatuurList.find(function (x) { return x.key2 === key2Value; });

                                    if (aanvoertemperatuurModel && aanvoertemperatuurModel.HT_LT === 'LT') {
                                        typesList = typesList.filter(function (x) { return x.key2 !== 'HT'; });
                                    } else {
                                        if (aanvoertemperatuurModel && aanvoertemperatuurModel.HT_LT === 'HT') {
                                            typesList = typesList.filter(function (x) { return x.key2 !== 'LT'; });
                                        }
                                        else {
                                            typesList = typesList.filter(function (x) { return x.key2 !== 'LT' && x.key2 !== 'HT'; });
                                        }
                                    }
                                }
                            } else {
                                typesList = typesList.filter(function (x) { return x.key2 !== 'LT' && x.key2 !== 'HT'; });
                            }
                        }

                        //-- Filter key6
                        const distributieType = _verwDistributieData.PropertyDatas['VERW-DISTR_TYPE'].Value;

                        if (!distributieType || distributieType !== 'VERW-DISTR_TYPE_E') {
                            typesList = typesList.filter(function (x) { return x.key6 === ''; });
                        }
                        else {
                            typesList = typesList.filter(function (x) { return x.key6 === 'geen watergedragen distributiesysteem aanwezig'; });
                        }

                        //-- Filter key4
                        if (hoogteHA09 === '<4') {
                            let typeDistributie = _verwDistributieData.PropertyDatas['VERW-DISTR_TYPE'].Value;

                            if (typeDistributie) {
                                let codedValuesDistributie = _verwDistributieProperties['VERW-DISTR_TYPE'].Domain.Codes;
                                let pijpsysteem = codedValuesDistributie.find(function (x) { return x.Id === typeDistributie; }).Value;

                                typesList = typesList.filter(function (x) { return x.key4 === pijpsysteem || x.key4 === ''; });

                                //-- Filter key5
                                if (!isHidden(propOntwerpAanvoerTemperatuur, _verwDistributieData)) {
                                    //-- filter met table9_14
                                    const temp = propOntwerpAanvoerTemperatuur.getValue(_verwDistributieData);
                                    if (temp) {
                                        let ontwerpAanvoerTemperatuur = _ontwerpAanvoertemperaturen.find(function (x) { return x.Id === temp; });
                                        let pijpswaarde = 0;

                                        if (typeDistributie === 'VERW-DISTR_TYPE_A' || typeDistributie === 'VERW-DISTR_TYPE_D') {
                                            pijpswaarde = ontwerpAanvoerTemperatuur.tabel9_3_eenpijps;
                                        }
                                        else if (typeDistributie === 'VERW-DISTR_TYPE_B' || typeDistributie === 'VERW-DISTR_TYPE_C') {
                                            pijpswaarde = ontwerpAanvoerTemperatuur.tabel9_3_tweepijps;
                                        }

                                        typesList = typesList.filter(function (x) { return x.key5 === pijpswaarde || x.key4 === ''; });
                                    }
                                } else {
                                    if (!isHidden(propOntwerpAanvoerTemperatuurHModel, _verwDistributieData)) {
                                        //-- TODO: filter via H_model
                                    }
                                }
                            }
                        }
                        break;
                    }
                case 'VERW-AFG_TYPE_RUIM': //-- HA15
                    {
                        if (ntabuilding.ntaVersionId >= 300) {
                            typesList = _ruimteTemperatuurRegelingTypes.filter(x => x.key0 === 'gt3.2');
                            break;
                        }

                        //-- Filter key1
                        let typeAfgifteSysteemHA15 = entdata.PropertyDatas['VERW-AFG_TYPE_AFG'].Value;

                        if (!typeAfgifteSysteemHA15) {
                            break;
                        }
                        let typeAfgifteCodedValuesHA15 = _verwAfgifteProperties["VERW-AFG_TYPE_AFG"].Domain.Codes;
                        let oppKey1HA15 = typeAfgifteCodedValuesHA15.find(function (x) { return x.Id === typeAfgifteSysteemHA15; }).FilterValue1;

                        typesList = _ruimteTemperatuurRegelingTypes.filter(function (x) { return x.key1 === oppKey1HA15; });

                        //-- Filter key3
                        let ruimte = entdata.PropertyDatas['VERW-AFG_RUIM'].Value;

                        if (!ruimte) {
                            break;
                        }

                        let afgifteRuimteCodedValues = _verwAfgifteProperties['VERW-AFG_RUIM'].Domain.Codes;
                        let ruimteValue = afgifteRuimteCodedValues.find(function (x) { return x.Id === ruimte; }).Value;

                        typesList = typesList.filter(function (x) { return x.key3 === ruimteValue; });

                        //-- Filter key6
                        let vertrekhoogteHA15 = entdata.PropertyDatas['VERW-AFG_VERT'].Value;

                        if (!vertrekhoogteHA15) {
                            break;
                        }

                        let hoogteHA15 = '';

                        if (vertrekhoogteHA15 === 'VERW-AFG_VERT_A') {
                            hoogteHA15 = '<4';
                        }
                        else if (vertrekhoogteHA15 === 'VERW-AFG_VERT_B') {
                            hoogteHA15 = '4-6';
                        }
                        else if (vertrekhoogteHA15 === 'VERW-AFG_VERT_C') {
                            hoogteHA15 = '6-8';
                        }
                        else if (vertrekhoogteHA15 === 'VERW-AFG_VERT_D') {
                            hoogteHA15 = '>8';
                        }
                        typesList = typesList.filter(function (x) { return x.key6.includes(hoogteHA15); });

                        if (oppKey1HA15 === 'lvne') {
                            if (ntaSharedLogic.isUtiliteit()) {
                                if (vertrekhoogteHA15 === 'VERW-AFG_VERT_A') {
                                    // Filter key2
                                    typesList = typesList.filter(function (x) { return x.key2 === 'U'; });

                                    // Filter key5
                                    let typeLuchtverwarming = entdata.PropertyDatas['VERW-AFG_TYPE_LUC'].Value;

                                    if (!typeLuchtverwarming) {
                                        break;
                                    }

                                    let afgifteTypeLuchtverwarmingCodedValues = _verwAfgifteProperties['VERW-AFG_TYPE_LUC'].Domain.Codes;
                                    let typeLuchtverwarmingValue = afgifteTypeLuchtverwarmingCodedValues.find(function (x) { return x.Id === typeLuchtverwarming; }).Value;

                                    typesList = typesList.filter(function (x) { return x.key5 === typeLuchtverwarmingValue; });
                                }
                            }
                            else {
                                // Filter key2
                                typesList = typesList.filter(function (x) { return x.key2 === 'W'; });
                                // Filter key3, dat is al eerder gebeurd
                            }
                        }
                        else if (oppKey1HA15 === 'lve') {
                            //-- Filter key4
                            let plaats = entdata.PropertyDatas['VERW-AFG_PL'].Value;

                            if (!plaats) {
                                break;
                            }

                            let plaatsCodedValues = _verwAfgifteProperties['VERW-AFG_PL'].Domain.Codes;
                            let plaatsValue = plaatsCodedValues.find(function (x) { return x.Id === plaats; }).Value;

                            typesList = typesList.filter(function (x) { return x.key4 === plaatsValue; });
                        }

                        break;
                    }
                case 'VERW-AFG_TYPE_OPP': //-- HA11
                    {
                        let vertrekhoogteHA11 = entdata.PropertyDatas['VERW-AFG_VERT'].Value;

                        if (!vertrekhoogteHA11) {
                            break;
                        }

                        let hoogteHA11 = '';

                        if (vertrekhoogteHA11 === 'VERW-AFG_VERT_A') {
                            hoogteHA11 = '<4';
                        }
                        else if (vertrekhoogteHA11 === 'VERW-AFG_VERT_B') {
                            hoogteHA11 = '4-6';
                        }
                        else if (vertrekhoogteHA11 === 'VERW-AFG_VERT_C') {
                            hoogteHA11 = '6-8';
                        }
                        else if (vertrekhoogteHA11 === 'VERW-AFG_VERT_D') {
                            hoogteHA11 = '>8';
                        }

                        //-- Filter key1
                        let oppTempList = _oppervlakteTemperatuurTypes.filter(function (x) { return x.key1.includes(hoogteHA11); });

                        //-- Filter key4
                        //-- Ventilatiesystemen zoeken die aan dezelfde rekenzones zijn gebonden als het afgiftesysteem
                        const afgifteRekenzones = ntaEntityData.getParents(entdata, 'RZ');
                        const ventilatieIds = new Set(afgifteRekenzones.flatMap(rz => ntaEntityData.getParentIds(rz, 'VENT')));

                        //-- VENT data ophalen
                        const ventilatieData = ntaEntityData.getList(ventilatieIds);

                        //-- VENTAAN data ophalen
                        const ventilatieAanvullendData = ventilatieData
                            .map(vent => ntaEntityData.getFirstChild(vent, 'VENTAAN'))
                            .filter(ed => ed);

                        let alleZonesNatuurlijk = true;
                        let alleZonesNietNatuurlijk = true;
                        let alleZonesIngevuld = true;
                        let ventBeide = false;

                        ventilatieData.forEach(function (ventData, index) {
                            let typeSysteem = ventData.PropertyDatas['VENT_SYS'].Value;

                            if (!typeSysteem) {
                                alleZonesIngevuld = false;
                                alleZonesNietNatuurlijk = false;
                                alleZonesNatuurlijk = false;
                            }
                            else if (typeSysteem === 'VENTSYS_NAT') {
                                alleZonesNietNatuurlijk = false;
                            }
                            else if (typeSysteem !== 'VENTSYS_NAT') {
                                alleZonesNatuurlijk = false;
                            }
                        })

                        if (alleZonesIngevuld && afgifteRekenzones.length > 1 && !alleZonesNatuurlijk && !alleZonesNietNatuurlijk) {
                            ventBeide = true;
                        }

                        for (let ventAanData of ventilatieAanvullendData) {
                            let typeSysteem = ventAanData.PropertyDatas['VENTAAN_SYS'].Value;

                            if (!typeSysteem) {
                                continue;
                            }
                            else if (typeSysteem === 'VENTSYS_NAT') {
                                alleZonesNietNatuurlijk = false;
                            }
                            else if (typeSysteem !== 'VENTSYS_NAT') {
                                alleZonesNatuurlijk = false;
                            }
                        }

                        if (ntaSharedLogic.isBasisOpname()) {
                            oppTempList = oppTempList.filter(function (x) { return x.key3 === 'basis'; });
                        }
                        else {
                            oppTempList = oppTempList.filter(function (x) { return x.key3 === ''; });
                        }

                        if (!alleZonesIngevuld) {
                            oppTempList = oppTempList.filter(function (x) { return x.key4 === 'mechanisch' || x.key4 === ''; });
                        }
                        else if (alleZonesNatuurlijk) {
                            oppTempList = oppTempList.filter(function (x) { return x.key4 === 'natuurlijk' || x.key4 === ''; });
                        }
                        else if (alleZonesNietNatuurlijk) {
                            oppTempList = oppTempList.filter(function (x) { return x.key4 === 'mechanisch' || x.key4 === ''; });
                        }
                        else if (ventBeide) {
                            oppTempList = oppTempList.filter(function (x) { return x.key4 === 'beide' || x.key4 === ''; });
                        }

                        oppTempList = ntaEntityData.distinct(oppTempList, type => type.AreaheatingValue);
                        //-- nu overzetten naar een codedValue formaat met Id en Value
                        oppTempList = oppTempList.map(x => ({
                            "Id": x.AreaheatingId,
                            "Value": x.AreaheatingValue,
                        }));

                        //-- Haal een evt. gecachete versie van de lijst voor deze opwekker op. Als de huidige lijst niet gewijzigd is, dan geven we de oude
                        //-- lijst terug, zodat Angular kan zien dat er niets gewijzigd is.
                        typesList = _listCache.useCacheIfUnchanged(entdata.EntityDataId + ':' + prop.Id, oppTempList, (a, b) => a.Id === b.Id);
                        break;
                    }
                case 'VERW-AFG_ISO_OPP': //-- HA12
                    {
                        let vertrekhoogteHA12 = entdata.PropertyDatas['VERW-AFG_VERT'].Value;

                        if (!vertrekhoogteHA12) {
                            break;
                        }

                        let hoogteHA12 = '';

                        if (vertrekhoogteHA12 === 'VERW-AFG_VERT_A') {
                            hoogteHA12 = '<4';
                        }
                        else if (vertrekhoogteHA12 === 'VERW-AFG_VERT_B') {
                            hoogteHA12 = '4-6';
                        }
                        else if (vertrekhoogteHA12 === 'VERW-AFG_VERT_C') {
                            hoogteHA12 = '6-8';
                        }
                        else if (vertrekhoogteHA12 === 'VERW-AFG_VERT_D') {
                            hoogteHA12 = '>8';
                        }

                        //-- Filter key1
                        let oppTempList = _oppervlakteTemperatuurTypes.filter(function (x) { return x.key1.includes(hoogteHA12); });

                        //-- Filter areaheating_system
                        let typeoppervlakte = entdata.PropertyDatas['VERW-AFG_TYPE_OPP'].Value;

                        if (!typeoppervlakte) {
                            break;
                        }
                        let areaHeatingValue = _oppervlakteTemperatuurTypes.find(function (x) { return x.AreaheatingId === typeoppervlakte; }).AreaheatingValue;
                        let key4 = _oppervlakteTemperatuurTypes.find(function (x) { return x.AreaheatingId === typeoppervlakte; }).key4;
                        oppTempList = oppTempList.filter(function (x) { return x.AreaheatingValue === areaHeatingValue && x.key4 === key4; });
                        oppTempList = oppTempList.filter(function (x) { return x.InsulationValue !== ''; });

                        oppTempList = ntaEntityData.distinct(oppTempList, type => type.InsulationValue);
                        //-- nu overzetten naar een codedValue formaat met Id en Value
                        oppTempList = oppTempList.map(x => ({
                            "Id": x.InsulationId,
                            "Value": x.InsulationValue,
                        }));

                        //-- Haal een evt. gecachete versie van de lijst voor deze opwekker op. Als de huidige lijst niet gewijzigd is, dan geven we de oude
                        //-- lijst terug, zodat Angular kan zien dat er niets gewijzigd is.
                        typesList = _listCache.useCacheIfUnchanged(entdata.EntityDataId + ':' + prop.Id, oppTempList, (a, b) => a.Id === b.Id);

                        break;
                    }
                case 'VERW-AFG_PL': //-- HA13
                    {
                        //-- Filter key1
                        let typeAfgifteSysteemHA13 = entdata.PropertyDatas['VERW-AFG_TYPE_AFG'].Value;

                        if (!typeAfgifteSysteemHA13) {
                            break;
                        }
                        let typeAfgifteCodedValuesHA13 = _verwAfgifteProperties["VERW-AFG_TYPE_AFG"].Domain.Codes;
                        let oppKey1HA13 = typeAfgifteCodedValuesHA13.find(function (x) { return x.Id === typeAfgifteSysteemHA13; }).FilterValue1;
                        let plaatsenCodedValues = _verwAfgifteProperties["VERW-AFG_PL"].Domain.Codes;

                        typesList = plaatsenCodedValues.filter(function (x) { return x.FilterValue1 === oppKey1HA13; });
                        break;
                    }
                case 'VERW-AFG-VENT_INV': //-- HA23
                    {
                        typesList = _verwAfgifteVentilatorenProperties['VERW-AFG-VENT_INV'].Domain.Codes;

                        const afgifte = ntaEntityData.getFirstParent(entdata, 'VERW-AFG');
                        const rekenzone = ntaEntityData.getFirstParent(entdata, 'RZ');
                        const firstVentilator = getAfgifteVentilatoren(afgifte, rekenzone)[0];

                        if (entdata !== firstVentilator) {
                            typesList = typesList.filter(function (x) { return x.Id !== 'VERW-AFG-VENT_INV_GEEN'; });
                        }

                        break;
                    }
                case 'VERW-AFG-VENT_SRT': //-- HA24
                    {
                        typesList = _afgifteVentilatorTypes;

                        if (geenVentilatorenAanwezig(entdata) || invoerVentilatorEigenWaarde(entdata)) {
                            typesList = typesList.filter(function (x) { return x.Id === 'VERW-AFG-VENT_SRT_NVT'; });
                            break;
                        }

                        typesList = typesList.filter(function (x) { return x.Id !== 'VERW-AFG-VENT_SRT_NVT'; });

                        // Zoek parent afgiftesysteem en rekenzone
                        const afgiftesysteem = ntaEntityData.getFirstParent(entdata, 'VERW-AFG');
                        const rekenzone = ntaEntityData.getFirstParent(entdata, 'RZ');
                        const vertrekhoogteHA24 = afgiftesysteem.PropertyDatas['VERW-AFG_VERT'].Value;

                        //-- 1) if le3.2 key4 should correspond with HA08
                        if (ntabuilding.ntaVersionId < 300) {
                            // Vertrekhoogte

                            const hoogteHA24 = {
                                'VERW-AFG_VERT_A': '<4',
                                'VERW-AFG_VERT_B': '4-6',
                                'VERW-AFG_VERT_C': '6-8',
                                'VERW-AFG_VERT_D': '>8',
                            }[vertrekhoogteHA24];

                            if (!hoogteHA24) {
                                break;
                            }

                            //-- Filter key4
                            typesList = typesList.filter(function (x) { return x.key4.includes(hoogteHA24); });
                        }

                        //-- Opwekkertype voor filter key2
                        const eersteOpwekker = verwOpwekkersData()[0];
                        const opwekkerTypeHA24 = eersteOpwekker.PropertyDatas['VERW-OPWEK_TYPE'].Value;

                        //-- Afgiftesysteemtype voor filter key1
                        const typeAfgifteSysteem = afgiftesysteem.PropertyDatas['VERW-AFG_TYPE_AFG'].Value;
                        if (!typeAfgifteSysteem) {
                            break;
                        }

                        const typeAfgifteCodedValuesHA24 = _verwAfgifteProperties["VERW-AFG_TYPE_AFG"].Domain.Codes;
                        const afgifteSysteemKey1 = typeAfgifteCodedValuesHA24.find(function (x) { return x.Id === typeAfgifteSysteem; }).FilterValue1;

                        //-- 2) if HO05='lokale direct gestookte luchtverwarmer - gas' show only rows with key2='lokale direct gestookte luchtverwarmer - gas' and rows with key3='per eenheid'
                        //-- Opwekkertype voor filter key2
                        if (opwekkerTypeHA24 === 'VERW-OPWEK_TYPE_N') {
                            //-- Filter key2 or key3
                            typesList = typesList.filter(function (x) { return x.key2 === 'lokale direct gestookte luchtverwarming - gas' || x.key3 === 'per eenheid'; });

                        //-- 3) if HA07 = luchtverwarming AND HO05≠ 'lokale direct gestookte luchtverwarming - gas' / 'lokale verwarming - elektrisch (incl. infrarood)'  show only rows with key1 = 'centraal'(if gt3.2 key 5 should corresond with HA08) and rows with key3 = 'per eenheid'; if there is already 1 row in the table in the same calculation zone with key1 = 'centraal' then only show rows with key3 = 'per eenheid'.
                        } else if ((afgifteSysteemKey1 === 'lvne' || afgifteSysteemKey1 === 'lve' || afgifteSysteemKey1 === 'lv') && opwekkerTypeHA24 !== 'VERW-OPWEK_TYPE_N' && opwekkerTypeHA24 !== 'VERW-OPWEK_TYPE_E') {

                            //-- Zoek andere ventilators van parent afgiftesysteem en rekenzone
                            const ventilators = getAfgifteVentilatoren(afgiftesysteem, rekenzone)
                                .filter(afgVent => afgVent !== entdata);

                            //-- Controleer of een andere ventilator al key1 centraal heeft
                            const centraalAlAanwezig = ventilators.some(ventilator => {
                                const ventilatortype = _afgifteVentilatorTypes.find(x => x.Id === ventilator.PropertyDatas['VERW-AFG-VENT_SRT'].Value);
                                return ventilatortype && ventilatortype.key1 === 'centraal';
                            });

                            if (centraalAlAanwezig) {
                                //-- Filter key3
                                typesList = typesList.filter(function (x) { return x.key3 === 'per eenheid'; });
                            } else {
                                //-- Filter key1 or key3
                                typesList = typesList.filter(function (x) { return x.key1 === 'centraal' || x.key3 === 'per eenheid'; });

                                //if gt3.2 key 5 should corresond with HA08
                                if (ntabuilding.ntaVersionId >= 300) {
                                    const hoogteHA24 = {
                                        'VERW-AFG_VERT_E': '<=8',
                                        'VERW-AFG_VERT_F': '>8',
                                    }[vertrekhoogteHA24];

                                    typesList = typesList.filter(function (x) { return x.key5.includes(hoogteHA24); });
                                }
                            }
                        } else  {
                            //-- Filter key3
                            typesList = typesList.filter(function (x) { return x.key3 === 'per eenheid'; });
                        }

                        break;
                    }
                default:
                    typesList = ntaValidation.codedValues(prop);
                    break;
            }

            ntaSharedLogic.checkPropdataInList(prop, entdata, typesList, verwarmingLogic);
            return typesList;
        } //-- end: getCodedValues ------------------------------------------------------------------------//

        function getOpenVerbrandingCodedValues(opwekker) {
            const rekenzones = ntaEntityData.findEntities(_verwData, 'RZ');

            const rzCodedValues = rekenzones
                .map(rekenzone => ({
                    "Id": rekenzone.EntityDataId,
                    "Value": 'toestel in zone ' + rekenzone.PropertyDatas['RZ_OMSCHR'].Value,
                }));

            let shouldAddBuiten = true;

            //-- Conditie [OCH]
            const propPositieOpwekker = ntabuilding.properties['VERW-OPWEK_POS'];
            if (!isHidden(propPositieOpwekker, opwekker) && propPositieOpwekker.getValue(opwekker) === "VERW-OPWEK_POS_BIN") {
                shouldAddBuiten = false;
            }

            if (shouldAddBuiten) {
                const prop = ntabuilding.properties['VERW-OPWEK_VERBR_OPS'];
                rzCodedValues.push(...ntaValidation.codedValues(prop));
            }

            //-- Haal een evt. gecachete versie van de lijst voor deze opwekker op. Als de huidige lijst niet gewijzigd is, dan geven we de oude
            //-- lijst terug, zodat Angular kan zien dat er niets gewijzigd is.
            return _listCache.useCacheIfUnchanged(opwekker.EntityDataId, rzCodedValues, (a, b) => a.Id === b.Id);
        } //-- end: getOpenVerbrandingCodedValues ------------------------------------------------------------//

        function isToestelWPvolgensTabel928(opwekker) {
            ///-- nu moet ik weten of deze 'WP' wel of niet volgens tabel 9.28 is
            const toestel = parseInt(opwekker.PropertyDatas['VERW-OPWEK_TOEW'].Value);
            const product = _selectionTable.H.find(x => x.Id === toestel);
            return product && product.Value === "warmtepomp - voldoet aan tabel 9.28";
        } //-- end: isToestelWPvolgensTabel928 ---------------------------------------------------------------//

        function validateDependencies(prop, entdata) {
            //-- Ontvangt via de dependencyValidator (die aangeroepen wordt na de saveValue) het veld waarvoor interne afhankelijkheden gelden
            if (!prop || !entdata) {
                return;
            }

            switch (prop.Id) {
                case 'VERW_VAT_AANW': { //-- HB16
                    buffervatenAanwezig();
                    break;
                }
                case 'VERW-OPWEK_GEM': {
                    const propToestel = ntabuilding.properties['VERW-OPWEK_TOEW'];
                    getSelectionTable(propToestel, entdata);
                    saveValueSelectionTable(propToestel, getListItem(propToestel, entdata), entdata);

                    checkGemeenschappelijkOpwekker(entdata);
                    break;
                }
                case 'VERW-OPWEK_REGIO': {
                    const propToestel = ntabuilding.properties['VERW-OPWEK_TOEW'];
                    getSelectionTable(propToestel, entdata);
                    saveValueSelectionTable(propToestel, getListItem(propToestel, entdata), entdata);
                    break;
                }
                case 'VERW-OPWEK_POMP': {
                    const propToestel = ntabuilding.properties['VERW-OPWEK_TOEW'];
                    getSelectionTable(propToestel, entdata);
                    saveValueSelectionTable(propToestel, getListItem(propToestel, entdata), entdata);

                    const propLocatie = ntabuilding.properties['VERW-OPWEK_HT_LOC'];
                    if (propLocatie) {
                        getSelectionTable(propLocatie, entdata);
                        saveValueSelectionTable(propLocatie, getListItem(propLocatie, entdata), entdata);
                    }
                    const propdata = prop.getData(entdata);
                    if (propdata.Value == "VERW-OPWEK_POMP_BUBI") {
                        const propDistrType = ntabuilding.properties['VERW-DISTR_TYPE'];
                        const newValue = "VERW-DISTR_TYPE_E"; //// geen watergedragen
                        saveValue(propDistrType, _verwDistributieData, newValue);
                    }
                    break;
                }
                case 'VERW-OPWEK_HT_INV': { // HO57
                    const propLocatie = ntabuilding.properties['VERW-OPWEK_HT_LOC'];
                    getSelectionTable(propLocatie, entdata);
                    setValuesDependingOnSelectionTable(entdata);
                    break;
                }
                case 'VERW-OPWEK_HT_LOC': { // HO58
                    getSelectionTable(prop, entdata);
                    setValuesDependingOnSelectionTable(entdata);
                    break;
                }
                case 'VERW-OPWEK_TOEW': {
                    getSelectionTable(prop, entdata);
                    setValuesDependingOnSelectionTable(entdata);
                    setEnergiefracties();
                    setRendementEnCOP(entdata);
                    setDescriptionInstallation(entdata);
                    setAanvoerTempList(entdata);
                    break;
                }
                case 'VERW-DISTR_TYPE': {
                    const propdata = prop.getData(entdata);
                    if (propdata.Value == "VERW-DISTR_TYPE_E") {
                        ///-- de zichtbaarheid en relevantie van de leidingen goed zetten.
                        eigenschappenDistributieleidingenIsHidden();

                        ///-- na het zetten van de juiste relevantie/zichtbaarheid validateren om eventuele
                        ///-- meldingen te verwijderen
                        const distrData = []
                            .concat(_verwDistributieData, verwDistributiePompData())
                            .concat(_verwDistributieBinnenData, _verwDistributieBuitenData)
                            .concat(_verwDistributieEigenschappenLeidingenData)
                            .filter(entdata => entdata) // filter alle nulls en undefineds eruit

                        ntaSharedLogic.startFormValidation(distrData, verwarmingLogic);
                    }
                    break;
                }
                case 'VERW-VAT_TYPE': {
                    getSelectionTable(prop, entdata);
                    setValuesDependingOnSelectionTable(entdata);
                    break;
                }
                case 'VERW-AFG_TYPE_RUIM': {
                    if (ntabuilding.ntaVersionId < 300) {
                        const propdataValue = prop.getValue(entdata);
                        if (propdataValue) {
                            const ruimteTemperatuurRegeling = _ruimteTemperatuurRegelingTypes.find(x => x.Id === propdataValue);
                            saveValue('VERW-AFG_TTR_NON', entdata, ruimteTemperatuurRegeling.delta_ctr);
                            saveValue('VERW-AFG_TAR_NON', entdata, ruimteTemperatuurRegeling.delta_roomaut);
                        }
                    }
                    break;
                }
                case 'VERW-AFG-VENT_SRT':
                case 'VERW-AFG-VENT_INV':
                    {
                        const propInvoerVent = ntabuilding.properties['VERW-AFG-VENT_INV'];
                        const propSoortVent = ntabuilding.properties['VERW-AFG-VENT_SRT'];
                        const invoerVentilator = propInvoerVent.getValue(entdata);
                        if (invoerVentilator === 'VERW-AFG-VENT_INV_FOR') {

                            const soortVentId = propSoortVent.getValue(entdata);
                            const soortVentilator = _afgifteVentilatorTypes.find(function (x) { return x.Id === soortVentId; });

                            if (soortVentilator && soortVentilator.key3 === 'per eenheid') {
                                const propPVent = ntabuilding.properties['VERW-AFG-VENT_P'];
                                isHidden(propPVent, entdata); // om te zorgen dat Relevant goed staat (anders werkt saveValue niet; het veld is tweerichtingsverkeer)
                                saveValue(propPVent, entdata, soortVentilator.Paux);
                            }
                        }

                        if (prop.Id === 'VERW-AFG-VENT_INV') {
                            if (invoerVentilator === 'VERW-AFG-VENT_INV_GEEN') {
                                const afgifte = ntaEntityData.getFirstParent(entdata, 'VERW-AFG');
                                const rekenzone = ntaEntityData.getFirstParent(entdata, 'RZ');
                                for (const ventilator of getAfgifteVentilatoren(afgifte, rekenzone)) {
                                    if (ventilator !== entdata) {
                                        ntaEntityData.delete(ventilator.EntityDataId);
                                    }
                                }
                            }

                            const propSoortVentilator = _verwAfgifteVentilatorenProperties['VERW-AFG-VENT_SRT'];
                            const nvt = 'VERW-AFG-VENT_SRT_NVT';
                            if (geenVentilatorenAanwezig(entdata) || invoerVentilatorEigenWaarde(entdata)) {
                                saveValue(propSoortVentilator, entdata, nvt);
                            } else if (propSoortVentilator.getValue(entdata) === nvt) {
                                saveValue(propSoortVentilator, entdata, '');
                            }
                        }
                        break;
                    }
                case 'VERW-OPWEK_TYPE':
                    {   //-- HO05 Conditie [OBN]
                        if (opwekkerNr(entdata) === 1) {
                            //-- Haal opwekker type uit tabel
                            let opwekType = getTypeOpwekker(entdata);

                            if (opwekType && (opwekType.key6 === 'lokaal' || opwekType.key2 === 'ONB')&& verwOpwekkersData().length > 1) {

                                //-- Verwijder alle opwekkers behalve de eerste
                                verwOpwekkersData().forEach(function (opwekker, index) {
                                    if (opwekkerNr(opwekker) !== 1) {
                                        ntaEntityData.delete(opwekker.EntityDataId);
                                    }
                                });
                            }
                        }

                        checkPrioOpwekker(entdata);

                        let propToestel = _verwOpwekkerProperties['VERW-OPWEK_TOEW'];
                        let propBron = _verwOpwekkerProperties['VERW-OPWEK_POMP'];

                        getSelectionTable(propToestel, entdata);
                        //-- als de bron van de WP zichtbaar is, moeten daar de nieuwe bronnen van opgehaald worden.
                        if (!isHidden(propBron, entdata)) {
                            getSelectionTable(propBron, entdata);
                        }
                        setValuesDependingOnSelectionTable(entdata);
                        setDescriptionInstallation(entdata);
                        break;
                    }
                case 'VERW-OPWEK_EM':
                case 'VERW-OPWEK_CW':
                case 'VERW-OPWEK_FABR':
                case 'VERW-OPWEK_VERM':
                case 'VERW-OPWEK_FUNCTIE':
                    {
                        //--- wijziging van het biomassa toestel of het fabricage jaar of HO41 kunnen invloed hebben op de lijst met toestellen
                        const propToestel = _verwOpwekkerProperties['VERW-OPWEK_TOEW'];
                        getSelectionTable(propToestel, entdata);
                        setValuesDependingOnSelectionTable(entdata);
                        setDescriptionInstallation(entdata);
                        break;
                    }
                case 'VERW-OPWEK_INVOER':
                case 'VERW-OPWEK_POS':
                    {   //-- HO06, HO09
                        //-- wijze van invoer heeft effect op selectie van items uit selectietabel
                        const propToestel = _verwOpwekkerProperties['VERW-OPWEK_TOEW'];
                        getSelectionTable(propToestel, entdata);
                        makeAanvoerTempList();

                        //-- Stel selectietabelwaarden opnieuw in
                        setValuesDependingOnSelectionTable(entdata);
                        setDescriptionInstallation(entdata);

                        //-- wijze van invoer heeft effect op de energiefractie
                        setEnergiefracties();
                        setRendementEnCOP(entdata);
                        break;
                    }
                case 'VERW-OPWEK_ENER':
                    {
                        setEnergiefracties();
                        break;
                    }
                case 'VERW-OPWEK_REGOR':
                    {   //-- conditie [OAW]
                        if (prop.getValue(entdata) === 'VERW-OPWEK_REGOR_HOR') {
                            const propHellingshoek = _verwOpwekkerProperties['VERW-OPWEK_REGH'];
                            saveValue(propHellingshoek, entdata, '0');
                        }
                        break;
                    }
                case 'VERW-OPWEK_REGBES':
                    {
                        //-- VO 2021-10-21: als de waarde links, rechts of beide is dan is de entity BELEMMERING relevant, anders niet en hoeft er ook geen validatie plaats te vinden
                        //-- Relevantie van BELEMMERING wordt bij isHidden al op true of false gezet nav contitie OAV (regeneratie zichtbaar). Dus als ik hier kom is BELEMMERING al relevant
                        //-- en moet ik hem op Hidden zetten voor keuze minimaal of overig
                        if (['BELEMTYPE_MIN', 'BELEMTYPE_OVERIG'].includes(prop.getValue(entdata))) {
                            const belemmeringED = ntaEntityData.getFirstChild(entdata, "BELEMMERING");
                            ntaEntityData.setEntityRelevancy(belemmeringED, false);
                            ntaEntityData.setEntityVisibility(belemmeringED, false);
                        }
                        break;

                    }
                case 'VERW-OPWEK_VERBR_OPS':
                    {
                        let propdata = prop.getData(entdata);
                        if (propdata.Value) {
                            saveKoppelingOpwekkerEnRekenzone(propdata, entdata);
                        }
                        break;
                    }
                case 'VERW-DISTR_ONTW':
                case 'VERW-DISTR_ONTW_H_MODEL':
                    {
                        setAllRendementEnCOP();
                        break;
                    }
                case 'VERW-DISTR-BIN_INV':
                case 'VERW-DISTR-BIN_ISO_LEI':
                    {
                        getCodedValues(prop, entdata);

                        let propBinnenDekking = _verwDistributieEigenschappenLeidingenProperties['VERW-DISTR-EIG_DEK'];
                        let propBinnenLabdaConstructie = _verwDistributieEigenschappenLeidingenProperties['VERW-DISTR-EIG_LAB_CON'];
                        let propBinnenLabdaIsolatie = _verwDistributieEigenschappenLeidingenProperties['VERW-DISTR-EIG_LAB_ISO'];
                        let propBinnenLabdaLeiding = _verwDistributieEigenschappenLeidingenProperties['VERW-DISTR-EIG_LAB_LEI'];
                        let newValueNVT = 'n.v.t.';
                        let newValueLeeg = '';
                        let newValue = binnenDekkingShouldBeHidden() ? newValueNVT : newValueLeeg;
                        saveValue(propBinnenDekking, _verwDistributieEigenschappenLeidingenBinnenData, newValue);
                        saveValue(propBinnenLabdaConstructie, _verwDistributieEigenschappenLeidingenBinnenData, newValue);

                        if (binnenLeidingIsOnGeisoleerd()) { // Als de rij van binnen niet zichtbaar hoort te zijn nvt invullen
                            saveValue(propBinnenLabdaIsolatie, _verwDistributieEigenschappenLeidingenBinnenData, newValueNVT);
                            saveValue(propBinnenLabdaLeiding, _verwDistributieEigenschappenLeidingenBinnenData, newValueLeeg);
                        }
                        else {
                            saveValue(propBinnenLabdaLeiding, _verwDistributieEigenschappenLeidingenBinnenData, newValueNVT);
                            saveValue(propBinnenLabdaIsolatie, _verwDistributieEigenschappenLeidingenBinnenData, newValueLeeg);
                        }

                        buffervatenIsHidden();
                        break;
                    }
                case 'VERW-DISTR-BUI_INV':
                case 'VERW-DISTR-BUI_ISO_LEI':
                    {
                        getCodedValues(prop, entdata);

                        let propBuitenDekking = _verwDistributieEigenschappenLeidingenProperties['VERW-DISTR-EIG_DEK'];
                        let propBuitenLabdaConstructie = _verwDistributieEigenschappenLeidingenProperties['VERW-DISTR-EIG_LAB_CON'];
                        let propBuitenLabdaIsolatie = _verwDistributieEigenschappenLeidingenProperties['VERW-DISTR-EIG_LAB_ISO'];
                        let propBuitenLabdaLeiding = _verwDistributieEigenschappenLeidingenProperties['VERW-DISTR-EIG_LAB_LEI'];
                        let newValueNVT = 'n.v.t.';
                        let newValueLeeg = '';
                        let newValue = buitenDekkingShouldBeHidden() ? newValueNVT : newValueLeeg;
                        saveValue(propBuitenDekking, _verwDistributieEigenschappenLeidingenBuitenData, newValue);
                        saveValue(propBuitenLabdaConstructie, _verwDistributieEigenschappenLeidingenBuitenData, newValue);

                        if (buitenLeidingIsOngeisoleerd()) { // Als de rij van binnen niet zichtbaar hoort te zijn nvt invullen
                            saveValue(propBuitenLabdaIsolatie, _verwDistributieEigenschappenLeidingenBuitenData, newValueNVT);
                            saveValue(propBuitenLabdaLeiding, _verwDistributieEigenschappenLeidingenBuitenData, newValueLeeg);
                        }
                        else {
                            saveValue(propBuitenLabdaLeiding, _verwDistributieEigenschappenLeidingenBuitenData, newValueNVT);
                            saveValue(propBuitenLabdaIsolatie, _verwDistributieEigenschappenLeidingenBuitenData, newValueLeeg);
                        }

                        buffervatenIsHidden();
                        break;
                    }
                case 'VERW-DISTR_AFGIFTE':
                case 'VERW-DISTR_MAX_AANVTEMP_WP':
                    {
                        setAllRendementEnCOP();
                        break;
                    }
                case 'VERW-VAT_OPSTEL':
                    {
                        const propdata = prop.getData(entdata);
                        if (propdata.Value) {
                            saveKoppelingOpstelPlaatsEnRekenzone(propdata, entdata);
                        }
                        break;
                    }

            }
        } //-- end: validateDependencies ------------------------------------------------------------------------//

        function startValidationEnergiefractie() {
            const opwekker = verwOpwekkersData()[0];

            const propEnergiefractieNonEditable = ntabuilding.properties['VERW-OPWEK_ENER_NON'];
            const propdataEnergiefractieNonEditable = propEnergiefractieNonEditable.getData(opwekker);

            const propEnergiefractie = ntabuilding.properties['VERW-OPWEK_ENER'];
            const propdataEnergiefractie = propEnergiefractie.getData(opwekker);

            validate(propEnergiefractieNonEditable, propdataEnergiefractieNonEditable, opwekker);
            validate(propEnergiefractie, propdataEnergiefractie, opwekker);
        } //-- end: startValidationEnergiefractie ------------------------------------------------------------------------//

        function startFormValidation() {
            setEnergiefracties();
            setAllRendementEnCOP();
            ntaSharedLogic.startFormValidation(getAllEntDatas(), verwarmingLogic);
        } //-- end: startFormValidation ------------------------------------------------------------------------//

        function endFormValidation() {
            ntaSharedLogic.endFormValidation(getAllEntDatas(), verwarmingLogic);
            setEnergiefracties();
            setAllRendementEnCOP();
        } //-- end: endFormValidation ------------------------------------------------------------------------//

        function getAllEntDatas() { // Geeft alle entdatas in één array terug
            return []
                .concat(_verwData)
                .concat(verwOpwekkersData())
                .concat(_verwDistributieData, verwDistributiePompData())
                .concat(_verwDistributieBinnenData, _verwDistributieBuitenData)
                .concat(_verwDistributieEigenschappenLeidingenData)
                .concat(_verwDistributieEigenschappenLeidingenBinnenData)
                .concat(_verwDistributieEigenschappenLeidingenBuitenData)
                .concat(verwAfgifteData())
                .concat(verwAfgifteData().flatMap(afgifte => getAfgifteVentilatoren(afgifte)))
                .concat(verwVatData())
                .concat(ntaSharedLogic.getCostEntdata(_installationId))
                .filter(entdata => entdata) // filter alle nulls en undefineds eruit
                ;
        } //-- end: getAllEntDatas ------------------------------------------------------------------------//

        function getTypeOpwekker(entdata) {
            const typeOpwekkerValue = entdata.PropertyDatas['VERW-OPWEK_TYPE'].Value;
            return _opwekkerTypes.find(x => x.Id === typeOpwekkerValue) || null;
        } //-- end: getTypeOpwekker ------------------------------------------------------------------------//

        /* Tabellen ************************************************************************************************/
        function createTable9_0() {
            const table9_0 = [
                { Id: 'VERW-OPWEK_TYPE_A', prio: 1,  key1: 'combi', key2: 'WP',  key3: 'product', key4: '',           key5: 'str,opp,lvne,rad,lv,ov',     key6: 'centraal', key7: '',         key8: '',     key9: 'beide',             rendement: 'COP',                    hulpenergie: 'methode 1', Value: 'warmtepomp - elektrisch', PHauxgenVspec: 0 },
                { Id: 'VERW-OPWEK_TYPE_B', prio: 2,  key1: 'combi', key2: 'WP',  key3: '',        key4: 'collectief', key5: 'str,opp,lvne,rad,lv,ov',     key6: 'centraal', key7: '',         key8: 'hide', key9: 'beide',             rendement: 'COP',                    hulpenergie: 'methode 2', Value: 'warmtepomp - gasmotor', PHauxgenVspec: 1 },
                { Id: 'VERW-OPWEK_TYPE_C', prio: 7,  key1: 'combi', key2: 'EW',  key3: 'product', key4: '',           key5: 'str,opp,lvne,rad,lv,ov',     key6: 'centraal', key7: '',         key8: '',     key9: 'beide',             rendement: 'primaire energiefactor', hulpenergie: 'methode 2', Value: 'externe warmtelevering', PHauxgenVspec: 0 },
                { Id: 'VERW-OPWEK_TYPE_D', prio: 20, key1: '',      key2: 'EL',  key3: '',        key4: '',           key5: 'str,opp,lvne,rad,lve,lv,ov', key6: '',         key7: 'bijstook', key8: 'hide', key9: 'beide',             rendement: 'COP',                    hulpenergie: 'methode 1', Value: 'elektrisch element', PHauxgenVspec: 0 },
                { Id: 'VERW-OPWEK_TYPE_E', prio: 8,  key1: '',      key2: 'EL',  key3: '',        key4: '',           key5: 'str,opp,lve,rad,ov',         key6: 'lokaal',   key7: '',         key8: '',     key9: 'individueel',       rendement: 'COP',                    hulpenergie: 'methode 2', Value: 'lokale verwarming - elektrisch (incl. infrarood)', PHauxgenVspec: 0 },
                { Id: 'VERW-OPWEK_TYPE_F', prio: 4,  key1: 'combi', key2: 'BM',  key3: '',        key4: '',           key5: 'str,opp,lvne,rad,lv,ov',     key6: 'centraal', key7: '',         key8: '',     key9: 'beide',             rendement: 'rendement',              hulpenergie: 'methode 2', Value: 'CV-ketel - biomassa', PHauxgenVspec: 10 },
                { Id: 'VERW-OPWEK_TYPE_G', prio: 9,  key1: 'combi', key2: 'EL',  key3: '',        key4: '',           key5: 'str,opp,lvne,rad,lv,ov',     key6: 'centraal', key7: '',         key8: 'hide', key9: 'beide',             rendement: 'COP',                    hulpenergie: 'methode 2', Value: 'CV-ketel - elektrisch', PHauxgenVspec: 0 },
                { Id: 'VERW-OPWEK_TYPE_H', prio: 11, key1: 'combi', key2: 'GAS', key3: 'product', key4: 'collectief', key5: 'str,opp,lvne,rad,lv,ov',     key6: 'centraal', key7: '',         key8: '',     key9: 'beide',             rendement: 'rendement',              hulpenergie: 'methode 1', Value: 'CV-ketel - gas', PHauxgenVspec: 1 },
                { Id: 'VERW-OPWEK_TYPE_I', prio: 5,  key1: '',      key2: 'BM',  key3: '',        key4: '',           key5: 'str,ov',                 key6: 'lokaal',   key7: '',         key8: '',     key9: 'individueel',       rendement: 'rendement',              hulpenergie: 'methode 2', Value: 'kachel - biomassa', PHauxgenVspec: 10 },
                { Id: 'VERW-OPWEK_TYPE_J', prio: 15, key1: '',      key2: 'GAS', key3: '',        key4: '',           key5: 'str,ov',                 key6: 'lokaal',   key7: '',         key8: '',     key9: 'individueel',       rendement: 'rendement',              hulpenergie: 'methode 2', Value: 'kachel - gas', PHauxgenVspec: 0 },
                { Id: 'VERW-OPWEK_TYPE_K', prio: 17, key1: '',      key2: 'GAS', key3: '',        key4: '',           key5: 'str,ov',                 key6: 'lokaal',   key7: '',         key8: '',     key9: 'individueel',       rendement: 'rendement',              hulpenergie: 'methode 2', Value: 'kachel - olie', PHauxgenVspec: 0 },
                { Id: 'VERW-OPWEK_TYPE_L', prio: 12, key1: '',      key2: 'GAS', key3: '',        key4: '',           key5: 'lvne,lv,ov',             key6: 'centraal', key7: '',         key8: '',     key9: 'beide',             rendement: 'rendement',              hulpenergie: 'methode 2', Value: 'centrale direct gestookte luchtverwarmer - gas', PHauxgenVspec: 1 },
                { Id: 'VERW-OPWEK_TYPE_M', prio: 10, key1: '',      key2: 'EL',  key3: '',        key4: '',           key5: 'lve,lv,ov',              key6: 'centraal', key7: '',         key8: 'hide', key9: 'beide',             rendement: 'COP',                    hulpenergie: 'methode 2', Value: 'centrale direct gestookte luchtverwarmer - elektrisch', PHauxgenVspec: 0 },
                { Id: 'VERW-OPWEK_TYPE_N', prio: 14, key1: '',      key2: 'GAS', key3: '',        key4: '',           key5: 'lvne,lv,ov',             key6: 'lokaal',   key7: '',         key8: '',     key9: 'individueel',       rendement: 'rendement',              hulpenergie: 'methode 2', Value: 'lokale direct gestookte luchtverwarmer - gas', PHauxgenVspec: 1 },
                { Id: 'VERW-OPWEK_TYPE_O', prio: 3,  key1: '',      key2: 'WKK', key3: '',        key4: '',           key5: 'str,opp,lvne,rad,lv,ov',     key6: 'centraal', key7: '',         key8: '',     key9: 'beide',             rendement: 'rendement',              hulpenergie: 'methode 1', Value: 'WKK - micro', PHauxgenVspec: 1 },
                { Id: 'VERW-OPWEK_TYPE_P', prio: 6,  key1: '',      key2: 'WKK', key3: '',        key4: '',           key5: 'str,opp,lvne,rad,lv,ov',     key6: 'centraal', key7: '',         key8: '',     key9: 'gemeenschappelijk', rendement: 'rendement',              hulpenergie: 'methode 2', Value: 'WKK - overig', PHauxgenVspec: 1 },
                { Id: 'VERW-OPWEK_TYPE_Q', prio: 18, key1: '',      key2: 'GAS', key3: '',        key4: '',           key5: 'str,opp,lvne,rad,lv,ov',     key6: 'centraal', key7: '',         key8: '',     key9: 'gemeenschappelijk', rendement: 'rendement',              hulpenergie: 'methode 2', Value: 'stoomketel - gas', PHauxgenVspec: 1 },
                { Id: 'VERW-OPWEK_TYPE_R', prio: 19, key1: '',      key2: 'GAS', key3: '',        key4: '',           key5: 'str,opp,lvne,rad,lv,ov',     key6: 'centraal', key7: '',         key8: '',     key9: 'gemeenschappelijk', rendement: 'rendement',              hulpenergie: 'methode 2', Value: 'stoomketel - olie', PHauxgenVspec: 1 },
                { Id: 'VERW-OPWEK_TYPE_S', prio: 15, key1: '',      key2: 'ONB', key3: '',        key4: '',           key5: 'str,opp,lvne,rad,lv,ov',     key6: 'centraal', key7: '',         key8: 'hide', key9: 'gemeenschappelijk', rendement: 'rendement',              hulpenergie: 'methode 2', Value: 'onbekende gemeenschappelijke opwekker', PHauxgenVspec: 0 },
                { Id: 'VERW-OPWEK_TYPE_T', prio: 13, key1: 'combi', key2: 'OLIE', key3: '',       key4: 'collectief', key5: 'str,opp,lvne,rad,lv,ov',     key6: 'centraal', key7: '',         key8: '',     key9: 'beide',             rendement: 'rendement',              hulpenergie: 'methode 2', Value: 'CV-ketel - olie', PHauxgenVspec: 1 },
                { Id: 'VERW-OPWEK_TYPE_U', prio: 2,  key1: 'combi', key2: 'WP',   key3: '',       key4: 'collectief', key5: 'str,opp,lvne,rad,lv,ov',     key6: 'centraal', key7: '',         key8: 'hide', key9: 'beide',             rendement: 'COP',                    hulpenergie: 'methode 2', Value: 'warmtepomp - gasabsorptie', PHauxgenVspec: 0 },
            ];

            const opwekkerCodedValues = ntabuilding.properties["VERW-OPWEK_TYPE"].Domain.Codes;

            table9_0.forEach(function (row, index) {
                const codedValue = opwekkerCodedValues.find(cv => cv.Id === row.Id);
                if (codedValue) {
                    row.Value = codedValue.Value;
                    row.Order = codedValue.Order;
                }
            });
            table9_0.sort(ntaSharedLogic.orderElements);
            table9_0.forEach(row => { table9_0[row.Id] = row; });

            return table9_0;
        } //-- end: createTable9_0 ------------------------------------------------------------------------//

        function createTable9_14() {
            const table9_14 = [
                { Id: 'VERW-DISTR_ONTW_A', Versie: 'le3-1', Value: '30°C', key1: 'Wp_forfaitair', key2: '', key3: 'noWP', tabel9_14: '30', thetaHgem: '28,5', overtemperatuur: '8,5', tabel9_3_tweepijps: '20', tabel9_3_eenpijps: '42,5', HT_LT: 'LT' },
                { Id: 'VERW-DISTR_ONTW_B', Versie: 'le3-1', Value: '35°C', key1: 'Wp_forfaitair', key2: '', key3: 'noWP', tabel9_14: '35', thetaHgem: '32,5', overtemperatuur: '12,5', tabel9_3_tweepijps: '20', tabel9_3_eenpijps: '42,5', HT_LT: 'LT' },
                { Id: 'VERW-DISTR_ONTW_C', Versie: 'le3-1', Value: '40°C', key1: 'Wp_forfaitair', key2: '', key3: 'noWP', tabel9_14: '40', thetaHgem: '37,5', overtemperatuur: '17,5', tabel9_3_tweepijps: '20', tabel9_3_eenpijps: '42,5', HT_LT: 'LT' },
                { Id: 'VERW-DISTR_ONTW_D', Versie: 'le3-1', Value: '45°C', key1: 'Wp_forfaitair', key2: '', key3: 'noWP', tabel9_14: '45', thetaHgem: '42,5', overtemperatuur: '22,5', tabel9_3_tweepijps: '30', tabel9_3_eenpijps: '42,5', HT_LT: 'LT' },
                { Id: 'VERW-DISTR_ONTW_E', Versie: 'le3-1', Value: '50°C', key1: 'Wp_forfaitair', key2: '', key3: 'noWP', tabel9_14: '50', thetaHgem: '46', overtemperatuur: '26', tabel9_3_tweepijps: '30', tabel9_3_eenpijps: '42,5', HT_LT: 'LT' },
                { Id: 'VERW-DISTR_ONTW_F', Versie: 'le3-1', Value: '55°C', key1: 'Wp_forfaitair', key2: '', key3: 'noWP', tabel9_14: '55', thetaHgem: '51', overtemperatuur: '31', tabel9_3_tweepijps: '42,5', tabel9_3_eenpijps: '42,5', HT_LT: 'HT' },
                { Id: 'VERW-DISTR_ONTW_G', Versie: 'le3-1', Value: '65°C', key1: '', key2: '', key3: 'noWP', tabel9_14: '65', thetaHgem: '60', overtemperatuur: '40', tabel9_3_tweepijps: '42,5', tabel9_3_eenpijps: '42,5', HT_LT: 'HT' },
                { Id: 'VERW-DISTR_ONTW_H', Versie: 'le3-1', Value: '75°C', key1: '', key2: '', key3: 'noWP', tabel9_14: '75', thetaHgem: '70', overtemperatuur: '50', tabel9_3_tweepijps: '60', tabel9_3_eenpijps: '60', HT_LT: 'HT' },
                { Id: 'VERW-DISTR_ONTW_I', Versie: 'le3-1', Value: '80°C', key1: '', key2: '', key3: 'noWP', tabel9_14: '80', thetaHgem: '70', overtemperatuur: '50', tabel9_3_tweepijps: '60', tabel9_3_eenpijps: '60', HT_LT: 'HT' },
                { Id: 'VERW-DISTR_ONTW_J', Versie: 'le3-1', Value: '90°C', key1: '', key2: '', key3: 'noWP', tabel9_14: '90', thetaHgem: '80', overtemperatuur: '60', tabel9_3_tweepijps: '60', tabel9_3_eenpijps: '60', HT_LT: 'HT' },
                { Id: 'VERW-DISTR_ONTW_K', Versie: 'le3-1', Value: 'onbekend', key1: '', key2: '', key3: 'noWP', tabel9_14: '90', thetaHgem: '80', overtemperatuur: '60', tabel9_3_tweepijps: '60', tabel9_3_eenpijps: '60', HT_LT: 'HT' },
                { Id: 'VERW-DISTR_ONTW_L', Versie: 'le3-1', Value: 'onbekend', key1: 'Wp_forfaitair', key2: '', key3: '', tabel9_14: '55', thetaHgem: '51', overtemperatuur: '31', tabel9_3_tweepijps: '42,5', tabel9_3_eenpijps: '42,5', HT_LT: 'HT' },

                { Id: 'VERW-DISTR_ONTW_OPP_A', Versie: 'le3-1', Value: '30°C', key1: 'Wp_forfaitair', key2: 'oppervlakteverwarming', key3: 'noWP', tabel9_14: '30', thetaHgem: '28,5', overtemperatuur: '8,5', tabel9_3_tweepijps: '20', tabel9_3_eenpijps: '42,5', HT_LT: 'LT' },
                { Id: 'VERW-DISTR_ONTW_OPP_B', Versie: 'le3-1', Value: '35°C', key1: 'Wp_forfaitair', key2: 'oppervlakteverwarming', key3: 'noWP', tabel9_14: '35', thetaHgem: '32,5', overtemperatuur: '12,5', tabel9_3_tweepijps: '20', tabel9_3_eenpijps: '42,5', HT_LT: 'LT' },
                { Id: 'VERW-DISTR_ONTW_OPP_C', Versie: 'le3-1', Value: '40°C', key1: 'Wp_forfaitair', key2: 'oppervlakteverwarming', key3: 'noWP', tabel9_14: '40', thetaHgem: '37,5', overtemperatuur: '17,5', tabel9_3_tweepijps: '20', tabel9_3_eenpijps: '42,5', HT_LT: 'LT' },
                { Id: 'VERW-DISTR_ONTW_OPP_D', Versie: 'le3-1', Value: '45°C', key1: 'Wp_forfaitair', key2: 'oppervlakteverwarming', key3: 'noWP', tabel9_14: '45', thetaHgem: '42,5', overtemperatuur: '22,5', tabel9_3_tweepijps: '30', tabel9_3_eenpijps: '42,5', HT_LT: 'LT' },
                { Id: 'VERW-DISTR_ONTW_OPP_E', Versie: 'le3-1', Value: '50°C', key1: 'Wp_forfaitair', key2: 'oppervlakteverwarming', key3: 'noWP', tabel9_14: '50', thetaHgem: '46', overtemperatuur: '26', tabel9_3_tweepijps: '30', tabel9_3_eenpijps: '42,5', HT_LT: 'LT' },
                { Id: 'VERW-DISTR_ONTW_OPP_F', Versie: 'le3-1', Value: '55°C', key1: 'Wp_forfaitair', key2: 'oppervlakteverwarming', key3: 'noWP', tabel9_14: '55', thetaHgem: '51', overtemperatuur: '31', tabel9_3_tweepijps: '42,5', tabel9_3_eenpijps: '42,5', HT_LT: 'HT' },
                { Id: 'VERW-DISTR_ONTW_OPP_G', Versie: 'le3-1', Value: '65°C', key1: '', key2: 'oppervlakteverwarming', key3: 'noWP', tabel9_14: '65', thetaHgem: '60', overtemperatuur: '40', tabel9_3_tweepijps: '42,5', tabel9_3_eenpijps: '42,5', HT_LT: 'HT' },
                { Id: 'VERW-DISTR_ONTW_OPP_H', Versie: 'le3-1', Value: '75°C', key1: '', key2: 'oppervlakteverwarming', key3: 'noWP', tabel9_14: '75', thetaHgem: '70', overtemperatuur: '50', tabel9_3_tweepijps: '60', tabel9_3_eenpijps: '60', HT_LT: 'HT' },
                { Id: 'VERW-DISTR_ONTW_OPP_I', Versie: 'le3-1', Value: '80°C', key1: '', key2: 'oppervlakteverwarming', key3: 'noWP', tabel9_14: '80', thetaHgem: '70', overtemperatuur: '50', tabel9_3_tweepijps: '60', tabel9_3_eenpijps: '60', HT_LT: 'HT' },
                { Id: 'VERW-DISTR_ONTW_OPP_J', Versie: 'le3-1', Value: '90°C', key1: '', key2: 'oppervlakteverwarming', key3: 'noWP', tabel9_14: '90', thetaHgem: '80', overtemperatuur: '60', tabel9_3_tweepijps: '60', tabel9_3_eenpijps: '60', HT_LT: 'HT' },
                { Id: 'VERW-DISTR_ONTW_OPP_K', Versie: 'le3-1', Value: 'onbekend', key1: '', key2: 'oppervlakteverwarming', key3: 'noWP', tabel9_14: '45', thetaHgem: '42,5', overtemperatuur: '22,5', tabel9_3_tweepijps: '30', tabel9_3_eenpijps: '42,5', HT_LT: 'LT' },
                { Id: 'VERW-DISTR_ONTW_OPP_L', Versie: 'le3-1', Value: 'onbekend', key1: 'Wp_forfaitair', key2: 'oppervlakteverwarming', key3: '', tabel9_14: '45', thetaHgem: '42,5', overtemperatuur: '22,5', tabel9_3_tweepijps: '30', tabel9_3_eenpijps: '42,5', HT_LT: 'LT' },

                { Id: 'VERW-DISTR_ONTW_GE32_A', Versie: 'ge3-2', Value: '30°C', key1: 'Wp_forfaitair_9.28_bronlt40', key2: '', key3: '', tabel9_14: '30', thetaHgem: '28,5', overtemperatuur: '8,5', tabel9_3_tweepijps: '20', tabel9_3_eenpijps: '42,5', HT_LT: 'LT' },
                { Id: 'VERW-DISTR_ONTW_GE32_B', Versie: 'ge3-2', Value: '35°C', key1: 'Wp_forfaitair_9.28_bronlt40', key2: '', key3: '', tabel9_14: '35', thetaHgem: '32,5', overtemperatuur: '12,5', tabel9_3_tweepijps: '20', tabel9_3_eenpijps: '42,5', HT_LT: 'LT' },
                { Id: 'VERW-DISTR_ONTW_GE32_C', Versie: 'ge3-2', Value: '40°C', key1: 'Wp_forfaitair_9.28_bronlt40', key2: '', key3: '', tabel9_14: '40', thetaHgem: '37,5', overtemperatuur: '17,5', tabel9_3_tweepijps: '20', tabel9_3_eenpijps: '42,5', HT_LT: 'LT' },
                { Id: 'VERW-DISTR_ONTW_GE32_D', Versie: 'ge3-2', Value: '45°C', key1: 'Wp_forfaitair_9.28', key2: '', key3: '', tabel9_14: '45', thetaHgem: '42,5', overtemperatuur: '22,5', tabel9_3_tweepijps: '30', tabel9_3_eenpijps: '42,5', HT_LT: 'LT' },
                { Id: 'VERW-DISTR_ONTW_GE32_E', Versie: 'ge3-2', Value: '50°C', key1: 'Wp_forfaitair_9.28', key2: '', key3: '', tabel9_14: '50', thetaHgem: '46', overtemperatuur: '26', tabel9_3_tweepijps: '30', tabel9_3_eenpijps: '42,5', HT_LT: 'LT' },
                { Id: 'VERW-DISTR_ONTW_GE32_F', Versie: 'ge3-2', Value: '55°C', key1: 'Wp_forfaitair_9.28', key2: '', key3: '', tabel9_14: '55', thetaHgem: '51', overtemperatuur: '31', tabel9_3_tweepijps: '42,5', tabel9_3_eenpijps: '42,5', HT_LT: 'HT' },
                { Id: 'VERW-DISTR_ONTW_GE32_G', Versie: 'ge3-2', Value: '60°C', key1: 'Wp_forfaitair', key2: '', key3: '', tabel9_14: '60', thetaHgem: '55', overtemperatuur: '35', tabel9_3_tweepijps: '42,5', tabel9_3_eenpijps: '42,5', HT_LT: 'HT' },
                { Id: 'VERW-DISTR_ONTW_GE32_H', Versie: 'ge3-2', Value: '65°C', key1: 'Wp_forfaitair', key2: '', key3: '', tabel9_14: '65', thetaHgem: '60', overtemperatuur: '40', tabel9_3_tweepijps: '42,5', tabel9_3_eenpijps: '42,5', HT_LT: 'HT' },
                { Id: 'VERW-DISTR_ONTW_GE32_I', Versie: 'ge3-2', Value: '70°C', key1: 'Wp_forfaitair', key2: '', key3: '', tabel9_14: '70', thetaHgem: '65', overtemperatuur: '45', tabel9_3_tweepijps: '60', tabel9_3_eenpijps: '42,5', HT_LT: 'HT' },
                { Id: 'VERW-DISTR_ONTW_GE32_J', Versie: 'ge3-2', Value: '75°C', key1: '', key2: '', key3: '', tabel9_14: '75', thetaHgem: '70', overtemperatuur: '50', tabel9_3_tweepijps: '60', tabel9_3_eenpijps: '60', HT_LT: 'HT' },
                { Id: 'VERW-DISTR_ONTW_GE32_K', Versie: 'ge3-2', Value: '80°C', key1: '', key2: '', key3: '', tabel9_14: '80', thetaHgem: '70', overtemperatuur: '50', tabel9_3_tweepijps: '60', tabel9_3_eenpijps: '60', HT_LT: 'HT' },
                { Id: 'VERW-DISTR_ONTW_GE32_L', Versie: 'ge3-2', Value: '90°C', key1: '', key2: '', key3: '', tabel9_14: '90', thetaHgem: '80', overtemperatuur: '60', tabel9_3_tweepijps: '60', tabel9_3_eenpijps: '60', HT_LT: 'HT' },
                { Id: 'VERW-DISTR_ONTW_GE32_M', Versie: 'ge3-2', Value: 'onbekend', key1: 'Wp_forfaitair_9.28', key2: '', key3: '', tabel9_14: '', thetaHgem: '', overtemperatuur: '', tabel9_3_tweepijps: '60', tabel9_3_eenpijps: '60', HT_LT: 'HT' },

            ];

            const ontwerpAanvoertemperatuurCodedValues = ntabuilding.properties["VERW-DISTR_ONTW"].Domain.Codes;

            table9_14.forEach(function (row, index) {
                const codedValue = ontwerpAanvoertemperatuurCodedValues.find(function (x) { return x.Id === row.Id; });
                if (codedValue !== undefined) {
                    row.Value = codedValue.Value;
                    row.Order = codedValue.Order;
                    row.FilterValue2 = codedValue.FilterValue2;
                    row.FilterValue3 = codedValue.FilterValue3;
                }
            });

            return table9_14;
        } //-- end: createTable9_14 ------------------------------------------------------------------------//

        function createTable9_8() {
            const table9_8 = [
                { Id: 'VERW-AFG_TYPE_STR_1', Value: '', key1: '6-8;>8', key3: '', key2: '', key4: '', key5: '', key6: '', thetastr: '', theta_komma_str: '1', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_2', Value: '', key1: '4-6', key3: '', key2: 'HT', key4: '', key5: '', key6: '', thetastr: '', theta_komma_str: '1', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_3', Value: '', key1: '4-6', key3: '', key2: 'LT', key4: '', key5: '', key6: '', thetastr: '', theta_komma_str: '0,35', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_5', Value: '', key1: '6-8;>8', key3: '', key2: '', key4: '', key5: '', key6: '', thetastr: '', theta_komma_str: '0,6', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_6', Value: '', key1: '4-6', key3: '', key2: 'HT', key4: '', key5: '', key6: '', thetastr: '', theta_komma_str: '0,6', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_8', Value: '', key1: '4-6', key3: '', key2: 'LT', key4: '', key5: '', key6: '', thetastr: '', theta_komma_str: '0,35', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_9', Value: '', key1: '4-6;6-8;>8', key3: '', key2: '', key4: '', key5: '', key6: '', thetastr: '', theta_komma_str: '1', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_10', Value: '', key1: '4-6;6-8;>8', key3: '', key2: '', key4: '', key5: '', key6: '', thetastr: '', theta_komma_str: '0,35', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_11', Value: '', key1: '4-6;6-8;>8', key3: '', key2: '', key4: '', key5: '', key6: '', thetastr: '', theta_komma_str: '0,25', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_12', Value: '', key1: '4-6;6-8;>8', key3: '', key2: '', key4: '', key5: '', key6: '', thetastr: '', theta_komma_str: '0,35', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_13', Value: '', key1: '4-6;6-8;>8', key3: '', key2: '', key4: '', key5: '', key6: '', thetastr: '', theta_komma_str: '0,2', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_14', Value: '', key1: '4-6;6-8;>8', key3: '', key2: '', key4: '', key5: '', key6: 'geen watergedragen distributiesysteem aanwezig', thetastr: '', theta_komma_str: '0,2', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_15', Value: '', key1: '4-6;6-8;>8', key3: '', key2: '', key4: '', key5: '', key6: 'geen watergedragen distributiesysteem aanwezig', thetastr: '', theta_komma_str: '0,4', delta_theta_emb: '0,5' },
                { Id: 'VERW-AFG_TYPE_STR_16', Value: '', key1: '<4', key3: 'lokaal', key2: '', key4: 'eenpijpssysteem', key5: '', key6: '', thetastr: '1,6', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_17', Value: '', key1: '<4', key3: 'lokaal', key2: '', key4: 'tweepijpssysteem', key5: '', key6: '', thetastr: '1,2', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_18', Value: '', key1: '<4', key3: 'lokaal', key2: '', key4: 'eenpijpssysteem gerenoveerd', key5: '', key6: '', thetastr: '1,2', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_19', Value: '', key1: '<4', key3: 'lokaal', key2: '', key4: 'onbekend eenpijps- of tweepijpssysteem', key5: '', key6: '', thetastr: '1,6', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_20', Value: '', key1: '<4', key3: 'lokaal', key2: '', key4: 'geen watergedragen distributiesysteem aanwezig', key5: '', key6: '', thetastr: '1,6', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_21', Value: '', key1: '<4', key3: 'ventilator', key2: '', key4: '', key5: '', key6: '', thetastr: '0', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_22', Value: '', key1: '<4', key3: 'mechanisch', key2: '', key4: '', key5: '', key6: '', thetastr: '0,2', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_23', Value: '', key1: '<4', key3: '', key2: '', key4: 'eenpijpssysteem', key5: '42,5', key6: '', thetastr: '1,2', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_24', Value: '', key1: '<4', key3: '', key2: '', key4: 'eenpijpssysteem', key5: '60', key6: '', thetastr: '1,6', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_25', Value: '', key1: '<4', key3: '', key2: '', key4: 'tweepijpssysteem', key5: '20', key6: '', thetastr: '0,4', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_26', Value: '', key1: '<4', key3: '', key2: '', key4: 'tweepijpssysteem', key5: '30', key6: '', thetastr: '0,5', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_27', Value: '', key1: '<4', key3: '', key2: '', key4: 'tweepijpssysteem', key5: '42,5', key6: '', thetastr: '0,7', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_28', Value: '', key1: '<4', key3: '', key2: '', key4: 'tweepijpssysteem', key5: '60', key6: '', thetastr: '1,2', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_29', Value: '', key1: '<4', key3: '', key2: '', key4: 'eenpijpssysteem gerenoveerd', key5: '20', key6: '', thetastr: '0,4', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_30', Value: '', key1: '<4', key3: '', key2: '', key4: 'eenpijpssysteem gerenoveerd', key5: '30', key6: '', thetastr: '0,5', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_31', Value: '', key1: '<4', key3: '', key2: '', key4: 'eenpijpssysteem gerenoveerd', key5: '42,5', key6: '', thetastr: '0,7', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_32', Value: '', key1: '<4', key3: '', key2: '', key4: 'eenpijpssysteem gerenoveerd', key5: '60', key6: '', thetastr: '1,2', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_33', Value: '', key1: '<4', key3: '', key2: '', key4: 'eenpijps- of tweepijpssysteem onbekend', key5: '42,5', key6: '', thetastr: '1,2', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_34', Value: '', key1: '<4', key3: '', key2: '', key4: 'eenpijps- of tweepijpssysteem onbekend', key5: '60', key6: '', thetastr: '1,6', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_35', Value: '', key1: '<4', key3: '', key2: '', key4: '', key5: '', key6: '', thetastr: '0,2', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_36', Value: '', key1: '<4', key3: 'natuurlijk', key2: '', key4: 'eenpijpssysteem', key5: '42,5', key6: '', thetastr: '1,2', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_37', Value: '', key1: '<4', key3: 'natuurlijk', key2: '', key4: 'eenpijpssysteem', key5: '60', key6: '', thetastr: '1,6', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_38', Value: '', key1: '<4', key3: 'natuurlijk', key2: '', key4: 'tweepijpssysteem', key5: '20', key6: '', thetastr: '0,4', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_39', Value: '', key1: '<4', key3: 'natuurlijk', key2: '', key4: 'tweepijpssysteem', key5: '30', key6: '', thetastr: '0,5', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_40', Value: '', key1: '<4', key3: 'natuurlijk', key2: '', key4: 'tweepijpssysteem', key5: '42,5', key6: '', thetastr: '0,7', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_41', Value: '', key1: '<4', key3: 'natuurlijk', key2: '', key4: 'tweepijpssysteem', key5: '60', key6: '', thetastr: '1,2', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_42', Value: '', key1: '<4', key3: 'natuurlijk', key2: '', key4: 'eenpijpssysteem gerenoveerd', key5: '20', key6: '', thetastr: '0,4', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_43', Value: '', key1: '<4', key3: 'natuurlijk', key2: '', key4: 'eenpijpssysteem gerenoveerd', key5: '30', key6: '', thetastr: '0,5', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_44', Value: '', key1: '<4', key3: 'natuurlijk', key2: '', key4: 'eenpijpssysteem gerenoveerd', key5: '42,5', key6: '', thetastr: '0,7', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_45', Value: '', key1: '<4', key3: 'natuurlijk', key2: '', key4: 'eenpijpssysteem gerenoveerd', key5: '60', key6: '', thetastr: '1,2', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_46', Value: '', key1: '<4', key3: 'natuurlijk', key2: '', key4: 'eenpijps- of tweepijpssysteem onbekend', key5: '42,5', key6: '', thetastr: '1,2', theta_komma_str: '', delta_theta_emb: '0' },
                { Id: 'VERW-AFG_TYPE_STR_47', Value: '', key1: '<4', key3: 'natuurlijk', key2: '', key4: 'eenpijps- of tweepijpssysteem onbekend', key5: '60', key6: '', thetastr: '1,6', theta_komma_str: '', delta_theta_emb: '0' },
            ];

            const emissionsystemCodedValues = ntabuilding.properties["VERW-AFG_TYPE_STR"].Domain.Codes;

            table9_8.forEach(function (row, index) {
                const codedValue = emissionsystemCodedValues.find(function (x) { return x.Id === row.Id; });
                if (codedValue !== undefined) {
                    row.Value = codedValue.Value;
                    row.Order = codedValue.Order;
                }
            });

            return table9_8;
        } //-- end: createTable9_8 ------------------------------------------------------------------------//

        function createTable9_3_1() {
            const table9_3_1 = [
                { Id: 'VERW-AFG_TYPE_RUIM_1', Value: '', key0: 'le3.2', key1: 'str', key2: 'WU', key3: 'forfaitair', key4: '', key5: '', key6: '<4;4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '0' },
                { Id: 'VERW-AFG_TYPE_RUIM_2', Value: '', key0: 'le3.2', key1: 'str', key2: 'WU', key3: 'forfaitair', key4: '', key5: '', key6: '<4;4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '-0,5' },
                { Id: 'VERW-AFG_TYPE_RUIM_3', Value: '', key0: 'le3.2', key1: 'str', key2: 'WU', key3: 'forfaitair', key4: '', key5: '', key6: '<4;4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '-1' },
                { Id: 'VERW-AFG_TYPE_RUIM_4', Value: '', key0: 'le3.2', key1: 'str', key2: 'WU', key3: 'forfaitair', key4: '', key5: '', key6: '<4;4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '-1,2' },
                { Id: 'VERW-AFG_TYPE_RUIM_5', Value: '', key0: 'le3.2', key1: 'str', key2: 'WU', key3: 'forfaitair', key4: '', key5: '', key6: '<4;4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '0' },
                { Id: 'VERW-AFG_TYPE_RUIM_6', Value: '', key0: 'le3.2', key1: 'str', key2: 'WU', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: '', key5: '', key6: '<4', delta_ctr: '1,5', delta_roomaut: '0' },
                { Id: 'VERW-AFG_TYPE_RUIM_7', Value: '', key0: 'le3.2', key1: 'str', key2: 'WU', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: '', key5: '', key6: '4-6;6-8;>8', delta_ctr: '0,7', delta_roomaut: '0' },
                { Id: 'VERW-AFG_TYPE_RUIM_8', Value: '', key0: 'le3.2', key1: 'str', key2: 'WU', key3: 'forfaitair', key4: '', key5: '', key6: '<4;4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '0' },
                { Id: 'VERW-AFG_TYPE_RUIM_9', Value: '', key0: 'le3.2', key1: 'str', key2: 'WU', key3: 'eigen waarde', key4: '', key5: '', key6: '<4;4-6;6-8;>8', delta_ctr: '', delta_roomaut: '' },

                { Id: 'VERW-AFG_TYPE_RUIM_10', Value: '', key0: 'le3.2', key1: 'opp', key2: 'WU', key3: 'forfaitair', key4: '', key5: '', key6: '<4;4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '0' },
                { Id: 'VERW-AFG_TYPE_RUIM_11', Value: '', key0: 'le3.2', key1: 'opp', key2: 'WU', key3: 'forfaitair', key4: '', key5: '', key6: '<4;4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '-0,5' },
                { Id: 'VERW-AFG_TYPE_RUIM_12', Value: '', key0: 'le3.2', key1: 'opp', key2: 'WU', key3: 'forfaitair', key4: '', key5: '', key6: '<4;4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '-1' },
                { Id: 'VERW-AFG_TYPE_RUIM_13', Value: '', key0: 'le3.2', key1: 'opp', key2: 'WU', key3: 'forfaitair', key4: '', key5: '', key6: '<4;4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '-1,2' },
                { Id: 'VERW-AFG_TYPE_RUIM_14', Value: '', key0: 'le3.2', key1: 'opp', key2: 'WU', key3: 'forfaitair', key4: '', key5: '', key6: '<4;4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '0' },
                { Id: 'VERW-AFG_TYPE_RUIM_15', Value: '', key0: 'le3.2', key1: 'opp', key2: 'WU', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: '', key5: '', key6: '<4', delta_ctr: '1,5', delta_roomaut: '0' },
                { Id: 'VERW-AFG_TYPE_RUIM_16', Value: '', key0: 'le3.2', key1: 'opp', key2: 'WU', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: '', key5: '', key6: '4-6;6-8;>8', delta_ctr: '0,7', delta_roomaut: '0' },
                { Id: 'VERW-AFG_TYPE_RUIM_17', Value: '', key0: 'le3.2', key1: 'opp', key2: 'WU', key3: 'forfaitair', key4: '', key5: '', key6: '4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '0' },
                { Id: 'VERW-AFG_TYPE_RUIM_18', Value: '', key0: 'le3.2', key1: 'opp', key2: 'WU', key3: 'eigen waarde', key4: '', key5: '', key6: '<4;4-6;6-8;>8', delta_ctr: '', delta_roomaut: '' },

                { Id: 'VERW-AFG_TYPE_RUIM_19', Value: '', key0: 'le3.2', key1: 'lvne', key2: 'W', key3: 'forfaitair', key4: '', key5: '', key6: '<4;4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '0' },
                { Id: 'VERW-AFG_TYPE_RUIM_20', Value: '', key0: 'le3.2', key1: 'lvne', key2: 'W', key3: 'forfaitair', key4: '', key5: '', key6: '<4;4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '-0,5' },
                { Id: 'VERW-AFG_TYPE_RUIM_21', Value: '', key0: 'le3.2', key1: 'lvne', key2: 'W', key3: 'forfaitair', key4: '', key5: '', key6: '<4;4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '-1' },
                { Id: 'VERW-AFG_TYPE_RUIM_22', Value: '', key0: 'le3.2', key1: 'lvne', key2: 'W', key3: 'forfaitair', key4: '', key5: '', key6: '<4;4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '-1,2' },
                { Id: 'VERW-AFG_TYPE_RUIM_23', Value: '', key0: 'le3.2', key1: 'lvne', key2: 'W', key3: 'forfaitair', key4: '', key5: '', key6: '<4;4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '0' },
                { Id: 'VERW-AFG_TYPE_RUIM_24', Value: '', key0: 'le3.2', key1: 'lvne', key2: 'W', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: '', key5: '', key6: '<4', delta_ctr: '1,5', delta_roomaut: '0' },
                { Id: 'VERW-AFG_TYPE_RUIM_25', Value: '', key0: 'le3.2', key1: 'lvne', key2: 'W', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: '', key5: '', key6: '4-6;6-8;>8', delta_ctr: '0,7', delta_roomaut: '0' },
                { Id: 'VERW-AFG_TYPE_RUIM_26', Value: '', key0: 'le3.2', key1: 'lvne', key2: 'W', key3: 'forfaitair', key4: '', key5: '', key6: '4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '0' },
                { Id: 'VERW-AFG_TYPE_RUIM_27', Value: '', key0: 'le3.2', key1: 'lvne', key2: 'W', key3: 'eigen waarde', key4: '', key5: '', key6: '<4;4-6;6-8;>8', delta_ctr: '', delta_roomaut: '' },

                { Id: 'VERW-AFG_TYPE_RUIM_28', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: 'buitenwandgebied', key5: '', key6: '<4', delta_ctr: '1,2', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_29', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: 'buitenwandgebied', key5: '', key6: '<4', delta_ctr: '1,1', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_30', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: 'buitenwandgebied', key5: '', key6: '<4', delta_ctr: '1,1', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_31', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: 'buitenwandgebied', key5: '', key6: '<4', delta_ctr: '0,7', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_32', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'forfaitair', key4: 'buitenwandgebied', key5: '', key6: '<4', delta_ctr: '1,2', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_33', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'forfaitair', key4: 'buitenwandgebied', key5: '', key6: '<4', delta_ctr: '1,2', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_34', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'forfaitair', key4: 'buitenwandgebied', key5: '', key6: '<4', delta_ctr: '1,1', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_35', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'forfaitair', key4: 'buitenwandgebied', key5: '', key6: '<4', delta_ctr: '1,1', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_36', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'eigen waarde', key4: 'buitenwandgebied', key5: '', key6: '<4', delta_ctr: '', delta_roomaut: '' },

                { Id: 'VERW-AFG_TYPE_RUIM_37', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: 'binnenwandgebied', key5: '', key6: '<4', delta_ctr: '1,6', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_38', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: 'binnenwandgebied', key5: '', key6: '<4', delta_ctr: '1,5', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_39', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: 'binnenwandgebied', key5: '', key6: '<4', delta_ctr: '1,5', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_40', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: 'binnenwandgebied', key5: '', key6: '<4', delta_ctr: '1,1', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_41', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'forfaitair', key4: 'binnenwandgebied', key5: '', key6: '<4', delta_ctr: '1,6', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_42', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'forfaitair', key4: 'binnenwandgebied', key5: '', key6: '<4', delta_ctr: '1,5', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_43', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'forfaitair', key4: 'binnenwandgebied', key5: '', key6: '<4', delta_ctr: '1,5', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_44', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'forfaitair', key4: 'binnenwandgebied', key5: '', key6: '<4', delta_ctr: '3,1', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_45', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'eigen waarde', key4: 'binnenwandgebied', key5: '', key6: '<4', delta_ctr: '', delta_roomaut: '' },

                { Id: 'VERW-AFG_TYPE_RUIM_46', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: 'binnenwandgebied', key5: '', key6: '4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_47', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: 'binnenwandgebied', key5: '', key6: '4-6;6-8;>8', delta_ctr: '0,7', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_48', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'forfaitair', key4: 'binnenwandgebied', key5: '', key6: '4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_52', Value: '', key0: 'le3.2', key1: 'lve', key2: 'WU', key3: 'eigen waarde', key4: '', key5: '', key6: '4-6;6-8;>8', delta_ctr: '', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_53', Value: '', key0: 'le3.2', key1: 'lvne', key2: 'U', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: '', key5: 'naverwarming van ingaande lucht', key6: '<4', delta_ctr: '1,3', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_54', Value: '', key0: 'le3.2', key1: 'lvne', key2: 'U', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: '', key5: 'naverwarming van ingaande lucht', key6: '<4', delta_ctr: '1', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_55', Value: '', key0: 'le3.2', key1: 'lvne', key2: 'U', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: '', key5: 'naverwarming van ingaande lucht', key6: '<4', delta_ctr: '1,5', delta_roomaut: '' },

                { Id: 'VERW-AFG_TYPE_RUIM_56', Value: '', key0: 'le3.2', key1: 'lvne', key2: 'U', key3: 'forfaitair', key4: '', key5: 'naverwarming van ingaande lucht', key6: '<4', delta_ctr: '1,8', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_57', Value: '', key0: 'le3.2', key1: 'lvne', key2: 'U', key3: 'forfaitair', key4: '', key5: 'naverwarming van ingaande lucht', key6: '<4', delta_ctr: '1,2', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_58', Value: '', key0: 'le3.2', key1: 'lvne', key2: 'U', key3: 'forfaitair', key4: '', key5: 'naverwarming van ingaande lucht', key6: '<4', delta_ctr: '1,9', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_59', Value: '', key0: 'le3.2', key1: 'lvne', key2: 'U', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: '', key5: 'recirculatie luchtverwarming', key6: '<4', delta_ctr: '0,7', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_60', Value: '', key0: 'le3.2', key1: 'lvne', key2: 'U', key3: 'forfaitair', key4: '', key5: 'recirculatie luchtverwarming', key6: '<4', delta_ctr: '1,1', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_61', Value: '', key0: 'le3.2', key1: 'lvne', key2: 'U', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: '', key5: '', key6: '4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_62', Value: '', key0: 'le3.2', key1: 'lvne', key2: 'U', key3: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', key4: '', key5: '', key6: '4-6;6-8;>8', delta_ctr: '0,7', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_63', Value: '', key0: 'le3.2', key1: 'lvne', key2: 'U', key3: 'forfaitair', key4: '', key5: '', key6: '4-6;6-8;>8', delta_ctr: '2,5', delta_roomaut: '' },

                { Id: 'VERW-AFG_TYPE_RUIM_64', Value: '', key0: 'gt3.2', key1: '',     key2: '', key3: '', key4: '', key5: '', key6: '', delta_ctr: '', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_65', Value: '', key0: 'gt3.2', key1: '',     key2: '', key3: '', key4: '', key5: '', key6: '', delta_ctr: '', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_66', Value: '', key0: 'gt3.2', key1: '',     key2: '', key3: '', key4: '', key5: '', key6: '', delta_ctr: '', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_67', Value: '', key0: 'gt3.2', key1: '',     key2: '', key3: '', key4: '', key5: '', key6: '', delta_ctr: '', delta_roomaut: '' },
                { Id: 'VERW-AFG_TYPE_RUIM_68', Value: '', key0: 'gt3.2', key1: '',     key2: '', key3: '', key4: '', key5: '', key6: '', delta_ctr: '', delta_roomaut: '' },
            ];

            const temperatureRegulationCodedValues = ntabuilding.properties["VERW-AFG_TYPE_RUIM"].Domain.Codes;

            table9_3_1.forEach(function (row, index) {
                const codedValue = temperatureRegulationCodedValues.find(function (x) { return x.Id === row.Id; });
                if (codedValue !== undefined) {
                    row.Value = codedValue.Value;
                    row.Order = codedValue.Order;
                }
            });

            return table9_3_1;
        } //-- end: createTable9_3_1 ------------------------------------------------------------------------//

        function createTable9_3_5() {
            const table9_3_5 = [
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_1', InsulationId: 'VERW-AFG_ISO_OPP_1', key1: '<4', key2: 'horizontaal', key3: '', key4: '', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_2', InsulationId: 'VERW-AFG_ISO_OPP_2', key1: '<4', key2: 'horizontaal', key3: '', key4: '', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '0,5', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_3', InsulationId: 'VERW-AFG_ISO_OPP_3', key1: '<4', key2: 'horizontaal', key3: '', key4: '', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '0,1', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_4', InsulationId: 'VERW-AFG_ISO_OPP_4', key1: '<4', key2: 'horizontaal', key3: '', key4: '', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },

                { AreaheatingId: 'VERW-AFG_TYPE_OPP_5', InsulationId: 'VERW-AFG_ISO_OPP_5', key1: '<4', key2: 'horizontaal', key3: '', key4: 'natuurlijk', delta_theta_str: '0', delta_theta_emb1: '0,4', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_6', InsulationId: 'VERW-AFG_ISO_OPP_6', key1: '<4', key2: 'horizontaal', key3: '', key4: 'natuurlijk', delta_theta_str: '0', delta_theta_emb1: '0,4', delta_theta_emb2: '0,5', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_7', InsulationId: 'VERW-AFG_ISO_OPP_7', key1: '<4', key2: 'horizontaal', key3: '', key4: 'natuurlijk', delta_theta_str: '0', delta_theta_emb1: '0,4', delta_theta_emb2: '0,1', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_8', InsulationId: 'VERW-AFG_ISO_OPP_8', key1: '<4', key2: 'horizontaal', key3: '', key4: 'natuurlijk', delta_theta_str: '0', delta_theta_emb1: '0,4', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },

                { AreaheatingId: 'VERW-AFG_TYPE_OPP_9', InsulationId: 'VERW-AFG_ISO_OPP_9', key1: '<4', key2: 'horizontaal', key3: '', key4: 'beide', delta_theta_str: '0', delta_theta_emb1: '0,4', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_10', InsulationId: 'VERW-AFG_ISO_OPP_10', key1: '<4', key2: 'horizontaal', key3: '', key4: 'beide', delta_theta_str: '0', delta_theta_emb1: '0,4', delta_theta_emb2: '0,5', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_11', InsulationId: 'VERW-AFG_ISO_OPP_11', key1: '<4', key2: 'horizontaal', key3: '', key4: 'beide', delta_theta_str: '0', delta_theta_emb1: '0,4', delta_theta_emb2: '0,1', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_12', InsulationId: 'VERW-AFG_ISO_OPP_12', key1: '<4', key2: 'horizontaal', key3: '', key4: 'beide', delta_theta_str: '0', delta_theta_emb1: '0,4', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },

                { AreaheatingId: 'VERW-AFG_TYPE_OPP_13', InsulationId: 'VERW-AFG_ISO_OPP_13', key1: '<4', key2: 'horizontaal', key3: '', key4: 'beide', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_14', InsulationId: 'VERW-AFG_ISO_OPP_14', key1: '<4', key2: 'horizontaal', key3: '', key4: 'beide', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '0,5', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_15', InsulationId: 'VERW-AFG_ISO_OPP_15', key1: '<4', key2: 'horizontaal', key3: '', key4: 'beide', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '0,1', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_16', InsulationId: 'VERW-AFG_ISO_OPP_16', key1: '<4', key2: 'horizontaal', key3: '', key4: 'beide', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },

                { AreaheatingId: 'VERW-AFG_TYPE_OPP_17', InsulationId: 'VERW-AFG_ISO_OPP_17', key1: '<4', key2: 'horizontaal', key3: '', key4: 'mechanisch', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_18', InsulationId: 'VERW-AFG_ISO_OPP_18', key1: '<4', key2: 'horizontaal', key3: '', key4: 'mechanisch', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '0,5', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_19', InsulationId: 'VERW-AFG_ISO_OPP_19', key1: '<4', key2: 'horizontaal', key3: '', key4: 'mechanisch', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '0,1', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_20', InsulationId: 'VERW-AFG_ISO_OPP_20', key1: '<4', key2: 'horizontaal', key3: '', key4: 'mechanisch', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },

                { AreaheatingId: 'VERW-AFG_TYPE_OPP_21', InsulationId: 'VERW-AFG_ISO_OPP_21', key1: '<4', key2: '', key3: '', key4: '', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },

                { AreaheatingId: 'VERW-AFG_TYPE_OPP_22', InsulationId: 'VERW-AFG_ISO_OPP_22', key1: '4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: '', delta_theta_str: '0', delta_theta_emb1: '', delta_theta_emb2: '', delta_theta_emb3: '1,4', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_23', InsulationId: 'VERW-AFG_ISO_OPP_23', key1: '4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: '', delta_theta_str: '0', delta_theta_emb1: '', delta_theta_emb2: '', delta_theta_emb3: '1,9', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_24', InsulationId: 'VERW-AFG_ISO_OPP_24', key1: '4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: '', delta_theta_str: '0', delta_theta_emb1: '', delta_theta_emb2: '', delta_theta_emb3: '0,5', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_25', InsulationId: 'VERW-AFG_ISO_OPP_25', key1: '4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: '', delta_theta_str: '0', delta_theta_emb1: '', delta_theta_emb2: '', delta_theta_emb3: '1', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_26', InsulationId: 'VERW-AFG_ISO_OPP_26', key1: '4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: '', delta_theta_str: '0', delta_theta_emb1: '', delta_theta_emb2: '', delta_theta_emb3: '0', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_27', InsulationId: 'VERW-AFG_ISO_OPP_27', key1: '4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: '', delta_theta_str: '0', delta_theta_emb1: '', delta_theta_emb2: '', delta_theta_emb3: '1,9', AreaheatingValue: '', InsulationValue: '' },

                { AreaheatingId: 'VERW-AFG_TYPE_OPP_28', InsulationId: 'VERW-AFG_ISO_OPP_28', key1: '<4;4-6;6-8;>8', key2: '', key3: '', key4: 'natuurlijk', delta_theta_str: '0,4', delta_theta_emb1: '0,2', delta_theta_emb2: '', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_29', InsulationId: 'VERW-AFG_ISO_OPP_29', key1: '<4;4-6;6-8;>8', key2: '', key3: '', key4: 'beide', delta_theta_str: '0,4', delta_theta_emb1: '0,2', delta_theta_emb2: '', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_30', InsulationId: 'VERW-AFG_ISO_OPP_30', key1: '<4;4-6;6-8;>8', key2: '', key3: '', key4: 'beide', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_31', InsulationId: 'VERW-AFG_ISO_OPP_31', key1: '<4;4-6;6-8;>8', key2: '', key3: '', key4: 'mechanisch', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },

                { AreaheatingId: 'VERW-AFG_TYPE_OPP_32', InsulationId: 'VERW-AFG_ISO_OPP_32', key1: '<4;4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: 'natuurlijk', delta_theta_str: '0,7', delta_theta_emb1: '0,7', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_33', InsulationId: 'VERW-AFG_ISO_OPP_33', key1: '<4;4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: 'natuurlijk', delta_theta_str: '0,7', delta_theta_emb1: '0,7', delta_theta_emb2: '0,5', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_34', InsulationId: 'VERW-AFG_ISO_OPP_34', key1: '<4;4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: 'natuurlijk', delta_theta_str: '0,7', delta_theta_emb1: '0,7', delta_theta_emb2: '0,1', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },

                { AreaheatingId: 'VERW-AFG_TYPE_OPP_35', InsulationId: 'VERW-AFG_ISO_OPP_35', key1: '<4;4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: 'beide', delta_theta_str: '0,7', delta_theta_emb1: '0,7', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_36', InsulationId: 'VERW-AFG_ISO_OPP_36', key1: '<4;4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: 'beide', delta_theta_str: '0,7', delta_theta_emb1: '0,7', delta_theta_emb2: '0,5', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_37', InsulationId: 'VERW-AFG_ISO_OPP_37', key1: '<4;4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: 'beide', delta_theta_str: '0,7', delta_theta_emb1: '0,7', delta_theta_emb2: '0,1', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },

                { AreaheatingId: 'VERW-AFG_TYPE_OPP_38', InsulationId: 'VERW-AFG_ISO_OPP_38', key1: '<4;4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: 'beide', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_39', InsulationId: 'VERW-AFG_ISO_OPP_39', key1: '<4;4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: 'beide', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '0,5', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_40', InsulationId: 'VERW-AFG_ISO_OPP_40', key1: '<4;4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: 'beide', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '0,1', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },

                { AreaheatingId: 'VERW-AFG_TYPE_OPP_41', InsulationId: 'VERW-AFG_ISO_OPP_41', key1: '<4;4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: 'mechanisch', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_42', InsulationId: 'VERW-AFG_ISO_OPP_42', key1: '<4;4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: 'mechanisch', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '0,5', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_43', InsulationId: 'VERW-AFG_ISO_OPP_43', key1: '<4;4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: 'mechanisch', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '0,1', delta_theta_emb3: '', AreaheatingValue: '', InsulationValue: '' },


                { AreaheatingId: 'VERW-AFG_TYPE_OPP_44', InsulationId: 'VERW-AFG_ISO_OPP_44', key1: '<4;4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: 'natuurlijk', delta_theta_str: '0,7', delta_theta_emb1: '0,7', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: 'plafondverwarming', InsulationValue: 'onbekende isolatie' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_45', InsulationId: 'VERW-AFG_ISO_OPP_45', key1: '<4;4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: 'beide', delta_theta_str: '0,7', delta_theta_emb1: '0,7', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: 'plafondverwarming en ventilatiesysteem A', InsulationValue: 'onbekende isolatie' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_46', InsulationId: 'VERW-AFG_ISO_OPP_46', key1: '<4;4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: 'beide', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: 'plafondverwarming en ventilatiesysteem B, C, D of E', InsulationValue: 'onbekende isolatie' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_47', InsulationId: 'VERW-AFG_ISO_OPP_47', key1: '<4;4-6;6-8;>8', key2: 'horizontaal', key3: '', key4: 'mechanisch', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: 'plafondverwarming', InsulationValue: 'zonder isolatie volgens NEN-EN 1264' },

                { AreaheatingId: 'VERW-AFG_TYPE_OPP_48', InsulationId: 'VERW-AFG_ISO_OPP_48', key1: '<4', key2: '', key3: 'basis', key4: '', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: 'vloerverwarming ', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_49', InsulationId: 'VERW-AFG_ISO_OPP_49', key1: '4-6;6-8;>8', key2: '', key3: 'basis', key4: '', delta_theta_str: '0', delta_theta_emb1: '', delta_theta_emb2: '', delta_theta_emb3: '1,9', AreaheatingValue: 'vloerverwarming ', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_50', InsulationId: 'VERW-AFG_ISO_OPP_50', key1: '<4;4-6;6-8;>8', key2: '', key3: 'basis', key4: 'natuurlijk', delta_theta_str: '0,4', delta_theta_emb1: '0,2', delta_theta_emb2: '', delta_theta_emb3: '', AreaheatingValue: 'wandverwarming ', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_51', InsulationId: 'VERW-AFG_ISO_OPP_51', key1: '<4;4-6;6-8;>8', key2: '', key3: 'basis', key4: 'beide', delta_theta_str: '0,4', delta_theta_emb1: '0,2', delta_theta_emb2: '', delta_theta_emb3: '', AreaheatingValue: 'wandverwarming en ventilatiesysteem A', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_52', InsulationId: 'VERW-AFG_ISO_OPP_52', key1: '<4;4-6;6-8;>8', key2: '', key3: 'basis', key4: 'beide', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '', delta_theta_emb3: '', AreaheatingValue: 'wandverwarming en ventilatiesysteem B, C, D of E', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_53', InsulationId: 'VERW-AFG_ISO_OPP_53', key1: '<4;4-6;6-8;>8', key2: '', key3: 'basis', key4: 'mechanisch', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '', delta_theta_emb3: '', AreaheatingValue: 'wandverwarming', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_54', InsulationId: 'VERW-AFG_ISO_OPP_54', key1: '<4;4-6;6-8;>8', key2: '', key3: 'basis', key4: 'natuurlijk', delta_theta_str: '0,7', delta_theta_emb1: '0,7', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: 'plafondverwarming', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_55', InsulationId: 'VERW-AFG_ISO_OPP_55', key1: '<4;4-6;6-8;>8', key2: '', key3: 'basis', key4: 'beide', delta_theta_str: '0,7', delta_theta_emb1: '0,7', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: 'plafondverwarming en ventilatiesysteem A', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_56', InsulationId: 'VERW-AFG_ISO_OPP_56', key1: '<4;4-6;6-8;>8', key2: '', key3: 'basis', key4: 'beide', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: 'plafondverwarming en ventilatiesysteem B, C, D of E', InsulationValue: '' },
                { AreaheatingId: 'VERW-AFG_TYPE_OPP_57', InsulationId: 'VERW-AFG_ISO_OPP_57', key1: '<4;4-6;6-8;>8', key2: '', key3: 'basis', key4: 'mechanisch', delta_theta_str: '0', delta_theta_emb1: '0,7', delta_theta_emb2: '1,4', delta_theta_emb3: '', AreaheatingValue: 'plafondverwarming', InsulationValue: '' },
            ];

            const oppervlakteTemperatuurTypeCodedValues = ntabuilding.properties["VERW-AFG_TYPE_OPP"].Domain.Codes;
            const oppervlakteTemperatuurIsolatieCodedValues = ntabuilding.properties["VERW-AFG_ISO_OPP"].Domain.Codes;

            table9_3_5.forEach(function (row, index) {
                const codedValue = oppervlakteTemperatuurTypeCodedValues.find(function (x) { return x.Id === row.AreaheatingId; });
                if (codedValue !== undefined) {
                    row.AreaheatingValue = codedValue.Value;
                    row.Order = codedValue.Order;
                }
            });

            table9_3_5.forEach(function (row, index) {
                const codedValue = oppervlakteTemperatuurIsolatieCodedValues.find(function (x) { return x.Id === row.InsulationId; });
                if (codedValue !== undefined) {
                    row.InsulationValue = codedValue.Value;
                    row.Order = codedValue.Order;
                }
            });

            return table9_3_5;
        } //-- end: createTable9_3_5 ------------------------------------------------------------------------//

        function createTable9_11_12_13() {
            const table9_11_12_13 = [
                { Id: 'VERW-AFG-VENT_SRT_1', Value: '', key1: 'lokaal', key2: '', key3: 'per eenheid', key4: '<4; 4-6;6-8; >8', key5: '', Paux: '10', factor: '' },
                { Id: 'VERW-AFG-VENT_SRT_2', Value: '', key1: 'lokaal', key2: '', key3: 'per eenheid', key4: '<4; 4-6;6-8; >8', key5: '', Paux: '12', factor: '' },
                { Id: 'VERW-AFG-VENT_SRT_3', Value: '', key1: 'lokaal', key2: 'lokale direct gestookte luchtverwarming - gas', key3: '', key4: '<4; 4-6;6-8; >8', key5: '', Paux: '', factor: '0,014' },
                { Id: 'VERW-AFG-VENT_SRT_4', Value: '', key1: 'lokaal', key2: 'lokale direct gestookte luchtverwarming - gas', key3: '', key4: '<4; 4-6;6-8; >8', key5: '', Paux: '', factor: '0,022' },
                { Id: 'VERW-AFG-VENT_SRT_5', Value: '', key1: 'centraal', key2: '', key3: '', key4: '<4; 4-6;6-8', key5: '<=8', Paux: '', factor: '0,008' },
                { Id: 'VERW-AFG-VENT_SRT_6', Value: '', key1: 'centraal', key2: '', key3: '', key4: '>8', key5: '>8', Paux: '', factor: '0,012' },
                { Id: 'VERW-AFG-VENT_SRT_7', Value: '', key1: 'centraal', key2: '', key3: '', key4: '<4; 4-6;6-8', key5: '<=8', Paux: '', factor: '0,004' },
                { Id: 'VERW-AFG-VENT_SRT_8', Value: '', key1: 'centraal', key2: '', key3: '', key4: '>8', key5: '>8', Paux: '', factor: '0,006' },
                { Id: 'VERW-AFG-VENT_SRT_9', Value: '', key1: 'centraal', key2: '', key3: '', key4: '<4; 4-6;6-8', key5: '<=8', Paux: '', factor: '0,008' },
                { Id: 'VERW-AFG-VENT_SRT_10', Value: '', key1: 'centraal', key2: '', key3: '', key4: '>8', key5: '>8', Paux: '', factor: '0,012' },
                { Id: 'VERW-AFG-VENT_SRT_11', Value: '', key1: 'centraal', key2: '', key3: '', key4: '<4; 4-6;6-8', key5: '<=8', Paux: '', factor: '0,009' },
                { Id: 'VERW-AFG-VENT_SRT_12', Value: '', key1: 'centraal', key2: '', key3: '', key4: '>8', key5: '>8', Paux: '', factor: '0,013' },
                { Id: 'VERW-AFG-VENT_SRT_13', Value: '', key1: 'centraal', key2: '', key3: '', key4: '<4; 4-6;6-8', key5: '<=8', Paux: '', factor: '0,005' },
                { Id: 'VERW-AFG-VENT_SRT_14', Value: '', key1: 'centraal', key2: '', key3: '', key4: '>8', key5: '>8', Paux: '', factor: '0,007' },
                { Id: 'VERW-AFG-VENT_SRT_15', Value: '', key1: 'centraal', key2: '', key3: '', key4: '<4; 4-6;6-8', key5: '<=8', Paux: '', factor: '0,009' },
                { Id: 'VERW-AFG-VENT_SRT_16', Value: '', key1: 'centraal', key2: '', key3: '', key4: '>8', key5: '>8', Paux: '', factor: '0,013' },
                { Id: 'VERW-AFG-VENT_SRT_NVT', Value: '', key1: '', key2: '', key3: '', key4: '', key5: '', Paux: '', factor: '' },
            ];

            const afgifteVentilatorenCodedValues = ntabuilding.properties["VERW-AFG-VENT_SRT"].Domain.Codes;

            table9_11_12_13.forEach(function (row, index) {
                const codedValue = afgifteVentilatorenCodedValues.find(function (x) { return x.Id === row.Id; });
                if (codedValue !== undefined) {
                    row.Value = codedValue.Value;
                    row.Order = codedValue.Order;
                }
            });

            return table9_11_12_13;
        } //-- end: createTable9_11_12_13 ------------------------------------------------------------------------//

    }

    return VerwarmingLogic;
}]);
