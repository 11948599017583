﻿angular.module('projectModule')
    .controller("BuildingResultsController", ['$scope', '$controller',  'ntaSharedLogic', 'BuildingResultsFactory',
function                                     ( $scope,   $controller,    ntaSharedLogic,   BuildingResultsFactory) {
    'use strict';
    const vm = this;
    const base = $controller('NTABaseController', { $scope: $scope });
    angular.extend(vm, base);


    const resultsLogic = new BuildingResultsFactory();
    vm.resultsLogic = resultsLogic;

    // FORM ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    vm.getForm = function () {
        return resultsLogic.form_buildingresults;
    };

    angular.element(function () { //alle touched properties valideren na het laden van form
        resultsLogic.startFormValidation();
        base.initializeUnitSpacing();
    });

    $scope.endFormValidation = function () {
        resultsLogic.endFormValidation();
    };



}]);
