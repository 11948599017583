﻿angular.module('projectModule')
    .service('ntaDependencyValidation',
        ['$log', 'ntaData', 'ntabuilding', 'ntaSharedLogic', 'ntaEntityData', 'ntaEntityDataOrg', 'settingsMenuData', 'ntaMeasureData', 'ntaCalcValidation', 'ntaResults', 'ntaMeldingen', 'AttributesFactory', 'BuildingBegrenzingFactory', 'BuildingCalcZonesFactory', 'ConstructiesFactory', 'BuildingLibraryFactory', 'LuchtdoorlatenFactory', 'BevochtigingFactory', 'KoelingFactory', 'PvFactory', 'InstallationFactory', 'TapwaterFactory', 'VentilatieFactory', 'VerlichtingFactory', 'VerwarmingFactory', 'WindturbineFactory', 'ZonneboilerFactory', 'MeasureRcFactory', 'MeasureUFactory', 'MeasureZonweringFactory', 'MeasureInfilFactory', 'MeasureVLeidingFactory', 'MeasureHvacFactory', 'MeasureWindtFactory', 'MeasureVerlFactory', 'MeasurePvFactory', 'VariantFactory', 'GebruikersprofielFactory', 'EnergierekeningFactory', 'NietGebouwGebondenElektriciteitFactory',
function ($log,   ntaData,   ntabuilding,   ntaSharedLogic,   ntaEntityData,   ntaEntityDataOrg,   settingsMenuData,   ntaMeasureData,   ntaCalcValidation,   ntaResults,   ntaMeldingen,   AttributesFactory,   BuildingBegrenzingFactory,   BuildingCalcZonesFactory,   ConstructiesFactory,   BuildingLibraryFactory,   LuchtdoorlatenFactory,   BevochtigingFactory,   KoelingFactory,   PvFactory,   InstallationFactory,   TapwaterFactory,   VentilatieFactory,   VerlichtingFactory,   VerwarmingFactory,   WindturbineFactory,   ZonneboilerFactory,   MeasureRcFactory,   MeasureUFactory,   MeasureZonweringFactory,   MeasureInfilFactory,   MeasureVLeidingFactory,   MeasureHvacFactory,   MeasureWindtFactory,   MeasureVerlFactory,   MeasurePvFactory,   VariantFactory,   GebruikersprofielFactory,   EnergierekeningFactory,   NietGebouwGebondenElektriciteitFactory) {
    'use strict';

    const vm = this;

    ntabuilding.ntaDependencyValidation = vm; //-- ntaDependencyValidation aan ntabuilding geven, omdat deze nodig is in de startBerekening en dit niet met injection kan

    vm.checkResultTOjuliRelations = ntaResults.checkResultTOjuliRelations;
    vm.checkResultTOjuliRelationsForUnitRz = ntaResults.checkResultTOjuliRelationsForUnitRz;


    // We breiden de ntabuilding.changeBuilding uit zodat onze onAfterChangeBuilding wordt aangeroepen na het switchen van gebouw
    const _originalChangeBuilding = ntabuilding.changeBuilding;
    ntabuilding.changeBuilding = function (newBuildingId) {
        const oldBuildingId = ntabuilding.buildingId;

        const result = _originalChangeBuilding.call(ntabuilding, newBuildingId);

        onAfterChangeBuilding(oldBuildingId);

        return result;
    };

    //-- We gaan hier luisteren naar het aanmaken/verwijderen van alle entdatas en reldatas, zodat we eventuele validaties meteen kunnen uitvoeren.
    ntaEntityData.on('afterCreate', onEntityCreated);
    ntaEntityData.on('afterDelete', data => {
        const firstEntdata = data.entitydatas[0];
        for (const entdata of data.entitydatas) {
            onEntityDeleted(entdata, { cascade: entdata === firstEntdata, data });
        }
        for (const relation of data.relationdatas) {
            onRelationDeleted(relation, { entdata: true, data });
        }
    });
    ntaEntityData.on('afterCreateRelation', onRelationCreated);
    ntaEntityData.on('afterDeleteRelation', onRelationDeleted);
    ntaEntityData.on('afterCopy', data => {
        for (const entdata of data.newEntdata) {
            onEntityCreated(entdata, { copied: true, data });
        }
        for (const relation of data.newrelations) {
            onRelationCreated(relation, { copied: true, data });
        }
    });

    function onEntityCreated(entdata, context = { copied: false, data: { "newEntdata": [entdata], "newrelations": [] } }) {
        // Hier kunnen we reageren op het aanmaken van een nieuwe entdata.
        // - context.copied geeft aan of deze entdata is aangemaakt n.a.v. het kopiëren van een entdata.
        // - context.data bevat alle gekopieerde entdatas en reldatas.

        switch (entdata.EntityId) {
            default: {
                break;
            }
        }
    } //-- end: onEntityCreated ---------------------------------------------------------------

    function onEntityDeleted(entdata, context = { cascade: false, data: { "entitydatas": [entdata], "relationdatas": [] } }) {
        // Hier kunnen we reageren op het verwijderen van een entdata.
        // - context.cascade geeft of deze entdata is verwijderd n.a.v. het verwijderen van een andere entdata.
        // - context.data bevat alle verwijderde entdatas en reldatas.

        switch (entdata.EntityId) {
            default: {
                break;
            }
        }
    } //-- end: onEntityDeleted ---------------------------------------------------------------

    function onRelationCreated(relation, context = { copied: false, data: { entityDatas: [], relationDatas: [relation] } }) {
        // Hier kunnen we reageren op het aanmaken van een relatie.
        // - context.copied geeft aan of de relatie is aangemaakt bij het kopiëren van een entdata.
        // - context.data bevat alle entdatas en reldatas die tegelijk aangemaakt zijn.
        // Deze functie wordt separaat aangeroepen voor elke reldata in in context.data.relationDatas.

        switch (relation.ParentEntityId) {
            case 'LIBCONSTRD': {
                if (relation.ChildEntityId !== 'MEASURE-RC') { // voorkom dat we dit 2x doen; het gebeurt namelijk ook al bij de ChildEntityId verderop.
                    // Nagaan of er een Rc-maatregel is gekoppeld aan deze LIBCONSTRD, en zo ja, deze valideren.
                    const measureRcs = ntaEntityData.getChildren(relation.Parent, 'MEASURE-RC');
                    validateMeasureRcs(measureRcs);
                }
                break;
            }
            case 'LIBCONSTRT': {
                if (relation.ChildEntityId !== 'MEASURE-U') { // voorkom dat we dit 2x doen; het gebeurt namelijk ook al bij de ChildEntityId verderop.
                    // Nagaan of er een U-maatregel is gekoppeld aan deze LIBCONSTRT, en zo ja, deze valideren.
                    const measureUs = ntaEntityData.getChildren(relation.Parent, 'MEASURE-U');
                    validateMeasureUs(measureUs);
                }
                break;
            }
        }

        switch (relation.ChildEntityId) {
            case 'MEASURE-RC': {
                // Een Rc-maatregel is (om wat voor reden dan ook) ergens aan gekoppeld, dus moeten
                //  totaaloppervlakte en totaalprijs opnieuw berekend worden.
                const measureRcId = relation.Child;
                const measureRc = ntaEntityData.get(measureRcId);
                validateMeasureRcs([measureRc]);
                break;
            }
            case 'MEASURE-U': {
                // Een U-maatregel is (om wat voor reden dan ook) ergens aan gekoppeld, dus moeten
                //  totaaloppervlakte en totaalprijs opnieuw berekend worden.
                const measureUId = relation.Child;
                const measureU = ntaEntityData.get(measureUId);
                validateMeasureUs([measureU]);
                break;
            }
            case 'MEASURE-ZONW': {
                // Een zonweringsmaatregel is (om wat voor reden dan ook) ergens aan gekoppeld, dus moeten
                //  totaaloppervlakte en totaalprijs opnieuw berekend worden.
                const measureZonwId = relation.Child;
                const measureZonw = ntaEntityData.get(measureZonwId);
                validateMeasureZonws([measureZonw]);
                break;
            }
        }
    } //-- end: onRelationCreated -------------------------------------------------------------

    function onRelationDeleted(relation, context = { entdata: false, data: { entitydatas: [], relationdatas: [relation] }  }) {
        // Hier kunnen we reageren op het verwijderen van een relatie.
        // - context.entdata geeft aan of deze relatie verwijderd is n.a.v. het verwijderen van een entdata.
        // - context.data bevat alle entdatas en reldatas die tegelijk verwijderd zijn.
        // Deze functie wordt separaat aangeroepen voor elke reldata in context.data.relationdatas.

        switch (relation.ParentEntityId) {
            case 'LIBCONSTRD': {
                if (relation.ChildEntityId !== 'MEASURE-RC') { // voorkom dat we dit 2x doen; het gebeurt namelijk ook al bij de ChildEntityId verderop.
                    // Nagaan of er een Rc-maatregel is gekoppeld aan deze LIBCONSTRD, en zo ja, deze valideren.
                    const measureRcs = ntaEntityData.getChildren(relation.Parent, 'MEASURE-RC');
                    validateMeasureRcs(measureRcs);
                }
                break;
            }
            case 'LIBCONSTRT': {
                if (relation.ChildEntityId !== 'MEASURE-U') { // voorkom dat we dit 2x doen; het gebeurt namelijk ook al bij de ChildEntityId verderop.
                    // Nagaan of er een U-maatregel is gekoppeld aan deze LIBCONSTRT, en zo ja, deze valideren.
                    const measureUs = ntaEntityData.getChildren(relation.Parent, 'MEASURE-U');
                    validateMeasureUs(measureUs);
                }
                break;
            }
        }

        switch (relation.ChildEntityId) {
            case 'MEASURE-RC': {
                // Een Rc-maatregel is (om wat voor reden dan ook) ergens van losgekoppeld, dus moeten
                //  totaaloppervlakte en totaalprijs opnieuw berekend worden.
                const measureRcId = relation.Child;
                const measureRc = ntaEntityData.get(measureRcId);
                // Dit hoeft natuurlijk niet te gebeuren als de Rc-maatregel zelf ook is verwijderd.
                if (measureRc) {
                    validateMeasureRcs([measureRc]);
                }
                break;
            }
            case 'MEASURE-U': {
                // Een U-maatregel is (om wat voor reden dan ook) ergens aan gekoppeld, dus moeten
                //  totaaloppervlakte en totaalprijs opnieuw berekend worden.
                const measureUId = relation.Child;
                const measureU = ntaEntityData.get(measureUId);
                if (measureU) {
                    validateMeasureUs([measureU]);
                }
                break;
            }
            case 'MEASURE-ZONW': {
                // Een zonweringsmaatregel is (om wat voor reden dan ook) ergens van losgekoppeld, dus moeten
                //  totaaloppervlakte en totaalprijs opnieuw berekend worden.
                const measureZonwId = relation.Child;
                const measureZonw = ntaEntityData.get(measureZonwId);
                // Dit hoeft natuurlijk niet te gebeuren als de zonweringsmaatregel zelf ook is verwijderd.
                if (measureZonw) {
                    validateMeasureZonws([measureZonw]);
                }
                break;
            }
        }
    } //-- end: onRelationDeleted -------------------------------------------------------------



    // Ontvangt veld dat gewijzigd is en controleert welke afhankelijkheden gevalideerd moeten worden
    vm.checkChangedField = function (prop, entdata, logic) {
        if (!ntabuilding.canSave()) return;
        if (!prop || !entdata || !logic) {
            return;
        }

        switch (prop.Id) {
            case 'SETTINGS_THBRUG': {
                setThermischeBruggenDependencies();
                break;
            }
            case 'SETTINGS_ONLY_ACTU_VERKL': {
                checkActueleVerklaringen();
                break;
            }
            case 'SETTINGS_MAATADVIES': {
                if (prop.getValue(entdata) === 'True') {
                    // zorg dat alle resultaten aanwezig zijn onder MWA-RESULTS
                    ntaResults.initialize(true);
                } else {
                    // [NGE-A] grijs deze keuze uit als onder instellingen 'maatwerkadvies' uit staat. Dan kan er dus ook geen vinkje gezet worden.
                    const propMethode = ntaData.properties['NGEBGEB-E_METHODE'];
                    const entdataNGEBGEBE = ntaEntityDataOrg.getFirstWithEntityId('NGEBGEB-E');
                    if (propMethode.getValue(entdataNGEBGEBE) === 'NGEBGEB-E_INTERNE_WARMTE') {
                        const logic = vm.getLogic(entdataNGEBGEBE);
                        logic.saveValue(propMethode, entdataNGEBGEBE, 'NGEBGEB-E_EIGEN_INVOER');
                    }
                }
                break;
            }
            case 'GEB_TYPEGEB': {

                // Indeling gebouw
                if (calczonesGeopend()) {
                    const calczonesLogic = new BuildingCalcZonesFactory(vm);

                    const propCalcUnit = ntabuilding.properties['RZFORM_CALCUNIT'];
                    const entdataRzForm = calczonesLogic.rzform;
                    const calcUnit = propCalcUnit.getValue(entdataRzForm);
                    // Controleer of de calcUnit wel geldig is voor dit type gebouw
                    const possibleCalcUnits = new Set(calczonesLogic.getCodedValues(propCalcUnit, entdataRzForm).map(cv => cv.Id));
                    if (!possibleCalcUnits.has(calcUnit)) {
                        switch (entdata.PropertyDatas[prop.Id].Value) {
                            case "TGEB_APP": { // los appartement
                                calczonesLogic.saveValue(propCalcUnit, entdataRzForm, "RZUNIT_GEBAPP");
                                break;
                            }
                            case "TGEB_UTILUNIT": { // losse unit in utiliteitsgebouw
                                calczonesLogic.saveValue(propCalcUnit, entdataRzForm, "RZUNIT_GEBUNIT");
                                break;
                            }
                            //-- BM 2021-10-12: nog een keer uitgezet. Kan nog niet mee met release.
                            //-- VO 2021-03-31: voor productie release 2 april 2021 nog geen per gebouw per unit mogelijk, voor
                            //-- ubouw. Dus _GEB kiezen bij de default
                            //case "TGEB_UTILIT":
                            case "TGEB_APPGEB": {
                                // dan kan het allebei; laten staan
                                break;
                            }
                            default: { // bij alle andere gebouwtypes kan alleen per gebouw gerekend worden
                                calczonesLogic.saveValue(propCalcUnit, entdataRzForm, "RZUNIT_GEB");
                                break;
                            }
                        }
                    }
                    vm.validateDependencies(propCalcUnit, calczonesLogic.rzform, calczonesLogic);
                    vm.validateDependencies('RZFORM_AANTWOONF', calczonesLogic.rzform, calczonesLogic); // is nodig ivm meldingen tapw

                    calczonesLogic.units().forEach(function (unit, index) {
                        vm.validateDependencies('UNIT_POS', unit, calczonesLogic);
                        vm.validateDependencies('UNIT_TYPEGEB', unit, calczonesLogic);
                        vm.validateDependencies('UNIT_TYPEWON', unit, calczonesLogic);
                        vm.validateDependencies('UNIT_AANTA', unit, calczonesLogic);
                        vm.validateDependencies('UNIT_AANTU', unit, calczonesLogic);
                    });
                }

                // Constructies
                const begrenzingenMetTConst = getAlleBegrenzingenMetTransparanteConstructie();
                begrenzingenMetTConst.forEach(function (begrenzing, index) {
                    if (constructieGeopend(begrenzing)) {
                        const constructieLogic = new ConstructiesFactory(begrenzing.EntityDataId, vm);
                        constructieLogic.constrTs().forEach(function (constrT, index) {
                            vm.validateDependencies('CONSTRT_ZONW', constrT, constructieLogic);
                        });
                    }
                });

                // Luchtdoorlaten
                if (luchtdoorlatenGeopend()) {
                    const ldLogic = new LuchtdoorlatenFactory(vm);
                    vm.validateDependencies('INFIL_INVOER', ldLogic.infiltratieData, ldLogic);
                }


                const isUtiliteit = ntaSharedLogic.isUtiliteit();
                const heeftVerlichting = getVerlichtingInstallations().length > 0;

                if (isUtiliteit !== heeftVerlichting) { //switchen van U naar W of //switchen van W naar U

                    if (!isUtiliteit) { // igvv Woningbouw gebruiksfunctie op woning zetten (-> leegmaken)
                        let unitrzfgs = ntaEntityData.getListWithEntityId("UNIT-RZ-GF");
                        unitrzfgs.forEach(function (unitrzfg, index) {
                            const unitrzgfId = unitrzfg.PropertyDatas['UNIT-RZ-GFID'];
                            ntaEntityData.saveprop(unitrzgfId, "");
                        });
                    }

                    //verwijder alle installaties
                    let installaties = ntaEntityData.getListWithEntityId("INSTALLATIE");
                    installaties.forEach(function (installatie, index) {
                        ntaEntityData.delete(installatie.EntityDataId);
                    });

                    //verwijder evt verlichtingszones (zijn geen children van een installatie, dus worden niet hierboven verwijderd)
                    let verlzones = ntaEntityData.getListWithEntityId("VERLZONE");
                    verlzones.forEach(function (verlzone, index) {
                        ntaEntityData.delete(verlzone.EntityDataId);
                    });

                    ///Maak nieuwe installaties aan
                    const installationsLogic = new InstallationFactory(vm);
                    installationsLogic.addInstallation('INST_VERW');
                    installationsLogic.addInstallation('INST_TAPW');
                    installationsLogic.addInstallation('INST_VENT');
                    if (ntaSharedLogic.isUtiliteit()) {
                        installationsLogic.addInstallation('INST_VERL');
                    }

                    /// Check de gebruiksfunctie van unit-rz-gfs
                    const calczonesLogic = new BuildingCalcZonesFactory(vm);
                    calczonesLogic.unitrzgfs().forEach(function (unitrzgf, index) {
                        vm.validateDependencies('UNIT-RZ-GFID', unitrzgf, calczonesLogic);
                    });
                }

                //-- VO 2021-12-09: PV-systemen controleren of je juiste invoermethode nog klopt bij het type gebouw
                const PVSystemen = ntaEntityData.getListWithEntityId("PV");
                const propInvoer = ntabuilding.properties['PV_INVOER'];
                for (const PV of PVSystemen) {
                    const pvInvoer = PV.PropertyDatas[propInvoer.Id].Value;
                    let newValue = pvInvoer;
                    if (ntaSharedLogic.isAppartementOfUtiliteitVoorBestaandeBouw()) {
                        //-- als ik nu een app of unit ben, en de invoer van het vorige gebouwtype was per gebouwdeel, dan moeten de relaties tussen PV-VELD en UNITs verwijderd worden. Het type
                        //-- invoer moet op PVINVOER_APP of PVINVOER_UNIT gezet worden.
                        if (pvInvoer === 'PVINVOER_GEB' || pvInvoer === 'PVINVOER_GEBDEEL') {
                            //-- invoer op App of Unit zetten
                            newValue = ntaSharedLogic.isUtiliteit() ? "PVINVOER_UNIT" : "PVINVOER_APP";
                        }
                    } else if (ntaSharedLogic.voorProjectwoningen()) {
                        newValue = 'PVINVOER_WONING';
                    } else {
                        //-- VO 2022-02-17: ook wanneer er van app/unit geswitcht wordt naar één gebouw (woning, woongebouw, utiliteitsgebouw enz.) moeten er aanpassingen gedaan
                        //-- worden aan de PVVelden.
                        newValue = 'PVINVOER_GEB';
                    }
                    const pvLogic = new PvFactory(ntaEntityData.getFirstParent(PV, "INSTALLATIE"), vm);
                    pvLogic.saveValue(propInvoer, PV, newValue); //-- hier wordt ook de relatie aangemaakt tussen de unit en veld en juiste relevantie gezet
                }

                break;
            }
            case 'GEB_SRTBW': {

                // Verwarming
                const verwInstallations = getVerwarmingInstallations();

                verwInstallations.forEach(function (verwInstallation, index) {
                    if (verwarmingInstallationGeopend(verwInstallation)) {
                        const verwLogic = new VerwarmingFactory(verwInstallation.EntityDataId, vm);

                        verwLogic.verwOpwekkersData().forEach(function (opwekker, index2) {
                            vm.validateDependencies('VERW-OPWEK_TOE_BIJ', opwekker, verwLogic);
                        });

                        let propBinnenIsolatieLeidingen = ntabuilding.properties['VERW-DISTR-BIN_ISO_LEI'];
                        let propBuitenIsolatieLeidingen = ntabuilding.properties['VERW-DISTR-BUI_ISO_LEI'];
                        verwLogic.getCodedValues(propBinnenIsolatieLeidingen, verwLogic.verwDistributieBinnenData);
                        verwLogic.getCodedValues(propBuitenIsolatieLeidingen, verwLogic.verwDistributieBuitenData);
                        vm.validateDependencies(propBinnenIsolatieLeidingen, verwLogic.verwDistributieBinnenData, verwLogic);
                        vm.validateDependencies(propBuitenIsolatieLeidingen, verwLogic.verwDistributieBuitenData, verwLogic);
                    }
                });

                // Koeling
                const koelInstallations = getKoelingInstallations();
                koelInstallations.forEach(function (koelInstallation, index) {

                    if (koelingInstallationGeopend(koelInstallation)) {
                        const koelLogic = new KoelingFactory(koelInstallation.EntityDataId, vm);

                        let propBinnenIsolatieLeidingen = ntabuilding.properties['KOEL-DISTR-BIN_ISO_LEI'];
                        let propBuitenIsolatieLeidingen = ntabuilding.properties['KOEL-DISTR-BUI_ISO_LEI'];
                        koelLogic.getCodedValues(propBinnenIsolatieLeidingen, koelLogic.koelDistributieBinnenData);
                        koelLogic.getCodedValues(propBuitenIsolatieLeidingen, koelLogic.koelDistributieBuitenData);
                        vm.validateDependencies(propBinnenIsolatieLeidingen, koelLogic.koelDistributieBinnenData, koelLogic);
                        vm.validateDependencies(propBuitenIsolatieLeidingen, koelLogic.koelDistributieBuitenData, koelLogic);
                    }
                });

                // Ventilatie
                const ventInstallations = getVentilatieInstallations();

                ventInstallations.forEach(function (ventInstallation, index) {
                    if (ventilatieInstallationGeopend(ventInstallation)) {
                        const ventLogic = new VentilatieFactory(ventInstallation.EntityDataId, vm);
                        vm.validateDependencies('VOORWARM_NT', ventLogic.voorverwarmingData, ventLogic);
                    }
                });


                vm.validateDependencies('GEB_BWJR', logic.buildingattributes, logic);
                break;
            }
            case 'GEB_BWJR':
            case 'GEB_OPLVJR': {

                // Bibliotheek
                if (bibliotheekGeopend()) {
                    const libraryLogic = new BuildingLibraryFactory(vm);
                    libraryLogic.libclosedconstructions.forEach(function (dichteConstructie, index) {
                        vm.validateDependencies('LIBCONSTRD_BEPALING', dichteConstructie, libraryLogic);
                    });
                }

                // Luchtdoorlaten
                /// We moeten de calculateAllQV10s altijd uitvoeren, ongeacht of dit formulier geopend is geweest.
                const ldLogic = new LuchtdoorlatenFactory(vm); //-- in de init van deze factory wordt calculateAllQV10s() aangeroepen

                // Verwarming
                const verwInstallations = getVerwarmingInstallations();
                verwInstallations.forEach(function (verwInstallation, index) {
                    if (verwarmingInstallationGeopend(verwInstallation)) {
                        const verwLogic = new VerwarmingFactory(verwInstallation.EntityDataId, vm);

                        verwLogic.verwOpwekkersData().forEach(function (opwekker, index2) {
                            //--onderstaande properties zijn afhankelijk van het bouwjaar
                            vm.validateDependencies('VERW-OPWEK_FABR', opwekker, verwLogic);
                            vm.validateDependencies('VERW-OPWEK_FABR_TOE', opwekker, verwLogic);
                            vm.validateDependencies('VERW-OPWEK_TOEW', opwekker, verwLogic);
                        });

                        //-- ook de isolatie van leidinglengte is afhankelijk van het bouwjaar. door het aanroepen van
                        //-- getCodedValue voor deze properties, wordt de juiste keuze lijst gemaakt en gecontroleer of
                        //-- de huidige waarde nog relevant is. Zo niet, dan wordt deze leeg gemaakt en gaat de
                        //-- validatedependencies af van deze properties, die dan pas velden leeg gaat maken.
                        verwLogic.getCodedValues(ntabuilding.properties['VERW-DISTR-BIN_ISO_LEI'], verwLogic.verwDistributieBinnenData);
                        verwLogic.getCodedValues(ntabuilding.properties['VERW-DISTR-BUI_ISO_LEI'], verwLogic.verwDistributieBuitenData);
                    }
                });

                // Koeling
                const koelInstallations = getKoelingInstallations();
                koelInstallations.forEach(function (koelInstallation, index) {

                    if (koelingInstallationGeopend(koelInstallation)) {
                        const koelLogic = new KoelingFactory(koelInstallation.EntityDataId, vm);
                        koelLogic.koelOpwekkersData.forEach(function (opwekker, index2) {
                            vm.validateDependencies('KOEL-OPWEK_FABR', opwekker, koelLogic);
                            vm.validateDependencies('KOEL-OPWEK_TOEW', opwekker, koelLogic);

                            vm.validateDependencies('KOEL-DISTR-BIN_ISO_LEI', koelLogic.koelDistributieBinnenData, koelLogic);
                            vm.validateDependencies('KOEL-DISTR-BUI_ISO_LEI', koelLogic.koelDistributieBuitenData, koelLogic);
                        });
                    }
                });

                // Tapwater
                const tapwInstallations = getTapwaterInstallations();
                tapwInstallations.forEach(function (tapwInstallation, index) {

                    if (tapwaterInstallationGeopend(tapwInstallation)) {
                        const tapwLogic = new TapwaterFactory(tapwInstallation.EntityDataId, vm);
                        tapwLogic.tapwOpwekkersData.forEach(function (opwekker, index2) {
                            vm.validateDependencies('TAPW-OPWEK_FABR_WKK', opwekker, tapwLogic);
                        });
                    }
                });

                // Ventilatie
                const ventInstallations = getVentilatieInstallations();
                ventInstallations.forEach(function (ventInstallation, index) {

                    if (ventilatieInstallationGeopend(ventInstallation)) {
                        const ventLogic = new VentilatieFactory(ventInstallation.EntityDataId, vm);

                        ventLogic.voorverwarmingIsHidden(ventLogic.ventilatieKolom);
                        ventLogic.voorverwarmingIsHidden(ventLogic.ventilatieAanvullendKolom);

                        vm.validateDependencies('VOORWARM_NT', ventLogic.voorverwarmingData, ventLogic);

                        ventLogic.ventilatorenData.tempUnits.forEach(function (tempUnit, index2) {
                            if (tempUnit.ventilatorEigenschappen) {
                                tempUnit.ventilatorEigenschappen.forEach(function (ventilatorEigenschap, index3) {
                                    vm.validateDependencies('VENTILATOREIG_JAAR', ventilatorEigenschap, ventLogic);
                                });
                            }
                        });
                        ventLogic.ventilatorenaanvullendData.tempUnits.forEach(function (tempUnit, index2) {
                            if (tempUnit.ventilatorEigenschappen) {
                                tempUnit.ventilatorEigenschappen.forEach(function (ventilatorEigenschap, index3) {
                                    vm.validateDependencies('VENTILATOREIG_JAAR', ventilatorEigenschap, ventLogic);
                                });
                            }
                        });
                    }

                });

                break;
            }
            case 'GEB_RENOVJR':
                {
                    // Luchtdoorlaten
                    /// We moeten de calculateAllQV10s altijd uitvoeren, ongeacht of dit formulier geopend is geweest.
                    const ldLogic = new LuchtdoorlatenFactory(vm); //-- in de init van deze factory wordt calculateAllQV10s() aangeroepen
                    break;
                }
            case 'GEB_OPN': {

                // Indeling gebouw
                if (calczonesGeopend()) {
                    const calczonesLogic = new BuildingCalcZonesFactory(vm);
                    for (const calczone of calczonesLogic.calczones()) {
                        if (ntabuilding.ntaVersionId < 200) {
                            vm.validateDependencies('RZ_BOUWW', calczone, calczonesLogic);
                        } else {
                            vm.validateDependencies('RZ_BOUWW_VL', calczone, calczonesLogic);
                            vm.validateDependencies('RZ_BOUWW_W', calczone, calczonesLogic);
                        }
                    }
                }

                //-- VO 2021-02-15: bij wisseling van opname veranderd ook de thermische bruggen setting.
                setThermischeBruggenDependencies();

                // Constructiess
                const begrenzingen = getBegrenzingen();
                begrenzingen.forEach(function (begrenzing, index) {

                    if (constructieGeopend(begrenzing)) {
                        const conLogic = new ConstructiesFactory(begrenzing.EntityDataId, vm);

                        conLogic.constrTs().forEach(function (constrT, index2) {
                            vm.validateDependencies('CONSTRT_BESCH', constrT, conLogic);
                            vm.validateDependencies('CONSTRT_ZONW', constrT, conLogic);

                            //-- bij basisopname is zomernachtventilatie voor alle transparante constries 'niet aanwezig' conditie [A5]
                            if (ntaSharedLogic.isBasisOpname()) {
                                const propZomernachtVentilatie = ntabuilding.properties['CONSTRT_ZNVENT'];
                                conLogic.saveValue(propZomernachtVentilatie, constrT, 'ZOMERNVENT_NAANW');

                            }
                        });

                        vm.validateDependencies('KENMKR_WW_KR', conLogic.ConstrKenmWWKLDR, conLogic);
                        vm.validateDependencies('KENMKR_VENT', conLogic.ConstrKenmKRVENT, conLogic);
                    }
                });

                // Verwarming
                const verwInstallations = getVerwarmingInstallations();
                verwInstallations.forEach(function (verwInstallation, index) {

                    if (verwarmingInstallationGeopend(verwInstallation)) {
                        const verwLogic = new VerwarmingFactory(verwInstallation.EntityDataId, vm);

                        // Distributie
                        vm.validateDependencies('VERW-DISTR-BIN_INV', verwLogic.verwDistributieBinnenData, verwLogic);
                        vm.validateDependencies('VERW-DISTR-BUI_INV', verwLogic.verwDistributieBuitenData, verwLogic);

                        // Afgifte
                        verwLogic.verwAfgifteData().forEach(function (afgifte, index) {
                            vm.validateDependencies('VERW-AFG_TYPE_OPP', afgifte, verwLogic);
                        });
                    }
                });

                // Koeling
                const koelInstallations = getKoelingInstallations();
                koelInstallations.forEach(function (koelInstallation, index) {

                    if (koelingInstallationGeopend(koelInstallation)) {
                        const koelLogic = new KoelingFactory(koelInstallation.EntityDataId, vm);
                        vm.validateDependencies('KOEL-DISTR-BIN_INV', koelLogic.koelDistributieBinnenData, koelLogic);
                        vm.validateDependencies('KOEL-DISTR-BUI_INV', koelLogic.koelDistributieBuitenData, koelLogic);
                    }
                });

                // Tapwater
                const tapwInstallations = getTapwaterInstallations();
                tapwInstallations.forEach(function (tapwInstallation, index) {

                    if (tapwaterInstallationGeopend(tapwInstallation)) {
                        const tapwLogic = new TapwaterFactory(tapwInstallation.EntityDataId, vm);
                        vm.validateDependencies('TAPW-DISTR-BIN_INV', tapwLogic.tapwDistributieBinnenData, tapwLogic);
                    }
                });

                // Ventilatie
                const ventInstallations = getVentilatieInstallations();
                ventInstallations.forEach(function (ventInstallation, index) {

                    if (ventilatieInstallationGeopend(ventInstallation)) {
                        const ventLogic = new VentilatieFactory(ventInstallation.EntityDataId, vm);
                        vm.validateDependencies('VENTDIS_DICHT', ventLogic.ventilatiedistributieData, ventLogic);
                        vm.validateDependencies('VENTDIS_LBK', ventLogic.ventilatiedistributieData, ventLogic);
                    }
                });

                break;
            }
            case 'LIBCONSTRD_TYPE': {

                const begrenzingenVanDichteConstructie = getBegrenzingenVoorDichteLibconstructie(entdata);
                for (const begrenzing of begrenzingenVanDichteConstructie) {
                    if (constructieGeopend(begrenzing)) {
                        const constructieLogic = new ConstructiesFactory(begrenzing.EntityDataId, vm);
                        constructieLogic.constrDs().forEach(function (constrD, index2) {
                            vm.validateDependencies('CONSTRD_LIB', constrD, constructieLogic);
                        });

                        constructieLogic.constrWGs().forEach(function (constrWG, index2) {
                            vm.validateDependencies('CONSTRWG_LIB', constrWG, constructieLogic);
                        });

                        vm.validateDependencies('KENMKR_WW_GVL', constructieLogic.ConstrKenmWWGVL, constructieLogic);

                        vm.validateDependencies('KENMKR_WW_KR', constructieLogic.ConstrKenmWWKLDR, constructieLogic);
                    }
                }

                // Rc-maatregelen
                const measureRcEntdatas = ntaEntityData.findEntities(entdata, 'CONSTRD.^BEGR.MEASURE-RC');
                validateMeasureRcs(measureRcEntdatas);

                break;
            }
            case 'LIBCONSTRD_RC': {

                // Rc-maatregelen
                const measureRcEntdatas = ntaEntityData.findEntities(entdata, 'CONSTRD.^BEGR.MEASURE-RC');
                validateMeasureRcs(measureRcEntdatas);

                // U-maatregelen
                const measureUEntdatas = ntaEntityData.findEntities(entdata, 'CONSTRD.^BEGR.MEASURE-U');
                validateMeasureUs(measureUEntdatas);

                break;
            }
            case 'LIBCONSTRL_POS': {

                const begrenzingenMetLConst = getBegrenzingenVoorLineaireLibconstructie(entdata);
                begrenzingenMetLConst.forEach(function (begrenzing, index) {

                    if (constructieGeopend(begrenzing)) {
                        const constructieLogic = new ConstructiesFactory(begrenzing.EntityDataId, vm);

                        constructieLogic.constrLs().forEach(function (constrL, index2) {
                            vm.validateDependencies('CONSTRL_LIB', constrL, constructieLogic);
                        });
                    }
                });

                break;
            }
            case 'LIBCONSTRFORM_KOZ': {

                const begrenzingenMetTConst = getAlleBegrenzingenMetTransparanteConstructie();
                begrenzingenMetTConst.forEach(function (begrenzing, index) {

                    if (constructieGeopend(begrenzing)) {
                        const constructieLogic = new ConstructiesFactory(begrenzing.EntityDataId, vm);
                        constructieLogic.constrTs().forEach(function (constrT, index) {
                            vm.validateDependencies('CONSTRT_AANT', constrT, constructieLogic);
                            vm.validateDependencies('CONSTRT_OPP', constrT, constructieLogic);
                        });
                    }
                });

                /// als type invoer 'opp per kozijnmerk' of 'geen kozijnmerken' moeten alle MEASURE-U aangepast worden omdat dan de oppervlakte
                /// bepaling anders gaat
                // U-maatregelen
                const measureUEntdatas = ntaEntityData.getListWithEntityId('MEASURE-U');
                validateMeasureUs(measureUEntdatas);

                break;
            }
            case 'LIBCONSTRT_TYPE': {

                const begrenzingenMetTConst = getBegrenzingenVoorTransparanteLibconstructie(entdata);
                begrenzingenMetTConst.forEach(function (begrenzing, index) {

                    if (constructieGeopend(begrenzing)) {
                        const constructieLogic = new ConstructiesFactory(begrenzing.EntityDataId, vm);

                        constructieLogic.constrTs().forEach(function (constrT, index2) {
                            vm.validateDependencies('CONSTRT_LIB', constrT, constructieLogic);
                        });
                    }

                });

                // U-maatregelen
                const measureUEntdatas = ntaEntityData.findEntities(entdata, 'CONSTRT.^BEGR.MEASURE-U');
                validateMeasureUs(measureUEntdatas);

                break;
            }
            case 'LIBCONSTRT_AC': {

                const begrenzingenMetTConst = getBegrenzingenVoorTransparanteLibconstructie(entdata);
                begrenzingenMetTConst.forEach(function (begrenzing, index) {

                    if (constructieGeopend(begrenzing)) {
                        const constructieLogic = new ConstructiesFactory(begrenzing.EntityDataId, vm);

                        constructieLogic.constrTs().forEach(function (constrT, index2) {
                            vm.validateDependencies('CONSTRT_OPP', constrT, constructieLogic);
                        });
                    }

                });

                // U-maatregelen
                const measureUEntdatas = ntaEntityData.findEntities(entdata, 'CONSTRT.^BEGR.MEASURE-U');
                validateMeasureUs(measureUEntdatas);

                break;
            }
            case 'LIBCONSTRT_G': {
                const gGlValue = prop.getValue(entdata);
                const begrenzingenMetLibConst = getBegrenzingenVoorTransparanteLibconstructie(entdata);
                const constrTIdsMetLibConstr = new Set(ntaEntityData.getChildIds(entdata));
                begrenzingenMetLibConst.forEach(function (begrenzing, index) {
                    if (constructieGeopend(begrenzing)) {
                        const constructieLogic = new ConstructiesFactory(begrenzing.EntityDataId, vm);
                        const propBeschaduwing = ntabuilding.properties["CONSTRT_BESCH"];
                        const propZonwering = ntabuilding.properties["CONSTRT_ZONW"];
                        //-- ik moet nu all constrT entiteiten ophalen van de begrenzing die een relatie hebben met deze entdata.
                        const alleConstrTs = constructieLogic.constrTs();
                        const constrTs = alleConstrTs.filter(function (x) { return constrTIdsMetLibConstr.has(x.EntityDataId); });
                        constrTs.forEach(function (constrT, index2) {
                            //-- VO 2020-11-27: als g van de libconstructi is gewijzigd ,moet ik bij de constructies die hier gebruik van maken
                            //-- controleren of beschaduwing en zonw nog kloppen.Bij g = 0 moet er een aanpassing en als g != 0 en ze
                            //-- waren "nvt" of "geen" dan de defaultwaarde zetten
                            if (gGlValue && ntaSharedLogic.parseFloat(gGlValue, 0) === 0) {
                                constructieLogic.saveValue(propBeschaduwing, constrT, 'n.v.t.');
                                constructieLogic.saveValue(propZonwering, constrT, 'ZONW_GEEN');
                            } else {
                                //-- Reset als niet langer g = 0. alleen voor beschaduwing, zonwering mag op geen blijven staan
                                let propValueBeschaduwing = propBeschaduwing.getValue(constrT);
                                if (propValueBeschaduwing === "n.v.t.") {
                                    constructieLogic.saveValue(propBeschaduwing, constrT, propBeschaduwing.DefaultValue);
                                }
                            }
                        });
                    }
                });

                // U-maatregelen
                const measureUEntdatas = ntaEntityData.findEntities(entdata, 'CONSTRT.^BEGR.MEASURE-U');
                validateMeasureUs(measureUEntdatas);

                break;
            }
            case 'LIBCONSTRT_U': {
                // U-maatregelen
                const measureUEntdatas = ntaEntityData.findEntities(entdata, 'CONSTRT.^BEGR.MEASURE-U');
                validateMeasureUs(measureUEntdatas);

                break;
            }
            case 'BEGR_VLAK':
            case 'BEGR_DAK':
            case 'BEGR_GEVEL':
            case 'BEGR_VLOER':
            case 'BEGR_KWAND':
            case 'BEGR_AOR':
            case 'BEGR_AOS':
            case 'BEGR_VL_OMV': {

                //-- klopt de relatie tussen constr en lib nog steeds voor de nieuwe begrenzing of vlaktype
                const constructieLogic = new ConstructiesFactory(entdata.EntityDataId, vm);
                if (constructieGeopend(entdata)) {
                    let propConstrLib = ntabuilding.properties['CONSTRD_LIB'];
                    constructieLogic.constrDs().forEach(function (constrD, index2) {
                        constructieLogic.getCodedValues(propConstrLib, constrD);                //-- juiste propdata uit lijst selecteren indien nodig
                        vm.validateDependencies(propConstrLib.Id, constrD, constructieLogic);   //-- juiste relatie maken tussen keuze en constructie
                    });

                    propConstrLib = ntabuilding.properties['CONSTRWG_LIB'];
                    constructieLogic.constrWGs().forEach(function (constrWG, index2) {
                        constructieLogic.getCodedValues(propConstrLib, constrWG);
                        vm.validateDependencies(propConstrLib.Id, constrWG, constructieLogic);
                    });

                    propConstrLib = ntabuilding.properties['CONSTRT_LIB'];
                    constructieLogic.constrTs().forEach(function (constrT, index2) {
                        constructieLogic.getCodedValues(propConstrLib, constrT);
                        vm.validateDependencies(propConstrLib.Id, constrT, constructieLogic);
                    });

                    propConstrLib = ntabuilding.properties['CONSTRL_LIB'];
                    constructieLogic.constrLs().forEach(function (constrL, index2) {
                        constructieLogic.getCodedValues(propConstrLib, constrL);                //-- juiste propdata uit lijst selecteren indien nodig
                        vm.validateDependencies(propConstrLib.Id, constrL, constructieLogic);   //-- juiste relatie maken tussen keuze en constructie
                    });

                    propConstrLib = ntabuilding.properties['KENMKR_WW_GVL'];
                    constructieLogic.getCodedValues(propConstrLib, constructieLogic.constrKenmWWGVL);
                    vm.validateDependencies(propConstrLib.Id, constructieLogic.constrKenmWWGVL, constructieLogic);

                    propConstrLib = ntabuilding.properties['KENMKR_WW_KR'];
                    constructieLogic.getCodedValues(propConstrLib, constructieLogic.constrKenmWWKLDR);
                    vm.validateDependencies(propConstrLib.Id, constructieLogic.constrKenmWWKLDR, constructieLogic);

                }
                //-- VO 2020-11-27: Afhankelijk van de waarde die voor het vlak gekozen is of voor de begrenzing kan
                //-- dat consequenties hebben voor het aantal constructies.

                //-- 1. Bij vlakvloer met bepaalde begrenzing mag er maar één constructie zijn Conditie[A5]
                if (constructieLogic.isConstrVloer()) {
                    //-- dichte beperken tot 1
                    let vloeren = constructieLogic.constrDs();
                    if (vloeren.length > 1) {
                        let index;
                        for (index = 0; index < vloeren.length; index++) {
                            if (index === 0) {
                                continue;
                            }
                            ntaEntityData.delete(vloeren[index].EntityDataId);
                        }
                    }
                }
                //-- 2. bij boven op onder maaiveld is minimaal één lineaire constructie verplicht
                if (constructieLogic.isBovenMaaiveld || constructieLogic.isOnderMaaiveld) {
                    if (constructieLogic.constrLs().length === 0) {
                        constructieLogic.createConstr(null, "CONSTRL");
                    }
                }

                vm.validateLineaireConstructies();

                if (prop.Id === 'BEGR_VLAK') {
                    // Koppel aan alle (voor dit vlaktype relevante) MEASURE-RCs, en ontkoppel van alle andere MEASURE-RCs.
                    const newMeasureRcs = ntaMeasureData.getMeasureRcsForBegrVlaktype(prop.getValue(entdata));
                    const newMeasureRcIds = new Set(newMeasureRcs.map(ed => ed.EntityDataId));
                    const oldMeasureRcRelations = ntaEntityData.getChildRelations(entdata, 'MEASURE-RC');
                    for (const relation of oldMeasureRcRelations) {
                        const measureRcId = relation.Child;
                        if (!newMeasureRcIds.has(measureRcId)) {
                            ntaEntityData.deleteRelation(relation.EntityRelationDataId);
                        }
                    }
                    const oldMeasureRcIds = new Set(oldMeasureRcRelations.map(rel => rel.Child));
                    for (const measureRcId of newMeasureRcIds) {
                        if (!oldMeasureRcIds.has(measureRcId)) {
                            ntaEntityData.createRelation(entdata.EntityDataId, measureRcId, false, false);
                        }
                    }
                }
                /// als het vlak/begrenzing wisselt van gevel/dak/vloer boven buitenlucht naar een begrenzing waarbij geen transparante
                /// constructies mogelijk zijn moet de relatie verbroken worden.
                const vlakTypeValue = entdata.PropertyDatas['BEGR_VLAK'].Value;
                const transparanteConstructiesMogelijk = ["VLAK_DAK", "VLAK_GEVEL", "VLAK_VLOER_BOVBUI"].includes(vlakTypeValue);
                if (!transparanteConstructiesMogelijk) {
                    ///controleer of er relaties zijn met een measure-u
                    const measureURelations = ntaEntityData.getChildRelations(entdata, 'MEASURE-U');
                    for (const relation of measureURelations) {
                        ntaEntityData.deleteRelation(relation.EntityRelationDataId);
                    }
                }

                // U-maatregelen
                validateMeasureUs(ntaEntityData.getListWithEntityId('MEASURE-U'));

                // Rc-maatregelen
                validateMeasureRcs(ntaEntityData.getListWithEntityId('MEASURE-RC'));

                // Zonweringsmaatregelen
                validateMeasureZonws(ntaEntityData.getListWithEntityId('MEASURE-ZONW'));

                break;
            }
            case 'BEGR_A': {

                if (constructieGeopend(entdata)) {
                    const constructieLogic = new ConstructiesFactory(entdata.EntityDataId, vm);
                }

                break;
            }
            case 'BEGR_HEL': {

                if (constructieGeopend(entdata)) {
                    const constructieLogic = new ConstructiesFactory(entdata.EntityDataId, vm);

                    const propBeschaduwing = ntabuilding.properties['CONSTRT_BESCH'];
                    constructieLogic.constrTs().forEach(function (constrt, index2) {
                        //-- afhankelijk van helling gelden er andere beschaduwingskeuzes
                        constructieLogic.getCodedValues(propBeschaduwing, constrt);
                    });
                }

                break;
            }
            case 'CONSTRD_LIB':
            case 'CONSTRD_OPP':
            case 'CONSTRWG_OPP': {

                // Rc-maatregelen
                const measureRcEntdatas = ntaEntityData.findEntities(entdata, '^LIBCONSTRD.MEASURE-RC', '^BEGR.MEASURE-RC');
                validateMeasureRcs(measureRcEntdatas);

                // U-maatregelen -> voor als er CONSTRT worden toegevoegd en verwijderd
                const measureUEntdatas = ntaEntityData.findEntities(entdata, '^BEGR.MEASURE-U');
                validateMeasureUs(measureUEntdatas);

                break;
            }
            case 'CONSTRT_LIB': {

                vm.validateDependencies('CONSTRT_OPP', entdata, logic);

                // U-maatregelen
                const measureUEntdatas = ntaEntityData.findEntities(entdata, '^LIBCONSTRT.MEASURE-U', '^BEGR.MEASURE-U');
                validateMeasureUs(measureUEntdatas);

                break;
            }
            case 'CONSTRT_OPP': {

                // U-maatregelen
                const measureUEntdatas = ntaEntityData.findEntities(entdata, '^BEGR.MEASURE-U', '^BEGR.MEASURE-U');
                validateMeasureUs(measureUEntdatas);

                // zonweringsmaatregelen
                const measureZonwEntdatas = ntaEntityData.findEntities(entdata, '^BEGR.MEASURE-ZONW');
                validateMeasureZonws(measureZonwEntdatas);

                break;
            }
            case 'CONSTRT_ZNVENT': {

                if (luchtdoorlatenGeopend()) {
                    const ldLogic = new LuchtdoorlatenFactory(vm);
                    ldLogic.zomernachtIsHidden();

                    vm.validateDependencies('LUCHTZOMNAC_BED', ldLogic.zomernachtData, ldLogic);
                }

                break;
            }
            case 'RZFORM_AANTWOONF': {
                ntaSharedLogic.initTapwUnitsWoon(); //-- hierin wordt gekeken of het aantal badk, keuk, goed staat

                //-- bij het aanmaken van een installationdefine factory wordt de checkErrors uitgevoerd die de aantallen keukens en badkamers checkt.
                const defineLogic = new InstallationFactory(vm);

                break;
            }
            case 'RZFORM_CALCUNIT': {

                const propdata = prop.getData(entdata);

                //-- bij switchen zorg dat de juiste gebouwtype image wordt getoond.
                let berekening = ntaData.projecttree.Gebouwberekeningen.find(x => x.GebouwId === entdata.BuildingId);
                if (propdata.Value === "RZUNIT_PROJECT") {
                    berekening.GebouwIcon = berekening.GebouwIcon.replace(".svg", "_PRJ.svg");
                } else {
                    berekening.GebouwIcon = berekening.GebouwIcon.replace("_PRJ.svg", ".svg");
                }

                //-- zorg dat de juiste unit-specifieke resultaatentiteiten bestaan en relevant worden gezet (of juist niet)
                ntaSharedLogic.checkUnitSpecificResultEntities();

                //-- zet alle install_aantal properties van KOEL, VERW, TAPW en VENT op Touched=false, zodat er gevalideerd wordt, omdat voor "per gebouw" en "per gebouw per appartement/unit"
                //-- de vergrendeling anders gaat voor gem en niet-gem installaties
                ntaSharedLogic.setTouchedFalseAantalIdentiekesystemen(false, false);
                //-- zorg dat de juiste identieke systemen geinitialiseerd worden.
                ntaSharedLogic.setAantalIdentiekeSystemen();
                ntaSharedLogic.initTapwUnitsWoon();

                //-- bij het aanmaken van een installationdefine factory wordt de checkErrors uitgevoerd die de validaties uitvoert.
                const defineLogic = new InstallationFactory(vm);

                //-- gemeenschappelijke ruimten: bij RZ
                if (propdata.Value !== 'RZUNIT_GEB') {
                    //-- dan is invoer Averlies altijd gelijk aan GRUIMTE_AV_INVOER_GR 'bij gemeenschappelijke ruimte;
                    const gruimtes = ntaEntityData.getListWithEntityId('GRUIMTE');
                    for (const gruimte of gruimtes) {
                        const propAvInvoer = ntabuilding.properties['GRUIMTE_AV_INVOER'];
                            if (propAvInvoer)
                                logic.saveValue(propAvInvoer, gruimte, 'GRUIMTE_AV_INVOER_GR');
                    }
                }

                // luchtdoorlaten
                if (luchtdoorlatenGeopend()) {
                    const ldLogic = new LuchtdoorlatenFactory(vm);

                    const propInfilInvoer = ntabuilding.properties['INFIL_INVOER'];
                    const propdataInfilInvoer = propInfilInvoer.getData(ldLogic.infiltratieData);

                    //-- als ik van per gebouw -> voor projectwoningen switch en ik ben infilinvoer INFIL_MWG dan moet infilinvoer naar INFIL_MWW
                    if (propdata.Value === 'RZUNIT_PROJECT' && propdataInfilInvoer.Value === 'INFIL_MWG') {
                        ntaEntityData.saveprop(propdataInfilInvoer, 'INFIL_MWW');
                    }
                    //-- als ik van projectwoningen naar woning switch en ik ben infilinvoer INFIL_MWW dan moet ik INFIL_MWG worden
                    if (propdata.Value === 'RZUNIT_GEB' && propdataInfilInvoer.Value === 'INFIL_MWW') {
                        ntaEntityData.saveprop(propdataInfilInvoer, 'INFIL_MWG');
                    }

                    vm.validateDependencies('INFIL_INVOER', ldLogic.infiltratieData, ldLogic);
                }

                // Verwarming
                const verwInstallations = getVerwarmingInstallations();
                verwInstallations.forEach(function (verwInstallation, index) {
                    if (verwarmingInstallationGeopend(verwInstallation)) {
                        const verwLogic = new VerwarmingFactory(verwInstallation.EntityDataId, vm);
                    }

                });

                // Koeling
                const koelInstallations = getKoelingInstallations();
                koelInstallations.forEach(function (koelInstallation, index) {
                    if (koelingInstallationGeopend(koelInstallation)) {
                        const koelLogic = new KoelingFactory(koelInstallation.EntityDataId, vm);
                    }

                });

                // Tapwater
                const tapwInstallations = getTapwaterInstallations();
                tapwInstallations.forEach(function (tapwInstallation, index) {
                    if (tapwaterInstallationGeopend(tapwInstallation)) {
                        const tapwLogic = new TapwaterFactory(tapwInstallation.EntityDataId, vm);
                    }

                });

                // Ventilatie
                const ventInstallations = getVentilatieInstallations();
                ventInstallations.forEach(function (ventInstallation, index) {
                    if (ventilatieInstallationGeopend(ventInstallation)) {
                        const ventLogic = new VentilatieFactory(ventInstallation.EntityDataId, vm);
                    }

                });

                // Zonneboilers
                for (const zonneboiler of getZonneboilerInstallations()) {
                    if (zonneboilerInstallationGeopend(zonneboiler)) {
                        const zonnbLogic = new ZonneboilerFactory(zonneboiler.EntityDataId, vm);
                        zonnbLogic.startFormValidation();
                    }
                }
                // PVsystemen
                if (ntabuilding.appVersionId > 1) {
                    //-- onderstaande in met name relevant bij projectwoningen en die hadden we in appVersion 1 nog niet.
                    for (const pvInstallatie of getPvInstallations()) {
                        const pvLogic = new PvFactory(pvInstallatie.EntityDataId, vm);

                        const propPVInvoer = ntabuilding.properties['PV_INVOER'];
                        const propdataPvInvoer = propPVInvoer.getData(pvLogic.pvData);

                        //-- als ik van per "gebouw" -> "voor projectwoningen" switch en ik ben infilinvoer PVINVOER_GEB dan moet pvinvoer naar PVINVOER_WONING
                        //-- (PVINVOER_GEB is geen optie bij projectwoningen.bij de overige wel)
                        if (propdata.Value === 'RZUNIT_PROJECT' && propdataPvInvoer.Value === 'PVINVOER_GEB') {
                            pvLogic.saveValue(propPVInvoer, pvLogic.pvData, 'PVINVOER_WONING');
                        }
                        //-- als ik van "projectwoningen, appartementen of units" terug switch "per gebouw" switch en ik ben pvinvoer <> PVINVOER_GEB dan moet ik PVINVOER_GEB worden
                        if (propdata.Value === 'RZUNIT_GEB' && propdataPvInvoer.Value !== 'PVINVOER_GEB') {
                            pvLogic.saveValue(propPVInvoer, pvLogic.pvData, 'PVINVOER_GEB');
                        }

                        pvLogic.startFormValidation();
                    }
                }

                // Windenergie
                //--Indien gekozen voor Projectwoningen moet de Entiteit WINDT, indien aanwezig, inclusief de parent INSTALLTIE op niet-relevant worden gezet.
                //--Indien niet gekozen voor Projectwoningen moet de Entiteit WINDT, indien aanwezig, inclusief de parent INSTALLTIE op relevant worden gezet.
                const windts = ntaEntityData.getListWithEntityId("WINDT", entdata.BuildingId);
                const installaties = windts.flatMap(x => ntaEntityData.getParents(x, "INSTALLATIE"));
                ntaEntityData.setEntityRelevancy(windts.concat(installaties), propdata.Value !== "RZUNIT_PROJECT");

                break;
            }
            case 'UNIT_TYPEGEB':
            case 'UNIT_POS':
            case 'UNIT_TYPEWON':
                {
                    // Luchtdoorlaten
                    if (luchtdoorlatenGeopend()) {
                        const ldLogic = new LuchtdoorlatenFactory(vm); //-- in de init van deze factory wordt calculateAllQV10s() aangeroepen
                    }

                    break;
                }
            case 'UNIT_AANTA':
            case 'UNIT_AANTU': {

                // Verwarming
                const verwInstallations = getVerwarmingInstallations();
                verwInstallations.forEach(function (verwInstallation, index) {
                    if (verwarmingInstallationGeopend(verwInstallation)) {
                        const verwLogic = new VerwarmingFactory(verwInstallation.EntityDataId, vm);
                        verwLogic.verwOpwekkersData().forEach(function (opwekker, index2) {
                            vm.validateDependencies('VERW-OPWEK_POS', opwekker, verwLogic);
                            vm.validateDependencies('VERW-OPWEK_TOEW', opwekker, verwLogic);
                        });
                    }
                });

                if (prop.Id === 'UNIT_AANTA') {
                    ntaSharedLogic.initTapwUnitsWoon(); //-- hierin wordt gekeken of het aantal badk, keuk, goed staat
                }

                //-- wanneer er sprake is een niet-gemeenschappelijke installatie moeten de INSTALL_AANTAL properties op Touched is false, zodat het aantal opnieuw bepaald wordt.
                ntaSharedLogic.setTouchedFalseAantalIdentiekesystemen(true, false);
                ntaSharedLogic.setAantalIdentiekeSystemen();

                // Rc-maatregelen
                const measureRcEntdatas = ntaEntityData.findEntities(entdata, 'UNIT-RZ.BEGR.MEASURE-RC');
                validateMeasureRcs(measureRcEntdatas);

                // U-maatregelen
                const measureUEntdatas = ntaEntityData.findEntities(entdata, 'UNIT-RZ.BEGR.MEASURE-U');
                validateMeasureUs(measureUEntdatas);

                // Zonwering-maatregelen
                const measureZonwEntdatas = ntaEntityData.findEntities(entdata, 'UNIT-RZ.BEGR.MEASURE-ZONW');
                validateMeasureZonws(measureZonwEntdatas);

                break;
            }
            case 'UNIT-RZID': {
                checkTapwaterUnits();
                checkVentilatieUnits();
                //-- en eventueel het aantal identieke systemen voor deze unit met deze rekenzone verwerken.
                ntaSharedLogic.setTouchedFalseAantalIdentiekesystemen(true, true);
                ntaSharedLogic.setAantalIdentiekeSystemen();
                break;
            }
            case 'UNIT-RZAG': {

                // Verwarming
                const verwInstallations = getVerwarmingInstallations();
                verwInstallations.forEach(function (verwInstallation, index) {
                    if (verwarmingInstallationGeopend(verwInstallation)) {
                        const verwLogic = new VerwarmingFactory(verwInstallation.EntityDataId, vm);
                        verwLogic.verwOpwekkersData().forEach(function (opwekker, index2) {
                            vm.validateDependencies('VERW-OPWEK_POS', opwekker, verwLogic);
                            vm.validateDependencies('VERW-OPWEK_TOEW', opwekker, verwLogic);
                        });
                    }
                });

                break;
            }
            case 'UNIT-RZ-GFID': {
                const tapwInstallations = getTapwaterInstallations();
                tapwInstallations.forEach(function (tapwInstallation, index) {
                    if (tapwaterInstallationGeopend(tapwInstallation)) {
                        const tapwLogic = new TapwaterFactory(tapwInstallation.EntityDataId, vm);

                        vm.validateDependencies('TAPW-DISTR_SPORT', tapwLogic.tapwDistributieData, tapwLogic);
                    }
                });

                //gebruikersprofiel branduren
                const gebrprofielLogic = new GebruikersprofielFactory(vm);
                vm.validateDependencies('GEBR-PROFIEL_INV', gebrprofielLogic.entdataGebruikersprofiel, gebrprofielLogic);

                break;
            }
            case 'UNIT-RZ-GFAG': {
                checkVerlichtingUnits();

                // Verwarming
                const verwInstallations = getVerwarmingInstallations();
                verwInstallations.forEach(function (verwInstallation, index) {
                    if (verwarmingInstallationGeopend(verwInstallation)) {
                        const verwLogic = new VerwarmingFactory(verwInstallation.EntityDataId, vm);
                        verwLogic.verwOpwekkersData().forEach(function (opwekker, index2) {
                            vm.validateDependencies('VERW-OPWEK_POS', opwekker, verwLogic);
                            vm.validateDependencies('VERW-OPWEK_TOEW', opwekker, verwLogic);
                        });
                    }
                });

                // Tapwater
                const tapwInstallations = getTapwaterInstallations();
                tapwInstallations.forEach(function (tapwInstallation, index) {
                    if (tapwaterInstallationGeopend(tapwInstallation)) {
                        const tapwLogic = new TapwaterFactory(tapwInstallation.EntityDataId, vm);

                        vm.validateDependencies('TAPW-DISTR_SPORT', tapwLogic.tapwDistributieData, tapwLogic);
                    }
                });

                //-- bij het aanmaken van een installationdefine factory wordt de checkErrors uitgevoerd die oppervlakten voor tapwater checkt.
                const defineLogic = new InstallationFactory(vm);

                //gebruikersprofiel branduren
                const gebrprofielLogic = new GebruikersprofielFactory(vm);
                vm.validateDependencies('GEBR-PROFIEL_INV', gebrprofielLogic.entdataGebruikersprofiel, gebrprofielLogic);

                break;
            }
            case 'GRUIMTE_AG': {
                checkVerlichtingUnits();
                //-- bij het aanmaken van een installationdefine factory wordt de checkErrors uitgevoerd die oppervlakten voor tapwater checkt.
                if (ntaSharedLogic.isUtiliteit()) {
                    const defineLogic = new InstallationFactory(vm);
                }
                break;
            }
            case 'GRUIMTE_AV_INVOER': {
                ///-- wanneer ik wissel van AVerlies bij rekenzones naar AVerlies bij gemeenschappelijke ruimten of visaversa moeten de begrenzingen
                ///-- van deze GRUIMTEN op relevant of niet relevant gezet worden.
                const relevant = entdata.PropertyDatas['GRUIMTE_AV_INVOER'].Value === 'GRUIMTE_AV_INVOER_GR';

                const begrlogic = new BuildingBegrenzingFactory(entdata.EntityDataId, vm); // nieuwe factory aanmaken, moet voor opvragen children omdat bij de initialisatie vlakken worden aangemaakt

                const begrenzingen = ntaEntityData.getChildren(entdata, 'BEGR');
                ntaEntityData.setEntityRelevancy(begrenzingen, relevant);

                //ook meldingen over deze entiteiten aanmaken/verwijderen
                ntaSharedLogic.startFormValidation(begrenzingen, begrlogic);

                let constructies = [];
                for (const begr of begrenzingen) {
                    const constrlogic = new ConstructiesFactory(begr.EntityDataId, vm);

                    const constructies = ntaEntityData.getChildren(begr);
                    ntaEntityData.setEntityRelevancy(constructies, relevant);

                    //ook meldingen over deze entiteiten aanmaken/verwijderen
                    ntaSharedLogic.startFormValidation(constructies, constrlogic);
                }

                break;
            }
            case 'INFIL_INVOER': {

                logic.getInfiltratieUnitDatas().forEach(function (infiltratieUnit, index) {
                    vm.validateDependencies('INFILUNIT_QV', infiltratieUnit, logic);
                    vm.validateDependencies('INFILUNIT_QV_NON', infiltratieUnit, logic);
                });


                break;
            }
            case 'CONSTRT_ZONW': { // C15

                vm.validateDependencies('CONSTRT_GGL_ALT', entdata, logic);
                vm.validateDependencies('CONSTRT_GGL_DIF', entdata, logic);
                vm.validateDependencies('CONSTRT_REGEL', entdata, logic);

                const zonwMeasures = ntaEntityData.getListWithEntityId(['MEASURE-ZONW']);
                for (const zonwMeasure of zonwMeasures) {
                    const measure = ntaEntityData.getFirstParent(zonwMeasure, "MEASURE");
                    const measureLogic = getMeasureLogic(measure);
                    vm.validateDependencies('MEASURE-ZONW_BESTAAND', zonwMeasure, measureLogic);
                }

                break;
            }

            // Installaties
            case 'INSTALL_AANTAL': {
                // de kosten van alle maatregelen én varianten opnieuw berekenen
                const measures = ntaEntityDataOrg.getListWithEntityId('MEASURE');
                for (const measure of measures) {
                    const logic = getMeasureLogic(measure);
                    if (logic && logic.getTotaleKosten) {
                        logic.getTotaleKosten(); // <-- deze slaat evt. gewijzigde kosten ook op
                    }
                }
                const variants = ntaEntityDataOrg.getListWithEntityId('VARIANT');
                vm.calculateCostsVariants(variants);
                break;
            }
            case 'TAPW-UNIT_KEUKENS': {
                const tapwaterEntiteit = ntaEntityData.getFirstParent(entdata, 'TAPW');
                const tapwaterInst = tapwaterEntiteit && ntaEntityData.getFirstParent(tapwaterEntiteit, 'INSTALLATIE');
                if (tapwaterInst && tapwaterInstallationGeopend(tapwaterInst)) {
                    const tapwLogic = new TapwaterFactory(tapwaterInst.EntityDataId, vm);

                    //-- en relevantie van de afgifte propdata's TAPW-AFG_LEI_AANR, TAPW-AFG_DIA_LEI, TAPW-AFG-LEI_LEN_AANR en TAPW-AFG-LEI_DIA_AANR wijzigen als keuken aantal 0 is/was Conditie [TAB]
                    vm.validateDependencies('TAPW-AFG_LEI_AANR', tapwLogic.tapwAfgifteData, tapwLogic);
                    vm.validateDependencies('TAPW-AFG_DIA_LEI', tapwLogic.tapwAfgifteData, tapwLogic);
                    tapwLogic.units.forEach(function (unit, index) {
                        vm.validateDependencies('TAPW-AFG-LEI_LEN_AANR', unit.leiding, tapwLogic);
                        vm.validateDependencies('TAPW-AFG-LEI_DIA_AANR', unit.leiding, tapwLogic);
                    })

                }
                break;
            }
            case 'TAPW-UNIT_BADRUIMTEN': {
                const tapwaterEntiteit = ntaEntityData.getFirstParent(entdata, 'TAPW');
                const tapwaterInst = tapwaterEntiteit && ntaEntityData.getFirstParent(tapwaterEntiteit, 'INSTALLATIE');
                if (tapwaterInst && tapwaterInstallationGeopend(tapwaterInst)) {
                    const tapwLogic = new TapwaterFactory(tapwaterInst.EntityDataId, vm);

                    tapwLogic.tapwDoucheData.forEach(function (doucheWTW, index) {
                        vm.validateDependencies('TAPW-DOUCHE_AANT_TYPE', doucheWTW, tapwLogic);

                        doucheWTW.tempUnits.forEach(function (tempUnit, index2) {
                            vm.validateDependencies('TAPW-DOUCHE-AANG_AANT', tempUnit.douche, tapwLogic);
                        });
                    });

                    //-- en relevantie van de afgifte propdata's TAPW-AFG-LEI_LEN_BAD en TAPW-AFG_LEI_BAD wijzigen als badkamer aantal 0 is/was Conditie [TAA]
                    vm.validateDependencies('TAPW-AFG_LEI_BAD', tapwLogic.tapwAfgifteData, tapwLogic);
                    tapwLogic.units.forEach(function (unit, index) {
                        vm.validateDependencies('TAPW-AFG-LEI_LEN_BAD', unit.leiding, tapwLogic);
                    })
                }
                break;
            }
            case 'KOEL-OPWEK_GEM':
            case 'TAPW-OPWEK_GEM':
            case 'VERW-OPWEK_GEM':
            case 'VENT_GEM':
            case 'VENT_LBK':
                {
                    const systType = prop.Id.substr(0, 4);
                    const systeem = ntaEntityData.getFirstParent(entdata, systType) || entdata;
                    const installation = ntaEntityData.getFirstParent(systeem, 'INSTALLATIE');
                    //-- het installatie_aantal veld van deze entity moet weer ff op Touched=false gezet worden, zodat
                    //-- de initializatie in setAantalIdentiekesystemen goed gaat.
                    ntaSharedLogic.setTouchedFalseAantalIdentiekesystemen(false, false, installation)

                    if (systType === 'TAPW') {
                        ntaSharedLogic.initTapwUnitsWoon(logic.tapwData);
                    }

                    ntaSharedLogic.setAantalIdentiekeSystemen();

                    //-- bij het aanmaken van een installationdefine factory wordt de checkErrors uitgevoerd die de aantallen keukens en badkamers checkt.
                    const defineLogic = new InstallationFactory(vm);

                    ntaSharedLogic.showWarningGemeenschappelijkeInstallatie(prop, entdata);

                    if (prop.Id === 'VERW-OPWEK_GEM') {
                        //-- controleer evt. tapwatersystemen met 'opwekker van verwarmingssysteem'
                        const tapwOpwekkers = ntaEntityData.findEntities(entdata, '^VERW.^TAPW-OPWEK');
                        for (const tapwOpwekker of tapwOpwekkers) {
                            const tapwInst = ntaEntityData.findEntity(tapwOpwekker, '^TAPW.^INSTALLATIE');
                            const tapwLogic = new TapwaterFactory(tapwInst.EntityDataId, vm);
                            vm.validateDependencies('TAPW-OPWEK_VERW-OPWEK', tapwOpwekker, tapwLogic);
                        }
                    }
                    break;
                }
            case 'TAPW-OPWEK_BRON_BOOS_KOEL': {

                const koel = ntaEntityData.getFirstChild(entdata, 'KOEL');
                if (koel) {
                    const installatie = ntaEntityData.getFirstParent(koel, 'INSTALLATIE');

                    if (koelingInstallationGeopend(installatie)) {
                        const koelLogic = new KoelingFactory(installatie.EntityDataId, vm);

                        vm.validateDependencies('KOEL-OPWEK_TYPE', koelLogic.koelOpwekkersData[0], koelLogic);
                        vm.validateDependencies('KOEL-OPWEK_INVOER', koelLogic.koelOpwekkersData[0], koelLogic);
                    }

                }

                break;
            }
            case 'VOORWARM_NT': {

                vm.validateDependencies('VOORWARM_ROO', logic.voorverwarmingData, logic);
                vm.validateDependencies('VOORWARM_AAN', logic.voorverwarmingData, logic);
                vm.validateDependencies('VOORWARM_MAXV', logic.voorverwarmingData, logic);
                vm.validateDependencies('VOORWARM_MAXT', logic.voorverwarmingData, logic);
                vm.validateDependencies('VOORWARM_BUIT', logic.voorverwarmingData, logic);
                vm.validateDependencies('VOORWARM_MAXI', logic.voorverwarmingData, logic);

                logic.units.forEach(function (unit, index) {
                    var unitRekenzones = logic.getUnitRekenzones(unit);
                    unitRekenzones.forEach(function (unitRekenzone, index2) {
                        var ventcapaciteiten = logic.getVentilatiecapaciteit(unitRekenzone);
                        ventcapaciteiten.forEach(function (ventcapaciteit, index3) {
                            vm.validateDependencies('VENTCAP_NV', ventcapaciteit, logic);
                        });
                    });
                });

                vm.validateDependencies('VENTDEB_CAP', logic.ventilatiedebietData, logic);

                break;
            }
            case 'VENTDEB_CAP': {
                vm.validateDependencies('VENTDEB_CAPTAB', logic.ventilatiedebietData, logic);

                break;
            }
            case 'VENTDEB_CAPTAB': {
                logic.units.forEach(function (unit, index) {
                    var unitRekenzones = logic.getUnitRekenzones(unit);
                    unitRekenzones.forEach(function (unitRekenzone, index2) {
                        var ventcapaciteiten = logic.getVentilatiecapaciteit(unitRekenzone);
                        ventcapaciteiten.forEach(function (ventcapaciteit, index3) {
                            logic.ventcapaciteitenProperties.forEach(function (propCap, index4) {
                                vm.validateDependencies(propCap, ventcapaciteit, logic);
                            });
                        });
                    });
                });
                break;
            }

            // Verwarming
            case 'VERW-OPWEK_INVOER': {
                vm.validateDependencies('VERW-OPWEK_ENER', entdata, logic);
                vm.validateDependencies('VERW-OPWEK_ENER_NON', entdata, logic);

                break;
            }
            case 'VERW-OPWEK':
            case 'VERW-OPWEK_TYPE':
            case 'VERW-DISTR_ONTW':
            case 'VERW-DISTR_ONTW_H_MODEL':
            case 'VERW-DISTR_TYPE':
            case 'VERW-OPWEK_POMP':
            case 'VERW-DISTR_AFGIFTE':
            case 'VERW-DISTR_MAX_AANVTEMP_WP': {
                const gekoppeldeTapwOpwekkers = prop.Id === 'VERW-OPWEK_TYPE' // HO05
                    ? new Set(ntaEntityData.findEntities(entdata, '^VERW.^TAPW-OPWEK'))
                    : new Set();

                const openedTapwInstallations = getTapwaterInstallations().filter(tapwaterInstallationGeopend);
                for (const tapwInstallation of openedTapwInstallations) {
                    const tapwLogic = new TapwaterFactory(tapwInstallation.EntityDataId, vm);
                    for (const tapwOpwekker of tapwLogic.tapwOpwekkersData) {
                        if (gekoppeldeTapwOpwekkers.has(tapwOpwekker)) {
                            tapwLogic.validate(null, tapwOpwekker.PropertyDatas['TAPW-OPWEK_TYPE']); // HO05; conditie [TODC]
                        }
                        vm.validateDependencies('TAPW-OPWEK_WARMTE_UIT_VERW', tapwOpwekker, tapwLogic);
                        tapwLogic.validate(null, tapwOpwekker.PropertyDatas['TAPW-OPWEK_TOES']);
                    }
                }
                break;
            }

            //Ventilatie
            case 'VENT_SYS':
            case 'VENT_VARIANT':
            case 'VENTAAN_SYS':
            case 'VENTAAN_VARIANT':
                {
                    if (prop.Id === 'VENT_SYS' || prop.Id === 'VENTAAN_SYS') {
                        ntaSharedLogic.setAantalIdentiekeSystemen();

                        const gebruikersprofiel = ntaEntityData.getFirstChild(entdata, 'GEBR-VENT');
                        if (gebruikersprofiel) {
                            const gebrProfielLogic = new GebruikersprofielFactory(vm);
                            const fPrac = ntaCalcValidation.getFPracVentForfaitair(prop.getValue(entdata));
                            gebrProfielLogic.saveValue('GEBR-VENT_FPRAC_NON', gebruikersprofiel, fPrac);
                        }
                    }

                    //-- VO 2021-06-23: en bij tapwater checken of het ventilatiesysteem nog steeds kan kloppen bij een eventuele ventilatieretourlucht wp
                    const tapwInstallations = getTapwaterInstallations();
                    tapwInstallations.forEach(function (tapwInstallation, index) {
                        const tapwLogic = new TapwaterFactory(tapwInstallation.EntityDataId, vm);
                        const propWPVentSys = ntabuilding.properties['TAPW-OPWEK_WP_VENTSYS'];
                        const propdataWPVentSys = propWPVentSys.getData(tapwLogic.tapwOpwekkersData[0]);
                        tapwLogic.validate(propWPVentSys, propdataWPVentSys);
                    });

                    break;
                }

            //Zonneboiler
            case 'ZONNC_PVT': {

                //PV
                if (ntabuilding.appVersionId === 1) {
                    //-- VO 2021-09-13: alleen bij appversie 1 is er een relatie tussen de pvinstallatie en de zonneboilers. Vanaf versie 2 is dat apart en
                    //-- onderstaande niet meer nodig.
                    const pvInstallations = getPvInstallations();
                    pvInstallations.forEach(function (pvInstallation, index) {
                        const pvLogic = new PvFactory(pvInstallation.EntityDataId, vm);
                        pvLogic.systemen().forEach(function (entdata, index2) {
                            vm.validateDependencies('PV-PVT_TYPESYS', entdata, pvLogic);
                        });
                    });
                }

                break;
            }

            //Koeling
            case 'KOEL-OPWEK_TYPE':
            case 'KOEL-OPWEK_BODEM_BRON_TEMP': {
                const koelInstallations = getKoelingInstallations();
                koelInstallations.forEach(function (koelInstallation, index) {
                    if (koelingInstallationGeopend(koelInstallation) && koelInstallation.EntityDataId !== logic.InstallId) {
                        const koelLogic = new KoelingFactory(koelInstallation.EntityDataId, vm);
                        for (const opwekker of koelLogic.koelOpwekkersData) {
                            vm.validateDependencies('KOEL-OPWEK_BODEM_BRON_TEMP', opwekker, koelLogic);
                        }
                    }
                });
                break;
            }
            case 'MEASURE-ZONW_KOSTEN_TOT':
            case 'MEASURE-U_KOSTEN_TOT':
            case 'MEASURE-RC_KOSTEN_TOT': {
                //variant-parents van de measure nalopen en totale kosten opnieuw berekenen -> calcTotaleKosten
                const measures = ntaEntityDataOrg.getParents(entdata, "MEASURE");
                for (const measure of measures) {
                    const variants = ntaEntityDataOrg.findEntities(measure, "VARIANT-MEASURE.^VARIANT", "VARIANT");
                    vm.calculateCostsVariants(variants);
                }
                break;
            }
            case 'MEASURE-COSTS_PER_SYS':
            case 'MEASURE-COSTS_INV':
            case 'MEASURE-COSTS_PER_M2':
            case 'MEASURE-COSTS_TOTAAL':
            case 'INFIL_KOSTEN_TOT':
            case 'VLEIDING_KOSTEN_TOT': {
                const measureId = ntaSharedLogic.getEditingMeasureId();
                const measure = ntaEntityDataOrg.get(measureId);
                if (measure) {
                    // Dan berekenen we nu opnieuw de kosten van alle varianten die gebruik maken van deze maatregel.
                    // Let op: we moeten de maatregelen en varianten bijwerken in de basisberekening!
                    const variants = ntaEntityDataOrg.findEntities(measure, "VARIANT-MEASURE.^VARIANT", "VARIANT");
                    vm.calculateCostsVariants(variants);
                }
                break;
            }
            case 'ENER-REK_VERBRAANW':
            case 'ENER-REK_DOEL': {
                const gebruikersprofielLogic = new GebruikersprofielFactory(vm);
                vm.validateDependencies('GEBR-PROFIEL_INTWARMTE', gebruikersprofielLogic.entdataGebruikersprofiel, gebruikersprofielLogic);
                vm.validateDependencies('GEBR-PROFIEL_INTWARMTE_APP', gebruikersprofielLogic.entdataGebruikersprofiel, gebruikersprofielLogic);
            }
        }
    };

    // Ontvangt afhankelijk veld dat gevalideerd moet worden /// TODO KAN GEINTEGREERD WORDEN BIJ AANROEP
    vm.validateDependencies = function (prop, entdata, logic) {
        /// eerst checken of prop string is en prop ophalen
        if (typeof prop === 'string') {
            prop = ntabuilding.properties[prop];
        }

        /// dan pas checken of gegevens bestaan in deze versie
        if (!prop || !entdata || !logic) {
            return;
        }

        const propdata = prop.getData(entdata);
        logic.validateDependencies(prop, entdata);
        logic.validate(prop, propdata, entdata);
    };

    vm.calculateCostsVariants = function (variants = ntaEntityDataOrg.getListWithEntityId('VARIANT')) {
        for (const variant of variants) {
            const logic = new VariantFactory(variant.EntityDataId, vm);
            logic.calcTotaleKosten();
        }
    };

    function validateMeasureRcs(measureRcEntdatas) {
        for (const measureRcEntdata of measureRcEntdatas) {
            // We valideren alleen de invoermethode; die zorgt ervoor dat zowel de totaaloppervlakte
            //  en (indien nodig) de totale kosten opnieuw berekend worden.
            const measure = ntaEntityData.getFirstParent(measureRcEntdata, "MEASURE");
            const measureLogic = vm.getMeasureLogic(measure);
            vm.validateDependencies('MEASURE-RC_METHODE', measureRcEntdata, measureLogic);
        }
    } //-- end: validateMeasureRcs ------------------------------------------------------------

    function validateMeasureZonws(measureZonwEntdatas) {
        for (const measureZonwEntdata of measureZonwEntdatas) {
            // We valideren alleen de invoermethode; die zorgt ervoor dat zowel de totaaloppervlakte
            //  en (indien nodig) de totale kosten opnieuw berekend worden.
            const measure = ntaEntityData.getFirstParent(measureZonwEntdata, "MEASURE");
            const measureLogic = vm.getMeasureLogic(measure);
            vm.validateDependencies('MEASURE-ZONW_BEGR_IDS', measureZonwEntdata, measureLogic);
        }
    } //-- end: validateMeasureZonws ----------------------------------------------------------

    function validateMeasureUs(measureUEntdatas) {
        for (const measureUEntdata of measureUEntdatas) {
            // We valideren alleen de invoermethode; die zorgt ervoor dat zowel de totaaloppervlakte
            //  en (indien nodig) de totale kosten opnieuw berekend worden.
            const measure = ntaEntityData.getFirstParent(measureUEntdata, "MEASURE");
            const measureLogic = vm.getMeasureLogic(measure);
            vm.validateDependencies('MEASURE-U_METHODE', measureUEntdata, measureLogic);
        }
    } //-- end: validateMeasureUs -------------------------------------------------------------------//

    function checkActueleVerklaringen() {
        /// als de berekening afgemeld is of vergrendeld dan hoeft deze check niet. Ofwel als ik de
        /// berekening niet mag opslaan/aanpassen
        if (!ntaData.canSaveBuilding()) return;

        /// alleen actuele verklaringen checken indien 'toon alleen actuele verklaringen' aan staat. Als de
        /// property nog niet bestaat in een versie dan is onlyActueleVerklaringen False en wordt er niet gecheckt.
        const onlyActueleVerklaringenPropdata = settingsMenuData.getSetting('SETTINGS_ONLY_ACTU_VERKL');

        if (onlyActueleVerklaringenPropdata && onlyActueleVerklaringenPropdata.Value === "True") {
            /// nu opzoek naar alle properties waar een systeemId van een product in kan zitten.
            /// HO20, HO58, KO11, TO22, TV05, TW08, V06, V12, V16, ZBA06, ZBB02, ZBC02, EPn07, EPn08

            /// verzamel alle propertydatas met systeemIds (ids van producten uit selectietabel)
            const productProperties = ntaData.properties
                .filter(prop => prop.Domain != null && prop.Domain.DomainType === 4);
            const productEntityIds = new Set(productProperties.map(prop => prop.EntityId));
            const productPropertyIds = new Set(productProperties.map(prop => prop.Id));
            // Eerst alle propdatas ophalen van de basisberekening
            const propdatasWithProductIds = ntaData.original.entdatas
                .filter(ed => ed.Relevant)                                      // alleen relevante entityDatas
                .filter(ed => productEntityIds.has(ed.EntityId))                // ... die productinfo bevatten
                .flatMap(ed => ed.PropertyDatas)                                // ... daarvan de propertyDatas
                .filter(pd => pd.Relevant)                                      // ... maar alleen de relevante
                .filter(pd => productPropertyIds.has(pd.PropertyId));           // ... die productinfo bevatten
            // Dan de propdatas van de deltas als deze er zijn.
            const deltasWithProductIds = [...ntaData.deltas.values()]
                    .flatMap(x => [...x.values()])
                    .filter(d => d.Relevant)
                    .filter(d => productPropertyIds.has(d.PropertyId));

            const propdataInfosByProductId = new Map(propdatasWithProductIds.concat(deltasWithProductIds)
                .map(pd => ({ productId: parseInt(pd.Value), entityDataId: pd.EntityDataId, propertyId: pd.PropertyId, shadowId: pd.ShadowId }))
                .filter(x => x.productId)
                .reduce((map, productinfo) => {
                    const array = map.get(productinfo.productId);
                    if (array) {
                        array.push(productinfo);
                    } else {
                        map.set(productinfo.productId, [productinfo]);
                    }
                    return map;                    
                }, new Map())
            );

            const vervallenProductIds = Object.values(ntaData.products)
                .flatMap(ps => ps.filter(p => propdataInfosByProductId
                    .has(p.Id) && p.ExpiryDate != null && Date.parse(p.ExpiryDate) < Date.now()))
                    .map(p => p.Id);
            const verlopenVerklAanwezig = vervallenProductIds.length > 0;

            if (verlopenVerklAanwezig) {
                const systeemNamen = new Set();
                for (const prodId of vervallenProductIds) {
                    let name = "";
                    const usedProducts = propdataInfosByProductId.get(prodId);
                    for (const usedProduct of usedProducts) {
                        if (usedProduct.shadowId) {
                            /// bij een ShadowId wordt het product gebruikt in een maatregel en
                            /// ga ik opzoek naar de maatregelnaam.
                            const maatregel = ntaData.original.get(usedProduct.shadowId);
                            if (maatregel.EntityId === "MEASURE") {
                                name = "maatregel: " + maatregel.PropertyDatas["MEASURE_NAAM"].Value;
                            }
                        } else {
                            const entdataProduct = ntaData.original.get(usedProduct.entityDataId);
                            const installatie = ntaEntityData.getFirstParent(entdataProduct, 'INSTALLATIE', true, "RZ");
                            if (installatie) {
                                name = "installatie: " + installatie.PropertyDatas["INSTALL_NAAM"].Value;
                            }
                        }
                        if (name) {
                            systeemNamen.add(name);
                        }
                    }
                }

                /// toon de melding met de installatienamen
                ntaMeldingen.warning("[W142]", undefined, [{ from: '{naam installaties}', to: '<li>' + [...systeemNamen].map(ntaSharedLogic.htmlEncode).join('</li><li>') + '</li>' }]);

                /// zet de setting op false
                ntaEntityData.saveprop(onlyActueleVerklaringenPropdata, 'False');
            }
        }

    } //-- end: checkActueleVerklaringen ------------------------------------------------------------//

    function setThermischeBruggenDependencies() {
        //-- VO 2022-01-19: Wanneer setting Therm Bruggen wisselt moeten ook de begrenzingen aan AOS/AOR specifiek (met guid) gewisseld worden naar AOR/AOS forfaitair.
        const toggleList = [
            { "Uitgebreid": "AOS_Z", "Forfaitair": "FOR_AOS_Z" },
            { "Uitgebreid": "AOS_ZW", "Forfaitair": "FOR_AOS_ZW" },
            { "Uitgebreid": "AOS_W", "Forfaitair": "FOR_AOS_W" },
            { "Uitgebreid": "AOS_NW", "Forfaitair": "FOR_AOS_NW" },
            { "Uitgebreid": "AOS_N", "Forfaitair": "FOR_AOS_N" },
            { "Uitgebreid": "AOS_NO", "Forfaitair": "FOR_AOS_NO" },
            { "Uitgebreid": "AOS_O", "Forfaitair": "FOR_AOS_O" },
            { "Uitgebreid": "AOS_ZO", "Forfaitair": "FOR_AOS_ZO" },
            { "Uitgebreid": "AOS_HOR", "Forfaitair": "FOR_AOS_HOR" },
            { "Uitgebreid": "AOS_VLOER", "Forfaitair": "VL_AOS_FOR" },
            { "Uitgebreid": "AOR_VLOER", "Forfaitair": "VL_AOR_FOR" },
            { "Uitgebreid": "AOR_DAK", "Forfaitair": "DAK_AOR_FOR" },
            { "Uitgebreid": "AOR_GVL", "Forfaitair": "GVL_AOR_FOR" },
        ];

        //-- de PropertyId die de begrenzing bevat voor elk vlaktype
        const _vlaktypeBegrenzingPropIds = {
            "VLAK_DAK": "BEGR_DAK",
            "VLAK_GEVEL": "BEGR_GEVEL",
            "VLAK_KELDERW": "BEGR_KWAND",
            "VLAK_VLOER": "BEGR_VLOER",
            "VLAK_VLOER_BOVBUI": "BEGR_VLOER_BOVBUI",
        };

        //-- welke berekeningsstatus is er na het wijzigen: methode Forfaitair of Uitgebreid
        const berekeningForfaitair = settingsMenuData.getSetting('SETTINGS_THBRUG').Value === "True";
        //-- uitgangspunt is dat er alleen een conversie plaatst vindt van uitgebreid naar Forfaitari. Bij andersom worden de waarden leeg gemaakt
        const zoekItem = "Uitgebreid";
        const conversieItem = "Forfaitair";

        //-- alle BEGR entdatas verzamelen die een begrenzing hebben met AOR of AOS
        const begrPropIds = ['BEGR_VLOER', 'BEGR_DAK', 'BEGR_GEVEL'];
        const entdatas = ntaEntityData.getListWithEntityId("BEGR")
            .filter(entdata => begrPropIds.some(propId => {
                const value = String(entdata.PropertyDatas[propId].Value);
                return value.includes('AOR') || value.includes('AOS');
            }));

        for (const entdata of entdatas) {
            const vlakTypeValue = entdata.PropertyDatas['BEGR_VLAK'].Value;
            const begrPropId = _vlaktypeBegrenzingPropIds[vlakTypeValue];
            const begrPropdata = entdata.PropertyDatas[begrPropId];
            const conversiedata = toggleList.filter(x => x[zoekItem] === begrPropdata.Value);
            if (conversiedata.length > 0) {
                //-- VO 2022-01-24: als de methode geswitched is naar Uitgebreid moet de value van de begrenzing leeg zijn.
                begrPropdata.Value = berekeningForfaitair ? conversiedata[0][conversieItem] : "";
                ntaEntityData.saveprop(begrPropdata);
            }
        }

        //-- hier moet eigen de libraryFactory aangeroepen worden. In de init van de libraryFactory zit checkConditieD die controleert of de lineaire en puntvormige thermische bruggen wel of niet relevant zijn.
        const koudebruggenRelevant = !(settingsMenuData.getSetting('SETTINGS_THBRUG').Value === "True");
        const thBruggen = ntaEntityData.getListWithEntityId(['LIBCONSTRL', 'LIBTHBRUG']);
        for (const thBrug of thBruggen) {
            ntaEntityData.setEntityRelevancy(thBrug, koudebruggenRelevant);
            ntaEntityData.setEntityVisibility(thBrug, koudebruggenRelevant);
        }
        //-- VO 2021-01-21: Dit moet ook gebeuren voor de constrL en constrP entiteiten.
        const constrLenP = ntaEntityData.getListWithEntityId(['CONSTRL', 'CONSTRP']);
        for (const constr of constrLenP) {
            ntaEntityData.setEntityRelevancy(constr, koudebruggenRelevant);
            ntaEntityData.setEntityVisibility(constr, koudebruggenRelevant);
        }

        vm.validateLineaireConstructies();
    }

    // Zoek UNIT-RZ, GRUIMTE en RZ (alleen voor AOR of AOS)
    //TODO KAN NAAR SHAREDLOGIC
    var getParentEntiteitenBegrenzingen = function () {
        return getUnitRzs()
            .concat(ntaSharedLogic.getGRuimtesForAverlies())
            .concat(getAORenAOS());
    };

    var getUnitRzs = function () {
        return ntaEntityData.getListWithEntityId('UNIT-RZ');
    };

    var getAORenAOS = function () {
        return ntaEntityData.getListWithEntityId('RZ')
            .filter(rz => ['AOR', 'AOS'].includes(rz.PropertyDatas['RZ_TYPEZ'].Value));
    };

    var getBegrenzingen = function () {
        return ntaEntityData.getListWithEntityId('BEGR');
    };

    var getAlleBegrenzingenMetTransparanteConstructie = function () {
        return ntaEntityData.getListWithEntityId('BEGR')
            .filter(begr => ntaEntityData.getChildIds(begr, 'CONSTRT').length > 0);
    };

    var getAllebegrenzingenMetLenPConstr = function () {
        return ntaEntityData.getListWithEntityId('BEGR')
            .filter(begr => ntaEntityData.getChildRelations(begr).some(rel => ['CONSTRL', 'CONSTRP'].includes(rel.ChildEntityId)));
    };

    var getBegrenzingenVoorDichteLibconstructie = function (libConstructieEntdata) {
        const constructies = ntaEntityData.getChildren(libConstructieEntdata)
            .filter(ed => ['CONSTRD', 'CONSTRWG', 'CONSTRWWGVL', 'CONSTRWWKLDR'].includes(ed.EntityId));
        const begrenzingen = new Set(constructies.map(con => ntaEntityData.getFirstParent(con, 'BEGR')));
        return Array.from(begrenzingen);
    };

    var getBegrenzingenVoorTransparanteLibconstructie = function (libConstructieEntdata) {
        const constructies = ntaEntityData.getChildren(libConstructieEntdata, 'CONSTRT');
        const begrenzingen = new Set(constructies.map(con => ntaEntityData.getFirstParent(con, 'BEGR')));
        return Array.from(begrenzingen);
    };

    var getBegrenzingenVoorLineaireLibconstructie = function (libConstructieEntdata) {
        const constructies = ntaEntityData.getChildren(libConstructieEntdata, 'CONSTRL');
        const begrenzingen = new Set(constructies.map(con => ntaEntityData.getFirstParent(con, 'BEGR')));
        return Array.from(begrenzingen);
    };

    var getMeasures = function () {
        return ntaEntityData.getListWithEntityId('MEASURE');
    };

    var getVariants = function () {
        return ntaEntityData.getListWithEntityId('VARIANT');
    };


    /// Onderstaande functie genereert een nieuwe functie die alle installaties van het opgegeven type ophaalt.
    function createInstallationGetter(installationType) {
        return function getInstallations() {
            const installations = ntaEntityData.getListWithEntityId('INSTALLATIE');
            return installations.filter(ed => ed.PropertyDatas['INSTALL_TYPE'].Value === installationType);
        };
    }
    var getVerwarmingInstallations = createInstallationGetter('INST_VERW');
    var getKoelingInstallations = createInstallationGetter('INST_KOEL');
    var getTapwaterInstallations = createInstallationGetter('INST_TAPW');
    var getVentilatieInstallations = createInstallationGetter('INST_VENT');
    var getZonneboilerInstallations = createInstallationGetter('INST_ZONNB');
    var getPvInstallations = createInstallationGetter('INST_PV');
    var getVerlichtingInstallations = createInstallationGetter('INST_VERL');
    var getWindInstallations = createInstallationGetter('INST_WINDT');
    var getBevochtigingInstallations = createInstallationGetter('INST_BEV');

    /// Onderstaande functie genereert een nieuwe functie die controleert of het formulier van de betreffende entiteit geopend is geweest of niet.
    /// Als parameter `relationRequired` truthy is, dan verwacht de nieuwe functie bij aanroep ook een entitydata. (Als 'relationRequired' een string is, wordt die string opgenomen in de foutmelding).
    /// Standaard wordt gezocht naar de propdata met PropertyId `entityId + '_OPEN'`, maar deze kan expliciet opgegeven worden mocht-ie afwijken.
    function createFormGeopendGetter(entityId, relationRequired = null, openPropertyId = entityId + '_OPEN') {
        return function isFormOpened(relatedED = null) {
            if (relationRequired && !relatedED) {
                const relationType = typeof relationRequired === 'string' ? relationRequired : 'relatie';
                throw new Error(`Bij ${entityId} moet een ${relationType} opgegeven worden!`);
            }
            const entityData = relatedED
                ? ntaEntityData.getFirstChild(relatedED, entityId)
                : ntaEntityData.getFirstWithEntityId(entityId);
            const geopendPD = entityData && entityData.PropertyDatas[openPropertyId];
            return !!geopendPD && geopendPD.Value === 'true';
        };
    }
    var algemenegegevensGeopend = createFormGeopendGetter('GEB');
    var bibliotheekGeopend = createFormGeopendGetter('LIBCONSTRFORM');
    var calczonesGeopend = createFormGeopendGetter('RZFORM');
    var begrenzingGeopend = createFormGeopendGetter('BEGR-FORM', 'begrenzing');
    var constructieGeopend = createFormGeopendGetter('CONSTRERROR', 'constructie');
    var luchtdoorlatenGeopend = createFormGeopendGetter('INFIL');
    var verwarmingInstallationGeopend = createFormGeopendGetter('VERW', 'installatie');
    var koelingInstallationGeopend = createFormGeopendGetter('KOEL', 'installatie');
    var ventilatieInstallationGeopend = createFormGeopendGetter('VENT', 'installatie');
    var zonneboilerInstallationGeopend = createFormGeopendGetter('ZONNB', 'installatie');
    var tapwaterInstallationGeopend = createFormGeopendGetter('TAPW', 'installatie');
    var pvInstallation1Geopend = createFormGeopendGetter('PVSYS', 'installatie');
    var pvInstallation2Geopend = createFormGeopendGetter('PV', 'installatie');
    var windenergieInstallationGeopend = createFormGeopendGetter('WINDT', 'installatie');
    var verlichtingInstallationGeopend = createFormGeopendGetter('VERL', 'installatie');
    var bevochtigingInstallationGeopend = createFormGeopendGetter('BEV', 'installatie');


    vm.getTapwOpwekVanWarmtepompRelation = function (installatie, entcode) {
        //-- bij KOEL en VERW gaat het om een boosterwarmtepomp, bij VENT gaat het om een ventilatiewarmtepomp
        const WPrelationEntityData = ntaEntityData.getFirstChild(installatie, entcode);
        return ntaEntityData.getFirstParent(WPrelationEntityData, 'TAPW-OPWEK');
    };

    //// TOT HIER KAN ALLES WAT ER BOVEN STAAT NAAR SHAREDLOGIC

    vm.checkDeletedRekenzone = function (begrenzingenMetRekenzone, installationsMetRekenzone) {
        //-- het kan zijn dat er begrenzingen zijn die grenzen aan deze rekenzone (igv AOR/AOS) dit wordt gecontroleerd in de begrenzing-factory
        for (const begrenzing of begrenzingenMetRekenzone) {
            //-- ik moet eerst opzoek naar de reatie van deze begrenzing met een UNIT-RZ omdat ik die entityDataId mee moet geven. Dit zullen alleen maar begrenzingen zijn van een unitrekenzone of gruimten
            const parent = ntaEntityData.getFirstParent(begrenzing, ['UNIT-RZ', 'GRUIMTE']);
            if (parent) {
                //-- voor deze begrenzing moet de propataValue van BEGR_VLOER, BEGR_GEVEL, BEGR_DAK op null gezet worden.
                const begrenzingLogic = new BuildingBegrenzingFactory(parent.EntityDataId, vm);
                const propVloer = ntabuilding.properties['BEGR_VLOER'];
                const propGevel = ntabuilding.properties['BEGR_GEVEL'];
                const propDak = ntabuilding.properties['BEGR_DAK'];
                begrenzingLogic.saveValue(propVloer, begrenzing, null);
                begrenzingLogic.saveValue(propGevel, begrenzing, null);
                begrenzingLogic.saveValue(propDak, begrenzing, null);
            }
        }

        //-- ook installaties die relaties hebben met deze rekenzone moeten gecontroleerd woden
        const installationsLogic = new InstallationFactory(vm);

        installationsMetRekenzone.forEach(function (installation, index) {
            if (installation.EntityId === 'VERW') {
                ntaSharedLogic.controleerRekenzoneVerwarmingAfgifteRelaties(installation.EntityDataId);
                const inst = ntaEntityData.getFirstParent(installation, 'INSTALLATIE');
                if (verwarmingInstallationGeopend(inst)) {
                    const verwLogic = new VerwarmingFactory(inst.EntityDataId, vm);
                }
            }
            else if (installation.EntityId === 'KOEL') {
                ntaSharedLogic.controleerRekenzoneKoelingAfgifteRelaties(installation.EntityDataId);
                const inst = ntaEntityData.getFirstParent(installation, 'INSTALLATIE');
                if (koelingInstallationGeopend(inst)) {
                    const koelLogic = new KoelingFactory(inst.EntityDataId, vm);
                }
            }
        });

        ///-- controleer voor de opstelplaatsen of deze niet gekoppeld zijn aan een verwijderde rekenzone. Als dat zo is, dan de
        ///-- relatie verwijderen en de value van de property op null zetten.
        const propIdsOpstelplaatsen = ["BOILV_OPSTELPL", "TAPW-OPWEK_OPSTEL", "TAPW-OPWEK_VERBR_OPS", "TAPW-VAT_OPSTEL", "VERW-OPWEK_VERBR_OPS", "VERW-VAT_OPSTEL"];
        for (const propId of propIdsOpstelplaatsen) {
            const prop = ntaData.properties[propId];
            const entdatas = ntaEntityData.getListWithEntityId(prop.EntityId)
            for (const entdata of entdatas) {
                const parent = ntaEntityData.getFirstParent(entdata, 'RZ');
                const propdata = entdata.PropertyDatas[propId];
                ntaEntityData.saveprop(propdata, parent && parent.EntityDataId || null);
            }
        }
    } //-- end: checkDeletedRekenzone ------------------------------------------------------------------------//

    vm.checkAddedRekenzone = function (id) {
        const verwarmingsinstallatie = ntaEntityData.findEntity(id, '^VERW.^INSTALLATIE');
        if (verwarmingsinstallatie) {
            if (verwarmingInstallationGeopend(verwarmingsinstallatie)) {
                const verwLogic = new VerwarmingFactory(verwarmingsinstallatie.EntityDataId, vm);
            }
        }

        const koelinstallatie = ntaEntityData.findEntity(id, '^KOEL.^INSTALLATIE');
        if (koelinstallatie) {
            if (koelingInstallationGeopend(koelinstallatie)) {
                const koelLogic = new KoelingFactory(koelinstallatie.EntityDataId, vm);
            }
        }

        const ventilatieInstallatie = ntaEntityData.findEntity(id, '^VENT.^INSTALLATIE');
        if (ventilatieInstallatie) {
            if (ventilatieInstallationGeopend(ventilatieInstallatie)) {
                const ventLogic = new VentilatieFactory(ventilatieInstallatie.EntityDataId, vm);
            }
        }

        vm.checkGebruikersprofielZone(ntaEntityData.get(id));
    };

    vm.checkGebruikersprofielZone = function (zone) {
        const gebruikersprofiel = ntaEntityData.getFirstWithEntityId('GEBR-PROFIEL');
        if (gebruikersprofiel) {
            const zoneChildren = new Set(ntaEntityData.getChildren(zone));
            if (zone.PropertyDatas['RZ_TYPEZ'].Value === 'RZ') {
                // voor een echte rekenzone, alle koppelentiteiten aanmaken
                const parentRels = [
                    { OnDelete: true, OnCopy: true, Parent: gebruikersprofiel.EntityDataId, ParentEntityId: gebruikersprofiel.EntityId },
                    { OnDelete: true, OnCopy: true, Parent: zone.EntityDataId, ParentEntityId: zone.EntityId },
                ];
                const existingGebrRzsEntityIds = new Set(ntaEntityData.getChildren(gebruikersprofiel)
                    .filter(child => zoneChildren.has(child))
                    .map(child => child.EntityId));
                const gebrRzsEntityIds = ['GEBR-RZ', 'GEBR-RZ-VERW', 'GEBR-RZ-KOEL', 'GEBR-RZ-TAPW', 'GEBR-RZ-BEZF', 'GEBR-RZ-WARMTEPROD', 'GEBR-RZ-VERL'];
                for (const entityId of gebrRzsEntityIds) {
                    if (!existingGebrRzsEntityIds.has(entityId)) {
                        const newId = ntaEntityData.create(entityId, -1, parentRels);
                        zoneChildren.add(ntaEntityData.get(newId));
                    }
                }

                //opzoeken GFs van rekenzone -> rz -> unitrz -> unitrzgf
                //doorloop GFs en maak koppelingen aan tussen GF en GEBR-RZ-WARMTEPROD-GF
                const warmteprod = ntaEntityData.getChildren(gebruikersprofiel, 'GEBR-RZ-WARMTEPROD')
                    .find(child => zoneChildren.has(child));
                const unitrzgfs = ntaEntityData.findEntities(zone, "UNIT-RZ.UNIT-RZ-GF");
                for (const unitrzgf of unitrzgfs) {
                    const unitrzgfChildren = new Set(ntaEntityData.getChildren(unitrzgf));
                    const hasExistingWarmteprodGfs = ntaEntityData.getChildren(warmteprod)
                        .some(child => unitrzgfChildren.has(child));

                    if (!hasExistingWarmteprodGfs) {
                        const parentRels = [
                            { OnDelete: true, OnCopy: true, Parent: warmteprod.EntityDataId, ParentEntityId: warmteprod.EntityId },
                            { OnDelete: true, OnCopy: true, Parent: unitrzgf.EntityDataId, ParentEntityId: unitrzgf.EntityId },
                        ];
                        ntaEntityData.create('GEBR-RZ-WARMTEPROD-GF', -1, parentRels);
                    }
                }
            } else {
                // verwijder evt. koppelingen met deze zone, die kennelijk nu een AOR of AOS is
                for (const childEntdata of ntaEntityData.getChildren(gebruikersprofiel).filter(ed => zoneChildren.has(ed))) {
                    ntaEntityData.delete(childEntdata.EntityDataId);
                }
            }
        }
    };

    vm.checkTapwaterWarmtepomp = function (tapwOpwekker) {
        if (tapwOpwekker) {
            //-- bij KOEL en VERW gaat het om een boosterwarmtepomp, bij VENT gaat het om een ventilatiewarmtepomp
            const tapwater = ntaEntityData.getFirstParent(tapwOpwekker, 'TAPW');
            const installatie = ntaEntityData.getFirstParent(tapwater, 'INSTALLATIE');

            const tapwLogic = new TapwaterFactory(installatie.EntityDataId, vm);

            // Velden controleren als selectietabel is opgehaald
            vm.checkWarmtepompTapwaterMetAndereSystemenNaSelectietabel(tapwLogic, tapwOpwekker);

        }
    };

    vm.checkDeletedKoeling = function () {

    };

    vm.checkDeletedVerwarming = function () {

    };

    vm.checkUnit = function (withChilds = true) {
        ntaSharedLogic.setTouchedFalseAantalIdentiekesystemen(true, true);
        ntaSharedLogic.setAantalIdentiekeSystemen();
        checkVentilatieUnits();
        checkPVVelden();
        //-- Voor woningbouw moet de tapwatercheck in Unit en bij Utitliteit moet dit in UnitRz
        if (!ntaSharedLogic.isUtiliteit()) {
            //-- Tapwater
            checkTapwaterUnits();
        }
        ntaSharedLogic.checkUnitSpecificResultEntities();
        if (withChilds) {
            vm.checkUnitRz();
        }
    };

    vm.checkUnitRz = function (unitRz = null) {

        //-- Luchtdoorlaten
        if (unitRz) {
            //-- unit-rz is toevoegd.
            if (luchtdoorlatenGeopend()) {
                const ldLogic = new LuchtdoorlatenFactory(vm);
                //-- ik moet wel de nieuwe propperites van de aangemaakt leiding valideren dat. Dat moet alleen
                //-- als de verticaleleidingen zichtbaar zijn.
                if (!ldLogic.verticaleLeidingenIsHidden()) {
                    ldLogic.leidingenByUnitRz(unitRz).forEach(function (leiding, index1) {
                        ldLogic.leidingenProperties.forEach(function (prop, index2) {
                            const propdata = prop.getData(leiding);
                            propdata.Touched = true;
                            ldLogic.validate(prop, propdata, leiding);
                        });
                    });
                }
            }
        }

        //-- Ventilatie
        const ventilatieInstallations = getVentilatieInstallations();
        ventilatieInstallations.forEach(function (ventInstallation, index) {
            if (ventilatieInstallationGeopend(ventInstallation)) {
                let ventLogic = new VentilatieFactory(ventInstallation.EntityDataId, vm);
                ventLogic = new VentilatieFactory(ventInstallation.EntityDataId, vm);

                vm.validateDependencies('VENTDEB_CAPTAB', ventLogic.ventilatiedebietData, ventLogic);
            }
        });

        //-- Alleen nodig bij utiliteit
        if (ntaSharedLogic.isUtiliteit()) {

            //-- Tapwater
            checkTapwaterUnits();

            vm.checkUnitRzGf();
        }

    };

    vm.checkUnitRzGf = function () {
        //-- Verlichting
        checkVerlichtingUnits();

        const gebrprofielLogic = new GebruikersprofielFactory(vm);
        vm.validateDependencies('GEBR-PROFIEL_INV', gebrprofielLogic.entdataGebruikersprofiel, gebrprofielLogic);
    };

    vm.checkGemeenschappelijkeRuimte = function () {
        // Verlichting
        checkVerlichtingUnits();
        if (ntaSharedLogic.isUtiliteit()) {
            //-- bij het aanmaken van een installationdefine factory wordt de checkErrors uitgevoerd die oppervlakten voor tapwater checkt.
            const defineLogic = new InstallationFactory(vm);
        }
    };

    vm.checkInfilUnit = function () {
        if (luchtdoorlatenGeopend()) {
            const ldLogic = new LuchtdoorlatenFactory
            //-- wanneer er een nieuwe unit gecreerd wordt moeten de infil units gevalideerd worden
            for (const infilunit of ldLogic.getInfiltratieUnitDatas()) {
                for (const prop of ldLogic.infiltratieUnitsProperties) {
                    const propdata = prop.getData(infilunit);
                    //-- als infilunit nieuw is zijn props nog touched false en worden ze niet gevalideerd. Dan heeft dit geen zin.
                    propdata.Touched = true;
                    ldLogic.validate(prop, propdata, infilunit);
                }
            }
        }
    };

    var checkTapwaterUnits = function () {
        // Tapwater
        const tapwaterInstallations = getTapwaterInstallations();
        tapwaterInstallations.forEach(function (tapwaterInstallation, index) {
            if (tapwaterInstallationGeopend(tapwaterInstallation)) {
                let tapwLogic = new TapwaterFactory(tapwaterInstallation.EntityDataId, vm);
                tapwLogic = new TapwaterFactory(tapwaterInstallation.EntityDataId, vm); // Tweede keer aanmaken, want de eerste keer zijn sommige entiteiten en relaties niet op tijd gemaakt // TODO: WTF?!?

                tapwLogic.leidinggegevensIsHidden();
                tapwLogic.units.forEach(function (unit, index2) {
                    tapwLogic.tapwAfgifteLeidingenProperties.forEach(function (propLeiding, index3) {
                        vm.validateDependencies(propLeiding, unit.leiding, tapwLogic);
                    });
                });

                tapwLogic.tapwDoucheData.forEach(function (doucheWTW, index2) {
                    tapwLogic.aangeslotenDouchesOpWTWIsHidden(doucheWTW);
                    doucheWTW.tempUnits.forEach(function (tempUnit, index2) {
                        tapwLogic.tapwDouchesAangeslotenOpWTWProperties.forEach(function (propDouche, index3) {
                            vm.validateDependencies(propDouche, tempUnit.douche, tapwLogic);
                        });
                    });
                    vm.validateDependencies('TAPW-DOUCHE_AANT_TYPE', doucheWTW, tapwLogic);
                });
            }
        });
    };

    var checkPVVelden = function () {
        ///-- voor alle PV systemen moet gecontroleerd worden of er PV velden ontbreken bij de huidige invoer.
        ///-- dit kan door de dependencyValidation van pvLogic aan te roepen met invoerPv prop.
        for (const pvInstallation of getPvInstallations()) {
            const pvLogic = new PvFactory(pvInstallation.EntityDataId, vm);
            pvLogic.checkPVVelden();
        }
    }

    var checkVentilatieUnits = function () {
        for (const ventInstallation of getVentilatieInstallations()) {
            if (ventilatieInstallationGeopend(ventInstallation)) {
                const ventLogic = new VentilatieFactory(ventInstallation.EntityDataId, vm);
                // ventLogic.checkUnits() wordt uitgevoerd bij initialisatie van de VentilatieFactory
            }
        }
    };

    var checkVerlichtingUnits = function () {
        const verlichtingInstallations = getVerlichtingInstallations();

        verlichtingInstallations.forEach(function (verlInstallation, index) {

            if (verlichtingInstallationGeopend(verlInstallation)) {
                const verlLogic = new VerlichtingFactory(verlInstallation.EntityDataId, vm);

                verlLogic.units.forEach(function (unit, index) {
                    verlLogic.getUnitRekenzones(unit).forEach(function (unitRekenzone, index2) {
                        unitRekenzone.verlzones.forEach(function (verlzone, index3) {
                            verlLogic.propertiesVZ.forEach(function (propVZ, index4) {
                                vm.validateDependencies(propVZ, verlzone, verlLogic);
                            });

                        });
                    });
                });
            }
        });

    };

    // Velden controleren als selectietabel is opgehaald
    vm.checkWarmtepompTapwaterMetAndereSystemenNaSelectietabel = function (tapwLogic, tapwOpwekker) {
        vm.validateDependencies('TAPW-OPWEK_BRON_BOOS', tapwOpwekker, tapwLogic);
        vm.validateDependencies('TAPW-OPWEK_BRON_BOOS_KOEL', tapwOpwekker, tapwLogic);
        vm.validateDependencies('TAPW-OPWEK_WP_VENTSYS', tapwOpwekker, tapwLogic);
        vm.validateDependencies('TAPW-OPWEK_WP_KOELSYS', tapwOpwekker, tapwLogic);
    };

    // Zonneboiler velden controleren na aanmaken relatie
    vm.checkZonneboiler = function (zonnbId) {
        const zonnb = ntaEntityData.get(zonnbId);
        const installatie = ntaEntityData.getFirstParent(zonnb, 'INSTALLATIE');

        if (zonneboilerInstallationGeopend(installatie)) {
            const zonnbLogic = new ZonneboilerFactory(installatie.EntityDataId, vm);
            zonnbLogic.startFormValidation();

            vm.validateDependencies('ZONNB_TYPE', zonnbLogic.systeem, zonnbLogic);
            vm.validateDependencies('KOEL-OPWEK_INVOER', zonnbLogic.systeem, zonnbLogic);
        }
    };

    // Maak verwarming logic aan als deze al eerder is geopend
    vm.createVerwarmingLogic = function (verwInstallation) {
        return getOrCreateDataLogic(verwInstallation);
    };

    /* Hieronder staat de lijst van alle formuliersoorten gedefinieerd, met per formulier:
        * - de entityId van de gekoppelde entiteit (met name tbv het raadplegen van de _OPEN property)
        * - de businesslogic-factory
        * - evt. een getCollection, als het formulier meerdere keren kan voorkomen in een gebouw.
        *   getCollection is dan een functie die een array met entdatas teruggeeft, die elk een eigen formulier hebben.
        * - relation is de omschrijving van het soort entiteit dat getCollection() teruggeeft.
        */
    const _definitions = [
        {
            name: 'Algemene gegevens',
            entityId: 'GEB',
            factory: AttributesFactory,
        },
        {
            name: 'Bibliotheek',
            entityId: 'LIBCONSTRFORM',
            factory: BuildingLibraryFactory,
        },
        {
            name: 'Indeling gebouw',
            entityId: 'RZFORM',
            factory: BuildingCalcZonesFactory,
        },
        {
            name: 'Begrenzingen',
            entityId: 'BEGR-FORM',
            relation: 'begrenzing',
            factory: BuildingBegrenzingFactory,
            getCollection: getParentEntiteitenBegrenzingen,
        },
        {
            name: 'Constructies',
            entityId: 'CONSTRERROR',
            relation: 'constructie',
            factory: ConstructiesFactory,
            getCollection: getBegrenzingen,
        },
        {
            name: 'Luchtdoorlaten',
            entityId: 'INFIL',
            factory: LuchtdoorlatenFactory,
        },
        {
            name: 'Installaties',
            entityId: 'INSTALLATIONS-FORM',
            factory: InstallationFactory,
        },
        {
            entityId: 'VERW',
            relation: 'installatie',
            factory: VerwarmingFactory,
            getCollection: getVerwarmingInstallations,
        },
        {
            entityId: 'KOEL',
            relation: 'installatie',
            factory: KoelingFactory,
            getCollection: getKoelingInstallations,
        },
        {
            entityId: 'TAPW',
            relation: 'installatie',
            factory: TapwaterFactory,
            getCollection: getTapwaterInstallations,
        },
        {
            entityId: 'VENT',
            relation: 'installatie',
            factory: VentilatieFactory,
            getCollection: getVentilatieInstallations,
        },
        {
            entityId: 'ZONNB',
            relation: 'installatie',
            factory: ZonneboilerFactory,
            getCollection: getZonneboilerInstallations,
        },
        {
            entityId: 'VERL',
            relation: 'installatie',
            factory: VerlichtingFactory,
            getCollection: getVerlichtingInstallations,
        },
        {
            entityId: 'BEV',
            relation: 'installatie',
            factory: BevochtigingFactory,
            getCollection: getBevochtigingInstallations,
        },
        {
            entityId: 'PVSYS',
            relation: 'installatie',
            factory: PvFactory,
            getCollection: getPvInstallations,
        },
        {
            entityId: 'PV',
            relation: 'installatie',
            factory: PvFactory,
            getCollection: getPvInstallations,
        },
        {
            entityId: 'WINDT',
            relation: 'installatie',
            factory: WindturbineFactory,
            getCollection: getWindInstallations,
        },
        {
            entityId: 'GEBR-PROFIEL',
            factory: GebruikersprofielFactory,
        },
        {
            entityId: 'ENER-REK',
            factory: EnergierekeningFactory,
        },
        {
            entityId: 'MEASURE',
            getLogic: getMeasureLogic,
            getCollection: getMeasures,
        },
        {
            entityId: 'VARIANT',
            factory: VariantFactory,
            getCollection: getVariants,
        },
        {
            entityId: 'NGEBGEB-E',
            factory: NietGebouwGebondenElektriciteitFactory,
        }
    ];

    const _logics = {};
    function getOrCreateDataLogic(entdata) {
        let entityId = entdata.EntityId;
        if (entdata.EntityId === 'INSTALLATIE') {
            entityId = entdata.PropertyDatas['INSTALL_TYPE'].Value.substr(5); // HACK
        }
        return getOrCreateLogic(entityId, entdata.EntityDataId);
    }
    function getOrCreateLogic(entityId, entityDataId = null) {
        const definition = _definitions.find(def => def.entityId === entityId);
        const requiresId = typeof definition.getCollection === 'function';
        const lookupKey = requiresId ? entityDataId : entityId;
        let logic = _logics[lookupKey];
        if (!logic) {
            if (definition.getLogic) {
                logic = definition.getLogic(entityDataId);
            } else if (requiresId) {
                logic = new definition.factory(entityDataId, vm);
            } else {
                logic = new definition.factory(vm);
            }
            _logics[lookupKey] = logic;
        }
        return logic;
    }
    vm.getLogic = function (entdataOrEntityId) {
        if (typeof entdataOrEntityId === 'string') {
            return getOrCreateLogic(entdataOrEntityId);
        } else {
            return getOrCreateDataLogic(entdataOrEntityId);
        }
    };

    /// hier worden de measure logics gecached. Bij het aanmaken van een nieuwe measurefactoy moet hij in
    /// _logicFactoryByMeasureEntityId toegevoegd worden. Voor het aanroepen van een measurefactory ALTIJD
    /// de getMeasureLogic van de dependency validation service aanroepen met de betreffende MEASURE
    const _measureLogics = new Map();
    const _logicFactoryByMeasureEntityId = new Map(Object.entries({
        'MEASURE-RC': MeasureRcFactory,
        'MEASURE-U': MeasureUFactory,
        'MEASURE-ZONW': MeasureZonweringFactory,
        'MEASURE-INFIL': MeasureInfilFactory,
        'MEASURE-VLEIDING': MeasureVLeidingFactory,
        'MEASURE-HVAC': MeasureHvacFactory,
        'MEASURE-PV': MeasurePvFactory,
        'MEASURE-WINDT': MeasureWindtFactory,
        'MEASURE-VERL': MeasureVerlFactory,
    }));
    vm.getMeasureLogic = getMeasureLogic;
    function getMeasureLogic(measureOrId) { /// hier komt of een guid of een entitydata-object
        const measure = typeof measureOrId === 'string' ? ntaEntityData.get(measureOrId) : measureOrId;

        /// checken of de measure wel echt een measure is.
        if (measure.EntityId !== 'MEASURE') {
            $log.warn('Meegegeven maatregel is niet van type MEASURE', measure);
            return null;
        }

        let logic = _measureLogics.get(measure);
        // De logic is `undefined` als we er nog geen hebben, en `null` als we er geen factory voor hebben.
        if (logic === undefined) {
            const propMeasureType = ntaData.properties["MEASURE_TYPE"];
            const measureType = propMeasureType && propMeasureType.getCode(measure);
            const Factory = measureType && _logicFactoryByMeasureEntityId.get(measureType.FilterValue1);
            if (Factory) {
                logic = new Factory(measure.EntityDataId, vm);
            } else {
                $log.warn('Geen logic factory bekend voor maatregel van type', measureType, measure);
                logic = null;
            }
            _measureLogics.set(measure, logic);
        }
        return logic;
    }

    // Valideer condities die over meerdere entiteiten gaan
    vm.validateLineaireConstructies = function () {
        //-- VO 21-12-2020: Voor de aanwezigheid van lineaire contructies,
        //-- wanneer er NIET forfaitaire gerekend wordt voor koudebruggen.
        const meldingenConstrL = ntaEntityData.getListWithEntityId('MELDING').filter(melding => melding.PropertyDatas['MELD_ERRORID'].Value === '[E078]');
        //-- eerst alle meldingen weggooien
        for (const melding of meldingenConstrL) {
            ntaEntityData.delete(melding.EntityDataId);
        }

        const koudebruggenVisible = settingsMenuData.getSetting('SETTINGS_THBRUG').Value === "False";
        if (koudebruggenVisible) {
            const unitRekenzones = ntaEntityData.getListWithEntityId('UNIT-RZ');
            for (const unitRekenzone of unitRekenzones) {
                let aantalLinConstr = 0;
                //-- haal alle constrL constructie op die relevant zijn
                const begrenzingen = ntaEntityData.getChildren(unitRekenzone, 'BEGR');
                if (begrenzingen.length > 0) {
                    let firstBegrenzing = null;
                    for (const begr of begrenzingen) {
                        const constructieLogic = new ConstructiesFactory(begr.EntityDataId, vm);
                        //-- begrenzingen die of boven of onder maaiveld zijn hebben sowieso al verplicht een constrL die relevant is. Begrenzingen die wel meetellen zijn o.a. buitenlucht, water, AOS-X of AORs.
                        if (!constructieLogic.isBovenMaaiveld && !constructieLogic.isOnderMaaiveld) {
                            if (!firstBegrenzing) {
                                firstBegrenzing = begr;
                            }
                            aantalLinConstr += constructieLogic.validLineaireConstructies();
                        }
                    }
                    const validE078Zone = aantalLinConstr > 0;

                    if (!validE078Zone && firstBegrenzing) {
                        const entConstrError = ntaEntityData.getFirstChild(firstBegrenzing, 'CONSTRERROR');
                        if (entConstrError) {
                            const propdataError = entConstrError.PropertyDatas['CONSTRERROR_LINCONSTR']; //-- de propdata CONSTRERROR_LINCONSTR van de eerste begrenzing
                            //-- naam van unit en rekenzone ophalen
                            const unitName = ntaSharedLogic.getUnitName(unitRekenzone);
                            const zoneName = ntaSharedLogic.getZoneName(unitRekenzone);
                            ntaSharedLogic.updateErrorReplaceObject(propdataError, "unit", unitName);
                            ntaSharedLogic.updateErrorReplaceObject(propdataError, "zone", zoneName);

                            ntaSharedLogic.setMelding('[E078]', propdataError, null, validE078Zone);
                        }
                    }
                }
            }
        }

    } //-- end: validateGlobalConditions ------------------------------------------------------------------------//

    // Valideer alle logics die niet eerder geopend zijn geweest
    vm.validateAllNotOpenedLogics = function () {

        for (const definition of _definitions) {
            const isGeopend = createFormGeopendGetter(definition.entityId, definition.relation);
            if (typeof definition.getCollection === 'function') {
                // dan kunnen er meerdere entiteiten van dit soort voorkomen
                for (const entdata of definition.getCollection()) {
                    if (!isGeopend(entdata)) {
                        const logic = definition.getLogic
                            ? definition.getLogic(entdata)
                            : new definition.factory(entdata.EntityDataId, vm);
                        logic.endFormValidation();
                    }
                }
            } else {
                // dan is er maar één entiteit, en hoeft er geen entdata meegegeven te worden
                if (!isGeopend()) {
                    const logic = new definition.factory(vm);
                    logic.endFormValidation();
                }
            }
        }

    };

    // Valideer alle logics
    vm.validateAllLogics = function () {

        for (const definition of _definitions) {
            if (typeof definition.getCollection === 'function') {
                // dan kunnen er meerdere entiteiten van dit soort voorkomen
                for (const entdata of definition.getCollection()) {
                    const logic = definition.getLogic
                        ? definition.getLogic(entdata)
                        : new definition.factory(entdata.EntityDataId, vm);
                    logic.endFormValidation();
                }
            } else {
                // dan is er maar één entiteit, en hoeft er geen entdata meegegeven te worden
                const logic = new definition.factory(vm);
                logic.endFormValidation();
            }
        }

    };

    // Valideer de logic die bij de opgegeven entdata hoort.
    vm.validateLogic = function (entdata, entityId = null) {
        if (!entityId) entityId = entdata.EntityId;

        const definition = _definitions.find(def => def.entityId === entityId);
        if (definition) {
            const logic = (typeof definition.getLogic === 'function')
                ? definition.getLogic(entdata)
                : (typeof definition.getCollection === 'function')
                    ? new definition.factory(entdata.EntityDataId, vm)
                    : new definition.factory(vm);
            logic.startFormValidation();
        } else {
            $log.warn('Geen logic gevonden voor ' + entityId, entdata);
        }
    };

    vm.onAfterChangeBuilding = onAfterChangeBuilding;

    // We voeren eenmalig alle validaties uit.
    onAfterChangeBuilding();


    // Alle validaties die uitgevoerd moeten worden na openen van een berekening
    function onAfterChangeBuilding(oldBuildingId) {
        // TODO: vm.validateAllOpenedLogics();

        checkActueleVerklaringen();
    }

}]);
