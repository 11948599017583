﻿angular.module('projectModule')
    .controller("BuildingResultsTOjuliController",
        ['$scope', '$controller', 'ntabuilding', '$log', 'ntaValidation', 'ntaEntityData', 'ntaRounding', 'ntaSharedLogic', 'ntaResults', 'BuildingResultsFactory',
function ($scope,   $controller,   ntabuilding,   $log,   ntaValidation,   ntaEntityData,   ntaRounding,   ntaSharedLogic,   ntaResults,   BuildingResultsFactory) {
    'use strict';
    const vm = this;
    const base = $controller('NTABaseController', { $scope: $scope });
    angular.extend(vm, base);

    const resultsLogic = new BuildingResultsFactory();

    ntaResults.checkResultTOjuliRelations();

    const _geb = ntaEntityData.getFirstWithEntityId('GEB');
    const _rzform = ntaEntityData.getFirstWithEntityId('RZFORM');
    const _gebType = _geb.PropertyDatas["GEB_TYPEGEB"].Value;
    const _calcUnit = _rzform.PropertyDatas["RZFORM_CALCUNIT"].Relevant && _rzform.PropertyDatas["RZFORM_CALCUNIT"].Value;

    vm.berekeningen = resultsLogic.getBerekeningen();
    vm.getResult = resultsLogic.getResult;
    vm.getRisicoOververhitting = resultsLogic.getRisicoOververhitting;
    vm.isGebouw = function (id = vm.activeberekening) {
        return resultsLogic.isGebouw(id);
    };
    vm.getIcon = resultsLogic.getIcon;
    vm.isNieuwbouw = ntaSharedLogic.isNieuwbouw;
    vm.isUtiliteit = ntaSharedLogic.isUtiliteit;

    vm.showChooser = () => vm.berekeningen.length > 1;

    vm.conditionG = function () {
        vm.showGTO(); //relevancy zetten van RESULT-TOJULI_GTO

        //-- conditie [G] indien le3-2 als G04='appartementengebouw' en Z23='per gebouw' OF als G04 = 'appartementengebouw' en Z23='per gebouw en appartement' en het betreft de gebouwberekening (eerste tegel), dan alle onderstaande velden op dit formulier verbergen en de volgende tekst tonen (...)
        return ntabuilding.ntaVersionId < 300
            && (vm.isAppPerGebouw() || vm.isAppPerAppartement() && vm.isGebouw());
    }; //-- end: conditionG -----------------------------------------------------------------------

    vm.isAppPerGebouw = function () {
        return _gebType === "TGEB_APPGEB" && _calcUnit === "RZUNIT_GEB";
    };

    vm.isAppPerAppartement = function () {
        return _gebType === "TGEB_APPGEB" && _calcUnit === "RZUNIT_GEBAPP";
    };

    vm.isBerekendPerApp_Unit = function () {
        return _calcUnit === "RZUNIT_GEBAPP" || _calcUnit === "RZUNIT_GEBUNIT";
    };

    vm.conditionW = function () {
        //-- conditie [W] indien ge3-2:
        //   - als G04='appartementengebouw' EN  Z23='per gebouw en appartement' en het betreft de gebouwberekening (eerste tegel)
        //   - als WB EN G04 = 'appartementengebouw' EN  Z23='per gebouw'
        //     dan alle onderstaande velden op dit formulier verbergen en de volgende tekst tonen (...)
        return ntabuilding.ntaVersionId >= 300
            && (vm.isAppPerAppartement() && vm.isGebouw()
                || !vm.isUtiliteit() && !vm.isNieuwbouw() && vm.isAppPerGebouw());
    };

    vm.isVersionLe32 = function () {
        return ntabuilding.ntaVersionId < 300;
    }

    //properties
    vm.TOjuliProperties = ntabuilding.properties['RESULT-TOJULI'].slice();
    vm.TOjuliProperties.forEach(p => vm.TOjuliProperties[p.Id] = p);
    // De lege regels boven TO24, TO27 en TO28 tussenvoegen
    for (const legeRegel of ['RESULT-TOJULI_WEINIG_RAMEN', 'RESULT-TOJULI_AANW_AANV_BER', 'RESULT_TOJULI_RISICO']) {
        const index = vm.TOjuliProperties.findIndex(prop => prop && prop.Id === legeRegel);
        if (index > -1) {
            vm.TOjuliProperties.splice(index, 0, { Id: null, showId: legeRegel });
        }
    }
    vm.GTOProperties = ntabuilding.properties['RESULT-GTO'];
    vm.tempmeth_prop = ntabuilding.properties["RESULT-TOJULI_FORM_TEMPMETH"];
    vm.gto_prop = ntabuilding.properties["RESULT-TOJULI_FORM_GTO"];

    //resultaten
    vm.TOjuliResultaten = [];
    vm.GTOResultaten = [];
    vm.TOjuliForm = null;

    vm.setOrder = function () {
        vm.TOjuliResultaten.forEach(function (x, i) {
            const unitrz = ntaEntityData.findEntity(x, '^RZ.UNIT-RZ', '^UNIT-RZ');
            if (unitrz) {
                x.Order = unitrz.Order;
            }
        });
        vm.GTOResultaten.forEach(function (x, i) {
            const unitrz = ntaEntityData.findEntity(x, '^RZ.UNIT-RZ', '^UNIT-RZ');
            if (unitrz) {
                x.Order = unitrz.Order;
            }
        });
        vm.TOjuliResultaten.sort(ntaSharedLogic.orderElements);
        vm.GTOResultaten.sort(ntaSharedLogic.orderElements);
    };

    vm.getRZName = function (entTOJuli) {
        const rz = ntaEntityData.findEntity(entTOJuli, '^RZ', '^UNIT-RZ.^RZ');
        return rz && rz.PropertyDatas["RZ_OMSCHR"].Value || '';
    };

    vm.selecteerBerekening = function (id) {
        vm.activeberekening = id;

        //check of Id gebouw of unit is
        //Indien gebouw haal rzs op en daarna de TOjulis/GTOs
        //Indien unit haal unitrzs op en daarna de TOjulis/GTOs
        const gebOrUnit = ntaEntityData.get(id);
        const rekenzoneIds = gebOrUnit.EntityId === "GEB"
            ? ntaEntityData.getListWithEntityId('RZ').map(rz => rz.EntityDataId)
            : ntaEntityData.getChildIds(gebOrUnit, "UNIT-RZ");
        const basis = ntaEntityData.getFirstWithEntityId("BASIS")

        let { resultEntdatasByEntityId } = ntaResults.getForParents(null, basis, false, ['RESULT-TOJULI', 'RESULT-GTO']);

        vm.TOjuliResultaten = (resultEntdatasByEntityId.get('RESULT-TOJULI') || [])
            .filter(ed => rekenzoneIds.intersection(ntaEntityData.getParentIds(ed)).length);
        vm.GTOResultaten = (resultEntdatasByEntityId.get('RESULT-GTO') || [])
            .filter(ed => rekenzoneIds.intersection(ntaEntityData.getParentIds(ed)).length);

        ({ resultEntdatasByEntityId } = ntaResults.getForParents(gebOrUnit, basis, false, ['RESULT-TOJULI_FORM']));
        vm.TOjuliForm = (resultEntdatasByEntityId.get('RESULT-TOJULI_FORM') || [])[0];

        vm.setOrder();

        const tile = document.getElementById('berekening+' + id);
        if (tile) tile.scrollIntoView({ block: 'nearest', inline: 'center', behavior: 'smooth', });
    };

    vm.selecteerBerekening(vm.berekeningen[0].Id);


    vm.showTojuli = function () {
        return !vm.isVersionLe32()
            || vm.TOjuliForm.PropertyDatas["RESULT-TOJULI_FORM_TEMPMETH"].Value === "TEMPMETH_TOJULI";
    };

    vm.showGTO = function () { //condition [E]
        return !resultsLogic.isHidden(vm.gto_prop, vm.TOjuliForm);
    };

    vm.hasTOjuliResults = function (prop) {
        switch (prop && prop.Id) {
            case 'RESULT-TOJULI_NOORD':         // TO04
            case 'RESULT-TOJULI_NOORD_OOST':    // TO05
            case 'RESULT-TOJULI_OOST':          // TO06
            case 'RESULT-TOJULI_ZUID_OOST':     // TO07
            case 'RESULT-TOJULI_ZUID':          // TO08
            case 'RESULT-TOJULI_ZUID_WEST':     // TO09
            case 'RESULT-TOJULI_WEST':          // TO10
            case 'RESULT-TOJULI_NOORD_WEST': {  // TO11
                //-- conditie [H] verberg rij indien G04 = appartementengebouw en Z23 = per gebouw; verberg rij indien G04 = appartementengebouw en Z23 = per gebouw en per appartement bij de 'gebouw' berekening (eerste tegel)
                return !vm.isAppPerGebouw()
                    && !(vm.isAppPerAppartement() && vm.isGebouw())
                    && vm.TOjuliResultaten.some(result => prop.getValue(result));
            }
            case 'RESULT-TOJULI_MAX': {         // TO12
                //-- conditie [H] verberg rij indien G04 = appartementengebouw en Z23 = per gebouw; verberg rij indien G04 = appartementengebouw en Z23 = per gebouw en per appartement bij de 'gebouw' berekening (eerste tegel)
                return !vm.isAppPerGebouw()
                    && !(vm.isAppPerAppartement() && vm.isGebouw());
            }
            case 'RESULT-TOJULI_WEINIG_RAMEN':  // TO24
            case 'RESULT-TOJULI_BEP_ZON': {     // TO25
                //-- conditie [I] toon alleen in gt3-2
                return ntabuilding.ntaVersionId >= 300 && !vm.isUtiliteit() && vm.isNieuwbouw();
            }
            case 'RESULT-TOJULI_KOELCAP': {     // TO26
                //-- conditie [J] toon alleen in gt3-2 EN berekening is WN EN als bij minimaal 1 rekenzone is sprake van een actief koelsysteem
                const propKoeling = vm.TOjuliProperties["RESULT_TOJULI_TYPE_KOEL"];
                return ntabuilding.ntaVersionId >= 300
                    && !vm.isUtiliteit()
                    && vm.isNieuwbouw()
                    && !!propKoeling
                    && vm.TOjuliResultaten.some(ed => propKoeling.getValue(ed) === 'RESULT-TOJULI_TYPE_KOEL_ACTIVE');
            }
            case 'RESULT-TOJULI_AANW_AANV_BER': {  // TO27
                return ntabuilding.ntaVersionId >= 300; //condition [V]
            }
            case 'RESULT_TOJULI_RISICO': {  // TO28 -> De underscore is expres ivm de BuildingSummary
                return !vm.isUtiliteit() && vm.isNieuwbouw() && ntabuilding.ntaVersionId >= 300; //condition [N] en [V]
            }
            case 'RESULT_TOJULI_TYPE_KOEL': { // specifiek voor opslag actieve koeling. De underscore is expres ivm de BuildingSummary
                return false;
            }
            case null: {                     // t.b.v. de lege rijen boven TO24, TO27 en TO28
                switch (prop.showId) {
                    case 'RESULT-TOJULI_WEINIG_RAMEN': {
                        return vm.hasTOjuliResults(ntabuilding.properties['RESULT-TOJULI_MAX'])
                            && vm.hasTOjuliResults(ntabuilding.properties['RESULT-TOJULI_WEINIG_RAMEN']); // condition [H]
                    }
                    case 'RESULT-TOJULI_AANW_AANV_BER':
                    case 'RESULT_TOJULI_RISICO': {
                        return ntabuilding.ntaVersionId >= 300; // condition [V]
                    }
                    default: {
                        return true;
                    }
                }
            }
            default: {
                $log.warn(`Unexpected prop.Id in BuildingResultsTOjuliController.hasTOjuliResults:`, prop);
                break;
            }
        }
        return true;
    };

    vm.hasZNV = function () {
        //I20 / I21 niet isHidden
        //[L] toon indien op één van de in de berekening aanwezige 'constructies' formulieren in kolom C17=aanwezig
        return ntaEntityData.getListWithEntityId('CONSTRT')
            .some(ed => ed.PropertyDatas['CONSTRT_ZNVENT'].Value === 'ZOMERNVENT_AANW');
    };

    vm.getGTOProperties = cat => vm.GTOProperties.filter(x => x.Id.startsWith("RESULT-GTO_" + cat));

    // FORM ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    vm.getForm = function () {
        return vm.form_buildingtojuliresults;
    };

    angular.element(function () { //alle touched properties valideren na het laden van form
        vm.startFormValidation();
        base.initializeUnitSpacing();
    });

    vm.startFormValidation = function () {
        resultsLogic.startFormValidation(vm.form_buildingtojuliresults);
    };

    $scope.endFormValidation = function () {
        resultsLogic.endFormValidation(vm.form_buildingtojuliresults);
    };

    vm.saveValue = function (prop, entdata) {
        if (!entdata || !prop || !prop.Id) {
            return;
        }
        var propdata = entdata.PropertyDatas[prop.Id];
        propdata.Touched = true; // opslaan veld is aangeraakt
        if (propdata.Value === undefined) { // niks doen -> undefined komt van een waarschuwing
            return;
        }

        let hidden = false;
        ntaValidation.IsValid(vm.form_buildingtojuliresults, prop, propdata, hidden);
        ntaEntityData.saveprop(propdata);
    };

    vm.getCodedValues = function (prop, entdata) {
        if (!prop || !prop.Id) {
            return [];
        }
        let list = ntaValidation.codedValues(prop);
        switch (prop.Id) {
            case 'RESULT-TOJULI_AANW_AANV_BER': {
                if (vm.isAppPerGebouw()) { //condition [T]
                    list = list.filter(x => x.Id !== 'RESULT-TOJULI_AANW_AANV_BER2');
                }
                //condition [M]
                if (!vm.isUtiliteit() && (!vm.isNieuwbouw() || (entdata.PropertyDatas['RESULT_TOJULI_TYPE_KOEL'].Value !== 'RESULT-TOJULI_TYPE_KOEL_ACTIVE' || entdata.PropertyDatas['RESULT-TOJULI_KOELCAP'].Value === 'nee'))) {
                    list = list.filter(x => x.Id !== 'RESULT-TOJULI_AANW_AANV_BER3' && x.Id !== 'RESULT-TOJULI_AANW_AANV_BER4');
                }
            }
        }

        return list;
    };

    vm.isReadOnly = function (prop, entdata) {
        if (!prop || !prop.Id || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
            return true;
        }
        return true;
    };

    vm.propertyHeaderIsHidden = function (prop) {
        if (!prop || !prop.Id) {
            return;
        }
        return false;
    };

    vm.isHidden = function (prop, entdata) {
        if (!prop || !prop.Id || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
            return;
        }

        let showit = true;
        return !showit;
    };

    vm.getPropDataValue = function (prop, entdata) {
        if (!prop || !prop.Id || !entdata) {
            return;
        }

        let value = prop.getValue(entdata);
        switch (prop.Id) {
            case 'RESULT-TOJULI_WEINIG_RAMEN':  // TO24
            case 'RESULT-TOJULI_BEP_ZON': {     // TO25
                value = {
                    'true': 'ja',
                    'false': 'nee',
                }[value] || '-';
                break;
            }
            case 'RESULT_TOJULI_TYPE_KOEL':
            case 'RESULT_TOJULI_RISICO':
            case 'RESULT-TOJULI_KOELCAP': {
                // laat de waarde onveranderd
                break;
            }
            default: {
                if (prop.Domain?.DomainType === 2) { // coded values
                    value = value && prop.getCode(entdata).Value;
                } else { //nummer
                    // afgeronde waarde laten zien, maar niet mee rekenen
                    value = ntaRounding.roundAndAddZerosNewValue(prop, value);
                    if (typeof value === 'string') {
                        value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
                    }
                }
            }
        }

        return value;
    }; //-- end: getPropDataValue -----------------------------------------------------------------

    vm.getPropData = function (prop, entdata) {
        const propdata = base.getPropData(prop, entdata);
        if (propdata && !propdata.Value) {
            propdata.Value = prop.DefaultValue;
        }
        return propdata;
    };

}]);