﻿angular.module('projectModule')
    .factory("InstallationDataFactory",
        ['ntaData', 'ntaSharedLogic',
function (ntaData,   ntaSharedLogicService) {
    'use strict';

    // De installatie van deze types moeten niet getoond worden in een maatregel
    const _separateMeasureInstallTypes = new Set(['INST_PV', 'INST_WINDT', 'INST_VERL']);

    // Van deze typen installaties kunnen er meerdere zijn (en deze moeten dus standaard een tellertje achter de naam krijgen)
    const _typesWithMultipleInstallations = new Set(['INST_VERW', 'INST_TAPW', 'INST_VENT', 'INST_KOEL', 'INST_ZONNB', 'INST_BEV', 'INST_PV']);

    // Bij deze typen installaties is het aantal relevant, en moet dat dus ook weergegeven worden bij de naam
    const _typesWithCount = new Set(['INST_VERW', 'INST_TAPW', 'INST_VENT', 'INST_KOEL', 'INST_ZONNB']);

    const _collator = new Intl.Collator('nl-NL', { sensitivity: 'base', numeric: true });

    /// ntaEntityData moet expliciet meegegeven worden, omdat deze in de boom gebruikt wordt,
    ///  en die moet altijd met basisberekening werken (ntaData.original).
    return function InstallationDataFactory(ntaEntityData, ntaSharedLogic = ntaSharedLogicService) {
        const self = this;

        /// == Description ========================================================================

        /// Deze InstallationDataFactory bevat enkele functies om installaties op te halen, de naam
        ///  en het bijbehorend icoon. Dit zijn functies die zowel nodig zijn in de
        ///  InstallationTreeController als in de InstallationFactory.
        /// Maar de InstallationTreeController moet altijd kijken naar ntaData.original, terwijl
        ///  de InstallationFactory altijd werkt met ntaData.current, vandaar dat er expliciet
        ///  een ntaEntityData meegegeven moet worden aan de constructor.


        /// == Instance variables =================================================================

        const _typeOrder = new Map(getInstallationTypes().map((code, index) => [code.Id, index + 1]));


        /// == Exports ============================================================================

        Object.assign(self, {
            nonMeasureInstallTypes: _separateMeasureInstallTypes,
            getInstallationTypes,
            getInstallations,
            hasInstallationCount,
            getName,
            getIcon,
            orderInstallations,
            sortInstallationsOfType,
        });


        ///== Initialization ======================================================================


        /// == Implementation =====================================================================

        function getInstallations(...types) {
            const isOriginal = ntaEntityData.getShadowId() === null;
            const isEditingMeasure = !isOriginal && ntaSharedLogic.isEditingMeasure();
            const isEditingMeasurePV = !isOriginal && ntaSharedLogic.isEditingMeasure("MEASURE-PV");
            return ntaEntityData.getListWithEntityId('INSTALLATIE')
                .filter(ed => ed.Relevant === true)
                .filter(ed => !types || !types.length || types.includes(ed.PropertyDatas['INSTALL_TYPE'].Value))
                .filter(ed => !isEditingMeasure
                    || !isEditingMeasurePV && !_separateMeasureInstallTypes.has(ed.PropertyDatas['INSTALL_TYPE'].Value)
                    || isEditingMeasurePV && ed.PropertyDatas['INSTALL_TYPE'].Value === 'INST_PV')
                .sort(orderInstallations);
        } //-- end: getInstallations --------------------------------------------------------------

        function getInstallationTypes() {
            const result = ntaData.properties["INSTALL_TYPE"].Domain.Codes;

            //-- VO 2021-08-18: in Appversion=1 heette pv nog pvsys. Dat hier ff omzetten.
            const [pvId, pvName] = ntaData.ntaVersion.appVersionId > 1
                ? ["INST_PV", "PV"]
                : ["INST_PVSYS", "PV(T)"];
            const typePv = result.find(x => x.Id.startsWith('INST_PV'));
            if (typePv && typePv.Id !== pvId) {
                typePv.Id = pvId;
                typePv.Value = pvName;
            }

            return result;
        } //-- end: getInstallationTypes ----------------------------------------------------------

        function orderInstallations(a, b) {
            const aIndex = _typeOrder.get(a.PropertyDatas['INSTALL_TYPE'].Value) || Number.MAX_SAFE_INTEGER;
            const bIndex = _typeOrder.get(b.PropertyDatas['INSTALL_TYPE'].Value) || Number.MAX_SAFE_INTEGER;

            return aIndex - bIndex
                || a.Order - b.Order;
        } //-- end: orderInstallations ------------------------------------------------------------

        function getName(installation, inclCount = false) {
            //-- VO 2022-03-10: nu we de installatienaam kunnen wijzigen moet de omschrijving weer opgehaald worden uit de Value
            const propdata = installation.PropertyDatas["INSTALL_NAAM"];
            let name = propdata && propdata.Value; //omschrijving
            if (!name) {
                //-- als er nog geen naam is gebruik dan de default waarde.
                name = getDefaultName(installation);
                //-- en sla deze op
                propdata && ntaEntityData.saveprop(propdata, name);
            }

            if (inclCount) {
                const count = getInstallationCount(installation);
                if (!isNaN(count)) {
                    name += " (" + count + "x)";
                }
            }

            return name;
        } //-- end: getName -----------------------------------------------------------------------

        function getDefaultName(installation) {
            //-- geef de default omschrijving van het systeem terug als er nog geen invoer is bij het systeem. Dus
            //-- bv verwarming 1 of tapwater 2.
            const propType = ntaData.properties['INSTALL_TYPE'];
            const code = propType.getCode(installation);
            let name = code && code.Value || 'systeem';

            const instType = propType.getValue(installation);
            if (_typesWithMultipleInstallations.has(instType)) {
                // Bij de installaties waar er meerdere van mogelijk zijn, aangeven de hoeveelste dit is
                const index = getInstallations()
                    .filter(ed => ed.PropertyDatas['INSTALL_TYPE'].Value === instType)
                    .findIndex(ed => ed === installation);
                name += ' ' + (index + 1);
            }

            return name;
        } //-- end: getDefaultName ----------------------------------------------------------------

        function getInstallationCount(installation) {
            const instType = installation.PropertyDatas['INSTALL_TYPE'].Value;
            const countRelevant = _typesWithCount.has(instType);
            if (!countRelevant)
                return NaN;

            let count = parseInt(installation.PropertyDatas['INSTALL_AANTAL'].Value, 10);
            //-- Zonneboiler moet hetzelfde aantal hebben als het tapwatersysteem waar het aan is gekoppeld.
            if (instType === 'INST_ZONNB') {
                const zonneboilerEntdata = ntaEntityData.getFirstChild(installation, 'ZONNB');
                if (zonneboilerEntdata) {
                    //-- als er geen koppeling is met tapwater, kan er een koppeling zijn met verwarming. Dan het aantal
                    //-- van het verwarmingssyteem achter de zonneboiler tonen.
                    const gekoppeldeEntdata = ntaEntityData.getFirstParent(zonneboilerEntdata, 'TAPW')
                        || ntaEntityData.getFirstParent(zonneboilerEntdata, 'VERW');
                    const gekoppeldeInstallationEntdata = ntaEntityData.getFirstParent(gekoppeldeEntdata, 'INSTALLATIE');
                    if (gekoppeldeInstallationEntdata) {
                        count = parseInt(gekoppeldeInstallationEntdata.PropertyDatas['INSTALL_AANTAL'].Value, 10);
                    }
                }
            }
            return count;
        } //-- end: getInstallationCount ----------------------------------------------------------

        function getIcon(installation) {
            const propType = ntaData.properties['INSTALL_TYPE'];
            const codedValue = propType.getCode(installation);
            const instType = propType.getValue(installation);

            let iconUrl = codedValue && codedValue.ImageUrl || '';

            //-- wanneer er DWTW aanwezig is, moet er een ander plaatje komen
            if (iconUrl && instType === 'INST_TAPW') {
                const douches = ntaEntityData.findEntities(installation, 'TAPW.TAPW-DOUCHE');
                if (douches.length > 0) {
                    iconUrl = iconUrl.replace('TAPW', 'TAPW-WTW');
                }
            }

            return iconUrl;
        } //-- end: getIcon -----------------------------------------------------------------------

        function hasInstallationCount(installation) {
            return _typesWithCount.has(installation.PropertyDatas['INSTALL_TYPE'].Value);
        } //-- end: hasInstallationCount ----------------------------------------------------------

        function sortInstallationsOfType(instType) {
            const propName = ntaData.properties['INSTALL_NAAM'];
            const installations = getInstallations()
                .filter(ed => ed.PropertyDatas['INSTALL_TYPE'].Value === instType)
                .sort((a, b) => _collator.compare(...[a, b].map(ed => propName.getValue(ed) || '')) || a.Order - b.Order);
            ntaEntityData.SaveReOrder(installations);
            return installations;
        } //-- end: sortInstallationsOfType -------------------------------------------------------


    }; //== end: InstallationDataFactory ==========================================================
}]); //== end =====================================================================================


angular.module('projectModule')
    .factory("InstallationRelationsFactory",
        ['$log', 'ntaData', 'NtaEntityDataFactory', 'NtaSharedLogicFactory', 'InstallationDataFactory',
function ($log,   ntaData,   NtaEntityDataFactory,   NtaSharedLogicFactory,   InstallationDataFactory) {
    'use strict';

    // Eén van deze twee moet ingevuld zijn
    const defaultOptions = {
        getData: null,         // bv. () => buildingData; deze wordt gebruikt om een nieuwe ntaEntityData te maken m.b.v. de NtaEntityDataFactory.
        ntaEntityData: null,   // bv. ntaEntityData of ntaEntityDataOrg
    };

    return function InstallationRelationsFactory(options = defaultOptions) {
        const self = this;

        /// == Description ========================================================================

        /// De InstallationRelationsFactory houdt alle relaties bij in het formulier.
        /// We maken hier een separate class van, omdat we deze relaties willen kunnen valideren
        ///  voor zowel de huidige omgeving als (in het geval van de basisberekening) ook alle
        ///  maatregelen.
        /// Dan kunnen we voor elke omgeving een separaat object maken. Vandaar dat de
        ///  buildingData van de betreffende omgeving meegegeven moet worden.


        /// == Imports ============================================================================

        const ntaEntityData = typeof options.getData === 'function'
            ? new NtaEntityDataFactory(options.getData, () => ntaData.buildingId)
            : options.ntaEntityData; //-- dan is een bestaande ntaEntityData meegegeven; die hoeft niet ingekapseld.
        const ntaSharedLogic = new NtaSharedLogicFactory(ntaEntityData);


        /// == Instance variables =================================================================

        const _instData = new InstallationDataFactory(ntaEntityData, ntaSharedLogic);

        const _instRelations = [];
        const _instErrors = [];     //-- alle errors die bij het checken verzameld worden voor zowel basis als maatregel


        ///== Exports =============================================================================

        Object.assign(self, {
            errors: _instErrors,

            checkErrors,

            setInstallationsRelations,
            setRelationsByInstallType,

            getTapwUnit,
            getSortedHotInstallations,
            getInstallationRelations,

            deleteInstRelation,
        });


        ///== Initialization ======================================================================

        setInstallationsRelations();


        /// == Implementation =====================================================================

        function checkErrors() {
            /// deze functie checkt de relaties tussen installaties en/of rekenzones.
            /// Zowel voor de huidige berekening (basis of HVAC) als voor alle HVAC maatregelen (als dit de basisberekening is).
            _instErrors.length = 0;

            checkRekenzoneKoppeling('VERW', '[E027]');
            checkSysteemKoppeling('VERW', '[E028]');
            checkMeasureCosts();

            //-- tapwater errors
            const tapwInstRels = _instRelations.filter(x => x.instType === 'TAPW');
            const totnKUnit = [];
            const totnBUnit = [];
            let errorE024 = false;
            const allTapwRelations = tapwInstRels.flatMap(instRel => instRel.relations);

            tapwInstRels.forEach(function (instRel, index) {
                let relTapwCnt = 0;
                instRel.relations.forEach(function (reldata, i) {
                    //-- dit zijn alle relations van deze en de andere tapwatersystemen die hiervoor gelden voor deze unit-(rekenzone).
                    const sameUnitRz = allTapwRelations.filter(x => x.id === reldata.id);
                    if (index === 0) {
                        totnKUnit.push(0);
                        totnBUnit.push(0);

                        //-- als errorE024 al true is hoef ik deze test niet nog een keer toe doen.
                        if (!errorE024 && ntaSharedLogic.isUtiliteit()) {
                            //-- en check op error E024 of alle unitRekenzones een relatie hebben met 1 of meerdere tapwsys
                            if (!reldata.relation) {
                                if (tapwInstRels.length > 1) {
                                    //-- check of de rel.Id van andere tapwatersystemen overeen komt met deze rel.Id en check of deze wel een relation heeft
                                    const isGekoppeld = sameUnitRz.some(unitrz => unitrz.relation);
                                    errorE024 = !isGekoppeld;
                                } else {
                                    //-- als hij niet gekoppeld is, en er is maar één systeem dan de melding weergeven
                                    errorE024 = true;
                                }
                            }
                        }
                    }
                    if (ntaSharedLogic.isUtiliteit()) {
                        if (reldata.relation) {
                            relTapwCnt++;
                            let AgRzVoorAlleTapwIngevuld = 0;
                            sameUnitRz.forEach(function (r) {
                                AgRzVoorAlleTapwIngevuld = r.relation ? AgRzVoorAlleTapwIngevuld + ntaSharedLogic.parseFloat(r.relAg, 0) : AgRzVoorAlleTapwIngevuld;
                            });

                            let errorCode = "";
                            let verschilAg = Math.round((ntaSharedLogic.parseFloat(reldata.relAgMax, 0) - AgRzVoorAlleTapwIngevuld) * 100) / 100;
                            if (verschilAg > 0) {
                                errorCode = "[E025]";
                            } else if (verschilAg < 0) {
                                errorCode = "[E026]";
                            }
                            if (errorCode !== "") {
                                verschilAg = Math.abs(verschilAg);
                                addInstError(errorCode, { 'unit naam': reldata.name, 'Ag': verschilAg.toString() });
                            }
                        }
                    } else { // woningbouw
                        if (reldata.relation) {
                            relTapwCnt++;
                            const aantalIdentiekeSystemen = parseInt(ntaEntityData.get(instRel.id).PropertyDatas['INSTALL_AANTAL'].Value) || 0; //-- Z22
                            const relnK = parseInt(reldata.relnK) || 0;
                            const relnB = parseInt(reldata.relnB) || 0;

                            totnKUnit[i] += relnK * aantalIdentiekeSystemen;
                            totnBUnit[i] += relnB * aantalIdentiekeSystemen;
                            if (relnK + relnB <= 0) {
                                addInstError('[E043]', { 'unit naam': reldata.name });
                            }
                        }
                        if (index === tapwInstRels.length - 1) {
                            //-- deze check doe ik pas bij het laatste tapwInst systeem, omdat ik dan het totaal van de keukens en badkamers heb
                            if (totnBUnit[i] <= 0) {
                                addInstError('[E044]', { 'unit naam': reldata.name });
                            }
                            if (totnKUnit[i] <= 0) {
                                addInstError('[E045]', { 'unit naam': reldata.name });
                            }
                            if (ntaSharedLogic.perGebouw()) {
                                const rzform = ntaEntityData.getFirstWithEntityId('RZFORM');
                                const propdataAantalWoonfuncties = rzform.PropertyDatas['RZFORM_AANTWOONF'];
                                if (propdataAantalWoonfuncties && propdataAantalWoonfuncties.Relevant) {
                                    const aantalWoonfuncties = parseInt(propdataAantalWoonfuncties.Value); //-- Z22
                                    if (aantalWoonfuncties) {
                                        if (totnBUnit[i] < aantalWoonfuncties) {
                                            addInstError('[E046]', { 'woonfuncties': aantalWoonfuncties });
                                        }
                                        if (totnKUnit[i] < aantalWoonfuncties) {
                                            addInstError('[E047]', { 'woonfuncties': aantalWoonfuncties });
                                        }
                                    }
                                }
                            }
                        }
                    }
                });
                if (relTapwCnt === 0) {
                    let errorCode = '[E062]';     //-- Z23=per gebouw
                    if (!ntaSharedLogic.perGebouw()) {
                        if (ntaSharedLogic.isUtiliteit()) {
                            errorCode = '[E063]'; //-- UN/UB en Z23=per gebouw en per unit
                        } else {
                            errorCode = '[E061]'; //-- WN/WB en Z23=per gebouw en per appartement
                        }
                    }
                    addInstError(errorCode, { 'installatie naam': instRel.name });
                }
            });
            if (errorE024) {
                // er moet minstens 1 unitRekenzone gekoppeld zijn aan een tapwatersysteem
                addInstError('[E024]');
            }

            const zonnbInstRels = _instRelations.filter(x => x.instType === 'ZONNB');
            for (const instRel of zonnbInstRels) {
                const trueRelations = instRel.relations.filter(x => x.relation === true);
                //-- één van de relations in relData moet true zijn, anders [E022];
                if (trueRelations.length === 0) {
                    addInstError('[E022]', { 'installatie naam': instRel.name });
                }
                //-- als er meer dan één van de relations in relData true zijn, dan moet het aantal installaties gelijk zijn aan elkaar, anders error 104!
                if (trueRelations.length > 1) {
                    const firstAantal = ntaEntityData.get(trueRelations[0].id).PropertyDatas['INSTALL_AANTAL'].Value;
                    if (!trueRelations.every(x => firstAantal === ntaEntityData.get(x.id).PropertyDatas['INSTALL_AANTAL'].Value)) {
                        addInstError('[E104]', { 'installatie naam': instRel.name });
                    }
                }
            }
            //-- bij koppeling van verw en tapw aan zelfde zonneboiler checken of gemeenschappelijke installatie invoer
            //-- van beide systemen gelijk zijn, anders [E097].
            const zonneboilers = ntaEntityData.getListWithEntityId('ZONNB');
            for (const zonnb of zonneboilers) {
                const tapw = ntaEntityData.getFirstParent(zonnb, 'TAPW');
                const verw = ntaEntityData.getFirstParent(zonnb, 'VERW');
                if (tapw && verw) {
                    const tapwOpwek = ntaEntityData.getFirstChild(tapw, "TAPW-OPWEK");
                    const verwOpwek = ntaEntityData.getFirstChild(verw, "VERW-OPWEK");
                    let valueTapw = tapwOpwek.PropertyDatas['TAPW-OPWEK_GEM'].Value || '';
                    let valueVerw = verwOpwek.PropertyDatas['VERW-OPWEK_GEM'].Value || '';
                    valueTapw = valueTapw.substr(valueTapw.length - 4);
                    valueVerw = valueVerw.substr(valueVerw.length - 4);
                    if (!(valueVerw === valueTapw)) {
                        addInstError('[E097]', { 'installatie naam': _instData.getName(ntaEntityData.getFirstParent(zonnb, 'INSTALLATIE')) });
                    }
                }
            }

            checkRekenzoneKoppeling('VENT', '[E030]');
            checkSysteemKoppeling('VENT', '[E028]');
            checkSysteemKoppeling('KOEL', '[E028]');
            checkSysteemKoppeling('BEV', '[E028]');

            // Controleer dat er geen vinkjes teveel staan
            checkExtraSysteemKoppelingCounts();

            return _instErrors.slice();
        } //-- end: checkErrors -------------------------------------------------------------------

        function checkRekenzoneKoppeling(sysType, errorCode) {
            //-- check of een rekenzone geen relatie heeft met een verwarmingssysteem, dan error [027]
            const hasRzMissingSystem = ntaSharedLogic.rekenzones()
                .some(rz => !ntaEntityData.getFirstParent(rz, sysType));
            if (hasRzMissingSystem) {
                addInstError(errorCode, null);
            }
        } //-- end: checkRekenzoneKoppeling -------------------------------------------------------

        function checkSysteemKoppeling(sysType, errorCode) {
            const systemen = ntaEntityData.getListWithEntityId(sysType);
            for (const systeem of systemen) {
                //-- check of dit systeem een relatie heeft met een rekenzone
                const rekenzone = ntaEntityData.getFirstChild(systeem, 'RZ');
                if (!rekenzone) {
                    const installatie = ntaEntityData.getFirstParent(systeem, 'INSTALLATIE');
                    if (!installatie) {
                        //-- dit is een situatie die niet voor zou moeten komen. Sla dit systeem over, en log het. Geen melding maken, anders kan de gebruiker niet rekenen.
                        $log.error(new Error(`Systeem ${systeem.EntityId} ${systeem.EntityDataId} heeft geen bovenliggende INSTALLATIE!`));
                        continue;
                    }
                    const naam = _instData.getName(installatie);
                    addInstError(errorCode, { 'installatie naam': naam }, ntaEntityData);
                    break;
                }
            }
        } //-- end: checkSysteemKoppeling ---------------------------------------------------------

        function checkExtraSysteemKoppelingCounts() {
            for (const entityId of ['RZ', 'VERW', 'TAPW', 'ZONNB']) {
                for (const entdata of ntaEntityData.getListWithEntityId(entityId)) {
                    const errors = ntaSharedLogic.validateSystemRelationCounts(entdata);
                    /// de meldingen zijn in validateSystemRelationCounts al aangemaakt.
                    _instErrors.push(...errors);
                }
            }
        } //-- end: checkExtraSysteemKoppelingCounts ----------------------------------------------

        function checkMeasureCosts() {
            // *als* we in een maatregel zitten, dan controleren dat er een MEASURE-COST entiteit bestaat voor elke installatie.
            if (ntaSharedLogic.isEditingMeasure()) {
                for (const installation of _instData.getInstallations()) {
                    const costs = ntaEntityData.getFirstChild(installation, 'MEASURE-COSTS');
                    if (!costs) {
                        const parentRels = [
                            { OnCopy: 1, OnDelete: 1, Parent: installation.EntityDataId, ParentEntityId: installation.EntityId }
                        ];
                        ntaEntityData.create('MEASURE-COSTS', -1, parentRels, [], []);
                    }
                }
            }
        } //-- end: checkMeasureCosts -------------------------------------------------------------

        function addInstError(errorCode, placeholders = null) {
            const error = ntaData.errors[errorCode];
            let message = error && error.Value || errorCode;
            if (placeholders) {
                for (const [key, value] of Object.entries(placeholders)) {
                    message = message.replaceAll('{{' + key + '}}', value);
                }
            }
            let instError = _instErrors.find(x => x.name === message);
            if (!instError) {
                instError = {
                    name: message,
                    code: errorCode,
                    shadowId: ntaEntityData.getShadowId(),
                };
                _instErrors.push(instError);
            }
            return instError;
        } //-- end: addInstError ------------------------------------------------------------------

        function setInstallationsRelations() {
            for (const installation of _instData.getInstallations()) {
                //-- als de relaties van deze installatie al voorkomt moet ik alleen de info aanpassen, anders moet ik hem aanmaken
                const instRel = _instRelations.find(x => x.id === installation.EntityDataId);
                if (!instRel) {
                    const systeem = ntaSharedLogic.systeem(installation);
                    const instName = _instData.getName(installation);
                    _instRelations.push({
                        id: installation.EntityDataId,
                        instType: systeem.EntityId,
                        name: instName,
                        relations: [],
                    });
                }
                setRelationsByInstallation(installation);
            }
        } //-- end: setInstallationsRelations -----------------------------------------------------

        function setRelationsByInstallType(installType) {
            const installationsById = new Map(_instData.getInstallations().map(ed => [ed.EntityDataId, ed]));

            const instRels = _instRelations.filter(x => x.instType === installType);
            for (const instRel of instRels) {
                const installation = installationsById.get(instRel.id);
                if (installation) {
                    setRelationsByInstallation(installation);
                }
            }
        } //-- end: setRelationsByInstallType -----------------------------------------------------

        function setRelationsByInstallation(installEntData) {
            const instRel = _instRelations.find(x => x.id === installEntData.EntityDataId);
            if (!instRel) {
                return;
            }

            const systeem = ntaSharedLogic.systeem(installEntData);
            const systeemId = systeem.EntityDataId; //-- de entdataId van de child (verw, tapw, verl enz.) van deze installatie
            switch (systeem.EntityId) {
                case 'VERW':
                case 'VENT':
                case 'KOEL':
                case 'BEV':
                    //-- rekenzones verzamelen
                    for (const rz of ntaSharedLogic.rekenzones()) {
                        const hasRelationsRZ = ntaEntityData.getParentRelations(rz, systeem.EntityId).some(rel => rel.Parent === systeemId);
                        let objRZ = instRel.relations.find(x => x.id === rz.EntityDataId);
                        if (!objRZ) {
                            objRZ = {
                                id: rz.EntityDataId,
                                name: rz.PropertyDatas["RZ_OMSCHR"].Value,
                                relType: 'RZ',
                                relation: hasRelationsRZ,
                            };
                            instRel.relations.push(objRZ);
                        } else {
                            objRZ.relation = hasRelationsRZ;
                        }
                    }
                    break;

                case 'TAPW': {
                    if (ntaSharedLogic.isUtiliteit()) {
                        //-- unit-rekenzones combinaties verzamelen
                        for (const unitRekenzone of ntaEntityData.getListWithEntityId("UNIT-RZ")) {
                            const rekenzone = ntaEntityData.getFirstParent(unitRekenzone, 'RZ');
                            if (!rekenzone) { //geen relatie
                                continue;
                            }
                            const unit = ntaEntityData.getFirstParent(unitRekenzone, 'UNIT');
                            const name = unit.PropertyDatas['UNIT_OMSCHR'].Value + ' - ' + rekenzone.PropertyDatas['RZ_OMSCHR'].Value;

                            let relevantRelation = false;
                            let agTapw = 0;
                            let idAgTapw = '';
                            const agMaxTapw = ntaSharedLogic.AgRz(unitRekenzone, false, false);

                            const tapwUnit = getTapwUnit(systeemId, unitRekenzone.EntityDataId, 'TAPW-UNIT-RZ');
                            const propdataAgTapw = tapwUnit && tapwUnit.PropertyDatas['TAPW-UNIT-RZ_OPP'];
                            if (tapwUnit) {
                                ntaEntityData.setEntityVisibility(tapwUnit, true);
                                relevantRelation = tapwUnit.Relevant;
                                if (propdataAgTapw) {
                                    idAgTapw = propdataAgTapw.PropertyDataId;
                                    agTapw = propdataAgTapw.Value;
                                }
                            }
                            let objTAP = instRel.relations.find(x => x.id === unitRekenzone.EntityDataId);
                            if (!objTAP) {
                                objTAP = {
                                    id: unitRekenzone.EntityDataId,
                                    name: name,
                                    relType: unitRekenzone.EntityId,
                                    relation: relevantRelation,
                                    relAg: agTapw,
                                    relAgMax: agMaxTapw,
                                    idAg: idAgTapw,
                                };
                                instRel.relations.push(objTAP);
                            }
                            //-- als er maar 1 tapwatersysteem is voor u-bouw, heb ik altijd een relatie met deze unit en is TAPW-UNIT-RZ_OPP altijd relevant en MaxTapw moet gekoppeld worden
                            const tapwInst = _instData.getInstallations('INST_TAPW');
                            if (tapwInst.length === 1) {
                                relevantRelation = true;
                                ntaEntityData.setEntityRelevancy(tapwUnit, relevantRelation);

                                objTAP.relAg = agMaxTapw;
                                if (propdataAgTapw) {
                                    const fPropdataValue = ntaSharedLogic.parseFloat(propdataAgTapw.Value, 0);
                                    if (fPropdataValue !== agMaxTapw) {
                                        propdataAgTapw.Value = agMaxTapw;
                                        ntaEntityData.saveprop(propdataAgTapw);
                                    }
                                }
                            }

                            objTAP.relation = relevantRelation;
                        }
                    } else {
                        for (const unit of ntaEntityData.getListWithEntityId("UNIT")) {
                            let relevantRelation = false;
                            let keuk = 0;
                            let badr = 0;
                            let idBkTapw = '';
                            let idKkTapw = '';
                            const tapwUnit = getTapwUnit(systeemId, unit.EntityDataId, 'TAPW-UNIT');
                            if (tapwUnit) {
                                ntaEntityData.setEntityVisibility(tapwUnit, true);
                                relevantRelation = tapwUnit.Relevant;
                                const propdataBadr = tapwUnit.PropertyDatas['TAPW-UNIT_BADRUIMTEN'];
                                const propdataKeuk = tapwUnit.PropertyDatas['TAPW-UNIT_KEUKENS'];
                                if (propdataBadr) {
                                    idBkTapw = propdataBadr.PropertyDataId;
                                    badr = propdataBadr.Value;
                                }
                                if (propdataKeuk) {
                                    idKkTapw = propdataKeuk.PropertyDataId;
                                    keuk = propdataKeuk.Value;
                                }
                            }
                            const name = unit.PropertyDatas['UNIT_OMSCHR'].Value;
                            let objTAP = instRel.relations.find(x => x.id === unit.EntityDataId);
                            if (!objTAP) {
                                objTAP = {
                                    id: unit.EntityDataId,
                                    name: name,
                                    relType: unit.EntityId,
                                    relation: relevantRelation,
                                    relnK: keuk,
                                    relnB: badr,
                                    idBk: idBkTapw,
                                    idKk: idKkTapw,
                                };
                                instRel.relations.push(objTAP);
                            }
                            objTAP.relation = relevantRelation;
                        }
                    }
                    break;
                }

                case 'ZONNB':
                    //-- eerst de relations leeg maken. Als er verw of tapw systemen verwijderd zijn doen ze niet meer mee
                    instRel.relations = [];
                    //-- verwarmings en tapwaterinstallaties verzamelen
                    for (const inst of getSortedHotInstallations()) {
                        const sys = ntaSharedLogic.systeem(inst);
                        const hasRelationsINST = ntaEntityData.getChildRelations(sys, systeem.EntityId).some(rel => rel.Child === systeemId);
                        const objINST = {
                            id: inst.EntityDataId,
                            name: _instData.getName(inst),
                            relType: sys.EntityId,
                            relation: hasRelationsINST,
                        };
                        instRel.relations.push(objINST);
                    }
                    break;

                case 'WINDT':
                case 'VERL':
                case 'PV':
                    break;
            }
        } //-- end: setRelationsByInstallation ----------------------------------------------------

        function getSortedHotInstallations() {
            //-- eerst tapwater, daarna verwarming
            const types = ['INST_TAPW', 'INST_VERW'];
            return _instData.getInstallations(...types)
                .sort((a, b) => types.indexOf(a.PropertyDatas['INSTALL_TYPE'].Value) - types.indexOf(b.PropertyDatas['INSTALL_TYPE'].Value) || a.Order - b.Order);
        } //-- end: getSortedHotInstallations -----------------------------------------------------

        function getTapwUnit(sysId, unitId, childType) {
            // check of het systeem TAPW-UNIT-(RZ) heeft
            const tapwKoppelingIds = ntaEntityData.getChildIds(sysId, childType); // zoek onder TAPW
            const unitKoppelingIds = new Set(ntaEntityData.getChildIds(unitId, childType)); // zoek onder UNIT(-RZ)
            // degene die we zoeken, zit in beide lijsten
            const koppelingId = tapwKoppelingIds.find(tapwKoppelingId => unitKoppelingIds.has(tapwKoppelingId));
            const tapwUnit = ntaEntityData.get(koppelingId)
                // als de betreffende koppel-entiteit niet bestaat, dan moeten we ’m maken
                || ntaSharedLogic.createTapwUnitAndRelations(sysId, unitId, childType === 'TAPW-UNIT' ? 'UNIT' : 'UNIT-RZ', childType, false);
            return tapwUnit;
        } //-- end: getTapwUnit -------------------------------------------------------------------

        function deleteInstRelation(id) {
            const index = _instRelations.findIndex(x => x.id === id);
            _instRelations.splice(index, 1);
        } //-- end: deleteInstRelation ------------------------------------------------------------

        function getInstallationRelations(installDataId) {
            const instRel = _instRelations.find(x => x.id === installDataId);
            return instRel && instRel.relations || [];
        } //-- end: getInstallationRelations -------------------------------------------------------

    } //== end: InstallationRelationsFactory ======================================================
}]); //== end =====================================================================================


angular.module('projectModule')
    .factory("InstallationFactory",
        ['ntabuilding', 'ntaEntityData', 'ntaValidation', '$log', '$mdDialog', 'progressCircle', 'ntaSharedLogic', 'ntaMeldingen', 'ntaResults', 'InstallationDataFactory', 'InstallationRelationsFactory',
function (ntabuilding,   ntaEntityData,   ntaValidation,   $log,   $mdDialog,   progressCircle,   ntaSharedLogic,   ntaMeldingen,   ntaResults,   InstallationDataFactory,   InstallationRelationsFactory) {
    'use strict';

    return function InstallationLogic(ntaDependencyValidation) {
        const installationLogic = this;

        const _instData = new InstallationDataFactory(ntaEntityData);
        const _instRelations = new InstallationRelationsFactory({ ntaEntityData });

        Object.assign(installationLogic, _instData);

        const _instSummaries = {};

        installationLogic.properties = ntabuilding.properties['INSTALLATIE'];
        installationLogic.propertiesTapwUnitRz = ntabuilding.properties['TAPW-UNIT-RZ'];
        installationLogic.propertiesTapwUnit = ntabuilding.properties['TAPW-UNIT'];

        installationLogic.aantalSystemenName = installationLogic.properties["INSTALL_AANTAL"].Name;

        installationLogic.installations = _instData.getInstallations;

        installationLogic.installationsFormEntdata = ntaEntityData.getFirstWithEntityId('INSTALLATIONS-FORM');
        installationLogic.installationTypes = _instData.getInstallationTypes();
        installationLogic.getInstallationName = _instData.getName;
        installationLogic.saveSummaryInput = saveSummaryInput;
        installationLogic.setInstallationsRelations = _instRelations.setInstallationsRelations;

        installationLogic.sortInstallationsOfType = _instData.sortInstallationsOfType;

        installationLogic.isHidden = isHidden;
        installationLogic.isReadOnly = isReadOnly;
        installationLogic.validate = validate;
        installationLogic.startFormValidation = startFormValidation;
        installationLogic.endFormValidation = endFormValidation;

        if (!installationLogic.installationsFormEntdata) {
            const newId = ntaEntityData.create('INSTALLATIONS-FORM', -1, [], [], []);
            installationLogic.installationsFormEntdata = ntaEntityData.get(newId);
        }

        installationLogic.getInstTypeName = function (entdata) {
            //-- vraag de naam van het installatiesysteem op. Bv verwarming of tapwater
            const type = entdata && entdata.PropertyDatas["INSTALL_TYPE"].Value;
            if (type) {
                return type.toLowerCase();
            }
            return "_";
        };

        installationLogic.getInstTypeCode = function (entdata) {
            //-- vraag de code op voor het systeem bij voorbeeld VERW, TAPW, VENT enz.
            if (entdata) {
                const instTypeName = installationLogic.getInstTypeName(entdata).toUpperCase();
                if (instTypeName !== "_") {
                    return instTypeName.substring(5, instTypeName.length);
                }
            }

            return -1;
        };

        function saveDefaultInstallationName(installation) {
            // getName zorgt ervoor dat er een standaard naam ingesteld wordt als er nog geen was.
            _instData.getName(installation);
        } //-- end: saveDefaultInstallationName ---------------------------------------------------

        installationLogic.instDescription = function (entdataOrId) {
            //-- geef de default omschrijving (samenvatting) van het systeem terug als er nog geen invoer is bij het systeem.
            let descr = "";
            const [entdata, entdataId] = getEntdataAndId(entdataOrId);
            if (entdata && entdata.BuildingId !== -1) {
                const propdataOmschr = entdata.PropertyDatas['INSTALL_OMSCHR'];
                descr = propdataOmschr.Value;
            }
            if (!descr) {
                descr = 'Nog geen invoer gedaan voor deze installatie';
            }
            return descr;
        } //-- end: instDescription ------------------------------------------------------------------------//

        installationLogic.systId = function (entdataOrId) {
            //-- op basis van de Id van de installatie moet ik in de relatietabel opzoek naar welk child hier bij hoort. Van dit child moet ik de Id weten en in de boom zetten
            const [installation] = getEntdataAndId(entdataOrId);
            const syst = ntaSharedLogic.systeem(installation);
            return syst && syst.EntityDataId || '';
        } //-- end: systId ------------------------------------------------------------------------//

        installationLogic.instError = function () {
            return _instRelations.errors;
        }; //-- end: instError --------------------------------------------------------------------

        installationLogic.checkErrors = function () {
            const errors = _instRelations.checkErrors();

            // Als we in de basisberekening zitten, en we zijn aan het controleren omdat er net een relatie gewijzigd is,
            //  of een installatie bijgekomen/verwijderd, dan moeten we een aantal controles uitvoeren
            //  voor elke HVAC-maatregel, omdat daar nu dubbele relaties in zouden kunnen zitten.
            // Zie ook https://trello.com/c/mSdZdj7O/1646-toevoegen-en-verwijderen-basisberekening.
            const currentShadowId = ntaEntityData.getShadowId();
            if (!currentShadowId) {
                const hvacMeasures = ntaEntityData.getListWithEntityId('MEASURE')
                    .filter(measure => measure.PropertyDatas['MEASURE_TYPE'].Value === 'MEASURE-HVAC_INSTALLATIE');
                for (const measure of hvacMeasures) {
                    const buildingData = ntaSharedLogic.getVariantBuildingData(measure.EntityDataId);
                    const instRelations = new InstallationRelationsFactory({ getData: () => buildingData });

                    errors.push(...instRelations.checkErrors());
                }
            }

            // Zoek bestaande meldingen op voor dit formulier en verwijder meldingen waarvan de error niet meer aanwezig is
            for (const melding of getMeldingenInstallatiesFormulier(!currentShadowId)) {
                if (!errors.some(x => {
                    const propdataErrorId = melding.PropertyDatas['MELD_ERRORID'];
                    const propdataShadowId = melding.PropertyDatas['MELD_SHADOWID'];
                    return x.meldingId === melding.EntityDataId
                        || (x.code === propdataErrorId.Value && (!propdataShadowId || x.shadowId === propdataShadowId.Value));
                })) {
                    ntaMeldingen.delete(melding);
                }
            }

            // Maak melding voor elke error (die niet al een melding heeft)
            for (const error of errors.filter(ie => !ie.meldingId)) {
                ntaMeldingen.melding(error.code, installationLogic.installationsFormEntdata.EntityDataId, false, error.name, error.shadowId || currentShadowId);
            }
        }; //-- end: installationLogic.checkErrors ------------------------------------------------

        function getMeldingenInstallatiesFormulier(includingMeasures) {
            const currentShadowId = ntaEntityData.getShadowId();
            return ntaMeldingen.getMeldingen()
                .filter(entdata => {
                    const propdataInputId = entdata.PropertyDatas['MELD_INPUTID'];
                    const propdataShadowId = entdata.PropertyDatas['MELD_SHADOWID'];
                    return propdataInputId && propdataInputId.Value.startsWith(installationLogic.installationsFormEntdata.EntityDataId)
                        && (includingMeasures || !propdataShadowId || propdataShadowId.Value === currentShadowId);
                });
        } //-- end: getMeldingenInstallatiesFormulier ---------------------------------------------

        ntaSharedLogic.setAantalIdentiekeSystemen();

        function setSummaryByInstall(installEntData, summArray) {
            //-- voor de validatie van het veld "aantal identieke systemen" moet de propertyDataId van de prop Aantal opgehaald worden
            const propdataAantal = installEntData.PropertyDatas['INSTALL_AANTAL'];

            const summary = {
                id: propdataAantal.PropertyDataId,
                entId: installEntData.EntityDataId,
                descr: _instData.getName(installEntData),
                aantal: -1,
                text: installationLogic.instDescription(installEntData),
                readOnly: ntaSharedLogic.aantalSystemenReadOnly(installationLogic.systId(installEntData)),
            };

            summArray.length = 0;
            const type = installationLogic.getInstTypeCode(installEntData);
            switch (type) {
                case 'VERW':
                case 'TAPW':
                case 'VENT':
                case 'KOEL':
                    summary.aantal = propdataAantal.Value;
                    summArray.push(summary);
                    break;

                case 'VERL':
                case 'BEV':
                case 'PV':
                case 'ZONNB':
                    //-- geen identieke systemen, dus aantal -1
                    summary.aantal = -1;
                    summArray.push(summary);
                    break;

                case 'WINDT':
                default:
                    break;
            }
        } //-- end: setSummaryByInstall ------------------------------------------------------------------------//

        function setInstallationsSummary() {
            for (const install of installationLogic.installations()) {
                //-- als summary van deze installatie al voorkomt moet ik alleen de info aanpassen ander moet ik hem aanmaken
                const summary = _instSummaries[install.EntityDataId]
                    || (_instSummaries[install.EntityDataId] = []);
                setSummaryByInstall(install, summary);
            }
        } //-- end: setInstallationsSummary ------------------------------------------------------------------------//

        setInstallationsSummary();

        installationLogic.getInstallationSummary = function (installDataId) {
            return _instSummaries[installDataId] || [];
        } //-- end: getInstallationSummary ------------------------------------------------------------------------//

        function isPropdataHidden(propdata, form = installationLogic.form_installationsDefine) {
            const control = form && form['ntainput' + propdata.PropertyDataId];
            return !control;
        } //-- end: isPropdataHidden ------------------------------------------------------------//

        function saveSummaryInput(summ) {
            const propAantal = installationLogic.properties['INSTALL_AANTAL'];
            const installation = ntaEntityData.get(summ.entId);
            const propdataAantal = installation.PropertyDatas.find(pd => pd.PropertyDataId === summ.id);
            if (propdataAantal && propdataAantal.Value !== summ.aantal) {
                propdataAantal.Value = summ.aantal;
                const hidden = isPropdataHidden(propdataAantal);
                if (ntaValidation.IsValid(installationLogic.form_installationsDefine, propAantal, propdataAantal, hidden)) {
                    propdataAantal.Touched = true;
                    ntaEntityData.saveprop(propdataAantal);
                    //-- het kan zijn dat de validate de waarde afgerond heeft en moet daarom opnieuw weergegeven worden.
                    summ.aantal = propdataAantal.Value;
                    installationLogic.checkErrors();

                    ntaDependencyValidation.checkChangedField(propAantal, installation, installationLogic); // <-- deze zorgt ervoor dat de kosten van de varianten ook berekend worden
                }
            }
        } //-- end: saveSummaryInput ------------------------------------------------------------------------//

        installationLogic.units = function () {
            return ntaEntityData.getListWithEntityId("UNIT");
        };

        installationLogic.unitsrz = function () {
            return ntaEntityData.getListWithEntityId("UNIT-RZ"); //-- alleen nodig bij utiliteitsbouw
        };

        installationLogic.installs = function () {
            return _instRelations.getSortedHeatingInstallations();
        };

        installationLogic.getTapwUnitEntData = function (install, relation) {
            if (install && relation) {
                const sTypeCode = installationLogic.getInstTypeCode(install);
                const sTypeId = installationLogic.systId(install); //-- de entdataId van de child (verw, tapw, verl enz.) van deze installatie
                if (sTypeCode !== 'TAPW') {
                    return null;
                }

                const childType = relation.relType === 'UNIT' ? 'TAPW-UNIT' : 'TAPW-UNIT-RZ';
                const unitId = relation.id;

                return _instRelations.getTapwUnit(sTypeId, unitId, childType);
            }

            return null;
        } //-- end: getTapwUnitEntData ------------------------------------------------------------------------//

        installationLogic.getInstallationRelations = _instRelations.getInstallationRelations;

        installationLogic.showRelations = function (inst) {
            //-- bij tapwater utitliteits gebouw hoef ik bij één systeem geen relaties te laten zien.
            if (installationLogic.getInstTypeCode(inst) === 'TAPW'
                && ntaSharedLogic.isUtiliteit()
                && _instData.getInstallations('INST_TAPW').length === 1)
            {
                return false;
            }

            return installationLogic.getInstallationRelations(inst.EntityDataId).length > 0;
        } //-- end: showRelations ------------------------------------------------------------------------//

        installationLogic.saveRelationKoppeling = function (inst, rel) {
            //-- installatietype zoeken
            const systeemtype = installationLogic.getInstTypeCode(inst);
            const systeemId = installationLogic.systId(inst);

            //-- koppeling zoeken met deze relatie
            let relatedId = rel.id; //-- in geval van rekenzone, unit of unit-rekenzone
            if (rel.relType === 'VERW' || rel.relType === 'TAPW') {
                relatedId = installationLogic.systId(rel.id); //-- in geval van installatie tapw of verw
            }
            let relation = null;
            if (systeemtype === 'TAPW') {
                //-- bij tapwater weet ik pas of ik een relatie heb al het betreffende tapwatersysteem een relatie heeft met een TAPW-UNIT-(RZ) entity en deze entity een relatie heeft met de rel.Id
                const childType = rel.relType === 'UNIT' ? 'TAPW-UNIT' : 'TAPW-UNIT-RZ';
                const entTapwUnit = _instRelations.getTapwUnit(systeemId, rel.id, childType);
                if (!entTapwUnit) {
                    $log.warn(`${systeemtype} ${systeemId} heeft geen bijbehorende ${childType}!`);
                    return;
                }
                ntaEntityData.setEntityRelevancy(entTapwUnit, !entTapwUnit.Relevant);

                // Geef property relevantie aan
                for (const propdata of entTapwUnit.PropertyDatas) {
                    ntaEntityData.setPropdataRelevancy(propdata, entTapwUnit.Relevant);
                    const hidden = isPropdataHidden(propdata);
                    ntaValidation.IsValid(installationLogic.form_installationsDefine, ntabuilding.properties[propdata.PropertyId], propdata, hidden);
                }

                ntaSharedLogic.setTouchedFalseAantalIdentiekesystemen(true, true, inst);
                ntaSharedLogic.setAantalIdentiekeSystemen();
                setInstallationsSummary();
                _instRelations.setInstallationsRelations();
                installationLogic.checkErrors();
                ntaDependencyValidation.validateLogic(inst, systeemtype);

                // bij het ontkoppelen van een warmtapwatersysteem, conditie [TOCV] controleren
                if (!entTapwUnit.Relevant) {
                    ntaSharedLogic.checkHotfillBoiler(systeemId);
                }

                //-- in principe is er altijd een tapwUnit aanwezig. Die wordt aangemaakt bij de create hier op het formulier of bij het aanmaken van een gebouw of rekenzone unit
                //-- ik kan nu uit de functie, want meer hoef ik niet te doen. Koppeling verwijderen hoeft niet omdat we voor tapwater met relevantie werken.
                return;

            } else if (systeemtype === 'ZONNB') {
                //-- vanaf 08-04-2020 zijn child en parent van RZ en VERW, VENT, KOEL en BEV relaties omgedraaid. Dit geldt vooralsnog niet voor de ZONNB met TAPW of VERW relatie
                relation = ntaEntityData.getRelation(relatedId, systeemId);
            } else {
                //-- bij de zonneboilers is sys (de relatedId) de parent en het installatiesysteem de child.
                relation = ntaEntityData.getRelation(systeemId, relatedId);
            }

            //-- dan de check
            let oldInstallation = null;
            if (relation) {
                //-- koppeling verwijderen (in principe kom ik met tapwater hier nooit, omdat ik daar met relevant true of false werk voor het TAPW-UNIT-(RZ) entiteit)
                if (systeemtype === 'ZONNB') {

                    //-- evt gekoppelde UNIT-ZONNC op niet relevant zetten
                    setRelevancyGekoppeldeUnitZonnecollectors(systeemId, false);

                    ntaSharedLogic.deleteZonneboilerSysteemRelation(relation.EntityRelationDataId);
                    ntaDependencyValidation.checkZonneboiler(systeemId);

                } else {
                    ntaSharedLogic.deleteRekenzoneSysteemRelation(relation.EntityRelationDataId, relatedId, systeemId, systeemtype);
                }
            } else {
                //--oude koppeling met andere systemen of rekenzones verwijderen (niet van toepassing voor zonneboilers)
                const oldRelation = ntaEntityData.getParentRelations(relatedId, systeemtype)[0];
                if (oldRelation) {
                    ntaSharedLogic.deleteRekenzoneSysteemRelation(oldRelation.EntityRelationDataId, relatedId, oldRelation.Parent, oldRelation.ParentEntityId);
                    if (systeemtype === 'VENT') {
                        oldInstallation = ntaEntityData.getFirstParent(oldRelation.Parent);
                    }
                }
                if (systeemtype === 'ZONNB') {
                    //-- oude koppeling met andere systemen verwijderen.
                    //-- ook nog checken of binnen het ZONNB-systeem zelf al een koppeling is met een verw of tapw systeem
                    const otherRelation = ntaEntityData.getParentRelations(systeemId, rel.relType)[0];
                    if (otherRelation) {

                        //-- evt gekoppelde UNIT-ZONNC op niet relevant zetten
                        setRelevancyGekoppeldeUnitZonnecollectors(systeemId, false);

                        ntaSharedLogic.deleteZonneboilerSysteemRelation(otherRelation.EntityRelationDataId);
                        ntaDependencyValidation.checkZonneboiler(systeemId);
                    }

                    ////-- ook nog checken of er een ander zonnb-systeem is met een koppeling naar dit verw of tapw systeem
                    const systeem = ntaEntityData.getFirstChild(rel.id, rel.relType); //ver/tapw
                    if (systeem) {
                        const secondRelation = ntaEntityData.getChildRelations(systeem, 'ZONNB')[0]; //relatie ver/tapw - zonnb
                        if (secondRelation) {
                            ntaSharedLogic.deleteZonneboilerSysteemRelation(secondRelation.EntityRelationDataId);
                            ntaDependencyValidation.checkZonneboiler(systeemId);
                        }
                    }

                    //- koppeling toevoegen
                    installationLogic.createZonneboilerSysteemRelation(relatedId, systeemId, systeemtype);

                    //-- evt gekoppelde UNIT-ZONNC op relevant zetten
                    setRelevancyGekoppeldeUnitZonnecollectors(systeemId, true);

                } else {
                    //- koppeling toevoegen
                    ntaSharedLogic.createRekenzoneSysteemRelation(relatedId, systeemId, systeemtype);
                }
            }
            _instRelations.setRelationsByInstallType(systeemtype);
            //-- als de koppeling is aangepast van dit systeem moeten voor alle systemen van dit typen de INSTALL_AANTAL op
            //-- Touched=false gezet worden wanneer het veld Readonly is. Dan wordt bij setAantalIdentieke systemen de juiste
            //-- waarde bepaald.
            ntaSharedLogic.setTouchedFalseAantalIdentiekesystemen(true, true, inst);
            ntaSharedLogic.setAantalIdentiekeSystemen();
            installationLogic.checkErrors();
            setInstallationsSummary();
            if (systeemtype === 'VENT') {
                ntaDependencyValidation.validateLogic(inst, systeemtype);
                if (oldInstallation) {
                    ntaDependencyValidation.validateLogic(oldInstallation, systeemtype);
                }
            }
        } //-- end: saveRekenzoneKoppeling ------------------------------------------------------------------------//

        function setRelevancyGekoppeldeUnitZonnecollectors(systeemId, relevant) {
            //-- evt gekoppelde UNIT-ZONNC op niet relevant zetten
            const units = ntaEntityData.findEntities(systeemId, "^VERW.RZ.UNIT-RZ.^UNIT", "^TAPW.TAPW-UNIT!.^UNIT", "^TAPW.TAPW-UNIT-RZ!.^UNIT-RZ.^UNIT");
            let unitzonncs = [];
            for (const unit of units) {
                unitzonncs = unitzonncs.concat(ntaEntityData.getChildren(unit, "UNIT-ZONNC"));
            }
            ntaEntityData.setEntityRelevancy(unitzonncs, relevant);
        };

        installationLogic.createZonneboilerSysteemRelation = function (sysId, zonnbId) {
            //-- bij zonneboiler is de relatie parent<->child zonnb<->verw of tapw
            ntaEntityData.createRelation(sysId, zonnbId, 0, 0);

            // Check zonneboilervelden
            ntaDependencyValidation.checkZonneboiler(zonnbId);
        };

        installationLogic.saveRelationInput = function (inst, rel) {
            //-- installatietype zoeken
            const installationType = installationLogic.getInstTypeCode(inst);
            const systemId = installationLogic.systId(inst);

            if (installationType === 'TAPW') {
                //-- bij tapwater weet ik pas of ik een relatie heb al het betreffende tapwatersysteem een relatie heeft met een TAPW-UNIT-(RZ) entity en deze entity een relatie heeft met de rel.Id
                const childType = rel.relType === 'UNIT' ? 'TAPW-UNIT' : 'TAPW-UNIT-RZ';
                const entTapwUnit = _instRelations.getTapwUnit(systemId, rel.id, childType);
                if (entTapwUnit) {
                    if (childType === 'TAPW-UNIT') {
                        const propBadr = installationLogic.propertiesTapwUnit['TAPW-UNIT_BADRUIMTEN'];
                        const propKeuk = installationLogic.propertiesTapwUnit['TAPW-UNIT_KEUKENS'];
                        const propdataBadr = propBadr.getData(entTapwUnit);
                        const propdataKeuk = propKeuk.getData(entTapwUnit);
                        propdataBadr.Touched = true;
                        propdataKeuk.Touched = true;
                        let changed = false;
                        if (propdataBadr.Value !== rel.relnB) {
                            changed = true;
                            propdataBadr.Value = rel.relnB;
                            ntaValidation.IsValid(installationLogic.form_installationsDefine, propBadr, propdataBadr, isPropdataHidden(propdataBadr));
                            ntaEntityData.saveprop(propdataBadr);
                            ntaDependencyValidation.checkChangedField(propBadr, entTapwUnit, installationLogic);

                            // Afgeronde waarde terugschrijven
                            if (propdataBadr.Value !== rel.relnB) {
                                rel.relnB = propdataBadr.Value;
                            }
                        }
                        if (propdataKeuk.Value !== rel.relnK) {
                            changed = true;
                            propdataKeuk.Value = rel.relnK;
                            ntaValidation.IsValid(installationLogic.form_installationsDefine, propKeuk, propdataKeuk, isPropdataHidden(propdataKeuk));
                            ntaEntityData.saveprop(propdataKeuk);
                            ntaDependencyValidation.checkChangedField(propKeuk, entTapwUnit, installationLogic);

                            // Afgeronde waarde terugschrijven
                            if (propdataKeuk.Value !== rel.relnK) {
                                rel.relnK = propdataKeuk.Value;
                            }
                        }
                        if (changed) {
                            // als het aantal badkamers en/of keukens gewijzigd is, conditie [TOCV] controleren
                            ntaSharedLogic.checkHotfillBoiler(systemId);
                        }

                    } else if (childType === 'TAPW-UNIT-RZ') {
                        const propAg = installationLogic.propertiesTapwUnitRz['TAPW-UNIT-RZ_OPP'];
                        const propdataAg = entTapwUnit.PropertyDatas[propAg.Id];
                        propdataAg.Touched = true;
                        if (propdataAg.Value !== rel.relAg) {
                            propdataAg.Value = rel.relAg;

                            ntaValidation.IsValid(installationLogic.form_installationsDefine, propAg, propdataAg, isPropdataHidden(propdataAg));
                            ntaEntityData.saveprop(propdataAg);
                            ntaDependencyValidation.checkChangedField(propAg, entTapwUnit, installationLogic);

                            // Afgeronde waarde terugschrijven
                            if (propdataAg.Value !== rel.relAg) {
                                rel.relAg = propdataAg.Value;
                            }
                        }
                    }
                }
            }
            installationLogic.checkErrors();
        } //-- end: saveRelationInput -------------------------------------------------------------

        installationLogic.getRelationName = function (rel) {
            switch (rel.reltype) {
                case 'VERW':
                case 'TAPW':
                    const install = installationLogic.installs().find(function (x) { return x.EntityDataId === rel.id; });
                    if (install) {
                        return _instData.getName(install);
                    }
                    break;
                case 'RZ':
                    const rz = ntaSharedLogic.rekenzones().find(ed => ed.EntityDataId);
                    if (rz) {
                        return rz.PropertyDatas["RZ_OMSCHR"].Value;
                    }
                    break;
            }
            return;
        } //-- end: getRelationName ------------------------------------------------------------------------//

        function createInstallationType(parentId, entdatatype) {
            const newSYSId = ntaEntityData.create(entdatatype, -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": parentId, "ParentEntityId": "INSTALLATIE" }], [], []);
            const isFirstSystem = ntaEntityData.getListWithEntityId(entdatatype).length === 1;
            switch (entdatatype) {
                case "PV": {
                    //-- er worden pas PV-VELDen aangemaakt als het type invoer is aangegeven.

                    //-- we maken wel een RESULT-PV aan voor elke unit (én voor het gebouw)
                    for (const unit of installationLogic.units().concat(ntaEntityData.getFirstWithEntityId('GEB'))) {
                        ntaResults.createResultPvEntity(unit, ntaEntityData.get(newSYSId), ntaEntityData.getFirstWithEntityId('BASIS'), ntaEntityData.getFirstWithEntityId('NTA-RESULTS'));
                    }
                    ntaResults.updateForParents();
                    break;
                }
                case "VENT": {
                    ntaEntityData.create('WARMTETERUG', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newSYSId, "ParentEntityId": "VENT" }], [], []);
                    ntaEntityData.create('VOORWARM', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newSYSId, "ParentEntityId": "VENT" }], [], []);
                    ntaEntityData.create('VENTDEB', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newSYSId, "ParentEntityId": "VENT" }], [], []);
                    ntaEntityData.create('VENTDIS', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newSYSId, "ParentEntityId": "VENT" }], [], []);
                    ntaEntityData.create('VENTILATOR', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newSYSId, "ParentEntityId": "VENT" }], [], []);

                    let newVentaanvullendId = ntaEntityData.create('VENTAAN', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newSYSId, "ParentEntityId": "VENT" }], [], []);
                    ntaEntityData.create('WARMTETERUG', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newVentaanvullendId, "ParentEntityId": "VENTAAN" }], [], []);
                    ntaEntityData.create('VENTILATOR', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newVentaanvullendId, "ParentEntityId": "VENTAAN" }], [], []);

                    const gebruikersprofiel = ntaEntityData.getFirstWithEntityId('GEBR-PROFIEL');
                    if (gebruikersprofiel) {
                        const parentRels = [
                            { OnDelete: true, OnCopy: true, Parent: gebruikersprofiel.EntityDataId, ParentEntityId: gebruikersprofiel.EntityId },
                            { OnDelete: true, OnCopy: true, Parent: newSYSId, ParentEntityId: entdatatype },
                        ];
                        ntaEntityData.create('GEBR-VENT', -1, parentRels);
                    }
                    break;
                }
                case "VERW": {
                    let opwekId = ntaEntityData.create('VERW-OPWEK', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newSYSId, "ParentEntityId": "VERW" }], [], []);
                    ntaEntityData.create('BELEMMERING', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": opwekId, "ParentEntityId": "VERW-OPWEK" }], [], []);

                    let newId = ntaEntityData.create('VERW-DISTR', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newSYSId, "ParentEntityId": "VERW" }], [], []);
                    let binId = ntaEntityData.create('VERW-DISTR-BIN', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newId, "ParentEntityId": "VERW-DISTR" }], [], []);
                    let buiId = ntaEntityData.create('VERW-DISTR-BUI', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newId, "ParentEntityId": "VERW-DISTR" }], [], []);
                    ntaEntityData.create('VERW-DISTR-EIG', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": binId, "ParentEntityId": "VERW-DISTR-BIN" }], [], [{ "PropertyId": 'VERW-DISTR-EIG_RUIMTE', "Value": 'binnen verwarmde zone' }]);
                    ntaEntityData.create('VERW-DISTR-EIG', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": buiId, "ParentEntityId": "VERW-DISTR-BUI" }], [], [{ "PropertyId": 'VERW-DISTR-EIG_RUIMTE', "Value": 'buiten verwarmde zone' }]);
                    ntaEntityData.create('VERW-DISTR-POMP', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newId, "ParentEntityId": "VERW-DISTR" }], [], []);

                    // Maak VERW-AFG
                    let newAfgifteId = ntaEntityData.create('VERW-AFG', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newSYSId, "ParentEntityId": "VERW" }], [], []);
                    ntaEntityData.create('VERW-AFG-VENT', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newAfgifteId, "ParentEntityId": "VERW-AFG" }], [], []);

                    break;
                }
                case "KOEL": {
                    ntaEntityData.create('KOEL-OPWEK', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newSYSId, "ParentEntityId": "KOEL" }], [], []);
                    let newId = ntaEntityData.create('KOEL-DISTR', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newSYSId, "ParentEntityId": "KOEL" }], [], []);
                    let binId = ntaEntityData.create('KOEL-DISTR-BIN', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newId, "ParentEntityId": "KOEL-DISTR" }], [], []);
                    let buiId = ntaEntityData.create('KOEL-DISTR-BUI', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newId, "ParentEntityId": "KOEL-DISTR" }], [], []);

                    // vanaf versie 3.3 bestaat KOEL-DISTR-BIN niet meer.
                    if (binId) ntaEntityData.create('KOEL-DISTR-EIG', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": binId, "ParentEntityId": "KOEL-DISTR-BIN" }], [], [{ "PropertyId": 'KOEL-DISTR-EIG_RUIMTE', "Value": 'binnen gekoelde zone' }]);

                    ntaEntityData.create('KOEL-DISTR-EIG', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": buiId, "ParentEntityId": "KOEL-DISTR-BUI" }], [], [{ "PropertyId": 'KOEL-DISTR-EIG_RUIMTE', "Value": 'buiten gekoelde zone' }]);
                    ntaEntityData.create('KOEL-DISTR-POMP', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newId, "ParentEntityId": "KOEL-DISTR" }], [], []);

                    let newAfgifteId = ntaEntityData.create('KOEL-AFG', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newSYSId, "ParentEntityId": "KOEL" }], [], []);
                    ntaEntityData.create('KOEL-AFG-VENT', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newAfgifteId, "ParentEntityId": "KOEL-AFG" }], [], []);

                    //-- nu de relaties leggen met de rekenzones en dit koelsysteem. Ik moet alleen relaties leggen als dit het eerste systeem is
                    if (isFirstSystem) {
                        ntaSharedLogic.createRZ_KOEL_Relations(null, newSYSId);
                        //-- hierna nog een keer de relaties goed zetten anders worden de tapw en verw sys niet aangevinkt.
                        _instRelations.setInstallationsRelations();
                        installationLogic.checkErrors();
                    }

                    break;
                }
                case "BEV": {
                    //-- nu de relaties leggen met de rekenzones en dit bevochtigingssysteem. Ik moet alleen relaties leggen als dit het eerste systeem is
                    if (isFirstSystem) {
                        ntaSharedLogic.createRZ_BEV_Relations(null, newSYSId);
                        //-- hierna nog een keer de relaties goed zetten anders worden de tapw en verw sys niet aangevinkt.
                        _instRelations.setInstallationsRelations();
                        installationLogic.checkErrors();
                    }
                    break;
                }
                case "ZONNB": {
                    let newId1 = ntaEntityData.create('ZONNBSYS', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newSYSId, "ParentEntityId": "ZONNB" }], [], []);
                    let newId2 = ntaEntityData.create('ZONNECOL', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newId1, "ParentEntityId": "ZONNBSYS" }], [], []);
                    ntaEntityData.create('BOILERVAT', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newId1, "ParentEntityId": "ZONNBSYS" }], [], []);
                    ntaEntityData.create('BELEMMERING', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newId2, "ParentEntityId": "ZONNECOL" }], [], []);

                    //-- als dit de eerste zonneboiler is moet ik een koppeling maken tussen deze zonnb en tapwsysteem 1 en verwarmingssysteem 2
                    if (ntaEntityData.getListWithEntityId("ZONNB").length === 1) {
                        //-- MCO 2021-06-10: standaard alleen aan tapwater koppelen.
                        const tapwGUID = ntaEntityData.getFirstWithEntityId("TAPW").EntityDataId;
                        ntaEntityData.createRelation(tapwGUID, newSYSId, false, false);

                        //-- hierna nog een keer de relaties goed zetten anders worden de tapw en verw sys niet aangevinkt.
                        _instRelations.setInstallationsRelations();
                        installationLogic.checkErrors();
                    }

                    //-- we maken ook een RESULT-PV aan voor elke unit (én voor het gebouw)
                    for (const unit of installationLogic.units().concat(ntaEntityData.getFirstWithEntityId('GEB'))) {
                        ntaResults.createResultPvEntity(unit, ntaEntityData.get(newId2), ntaEntityData.getFirstWithEntityId('BASIS'), ntaEntityData.getFirstWithEntityId('NTA-RESULTS'));
                    }
                    ntaResults.updateForParents();
                    break;
                }
                case "TAPW": {
                    ntaEntityData.create('TAPW-OPWEK', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newSYSId, "ParentEntityId": "TAPW" }], [], []);
                    ntaEntityData.create('TAPW-VAT', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newSYSId, "ParentEntityId": "TAPW" }], [], []);
                    ntaEntityData.create('TAPW-AFG', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newSYSId, "ParentEntityId": "TAPW" }], [], []);
                    let newId = ntaEntityData.create('TAPW-DISTR', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newSYSId, "ParentEntityId": "TAPW" }], [], []);
                    let binId = ntaEntityData.create('TAPW-DISTR-BIN', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newId, "ParentEntityId": "TAPW-DISTR" }], [], []);
                    let buiId = ntaEntityData.create('TAPW-DISTR-BUI', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newId, "ParentEntityId": "TAPW-DISTR" }], [], []);
                    ntaEntityData.create('TAPW-DISTR-EIG', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": binId, "ParentEntityId": "TAPW-DISTR-BIN" }], [], [{ "PropertyId": 'TAPW-DISTR-EIG_RUIMTE', "Value": 'binnen verwarmde zone' }]);
                    ntaEntityData.create('TAPW-DISTR-EIG', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": buiId, "ParentEntityId": "TAPW-DISTR-BUI" }], [], [{ "PropertyId": 'TAPW-DISTR-EIG_RUIMTE', "Value": 'buiten verwarmde zone' }]);
                    ntaEntityData.create('TAPW-DISTR-POMP', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newId, "ParentEntityId": "TAPW-DISTR" }], [], []);

                    //-- nu de relaties leggen met de unit-(rz) en dit tapwater systeem
                    ntaSharedLogic.setTapwUnitRelations(newSYSId);
                    break;
                }
                default: {
                    break;
                }
            }
            ntaSharedLogic.setAantalIdentiekeSystemen();
            setInstallationsSummary();
            _instRelations.setInstallationsRelations();
            installationLogic.checkErrors();

            progressCircle.setShowProgressValue(false);
        } //-- end: createInstallationType ------------------------------------------------------------------------//


        /////////////////////////////////////////////////////////////////////////////////////////// CRUD akties /////////////////////////////////////////////////////////////////////////////////////////////////////
        installationLogic.addInstallation = function (insttypeId) {
            const entId = insttypeId.substring(5);
            progressCircle.setShowProgressValue(true, 'aanmaken nieuwe installatie');
            const newId = ntaEntityData.create('INSTALLATIE', -1, [], [], [{ "PropertyId": 'INSTALL_TYPE', "Value": insttypeId }]);
            const installation = ntaEntityData.get(newId);
            saveDefaultInstallationName(installation);
            createInstallationType(newId, entId);

            //-- nu van de nieuw aangemaakte installatie de Touched van INSTALL_AANTAL op True zetten, anders gaat het niet goed bij het aanmaken van een ander of tweede installatiesysteem. Bij het verlaten
            //-- van het formulier worden de velden in principe pas op Touched = True gezet, maar bij setAantalIdentiekeSystemen wordt Touched van INSTALL_NAANTAL gebruikt om te bepalen of er wel of niet een
            //-- default waarde berekend moet worden.
            const propdataAantal = installation.PropertyDatas["INSTALL_AANTAL"];
            if (propdataAantal) {
                propdataAantal.Touched = true;
                ntaEntityData.saveprop(propdataAantal);
            }

            // PV-systemen alfabetisch sorteren
            if (insttypeId === 'INST_PV') {
                _instData.sortInstallationsOfType(insttypeId);
            }

        }; //-- end: addInstallation --------------------------------------------------------------------------------//

        installationLogic.copyClick = function (id) {
            if (!id) {
                return;
            }

            progressCircle.setShowProgressValue(true, 'kopiëren installatie');
            const newEntdatas = ntaEntityData.copy(id);

            //-- naam van de installatie uitbreiden met " - kopie"
            const installatie = newEntdatas.find(e => e.EntityId === 'INSTALLATIE');
            const propdataNaam = installatie && installatie.PropertyDatas["INSTALL_NAAM"];
            if (propdataNaam) {
                const newName = propdataNaam.Value + " - kopie";
                ntaEntityData.saveprop(propdataNaam, newName);
            }

            const propdataType = installatie && installatie.PropertyDatas["INSTALL_TYPE"];
            const instType = propdataType && propdataType.Value;
            if (instType === 'INST_PV') {
                _instData.sortInstallationsOfType(instType);
            }

            // Verwijder bij het kopiëren van zonneboiler de gekopieerde relaties met verwarming en tapwater
            const newZonneboiler = newEntdatas.find(e => e.EntityId === 'ZONNB');
            if (newZonneboiler) {
                let relations = ntaEntityData.getRelations(newZonneboiler).filter(r => r.ParentEntityId === 'TAPW' || r.ParentEntityId === 'VERW');
                for (let rel of relations) {
                    ntaEntityData.deleteRelation(rel.EntityRelationDataId);
                }
            }

            //-- VO 2022-01-13: Wanneer er een kopie gemaakt is van een verw, koel of vent installatie kan er o.a. in het geval van een ventilatieretourlucht wp een koppeling gemaakt
            //-- zijn tussen dit nieuwe systeem en de TAPW-OPWEK. Dit omdat copyEntdata automatisch alle relaties met parents kopieert, ongeacht of het cascade is of niet.
            //-- Maar een tapwateropwekker kan maar aan één systeem te gelijk gekoppeld zijn, dus de gekopieerde relatie weghalen indien aanwezig. Dit zal opgelost gaan worden
            //-- wanneer Uniec3 ook met Metadata voor relaties gaat werken.
            const checkSystem = newEntdatas.find(e => e.EntityId === 'VERW' || e.EntityId === 'KOEL' || e.EntityId === 'VENT')
            if (checkSystem) {
                const relations = ntaEntityData.getParentRelations(checkSystem, 'TAPW-OPWEK');
                for (let rel of relations) {
                    ntaEntityData.deleteRelation(rel.EntityRelationDataId);
                }
            }

            ntaSharedLogic.setAantalIdentiekeSystemen();
            setInstallationsSummary();
            _instRelations.setInstallationsRelations();
            installationLogic.checkErrors();
            ntaSharedLogic.initTapwUnitRzsUbouw();
            progressCircle.setShowProgressValue(false);
        };

        installationLogic.deleteClick = function (id) {
            if (!id) {
                return;
            }
            //- controleren of het de laatste Verw, Tapw of Vent of igv U Verl is
            const entdata = ntaEntityData.get(id);
            const entCode = installationLogic.getInstTypeCode(entdata);
            const installType = entdata.PropertyDatas["INSTALL_TYPE"].Value;
            const installCount = ntaEntityData.getListWithEntityId('INSTALLATIE')
                .filter(ed => ed.PropertyDatas['INSTALL_TYPE'].Value === installType).length;
            let deleteAllowed = false;
            if (entCode === "BEV" || entCode === "ZONNB" || entCode === "KOEL" || !ntaSharedLogic.isEditingMeasure("MEASURE-PV") && entCode === "PV" || entCode === "WINDT") {
                deleteAllowed = true;
            } else if (entCode === "VERL") {
                deleteAllowed = !ntaSharedLogic.isUtiliteit();
            } else {
                deleteAllowed = installCount > 1;
            }

            const installationName = _instData.getName(entdata);
            if (deleteAllowed) {
                const confirm = $mdDialog.confirm()
                    .title('Verwijderen installatie')
                    .textContent('U staat op het punt om installatie "' + installationName + '" te verwijderen. Weet u het zeker?')
                    .ariaLabel('Verwijderen')
                    .targetEvent(event)
                    .ok('Ja')
                    .cancel('Nee');

                $mdDialog.show(confirm).then(function () {

                    // Als KOEL of VERW vraag tapwater met evt. boosterwarmtepomp op
                    // Als VENT vraag tapwater met evt. ventiltiewarmtepomp op
                    let tapwOpwekker = null;
                    if (entCode === 'KOEL' || entCode === 'VERW' || entCode === 'VENT') {
                        tapwOpwekker = ntaDependencyValidation.getTapwOpwekVanWarmtepompRelation(entdata, entCode);
                    }

                    _instRelations.deleteInstRelation(id);
                    ntaEntityData.delete(id);
                    ntaSharedLogic.setAantalIdentiekeSystemen();
                    setInstallationsSummary();
                    _instRelations.setInstallationsRelations();
                    installationLogic.checkErrors();
                    ntaSharedLogic.initTapwUnitRzsUbouw();
                    // Als KOEL of VERW check tapwater met boosterwarmtepomp
                    if (entCode === 'KOEL' || entCode === 'VERW' || entCode === 'VENT') {
                        ntaDependencyValidation.checkTapwaterWarmtepomp(tapwOpwekker);
                    }
                });
            } else {
                const systeemtype = {
                    'VERW': 'verwarmingssysteem',
                    'TAPW': 'warm-tapwatersysteem',
                    'VENT': 'ventilatiesysteem',
                    'VERL': 'verlichtingssysteem',
                    'PV'  : 'PV-systeem',
                }[entCode] || 'systeem van dit type';

                const alert = $mdDialog.alert()
                    .title('Verwijderen niet toegestaan')
                    .textContent(`Het is niet mogelijk om installatie "${installationName}" te verwijderen. Er dient minimaal één ${systeemtype} aanwezig te zijn.`)
                    .ok('Sluiten');

                $mdDialog.show(alert);
            }
        }; //-- end: installationLogic.deleteClick ------------------------------------------------

        function getEntdataAndId(entdataOrId) {
            //-- ontvangt een entdata OF een EntityDataId, en geeft een array terug met 0. de betreffende entdata (indien gevonden) en 1. de EntityDataId.
            let entdata = entdataOrId;
            let entdataId = entdataOrId;
            if (typeof entdataOrId === 'object' && !!entdataOrId) { // let op: typeof null === 'object'
                entdataId = entdata.EntityDataId;
            } else if (typeof entdataOrId === 'string') {
                entdata = ntaEntityData.get(entdataId);
            }
            return [entdata, entdataId];
        } //-- end: getEntdataAndId ---------------------------------------------------------------

        function isHidden(prop, entdata) {
            if (typeof prop === 'string') prop = ntabuilding.properties[prop];
            const propdata = prop && entdata && prop.getData(entdata);
            if (!propdata) return true;

            let participates = true; // false betekent dat deze propdata niet meedoet op dit formulier; dus moet er niets opgeslagen worden
            let visible = true;
            let relevant = null; // relevant = null betekent: relevant is hetzelfde als visible.

            switch (prop.Id) {
                case 'INSTALL_AANTAL': {
                    visible = _instData.hasInstallationCount(entdata);
                    break;
                }
                case 'TAPW-UNIT_BADRUIMTEN':
                case 'TAPW-UNIT_KEUKENS': {
                    visible = !ntaSharedLogic.isUtiliteit();
                    break;
                }
                case 'TAPW-UNIT-RZ_OPP': {
                    visible = ntaSharedLogic.isUtiliteit();
                    break;
                }
                case 'TAPW-UNIT-RZ_OPPMAX': {
                    visible = false;
                    break;
                }
                default: {
                    participates = false; // elk veld dat niet expliciet is behandeld hierboven, doet niet mee.
                    break;
                }
            }

            if (participates) {
                // standaard is relevant gelijk aan visible
                if (relevant === null) relevant = visible;
                ntaEntityData.setPropdataStatus(propdata, relevant, visible);
            }

            return !visible || !participates;
        } //-- end: isHidden ----------------------------------------------------------------------

        function isReadOnly(prop, entdata) {
            if (typeof prop === 'string') prop = ntabuilding.properties[prop];
            if (!prop || !entdata) return true;

            switch (prop.Id) {
                case 'INSTALL_NAAM': {
                    const installType = installationLogic.getInstTypeCode(entdata);
                    //-- naam wijzigen is niet toegestaan voor installatie die maar één keer voorkomen zoals verlichting en windturbines
                    return ["WINDT", "VERL"].includes(installType);
                }
                case 'INSTALL_AANTAL': {
                    const systeem = ntaSharedLogic.systeem(entdata);
                    const systeemId = systeem && systeem.EntityDataId;
                    return ntaSharedLogic.aantalSystemenReadOnly(systeemId);
                }
            }
            return false;
        } //-- end: isReadOnly --------------------------------------------------------------------

        function validate(prop, propdata, entdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !entdata || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            const hidden = isHidden(prop, entdata);
            const readonly = isReadOnly(prop, entdata);
            let valid = ntaValidation.IsValid(installationLogic.form_installationsDefine, prop, propdata, hidden, readonly);

            switch (prop.Id) {
            }

            return valid;
        } //-- end: validate ----------------------------------------------------------------------

        function startFormValidation() {
            if (ntaSharedLogic.isUtiliteit()) {
                //-- de Ag property van TAPW-UNIT-RZ valideren
                ntaSharedLogic.initTapwUnitRzsUbouw();
            }
            return ntaSharedLogic.startFormValidation(getAllEntDatas(), installationLogic);
        } //-- end: startFormValidation -----------------------------------------------------------

        function endFormValidation() {
            return ntaSharedLogic.endFormValidation(getAllEntDatas(), installationLogic);
        } //-- end: endFormValidation -------------------------------------------------------------

        function getAllEntDatas() { // Geeft alle entdatas in één array terug
            return _instData.getInstallations()
                .concat(ntaEntityData.getListWithEntityId('TAPW-UNIT'))
                .concat(ntaEntityData.getListWithEntityId('TAPW-UNIT-RZ'))
                .filter(entdata => entdata) // filter alle nulls en undefineds eruit
                ;
        } //-- end: getAllEntDatas ----------------------------------------------------------------


        installationLogic.checkErrors();
    };

}]);