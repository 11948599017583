﻿angular.module('projectModule')
    .factory('MeasureVLeidingFactory',
        ['$log', 'ntaData', 'ntabuilding', 'ntaValidation', 'ntaEntityDataOrg', 'ntaSharedLogic', 'ListCache', 'ntaDeltas',
function ($log,   ntaData,   ntabuilding,   ntaValidation,   ntaEntityDataOrg,   ntaSharedLogic,   ListCache,   ntaDeltas) {
    'use strict';

    return function MeasureVLeidingLogic(measureId, ntaDependencyValidation) {
        const self = this;

        //== Imports ==============================================================================
        self.ntaValidation = ntaValidation;
        self.dependencyValidator = ntaDependencyValidation;

        //== Instance data ========================================================================

        const _entdataMeasure = ntaEntityDataOrg.get(measureId);
        const _entdataMeasureVLeiding = ntaEntityDataOrg.getFirstChild(_entdataMeasure, 'MEASURE-VLEIDING');
        const _listCache = new ListCache();

        const _measureType = ntaData.properties['MEASURE_TYPE'].getCode(_entdataMeasure);

        //== Exports ==============================================================================
        self.measureId = _entdataMeasure.EntityDataId;
        self.entdataMeasureInfil = _entdataMeasureVLeiding;
        self.properties = ntaData.properties[_entdataMeasureVLeiding.EntityId];
        self.measureType = _measureType;

        // al deze methods exporteren zodat ze publiek beschikbaar zijn
        Object.assign(self, {
            // specifieke methods voor maatregelen
            getTileDetails,
            getTotaleKosten,

            // standaard methods tbv validatie
            isReadOnly,
            hasCodedValues,
            getCodedValues,
            saveValue,
            validate,
            validateDependencies,
            startFormValidation,
            endFormValidation,
            setGeopend,
        });

        //== Initialization =======================================================================

        // (geen initialisatie nodig)

        //== Implementation =======================================================================

        function getTileDetails(render) {
            const lines = [];

            if (ntaSharedLogic.showCosts()) {
                lines.push({});
                lines.push(render.detail('VLEIDING_KOSTEN_TOT', getShadowEntdata()));
            }

            return lines;
        } //-- end: getTileDetails ----------------------------------------------------------------

        function getTotaleKosten() {
            const entdataVLeiding = getShadowEntdata();
            return ntaSharedLogic.parseFloat(entdataVLeiding.PropertyDatas['VLEIDING_KOSTEN_TOT'].Value, 0);
        } //-- end: getTotaleKosten ---------------------------------------------------------------

        function getShadowEntdata() {
            const buildingData = ntaDeltas.getShadowBuildingData(_entdataMeasure.EntityDataId, ['VLEIDING']);
            return buildingData.getFirstWithEntityId('VLEIDING');
        } //-- end: getShadowEntdata --------------------------------------------------------------

        function isReadOnly(prop) {
            if (!prop)
                return true;

            switch (prop.Id) {
                case 'MEASURE_OPEN':
                    return true;
            }

            return false;
        } //-- end: isReadOnly --------------------------------------------------------------------

        function hasCodedValues(prop) {
            return ntaValidation.hasCodedValues(prop);
        } //-- end: hasCodedValues ----------------------------------------------------------------

        function getCodedValues(prop) {
            if (!prop) {
                return [];
            }

            let codedValues = [];
            switch (prop.Id) {
                default: {
                    codedValues = ntaValidation.codedValues(prop);
                    break;
                }
            }

            // Zorg dat we alleen een nieuwe lijst teruggeven als deze gewijzigde waardes heeft,
            //  anders denkt AngularJS steeds dat het om een compleet nieuwe lijst gaat, en triggert
            //  deze oneindige digests.
            return _listCache.useCacheIfUnchanged(prop.Id, codedValues, (a, b) => a.Id === b.Id && a.Value === b.Value);
        } //-- end: getCodedValues ----------------------------------------------------------------

        function saveValue(propOrId, entdata, newValue) {
            const prop = typeof propOrId === 'string' ? ntaData.properties[propOrId] : propOrId;
            switch (prop && prop.Id) {
                default: {
                    break;
                }
            }

            return ntaSharedLogic.saveValue(prop, entdata, newValue, self, ntaEntityDataOrg);
        } //-- end: saveValue ---------------------------------------------------------------------

        function validate(prop, propdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            const hidden = true; //isHidden(prop);
            const readOnly = true; //isReadOnly(prop);

            let valid = ntaValidation.IsValid(self.form, prop, propdata, hidden, readOnly);

            switch (prop.Id) {
                default: {
                    break;
                }
            }

            return valid;
        } //-- end: validate ---------------------------------------------------------------------

        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = prop.getData(entdata);
            const checkValue = propdata.Value;

            let performDefaultChecks = false;

            switch (prop.Id) {
                default: {
                    break;
                }
            }

            if (performDefaultChecks) {
                isHidden(prop, entdata);
                if (ntaValidation.hasCodedValues(prop)) {
                    getCodedValues(prop);
                }
                if (propdata.Value !== checkValue) {
                    saveValue(prop, entdata);
                }
            }
        } //-- end: validateDependencies ----------------------------------------------------------

        function startFormValidation() {
            return ntaSharedLogic.startFormValidation(getAllEntDatas(), self);
        } //-- end: startFormValidation -----------------------------------------------------------

        function endFormValidation() {
            if (![null, _entdataMeasure.EntityDataId].includes(ntaData.current.shadowId)) return [];

            return ntaSharedLogic.endFormValidation(getAllEntDatas(), self);
        } //-- end: endFormValidation -------------------------------------------------------------

        function getAllEntDatas() {
            return []
                .concat(_entdataMeasureVLeiding)

                .filter(ed => ed);
        } //-- end: getAllEntDatas ----------------------------------------------------------------

        function setGeopend() {
            const propdataOpen = _entdataMeasure.PropertyDatas['MEASURE_OPEN'];
            ntaEntityDataOrg.saveprop(propdataOpen, 'true');
        } //-- end: setGeopend --------------------------------------------------------------------

    };
}]);
