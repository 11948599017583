﻿angular.module('projectModule')
    .factory('MeasurePvFactory',
        ['$log', 'ntaData', 'ntabuilding', 'ntaValidation', 'ntaEntityDataOrg', 'ntaSharedLogic', 'ntaDeltas', 'ntaRounding', 'InstallationDataFactory',
function ($log,   ntaData,   ntabuilding,   ntaValidation,   ntaEntityDataOrg,   ntaSharedLogic,   ntaDeltas,   ntaRounding,   InstallationDataFactory) {
    'use strict';

    return function MeasurePvLogic(measureId, ntaDependencyValidation) {
        const self = this;

        //== Imports ==============================================================================
        self.ntaValidation = ntaValidation;
        self.dependencyValidator = ntaDependencyValidation;

        //== Instance data ========================================================================
        const _instData = new InstallationDataFactory(ntaEntityDataOrg);

        const _measureId = measureId;
        const _entdataMeasure = ntaEntityDataOrg.get(_measureId);
        const _entdataMeasurePv = ntaEntityDataOrg.getFirstChild(_entdataMeasure, 'MEASURE-PV');
        const _measureType = ntaData.properties['MEASURE_TYPE'].getCode(_entdataMeasure);

        //== Exports ==============================================================================
        self.entdataMeasurePv = _entdataMeasurePv;
        self.properties = ntaData.properties[_entdataMeasurePv.EntityId];
        self.measureType = _measureType;

        // al deze methods exporteren zodat ze publiek beschikbaar zijn
        Object.assign(self, {
            canHaveSubmeasures: true,

            // specifieke methods voor maatregelen
            getTileDetails,
            getTotaleKosten,
            getSubmeasures,

            // standaard methods tbv validatie
            saveValue,
            validate,
            validateDependencies,
            startFormValidation,
            endFormValidation,
            setGeopend,
        });

        //== Initialization =======================================================================

        // (geen initialisatie nodig)


        //== Implementation =======================================================================

        function getTileDetails(render) {
            const lines = [];

            if (ntaSharedLogic.showCosts()) {
                lines.push({ name: 'kosten per systeem:' });

                const buildingData = getShadowBuildingData(['INSTALLATIE', 'PV', 'PV-VELD', 'MEASURE-COSTS']);
                const installations = getInstallations(buildingData);

                for (const installation of installations) {
                    const amount = ntaSharedLogic.calculatePVCosts(installation.EntityDataId, buildingData);
                    const propdataOmschr = installation.PropertyDatas['INSTALL_NAAM'];
                    const name = propdataOmschr.Value;
                    lines.push({ name: '  ' + name, value: render.propValue('MEASURE-COSTS_TOTAAL', amount) });
                }

                if (lines.length === 1) {
                    lines.push({ name: '  (geen kosten opgegeven)' });
                }

                lines.push({});
                lines.push({ name: 'totale kosten', value: render.propValue('MEASURE-PV_TOT_KOSTEN', getTotaleKosten(buildingData)) });
            }

            return lines;
        } //-- end: getTileDetails ----------------------------------------------------------------

        function getTotaleKosten(buildingData = getShadowBuildingData(['INSTALLATIE', 'PV', 'PV-VELD', 'MEASURE-COSTS'])) {
            const installations = getInstallations(buildingData);

            let totalCosts = 0;
            for (const installation of installations) {
                const amount = ntaSharedLogic.calculatePVCosts(installation.EntityDataId, buildingData);
                if (!isNaN(amount)) {
                    totalCosts += amount;
                }
            }

            /// als we in de juiste schaduwomgeving zitten, dan het totaal opslaan.
            if (ntaEntityDataOrg.getShadowId() === _measureId) {
                const propTotal = ntaData.properties['MEASURE-PV_TOT_KOSTEN'];
                const propdataTotal = propTotal.getData(_entdataMeasurePv);
                if (propdataTotal) {
                    const formattedAmount = ntaRounding.roundAndAddZerosNewValue(propTotal, totalCosts);
                    ntaEntityDataOrg.saveprop(propdataTotal, formattedAmount);
                }
            }

            return totalCosts;
        } //-- end: getTotaleKosten ---------------------------------------------------------------

        function getSubmeasures() {
            const projectId = ntaData.projectdata.ProjectId;
            const buildingId = _entdataMeasure.BuildingId;
            const baseUrl = `/Project/${projectId}/Berekening/${buildingId}/Maatregelen/${_measureId}`;

            const propType = ntaData.properties['INSTALL_TYPE'];

            const installations = getInstallations();
            const submeasures = installations
                .map(ed => ({
                    id: ed.EntityDataId,
                    name: _instData.getName(ed, true),
                    icon: propType && propType.getCode(ed) && propType.getCode(ed).ImageUrl || '',
                    url: `${baseUrl}/${propType.getValue(ed).toLowerCase()}/${ed.EntityDataId}`,
                }));
            return submeasures;
        } //-- end: getSubmeasures ----------------------------------------------------------------

        function getShadowBuildingData(entityIds = null) {
            return ntaDeltas.getShadowBuildingData(_entdataMeasure.EntityDataId, entityIds);
        } //-- end: getShadowBuildingData ---------------------------------------------------------

        function getInstallations(buildingData = getShadowBuildingData(['INSTALLATIE'])) {
            const propType = ntaData.properties['INSTALL_TYPE'];

            return buildingData.getListWithEntityId('INSTALLATIE')
                .filter(ed => propType.getValue(ed) === "INST_PV")
                .sort(_instData.orderInstallations);
        } //-- end: getInstallations --------------------------------------------------------------

        function saveValue(prop, entdata, newValue) {
            return ntaSharedLogic.saveValue(prop, entdata, newValue, self, ntaEntityDataOrg);
        } //-- end: saveValue ---------------------------------------------------------------------

        function validate(prop, propdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            const hidden = true; //isHidden(prop);
            const readOnly = true; //isReadOnly(prop);

            let valid = ntaValidation.IsValid(self.form, prop, propdata, hidden, readOnly);

            const contrl = self.form && self.form['ntainput' + propdata.PropertyDataId];

            switch (prop.Id) {
                default: {
                    break;
                }
            }

            return valid;
        } //-- end: validate ---------------------------------------------------------------------

        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = prop.getData(entdata);
            const checkValue = propdata.Value;

            let performDefaultChecks = false;

            switch (prop.Id) {
                default:
                    break;
            }

            if (performDefaultChecks) {
                isHidden(prop, entdata);
                if (ntaValidation.hasCodedValues(prop)) {
                    getCodedValues(prop);
                }
                if (propdata.Value !== checkValue) {
                    saveValue(prop, entdata);
                }
            }
        } //-- end: validateDependencies ----------------------------------------------------------

        function startFormValidation() {
            return ntaSharedLogic.startFormValidation(getAllEntDatas(), self);
        } //-- end: startFormValidation -----------------------------------------------------------

        function endFormValidation() {
            if (![null, _entdataMeasure.EntityDataId].includes(ntaData.current.shadowId)) return [];

            return ntaSharedLogic.endFormValidation(getAllEntDatas(), self);
        } //-- end: endFormValidation -------------------------------------------------------------

        function getAllEntDatas() {
            return [_entdataMeasurePv];
        } //-- end: getAllEntDatas ----------------------------------------------------------------

        function setGeopend() {
            const propdataOpen = _entdataMeasure.PropertyDatas['MEASURE_OPEN'];
            ntaEntityDataOrg.saveprop(propdataOpen, 'true');
        } //-- end: setGeopend --------------------------------------------------------------------

    };
}]);
