﻿angular.module('projectModule')
    .controller("BuildingCalcZonesController",
        ['$scope', '$controller', 'time', '$mdDialog', 'ntabuilding', 'ntaValidation', 'ntaMeldingen', 'ntaEntityData', 'ntaSharedLogic', 'ntaDependencyValidation', 'ntaResults', 'settingsMenuData', 'BuildingCalcZonesFactory', '$log', 'ntaOrientation', 'Pager',
function ($scope,   $controller,   time,   $mdDialog ,  ntabuilding,   ntaValidation,   ntaMeldingen,   ntaEntityData,   ntaSharedLogic,   ntaDependencyValidation,   ntaResults,   settingsMenuData,  BuildingCalcZonesFactory,   $log,   ntaOrientation,   Pager) {
    'use strict';

    const base = $controller('NTABaseController', { $scope: $scope })
    const vm = angular.extend(this, base);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    vm.ntaValidation = ntaValidation;

    const calczonesLogic = new BuildingCalcZonesFactory(ntaDependencyValidation);
    vm.getCalczonesLogic = calczonesLogic;

    const _isUtiliteit = ntaSharedLogic.isUtiliteit();
    const _isGebouw = isGebouw();

    /* Properties *************************************************************************************************************************************************************/
    vm.rzformproperties = calczonesLogic.rzformproperties;
    vm.calczproperties = calczonesLogic.calczproperties;
    vm.unitproperties = calczonesLogic.unitproperties;
    vm.unitrzproperties = calczonesLogic.unitrzproperties;
    vm.unitrzgfproperties = calczonesLogic.unitrzgfproperties;
    vm.gruimteproperties = calczonesLogic.gruimteproperties;

    /* Data *************************************************************************************************************************************************************/
    vm.rzform = calczonesLogic.rzform;
    vm.calczones = calczonesLogic.calczones;
    vm.units = calczonesLogic.units;
    vm.unitrzs = calczonesLogic.unitrzs;
    vm.unitrzsByUnit = calczonesLogic.unitrzsByUnit;
    vm.unitrzgfs = calczonesLogic.unitrzgfs;
    vm.unitrzgfsByUnitRz = calczonesLogic.unitrzgfsByUnitRz;
    vm.gruimtes = calczonesLogic.gruimtes;

    vm.getForm = getForm;
    vm.saveValue = saveValue;
    vm.getName = getName;
    vm.getPlaceholder = getPlaceholder;

    vm.getUnitName = getUnitName;
    vm.getAddName = getAddName;
    vm.getPropData = getPropData;

    vm.hideAddUnit = hideAddUnit;
    vm.isGebouw = _isGebouw;
    vm.isUtiliteit = _isUtiliteit;
    vm.isUitzGFS = isUitzGFS;
    vm.getUitzGfsMelding = getUitzGfsMelding;

    vm.saveGRuimteKoppeling = saveGRuimteKoppeling;
    vm.getGRuimteZoneOmschr = getGRuimteZoneOmschr;
    vm.getWordtGebruiktTBV = getWordtGebruiktTBV;

    vm.isHidden = calczonesLogic.isHidden;
    vm.isReadOnly = calczonesLogic.isReadOnly;
    vm.isDisabled = calczonesLogic.isReadOnly;
    vm.getCodedValues = calczonesLogic.getCodedValues;
    vm.hasRelation = ntaSharedLogic.hasRelation;

    vm.addCalczoneProgress = false;
    vm.addUnitProgress = false;
    vm.addGRuimteProgress = false;

    vm.addCalcZone = addCalcZone;
    vm.addUnit = addUnit;
    vm.addUnitRz = addUnitRz;
    vm.addUnitRzGf = addUnitRzGf;
    vm.addGRuimte = addGRuimte;

    vm.copyCalcZone = copyCalcZone;
    vm.copyUnit = copyUnit;
    vm.copyUnitRz = copyUnitRz;
    vm.copyUnitRzGf = copyUnitRzGf;
    vm.copyGRuimte = copyGRuimte;

    vm.deleteCalcZone = deleteCalcZone;
    vm.deleteUnit = deleteUnit;
    vm.deleteUnitRz = deleteUnitRz;
    vm.deleteUnitRzGf = deleteUnitRzGf;
    vm.deleteGRuimte = deleteGRuimte;

    vm.showRotateMirrorDialog = ntaOrientation.showRotateMirrorDialog;

    //-- voor de ui-sortable
    vm.rekenzoneList = []; //-- array die alle rekenzones bijhoudt
    vm.unitList = []; //-- array die units bijhoudt
    vm.unitRzList = {}; //-- object dat per unit een array van unitrzs bijhoudt
    vm.unitRzGfList = {}; //-- object dat per unitRz een array van unitrzgfs bijhoudt
    vm.gruimteList = []; //-- array die gemeenschappelijke ruimten bijhoudt

    $scope.endFormValidation = function () {
        calczonesLogic.endFormValidation();
    };

    //-- paging
    vm.pager = new Pager({ storageName: 'calczones', visible: shouldShowPagingBar() });
    vm.pager.on('validationRequested', validatePages);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INITALIZIATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    angular.element(function () { //alle touched properties valideren na het laden van form
        calczonesLogic.startFormValidation();
        vm.initializeUnitSpacing();
    });

    vm.gRuimteTableHeader = 'Definieer gemeenschappelijke ruimten';
    if (_isUtiliteit) {
        vm.gRuimteTableHeader = 'Definieer gemeenschappelijke ruimten zonder eigen rekenzone';
    }

    vm.setGeopend('RZFORM_OPEN', vm.rzform);
    updateLists();

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function saveValue(prop, entdata) {
        switch (prop.Id) {
            case "RZFORM_CALCUNIT": {
                //-- wanneer er sprake is van prop='RZFORM_CALCUNIT' moet er eerst een confirm dialog komen
                const propdata = prop.getData(entdata);

                //[A11] indien in een berekening 'maatwerkadvies' aan staat en deze optie wordt gekozen toon [W102]
                //Alleen optie RZUNIT_GEB mag iggv maatwerkadvies
                if (propdata.Value !== "RZUNIT_GEB" && String(settingsMenuData.getSetting('SETTINGS_MAATADVIES').Value).toLowerCase() === "true") {
                    calczonesLogic.saveValue(prop, entdata, 'RZUNIT_GEB'); //keuze terugdraaien
                    ntaMeldingen.warning("[W133]");
                    return;
                }

                //-- Indien value 'per gebouw is' -> maar één unit mogelijk
                if (propdata.Value === "RZUNIT_GEB") {
                    if (calczonesLogic.units().length > 1) {
                        //-- Conditie [A1]
                        let errorCode, originalValue;
                        if (ntaSharedLogic.isUtiliteit()) {
                            originalValue = "RZUNIT_GEBUNIT";
                            errorCode = "[W055]";
                        } else if (ntaSharedLogic.isNietGrondGebonden()) {
                            originalValue = "RZUNIT_GEBAPP";
                            errorCode = "[W013]";
                        } else {
                            originalValue = "RZUNIT_PROJECT";
                            errorCode = "[W080]";
                        }

                        //-- waarde weer even naar de oorspronkelijke value, anders laat AngularJs een scherm zien
                        //-- op basis van deze nieuwe waarde, maar dat is misschien nog helemaal niet zo.
                        propdata.Value = originalValue;

                        //-- Eerst moet de gebruiker een vraag beantwoorden.
                        const warning = ntabuilding.warnings.find(w => w.Id === errorCode).Value;
                        const confirm = $mdDialog.confirm()
                            .title('Wijzigen type berekening')
                            .textContent(warning)
                            .ariaLabel('Wijzigen type berekening')
                            .targetEvent(event)
                            .ok('doorgaan')
                            .cancel('annuleren');

                        $mdDialog.show(confirm)
                            .then(function () {
                                //-- bij {doorgaan} pas de RZUNIT_GEB value opslaan.
                                calczonesLogic.saveValue(prop, entdata, 'RZUNIT_GEB');
                                //-- Conditie [AA]
                                calczonesLogic.deleteExcessUnits();
                            }, function () {
                                //-- gekozen voor {annuleren} dus de waarde niet opslaan.
                            })
                            .finally(() => {
                                ntaResults.setUnitResultRelevancy();
                                vm.initializeUnitSpacing();
                            });

                        // Voorkom dat calczonesLogic.saveValue wordt uitgevoerd terwijl de dialoog weergegeven wordt.
                        return;

                    } else {
                        //-- Conditie [A2]
                        let warningCode;
                        if (ntaSharedLogic.isUtiliteit()) {
                            warningCode = "[W016]";
                        } else if (ntaSharedLogic.isNietGrondGebonden()) {
                            warningCode = "[W015]";
                        }
                        if (warningCode) {
                            ntaMeldingen.warning(warningCode);
                        }
                    }
                } else if (propdata.Value === 'RZUNIT_PROJECT') {
                    // Conditie [Z]
                    if (calczonesLogic.calczones().some(rz => rz.PropertyDatas['RZ_TYPEZ'].Value !== 'RZ')) {
                        propdata.Value = 'RZUNIT_GEB';

                        const warning = ntabuilding.warnings.find(w => w.Id === '[W089]').Value;
                        const confirm = $mdDialog.confirm()
                            .title('Wijzigen type berekening')
                            .ariaLabel('Wijzigen type berekening')
                            .textContent(warning)
                            .targetEvent(event)
                            .ok('doorgaan')
                            .cancel('annuleren');
                        $mdDialog.show(confirm)
                            .then(() => {
                                //-- bij {doorgaan} pas de RZUNIT_PROJECT value opslaan.
                                calczonesLogic.saveValue(prop, entdata, 'RZUNIT_PROJECT');
                            }, () => {
                                //-- gekozen voor {annuleren} dus de waarde niet opslaan.
                            })
                            .finally(() => {
                                ntaResults.setUnitResultRelevancy();
                                vm.initializeUnitSpacing();
                            });

                        // Voorkom dat calczonesLogic.saveValue wordt uitgevoerd terwijl de dialoog weergegeven wordt.
                        return;
                    }
                }
                ntaResults.setUnitResultRelevancy();
            }
        }

        calczonesLogic.saveValue(prop, entdata);
        vm.initializeUnitSpacing();
    } //-- end: saveValue ------------------------------------------------------------------------//

    function validatePages(event) {
        const invalidPropdatas = calczonesLogic.startFormValidation();
        const invalidEntdatas = invalidPropdatas
            .map(propdata => propdata && ntaEntityData.get(propdata.EntityDataId))
            .filter(entdata => entdata)
            .distinct();
        for (const page of event.pages) {
            const unitsOnPage = page.rows;
            page.isValid = !unitsOnPage.some(unit => invalidEntdatas.some(entdata => entdataBelongsToUnit(entdata, unit)));
        }
    } //-- end: validatePages -------------------------------------------------------------------//

    function entdataBelongsToUnit(entdata, unit) {
        if (entdata === unit) return true;
        if (ntaEntityData.isRelation(unit, entdata)) return true;
        if (ntaEntityData.getFirstParent(entdata, 'UNIT', true, 'RZ') === unit) return true;

        // TODO: welke andere entiteiten komen hier zoal terug op het formulier?

        return false;
    } //-- end: entdataBelongsToUnit ------------------------------------------------------------//

    function getForm() {
        return calczonesLogic.form_rzunit;
    } //-- end: getForm ------------------------------------------------------------------------//

    function getPlaceholder(prop, entdata) {
        return base.getPlaceholder(prop, entdata, calczonesLogic);
    } //-- end: getPlaceholder ------------------------------------------------------------------------//


    //-- VO 2020-10-26: onderstaande getPropdata kan weer weg als de generieke oplossing is doorgevoerd in shared-logic cf trello kaartje https://trello.com/c/eGcoEgLf
    function getPropData(prop, entdata) {
        const result = base.getPropData(prop, entdata);

        if (prop.Id === 'UNIT-RZID') {
            //-- in dit geval moet ik de value van het Result overschrijven met de ID van de RZ waar deze UNIT-RZ een relatie mee heeft.
            const parent = ntaEntityData.getFirstParent(entdata, 'RZ');
            if (parent) {
                result.Value = parent.EntityDataId;
            }
        }

        return result;
    } //-- end: getPropdata ------------------------------------------------------------------------//

    function getName(prop) {
        let str = "";
        switch (prop.Id) {
            case 'UNIT_AANTA':
            case 'UNIT_AANTU': {
                str = ntaSharedLogic.voorProjectwoningen() ? 'n<sub>woningen</sub>' : prop.Name;
                const aantal = vm.units().reduce((totaal, unit) => totaal + Number(unit.PropertyDatas[prop.Id].Value), 0);
                str += " (tot. " + aantal + ")";
                break;
            }
            case 'UNIT-RZ-GFAG':
            case 'UNIT-RZAG': { // [V]
                str = prop.Unit;
                const calcUnit = ntaSharedLogic.getCalcUnit(vm.rzform);
                if (calcUnit === "RZUNIT_GEBUNIT") {
                    str = prop.Name + " per unit [" + str + "]";
                } else if (calcUnit === "RZUNIT_GEBAPP") {
                    str = prop.Name + " per app. [" + str + "]";
                } else if (calcUnit === "RZUNIT_PROJECT") {
                    str = prop.Name + " per woning [" + str + "]";
                } else {
                    str = prop.Name + " [" + str + "]";
                }
                break;
            }
            default: {
                str = base.getName(prop);
                break;
            }
        }
        return str;
    } //-- end: getName ------------------------------------------------------------------------//

    function getUnitName() { // [A]
        let unitName = "";
        const bPerGebouw = ntaSharedLogic.perGebouw();
        if (_isUtiliteit) {
            unitName = bPerGebouw ? "utiliteitsgebouw" : "units";
        } else if (ntaSharedLogic.voorProjectwoningen()) {
            unitName = "woningen";
        } else {
            unitName = bPerGebouw ? "woning" : "appartementen";
        }

        return unitName;
    } //-- end: getUnitName ------------------------------------------------------------------------//

    function getAddName() {
        if (_isUtiliteit) {
            return "unit";
        } else if (ntaSharedLogic.voorProjectwoningen()) {
            return "woning";
        } else {
            return "appartement";
        }
    } //-- end: getAddName ------------------------------------------------------------------------//

    function hideAddUnit() {
        return !ntaSharedLogic.perGebouwEnAppartementOfUnit()
            && !ntaSharedLogic.voorProjectwoningen();
    } //-- end: hideAddUnit ------------------------------------------------------------------------//

    function isGebouw() { //[U]
        return ntaSharedLogic.getGebouwType() === 'TGEB_UTILIT' || ntaSharedLogic.getGebouwType() === 'TGEB_APPGEB';
    } //-- end: isGebouw ------------------------------------------------------------------------//

    function shouldShowPagingBar() {
        return ntaSharedLogic.perGebouwEnAppartementOfUnit()
            || ntaSharedLogic.voorProjectwoningen();
    } //-- end: shouldShowPagingBar -------------------------------------------------------------//



    function updateLists() {
        //-- onderstaande lijst zijn voor de ng-model binding van de sortabletabellen. Dit moeten namelijk assignable expressions zijn. Als ze een functie zijn doordat ze
        //-- iedere keer opnieuw opgehaald worden in de de factory gaat dat niet goed. Daarom expliciet in de controller bij houden.
        updateRekenzoneList();
        updateUnitList();
        updateUnitRzList();
        updateUnitRzGfList()
        updateGRuimteList();
    } //-- end: updateLists ------------------------------------------------------------------------//

    function updateRekenzoneList() {
        vm.rekenzoneList = vm.calczones();
    } //-- end: updateRekenzoneList ------------------------------------------------------------------------//

    function updateUnitList() {
        vm.unitList = vm.units();
    } //-- end: updateUnitList ------------------------------------------------------------------------//

    function updateUnitRzList() {
        vm.unitRzList = {};
        vm.units().forEach(function (unit, index) {
            vm.unitRzList[unit.EntityDataId] = vm.unitrzsByUnit(unit);
        });
    } //-- end: updateUnitRzList ------------------------------------------------------------------------//

    function updateUnitRzGfList() {
        vm.unitRzGfList = {};
        vm.unitrzs().forEach(function (unitrz, index) {
            vm.unitRzGfList[unitrz.EntityDataId] = vm.unitrzgfsByUnitRz(unitrz);
        });
    } //-- end: updateUnitRzGfList ------------------------------------------------------------------------//

    function updateGRuimteList() {
        vm.gruimteList = vm.gruimtes();
    } //-- end: updateGRuimteList ------------------------------------------------------------------------//

    function getGRuimteZoneOmschr(item) {
        let Omschr = "";

        if (_isUtiliteit) { //utiliteit -> lijst met unit_omschr rz en gfunctie
            const unitrz = ntaEntityData.getFirstParent(item, "UNIT-RZ");
            if (unitrz) {
                const unit = ntaEntityData.getFirstParent(unitrz, "UNIT");
                if (unit) {
                    Omschr = unit.PropertyDatas["UNIT_OMSCHR"].Value;
                    const rz = ntaEntityData.getFirstParent(unitrz, "RZ");
                    if (rz) {
                        Omschr = Omschr + ": " + rz.PropertyDatas["RZ_OMSCHR"].Value;
                    }
                }
            }

            const atrrValue = item.PropertyDatas["UNIT-RZ-GFID"].Value;
            if (atrrValue) {
                const prop = vm.unitrzgfproperties["UNIT-RZ-GFID"];
                Omschr = Omschr + ": " + prop.Domain.Codes.find(function (x) { return x.Id === atrrValue; }).Value;
            }
        } else { // TGEB_APP
            if (item) {
                Omschr = item.PropertyDatas["RZ_OMSCHR"].Value;
            }
        }

        return Omschr;
    } //-- end: getGRuimteZoneOmschr ------------------------------------------------------------------------//

    function getWordtGebruiktTBV() {
        if (_isUtiliteit) {
            //-- Utiliteit gebruikt rekenzones plus gebruiksfunctie
            return vm.unitrzgfs();
        }
        else {
            //-- Appartementen gebruiken direct de rekenzones
            return vm.calczones().filter(function (x) { return x.PropertyDatas["RZ_TYPEZ"].Value === "RZ"; });
        }
    } //-- end: getWordtGebruiktTBV ------------------------------------------------------------------------//

    function saveGRuimteKoppeling(item, gruimte, ev) {
        // koppeling zoeken met dit item (rz bij woning en unitrzgf bij ubouw)
        const relation = ntaEntityData.getChildRelations(item, gruimte.EntityId).find(rel => rel.Child === gruimte.EntityDataId);

        if (relation) {
            // koppeling verwijderen
            ntaEntityData.deleteRelation(relation.EntityRelationDataId);
        } else {
            // koppeling toevoegen
            ntaEntityData.createRelation(item.EntityDataId, gruimte.EntityDataId, false, false);
        }
        //-- check of er wel UNIT(RZ)s gekoppeld zijn aan de gruimten [R]
        calczonesLogic.checkConditionR();

        //-- past de oppervlakten verdeling aan en valideert verlichting en tapwater
        if (ntaSharedLogic.isUtiliteit()) {
            ntaSharedLogic.initTapwUnitRzsUbouw();
            //-- check of of de sportfunctie goed gaat[O].
            calczonesLogic.checkConditionO();
        }
        ntaDependencyValidation.checkGemeenschappelijkeRuimte();

        ev.stopImmediatePropagation();
        ev.preventDefault();
    } //-- end: saveGRuimteKoppeling ------------------------------------------------------------------------//

    function isUitzGFS(unitrz) {
        let bSamengevoegdeGebruiksfunctie = false;
        if (_isUtiliteit) {
            const uitz = unitrz.PropertyDatas['UNITRZ_GFS_UITZ'];
            if (uitz) {
                if (uitz.Value) {
                    bSamengevoegdeGebruiksfunctie = uitz.Value !== "GF_SAMV_UITZ4";
                }
            }
        }

        return bSamengevoegdeGebruiksfunctie;
    } //-- end: isUitzGFS ------------------------------------------------------------------------//

    function getUitzGfsMelding(unitrz) {
        //-- als een gebruiksfunctie is samengevoegd met een andere in een bepaalde unitrz terwijl dit eigenlijk niet mag, moet de aangevinkte uitzondering weergegeven worden.
        const uitz = unitrz.PropertyDatas['UNITRZ_GFS_UITZ'];
        if (uitz && uitz.Value.length > 0) {
            const unit = ntaEntityData.getFirstParent(unitrz, "UNIT");
            let unitOmschr = "";
            if (unit) {
                unitOmschr = unit.PropertyDatas["UNIT_OMSCHR"].Value;
            }

            const rz = ntaEntityData.getFirstParent(unitrz, "RZ");
            let rzOmschr = "";
            if (rz) {
                rzOmschr = rz.PropertyDatas["RZ_OMSCHR"].Value;
            }

            const str = unitOmschr + ": in rekenzone " + rzOmschr + " zijn gebruiksfuncties samengevoegd waarbij de vereiste " +
                                                                    "ventilatiecapaciteit meer dan een factor 4 verschilt. Dit is toegestaan op basis van de volgende uitzondering: ";
            const codedValues = ntabuilding.properties['UNITRZ_GFS_UITZ'].Domain.Codes;
            const strUitz = codedValues.find(function (x) { return x.Id === uitz.Value; }).Value;

            return str + strUitz;
        }

        return "";
    } //-- end: getUitzGfsMelding ------------------------------------------------------------------------//

    function addCalcZone(entdata) {
        vm.addCalczoneProgress = true;

        const newId = ntaEntityData.create('RZ', entdata ? entdata.Order : -1, [], [], [{ "PropertyId": "RZ_TYPEZ", "Value": "RZ" }]);
        ntaSharedLogic.setRekenzoneInstallRelations(newId);
        ntaDependencyValidation.checkAddedRekenzone(newId);
        calczonesLogic.checkTypePlafond();
        //-- ik maak altijd het type 'rekenzone'. daarom checken of er wel unit-rz's zijn om aan te kopppelen.
        calczonesLogic.checkUnitRekenzonesExist();
        ntaResults.updateForParents(ntaEntityData.get(newId));


        updateLists();

        vm.addCalczoneProgress = false;
    } //-- end: addCalcZone ------------------------------------------------------------------------//

    function addUnit(unit) {
        vm.addUnitProgress = true;

        const newUnitId = ntaEntityData.create('UNIT', unit ? unit.Order : -1, [], [], []);
        ntaSharedLogic.setUnitTapwRelations(newUnitId, null); //-- hier wordt de TAP-UNIT gecreeerd in geval van w-bouw, in geval van ubouw gebeurd hier niets omdat tweede parameter null is.

        //-- elke unit heeft een INFILUNIT voor luchtdoorlaten.
        ntaEntityData.create('INFILUNIT', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newUnitId, "ParentEntityId": "UNIT" }], [], []);
        //-- elke unit heeft een UNIT-RZ
        const newUnit = ntaEntityData.get(newUnitId);
        vm.addUnitRz(newUnit, null);

        updateUnitList();

        ntaDependencyValidation.checkUnit(false);
        ntaDependencyValidation.checkInfilUnit();
        ntaResults.updateForParents(newUnit);

        vm.addUnitProgress = false;
    } //-- end: addUnit ------------------------------------------------------------------------//

    function addUnitRz(unit, unitrz) {
        vm.addUnitProgress = true;
        const newUnitRz = calczonesLogic.createUnitRz(unit, unitrz);
        ntaResults.updateForParents(newUnitRz);
        updateUnitRzList();
        vm.addUnitProgress = false;
    } //-- end: addUnitRz ------------------------------------------------------------------------//

    function addUnitRzGf(unitrz, unitrzgf) {
        vm.addUnitProgress = true;
        calczonesLogic.createUnitRzGf(unitrz, unitrzgf);
        updateUnitRzGfList();
        vm.addUnitProgress = false;
    } //-- end: addUnitRzGf ------------------------------------------------------------------------//

    function addGRuimte(gruimte) {
        vm.addGRuimteProgress = true;
        //-- wanneer de berekening NIET per gebouw is, dan is er altijd sprak van invoer Averlies bij gruimtes
        const propdatas = !ntaSharedLogic.perGebouw() ? [{ "PropertyId": "GRUIMTE_AV_INVOER", "Value": "GRUIMTE_AV_INVOER_GR" }] : [];
        ntaEntityData.create('GRUIMTE', gruimte ? gruimte.Order : -1, [], [], propdatas);
        updateLists();
        vm.addGRuimteProgress = false;
    } //-- end: addGRuimte ------------------------------------------------------------------------//


    function copyCalcZone(entdata) {
        if (!entdata) {
            return;
        }

        const copiedEntDatas = ntaEntityData.copy(entdata.EntityDataId);

        updateLists();

        const rz = copiedEntDatas.find(ed => ed.EntityId === 'RZ');
        ntaResults.updateForParents(rz);

        calczonesLogic.validateUnitRekenzoneKoppeling();
        ntaDependencyValidation.checkAddedRekenzone(rz.EntityDataId);
    } //-- end: copyCalcZone ------------------------------------------------------------------------//

    function copyUnit(entdata) {
        if (!entdata) {
            return;
        }

        const [newUnit] = ntaEntityData.copy(entdata.EntityDataId);

        updateLists();
        ntaDependencyValidation.checkUnit(); // Check andere formulieren na toevoegen unit
        ntaResults.updateForParents(newUnit);
        ntaResults.checkResultTOjuliRelations(newUnit);

        if (_isUtiliteit) {
            calczonesLogic.checkConditionO();
            ntaSharedLogic.initTapwUnitRzsUbouw();
        }
    } //-- end: copyUnit ------------------------------------------------------------------------//

    function copyUnitRz(unit, entdata) {
        if (!entdata) {
            return;
        }
        if (vm.unitrzsByUnit(unit).length >= vm.calczones().length) { // kan niks toevoegen als alle rzs zijn gekozen.
            return;
        }

        const [unitRz] = ntaEntityData.copy(entdata.EntityDataId);

        updateLists();

        ntaDependencyValidation.checkUnitRz();
        ntaResults.updateForParents(unitRz);
        ntaResults.checkResultTOjuliRelationsForUnitRz(unitRz);

        if (_isUtiliteit) {
            calczonesLogic.checkConditionO();
            ntaSharedLogic.initTapwUnitRzsUbouw();
        }

        time.delayActions(calczonesLogic.validateUnitRekenzoneKoppeling);
    } //-- end: copyUnitRz ------------------------------------------------------------------------//


    function copyUnitRzGf(unitrz, entdata) {
        if (!entdata) {
            return;
        }

        const prop = vm.unitrzgfproperties['UNIT-RZ-GFID'];
        const vals = prop.Domain.Codes;
        if (vm.unitrzgfsByUnitRz(unitrz).length >= vals.length) { // kan niks toevoegen als alle gfs zijn gekozen.
            return;
        }

        ntaEntityData.copy(entdata.EntityDataId);

        ntaDependencyValidation.checkUnitRzGf();

        if (_isUtiliteit) {
            calczonesLogic.checkConditionL(unitrz);
            calczonesLogic.checkConditionO();
            ntaSharedLogic.initTapwUnitRzsUbouw();
        }

        updateLists();
    } //-- end: copyUnitRzGf ------------------------------------------------------------------------//

    function copyGRuimte(entdata) {
        if (!entdata) { return; }
        ntaEntityData.copy(entdata.EntityDataId);

        updateLists();

        if (_isUtiliteit) {
            calczonesLogic.checkConditionO();
            ntaSharedLogic.initTapwUnitRzsUbouw();
        }
        ntaDependencyValidation.checkGemeenschappelijkeRuimte();
    } //-- end: copyGRuimte ------------------------------------------------------------------------//

    function deleteCalcZone(entdata) {
        if (!entdata) {
            return;
        }
        if (vm.calczones().length === 1) { //-- kan de laatste rz niet weggooien
            return;
        }

        //-- Conditie [AD] controleren of deze rekenzone gebruikt wordt in relatie tot een UNIT-RZ
        const childUnitRzs = ntaEntityData.getChildren(entdata, 'UNIT-RZ');
        if (childUnitRzs.length > 0) {
            ntaMeldingen.warning("[W036]");
            return;
        }

        const begrenzingenMetRekenzone = ntaEntityData.getChildren(entdata, 'BEGR');
        const installationsMetRekenzone = ntaSharedLogic.getInstallationsRekenzone(entdata.EntityDataId);

        ntaEntityData.delete(entdata.EntityDataId);
        //-- nadat de rekenzone verwijderd is, voor begrenzingen en installatie chekcen/resetten van gerelateerde zaken
        ntaDependencyValidation.checkDeletedRekenzone(begrenzingenMetRekenzone, installationsMetRekenzone);
        ntaResults.updateForDeletedParent(entdata);
        updateLists();
    } //-- end: deleteCalcZone ------------------------------------------------------------------------//

    function deleteUnit(entdata) {
        if (!entdata) {
            return;
        }
        if (!ntaSharedLogic.perGebouwEnAppartementOfUnit()) {
            if (vm.units().length === 1) { //-- kan de laatste unit niet weggooien
                return;
            }
        }
        ntaEntityData.delete(entdata.EntityDataId);

        updateLists();
        ntaDependencyValidation.checkUnit();
        if (_isUtiliteit) {
            calczonesLogic.checkConditionO();
            ntaSharedLogic.initTapwUnitRzsUbouw();
        }
        ntaResults.updateForDeletedParent(entdata);
    } //-- end: deleteUnit ------------------------------------------------------------------------//

    function deleteUnitRz(parent, entdata) {
        if (!entdata || vm.unitrzsByUnit(parent).length === 1) { //-- kan de laatste unitrz niet weggooien
            return;
        }

        ntaEntityData.delete(entdata.EntityDataId);

        updateLists();

        ntaDependencyValidation.checkUnitRz();
        if (_isUtiliteit) {
            calczonesLogic.checkConditionO();
            ntaSharedLogic.initTapwUnitRzsUbouw();
        }
        ntaResults.updateForDeletedParent(entdata);
        calczonesLogic.validateUnitRekenzoneKoppeling();
    } //-- end: deleteUnitRz ------------------------------------------------------------------------//

    function deleteUnitRzGf(parent, entdata) {
        if (!entdata || vm.unitrzgfsByUnitRz(parent).length === 1) { //-- kan de laatste unitrzgf niet weggooien
            return;
        }

        ntaEntityData.delete(entdata.EntityDataId);

        ntaDependencyValidation.checkUnitRzGf();

        if (!calczonesLogic.samenvoegenGFWarning(parent)) { //-- geen error, dus verwijderen melding.
            const propUitz = ntabuilding.properties['UNITRZ_GFS_UITZ'];
            calczonesLogic.saveValue(propUitz, parent, null);
        }
        updateLists();

        if (_isUtiliteit) {
            calczonesLogic.checkConditionL(parent);
            calczonesLogic.checkConditionO();
            ntaSharedLogic.initTapwUnitRzsUbouw();
        }
    } //-- end: deleteUnitRzGf ------------------------------------------------------------------------//

    function deleteGRuimte(entdata) {
        if (!entdata) {
            return;
        }
        ntaEntityData.delete(entdata.EntityDataId);
        updateLists();

        if (_isUtiliteit) {
            calczonesLogic.checkConditionO();
            ntaSharedLogic.initTapwUnitRzsUbouw();
        }
        //-- checken van verlichtingzones en tapwaterunit oppervlakten
        ntaDependencyValidation.checkGemeenschappelijkeRuimte();
    } //-- end: deleteGRuimte ------------------------------------------------------------------------//

    vm.sortableOptionsRZ = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            ntaEntityData.SaveReOrder(vm.rekenzoneList);
        }
    }

    vm.sortableOptionsU = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            ntaEntityData.SaveReOrder(vm.pager.pageRows);
        }
    }

    vm.sortableOptionsURZ = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            var unitrzs = vm.unitRzList[ui.item.attr("unitId")];
            ntaEntityData.SaveReOrder(unitrzs);
        }
    }

    vm.sortableOptionsURZGF = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            var unitrzgfs = vm.unitRzGfList[ui.item.attr("unitrzId")];
            ntaEntityData.SaveReOrder(unitrzgfs);
        }
    };

    vm.sortableOptionsGR = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            ntaEntityData.SaveReOrder(vm.gruimteList);
        }
    }

}]);
