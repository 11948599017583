﻿angular.module('projectModule')
    .service('ntaRounding',
        ['$log', '$location',
function ($log,   $location) {
    'use strict';
    const vm = this;

    const _numberFormats = new Map();

    const decRound = {
        "R001": 0,
        "R002": 1,
        "R003": 2,
        "R004": 3,
        "R005": 4,
        "R006": 0,
        "R007": 1,
        "R008": 2,
        "R009": 3,
        "R010": 3,
        "R011": 2,
        "R013": 3,
        "R014": 2,
        "R015": 1,
        "R016": 0,
        "R017": 3,
        "R018": 0,
        "R019": 4,
        "R023": 0,
        "R024": 0
    };

    vm.parseFloat = function (text, defaultValue = NaN) {
        const result = Number.parseFloat(String(text).replace(',', '.'));
        if (!isNaN(result))
            return result;
        else
            return defaultValue;
    };

    vm.getDecimals = function (flvalue, rounding) {
        let decimals = decRound[rounding];
        if (rounding === 'R012') {
            if (flvalue > 1) {
                decimals = 1;
            }
            else {
                decimals = 2;
            }
        }
        return decimals;
    };

    vm.ntaround = function (value, rounding) {
        //value = parseFloat(value.replace(/,/, '.'));

        if (rounding === 'R000' || !rounding) {
            return value;
        }

        const decimals = vm.getDecimals(value, rounding);

        if (rounding === "R001" || rounding === "R002" || rounding === "R003" || rounding === "R004" || rounding === "R005") {
            //return Number(Math.ceil(value * 10 ** decimals) / 10 ** decimals); // is round UP to decimal
            //aanpassing, zie R014 voor uitleg
            return Number(Math.ceil(Math.round(value * 10 ** decimals * 10) / 10) / 10 ** decimals); // is round UP to decimal
        }
        if (rounding === "R006" || rounding === "R007" || rounding === "R008" || rounding === "R009" || rounding === "R010") {
            //return Number(Math.floor(value * 10 ** decimals) / 10 ** decimals); // is round DOWN to decimal
            //aanpassing, zie R014 voor uitleg
            return Number(Math.floor(Math.round(value * 10 ** decimals * 10) / 10) / 10 ** decimals); // is round DOWN to decimal
        }
        if (rounding === "R011" || rounding === "R018") {
            //return Number(Math.floor(value * (10 ** decimals / 5)) / (10 ** decimals / 5)); // is round DOWN to 0,05  // 1,248 -> 1,20; 1,252 -> 1,25
            //aanpassing, zie R014 voor uitleg
            return Number(Math.floor(Math.round(value * (10 ** decimals / 5) * 10) / 10) / (10 ** decimals / 5)); // is round DOWN to 0,05  // 1,248 -> 1,20; 1,252 -> 1,25
        }
        if (rounding === "R012") {
            return Number(Math.round(value * 10 ** decimals) / 10 ** decimals); // is round HALF UP to decimals
        }
        if (rounding === "R013") {
            //return Number(Math.floor(value * (10 ** decimals / 25)) / (10 ** decimals / 25)); // is round DOWN to 0,025  // 1,248 -> 1,20; 1,252 -> 1,25
            //aanpassing, zie R014 voor uitleg
            return Number(Math.floor(Math.round(value * (10 ** decimals / 25) * 10) / 10) / (10 ** decimals / 25)); // is round DOWN to 0,025  // 1,248 -> 1,20; 1,252 -> 1,25

        }
        if (rounding === "R014" || rounding === "R015" || rounding === "R016" || rounding === "R017" || rounding === "R019") {
            // Aantal relevante decimalen opschuiven om af te kunnen ronden, plus een extra decimaal tegen floating point onnauwkeurigheid
            // Afronden om floating point onnauwkeurigheid op te lossen
            // Extra decimaal voor floating point onnauwkeurigheid terugschuiven
            // Afronden van relevante decimalen
            // Aantal relevante decimalen terugschuiven
            return Number(Math.round(Math.round(value * 10 ** decimals * 10) / 10) / 10 ** decimals); // is round HALF UP to decimal
        }
        if (rounding === 'R023') {
            return Number(Math.round(value / 10) * 10);
        }
        if (rounding === 'R024') {
            return Number(Math.round(value / 50) * 50);
        }


        if (rounding === "R020") {
            const sigNums = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 44, 48, 52, 56, 60, 65, 70, 75, 80, 85, 90, 95, 100];
            const strVal = value.toString().split('');

            // Het eerste cijfer zoeken dat geen 0 is
            let hasDot = false;
            let indexFirstDigit = 0;
            let index;
            for (index = 0; index < strVal.length; index++) {
                if (strVal[index] === '0') {
                    continue;
                }
                else if (strVal[index] === '.') {
                    hasDot = true;
                    continue;
                }
                indexFirstDigit = index;
                break;
            }

            let numberToRound;

            // Het getal samenstellen dat wordt afgerond
            if (indexFirstDigit === strVal.length - 1) {
                numberToRound = strVal[indexFirstDigit] + '0';
            }
            else if (strVal[indexFirstDigit + 1] === '.') {
                numberToRound = strVal[indexFirstDigit] + strVal[indexFirstDigit + 2];
            }
            else {
                numberToRound = strVal[indexFirstDigit] + strVal[indexFirstDigit + 1];
            }

            // Het getal in de lijst zoeken waarnaar moet worden afgerond
            for (index = 0; index < sigNums.length; index++) {
                if (parseInt(numberToRound) <= sigNums[index]) {
                    numberToRound = sigNums[index].toString();
                    break;
                }
            }

            // Het afgeronde getal terugzetten in de array
            if (indexFirstDigit === strVal.length - 1) { // Als het eerste cijfer ongelijk aan 0 ook de laatste is
                strVal[indexFirstDigit] = numberToRound[0];
                if (hasDot) { // Plak het tweede cijfer van het afgeronde getal achter de eerste
                    strVal.push(numberToRound[1]);
                }
                else { // Plak een punt en het tweede cijfer van het afgeronde getal achter de eerste
                    strVal.push('.');
                    strVal.push(numberToRound[1]);
                }
            }
            else if (strVal[indexFirstDigit + 1] === '.') { // Als er een komma tussen het eerste en tweede cijfer valt
                if (numberToRound === '100') { // Als is afgerond op 100, moet er een 1 voor het getal
                    strVal.unshift('1');
                    strVal[indexFirstDigit + 1] = '0';
                }
                else { // Geen 100: eerste en tweede cijfer vervangen door afgerond getal
                    strVal[indexFirstDigit] = numberToRound[0];
                    strVal[indexFirstDigit + 2] = numberToRound[1];
                }

                // Alle volgende cijfers op 0 zetten
                for (index = indexFirstDigit + 3; index < strVal.length; index++) {
                    if (strVal[index] === '.') {
                        continue;
                    }
                    strVal[index] = '0';
                }
            }
            else { // Er is meer dan 1 relevant cijfer en is niet van de tweede gescheiden door een komma
                if (numberToRound === '100' && !hasDot) { // Als is afgerond op 100, moet er een 1 voor het getal
                    strVal.unshift('1');
                    strVal[indexFirstDigit + 1] = '0';
                }
                else if (numberToRound === '100' && hasDot) { // Als is afgerond op 100 en er een komma is, moet er een 1 vooraan op de juiste plek worden gezet
                    if (strVal[indexFirstDigit - 1] === '.') { // 1 voor de komma
                        strVal[indexFirstDigit - 2] = '1';
                        strVal[indexFirstDigit] = '0';
                        strVal[indexFirstDigit + 1] = '0';
                    }
                    else { // 1 ergens achter de komma
                        strVal[indexFirstDigit - 1] = '1';
                        strVal[indexFirstDigit] = '0';
                        strVal[indexFirstDigit + 1] = '0';
                    }
                }
                else { // Geen 100: eerste en tweede cijfer vervangen door afgerond getal
                    strVal[indexFirstDigit] = numberToRound[0];
                    strVal[indexFirstDigit + 1] = numberToRound[1];
                }

                // Alle volgende cijfers op 0 zetten
                for (index = indexFirstDigit + 2; index < strVal.length; index++) {
                    if (strVal[index] === '.') {
                        continue;
                    }
                    strVal[index] = '0';
                }
            }

            // Value weer in elkaar zetten
            const strValue = strVal.join('');
            return parseFloat(strValue);
        }


        if (rounding === "R021") {
            //-- TODO 2020-02-24 VO: onderstaande code moet gelijk worden aan code R020 maar dan afronding naar beneden.
            return value;
        //    var sigVal = 0;
        //    var sigNums = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 44, 48, 52, 56, 60, 65, 70, 75, 80, 85, 90, 95];
        //    var strVal = value.toString().split('');
        //    var newStr = [];
        //    var cnt = 0;
        //    if (strVal[0] === '0') {
        //        sigVal = parseFloat(strVal[2] + strVal[3]);
        //        if (sigVal < 10 && sigVal < 95) { // getallen onder de 10 moet altijd op 10 terecht komen, getallen boven de 95 altijd op 95.
        //            while (sigNums.indexOf(sigVal) === -1 && cnt <= 10) { //cnt voor voorkomen eeuwige loop
        //                sigVal++;
        //                cnt++;
        //            }
        //        } else {
        //            while (sigNums.indexOf(sigVal) === -1 && cnt <= 10) {
        //                sigVal--;
        //                cnt++;
        //            }
        //        }
        //        newStr = sigVal.toString();
        //        strVal[2] = newStr[0];
        //        strVal[3] = newStr[1];
        //        for (var i = 4; i < strVal.length; i++) {
        //            if (strVal[i] !== ".") {
        //                strVal[i] = "0";
        //            }
        //        }
        //    } else {
        //        if (strVal[1] !== ".") {
        //            sigVal = parseFloat(strVal[0] + strVal[1]);
        //        } else {
        //            sigVal = parseFloat(strVal[0] + strVal[2]);
        //        }
        //        if (sigVal < 10 && sigVal < 95) { // getallen onder de 10 moet altijd op 10 terecht komen, getallen boven de 95 altijd op 95.
        //            while (sigNums.indexOf(sigVal) === -1 && cnt <= 10) { //cnt voor voorkomen eeuwige loop
        //                sigVal++;
        //                cnt++;
        //            }
        //        } else {
        //            while (sigNums.indexOf(sigVal) === -1 && cnt <= 10) {
        //                sigVal--;
        //                cnt++;
        //            }
        //        }
        //        newStr = sigVal.toString();
        //        strVal[0] = newStr[0];
        //        var jcnt = 0; //starten vanaf
        //        if (strVal[1] !== ".") {
        //            strVal[1] = newStr[1];
        //            jcnt = 2;
        //        } else {
        //            strVal[2] = newStr[1];
        //            jcnt = 3;
        //        }
        //        for (var j = jcnt; j < strVal.length; j++) {
        //            if (strVal[j] !== ".") {
        //                strVal[j] = "0";
        //            }
        //        }
        //    }
        //    return parseFloat(strVal.toString().replace(/,/g, ''));
        }
        return Number(Math.round(value * 10 ** decimals) / 10 ** decimals); // default -> round HALF UP to decimal;
    };

    vm.roundAndAddZeros = function (prop, propdata, rounding = null) {
        if (prop.PropertyType === 1 && propdata && propdata.Value && propdata.Value !== "n.v.t.") {
            if (!rounding) {
                rounding = prop.Rounding;
            }

            //let flvalue = parseFloat(propdata.Value.replace(/,/, '.'));
            let flvalue = vm.parseFloat(propdata.Value);

            if (isNaN(flvalue)) {
                return;
            }

            flvalue = vm.ntaround(flvalue, rounding);
            propdata.Value = flvalue.toString().replace('.', ",");

            if (rounding === 'R020') {
                if (propdata.Value.length === 1) {
                    propdata.Value = propdata.Value + ',' + '0';
                }
            }
            else {
                //0-en aanvullen obc decimals
                let len = propdata.Value.length;
                let indx = propdata.Value.indexOf(",");
                indx = indx === -1 ? len : indx + 1; //geen komma -> index = lengte, wel komma -> index + 1 omdat de komma niet moet worden meegeteld...
                let decimals = vm.getDecimals(flvalue, rounding);
                let numzero = decimals - (len - indx);
                if (len === indx && numzero > 0) {
                    propdata.Value = propdata.Value + ",";
                }
                for (var i = 0; i < numzero; i++) {
                    propdata.Value = propdata.Value + "0";
                }
            }
        }
    };

    vm.roundAndAddZerosNewValue = function (prop, newValue, rounding = null) {
        let strresult = null;
        if (!prop || prop.PropertyType === 1) {
            if (!rounding && prop) {
                rounding = prop.Rounding;
            }

            let flvalue = vm.parseFloat(newValue);

            if (isNaN(flvalue)) {
                return null;
            }

            flvalue = vm.ntaround(flvalue, rounding);
            strresult = flvalue.toString().replace('.', ",");

            if (rounding === 'R020') {
                if (strresult.length === 1) {
                    strresult = strresult + ',0';
                }
            }
            else {
                //0-en aanvullen obc decimals
                const len = strresult.length;
                let indx = strresult.indexOf(",");
                indx = indx === -1 ? len : indx + 1; //geen komma -> index = lengte, wel komma -> index + 1 omdat de komma niet moet worden meegeteld...
                const decimals = vm.getDecimals(flvalue, rounding);
                const numzero = decimals - (len - indx);
                if (len === indx && numzero > 0) {
                    strresult = strresult + ",";
                }
                for (var i = 0; i < numzero; i++) {
                    strresult = strresult + "0";
                }
            }
        }
        return strresult;
    };

    vm.formatNumber = function (value, decimals, ifNull = "--") {
        if (typeof value === "number") {
            return getNumberFormat(decimals).format(value);
        } else if (value === null) {
            return ifNull;
        } else {
            return value;
        }
    };

    function getNumberFormat(decimals) {
        decimals = decimals || 0;
        let numberFormat = _numberFormats.get(decimals);
        if (!numberFormat) {
            numberFormat = new Intl.NumberFormat("nl-NL", {
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals,
            });
            _numberFormats.set(decimals, numberFormat);
        }
        return numberFormat;
    }

}]);
