﻿angular.module('projectModule')
    .controller('EnergierekeningController',
        ['$scope', '$controller', '$log', 'ntaData', 'ntaValidation', 'ntaDependencyValidation', 'EnergierekeningFactory', 'ntaDiagram', 'ntaMeldingen',
function ($scope,   $controller,   $log,   ntaData,   ntaValidation,   ntaDependencyValidation,   EnergierekeningFactory,   ntaDiagram,   ntaMeldingen) {
    'use strict';
    const ctrl = this;
    const base = $controller('NTABaseController', { $scope: $scope })
    angular.extend(ctrl, base);

    //== Imports ==================================================================================
    ctrl.ntaValidation = ntaValidation;

    //== Instance data ============================================================================
    const logic = new EnergierekeningFactory(ntaDependencyValidation);
    const _subtitleByPropId = new Map(Object.entries({
        'ENER-REK_EERSTEMAAND': 'Verbruik',
        'TARV_SALDERING':       'Tarieven',
        'CLIMATE_KNMI_INV':     'Klimaat',
    }));
    const _propsBySubtitle = new Map(); // <-- hierin houden we, per subtitel, een array met alle bijbehorende properties bij
    _subtitleByPropId.forEach((subtitle, propId) => {
        const prop = ntaData.properties[propId];
        if (prop) {
            const props = _propsBySubtitle.get(subtitle) || [];
            if (props.length === 0) {
                _propsBySubtitle.set(subtitle, props)
            }
            props.push(prop);
        }
    });
    // Zorg dat de properties in volgorde van weergave staan
    _propsBySubtitle.forEach(props => props.sort((a, b) => a.Order - b.Order));


    //== Exports ==================================================================================
    ctrl.logic = logic;
    ctrl.propertiesEnergierekeningTop = logic.propertiesEnergierekeningTop;
    ctrl.propertiesEnergierekeningBottom = logic.propertiesEnergierekeningBottom;
    ctrl.propertiesVerbruik = logic.propertiesVerbruik;
    ctrl.propertiesVerbruikOverig = logic.propertiesVerbruikOverig;
    ctrl.propertiesTarieven = logic.propertiesTarieven;
    ctrl.propertiesEnergietarieven = logic.propertiesEnergietarieven;
    ctrl.propertiesTarievenOverig = logic.propertiesTarievenOverig;
    ctrl.propertiesClimate = logic.propertiesClimate;
    ctrl.entdataEnergierekening = logic.entdataEnergierekening;
    ctrl.entdataVerbruikOverig = logic.entdataVerbruikOverig;
    ctrl.entdataTarieven = logic.entdataTarieven;
    ctrl.entdataTarievenOverig = logic.entdataTarievenOverig;
    ctrl.entdataClimate = logic.entdataClimate;
    ctrl.getVerbruiken = logic.getVerbruiken;
    ctrl.getEnergietarieven = logic.getEnergietarieven;
    ctrl.isReadOnly = logic.isReadOnly;
    ctrl.getCodedValues = logic.getCodedValues;
    ctrl.isItemChecked = logic.isItemChecked;
    ctrl.getColCount = logic.getColCount;
    ctrl.isChecked = logic.isChecked;
    ctrl.showTableVerbruik = logic.showTableVerbruik;
    ctrl.conditionB = logic.conditionB;
    ctrl.getSumOfVerbruik = logic.getSumOfVerbruik;
    ctrl.isTotalVerbruikVisible = logic.isTotalVerbruikVisible;

    ctrl.getForm = () => logic.form;
    ctrl.getPropData = logic.getPropData;
    ctrl.getPropertyType = prop => prop && prop.PropertyType;

    Object.assign(ctrl, {
        isHidden,
        getSubtitle,
        getName,
        getClass,
        datepickerDetail,
        datepickerMinDate,
        datepickerMaxDate,
        datepickerFormat,
        saveValue,
        toggleItemChecked,
    });

    //== Initialization ===========================================================================

    angular.element(function () { //alle touched properties valideren na het laden van form
        logic.startFormValidation();
        logic.setGeopend();
    });

    $scope.endFormValidation = logic.endFormValidation;


    //== Implementation ===========================================================================

    function isHidden(prop, entdata) {
        if (typeof prop === 'string') prop = ntaData.properties[prop];
        const visible = !logic.isHidden(prop, entdata);
        return !visible;
    } //-- end: isHidden --------------------------------------------------------------------------

    function getSubtitle(prop, entdata) {
        const subtitle = _subtitleByPropId.get(prop.Id) || '';

        // Controleer of deze subtitel niet al eerder is weergegeven (veralgemenisering van [MW-AF])
        for (const prevProp of _propsBySubtitle.get(subtitle) || []) {
            if (prevProp === prop) {
                break; // we hoeven alleen te kijken naar eerdere properties
            } else {
                const prevPropdata = prevProp.getData(entdata);
                if (prevPropdata && prevPropdata.Visible) {
                    return ''; // dan is deze subtitel al eerder weergegeven, en hoeft dat niet nog een keer te gebeuren.
                }
            }
        }

        return subtitle;
    } //-- end: getSubtitle -----------------------------------------------------------------------

    function getName(prop, includingUnit = true) {
        if (prop.Id === 'VERBR_VERBR_MJ') {
            switch (logic.entdataEnergierekening.PropertyDatas['ENER-REK_DOEL'].Value) {
                case 'ENER-REK_DOEL1': return 'maand';
                case 'ENER-REK_DOEL2': return 'jaarlijks verbruik';
                default: return '';
            }
        }
        return base.getName(prop, includingUnit);
    } //-- end: getName ---------------------------------------------------------------------------


    function getClass(prop) {
        switch (prop.PropertyType) {
            case 1: // number
                return 'columnCenter';
            case 3: // boolean
                return 'ntacheckbox columnCenter';
        }
        return '';
    } //-- end: getClass --------------------------------------------------------------------------

    function datepickerDetail() {
        return "month";
    } //-- end: datepickerDetail ------------------------------------------------------------------

    function datepickerMinDate() {
        return logic.getMinStartDate();
    } //-- end: datepickerMinDate -----------------------------------------------------------------

    function datepickerMaxDate() {
        return logic.getMaxStartDate();
    } //-- end: datepickerMaxDate -----------------------------------------------------------------

    function datepickerFormat() {
        return {
            formatDate: function (date) {
                return date ? moment(date).format('MMMM YYYY') : '';
            },
            parseDate: function (dateString) {
                var m = moment(dateString, 'MMMM YYYY', true); 
                return m.isValid() ? m.toDate() : new Date(NaN);
            },
            isDateComplete: function (dateString) {
                return true;
            }
        };
    } //-- end: datepickerFormat ------------------------------------------------------------------

    function saveValue(prop, entdata) {
        if (prop.Id === 'ENER-REK_EERSTEMAAND') {
            // Dag 2 specificeren, anders kan het mis gaan door de timezone offset.
            // De datum wordt opgeslagen op middernacht van de eerste van de maand *in de tijdszone van de browser*;
            //  in JSON wordt dit geconverteerd naar UTC, en dat is 22:00 of 23:00 de vorige dag, en dus de vorige maand!
            // Zie kaartje Az9QIRxb en https://stackoverflow.com/questions/44744476/angular-material-datepicker-date-becomes-one-day-before-selected-date
            const propdata = prop.getData(entdata);
            if (typeof propdata.Value === 'string') {
                propdata.Value = new Date(propdata.Value);
            }
            if (propdata.Value instanceof Date) {
                propdata.Value.setDate(2);
            }

            // [ER-U] indien dit veld wordt gewijzigd en in tabel ER07 zijn al velden ingevuld door de gebruiker geef [W132]
            if (ctrl.showTableVerbruik()) {
                const verbruikIngevuld = ctrl.getVerbruiken()
                    .flatMap(ed => ed.PropertyDatas)
                    .filter(pd => pd.PropertyId !== 'VERBR_VERBR_MJ')
                    .some(pd => pd.Visible && pd.Relevant && pd.Value);
                if (verbruikIngevuld) {
                    ntaMeldingen.warning('[W132]'); // U wijzigt de beginmaand van de energierekening. Controleer of de reeds ingevoerde verbruiken bij de juiste maand-jaar staan.
                }
            }
        }

        logic.saveValue(prop, entdata);

        if (entdata.EntityId === 'VERBR') {
            ntaDiagram.updateVerbruikDiagramWindow(prop, entdata);
        }
        if (prop.Id === 'ENER-REK_EERSTEMAAND') {
            ntaDiagram.updateAllDataDiagramWindow({ refreshVerbruik: true, refreshClimate: true });
        }
        if (prop.Id === 'CLIMATE_KNMI_STATION' || prop.Id === 'CLIMATE_KNMI_INV' || prop.Id === 'CLIMATE_POSTCODE' || prop.Id === 'CLIMATE_HEAT_ISLAND') {
            ntaDiagram.updateAllDataDiagramWindow({ refreshVerbruik: false, refreshClimate: true });
        }

        base.initializeUnitSpacing();
    } //-- end: saveValue -------------------------------------------------------------------------

    function toggleItemChecked(prop, entdata, item) {
        logic.toggleItemChecked(prop, entdata, item);
        ntaDiagram.updateAllDataDiagramWindow({ refreshVerbruik: true, refreshClimate: false });

    } //-- end: toggleItemChecked -----------------------------------------------------------------

}]);
