﻿angular.module('projectModule')
    .factory('NtaSharedLogicFactory',
        ['ntabuilding', 'ntaEntityDataOrg', 'ntaMeldingen', 'ntaRounding', '$location', '$log', 'ntaData', 'ntaSelectionTable', 'ntaDeltas', 'ntaResults', 'NtaEntityDataFactory',
function (ntabuilding,   ntaEntityDataOrg,   ntaMeldingen,   ntaRounding,   $location,   $log,   ntaData,   ntaSelectionTable,   ntaDeltas,   ntaResults,   NtaEntityDataFactory) {
    'use strict';

    const _afgifteEntityIds = {
        'VERW': ['VERW-AFG', 'VERW-AFG-VENT'],
        'KOEL': ['KOEL-AFG', 'KOEL-AFG-VENT'],
    };

    //ConditionA [ER-A]
    //  energiedrager	        eenheid	    prijs per eenheid   conditie
    //  gas 	                m3aeq	    € / m3	            toon indien in basisberekening aanwezig is:
    //                                                          - bij een verwarmingsformulier bij minimaal één van de opwekkers in HO05 (VERW-OPWEK_TYPE) is 'warmtepomp - gasabsorptie (VERW-OPWEK_TYPE_U) / warmtepomp - gasmotor (VERW-OPWEK_TYPE_B) / CV-ketel - gas (VERW-OPWEK_TYPE_H) / kachel - gas (VERW-OPWEK_TYPE_J) / centrale direct gestookte luchtverwarmer - gas (VERW-OPWEK_TYPE_L) / lokale direct gestookte luchtverwarmer - gas (VERW-OPWEK_TYPE_N)/ WKK - micro (VERW-OPWEK_TYPE_O) / WKK - overig (VERW-OPWEK_TYPE_P) / stoomketel - gas (VERW-OPWEK_TYPE_Q) / onbekende gemeenschappelijke opwekker' (VERW-OPWEK_TYPE_S)
    //                                                          - OF bij een warm tapwaterformulier bij minimaal één van de opwekkers in TO05 (TAPW-OPWEK_TYPE) is 'warmtepomp - gas (TAPW-OPWEK_TYPE_3) / CV-ketel - gas (TAPW-OPWEK_TYPE_12) / geiser - gas (TAPW-OPWEK_TYPE_14) / gasboiler ≤ 150 kW (TAPW-OPWEK_TYPE_15) / gasboiler > 150 kW (TAPW-OPWEK_TYPE_16) / WKK (TAPW-OPWEK_TYPE_17) / zonneboiler met geïntegreerde gasgestookte naverwarming (TAPW-OPWEK_TYPE_19) / onbekende gemeenschappelijke opwekker' (TAPW-OPWEK_TYPE_20)
    //                                                          - OF bij een bevochtigingsformulier bij BE02 (BEV_TYPE) is gekozen 'stoombevochtiging - gas' (BEV_TYPE_ST_CENGAS)
    //                                                          - OF bij een koelingformulier bij minimaal één van de opwekkers in KO05 (KOEL-OPWEK_TYPE) is gekozen voor een opwekker 'compressiekoeling - gas (KOEL-OPWEK_TYPE_2) / absorptiekoeling - gas (KOEL-OPWEK_TYPE_8) / absorptiekoeling - WKK' (KOEL-OPWEK_TYPE_10)
    //  e-levering	            kWh	        € / kWh	            toon altijd
    //  e-teruglevering	        kWh	        € / kWh	            toon indien in basisberekening aanwezig is:
    //  en e-opwekking(omvormer)                                - een PV formulier
    //                                                          - OF windenergie formulier
    //                                                          - OF een zonneboilerformulier met bij ZBC17 (ZONNC_PVT) = 'PVT systeem onafgedekt' (ZONNC_PVT_ONAFG) / 'PVT systeem afgedekt' (ZONNC_PVT_AFG)
    //                                                          - OF bij een verwarmingsformulier bij minimaal één van de opwekkers in HO05 (VERW-OPWEK_TYPE) is 'WKK - micro (VERW-OPWEK_TYPE_O) / WKK - overig' (VERW-OPWEK_TYPE_P)
    //                                                          - OF bij een warm tapwaterformulier bij minimaal één van de opwekkers in TO05 (TAPW-OPWEK_TYPE) is 'WKK' (TAPW-OPWEK_TYPE_17)
    //  olie	                liter       € / liter	        toon indien in basisberekening aanwezig is:
    //                                                          - bij een verwarmingsformulier bij minimaal één van de opwekkers in HO05 (VERW-OPWEK_TYPE) is 'CV-ketel olie (VERW-OPWEK_TYPE_T) / kachel - olie (VERW-OPWEK_TYPE_K) / stoomketel - olie' (VERW-OPWEK_TYPE_R)
    //                                                          - OF bij een warm tapwaterformulier bij minimaal één van de opwekkers in TO05 (TAPW-OPWEK_TYPE) is 'CV-ketel - olie' (TAPW-OPWEK_TYPE_13)
    //                                                          - OF bij een bevochtigingsformulier bij BE02 (BEV_TYPE) is gekozen 'stoombevochtiging - olie' (BEV_TYPE_ST_CENOLIE)
    //  biomassa	            kWh	        € / kWh	            toon indien in basisberekening aanwezig is:
    //                                                          - bij een verwarmingsformulier bij minimaal één van de opwekkers in HO05 (VERW-OPWEK_TYPE) is 'CV-ketel biomassa (VERW-OPWEK_TYPE_F) / kachel - biomassa' (VERW-OPWEK_TYPE_I)
    //                                                          - OF bij een warm tapwaterformulier bij minimaal één van de opwekkers in TO05 (TAPW-OPWEK_TYPE) is 'CV-ketel - biomassa' (TAPW-OPWEK_TYPE_10)
    //                                                          - OF bij een bevochtigingsformulier bij BE02 (BEV_TYPE) is gekozen 'stoombevochtiging - gas' (BEV_TYPE_ST_CENGAS)
    //  warmte	                GJ	        € / GJ	            toon indien in basisberekening aanwezig is:
    //                                                          - bij een verwarmingsformulier bij minimaal één van de opwekkers in HO05 (VERW-OPWEK_TYPE) is 'externe warmtelevering' (VERW-OPWEK_TYPE_C)
    //                                                          - OF bij een warm tapwaterformulier bij minimaal één van de opwekkers in TO05 (TAPW-OPWEK_TYPE) is 'externe warmtelevering' (TAPW-OPWEK_TYPE_4)
    //                                                          - OF bij een koelingformulier bij minimaal één van de opwekkers in KO05 (KOEL-OPWEK_TYPE) is gekozen voor een opwekker 'absorptiekoeling - externe warmtelevering' (KOEL-OPWEK_TYPE_9)
    //  koude	                GJ	        € / GJ	            toon indien in basisberekening aanwezig is:
    //                                                          - bij een koelingformulier bij minimaal één van de opwekkers in KO05 (KOEL-OPWEK_TYPE) is 'externe koudelevering' (KOEL-OPWEK_TYPE_7)

    //info voor conditionA [ER-A]
    const _energyCarriers = [
        {
            type: 'GAS', conditions: [
                { entity: 'VERW-OPWEK', property: 'VERW-OPWEK_TYPE', values: ["VERW-OPWEK_TYPE_U", "VERW-OPWEK_TYPE_B", "VERW-OPWEK_TYPE_H", "VERW-OPWEK_TYPE_J", "VERW-OPWEK_TYPE_L", "VERW-OPWEK_TYPE_N", "VERW-OPWEK_TYPE_O", "VERW-OPWEK_TYPE_P", "VERW-OPWEK_TYPE_Q", "VERW-OPWEK_TYPE_S"] },
                { entity: 'TAPW-OPWEK', property: 'TAPW-OPWEK_TYPE', values: ["TAPW-OPWEK_TYPE_3", "TAPW-OPWEK_TYPE_12", "TAPW-OPWEK_TYPE_14", "TAPW-OPWEK_TYPE_15", "TAPW-OPWEK_TYPE_16", "TAPW-OPWEK_TYPE_17", "TAPW-OPWEK_TYPE_19", "TAPW-OPWEK_TYPE_20"] },
                { entity: 'BEV', property: 'BEV_TYPE', values: ["BEV_TYPE_ST_CENGAS"] },
                { entity: 'KOEL-OPWEK', property: 'KOEL-OPWEK_TYPE', values: ["KOEL-OPWEK_TYPE_2", "KOEL-OPWEK_TYPE_8", "KOEL-OPWEK_TYPE_10"] }]
        },
        {
            type: 'E', conditions: [
                { entity: 'PV' },
                { entity: 'WINDT' },
                { entity: 'ZONNECOL', property: 'ZONNC_PVT', values: ["ZONNC_PVT_ONAFG", "ZONNC_PVT_AFG"] },
                { entity: 'VERW-OPWEK', property: 'VERW-OPWEK_TYPE', values: ["VERW-OPWEK_TYPE_O", "VERW-OPWEK_TYPE_P"] },
                { entity: 'TAPW-OPWEK', property: 'TAPW-OPWEK_TYPE', values: ["TAPW-OPWEK_TYPE_17"] }]
        },
        {
            type: 'OLIE', conditions: [
                { entity: 'VERW-OPWEK', property: 'VERW-OPWEK_TYPE', values: ["VERW-OPWEK_TYPE_T", "VERW-OPWEK_TYPE_K", "VERW-OPWEK_TYPE_R"] },
                { entity: 'TAPW-OPWEK', property: 'TAPW-OPWEK_TYPE', values: ["TAPW-OPWEK_TYPE_13"] },
                { entity: 'BEV', property: 'BEV_TYPE', values: ["BEV_TYPE_ST_CENOLIE"] }]
        },
        {
            type: 'BIOM', conditions: [
                { entity: 'VERW-OPWEK', property: 'VERW-OPWEK_TYPE', values: ["VERW-OPWEK_TYPE_F", "VERW-OPWEK_TYPE_I"] },
                { entity: 'TAPW-OPWEK', property: 'TAPW-OPWEK_TYPE', values: ["TAPW-OPWEK_TYPE_10"] }]
        },
        {
            type: 'W', conditions: [
                { entity: 'VERW-OPWEK', property: 'VERW-OPWEK_TYPE', values: ["VERW-OPWEK_TYPE_C"] },
                { entity: 'TAPW-OPWEK', property: 'TAPW-OPWEK_TYPE', values: ["TAPW-OPWEK_TYPE_4"] },
                { entity: 'KOEL-OPWEK', property: 'KOEL-OPWEK_TYPE', values: ["KOEL-OPWEK_TYPE_9"] }]
        },
        {
            type: 'K', conditions: [{ entity: 'KOEL-OPWEK', property: 'KOEL-OPWEK_TYPE', values: ["KOEL-OPWEK_TYPE_7"] }]
        },
    ];


    return function NtaSharedLogicFactory(ntaEntityData) {
        const vm = this;

        /// INTERFACE ////////////////////////////////////////////////////////////
        vm.parseFloat = parseFloat;
        vm.startFormValidation = startFormValidation;
        vm.endFormValidation = endFormValidation;
        vm.setMelding = setMelding;
        vm.getMsgTxt = getMsgTxt;
        vm.htmlEncode = htmlEncode;
        vm.updateErrorReplaceObject = updateErrorReplaceObject;

        vm.getPropData = getPropData;
        vm.unfoldPropdata = unfoldPropdata;
        vm.getRelData = getRelData;
        vm.hasRelation = hasRelation;
        vm.getUnitSpecificResultPropsAndEntdatas = getUnitSpecificResultPropsAndEntdatas;
        vm.getUnitSpecificResultPropAndEntdata = getUnitSpecificResultPropAndEntdata;
        vm.getUnitSpecificResultPropdatas = getUnitSpecificResultPropdatas;
        vm.getUnitSpecificResultPropdata = getUnitSpecificResultPropdata;
        vm.isUnitSpecificPropId = isUnitSpecificPropId;
        vm.getNonUnitSpecificPropdata = getNonUnitSpecificPropdata;
        vm.useUnitSpecificValue = useUnitSpecificValue;

        vm.setUnitTapwRelations = setUnitTapwRelations;
        vm.setTapwUnitRelations = setTapwUnitRelations;
        vm.createTapwUnitAndRelations = createTapwUnitAndRelations;
        vm.checkHotfillBoiler = checkHotfillBoiler;

        vm.showBelemmeringsProperty = showBelemmeringsProperty;
        vm.getBelemmeringPropertyIds = getBelemmeringPropertyIds;

        vm.rekenzones = getRekenzones;
        vm.setRekenzoneInstallRelations = setRekenzoneInstallRelations;
        vm.createRekenzoneSysteemRelation = createRekenzoneSysteemRelation;
        vm.deleteRekenzoneSysteemRelation = deleteRekenzoneSysteemRelation;
        vm.deleteZonneboilerSysteemRelation = deleteZonneboilerSysteemRelation;
        vm.validateSystemRelationCounts = validateSystemRelationCounts;
        vm.validateRekenzoneSystemCounts = validateRekenzoneSystemCounts;
        vm.validateSystemZonneboilerCounts = validateSystemZonneboilerCounts;
        vm.validateZonneboilerSystemCounts = validateZonneboilerSystemCounts;

        vm.controleerRekenzoneVerwarmingAfgifteRelaties = controleerRekenzoneAfgifteRelaties;
        vm.controleerRekenzoneKoelingAfgifteRelaties = controleerRekenzoneAfgifteRelaties;

        vm.createRZ_VERW_Relations = createRZ_VERW_Relations;
        vm.createRZ_VENT_Relations = createRZ_VENT_Relations;
        vm.createRZ_KOEL_Relations = createRZ_KOEL_Relations;
        vm.createRZ_BEV_Relations = createRZ_BEV_Relations;

        vm.initTapwUnitsWoon = initTapwUnitsWoon;
        vm.initTapwUnitRzsUbouw = initTapwUnitRzsUbouw;

        vm.setAantalIdentiekeSystemen = setAantalIdentiekeSystemen;
        vm.setTouchedFalseAantalIdentiekesystemen = setTouchedFalseAantalIdentiekesystemen;
        vm.checkPropdataInList = checkPropdataInList;
        vm.saveValue = saveValue;
        vm.saveValueSelectionTable = saveValueSelectionTable;
        vm.saveProductValueToPropData = saveProductValueToPropData;

        vm.navToIndelingGebouw = navToIndelingGebouw;
        vm.navToInstallations = navToInstallations;
        vm.navToInstallation = navToInstallation;

        vm.isUtiliteit = isUtiliteit;
        vm.isNieuwbouw = isNieuwbouw;
        vm.isBestaandeBouw = isBestaandeBouw;
        vm.isNietGrondGebonden = isNietGrondGebonden;
        vm.isBasisOpname = isBasisOpname;
        vm.isDetailOpname = isDetailOpname;
        vm.isAppartementOfUtiliteitVoorBestaandeBouw = isAppartementOfUtiliteitVoorBestaandeBouw;
        vm.isUnitInUtiliteitsgebouw = isUnitInUtiliteitsgebouw;
        vm.isGroteInstallatie = isGroteInstallatie;
        vm.isGemeenschappelijkInstallatie = isGemeenschappelijkInstallatie;
        vm.aantalSystemenReadOnly = aantalSystemenReadOnly;
        vm.isVakantiewoning = isVakantiewoning;
        vm.systeem = systeem;
        vm.isEditingMeasure = isEditingMeasure;
        vm.getEditingMeasureId = getEditingMeasureId;
        vm.isValidatingVariantWithMeasureType = isValidatingVariantWithMeasureType;

        vm.getZoneName = getZoneName;
        vm.getUnitName = getUnitName;
        vm.getGebEntdata = getGebEntdata;
        vm.getGebouwType = getGebouwtype;
        vm.getBouwjaar = getBouwjaar;
        vm.getNGetalByUnitRz = getNGetalByUnitRz;
        vm.getDefaultValueAantalWoonfuncties = getDefaultValueAantalWoonfuncties;
        vm.getInstallationsRekenzone = getInstallationsRekenzone;
        vm.getGRuimtesForAverlies = getGRuimtesForAverlies;

        vm.getCalcUnit = getCalcUnit;
        vm.perGebouw = perGebouw;
        vm.perAppartementOfUnit = perAppartementOfUnit;
        vm.perGebouwEnAppartement = perGebouwEnAppartement;
        vm.perGebouwEnUnit = perGebouwEnUnit;
        vm.perGebouwEnAppartementOfUnit = perGebouwEnAppartementOfUnit;
        vm.voorProjectwoningen = voorProjectwoningen;

        vm.AgRz = AgRz;
        vm.AgTotaal = AgTotaal;
        vm.AgSumOfUnitRZs = AgSumOfUnitRZs;
        vm.AgCommOfEntdata = AgCommOfEntdata;
        vm.AgSumRZbySystem = AgSumRZbySystem;
        vm.getGebruiksfunctieWeightsByRekenzone = getGebruiksfunctieWeightsByRekenzone;

        vm.orderElements = orderElements;
        vm.orderSystemByInstallation = orderSystemByInstallation;
        vm.orderByValue = orderByValue;

        vm.checkUnitSpecificResultEntities = checkUnitSpecificResultEntities;

        vm.checkResultEis = checkResultEis;
        vm.isEMGforf = isEMGforf;
        vm.isTOjuli = heeftTOjuli;
        vm.isUnitTOjuli = heeftUnitTOjuli;
        vm.isEnergielabel = heeftEnergielabel;
        vm.markResultsVisible = markResultsVisible;

        vm.isMaatwerkadvies = showCosts;
        vm.showCosts = showCosts;
        vm.showVariants = showVariants;
        vm.showOnlyValidProducts = showOnlyValidProducts;
        vm.calculateInstallationCosts = calculateInstallationCosts;
        vm.calculatePVCosts = calculatePVCosts;
        vm.getCostEntdata = getCostEntdata;
        vm.getVariantBuildingData = getVariantBuildingData;
        vm.hasConstructionOverlap = hasConstructionOverlap;

        vm.showWarningGemeenschappelijkeInstallatie = showWarningGemeenschappelijkeInstallatie;
        vm.preventBackspace = preventBackspace;
        vm.getDragerAanwezigMap = getDragerAanwezigMap;
        vm.getVerbruikMonthAndYear = getVerbruikMonthAndYear;

        /// INITIALIZATION ///////////////////////////////////////////////////////

        const _unitResultProps = ntaData.unitResultProperties;
        const _unitSpecificPropIds = new Set(Object.values(_unitResultProps).map(info => info.propId));


        /// IMPLEMENTATION ///////////////////////////////////////////////////////
        function getDragerAanwezigMap() {
            //vullen voor conditionA [ER-A]
            const dragerAanwezig = new Map();
            for (const energyCarrier of _energyCarriers) {
                dragerAanwezig.set(energyCarrier.type, isEnergiedragerAanwezig(energyCarrier.conditions));
            }
            return dragerAanwezig;
        } //-- end: getDragerAanwezigMap  ----------------------------------------------------------------//

        function htmlEncode(text) {
            if (!text) return text;

            const span = document.createElement('span');
            span.textContent = text;
            return span.innerHTML;
        }

        function orderElements(a, b) {
            return a.Order - b.Order;
        } //-- end: orderElements ------------------------------------------------------------------------//

        function orderSystemByInstallation(a, b) {
            const installationA = ntaEntityData.getFirstParent(a, 'INSTALLATIE');
            const installationB = ntaEntityData.getFirstParent(b, 'INSTALLATIE');
            return (installationA && installationA.Order || Number.MAX_SAFE_INTEGER) - (installationB && installationB.Order || Number.MAX_SAFE_INTEGER);
        }

        function orderByValue(a, b) {
            if (a.Value === b.Value) {
                return 0;
            } else if (a.Value == b.Value) {
                return 0;
            } else if (a.Value < b.Value) {
                return -1;
            } else {
                return 1;
            }
        }

        function parseFloat(text, defaultValue = NaN) {
            const result = Number.parseFloat(String(text).replace(',', '.'));
            if (!isNaN(result))
                return result;
            else
                return defaultValue;
        } //-- end: parseFloat ------------------------------------------------------------------------//

        function checkUnitSpecificResultEntities() {
            /// Controleer of alle unit-specifieke entiteiten bestaan (indien nodig); of zorg er anders voor dat de betreffende entiteiten niet relevant zijn.
            const unitResEntdatas = new Set();
            const createdEntdataIds = [];
            const relevant = voorProjectwoningen();
            if (relevant) {
                const units = ntaEntityData.getListWithEntityId('UNIT');

                // Controleer of alle betreffende entiteiten bestaan; zo niet, maak ze dan aan.
                const resultPropIds = Object.keys(_unitResultProps);
                for (const resultPropId of resultPropIds) {
                    const prop = ntaData.properties[resultPropId];
                    if (!prop) continue;

                    const { path, entityId } = _unitResultProps[resultPropId];

                    for (const mainEntData of ntaEntityData.getListWithEntityId(prop.EntityId)) {
                        const resParent = path ? ntaEntityData.findEntity(mainEntData, path) : mainEntData;
                        if (!resParent) {
                            $log.warn('Verwachte resultaatparent van', mainEntData, `niet gevonden op ${path}.`);
                            continue;
                        }

                        const resChildren = new Set(ntaEntityData.getChildren(resParent, entityId));
                        for (const unit of units) {
                            const unitResEntdata = ntaEntityData.getChildren(unit, entityId)
                                .find(ed => resChildren.has(ed));
                            if (unitResEntdata) {
                                unitResEntdatas.add(unitResEntdata);
                            } else {
                                // De resultaatentiteit bestaat nog niet; dan maken we deze nu aan.
                                // Deze hoeven we niet meer op relevant te zetten: dat is standaard
                                //  al het geval; dus niet meer toevoegen aan unitResEntdatas.
                                const parentRels = [
                                    { "OnCopy": 1, "OnDelete": 1, "Parent": unit.EntityDataId, "ParentEntityId": unit.EntityId },
                                    { "OnCopy": 1, "OnDelete": 1, "Parent": resParent.EntityDataId, "ParentEntityId": resParent.EntityId },
                                ];
                                const newId = ntaEntityData.create(entityId, -1, parentRels);
                                createdEntdataIds.push(newId);

                                if (resParent.EntityId === 'ZONNECOL') {
                                    const zonnbUnits = ntaEntityData.findEntities(ntaEntityData.getFirstChild(resParent.EntityDataId), "^VERW.RZ.UNIT-RZ.^UNIT", "^TAPW.TAPW-UNIT!.^UNIT", "^TAPW.TAPW-UNIT-RZ!.^UNIT-RZ.^UNIT")
                                    ntaEntityData.setEntityRelevancy(ntaEntityData.get(newId), zonnbUnits.includes(unit));

                                }
                            }
                        }
                    }
                }
            } else {
                // Alle unit-specifieke resultaatentiteiten moeten dan op niet-relevant gezet worden,
                //  behalve degene die ook niet-resultaatproperties hebben (zoals zonnecollectoren).
                const resultOnlyEntityIds = new Set(Object.values(_unitResultProps)
                    .map(info => info.entityId)
                    .distinct()
                    .filter(entityId => (ntaData.properties[entityId] || []).every(prop => prop.CalcResult)));
                const irrelevantEntdatas = Object.values(_unitResultProps)
                    .filter(info => resultOnlyEntityIds.has(info.entityId))
                    .map(info => info.entityId)
                    .distinct() // zorgen dat we geen EntityId dubbel gaan opvragen
                    .flatMap(entityId => ntaEntityData.getListWithEntityId(entityId));
                for (const entdata of irrelevantEntdatas) {
                    unitResEntdatas.add(entdata);
                }
            }
            ntaEntityData.setEntityRelevancy([...unitResEntdatas], relevant);

            // We geven een lijst terug met alle EntdataIds die we hebben toegevoegd
            return createdEntdataIds;
        } //-- end: checkUnitSpecificResultEntities ---------------------------------------------------//

        function getUnitSpecificResultPropsAndEntdatas(prop, entdata, selectedUnitId = null, secondTime = false) {
            const pairs = [];

            if (typeof prop === 'string') prop = ntaData.properties[prop];

            if (!prop) return pairs;
            if (!entdata) return pairs;
            if (!prop.CalcResult) return pairs;
            if (!voorProjectwoningen()) return pairs;

            const unitResultProp = _unitResultProps[prop.Id];
            if (unitResultProp) {
                const { path, entityId, propId } = unitResultProp;
                const altProp = ntaData.properties[propId];
                if (altProp) {
                    const parent = path ? ntaEntityData.findEntity(entdata, path) : entdata;
                    if (parent) {
                        const units = selectedUnitId
                            ? ntaEntityData.getList([selectedUnitId])
                            : ntaEntityData.getListWithEntityId('UNIT');
                        for (const unit of units) {
                            const altEntdata = ntaEntityData.getChildren(parent, entityId)
                                .find(ed => ntaEntityData.isRelation(unit, ed));
                            if (altEntdata) {
                                pairs.push([altProp, altEntdata]);
                            } else if (secondTime) {
                                $log.error(`Geen ${entityId} gevonden voor ${unit.EntityId} ${unit.EntityDataId} en ${entdata.EntityId} ${entdata.EntityDataId}, ook niet na ${checkUnitSpecificResultEntities.name}!`);
                            } else {
                                $log.warn(`Geen ${entityId} gevonden voor ${unit.EntityId} ${unit.EntityDataId} en ${entdata.EntityId} ${entdata.EntityDataId}.`);
                                // Kennelijk ontbreken er unitspecifieke resultaatentiteiten; we gaan ze allemaal controleren (en corrigeren indien nodig)
                                const createdEntdataIds = checkUnitSpecificResultEntities();
                                if (createdEntdataIds.length > 0) {
                                    // Als er entiteiten zijn toegevoegd, dan opnieuw proberen
                                    return getUnitSpecificResultPropsAndEntdatas(prop, entdata, selectedUnitId, true);
                                }
                            }
                        }
                    } else {
                        $log.warn(`Geen entiteit gevonden op ${path} vanaf ${entdata.EntityId} ${entdata.EntityDataId}.`);
                    }
                } else {
                    $log.warn(`Unit-specifieke property ${propId} niet gevonden als alternatief voor ${prop.Id}!`);
                }
            }

            return pairs;
        } //-- end: getUnitSpecificResultPropsAndEntdatas -------------------------------------------//

        function getUnitSpecificResultPropAndEntdata(prop, entdata, selectedUnitId = ntabuilding.activeBerekening && ntabuilding.activeBerekening.Id) {
            const [pair] = selectedUnitId && getUnitSpecificResultPropsAndEntdatas(prop, entdata, selectedUnitId) || [];
            return pair || [];
        } //-- end: getUnitSpecificResultPropAndEntdata ---------------------------------------------//

        function getUnitSpecificResultPropdatas(prop, entdata, selectedUnitId = null) {
            const pairs = getUnitSpecificResultPropsAndEntdatas(prop, entdata, selectedUnitId);
            return pairs.map(([unitResProp, unitResEntdata]) => unitResProp.getData(unitResEntdata));
        } //-- end: getUnitSpecificResultPropdatas --------------------------------------------------//

        function getUnitSpecificResultPropdata(prop, entdata, selectedUnitId = ntabuilding.activeBerekening && ntabuilding.activeBerekening.Id) {
            const [unitResProp, unitResEntdata] = selectedUnitId && getUnitSpecificResultPropAndEntdata(prop, entdata, selectedUnitId) || [];
            return unitResProp && unitResEntdata && unitResProp.getData(unitResEntdata);
        } //-- end: getUnitSpecificResultPropdata ---------------------------------------------------//

        function isUnitSpecificPropId(propId) {
            return _unitSpecificPropIds.has(propId);
        } //-- end: isUnitSpecificPropId ------------------------------------------------------------//

        function getNonUnitSpecificPropdata(propdata) {
            const unitResPropId = propdata.PropertyId;
            if (isUnitSpecificPropId(unitResPropId)) {
                const [mainPropId, { path, entityId, propId }] = Object.entries(_unitResultProps)
                    .find(([_, info]) => info.propId === unitResPropId);
                const mainProp = ntaData.properties[mainProp];
                const mainEntityId = mainProp.EntityId;
                let mainEntdata = ntaEntityData.getParents(propdata.EntityDataId).find(ed => ed.EntityId !== 'UNIT');
                if (path) {
                    const fullPath = mainEntityId + '.' + path;
                    const reversePathSections = [];
                    let lastUp = false;
                    for (const [_, up, entityId] of fullPath.matchAll(/(\^)?([A-Z0-9_-]+)/g).reverse()) {
                        const section = lastUp ? '' : '^' + entityId;
                        reversePathSections.push(section);
                        lastUp = up;
                    }
                    const reversePath = reversePathSections.join('.');
                    mainEntdata = ntaEntityData.findEntity(mainEntdata, reversePath);
                }
                const mainPropdata = mainEntdata && mainEntdata.PropertyDatas[mainPropId];
                if (mainPropdata) {
                    return mainPropdata;
                }
            }
            return propdata;
        } //-- end: getNonUnitSpecificPropdata ------------------------------------------------------//

        function useUnitSpecificValue(prop, entdata) {
            if (!entdata) return false;
            if (!voorProjectwoningen()) return false;

            if (typeof prop === 'string') prop = ntaData.properties[prop];

            const propdata = prop.getData(entdata);
            if (!propdata) return false;

            const [unitResProp, unitResEntdata] = getUnitSpecificResultPropAndEntdata(prop, entdata);
            if (!unitResProp || !unitResEntdata || !unitResEntdata.Relevant) return false;

            const unitResPropdata = unitResProp.getData(unitResEntdata);
            if (!unitResPropdata) return false;

            propdata.Value = unitResPropdata.Value;
            return true;
        } //-- end: useUnitSpecificValue ------------------------------------------------------------//

        function getPropData(prop, entdata, unitSpecificIfRelevant = true) {
            if (typeof prop === 'string') prop = ntaData.properties[prop];

            if (!prop) return;
            if (!entdata) return;

            if (voorProjectwoningen() && unitSpecificIfRelevant) {
                // Bij projectwoningen worden de resultaatproperties per unit opgeslagen;
                //  we moeten dan de unit-specifieke versie van de betreffende propdata opzoeken,
                //  en die teruggeven ipv de gevraagde propdata.
                const [altProp, altEntdata] = getUnitSpecificResultPropAndEntdata(prop, entdata);
                if (altProp && altEntdata) {
                    prop = altProp;
                    entdata = altEntdata;
                }
            }

            const propdata = prop.getData(entdata);

            //if (prop.Domain) {
            // -- VO 2020-10-07: Code voor trello kaartje https://trello.com/c/eGcoEgLf
            //    if (prop.Domain.DomainType === 6 || prop.Domain.DomainType === 7) {
            //        //-- deze property is verwijst naar een andere enitity. Ik  moet de enity ophalen en de Id als value teruggeven.
            //        let reldata = getRelDataByParent(entdata, prop.Domain.RelationName);
            //        if (reldata) {
            //            propdata.Value = reldata.Child;
            //        } else {
            //            reldata = getRelDataByChild(entdata, prop.Domain.RelationName);
            //            if (reldata) {
            //                propdata.Value = reldata.Parent;
            //            } else {
            //                //-- er is geen relatie
            //                if (!prop.Domain.DomainType === 7) {
            //                    //-- dan is de prop gecombineert tussen codedvalues en entity verwijzignen en kan er bij "geen realtie" een codedvalue gekozen zijn en mag ik hem niet overschrijven.
            //                    propdata.Value = null;
            //                }
            //            }
            //        }
            //    }
            //}

            return propdata;
        } //-- end: getPropData ---------------------------------------------------------------------//

        function unfoldPropdata(propdata) {
            let prop, entdata;
            if (propdata) {
                prop = ntaData.properties[propdata.PropertyId];
                entdata = ntaEntityData.get(propdata.EntityDataId);
            }
            return [prop, entdata];
        } //-- end: unfoldPropdata ------------------------------------------------------------------//

        function getRelData(entdata, relatedEntityId) {
            return ntaEntityData.getChildRelations(entdata, relatedEntityId)[0]
                || ntaEntityData.getParentRelations(entdata, relatedEntityId)[0];
        } //-- end: getRelData ----------------------------------------------------------------------//

        function getZoneName(entdata) {
            if (!entdata) {
                return;
            }

            let name = "";
            const propRzOmschr = ntaData.properties['RZ_OMSCHR']
            const propGruimteOmschr = ntaData.properties['GRUIMTE_OMSCHR']
            switch (entdata.EntityId) {
                case 'UNIT-RZ':
                    {
                        //-- ik ga vlakken (begrenzingen) onder een rekenzone 'hangen'
                        const rz = ntaEntityData.getFirstParent(entdata, "RZ");
                        if (rz) {
                            name = propRzOmschr.getValue(rz);
                        }
                        break;
                    }
                case 'RZ':
                    {
                        //-- ik ga vlakken (begrenzingen) onder een AOR of AOS 'hangen'
                        name = propRzOmschr.getValue(entdata);
                        break;
                    }
                case 'GRUIMTE':
                    {
                        //-- ik ga vlakken (begrenzingen) onder een gemeenschappelijke ruimte 'hangen'
                        name = propGruimteOmschr.getValue(entdata);
                        break;
                    }
            }
            return name;
        } //-- end: getZoneName ------------------------------------------------------------------------//

        function getUnitName(entdata) {
            if (!entdata) {
                return;
            }

            let name = "";
            const propUnitOmschr = ntaData.properties['UNIT_OMSCHR']
            switch (entdata.EntityId) {
                case 'UNIT-RZ':
                    {
                        const unit = ntaEntityData.getFirstParent(entdata, "UNIT");
                        if (unit) {
                            name = propUnitOmschr.getValue(unit);
                        }
                        break;
                    }
            }
            return name;
        } //-- end: getUnitName ------------------------------------------------------------------------//

        function startFormValidation(entdatas, logic) {
            const invalidPropdatas = [];
            if (ntabuilding.canSave()) {
                for (const entdata of entdatas) {
                    for (const propdata of entdata.PropertyDatas) {
                        try {
                            const isValid = logic.validate(ntaData.properties[propdata.PropertyId], propdata, entdata);
                            if (isValid === false) {
                                invalidPropdatas.push(propdata);
                            }
                        } catch (err) {
                            $log.warn(err.message, err);
                        }
                    }
                }
            }
            return invalidPropdatas;
        } //-- end: startFormValidation ------------------------------------------------------------------------//

        function endFormValidation(entdatas, logic) {
            for (const entdata of entdatas) {
                for (const propdata of entdata.PropertyDatas) {
                    if (!propdata.Touched) {
                        propdata.Touched = true;
                        ntaEntityData.saveprop(propdata);
                    }
                }
            }
            return startFormValidation(entdatas, logic);
        } //-- end: endFormValidation ------------------------------------------------------------------------//

        function setMelding(code, propdata, form, valid, touched = false, shadowId = ntaData.current.shadowId) {
            if (!propdata) {
                return
            }

            //-- de message voor de control op formulier zetten
            const contrl = form && form['ntainput' + propdata.PropertyDataId];
            if (contrl) {
                contrl.$setValidity(code, valid);
                if (touched) {
                    contrl.$touched = true;
                    contrl.$untouched = false;
                }
            }

            let msg = "";
            if (propdata.replaceObjects) {
                let error = ntaData.errors[code].Value;
                for (const obj of propdata.replaceObjects) {
                    const search = '{{ctrl.getMsgTxt(prop, entdata, "' + obj.id + '")}}';
                    error = error.replace(search, obj.text);
                }
                msg = error;
            }
            ntaMeldingen.melding(code, propdata.PropertyDataId, valid, msg, shadowId);
        } //-- end: setMeldingVariableMessage ------------------------------------------------------------------------//

        function getMsgTxt(prop, entdata, replaceId) {
            let txt = "";
            if (!prop || !entdata) {
                return txt;
            }

            let propdata = prop.getData(entdata);
            if (propdata && propdata.replaceObjects) {
                let obj = propdata.replaceObjects.find(function (x) { return x.id === replaceId; });
                txt = obj ? obj.text : txt;
            }

            return txt;
        } //-- end: getMsgTxt ------------------------------------------------------------------------//

        function updateErrorReplaceObject(propdata, id, text) {
            if (!propdata) {
                $log.warn(`Geen propdata om tekst voor ‘${id}’ te vervangen door „${text}”.`);
                return;
            }

            if (!propdata.replaceObjects) {
                propdata.replaceObjects = [];
            }

            let result = propdata.replaceObjects.find(function (x) { return x.id === id; });
            if (!result) {
                result = { id: id, text: "" };
                propdata.replaceObjects.push(result);
            }
            result.text = text;

            return result;
        }//-- end: updateErrorReplaceObject  ------------------------------------------------------------------------//

        function setUnitTapwRelations(unitId, unitrzId) {
            //-- bij het aanmaken van een Unit of een UNIT-RZ moet er voor ieder tapwatersysteem een TAPW-UNIT-(RZ) aangemaakt worden. Alleen voor het eerste
            //-- tapwatersysteem moet deze nieuwe TAPW-UNIT-(RZ) relevant zijn. De gene die ik voor het tweede systeem aanmaak moeten niet relevant zijn.
            const utiliteit = isUtiliteit();
            const parentEntityType = utiliteit ? 'UNIT-RZ' : 'UNIT';
            const koppelEntityType = utiliteit ? 'TAPW-UNIT-RZ' : 'TAPW-UNIT';
            const parentId = utiliteit ? unitrzId : unitId;
            if (parentId) {
                const tapwinstallations = ntaEntityData.getListWithEntityId('TAPW');
                tapwinstallations.forEach(function (tapw, index) {
                    createTapwUnitAndRelations(tapw.EntityDataId, parentId, parentEntityType, koppelEntityType, index === 0);
                });
            }
        } //-- end: setUnitTapwRelations ------------------------------------------------------------------------//

        function setTapwUnitRelations(tapwId) {
            //-- bij het aanmaken van een TAPW systeem moet er voor iedere UNIT-(RZ) een TAPW-UNIT-(RZ) aangemaakt worden. Omdat het eerste tapwater systeem
            //-- bij het aanmaken van een Building al gekoppeld is met de op dat moment aanwezige UNIT-(RZ)s moet ik alle TAPW-UNIT-(RZ) die ik hier aanmaak op
            //-- relevant=false zetten.
            const utiliteit = isUtiliteit();
            const parentEntityType = utiliteit ? 'UNIT-RZ' : 'UNIT';
            const koppelEntityType = utiliteit ? 'TAPW-UNIT-RZ' : 'TAPW-UNIT';
            const parents = ntaEntityData.getListWithEntityId(parentEntityType);
            parents.forEach(function (parent, index) {
                createTapwUnitAndRelations(tapwId, parent.EntityDataId, parentEntityType, koppelEntityType, false);
            });
        } //-- end: setTapwUnitRelations ------------------------------------------------------------------------//

        function getRekenzones() {
            return ntaEntityData.getListWithEntityId('RZ')
                .filter(rz => rz.PropertyDatas['RZ_TYPEZ'].Value === 'RZ');
        } //-- end: rekenzones ------------------------------------------------------------------------//

        function validateSystemRelationCounts(entdata) {
            switch (entdata.EntityId) {
                case 'RZ':
                    return validateRekenzoneSystemCounts(entdata);
                case 'VERW':
                case 'TAPW':
                    return validateSystemZonneboilerCounts(entdata);
                case 'ZONNB':
                    return validateZonneboilerSystemCounts(entdata);
                default:
                    $log.warn(`Onverwachte type entiteit in ${validateSystemRelationCounts.name}(`, entdata, ')');
                    break;
            }
        } //-- end: validationSystemRelationCounts ----------------------------------------------------

        function validateRekenzoneSystemCounts(rzEntdata) {
            const instErrors = []; // zie installationLogic.checkErrors
            /*
                * Controleer of verschillende verwarmingsystemen op dezelfde rekenzone(s) zijn aangesloten. Indien dit het geval is toon [E115]
                * Controleer of verschillende koelsystemen op dezelfde rekenzone(s) zijn aangesloten. Indien dit het geval is toon [E116]
                * Controleer of verschillende ventilatiesystemen op dezelfde rekenzone(s) zijn aangesloten. Indien dit het geval is toon [E117]
                * Controleer of verschillende bevochtigingssystemen op dezelfde rekenzone(s) zijn aangesloten. Indien dit het geval is toon [E118]
            */
            const systemTypes = [
                { entityId: 'VERW', min: 1, errorId: '[E115]' },
                { entityId: 'KOEL', min: 0, errorId: '[E116]' },
                { entityId: 'VENT', min: 1, errorId: '[E117]' },
                { entityId: 'BEV', min: 0, errorId: '[E118]' },
            ];
            const isRekenzone = rzEntdata.PropertyDatas['RZ_TYPEZ'].Value === 'RZ';
            const installationsFormId = ntaEntityData.getFirstWithEntityId('INSTALLATIONS-FORM').EntityDataId;
            const meldingInputIds = [installationsFormId, rzEntdata.EntityDataId];
            const shadowId = ntaEntityData.getShadowId();
            for (const { entityId, min, errorId } of systemTypes) {
                let valid = true; // voor een AOR of AOS moet deze melding überhaupt niet weergegeven worden.
                let instError = null;
                const error = ntaData.errors[errorId];
                const message = error && error.Value && error.Value.replace(/{{naam rekenzone}}/g, rzEntdata.PropertyDatas['RZ_OMSCHR'].Value);
                if (isRekenzone) {
                    const systems = ntaEntityData.getParents(rzEntdata, entityId);
                    valid = min <= systems.length && systems.length <= 1;
                    if (!valid) {
                        instError = {
                            code: errorId,
                            name: message,
                            shadowId,
                        };
                    }
                }
                const res = ntaMeldingen.melding(errorId, meldingInputIds, valid, message, shadowId);
                if (instError) {
                    instError.meldingId = res.newId || res.existing && res.existing.EntityDataId;
                    instErrors.push(instError);
                }
            }
            return instErrors;
        } //-- end: validateRekenzoneSystemCounts -----------------------------------------------------

        function validateSystemZonneboilerCounts(systemEntdata) {
            const instErrors = []; // zie installationLogic.checkErrors
            /*
                * Controleer of verschillende zonneboilers op hetzelfde verwarmingssysteem of tapwatersysteem zijn aangesloten. Indien dit het geval is toon [E119]
            */
            const errorId = '[E119]';
            const installationsFormId = ntaEntityData.getFirstWithEntityId('INSTALLATIONS-FORM').EntityDataId;
            const meldingInputIds = [installationsFormId, systemEntdata.EntityDataId];
            const valid = ntaEntityData.getChildren(systemEntdata, 'ZONNB').length <= 1;
            const error = ntaData.errors[errorId];
            const installation = ntaEntityData.getFirstParent(systemEntdata, 'INSTALLATIE');
            let installationName = installation && installation.PropertyDatas['INSTALL_NAAM'] && installation.PropertyDatas['INSTALL_NAAM'].Value;
            if (!installationName) {
                const typeCode = ntaData.properties['INSTALL_TYPE'].getCode(installation);
                installationName = typeCode && typeCode.Value || 'systeem';
            }
            const message = error && error.Value && error.Value.replace(/{{naam systeem}}/g, installationName);
            const shadowId = ntaEntityData.getShadowId();
            const res = ntaMeldingen.melding(errorId, meldingInputIds, valid, message, shadowId);
            if (!valid) {
                instErrors.push({
                    code: errorId,
                    name: message,
                    shadowId,
                    meldingId: res.newId || res.existing && res.existing.EntityDataId,
                });
            }
            return instErrors;
        } //-- end: validateSystemZonneboilerCounts ---------------------------------------------------

        function validateZonneboilerSystemCounts(zbEntdata) {
            const instErrors = []; // zie installationLogic.checkErrors
            /*
                * Controleer of verschillende zonneboilers op hetzelfde verwarmingssysteem of tapwatersysteem zijn aangesloten. Indien dit het geval is toon [E120]
            */
            const errorId = '[E120]';
            const installationsFormId = ntaEntityData.getFirstWithEntityId('INSTALLATIONS-FORM').EntityDataId;
            const meldingInputIds = [installationsFormId, zbEntdata.EntityDataId];
            const error = ntaData.errors[errorId];
            const installation = ntaEntityData.findEntity(zbEntdata, '^INSTALLATIE');
            let installationName = installation && installation.PropertyDatas['INSTALL_NAAM'] && installation.PropertyDatas['INSTALL_NAAM'].Value;
            if (!installationName) {
                const typeCode = ntaData.properties['INSTALL_TYPE'].getCode(installation);
                installationName = typeCode && typeCode.Value || 'zonneboiler';
            }
            const message = error && error.Value && error.Value.replace(/{{naam zonneboiler}}/g, installationName);
            const shadowId = ntaEntityData.getShadowId();
            for (const entityId of ['VERW', 'TAPW']) {
                const systems = ntaEntityData.getParents(zbEntdata, entityId);
                const valid = systems.length <= 1;
                const msg = message.replace(/{{type systeem}}/g, ntaData.entities[entityId].Name.toLowerCase());
                const res = ntaMeldingen.melding(errorId, meldingInputIds, valid, msg, shadowId);
                if (!valid) {
                    instErrors.push({
                        code: errorId,
                        name: msg,
                        shadowId,
                        meldingId: res.newId || res.existing && res.existing.EntityDataId,
                    });
                }
            }
            return instErrors;
        } //-- end: validateZonneboilerSystemCounts ---------------------------------------------------

        function setRekenzoneInstallRelations(rzId) {
            //-- bij het aanmaken van een rekenzone moet ik een koppeling maken met de betreffende installatiesystemen
            createRZ_VERW_Relations(rzId);
            createRZ_VENT_Relations(rzId);
            createRZ_KOEL_Relations(rzId);
            createRZ_BEV_Relations(rzId);
        } //-- end: setRekenzoneInstallRelations ------------------------------------------------------------------------//

        function deleteZonneboilerSysteemRelation(relId) {
            ntaEntityData.deleteRelation(relId);
        } //-- end: deleteZonneboilerSysteemRelation ------------------------------------------------------------------------//

        function createRekenzoneSysteemRelation(rzId, sysId, sysType) {
            //-- bij alle andere systemen met rekenzones is het
            ntaEntityData.createRelation(sysId, rzId, 0, 0);

            //-- nu nog de specifieke relaties voor afgifte aanmaken
            const [afgifteEntityId, afgifteventilatorEntityId] = _afgifteEntityIds[sysType] || [];

            if (afgifteEntityId) {
                // VERW of KOEL
                const afgifte = ntaEntityData.getFirstChild(sysId, afgifteEntityId);
                if (afgifte) {
                    const afgifteRzRelatie = ntaEntityData.getRelation(rzId, afgifte);
                    if (!afgifteRzRelatie) { // bestaat nog niet
                        ntaEntityData.createRelation(rzId, afgifte.EntityDataId, 0, 0);
                    }
                    // bij het koppelen van een *-AFG ook de *-AFG-VENT koppelen
                    const ventilatorenAfgifte = ntaEntityData.getChildren(afgifte, afgifteventilatorEntityId);
                    const ventilatorenRekenzone = new Set(ntaEntityData.getChildren(rzId, afgifteventilatorEntityId));
                    let afgifteventilator = ventilatorenAfgifte.find(afgVent => ventilatorenRekenzone.has(afgVent));
                    if (!afgifteventilator) {
                        // check of er een afgifteventilator is die nog niet aan een rekenzone hangt
                        afgifteventilator = ventilatorenAfgifte.find(afgVent => !ntaEntityData.getFirstParent(afgVent, 'RZ'));
                        if (!afgifteventilator) {
                            const parentRels = [
                                { Parent: afgifte.EntityDataId, ParentEntityId: afgifte.EntityId, OnCopy: 1, OnDelete: 1 },
                            ];
                            const newId = ntaEntityData.create(afgifteventilatorEntityId, -1, parentRels, [], null);
                            afgifteventilator = ntaEntityData.get(newId);

                        }
                        ntaEntityData.createRelation(rzId, afgifteventilator.EntityDataId, 1, 1);
                    }
                }

                //-- conditie [AAA] van verwarming / conditie [KAP] van koeling
                //-- controleer hoeveel afgiftesystemen dit systeem heeft
                const afgiftesystemen = ntaEntityData.getChildren(sysId, afgifteEntityId);

                //-- controleer hoeveel rekenzones er nog gekoppeld zijn aan dit systeem
                const systeemrekenzones = ntaEntityData.getChildren(sysId, 'RZ');

                afgiftesystemen.forEach((afgiftesysteem, index) => {
                    if (index < systeemrekenzones.length) {
                        ntaEntityData.setEntityRelevancy(afgiftesysteem, true);
                        ntaEntityData.setEntityVisibility(afgiftesysteem, true);
                    }
                });
            } else {
                //-- bij ventilatiesystemen moeten de wtw toevoerkanalen gecheckt worden want deze zitten ook gekopppeld
                //-- aan rekenzones. Iedere rekenzone heeft per wtw een WARMTE-TOEV-KAN entitieit. Als de koppeling gemaakt wordt, m
                //-- moet deze op relevant gezet worden. Dat gebeurt in de init van de ventilatiefactory, maar moet eigenlijk hier, omdat
                //-- ik ook kan switchen zonder naar het formulier te gaan. Uiteindelijk is dit afgehandeld in de define-factory die na
                //-- het aan of uitvinken van een relatie de logic van ventilatie aanroept.
                if (sysType === 'VENT') {
                    //-- alle units die horen bij de net aangevinkte rekenzone moet voor ventilatie gecheckt worden op hun relatie-
                    //-- onderelen: WARMTE-TOEV-KAN, VENTILATOREIG en VENT-VERB
                }
                return;
            }

        } //-- end: createRekenzoneSysteemRelation ------------------------------------------------------------//

        function deleteRekenzoneSysteemRelation(relId, rzId, sysId, sysType) {
            ntaEntityData.deleteRelation(relId);

            const [afgifteEntityId, afgifteventilatorEntityId] = _afgifteEntityIds[sysType] || [];

            if (afgifteEntityId) {
                const afgiftesystemen = ntaEntityData.getChildren(sysId, afgifteEntityId);
                for (const afgifte of afgiftesystemen) {
                    const afgifteRzRelaties = ntaEntityData.getRelationsBetween(rzId, afgifte);
                    for (const afgifteRZRelatie of afgifteRzRelaties) {
                        ntaEntityData.deleteRelation(afgifteRZRelatie.EntityRelationDataId);
                    }

                    //ook relaties met VERW-AFG-VENT/KOEL-AFG-VENT verwijderen
                    const afgifteVentRzRelaties = ntaEntityData.getChildIds(afgifte, afgifteventilatorEntityId)
                        .flatMap(afgifteVentId => ntaEntityData.getRelationsBetween(rzId, afgifteVentId));
                    for (const afgifteVentRZRelatie of afgifteVentRzRelaties) {
                        ntaEntityData.deleteRelation(afgifteVentRZRelatie.EntityRelationDataId);
                    }

                }

                controleerRekenzoneAfgifteRelaties(sysId);
            }
        } //-- end: deleteRekenzoneSysteemRelation ------------------------------------------------------------------------//

        function controleerRekenzoneAfgifteRelaties(sysId) {
            const systeem = ntaEntityData.get(sysId);
            if (!systeem) {
                return;
            }
            const [afgifteEntityId] = _afgifteEntityIds[systeem.EntityId] || [];

            if (afgifteEntityId) {
                //-- conditie [AAA] van verwarming / [KAP] van koeling

                //-- contorleer hoeveel afgifte systemen dit systeem heeft
                const afgiften = ntaEntityData.getChildren(systeem, afgifteEntityId);

                //-- controleer hoeveel rekenzones er nog gekoppeld zijn aan dit systeem
                const aantalRekenzones = ntaEntityData.getChildRelations(systeem, 'RZ').length;

                //-- als er minder rekenzones zijn dan afgiftesystemen moet ik de laatste afgiftesystemen irrelevant maken.
                const irrelevanteAfgiften = afgiften.slice(aantalRekenzones);
                ntaEntityData.setEntityRelevancy(irrelevanteAfgiften, false);
                ntaEntityData.setEntityVisibility(irrelevanteAfgiften, false);
            }
        }

        function createRZ_VERW_Relations(rzGuid, verwGuid = null) {
            let verwInstall = null;
            if (verwGuid) {
                verwInstall = ntaEntityData.get(verwGuid);
            } else {
                //-- als er geen systeem meegegeven wordt, moet ik de eerste ophalen
                verwInstall = ntaEntityData.getFirstWithEntityId('VERW'); //-- het eerste verwarmingssysteem
            }
            if (verwInstall) {
                //-- voor alle rekenzone een koppeling maken met dit systeem en zijn afgifte
                if (rzGuid) {
                    //-- als er een rzGuid bekent is bij deze aanroep alleen relatie maken met de rekenzone die meegegeven wordt.
                    vm.createRekenzoneSysteemRelation(rzGuid, verwInstall.EntityDataId, verwInstall.EntityId);
                } else {
                    //-- anders relaties maken met alle rekenzones
                    vm.rekenzones().forEach(function (rz, index) {
                        vm.createRekenzoneSysteemRelation(rz.EntityDataId, verwInstall.EntityDataId, verwInstall.EntityId);
                    });
                }
            }
        } //-- end: createRZ_VERW_Relations ------------------------------------------------------------------------//

        function createRZ_VENT_Relations(rzGuid, ventGuid) {
            let ventInstall = null;
            if (ventGuid) {
                ventInstall = ntaEntityData.get(ventGuid);
            } else {
                //-- als er geen systeem meegegeven wordt, moet ik de eerste ophalen
                ventInstall = ntaEntityData.getFirstWithEntityId('VENT'); //-- het eerste verwarmingssysteem
            }
            if (ventInstall) {
                //-- voor alle rekenzone een koppeling maken met dit systeem en zijn afgifte
                if (rzGuid) {
                    //-- als er een rzGuid bekent is bij deze aanroep alleen relatie maken met de rekenzone die meegegeven wordt.
                    vm.createRekenzoneSysteemRelation(rzGuid, ventInstall.EntityDataId, ventInstall.EntityId);
                } else {
                    //-- anders relaties maken met alle rekenzones
                    vm.rekenzones().forEach(function (rz, index) {
                        vm.createRekenzoneSysteemRelation(rz.EntityDataId, ventInstall.EntityDataId, ventInstall.EntityId);
                    });
                }
            }
        } //-- end: createRZ_VENT_Relations ------------------------------------------------------------------------//

        function createRZ_KOEL_Relations(rzGuid, koelGuid) {
            let koelInstall = null;
            if (koelGuid) {
                koelInstall = ntaEntityData.get(koelGuid);
            } else {
                //-- als er geen systeem meegegeven wordt, moet ik de eerste ophalen
                koelInstall = ntaEntityData.getFirstWithEntityId('KOEL'); //-- het eerste verwarmingssysteem
            }
            if (koelInstall) {
                //-- voor alle rekenzone een koppeling maken met dit systeem en zijn afgifte
                if (rzGuid) {
                    //-- als er een rzGuid bekent is bij deze aanroep alleen relatie maken met de rekenzone die meegegeven wordt.
                    vm.createRekenzoneSysteemRelation(rzGuid, koelInstall.EntityDataId, koelInstall.EntityId);
                } else {
                    //-- anders relaties maken met alle rekenzones
                    vm.rekenzones().forEach(function (rz, index) {
                        vm.createRekenzoneSysteemRelation(rz.EntityDataId, koelInstall.EntityDataId, koelInstall.EntityId);
                    });
                }
            }
        } //-- end: createRZ_KOEL_Relations ------------------------------------------------------------------------//

        function createRZ_BEV_Relations(rzGuid, bevGuid) {
            let bevInstall = null;
            if (bevGuid) {
                bevInstall = ntaEntityData.get(bevGuid);
            } else {
                //-- als er geen systeem meegegeven wordt, moet ik de eerste ophalen
                bevInstall = ntaEntityData.getFirstWithEntityId('BEV'); //-- het eerste verwarmingssysteem
            }
            if (bevInstall) {
                //-- voor alle rekenzone een koppeling maken met dit systeem en zijn afgifte
                if (rzGuid) {
                    //-- als er een rzGuid bekent is bij deze aanroep alleen relatie maken met de rekenzone die meegegeven wordt.
                    vm.createRekenzoneSysteemRelation(rzGuid, bevInstall.EntityDataId, bevInstall.EntityId);
                } else {
                    //-- anders relaties maken met alle rekenzones
                    vm.rekenzones().forEach(function (rz, index) {
                        vm.createRekenzoneSysteemRelation(rz.EntityDataId, bevInstall.EntityDataId, bevInstall.EntityId);
                    });
                }
            }
        } //-- end: createRZ_BEV_Relations ------------------------------------------------------------------------//

        function createTapwUnitAndRelations(tapwId, unitId, unitEntityType, koppelEntityType, relevant) {
            const parentRels = [
                { Parent: tapwId, ParentEntityId: 'TAPW', OnCopy: 1, OnDelete: 1, },
                { Parent: unitId, ParentEntityId: unitEntityType, OnCopy: 1, OnDelete: 1, },
            ];
            const newId = ntaEntityData.create(koppelEntityType, -1, parentRels, [], []);
            const entTapwUnit = ntaEntityData.get(newId);
            ntaEntityData.setEntityRelevancy(entTapwUnit, relevant);
            ntaEntityData.setEntityVisibility(entTapwUnit, relevant);

            //-- nu moet ik de nieuwe entiteit nog vullen met default waarden.
            if (entTapwUnit.EntityId === 'TAPW-UNIT') {
                initTapwUnitWoon(entTapwUnit);
            } else if (entTapwUnit.EntityId === 'TAPW-UNIT-RZ') {
                initTapwUnitUbouw(entTapwUnit);
            }
            return entTapwUnit;
        } //-- end: createTapwUnitAndRelations ------------------------------------------------------------------------//

        function checkHotfillBoiler(tapwEntdataOrId, typeOpwekker = undefined) {
            // [TOCV] indien WN/WB EN in kolom 2 bij TO05 = hotfill boiler (met/zonder elektrisch element) EN aan het warm tapwatersysteem (installatie tegels) zijn uitsluitend keuken(s) gekoppeld: toon [W144]
            // Deze functie geeft de Promise van de melding als deze weergegeven wordt, en `false` als dat niet zo is.
            if (isUtiliteit()) return false;

            // Als het type opwekker niet expliciet is opgegeven, zoek dan de huidige waarde op
            if (typeOpwekker === undefined) {
                const tapwOpwekker2 = ntaEntityData.findEntities(tapwEntdataOrId, 'TAPW-OPWEK')[1]; // de 2e opwekker
                typeOpwekker = tapwOpwekker2 && tapwOpwekker2.PropertyDatas['TAPW-OPWEK_TYPE'].Value; // TO05
            }
            if (typeOpwekker === 'TAPW-OPWEK_TYPE_23') { // hotfill boiler (met/zonder elektrisch element)
                const tapwUnits = ntaEntityData.findEntities(tapwEntdataOrId, 'TAPW-UNIT!');
                const aantalKeukens = tapwUnits.reduce((sum, tapwUnit) => sum + (parseInt(tapwUnit.PropertyDatas['TAPW-UNIT_KEUKENS'].Value) || 0), 0);
                const aantalBadkamers = tapwUnits.reduce((sum, tapwUnit) => sum + (parseInt(tapwUnit.PropertyDatas['TAPW-UNIT_BADRUIMTEN'].Value) || 0), 0);
                if (aantalKeukens > 0 && aantalBadkamers === 0) {
                    return ntaMeldingen.warning('[W144]');
                }
            }

            return false;
        } //-- end: checkHotfillBoiler ------------------------------------------------------------


        const _belemmeringPropIds = { "empty": true };

        function showBelemmeringsProperty(prop, entdata, belemmeringstype, isInstallatie = false) {
            let showit = getBelemmeringPropertyIds(belemmeringstype, isInstallatie).has(prop.Id);
            const belemmeringPropsAll = ntaData.properties['BELEMMERING']

            switch (prop.Id) {
                case "BELEMM_HOEK_BELEM":
                case "BELEMM_HOOGTE_BELEM":
                case "BELEMM_HOR_A_BELEM": {
                    const propdata = getPropData(belemmeringPropsAll.find(x => x.Id === "BELEMM_CONST_BELEM"), entdata);
                    showit = showit && propdata && propdata.Value === "BELEMM_CONST-BELEMM_REKEN"; //conditie [K]
                    break;
                }
                case "BELEMM_HOEK_OVERST":
                case "BELEMM_HOOGTE_OVERST":
                case "BELEMM_HOR_A_OVERST": {
                    if (belemmeringstype === "BELEMTYPE_CO_EN_ZIJ") {
                        const propdata = getPropData(belemmeringPropsAll.find(x => x.Id === "BELEMM_CONST_OVERST_ZIJBELEM"), entdata)
                        showit = showit && propdata && propdata.Value === "BELEMM_CONST-OVERST_REKEN"; //conditie [L]
                        break;
                    } else {
                        const propdata = getPropData(belemmeringPropsAll.find(x => x.Id === "BELEMM_CONST_OVERST"), entdata)
                        showit = showit && propdata && propdata.Value === "BELEMM_CONST-OVERST_REKEN"; //conditie [L]
                        break;
                    }
                }
                case "BELEMM_HOEK_LINKS":
                case "BELEMM_HOR_B_LINKS":
                case "BELEMM_HOR_A_LINKS": {
                    const propdata = getPropData(belemmeringPropsAll.find(x => x.Id === "BELEMM_ZIJ_LINKS"), entdata)
                    showit = showit && propdata && propdata.Value === "BELEMM_ZIJBELEMML_REKEN"; //conditie [M]
                    break;
                }
                case "BELEMM_HOEK_RECHTS":
                case "BELEMM_HOR_B_RECHTS":
                case "BELEMM_HOR_A_RECHTS": {
                    const propdata = getPropData(belemmeringPropsAll.find(x => x.Id === "BELEMM_ZIJ_RECHTS"), entdata)
                    showit = showit && propdata && propdata.Value === "BELEMM_ZIJBELEMMR_REKEN"; //conditie [N]
                    break;
                }
            }
            return showit;
        } //-- end: showBelemmeringsProperty ----------------------------------------------------------

        function getBelemmeringPropertyIds(belemmeringstype, isInstallatie = false) {
            const instPrefix = "inst_";

            if (_belemmeringPropIds.empty) {
                const propIds = {
                    "BELEMTYPE_ZIJ_LINKS": ["BELEMM_ZIJ_LINKS", "BELEMM_HOOGTE_LINKS", "BELEMM_HOR_A_LINKS", "BELEMM_HOR_B_LINKS", "BELEMM_HOEK_LINKS"],
                    "BELEMTYPE_ZIJ_RECHTS": ["BELEMM_ZIJ_RECHTS", "BELEMM_HOOGTE_RECHTS", "BELEMM_HOR_A_RECHTS", "BELEMM_HOR_B_RECHTS", "BELEMM_HOEK_RECHTS"],
                    "BELEMTYPE_CONST_BELEM": ["BELEMM_CONST_BELEM", "BELEMM_HOR_A_BELEM", "BELEMM_HOOGTE_BELEM", "BELEMM_HOEK_BELEM"],
                    "BELEMTYPE_CONST_OVERST": ["BELEMM_CONST_OVERST", "BELEMM_HOR_A_OVERST", "BELEMM_HOOGTE_OVERST", "BELEMM_HOEK_OVERST"],
                    "BELEMTYPE_CO_EN_ZIJ": ["BELEMM_CONST_OVERST_ZIJBELEM", "BELEMM_HOR_A_OVERST", "BELEMM_HOOGTE_OVERST", "BELEMM_HOEK_OVERST"],
                };
                propIds["BELEMTYPE_ZIJ_BEIDE"] = propIds["BELEMTYPE_ZIJ_LINKS"].concat(propIds["BELEMTYPE_ZIJ_RECHTS"]);

                const noInstPropIds = ['BELEMM_HOOGTE_LINKS', 'BELEMM_HOOGTE_RECHTS']; // Deze properties moeten achterwege gelaten geworden als het om beschaduwing van installaties gaat

                for (const type of Object.keys(propIds)) {
                    const propIdSet = new Set(propIds[type]);

                    _belemmeringPropIds[type] = propIdSet;

                    const instPropIdSet = new Set(propIdSet);
                    for (const propId of noInstPropIds) {
                        instPropIdSet.delete(propId);
                    }
                    _belemmeringPropIds[instPrefix + type] = instPropIdSet;
                }

                delete _belemmeringPropIds.empty;
            }

            const prefix = isInstallatie ? instPrefix : '';
            return _belemmeringPropIds[prefix + belemmeringstype] || new Set([]);
        } //-- end: getBelemmeringPropertyIds ---------------------------------------------------------

        function getGebruiksfunctieWeightsByRekenzone(inclGemeenschappelijkeRuimtes = true, rekenzoneIds = null) {
            const gebruiksfunctieweightsByRekenzone = new Map();

            const rekenzones = rekenzoneIds
                ? (Array.isArray(rekenzoneIds) ? rekenzoneIds : [rekenzoneIds]).map(rzid => ntaEntityData.get(rzid)).filter(rz => rz)
                : getRekenzones();
            for (const rz of rekenzones) {
                const aandeelPerGebruiksfunctie = new Map();
                if (isUtiliteit()) {
                    let agTotaal = 0.0;
                    const gebruiksfunctieEDs = ntaEntityData.findEntities(rz, 'UNIT-RZ.UNIT-RZ-GF');
                    for (const gebrfuncED of gebruiksfunctieEDs) {
                        let agGebrFunc = parseFloat(gebrfuncED.PropertyDatas['UNIT-RZ-GFAG'].Value, 0.0);
                        if (inclGemeenschappelijkeRuimtes) {
                            agGebrFunc += AgCommByFunction(gebruiksfunctieEDs);
                        }
                        agTotaal += agGebrFunc;

                        const gebruiksfunctieId = gebrfuncED.PropertyDatas['UNIT-RZ-GFID'].Value;
                        const agSoFar = aandeelPerGebruiksfunctie.get(gebruiksfunctieId) || 0.0;
                        aandeelPerGebruiksfunctie.set(gebruiksfunctieId, agSoFar + agGebrFunc);
                    }
                    if (agTotaal) {
                        for (const gebruiksfunctieId of aandeelPerGebruiksfunctie.keys()) {
                            aandeelPerGebruiksfunctie.set(gebruiksfunctieId, aandeelPerGebruiksfunctie.get(gebruiksfunctieId) / agTotaal);
                        }
                    }
                } else {
                    // Bij woningbouw zijn geen afzonderlijke gebruiksfuncties; om dezelfde structuur
                    //  te kunnen gebruiken geven we de niet-bestaande GF_WONING terug.
                    aandeelPerGebruiksfunctie.set('GF_WONING', 1);
                }
                gebruiksfunctieweightsByRekenzone.set(rz.EntityDataId, aandeelPerGebruiksfunctie);
            }
            return gebruiksfunctieweightsByRekenzone;
        } //-- end: getGebruiksfunctieWeightsByRekenzone ----------------------------------------------

        function AgRz(unitRz, inclGruimten, inclNgetal) {
            let result = 0;
            //-- in deze functie bepaal ik de Ag van een UnitRZ EXCLUSIEF de bijbehorende gemeenschappelijke ruimte verdeling, en EXCLUSIEF de vermenigvuldiging met het aantal units/appartementen
            //-- ingeval van woningbouw is unitRz een 'UNIT' en in geval van ubouw is het een 'UNIT-RZ'. Naamgeving in deze functie is gebaseerd op de ubouw situatie.
            const childEntId = isUtiliteit() ? 'UNIT-RZ-GF' : 'UNIT-RZ';
            const propAg = isUtiliteit() ? 'UNIT-RZ-GFAG' : 'UNIT-RZAG';

            //-- van deze unitRz moet ik alle unitRzGfs hebben
            const relUnRz_UnRzGf = ntaEntityData.getChildRelations(unitRz, childEntId);
            relUnRz_UnRzGf.forEach(function (rel, index) {
                const unitRzGf = ntaEntityData.get(rel.Child);
                result += vm.parseFloat(unitRzGf.PropertyDatas[propAg].Value, 0);
            });

            if (inclNgetal) {
                const n = getNGetalByUnitRz(unitRz);
                result = n * result;
            }

            if (inclGruimten) {
                let agComm = 0;
                //-- in deze functie bepaal ik de Ag van een UnitRZ INCLUSIEF de bijbehorende gemeenschappelijke ruimte verdeling, maar exclusief de vermenigvuldiging met het aantal units/appartementen
                //-- ingeval van woningbouw is unitRz een 'UNIT' en in geval van ubouw is het een 'UNIT-RZ'. Naamgeving in deze functie is gebaseerd op de ubouw situatie.

                //-- van deze unitRz moet ik alle unitRzGfs hebben
                const unitRzGfs = ntaEntityData.getChildren(unitRz, childEntId);
                for (const unitRzGf of unitRzGfs) {
                    //-- nu de ag van de gemeenschappelijke ruimte bepalen
                    agComm += AgCommByFunction(unitRzGf);
                }

                result = result + agComm;
            }

            return result;
        } //-- end: AgRz ------------------------------------------------------------------------//

        function AgCommByFunction(unitRzGf) {
            let agComm = 0;
            //-- in deze functie bepaal ik het opp van de gemeenschappelijke ruimte dat naar ratio is berekend voor deze unitRzGf
            //-- ingeval van woningbouw is unitRzGf een 'UNIT-RZ' en in geval van ubouw is het een 'UNIT-RZ-GF'. Naamgeving in deze functie is gebaseerd op de ubouw situatie.
            const gemRuimten = ntaEntityData.getListWithEntityId('GRUIMTE');
            const propAg = isUtiliteit() ? 'UNIT-RZ-GFAG' : 'UNIT-RZAG';

            // nu de gemeenschappelijke ruimte berekenen.
            for (const gruimte of gemRuimten) {
                //-- alle unit-rz ophalen die aangesloten zijn op deze gruimte
                const unitrzgfs = ntaEntityData.getParents(gruimte, unitRzGf.EntityId);

                //-- van alle unitrzgf die aangesloten zijn op de ruimte de opp ophalen * met het aantal units voor deze unit-rz-gf
                let agGruimteFuncties = 0;
                for (const u of unitrzgfs) {
                    agGruimteFuncties += AgTotaalVanUnitRzGf(u, true); //-- deze Ag waarde is vermenigvuldig met nUnit/nApp.
                }
                //-- voor de gemeensachppelijk ruimte bepalen wat de factor is waar de aangesloten unitrzgfs mee vermenigvuldigd moeten wroden.
                const gruimteAg = vm.parseFloat(gruimte.PropertyDatas['GRUIMTE_AG'].Value, 0);
                gruimte.factor = 1;
                if (agGruimteFuncties > 0) {
                    gruimte.factor = gruimteAg / agGruimteFuncties;
                }

                //-- nu he oppvl gedeelte van de gemeenscappelijke ruimte per unitrzgf bepalen dit alleen voor de meegegeven unitRzGf
                for (const u of unitrzgfs) {
                    if (unitRzGf.EntityDataId === u.EntityDataId) {
                        const AgRzGF = vm.parseFloat(u.PropertyDatas[propAg].Value, 0);
                        agComm += AgRzGF * gruimte.factor;
                    }
                }
            }

            return agComm;
        } //-- end: AgCommByFunction ------------------------------------------------------------------------//

        function initTapwUnitUbouw(tapwUnit) {
            const tapwCnt = ntaEntityData.getListWithEntityId('TAPW').length;

            const propAg = ntaData.properties['TAPW-UNIT-RZ_OPP'];
            const propdataAg = tapwUnit.PropertyDatas[propAg.Id];

            const unitRz = ntaEntityData.getFirstParent(tapwUnit, 'UNIT-RZ');

            const agMax = AgRz(unitRz, false, false); //-- bij tapwater geen gruimten meenemen. Dit gebeurt in rekenkern. Geen Ngetal meenemen, dit gebeurt in koppeling
            let agInit = 0;
            if (tapwCnt === 1) { //-- dan is er maar één tapwatersysteem
                agInit = agMax;
            } else {
                //-- nu moet ik de ingevoerde AgTapw van de reeds aanwezig TAPW-UNIT-RZ bij elkaar optellen en van deze agMax afhalen om ag te bepalen.
                const tapwUnitRzs = ntaEntityData.getChildren(unitRz, 'TAPW-UNIT-RZ');
                for (const tapwUnitRz of tapwUnitRzs) {
                    const propdataAg = tapwUnitRz.PropertyDatas[propAg.Id];
                    agInit += vm.parseFloat(propdataAg.Value, 0);
                }
                agInit = agMax - agInit;
            }

            if (!propdataAg.Touched || tapwCnt === 1) {
                //--- alleen als het veld nog niet door de gebruiker zelf is ingevoerd mag ik deze overschrijven met een initiele waarde
                ntaEntityData.saveprop(propdataAg, ntaRounding.roundAndAddZerosNewValue(propAg, agInit));
            }
        } //-- end: initTapwUnitUbouw ------------------------------------------------------------------------//

        function initTapwUnitWoon(tapwUnit) {
            const propdataBadr = tapwUnit.PropertyDatas['TAPW-UNIT_BADRUIMTEN'];
            const propdataKeuk = tapwUnit.PropertyDatas['TAPW-UNIT_KEUKENS'];
            let sAantalBadk = propdataBadr.Touched && propdataBadr.Relevant ? propdataBadr.Value : '1'; // we werken in deze hele keten met strings, dus hier ook...
            let sAantalKeuk = propdataKeuk.Touched && propdataKeuk.Relevant ? propdataKeuk.Value : '1'; // we werken in deze hele keten met strings, dus hier ook...
            if (vm.perGebouw()) {
                // Bepalen of het wel of niet om een gemeenschappelijke installatie gaat
                const tapwater = ntaEntityData.getFirstParent('TAPW');
                const opwekker = ntaEntityData.getFirstChild(tapwater, 'TAPW-OPWEK'); //-- kijken naar de eerste opwekker van het systeem, die bepaalt of het gemeenschappelijk is of niet.
                const propdataGemeenschappelijk = opwekker && opwekker.PropertyDatas['TAPW-OPWEK_GEM'];
                if (propdataGemeenschappelijk && propdataGemeenschappelijk.Relevant && propdataGemeenschappelijk.Value === 'TAPW-OPWEK_GEM_WEL') {
                    sAantalBadk = getDefaultValueAantalWoonfuncties();
                    sAantalKeuk = sAantalBadk;
                }
            }
            //--- alleen als het veld nog niet dooor de gebruiker zelf is ingevoerd mag ik deze overschrijven met een initiele waarde.
            //-- als ik relevant bent moet ik een waarde krijgen, anders altijd 0.
            if (propdataBadr.Relevant) {
                //-- alleen als ik nog niet touched ben mag ik overschrijven.
                //-- als keuken en badkamer al wel touched zijn moet aantal 0 gelijk blijven en en anders geinit worden.
                if (!propdataBadr.Touched || (propdataBadr.Touched && parseInt(sAantalBadk) > 0)) {
                    ntaEntityData.saveprop(propdataBadr, sAantalBadk);
                }
            } else {
                ntaEntityData.saveprop(propdataBadr, '0');
            }
            //-- dit zelfde geldt voor keukens
            if (propdataKeuk.Relevant) {
                //-- alleen als ik nog niet touched ben mag ik overschrijven.
                if (!propdataKeuk.Touched || (propdataKeuk.Touched && parseInt(sAantalKeuk) > 0)) {
                    ntaEntityData.saveprop(propdataKeuk, sAantalKeuk);
                }
            } else {
                ntaEntityData.saveprop(propdataKeuk, '0');
            }
        } //-- end: initTapwUnitWoon ------------------------------------------------------------------------//

        function initTapwUnitsWoon() {
            const tapwUnits = ntaEntityData.getListWithEntityId('TAPW-UNIT');
            for (const tapwUnit of tapwUnits) {
                initTapwUnitWoon(tapwUnit);
            }
        } //-- end: initTapwUnitsWoon ------------------------------------------------------------------------//

        function initTapwUnitRzsUbouw() {
            const tapwUnitRzs = ntaEntityData.getListWithEntityId('TAPW-UNIT-RZ');
            for (const tapwUnitRz of tapwUnitRzs) {
                initTapwUnitUbouw(tapwUnitRz);
            }
        } //-- end: initTapwUnitRzsUbouw ------------------------------------------------------------------------//

        function setTouchedFalseAantalIdentiekesystemen(checkGemInstallatieRelevant, checkCalcMethRelevant, installToCheck = null) {
            //-- wanneer er sprake is een niet-gemeenschappelijke installatie bij per app/unit berekening moeten de INSTALL_AANTAL properties op Touched is false, zodat het aantal opnieuw bepaald wordt.
            const systemsToCheck = ['INST_VERW', 'INST_TAPW', 'INST_KOEL', 'INST_VENT'];
            const installaties = ntaEntityData.getListWithEntityId("INSTALLATIE").filter(ed => systemsToCheck.includes(ed.PropertyDatas['INSTALL_TYPE'].Value));
            for (const installatie of installaties) {
                if (!installToCheck || installToCheck === installatie) {
                    const sysType = installatie.PropertyDatas['INSTALL_TYPE'].Value.substr(5);
                    let changeTouchedGem = true;
                    if (checkGemInstallatieRelevant) {
                        const sys = ntaEntityData.getFirstChild(installatie, sysType);
                        changeTouchedGem = !isGemeenschappelijkInstallatie(sys);
                    }
                    let changedTouchedCalc = true;
                    if (checkCalcMethRelevant) {
                        changedTouchedCalc = ["TGEB_APPGEB", "TGEB_UTILIT"].includes(getGebouwtype());
                    }
                    if (changeTouchedGem && changedTouchedCalc) {
                        const propdata = installatie.PropertyDatas['INSTALL_AANTAL'];
                        propdata.Touched = false;
                        ntaEntityData.saveprop(propdata);
                    }
                }
            }
        }

        function setAantalIdentiekeSystemen() {
            //-- alle installaties ophalen en afgaan om het aantal identieke systemn te zetten
            const installaties = ntaEntityData.getListWithEntityId('INSTALLATIE');
            for (const install of installaties) {
                setAantalIdentiekeSystemenPerInstallatie(install);
            }
        } //-- end: setAantalIdentiekeSystemen ------------------------------------------------------------------------//

        function setAantalIdentiekeSystemenPerInstallatie(install) {
            const sys = systeem(install);
            switch (sys && sys.EntityId) {
                case 'VERW':
                case 'TAPW':
                case 'VENT':
                case 'KOEL':
                    {

                        const sysAantal = ntaEntityData.getListWithEntityId(sys.EntityId).length;
                        const gebouwType = ntaEntityData.getFirstWithEntityId('GEB').PropertyDatas['GEB_TYPEGEB'];
                        const propdataInstallCount = install.PropertyDatas['INSTALL_AANTAL'];

                        const aantWoningen = vm.getDefaultValueAantalWoonfuncties(); //-- hier komt een string terug

                        //-- VO 2021-08-04: voor alle systemen geldt nu dat ook voor G04≠appartementengebouw / utiliteitsgebouw vrije invoer van de aantal identieke systemen mogelijk moet zijn.
                        //-- deze waarde mag daarom niet overschreven worden met '1', maar moet gelijkt zijn aan de ingevoerde waarde, welke pas ingevoerd is als de propdata Touched is.
                        let value = propdataInstallCount.Touched ? propdataInstallCount.Value : '1';

                        const aantalHerberekenen = (gebouwType.Value === 'TGEB_APPGEB' || gebouwType.Value === 'TGEB_UTILIT') || voorProjectwoningen();

                        //-- alleen als het veld AANTAL nog niet 'aangeraakt/gevuld/enz' Touched is of readonly is, en moet worden herberekend, dan een default waarde bepalen
                        if ((!propdataInstallCount.Touched || aantalSystemenReadOnly(sys.EntityDataId)) && aantalHerberekenen) {
                            if (vm.perGebouw()) {

                                if (gebouwType.Value === 'TGEB_APPGEB' && sysAantal === 1) {
                                    //-- hier geldt Z22 (vergrendeld)
                                    value = aantWoningen;
                                } //-- in alle andere gevallen blijft de default waarde 1
                            } else {
                                //-- per gebouw en per unit of appartement geldt: Check in tabel Z08 welke unieke appartementen of units zijn aangesloten op het systeem; dit is waar minimaal 1 rekenzone
                                //-- uit kolom Z13 gelijk is aan de op het systeem aangesloten rekenzones. Tel vervolgens alle waarden in kolom Z12 op voor zover aangesloten. Vergrendel waarde.
                                //-- nu alle rekenzones opzoeken die hier aan gekoppeld zijn en daar de aantallen van optellen.
                                const unitIds = [];

                                if (sys.EntityId !== 'TAPW') {
                                    // Rekenzones ophalen
                                    const rekenzoneIds = ntaEntityData.getChildIds(sys, 'RZ');

                                    // Unit rekenzones ophalen
                                    const unitRekenzoneIds = rekenzoneIds.flatMap(rzid => ntaEntityData.getChildIds(rzid, 'UNIT-RZ'));

                                    // Units ophalen
                                    unitIds.push(...unitRekenzoneIds.flatMap(unitRzId => ntaEntityData.getParentIds(unitRzId, 'UNIT')));
                                } else {
                                    const childEntityId = isUtiliteit() ? 'TAPW-UNIT-RZ' : 'TAPW-UNIT';
                                    const parentEntityId = isUtiliteit() ? 'UNIT-RZ' : 'UNIT';
                                    //-- bij tapwater moet ik kijken naar de tapw-unit entities die een relatie hebben met dit systeem en of deze tapw-unit relevant is (ofwel aangevinkt)
                                    const tapwUnits = ntaEntityData.getChildren(sys, childEntityId)
                                        .filter(tapwUnit => tapwUnit.Relevant);
                                    for (const tapwUnit of tapwUnits) {
                                        //-- relatie van deze tapw-unit met de unit opzoeken
                                        const parentId = ntaEntityData.getParentIds(tapwUnit, parentEntityId)[0];
                                        if (isUtiliteit()) {
                                            //-- de Parent van deze relatie is niet UNIT, maar UNIT-RZ. Ik moet dus op zoek naar de parent relatie van deze parent.
                                            const unitId = ntaEntityData.getParentIds(parentId, 'UNIT')[0];
                                            unitIds.push(unitId);
                                        } else {
                                            unitIds.push(parentId);
                                        }
                                    }
                                }

                                const units = ntaEntityData.getList(new Set(unitIds));
                                const nProp = isUtiliteit() ? 'UNIT_AANTU' : 'UNIT_AANTA';
                                const n = units.reduce((sum, unit) => sum + vm.parseFloat(unit.PropertyDatas[nProp].Value, 1), 0);
                                value = n.toString();
                            }
                        }

                        ntaEntityData.saveprop(propdataInstallCount, value);

                        break;
                    }
            }
        } //-- end: setAantalIdentiekeSystemenPerInstallatie ------------------------------------------------------------------------//

        function isGemeenschappelijkInstallatie(sys) {
            let result = false;
            let entdata;
            let propId = '';
            let propTrueValue = '';
            let childEntityId = 'n.v.t.';
            switch (sys.EntityId) {
                case 'VERW':
                    propId = 'VERW-OPWEK_GEM';
                    propTrueValue = 'VERW-OPWEK_GEM_WEL';
                    childEntityId = 'VERW-OPWEK';
                    break;
                case 'TAPW':
                    propId = 'TAPW-OPWEK_GEM';
                    propTrueValue = 'TAPW-OPWEK_GEM_WEL';
                    childEntityId = 'TAPW-OPWEK';
                    break;
                case 'VENT':
                    propId = ['VENT_GEM', 'VENT_LBK'].find(propId => sys.PropertyDatas[propId] && sys.PropertyDatas[propId].Relevant);
                    propTrueValue = propId === 'VENT_LBK' ? 'VENT_LBK_WEL' : 'VENT_GEM_WEL';
                    entdata = sys;
                    break;
                case 'KOEL':
                    propId = 'KOEL-OPWEK_GEM';
                    propTrueValue = 'KOEL-OPWEK_GEM_WEL';
                    childEntityId = 'KOEL-OPWEK';
                    break;
            }
            const children = ntaEntityData.getChildren(sys, childEntityId);

            //-- kan zijn dat het systeem opwekker childs heeft, dan moet ik die entdata hebben om de properties op te halen.
            if (children.length > 0) {
                //-- ik ga uit van de preferente opwekker op gemeenschappelijke installatie waarde op te halen
                entdata = children[0];
            }

            //--  Als het systeem wel een child opwekker zou moeten hebben, maar deze is nog niet aangemaakt uitgaan van niet-gemeenschappelijk result blijft false
            if (entdata) {
                const propdata = entdata.PropertyDatas[propId];
                //-- als gemeenschappelijk installatie nog niet is ingevuld en null is, dan uitgaan van niet-gemeenschappelijk.
                result = !!propdata && propdata.Relevant && propdata.Value === propTrueValue;
            }

            return result;
        } //-- end: _isGemeenschappelijkInstallatie ------------------------------------------------------------------------//

        function systeem(installatie) {
            //-- op basis van de Id van de installatie moet ik in de relatietabel opzoek naar welk child hier bij hoort. Van dit child moet ik de Id weten en in de boomzetten
            return installatie && ntaEntityData.getFirstChild(installatie, installatie.PropertyDatas['INSTALL_TYPE'].Value.substring(5));
        } //-- end: systeem ------------------------------------------------------------------------//

        function isEditingMeasure(measureType) {
            return !!getEditingMeasureId(measureType);
        } //-- end: isEditingMeasure ------------------------------------------------------------------

        function getEditingMeasureId(measureType = null) {
            const shadowId = ntaEntityData.getShadowId();
            const shadowEntdata = ntaData.original.get(shadowId);
            const typeProp = ntaData.properties["MEASURE_TYPE"];
            return shadowEntdata
                && shadowEntdata.EntityId === 'MEASURE'
                && (!measureType || typeProp.getValue(shadowEntdata) === measureType)
                && shadowId
                || null;
        } //-- end: getEditingMeasureId ---------------------------------------------------------------

        function showOnlyValidProducts() {
            /// bij afgemelde berekeningen moet altijd alle producten getoond worden. Ook de vervallen.
            /// Zo'n berekening kan vervallen verklaringen bevatten en die wil je dan nog wel zien op het
            /// formulier.
            if (ntaData.canSaveBuilding()) {
                const settings = ntaData.original.getFirstWithEntityId('SETTINGS');
                const propdataActueleVerklaring = settings.PropertyDatas['SETTINGS_ONLY_ACTU_VERKL'];
                return !!propdataActueleVerklaring && propdataActueleVerklaring.Value === 'True';
            }

            return false;
        } //-- end: showOnlyValidProducts --------------------------------------------------------------------------------//

        function isValidatingVariantWithMeasureType(...measureTypes) {
            const shadowId = ntaEntityData.getShadowId();
            const shadowEntdata = ntaData.original.get(shadowId);
            let result = shadowEntdata && shadowEntdata.EntityId === 'VARIANT';
            if (result && measureTypes.length) {
                const measures = ntaEntityData.findEntities(shadowEntdata, 'VARIANT-MEASURE.^MEASURE', '^MEASURE');
                const measureTypeSet = new Set(measureTypes);
                const typeProp = ntaData.properties["MEASURE_TYPE"];
                result = measures.some(ed => measureTypeSet.has(typeProp.getValue(ed)));
            }
            return result;
        } //-- end: isValidatingVariantWithMeasureType ------------------------------------------------

        function navToInstallations() {
            let path = `/Project/${ntabuilding.projectId}/Berekening/${ntabuilding.buildingId}/`;
            const measureId = getEditingMeasureId();
            if (measureId) {
                path += `Maatregelen/${measureId}`;
            } else {
                path += `Installaties`;
            }
            $location.path(path);
        } //-- end: navToInstallations ------------------------------------------------------------------------//

        function navToInstallation(inst) {
            const projectId = ntaData.projectdata.ProjectId;
            const typeName = inst.PropertyDatas['INSTALL_TYPE'].Value.toLowerCase();
            const measureId = getEditingMeasureId();

            let path = `/Project/${projectId}/Berekening/${inst.BuildingId}/`;
            if (measureId) {
                path += `Maatregelen/${measureId}/`;
            } else {
                path += `Installatie/`;
            }
            path += `${typeName}/${inst.EntityDataId}`;

            $location.path(path);
        } //-- end: navToInstallation ------------------------------------------------------------------------//

        function navToIndelingGebouw(entdata, prjId, bldId) {
            const path = `/Project/${prjId}/Berekening/${bldId}/Indeling_gebouw`;
            $location.path(path);
        } //-- end: navToIndelingGebouw ------------------------------------------------------------------------//

        function getGebEntdata() {
            return ntaEntityData.getListWithEntityId('GEB')[0];
        } //-- end: getGebEntdata -------------------------------------------------------------------//

        function getGebouwtype(geb = null) {
            if (!geb) geb = getGebEntdata();
            return geb.PropertyDatas['GEB_TYPEGEB'].Value;
        } //-- end: getGebouwType ------------------------------------------------------------------------//

        function isVakantiewoning(geb = null) {
            if (!geb) geb = getGebEntdata();
            const gebouwType = geb.PropertyDatas['GEB_TYPEGEB'].Value;
            return gebouwType === 'TGEB_VAKWON';
        } //-- end: isVakantiewoning --------------------------------------------------------------

        function isUtiliteit(geb = null) {
            if (!geb) geb = getGebEntdata();
            const gebouwType = geb.PropertyDatas['GEB_TYPEGEB'].Value;
            return gebouwType === 'TGEB_UTILIT' || gebouwType === 'TGEB_UTILUNIT';
        } //-- end: isUtiliteit ------------------------------------------------------------------------//

        function isNieuwbouw(geb = null) {
            if (!geb) geb = getGebEntdata();
            const bouwsoort = geb.PropertyDatas['GEB_SRTBW'].Value;
            return bouwsoort === 'NIEUWB';
        } //-- end: isNieuwbouw ------------------------------------------------------------------------//

        function isBestaandeBouw(geb = null) {
            if (!geb) geb = getGebEntdata();
            const bouwsoort = geb.PropertyDatas['GEB_SRTBW'].Value;
            return bouwsoort === 'BESTB' || bouwsoort === 'BESTBG';
        } //-- end: isBestaandeBouw ------------------------------------------------------------------------//

        function isNietGrondGebonden(geb = null) {
            if (!geb) geb = getGebEntdata();
            const gebouwType = geb.PropertyDatas['GEB_TYPEGEB'].Value;
            return gebouwType === 'TGEB_APPGEB' || gebouwType === 'TGEB_APP';
        } //-- end: isNietGrondGebonden ------------------------------------------------------------------------//

        function isBasisOpname(geb = null) {
            if (!geb) geb = getGebEntdata();
            const opname = geb.PropertyDatas['GEB_OPN'].Value;
            return opname === 'OPN_BASIS';
        } //-- end: isBasisOpname ------------------------------------------------------------------------//

        function isDetailOpname(geb = null) {
            if (!geb) geb = getGebEntdata();
            const opname = geb.PropertyDatas['GEB_OPN'].Value;
            return opname === 'OPN_DETAIL';
        } //-- end: isDetailOpname ------------------------------------------------------------------------//

        function isAppartementOfUtiliteitVoorBestaandeBouw(geb = null) {
            if (!geb) geb = getGebEntdata();
            const gebouwType = geb.PropertyDatas['GEB_TYPEGEB'].Value;
            return gebouwType === 'TGEB_UTILUNIT' || gebouwType === 'TGEB_APP';
        } //-- end: isAppartementOfUtiliteitVoorBestaandeBouw ------------------------------------------------------------------------//

        function isUnitInUtiliteitsgebouw(geb = null) {
            if (!geb) geb = getGebEntdata();
            const gebouwType = geb.PropertyDatas['GEB_TYPEGEB'].Value;
            return gebouwType === 'TGEB_UTILUNIT';
        } //-- end: isUnitInUtiliteitsgebouw ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

        function getBouwjaar(geb = null) {
            if (!geb) geb = getGebEntdata();

            const propId = isBestaandeBouw(geb) ? 'GEB_BWJR' : 'GEB_OPLVJR';
            const bouwjaar = parseInt(geb.PropertyDatas[propId].Value);
            return isNaN(bouwjaar) ? 100 : bouwjaar;
        } //-- end: getBouwjaar ------------------------------------------------------------------------//

        function getInstallationsRekenzone(rekenzoneId) {
            const systeemIds = ntaEntityData.getParentRelations(rekenzoneId)
                .filter(rel => ['VERW', 'KOEL', 'VENT'].includes(rel.ParentEntityId))
                .map(rel => rel.Parent);
            return ntaEntityData.getList(systeemIds);
        }//-- end: getInstallationsRekenzone ------------------------------------------------------------------------//

        function perGebouwEnAppartement() {
            return perGebouwEnAppartementOfUnit('APP');
        }//-- end: perGebouwEnAppartement ------------------------------------------------------------------------//

        function perGebouwEnUnit() {
            return perGebouwEnAppartementOfUnit('UNIT');
        }//-- end: perGebouwEnAppartement ------------------------------------------------------------------------//

        function perGebouwEnAppartementOfUnit(bouw = 'BEIDE') {
            const gebouwtype = ntaEntityData.getFirstWithEntityId('GEB').PropertyDatas['GEB_TYPEGEB'].Value;
            const energieBerekenen = getCalcUnit();

            if (bouw === 'APP') { // Conditie [TAC]
                return gebouwtype === 'TGEB_APPGEB' && energieBerekenen === 'RZUNIT_GEBAPP';
            }
            else if (bouw === 'UNIT') {
                return gebouwtype === 'TGEB_UTILIT' && energieBerekenen === 'RZUNIT_GEBUNIT';
            }
            else { // Conditie [TWL] en [SW], omgekeerd conditie [TWM] en [SX]
                return (gebouwtype === 'TGEB_APPGEB' || gebouwtype === 'TGEB_UTILIT') && (energieBerekenen === 'RZUNIT_GEBAPP' || energieBerekenen === 'RZUNIT_GEBUNIT');
            }
        } //-- end: perGebouwEnAppartementOfUnit ------------------------------------------------------------------------//

        function getCalcUnit(rzForm = null) {
            if (!rzForm) rzForm = ntaEntityData.getFirstWithEntityId('RZFORM');

            const propdata = rzForm.PropertyDatas['RZFORM_CALCUNIT'];
            let calcUnit = propdata.Value;

            if (!calcUnit || !propdata.Visible) { // defaultwaarde zetten
                const gebouwtype = ntaEntityData.getFirstWithEntityId('GEB').PropertyDatas['GEB_TYPEGEB'].Value;
                switch (gebouwtype) {
                    case 'TGEB_APPGEB':
                    case 'TGEB_APP':
                        calcUnit = 'RZUNIT_GEBAPP';
                        break;
                    case 'TGEB_UTILIT':
                        //-- BM 2021-08-10: Per gebouw per unit voor ubouw was uitgezet. Bij bestaande projecten wil je RZUNIT_GEB als default houden
                        calcUnit = 'RZUNIT_GEB';
                        break;
                    case 'TGEB_UTILUNIT':
                        calcUnit = 'RZUNIT_GEBUNIT';
                        break;
                    default:
                        calcUnit = 'RZUNIT_GEB';
                        break;
                }
                ntaEntityData.saveprop(propdata, calcUnit);
            }

            return calcUnit;
        } //-- end: getCalcUnit ------------------------------------------------------------------------//

        function getGRuimtesForAverlies() {
            let gruimtes = ntaEntityData.getListWithEntityId('GRUIMTE');
            gruimtes = gruimtes.filter(gr => {
                const propdata = gr.PropertyDatas['GRUIMTE_AV_INVOER'];
                return !propdata || propdata.Value === 'GRUIMTE_AV_INVOER_GR';
            });

            return gruimtes;
        }
        function perGebouw() {
            const energieBerekenen = getCalcUnit();
            return energieBerekenen === 'RZUNIT_GEB';
        } //-- end: perGebouw ------------------------------------------------------------------------//

        function voorProjectwoningen() {
            const energieBerekenen = getCalcUnit();
            return energieBerekenen === 'RZUNIT_PROJECT';
        } //-- end: voorProjectwoningen -------------------------------------------------------------//

        function perAppartementOfUnit() {
            const gebouwtype = ntaEntityData.getFirstWithEntityId('GEB').PropertyDatas['GEB_TYPEGEB'].Value;
            const energieBerekenen = getCalcUnit();
            switch (gebouwtype) {
                case 'TGEB_APP':
                case 'TGEB_UTILUNIT':
                    return true;

                case 'TGEB_APPGEB':
                    return energieBerekenen === 'RZUNIT_GEBAPP';

                case 'TGEB_UTILIT':
                    return energieBerekenen === 'RZUNIT_GEBUNIT';

                default:
                    return false;
            }
        } //-- end: perAppartementOfUnit ------------------------------------------------------------//

        function getDefaultValueAantalWoonfuncties() {
            const energieBerekenen = getCalcUnit();

            const gebattribs = getGebEntdata();
            const value = gebattribs.PropertyDatas['GEB_TYPEGEB'].Value;

            if (value !== "TGEB_UTILIT" && value !== "TGEB_APPGEB") { // Als Z23 isHidden
                return '1';
            }

            const units = ntaEntityData.getListWithEntityId('UNIT');

            if (energieBerekenen === 'RZUNIT_GEB') { // Als Z23 is per gebouw
                const rzform = ntaEntityData.getFirstWithEntityId('RZFORM');
                const aantalWoningen = rzform.PropertyDatas['RZFORM_AANTWOONF'].Value;
                return aantalWoningen;
            }
            else if (energieBerekenen === 'RZUNIT_GEBAPP') { // Als Z23 is per gebouw en per appartement
                let som = 0;

                units.forEach(function (unit, index) {
                    const aantalAppartementen = unit.PropertyDatas['UNIT_AANTA'].Value;
                    som += parseInt(aantalAppartementen) || 0;
                });

                return som.toString();
            }
            else if (energieBerekenen === 'RZUNIT_GEBUNIT') { // Als Z23 is per gebouw en per unit
                let som = 0;

                units.forEach(function (unit, index) {
                    const aantalUnits = unit.PropertyDatas['UNIT_AANTU'].Value;
                    som += parseInt(aantalUnits) || 0;
                });

                return som.toString();
            }
            else {
                return '';
            }
        }//-- end: getDefaultValueAantalWoonfuncties ------------------------------------------------------------------------//

        function AgTotaal() {
            //-- dit is het totaal van het gehele gebouw (of ik nu per unit/app of per gebouw reken)
            return AgTotaalZones() + AgTotaalGruimten();
        } //-- end: AgTotaal ------------------------------------------------------------------------//

        function AgTotaalZones() {
            let units = [];
            let AgTotZones = 0;
            if (isUtiliteit()) {
                units = ntaEntityData.getListWithEntityId('UNIT-RZ-GF');
            } else {
                units = ntaEntityData.getListWithEntityId('UNIT-RZ');
            }
            units.forEach(function (unit, index) {
                //-- oppervlakten van zones
                const Ag = AgTotaalVanUnitRzGf(unit, true);
                AgTotZones += Ag;
            });
            return AgTotZones;
        } //-- end: AgTotaalZones() ------------------------------------------------------------------------//

        function AgTotaalGruimten() {
            let AgTotGruimten = 0;
            const gruimtes = ntaEntityData.getListWithEntityId('GRUIMTE');
            gruimtes.forEach(function (gruimte, index) {
                //-- oppervlakten van gemeenschappelijke ruimten
                const propdataAg = gruimte.PropertyDatas['GRUIMTE_AG'];
                const Ag = vm.parseFloat(propdataAg && propdataAg.Value, 0);
                AgTotGruimten += Ag;
            });

            return AgTotGruimten;
        } //-- end: AgTotaalGruimten ------------------------------------------------------------------------//

        function AgTotaalVanUnitRzGf(entdata, inclNgetal) {
            //-- er wordt in geval van ubouw een unit-rz-gf meegegeven met een Ag, en in geval van woningbouw is dit een unit-rz. Ik controleer op entityId
            //-- deze Ag moet vermenigvuldigd worden met het aantal units of appartementen om het totale Ag van deze unit-rz-(gf) te krijgen. Deze totale Ag
            //-- is nodig voor de bepaling van de hoeveelheid Ag van de gemeenschappelijke ruimte die moet worden meegenoemn.
            if (!entdata) {
                return 0;
            }

            const n = inclNgetal ? getNGetalByUnitRz(entdata) : 1;

            let agUnitRzGf = 0;
            if (entdata.EntityId === 'UNIT-RZ') {
                agUnitRzGf = vm.parseFloat(entdata.PropertyDatas['UNIT-RZAG'].Value, 0);
            } else if (entdata.EntityId === 'UNIT-RZ-GF') {
                agUnitRzGf = vm.parseFloat(entdata.PropertyDatas['UNIT-RZ-GFAG'].Value, 0);
            }

            const agTot = n * agUnitRzGf;

            return agTot;
        }//-- end: AgTotaalVanUnitRzGf ------------------------------------------------------------------------//

        function getNGetalByUnitRz(entdata) {
            //-- er wordt in geval van ubouw een unit-rz-gf meegegeven met een Ag, en in geval van woningbouw is dit een unit-rz. Ik controleer op entityId
            //-- deze Ag moet vermenigvuldigd worden met het aantal units of appartementen om het totale Ag van deze unit-rz-(gf) te krijgen. Deze totale Ag
            //-- is nodig voor de bepaling van de hoeveelheid Ag van de gemeenschappelijke ruimte die moet worden meegenoemn.
            if (!entdata) {
                return 0;
            }

            let propdata;
            if (entdata.EntityId === 'UNIT-RZ') {
                const unit = ntaEntityData.getFirstParent(entdata, 'UNIT');
                propdata = unit.PropertyDatas['UNIT_AANTA'];

            } else if (entdata.EntityId === 'UNIT-RZ-GF') {
                const unitRz = ntaEntityData.getFirstParent(entdata, 'UNIT-RZ');
                const unit = ntaEntityData.getFirstParent(unitRz, 'UNIT');
                propdata = unit.PropertyDatas['UNIT_AANTU'];

            } else {
                return 0;
            }

            //-- het kan zijn dat de waarde van deze value NULL is, dan is hij niet relevant omdat het om grondgebonden woningen of een
            //-- bepaalde invoermethode gaat.
            return propdata.Relevant ? vm.parseFloat(propdata.Value, 0) : 1;
        } //-- end:getNGetalByUnitRz  ------------------------------------------------------------------------//

        function AgSumRZbySystem(systeemData, inclGruimten, unitED = null) {
            //-- ik krijg een systeem data mee. Ofwel een entity met code VERW, TAPW, KOEL enz. Voor deze entitydata moet ik bepalen of dit
            //-- formula 6.1c voor woningbouw en 6.1g voor ubouw

            // Rekenzones ophalen
            const rekenzoneIds = ntaEntityData.getChildIds(systeemData, 'RZ');

            // Unit-rekenzones ophalen
            const unitRekenzoneIds = rekenzoneIds.flatMap(rzid => ntaEntityData.getChildIds(rzid, 'UNIT-RZ'))
                .filter(unitRzId => !unitED || ntaEntityData.isRelation(unitED, unitRzId));

            return AgSumOfUnitRZs(unitRekenzoneIds, inclGruimten, true);
        }//-- end: AgSumRZbySystem ------------------------------------------------------------------------//

        function AgCommOfEntdata(entdata) { //-- formula 6.1i
            let AgComm = 0;

            const gemRuimten = ntaEntityData.getListWithEntityId('GRUIMTE');
            for (const gruimte of gemRuimten) {
                let parentList = [];
                if (entdata.EntityId === 'UNIT-RZ-GF') {
                    //-- alle unit-rz-gf ophalen die aangesloten zijn op deze gruimte.
                    parentList = ntaEntityData.getParents(gruimte, 'UNIT-RZ-GF');
                } else if (entdata.EntityId === 'UNIT-RZ') {
                    //-- alle unit-rz ophalen die aangesloten zijn op deze gruimte
                    parentList = ntaEntityData.getParents(gruimte, 'RZ')
                        .flatMap(rz => ntaEntityData.getChildren(rz, 'UNIT-RZ'));
                } else {
                    $log.warn(`Onverwachte entityId ${entdata.EntityId} in ${new Error().stack}.`);
                }
                //-- van alle unitrz(gf) die aangesloten zijn op de ruimte de opp ophalen vermenigvuldigd met het aantal units voor deze unit-rz[-gf]
                let sumAgParents = 0;
                let agTot = 0;
                for (const parent of parentList) {
                    const agTotParent = AgTotaalVanUnitRzGf(parent, true);
                    sumAgParents += agTotParent;
                    if (parent === entdata) {
                        agTot = agTotParent;
                    }
                }
                //-- voor de gemeensachppelijk ruimte bepalen wat de factor is waar de aangesloten unitrz(gf)s mee vermenigvuldigd moeten wroden.
                const gruimteAg = vm.parseFloat(gruimte.PropertyDatas['GRUIMTE_AG'].Value, 0);
                const gruimteFactor = sumAgParents > 0 ? gruimteAg / sumAgParents : 1;

                //-- nu het oppvl gedeelte van de gemeenschappelijke ruimte per unitrz(gf) bepalen, zodat deze later bij elkaar opgeteld kunnen worden om de Agcomm te bepalen
                AgComm += agTot * gruimteFactor;
            }
            return AgComm;
        } //-- end: AgCommOfUnitRzGf ------------------------------------------------------------------------//


        function AgSumOfUnitRZs(unitRekenzoneIds, inclGruimten, inclNgetal) { //formula 6.1c voor woningbouw en 6.1g voor ubouw
            console.assert(inclNgetal || !inclGruimten, 'Als inclNgetal === false, dan moet inclGruimten === false zijn, anders worden oppervlakten inclusief en exclusief aantal bij elkaar opgeteld!\n' + new Error().stack);

            const unitRekenzones = isUtiliteit()
                ? unitRekenzoneIds.flatMap(unitRzId => ntaEntityData.getChildren(unitRzId, 'UNIT-RZ-GF'))
                : ntaEntityData.getList(unitRekenzoneIds).filter(ed => ed.EntityId === 'UNIT-RZ');

            let sumAgRZ = 0;
            let sumAgComm = 0;

            for (const unitRekenzone of unitRekenzones) {
                sumAgRZ += AgTotaalVanUnitRzGf(unitRekenzone, inclNgetal);
                if (inclGruimten) {
                    //-- van alle gebruiksfuncties in de unitRekenzone het common (gemeenschappelijk) deel ophalen
                    sumAgComm += AgCommOfEntdata(unitRekenzone);
                }
            }

            return sumAgRZ + sumAgComm;
        }//-- end: AgSumOfUnitRZ ------------------------------------------------------------------------//

        function isGroteInstallatie(systeemData) {
            //-- ik krijg een systeem data mee. Ofwel een entity met code VERW, TAPW, KOEL enz. Voor deze entitydata moet ik bepalen of dit
            //-- systeem voor een grote of een kleine installatie moet gelden adh de gebouwgegevens.
            let bGroteInstall = false;
            if (!systeemData) {
                return bGroteInstall;
            }

            switch (systeemData.EntityId) {
                case "VERW": {
                    //-- Bij projectwoningen moet _per woning_ gekeken worden of het een ‘grote installatie’ is.
                    //-- In alle andere gevallen moet dat voor de hele berekening gedaan worden; dit kan bereikt worden door null in plaats van een unit door te geven.
                    const unitsToTest = [];
                    let aantalIdentiek = 1;
                    if (voorProjectwoningen()) {
                        //-- test de projectwoningen afzonderlijk
                        unitsToTest.push(...ntaEntityData.getListWithEntityId('UNIT'));
                    } else {
                        //-- test de hele berekening, maar...
                        unitsToTest.push(null);

                        //-- ...verdeel over identieke installaties
                        const installatie = ntaEntityData.getFirstParent(systeemData, 'INSTALLATIE');
                        const propdataAantal = installatie && installatie.PropertyDatas['INSTALL_AANTAL'];
                        aantalIdentiek = propdataAantal && parseInt(propdataAantal.Value) || 0;
                    }
                    bGroteInstall = (aantalIdentiek > 0) && unitsToTest.some(unit => {
                        const Agsum = AgSumRZbySystem(systeemData, true, unit); //-- voor bepaling installatiegrootte wel inclusief gemeenschappelijke ruimten
                        return Agsum / aantalIdentiek > 500;
                    });
                    break;
                }
                case "TAPW": {
                    if (isUtiliteit()) {
                        //-- hiervoor geldt Ag;W;sys > 500, dan is er sprake van een grote installatie
                        let AgWsys = 0;
                        let tapwSyss = ntaEntityData.getListWithEntityId('TAPW');
                        if (tapwSyss.length > 1) {
                            AgWsys = 0; //-- oppervlakte op unit-rz die op de tegel van dit systeem is ingevoerd * aantal units van die rekenzone

                        } else {
                            AgWsys = AgTotaal();  //--totale oppervlak.
                        }
                        bGroteInstall = AgWsys > 500;
                    } else {
                        //-- sprake van woningbouw
                    }
                    break;
                }
            }

            return bGroteInstall;
        } //-- end: isGroteInstallatie ------------------------------------------------------------------------//

        function aantalSystemenReadOnly(systeemId) {
            //-- VO 2021-08-04: Aantal identieke systemen is altijd vrije invoer (readonly=false), behalve bij per app/unit of projectwoningen EN niet-gemeenschappelijke installatie.
            const meerdereUnitsRekenen = perGebouwEnAppartementOfUnit() || voorProjectwoningen();
            const nietGemeenschappelijk = !isGemeenschappelijkInstallatie(ntaEntityData.get(systeemId));

            return meerdereUnitsRekenen && nietGemeenschappelijk;

        } //-- end:aantalSystemenReadOnly  ------------------------------------------------------------------------//


        function hasRelation(parent, child) {
            //-- Functie om te controleren of twee entiteiten een relatie hebben
            return ntaEntityData.getRelations(parent.EntityDataId).some(function (x) { return x.Parent === parent.EntityDataId && x.Child === child.EntityDataId; });
        } //-- end: hasRelation ------------------------------------------------------------------------//

        function checkPropdataInList(prop, entdata, list, logic) {
            const propdata = prop.getData(entdata);
            let listItem = null;
            let valueChanged = false;
            if (propdata && list && (!logic.isReadOnly || !logic.isReadOnly(prop, entdata))) {
                if (list.length === 0) {
                    //-- bij 0 items in de lijst moet de propdata.value op op default gezet worden gezet worden
                    if (propdata.Value !== prop.DefaultValue) {
                        propdata.Value = prop.DefaultValue;
                        valueChanged = true;
                    }
                } else if (list.length === 1) {
                    //-- bij 1 item in de lijst moet de propdata.value gelijk worden aan de waarde uit de lijst
                    let checkValue = propdata.Value !== null ? propdata.Value.toString() : propdata.Value;
                    if (checkValue !== list[0].Id.toString()) {
                        propdata.Value = list[0].Id;
                        valueChanged = true;
                    }
                }

                if (propdata.Value && list.length > 1) {
                    //-- als propdata.Value ongelijk is aan null moet ik kijken of het item in de lijst zit
                    listItem = logic.getListItem(prop, entdata, list);
                    if (!listItem && propdata.Value !== prop.DefaultValue) {
                        propdata.Value = prop.DefaultValue;
                        valueChanged = true;
                    } else {
                        //-- als ik prop domaintype 4 of 5 ben moet autocompletslecteditems atlijd zetten
                        if (prop.Domain && (prop.Domain.DomainType === 4 || prop.Domain.DomainType === 5)) {
                            logic.setSelectedItemSelectionTable(prop, entdata, listItem);
                        }
                    }
                }
            }
            if (valueChanged) {
                //-- en nu de selectedItems zetten voor de property als het domaintype 4 of 5 is. Voor de md-autocomplete moet dit
                if (prop.Domain.DomainType === 4 || prop.Domain.DomainType === 5) {
                    logic.setSelectedItemSelectionTable(prop, entdata, listItem);
                }
                logic.saveValue(prop, entdata);
            }
        } //-- end: checkPropdataInList ------------------------------------------------------------------------//

        function saveValue(prop, entdata, newValue, logic, writableBuildingData = ntaEntityData) {
            if (typeof prop === 'string') {
                prop = ntaData.properties[prop];
            }
            if (!prop || !entdata) {
                return false;
            }

            const propdata = prop.getData(entdata);
            const oldValue = propdata.Value;
            let exit = false;
            //-- VO als er een newValue waarde meegegeven wordt, moet eerst gecontroleerd worden of deze waarde niet gelijk is. Als de functie geen newValue mee krijgt is hij undefined. Undefined is een waarde die we nooit in Value plaatsen
            if (newValue !== undefined) {
                //-- als deze property CalcInput én CalcResult is, dan is de server verantwoordelijk voor de waarde als-ie niet relevant is.
                if (prop.CalcInput && prop.CalcResult && !propdata.Relevant) {
                    return true;
                }

                //-- controleren of de nieuwe waarde wel anders is als de bestaande waarde, anders hoeft er niet opgeslagen te worden.
                //-- wel converteren naar string, want bij productnr is het een getal en dat vindt de vergelijking === niet het zelfde
                if (newValue !== null && newValue !== 'n.v.t.') {
                    if (prop.PropertyType === 1 /* number */) {
                        newValue = ntaRounding.roundAndAddZerosNewValue(prop, newValue);
                    } else {
                        newValue = newValue.toString();
                    }
                }

                if (oldValue === newValue) {
                    exit = true;
                } else {
                    //-- als hij verschilt, mag de nieuwe waarde eindelijk in propdata gezet worden en mag de opslag verder gaan.
                    propdata.Value = newValue;
                }
            }

            //-- als dit een resultaatveld is, dan gebruiken we de nieuwe waarde voor alle units
            const unitSpecificPropdatas = getUnitSpecificResultPropdatas(prop, entdata);
            if (prop.CalcResult) {
                //-- als er niet expliciet een waarde meegegeven is, dan moeten we de unit-specifieke waarde gebruiken (indien relevant)
                if (newValue === undefined) {
                    useUnitSpecificValue(prop, entdata);
                }

                for (const pd of unitSpecificPropdatas) {
                    if (pd.Value !== propdata.Value) {
                        pd.Value = propdata.Value;
                        pd.Touched = true;
                        exit = false; ///-- wijzigingen in unitspecificpropertie en die moeten wel opgeslagen worden.
                    }
                }

                writableBuildingData.validatePropdatas(unitSpecificPropdatas);
            }

            if (exit) {
                return true;
            }

            propdata.Touched = true; // opslaan veld is aangeraakt

            if (propdata.Value === undefined) { // niks doen -> undefined komt van een waarschuwing
                return true;
            }

            logic.validate(prop, propdata, entdata);

            if (writableBuildingData.saveprops([propdata].concat(unitSpecificPropdatas))) {
                logic.validateDependencies(prop, entdata);
                logic.dependencyValidator.checkChangedField(prop, entdata, logic);
                return true;
            }

            return false;
        } //-- end: saveValue ------------------------------------------------------------------------//

        function saveValueSelectionTable(prop, item, entdata, logic) {
            if (!prop || !entdata) {
                return;
            }
            const propdata = entdata.PropertyDatas[prop.Id];
            if (propdata) {
                propdata.Touched = true;
            }

            let value;
            if (item && item.Id) {
                value = String(item.Id);
            } else {
                value = null;
            }

            logic.saveValue(prop, entdata, value);
        } //-- end: saveValueSelectionTable ------------------------------------------------------------------------//

        function saveProductValueToPropData(logic, product, propName, entdata, propId, valueConverter = undefined) {
            const prop = typeof propId === 'string' ? ntaData.properties[propId] : propId;
            let value = prop.DefaultValue;
            if (product && product.hasOwnProperty(propName) && product[propName] !== null) {
                value = product[propName];
                if (typeof valueConverter === 'function') {
                    value = valueConverter(value, prop);
                }
                if (prop.PropertyType === 1 /* number */) {
                    value = ntaRounding.roundAndAddZerosNewValue(prop, value);
                }
            } else if (product && !product.hasOwnProperty(propName)) {
                $log.error(`Opgevraagd product heeft geen property '${propName}'!`, product);
            }

            return logic.saveValue(prop, entdata, value, logic); // saveValue roept al validate aan
        } //-- end: saveProductValueToPropData  ------------------------------------------------------------------------//

        function checkResultEis(propertyId, prestatieEntdata = null) {
            if (!vm.isNieuwbouw() || vm.isUnitInUtiliteitsgebouw())
                return null;

            const resultData = prestatieEntdata || ntabuilding.results.find(x => x.BuildingId === ntabuilding.buildingId);
            if (!resultData || !resultData.PropertyDatas[propertyId] || !resultData.PropertyDatas[propertyId + '_EIS'])
                return null;

            let value = vm.parseFloat(resultData.PropertyDatas[propertyId].Value);
            let eis = vm.parseFloat(resultData.PropertyDatas[propertyId + '_EIS'].Value);

            if (isNaN(eis)) {
                return null;
            }

            if (propertyId === "EP_BENG3") {
                [value, eis] = [eis, value]; /// [F]
            }

            return value <= eis;
        }

        function heeftEnergielabel() {
            let gebouwtype = getGebouwtype();
            const show = gebouwtype !== 'TGEB_APPGEB' && !perGebouwEnUnit();

            const resultData = ntabuilding.results.find(x => x.BuildingId === ntabuilding.buildingId);
            if (resultData) {
                const propdataEnergielabel = resultData.PropertyDatas['EP_ENERGIELABEL'];
                ntaEntityDataOrg.setPropdataStatus(propdataEnergielabel, show, show);
            }

            return show;
        }

        function heeftTOjuli(entitydDataId) {
            const gebouwtype = getGebouwtype();

            //BENG Indicator condition [B]
            let show;
            if (ntabuilding.ntaVersionId < 300) {
                show = gebouwtype === 'TGEB_GRWON' || gebouwtype === 'TGEB_APP';
            } else { //ntabuilding.ntaVersionId >= 300
                const gebOrUnit = entitydDataId ? ntaEntityDataOrg.get(entitydDataId) : getGebEntdata();
                if (!gebOrUnit) return; //kan voorkomen bij switchen berekening -> activeberekening is dan nog even onbekend.
                const tojulis = gebOrUnit.EntityId === 'GEB' ? ntaEntityDataOrg.getListWithEntityId('RZ').flatMap(rz => ntaEntityDataOrg.getChildren(rz, 'RESULT-TOJULI')) : // tojuli's van gebouw
                    ntaEntityDataOrg.findEntities(gebOrUnit, 'UNIT-RZ').flatMap(unitrz => ntaEntityDataOrg.getChildren(unitrz, 'RESULT-TOJULI')); //tojuli's van unit

                show = !isNieuwbouw() && (gebouwtype === 'TGEB_GRWON' || gebouwtype === 'TGEB_APP') && tojulis.some(ed => ed.PropertyDatas["RESULT_TOJULI_TYPE_KOEL"]?.Value !== "RESULT-TOJULI_TYPE_KOEL_ACTIVE");
            }

            const resultData = ntabuilding.results.find(x => x.BuildingId === ntabuilding.buildingId);
            if (entitydDataId && resultData) { //status alleen zetten bij bekende entityDataId -> de functie wordt namelijk ook bevraagd vanuit het ResultatenMenu
                const propdataTOJuli = resultData.PropertyDatas['EP_TOJULI'];
                ntaEntityDataOrg.setPropdataStatus(propdataTOJuli, show, show);
            }

            return show;
        }

        function heeftUnitTOjuli() {
            const gebouwtype = getGebouwtype();
            return ntabuilding.ntaVersionId >= 300 || gebouwtype === 'TGEB_APPGEB' && getCalcUnit() !== 'RZUNIT_GEB';
        }

        /// Deze functie doet functioneel hetzelfde als ntaDeltas.getShadowBuildingData, behalve dan
        ///  dat-ie voor een VARIANT ook de delta’s voor alle bijbehorende MEASUREs ophaalt dan wel
        ///  genereert, en deze ook verwerkt; zodoende levert dit in alle gevallen een complete
        ///  buildingData. Dat is niet het geval voor ntaDeltas.getShadowBuildingData -- omdat
        ///  we dan in een Circular Reference-hel terechtkomen. +<8-(
        /// TODO: idealiter moeten we ervoor zorgen dat er maar één functie getShadowBuildingData is,
        ///  die in alle gevallen een volledige buildingData oplevert. Daarvoor moeten alle onderlinge
        ///  dependencies in kaart gebracht worden.
        function getVariantBuildingData(shadowId) {
            if (!shadowId) {
                return ntaData.original;
            } else if (shadowId === ntaData.current.shadowId) {
                return ntaData.current;
            } else {
                const shadowEntdata = ntaEntityDataOrg.get(shadowId);
                let deltas = null;
                if (shadowEntdata && shadowEntdata.EntityId === 'VARIANT') {
                    deltas = [];

                    for (const measure of ntaEntityDataOrg.findEntities(shadowId, 'VARIANT-MEASURE.^MEASURE','^MEASURE')) {
                        const logic = ntabuilding.ntaDependencyValidation.getMeasureLogic(measure);
                        if (logic && logic.generateDeltas) {
                            deltas.push(...logic.generateDeltas(shadowId));
                        } else {
                            const deltasByKey = ntaData.deltas.get(measure.EntityDataId);
                            // Kopieer alle delta’s van deze maatregel
                            deltas.push(...deltasByKey && [...deltasByKey.values()]
                                .map(delta => Object.assign(Object.create(null), delta, { ShadowId: shadowId }))
                                || []);
                        }
                    }

                }
                return ntaDeltas.getShadowBuildingData(shadowId, null, deltas);
            }
        } //-- end: getShadowBuildingData -------------------------------------------------------------

        function isEMGforf(variantId = null) {

            // [Q] toon rij indien G04≠'utiliteitsgebouw' of 'unit in utiliteitsgebouw' EN tenminste 1 van de volgende situaties doet zich voor:
            //     * HO06≠forfaitair AND table9_0 key2 = 'EW' in corresponding row with HO05 (check alle opwekkers)
            //     * HO11 = 'hoge brontemperatuur: ≥ 20°C en < 40°C' OF 'hoge brontemperatuur: ≥ 40°C'
            //     * TO07≠forfaitair AND table13_0 key1 = 'EW' in corresponding row with TO05 (check alle opwekkers)
            //     * indien koeling aanwezig EN KO06≠forfaitair AND table10_0 key2 = 'EK' in corresponding row with KO05 (check alle opwekkers)

            let show = false;
            // Deze code is geport als DetermineEMGforf in CalculationV1.cs. Als hier iets gewijzigd wordt, dan moet dat daar ook gebeuren!
            if (!isUtiliteit()) {
                const buildingData = getVariantBuildingData(variantId);

                const opwekkersInfo = {
                    'VERW-OPWEK': { invoerPropId: 'VERW-OPWEK_INVOER', forfaitair: 'VERW-OPWEK_INVOER_FORF', typePropId: 'VERW-OPWEK_TYPE', externeLevering: 'VERW-OPWEK_TYPE_C', pompPropId: 'VERW-OPWEK_POMP', pompvalues: ['VERW-OPWEK_POMP_HT20', 'VERW-OPWEK_POMP_HT40'] },
                    'TAPW-OPWEK': { invoerPropId: 'TAPW-OPWEK_INV',    forfaitair: 'TAPW-OPWEK_INV_FORF',    typePropId: 'TAPW-OPWEK_TYPE', externeLevering: 'TAPW-OPWEK_TYPE_4' },
                    'KOEL-OPWEK': { invoerPropId: 'KOEL-OPWEK_INVOER', forfaitair: 'VERW-OPWEK_INVOER_FORF', typePropId: 'KOEL-OPWEK_TYPE', externeLevering: 'KOEL-OPWEK_TYPE_7' },
                };
                // Doorzoek alle opwekkers, op zoek naar één die voldoet aan de criteria (niet forfaitair, en wel externe warmtelevering)
                const opwekkers = Object.keys(opwekkersInfo).flatMap(entityId => buildingData.getListWithEntityId(entityId));
                for (const opwekker of opwekkers) {
                    const info = opwekkersInfo[opwekker.EntityId];
                    const invoer = opwekker.PropertyDatas[info.invoerPropId].Value;
                    const type = opwekker.PropertyDatas[info.typePropId].Value;
                    if (invoer !== info.forfaitair && type === info.externeLevering) {
                        show = true;
                        break;
                    }
                    if (info.pompPropId && info.pompvalues.includes(opwekker.PropertyDatas[info.pompPropId].Value)) {
                        show = true;
                        break;
                    }
                }
            }

            const variant = ntaEntityDataOrg.get(variantId) || ntaEntityDataOrg.getFirstWithEntityId("BASIS");
            const { resultEntdatasByEntityId } = ntaResults.getForParents(null, variant, null, ['PRESTATIE']);
            const prestaties = resultEntdatasByEntityId.get('PRESTATIE') || [];
            const propdatasBeng2EMGforf = prestaties.map(ed => ed.PropertyDatas['EP_BENG2_EMGFORF']);
            ntaEntityDataOrg.setPropdataStatus(propdatasBeng2EMGforf, show);

            return show;
        }

        function markResultsVisible(shouldProcessVariants) {
            const gebouwBerekend = !vm.isAppartementOfUtiliteitVoorBestaandeBouw() && !vm.voorProjectwoningen();
            const unitsBerekend = !gebouwBerekend || vm.perGebouwEnAppartementOfUnit();

            const geb = getGebEntdata();
            const units = ntaEntityData.getListWithEntityId('UNIT');
            const allParents = [geb, ...units];

            const visibleParents = [];
            if (gebouwBerekend) visibleParents.push(geb);
            if (unitsBerekend) visibleParents.push(...units);

            const visibleResults = visibleParents
                .flatMap(parent => ntaEntityData.getChildren(parent).filter(entdata => entdata.EntityId.startsWith('RESULT-') || entdata.EntityId === 'PRESTATIE'));
            ntaEntityData.setEntityVisibility(visibleResults, true);

            const hiddenResults = allParents.filter(parent => !visibleParents.includes(parent))
                .flatMap(parent => ntaEntityData.getChildren(parent).filter(entdata => entdata.EntityId.startsWith('RESULT-') || entdata.EntityId === 'PRESTATIE'));
            ntaEntityData.setEntityVisibility(hiddenResults, false);

            if (!shouldProcessVariants) { //// indien varianten niet meedoen, results van varianten op niet visible/relevant zetten
                const variantResults = visibleResults.filter(result => ntaEntityData.getFirstParent(result, "VARIANT"));
                ntaEntityData.setEntityVisibility(variantResults, false);
            }
        }

        async function showWarningGemeenschappelijkeInstallatie(prop, entdata) {
            try {
                // Condities [OCN] voor HO42, [KOBB] voor KO07, [TOCL] voor TO09 en [SAG] voor V83
                const propdata = entdata.PropertyDatas[prop.Id];
                const isGemeenschappelijk = ['VERW-OPWEK_GEM_WEL', 'KOEL-OPWEK_GEM_WEL', 'TAPW-OPWEK_GEM_WEL', 'VENT_GEM_WEL', 'VENT_LBK_WEL'].includes(propdata.Value);
                const isNietGemeenschappelijk = ['VERW-OPWEK_GEM_NIET', 'KOEL-OPWEK_GEM_NIET', 'TAPW-OPWEK_GEM_NIET', 'VENT_GEM_NIET', 'VENT_LBK_NIET'].includes(propdata.Value);

                let code;
                if (perGebouwEnAppartement()) { // Z23 = per gebouw en per appartement
                    /// er moet een andere melding gegeven worden voor tapwater dan voor de andere typen systeem:
                    /// [W061] dan wel [W060] voor conditie [TOCL] (bij TAPW)
                    /// [W059] dan wel [W058] voor conditie [OCN], [KOBB] of [SAG]
                    if (isNietGemeenschappelijk) { // 'niet-gemeenschappelijke installatie' / 'installatie met individuele aflevering' / 'luchtbehandelingskast niet aanwezig'
                        code = prop.Id.includes('TAPW') ? '[W061]' : '[W059]'
                    } else {
                        code = prop.Id.includes('TAPW') ? '[W060]' : '[W058]';
                    }
                } else if (perGebouwEnUnit()) { // Z23 = per gebouw en per unit
                    if (isGemeenschappelijk) {  // 'gemeenschappelijke installatie' / 'installatie met centrale aflevering' / 'luchtbehandelingskast aanwezig'
                        code = '[W058]';
                    } else if (isNietGemeenschappelijk) { // 'niet-gemeenschappelijke installatie' / 'installatie met individuele aflevering' / 'luchtbehandelingskast niet aanwezig'
                        code = '[W062]';
                    }
                } else if (!isUtiliteit()) code = prop.Id.includes('TAPW') ? '[W060]' : '[W058]'; // WN/WB: In alle andere gevallen toon [W058] / [W060]

                if (code) {
                    const result = await ntaMeldingen.warning(code);
                    // Result is `true` als op de OK-button geklikt is, anders `false`.
                    // We moeten alleen naar de installaties navigeren als er ook een cancel-button was (FilterValue3).
                    const warning = result && ntaData.warnings.find(w => w.Id === code);
                    if (warning && warning.FilterValue3) {
                        navToInstallations();
                    }
                }
            } catch (err) {
                $log.error(err, 'voor', prop, 'in', entdata);
            }
        } //-- end: showWarningGemeenschappelijkeInstallatie ------------------------------------------

        function showVariants() {
            const settings = ntaData.original.getFirstWithEntityId('SETTINGS');
            const propdataVarianten = settings.PropertyDatas['SETTINGS_VARIANTEN'];
            return !!propdataVarianten && propdataVarianten.Value === 'True';
        } //-- end: showVariants ----------------------------------------------------------------------

        function showCosts() {
            // [RC-C] toon indien bij instellingen gekozen voor 'maatwerk advies'
            // [ZONW-C] toon indien bij instellingen gekozen voor 'maatwerk advies'
            const settings = ntaData.original.getFirstWithEntityId('SETTINGS');
            const maatadviesPD = settings && settings.PropertyDatas['SETTINGS_MAATADVIES'];
            return maatadviesPD && maatadviesPD.Value === 'True';
        } //-- end: showCosts -------------------------------------------------------------------------

        function calculateInstallationCosts(installation, measureCosts) {
            const propdataNumber = installation.PropertyDatas['INSTALL_AANTAL'];
            const numberOfSystems = propdataNumber.Relevant ? parseFloat(propdataNumber.Value) : 1;

            const amountPerSys = parseFloat(measureCosts && measureCosts.PropertyDatas['MEASURE-COSTS_PER_SYS'].Value);

            const amount = amountPerSys * numberOfSystems;

            /// als we in de juiste schaduwomgeving zitten, dan het totaal opslaan.
            if (ntaEntityData.getShadowId() === (ntaData.shadow && ntaData.shadow.shadowId)) {
                const propTotal = ntaData.properties['MEASURE-COSTS_TOTAAL'];
                const propdataTotal = measureCosts && propTotal.getData(measureCosts);
                if (propdataTotal) {
                    const formattedAmount = ntaRounding.roundAndAddZerosNewValue(propTotal, amount);
                    ntaEntityData.saveprop(propdataTotal, formattedAmount);
                }
            }

            return amount;
        } //-- end: calculateInstallationCosts --------------------------------------------------------

        function calculatePVCosts(installationId, buildingData) {
            const pv = buildingData.getFirstChild(installationId, 'PV');
            const measureCosts = buildingData.getFirstChild(installationId, 'MEASURE-COSTS')
            const propInv = ntaData.properties['MEASURE-COSTS_INV'];
            let amount = 0;
            if (propInv.getValue(measureCosts) === 'MEASURE_KOSTEN_PER_M2') { //-- indien PV07 = kostenkentallen per m²:
                //-- PV08 x ΣEPn18 van het maatregelformulier of
                //-- PV08 x ΣEPn19 van maatregelformulier x waarde uit kolom Apv van PV_model(selectietabel) in de regel van het in EPn07 van het maatregelformulier gekozen product
                const propEpn18 = ntaData.properties['PV-VELD_AANTALM2'];
                const propEpn19 = ntaData.properties['PV-VELD_AANTALPNL'];
                const propPvProd = ntaData.properties['PV_WPPRDT'];
                const propPvProdForf = ntaData.properties['PV_WPPRDTFORF'];

                let somM2 = 0;
                const pvAlleVelden = buildingData.getChildren(pv, "PV-VELD");
                for (const pvVeld of pvAlleVelden) {
                    const propdataEpn18 = propEpn18.getData(pvVeld);
                    const propdataEpn19 = propEpn19.getData(pvVeld);
                    if (propdataEpn18.Relevant) {
                        somM2 += parseFloat(propdataEpn18.Value);
                    } else {
                        const propdataProd = propPvProd.getData(pv);
                        const propdataForf = propPvProdForf.getData(pv);
                        const productValue = propdataProd.Relevant ? propdataProd.Value : (propdataForf.Relevant ? propdataForf.Value : '');
                        const productId = parseInt(productValue);
                        const product = ntaSelectionTable.getProducts('PV', isUtiliteit()).find(x => x.Id === productId);
                        somM2 += parseFloat(propdataEpn19.Value) * (product ? product.Apv : 0);
                    }
                }

                const propPV08 = ntaData.properties['MEASURE-COSTS_PER_M2'];
                const PV08 = parseFloat(propPV08.getValue(measureCosts));
                amount = PV08 * somM2;

                /// als we in de juiste schaduwomgeving zitten, dan het totaal opslaan.
                if (ntaEntityData.getShadowId() === buildingData.shadowId) {
                    const propTotal = ntaData.properties['MEASURE-COSTS_TOTAAL'];
                    const propdataTotal = measureCosts && propTotal.getData(measureCosts);
                    if (propdataTotal) {
                        const formattedAmount = ntaRounding.roundAndAddZerosNewValue(propTotal, amount);
                        ntaEntityData.saveprop(propdataTotal, formattedAmount);
                    }
                }
            } else {
                const propTotal = ntaData.properties['MEASURE-COSTS_TOTAAL'];
                const totalValue = measureCosts && propTotal.getValue(measureCosts);
                amount = parseFloat(totalValue);
            }
            return amount;
        } //-- end: calculatePVCosts ------------------------------------------------------------------------//

        function getCostEntdata(installationId) {
            const costEntdata = ntaEntityData.getFirstChild(installationId, 'MEASURE-COSTS');
            if (costEntdata) {
                const relevant = showCosts() && (isEditingMeasure() || isValidatingVariantWithMeasureType());
                ntaEntityData.setEntityRelevancy(costEntdata, relevant);
            }
            return costEntdata;
        }; //-- end: getCostEntdata ---------------------------------------------------------------

        function hasConstructionOverlap(entdata, measures, valueToSave) {
            const typeProp = ntaData.properties['MEASURE_TYPE'];
            const type = typeProp.Domain.Codes.find(x => x.Id === typeProp.getValue(entdata)).FilterValue1;
            const entdataLogic = ntabuilding.ntaDependencyValidation.getMeasureLogic(entdata.EntityDataId);
            const entdataConstructieIds = entdataLogic.getMatchingConstrEntdatas().map(x => x.EntityDataId);
            for (const measure of measures) {
                if (entdata === measure) continue;
                const measureLogic = ntabuilding.ntaDependencyValidation.getMeasureLogic(measure.EntityDataId);
                if (measureLogic && measureLogic.getMatchingConstrEntdatas) {
                    const measureConstructieIds = new Set(measureLogic.getMatchingConstrEntdatas().map(x => x.EntityDataId));
                    if (entdataConstructieIds.some(r => measureConstructieIds.has(r))) {
                        switch (type) {
                            case 'MEASURE-ZONW': {
                                return typeProp.getValue(measure) === 'MEASURE-ZONWERING'; //alleen overlap van zonwering met zonwering
                            }
                            case 'MEASURE-RC': {
                                return true;
                            }
                            case 'MEASURE-U': {
                                const propVervangItem = ntaData.properties['MEASURE-U_VERVANG_ITEM'];
                                const propMethode = ntaData.properties['MEASURE-U_METHODE'];

                                const measureU = ntaEntityData.getFirstChild(measure, 'MEASURE-U');
                                if (!measureU) continue; //wrong type
                                const measureUMethodeValue = propMethode.getValue(measureU);
                                const measureUVervangValue = propVervangItem.getValue(measureU);

                                const entdataU = ntaEntityData.getFirstChild(entdata, 'MEASURE-U');
                                const entdataMethodeValue = propMethode.getValue(entdataU);
                                const entdataVervangValue = valueToSave || propVervangItem.getValue(entdataU);
                                if ((measureUMethodeValue === 'MEASURE-U_METHODE_LIB' || entdataMethodeValue === 'MEASURE-U_METHODE_LIB') ||
                                    (measureUMethodeValue === entdataMethodeValue &&
                                        (entdataVervangValue === measureUVervangValue || measureUVervangValue === 'MEASURE-U_VERVANG_U_GGL' || entdataVervangValue === 'MEASURE-U_VERVANG_U_GGL'))) {
                                    return true;
                                }
                            }
                        }
                    }
                }
            }
            return false;
        } //-- end: hasConstructionOverlap --------------------------------------------------------


        // Backspace niet toelaten in het autocomplete component. Dit om een loop en errors te voorkomen.
        // Zie https://trello.com/c/r3HbIxXo en in logs de error - melding -> "Error: [$rootScope:infdig] 10 $digest() iterations reached. Aborting!"
        // De loop oplossen vraagt een refactoring van de factories.
        function preventBackspace(keyEvent) {
            if (keyEvent.key === 'Backspace') {
                keyEvent.preventDefault();
            }
        } //-- end: preventBackspace --------------------------------------------------------------

        function isEnergiedragerAanwezig(conditions) {
            const result = { 'verbruiken': false, 'tarieven': false };

            for (const condition of conditions) {
                const entities = ntaEntityDataOrg.getListWithEntityId(condition.entity).filter(x => x.Relevant);
                if ((!condition.property && entities.length > 0) || entities.some(x => x.PropertyDatas[condition.property].Relevant && condition.values.includes(x.PropertyDatas[condition.property].Value))) {
                    result.verbruiken = true;
                    result.tarieven = true;
                    break;
                }
            }

            if (!result.tarieven) { //voor de tarieven ook kijken naar dragers in de maatregelen van varianten
                const variants = ntaEntityDataOrg.getListWithEntityId("VARIANT");
                for (const variant of variants) {
                    const buildingData = vm.getVariantBuildingData(variant.EntityDataId);
                    const ntaEntityData = new NtaEntityDataFactory(() => buildingData);
                    for (const condition of conditions) {
                        const entities = ntaEntityData.getListWithEntityId(condition.entity).filter(x => x.Relevant);
                        if ((!condition.property && entities.length > 0) || entities.some(x => x.PropertyDatas[condition.property].Relevant && condition.values.includes(x.PropertyDatas[condition.property].Value))) {
                            result.tarieven = true;
                            return result;
                        }
                    }
                }
            }
            return result;
        } //-- end: isEnergiedragerAanwezig -------------------------------------------------------

        function getVerbruikMonthAndYear(verbruik, energierekening) {
            const propVerbrMJ = ntaData.properties['VERBR_VERBR_MJ'];
            const propdata = propVerbrMJ.getData(verbruik);
            if (propdata.Value === 'VERBR_MJ_JR') {
                return {
                    "PropertyDataId": propdata.PropertyDataId,
                    "Value": ''
                };
            }
            //ophalen codes
            const codes = propVerbrMJ.Domain.Codes.filter(x => x.Id !== 'VERBR_MJ_JR'); //jaar er uit filteren
            const codeIds = codes.map(x => x.Id);

            //jaar en maand
            const eersteMaand = energierekening.PropertyDatas['ENER-REK_EERSTEMAAND'].Value;
            const date = new Date(eersteMaand);
            const jaar = date.getFullYear();
            const plusJaar = jaar + (codeIds.indexOf(propdata.Value) < date.getMonth() ? 1 : 0);

            if (propdata.Value === 'VERBR_MJ_JR') {
                const monthNames = codes.map(x => x.Value);
                const month = monthNames.at(date.getMonth());
                const lastmonth = monthNames.at(date.getMonth() - 1);
                return {
                    "PropertyDataId": propdata.PropertyDataId,
                    "Value": month + ' ' + jaar + ' t/m ' + lastmonth + ' ' + plusJaar
                };
            } else {
                return {
                    "PropertyDataId": propdata.PropertyDataId,
                    "Value": propVerbrMJ.getCode(verbruik).Value + ' ' + plusJaar
                };
            }
        } //-- end: getVerbruikMonthAndYear ---------------------------------------------------------------

    };

}]);