﻿angular.module('projectModule')
    .controller("BuildingResultsOverzichtController",
          ['$scope', '$controller', 'ntaValidation', 'BuildingResultsOverzichtFactory',
function  ( $scope,   $controller,   ntaValidation,   BuildingResultsOverzichtFactory) {
    'use strict';
    const base = $controller('NTABaseController', { $scope: $scope });
    const vm = angular.extend(this, base);

   ////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INTERFACE              ////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////
    const resultsOverzichtLogic = new BuildingResultsOverzichtFactory();
    vm.ntaValidation = ntaValidation;
    vm.getResultsOverzichtLogic = () => resultsOverzichtLogic;
    vm.resultoverzichtproperties = resultsOverzichtLogic.resultoverzichtproperties;
    vm.resultoverzichtData = resultsOverzichtLogic.resultoverzichtData;
    vm.isHidden = resultsOverzichtLogic.isHidden; 
    vm.getCodedValues = resultsOverzichtLogic.getCodedValues;
    vm.isItemChecked = resultsOverzichtLogic.isItemChecked;
    vm.toggleItemChecked = resultsOverzichtLogic.toggleItemChecked;
    vm.getTablesHtml = resultsOverzichtLogic.getTablesHtml;
    vm.exportResultOverzicht = resultsOverzichtLogic.exportResultOverzicht;
    vm.getForm = () => resultsOverzichtLogic.form_buildingoverzichtresults;

    vm.getExportTypes = getExportTypes;
    vm.saveValue = saveValue;
    vm.startFormValidation = startFormValidation;

    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///         INITALIZIATION         ////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////
    angular.element(function () { //alle touched properties valideren na het laden van form
        vm.startFormValidation();
        base.initializeUnitSpacing();
    });

    const exportTypes = [
        { Id: 'RESULT-OVERZICHT_EXPORT_XLS', Value: 'exporteren naar Excel' },
    //    { Id: 'RESULT-OVERZICHT_EXPORT_CSV', Value: 'exporteren naar CSV' },
    //    { Id: 'RESULT-OVERZICHT_EXPORT_PDF', Value: 'exporteren naar PDF' },
    ];

    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///         IMPLEMENTATION         ////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////
    function getExportTypes() {
        return exportTypes;
    }

    function saveValue(prop, entdata, newValue) {
        resultsOverzichtLogic.saveValue(prop, entdata, newValue);
        base.initializeUnitSpacing();
    } //-- end: saveValue -------------------------------------------------------------------------

    function startFormValidation() {
        resultsOverzichtLogic.startFormValidation();
    }

    $scope.endFormValidation = function () {
        resultsOverzichtLogic.endFormValidation();
    };

}]);