﻿angular.module('projectModule')
    .controller("InstallationTreeController",
        ['$scope', '$controller', 'ntaEntityDataOrg', 'InstallationDataFactory', 'ListCache',
function ($scope,   $controller,   ntaEntityDataOrg,   InstallationDataFactory,   ListCache) {
    'use strict';
    const ctrl = this;
    angular.extend(ctrl, $controller('NTABaseController', { $scope: $scope }));

    /// == Description ============================================================================

    /// Deze controller wordt alleen gebruikt om de installaties in de navigatieboom weer te geven.
    /// We gebruiken niet (meer) de InstallationDefineController, omdat die gebruikt wordt zowel
    ///  voor de berekening als voor de maatregelen; die kijkt dus naar de ntaData.current, en hier
    ///  moeten we juist altijd naar de ntaData.original kijken.


    /// == Imports ================================================================================



    /// == Instance variables =====================================================================

    const _installationData = new InstallationDataFactory(ntaEntityDataOrg);
    const _listCache = new ListCache();


    /// == Imports ================================================================================

    Object.assign(ctrl, {
        getInstallationInfos,
    });


    /// == Implementation =========================================================================

    function getInstallationInfos() {
        const results = _installationData.getInstallations()
            .map(entdata => ({
                id: entdata.EntityDataId,
                typeName: entdata.PropertyDatas["INSTALL_TYPE"].Value.toLowerCase(),
                name: _installationData.getName(entdata, true),
                icon: _installationData.getIcon(entdata),
            }));

        return _listCache.useCacheIfUnchanged('installationInfos', results, (a, b) => a.id === b.id && a.name === b.name && a.icon === b.icon);
    } //-- end: getInstallationInfos --------------------------------------------------------------

}]); //== end: InstallationTreeController =========================================================


angular.module('projectModule')
    .controller("InstallationDefineController",
        ['$scope', '$controller', 'ntaEntityData', 'ntaValidation', 'ntaSharedLogic', 'ntaDependencyValidation', 'InstallationFactory',
function ($scope,   $controller,   ntaEntityData,   ntaValidation,   ntaSharedLogic,   ntaDependencyValidation,   InstallationFactory) {
    'use strict';
    const vm = this;
    angular.extend(vm, $controller('NTABaseController', { $scope: $scope }));

    const installationLogic = new InstallationFactory(ntaDependencyValidation);
    vm.getInstallationLogic = function () {
        return installationLogic;
    };

    vm.ntaValidation = ntaValidation;

    vm.properties = installationLogic.properties;
    vm.propertiesTapwUnitRz = installationLogic.propertiesTapwUnitRz;
    vm.propertiesTapwUnit = installationLogic.propertiesTapwUnit;

    vm.aantalSystemenName = installationLogic.aantalSystemenName;
    vm.installations = installationLogic.installations;

    vm.getInstTypeCode = installationLogic.getInstTypeCode;

    vm.instDescription = installationLogic.instDescription;
    vm.systId = installationLogic.systId;
    vm.instError = installationLogic.instError;
    vm.checkErrors = installationLogic.checkErrors;

    vm.copyAllowed = copyAllowed;
    vm.editNameAllowed = editNameAllowed;
    vm.getInstallatieNaam = installationLogic.getInstallationName;
    vm.editInstallatieNaam = editInstallatieNaam;
    vm.saveInstallatieNaam = saveInstallatieNaam;

    vm.getAllowedInstallationtypes = getAllowedInstallationtypes;
    vm.getInstallationSummary = installationLogic.getInstallationSummary;

    vm.isUtiliteit = ntaSharedLogic.isUtiliteit;

    //maatregel PV: als in de basisberekening geen PV aanwezig is, in de PV maatregel standaard 1 tegel toevoegen
    if (ntaSharedLogic.isEditingMeasure("MEASURE-PV") && installationLogic.getInstallations().length === 0) {
        installationLogic.addInstallation('INST_PV');
    }


    ////////// weergave van omschrijving en samenvatting op de verschillende installatie tegels //////////

    vm.getTitle = function () {
        return !ntaSharedLogic.isEditingMeasure()
            ? 'Installaties'
            : ntaSharedLogic.isEditingMeasure("MEASURE-PV")
                ? 'Maatregel: wijzigen PV installaties'
                : 'Maatregel: wijzigen HVAC installaties';
    }//-- end: getTitle ------------------------------------------------------------------------//

    vm.getAantalProp = function () {
        return vm.properties["INSTALL_AANTAL"];
    }; //-- end: getAantalProp --------------------------------------------------------------------------------//

    vm.showSummary = function (inst) {
        return vm.getInstallationSummary(inst.EntityDataId).length > 0;
    } //-- end: showSummary ------------------------------------------------------------------------//

    vm.saveSummaryInput = function (summ, ev) {
        installationLogic.saveSummaryInput(summ);

        if (ev) {
            ev.stopImmediatePropagation();
            ev.preventDefault();
        }

    } //-- end: saveSummaryInput ------------------------------------------------------------------------//

    vm.clickInput = function (ev) {
        ev.stopImmediatePropagation();
        ev.preventDefault();
    }; //-- end: clickInput --------------------------------------------------------------------------------//


    ////////////////////////////// Relaties tussen systemen onderling en rekenzones //////////////////////////////
    vm.getAgProp = function () {
        return vm.propertiesTapwUnitRz['TAPW-UNIT-RZ_OPP'];
    };

    vm.getBkProp = function () {
        return vm.propertiesTapwUnit['TAPW-UNIT_BADRUIMTEN'];
    };

    vm.getKkProp = function () {
        return vm.propertiesTapwUnit['TAPW-UNIT_KEUKENS'];
    };

    vm.units = installationLogic.units;
    vm.unitsrz = installationLogic.unitsrz;
    vm.installs = installationLogic.installs;

    vm.getTapwUnitEntData = installationLogic.getTapwUnitEntData;
    vm.getInstallationRelations = installationLogic.getInstallationRelations;
    vm.showRelations = installationLogic.showRelations;

    vm.saveRelationKoppeling = function (inst, rel, ev) {
        installationLogic.saveRelationKoppeling(inst, rel);

        ev.stopImmediatePropagation();
        ev.preventDefault();
    } //-- end: saveRekenzoneKoppeling ------------------------------------------------------------------------//

    vm.saveRelationInput = function (inst, rel, ev) {
        installationLogic.saveRelationInput(inst, rel);

        ev.stopImmediatePropagation();
        ev.preventDefault();
    } //-- end: saveRekenzoneKoppeling ------------------------------------------------------------------------//

    vm.getRelationName = installationLogic.getRelationName;

    //////////////////////////////////////// CRUD akties ////////////////////////////////////////

    vm.addInstallation = installationLogic.addInstallation;
    vm.copyClick = installationLogic.copyClick;
    vm.deleteClick = installationLogic.deleteClick;

    function editNameAllowed(installatie) {
        return !installationLogic.isReadOnly('INSTALL_NAAM', installatie);
    } //-- end: editNameAllowed --------------------------------------------------------------------------------//

    function editInstallatieNaam(installId, event) {
        if (!installId) {
            return;
        }
        $("#installatienaam" + installId).removeClass("showname");
        $("#installatienaaminput" + installId).addClass("editname");
        $("#installatienaaminput" + installId).focus();
        $("#installatienaaminput" + installId).select();

        event.stopImmediatePropagation();
        event.preventDefault();
    } //-- end: editInstallatieNaam --------------------------------------------------------------------------------//

    function saveInstallatieNaam(installId, event, newName = "") {
        const installation = ntaEntityData.get(installId);
        const propdataName = installation.PropertyDatas["INSTALL_NAAM"];
        propdataName && ntaEntityData.saveprop(propdataName, newName);

        // als installatie een PV-systeem is, dan de PV-installaties alfabetisch sorteren
        const instType = installation.PropertyDatas['INSTALL_TYPE'].Value;
        if (instType === 'INST_PV') {
            installationLogic.sortInstallationsOfType(instType);
        }

        //-- bij verw of tapw moet ook eventueel de naam aangepast worden bij een eventuele zonneboiler
        if (vm.installations().some(i => i.PropertyDatas["INSTALL_TYPE"].Value === "INST_ZONNB")) {
            installationLogic.setInstallationsRelations();
        }

        $("#installatienaam" + installId).addClass("showname");
        $("#installatienaaminput" + installId).removeClass("editname");

        event.stopImmediatePropagation();
        event.preventDefault();
    } //-- end: saveInstallatieNaam -------------------------------------------------------------//

    function getAllowedInstallationtypes() {
        const res = installationLogic.installationTypes
            //-- op basis van VERW, KOEL, ZONNB enz wordt gecontroleerd of ik nog een systeem mag toevoegen
            .filter(x => isCodedValueAllowed(x.Id) && isAddAllowed(x.Id.substring(5)));
        return res;
    } //-- end: getAllowedInstallationtypes --------------------------------------------------------------------------------//

    function isCodedValueAllowed(codedValueId) {
        let result = true;
        if (!ntaSharedLogic.isUtiliteit() && (codedValueId === "INST_BEV" || codedValueId === "INST_VERL")) {
            result = false;
        }
        if (ntaSharedLogic.voorProjectwoningen() && codedValueId === "INST_WINDT") {
            result = false;
        }
        if (ntaSharedLogic.isEditingMeasure() && installationLogic.nonMeasureInstallTypes.has(codedValueId)) {
            result = false;
        } 
        if (ntaSharedLogic.isEditingMeasure("MEASURE-PV")) {
            result = codedValueId === "INST_PV";
        }
        //-- hier ook gaan checken of er nog een tweede systeem aangemaakt mag worden
        return result;
    } //-- end: isCodedValueAllowed ------------------------------------------------------------------------//

    function copyAllowed(installation) {
        const entdata = ntaEntityData.get(installation.EntityDataId);
        const entId = installationLogic.getInstTypeCode(entdata);

        return isAddAllowed(entId);
    } //-- end: copyAllowed ------------------------------------------------------------------------//

    function isAddAllowed(sysType) {
        // alleen als ik WINDT of VERL ben mag ik er maar 1 toevoegen
        let result = true;
        switch (sysType) {
            case "WINDT":
            case "VERL":
                result = !(ntaEntityData.getListWithEntityId(sysType).length === 1);
        }
        return result;
    } //-- end: addAllowed ------------------------------------------------------------------------//



    ////////////////////////////////////////  Overig ////////////////////////////////////////
    vm.instIconImg = function (entdataId) {
        let iconImg = "";
        const entdata = ntaEntityData.get(entdataId);
        if (entdata && entdata.EntityId === 'INSTALLATIE' && entdata.BuildingId !== -1) {
            const installType = entdata.PropertyDatas["INSTALL_TYPE"].Value;
            const codedValue = installationLogic.installationTypes.find(type => type.Id === installType);
            if (codedValue && codedValue.ImageUrl) {
                iconImg = codedValue.ImageUrl;
                if (installType === 'INST_TAPW') {
                    //-- wanneer er DWTW aanwezig is, moet er een ander plaatje komen
                    const idSys = vm.systId(entdata);
                    const doucherelations = ntaEntityData.getChildRelations(idSys, 'TAPW-DOUCHE');
                    if (doucherelations.length > 0) {
                        iconImg = iconImg.replace('TAPW', 'TAPW-WTW');
                    }
                }
            }
        }
        return iconImg;
    }; //-- instIconImg

    vm.openMenu = function ($mdMenu, ev) {
        $mdMenu.open(ev);

        ev.stopImmediatePropagation();
        ev.preventDefault();
    } //-- end: openMenu ------------------------------------------------------------------------//

    vm.showMenu = function (entdata) {
        return vm.getInstTypeCode(entdata) !== 'VERL';
    };

    vm.navToInstallinTree = function (inst) {
        // Tijdens het editen van de installatienaam niet wegnavigeren
        if (!$("#installatienaam" + inst.EntityDataId).hasClass("showname")) {
            return;
        }

        ntaSharedLogic.navToInstallation(inst);
    } //-- end: navToInstallinTree ------------------------------------------------------------------------//

    //////////////////////////////////////// FORM //////////////////////////////////////////////////
    vm.getForm = function () {
        return installationLogic.form_installationsDefine;
    };

    $scope.endFormValidation = installationLogic.endFormValidation;

    angular.element(function () { //alle touched properties valideren na het laden van form
        installationLogic.startFormValidation();
        vm.checkErrors();
    }); //-- end: endFormValidation ------------------------------------------------------------------------//

}]);