﻿angular.module('projectModule')
    .controller("InstallationBevochtigingController",
        ['$scope', '$controller', 'ntaValidation', 'ntaSharedLogic', '$routeParams', 'ntaDependencyValidation', 'ntaMeasure', 'BevochtigingFactory',
function ($scope,   $controller,   ntaValidation,   ntaSharedLogic,   $routeParams,   ntaDependencyValidation,   ntaMeasure,   BevochtigingFactory) {
    'use strict';

    const vm = this;
    const base = $controller('NTABaseController', { $scope: $scope })
    angular.extend(vm, base);

    vm.ntaValidation = ntaValidation;
    vm.installId = $routeParams.id3;

    // Object met de logica van de bevochting controller die nodig is bij het afhandelen van condities
    const bevochtigingLogic = new BevochtigingFactory(vm.installId, ntaDependencyValidation);

    //vm.getBevochtigingLogic = bevochtigingLogic;
    vm.getBevochtigingLogic = function () {
        return bevochtigingLogic;
    };

    //-- welke pvsys entitie hoort daar bij om mee te geven als parent bij het aanmaken van pv-pvt entities
    vm.properties = bevochtigingLogic.properties;
    vm.bevdata = bevochtigingLogic.bevdata;

    vm.getTitle = () => ntaSharedLogic.isEditingMeasure() ? 'Maatregel: wijzigen bevochtiging' : 'Bevochtiging';

    //FORM///////////////////////////////////////////////////////////////////////////////////////////////
    vm.getForm = function () {
        return bevochtigingLogic.form_installationbevochtiging;
    };

    var startFormValidation = function () {
        //vm.properties.forEach(function (prop, index) {
        //    var propdata = vm.bevdata.PropertyDatas[prop.Id];
        //    ntaValidation.IsValid(vm.getForm(), prop, propdata);
        //});

        bevochtigingLogic.startFormValidation();
    };

    $scope.endFormValidation = function () {
        //vm.bevdata.PropertyDatas.forEach(function (propdata, index) {
        //    if (!propdata.Touched) {
        //        propdata.Touched = true;
        //        ntaEntityData.saveprop(propdata);
        //    }
        //});
        //startFormValidation();

        bevochtigingLogic.endFormValidation();
    };

    ntaMeasure.setBtwPropIds(vm);

    angular.element(function () { //alle touched properties valideren na het laden van form
        startFormValidation();
        base.initializeUnitSpacing();
    });

    //PROPERTIES ////////////////////////////////////////////////////////////////////////////////////////
    vm.getCodedValues = bevochtigingLogic.getCodedValues;
    vm.saveValue = function (prop) {
        bevochtigingLogic.saveValue(prop);
        base.initializeUnitSpacing();
    };
    vm.isHidden = bevochtigingLogic.isHidden;
    vm.isReadOnly = bevochtigingLogic.isReadOnly;

    vm.getPropData = function (prop) {
        if (!prop) {
            return;
        }
        return ntaSharedLogic.getPropData(prop, vm.bevdata);
    };

    vm.getPropertyType = function (prop) {
        if (!prop) {
            return;
        }
        return prop.PropertyType;
    };

    vm.isDisabled = function (prop) {
        return vm.isReadOnly(prop);
    };

    bevochtigingLogic.setGeopend();

}]);
