﻿angular.module('projectModule')
    .factory('EntityData',
        ['$log', 'ntaData', 'PropertyData',
function ($log,   ntaData,   PropertyData) {
    'use strict';

    const defaultObjectProps = {
        Order: 10,
        Relevant: true,
        Visible: true,
    };

    return function EntityData(entityId, objectProps = defaultObjectProps, propdatas = []) {

        const entity = ntaData.entities[entityId];
        if (!entity) {
            $log.warn(`Kan geen EntityData maken; EntityId ${entityId} is niet bekend!`);
            return null;
        }

        if (!objectProps) objectProps = defaultObjectProps;

        this.BuildingId = objectProps.BuildingId || ntaData.buildingId;
        this.EntityDataId = objectProps.EntityDataId || uuid.v4();
        this.EntityId = entityId;
        this.EntityVersionId = entity.VersionId;
        this.Order = objectProps.Order || defaultObjectProps.Order;
        this.Relevant = typeof objectProps.Relevant === 'boolean' ? objectProps.Relevant : defaultObjectProps.Relevant;
        this.Visible = typeof objectProps.Visible === 'boolean' ? objectProps.Visible : defaultObjectProps.Visible;

        const properties = ntaData.properties[entityId];

        this.PropertyDatas = properties.map(prop => new PropertyData(this, prop, propdatas.find(pd => pd.PropertyId === prop.Id)));
        for (const propdata of this.PropertyDatas) {
            this.PropertyDatas[propdata.PropertyId] = propdata;
        }

    }; //== end: EntityData =======================================================================
}]);


angular.module('projectModule')
    .factory('PropertyData',
        ['$log', 'ntaData',
function ($log,   ntaData) {
    'use strict';

    const defaultObjectProps = {
        Touched: false,
        Relevant: true,
        Visible: true,
    };

    return function PropertyData(entdata, propOrId, objectProps = defaultObjectProps) {

        const property = typeof propOrId === 'string' ? ntaData.properties[propOrId] : propOrId;

        if (!property) {
            $log.warn(`Kan geen PropertyData maken; PropertyId ${propOrId} is niet bekend!`, propOrId);
            return null;
        }

        if (!objectProps) objectProps = defaultObjectProps;

        this.BuildingId = objectProps.BuildingId || entdata.BuildingId || ntaData.buildingId;
        this.PropertyDataId = entdata.EntityDataId + ':' + property.Id;
        this.EntityDataId = entdata.EntityDataId;
        this.PropertyId = property.Id;
        this.PropertyVersionId = property.VersionId;
        this.Value = Object.keys(objectProps).includes('Value') ? objectProps.Value : property.DefaultValue;
        this.Touched = typeof objectProps.Touched === 'boolean' ? objectProps.Touched : defaultObjectProps.Touched;
        this.Relevant = typeof objectProps.Relevant === 'boolean' ? objectProps.Relevant : defaultObjectProps.Relevant;
        this.Visible = typeof objectProps.Visible === 'boolean' ? objectProps.Visible : defaultObjectProps.Visible;

    }; //== end: PropertyData =====================================================================
}]);


angular.module('projectModule')
    .factory('RelationData',
        ['$log', 'ntaData',
function ($log,   ntaData) {
    'use strict';

    return function RelationData(parent, child, onDelete, onCopy) {

        Object.assign(this, {
            "BuildingId": parent.BuildingId,
            "EntityRelationDataId": parent.EntityDataId + ':' + child.EntityDataId,
            "Parent": parent.EntityDataId,
            "ParentEntityId": parent.EntityId,
            "Child": child.EntityDataId,
            "ChildEntityId": child.EntityId,
            "OnCopy": !!onCopy,
            "OnDelete": !!onDelete,
        });

    }; //== end: RelationData =====================================================================
}]);


