﻿'use strict';
angular.module('projectModule').controller("BuildingBegrenzingController", ['$scope','$controller','$routeParams','ntaValidation','ntaEntityData','ntaMeldingen','ntaDependencyValidation','BuildingBegrenzingFactory',
function                                                                    ($scope,  $controller,  $routeParams,  ntaValidation,  ntaEntityData,  ntaMeldingen,  ntaDependencyValidation,  BuildingBegrenzingFactory) {

    const base = $controller('NTABaseController', { $scope: $scope })
    const vm = angular.extend(this, base);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    vm.ntaValidation = ntaValidation;
    vm.entId = $routeParams.id3;

    const begrenzingLogic = new BuildingBegrenzingFactory(vm.entId, ntaDependencyValidation);
    vm.getBegrenzingLogic = begrenzingLogic;

    /* Properties *************************************************************************************************************************************************************/
    vm.properties = begrenzingLogic.properties;
    vm.propOpmerkingen = begrenzingLogic.propOpmerkingen;

    /* Data *************************************************************************************************************************************************************/
    vm.entity = begrenzingLogic.entity; //-- ik ben of een entiteit van GRUIMTE of RZ bij AOR of AOS en UNIT-RZ als het een rekenzone is
    vm.begrForm = begrenzingLogic.begrForm;
    vm.vlakken = begrenzingLogic.vlakken;

    //-- voor de ui-sortable
    vm.vlakkenList = []; //-- array die alle vlakken bijhoudt

    vm.getForm = getForm;
    vm.saveValue = saveValue;
    vm.getPlaceholder = getPlaceholder;

    vm.isHidden = begrenzingLogic.isHidden;
    vm.isReadOnly = begrenzingLogic.isReadOnly;
    vm.isDisabled = begrenzingLogic.isReadOnly;
    vm.getCodedValues = begrenzingLogic.getCodedValues;

    vm.unitName = begrenzingLogic.unitName;
    vm.zoneName = begrenzingLogic.zoneName;
    vm.getSelection = begrenzingLogic.getSelection;
    vm.saveSelection = begrenzingLogic.saveSelection;

    vm.addVlakProgress = false;

    vm.addVlak = addVlak;
    vm.copyVlak = copyVlak;
    vm.deleteVlak = deleteVlak;

    $scope.endFormValidation = function () {
        begrenzingLogic.endFormValidation();
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INITALIZIATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    angular.element(function () { //alle touched properties valideren na het laden van form
        begrenzingLogic.startFormValidation();
        vm.initializeUnitSpacing();
    });

    if (vm.begrForm) {
        vm.setGeopend('BEGR-FORM_OPEN', vm.begrForm);
    }
    updateLists();

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function saveValue(prop, entdata) {
        begrenzingLogic.saveValue(prop, entdata);
        vm.initializeUnitSpacing();
    } //-- end: saveValue ------------------------------------------------------------------------//

    function getForm() {
        return begrenzingLogic.form_bldbegr;
    } //-- end: getForm ------------------------------------------------------------------------//

    function getPlaceholder(prop, entdata) {
        return base.getPlaceholder(prop, entdata, begrenzingLogic);
    } //-- end: getPlaceholder ------------------------------------------------------------------------//

    function updateLists() {
        //-- onderstaande lijst zijn voor de ng-model binding van de sortabletabellen. Dit moeten namelijk assignable expressions zijn. Als ze een functie zijn doordat ze
        //-- iedere keer opnieuw opgehaald worden in de de factory gaat dat niet goed. Daarom expliciet in de controller bij houden.
        updateVlakkenList();
    } //-- end: updateLists ------------------------------------------------------------------------//

    function updateVlakkenList() {
        vm.vlakkenList = [];
        vm.vlakken().forEach(function (vlak, index) {
            vm.vlakkenList.push(vlak);
        });
    } //-- end: updateVlakkenList ------------------------------------------------------------------------//

    function addVlak(entdata) {
        vm.addVlakProgress = true;
        begrenzingLogic.createVlak(entdata);
        begrenzingLogic.validateKelderwanden();
        updateLists();
        vm.addVlakProgress = false;
    } //-- end: addVlak ------------------------------------------------------------------------//

    function copyVlak(entdata) {
        if (!entdata) {
            return;
        }

        let newEntdatas = ntaEntityData.copy(entdata.EntityDataId);
        let vlak = newEntdatas.find(function (x) { return x.EntityId === 'BEGR'; });

        //--- Propdatas op touched zetten
        vlak.PropertyDatas.forEach(function (propdata, index) {
            propdata.Touched = true;
            ntaEntityData.saveprop(propdata);
        });

        begrenzingLogic.validateKelderwanden();
        updateLists();
    } //-- end: copyVlak ------------------------------------------------------------------------//

    function deleteVlak(entdata) {
        if (!entdata) {
            return;
        }
        if (vm.vlakken().length <= 1) { //-- kan het laatste vlak niet weggooien
            ntaMeldingen.warning("[W022]", 'Verwijderen niet toegestaan');
            return;
        }

        ntaEntityData.delete(entdata.EntityDataId);
        begrenzingLogic.validateKelderwanden();
        updateLists();
    } //-- end: deleteVlak ------------------------------------------------------------------------//

    vm.sortableVlakken = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            ntaEntityData.SaveReOrder(vm.vlakkenList);
        }
    };

}]);
