﻿'use strict';
angular.module('projectModule').controller("BuildingAttrController", ['$scope', '$controller', '$log', '$q', '$mdDialog', 'ntaData', 'projecttree', 'ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaSharedLogic', 'ntaMeldingen', 'settingsMenuData', 'ntaDependencyValidation', 'AttributesFactory', function ($scope, $controller, $log, $q, $mdDialog, ntaData, projecttree, ntabuilding, ntaValidation, ntaEntityData, ntaSharedLogic, ntaMeldingen, settingsMenuData, ntaDependencyValidation, AttributesFactory) {
    const vm = this;
    const base = $controller('NTABaseController', { $scope: $scope })
    angular.extend(vm, base);

    var attrLogic = new AttributesFactory(ntaDependencyValidation);
    vm.getAttrLogic = function () {
        return attrLogic;
    };

    //Data
    vm.ntaValidation = ntaValidation;
    vm.properties = attrLogic.properties;
    vm.buildingattributes = function () {
        return attrLogic.buildingattributes;
    };

    //Form
    angular.element(function () { //alle touched properties valideren na het laden van form
        vm.properties.forEach(function (prop, index) {
            var propdata = vm.buildingattributes().PropertyDatas[prop.Id];
            validate(prop, propdata);
        });
    });

    $scope.endFormValidation = function () {
        attrLogic.endFormValidation();
    };

    vm.getForm = function () {
        return attrLogic.form_attrib;
    };

    vm.saveValue = function (prop) {
        attrLogic.saveValue(prop);
        base.initializeUnitSpacing();
    };
    vm.saveValueAutocomplete = attrLogic.saveValueAutocomplete;
    var validate = attrLogic.validate;


    vm.confirmGebTypeChange = function (prop, value) {
        if (prop.Id === "GEB_TYPEGEB") {
            var propdata = vm.getPropData(prop);
            if (propdata && value !== propdata.Value) {
                var warning = ntabuilding.warnings.find(function (x) { return x.Id === "[W001]"; }).Value;
                return ntaMeldingen.confirm_before_save_dialog("Wijzigen type gebouw", warning, attrLogic.form_attrib, propdata, value, function () { vm.saveValue(prop); });
            }
        }
        return true;
    };

    vm.getPropData = function (prop) {
        return base.getPropData(prop, vm.buildingattributes());
    };

    vm.getPropDataAutocomplete = function (prop) {
        if (!prop) {
            return;
        }
        if (vm.buildingattributes()) {
            var propData = vm.buildingattributes().PropertyDatas[prop.Id];
            if (propData.Value) {
                var cvs = ntaValidation.codedValues(prop);
                if (cvs && cvs.length > 2) { // is coded value
                    var cvData = cvs.find(function (x) { return x.Id === propData.Value; });
                    var aRes = { Value: cvData };
                    return aRes;
                }
            }
            return;
        } else {
            return prop.DefaultValue;
        }
    };

    vm.getPropertyType = function (prop) {
        return prop && prop.PropertyType;
    };

    vm.isHidden = attrLogic.isHidden;
    vm.getCodedValues = attrLogic.getCodedValues;

    attrLogic.setGeopend();

}]);