﻿var settingsModule = angular.module("settingsModule", ["ngAnimate", "ngMaterial"])
    .config(function ($mdThemingProvider) {
        $mdThemingProvider.theme('default')
            .primaryPalette('light-green')
            .accentPalette('orange');
    });

settingsModule.factory('settingsMenuData', [function () {
    'use strict';
    const self = {};

    self.settings = [];
    self.settingsEntiteit = null;

    self.setCurrentSettings = function (currentSettings, settingProps) {
        self.settingsEntiteit = currentSettings;
        for (const setting of currentSettings.PropertyDatas) {
            // maak self.settings op propertyId toegankelijk
            currentSettings.PropertyDatas[setting.PropertyId] = setting;
            // neem de PropertyName en Order over van de property;
            //  de PropertyName wordt gebruikt tbv de menutekst, en de Order wordt gebruikt tbv de volgorde.
            const prop = settingProps[setting.PropertyId];
            setting.Prop = prop;
            setting.PropertyName = prop.Name;
            setting.Order = prop.Order;
        }
        self.settings = currentSettings.PropertyDatas;
    };

    self.getSetting = function (name) {
        return self.settings[name] || {};
    };

    return self;
}]);



angular.module('projectModule')
    .controller('SettingsController',
        ['progressCircle', 'settingsMenuData', 'ntabuilding', 'ntaSharedLogic', 'ntaDependencyValidation', 'ntaData', 'ntaMeldingen', '$location', '$rootScope', '$mdDialog',
function (progressCircle,   settingsMenuData,   ntabuilding,   ntaSharedLogic,   ntaDependencyValidation,   ntaData,   ntaMeldingen,   $location,   $rootScope,   $mdDialog) {
    'use strict';
    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///         INTERFACE              ////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////
    const vm = this;
    vm.dependencyValidator = ntaDependencyValidation;

    vm.settingClick = settingClick;
    vm.validate = validate;
    vm.validateDependencies = validateDependencies;

    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///         IMPLEMENTATION         ////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////
    async function settingClick(propDataSetting) {
        const propSetting = propDataSetting.Prop;

        const wasChecked = String(propDataSetting.Value).toLowerCase() === 'true';
        let check = !wasChecked;

        switch (propDataSetting.PropertyId) {
            case 'SETTINGS_THBRUG':
                if (check && !await canSetTHBRUG()) {
                    check = false; // dan niet
                }
                break;

            case 'SETTINGS_VARIANTEN':
            case 'SETTINGS_MAATADVIES': {
                if (check) {
                    // Controleer of de gebruiker de juiste licentie heeft voor de varianten- en maatwerkadviesmodule
                    const isUtiliteit = ntaSharedLogic.isUtiliteit();
                    if (!ntaData.modules.includes(isUtiliteit ? 'nonResidential.bespoke' : 'residential.bespoke')) {
                        await ntaMeldingen.warning(isUtiliteit ? '[W136]' : '[W135]');
                        check = false; // dan mag het niet

                    }

                    if (check && propDataSetting.PropertyId === 'SETTINGS_MAATADVIES' && (ntaSharedLogic.perGebouwEnAppartement() || ntaSharedLogic.voorProjectwoningen())) {
                        /// controleer of ntabuilding een projectwoningen berekening is, of een
                        /// appartementengebouw met Z23 = per gebouw en per appartement. Zo JA,
                        /// dan melding W102 tonen en waarde niet op True zetten
                        await ntaMeldingen.warning("[W102]");
                        check = false; // dan mag het niet
                    }
                }
                break;
            }

            case 'SETTINGS_NGEBGEB-E': {
                showPopupNietGebouwGebonden();
                check = false; // dit is eigenlijk een ‘gewoon’ menu-item, dat nooit een vinkje krijgt.
                break;
            }
        }

        if (check !== wasChecked) {
            progressCircle.setShowProgressValue(true, 'wijzigen instelling');
            try {
                saveValue(propSetting, settingsMenuData.settingsEntiteit, check ? 'True' : 'False');

                $rootScope.$broadcast('settingsChanged');
            } finally {
                progressCircle.setShowProgressValue(false);
            }
        }
    } //-- end: settingClick --------------------------------------------------------------------//

    async function canSetTHBRUG() {
        const confirm = $mdDialog.confirm()
            .title('Thermische bruggen')
            .htmlContent('De berekening zal bij forfaitaire thermische bruggen geen gebruik meer maken van de ingevoerde lineaire en puntvormige thermische bruggen. Ook worden ingevoerde AOR-en en AOS-en verwijderd.<br><br>Weet u zeker dat u forfaitair wilt rekenen?')
            .ariaLabel('Thermische bruggen forfaitair')
            .ok('Ja')
            .cancel('Nee');

        try {
            await $mdDialog.show(confirm);
            return true;
        } catch (err) {
            return false;
        }
    } //-- end: canSetTHBRUG ----------------------------------------------------------------------

    function showPopupNietGebouwGebonden() {
        $mdDialog.show({
            templateUrl: '/src/app/templates/niet-gebgeb-elektr.html',
            parent: angular.element(document.body),
            openFrom: '#instellingMenu',
            closeTo: '#instellingMenu',
            clickOutsideToClose: true,
        })
            .then(() => { }, () => { }); // we doen niets met het resultaat, dus de reject ook niet loggen als foutmelding.
    } //-- end: showPopupNietGebouwGebonden -------------------------------------------------------

    function saveValue(propOrId, entdata, newValue) {
        // Zorg dat de instellingen altijd opgeslagen worden in de berekening, niet in de schaduwomgeving.
        const buildingData = ntaData.current;
        try {
            ntaData.current = ntaData.original;

            ntaSharedLogic.saveValue(propOrId, entdata, newValue, vm);

        } finally {
            ntaData.current = buildingData;
        }
    } //-- end: saveValue -----------------------------------------------------------------------//

    function validate(prop, propdata, entdata) {
        if (!ntabuilding.canSave()) return;
        if (!prop || !entdata || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
            return;
        }

        switch (prop.Id) {
            default: {
                //-- VO 2021-02-15: Hier gebeurt vooralsnog niets, maar entiteit Settings mot hetzelfde werken als de anderen en in
                //-- de saveValue van ntasharedLogic de dependency validator aanroepen die de lineaire constructies op relevant of niet
                //-- relevant als de setting thermische bruggen gewijzigd word. Later volgen er vast nog meer settings en afhankelijkheden.
                break;
            }
        }
    } //-- end: validate ------------------------------------------------------------------------//

    function validateDependencies(prop, entdata) {
        if (!prop || !entdata) {
            return;
        }

        const propdata = prop.getData(entdata);
        switch (prop.Id) {
            case 'SETTINGS_VARIANTEN': {
                if (propdata.Value !== 'True') {
                    saveValue('SETTINGS_MAATADVIES', entdata, 'False');
                    // als we in een maatregel- of variantformulier staan, dan naar het formulier algemene gegevens gaan
                    const match = /^(\/Project\/\d+\/Berekening\/\d+\/)(Maatregel|Variant)/i.exec($location.path())
                    if (match) {
                        $location.path(match[1]);
                    }
                }
                break;
            }
            case 'SETTINGS_MAATADVIES': {
                if (propdata.Value === 'True') {
                    saveValue('SETTINGS_VARIANTEN', entdata, 'True');
                } else {
                    // als we in het formulier ‘Gebruikersprofiel fitten’ of ‘Energierekening’ staan, dan naar het formulier algemene gegevens gaan
                    const match = /^(\/Project\/\d+\/Berekening\/\d+\/)(Gebruikersprofiel|Energierekening)/i.exec($location.path())
                    if (match) {
                        $location.path(match[1]);
                    }
                }
                break;
            }
            default: {
                //-- VO 2021-02-15: Hier gebeurt vooralsnog niets, maar entiteit Settings moet hetzelfde werken als de anderen en in
                //-- de saveValue van ntasharedLogic de dependency validator aanroepen die de lineaire constructies op relevant of niet
                //-- relevant als de setting thermische bruggen gewijzigd word. Later volgen er vast nog meer settings en afhankelijkheden.
                break;
            }
        }
    } //-- end: validateDependencies ------------------------------------------------------------//

}]);

