angular.module('ntaModule')
    .service('ntaVersions',
        ['$log', '$mdDialog',
function ($log,   $mdDialog) {
    'use strict';
    const self = this;

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Instance variables
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const _ntaVersions = [];    // alle ntaVersions, geïndexeerd op ntaVersionId
    const _warnings = [];       // alle warnings, als lijst en ook geïndexeerd op Id
    const _manualVersions = []; // alle versies die handmatig geüpgradet moeten worden, in AFlopende volgorde
    let _activeVersion = null;
    let _promiseActiveVersion = null;   // een promise die zal resolven met de actieve ntaVersion
    let _resolveActiveVersion = null;   // de resolver waaraan de actieve ntaVersion zal moeten worden meegegeven

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Interface
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    self.setVersions = setVersions;
    self.setWarnings = setWarnings;

    self.getVersionById = getVersionById;
    self.getManualVersions = () => _manualVersions;
    self.getActiveVersion = getActiveVersion;
    self.getActiveVersionAsync = getActiveVersionAsync;
    self.setActiveVersion = setActiveVersion;
    self.trySetActiveVersion = trySetActiveVersion;
    self.canOpenWithVersionIdAsync = canOpenWithVersionIdAsync;

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Implementation
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    function setVersions(ntaVersions) {
        for (const ntaVersion of ntaVersions) {
            _ntaVersions[ntaVersion.ntaVersionId] = ntaVersion;
            ntaVersion.name = ntaVersion.version.replace(/^(\d+\.\d+).*$/, '$1');
        }
        // De ‘handmatige’ versies opzoeken, in aflopende volgorde; we willen alleen de ‘eind’versies vóór elke ‘handmatige’ versie, en de meest recente versie.
        _manualVersions.length = 0;
        let keepNext = true;
        for (const ntaVersion of ntaVersions.sort((a, b) => b.ntaVersionId - a.ntaVersionId)) {
            if (keepNext) {
                _manualVersions.push(ntaVersion);
            }
            keepNext = !ntaVersion.automatic;
        }

        const sessionNtaVersionId = parseInt(sessionStorage.getItem("activeNtaVersionId"));
        let defaultNtaVersion = _manualVersions.find(v => v.ntaVersionId === sessionNtaVersionId);

        // tot 1 juli 2024 moet er standaard met 3.2 gewerkt worden
        //-- VO 2024-06-10: tijdelijke op juni gezet anders kan afmelden voor 3.3 niet getest worden 
        if (!defaultNtaVersion && moment().isBefore('2024-06-01')) {
            defaultNtaVersion = _manualVersions.find(v => v.name === '3.2');
        }

        // standaard is de meest recente versie actief
        if (!defaultNtaVersion) {
            defaultNtaVersion = _manualVersions[0];
        }

        setActiveVersion(defaultNtaVersion);
    } //-- end: setVersions ---------------------------------------------------------------------//

    function setWarnings(warnings) {
        for (const warning of warnings) {
            _warnings.push(warning);
            _warnings[warning.Id] = warning;
        }
    } //-- end: setWarnings ---------------------------------------------------------------------//

    function getVersionById(ntaVersionId) {
        return _ntaVersions[ntaVersionId];
    } //-- end: getVersionById ------------------------------------------------------------------//

    function getActiveVersion() {
        return _activeVersion || { version: '--' };
    } //-- end: getActiveVersion ----------------------------------------------------------------//

    /// getActiveVersionAsync geeft altijd de actieve versie (asynchroon) terug;
    ///  als er nog geen actieve versie is, wacht-ie tot die er wel is.
    async function getActiveVersionAsync() {
        if (_activeVersion) {
            return _activeVersion;
        }
        if (!_promiseActiveVersion) {
            _promiseActiveVersion = new Promise((resolve, reject) => {
                _resolveActiveVersion = resolve;
            });
        }
        return _promiseActiveVersion;
    } //-- end: getActiveVersionAsync -----------------------------------------------------------//

    function setActiveVersion(ntaVersionOrId) {
        const ntaVersion = typeof (ntaVersionOrId) === 'number' ? getVersionById(ntaVersionOrId) : ntaVersionOrId;

        if (typeof _resolveActiveVersion === 'function') {
            _resolveActiveVersion(ntaVersion);
            _resolveActiveVersion = null;
        }

        _activeVersion = ntaVersion;

        const versionElement = document.getElementById('active-version');
        if (versionElement) versionElement.textContent = ntaVersion.version;

        sessionStorage.setItem('activeNtaVersionId', ntaVersion.ntaVersionId);
    } //-- end: setActiveVersion ----------------------------------------------------------------//

    async function trySetActiveVersion(ntaVersionOrId) {
        const activeVersion = await getActiveVersionAsync();
        const targetVersion = typeof (ntaVersionOrId) === 'number' ? getVersionById(ntaVersionOrId) : ntaVersionOrId;

        let warning;
        /// Als de gebruiker switcht naar 3.3 een popup met [W070] weergeven.
        /// VO 2024-06-10: tijdelijke op juni gezet anders kan afmelden voor 3.3 niet getest worden
        if (moment().isBefore("2024-06-01") && activeVersion.ntaVersionId < 300 && targetVersion.ntaVersionId >= 300) {
            warning = '[W070]'; // Momenteel moet nog afgemeld worden met Uniec 3.2. Per 1 juli 2024 zal afmelden met Uniec 3.3 wettelijk van kracht worden. Gaat u de berekening op of na 1 juli 2024 afmelden dan moet u gebruik maken van Uniec 3.3. Wilt u voor die datum afmelden dient u gebruik te blijven maken van Uniec 3.2.

        /// Als de gebruiker switcht van 3.3 naar 3.2, 3.1 of 3.0 een popup met [W081] weergeven.
        } else if (300 <= activeVersion.ntaVersionId && targetVersion.ntaVersionId < 300) {
            warning = '[W081]'; // Sinds 1 juli 2023 is Uniec 3.2 wettelijk van kracht. Voor nieuw af te melden berekeningen moet u gebruik maken van Uniec 3.2 en mag u geen gebruik meer maken van oudere Uniec 3 versies . Wilt u een registratie van voor 1 juli 2023 vervangen dan dient u gebruik te maken van de versie van Uniec die gebruikt is ten tijde van de oorspronkelijke registratie.
        }

        let shouldSwitch = !warning;
        if (!shouldSwitch) {

            const buttons = [
                { text: `blijf in versie ${activeVersion.name}`, result: false },
                { text: `ga over naar versie ${targetVersion.name}`, result: true },
            ];

            shouldSwitch = await new Promise((resolve, reject) => {
                $mdDialog.show({
                    template: `
    <md-dialog aria-label="Uniec 3 versie">
        <md-toolbar>
            <div class="md-toolbar-tools" style="color:#fff; font-size:16px;">
                Uniec 3 versie
            </div>
        </md-toolbar>
        <md-dialog-content>
            <div style="max-width:720px; padding:20px;" ng-bind-html="ctrl.warning">
            </div>
        </md-dialog-content>
        <md-dialog-actions>
            <md-button ng-repeat="button in ctrl.buttons" ng-click="ctrl.open(button)" class="md-primary">
                {{button.text}}
            </md-button>
        </md-dialog-actions>
    </md-dialog>`,
                    controller: DialogController,
                    controllerAs: 'ctrl',
                });

                function DialogController($mdDialog) {
                    this.warning = _warnings[warning] && _warnings[warning].Value || warning;
                    this.buttons = buttons;
                    this.open = function (button) {
                        resolve(button.result);
                        $mdDialog.hide();
                    };
                    this.cancel = function () {
                        resolve(false);
                        $mdDialog.hide();
                    };
                }
            });
        }

        if (shouldSwitch) {
            setActiveVersion(ntaVersionOrId);
        }
    } //-- end: trySetActiveVersion

    /// Geeft een Promise terug die resolvet met de ntaVersionId waarmee de berekening geopend moet worden,
    ///  of null als de berekening niet geopend moet worden.
    async function canOpenWithVersionIdAsync(berekeningen, isFromAfmelden = false) {
        const recentVersion = _manualVersions[0];
        const activeVersion = await getActiveVersionAsync();
        const autoActiveVersion = getLastAutomaticVersion(activeVersion.ntaVersionId); // Achterhaal in welke ‘hoofdversie’ de berekening en de applicatie nu zitten.

        let openWithVersionId = null;
        let warning;
        let versionOptions = []; // de versies waarmee de berekening geopend kan worden (tbv knoppen ‘openen in versie ...’)
        let actions = [];
        let buildingVersion;
        let textPlaceholders = new Map();

        for (const berekening of berekeningen) {
            if (!warning) {
                const buildingVersionId = berekening.NTAVersionId;
                const autoBuildingVersion = getLastAutomaticVersion(buildingVersionId); // Achterhaal in welke ‘hoofdversie’ de berekening en de applicatie nu zitten.
                buildingVersion = getVersionById(buildingVersionId);

                if (isFromAfmelden) {
                    if (autoBuildingVersion.ntaVersionId < 300) { // afmelden met een 3.0, 3.1 of 3.2 versie -> kan alleen worden vervangen
                        //-- VO 2024-06-20: tekst algemener gemaakt. Hoeft dus eigenlijk niet meer met deze passiveText en actionText te werken,
                        //-- maar laat het toch maar zo staan voor wanneer de meningen weer omdraaien. Op deze manier hoeft ook de database niet 
                        //-- aangepast te worden.
                        const [passiveText, actionText] = ['vervangen/herlabeld', 'vervangen/herlabelen'];
                        textPlaceholders.set('vervangen', passiveText);
                        if (berekeningen.length > 1) { // meerdere berekeningen = folder -> W087
                            warning = '[W087]'; //De berekeningen in deze map zijn gemaakt in versie {{buildingVersion}}. In versie {{buildingVersion}} kunnen alleen eerdere afmeldingen die met deze versie zijn gedaan, worden vervangen. Voor het doen van een nieuwe registratie moeten de berekeningen eerst geopend en doorgerekend worden in versie {{recentVersion}}.
                            versionOptions = [buildingVersion]; // afmelden in oudere versie of annuleren
                            actions = [{ "id": buildingVersion.ntaVersionId, "text": actionText }]
                        } else { // enkele berekening -> W086
                            warning = '[W086]'; // Deze berekening is gemaakt in versie {{buildingVersion}}. In versie {{buildingVersion}} kunnen alleen eerdere afmeldingen die met deze versie zijn gedaan, worden vervangen. Voor het doen van een nieuwe registratie open de berekening eerst in versie {{recentVersion}} en reken deze door.
                            versionOptions = [buildingVersion, recentVersion]; // afmelden in v3.0 of v3.1, openen in v3.1 of annuleren
                            actions = [{ "id": buildingVersion.ntaVersionId, "text": actionText }, { "id": recentVersion.ntaVersionId, "text": "openen" }]
                        }
                    } else if (autoBuildingVersion.ntaVersionId !== autoActiveVersion.ntaVersionId) {
                        warning = '[W084]'; // U werkt momenteel in Uniec versie {{activeVersion}}. De berekening(en) die u wilt afmelden is/zijn gemaakt in Uniec versie {{buildingVersion}}. Wilt u overschakelen naar Uniec versie {{buildingVersion}}?
                        versionOptions = [buildingVersion];
                    } else {
                        openWithVersionId = buildingVersionId;
                    }
                } else {
                    if (autoBuildingVersion === autoActiveVersion) {
                        openWithVersionId = buildingVersionId;

                    } else if (autoBuildingVersion.ntaVersionId < autoActiveVersion.ntaVersionId) {
                        // de berekening heeft een oudere versie, dus die zal geüpgraded moeten worden
                        if (berekening.Afgemeld) {
                            // maar een afgemelde berekening kan niet geüpgraded worden!
                            warning = '[W075]'; // U werkt momenteel in Uniec versie {{activeVersion}}. De afgemelde berekening die u wilt openen is gemaakt in Uniec versie {{buildingVersion}}. Afgemelde berekeningen kunnen alleen geopend worden in de versie waarin ze gemaakt zijn. Indien u de berekening wilt openen in Uniec versie {{activeVersion}} moet u eerst een kopie van deze afgemelde berekening maken. De kopie kan daarna bijgewerkt worden naar versie {{activeVersion}}.
                            versionOptions = [buildingVersion];
                        } else {
                            // leg de keuze voor aan de gebruiker
                            warning = '[W067]'; // U werkt momenteel in Uniec versie {{activeVersion}}. De berekening die u wilt openen is gemaakt in Uniec versie {{buildingVersion}}. Indien u de berekening wilt openen in Uniec versie {{activeVersion}} wordt een kopie van de berekening gemaakt en deze bijgewerkt naar versie {{activeVersion}}. De oorspronkelijke berekening gemaakt in versie {{buildingVersion}} blijft bewaard.
                            versionOptions = [buildingVersion, activeVersion];
                        }

                    } else if (autoActiveVersion.ntaVersionId < autoBuildingVersion.ntaVersionId) {
                        // een berekening kan niet teruggezet worden naar een oudere versie
                        warning = '[W068]'; // U werkt momenteel in Uniec versie {{activeVersion}}. De berekening die u wilt openen is gemaakt in Uniec versie {{buildingVersion}}. Het is niet mogelijk een berekening gemaakt in Uniec versie {{buildingVersion}} te openen in de oudere Uniec versie {{activeVersion}}.
                        versionOptions = [buildingVersion];
                    }
                }
            }
        }

        if (warning) {
            openWithVersionId = await new Promise((resolve, reject) => {
                $mdDialog.show({
                    template: `
<md-dialog aria-label="Uniec 3 versie">
    <md-toolbar>
        <div class="md-toolbar-tools" style="color:#fff; font-size:16px;">
            Uniec 3 versie
        </div>
    </md-toolbar>
    <md-dialog-content>
        <div style="max-width:720px; padding:20px;" ng-bind-html="ctrl.warning">
        </div>
    </md-dialog-content>
    <md-dialog-actions>
        <md-button ng-repeat="version in ctrl.versions" ng-click="ctrl.open(version)" class="md-primary">
            {{ctrl.getAction(version)}} in versie {{version.name}}
        </md-button>
        <md-button ng-click="ctrl.cancel()" class="md-secondary">
            Annuleren
        </md-button>
    </md-dialog-actions>
</md-dialog>`,
                    controller: DialogController,
                    controllerAs: 'ctrl',
                });

                function DialogController($mdDialog) {
                    const replacements = Object.fromEntries(textPlaceholders);
                    replacements.activeVersion = activeVersion.name;
                    replacements.buildingVersion = buildingVersion.name;
                    replacements.recentVersion = recentVersion.name;

                    this.warning = _warnings[warning] && _warnings[warning].Value
                        && _warnings[warning].Value.replace(/{{(.+?)}}/g, (match, placeholder) => replacements[placeholder] || '')
                        || warning;
                    this.versions = versionOptions;
                    this.actions = actions;
                    this.open = function (ntaVersion) {
                        resolve(ntaVersion.ntaVersionId);
                        $mdDialog.hide();
                    };
                    this.cancel = function () {
                        resolve(null);
                        $mdDialog.hide();
                    };
                    this.getAction = function (version) {
                        const action = actions.find(x => x.id === version.ntaVersionId);
                        return isFromAfmelden ? (action?.text || "afmelden") : "openen";
                    };
                }
            });
        }
        return openWithVersionId;
    } //-- end: canOpenWithVersionIdAsync -------------------------------------------------------//

    function getLastAutomaticVersion(versionId) {
        // Let op: _manualVersions is gesorteerd in AFLOPENDE volgorde!
        let latestVersion;
        for (const manualVersion of _manualVersions) {
            if (versionId > manualVersion.ntaVersionId) {
                break;
            }
            latestVersion = manualVersion;
        }
        return latestVersion;
    } //-- end: getLastAutomaticVersion ---------------------------------------------------------//

}]);

//Controllers
angular.module('projectModule')
    .controller('VersionController',
        ['ntaVersions', '$log', '$timeout',
function (ntaVersions,   $log,   $timeout) {
    'use strict';
    const ctrl = this;

    Object.assign(ctrl, ntaVersions);
}]);
