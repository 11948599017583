﻿angular.module('projectModule')
    .service('ntaMeasureData',
        ['$log', 'ntaData', 'ntaSharedLogic', 'ntaEntityData',
function ($log,   ntaData,   ntaSharedLogic,   ntaEntityData) {
    'use strict';
    const self = this;

    /// == Imports ================================================================================


    /// == Instance variables =====================================================================

    const _begrVlaktypeByMeasureType = new Map(Object.entries({
        'MEASURE-RC_VLOER': 'VLAK_VLOER',
        'MEASURE-RC_VLOER_BOVBUI': 'VLAK_VLOER_BOVBUI',
        'MEASURE-RC_GEVEL': 'VLAK_GEVEL',
        'MEASURE-RC_DAK': 'VLAK_DAK',
        'MEASURE-RC_KELDERWAND': 'VLAK_KELDERW',
        'MEASURE-RC_BODEM': 'VLAK_VLOER', // TODO: welke begrenzingen tonen voor de bodem-Rc?
    }));

    const _begrenzingPropId = {
        VLAK_VLOER: 'BEGR_VLOER',
        VLAK_GEVEL: 'BEGR_GEVEL',
        VLAK_DAK: 'BEGR_DAK',
        VLAK_KELDERW: 'BEGR_KWAND',
        VLAK_VLOER_BOVBUI: 'BEGR_VLOER_BOVBUI'
    };


    const _measureTypesByBegrVlaktype = [..._begrVlaktypeByMeasureType]
        .reduce((map, [measureType, begrVlaktype]) => {
            const set = map.get(begrVlaktype) || new Set();
            map.set(begrVlaktype, set);
            set.add(measureType);
            return map;
        }, new Map());

    const _collator = new Intl.Collator('nl-NL', { sensitivity: 'accent' });

    /// == Exports ================================================================================

    Object.assign(self, {
        // -- fields --
        begrVlaktypeByMeasureType: _begrVlaktypeByMeasureType,
        measureTypesByBegrVlaktype: _measureTypesByBegrVlaktype,

        // -- methods --
        getList,
        getTypes,
        getPropMeasureType,
        getName,
        getDefaultName,
        getMeasureRcsForBegrVlaktype,
        getBegrenzingByUniqueBegrenzing,
        checkBegrRelations,
        isItemChecked,
        toggleItemChecked,
        sortMeasuresByName,
    });


    /// == Initialization =========================================================================
    sortMeasuresByName();

    /// == Implementation =========================================================================

    function getList() {
        return ntaData.original.getListWithEntityId('MEASURE');
    } //-- end: getList ---------------------------------------------------------------------------

    function getTypes() {
        const propMeasureType = getPropMeasureType();
        const types = propMeasureType && propMeasureType.Domain.Codes || [];
        return types && ntaSharedLogic.isUtiliteit() ? types : types.filter(x => x.Id != 'MEASURE-VERL');
    } //-- end: getMeasureTypes -------------------------------------------------------------------

    function getPropMeasureType() {
        // Deze moeten we dynamisch ophalen, want we kunnen switchen van en naar berekeningen
        //  in een versie die deze property überhaupt niet kent.
        return ntaData.properties["MEASURE_TYPE"];
    } //-- end: getPropMeasureType ----------------------------------------------------------------

    function orderMeasures(a, b) {
        const measureTypes = getTypes();
        const aIndex = measureTypes.findIndex(x => x.Id === a.PropertyDatas['MEASURE_TYPE'].Value);
        const bIndex = measureTypes.findIndex(x => x.Id === b.PropertyDatas['MEASURE_TYPE'].Value);

        return aIndex - bIndex
            || _collator.compare(a.PropertyDatas['MEASURE_NAAM'].Value, b.PropertyDatas['MEASURE_NAAM'].Value)
            || a.Order - b.Order;
    } //-- end: orderMeasures ---------------------------------------------------------------------

    function sortMeasuresByName() {
        const measures = ntaData.original.getListWithEntityId('MEASURE').sort(orderMeasures);

        ///opslaan op de server
        ntaEntityData.SaveReOrder(measures);

        return measures;
    } //-- end: orderMeasures ---------------------------------------------------------------------

    function getName(measure) {
        return measure.PropertyDatas['MEASURE_NAAM'].Value;
    } //-- end: getName ---------------------------------------------------------------------------

    function getDefaultName(measureTypeIdOrEntdata) {
        const propMeasureType = getPropMeasureType();
        const [measureTypeId, entdata] = typeof measureTypeIdOrEntdata === 'string'
            ? [measureTypeIdOrEntdata, null]
            : [propMeasureType.getValue(measureTypeIdOrEntdata), measureTypeIdOrEntdata];

        const measureType = propMeasureType.Domain.Codes.find(code => code.Id === measureTypeId);
        const defaultName = measureType && htmlDecode(measureType.Value) || '';

        const measureNames = new Set(
            getList()
                .filter(ed => ed !== entdata)
                .map(ed => ed.PropertyDatas['MEASURE_NAAM'].Value)
        );
        let counter = 1;
        let name;
        do {
            name = defaultName + ' ' + counter++;
        } while (measureNames.has(name));

        return name;
    } //-- end: getDefaultName --------------------------------------------------------------------

    function htmlDecode(text) {
        if (!text) return text;
        const span = document.createElement('span');
        span.innerHTML = text;
        return span.innerText;
    } //-- end: htmlDecode ------------------------------------------------------------------------

    function getMeasureRcsForBegrVlaktype(begrVlaktype) {
        const measureTypeIds = _measureTypesByBegrVlaktype.get(begrVlaktype);
        return getList()
            .filter(ed => measureTypeIds.has(ed.PropertyDatas['MEASURE_TYPE'].Value))
            .map(ed => ntaData.original.getFirstChild(ed, 'MEASURE-RC'));
    } //-- end: getMeasureRcsForBegrVlaktype ------------------------------------------------------


    function getBegrenzingByUniqueBegrenzing(begrenzingen, begrenzingLogic) {
        //benodige gegevens:    Id: unieke begrenzing,
        //                      Value: unieke begrenzing,
        // toevoeging list met EntitydataIds of Begrenzingen
        const result = [];
        const propVlak = ntaData.properties['BEGR_VLAK'];
        for (const begrenzing of begrenzingen) {
            const vlak = propVlak.getValue(begrenzing);
            const propId = _begrenzingPropId[vlak];
            const propBegr = ntaData.properties[propId];
            const orientatie = propBegr.getValue(begrenzing);
            const existingItem = result.find(x => x.Id === orientatie);
            if (existingItem) {
                existingItem.entdatas.push(begrenzing);
            } else {
                const vlakCode = propVlak.Domain.Codes.find(x => x.Id === vlak);
                const vlakValue = vlakCode && vlakCode.Value || "";

                const codedValues = begrenzingLogic.getCodedValues(propBegr, begrenzing);

                const orientatieCode = codedValues.find(x => x.Id === orientatie);
                const orientatieValue = orientatieCode && orientatieCode.Value || "";
                result.push({ Id: orientatie, Value: vlakValue + " - " + orientatieValue, entdatas: [begrenzing] });
            }
        }
        return result;
    } //-- end: getBegrenzingByUniqueBegrenzing ---------------------------------------------------

    function checkBegrRelations(prop, measure, items) {
        //controleer per item of de relaties tussen begr en measure goed zijn.
        for (const item of items) {
            for (const begr of item.entdatas) {
                const relations = ntaEntityData.getRelationsBetween(begr, measure);
                if (isItemChecked(prop, measure, item)) {
                    if (relations.length === 0) { // relatie toevoegen
                        ntaEntityData.createRelation(begr.EntityDataId, measure.EntityDataId, false, true);
                    }
                } else {
                    for (const relation of relations) { // relatie verwijderen (als er een relatie is)
                        ntaEntityData.deleteRelation(relation.EntityRelationDataId);
                    }
                }
            }
        }

        //controleer of de bestaande relaties met begrenzingen wel beschikbaar zijn -> anders verwijderen. Zie https://trello.com/c/rEOQ926j/1441-melding-dat-maatregel-niet-kan-worden-toegevoegd-aan-variant
        const availableBegrenzingen = new Set(items.flatMap(x => x.entdatas).flatMap(x => x.EntityDataId));
        const relations = ntaEntityData.getParentRelations(measure, 'BEGR');
        const relationsToDelete = relations.filter(x => !availableBegrenzingen.has(x.Parent));
        for (const relation of relationsToDelete) { 
            ntaEntityData.deleteRelation(relation.EntityRelationDataId);
        }


    } //-- end: checkBegrRelations ----------------------------------------------------------

    function isItemChecked(prop, entdata, item) {
        if (!prop || !entdata || !item)
            return false;
        const valuesSet = new Set(String(prop.getValue(entdata)).split('|'));
        return valuesSet.has(item.Id);
    } //-- end: isItemChecked ---------------------------------------------------------------------

    function toggleItemChecked(prop, entdata, item) {
        // Eerst opslaan, want bij het toevoegen/verwijderen van relaties wordt de measure gevalideerd en de totale kosten bepaald.
        // Hierbij worden de relaties tussen maatregel en begrenzing gecontroleerd via isItemChecked...
        const isChecked = isItemChecked(prop, entdata, item);
        const propdata = prop.getData(entdata);
        const value = propdata.Value;
        const newValue = isChecked ? value.replace(item.Id + "|", "") : value ? value + item.Id + "|" : item.Id + "|";
        ntaEntityData.saveprop(propdata, newValue);

        if (isChecked) {
            // relatie(s) verwijderen
            for (const begr of item.entdatas) {
                const relations = ntaEntityData.getRelationsBetween(begr, entdata);
                for (const relation of relations) {
                    ntaEntityData.deleteRelation(relation.EntityRelationDataId);
                }
            }
        } else {
            // relatie(s) aanmaken
            for (const begr of item.entdatas) {
                ntaEntityData.createRelation(begr.EntityDataId, entdata.EntityDataId, false, true);
            }
        }
    } //-- end: toggleItemChecked -----------------------------------------------------------------


}]); //== end: ntaMeasureData =====================================================================


angular.module('projectModule')
    .service('ntaMeasure',
        ['$log', 'ntaMeasureData', 'ntaEntityData', 'ntaData', 'ntaSharedLogic', 'ListCache', 'ntaMeldingen', 'ntaDependencyValidation',
function ($log,   ntaMeasureData,   ntaEntityData,   ntaData,   ntaSharedLogic,   ListCache,   ntaMeldingen,   ntaDependencyValidation) {
    'use strict';
    const self = this;

    /// == Imports ================================================================================

    const getList = ntaMeasureData.getList;
    const getTypes = ntaMeasureData.getTypes;
    const getPropMeasureType = ntaMeasureData.getPropMeasureType;
    const getName = ntaMeasureData.getName;
    const getDefaultName = ntaMeasureData.getDefaultName;
    const sortMeasuresByName = ntaMeasureData.sortMeasuresByName;


    /// == Instance variables =====================================================================

    const _currency = new Intl.NumberFormat('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const _listCache = new ListCache();

    // Alle velden waar incl/excl BTW aan de naam toegevoegd moet worden
    const _vatPropIds = new Set([
        'MEASURE-RC_KOSTEN_PER_M2', 'MEASURE-RC_KOSTEN_TOT',                        // [RC-H]
        'MEASURE-U_KOSTEN_PER_M2', 'MEASURE-U_KOSTEN_TOT',                          // [U-N]
        'MEASURE-ZONW_KOSTEN_PER_M2', 'MEASURE-ZONW_KOSTEN_TOT',                    // [ZON-F]
        'INFIL_KOSTEN_TOT',                                                         // [INF-G]
        'VLEIDING_KOSTEN_TOT',                                                      // [VERTI-B]
        'MEASURE-COSTS_PER_SYS', 'MEASURE-COSTS_PER_M2', 'MEASURE-COSTS_TOTAAL',    // [PV-D], [HVAC-D], [WI-B], [VERL-B]
    ]);


    /// == Exports ================================================================================

    Object.assign(self, {
        // -- methods --
        create,
        copy,
        delete: remove,
        getList,
        getTypes,
        getName,
        getDefaultName,
        getTileDetails,
        canHaveSubmeasures,
        sortMeasuresByName,
        getSubmeasures,

        setBtwPropIds,
        getPropVatText,
        getVatText,

        getLogic,
    });


    /// == Initialization =========================================================================



    /// == Implementation =========================================================================

    function create(measureTypeId) {
        const measureId = ntaEntityData.create('MEASURE', -1, [], [], [
            { PropertyId: 'MEASURE_TYPE', Value: measureTypeId },
            { PropertyId: 'MEASURE_NAAM', Value: getDefaultName(measureTypeId) },
        ]);
        const measure = ntaEntityData.get(measureId);

        const propMeasureType = getPropMeasureType();
        const measureType = propMeasureType.getCode(measure);
        if (measureType && ntaData.entities[measureType.FilterValue1]) {
            const newId = ntaEntityData.create(measureType.FilterValue1, -1, [{ "OnCopy": true, "OnDelete": true, "Parent": measureId, "ParentEntityId": measure.EntityId }], [], []);
            switch (measureType.FilterValue1) {
                case 'MEASURE-RC': {
                    selectAllBegrenzingen(newId, measure, 'MEASURE-RC_BEGR_IDS');
                    break;
                }
                case 'MEASURE-U': {
                    selectAllBegrenzingen(newId, measure, 'MEASURE-U_BEGR_IDS');
                    break;
                }
                case 'MEASURE-ZONW': {
                    selectAllBegrenzingen(newId, measure, 'MEASURE-ZONW_BEGR_IDS');
                    break;
                }
            }

        } else {
            $log.error(propMeasureType.getValue(measure), ' is (nog) niet gedefinieerd!');
        }

        ntaMeasureData.sortMeasuresByName(); 

        return measure;
    } //-- end: create ----------------------------------------------------------------------------

    function copy(id) {
        var newEntdatas = ntaEntityData.copy(id);

        ntaMeasureData.sortMeasuresByName(); 

        return newEntdatas;
    } //-- end: copy --------------------------------------------------------------------------------//

    function remove(measure) {
        ntaEntityData.delete(measure.EntityDataId);

        const meldingen = ntaMeldingen.getMeldingen()
            .filter(ed => {
                const propdata = ed.PropertyDatas['MELD_SHADOWID'];
                return propdata && propdata.Value === measure.EntityDataId;
            });
        for (const melding of meldingen) {
            ntaMeldingen.delete(melding);
        }

        ntaMeasureData.sortMeasuresByName();
    } //-- end: remove ----------------------------------------------------------------------------

    function canHaveSubmeasures(measure) {
        const logic = getLogic(measure);
        return logic && (typeof logic.canHaveSubmeasures === 'function' ? logic.canHaveSubmeasures(measure) : logic.canHaveSubmeasures) || false;
    } //-- end: canHaveSubmeasures ----------------------------------------------------------------

    function getSubmeasures(measure) {
        const logic = getLogic(measure);
        const submeasures = logic && logic.getSubmeasures && logic.getSubmeasures(measure) || [];
        return _listCache.useCacheIfUnchanged('submeasures:' + measure.EntityDataId, submeasures, (a, b) => a.id === b.id && a.name === b.name && a.icon === b.icon);
    } //-- end: getSubmeasures --------------------------------------------------------------------

    function setBtwPropIds(ctrl) {
        const vatSuffix = getVatText();
        for (const propId of _vatPropIds) {
            ctrl.nameSuffixByPropId.set(propId, vatSuffix);
        }
    } //-- end: setBtwPropIds ---------------------------------------------------------------------

    function getPropVatText(prop) {
        return _vatPropIds.has(prop.Id) ? getVatText() : '';
    } //-- end: getPropVatText --------------------------------------------------------------------

    function getVatText() {
        return (ntaSharedLogic.isUtiliteit() ? 'excl.' : 'incl.') + ' BTW';
    } //-- end: getPropVatText --------------------------------------------------------------------

    function getLogic(measureOrId) {
        return ntaDependencyValidation.getMeasureLogic(measureOrId);
    } //-- end: getLogic --------------------------------------------------------------------------

    function getTileDetails(measure) {
        const measureType = getPropMeasureType().getCode(measure);
        const specificMeasure = ntaEntityData.getFirstChild(measure, measureType.FilterValue1);

        const logic = getLogic(measure);
        let lines = [];
        if (logic && logic.getTileDetails) {
            const renderer = {
                detail: renderDetail,
                value: renderValue,
                propValue: renderPropValue,
            };
            lines = logic.getTileDetails(renderer);
        } else {
            lines.push({ name: measureType.Value });
        }

        return _listCache.useCacheIfUnchanged(measure.EntityDataId, lines, (a, b) => a.name === b.name && a.value === b.value);

        // -- hulpfuncties --
        function renderDetail(propdataOrId, entdata = specificMeasure) {
            const propdata = getPropdata(propdataOrId, entdata);
            const prop = ntaData.properties[propdata.PropertyId];
            return {
                name: prop.Name,
                value: renderValue(propdata),
            };
        }

        function renderValue(propdataOrId, entdata = specificMeasure) {
            const propdata = getPropdata(propdataOrId, entdata);
            const prop = ntaData.properties[propdata.PropertyId];
            const code = prop.getCode(ntaEntityData.get(propdata.EntityDataId));
            const value = code && code.Value || propdata.Value;
            return renderPropValue(prop, value);
        }

        function renderPropValue(propOrId, value) {
            const prop = typeof propOrId === 'string' ? ntaData.properties[propOrId] : propOrId;
            if (value === undefined) {
                return value;
            } else if (prop.Unit === '€') {
                return value !== null
                    ? prop.Unit + ' ' + _currency.format(ntaSharedLogic.parseFloat(value))
                    : prop.Unit + ' --';
            } else if (value === null) {
                value = '--';
            }
            return value + ' ' + (prop.Unit || '');
        }

        function getPropdata(propdataOrId, entdata = specificMeasure) {
            return typeof propdataOrId === 'string'
                ? entdata.PropertyDatas[propdataOrId]
                : propdataOrId;
        }
    } //-- end: getTileDetails --------------------------------------------------------------------

    function selectAllBegrenzingen(entitydataId, measure, propId) {
        if (!entitydataId) return;

        const entdata = ntaEntityData.get(entitydataId); //measure-RC, of measure-U, of measure-PV enz. 
        const logic = getLogic(measure);
        const propBegrId = logic.properties[propId];
        const begrenzingItems = logic.getCodedValues(propBegrId);
        for (const begrItem of begrenzingItems) {
            ntaMeasureData.toggleItemChecked(propBegrId, entdata, begrItem);
        }
    } //-- end: selectAllBegrenzingen --------------------------------------------------------------------------

}]); //== end: ntaMeasure =========================================================================
