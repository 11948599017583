﻿angular.module('projectModule')
    .controller("FormVersionController",
        ['ntaData', '$routeParams', 'baseNtaEntityData',
function (ntaData,   $routeParams,   ntaEntityData) {
    'use strict';
    const self = this;

    self.getFormVersion = function (folder, formId, routeParamId = null, propId = null) {
        if (ntaData.switching) {
            return `/src/app/templates/switching.html?v=${ntaData.commit}`;
        }

        if (routeParamId && propId) {
            const entdataId = $routeParams[routeParamId];
            const entdata = ntaEntityData.get(entdataId);
            const codedValueType = entdata && ntaData.properties[propId].getCode(entdata);
            const typeEntityId = codedValueType && codedValueType.FilterValue1;
            const typeEntity = typeEntityId && ntaData.entities[typeEntityId];
            formId = typeEntity && typeEntity.FormId || formId;
        }

        const form = ntaData.forms[formId];
        if (!form) {
            return;
        } else if (folder === 'maatwerk') {
            if (formId.startsWith('inst')) {
                folder = 'installations';
            } else if (formId.startsWith('building')) {
                folder = 'building';
            }
        }

        const name = form.Name;
        return `/src/app/${folder}/${name}.html?v=${ntaData.commit}`;
    };

}]);
