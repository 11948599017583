angular.module('indexModule')
    .factory('HubConnection',
        ['$log', '$mdToast', 'EventSource', 'ntaAlert', 'progressCircle',
function ($log,   $mdToast,   EventSource,   ntaAlert,   progressCircle) {
    'use strict';

    const defaultOptions = {
        showProgress: true,
        progressText: 'even geduld...',
        reconnectAfterCloseMS: 250,
        showDisconnectedToast: true,
        showDisconnectedToastAfterMS: 1000,
    };

    return function HubConnection(hubUrl, options = defaultOptions) {
        const self = this;

        /// TODO: beschrijving

        /// -- Imports --------------------------------------------------------------------------------

        // signalR


        /// -- Instance variables ---------------------------------------------------------------------

        options = Object.assign({}, defaultOptions, options);

        const _eventSource = new EventSource(self);
        const _reconnectionActions = []; // een lijst van uit te voeren functies zodra we een SignalR-verbinding hebben

        let _run; // de gegevens van de meest recente keer dat ‘run’ aangeroepen is.

        const _disconnectedToast = $mdToast.simple()
            .textContent('Verbinding kwijt. Herstellen...')
            .action('×').actionKey('h').actionHint('druk op Ctrl-H om te sluiten')
            .hideDelay(0)
            .capsule(false);
        let _connectionToastTimeout; // timeout tussen het wegvallen van een verbinding en het weergeven van de toast
        let _disconnectedToastShown; // of deze connection de toast heeft weergegeven

        // blijf 45 minuten lang proberen de verbinding te herstellen: onmiddellijk, na 1, 2, 6, 10, 20 en 30 secondes, en daarna om de 30 secondes
        const _reconnectionIntervals = [0, 1000, 1000, 4000, 4000, 10000, 10000].concat(Array.from({ length: 45 * 60 / 30 - 1 }).map(x => 30000));

        const _connection = new signalR.HubConnectionBuilder()
            .withUrl(hubUrl)
            .configureLogging({
                log: (logLevel, message) => {
                    let method = 'debug';
                    switch (logLevel) {
                        case signalR.LogLevel.Critical: method = 'error'; break;
                        case signalR.LogLevel.Error: method = 'error'; break;
                        case signalR.LogLevel.Warning: method = 'warn'; break;
                        case signalR.LogLevel.Information: method = 'info'; break;
                    }
                    $log[method](`SignalR${hubUrl}:`, message);
                }
            })
            .withAutomaticReconnect(_reconnectionIntervals)
            .build();


        /// -- Exports --------------------------------------------------------------------------------

        self.run = run;
        self.runWithId = runWithId;
        self.invoke = invoke;
        self.send = send;
        self.whenConnected = whenConnected;

        // EventSource
        self.on = (eventName, handler, ...extraArgs) => {
            _eventSource.on(eventName, handler, ...extraArgs);
            _connection.on(eventName, (...args) => _eventSource.trigger(eventName, ...args));
        };
        self.off = (eventName, handler) => {
            _eventSource.off(eventName, handler);
            _connection.off(eventName, handler);
        };


        /// -- Initialization -------------------------------------------------------------------------

        // SignalR connection events
        _connection.onreconnecting(error => {
            showDisconnectedToast(options.showDisconnectedToastAfterMS);
        });
        _connection.onreconnected(onReconnected);
        _connection.onclose(error => {
            if (_run || _reconnectionActions.length) {
                if (_connectionToastTimeout) {
                    clearTimeout(_connectionToastTimeout);
                    _connectionToastTimeout = null;
                }
                showDisconnectedToast();

                setTimeout(reconnect, options.reconnectAfterCloseMS);
            } else if (_disconnectedToastShown) {
                hideDisconnectedToast();
            }
        });

        // Hub events
        _connection.on("Progress", (percentage, text) => {
            if (options.showProgress) {
                progressCircle.setProgressValue(percentage);
                if (text !== null) {
                    progressCircle.progressDescr = text;
                }
            }
        });
        _connection.on("Error", message => {
            if (options.showProgress) {
                progressCircle.setShowProgressValue(false);
            }
            rejectRun(message);
        });
        _connection.on("Finished", (...results) => {
            if (options.showProgress) {
                progressCircle.setShowProgressValue(false);
            }
            if (results.length === 1) {
                resolveRun(results[0]);
            } else {
                resolveRun(results);
            }
        });

        // SignalR-verbinding initialiseren
        if (options.showProgress) {
            progressCircle.setShowProgressValue(true, options.progressText, true);
        }
        reconnect();


        /// -- Implementation -------------------------------------------------------------------------

        async function reconnect(attempt = 0) {
            try {
                await _connection.start();
            } catch (err) {
                if (attempt === 0) {
                    showDisconnectedToast();
                    $log.warn(`Cannot start SignalR ${hubUrl} connection:`, err);
                }
                const timeoutMS = _reconnectionIntervals[attempt];
                if (typeof timeoutMS === 'number') {
                    setTimeout(() => reconnect(attempt + 1), timeoutMS);
                } else {
                    $log.error(`Really cannot start SignalR ${hubUrl} connection:`, err);
                    hideDisconnectedToast();
                    rejectRun(err);
                    await ntaAlert.showNetworkError(); // ververst de pagina na sluiten dialoog
                }
                return;
            }

            onReconnected(_connection.connectionId); // de initiële ‘connection’ telt niet als ‘reconnection’, dus we moeten zelf ‘onReconnected’ aanroepen
        } //-- end: reconnect

        async function showDisconnectedToast(afterMS = 0) {
            if (options.showDisconnectedToast) {
                if (afterMS > 0) {
                    if (_connectionToastTimeout) {
                        return; // er staat al een timeout uit, laat die maar gaan
                    } else {
                        await new Promise(resolve => {
                            _connectionToastTimeout = setTimeout(resolve, afterMS);
                        });
                    }
                }
                clearTimeout(_connectionToastTimeout);
                _connectionToastTimeout = null;

                const promise = $mdToast.show(_disconnectedToast);
                _disconnectedToastShown = true;
                promise.finally(() => {
                    _disconnectedToastShown = false;
                });
            }
        } //-- end: showDisconnectedToast

        function hideDisconnectedToast() {
            if (options.showDisconnectedToast) {
                $mdToast.hide();
            }
        } //-- end: hideDisconnectedToast

        // run: roept runWithId aan met een zelfverzonnen runId
        async function run(method, ...args) {
            return await runWithId(method, uuid.v4(), ...args);
        } //-- end: run

        // runWithId:
        async function runWithId(method, runId, ...args) {
            await whenConnected();
            if (_run) {
                $log.warn(`HubConnection.run aangeroepen voordat de vorige run klaar was! De eerste keer voor ${_run.method}(`, _run.key, `, ...), deze keer voor ${method}(`, runId, ').');
            }
            _run = {
                method,
                key: runId,
            };
            const runPromise = new Promise((resolve, reject) => {
                _run.resolve = resolve;
                _run.reject = reject;
            });
            _connection.send(method, _run.key, ...args);

            return await runPromise;
        } //-- end: run

        function resolveRun(result) {
            if (_run && _run.resolve) {
                try {
                    _run.resolve(result);
                } catch (err) {
                    $log.error(err, 'while resolving run', _run.key, 'with result', result);
                } finally {
                    if (_run.key) {
                        send("ClearLastEvent", _run.key);
                    }
                }
            }
            _run = null;
        } //-- end: resolveRun

        function rejectRun(error) {
            if (_run && _run.reject) {
                try {
                    _run.reject(error);
                } catch (err) {
                    $log.error(err, 'while rejecting run', _run.key, '(with error ', error, ')');
                } finally {
                    if (_run.key) {
                        send("ClearLastEvent", _run.key);
                    }
                }
            }
            _run = null;
        } //-- end: rejectRun

        // invoke: https://learn.microsoft.com/en-us/javascript/api/@microsoft/signalr/hubconnection#@microsoft-signalr-hubconnection-invoke
        // "Invokes a hub method on the server using the specified name and arguments.
        //  The Promise returned by this method resolves when the server indicates it has finished
        //  invoking the method. When the promise resolves, the server has finished invoking the
        //  method. If the server method returns a result, it is produced as the result of
        //  resolving the Promise."
        async function invoke(method, ...args) {
            await whenConnected();
            return await _connection.invoke(method, ...args);
        } //-- end: invoke

        // send: https://learn.microsoft.com/en-us/javascript/api/@microsoft/signalr/hubconnection#@microsoft-signalr-hubconnection-send
        // "Invokes a hub method on the server using the specified name and arguments. Does not
        //  wait for a response from the receiver. The Promise returned by this method resolves
        //  when the client has sent the invocation to the server. The server may still be
        //  processing the invocation."
        async function send(method, ...args) {
            await whenConnected();
            return await _connection.send(method, ...args);
        } //-- end: send

        /// whenConnected: voert de meegegeven functie ‘action’ uit zodra er een SignalR-verbinding aanwezig is.
        ///  De meegegeven function ‘action’ wordt aangeroepen met als parameter het connection-object.
        ///  Deze functie geeft een Promise terug; als er een ‘action’ is meegegeven, resolvet deze
        ///   met het resultaat van die functie; als er geen ‘action’ meegegeven is, dan resolvet de
        ///   Promise met het connection-object zodra er verbinding is.
        async function whenConnected(action = connection => connection) {
            if (_connection.state === 'Connected') {
                return action(_connection);
            } else {
                const promise = new Promise((resolve, reject) => {
                    _reconnectionActions.push(() => {
                        try {
                            const result = action(_connection);
                            if (result && typeof result.then === 'function') { // dan is het ook een promise-achtige, en moeten we die awaiten
                                result.then(r => resolve(r));
                                if (typeof result.catch === 'function') {
                                    result.catch(r => reject(r));
                                }
                            } else {
                                resolve(result);
                            }
                        } catch (err) {
                            reject(err);
                        }
                    });
                });
                if (_connection.state === 'Disconnected') {
                    // Herstel de verbinding indien nodig (zou niet nodig moeten zijn)
                    reconnect();
                } else {
                    $log.info(`Not reconnecting connection ${_run ? '(with runId ' + _run.key + ')' : ''} because it has state ${_connection.state}.`);
                }
                return promise;
            }
        } //-- whenConnected

        function onReconnected(connectionId) {
            hideDisconnectedToast();

            let action = _reconnectionActions.shift();
            while (action) {
                try {
                    action(connectionId);
                } catch (err) {
                    $log.error(err);
                }
                action = _reconnectionActions.shift();
            }

            if (_run && _run.key) {
                send("RepeatLastEvent", _run.key);
            }
        } //-- onReconnected



    } //-- end: HubConnection

}]);