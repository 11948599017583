﻿angular.module('projectModule')
    .factory("BuildingCalcZonesFactory",
        ['$mdDialog', 'ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaMeldingen', 'settingsMenuData', 'ntaSharedLogic', 'ntaRounding', 'ntaResults', 'ListCache',
function ($mdDialog,   ntabuilding,   ntaValidation,   ntaEntityData,   ntaMeldingen,   settingsMenuData,   ntaSharedLogic,   ntaRounding,   ntaResults,   ListCache) {
    'use strict';
    function CalcZonesLogic(ntaDependencyValidation) {
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        const calczonesLogic = this;

        const _listCache = new ListCache();
        const _isUtiliteit = ntaSharedLogic.isUtiliteit();

        /* Properties **********************************************************************************************/
        const _rzformproperties = ntabuilding.properties['RZFORM'];
        const _calczproperties = ntabuilding.properties['RZ'];
        const _unitproperties = ntabuilding.properties['UNIT'];
        const _unitrzproperties = ntabuilding.properties['UNIT-RZ'];
        const _unitrzgfproperties = ntabuilding.properties['UNIT-RZ-GF'];
        const _gruimteproperties = ntabuilding.properties['GRUIMTE'];


        calczonesLogic.rzformproperties = _rzformproperties;
        calczonesLogic.calczproperties = _calczproperties;
        calczonesLogic.unitproperties = _unitproperties;
        calczonesLogic.unitrzproperties = _unitrzproperties;
        calczonesLogic.unitrzgfproperties = _unitrzgfproperties;
        calczonesLogic.gruimteproperties = _gruimteproperties;

        /* Data *************************************************************************************************************************************************************/
        const _rzform = ntaEntityData.getFirstWithEntityId('RZFORM');

        calczonesLogic.rzform = _rzform;
        calczonesLogic.calczones = calczones;
        calczonesLogic.units = units;
        calczonesLogic.unitrzs = unitrzs;
        calczonesLogic.unitrzgfs = unitrzgfs;
        calczonesLogic.gruimtes = gruimtes;

        calczonesLogic.unitrzsByUnit = unitrzsByUnit;
        calczonesLogic.unitrzgfsByUnitRz = unitrzgfsByUnitRz;

        calczonesLogic.isHidden = isHidden;
        calczonesLogic.isReadOnly = isReadOnly;

        calczonesLogic.validate = validate;
        calczonesLogic.validateDependencies = validateDependencies;
        calczonesLogic.validateUnitRekenzoneKoppeling = validateUnitRekenzoneKoppeling;

        calczonesLogic.startFormValidation = startFormValidation;
        calczonesLogic.endFormValidation = endFormValidation;

        calczonesLogic.saveValue = saveValue;
        calczonesLogic.getCodedValues = getCodedValues;

        calczonesLogic.getListItem = getListItem;
        calczonesLogic.dependencyValidator = ntaDependencyValidation;

        calczonesLogic.deleteExcessUnits = deleteExcessUnits;
        calczonesLogic.samenvoegenGFWarning = samenvoegenGFWarning;
        calczonesLogic.checkConditionL = checkConditionL;
        calczonesLogic.checkConditionO = checkConditionO;
        calczonesLogic.checkConditionR = checkConditionR;
        calczonesLogic.checkTypePlafond = checkTypePlafond;
        calczonesLogic.checkUnitRekenzonesExist = checkUnitRekenzonesExist;

        calczonesLogic.createUnitRz = createUnitRz;
        calczonesLogic.createUnitRzGf = createUnitRzGf;

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         INITALIZIATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        deleteExcessUnits();
        deleteOrphanUnitRzGf();
        checkTypePlafond();

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        function calczones() {
            return ntaEntityData.getListWithEntityId('RZ');
        } //-- end: calczones ------------------------------------------------------------------------//

        function units() {
            return ntaEntityData.getListWithEntityId('UNIT');
        } //-- end: units ------------------------------------------------------------------------//

        function unitrzs() {
            return ntaEntityData.getListWithEntityId('UNIT-RZ');
        } //-- end: unitrzs ------------------------------------------------------------------------//

        function unitrzsByUnit(unit) {
            return ntaEntityData.getChildren(unit, 'UNIT-RZ');
        } //-- end: unitrzsByUnit ------------------------------------------------------------------------//

        function unitrzgfs() {
            return ntaEntityData.getListWithEntityId('UNIT-RZ-GF');
        } //-- end: unitrzgfs ------------------------------------------------------------------------//

        function unitrzgfsByUnitRz(unitrz) {
            return ntaEntityData.getChildren(unitrz, 'UNIT-RZ-GF');
        } //-- end: unitrzgfsByUnitRz ------------------------------------------------------------------------//

        function gruimtes() {
            //-- hier moet ik alle gruimten laten zien en niet die uit sharedlogic
            return ntaEntityData.getListWithEntityId('GRUIMTE');
        } //-- end: gruimtes ------------------------------------------------------------------------//

        function createUnitRz(unit, unitrz) {
            const newUnitRzId = ntaEntityData.create('UNIT-RZ', unitrz ? unitrz.Order : -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": unit.EntityDataId, "ParentEntityId": "UNIT" }], [], []);
            //-- elke unit-rz heeft een VLEIDINGL voor luchtdoorlaten
            ntaEntityData.create('VLEIDINGL', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newUnitRzId, "ParentEntityId": "UNIT-RZ" }], [], []);
            //-- elke unit-rz heeft een UNIT-RZ-GF
            const newUnitRz = ntaEntityData.get(newUnitRzId);
            createUnitRzGf(newUnitRz, null);

            ntaDependencyValidation.checkUnitRz(newUnitRz);
            ntaResults.checkResultTOjuliRelationsForUnitRz(newUnitRz, unit);
            ntaSharedLogic.setUnitTapwRelations(null, newUnitRzId);

            return newUnitRz;
        } //-- end: createUnitRz ------------------------------------------------------------------------//

        function createUnitRzGf(unitrz, unitrzgf) {
            ntaEntityData.create('UNIT-RZ-GF', unitrzgf ? unitrzgf.Order : -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": unitrz.EntityDataId, "ParentEntityId": "UNIT-RZ" }], [], []);
            ntaDependencyValidation.checkUnitRzGf();
        } //-- end: createUnitRzGf ------------------------------------------------------------------------//

        function getListItem(prop, entdata, list = null) {
            let listItem = null;

            if (prop && prop.Domain && prop.Domain.DomainType > 1 && entdata) {
                var tmpList = null;
                var tmpId;
                var propData = entdata.PropertyDatas[prop.Id];
                switch (prop.Id) {
                    default:
                        tmpId = propData.Value;
                        tmpList = list; // de gefilterde getCodedValue list. Op deze list moet ik checken
                        if (!tmpList) {
                            //-- anders ongefilterde codedValue list gebruiken
                            tmpList = prop.Domain.Codes;
                        }
                        break;
                }

                if (propData.Value && tmpList && tmpList.length > 0) {
                    listItem = tmpList.find(function (x) { return x.Id === tmpId; });
                }
            }

            return listItem;
        } //-- end: getListItem ------------------------------------------------------------------------//

        function deleteExcessUnits() {
            //-- Conditie [AA]
            if (!ntaSharedLogic.perGebouwEnAppartementOfUnit() && !ntaSharedLogic.voorProjectwoningen()) {
                const chkUnits = units();
                if (chkUnits.length > 1) {
                    //-- alle units verwijderen behalve de eerste
                    for (let i = 1; i < chkUnits.length; i++) {
                        const unit = chkUnits[i];
                        ntaEntityData.delete(unit.EntityDataId);
                    }

                    ntaDependencyValidation.checkUnit();
                    if (_isUtiliteit) {
                        ntaSharedLogic.initTapwUnitRzsUbouw();
                    }
                }
            }
        } //-- end: deleteExcessUnits ------------------------------------------------------------------------//

        function deleteUnheatedZones() {
            //-- Conditie [Z]
            if (ntaSharedLogic.voorProjectwoningen()) {
                for (const aor of calczones().filter(ed => ed.PropertyDatas['RZ_TYPEZ'].Value !== 'RZ')) {
                    ntaEntityData.delete(aor.EntityDataId);
                }
            }
        } //-- end: deleteUnheatedZones ---------------------------------------------------------//

        function deleteOrphanUnitRzGf() {
            for (const unitrzgf of unitrzgfs()) {
                let unitrz = ntaEntityData.getFirstParent(unitrzgf, "UNIT-RZ");
                if (!unitrz) {
                    ntaEntityData.delete(unitrzgfs.EntityDataId);
                }
            }
        } //-- end: deleteOrphanUnitRzGf ------------------------------------------------------------------------//

        function checkUnitRekenzonesExist() {
            //-- controleren of er ten minste één unit-rz per unit is als rz is van het type 'rekenzone'
            const units = ntaEntityData.getListWithEntityId('UNIT');
            for (const unit of units) {
                const unitRzs = ntaEntityData.getChildren(unit, 'UNIT-RZ');
                if (unitRzs.length === 0) {
                    createUnitRz(unit, null);
                }
            }
        } //-- end: checkUnitRekenzonesExist ------------------------------------------------------------------------//

        function checkTypePlafond() {
            let newValue = '';
            const propTypePlafond = ntabuilding.properties['RZ_TYPEPLFND'];
            for (const rz of calczones()) {
                const propdata = propTypePlafond.getData(rz);
                if (propdata.Value === null || propdata.Value === "") {
                    if (_isUtiliteit) {
                        newValue = "TYPEPLFND_GESLOTEN";
                    } else {
                        newValue = "TYPEPLFND_GEEN";
                    }
                    saveValue(propTypePlafond, rz, newValue);
                }
            }
        } //-- end: checkTypePlafond ------------------------------------------------------------------------//

        function validate(prop, propdata, entdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !entdata || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }
            const hidden = isHidden(prop, entdata);
            const readonly = isReadOnly(prop, entdata);
            let valid = ntaValidation.IsValid(calczonesLogic.form_rzunit, prop, propdata, hidden, readonly);

            switch (prop.Id) {
                case 'RZFORM_CALCUNIT': {
                    // -- conditie [Z] Als in een berekening al een AOR/AOS is ingevoerd en er wordt geswitched naar projectwoningen dan de ingevoerde AOR/AOS verwijderen.
                    if (propdata.Value === 'RZUNIT_PROJECT') {
                        deleteUnheatedZones();
                    }
                    break;
                }
                case 'RZ_TYPEZ': {
                    // we ‘misbruiken’ de validatie van dit veld om na te gaan of het juiste aantal systemen is gekoppeld aan deze rekenzone.
                    ntaSharedLogic.validateRekenzoneSystemCounts(entdata);
                    break;
                }
                case 'UNIT-RZID': {
                    validateUnitRekenzoneKoppelingByPropdata(prop, entdata);
                    break;
                }
                case 'UNIT_AANTU': {
                    checkConditionO();
                    break;
                }
                case 'UNIT-RZ-GFID':{
                    //-- Condition [N] #dialogbox 1
                    const unitrz = ntaEntityData.getFirstParent(entdata, "UNIT-RZ");
                    const rz = ntaEntityData.getFirstParent(unitrz, "RZ");
                    const unitrzgfs = unitrzgfsByUnitRz(unitrz);
                    const propValue = prop.getValue(entdata);
                    let rzOmschr = "";
                    if (rz) {
                        rzOmschr = "Rekenzone " + rz.PropertyDatas["RZ_OMSCHR"].Value + ": ";
                    }

                    const propUitzondering = ntabuilding.properties['UNITRZ_GFS_UITZ'];
                    const propValueUitzondering = propUitzondering.getValue(unitrz);
                    unitrzgfs.forEach(function (item, index) {
                        var itemValue = prop.getValue(item);
                        var GFWarning = gfcombosInfo(propValue, itemValue);
                        if (GFWarning.length === 0) { // omdraaien
                            GFWarning = gfcombosInfo(itemValue, propValue);
                        }
                        //-- Melding alleen maken als er waarschuwingen zijn en als de melding niet eerder is gemaakt
                        if (GFWarning.length > 0 && (propValueUitzondering === null || propValueUitzondering === 'GF_SAMV_UITZ4')) {
                            showGFDialog(rzOmschr + GFWarning, unitrz, entdata);
                        }
                    });

                    valid = valid && checkConditionL(unitrz);

                    checkConditionO();

                    break;
                }
                case 'GRUIMTE_UNITID': {
                    checkConditionR();
                    checkConditionO();
                    break;
                }
            }
            return valid;
        } //-- end: validate ------------------------------------------------------------------------//

        function checkConditionL(unitrz) {
            let valid = true; //-- alle unitrzgfs in deze unitrz hebben verschillende codes

            //-- conditie [L]: binnen een rekenzone unit mag een gebruiksfunctie  maar 1 x voorkomen. Geef anders melding [E004]
            const unitrzgfs = unitrzgfsByUnitRz(unitrz);
            const prop = ntabuilding.properties['UNIT-RZ-GFID'];
            const propdatasRzGfs = unitrzgfs.map(x => x.PropertyDatas['UNIT-RZ-GFID']);
            for (const unitrzgf of unitrzgfs) {
                let bVerschillendeGfCodes = true;
                const propdata = prop.getData(unitrzgf);
                if (propdata.Value) {
                    //-- komt deze gf codedValue al voor in de andere unitrzgfs die bij deze unit horen
                    bVerschillendeGfCodes = propdatasRzGfs.filter(pd => pd.Value === propdata.Value).length === 1;
                    //-- als er binnen de unitrz sprake is van verschillende codes moet valid false worden en niet meer overschreven worden met true
                    if (valid) {
                        valid = bVerschillendeGfCodes;
                    }
                }
                ntaSharedLogic.setMelding('[E004]', propdata, calczonesLogic.form_rzunit, bVerschillendeGfCodes);
            }

            return valid;
        }//-- end: checkConditionL --------------------------------------------------------------------------------//

        function checkConditionT(prop, rz) {
            const propdata = prop.getData(rz);
            const propCM = ntabuilding.properties['RZ_CM'];
            const propdataCM = propCM.getData(rz);
            let newValue = propdataCM.Value;
            //-- [T] bij bouwwijze Bijlage B moet CM ingevuld worden, anders is CM n.v.t.
            if (propdata.Value !== 'CONSTR_METH10') {
                newValue = 'n.v.t.';
            } else if (newValue === 'n.v.t.') {
                newValue = "";
            }
            saveValue(propCM, rz, newValue);
        } //-- end: checkConditionT -------------------------------------------------------------//

        function checkConditionY() {
            //-- Conditie [Y]
            for (const rz of calczones()) {
                const rzType = rz.PropertyDatas["RZ_TYPEZ"];
                if (rzType.Value === "RZ" && !ntaEntityData.getFirstChild(rz, "UNIT-RZ")) {
                    ntaMeldingen.warning("[W014]");
                    return;
                }
            }
        } //-- end: checkConditionY -------------------------------------------------------------//

        function checkConditionR() {
            //-- Conditie [R]
            for (const gruimte of gruimtes()) {
                let bValid = true;
                if (_isUtiliteit && ntaEntityData.getParents(gruimte, "UNIT-RZ-GF").length === 0) {
                    bValid = false;
                }
                if (!_isUtiliteit && ntaEntityData.getParents(gruimte, "RZ").length === 0) {
                    bValid = false;
                }
                const propdata = gruimte.PropertyDatas["GRUIMTE_OMSCHR"];
                ntaSharedLogic.setMelding("[E011]", propdata, calczonesLogic.form_rzunit, bValid);
            }
        } //-- end: checkConditionR ------------------------------------------------------------------------//

        function checkConditionO() {
            //-- Conditie [O] if calculation zone contains Z14 ID = 9 (sportfunctie): check if Ag (Z15) ≥ 0,9 * Ag;z+com;i (formula 6.1g) or
            //-- if Ag(Z15) < 0, 1 * Ag; z + com; i(formula 6.1g).If not than show[E005]
            for (const unitrzgf of unitrzgfs()) {
                const propdata = unitrzgf.PropertyDatas["UNIT-RZ-GFID"];
                let bValid = true;
                if (propdata.Value === "GF_SPORT") {
                    //-- Ag van gf_sport
                    let dAgSport = ntaSharedLogic.parseFloat(unitrzgf.PropertyDatas["UNIT-RZ-GFAG"].Value);

                    bValid = !!dAgSport;

                    if (bValid) {
                        let dAgrzcommTot = 0;
                        let inclGruimten = !ntaSharedLogic.perGebouwEnAppartementOfUnit();//-- bij volledig gebouw dooreken moeten de gruimtens mee. Per unit rekenen is zonder gruimten
                        if (inclGruimten) {
                            dAgSport = dAgSport + ntaSharedLogic.AgCommOfEntdata(unitrzgf);
                        }
                        //-- AgSport vermenigvuldigen met het aantal units, want AgrzcommTot is ook x het aantal units
                        const n = ntaSharedLogic.getNGetalByUnitRz(unitrzgf);
                        dAgSport = n * dAgSport;

                        //-- alle unitrz ophalen die een link hebben met de rekenzone
                        const unitrz = ntaEntityData.getFirstParent(unitrzgf, "UNIT-RZ");
                        //-- opzoek naar de relatie van deze unitrz met zijn rekenzone;
                        if (unitrz) {
                            const rz = ntaEntityData.getFirstParent(unitrz, 'RZ');
                            const unitrzids = ntaEntityData.getChildIds(rz, 'UNIT-RZ');

                            dAgrzcommTot = ntaSharedLogic.AgSumOfUnitRZs(unitrzids, inclGruimten, true); //-- dit is formula 6.1g met gruimten en formula 6.1k zonder gruimten
                        }

                        if (!(dAgSport >= 0.9 * dAgrzcommTot || dAgSport < 0.1 * dAgrzcommTot)) {
                            bValid = false;
                        }
                    }
                }
                ntaSharedLogic.setMelding('[E005]', propdata, calczonesLogic.form_rzunit, bValid);
            }
        } //-- end: checkConditionO ------------------------------------------------------------------------//

        function saveValue(prop, entdata, newValue) {
            ntaSharedLogic.saveValue(prop, entdata, newValue, calczonesLogic);
        } //-- end: saveValue ------------------------------------------------------------------------//

        function isHidden(prop, entdata = null) {
            //-- VO 2020-10-01: deze isHidden wordt ook gebruikt voor de tabelheader. In andere factory's heet dit propertyHeaderIsHidden. Voor
            //-- bouwkundig doen we dit in een, vandaar anders checken op entdata.
            if (!prop) return false;
            if (entdata && entdata.BuildingId !== ntabuilding.buildingId) return false;

            const propdata = entdata && prop.getData(entdata);

            const gebouwType = ntaSharedLogic.getGebouwType();
            const calcUnit = ntaSharedLogic.getCalcUnit();

            let showit = true;
            let relevant = null;

            //-- nu per property kijken wat de conditie is
            switch (prop.Id) {
                case 'RZ_CM':
                    {   //-- check of "intw cap" data [T]
                        showit = isBouwwijzeBijlageB() && calcUnit !== 'RZUNIT_PROJECT'; //[AF]
                        break;
                    }
                case 'RZ_BOUWLG': {
                    showit = calcUnit !== 'RZUNIT_PROJECT'; //[AF]
                    break;
                }
                case 'UNIT-RZCM':
                    {
                        showit = isBouwwijzeBijlageB() && calcUnit === 'RZUNIT_PROJECT'; //[AG]
                        break;
                    }
                case 'RZ_TYPEPLFND':
                    {   //-- check of "intw cap" data
                        showit = false;
                        if (_isUtiliteit) {
                            // [S]
                            showit = calczones().some(rz => !isBouwwijzeBijlageB(rz));
                        }

                        break;
                    }
                case 'RZFORM_CALCUNIT':
                    {
                        showit = !['TGEB_APP', 'TGEB_UTILIT', 'TGEB_UTILUNIT'].includes(gebouwType); // [U1]
                        //-- altijd relevant
                        relevant = true;

                        ntaResults.setUnitResultRelevancy();
                        break;
                    }
                case 'RZFORM_OPEN':
                    {
                        showit = false;
                        break;
                    }
                case 'RZFORM_AANTWOONF':
                    {
                        showit = gebouwType === "TGEB_APPGEB" && calcUnit === "RZUNIT_GEB";
                        break;
                    }
                case 'UNIT_TYPEWON':
                    {
                        showit = !(gebouwType === "TGEB_UTILIT" || gebouwType === "TGEB_APPGEB" || gebouwType === "TGEB_UTILUNIT" || gebouwType === "TGEB_APP");
                        break;
                    }
                case 'UNIT_POS':
                    {   // conditie [D]
                        showit = gebouwType === "TGEB_UTILUNIT" ||  //-- unit in utiliteitsgebouw
                            gebouwType === "TGEB_APP" ||       //-- appartement (voor bestaande bouw)
                            (gebouwType === "TGEB_UTILIT" && calcUnit === "RZUNIT_GEBUNIT") ||
                            (gebouwType === "TGEB_APPGEB" && calcUnit === "RZUNIT_GEBAPP");
                        break;
                    }
                case 'UNIT_TYPEGEB':
                    {
                        showit = (gebouwType === "TGEB_UTILIT" && calcUnit !== "RZUNIT_GEBUNIT") || (gebouwType === "TGEB_APPGEB" && calcUnit !== "RZUNIT_GEBAPP");
                        break;
                    }
                case 'UNIT_AANTU':
                    {
                        showit = gebouwType === "TGEB_UTILIT" && conditieH(); // [H]
                        break;
                    }
                case 'UNIT_AANTA':
                    {
                        showit = gebouwType !== "TGEB_UTILIT" && conditieH(); // [H]
                        break;
                    }
                case 'UNIT-RZBLAAG':
                    {
                        showit = conditieH(); // [H]
                        break;
                    }
                case 'UNIT-RZAG':
                    {
                        showit = !(gebouwType === "TGEB_UTILIT" || gebouwType === "TGEB_UTILUNIT");
                        break;
                    }
                case 'UNIT-RZ-GFAG':
                    {
                        showit = gebouwType === "TGEB_UTILIT" || gebouwType === "TGEB_UTILUNIT";
                        break;
                    }
                case 'UNIT-RZ-GFID':
                    {
                        showit = gebouwType === "TGEB_UTILIT" || gebouwType === "TGEB_UTILUNIT";
                        relevant = true;
                        break;
                    }
                case 'UNITRZ_GFS_UITZ': {
                    showit = false;
                    break;
                }
                case 'GRUIMTE_AV_INVOER': {
                    showit = ntaSharedLogic.perGebouw();
                    break;
                }
            }

            // standaard is relevant gelijk aan visible
            if (relevant === null) relevant = showit;

            if (propdata) {
                //--- voor de isHidden van de header van tabellen is de relevantie zetten niet nodig.
                ntaEntityData.setPropdataRelevancy(propdata, relevant, calczonesLogic.form_rzunit);
                ntaEntityData.setPropdataVisibility(propdata, showit);
            }
            return !showit;
        } //-- end: isHidden ------------------------------------------------------------------------//

        function isBouwwijzeBijlageB(rz) {
            return rz
                ? getBouwwijze(rz) === 'CONSTR_METH10'
                : calczones().some(isBouwwijzeBijlageB);
        }

        function getBouwwijze(rz) {
            const propId = ntabuilding.ntaVersionId >= 200
                ? "RZ_BOUWW_W"
                : "RZ_BOUWW";
            return rz.PropertyDatas[propId].Value;
        }

        function conditieH() {
            //-- [H] show column if Z23=per gebouw en per 'appartement' 'unit' OR if Z23='voor projectwoningen'
            const showit = ntaSharedLogic.perGebouwEnAppartementOfUnit()
                || ntaSharedLogic.voorProjectwoningen();

            return showit;
        }

        function saveKoppelingUnitEnRekenzone(propdata, entdata) {
            //-- koppeling zoeken met rekenzone (er kan maar één relatie zijn tussen RZ <<-->> UNIT-RZ zijn)
            const relations = ntaEntityData.getParentRelations(entdata, 'RZ');

            //-- Als er al een relatie met deze rekenzone bestaat, hoeft er niets te gebeuren
            if (relations.length === 1 && relations[0].Parent === propdata.Value) {
                return relations[0];
            }

            //-- oude koppeling verwijderen conditie [KAA]
            for (const relation of relations) {
                ntaEntityData.deleteRelation(relation.EntityRelationDataId);
            }

            //-- koppeling toevoegen; zowel verwijderen als kopiëren moeten cascaden
            return ntaEntityData.createRelation(propdata.Value, entdata.EntityDataId, true, true);
        } //-- end: saveKoppelingOpwekkerEnRekenzone ---------------------------------------------------------------------//

        function isReadOnly(prop, entdata) {
            let bReadOnly = false;

            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return bReadOnly;
            }

            switch (prop.Id) {
                case 'RZ_CM':
                case 'RZ_TYPEPLFND':
                case 'RZ_BOUWW': // Z05
                case 'RZ_BOUWW_VL': // Z27
                case 'RZ_BOUWW_W': // Z28
                case 'RZ_BOUWLG':
                    {
                        const typez = entdata.PropertyDatas["RZ_TYPEZ"].Value;

                        //-- dus type AOR of AOS of geen Bouwwijze Bijlage B
                        bReadOnly = typez !== "RZ";
                        //-- bij RZ_CM en RZ_TYPEPLFND ook nog checken op bouwwijze.
                        if (!bReadOnly) {
                            //-- dan is er sprake van een rekenzone en moet ik de bouwwijze checken
                            if (prop.Id === 'RZ_CM') {
                                bReadOnly = !isBouwwijzeBijlageB(entdata);
                            }
                            if (prop.Id === 'RZ_TYPEPLFND') {
                                bReadOnly = isBouwwijzeBijlageB(entdata);
                            }
                        }

                        if (bReadOnly) {
                            //-- wanneer ik readonly ben voor deze velden moet ik nvt laten zien
                            //-- in principe wordt dit na de refactoring geregeld in de validateDependencies, maar bij oude project is dit nog niet zo.
                            //-- dus voor de weergave wordt de propdata.Value ff op n.v.t. gezet, maar het wordt niet opgeslagen
                            const propdata = entdata.PropertyDatas[prop.Id];
                            propdata.Value = 'n.v.t.'
                        }
                        break;
                    }
                case 'UNIT-RZCM':
                    {
                        //get RZ behorende bij UNIT-RZ
                        //get Bouwwijze van RZ
                        //if Bouwwijze van RZ !== CONSTR_METH10 dan Readonly
                        const rz = ntaEntityData.getFirstParent(entdata, "RZ");
                        if (rz) {
                            //-- als unit-rz net is aangemaakt en er zijn meerdere rekenzones heeft UNIT-RZ nog geen koppeling met RZ, dus ff checken of RZ bestaat.
                            bReadOnly = !isBouwwijzeBijlageB(rz);
                            const isNvt = prop.getValue(entdata) === 'n.v.t.';
                            if (bReadOnly !== isNvt) {
                                if (bReadOnly) {
                                    saveValue(prop, entdata, 'n.v.t.');
                                } else {
                                    saveValue(prop, entdata, '');
                                }
                            }
                        }
                        break;
                    }
            }

            return bReadOnly;
        } //-- end: isReadOnly ------------------------------------------------------------------------//

        function getCodedValues(prop, entdata) {
            let typesList = [];

            if (!entdata || !prop || entdata.BuildingId !== ntabuilding.buildingId) {
                return typesList;
            }

            let gebType = ntaSharedLogic.getGebouwType();
            switch (prop.Id) {
                case 'RZ_TYPEZ': {
                    const lineaireWarmtebruggenForfaitair = settingsMenuData.getSetting('SETTINGS_THBRUG').Value === "True";
                    const calcUnit = ntaSharedLogic.getCalcUnit();
                    typesList = ntaValidation.codedValues(prop)
                        .filter(cv => !['AOR', 'AOS'].includes(cv.Id) || (!lineaireWarmtebruggenForfaitair && calcUnit !== 'RZUNIT_PROJECT'));
                    break;
                }
                case 'RZFORM_CALCUNIT': {
                    typesList = ntaValidation.codedValues(prop, gebType, true, gebType, false)
                        .filter(cv => cv.Id !== 'RZUNIT_PROJECT' || !(['TGEB_APPGEB', 'TGEB_UTILIT', 'TGEB_APP', 'TGEB_UTILUNIT'].includes(gebType) || ntabuilding.ntaVersionId < 100)) // [A4]
                        .filter(cv => cv.Id !== 'RZUNIT_GEBAPP' || gebType === 'TGEB_APPGEB') // [U2]
                        //-- MCO 2022-05-24: onderstaande regel pas herstellen als we ook bij utiliteitsbouw kunnen rekenen per gebouw en per unit.
                        //.filter(cv => cv.Id !== 'RZUNIT_GEBUNIT' || gebType === 'TGEB_UTILIT') // [U2]
                    break;
                }
                case 'UNIT_POS': {
                    if (gebType === "TGEB_UTILIT" || gebType === "TGEB_UTILUNIT") {
                        gebType = "UTIL";
                    }
                    typesList = ntaValidation.codedValues(prop, gebType, true, gebType, false);
                    break;
                }
                case 'UNIT_TYPEGEB': {
                    typesList = ntaValidation.codedValues(prop, "", true, gebType, false);
                    break;
                }
                case 'UNIT-RZID': {
                    typesList = getAvailableCalczones();
                    break;
                }
                case 'RZ_BOUWW':     // Z05
                case 'RZ_BOUWW_VL':  // Z27
                case 'RZ_BOUWW_W': { // Z28
                    typesList = ntaValidation.codedValues(prop);

                    if (ntaSharedLogic.isBasisOpname()) { // [AC]
                        typesList = typesList.filter(cv => cv.Id !== 'CONSTR_METH10');
                    }
                    break;
                }
                default: {
                    typesList = ntaValidation.codedValues(prop);
                    break;
                }
            }

            ntaSharedLogic.checkPropdataInList(prop, entdata, typesList, calczonesLogic);
            return typesList;
        } //-- end: getCodedValues ------------------------------------------------------------------------//

        function getAvailableCalczones() {
            const rzCodedValues = calczones()
                .filter(ed => ed.PropertyDatas["RZ_TYPEZ"].Value === "RZ")
                .map(rekenzone => ({
                    "Id": rekenzone.EntityDataId,
                    "Value": rekenzone.PropertyDatas['RZ_OMSCHR'].Value,
                }));

            //-- Haal een evt. gecachete versie van de lijst voor rekenzone-unit op. Als de huidige lijst niet gewijzigd is, dan geven we de oude
            //-- lijst terug, zodat Angular kan zien dat er niets gewijzigd is.
            return _listCache.useCacheIfUnchanged('rekenzones', rzCodedValues, (a, b) => a.Id === b.Id && a.Value === b.Value);
        } //-- end: getAvailableCalczones ------------------------------------------------------------------------//

        function validateUnitRekenzoneKoppeling() {
            const prop = ntabuilding.properties['UNIT-RZID'];
            for (const unit of units()) {
                const unitrzs = unitrzsByUnit(unit);

                //-- Zet eerst alles op true
                for (const unitrz of unitrzs) {
                    const propdata_check = prop.getData(unitrz);
                    ntaSharedLogic.setMelding('[E003]', propdata_check, calczonesLogic.form_rzunit, true);
                }

                let rzIdUsed = [];
                for (const unitrz of unitrzs) {
                    const rz_check = ntaEntityData.getFirstParent(unitrz, "RZ");
                    //-- als de unit-rz nog niet gekoppeld is aan een RZ hoef ik geen check te doen. Dit is het geval bij nieuw aanmaken van UNIT-RZ
                    if (rz_check) {
                        const propdata_check = prop.getData(unitrz);
                        if (!rzIdUsed.includes(rz_check.EntityDataId)) {
                            //-- als deze rzId nog niet voorkomt, dan toevoegen. 1x mag namelijk
                            rzIdUsed.push(rz_check.EntityDataId);
                        } else {
                            //-- als hij alvoorkwam moet bij deze unitrz propdata de melding komen.
                            ntaSharedLogic.setMelding('[E003]', propdata_check, calczonesLogic.form_rzunit, false);
                        }
                    }

                }
            }
            ntaResults.checkResultTOjuliRelations(); // check of aanmaken resultaat entiteiten TOjuli en GTO nodig is.

        } //-- end: validateUnitRekenzoneKoppeling ------------------------------------------------------------------------//

        function validateUnitRekenzoneKoppelingByPropdata(prop, entdata) {
            //-- VO 2020-10-26: Hier komt ik wanneer ik specifiek de UNIT-RZID gewijzigd heb. Dan weet ik welke propdata ik moet controleren. Dat is anders dan bij de
            //-- validateUnitRekenzoneKoppeling. Daar moet ik alle UNIT-RZ af en wanneer ik een RZ tegenkom die al gebruikt is, moet voor die betreffende UNIT-RZID een melding komen.
            //-- dat is bijvoorbeeld wanneer er een kopie van een rekenzone wordt gemaakt, of een rekenzone verwijderd wordt of een type rekenzone verandert in een AOR of AOS.
            if (prop.Id !== 'UNIT-RZID')
                return;

            const propdata = prop.getData(entdata); //-- nodig om de melding te zetten bij de juist propdata. De value van propdata wordt niet gebruikt. Alleen de relatie tussen UNIT-RZ <--> RZ.
            const unit = ntaEntityData.getFirstParent(entdata, "UNIT");
            const rz = ntaEntityData.getFirstParent(entdata, "RZ");

            if (unit && rz) {
                // de melding ‘Invoer is verplicht’ weghalen, want die invoer is er sowieso als we hier zijn gekomen.
                ntaSharedLogic.setMelding('[D001]', propdata, calczonesLogic.form_rzunit, true);

                const unitrzs = unitrzsByUnit(unit);

                //-- voor al deze unitrzs kijken of ze een andere rekenzone hebben.
                //-- Zet eerst alles op true
                let bValidRzId = true;
                for (const unitrz of unitrzs) {
                    const propdata_check = prop.getData(unitrz);
                    ntaSharedLogic.setMelding('[E003]', propdata_check, calczonesLogic.form_rzunit, bValidRzId);
                }

                //-- Doorloop eerst huidige unitrz
                for (const unitrz of unitrzs) {
                    if (entdata.EntityDataId !== unitrz.EntityDataId) {
                        const rz_check = ntaEntityData.getFirstParent(unitrz, "RZ");
                        if (rz_check && rz_check.EntityDataId === rz.EntityDataId) {
                            //-- de gekozen rekenzone was al gebruikt, dus valid=false voor de propertydata
                            bValidRzId = false;
                            break;
                        }
                    }
                }
                ntaSharedLogic.setMelding('[E003]', propdata, calczonesLogic.form_rzunit, bValidRzId);

                //-- Doorloop alleen de rest als bValidRzId true is gebleven om meerdere dezelfde meldingen te voorkomen
                if (bValidRzId) {
                    for (const unitrz of unitrzs) {
                        const rz_check = ntaEntityData.getFirstParent(unitrz, "RZ");
                        const propdata_check = prop.getData(unitrz);
                        //-- huidige unitrz is hierboven al gecheckt
                        if (rz_check && entdata.EntityDataId !== unitrz.EntityDataId) {
                            for (const unitrz_check of unitrzs) {
                                const rz_check_in_check = ntaEntityData.getFirstParent(unitrz_check, "RZ");
                                if (rz_check_in_check && unitrz.EntityDataId !== unitrz_check.EntityDataId) {
                                    if (rz_check.EntityDataId === rz_check_in_check.EntityDataId) {
                                        bValidRzId = false;
                                        break;
                                    }
                                }

                            }
                            ntaSharedLogic.setMelding('[E003]', propdata_check, calczonesLogic.form_rzunit, bValidRzId);
                        }
                    }
                }
            }
            ntaResults.checkResultTOjuliRelationsForUnitRz(entdata, unit); // check of aanmaken resultaat entiteiten TOjuli en GTO nodig is.
        } //-- end: validateUnitRekenzoneKoppelingByPropdata ------------------------------------------------------------------------//

        function setCmUnitRekenzones(rzs) {
            if (!isBouwwijzeBijlageB() || !ntaSharedLogic.voorProjectwoningen()) {
                for (const rz of rzs) {
                    const unitRzs = ntaEntityData.getChildren(rz, "UNIT-RZ");
                    const rzCm = rz.PropertyDatas["RZ_CM"].Value;
                    if (rzCm !== "n.v.t.") {
                        let AgRzTot = 0;
                        //-- alle unit-rz's ophalen om de Cm van de unit-rz aan te passen
                        const AgUnitRzs = [];
                        for (const unitRz of unitRzs) {
                            const Agrz = ntaSharedLogic.AgSumOfUnitRZs([unitRz.EntityDataId], false, false)
                            let n = 1;
                            if (ntaSharedLogic.perAppartementOfUnit()) {
                                n = ntaSharedLogic.getNGetalByUnitRz(unitRz);
                            } else {
                                //-- anders aantal app of units ophalen bij RZFORM_AANTWOONF als het woningbouw is
                                if (ntaSharedLogic.getGebouwType() === "TGEB_APPGEB") {
                                    n = parseInt(_rzform.PropertyDatas["RZFORM_AANTWOONF"].Value) || 0;
                                }
                            }

                            AgUnitRzs.push({ unitRz, Agrz });
                            AgRzTot += n * Agrz;
                        }
                        //-- de Cm van de unit-rekenzone bepalen
                        if (AgRzTot > 0) {
                            const propCmUnitRz = ntabuilding.properties["UNIT-RZCM"];
                            for (const item of AgUnitRzs) {
                                const unitRzCm = ntaRounding.roundAndAddZerosNewValue(propCmUnitRz, rzCm * item.Agrz / AgRzTot);
                                ntaEntityData.saveprop(item.unitRz.PropertyDatas[propCmUnitRz.Id], unitRzCm);
                            }
                        }
                    } else {
                        for (const unitRz of unitRzs) {
                            ntaEntityData.saveprop(unitRz.PropertyDatas["UNIT-RZCM"], null);
                        }
                    }
                    }
            }
        } //-- setCmUnitRekenzones

        function switchGebrWarmteProd(newRekenzone, oldRekenzone) {
            /// hier moet de relatie tussen entiteit GEBR-RZ-WARMTEPROD (P) EN GEBR-RZ-WARMTEPROD-GF (C)
            /// van de oude rekenzone (RZ en GEBR-RZ-WARMTEPROD hebben ook een relatie) omgezet worden
            /// naar een relatie tussen de GEBR-RZ-WARMTEPROD van de nieuwe rekenzone met de GEBR-RZ-WARMTEPROD-GF
            /// waar de relatie net van verwijderd is.

            if (newRekenzone != oldRekenzone) {
                const grwOld = ntaEntityData.getFirstChild(oldRekenzone, "GEBR-RZ-WARMTEPROD");
                const grwNew = ntaEntityData.getFirstChild(newRekenzone, "GEBR-RZ-WARMTEPROD");

                const grwf = ntaEntityData.findEntity(newRekenzone, "UNIT-RZ.UNIT-RZ-GF.GEBR-RZ-WARMTEPROD-GF");

                if (grwOld && grwNew && grwf) {
                    const relation = ntaEntityData.getRelation(grwOld, grwf);
                    ntaEntityData.deleteRelation(relation.EntityRelationDataId);
                    ntaEntityData.createRelation(grwNew.EntityDataId, grwf.EntityDataId, true, true);
                }
            }
        }//-- switchGebrWarmteProd

        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            switch (prop.Id) {
                case 'UNIT-RZID':
                    {
                        const propdata = prop.getData(entdata);
                        if (propdata.Value) {
                            //-- bewaar de oude rekenzone om de GEBR-RZ-WARMTEPROD goed te switchen
                            const oldRekenzone = ntaEntityData.getFirstParent(entdata, "RZ");
                            //-- zet de nieuwe relatie tussen de UNIT-RZ en de geselecteerde RZ
                            const relation = saveKoppelingUnitEnRekenzone(propdata, entdata);
                            //-- en dan nu nog een keer de validate aanroepen om de gebruiker te waarschuwen als hij meerdere keren dezelfde zone gekozen heeft.
                            validateUnitRekenzoneKoppelingByPropdata(prop, entdata);
                            //-- zorgen dat de interne warmtelast ook weer goed staat
                            const rekenzone = ntaEntityData.get(relation.Parent);
                            setCmUnitRekenzones([rekenzone]);

                            switchGebrWarmteProd(rekenzone, oldRekenzone);
                        }
                        break;
                    }
                case 'UNIT-RZ-GFID':
                    {
                        const unitrz = ntaEntityData.getFirstParent(entdata, 'UNIT-RZ');
                        if (!samenvoegenGFWarning(unitrz)) { //-- geen error, dus verwijderen melding.
                            const propUitz = ntabuilding.properties['UNITRZ_GFS_UITZ'];
                            saveValue(propUitz, unitrz, null);
                        }
                        //-- normaliter hoort deze check in de validate, maar ik weet na het samenvoegen pas wat de nieuwe verdeling gebruiksfuncties is, vandaar ook hier.
                        checkConditionO();
                        break;
                    }
                case 'RZ_TYPEZ':
                    {
                        //-- als het type van de rekenzone is gewijzigd (van AOS -> RZ of RZ -> AOR etc.) moeten relaties van de begrenzingen (vlakken) aangepast worden. Bij
                        //-- AO(R/S) 'hangen' deze onder de rekenzone (entiteit RZ) bij type RZ 'hangen' deze onder de unit-rekenzone (entiteit UNIT-RZ). Om de begrenzingen
                        //-- te behouden moeten dit dus aangepast worden.

                        const propdata = prop.getData(entdata);
                        //-- ik ga eerst kijken of er UNIT-RZ relaties zijn (dan was deze propertydata eerst "RZ");
                        const unitRzs = ntaEntityData.getChildren(entdata, 'UNIT-RZ');
                        if (unitRzs.length > 0 && propdata.Value !== 'RZ') {
                            //-- dan moet ik de begrenzingen van iedere UNIT-RZ ophalen.
                            for (const unitRz of unitRzs) {
                                //-- de begrenzingen van deze Unit-RZ moet ik gaan koppelen aan de entdata.
                                const relBegrenzingen = ntaEntityData.getChildRelations(unitRz, 'BEGR');
                                for (const rel of relBegrenzingen) {
                                    //-- eerst koppeling toevoegen; zowel verwijderen als kopiëren moeten cascaden
                                    ntaEntityData.createRelation(entdata.EntityDataId, rel.Child, true, true);
                                    //-- daarna oude koppeling verwijderen
                                    ntaEntityData.deleteRelation(rel.EntityRelationDataId);
                                }
                                //-- nu deze unit-rz verwijderen want die is niet relevant meer
                                ntaEntityData.delete(unitRz.EntityDataId);
                            }
                        } else {
                            //-- dan kijken of deze zone misschien direct begrenzingen aan zich heeft 'hangen'
                            const begrenzingen = ntaEntityData.getChildren(entdata, 'BEGR');
                            if (begrenzingen.length > 0 && propdata.Value === 'RZ') {
                                //-- dan ben ik een rekenzone geworden en moet de begrenzingen weg aan deze rekenzone weg.
                                for (const begrenzing of begrenzingen) {
                                    ntaEntityData.delete(begrenzing.EntityDataId);
                                }
                            }
                            if (propdata.Value === 'RZ') {
                                checkUnitRekenzonesExist();
                            }
                        }

                        //-- ook moet na een switch tussen entiteit RZ met type (RZ) en entiteit RZ met type (AOR/AOS) de relaties met installaties verwijderd worden.
                        //-- dit moet ook als alle UNIT-RZ's met deze rekenzone al weggegooid zijn. Deze relaties zijn UNIT-RZ onafhankelijk.
                        const installationsMetRekenzone = ntaSharedLogic.getInstallationsRekenzone(entdata.EntityDataId);
                        for (const inst of installationsMetRekenzone) {
                            const rel = ntaEntityData.getRelation(inst, entdata)
                            if (rel) {
                                ntaSharedLogic.deleteRekenzoneSysteemRelation(rel.EntityRelationDataId, rel.Child, rel.Parent, rel.ParentEntityId);
                            }
                        }
                        //-- ook nog controleren of er een tapw-opwek relatie is. Dit kan wanneer de Rekenzone gebruikt is als opstelplaats.
                        const relationOpstelplaats = ntaEntityData.getChildRelations(entdata, 'TAPW-OPWEK');
                        if (relationOpstelplaats.length > 0) {
                            ntaEntityData.deleteRelation(relationOpstelplaats[0].EntityRelationDataId);
                        }

                        if (propdata.Value === 'RZ') {
                            ntaResults.updateForParents(entdata);
                        } else {
                            ntaResults.updateForDeletedParent(entdata);
                        }

                        break;
                    }
                case 'RZ_BOUWW': { // Z05
                    checkConditionT(prop, entdata);
                    break;
                }
                case 'RZ_BOUWW_VL':  // Z27
                case 'RZ_BOUWW_W': { // Z28
                    // [A10] bouwwijze ‘volgens bijlage B’ moet altijd óf voor beide gelden, of voor geen van beide.
                    const VOLGENS_BIJLAGE_B = 'CONSTR_METH10';
                    const propdata = prop.getData(entdata);
                    const otherPropId = prop.Id === 'RZ_BOUWW_VL' ? 'RZ_BOUWW_W' : 'RZ_BOUWW_VL';
                    const otherPropdata = entdata.PropertyDatas[otherPropId];

                    if (propdata.Value === VOLGENS_BIJLAGE_B) {
                        saveValue(otherPropId, entdata, propdata.Value);

                    } else if (otherPropdata.Value === VOLGENS_BIJLAGE_B) {
                        saveValue(otherPropId, entdata, null);
                    }

                    checkConditionT(prop, entdata);
                    break;
                }
                case 'RZ_CM':
                    {
                        //-- na het aanpassen van deze waarde moet dit ook gewijzigd worden in de UNIT-RZCM die oppervlakte gewogen geldt voor de unit rekenzone
                        setCmUnitRekenzones([entdata]);
                        break;
                    }
                case 'RZFORM_CALCUNIT':
                    {
                        ntaResults.setUnitResultRelevancy();

                        //-- Cm voor UnitRekenzones is afhankelijk van aantal units/app of aantal woningen
                        const rzs = ntaSharedLogic.rekenzones();
                        setCmUnitRekenzones(rzs);

                        //set active building ivm resultaten
                        if (prop.getValue(entdata) == 'RZUNIT_GEB') {
                            ntabuilding.activeBerekening.Id = ntaEntityData.getFirstWithEntityId('GEB').EntityDataId;
                        }

                        //check of alle units een relatie hebben naar de resultaat entiteiten
                        ntaResults.checkResultEntities();

                        break;
                    }
                case 'RZFORM_AANTWOONF':
                    {
                        //-- Cm voor UnitRekenzones is afhankelijk van aantal units/app of aantal woningen
                        const rzs = ntaSharedLogic.rekenzones();
                        setCmUnitRekenzones(rzs);

                        break;
                    }
                case 'UNIT_AANTA':
                case 'UNIT_AANTU':
                    {
                        //-- Cm voor UnitRekenzones zijn Ag en dus ook het aantal units/appartementen afhankelijk
                        const rzs = ntaEntityData.findEntities(entdata, "UNIT-RZ.^RZ");
                        setCmUnitRekenzones(rzs);

                        break;
                    }
                case 'UNIT-RZAG':
                    {
                        //-- Cm voor UnitRekenzones zijn Ag afhankelijk
                        const rz = ntaEntityData.findEntity(entdata, "^RZ");
                        setCmUnitRekenzones([rz]);
                        break;
                    }
                case 'UNIT-RZ-GFAG':
                    {
                        //-- Cm voor UnitRekenzones zijn Ag afhankelijk
                        const rz = ntaEntityData.findEntity(entdata, "^UNIT-RZ.^RZ");
                        setCmUnitRekenzones([rz]);

                        ntaSharedLogic.initTapwUnitRzsUbouw();
                        checkConditionO();
                        break;
                    }
                case 'GRUIMTE_AG':
                    {
                        if (_isUtiliteit) {
                            ntaSharedLogic.initTapwUnitRzsUbouw();
                            checkConditionO();
                        }
                        break;
                    }
            }
        } //-- end: validateDependencies ------------------------------------------------------------------------//

        function startFormValidation() {
            return ntaSharedLogic.startFormValidation(getAllEntDatas(), calczonesLogic);
        } //-- end: startFormValidation ------------------------------------------------------------------------//

        function endFormValidation() {
            checkConditionY();
            return ntaSharedLogic.endFormValidation(getAllEntDatas(), calczonesLogic);
        } //-- end: endFormValidation ------------------------------------------------------------------------//

        function getAllEntDatas() { // Geeft alle entdatas in één array terug
            return []
                .concat(_rzform)
                .concat(calczones())
                .concat(units())
                .concat(unitrzs())
                .concat(unitrzgfs())
                .concat(gruimtes())
                .filter(entdata => entdata) // filter alle nulls en undefineds eruit
                ;
        } //-- end: getAllEntDatas ------------------------------------------------------------------------//

        function showGFDialog(GFWarning, unitrz, unitrzgf) {
            $mdDialog.show({
                locals: { GFWarning: GFWarning, unitrz: unitrz, unitrzgf: unitrzgf },
                controller: GFDialogController,
                templateUrl: '/src/app/templates/dialog_gebruiksfuncties.html?v=' + ntabuilding.commit,
                clickOutsideToClose: true
            })
                .then(function (successData) {
                    saveUitz(unitrz, unitrzgf);
                }, function (cancelData) {
                    saveUitz(unitrz, unitrzgf);
                });
        }//-- end: showGFDialog ------------------------------------------------------------------------//

        function saveUitz(unitrz, unitrzgf) {
            const uitz = unitrz.PropertyDatas['UNITRZ_GFS_UITZ'];
            if (uitz && uitz.Value === "GF_SAMV_UITZ4") { // samenvoegen is niet toegestaan -> op null zetten en opslaan!

                let propGfId = ntabuilding.properties['UNIT-RZ-GFID'];
                saveValue(propGfId, unitrzgf, null);
            }
        } //-- end: saveUitz ------------------------------------------------------------------------//

        function GFDialogController($scope, $mdDialog, ntabuilding, GFWarning, unitrz, unitrzgf) {
            $scope.GFWarning = GFWarning;
            $scope.unitrz = unitrz;
            $scope.unitrzgf = unitrzgf;
            $scope.hide = function () {
                $mdDialog.hide($scope.selectedItemId);
            };
            $scope.cancel = function () {
                $mdDialog.cancel();
            };
            $scope.answer = function () {
                $mdDialog.hide();
            };

            $scope.getCodedValues = function () {
                var prop = ntabuilding.properties['UNITRZ_GFS_UITZ'];
                return ntaValidation.codedValues(prop);
            };

            $scope.setSelectedItem = function (itemId) {
                $scope.selectedItemId = itemId;

                let newValue = itemId;
                let prop = ntabuilding.properties['UNITRZ_GFS_UITZ'];
                saveValue(prop, unitrz, newValue);
            };
        } //-- end: GFDialogController ------------------------------------------------------------------------//

        function samenvoegenGFWarning(unitrz) {
            let hasWarning = false;
            const unitrzgfs = unitrzgfsByUnitRz(unitrz);

            for (let index1 = 0; index1 < unitrzgfs.length; index1++) {
                for (let index2 = index1 + 1; index2 < unitrzgfs.length; index2++) {
                    const val1 = unitrzgfs[index1].PropertyDatas["UNIT-RZ-GFID"].Value;
                    const val2 = unitrzgfs[index2].PropertyDatas["UNIT-RZ-GFID"].Value;
                    let GFWarning = gfcombosInfo(val1, val2);
                    if (GFWarning.length === 0) {
                        //-- omdraaien
                        GFWarning = gfcombosInfo(val2, val1);
                    }
                    if (GFWarning.length > 0) {
                        hasWarning = true;
                        break;
                    }
                }
                if (hasWarning) {
                    break;
                }
            }

            return hasWarning;
        } //-- end: samenvoegenGFWarning ------------------------------------------------------------------------//

        function gfcombosInfo(val1, val2) {
            if (val1 === "GF_BIJEENKIND" && (val2 === "GF_SPORT" || val2 === "GF_WINKEL")) {
                return ntabuilding.errors.find(function (x) { return x.Id === "[E006]"; }).Value;
            }
            if (val1 === "GF_BIJEENOVER" && val2 === "GF_WINKEL") {
                return ntabuilding.errors.find(function (x) { return x.Id === "[E007]"; }).Value;
            }
            if (val1 === "GF_CELF" && val2 === "GF_ONDERW") {
                return ntabuilding.errors.find(function (x) { return x.Id === "[E008]"; }).Value;
            }
            if (val1 === "GF_GEZONDBED" && (val2 === "GF_SPORT" || val2 === "GF_WINKEL")) {
                return ntabuilding.errors.find(function (x) { return x.Id === "[E009]"; }).Value;
            }
            if (val1 === "GF_ONDERW" && (val2 === "GF_LOGIES" || val2 === "GF_SPORT" || val2 === "GF_WINKEL")) {
                return ntabuilding.errors.find(function (x) { return x.Id === "[E010]"; }).Value;
            }

            return "";
        } //-- end: gfcombosInfo ------------------------------------------------------------------------//

    }

    return CalcZonesLogic;
}]);
