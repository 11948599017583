﻿angular.module('projectModule')
    .factory('NietGebouwGebondenElektriciteitFactory',
        ['$log', 'ntaData', 'ntabuilding', 'ntaValidation', 'ntaEntityDataOrg', 'ntaSharedLogic',
function ($log,   ntaData,   ntabuilding,   ntaValidation,   ntaEntityDataOrg,   ntaSharedLogic) {
    'use strict';

    // Constantes die voor elke instantie hetzelfde zijn


    return function NietGebouwGebondenElektriciteit(ntaDependencyValidation) {
        const self = this;

        //== Imports ==============================================================================

        self.ntaValidation = ntaValidation;
        self.dependencyValidator = ntaDependencyValidation;


        //== Instance data ========================================================================

        const _entdataNietGebGebE = ntaEntityDataOrg.getFirstWithEntityId('NGEBGEB-E')
            || ntaData.entities['NGEBGEB-E'] && ntaEntityDataOrg.get(ntaEntityDataOrg.create('NGEBGEB-E')); // automatisch aanmaken indien nog niet bestaand


        //== Exports ==============================================================================

        self.entdata = _entdataNietGebGebE;
        self.properties = _entdataNietGebGebE && ntaData.properties[_entdataNietGebGebE.EntityId] || [];

        // al deze methods exporteren zodat ze publiek beschikbaar zijn
        Object.assign(self, {
            // standaard methods tbv validatie
            isHidden,
            isReadOnly,
            hasCodedValues,
            getCodedValues,
            getListItem,
            saveValue,
            validate,
            validateDependencies,
            startFormValidation,
            endFormValidation,
            setGeopend,
        });


        //== Initialization =======================================================================

        // (geen initialisatie nodig)


        //== Implementation =======================================================================

        function isReadOnly(prop, entdata = _entdataNietGebGebE, codedValue) {
            if (!prop)
                return true;

            switch (prop.Id) {
                case 'NGEBGEB-E_METHODE': {
                    // [NGE-A] grijs deze keuze uit als onder instellingen 'maatwerkadvies' uit staat. Dan kan er dus ook geen vinkje gezet worden.
                    return !!codedValue
                        && codedValue.Id === 'NGEBGEB-E_INTERNE_WARMTE'
                        && !ntaSharedLogic.isMaatwerkadvies();
                }
            }

            return false;
        } //-- end: isReadOnly --------------------------------------------------------------------

        function isHidden(prop, entdata = _entdataNietGebGebE) {
            if (typeof prop === 'string') prop = ntaData.properties[prop];
            if (!prop || !entdata) {
                return true;
            }
            const propdata = entdata.PropertyDatas[prop.Id];
            if (!propdata) {
                return true;
            }

            let visible = true;
            let relevant = null; // null = zelfde als visible

            switch (prop.Id) {
                case 'NGEBGEB-E_OPEN': {
                    visible = false;
                    break;
                }
                case 'NGEBGEB-E_INVOER': {
                    visible = conditionB();
                    break;
                }
                case 'NGEBGEB-E_VAST': {
                    // [NGE-C] als deze optie wordt aangevinkt, vink de andere optie in dit blok uit en verberg de invoervelden die horen bij die keuze (als die er zijn)
                    visible = conditionB() && entdata.PropertyDatas['NGEBGEB-E_INVOER'].Value === 'NGEBGEB-E_VAST';
                    break;
                }
                case 'NGEBGEB-E_PER_M2':
                case 'NGEBGEB-E_MIN':
                case 'NGEBGEB-E_MAX': {
                    // [NGE-C] als deze optie wordt aangevinkt, vink de andere optie in dit blok uit en verberg de invoervelden die horen bij die keuze (als die er zijn)
                    visible = conditionB() && entdata.PropertyDatas['NGEBGEB-E_INVOER'].Value === 'NGEBGEB-E_PER_M2';
                    break;
                }
            }

            if (relevant === null) relevant = visible;
            ntaEntityDataOrg.setPropdataStatus(propdata, relevant, visible);
            return !visible;
        } //-- end: isHidden ----------------------------------------------------------------------

        function conditionB() {
            // [NGE-B] toon dit blok alleen als gekozen is voor eigen invoer
            return _entdataNietGebGebE.PropertyDatas['NGEBGEB-E_METHODE'].Value === 'NGEBGEB-E_EIGEN_INVOER';
        }

        function hasCodedValues(prop) {
            return ntaValidation.hasCodedValues(prop);
        } //-- end: hasCodedValues ----------------------------------------------------------------

        function getCodedValues(prop, entdata = _entdataNietGebGebE) {
            const codedValues = ntaValidation.codedValues(prop);

            ntaSharedLogic.checkPropdataInList(prop, entdata, codedValues, self);

            return codedValues;
        } //-- end: getCodedValues ----------------------------------------------------------------

        function getListItem(prop, entdata = _entdataNietGebGebE, list = null) {
            let listItem = null;

            if (prop && prop.Domain && prop.Domain.DomainType > 1 && entdata) {
                const propValue = prop.getValue(entdata);
                let itemId = propValue;
                switch (prop.Id) {
                }
                if (!list) {
                    //-- anders ongefilterde codedValue list gebruiken
                    list = prop.Domain.Codes;
                }
                if (list && list.length > 0) {
                    listItem = list.find(item => item.Id === itemId);
                }
            }

            return listItem;
        } //-- end: getListItem ------------------------------------------------------------------

        function saveValue(propOrId, entdata, newValue) {
            const prop = typeof propOrId === 'string' ? ntaData.properties[propOrId] : propOrId;

            ntaSharedLogic.saveValue(prop, entdata, newValue, self);
        } //-- end: saveValue ---------------------------------------------------------------------

        function validate(prop, propdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            const hidden = isHidden(prop);
            const readOnly = isReadOnly(prop);

            let valid = ntaValidation.IsValid(self.form, prop, propdata, hidden, readOnly);

            switch (prop.Id) {
                // hier evt. property-specifieke validaties opnemen
            }

            return valid;
        } //-- end: validate ---------------------------------------------------------------------

        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = prop.getData(entdata);
            const checkValue = propdata.Value;

            let performDefaultChecks = true;

            switch (prop.Id) {
                case 'NGEBGEB-E_METHODE':
                    for (const propId of ['NGEBGEB-E_VAST', 'NGEBGEB-E_PER_M2', 'NGEBGEB-E_MIN', 'NGEBGEB-E_MAX']) {
                        validate(ntaData.properties[propId], entdata);
                    }
                    break;
            }

            if (performDefaultChecks) {
                isHidden(prop, entdata);
                if (ntaValidation.hasCodedValues(prop)) {
                    getCodedValues(prop);
                }
                if (propdata.Value !== checkValue) {
                    saveValue(prop, entdata);
                }
            }
        } //-- end: validateDependencies ----------------------------------------------------------

        function startFormValidation() {
            return ntaSharedLogic.startFormValidation(getAllEntDatas(), self);
        } //-- end: startFormValidation -----------------------------------------------------------

        function endFormValidation() {
            if (ntaData.current.shadowId) return [];

            return ntaSharedLogic.endFormValidation(getAllEntDatas(), self);
        } //-- end: endFormValidation -------------------------------------------------------------

        function getAllEntDatas() {
            return [
                _entdataNietGebGebE
            ].filter(ed => ed);
        } //-- end: getAllEntDatas ----------------------------------------------------------------

        function setGeopend() {
            const propdataOpen = _entdataNietGebGebE && _entdataNietGebGebE.PropertyDatas['NGEBGEB-E_OPEN'];
            propdataOpen && ntaEntityDataOrg.saveprop(propdataOpen, 'true');
        } //-- end: setGeopend --------------------------------------------------------------------

    };
}]);
