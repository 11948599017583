'use strict';
angular.module('indexModule').service('searchService', [ function () {
    const vm = this;
    
    vm.searchItemsContaining = async function(searchQuery) {
        const response = await fetch(`SearchResults?searchQuery=${encodeURIComponent(searchQuery)}`);
        const reply = await response.json();
        if (reply.error) {
            throw new Error(reply.error);
        } else {
            return reply;
        }
    }
}]);