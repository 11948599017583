﻿angular.module('projectModule')
    .service('ntaOrientation',
        ['$log', '$mdDialog', 'ntabuilding', 'ntaEntityData', 'ntaSharedLogic', 'ntaDependencyValidation', 'BuildingBegrenzingFactory', 'PvFactory',
function ($log,   $mdDialog,   ntabuilding,   ntaEntityData,   ntaSharedLogic,   ntaDependencyValidation,   BuildingBegrenzingFactory,   PvFactory) {
    'use strict';
    const self = this;

    /// ntaOrientation verzorgt het roteren en spiegelen.

    /// -- Imports --------------------------------------------------------------------------------



    /// -- Instance variables ---------------------------------------------------------------------

    const _begrenzingPropId = { // per vlaktype, de property waar de begrenzing (en oriëntatie) in opgeslagen staat
        VLAK_VLOER: 'BEGR_VLOER',
        VLAK_GEVEL: 'BEGR_GEVEL',
        VLAK_DAK: 'BEGR_DAK',
        VLAK_KELDERW: 'BEGR_KWAND',
    };

    const _orientationAngles = ['N', 'NO', 'O', 'ZO', 'Z', 'ZW', 'W', 'NW', 'N']
        .map((o, i) => ({ orientation: o, angle: i * 45 })); // alle windrichtingen, met de hoek t.o.v. het noorden, met de wijzers van de klok mee, in °


    /// -- Exports --------------------------------------------------------------------------------

    self.showRotateMirrorDialog = showRotateMirrorDialog;
    self.rotateUnits = rotateUnits;
    self.mirrorUnits = mirrorUnits;


    /// -- Initialization -------------------------------------------------------------------------

    for (const oa of _orientationAngles) {
        if (!(oa.orientation in _orientationAngles)) {
            _orientationAngles[oa.orientation] = oa;
        }
    }


    /// -- Tests ----------------------------------------------------------------------------------

    const testing = false;
    if (testing) {
        {
            console.info(getOrientationFromAngle.name);
            for (let i = 0; i < 10; i++) {
                const angle = Math.round(10 * Math.random() * 360) / 10;
                console.log(`${angle}° = ${getOrientationFromAngle(angle)}`);
            }
        }
        {
            const allOrientationCodes = ntabuilding.properties
                .flatMap(prop => prop.Domain && Array.isArray(prop.Domain.Codes) && prop.Domain.Codes || [])
                .map(cv => cv.Id)
                .filter(code => /_([NOZW]{1,2}$)/.test(code));

            console.info(getAngleFromOrientation.name);
            console.table(allOrientationCodes.map(code => {
                const orientation = /_([NOZW]{1,2})$/.exec(code)[1];
                const angle = getAngleFromOrientation(orientation);
                return [code, angle];
            }));
        }
        {
            console.info('Roteren');
            const rotationAngles = [45, 90, 135, 180, 225, 270, 315];
            const test = [];
            for (const angle of rotationAngles) {
                test[angle] = Object.fromEntries(_orientationAngles.map(oa => [oa.orientation, getOrientationFromAngle(rotate(oa.angle, angle))]));
            }
            console.table(test);
        }
        {
            console.info('Spiegelen');
            const mirrorAxes = [
                { name: 'N-Z', angle: 0 },
                { name: 'W-O', angle: 90 },
                { name: 'NW-ZO', angle: 135 },
                { name: 'ZW-NO', angle: 45 },
            ];
            const test = mirrorAxes.map(m => Object.assign({ axis: m.name }, Object.fromEntries(_orientationAngles.map(oa => [oa.orientation, getOrientationFromAngle(mirror(oa.angle, m.angle))]))));
            console.table(test);
        }
    }


    /// -- Implementation -------------------------------------------------------------------------

    async function showRotateMirrorDialog(unit = null) {
        try {
            return await $mdDialog.show({
                locals: {
                    unit,
                },
                controller: DialogRotateMirrorController,
                controllerAs: 'ctrl',
                templateUrl: '/src/app/templates/dialog_spiegelen_roteren.html?v=' + ntabuilding.commit,
                disableParentScroll: false,
                clickOutsideToClose: true,
            });
        } catch (err) {
            if (err) $log.warn(err);
        }
    }

    function DialogRotateMirrorController($scope, $mdDialog, unit) {
        const ctrl = this;

        const heelGebouw = !unit;
        const units = unit ? [unit] : ntaEntityData.getListWithEntityId('UNIT');

        let subject; // conditie [A1]
        if (!unit) {
            if (ntaSharedLogic.perGebouwEnAppartementOfUnit()) {
                subject = 'gehele gebouw';
            } else {
                subject = units[0].PropertyDatas['UNIT_OMSCHR'].Value; // Z10
            }
        } else {
            subject = (ntaSharedLogic.isUtiliteit() ? 'unit' : ntaSharedLogic.voorProjectwoningen() ? 'woning' : 'appartement')
                + ': ' + unit.PropertyDatas['UNIT_OMSCHR'].Value;
        }
        $scope.subject = subject;

        // conditie [A2]
        let showPV = ntaEntityData.getListWithEntityId('PV').length > 0;
        if (showPV && unit) {
            showPV = ntaEntityData.getChildren(unit, 'PV-VELD')
                .some(pvVeld => pvVeld.Relevant && ['PVINVOER_APP', 'PVINVOER_UNIT', 'PVINVOER_WONING'].includes(pvVeld.PropertyDatas['PV-VELD_TYPEINVOER'].Value));
        }
        $scope.showPV = showPV;

        const showZB = ntaEntityData.getListWithEntityId('ZONNB').length > 0;
        $scope.showZB = showZB;

        // De keuzes voor het formulier
        $scope.rotationAngles = [45, 90, 135, 180, 225, 270, 315]; // hoeveel graden er geroteerd moet kunnen worden
        $scope.mirrorAxes = [ // over welke as er gespiegeld moet kunnen worden
            { name: 'N-Z', angle: 0 },
            { name: 'W-O', angle: 90 },
            { name: 'NW-ZO', angle: 135 },
            { name: 'ZW-NO', angle: 45 },
        ];

        // De keuzes die de gebruiker moet maken
        ctrl.action = '';
        ctrl.includingPV = false;

        $scope.execute = function () {
            if (ctrl.action) {
                $mdDialog.hide();

                const angle = parseInt(ctrl.action.substr(1));

                if (ctrl.action.startsWith('R')) {
                    rotateUnits(heelGebouw, units, angle, ctrl.includingPV);

                } else if (ctrl.action.startsWith('M')) {
                    mirrorUnits(heelGebouw, units, angle, ctrl.includingPV);

                }
            }
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
    }

    function rotateUnits(entireBuilding, units, angle, includingPV) {
        return reorientUnits(entireBuilding, units, includingPV, oldAngle => rotate(oldAngle, angle));
    }

    function mirrorUnits(entireBuilding, units, angle, includingPV) {
        const changes = reorientUnits(entireBuilding, units, includingPV, oldAngle => mirror(oldAngle, angle));

        // Bij spiegelen moeten we van alle zijbelemmeringen links en rechts omwisselen
        mirrorObstructions(changes.map(change => change.propdata));

        return changes;
    }

    function rotate(initialAngle, rotationAngle) {
        // roteren, {rotationAngle}° met de klok mee (N = 0°, O = 90°, Z = 180°, W = 270°, etc.)
        return (initialAngle + rotationAngle) % 360;
    }
    function mirror(initialAngle, centerlineAngle) {
        // spiegelen t.o.v. hoek {centerLineAngle}° (N-Z = 0° of 180°; W-O = 90° of 270°, ZW-NO = 45°, etc...)
        // We tellen er 360 bij op en doen dan mod 360 om binnen het bereik 0..359 (inclusief) te blijven.
        return (2 * centerlineAngle - initialAngle + 360) % 360;
    }

    function reorientUnits(entireBuilding, units, includingPV, calculateNewAngle) {
        const changes = [];

        // Haal alle begrenzingen op van de opgegeven units
        const begrParents = units.flatMap(unit => ntaEntityData.getChildren(unit, 'UNIT-RZ'));
        if (entireBuilding) {
            begrParents.push(...ntaEntityData.getListWithEntityId('GRUIMTE'));
            begrParents.push(...ntaEntityData.getListWithEntityId('RZ').filter(ed => ed.PropertyDatas['RZ_TYPEZ'].Value !== 'RZ'));
        }
        const rotatedBegrs = new Set();
        for (const begrParent of begrParents) {
            let logic = null;
            const begrenzingen = ntaEntityData.getChildren(begrParent, 'BEGR');
            for (const begr of begrenzingen) {
                if (!rotatedBegrs.has(begr)) {
                    const propId = _begrenzingPropId[begr.PropertyDatas['BEGR_VLAK'].Value];
                    const propdata = propId && begr.PropertyDatas[propId];
                    const oldBegrenzing = propdata && propdata.Value;
                    const newBegrenzing = reorientCodedValue(oldBegrenzing, calculateNewAngle);
                    if (newBegrenzing !== oldBegrenzing) {
                        // We hebben de logic nodig om de nieuwe waarde op te slaan met validaties
                        if (!logic) logic = new BuildingBegrenzingFactory(begrParent.EntityDataId, ntaDependencyValidation);
                        logic.saveValue(propId, begr, newBegrenzing);
                    }
                    // rapporteer de propdata, ook al is-ie niet gewijzigd, zodat bij spiegelen de bijbehorende belemmeringen wel gespiegeld worden.
                    changes.push({
                        propdata: propdata,
                        from: getCodeDescription(propId, oldBegrenzing),
                        to: getCodeDescription(propId, newBegrenzing),
                    });
                    rotatedBegrs.add(begr); // zodat we deze begrenzing niet nog een keer draaien
                }
            }
        }
        if (includingPV) {
            const pvChanges = reorientPVs(entireBuilding, units, calculateNewAngle);
            changes.push(...pvChanges);
        }

        return changes;
    } //-- reorientUnits

    function reorientPVs(entireBuilding, units, calculateNewAngle) {
        const changes = [];

        const pvVelden = entireBuilding
            ? ntaEntityData.getListWithEntityId('PV-VELD')
            : units.flatMap(unit => ntaEntityData.getChildren(unit, 'PV-VELD'))
                .filter(pvVeld => pvVeld.Relevant && ['PVINVOER_APP', 'PVINVOER_UNIT', 'PVINVOER_WONING'].includes(pvVeld.PropertyDatas['PV-VELD_TYPEINVOER'].Value));

        for (const pvVeld of pvVelden) {
            const propId = 'PV-VELD_ORIE';
            const propdata = pvVeld.PropertyDatas[propId];
            const oldValue = propdata.Value;
            const newValue = reorientCodedValue(oldValue, calculateNewAngle);
            if (newValue !== oldValue) {
                const pv = ntaEntityData.getFirstParent(pvVeld, 'PV');
                const installatie = ntaEntityData.getFirstParent(pv, 'INSTALLATIE');
                if (installatie) {
                    const installationId = installatie.EntityDataId;
                    const logic = new PvFactory(installationId, ntaDependencyValidation);
                    logic.saveValue(propId, pvVeld, newValue);
                }
            }
            // rapporteer de propdata, ook al is-ie niet gewijzigd, zodat bij spiegelen de bijbehorende belemmeringen wel gespiegeld worden.
            changes.push({
                propdata: propdata,
                from: getCodeDescription(propId, oldValue),
                to: getCodeDescription(propId, newValue),
            });
        }

        return changes;
    } //-- reorientPVs

    function reorientCodedValue(oldValue, calculateNewAngle) {
        // We gaan ervan uit dat de CodedValue eindigt op N, NO, O, ZO, Z, ZW, W, of NW; en als dat niet zo is, dan kan deze entiteit niet geroteerd of gespiegeld worden.
        const match = /_([NOZW]{1,2}$)/.exec(oldValue);
        const oldOrientation = match && match[1];
        if (oldOrientation) {
            const oldAngle = getAngleFromOrientation(oldOrientation);
            const newAngle = calculateNewAngle(oldAngle);
            const newOrientation = getOrientationFromAngle(newAngle);
            const newValue = oldValue.substr(0, match.index + 1) + newOrientation;
            return newValue;
        }
        return oldValue;
    } //-- reorientCodedValue

    function getAngleFromOrientation(orientation) {
        const oa = _orientationAngles[orientation];
        return oa && oa.angle;
    }
    function getOrientationFromAngle(angle) {
        const orientationDiffs = _orientationAngles
            .map(oa => Object.assign({}, oa, { diff: Math.abs(angle % 360 - oa.angle) }))
            .sort((a, b) => a.diff - b.diff);
        return orientationDiffs[0].orientation;
    }

    function getCodeDescription(propOrId, code) {
        const prop = typeof propOrId === 'string' ? ntabuilding.properties[propOrId] : propOrId;
        const codes = prop && prop.Domain && prop.Domain.Codes || [];
        const codedValue = codes.find(c => c.Id === code);
        const description = codedValue && codedValue.Value;
        return description || code;
    } //-- getCodeDescription

    function mirrorObstructions(orientationPropdatas) {
        // Verzamel eerst alle belemmeringstype-propertyData’s
        const belemmeringtypePropdatas = [];
        for (const propdata of orientationPropdatas) {
            const entdata = ntaEntityData.get(propdata.EntityDataId);
            switch (entdata.EntityId) {
                case 'BEGR': // van een gespiegelde begrenzing, de beschaduwing van alle transparante delen nalopen
                    belemmeringtypePropdatas.push(...ntaEntityData.getChildren(entdata, 'CONSTRT').map(constrT => constrT.PropertyDatas['CONSTRT_BESCH']));
                    break;
                case 'ZONNB': // van een gespiegelde zonneboiler, de beschaduwing van alle zonnecollectoren nalopen
                    belemmeringtypePropdatas.push(...ntaEntityData.getChildren(entdata, 'ZONNBSYS').flatMap(zonnbsys => ntaEntityData.getChildren(zonnbsys, 'ZONNECOL')).map(zonnecol => zonnecol.PropertyDatas['ZONNC_BESCH']));
                    break;
                case 'PV-VELD': // de beschaduwing van het PV-veld nalopen
                    belemmeringtypePropdatas.push(entdata.PropertyDatas['PV-VELD_BELEM']);
                    break;
            }
        }

        // Wissel nu links en rechts om bij alle zijbelemmeringen
        for (const propdata of belemmeringtypePropdatas) {
            const belemmering = ntaEntityData.getFirstChild(propdata.EntityDataId, 'BELEMMERING');
            if (!belemmering)
                continue;

            // Belemmeringstype spiegelen
            switch (propdata.Value) {
                case 'BELEMTYPE_ZIJ_RECHTS':
                    ntaEntityData.saveprop(propdata, 'BELEMTYPE_ZIJ_LINKS');
                    break;
                case 'BELEMTYPE_ZIJ_LINKS':
                    ntaEntityData.saveprop(propdata, 'BELEMTYPE_ZIJ_RECHTS');
                    break;
                case 'BELEMTYPE_ZIJ_BEIDE':
                    // het belemmeringstype blijft dan ongewijzigd; alleen de veldwaardes omwisselen
                    break;
                default:
                    // alle andere belemmeringstypes overslaan
                    continue;
            }

            // Waardes van links en rechts omwisselen
            const propIdPairs = [
                ['BELEMM_HOR_A_LINKS', 'BELEMM_HOR_A_RECHTS'],
                ['BELEMM_HOR_B_LINKS', 'BELEMM_HOR_B_RECHTS'],
                ['BELEMM_HOEK_LINKS', 'BELEMM_HOEK_RECHTS'],
                ['BELEMM_HOOGTE_LINKS', 'BELEMM_HOOGTE_RECHTS'],
                ['BELEMM_ZIJ_LINKS', 'BELEMM_ZIJ_RECHTS']
            ];
            const valuePairsByPropId = {
                'BELEMM_ZIJBELEMML_B1': 'BELEMM_ZIJBELEMMR_B1',
                'BELEMM_ZIJBELEMML_B2': 'BELEMM_ZIJBELEMMR_B2',
                'BELEMM_ZIJBELEMML_REKEN': 'BELEMM_ZIJBELEMMR_REKEN',
                'BELEMM_ZIJBELEMMR_B1': 'BELEMM_ZIJBELEMML_B1',
                'BELEMM_ZIJBELEMMR_B2': 'BELEMM_ZIJBELEMML_B2',
                'BELEMM_ZIJBELEMMR_REKEN': 'BELEMM_ZIJBELEMML_REKEN',
            };
            for (const propIds of propIdPairs) {
                const [pdLinks, pdRechts] = propIds.map(propId => belemmering.PropertyDatas[propId]);
                if (pdLinks.Value !== pdRechts.Value || pdLinks.Relevant !== pdRechts.Relevant) {
                    const oldLinksValue = pdLinks.Value;
                    const oldLinksRelevant = pdLinks.Relevant;

                    if (propIds[0].startsWith("BELEMM_ZIJ_")) {
                        /// voor zij belemmeringen moet ook de Value van links <--> rechts
                        const oldRechtsValue = pdRechts.Value;
                        pdRechts.Value = valuePairsByPropId[oldLinksValue];
                        pdLinks.Value = valuePairsByPropId[oldRechtsValue];
                    } else {
                        pdLinks.Value = pdRechts.Value;
                        pdRechts.Value = oldLinksValue;
                    }

                    pdLinks.Relevant = pdRechts.Relevant;
                    pdRechts.Relevant = oldLinksRelevant;

                    ntaEntityData.saveprop(pdLinks);
                    ntaEntityData.saveprop(pdRechts);
                }
            }
        }
    } //-- mirrorObstructions

}]);
