angular.module('indexModule').controller('SearchController', ['$scope', 'indextree', 'searchFactory', 'searchService', 'progressCircle', function ($scope, indextree, searchFactory, searchService, progressCircle) {
    'use strict';
    const vm = this;
    vm.indextree = indextree;
    vm.searchQuery = searchFactory.searchQuery;

    vm.searchResults = searchFactory.searchResults;

    vm.search = async function () {
        const searchQuery = vm.searchQuery;
        searchFactory.searchQuery = searchQuery;
        if (!searchQuery)
            return;
        progressCircle.setShowProgressValue(true, "aan het zoeken", false);
        let results = await searchService.searchItemsContaining(searchQuery);
        progressCircle.setShowProgressValue(false);

        let buildingPaths = getPaths(results.buildingResults.buildings);
        let projectPaths = getPaths(results.projectResults.projects);
        let folderPaths = getPaths(results.folderResults.folders);

        vm.searchResults = {
            searchResultCount: (results.projectResults.projects.length + results.buildingResults.buildings.length + results.folderResults.folders.length),
            buildingResults:
            {
                buildings: results.buildingResults.buildings,
                paths: buildingPaths
            },
            projectResults:
            {
                projects: results.projectResults.projects,
                paths: projectPaths
            },
            folderResults:
            {
                folders: results.folderResults.folders,
                paths: folderPaths
            }
        }
        searchFactory.searchResults = vm.searchResults;
    };

    function getPaths(searchResults) {
        let paths = [];

        for (var i = 0; i < searchResults.length; i++) {
            let path = getPath(searchResults[i]);
            paths.push(path);
        }
        return paths;
    }

    function getPath(searchResult) {
        let folder = indextree.foldersById.get(searchResult.FolderId);
        let path = `${folder.Name}`;
        while (folder.ParentId != -1) {
            let parentFolder = indextree.foldersById.get(folder.ParentId);
            if (!!parentFolder) {
                path = `${parentFolder.Name}/${path}`;
                folder = parentFolder;
            } else {
                folder.ParentId = -1
            }
        }
        return `/${path}`;
    };
}]);