﻿'use strict';
angular.module('projectModule').factory("BuildingBegrenzingFactory", ['ntabuilding','ntaValidation','ntaEntityData','ntaMeldingen','settingsMenuData','ntaRounding','ntaSharedLogic',
function                                                              (ntabuilding,  ntaValidation,  ntaEntityData,  ntaMeldingen,  settingsMenuData,  ntaRounding,  ntaSharedLogic) {
    function BegrenzingLogic(entId, ntaDependencyValidation) {
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        const begrenzingLogic = this; // Object dat aan de controller gegeven zal worden
        begrenzingLogic.entId = entId;

        let _rz = null;
        let _unitName = null;
        let _zoneName = null;
        let _isAORofAOS = false;
        let _begrForm = null;

        const _entId = entId;
        const _entity = ntaEntityData.get(_entId);
        const _propRzOmschr = ntabuilding.properties['RZ_OMSCHR'];
        const _propOpmerkingen = ntabuilding.properties["BEGR-FORM_OPM"];

        if (_entity) {
            _zoneName = ntaSharedLogic.getZoneName(_entity);
            _unitName = ntaSharedLogic.getUnitName(_entity);
            if (_entity.EntityId === 'UNIT-RZ') {
                _rz = ntaEntityData.getFirstParent(_entId, "RZ");
            } else {
                if (_entity.EntityId === 'RZ') {
                    _rz = _entity;
                }
            }
        }

        //-- de PropertyId die de begrenzing bevat voor elk vlaktype
        const _vlaktypeBegrenzingPropIds = {
            "VLAK_DAK": "BEGR_DAK",
            "VLAK_GEVEL": "BEGR_GEVEL",
            "VLAK_KELDERW": "BEGR_KWAND",
            "VLAK_VLOER": "BEGR_VLOER",
            "VLAK_VLOER_BOVBUI": "BEGR_VLOER_BOVBUI",
        };
        const _begrenzingKeuzes = {};

        /* Properties **********************************************************************************************/
        const _propertiesVlak = ntabuilding.properties['BEGR'];
        begrenzingLogic.properties = _propertiesVlak;
        begrenzingLogic.propOpmerkingen = _propOpmerkingen;

        /* Data *************************************************************************************************************************************************************/
        _begrForm = ntaEntityData.getFirstChild(_entity, 'BEGR-FORM');
        begrenzingLogic.entity = _entity;
        begrenzingLogic.vlakken = vlakken;
        begrenzingLogic.unitName = _unitName;
        begrenzingLogic.zoneName = _zoneName;

        begrenzingLogic.isHidden = isHidden;
        begrenzingLogic.isReadOnly = isReadOnly;

        begrenzingLogic.validate = validate;
        begrenzingLogic.validateKelderwanden = validateKelderwanden;
        begrenzingLogic.validateDependencies = validateDependencies;

        begrenzingLogic.startFormValidation = startFormValidation;
        begrenzingLogic.endFormValidation = endFormValidation;

        begrenzingLogic.saveValue = saveValue;
        begrenzingLogic.getCodedValues = getCodedValues;

        begrenzingLogic.getListItem = getListItem;
        begrenzingLogic.dependencyValidator = ntaDependencyValidation;

        begrenzingLogic.getSelection = getSelection;
        begrenzingLogic.saveSelection = saveSelection;
        begrenzingLogic.createVlak = createVlak;

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         INITALIZIATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        initFormEnVlakken();
        setAORofAOSType();

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        function initFormEnVlakken() {
            begrenzingLogic.begrForm = _begrForm;
            if (!ntabuilding.canSave()) return;
            //-- begrenzingsForm en Vlakken (entiteiten BEGR-FORM en BEGR) worden niet standaard aangemaakt bij het creeeren van een een UNIT-RZ, AOR, AOS of GRUIMTE. Dit omdata
            //-- ander links de boom vol komt te staan met lege vlakken (begrenzingen) waar de gebruiker niks van snapt. Maar bij het valideren moet er wel gekeken worden
            //-- of er vlakken zijn en dan worden ze pas aangemaakt in deze init, als ze er nog niet zijn.
            if (_entity) {
                if (vlakken().length === 0) {
                    createVlak(null);
                }
                if (!_begrForm) {
                    //-- blijkbaar zijn er ook nog steeds projecten in omloop die wel vlakken hebben maar nog geen form. Ik weet niet precies wanneer BEGR-FORM voor het eerst
                    //-- voor het eerst gelanceerd is, dus vandaar ook nog maar specifiek de test op BERGFORM en niet afhankelijk van de aanwezigheid van vlakken
                    ntaEntityData.create('BEGR-FORM', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": _entId, "ParentEntityId": _entity.EntityId }], []);
                    _begrForm = ntaEntityData.getFirstChild(_entity, 'BEGR-FORM');
                }
                const begrForms = ntaEntityData.getChildren(_entity, 'BEGR-FORM');
                if (begrForms.length > 1) {
                    for (const begrForm of begrForms.slice(1)) {
                        ntaEntityData.delete(begrForm.EntityDataId);
                    }
                }
            } 

            begrenzingLogic.begrForm = _begrForm;
        } //-- end: initFormEnVlakken ------------------------------------------------------------------------//

        function createVlak(entdata) {
            const begrId = ntaEntityData.create('BEGR', entdata ? entdata.Order : -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": _entId, "ParentEntityId": _entity.EntityId }], [], []);
            ntaEntityData.create('CONSTRERROR', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": begrId, "ParentEntityId": "BEGR" }], [], []);
            //-- een nieuwe dichte constructie is handig maar niet noodzakelijk. Als gebruiker geen dichte delen heeft wil je niet dat er steeds één aangemaakt wordt,
            //-- dus eigenlijk bij het aanmaken van een begrenzing moet er standaard een dichtdeel aangemaakt worden en een wandgronddeel. En anders niet.
            ntaEntityData.create('CONSTRD', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": begrId, "ParentEntityId": "BEGR" }], [], []);
            ntaEntityData.create('CONSTRWG', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": begrId, "ParentEntityId": "BEGR" }], [], []);
            ntaEntityData.create('CONSTRKENMW', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": begrId, "ParentEntityId": "BEGR" }], [], []);
            ntaEntityData.create('CONSTRKENMV', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": begrId, "ParentEntityId": "BEGR" }], [], []);
            ntaEntityData.create('CONSTRKRVENT', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": begrId, "ParentEntityId": "BEGR" }], [], []);
            ntaEntityData.create('CONSTRWWGVL', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": begrId, "ParentEntityId": "BEGR" }], [], []);
            ntaEntityData.create('CONSTRWWKLDR', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": begrId, "ParentEntityId": "BEGR" }], [], []);
        } //-- end: createVlak ------------------------------------------------------------------------//

        function setAORofAOSType() {
            if (_entity && _entity.EntityId === 'RZ') {
                let zoneType = _rz.PropertyDatas['RZ_TYPEZ'].Value;
                _isAORofAOS = zoneType === 'AOR' || zoneType === 'AOS';
            }
        } //-- end: setAORofAOSType ------------------------------------------------------------------------//

        function vlakken() {
            if (_entity) {
                let vlakken = ntaEntityData.getChildren(_entity, "BEGR");
                // Alleen in het geval van AOR en AOS zijn begrenzing direct aan een RZ gekoppeld; andere begrenzingen zijn aan een UNIT-RZ of GRUIMTE gekoppeld
                if (_entity.EntityId === 'RZ') {
                    //-- ik moet deze vlakken even filteren omdat er ook relaties tussen RZ<-->BEGR zijn die gekozen zijn als property begrenzing is gekozen bij rekenzones en/of gemeenschappelijke ruimten
                    vlakken = vlakken.filter(vlak => ntaEntityData.getParentRelations(vlak, ['UNIT-RZ', 'GRUIMTE']).length === 0);
                }
                return vlakken;
            }
            return [];
        } //-- end: vlakken ------------------------------------------------------------------------//

        function getListItem(prop, entdata, list = null) {
            let listItem = null;

            if (prop && prop.Domain && prop.Domain.DomainType > 1 && entdata) {
                var tmpList = null;
                var tmpId;
                var propData = entdata.PropertyDatas[prop.Id];
                switch (prop.Id) {
                    case 'BEGR_DAK':
                    case 'BEGR_GEVEL':
                    case 'BEGR_VLOER':
                    case 'BEGR_KWAND':
                        {
                            //-- bij deze properties is de lijst anders opgebouwd en werkt het anders omdat ze via track id werken met angularjs
                            //-- hierdoor wordt bij de getCodedValues geen check uitgevoerd of de value van de propdata nog wel in de lijst zit.
                            return null;
                        }
                    default:
                        tmpId = propData.Value;
                        tmpList = list; // de gefilterde getCodedValue list. Op deze list moet ik checken
                        if (!tmpList) {
                            //-- anders ongefilterde codedValue list gebruiken
                            tmpList = prop.Domain.Codes;
                        }
                        break;
                }

                if (propData.Value && tmpList && tmpList.length > 0) {
                    listItem = tmpList.find(function (x) { return x.Id === tmpId; });
                }
            }

            return listItem;
        } //-- end: getListItem ------------------------------------------------------------------------//

        function saveValue(prop, entdata, newValue) {
            ntaSharedLogic.saveValue(prop, entdata, newValue, begrenzingLogic);
        } //-- end: saveValue ------------------------------------------------------------------------//

        function validate(prop, propdata, entdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !entdata || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }
            const hidden = isHidden(prop, entdata);
            const readonly = isReadOnly(prop, entdata);
            let valid = ntaValidation.IsValid(begrenzingLogic.form_bldbegr, prop, propdata, hidden, readonly);

            switch (prop.Id) {
                case 'BEGR_HEL':
                    {
                        let validHelling = true;
                        const begrCoded = begrCodedval(entdata);
                        if (begrCoded) {
                            const helDef = parseInt(begrCoded.FilterValue2); // initial value
                            const helMin = parseInt(begrCoded.FilterValue3); // minimum value
                            const helMax = parseInt(begrCoded.FilterValue4); // maximum value
                            if (isNaN(helDef) || isNaN(helMin)) {
                                //-- zonder grenswaarden is het veld read-only, wordt 'n.v.t.' weergegeven, en is het (dus) valide;
                                //-- de daadwerkelijke waarde moet de koppellaag zelf afleiden van vlaktype en begrenzing
                                saveValue(prop, entdata, 'n.v.t.');
                            } else {
                                const hellingshoek = parseInt(propdata.Value);
                                validHelling = !(isNaN(hellingshoek) || hellingshoek < helMin || hellingshoek > helMax);

                                ntaSharedLogic.updateErrorReplaceObject(propdata, "min", helMin);
                                ntaSharedLogic.updateErrorReplaceObject(propdata, "max", helMax);
                            }
                        }
                        ntaSharedLogic.setMelding('[D012]', propdata, begrenzingLogic.form_bldbegr, validHelling);

                        valid = valid && validHelling;
                        break;
                    }
                case 'BEGR_VLOER':
                case 'BEGR_GEVEL':
                case 'BEGR_DAK':
                    {
                        //-- VO 2020-11-09: Het kan zijn dat de waarde van deze property een begrenzing met een AOR of AOS was. Als de relatie
                        //-- met deze AOR of AOS weg is, kan het zijn dat er nog wel een waarden in de value staat bv. AOS_Z. Deze moet naar null
                        //-- de property nog een keer door de generieke validatie heen zodat de melding 'invoer verplicht' komt.
                        //-- VO 2020-11-11: dit ga ik oplossen in de dependencyValidator bij het verwijderen van een zone.
                        break;
                    }
                case 'BEGR_KWAND':
                    {
                        //-- Conditie [L]
                        let kelderwanden = vlakken().filter(function (x) { return x.PropertyDatas["BEGR_VLAK"].Value === "VLAK_KELDERW" && x.EntityDataId !== entdata.EntityDataId; }); //--selecteer kelderwanden
                        let parent = ntaEntityData.getFirstParent(entdata, "BEGR");
                        let validKelderwand = true;
                        if (entdata.PropertyDatas["BEGR_VLAK"].Value === "VLAK_KELDERW") {
                            let index;
                            for (const kelderwand of kelderwanden) {
                                let parent2 = ntaEntityData.getFirstParent(kelderwand, "BEGR");
                                if (parent === parent2) {
                                    validKelderwand = false;
                                    break;
                                }
                            }
                        }
                        ntaSharedLogic.setMelding('[E016]', propdata, begrenzingLogic.form_bldbegr, validKelderwand);

                        valid = valid && validKelderwand;
                        break;
                    }
                case 'BEGR_VLAK':
                    {
                        let validVlak = true;

                        if (propdata.Value === 'VLAK_KELDERW') {
                            //-- Conditie [K]
                            let vloeren = getVloerenOnderMaaiveld();

                            if (vloeren.length < 1) {
                                validVlak = false;
                            }
                        } else { // geen kelderwand? Dan geen relaties naar andere BEGR
                            const oldBegrRelations = ntaEntityData.getParentRelations(entdata, 'BEGR');
                            for (const oldBegrRelation of oldBegrRelations) {
                                ntaEntityData.deleteRelation(oldBegrRelation.EntityRelationDataId);
                            }
                        }

                        ntaSharedLogic.setMelding('[E014]', propdata, begrenzingLogic.form_bldbegr, validVlak);

                        valid = valid && validVlak;

                        break;
                    }
                default:
            }
            return valid;
        } //-- end: validate ------------------------------------------------------------------------//

        function validateKelderwanden() {
            validateKelderwandenVloerenOnderMaailveldAanwezig();
            validateKelderwandenSamenvoegenZelfdeBegrenzing();
        } //-- end: validateKelderwanden ------------------------------------------------------------------------//

        function getVloerenOnderMaaiveld() {
            var vloeren = vlakken().filter(function (x) { return x.PropertyDatas["BEGR_VLAK"].Value === "VLAK_VLOER"; }); //selecteer vloeren
            vloeren = vloeren.filter(function (x) {
                const propbegrvl = x.PropertyDatas["BEGR_VLOER"];
                if (propbegrvl && propbegrvl.Value) {
                    return propbegrvl.Value.indexOf("VL_OMV") > -1;
                }
                return false;
            }); //-- selecteer vloeren onder maaiveld

            return vloeren;
        } //-- end: getVloerenOnderMaaiveld ------------------------------------------------------------------------//

        function isReadOnly(prop, entdata) {
            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return false;
            }
            if (prop.Id === "BEGR_HEL") {
                var begrCoded = begrCodedval(entdata);
                if (begrCoded) {
                    const hellingDef = parseInt(begrCoded.FilterValue2);
                    const hellingMin = parseInt(begrCoded.FilterValue3);
                    if (isNaN(hellingDef) || isNaN(hellingMin)) { //initial_inclination === null -> nvt -> read-only || //inclination;min === null -> nvt -> read-only met invoer initial_inclination waarde
                        return true;
                    }
                }
            }
            return false;
        } //-- end: isReadOnly ------------------------------------------------------------------------//

        function begrCodedval(entdata) {
            const vlaktype = entdata.PropertyDatas["BEGR_VLAK"].Value;
            const begrPropId = _vlaktypeBegrenzingPropIds[vlaktype];
            const prop = ntabuilding.properties[begrPropId];
            if (prop) {
                let begrenzing = prop.getValue(entdata);
                if (begrenzing === 'n.v.t.') { // kelderwand heeft geen waarde
                    begrenzing = entdata.selectedItem;
                }
                const values = getCodedValues(prop, entdata);
                return values.find(function (x) { return x.Id.includes(begrenzing); }); //-- bij AOR en AOS staat er nog een guid voor dus checken met includes
            }
        } //-- end: begrCodedval ------------------------------------------------------------------------//

        function isHidden(prop, entdata) {
            //-- VO 2020-11-09: deze isHidden wordt ook gebruikt voor de tabelheader. In andere factory's heet dit propertyHeaderIsHidden. Voor
            //-- bouwkunidg doen we dit in een, vandaar anders checken op entdata.
            if (!prop) {
                return false;
            }

            let propdata = null;
            let vlaktype = null;
            if (entdata) {
                if (entdata.BuildingId !== ntabuilding.buildingId) {
                    return false;
                }
                propdata = prop.getData(entdata);
                vlaktype = entdata.EntityId === 'BEGR' ? entdata.PropertyDatas["BEGR_VLAK"].Value : null;
            }

            let showit = true;
            let relevant = null;
            switch (prop.Id) {
                case "BEGR_VLOER":
                    showit = vlaktype === "VLAK_VLOER" || vlaktype === null; //-- igv entdata===null door headercheck
                    break;
                case "BEGR_VLOER_BOVBUI":
                    showit = vlaktype === "VLAK_VLOER_BOVBUI";
                    break;
                case "BEGR_GEVEL":
                    showit = vlaktype === "VLAK_GEVEL";
                    break;
                case "BEGR_DAK":
                    showit = vlaktype === "VLAK_DAK";
                    break;
                case "BEGR_KWAND":
                    showit = vlaktype === "VLAK_KELDERW";
                    break;
                case "BEGR_DUMMY":
                    {
                        let propVloer = ntabuilding.properties['BEGR_VLOER'];
                        let propDak = ntabuilding.properties['BEGR_DAK'];
                        let propGevel = ntabuilding.properties['BEGR_GEVEL'];
                        let propKelderwand = ntabuilding.properties['BEGR_KWAND'];
                        let propVloerBB = ntabuilding.properties['BEGR_VLOER_BOVBUI'];

                        showit = isHidden(propVloer, entdata) && isHidden(propDak, entdata) && isHidden(propGevel, entdata) && isHidden(propKelderwand, entdata) && isHidden(propVloerBB, entdata);
                        break;
                    }
                case "BEGR_HEL":
                    showit = true;
                    relevant = !isReadOnly(prop, entdata);
                    break;
                case "BEGR_AOS":
                case "BEGR_AOR":
                case "BEGR_VL_OMV":
                    showit = false;
                    break;
            }

            //-- standaard is relevant gelijk aan showit
            if (relevant === null) relevant = showit;
            ntaEntityData.setPropdataRelevancy(propdata, relevant, begrenzingLogic.form_bldbegr);
            ntaEntityData.setPropdataVisibility(propdata, showit);

            return !showit;
        } //-- end: isHidden ------------------------------------------------------------------------//

        function getCodedValues(prop, entdata) {
            let typesList = [];

            if (!entdata || !prop || entdata.BuildingId !== ntabuilding.buildingId) {
                return typesList;
            }

            switch (prop.Id) {
                case 'BEGR_VLAK':
                    {
                        typesList = ntaValidation.codedValues(prop);
                        //-- Conditie [P] geen keldewand keuzen als G04 woonboot of woonwagen is.
                        if (['TGEB_WOONW', 'TGEB_WOONBN', 'TGEB_WOONBB'].includes(ntaSharedLogic.getGebouwType())) {
                            typesList = typesList.filter(t => t.Id !== 'VLAK_KELDERW');
                        }
                        break;
                    }
                case 'BEGR_DAK':
                case 'BEGR_GEVEL':
                case 'BEGR_VLOER':
                case 'BEGR_KWAND':
                    {
                        const vlaktype = entdata.PropertyDatas["BEGR_VLAK"].Value;
                        if (prop.Id !== _vlaktypeBegrenzingPropIds[vlaktype]) {
                            break;
                        }

                        typesList = ntaValidation.codedValues(prop);

                        // conditie [Q] indien versie = v3.1 of hoger verberg de regels waar table8 kolom 'ID v3.1' een waarde heeft
                        if (ntabuilding.ntaVersionId >= 100) {
                            const reflecterendeFolieOnderVloer = new Set([
                                'VL_MV_KR_FVL',     // 15. op/boven mv; boven kruipruimte, incl. refl. folie onder vloer
                                'VL_MV_ONVK_FVL',   // 18. op/boven mv; boven onverwarmde kelder, incl. refl. folie onder vloer
                                'VL_OMV_KR_FVL',    // 20. onder mv; boven kruipruimte, incl. refl. folie onder vloer
                                'VL_OMV_ONVK_FVL',  // 23. onder mv; boven onverwarmde kelder, incl. refl. folie onder vloer
                            ]);
                            typesList = typesList.filter(x => !reflecterendeFolieOnderVloer.has(x.Id));
                        }

                        let newKeuzes = typesList.map(codedval => ({
                            "Id": codedval.Id,
                            "Value": codedval.Value,
                            "ImageUrl": null,
                            "FilterValue1": codedval.FilterValue1,
                            "FilterValue2": codedval.FilterValue2,
                            "FilterValue3": codedval.FilterValue3,
                            "FilterValue4": codedval.FilterValue4
                        }));

                        //-- wanneer thermische koudebruggen NIET forfaitair is, kunnen de vlakken grenzen aan een AOR of AOS. Wanneer WEL forfaitair
                        //-- altijd begrenzing AOR_FOR toevoegen
                        //-- VO 2022-02-23: In overleg met Remi en Martin is het vanaf 3.0.19.2 en 3.1.0.1 niet meer mogelijk om aan een AOR of AOS te grenzen 
                        //-- als de zone zelf van type AOR of AOS is. Dan hoeft de maakAORAOSBegrenzingen() niet aangeroepen te worden.
                        if (!_isAORofAOS) {
                            const thermBruggForfaitair = settingsMenuData.getSetting('SETTINGS_THBRUG').Value === "True";
                            if (!thermBruggForfaitair) {
                                //-- conditie [M] als Z03 = 'AOR' of 'AOS' verberg in table8 alle rijen met key1='aor' of 'aos'
                                const rzs = ntaEntityData.getListWithEntityId("RZ");
                                for (const rz of rzs) {
                                    if (rz !== _rz) {
                                        const zoneType = rz.PropertyDatas["RZ_TYPEZ"].Value;
                                        newKeuzes = newKeuzes.concat(maakAORAOSBegrenzingen(prop, zoneType, rz));
                                    }
                                }
                            }
                            //-- geen conditie [J] meer. Altijd AOR_FOR en AOS_FOR toevoegen. Ook al ben ik therm bruggen uitgebreid
                            const list = maakAORAOSBegrenzingen(prop, "AOS").concat(maakAORAOSBegrenzingen(prop, 'AOR'));
                            newKeuzes = newKeuzes.concat(list);
                        }

                        if (prop.Id === 'BEGR_KWAND') {
                            const vloeren = vlakken()
                                .filter(vlak => vlak.PropertyDatas["BEGR_VLAK"].Value === "VLAK_VLOER") //-- selecteer vloeren
                                .filter(vloer => vloer.PropertyDatas["BEGR_VLOER"].Value.includes("VL_OMV")); //-- selecteer vloeren onder maaiveld

                            for (const vloer of vloeren) {
                                let omschr = vloer.PropertyDatas["BEGR_OMSCHR"].Value;
                                omschr = omschr !== null ? omschr : "";
                                newKeuzes.push({ "Id": vloer.EntityDataId + "+", "Value": "grond; " + omschr, "ImageUrl": null, "FilterValue2": "90", "FilterValue3": "1", "FilterValue4": "179" });
                            }

                            if (vloeren.length === 0) {
                                newKeuzes.push({ "Id": "GEENVL_ONDERMV", "Value": "grond; maak eerst een vloer onder MV aan", "ImageUrl": null, "FilterValue2": "90", "FilterValue3": "1", "FilterValue4": "179" });
                            }
                        }

                        //-- zijn ze hetzelfde dan niks wijzigen -> anders $digest() iterations error
                        if (JSON.stringify(newKeuzes) !== JSON.stringify(_begrenzingKeuzes[prop.Id])) {
                            _begrenzingKeuzes[prop.Id] = newKeuzes;
                        }
                        typesList = _begrenzingKeuzes[prop.Id];

                        //-- bij deze properties is de lijst anders opgebouwd en werkt het anders omdat ze via track id werken met angularjs
                        //-- hierdoor wordt bij de getCodedValues geen check uitgevoerd of de value van de propdata nog wel in de lijst zit.
                        return typesList;
                    }
                default: {
                    typesList = ntaValidation.codedValues(prop);
                    break;
                }
            }

            ntaSharedLogic.checkPropdataInList(prop, entdata, typesList, begrenzingLogic);
            return typesList;
        } //-- end: getCodedValues ------------------------------------------------------------------------//

        function maakAORAOSBegrenzingen(prop, zoneType, rz = null) {
            let results = [];
            //-- functie die begrenzingen voor AOR en AOS genereert. Bij de forfaitaire methode is dat onafhankelijk van een rekenzone. Bij de
            //-- uitgebreide thermische bruggen methode wordt er voor iedere AOS en AOR een nieuwe begrenzing aangemaakt met de guid van de rz in de Id.

            //-- Als een EntityDataId wordt gebruikt in de valueId, zet daar dan altijd een + teken achter, zodat deze in saveSelection herkend wordt
            const rzOmschrijving = rz ? _propRzOmschr.getValue(rz) : "";

            let valueId = "";
            let valueF2 = null;
            let valueF3 = null;
            let valueF4 = null;
            if (zoneType === "AOR") {
                let valueOmschrijving = rz ? "AOR; " + rzOmschrijving : "AOR forfaitair";
                if (prop.Id === "BEGR_VLOER") {
                    valueId = rz ? rz.EntityDataId + "+AOR_VLOER" : "VL_AOR_FOR";
                    valueF2 = "180";
                }
                else if (prop.Id === "BEGR_GEVEL") {
                    valueId = rz ? rz.EntityDataId + "+AOR_GVL" : "GVL_AOR_FOR";
                    valueF2 = "90";
                    valueF3 = "1";
                    valueF4 = "179";
                }
                else if (prop.Id === "BEGR_DAK") {
                    valueId = rz ? rz.EntityDataId + "+AOR_DAK" : "DAK_AOR_FOR";
                    valueF2 = "0";
                }
                if (valueId) {
                    results.push({ "Id": valueId, "Value": valueOmschrijving, "ImageUrl": null, "FilterValue2": valueF2, "FilterValue3": valueF3, "FilterValue4": valueF4 });
                }
            }
            else if (zoneType === "AOS") {
                let valueOmschrijving = rz ? "AOS; " + rzOmschrijving : "AOS forfaitair";

                if (prop.Id === "BEGR_VLOER") {
                    valueId = rz ? rz.EntityDataId + "+AOS_VLOER" : "VL_AOS_FOR";
                    results.push({ "Id": valueId, "Value": valueOmschrijving, "ImageUrl": null, "FilterValue2": "180", "FilterValue3": null, "FilterValue4": null });
                }
                else if ((prop.Id === "BEGR_GEVEL") || (prop.Id === "BEGR_DAK")) {
                    valueId = rz ? rz.EntityDataId + "+" : "FOR_";
                    valueF2 = prop.Id === "BEGR_GEVEL" ? "90" : "45";
                    results.push({ "Id": valueId + "AOS_Z",  "Value": valueOmschrijving + "; Z",  "ImageUrl": null, "FilterValue2": valueF2, "FilterValue3": "1", "FilterValue4": "179" });
                    results.push({ "Id": valueId + "AOS_ZW", "Value": valueOmschrijving + "; ZW", "ImageUrl": null, "FilterValue2": valueF2, "FilterValue3": "1", "FilterValue4": "179" });
                    results.push({ "Id": valueId + "AOS_W",  "Value": valueOmschrijving + "; W",  "ImageUrl": null, "FilterValue2": valueF2, "FilterValue3": "1", "FilterValue4": "179" });
                    results.push({ "Id": valueId + "AOS_NW", "Value": valueOmschrijving + "; NW", "ImageUrl": null, "FilterValue2": valueF2, "FilterValue3": "1", "FilterValue4": "179" });
                    results.push({ "Id": valueId + "AOS_N",  "Value": valueOmschrijving + "; N",  "ImageUrl": null, "FilterValue2": valueF2, "FilterValue3": "1", "FilterValue4": "179" });
                    results.push({ "Id": valueId + "AOS_NO", "Value": valueOmschrijving + "; NO", "ImageUrl": null, "FilterValue2": valueF2, "FilterValue3": "1", "FilterValue4": "179" });
                    results.push({ "Id": valueId + "AOS_O",  "Value": valueOmschrijving + "; O",  "ImageUrl": null, "FilterValue2": valueF2, "FilterValue3": "1", "FilterValue4": "179" });
                    results.push({ "Id": valueId + "AOS_ZO", "Value": valueOmschrijving + "; ZO", "ImageUrl": null, "FilterValue2": valueF2, "FilterValue3": "1", "FilterValue4": "179" });

                    //-- alleen bij daken kan er ook een horizontale begrenzing gelden.
                    if (prop.Id === "BEGR_DAK") {
                        results.push({ "Id": valueId + "AOS_HOR", "Value": valueOmschrijving + "; HOR", "ImageUrl": null, "FilterValue2": "0", "FilterValue3": null, "FilterValue4": null });
                    }
                }
            }
            return results;
        } //-- end:  maakAORAOSBegrenzingen --------------------------------------------------------------------------------//

        function validateKelderwandenVloerenOnderMaailveldAanwezig() {
            //-- Conditie [K]
            const kelderwanden = vlakken().filter(vlak => vlak.PropertyDatas["BEGR_VLAK"].Value === "VLAK_KELDERW"); //selecteer kelderwanden
            const prop = ntabuilding.properties['BEGR_VLAK'];
            for (const kelderwand of kelderwanden) {
                const propdata = prop.getData(kelderwand);
                validate(prop, propdata, kelderwand);
            }
        } //-- end: validateKelderwandenVloerenOnderMaailveldAanwezig ------------------------------------------------------------------------//

        function validateKelderwandenSamenvoegenZelfdeBegrenzing() {
            //-- Conditie [L]
            const kelderwanden = vlakken().filter(vlak => vlak.PropertyDatas["BEGR_VLAK"].Value === "VLAK_KELDERW"); //-- selecteer kelderwanden
            const prop = ntabuilding.properties['BEGR_KWAND'];
            for (const kelderwand of kelderwanden) {
                const propdata = prop.getData(kelderwand);
                validate(prop, propdata, kelderwand);
            }
        } //-- end: validateKelderwandenSamenvoegenZelfdeBegrenzing ------------------------------------------------------------------------//

        function getSelection(prop, entdata) {
            if (!entdata) {
                return;
            }
            //-- Combinatie propdata (coded value) en evt. relatie met AOR/AOS of andere BEGR (igv kelderwand) en ctrl.getPropData(entdata, prop.Id).Value
            const propdata = entdata.PropertyDatas[prop.Id];

            const vlakType = entdata.PropertyDatas["BEGR_VLAK"];
            const parentEntityId = vlakType.Value === "VLAK_KELDERW" ? "BEGR" : "RZ";
            const parent = ntaEntityData.getFirstParent(entdata, parentEntityId);

            if (parent && !_isAORofAOS) {
                if (propdata.Value && propdata.Value !== "n.v.t.") {
                    return { "Value": parent.EntityDataId.toString() + "+" + propdata.Value };
                }
                else if (propdata.Value) {
                    return { "Value": parent.EntityDataId.toString() + "+" };
                }
                return { "Value": parent.EntityDataId.toString() };
            }
            return propdata;
        } //-- end: getSelection ------------------------------------------------------------------------//

        function saveSelection(prop, entdata) {
            const propdata = prop.getData(entdata);
            if (entdata.selectedItem.includes("+")) { //-- selectedItem is relatie met AOR/AOS of andere BEGR (igv kelderwand)
                const [parentId, value] = entdata.selectedItem.split("+"); //selectedItem is combinatie van relatie met value
                //--relatie
                const parent = ntaEntityData.get(parentId); //-- parentId bevat de id van de betreffende zone (AOS/AOR) of BEGR
                if (parent) {
                    //-- Verwijder oude relaties
                    const oldRelations = ntaEntityData.getParentRelations(entdata, parent.EntityId);
                    for (const oldRelation of oldRelations) {
                        ntaEntityData.deleteRelation(oldRelation.EntityRelationDataId);
                    }

                    //-- koppeling toevoegen
                    ntaEntityData.createRelation(parent.EntityDataId, entdata.EntityDataId, 0, 0); //geen cascade -> als RZ wordt gekopieerd wil je niet UnitRZ meekopieren
                }
                //-- evt. value
                if (value) {
                    propdata.Value = value;
                } else {
                    propdata.Value = "n.v.t.";
                }
            } else {
                //-- Verwijder oude relaties
                if (!_isAORofAOS) {
                    const oldRzRelations = ntaEntityData.getParentRelations(entdata, 'RZ');
                    for (const oldRzRelation of oldRzRelations) {
                        ntaEntityData.deleteRelation(oldRzRelation.EntityRelationDataId);
                    }
                }
                const oldBegrRelations = ntaEntityData.getParentRelations(entdata, 'BEGR');
                for (const oldBegrRelation of oldBegrRelations) {
                    ntaEntityData.deleteRelation(oldBegrRelation.EntityRelationDataId);
                }

                //-- Set value
                propdata.Value = entdata.selectedItem;
            }

            saveValue(prop, entdata);
        } //-- end: saveSelection ------------------------------------------------------------------------//

        function calcFormulaF1(begr) {
            let result = 0;
            if (begr) {
                let somAConstrTs = 0;
                //-- alleen als je gevel of dak bent, dan dit doorlopen. -> relaties blijven behouden als je switch van vlaktype, dus de vloer kan relaties hebben met ConstrT's.
                let vlaktype = begr.PropertyDatas['BEGR_VLAK'].Value;
                if (vlaktype === "VLAK_GEVEL" || vlaktype === "VLAK_DAK") {
                    const constrTs = ntaEntityData.getChildren(begr, 'CONSTRT');
                    for (const constrT of constrTs) {
                        const oppervlakte = constrT.PropertyDatas['CONSTRT_OPP'].Value;
                        somAConstrTs += ntaSharedLogic.parseFloat(oppervlakte, 0);
                    }
                }

                const vlakA = begr.PropertyDatas["BEGR_A"].Value;
                result = ntaSharedLogic.parseFloat(vlakA, 0) - somAConstrTs;
            }
            return result;
        } //-- end: calcFormulaF1 ------------------------------------------------------------------------//

        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            switch (prop.Id) {
                case 'BEGR_L':
                case 'BEGR_B':
                    {
                        const propLengte = ntabuilding.properties["BEGR_L"];
                        const propBreedte = ntabuilding.properties["BEGR_B"];
                        const lengte = ntaSharedLogic.parseFloat(propLengte.getValue(entdata));
                        const breedte = ntaSharedLogic.parseFloat(propBreedte.getValue(entdata));
                        if (!isNaN(lengte) && !isNaN(breedte)) {
                            //-- Bereken A
                            const propBegrA = ntabuilding.properties['BEGR_A'];
                            const newAvalue = ntaRounding.roundAndAddZerosNewValue(propBegrA, lengte * breedte);
                            saveValue(propBegrA, entdata, newAvalue);
                        }
                        break;
                    }
                case 'BEGR_A':
                    {
                        //-- Conditie [I], verwijder L en B als A niet gelijk is aan L * B
                        const propBegrA = ntabuilding.properties['BEGR_A'];
                        const propLengte = ntabuilding.properties["BEGR_L"];
                        const propBreedte = ntabuilding.properties["BEGR_B"];
                        const lengte = ntaSharedLogic.parseFloat(propLengte.getValue(entdata));
                        const breedte = ntaSharedLogic.parseFloat(propBreedte.getValue(entdata));
                        const propValueBegrA = propBegrA.getValue(entdata);
                        const oppervlakte = ntaSharedLogic.parseFloat(ntaRounding.roundAndAddZerosNewValue(prop, lengte * breedte));
                        if (oppervlakte !== ntaSharedLogic.parseFloat(propValueBegrA)) {
                            const emptyValue = '';
                            saveValue(propLengte, entdata, emptyValue);
                            saveValue(propBreedte, entdata, emptyValue);
                        }

                        //--updaten constrD_opp
                        //-- VO 2020-11-05: eigenlijk is onderstaande iets dat in de dependencyValidator geregeld moet worden omdat het data uit een andere logic bevat ook
                        //-- omdat ik niet weet wat de validate van deze logic doet met een onbekende entity.
                        const constrDs = ntaEntityData.getChildren(entdata, 'CONSTRD');
                        if (constrDs.length === 1) {
                            const propD_Opp = ntabuilding.properties['CONSTRD_OPP'];
                            const opp = ntaRounding.roundAndAddZerosNewValue(propD_Opp, calcFormulaF1(entdata).toString());
                            saveValue(propD_Opp, constrDs[0], opp);
                        }
                        break;
                    }
                case 'BEGR_VLOER':
                case 'BEGR_VLOER_BOVBUI':
                case 'BEGR_GEVEL':
                case 'BEGR_DAK':
                case 'BEGR_KWAND':
                    {
                        const codedval = begrCodedval(entdata);
                        if (codedval) { //initial_inclination
                            const propHelling = ntabuilding.properties["BEGR_HEL"];
                            const propdataHelling = propHelling.getData(entdata);
                            const hellingDef = parseInt(codedval.FilterValue2);
                            const hellingMin = parseInt(codedval.FilterValue3);
                            const hellingshoek = parseInt(propdataHelling.Value);
                            const notEditable = (isNaN(hellingDef) || isNaN(hellingMin));
                            if (!propdataHelling.Touched || isNaN(hellingshoek) !== notEditable || (notEditable && propdataHelling.Value !== 'n.v.t.')) {
                                let newValue = codedval.FilterValue2;
                                if (notEditable) {
                                    newValue = 'n.v.t.';
                                }
                                saveValue(propHelling, entdata, newValue);
                            }
                        }
                        //-- controleer of het nog steeds goed gaat met de kelderwanden. Als het vlak geen vloer meer is, kan de begrenzing van een kelderwand niet meer kloppen
                        validateKelderwanden();
                        break;
                    }
                case 'BEGR_VLAK':
                    {
                        //-- Bij wijzigen van het type vlak de oude data wissen
                        const propdataDak = entdata.PropertyDatas["BEGR_DAK"];
                        const propdataGevel = entdata.PropertyDatas["BEGR_GEVEL"];
                        const propdataKelderwand = entdata.PropertyDatas["BEGR_KWAND"];
                        const propdataVloer = entdata.PropertyDatas["BEGR_VLOER"];
                        const propdataVloerBB = entdata.PropertyDatas["BEGR_VLOER_BOVBUI"];

                        ntaEntityData.saveprop(propdataDak, '');
                        ntaEntityData.saveprop(propdataGevel, '');
                        ntaEntityData.saveprop(propdataKelderwand, '');
                        ntaEntityData.saveprop(propdataVloer, '');
                        ntaEntityData.saveprop(propdataVloerBB, '');

                        //-- Verwijder oude relaties
                        if (!_isAORofAOS) {
                            const oldRelations = ntaEntityData.getParentRelations(entdata, 'RZ');
                            for (const oldRelation of oldRelations) {
                                ntaEntityData.deleteRelation(oldRelation.EntityRelationDataId);
                            }
                        }

                        //-- controleer of het nog steeds goed gaat met de kelderwanden. Als het vlak geen vloer meer is, kan de begrenzing van een kelderwand niet meer kloppen
                        validateKelderwanden();
                        break;
                    }
            }
        } //-- end: validateDependencies ------------------------------------------------------------------------//

        function startFormValidation() {
            ntaSharedLogic.startFormValidation(getAllEntDatas(), begrenzingLogic);
        } //-- end: startFormValidation ------------------------------------------------------------------------//

        function endFormValidation() {
            ntaSharedLogic.endFormValidation(getAllEntDatas(), begrenzingLogic);
        } //-- end: endFormValidation ------------------------------------------------------------------------//

        function getAllEntDatas() { // Geeft alle entdatas in één array terug
            return []
                .concat(_begrForm)
                .concat(vlakken())
                .filter(entdata => entdata) // filter alle nulls en undefineds eruit
                ;
        } //-- end: getAllEntDatas ------------------------------------------------------------------------//
    }
    return BegrenzingLogic;
}]);