﻿angular.module('projectModule').controller("BuildingLibraryController", ['$scope', '$controller', 'ntaValidation', 'ntaEntityData', 'ntaMeldingen', 'ntaDependencyValidation', 'BuildingLibraryFactory', 'ntaFindAndReplace',
function                                                                 ($scope,   $controller,   ntaValidation,   ntaEntityData,   ntaMeldingen,   ntaDependencyValidation,   BuildingLibraryFactory,   ntaFindAndReplace) {
    const base = $controller('NTABaseController', { $scope: $scope })
    const vm = angular.extend(this, base);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    vm.ntaValidation = ntaValidation;

    const libraryLogic = new BuildingLibraryFactory(ntaDependencyValidation);
    vm.getLibraryLogic = libraryLogic;

    /* Properties *************************************************************************************************************************************************************/
    vm.libformulierproperties = libraryLogic.libformulierproperties;
    vm.libclosedconstructionsproperties = libraryLogic.libclosedconstructionsproperties;
    vm.libtranspconstructionsproperties = libraryLogic.libtranspconstructionsproperties;
    vm.liblinconstructionsproperties = libraryLogic.liblinconstructionsproperties;
    vm.libthermbrugproperties = libraryLogic.libthermbrugproperties;

    /* Data *************************************************************************************************************************************************************/
    vm.libClosedConstructions = libraryLogic.libClosedConstructions;
    vm.libTranspConstructions = libraryLogic.libTranspConstructions;
    vm.libLinConstructions = libraryLogic.libLinConstructions;
    vm.libThermbruggen = libraryLogic.libThermbruggen;
    vm.libFormulier = libraryLogic.libFormulier;

    //-- voor de ui-sortable
    vm.libClosedConstrList = []; //-- array die alle dichte constructies bijhoudt
    vm.libTranspConstrList = []; //-- array die alle transparante constructies bijhoudt
    vm.libLineairConstrList = []; //-- array die alle lineaire koudebruggen bijhoudt
    vm.libThermisConstrList = []; //-- array die alle thermische koudebruggen bijhoudt

    vm.getCodedValues = libraryLogic.getCodedValues;
    vm.isReadOnly = libraryLogic.isReadOnly;
    vm.isHiddenProp = libraryLogic.isHiddenProp;
    vm.isHidden = libraryLogic.isHidden;
    vm.checkConditionD = libraryLogic.checkConditionD;

    vm.saveValue = saveValue;
    vm.getForm = getForm;
    vm.getPlaceholder = getPlaceholder;

    vm.addLibClosedConstructionProgress = false;
    vm.addLibTranspConstructionProgress = false;
    vm.addLibLinConstructionProgress = false;
    vm.addLibThermBrugProgress = false;
    vm.deleteUnusedConstructionProgress = false;

    vm.addLibLinConstruction = addLibLinConstruction;
    vm.addLibClosedConstruction = addLibClosedConstruction;
    vm.addLibTranspConstruction = addLibTranspConstruction;
    vm.addLibThermBrug = addLibThermBrug;

    vm.copyConstruction = copyConstruction;
    vm.deleteConstruction = deleteConstruction;
    vm.deleteUnusedConstruction = deleteUnusedConstruction;
    vm.showFindAndReplaceDialog = ntaFindAndReplace.showFindAndReplaceDialog;

    $scope.endFormValidation = function () {
        libraryLogic.endFormValidation();
    };


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INITALIZIATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    angular.element(function () { //alle touched properties valideren na het laden van form
        libraryLogic.startFormValidation();
        vm.initializeUnitSpacing();
    });

    if (vm.libformulier) {
        vm.setGeopend('LIBCONSTRFORM_OPEN', vm.libformulier);
    }
    updateLists();

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function saveValue(prop, entdata) {
        libraryLogic.saveValue(prop, entdata);
        base.initializeUnitSpacing();
    } //-- end: saveValue ------------------------------------------------------------------------//

    function getForm() {
        return libraryLogic.form_bldlib;
    } //-- end: getForm ------------------------------------------------------------------------//

    function getPlaceholder(prop, entdata) {
        return base.getPlaceholder(prop, entdata, libraryLogic);
    } //-- end: getPlaceholder ------------------------------------------------------------------------//

    function updateLists() {
        //-- onderstaande lijst zijn voor de ng-model binding van de sortabletabellen. Dit moeten namelijk assignable expressions zijn. Als ze een functie zijn doordat ze
        //-- iedere keer opnieuw opgehaald worden in de de factory gaat dat niet goed. Daarom expliciet in de controller bij houden.
        vm.libClosedConstrList = vm.libClosedConstructions();
        vm.libTranspConstrList = vm.libTranspConstructions();
        vm.libLineairConstrList = vm.libLinConstructions();
        vm.libThermisConstrList = vm.libThermbruggen();

    } //-- end: updateLists ------------------------------------------------------------------------//

    function addLibClosedConstruction(entdata) {
        vm.addLibClosedConstructionProgress = true;

        libraryLogic.createLibClosedConstruction(entdata);
        updateLists();

        vm.addLibClosedConstructionProgress = false;
    } //-- end: addLibClosedConstruction ------------------------------------------------------------------------//

    function addLibTranspConstruction() {
        vm.addLibTranspConstructionProgress = true;

        libraryLogic.createLibTranspConstruction();
        updateLists();

        vm.addLibTranspConstructionProgress = false;
    } //-- end: addLibTranspConstruction ------------------------------------------------------------------------//

    function addLibLinConstruction() {
        vm.addLibLinConstructionProgress = true;

        libraryLogic.createLibLinConstruction();
        updateLists();

        vm.addLibLinConstructionProgress = false;
    } //-- end: addLibLinConstruction ------------------------------------------------------------------------//

    function addLibThermBrug() {
        vm.addLibThermBrugProgress = true;

        libraryLogic.createLibThermBrug();
        updateLists();

        vm.addLibThermBrugProgress = false;
    } //-- end: addLibThermBrug ------------------------------------------------------------------------//

    function copyConstruction(entdata) {
        if (!entdata) {
            return;
        }

        let newEntdatas = ntaEntityData.copy(entdata.EntityDataId);
        let constr = newEntdatas.find(function (x) { return x.EntityId === entdata.EntityId; });

        //--- Propdatas op touched zetten
        constr.PropertyDatas.forEach(function (propdata, index) {
            propdata.Touched = true;
            ntaEntityData.saveprop(propdata);
        });

        updateLists();
    } //-- end: copyConstruction ------------------------------------------------------------------------//

    function deleteUnusedConstruction(libconstructions) {
        vm.deleteUnusedConstructionProgress = true;

        //-- bekijk voor alle libconstructies of ze gekozen zijn door een project constructie. Als dit niet het geval is
        //-- dan kan de libconstructie verwijderd worden.
        for (const libconstr of libconstructions) {
            const hasRelevantChildren = ntaEntityData.getChildren(libconstr).some(x => x.Relevant);
            if (!hasRelevantChildren) {
                deleteConstruction(libconstr)
            }
        }

        updateLists();

        vm.deleteUnusedConstructionProgress = false;
    } //-- end: deleteUnusedConstruction ------------------------------------------------------------------------//

    function deleteConstruction(entdata) {
        if (!entdata) {
            return;
        }
        let warning = "";
        let bLaatsteConstr = true;
        /// controleer of de libconstructie gebruikt wordt door constructies die relevant zijn.
        let hasRelevantChildren = ntaEntityData.getChildren(entdata).some(x => x.Relevant);
        switch (entdata.EntityId) {
            case 'LIBCONSTRD': {
                warning = "[W035]";
                bLaatsteConstr = vm.libClosedConstrList.length <= 1;
                break;
            }
            case 'LIBCONSTRT': {
                warning = "[W032]";
                bLaatsteConstr = vm.libTranspConstrList.length <= 1;
                break;
            }
            case 'LIBCONSTRL': {
                warning = "[W033]";
                bLaatsteConstr = vm.libLineairConstrList.length <= 1;
                break;
            }
            case 'LIBTHBRUG': {
                bLaatsteConstr = false; //-- thermische bruggen is niet verplicht
                break;
            }
            default: {
                hasRelevantChildren = false;
                break;
            }
        }
        if (bLaatsteConstr) { //-- kan de laatste niet altijd weggooien
            ntaMeldingen.warning(warning, 'Verwijderen niet toegestaan');
            return;
        }

        //-- check of constr wordt gebruikt. Zo ja, niet verwijderen
        if (!hasRelevantChildren) {
            ntaEntityData.delete(entdata.EntityDataId);
            updateLists();
        } else {
            ntaMeldingen.warning("[W004]");
        }

    } //-- end: deleteConstruction ------------------------------------------------------------------------//

    vm.sortableOptionsD = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            ntaEntityData.SaveReOrder(vm.libClosedConstrList);
        }
    };

    vm.sortableOptionsT = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            ntaEntityData.SaveReOrder(vm.libTranspConstrList);
        }
    };

    vm.sortableOptionsL = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            ntaEntityData.SaveReOrder(vm.libLineairConstrList);
        }
    };

    vm.sortableOptionsTB = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            ntaEntityData.SaveReOrder(vm.libThermisConstrList);
        }
    };

}]);