﻿angular.module('projectModule')
    .factory('BuildingData',
        ['$log',
function ($log) {
    'use strict';

    // ntaData moet expliciet meegegeven worden aan de constructor, omdat we anders last krijgen
    //  van kringverwijzingen.
    return function BuildingData(ntaData, options = null) {
        const self = this;

        /// == Description ========================================================================

        /// Een BuildingData bevat alle EntityDatas (met PropertyDatas) en RelationDatas van een
        ///  berekening. Dit kan ook een schaduwkopie zijn; in dat geval bevat de property
        ///  ‘shadowId’ de EntityDataId van de MEASURE of VARIANT waar deze schaduwkopie bij hoort.
        /// Net als BuildingData op de server biedt deze class ook allerlei functies om entdatas
        ///  en reldatas te benaderen en te doorzoeken, op een snelle en efficiënte manier (dankzij
        ///  de opzoekcache).
        /// Let erop dat de add- en remove-methods ALLEEN binnen deze class hun werk doen, en dus
        ///  niets naar de server sturen. Gebruik ntaEntityData als dat laatste ook moet gebeuren.


        /// == Imports ============================================================================

        const orderByBuildingEntityIdAndOrder = ntaData.orderByBuildingEntityIdAndOrder;
        const orderByEntityIdAndOrder = ntaData.orderByEntityIdAndOrder;


        /// == Instance variables =================================================================

        const _entdatas = [];
        const _reldatas = [];
        const _cache = {
            entdatas: new Map(),
            reldatas: new Map(),
        };


        /// == Exports ============================================================================

        // alle public members definiëren
        Object.assign(self, {
            // -- fields --
            shadowId: options && options.shadowId,
            partial: false,

            // -- methods --
            // laden/lossen
            load,
            unload,
            clone,

            addEntdata,
            removeEntdata,
            addReldata,
            removeReldata,
            addReldataToCache,
            removeReldataFromCache,

            // controleren
            checkData,

            // opvragen/navigeren
            get,
            getList,
            getFirstWithEntityId,
            getListWithEntityId,
            getRelationById,
            getRelations,
            isRelation,
            getRelation,
            getRelationsBetween,
            getFirstParent,
            getFirstChild,
            getChildRelations,
            getChildIds,
            getChildren,
            getParentRelations,
            getParentIds,
            getParents,
            getDescendants,
            getWithDescendants,
            findEntities,
            findEntity,
            getShadowId,
        });

        // entdatas en reldatas zijn ook public properties, maar read-only.
        Object.defineProperties(self, {
            entdatas: {
                enumerable: true,
                value: _entdatas,
                writable: false,
            },
            reldatas: {
                enumerable: true,
                value: _reldatas,
                writable: false,
            },
        });


        /// == Initialization =====================================================================

        // ...


        /// == Implementation =====================================================================

        /// --- LADEN/LOSSEN ------------------------------------------------------------------ ///

        function load(entdatas, reldatas) {
            entdatas.sort(orderByEntityIdAndOrder);

            // PropertyDatas indexeren op PropertyId
            for (const entdata of entdatas) {
                entdata.PropertyDatas.sort((a, b) => ntaData.properties[a.PropertyId].Order - ntaData.properties[b.PropertyId].Order);
                for (const propdata of entdata.PropertyDatas) {
                    entdata.PropertyDatas[propdata.PropertyId] = propdata;
                }
            }

            // EntityDatas cachen op EntityDataId en op EntityId
            for (const entdata of entdatas) {
                addEntdataToCache(entdata);
            }

            // RelationDatas cachen op Parent en Child
            for (const rel of reldatas) {
                addReldataToCache(rel);
            }

            // Data toevoegen
            _entdatas.length = 0;
            for (const entdata of entdatas) {
                _entdatas.push(entdata);
            }

            _reldatas.length = 0;
            for (const reldata of reldatas) {
                _reldatas.push(reldata);
            }

            // Zorg dat _entdatas goed gesorteerd is; dat is van belang voor het snel invoegen
            _entdatas.sort(orderByBuildingEntityIdAndOrder);
            _reldatas.sort(orderRelationsById);
        } //-- end: load --------------------------------------------------------------------------

        function unload(buildingId) {
            deleteFromArray(_entdatas, entdata => removeEntdataFromCache(entdata.EntityDataId));
            deleteFromArray(_reldatas, reldata => removeReldataFromCache(reldata));

            function deleteFromArray(array, removeFromCache = item => undefined) {
                let deleteCount = 0;
                for (let i = array.length - 1; i >= 0; i--) {
                    const item = array[i];
                    if (item.BuildingId == buildingId) {
                        deleteCount++;
                        removeFromCache(item);
                    } else if (deleteCount > 0) {
                        array.splice(i + 1, deleteCount);
                        deleteCount = 0;
                    }
                }
                if (deleteCount > 0) {
                    array.splice(0, deleteCount);
                }
            }
        } //-- end: unload ------------------------------------------------------------------------

        function clone(options = null) {
            const entityIds = (options && options.entityIds) ? new Set(options.entityIds) : null;
            const exceptEntityIds = (options && options.exceptEntityIds) ? new Set(options.exceptEntityIds) : null;

            const copiedEntdatas = [];
            for (const entdata of _entdatas) {
                if (entityIds && !entityIds.has(entdata.EntityId)) continue;
                if (exceptEntityIds && exceptEntityIds.has(entdata.EntityId)) continue;

                const copiedEntdata = Object.assign({}, entdata, {
                    PropertyDatas: entdata.PropertyDatas.map(propdata => Object.assign({}, propdata))
                });
                for (const propdata of copiedEntdata.PropertyDatas) {
                    copiedEntdata.PropertyDatas[propdata.PropertyId] = propdata;
                }
                copiedEntdatas.push(copiedEntdata);
            }

            const copiedReldatas = _reldatas
                .filter(reldata => !entityIds || entityIds.has(reldata.ParentEntityId) || entityIds.has(reldata.ChildEntityId))
                .filter(reldata => !exceptEntityIds || !exceptEntityIds.has(reldata.ParentEntityId) && !exceptEntityIds.has(reldata.ChildEntityId))
                .map(reldata => Object.assign({}, reldata));

            const clone = new BuildingData(ntaData, options);
            clone.load(copiedEntdatas, copiedReldatas);
            clone.partial = !!entityIds;

            return clone;
        } //-- end: clone -------------------------------------------------------------------------


        /// --- WIJZIGEN ---------------------------------------------------------------------- ///

        function addEntdata(entdata, preventDuplicates = false) {
            const index = insertInSortedArray(_entdatas, entdata, orderByBuildingEntityIdAndOrder);
            if (preventDuplicates) {
                const previous = _entdatas[index - 1];
                if (previous && previous.EntityDataId === entdata.EntityDataId) {
                    _entdatas.splice(index - 1, 1);
                }
            }
            addEntdataToCache(entdata, preventDuplicates);
        } //-- end: addEntdata --------------------------------------------------------------------

        function removeEntdata(entdataId, data = { entitydatas: new Set(), relationdatas: new Set() }) {

            const entdata = get(entdataId) || _entdatas.find(ed => ed.EntityDataId === entdataId);

            const success = !!entdata;
            if (success) {

                //Eerst alles verzamelen voor server
                data.entitydatas.add(entdata);
                const reldatas = getRelations(entdataId);
                for (const reldata of reldatas) {
                    data.relationdatas.add(reldata);
                }

                //Dan evt. children verwijderen.
                const childRels = reldatas.filter(rel => rel.Parent === entdataId);
                for (const reldata of childRels) {
                    if (reldata.OnDelete) {
                        removeEntdata(reldata.Child, data);
                    }
                    removeReldata(reldata.EntityRelationDataId);
                }

                //Verwijderen uit _entdatas
                const index = binarySearch(_entdatas, entdata, orderByBuildingEntityIdAndOrder);
                if (index >= 0) {
                    _entdatas.splice(index, 1);
                }

                //relaties verwijderen
                const parentRels = reldatas.filter(rel => rel.Child === entdataId);
                for (const reldata of parentRels) {
                    removeReldata(reldata.EntityRelationDataId);
                }
            }

            // en uiteindelijk verwijderen uit de cache
            removeEntdataFromCache(entdataId);

            return {
                success,
                // maak er gewone arrays van (ipv Sets)
                entitydatas: [...data.entitydatas],
                relationdatas: [...data.relationdatas],
            };
        } //-- end: removeEntdata -----------------------------------------------------------------

        function addReldata(reldata, preventDuplicates = false) {
            const index = insertInSortedArray(_reldatas, reldata, orderRelationsById);
            if (preventDuplicates) {
                const previous = _reldatas[index - 1];
                if (previous && previous.EntityRelationDataId === reldata.EntityRelationDataId) {
                    _reldatas.splice(index - 1, 1);
                }
            }
            addReldataToCache(reldata, preventDuplicates);
        } //-- end: addReldata --------------------------------------------------------------------

        function removeReldata(reldataId) {
            const reldata = getRelationById(reldataId);
            const index = reldata ? binarySearch(_reldatas, reldata, orderRelationsById) : -1;
            if (index > -1) {
                _reldatas.splice(index, 1);
            }
            if (reldata) {
                removeReldataFromCache(reldata);
            }
            return reldata;
        } //-- end: removeReldata -----------------------------------------------------------------


        /// --- CACHE BIJWERKEN --------------------------------------------------------------- ///

        function addEntdataToCache(entdata, preventDuplicates = false) {
            _cache.entdatas.set(entdata.EntityDataId, entdata);
            const key = entdata.BuildingId + entdata.EntityId;
            const entdataList = _cache.entdatas.get(key) || [];
            if (entdataList.length === 0) {
                _cache.entdatas.set(key, entdataList);
            }
            entdataList.push(entdata);
            if (preventDuplicates) {
                const index = entdataList.findIndex(ed => ed !== entdata && ed.EntityDataId === entdata.EntityDataId);
                if (index > -1) {
                    entdataList.splice(index, 1);
                }
            }
        } //-- end: addEntdataToCache -------------------------------------------------------------

        function removeEntdataFromCache(entdataId) {
            const entdata = _cache.entdatas.get(entdataId);
            if (entdata) {
                const key = entdata.BuildingId + entdata.EntityId;
                const list = _cache.entdatas.get(key) || [];
                const index = list.findIndex(ed => ed.EntityDataId === entdataId);
                if (index > -1) list.splice(index, 1);
            }
            _cache.entdatas.delete(entdataId);
            // ook verwijderen uit relaties met andere entdatas
            for (const reldata of _cache.reldatas.get(entdataId) || []) {
                removeReldataFromCache(reldata);
            }
            _cache.reldatas.delete(entdataId); // deze zou nu leeg moeten zijn
        } //-- end: removeEntdataFromCache --------------------------------------------------------

        function addReldataToCache(reldata, preventDuplicates = false) {
            preventDuplicates &= _cache.reldatas.has(reldata.EntityRelationDataId);
            _cache.reldatas.set(reldata.EntityRelationDataId, reldata);

            const childRelations = _cache.reldatas.get(reldata.Parent) || [];
            if (childRelations.length === 0) {
                _cache.reldatas.set(reldata.Parent, childRelations);
            }
            const childIndex = insertInSortedArray(childRelations, reldata, orderRelationsById);
            if (preventDuplicates) {
                const previous = childRelations[childIndex - 1];
                if (previous && previous.EntityRelationDataId === reldata.EntityRelationDataId) {
                    childRelations.splice(childIndex - 1, 1);
                }
            }

            if (reldata.Child !== reldata.Parent) {
                const parentRelations = _cache.reldatas.get(reldata.Child) || [];
                if (parentRelations.length === 0) {
                    _cache.reldatas.set(reldata.Child, parentRelations);
                }
                const parentIndex = insertInSortedArray(parentRelations, reldata, orderRelationsById);
                if (preventDuplicates) {
                    const previous = parentRelations[parentIndex - 1];
                    if (previous && previous.EntityRelationDataId === reldata.EntityRelationDataId) {
                        parentRelations.splice(parentIndex - 1, 1);
                    }
                }
            }
        } //-- end: addReldataToCache -------------------------------------------------------------

        function removeReldataFromCache(reldata) {
            _cache.reldatas.delete(reldata.EntityRelationDataId);

            const childRelations = _cache.reldatas.get(reldata.Parent) || [];
            const index = binarySearch(childRelations, reldata, orderRelationsById);
            if (index > -1) childRelations.splice(index, 1);

            if (reldata.Child !== reldata.Parent) {
                const parentRelations = _cache.reldatas.get(reldata.Child) || [];
                const index = binarySearch(parentRelations, reldata, orderRelationsById);
                if (index >= 0) parentRelations.splice(index, 1);
            }
        } //-- end: removeReldataFromCache --------------------------------------------------------


        /// --- OPVRAGEN/NAVIGEREN ------------------------------------------------------------ ///

        function get(entityDataId) {
            return _cache.entdatas.get(entityDataId);
        } //-- end: get ---------------------------------------------------------------------------

        function getList(entityDataIds) {
            return Array.from(new Set(entityDataIds)) // zorg ervoor dat getList nooit duplicaten teruggeeft
                .map(id => _cache.entdatas.get(id))
                .filter(ed => ed) // zorg ervoor dat getList nooit undefined teruggeeft in z’n array
                .sort(orderByEntityIdAndOrder);
        } //-- end: getList------------------------------------------------------------------------

        function getFirstWithEntityId(entityId, buildingId = ntaData.buildingId, skipSorting = false) {
            return getListWithEntityId(entityId, buildingId, skipSorting)[0];
        } //-- end: getFirstWithEntityId ----------------------------------------------------------

        function getListWithEntityId(entityId, buildingId = ntaData.buildingId, skipSorting = false) {
            if (!buildingId) {
                return ntaData.projecttree.Gebouwberekeningen
                    .map(b => b.GebouwId)
                    .flatMap(bldId => getListWithEntityId(entityId, bldId, skipSorting));
            } else if (Array.isArray(entityId)) {
                return entityId.flatMap(entId => getListWithEntityId(entId, buildingId, skipSorting))
                    .sort(orderByEntityIdAndOrder);
            }
            const key = buildingId + entityId;
            const entdatas = _cache.entdatas.get(key) || [];
            if (!skipSorting) {
                entdatas.sort(orderByEntityIdAndOrder);
            }
            return entdatas.slice();
        } //-- end: getListWithEntityId -----------------------------------------------------------

        function getRelationById(relationDataId) {
            return _cache.reldatas.get(relationDataId);
        } //-- end: getRelationById ---------------------------------------------------------------

        function isRelation(parentOrId, childOrId) {
            return !!getRelation(parentOrId, childOrId);
        } //-- end: isRelation --------------------------------------------------------------------

        function getRelation(parentOrId, childOrId) {
            const parentId = parentOrId && parentOrId.EntityDataId || parentOrId;
            const childId = childOrId && childOrId.EntityDataId || childOrId;
            return getRelations(parentOrId)
                .find(rel => rel.Parent === parentId && rel.Child === childId);
        } //-- end: getRelation -------------------------------------------------------------------

        function getRelationsBetween(parentOrId, childOrId) {
            const parentId = parentOrId && parentOrId.EntityDataId || parentOrId;
            const childId = childOrId && childOrId.EntityDataId || childOrId;
            return getRelations(parentOrId)
                .filter(rel => rel.Parent === parentId && rel.Child === childId);
        } //-- end: getRelationsBetween -----------------------------------------------------------

        function getFirstParent(entityDataOrId, parentEntityId = "", crawl = false, exclParentEntityId = "") {
            const firstParent = getParents(entityDataOrId, parentEntityId)[0];
            if (firstParent) {
                return firstParent;
            } else if (parentEntityId && crawl) { // doorzoeken
                const reldatas = getParentRelations(entityDataOrId).filter(rel => rel.ParentEntityId !== exclParentEntityId);
                for (const rel of reldatas) {
                    if (rel.ParentEntityId === parentEntityId) {
                        return get(rel.Parent);
                    } else {
                        const firstParent = getFirstParent(rel.Parent, parentEntityId, crawl, exclParentEntityId);
                        if (firstParent) {
                            return firstParent;
                        }
                    }
                }
            }
        } //-- end: getFirstParent ----------------------------------------------------------------

        function getFirstChild(entityDataOrId, childEntityId = "") {
            return getChildren(entityDataOrId, childEntityId)[0];
        } //-- end: getFirstChild -----------------------------------------------------------------

        function getRelations(entityDataOrId) {
            const entityDataId = entityDataOrId && entityDataOrId.EntityDataId || entityDataOrId;
            return _cache.reldatas.get(entityDataId) || [];
        } //-- end: getRelations ------------------------------------------------------------------

        function getChildRelations(entityDataOrId, childEntityId = "") {
            const entityDataId = entityDataOrId && entityDataOrId.EntityDataId || entityDataOrId;
            let relations = getRelations(entityDataOrId).filter(rel => rel.Parent === entityDataId);
            if (childEntityId) {
                if (Array.isArray(childEntityId)) {
                    childEntityId = new Set(childEntityId);
                }
                if (childEntityId instanceof Set) {
                    relations = relations.filter(rel => childEntityId.has(rel.ChildEntityId));
                } else {
                    relations = relations.filter(rel => rel.ChildEntityId === childEntityId);
                }
            }
            return relations;
        } //-- end: getChildRelations -------------------------------------------------------------

        function getChildIds(entityDataOrId, childEntityId = "") {
            let relations = getChildRelations(entityDataOrId, childEntityId);
            return relations.map(rel => rel.Child);
        } //-- end: getChildIds -------------------------------------------------------------------

        function getChildren(entityDataOrId, childEntityId = "") {
            const childIds = getChildIds(entityDataOrId, childEntityId);
            const children = getList(childIds);
            return children;
        } //-- end: getChildren -------------------------------------------------------------------

        function getParentRelations(entityDataOrId, parentEntityId = "") {
            const entityDataId = entityDataOrId && entityDataOrId.EntityDataId || entityDataOrId;
            let relations = getRelations(entityDataOrId).filter(rel => rel.Child === entityDataId);
            if (parentEntityId) {
                if (Array.isArray(parentEntityId)) {
                    parentEntityId = new Set(parentEntityId);
                }
                if (parentEntityId instanceof Set) {
                    relations = relations.filter(rel => parentEntityId.has(rel.ParentEntityId));
                } else {
                    relations = relations.filter(rel => rel.ParentEntityId === parentEntityId);
                }
            }
            return relations;
        } //-- end: getParentRelations ------------------------------------------------------------

        function getParentIds(entityDataOrId, parentEntityId = "") {
            let relations = getParentRelations(entityDataOrId, parentEntityId);
            return relations.map(rel => rel.Parent);
        } //-- end: getParentIds ------------------------------------------------------------------

        function getParents(entityDataOrId, parentEntityId = "") {
            const parentIds = getParentIds(entityDataOrId, parentEntityId);
            return getList(parentIds);
        } //-- end: getParents --------------------------------------------------------------------

        function getDescendants(entityDataOrId, predicate = descendant => true, set = new Set()) {
            const descendants = [];

            const entityDataId = entityDataOrId && entityDataOrId.EntityDataId || entityDataOrId;
            set.add(entityDataId);

            for (const childEntdata of getChildren(entityDataOrId)) {
                if (!set.has(childEntdata.EntityDataId) && predicate(childEntdata)) {
                    descendants.push(childEntdata);
                    descendants.push(...getDescendants(childEntdata, predicate, set));
                }
            }

            return descendants;
        } //-- end: getDescendants ----------------------------------------------------------------

        function getWithDescendants(entityDataOrId, predicate = descendant => true) {
            const descendants = getDescendants(entityDataOrId, predicate);
            const entdata = typeof entityDataOrId === 'string'
                ? get(entityDataOrId)
                : entityDataOrId;
            if (entdata) {
                descendants.unshift(entdata);
            }
            return descendants;
        } //-- end: getWithDescendants ------------------------------------------------------------


        // JS port van BuildingData.FindEntities:
        /// Zoekt één of meer <see cref="IEntityData"/>s op, beginnend bij <paramref name="startED"/>, en het hele pad van <paramref name="paths"/> doorlopend.
        /// </summary>
        /// <param name="startED">Het uitgangspunt van de zoektocht.</param>
        /// <param name="paths">
        /// Eén of meer paden die afgezocht worden. Een pad is een string die bestaat uit EntityCodes, gescheiden door ‘.’ een punt.
        /// Standaard wordt elke EntityCode gezocht onder de Children; om de Parents te doorzoeken moet een ‘^’ circumflex links van de EntityCode gezet worden.
        /// Wanneer een entity relevant moet zijn zet dan een '!' uitroepteken na de betreffende EntityCode.
        /// <para>Voorbeeld:</para>
        /// <c>UNIT-RZ.^RZ.VENT.VENTAAN!</c> zal UNIT-RZ opzoeken in de Children van <paramref name="startED"/>,
        /// dan <c>RZ</c> in de parents daarvan, dan <c>VENT</c> in de children van de <c>RZ</c>, en uiteindelijk <c>VENTAAN</c> in de children van <c>VENT</c>.
        /// De functie retourneert hier dus een lijst van <c>VENTAAN</c>-entiteiten, indien de VENTAAN entiteiten relevant zijn.
        /// <para>Elk afzonderlijk pad in <paramref name="paths"/> start weer bij <paramref name="startED"/>.</para>
        /// </param>
        /// <returns>Een lijst met alle unieke gevonden <see cref="NTAEntityData"/>s.</returns>
        function findEntities(entityDataOrId, ...paths) {
            const startEntdata = entityDataOrId && (entityDataOrId.EntityDataId && entityDataOrId || get(entityDataOrId));
            if (!startEntdata) return [];

            const rexEntityPath = /(^|\.?|\^)([\w-]+)(!)?(?:\[(\d+)\])?(?=\.|\^|$)/g;

            const results = [];
            for (const path of paths) {
                let entdatas = [startEntdata];
                const pathSections = path.matchAll(rexEntityPath);
                for (const pathSection of pathSections) {
                    const shouldGetParents = pathSection[1] === "^";
                    const getRelatedEntdatas = shouldGetParents ? getParents : getChildren;
                    const entityId = pathSection[2];
                    const mustBeRelevant = !!pathSection[3];
                    const number = parseInt(pathSection[4]);
                    entdatas = entdatas.flatMap(baseEntdata => {
                        let relatedEntdatas = getRelatedEntdatas(baseEntdata, entityId);
                        if (mustBeRelevant) {
                            relatedEntdatas = relatedEntdatas.filter(ed => ed.Relevant);
                        }
                        if (isNaN(number)) {
                            return relatedEntdatas;
                        } else {
                            return relatedEntdatas.slice(number, number + 1);
                        }
                    });
                }
                results.push(...entdatas);
            }
            return [...new Set(results)]; // zorg dat we geen dubbelen teruggeven
        } //-- end: findEntities ------------------------------------------------------------------

        function findEntity(entityDataOrId, ...paths) {
            return findEntities(entityDataOrId, ...paths)[0];
        } //-- end: findEntity --------------------------------------------------------------------

        function getShadowId() {
            return self.shadowId || null;
        } //-- end: getShadowId -------------------------------------------------------------------


        /// -- CONTROLES -----------------------------------------------------------------------///

        function checkData(PropertyData) {
            const fixes = {
                propdatasToSave: [],
                propdatasToDelete: [],
                entdatasToSave: [],
                entdatasToDelete: [],
                reldatasToSave: [],
                reldatasToDelete: [],
            };
            checkEntityDatas(_entdatas, fixes, PropertyData);
            checkRelationDatas(_reldatas, fixes);
            return fixes;
        } //-- end: checkData ---------------------------------------------------------------------

        // Deze functie controleert of elke EntityData de juiste PropertyDatas heeft, verwijdert de overbodige en vult de ontbrekende aan.
        // (deze functie gaat ervan uit dat de PropertyDatas al geïndexeerd zijn)
        function checkEntityDatas(entdatas, fixes, PropertyData) {
            const indicesToSplice = [];
            for (let i = 0; i < entdatas.length; i++) {
                const entdata = entdatas[i];

                const entity = ntaData.entities[entdata.EntityId];
                if (!entity) {
                    $log.warn(`EntityData ${entdata.BuildingId}/${entdata.EntityDataId} met onbekende EntityId ‘${entdata.EntityId}’ wordt verwijderd.`);
                    fixes.entdatasToDelete.push(entdata);
                } else if (entdata.EntityVersionId !== entity.VersionId) {
                    $log.warn(`EntityData ${entdata.BuildingId}/${entdata.EntityDataId} verwees naar onverwachte versie van EntityId ‘${entdata.EntityId}’: ${entdata.EntityVersionId} i.p.v. ${entity.VersionId}.`);
                    entdata.EntityVersionId = entity.VersionId;
                    fixes.entdatasToSave.push(entdata);
                }

                const properties = ntaData.properties[entdata.EntityId] || [];
                const extraPropdatas = entdata.PropertyDatas.filter(propdata => !properties[propdata.PropertyId]);
                for (const propdata of extraPropdatas) {
                    $log.warn(`Onverwachte property ${propdata.PropertyDataId} in ${entdata.BuildingId}/[${entdata.EntityId}]${entdata.EntityDataId} (value = ‘${propdata.Value}’) wordt verwijderd.`);
                    const index = entdata.PropertyDatas.indexOf(propdata);
                    if (index >= 0) entdata.PropertyDatas.splice(index, 1);
                    delete entdata.PropertyDatas[propdata.PropertyId];
                    fixes.propdatasToDelete.push(propdata);
                }
                const isMissingProperties = properties.some(prop => !entdata.PropertyDatas[prop.Id]);
                if (isMissingProperties) {
                    if (entdata.PropertyDatas.length === 0) {
                        $log.warn(`Entity ${entdata.BuildingId}/[${entdata.EntityId}]${entdata.EntityDataId} heeft geen properties, en wordt verwijderd.`);
                        indicesToSplice.push(i);
                        fixes.entdatasToDelete.push(entdata);
                    } else {
                        // Onderstaande check gaat ervan uit dat zowel de properties als de PropertyDatas gesorteerd zijn volgens de Order.
                        entdata.PropertyDatas.sort((a, b) => properties[a.PropertyId].Order - properties[b.PropertyId].Order);
                        properties.forEach((prop, index) => {
                            if (!entdata.PropertyDatas[index] || entdata.PropertyDatas[index].PropertyId !== prop.Id) {
                                $log.warn(`Ontbrekende property ${prop.Id} in ${entdata.BuildingId}/[${entdata.EntityId}]${entdata.EntityDataId} wordt toegevoegd.`);
                                const newPropdata = new PropertyData(entdata, prop);
                                entdata.PropertyDatas.splice(index, 0, newPropdata);
                                entdata.PropertyDatas[prop.Id] = newPropdata;
                                fixes.propdatasToSave.push(newPropdata);
                            }
                        });
                    }
                }
            }
            for (const index of indicesToSplice.sort((a, b) => b - a)) {
                removeEntdataFromCache(entdatas[index].EntityDataId);
                entdatas.splice(index, 1);
            }
            return indicesToSplice.length;
        } //-- end: checkEntityDatas --------------------------------------------------------------

        // Deze functie controleert of de relaties wel verwijzen naar bestaande entiteiten, en zo ja, of ze van het juiste type zijn.
        // Relaties die naar een niet-bestaande entiteit verwijzen, worden verwijderd. Relaties waarvan de entiteiten niet van het juiste type zijn, worden alleen in het log vermeld.
        // (deze functie gaat ervan uit dat de EntityDatas al in de opzoekcache zitten)
        function checkRelationDatas(reldatas, fixes) {
            const indicesToSplice = [];
            const entdatasToDelete = new Set(fixes.entdatasToDelete);
            reldatas.forEach((rel, index) => {
                let parent = get(rel.Parent);
                if (parent && entdatasToDelete.has(parent)) {
                    parent = null;
                }
                let child = get(rel.Child);
                if (child && entdatasToDelete.has(child)) {
                    child = null;
                }
                if (!parent || !child) {
                    let entDescription = ': ';
                    if (!parent) entDescription += `parent [${rel.ParentEntityId}]${rel.Parent}`;
                    if (!parent && !child) entDescription = 'en' + entDescription + ' en ';
                    if (!child) entDescription += `child [${rel.ChildEntityId}]${rel.Child}`;
                    $log.warn(`Relatie ${rel.BuildingId}/${rel.EntityRelationDataId} verwijst naar niet-bestaande entiteit${entDescription} en wordt verwijderd.`);
                    indicesToSplice.push(index);
                    fixes.reldatasToDelete.push(rel);
                }
                if (parent && parent.EntityId !== rel.ParentEntityId) {
                    $log.error(`Relatie ${rel.BuildingId}/${rel.EntityRelationDataId}: parent is een ${parent.EntityId} ipv een ${rel.ParentEntityId} zoals de relatie aangeeft.`);
                }
                if (child && child.EntityId !== rel.ChildEntityId) {
                    $log.error(`Relatie ${rel.BuildingId}/${rel.EntityRelationDataId}: child is een ${child.EntityId} ipv een ${rel.ChildEntityId} zoals de relatie aangeeft.`);
                }
            });
            for (const index of indicesToSplice.sort((a, b) => b - a)) {
                removeReldataFromCache(reldatas[index]);
                reldatas.splice(index, 1);
            }
            return indicesToSplice.length;
        } //-- end: checkRelationDatas ------------------------------------------------------------


        /// -- HULPFUNCTIES --------------------------------------------------------------------///

        function orderRelationsById(a, b) {
            return a.EntityRelationDataId < b.EntityRelationDataId ? -1 : (a.EntityRelationDataId > b.EntityRelationDataId ? 1 : 0);
        } //-- end: orderRelationsById ------------------------------------------------------------

        // zoekt op waar ‘item’ ingevoegd moet worden, voegt ’m in (of toe aan) het array, en geeft de index terug.
        // ‘array’ moet al gesorteerd zijn volgens de ‘compareFunction’.
        function insertInSortedArray(array, item, compareFunction) {
            let deleteCount = 0;
            let index = binarySearch(array, item, compareFunction);
            if (index >= 0) {
                index += 1;
            } else {
                index = ~index;
            }
            array.splice(index, deleteCount, item);
            return index;
        } //-- end: insertInSortedArray -----------------------------------------------------------

        // https://stackoverflow.com/a/29018745/3092116
        function binarySearch(ar, el, compare_fn) {
            let m = 0;
            let n = ar.length - 1;
            while (m <= n) {
                const k = (n + m) >> 1;
                const cmp = compare_fn(el, ar[k]);
                if (cmp > 0) {
                    m = k + 1;
                } else if (cmp < 0) {
                    n = k - 1;
                } else {
                    return k;
                }
            }
            return -m - 1;
        } //-- end: binarySearch ------------------------------------------------------------------

    }; //== end: BuildingData =====================================================================

}]);
