﻿var resultsModule = angular.module("resultsModule", ["ngAnimate", "ngMaterial"])
    .config(function ($mdThemingProvider) {
        $mdThemingProvider.theme('default')
            .primaryPalette('light-green')
            .accentPalette('orange');
    });

resultsModule.factory('resultsMenuData', ['$http', '$q', '$mdDialog', function ($http, $q, $mdDialog) {
    return {
        buildingId: 0,
        setBuildingId: function (buildingId) {
            this.buildingId = buildingId;
        },
        getBuildingId: function () {
            return this.buildingId;
        },

        projectId: 0,
        setProjectId: function (projectId) {
            this.projectId = projectId;
        },
        getProjectId: function () {
            return this.projectId;
        },

        // deze wordt overschreven in ntaSharedLogic
        isTOjuliVisible: function () {
            return true;
        },
    };
}]);

angular.module('projectModule')
    .controller('ResultsMenuController',
        ['$log', 'ntaData', 'resultsMenuData', 'ntaSharedLogic',
function ($log,   ntaData,   resultsMenuData,   ntaSharedLogic) {
    'use strict';
    const vm = this;

    vm.getBuildingId = () => resultsMenuData.getBuildingId();

    vm.getProjectId = () => resultsMenuData.getProjectId();

    vm.isTOjuliVisible = () => resultsMenuData.isTOjuliVisible();

    vm.showVariants = ntaSharedLogic.showVariants;

    vm.showOverzicht = function () {
        return !!ntaData.properties['RESULT-OVERZICHT']; // keuze alleen weergeven als het formulier überhaupt iets laat zien: zonder RESULT-OVERZICHT krakt het.
    };

    vm.getOverzichtType = function () {
        const calcUnit = ntaSharedLogic.getCalcUnit();
        if (ntaSharedLogic.showVariants()) {
            return 'varianten';
        }
        if (calcUnit === 'RZUNIT_GEBAPP') {
            return 'appartementen';
        }
        if (calcUnit === 'RZUNIT_PROJECT') {
            return 'projectwoningen';
        }
        return 'resultaten';
    }
}]);