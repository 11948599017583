﻿(function () {
    'use strict';
    const app = angular.module('ntaLogger', []);

    const logLevels = ['log', 'debug', 'info', 'warn', 'error'];

    app.config(['$provide', 'serverLogServiceProvider', function ($provide, serverLogServiceProvider) {
        //Bool of de logberichten in de console bij de client zichtbaar zijn
        serverLogServiceProvider.hideConsoleLogs(false);
        $provide.decorator('$log', ['$delegate', 'serverLogService', function ($delegate, serverLogService) {

            for (const level of logLevels) {
                //De originele methode opslaan in variabele
                const callback = $delegate[level];
                $delegate[level] = (...args) => serverLogService.log(level, args, callback);
            }

            return $delegate;

        }]);
    }]);

    app.provider('serverLogService', function () {
        this.loglevel = 'log';
        this.hideLogs = false;
        this.$get = function () {
            //Vanaf welk niveau er naar de logfile geschreven moet worden
            const configLevel = 'warn';
            const hideLogs = this.hideLogs;
            return {
                log: async function (logLevel, messages, fn) {
                    if (logLevels.indexOf(configLevel) <= logLevels.indexOf(logLevel)) {
                        // probeer de berichten presenteerbaar te maken voor de server
                        const message = (await Promise.all(messages.map(stringifyMessage))).join(' ');

                        const data = { logLevel, message, url: location.href };
                        //d.m.v. ajax het bericht naar de server sturen en in de logfile schrijven
                        $.ajax({
                            url: '/logging/WriteLogMessage',
                            method: 'post',
                            data: data
                        });
                    }
                    if (!hideLogs) {
                        fn(...messages);
                    }
                }
            };
        };
        this.setLogLevel = function (value) {
            this.loglevel = value;
        };
        this.hideConsoleLogs = function (value) {
            this.hideLogs = value;
        };

        async function stringifyMessage(message) {
            const maxLength = 100000;
            if (!message) {
                message = JSON.stringify(message) + ''; // die extra «+ ''» is om van de waarde undefined de string "undefined" te maken
            } else if (message instanceof Response) {
                const response = message;
                const description = Object.fromEntries(['type', 'url', 'redirected', 'ok', 'status', 'statusText'].map(p => [p, response[p]]));
                try {
                    description.headers = response.headers && Object.fromEntries(response.headers);
                    description.body = await response.text();
                } catch (err) {
                    // negeer fouten hier: dan loggen we de body maar niet
                }
                message = JSON.stringify(description);
            } else if (message.stack || message instanceof Error) {
                message = String(message) + '\n' + message.stack;
            } else if (typeof message.message === 'string') {
                message = message.message;
            } else if (typeof message === 'function') {
                message = `[${message.name}()]`;
            } else if (message.outerHTML) {
                message = message.outerHTML;
            } else if (typeof message !== 'string') {
                try {
                    let json = JSON.stringify(message) + '';
                    if (json !== '{}') {
                        message = json;
                    } else {
                        if (typeof message[Symbol.iterator] === 'function') {
                            json = '[';
                            for (const item of message) {
                                if (json.length >= maxLength) {
                                    json += '…';
                                    break;
                                } else {
                                    if (json.length > 1) json += ', ';
                                    json += await stringifyMessage(item);
                                }
                            }
                            json += ']';
                        }
                        message = '[' + (message.constructor && message.constructor.name) + '] ' + json;
                    }
                } catch (err) {
                    // negeer fouten hier
                }
            }
            if (message.length > maxLength) {
                message = message.substr(0, maxLength) + '…';
            }
            return message;
        } //-- end: stringifyMessage
    });
}());