﻿angular.module('projectModule')
    .factory("MeasuresFactory",
        ['ntabuilding', 'ntaData', 'ntaEntityData', 'ntaValidation', '$log', '$mdDialog', 'progressCircle', 'ntaSharedLogic', 'ntaMeldingen', 'ntaMeasure',
function (ntabuilding,   ntaData,   ntaEntityData,   ntaValidation,   $log,   $mdDialog,   progressCircle,   ntaSharedLogic,   ntaMeldingen,   ntaMeasure) {
    'use strict';

    function MeasuresLogic(ntaDependencyValidation) {
        const measuresLogic = this;

        measuresLogic.properties = ntabuilding.properties['MEASURE'];
        measuresLogic.measures = () => ntaMeasure.getList().filter(x => x.Relevant);
        measuresLogic.measureTypes = ntaMeasure.getTypes();
        measuresLogic.getMeasureName = ntaMeasure.getName;
        measuresLogic.getTileDetails = ntaMeasure.getTileDetails;

        measuresLogic.sortMeasuresByName = ntaMeasure.sortMeasuresByName;

        /////////////////////////////////////////////////////////////////////////////////////////// CRUD akties /////////////////////////////////////////////////////////////////////////////////////////////////////
        measuresLogic.addMeasure = function (measuretypeId) {
            progressCircle.setShowProgressValue(true, 'aanmaken nieuwe maatregel');
            try {

                return ntaMeasure.create(measuretypeId);

            } finally {
            progressCircle.setShowProgressValue(false);
            }
        }; //-- end: addMeasure --------------------------------------------------------------------------------//

        measuresLogic.copyClick = function (id) {
            if (!id) {
                return;
            }

            progressCircle.setShowProgressValue(true, 'kopiëren maatregel');
            const newEntdatas = ntaMeasure.copy(id);

            //-- naam van de maatregel uitbreiden met " - kopie"
            const measure = newEntdatas.find(e => e.EntityId === 'MEASURE');
            const propdataNaam = measure && measure.PropertyDatas["MEASURE_NAAM"];
            if (propdataNaam) {
                const newName = propdataNaam.Value + " - kopie";
                ntaEntityData.saveprop(propdataNaam, newName);
            }
            progressCircle.setShowProgressValue(false);
        };

        measuresLogic.deleteClick = function (id) {
            if (!id) {
                return;
            }
            const entdata = ntaEntityData.get(id);
            const measureName = ntaMeasure.getName(entdata);

            const variant = ntaEntityData.findEntity(id, 'VARIANT-MEASURE.^VARIANT', 'VARIANT');
            if (variant) {
                ntaMeldingen.warning("[W104]");
                return;
            }

            const confirm = $mdDialog.confirm()
                .title('Verwijderen maatregel')
                .htmlContent('U staat op het punt om maatregel "' + measureName + '" te verwijderen. Weet u het zeker?')
                .ariaLabel('Verwijderen')
                .targetEvent(event)
                .ok('Ja')
                .cancel('Nee');

            $mdDialog.show(confirm).then(function () {
                ntaMeasure.delete(entdata);
            });
        };

        measuresLogic.validate = function (prop, propdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }
            ntaValidation.IsValid(measuresLogic.form_measure, prop, propdata);  //Client Validatie
        };

        measuresLogic.startFormValidation = function () {
            ntaSharedLogic.startFormValidation(getAllEntDatas(), measuresLogic);
        };

        measuresLogic.endFormValidation = function () {
            if (ntaData.current.shadowId !== null) return [];

            ntaSharedLogic.endFormValidation(getAllEntDatas(), measuresLogic);
        };

        function getAllEntDatas() {
            return [].concat(
                ntaEntityData.getListWithEntityId('MEASURE'),
            ).filter(ed => ed); // null en undefined wegfilteren
        }

    }

    return MeasuresLogic;
}]);