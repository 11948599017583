﻿angular.module('projectModule')
    .service('ntaSelectionTable',
        ['$http', 'ntaData',
function ($http,   ntaData) {
    'use strict';
    const self = this;

    /// -- Instance variables ---------------------------------------------------------------------

    // _products is een object dat alle eerder opgevraagde producten bevat, gegroepeerd op type product en op ‘module’ (W, U, of WU)
    // Deze ziet er na verloop van tijd als volgt uit:
    //  const _products = {
    //      "VE|W": [{ Id: 1, ... }, { Id: 2, ... }, ...],
    //      "VE|U": [{ Id: 1, ... }, { Id: 26, ... }, ...],
    //      "H|W": [{ Id: 256, ... }, ...],
    //      "H|U": [...],
    //      "H|256": [{ ... } ], // subvalues
    //      "C|W": [ ... ],
    //  };
    const _products = {};

    // _promises is de lijst met uitstaande serververzoeken, zodat we geen tweede verzoek voor dezelfde tabel gaan openen.
    // Het is een object waarvan de propertynamen dezelfde zijn als _products, maar die allemaal een Promise bevatten. Deze
    //  zullen t.z.t. resolven naar de betreffende productenlijst van de server.
    const _promises = {};


    /// -- Exports --------------------------------------------------------------------------------
    self.getProductsAsync = getProductsAsync;
    self.getProducts = getProducts;
    self.loadProducts = loadProducts;

    ntaData.ntaSelectionTable = self;

    /// -- Initialization -------------------------------------------------------------------------
    loadProducts(ntaData.products);

    /// -- Implementation -------------------------------------------------------------------------

    // loadProducts: (her)initialiseert de productenlijst.
    function loadProducts(products) {
        ntaData.products = products;

        // Evt. bestaande producten weggooien
        for (const key of Object.keys(_products)) {
            delete _products[key];
        }

        for (const productType of Object.keys(products)) {
            // Controleer of de karakters vanaf 1 van productType een getal is; zo nee, dan is het een ‘gewone’ lijst met productinfo.
            const subValueId = productType.substring(1);
            if (isNaN(parseInt(subValueId, 10))) {
                const listW = [], listU = [];
                for (const product of products[productType]) {
                    if (product.Module === 0 || product.Module === 2) listW.push(product);
                    if (product.Module === 1 || product.Module === 2) listU.push(product);
                }
                _products[productType + '|W'] = listW;
                _products[productType + '|U'] = listU;
            } else {
                // productType is een getal met een H of W er voor en is het een subvalue; dan is het een H_subvalue
                var char = productType[0];
                switch (char) {
                    case 'H': _products['H|' + subValueId] = products[productType]; break;
                    case 'W': _products['W|' + subValueId] = products[productType]; break;
                }
            }
        }

        for (const key of Object.keys(_products)) {
            // alleen de producten checken van key's zonder getal, dat zijn namelijk subvalues
            const getal = key.substring(2);
            if (isNaN(parseInt(getal, 10))) {
                for (const p of _products[key]) {
                    // Voorzie aan het einde de vervallen producten van de naam vervallen XXXX-XX-XX
                    // We gebruiken hier de ISO formatie omdat dit nu ook al gebruikt wordt in de teksten
                    // van de selectietabel excel.
                    const isValid = p.ExpiryDate == null || Date.parse(p.ExpiryDate) > Date.now();
                    if (!isValid && !p.Value.toLowerCase().includes("vervallen")) {
                        const date = p.ExpiryDate ? p.ExpiryDate.substring(0, 10) : "";
                        p.Value = p.Value + " - "  + "vervallen " + date ;
                    }
                }
            }
        }
    }

    // getProducts: geeft SYNCHROON de _reeds aanwezige_ productinformatie van het opgegeven type op, evt. gefilterd op module.
    // LET OP: als deze niet eerder opgehaald is, of er nog geen antwoord van de server is, geeft deze functie `undefined` terug!
    function getProducts(productType, module = "") {
        const key = getKey(productType, module);
        return _products[key];
    }

    // getProducts: haalt asynchroon de productinformatie van het opgegeven type op, evt. gefilterd op module.
    // * productType is H voor verwarming, C voor koeling, VE voor verwarming etc.
    // * module kan de volgende soorten waarde meekrijgen:
    //   - een boolean (true = utiliteitsbouw, false = woningbouw)
    //   - 'W', 'U', 'WU' (voor woningbouw of utiliteitsbouw, of beide)
    //   - een getal dat de productId van een H_model is. Dan worden de ‘subvalues’ van het betreffende product opgehaald.
    // Geeft altijd een Promise die resolvet naar een Array met productinformatie-objecten.
    async function getProductsAsync(productType, module = "") {
        const key = getKey(productType, module);

        // Controleer eerst of we deze producten al hebben
        const products = _products[key];
        if (products) {
            return products; // (omdat dit een async function is, wordt deze automatisch verpakt in een Promise)
        }

        // Controleer dan of we al een verzoek hebben uitstaan
        let promise = _promises[key];
        if (!promise) {
            // deze producten hebben we nog niet, en moeten we dus aan de server vragen
            promise = getProductsFromServer(productType, module);

            // registreren dat er nu een verzoek uitstaat
            _promises[key] = promise;
        }
        // En dan geven we nu de Promise terug, die zal resolven als het antwoord van de server er is
        return promise;
    }

    // getProductsFromServer: zet het verzoek aan de server uit, wacht daarop, werkt onze interne administratie bij,
    //  en geeft een Promise terug, die t.z.t. resolvet naar de Array die de server heeft teruggegeven.
    async function getProductsFromServer(productType, module) {
        // Je kunt voor module ook isUtiliteit() meegeven...
        if (typeof module === 'boolean') {
            module = module ? 'U' : 'W';
        }

        let url = '/api/selectiontable/' + productType;
        if (typeof module === 'string' && module) {
            url += '?module=' + module;
        } else if (typeof module === 'number') {
            url += '/' + module;
        }
        const key = getKey(productType, module);
        try {
            // Wacht op het antwoord van de server
            const response = await $http.get(url);
            const products = response.data;

            // Sla de producten op in onze cache
            _products[key] = products;

            // En ‘resolve’ de promise met de productenlijst van de server
            return products;

        } catch (response) {
            console.error(url, response);
            let data = response && response.data || response;
            if (typeof data === 'string') {
                try {
                    data = JSON.parse(data);
                } catch (e) {
                }
            }
            // Of ‘reject’ de promise met de servergegevens, of anders de hele response.
            throw data;
        } finally {
            // Verwijder deze promise hoe dan ook uit de wachtlijst
            delete _promises[key];
        }
    }

    // getKey: genereert een sleutel die gebruikt wordt om een combinatie van productType en module op te slaan in _products en _promises.
    function getKey(productType, module) {
        // Je kunt voor module ook isUtiliteit() meegeven...
        if (typeof module === 'boolean') {
            module = module ? 'U' : 'W';
        }
        return productType + '|' + module;
    }

}]);