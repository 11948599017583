﻿angular.module('projectModule')
    .factory('VariantFactory',
        ['$log', 'ntaData', 'ntaValidation', 'ntaEntityDataOrg', 'ntaMeldingen', 'ntaSharedLogic', 'ntaDeltas', 'ListCache', 'ntaMeasureData',
function ($log,   ntaData,   ntaValidation,   ntaEntityDataOrg,   ntaMeldingen,   ntaSharedLogic,   ntaDeltas,   ListCache,   ntaMeasureData) {
    'use strict';

    return function VariantLogic(variantId, ntaDependencyValidation) {
        const self = this;

        //== Imports ==============================================================================
        self.ntaValidation = ntaValidation;
        self.dependencyValidator = ntaDependencyValidation;

        // == Instance variables =================================================================
        const _entdataVariant = ntaEntityDataOrg.get(variantId);
        const _listCache = new ListCache();
        const _measureItems = getMeasureItems();

        //== Exports ==============================================================================
        self.entdataVariant = _entdataVariant;
        self.properties = ntaData.properties[_entdataVariant.EntityId];

        // al deze methods exporteren zodat ze publiek beschikbaar zijn
        Object.assign(self, {
            // specifieke methods voor maatregelen
            getAvailableMeasureItems,
            getVariantMeasures,
            addVariantMeasure,
            deleteVariantMeasure,
            getMeasureName,
            calcTotaleKosten,

            // standaard methods tbv validatie
            isReadOnly,
            hasCodedValues,
            getCodedValues,
            isItemChecked,
            toggleItemChecked,
            saveValue,
            validate,
            validateDependencies,
            startFormValidation,
            endFormValidation,
            setGeopend,
        });

        //== Initialization =======================================================================


        //== Implementation =======================================================================

        function getMeasureItems() {
            const measures = ntaMeasureData.getList()
                .map(ed => ({
                    "Id": ed.EntityDataId,
                    "Value": ed.PropertyDatas["MEASURE_NAAM"].Value,
                    "Type": ed.PropertyDatas["MEASURE_TYPE"].Value,
                }));
            return measures;
        } //-- end: getMeasureItems ---------------------------------------------------------------

        function getAvailableMeasureItems() {
            const chosenMeasureIds = new Set(getChosenMeasures().map(ed => ed.EntityDataId));
            return _measureItems.filter(measure => !chosenMeasureIds.has(measure.Id));
        } //-- end: getAvailableMeasures ----------------------------------------------------------

        function isConditionVarA() {
            // [VAR-A] indien versie le3.2 EN G12 = basisopname en EN op het formulier 'installaties' is geen koelsysteem aanwezig toon [W116]
            if (ntaData.ntaVersion.ntaVersionId < 300 && ntaSharedLogic.isBasisOpname()) {
                // Als er na toepassen van alle gekozen maatregelen GEEN koeling aanwezig is, mag GEEN zonwering gekozen worden.

                let buildingData = ntaData.original;
                const hvacMeasures = getChosenMeasures()
                    .filter(x => x.PropertyDatas['MEASURE_TYPE'].Value === 'MEASURE-HVAC_INSTALLATIE');
                if (hvacMeasures.length) {
                    // Er zijn HVAC-maatregelen, dus maak een tijdelijke kopie van (de installaties van) de basisberekening
                    //  en pas daar de HVAC-maatregelen op toe.
                    const deltas = hvacMeasures.reduce((allDeltas, measure) => allDeltas.concat([...(ntaData.deltas.get(measure.EntityDataId) || new Map()).values()]), []);
                    buildingData = ntaDeltas.getShadowBuildingData(_entdataVariant.EntityDataId, ['INSTALLATIE'], deltas);
                }

                return !buildingData.getListWithEntityId('INSTALLATIE')
                    .some(ed => ed.PropertyDatas['INSTALL_TYPE'].Value === 'INST_KOEL');
            }
            return false;
        } //-- end: isConditionVarA ---------------------------------------------------------------

        function getChosenMeasures() {
            return ntaEntityDataOrg.findEntities(_entdataVariant, 'VARIANT-MEASURE.^MEASURE', '^MEASURE');
        } //-- end: getMeasures -------------------------------------------------------------------

        function getVariantMeasures() {
            // LET OP: in oude berekeningen (NTAVersions 202 t/m 204) geeft deze functie een lijst
            //  met MEASURE entdatas terug; in latere versies zijn het VARIANT-MEASURE entdatas.
            // Gebruik getChoseMeasures() om altijd een lijst met MEASURE-entdatas te krijgen,
            //  of getMeasure(entdata) om de MEASURE te krijgen van één van de entdatas die deze functie teruggeeft.
            return ntaEntityDataOrg.findEntities(_entdataVariant, 'VARIANT-MEASURE', '^MEASURE');
        } //-- end: getVariantMeasures ------------------------------------------------------------

        async function addVariantMeasure(item) {
            const newMeasure = ntaEntityDataOrg.get(item.Id);
            const typeProp = ntaData.properties['MEASURE_TYPE'];
            const codedValue = getCodedValues(typeProp).find(x => x.Id === item.Type);

            //[VAR-B] alleen de type maatregelen 'Rc ...' en 'UW/UD…' en 'zonwering' mogen meerdere keren gekozen worden in de tabel. Indien één van de andere types voor de tweede keer wordt gekozen toon [W105]
            const singleMeasureTypes = ["MEASURE-HVAC_INSTALLATIE", "MEASURE-PV", "MEASURE-INFILTRATIE", "MEASURE-VERL", "MEASURE-VERT_LEIDINGEN", "MEASURE-WINDT"];
            const selectedMeasureTypes = getChosenMeasures().map(x => x.PropertyDatas['MEASURE_TYPE'].Value);
            if (singleMeasureTypes.includes(item.Type) && selectedMeasureTypes.includes(item.Type)) {
                const nameProp = ntaData.properties['MEASURE_NAAM'];
                await ntaMeldingen.warning("[W105]", undefined, [{ from: '{naam maatregel op tegel}', to: nameProp.getValue(newMeasure) }, { from: '{type maatregel uit M02}', to: codedValue.Value }]);
                return;
            }

            //[VAR-C] voer bij toevoeging van een maatregel aan de variant de volgende controles uit:
            //Indien meerdere maatregelen 'Rc ...': controleer of de maatregelen wijzigingen bij dezelfde constructies doorgeven.
            //  Zo ja, toon [W106] en voeg de maatregel NIET toe aan de variant
            //Indien meerdere maatregelen U / ggl controleer  of de wijzigingen van U-waarden niet dezelfde constructie betreffen en
            //  controleer apart of maatregel van de ggl niet dezelfde constructie betreffen.
            //  Let op: 1 maatregel die de U - waarde van de constructie wijzigt een een tweede maatregel die de ggl van dezelfde constructie wijzigt mag wel.
            //  Indien maatregelen dezelfde constructie en dezelfde grootheir(Uwaarde / gglwaarde) betreffen geef dan melding [W106] en
            //  voeg de maatregel NIET toe aan de variant.
            //Indien meerdere maatregelen 'PV vervangen': controleer of de vervanging niet over hetzelfde systeem gaat. PV03 van alle 'PV vervangen' maatregelen moet verschillen. Indien dit niet het geval is toon [W107] en voeg de maatregel NIET toe aan de variant.
            //Indien meerde maatregelen 'zonwering': controleer of de zonwering maatregelen btrekking hebben op eenzelfde constructie. Zo ja toon [W106] en voeg de maatregel NIET toe aan de variant.
            const measures = getChosenMeasures();

            switch (codedValue.FilterValue1) {
                case 'MEASURE-ZONW': {
                    const zonweringMeasures = measures.filter(x => x.PropertyDatas["MEASURE_TYPE"].Value === "MEASURE-ZONWERING"); //alleen overlap van zonwering met zonwering
                    if (ntaSharedLogic.hasConstructionOverlap(newMeasure, zonweringMeasures )) { 
                        await ntaMeldingen.warning('[W106]');
                        return;
                    };
                    break;
                }
                case 'MEASURE-U':
                case 'MEASURE-RC': {
                    if (ntaSharedLogic.hasConstructionOverlap(newMeasure, measures)) {
                        await ntaMeldingen.warning('[W106]');
                        return;
                    };
                    break;
                }
                case 'MEASURE-PV': {
                    const newMeasurePV = ntaEntityDataOrg.getFirstChild(newMeasure, 'MEASURE-PV');
                    const newPV = ntaEntityDataOrg.getFirstParent(newMeasurePV, 'PV');
                    for (const measure of measures) {
                        const type = typeProp.getValue(measure);
                        if (item.Type === type) {
                            const measurePV = ntaEntityDataOrg.getFirstChild(measure, 'MEASURE-PV');
                            const pv = ntaEntityDataOrg.getFirstParent(measurePV, 'PV');
                            if (pv && newPV && pv.EntityDataId === newPV.EntityDataId) {
                                await ntaMeldingen.warning('[W107]');
                                return;
                            }
                            break;
                        }
                    }
                }
            }

            // [VAR-A] indien G12 = basisopname en EN op het formulier 'installaties' is geen koelsysteem aanwezig toon [W116]
            if (codedValue.Id === 'MEASURE-ZONWERING' && isConditionVarA()) {
                await ntaMeldingen.warning('[W116]');
            };

            const parentRels = [
                // als een variant gekopieerd wordt, moeten de gekoppelde maatregelen ook toegevoegd worden aan de kopie.
                { OnCopy: 1, OnDelete: 1, Parent: _entdataVariant.EntityDataId, ParentEntityId: _entdataVariant.EntityId },
                // als een maatregel gekopieerd wordt, moet de kopie NIET toegevoegd worden aan de varianten waar het origineel in zit.
                { OnCopy: 0, OnDelete: 1, Parent: newMeasure.EntityDataId, ParentEntityId: newMeasure.EntityId },
            ];
            ntaEntityDataOrg.create('VARIANT-MEASURE', -1, parentRels);
            calcTotaleKosten();
            checkConditionVAR_E();
        } //-- end: addVariantMeasure -------------------------------------------------------------

        function deleteVariantMeasure(entdata) {
            switch (entdata.EntityId) {
                case 'VARIANT-MEASURE':
                    ntaEntityDataOrg.delete(entdata.EntityDataId);
                    break;
                case 'MEASURE':
                    const rel = ntaEntityDataOrg.getRelation(entdata, _entdataVariant.EntityDataId);
                    rel && ntaEntityDataOrg.deleteRelation(rel.EntityRelationDataId);
                    break;
            }
            calcTotaleKosten();
            checkConditionVAR_E();
        } //-- end: deleteVariantMeasure ----------------------------------------------------------

        function getMeasure(variantMeasure) {
            switch (variantMeasure.EntityId) {
                case 'VARIANT-MEASURE':
                    return ntaEntityDataOrg.getFirstParent(variantMeasure, 'MEASURE');
                case 'MEASURE':
                    return variantMeasure;
            }
        } //-- end: getMeasure --------------------------------------------------------------------

        function getMeasureName(variantMeasure) {
            const measure = getMeasure(variantMeasure);
            const propdata = measure && measure.PropertyDatas['MEASURE_NAAM'];
            return propdata && propdata.Value || '-';
        } //-- end: getMeasureName ----------------------------------------------------------------

        function isReadOnly(prop) {
            if (!prop)
                return true;

            switch (prop.Id) {
                case 'VARIANT_NAAM':
                    return false;
                case 'VARIANT_KOSTEN_TOT':
                    return true;
            }

            return false;
        } //-- end: isReadOnly --------------------------------------------------------------------

        function isHidden(prop, entdata = _entdataVariant) {
            if (typeof prop === 'string') prop = ntaData.properties[prop];
            if (!prop || !entdata) {
                return true;
            }
            const propdata = entdata.PropertyDatas[prop.Id];
            if (!propdata) {
                return true;
            }

            let visible = true;
            let relevant = null; // null => zelfde als visible

            switch (prop.Id) {
                case 'VARIANT_NAAM': {
                    visible = true;
                    break;
                }
                case 'VARIANT_KOSTEN_TOT': {
                    visible = ntaSharedLogic.showCosts();
                    break;
                }
                case 'VARIANT_ERROR': {
                    visible = false;
                    break;
                }
                case 'VARIANT_NCW': {
                    visible = false;
                    break;
                }
            }

            if (relevant === null) relevant = visible;
            ntaEntityDataOrg.setPropdataStatus(propdata, relevant, visible);
            return !visible;
        } //-- end: isHidden ----------------------------------------------------------------------

        function hasCodedValues(prop) {
            return ntaValidation.hasCodedValues(prop);
        } //-- end: hasCodedValues ----------------------------------------------------------------

        function getCodedValues(prop) {
            if (!prop) {
                return [];
            }

            let codedValues = [];
            switch (prop.Id) {
                default: {
                    codedValues = ntaValidation.codedValues(prop);
                    break;
                }
            }

            // Zorg dat we alleen een nieuwe lijst teruggeven als deze gewijzigde waardes heeft,
            //  anders denkt AngularJS steeds dat het om een compleet nieuwe lijst gaat, en triggert
            //  deze oneindige digests.
            return _listCache.useCacheIfUnchanged(prop.Id, codedValues, (a, b) => a.Id === b.Id && a.Value === b.Value);
        } //-- end: getCodedValues ----------------------------------------------------------------

        function isItemChecked(prop, entdata, item) {
            if (!prop || !entdata || !item)
                return false;

            return new Set(ntaEntityDataOrg.getParentIds(entdata)).has(item.Id);
        } //-- end: isItemChecked -----------------------------------------------------------------

        function toggleItemChecked(prop, entdata, item) {
            if (isItemChecked(prop, entdata, item)) {
                // relatie(s) verwijderen
                const relations = ntaEntityDataOrg.getRelationsBetween(item.Id, entdata);
                for (const relation of relations) {
                    ntaEntityDataOrg.deleteRelation(relation.EntityRelationDataId);
                }
            } else {
                // relatie aanmaken
                ntaEntityDataOrg.createRelation(item.Id, entdata.EntityDataId, false, false);
            }

            // De Ids van alle gekoppelde items aan elkaar plakken en in de propdata opslaan.
            const newValue = getCodedValues(prop, entdata)
                .filter(item => isItemChecked(prop, entdata, item))
                .map(item => item.Id)
                .join('|');
            saveValue(prop, entdata, newValue);
        } //-- end: toggleItemChecked -------------------------------------------------------------

        function calcTotaleKosten() {
            let totaleKosten = 0;
            const measures = getChosenMeasures();
            for (const measure of measures) {
                const logic = ntaDependencyValidation.getMeasureLogic(measure);
                if (logic) {
                    totaleKosten += logic.getTotaleKosten();
                }
            }
            saveValue('VARIANT_KOSTEN_TOT', _entdataVariant, totaleKosten);
            return totaleKosten;
        }//-- end: calcTotaleKosten ---------------------------------------------------------------

        function saveValue(prop, entdata, newValue) {
            return ntaSharedLogic.saveValue(prop, entdata, newValue, self, ntaEntityDataOrg);
        } //-- end: saveValue ---------------------------------------------------------------------

        function validate(prop, propdata) {
            if (!ntaData.canSaveBuilding()) return;
            if (!prop || !propdata || propdata.BuildingId !== ntaData.buildingId) {
                return;
            }

            const hidden = isHidden(prop);
            const readOnly = isReadOnly(prop);
            let valid = ntaValidation.IsValid(self.form, prop, propdata, hidden, readOnly);

            if (prop.Id === 'VARIANT_ERROR') { // conditie geldt voor maatregele binnen varaint. Deze melding daarom maar gezet op de naam propertie
                valid = valid && checkConditionVAR_E();
            }

            return valid;
        } //-- end: validate ---------------------------------------------------------------------

        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = prop.getData(entdata);
            const checkValue = propdata.Value;

            let performDefaultChecks = true;

            switch (prop.Id) {
                case 'MEASURE-RC_METHODE':      // RC02
            }

            if (performDefaultChecks) {
                isHidden(prop, entdata);
                if (ntaValidation.hasCodedValues(prop)) {
                    getCodedValues(prop);
                }
                if (propdata.Value !== checkValue) {
                    saveValue(prop, entdata);
                }
            }
        } //-- end: validateDependencies ----------------------------------------------------------

        function checkConditionVAR_E() {
            //-- Conditie [VAR-E] Controleer bij het verlaten van een variantformulier en bij het klikken op rekenen of een variant maatregelen bevat. Als dit niet het geval is toon [W109].
            const valid = getVariantMeasures().length > 0;
            const msg = ntaData.errors['[E114]'].Value
                .replace("{{variant naam}}", _entdataVariant.PropertyDatas['VARIANT_NAAM'].Value);
            ntaMeldingen.melding('[E114]', _entdataVariant.PropertyDatas['VARIANT_ERROR'].PropertyDataId, valid, msg);

            return valid;
        } //-- end: checkConditionVAR_E -----------------------------------------------------------

        function startFormValidation() {
            calcTotaleKosten();
            return ntaSharedLogic.startFormValidation(getAllEntDatas(), self);
        } //-- end: startFormValidation -----------------------------------------------------------

        function endFormValidation() {
            if (ntaData.current.shadowId !== null) return [];

            return ntaSharedLogic.endFormValidation(getAllEntDatas(), self);
        } //-- end: endFormValidation -------------------------------------------------------------

        function getAllEntDatas() {
            return [];
        } //-- end: getAllEntDatas ----------------------------------------------------------------

        function setGeopend() {
            //const propdataOpen = _entdataMeasure.PropertyDatas['MEASURE_OPEN'];
            //ntaEntityData.saveprop(propdataOpen, 'true');
        } //-- end: setGeopend --------------------------------------------------------------------

    };
}]);
