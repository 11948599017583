﻿angular.module('projectModule')
    .controller("ProjectTreeController",
        ['projecttree', '$scope', 'ntaData', 'ntabuilding', 'progressCircle', 'ntaEntityDataOrg', '$location', '$timeout', 'ntaMeasure', 'ntaVariant', 'ntaSharedLogic', '$log', 'ntaStorage', 'ntaVersions', 'activeBuildingService', 'time', 'ntaResults', 'BuildingResultsFactory',
function (projecttree,   $scope,   ntaData,   ntabuilding,   progressCircle,   ntaEntityDataOrg,   $location,   $timeout,   ntaMeasure,   ntaVariant,   ntaSharedLogic,   $log,   ntaStorage,   ntaVersions,   activeBuildingService,   time,   ntaResults,   BuildingResultsFactory) {
    'use strict';
    const vm = this;
    const resultsLogic = new BuildingResultsFactory();

    vm.getBerekeningen = function () {
        const berekeningen = resultsLogic.getBerekeningen();

        // Zorg dat er altijd een berekening uit de lijst geselecteerd is
        if (!berekeningen.includes(ntabuilding.activeBerekening)) {
            let newBerekening;
            if (ntabuilding.activeBerekening) {
                newBerekening = berekeningen.find(berekening => berekening.Id === ntabuilding.activeBerekening.Id);
            }
            ntabuilding.activeBerekening = newBerekening || berekeningen[0];
            resultsLogic.setExtraResultParents(null, false);
            resultsLogic.selecteerBerekening(ntabuilding.activeBerekening.Id);
        }

        return berekeningen;
    }

    vm.getResult = resultsLogic.getResult;
    vm.risicoOververhittingAlleApp = resultsLogic.getRisicoOververhittingAlleApp;


    vm.gebouwen = function () {
        return projecttree.berekeningen;
    };

    progressCircle.setShowProgressValue(true, 'gegevens worden geladen...');

    angular.element(document).ready(function () {
        setTimeout(() => progressCircle.setShowProgressValue(false), 100);
    });

    vm.expanded = projecttree.expanded;

    vm.isExpanded = function (name) {
        const expanded = !!projecttree.expanded[name];
        if (!expanded) {
            // Hier registreren we ‘name’ expliciet, zodat MessagesRightController.goTo(melding) weet dat deze ingeklapt kan worden.
            projecttree.expanded[name] = false;
        }
        return expanded;
    };

    vm.toggle = function (event, name) {
        event.preventDefault();
        vm.expanded[name] = !vm.expanded[name];
    };

    vm.expandedIconText = (name) => vm.isExpanded(name) ? 'expand_less' : 'expand_more';

    vm.calcHeight = function () {
        const sidebar = document.querySelector('.sidebar');
        const results = document.querySelector('.building-results');
        return sidebar.offsetHeight - 117 - results.offsetHeight;
    };

    var _getActiveBuilding = function (gebouwId) {
        const building = vm.gebouwen().find(bld => bld.GebouwId === gebouwId);
        vm.showBuilding = !!building;
        return building;
    };

    vm.projectnaam = projecttree.projectnaam;
    vm.showBuilding = projecttree.buildingid !== -1;
    vm.activeBuilding = function () {
        return _getActiveBuilding(projecttree.buildingid);
    };

    vm.activeBuildingIcon = function () {
        if (vm.showBuilding) {
            return vm.activeBuilding().GebouwIcon;
        }
    };

    vm.getFormDescription = function (formId) {
        const form = ntaData.forms[formId];
        return form && form.Description || '';
    };

    vm.activeBuildingName = function () {
        if (vm.showBuilding) {
            return vm.activeBuilding().Name;
        }
    };

    vm.activeBuildingResults = function () {
        return {
            BENG1: resultFormatter("EP_BENG1"),
            BENG2: resultFormatter("EP_BENG2"),
            BENG2EMGforf: resultFormatter("EP_BENG2_EMGFORF"),
            BENG3: resultFormatter("EP_BENG3", "-- "),
            TOjuli: resultFormatter("EP_TOJULI"),
            Energielabel: resultFormatter("EP_ENERGIELABEL"),
            BENG1EIS: resultFormatter("EP_BENG1_EIS", undefined),
            BENG2EIS: resultFormatter("EP_BENG2_EIS"),
            BENG3EIS: resultFormatter("EP_BENG3_EIS"),
            TOjuliEIS: resultFormatter("EP_TOJULI_EIS")
        };

        function resultFormatter(propertyId, ifNull = "--") {
            const isEis = propertyId.endsWith('_EIS');
            return function () {
                if (isEis && (!ntaSharedLogic.isNieuwbouw() || ntaSharedLogic.isUnitInUtiliteitsgebouw())) {
                    return '';
                }
                const berekening = vm.isProjectWoningen()
                    ? ntaEntityDataOrg.getListWithEntityId('UNIT').find(x => x.EntityDataId === ntabuilding.activeBerekening.Id)
                    : ntaEntityDataOrg.getFirstWithEntityId('GEB');
                const resultData = vm.getResult(berekening, propertyId)
                return resultData === '--' ? ifNull : resultData;
            };
        }
    };

    vm.isNieuwbouw = ntaSharedLogic.isNieuwbouw;
    vm.isUnitInUtiliteitsgebouw = ntaSharedLogic.isUnitInUtiliteitsgebouw;
    vm.isGebouwEnAppartement = ntaSharedLogic.perGebouwEnAppartementOfUnit;
    vm.isEnergielabel = ntaSharedLogic.isEnergielabel;
    vm.isEMGforf = ntaSharedLogic.isEMGforf;
    vm.isTOjuli = isTOjuli;
    vm.needsCalculating = needsCalculating;
    vm.isCalculating = isCalculating;
    vm.isDemo = ntaData.apptype === 2; //Demo

    vm.isVersionLe32 = isVersionLe32;
    vm.hasRisicoOververhitting = hasRisicoOververhitting;
    vm.risicoOververhitting = risicoOververhitting;
    vm.hasRisicoOververhittingAlleApp = hasRisicoOververhittingAlleApp;
    vm.risicoOververhittingAlleWon = risicoOververhittingAlleWon;

    vm.isProjectWoningen = ntaSharedLogic.voorProjectwoningen;
    vm.isVakantiewoning = ntaSharedLogic.isVakantiewoning;

    vm.resultaat = vm.activeBuildingResults();
    ntabuilding.activeBerekening = vm.getBerekeningen()[0];

    function needsCalculating() {
        return ntaEntityDataOrg.getCalculationNeeded(ntaData.buildingId) || isCalculating();
    }
    function isCalculating() {
        return !!ntaData.getBuildingCalculationId(ntaData.buildingId);
    }

    vm.getActiveBerekening = function () {
        return ntabuilding.activeBerekening;
    };

    vm.setActiveBerekening = function (berekening) {
        ntabuilding.activeBerekening = berekening;

        // Zorg dat alle eenheden ook weer goed gepositioneerd worden
        const controller = $('.propertyUnit').controller();
        if (controller && typeof controller.initializeUnitSpacing === 'function') {
            $timeout(() => controller.initializeUnitSpacing(), 100, false);
        }
    };

    vm.checkResultEis = function (propId) {
        return resultsLogic.checkResultEis(propId, ntabuilding.activeBerekening.Id);
    };

    vm.checkResultEisTOJuli = function (propId) {
        return vm.isNieuwbouw() ? vm.checkResultEis(propId) : ""; // BENG Indicator conditie [G]
    };

    vm.allToJuliOke = function () { //conditie [T]
        return resultsLogic.getBerekeningen()
            .filter(berekening => !berekening.isGebouw) // alleen appartementen checken, niet het hele gebouw
            .every(berekening => resultsLogic.checkResultEis("EP_TOJULI", berekening));
    };

    vm.allResultsOke = function () { //conditie [U]
        const isVakantiewoning = vm.isVakantiewoning();
        return resultsLogic.getBerekeningen()
            .every(berekening => resultsLogic.checkResultEis("EP_BENG1", berekening)
                && resultsLogic.checkResultEis("EP_BENG2", berekening)
                && resultsLogic.checkResultEis("EP_BENG3", berekening)
                && (isVakantiewoning || resultsLogic.checkResultEis("EP_TOJULI", berekening))
            );
    }

    function isVersionLe32() {
        return ntabuilding.ntaVersionId < 300;
    }

    function isTOjuli() {
        return ntaSharedLogic.isTOjuli(ntabuilding.activeBerekening.Id);
    }

    function hasRisicoOververhitting() {
        //BENG indicator condition [Y] toon als versie ge3.3 EN WN EN: - G04 = grondgebonden woning / appartement OF - G04 = appartementengebouw EN Z23 = per gebouw
        return resultsLogic.hasRisicoOververhitting();
    }

    function risicoOververhitting() {
        //R12: 'Toon 'bepaal per app.' als TO28 ='maak EP berekening per appartement' bij 1 of meer rekenzones
        //en anders toon 'voldoet niet' als TO28 = 'voldoet niet' bij 1 of meer rekenzones
        //en anders toon 'voldoet' als TO28 op het formulier TOjuli bij alle rekenzones is 'voldoet'.
        return resultsLogic.getRisicoOververhitting(ntabuilding.activeBerekening.Id).replace('maak EP berekening per appartement', 'bepaal per app.');
    }

    function hasRisicoOververhittingAlleApp() {
        //BENG indicator condition [Z] toon als versie ge3.3 EN WN EN Z23 = per gebouw en per appartement
        return ntabuilding.ntaVersionId >= 300 && vm.isNieuwbouw() && ntaSharedLogic.perGebouwEnAppartementOfUnit();
    }

    function risicoOververhittingAlleWon() {
        //'(Toon 'voldoen' als G04 ≠ 'vakantiewoning' EN bij alle projectwoningen een groen vinkje staat bij BENG1, 2, 3 en risico oververhiiting; OF
        //                     G04 = 'vakantiewoning' EN bij alle projectwoningen een groen vinkje staat bij BENG1, 2, 3;
        //  anders 'voldoen niet'.
        const woningen = vm.getBerekeningen();
        const resBeng123 = woningen.every(berekening => ['EP_BENG1', 'EP_BENG2', 'EP_BENG3'].every(propId => resultsLogic.checkResultEis(propId, berekening.Id)));
        if (resBeng123 && (vm.isVakantiewoning() || woningen.every(berekening => resultsLogic.getRisicoOververhitting(berekening.Id) === 'voldoet'))) {
            return 'voldoet';
        } else {
            return 'voldoet niet';
        }
    }


    vm.setActiveBuilding = async function (gebouw) {
        const originalGebouw = $scope.gebouw;

        const ntaVersionId = await ntaVersions.canOpenWithVersionIdAsync([gebouw]);
        if (!ntaVersionId) {
            $timeout(() => $scope.gebouw = originalGebouw, 0);
            return;
        }

        progressCircle.setShowProgressValue(true, 'berekening laden...');
        await time.whenDelayedActionsDone();
        await ntaStorage.saveDataToServer();

        const connectionId = await progressCircle.startConnection();
        if (connectionId) {
            progressCircle.setShowProgressValue(true, 'berekening laden...', true);
        }

        if (!await ntaData.downloadFromServer(gebouw.ProjectId, gebouw.GebouwId, connectionId, ntaVersionId)) {
            progressCircle.setShowProgressValue(false);

            // zorg dat de selectie weer teruggezet wordt op het huidige gebouw
            $log.error('Oeps', `Building ${gebouw.GebouwId}: kon niet gedownload worden.`);
            await ntaStorage.showErrorAndReload(false);
            $timeout(() => $scope.gebouw = originalGebouw, 0);

            // probeer de pagina van het gebouw rechtstreeks te laden
            const newUrl = location.href.replace(/(\/Berekening\/)(\d+)(\/.*)?$/, (match, prefix, buildingId, suffix) => prefix + gebouw.GebouwId + suffix);
            if (newUrl !== location.href) {
                location.assign(newUrl);
            } else {
                location.reload();
            }

            return;
        }

        try {
            projecttree.buildingid = gebouw.GebouwId;

            ntabuilding.changeBuilding(gebouw.GebouwId);
            resultsLogic.setExtraResultParents();
            ntaResults.initialize(true);

            let path = $location.path();
            // opzoek of het path op een berekening staat. alleen dan de Id in het path aanpassen
            if (path.indexOf("Berekening") > -1) {
                const pathSections = path.split("/");
                pathSections[pathSections.indexOf("Berekening") + 1] = gebouw.GebouwId;

                //aanpassing voor switchen tussen gebouw en scheidingsvlakken -> Scheidingsvlakken verschillen tussen gebouwen -> Selecteer Indeling_gebouw
                const begrenzingIndex = pathSections.indexOf("Begrenzing");
                if (begrenzingIndex !== -1) {
                    pathSections[begrenzingIndex] = "Indeling_gebouw";
                    pathSections.splice(begrenzingIndex + 1, pathSections.length - begrenzingIndex);
                }

                //aanpassing voor switchen tussen gebouw en constructies -> Constructies verschillen tussen gebouwen -> Selecteer Indeling_gebouw
                const constructieIndex = pathSections.indexOf("Constructies");
                if (constructieIndex !== -1) {
                    pathSections[constructieIndex] = "Indeling_gebouw";
                    pathSections.splice(constructieIndex + 1, pathSections.length - constructieIndex);
                }

                //aanpassing voor switchen tussen installatiesystemen
                const instIndex = pathSections.indexOf("Installatie");
                if (instIndex !== -1) {
                    pathSections[instIndex] = "Installaties";
                    pathSections.splice(instIndex + 1, pathSections.length - instIndex);
                }

                //aanpassing voor switchen tussen maatregelen. MaatregelIds verschillen tussen gebouwen -> selecteer Maatregelen, of als deze niet zichtbaar zijn, Algemene_gegevens.
                let sectionIndex = pathSections.indexOf("Maatregelen");
                if (sectionIndex !== -1) {
                    if (!vm.showVariants()) {
                        pathSections[sectionIndex] = "Algemene_gegevens";
                    }
                    pathSections.splice(sectionIndex + 1, pathSections.length - sectionIndex);
                }

                //aanpassing voor switchen tussen varianten. VariantIds verschillen tussen gebouwen -> selecteer Varianten, of als deze niet zichtbaar zijn, Algemene_gegevens.
                sectionIndex = pathSections.indexOf("Varianten");
                if (sectionIndex !== -1) {
                    if (!vm.showVariants()) {
                        pathSections[sectionIndex] = "Algemene_gegevens";
                    }
                    pathSections.splice(sectionIndex + 1, pathSections.length - sectionIndex);
                }

                //aanpassing voor switchen tussen gebruikersprofiel. Deze kan niet beschikbaar zijn -> selecteer Algemene_gegevens.
                sectionIndex = pathSections.indexOf("Gebruikersprofiel");
                if (sectionIndex !== -1) {
                    if (!vm.showCosts()) {
                        pathSections[sectionIndex] = "Algemene_gegevens";
                    }
                    pathSections.splice(sectionIndex + 1, pathSections.length - sectionIndex);
                }

                //aanpassing voor switchen tussen resultatenoverzicht. Deze kan niet beschikbaar zijn -> selecteer Energieprestatie.
                sectionIndex = pathSections.indexOf("Overzicht");
                if (sectionIndex !== -1) {
                    if (!vm.showOverzicht()) {
                        pathSections[sectionIndex] = "Energieprestatie";
                    }
                    pathSections.splice(sectionIndex + 1, pathSections.length - sectionIndex);
                }

                sectionIndex = pathSections.indexOf("Energierekening");
                if (sectionIndex !== -1) {
                    if (!vm.showCosts()) {
                        pathSections[sectionIndex] = "Algemene_gegevens";
                    }
                    pathSections.splice(sectionIndex + 1, pathSections.length - sectionIndex);
                }


                path = pathSections.join("/");

                $location.path(path);
            }

            await activeBuildingService.setActiveBuilding(gebouw.GebouwId);
            ntabuilding.activeBerekening = vm.getBerekeningen()[0];

            progressCircle.setShowProgressValue(false);
        } catch (err) {
            progressCircle.setShowProgressValue(false);
            $log.error('Oeps', `Building ${gebouw.GebouwId}: `, err);
            await ntaStorage.showErrorAndReload();
        }
    };

    vm.showOverzicht = function () {
        return !!ntaData.properties['RESULT-OVERZICHT']; // keuze alleen weergeven als het formulier überhaupt iets laat zien: zonder RESULT-OVERZICHT krakt het.
    };

    vm.showEis = function () {
        return vm.isNieuwbouw() && !vm.isUnitInUtiliteitsgebouw();
    };

    // rekenzone, begrenzing
    vm.getunits = function () {
        return ntaEntityDataOrg.getListWithEntityId('UNIT');
    };

    vm.getunitrzs = function (entdataId) {
        return ntaEntityDataOrg.getChildren(entdataId, "UNIT-RZ");
    };

    vm.getaors = function () {
        var rzs = ntaEntityDataOrg.getListWithEntityId('RZ');
        return rzs.filter(function (x) {
            return x.PropertyDatas["RZ_TYPEZ"].Value === "AOR";
        });
    };

    vm.getaoss = function () {
        var rzs = ntaEntityDataOrg.getListWithEntityId('RZ');
        return rzs.filter(function (x) {
            return x.PropertyDatas["RZ_TYPEZ"].Value === "AOS";
        });
    };

    vm.getgruimtes = function () {
        return ntaSharedLogic.getGRuimtesForAverlies();
    };

    vm.getvlakken = function (entdata) {
        if (entdata) {
            if (entdata.EntityId === 'RZ') { // Alleen in het geval van AOR en AOS zijn begrenzing direct aan een RZ gekoppeld
                const alleVlakIds = ntaEntityDataOrg.getChildIds(entdata, "BEGR");
                const vlakIds = alleVlakIds.filter(vlakId => ntaEntityDataOrg.getParentRelations(vlakId, ['UNIT-RZ', 'GRUIMTE']).length === 0);
                return ntaEntityDataOrg.getList(vlakIds);
            } else { // Andere begrenzingen zijn aan een UNIT-RZ of GRUIMTE gekoppeld
                return ntaEntityDataOrg.getChildren(entdata, "BEGR");
            }
        }
    };


    vm.unitDescription = function (entdata) {
        if (entdata && entdata.BuildingId !== -1) {
            return entdata.PropertyDatas["UNIT_OMSCHR"].Value;
        }
        return "";
    };

    vm.rekenzoneDescription = function (entdata) {
        if (entdata && entdata.BuildingId !== -1) {
            return entdata.PropertyDatas["RZ_OMSCHR"].Value;
        }
        return "";
    };

    vm.rekenzoneDescriptionFromRZUnit = function (entdata) {
        const rz = entdata && ntaEntityDataOrg.getFirstParent(entdata, "RZ");
        return vm.rekenzoneDescription(rz);
    };

    vm.gruimteDescription = function (entdata) {
        if (entdata && entdata.BuildingId !== -1) {
            return entdata.PropertyDatas["GRUIMTE_OMSCHR"].Value;
        }
        return "";
    };

    const _begrenzingPropId = {
        VLAK_VLOER: 'BEGR_VLOER',
        VLAK_GEVEL: 'BEGR_GEVEL',
        VLAK_DAK: 'BEGR_DAK',
        VLAK_KELDERW: 'BEGR_KWAND',
    };

    vm.vlakDescription = function (entdata) {
        if (entdata && entdata.BuildingId !== -1) {
            // Eerst de omschrijving opzoeken
            const propdataOmschr = entdata.PropertyDatas["BEGR_OMSCHR"];
            let description = propdataOmschr && propdataOmschr.Value || '';

            // Dan de oriëntatie opzoeken
            const propdataVlak = entdata.PropertyDatas['BEGR_VLAK'];
            const propId = _begrenzingPropId[propdataVlak && propdataVlak.Value];
            const propdataBegrenzing = propId && entdata.PropertyDatas[propId];
            const begrenzingCode = propdataBegrenzing && propdataBegrenzing.Value;
            const match = /_([NOZW]{1,2}|HOR)$/.exec(begrenzingCode);
            const orientatie = match && match[1];
            if (orientatie) {
                description += ' (' + orientatie + ')';
            }
            return description;
        }
    };

    vm.showVariants = () => {
        const visible = projecttree.showVariants();
        if (ntabuilding.canSave()) {
            const entdatas = vm.getMeasures();
            entdatas.push(...entdatas.flatMap(measure => ntaEntityDataOrg.getChildren(measure)));
            entdatas.push(...vm.getVariants());

            ntaEntityDataOrg.setEntityVisibility(entdatas, visible);
            ntaEntityDataOrg.setEntityRelevancy(entdatas, visible);
        }
        return visible;
    };

    vm.showCosts = ntaSharedLogic.showCosts;

    vm.getMeasures = ntaMeasure.getList;
    vm.canHaveSubmeasures = ntaMeasure.canHaveSubmeasures;
    vm.getSubmeasures = ntaMeasure.getSubmeasures;

    vm.getMeasureIcon = function (measure) {
        const propType = ntaData.properties['MEASURE_TYPE'];
        const domainCode = propType.getCode(measure);
        return domainCode && domainCode.ImageUrl || '';
    };

    vm.getMeasureTypeName = function (measure) {
        const propType = ntaData.properties['MEASURE_TYPE'];
        const domainCode = propType.getCode(measure);
        return domainCode && htmlDecode(domainCode.Value) || '';
    };

    function htmlDecode(text) {
        if (!text) return text;
        const span = document.createElement('span');
        span.innerHTML = text;
        return span.innerText;
    } //-- end: htmlDecode ------------------------------------------------------------------------

    vm.getMeasureName = ntaMeasure.getName;

    vm.getVariants = ntaVariant.getList;

    vm.getVariantName = ntaVariant.getName;

    vm.startBerekening = ntabuilding.startBerekening;

    window.addEventListener("keydown", event => {
        const key = event.key;
        switch (key) {
            case 's': {
                if (event.ctrlKey !== event.metaKey) {
                    if (event.shiftKey) {
                        // TODO: het hele project exporteren?
                    } else {
                        event.preventDefault();
                        ntaStorage.whenDoneSaving(async () => await exportBuilding(ntaData.buildingId), { title: 'berekening exporteren...' });
                    }
                }
                break;
            }
            case 'F9': {
                vm.startBerekening();
                break;
            }
        }
    });

    async function exportBuilding(buildingId, connectionId = '') {
        const today = new Date().toISOString().substring(0, 10);
        const filename = `gebouw-${buildingId}_${today}.uniec3`;
        return await projecttree.downloadFile(`Projects/ExportProjectFolder?bldId=${buildingId}&connectionId=${connectionId}`, filename);
    }

}]);
