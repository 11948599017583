angular.module('ntaModule')
    .service('activeBuildingService',
function () {
    'use strict';
    const vm = this;

    // Deze wordt overschreven door de echte indextree *als* deze aangemaakt wordt.
    vm.indextree = {
        activeBuilding: null,
    };

    vm.setActiveBuilding = async function (buildingId) {

        vm.indextree.activeBuilding = buildingId;

        //Set as active on server
        const response = await fetch("Projects/UpdateActiveBuilding", {
            method: "PUT",
            body: buildingId,
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.ok;
    };

    vm.getActiveBuilding = function () {
        return vm.indextree.activeBuilding;
    };
});