﻿angular.module('projectModule')
    .factory("WindturbineFactory",
        ['ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaSharedLogic',
function (ntabuilding,   ntaValidation,   ntaEntityData,   ntaSharedLogic) {
    'use strict';

    return function WindenergieLogic(installId, ntaDependencyValidation) {
        const windenergieLogic = this;

        const _isEditingMeasure = ntaSharedLogic.isEditingMeasure("MEASURE-WINDT");

        // in een maatregel krijgen we geen installatie-ID mee
        if (_isEditingMeasure && !ntaEntityData.get(installId)) {
            // zoek eerst een evt. bestaande windturbine
            const windt = ntaEntityData.getFirstWithEntityId('WINDT');
            const installation = windt && ntaEntityData.getFirstParent(windt, 'INSTALLATIE');
            installId = installation && installation.EntityDataId;
            if (!ntaEntityData.get(installId)) {
                // als er nog geen was, dan maken we er eentje aan
                installId = ntaEntityData.create('INSTALLATIE', -1, [], [], [{ "PropertyId": 'INSTALL_TYPE', "Value": 'INST_WINDT' }]);
                if (windt) {
                    ntaEntityData.createRelation(installId, windt.EntityDataId, true, true);
                } else {
                    ntaEntityData.create('WINDT', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": installId, "ParentEntityId": "INSTALLATIE" }], []);
                }
            }
        }

        const _installId = installId;
        const _winddata = ntaEntityData.getFirstChild(_installId, 'WINDT');

        windenergieLogic.installId = _installId;
        windenergieLogic.winddata = _winddata;

        // Properties
        windenergieLogic.properties = ntabuilding.properties['WINDT'];

        windenergieLogic.saveValue = function (prop, entdata = _winddata) {
            if (!prop || !entdata) {
                return;
            }
            const propdata = prop.getData(entdata);
            propdata.Touched = true; // opslaan veld is aangeraakt

            if (propdata.Value === undefined) { // niks doen -> undefined komt van een waarschuwing
                return;
            }
            windenergieLogic.validate(prop, propdata);
            if (ntaEntityData.saveprop(propdata)) { //Server validatie en evt. opslaan
                //recalculate
                setDescriptionInstallation();

                ntaDependencyValidation.checkChangedField(prop, _winddata, windenergieLogic);
            }
        };

        windenergieLogic.saveValueAutocomplete = function (prop, item, value) {
            if (!prop || !_winddata) {
                return;
            }
            const propdata = prop.getData(_winddata);
            propdata.Touched = true;

            if (item && item.Id) {
                propdata.Value = item.Id;
            } else {
                propdata.Value = value;
            }
            windenergieLogic.validate(prop, propdata);
            if (ntaEntityData.saveprop(propdata)) { //Server validatie en evt. opslaan
                //recalculate
            }
        };

        function setDescriptionInstallation() {
            const propOpwekVermogen = windenergieLogic.properties["WINDT_OVPJ"];
            const propdataOpwekVermogen = _winddata.PropertyDatas[propOpwekVermogen.Id];

            let descr = propOpwekVermogen.Name + " is " + propdataOpwekVermogen.Value + " " + propOpwekVermogen.Unit;
            descr = descr.charAt(0).toUpperCase() + descr.slice(1);

            const propdataInstallDescr = ntaEntityData.get(_installId).PropertyDatas['INSTALL_OMSCHR'];
            ntaEntityData.saveprop(propdataInstallDescr, descr);
        } //setDescriptionInstallation

        windenergieLogic.startFormValidation = function () {
            return ntaSharedLogic.startFormValidation(getAllEntdatas(), windenergieLogic);
        }; //-- end: startFormValidation ----------------------------------------------------------

        windenergieLogic.endFormValidation = function () {
            return ntaSharedLogic.endFormValidation(getAllEntdatas(), windenergieLogic);
        }; //-- end: endFormValidation ------------------------------------------------------------

        function getAllEntdatas() {
            return [
                _winddata,
                ntaSharedLogic.getCostEntdata(_installId)
            ]
                .filter(entdata => entdata);
        } //-- end: getAllEntdatas ----------------------------------------------------------------

        windenergieLogic.validate = function (prop, propdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }
            const hidden = windenergieLogic.isHidden(prop);
            ntaValidation.IsValid(windenergieLogic.form_installationwindturbine, prop, propdata, hidden);  //Client Validatie
        };

        windenergieLogic.isHidden = function (prop, entdata = _winddata) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = prop.getData(entdata);

            let visible = true;
            let relevant = null;

            switch (prop.Id) {
                case 'WINDT_OPEN':
                case 'MEASURE-COSTS_INV':
                case 'MEASURE-COSTS_PER_M2':
                case 'MEASURE-COSTS_PER_SYS':
                    visible = false;
                    break;

                default: {
                    visible = true;
                    break;
                }
            }

            if (relevant === null) relevant = visible;

            ntaEntityData.setPropdataStatus(propdata, relevant, visible);

            return !visible;
        };

        windenergieLogic.setGeopend = function () {
            const propdataOpen = _winddata.PropertyDatas['WINDT_OPEN'];
            ntaEntityData.saveprop(propdataOpen, 'true');
        };

        // Ontvangt afhankelijk veld dat gevalideerd moet worden
        windenergieLogic.validateDependencies = function (prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            switch (prop.Id) {
                case 'WINDT_OVPJ': {
                    const checkValue = prop.getValue(entdata);
                    windenergieLogic.isHidden(prop, entdata);
                    if (checkValue !== prop.getData(entdata).Value) {
                        windenergieLogic.saveValue(prop);
                    }
                    setDescriptionInstallation(entdata);
                    break;
                }

                default: {
                    const checkValue = prop.getValue(entdata);
                    windenergieLogic.isHidden(prop, entdata);
                    if (checkValue !== prop.getData(entdata).Value) {
                        windenergieLogic.saveValue(prop);
                    }
                    break;
                }
            }
        };

        function checkMeasureCosts() {
            // *als* we in een maatregel zitten, dan controleren dat er een MEASURE-COST entiteit bestaat voor elke installatie.
            if (_isEditingMeasure) {
                const installatie = ntaEntityData.get(_installId);
                const costs = ntaEntityData.getFirstChild(installatie, 'MEASURE-COSTS');
                if (!costs) {
                    const parentRels = [
                        { OnCopy: 1, OnDelete: 1, Parent: installatie.EntityDataId, ParentEntityId: installatie.EntityId }
                    ];
                    ntaEntityData.create('MEASURE-COSTS', -1, parentRels, [], []);
                }
            }
        }

        checkMeasureCosts();

    }
}]);
