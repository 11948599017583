﻿angular.module('projectModule').factory("BuildingLibraryFactory", ['ntabuilding', 'ntaValidation', 'ntaEntityData', 'settingsMenuData', 'ntaSharedLogic',
function (ntabuilding, ntaValidation, ntaEntityData, settingsMenuData, ntaSharedLogic) {
    'use strict';
    function LibraryLogic(ntaDependencyValidation) {
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        const libraryLogic = this; // Object dat aan de controller gegeven zal worden

        const _basisOpname = ntaSharedLogic.isBasisOpname();

        /* Properties **********************************************************************************************/
        const _libclosedconstructionsproperties = ntabuilding.properties['LIBCONSTRD'];
        const _libtranspconstructionsproperties = ntabuilding.properties['LIBCONSTRT'];
        const _liblinconstructionsproperties = ntabuilding.properties['LIBCONSTRL'];
        const _libthermbrugproperties = ntabuilding.properties['LIBTHBRUG'];
        const _libformulierproperties = ntabuilding.properties['LIBCONSTRFORM'];

        libraryLogic.libclosedconstructionsproperties = _libclosedconstructionsproperties;
        libraryLogic.libtranspconstructionsproperties = _libtranspconstructionsproperties;
        libraryLogic.liblinconstructionsproperties = _liblinconstructionsproperties;
        libraryLogic.libthermbrugproperties = _libthermbrugproperties;
        libraryLogic.libformulierproperties = _libformulierproperties;

        /* Data *************************************************************************************************************************************************************/

        let _libformulier = ntaEntityData.getFirstWithEntityId('LIBCONSTRFORM');

        libraryLogic.libClosedConstructions = libClosedConstructions;
        libraryLogic.libTranspConstructions = libTranspConstructions;
        libraryLogic.libLinConstructions = libLinConstructions;
        libraryLogic.libThermbruggen = libThermbruggen;


        /* Tabellen **********************************************************************************************/
        const _tableDichteConstructies = createTableDichteConstructies();
        const _tableTransparanteConstructies = createTableTransparanteConstructies();
        const _tableLineaireConstructies = createTableLineaireConstructies();

        libraryLogic.isHidden = isHidden;
        libraryLogic.isHiddenProp = isHiddenProp;
        libraryLogic.isReadOnly = isReadOnly;

        libraryLogic.validate = validate;
        libraryLogic.validateDependencies = validateDependencies;

        libraryLogic.startFormValidation = startFormValidation;
        libraryLogic.endFormValidation = endFormValidation;

        libraryLogic.saveValue = saveValue;
        libraryLogic.getCodedValues = getCodedValues;

        libraryLogic.getListItem = getListItem;
        libraryLogic.dependencyValidator = ntaDependencyValidation;

        libraryLogic.initFormEnConstructies = initFormEnConstructies; //-- weet nog niet of dit eigenlijk in controller thuis hoort omdat het om het formulier gaat
        libraryLogic.createLibClosedConstruction = createLibClosedConstruction;
        libraryLogic.createLibTranspConstruction = createLibTranspConstruction;
        libraryLogic.createLibLinConstruction = createLibLinConstruction;
        libraryLogic.createLibThermBrug = createLibThermBrug;

        libraryLogic.checkConditionD = checkConditionD;

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         INITALIZIATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        initFormEnConstructies();
        checkConditionD(); //-- <<-- hier wordt gekeken of de lineaire en puntvormige koudebruggen relevant/visible moeten zijn nav settingMenu thermische bruggen forfaitair


        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        function initFormEnConstructies() {
            if (!_libformulier) {
                ntaEntityData.create('LIBCONSTRFORM', -1, [], [], []);
                _libformulier = ntaEntityData.getFirstWithEntityId('LIBCONSTRFORM');
            }
            libraryLogic.libFormulier = _libformulier;

            if (libClosedConstructions().length === 0) {
                createLibClosedConstruction(null);
            }

            if (libTranspConstructions().length === 0) {
                createLibTranspConstruction(null);
            }

            if (libLinConstructions().length === 0) {
                createLibLinConstruction(null);
            }
        } //-- end: initFormEnConstructies ------------------------------------------------------------------------//

        function libClosedConstructions() {
            return ntaEntityData.getListWithEntityId('LIBCONSTRD');
        } //-- end: libClosedConstructions ------------------------------------------------------------------------//

        function libTranspConstructions() {
            return ntaEntityData.getListWithEntityId('LIBCONSTRT')
        } //-- end: libTranspConstructions ------------------------------------------------------------------------//

        function libLinConstructions() {
            return ntaEntityData.getListWithEntityId('LIBCONSTRL')
        } //-- end: libLinConstructions ------------------------------------------------------------------------//

        function libThermbruggen() {
            return ntaEntityData.getListWithEntityId('LIBTHBRUG')
        } //-- end: libThermbruggen ------------------------------------------------------------------------//

        function getListItem(prop, entdata, list = null) {
            let listItem = null;

            if (prop && prop.Domain && prop.Domain.DomainType > 1 && entdata) {
                var tmpList = null;
                var tmpId;
                var propdata = entdata.PropertyDatas[prop.Id];
                switch (prop.Id) {
                    default:
                        tmpId = propdata.Value;
                        tmpList = list; // de gefilterde getCodedValue list. Op deze list moet ik checken
                        if (!tmpList) {
                            //-- anders ongefilterde codedValue list gebruiken
                            tmpList = prop.Domain.Codes;
                        }
                        break;
                }

                if (propdata.Value && tmpList && tmpList.length > 0) {
                    listItem = tmpList.find(function (x) { return x.Id === tmpId; });
                }

                if (!listItem) {
                    //-- ik kan nu kijken of er eventureel een alternatief beschikbaalr is.
                    let alternative = '';
                    if (prop.Domain && prop.Domain.DomainType === 4) {
                        let product = getProductWithoutFilter(prop, entdata);
                        if (product) {
                            listItem = list.find(function (x) { return x.Value === product.Value; });

                            //-- Stel alternatief toestel in
                            if (listItem) {
                                alternative = listItem.Id.toString();
                            }
                        }
                    } else {
                        if (prop.Id === 'LIBCONSTRT_BEPALING') {
                            const transparanteconstructieOptie = _tableTransparanteConstructies.find(function (x) { return x.Id === propdata.Value; });
                            if (transparanteconstructieOptie) {
                                listItem = list.find(function (x) { return x.Value === transparanteconstructieOptie.Value; });

                                // Stel alternatieve bepaling in
                                if (listItem) {
                                    alternative = listItem.Id;
                                }
                            }
                        }
                    }

                    if (alternative !== '') {
                        saveValue(prop, entdata, alternative);
                    }
                }
            }

            return listItem;
        } //-- end: getListItem ------------------------------------------------------------------------//

        function validate(prop, propdata, entdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !entdata || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            let hidden = isHidden(prop, entdata);
            let readonly = isReadOnly(prop, entdata);
            if (!hidden && !readonly && ntaValidation.hasCodedValues(prop)) {
                getCodedValues(prop, entdata);
            }
            let valid = ntaValidation.IsValid(libraryLogic.form_bldlib, prop, propdata, hidden, readonly);

            switch (prop.Id) {
                default:
            }

            return valid;
        } //-- end: validate ------------------------------------------------------------------------//

        function saveValue(prop, entdata, newValue) {
            ntaSharedLogic.saveValue(prop, entdata, newValue, libraryLogic);
        } //-- end: saveValue ------------------------------------------------------------------------//

        function getCodedValues(prop, entdata) {
            let typesList = [];

            if (!entdata || !prop || entdata.BuildingId !== ntabuilding.buildingId) {
                return typesList;
            }

            switch (prop.Id) {
                case 'LIBCONSTRD_METH':
                case 'LIBCONSTRT_METH':
                case 'LIBCONSTRL_METH':
                case 'LIBTHBRUG_METH': {
                    //-- VO 2020-12-16: voorals nog even niet filteren op BASIS om dat vrije invoer ook nog mogelijk moet zijn omdat de BCRG nog niet zo ver is.
                    //--const value = _basisOpname ? 'BASIS' : null;
                    const value = null;
                    typesList = ntaValidation.codedValues(prop, value, true);

                    if (prop.Id === 'LIBCONSTRT_METH') {
                       typesList = typesList.filter(function (x) { return x.Id !== 'TRANS_NVT'; }); //vooralsnog nvt er altijd uit ! 
                    }

                    break;
                }
                case 'LIBCONSTRD_BEPALING': {
                    typesList = _tableDichteConstructies;

                    //-- Toon n.v.t.
                    if (bepalingShouldBeHidden(entdata)) {
                        typesList = typesList.filter(function (x) { return x.Id === 'LIBCONSTRD_BEPALING_NVT'; });
                        break;
                    }
                    else {
                        typesList = typesList.filter(function (x) { return x.Id !== 'LIBCONSTRD_BEPALING_NVT'; });
                    }

                    //-- Filter op key1
                    let gebouwtype = ntaSharedLogic.getGebouwType();

                    if (gebouwtype === 'TGEB_WOONW') {
                        typesList = typesList.filter(function (x) { return x.key1 === 'woonwagen' || x.key1 === ''; });
                    }
                    else if (gebouwtype === 'TGEB_WOONBN') {
                        typesList = typesList.filter(function (x) { return x.key1 === 'woonboot' || x.key1 === 'woonboot - ligplaats voor 1 januari 2018' || x.key1 === ''; });
                    }
                    else if (gebouwtype === 'TGEB_WOONBB') {
                        typesList = typesList.filter(function (x) { return x.key1 === 'woonboot' || x.key1 === 'woonboot - ligplaats vanaf 1 januari 2018' || x.key1 === ''; });
                    }
                    else if (gebouwtype && gebouwtype !== 'TGEB_WOONBB' && gebouwtype !== 'TGEB_WOONBN' && gebouwtype !== 'TGEB_WOONW') {
                        typesList = typesList.filter(function (x) { return x.key1 === '≠ woonboot of woonwagen' || x.key1 === ''; });
                    }

                    //-- Filter op key2
                    let bouwjaar = ntaSharedLogic.getBouwjaar();

                    if (bouwjaar > 1964) {
                        typesList = typesList.filter(function (x) { return x.key2 !== 'bouwjaar 1000 tm 1964'; });
                    }
                    if (bouwjaar > 1974) {
                        typesList = typesList.filter(function (x) { return x.key2 !== 'bouwjaar 1000 tm 1974'; });
                    }
                    if (bouwjaar > 1982) {
                        typesList = typesList.filter(function (x) { return x.key2 !== 'bouwjaar 1000 tm 1982'; });
                    }
                    if (bouwjaar > 1987) {
                        typesList = typesList.filter(function (x) { return x.key2 !== 'bouwjaar 1000 tm 1987'; });
                    }
                    if (bouwjaar > 1991) {
                        typesList = typesList.filter(function (x) { return x.key2 !== 'bouwjaar 1000 tm 1991'; });
                    }
                    if (bouwjaar > 2013) {
                        typesList = typesList.filter(function (x) { return x.key2 !== 'bouwjaar 1000 tm 2013'; });
                    }
                    if (bouwjaar > 2014) {
                        typesList = typesList.filter(function (x) { return x.key2 !== 'bouwjaar 1000 tm 2014'; });
                    }
                    if (bouwjaar > 2017) {
                        typesList = typesList.filter(function (x) { return x.key2 !== 'bouwjaar 1000 tm 2017'; });
                    }
                    if (bouwjaar > 2020) {
                        typesList = typesList.filter(function (x) { return x.key2 !== 'bouwjaar 1000 tm 2020'; });
                    }

                    //-- Filter op key3
                    let vlakType = entdata.PropertyDatas['LIBCONSTRD_TYPE'].Value;
                    let includeChar = '';
                    switch (vlakType) {
                        case 'LIBVLAK_VLOER': includeChar = 'v'; break;
                        case 'LIBVLAK_GEVEL': includeChar = 'g'; break;
                        case 'LIBVLAK_DAK': includeChar = 'd'; break;
                        case 'LIBVLAK_KELDERW': includeChar = 'k'; break;
                        case 'LIBVLAK_VLOER_BOVBUI': includeChar = 'vb'; break;
                        case 'LIBVLAK_BODEM': includeChar = 'b'; break;
                    }
                    //-- VO 2022-01-17: Belangrijk is dat er voor en achter de char's van key3 altijd een "," staat. Ook bij de eerste en de laatste. Anders kan er niet goed gefilterd worden bij getCodedValues
                    includeChar = "," + includeChar + ",";
                    typesList = typesList.filter(function (x) { return ("," + x.key3 + ",").includes(includeChar); });
                    break;
                }
                case 'LIBCONSTRT_BEPALING': { // L33
                    typesList = _tableTransparanteConstructies;

                    //-- Filterern op version
                    const version = ntabuilding.ntaVersionId >= 300 ? "gt3.2" : "le3.2";
                    typesList = typesList.filter(x => !x.version || x.version === version);

                    //-- Toon n.v.t.
                    if (bepalingTransShouldBeHidden(entdata)) {
                        typesList = typesList.filter(function (x) { return x.Id === 'LIBCONSTRT_BEPALING_NVT'; });
                        break;
                    }
                    typesList = typesList.filter(function (x) { return x.Id !== 'LIBCONSTRT_BEPALING_NVT'; });

                    //-- Filter op key1
                    const typeTransparanteConstructie = entdata.PropertyDatas['LIBCONSTRT_TYPE'].Value;

                    if (typeTransparanteConstructie === 'TRANSTYPE_RAAM') {
                        typesList = typesList.filter(function (x) { return x.key1 === 'raam'; });

                        //-- Filter op key2
                        const typeKozijn = entdata.PropertyDatas['LIBCONSTRT_KOZ'].Value;
                        let key2Value = '';
                        switch (typeKozijn) {
                            case 'LIBCONSTRT_KOZ_1': key2Value = 'hout / kunststof; grenzend aan buiten'; break;
                            case 'LIBCONSTRT_KOZ_2': key2Value = 'hout / kunststof; niet grenzend aan buiten'; break;
                            case 'LIBCONSTRT_KOZ_3': key2Value = 'metaal ther. onderbroken; grenzend aan buiten'; break;
                            case 'LIBCONSTRT_KOZ_4': key2Value = 'metaal ther. onderbroken; niet grenzend aan buiten'; break;
                            case 'LIBCONSTRT_KOZ_5': key2Value = 'metaal niet ther. onderbroken; grenzend aan buiten'; break;
                            case 'LIBCONSTRT_KOZ_6': key2Value = 'metaal niet ther. onderbroken; niet grenzend aan buiten'; break;
                        }
                        if (key2Value !== '') {
                            typesList = typesList.filter(function (x) { return x.key2 === key2Value; });
                        } else {
                            typesList = [];
                        }
                    } else if (typeTransparanteConstructie === 'TRANSTYPE_DEUR') {
                        typesList = typesList.filter(function (x) { return x.key1 === 'deur'; });
                    } else if (typeTransparanteConstructie === 'PNL_KOZ') {
                        typesList = typesList.filter(function (x) { return x.key1 === 'paneel'; });
                        //-- Filter op key2
                        const typeKozijn = entdata.PropertyDatas['LIBCONSTRT_KOZ'].Value;
                        let key2Value = '';
                        switch (typeKozijn) {
                            case 'LIBCONSTRT_KOZ_1': key2Value = 'hout / kunststof; grenzend aan buiten'; break;
                            case 'LIBCONSTRT_KOZ_2': key2Value = 'hout / kunststof; niet grenzend aan buiten'; break;
                            case 'LIBCONSTRT_KOZ_3': key2Value = 'metaal ther. onderbroken; grenzend aan buiten'; break;
                            case 'LIBCONSTRT_KOZ_4': key2Value = 'metaal ther. onderbroken; niet grenzend aan buiten'; break;
                            case 'LIBCONSTRT_KOZ_5': key2Value = 'metaal niet ther. onderbroken; grenzend aan buiten'; break;
                            case 'LIBCONSTRT_KOZ_6': key2Value = 'metaal niet ther. onderbroken; niet grenzend aan buiten'; break;
                        }
                        if (key2Value !== '') {
                            typesList = typesList.filter(function (x) { return x.key2 === key2Value; });
                        } else {
                            typesList = [];
                        }

                        //filter key3 op bouwjaar (GEB_BWJR, GEB_OPLVJR) uit GEB
                        let bouwjaar = ntaSharedLogic.getBouwjaar();
                        typesList.filter(function (x) { return x.key3 === Number.NaN ? true : x.key3 <= bouwjaar; }); // als key3 geen getal is altijd meenemen
                    }
                    else {
                        typesList = [];
                    }
                    break;
                }
                case 'LIBCONSTRT_KOZ': {
                    typesList = ntaValidation.codedValues(prop);

                    if (kozijnShouldBeHidden(entdata)) {
                        typesList = typesList.filter(function (x) { return x.Id === 'LIBCONSTRT_KOZ_NVT'; });
                    }
                    else {
                        typesList = typesList.filter(function (x) { return x.Id !== 'LIBCONSTRT_KOZ_NVT'; });
                    }

                    break;
                }
                case 'LIBCONSTRL_BEPALING': { // L34
                    typesList = _tableLineaireConstructies;

                    //-- Toon n.v.t.
                    if (bepalingLinShouldBeHidden(entdata)) {
                        typesList = typesList.filter(function (x) { return x.Id === 'LIBCONSTRL_BEPALING_NVT'; });
                        break;
                    }
                    else {
                        typesList = typesList.filter(function (x) { return x.Id !== 'LIBCONSTRL_BEPALING_NVT'; });
                    }

                    //-- Filter op key1
                    let typeLineaireConstructie = entdata.PropertyDatas['LIBCONSTRL_POS'].Value;
                    let key1Value = '';
                    switch (typeLineaireConstructie) {
                        case 'LCPOS_FUND': key1Value = 'f'; break;
                        case 'LCPOS_VLOERONGEB': key1Value = 'vo'; break;
                        case 'LCPOS_VLOER': key1Value = 'vl'; break;
                        case 'LCPOS_DAK': key1Value = 'd'; break;
                    }
                    typesList = typesList.filter(function (x) { return x.key1.includes(key1Value); });

                    break;
                }
                case 'LIBCONSTRD_TYPE': { //L05
                    typesList = ntaValidation.codedValues(prop);
                    //-- Conditie [M] bij woonboten geen keuze voor kelderwanden
                    const gebouwType = ntaSharedLogic.getGebouwType();
                    if (['TGEB_WOONBB', 'TGEB_WOONBN', 'TGEB_WOONW'].includes(gebouwType)) {
                        typesList = typesList.filter(t => t.Id != 'LIBVLAK_KELDERW');
                    }

                    break;
                }
                default: {
                    typesList = ntaValidation.codedValues(prop);
                    break;
                }
            }

            ntaSharedLogic.checkPropdataInList(prop, entdata, typesList, libraryLogic);
            return typesList;
        } //-- end: getCodedValues ------------------------------------------------------------------------//

        function isReadOnly(prop, entdata) {
            let bReadOnly = false;

            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return bReadOnly;
            }

            switch (prop.Id) {
                case 'LIBCONSTRD_BEPALING':
                    {
                        bReadOnly = bepalingShouldBeHidden(entdata);
                        break;
                    }
                case 'LIBCONSTRD_DIKTE_ISO':
                    {
                        bReadOnly = dikteIsolatieShouldBeHidden(entdata);
                        break;
                    }
                case 'LIBCONSTRD_DIKTE_RIET':
                    {
                        bReadOnly = dikteRietpakketShouldBeHidden(entdata);
                        break;
                    }
                case 'LIBCONSTRD_RC':
                    {
                        let methodiek = entdata.PropertyDatas['LIBCONSTRD_METH'].Value;
                        bReadOnly = methodiek === 'BESLISS';
                        break;
                    }
                case 'LIBCONSTRT_KOZ':
                    {
                        bReadOnly = kozijnShouldBeHidden(entdata);
                        break;
                    }
                case 'LIBCONSTRT_BEPALING':
                    {
                        bReadOnly = bepalingTransShouldBeHidden(entdata);
                        break;
                    }
                case 'LIBCONSTRL_BEPALING':
                    {
                        bReadOnly = bepalingLinShouldBeHidden(entdata);
                        break;
                    }
                case 'LIBCONSTRT_U':
                case 'LIBCONSTRT_G':
                    {
                        let propMethodiek = _libtranspconstructionsproperties['LIBCONSTRT_METH'];
                        let propValueMethodiek = propMethodiek.getValue(entdata);
                        bReadOnly = !isHidden(propMethodiek, entdata) && propValueMethodiek === 'TRANS_BESLISS';
                        break;
                    }
                case 'LIBCONSTRL_PSI':
                    {
                        let methodiek = entdata.PropertyDatas['LIBCONSTRL_METH'].Value;
                        bReadOnly = methodiek === 'LIN_BESLISS';
                        break;
                    }
            }

            return bReadOnly;
        } //-- end: isReadOnly ------------------------------------------------------------------------//

        function isHiddenProp(prop) {
            if (!prop) {
                return true;
            }

            let hidden = false;

            switch (prop.Id) {
                case 'LIBTHBRUG_METH':
                    {
                        //-- TODO -> In fase 1 is deze property hidden. In fase 1 zijn er nog geen methodes geimplementeerd.
                        hidden = true;
                        break;
                    }
                case 'LIBCONSTRT_AC':
                    {
                        if (_libformulier) {
                            const koz = _libformulier.PropertyDatas["LIBCONSTRFORM_KOZ"].Value;
                            hidden = koz === "KOZKENM_GEEN";
                        }
                        break;
                    }
                case 'LIBCONSTRD_BEPALING':
                    {
                        hidden = bepalingIsHidden();
                        break;
                    }
                case 'LIBCONSTRD_DIKTE_ISO':
                    {
                        hidden = dikteIsolatieIsHidden();
                        break;
                    }
                case 'LIBCONSTRD_DIKTE_RIET':
                    {
                        hidden = dikteRietpakketIsHidden();
                        break;
                    }
                case 'LIBCONSTRT_KOZ':
                    {
                        hidden = kozijnIsHidden();
                        break;
                    }
                case 'LIBCONSTRT_BEPALING':
                    {
                        hidden = bepalingTransIsHidden();
                        break;
                    }
                case 'LIBCONSTRL_BEPALING':
                    {
                        hidden = bepalingLinIsHidden();
                        break;
                    }
            }

            return hidden;
        } //-- end: isHiddenProp ------------------------------------------------------------------------//

        function isHidden(prop, entdata) {
            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return false;
            }

            const propdata = prop.getData(entdata);
            let showit = true;
            let relevant = null;

            switch (prop.Id) {
                case 'LIBTHBRUG_METH': {
                    showit = false;
                    break;
                }
                case 'LIBCONSTRT_AC': {
                    if (_libformulier) {
                        const koz = _libformulier.PropertyDatas["LIBCONSTRFORM_KOZ"].Value;
                        const hide = koz === "KOZKENM_GEEN";
                        showit = !hide;
                    }
                    break;
                }
                case 'LIBCONSTRFORM_OPEN': {
                    showit = false;
                    break;
                }
                case 'LIBCONSTRFORM_OPM': {
                    showit = true;
                    break;
                }
                case 'LIBCONSTRD_BEPALING': { // L29
                    showit = !bepalingIsHidden();
                    relevant = !bepalingShouldBeHidden(entdata);
                    break;
                }
                case 'LIBCONSTRD_DIKTE_ISO': { // L30
                    showit = !dikteIsolatieIsHidden();
                    relevant = !dikteIsolatieShouldBeHidden(entdata);
                    break;
                }
                case 'LIBCONSTRD_DIKTE_RIET': { // L31
                    showit = !dikteRietpakketIsHidden();
                    relevant = !dikteRietpakketShouldBeHidden(entdata);
                    break;
                }
                case 'LIBCONSTRT_KOZ': { // L32
                    showit = !kozijnIsHidden();
                    relevant = !kozijnShouldBeHidden(entdata);
                    break;
                }
                case 'LIBCONSTRT_BEPALING': { // L33
                    showit = !bepalingTransIsHidden();
                    relevant = !bepalingTransShouldBeHidden(entdata);
                    break;
                }
                case 'LIBCONSTRL_BEPALING': { // L34
                    showit = !bepalingLinIsHidden();
                    relevant = !bepalingLinShouldBeHidden(entdata);
                    break;
                }
                default:
                    //-- VO 2020-11-18: ik ga even testen hoe dit werkt. Veel problemen met spook validatie en dat komt misschien omdat de property hierboven niet gechekt
                    //-- hoeft te worden en daardoor gezien wordt als hidden = false, maar ik als zijn entiteit niet relevant is, is hij ook hidden.
                    showit = entdata.Relevant;
            }

            //-- standaard is relevant gelijk aan showit
            if (relevant === null) relevant = showit;
            ntaEntityData.setPropdataRelevancy(propdata, relevant, libraryLogic.form_bldlib);
            ntaEntityData.setPropdataVisibility(propdata, showit);

            return !showit;
        } //-- end: isHidden ------------------------------------------------------------------------//

        function bepalingIsHidden() {
            //-- Conditie [E]
            let showit = false; //-- moet ik de propertie in de header van de dichte constructietabel laten zien
            for (const closedConst of libClosedConstructions()) {
                showit = !bepalingShouldBeHidden(closedConst);
                //-- als er sowieso één dichte constructie een bepaling heeft moet dan de header laten zien
                if (showit) {
                    break;
                }
            }

            return !showit;
        } //-- end: bepalingIsHidden ------------------------------------------------------------------------//

        function bepalingShouldBeHidden(entdata) {
            //-- Conditie [E]
            let methodiek = entdata.PropertyDatas['LIBCONSTRD_METH'].Value;
            let showit = methodiek === 'BESLISS';

            return !showit;
        } //-- end: bepalingShouldBeHidden ------------------------------------------------------------------------//

        function dikteIsolatieIsHidden() {
            //-- Conditie [F]
            let showit = false; //-- moet ik de propertie in de header van de dichte constructietabel laten zien
            for (const closedConst of libClosedConstructions()) {
                showit = !dikteIsolatieShouldBeHidden(closedConst);
                //-- als er sowieso één dichte constructie een isolatie heeft moet dan de header laten zien
                if (showit) {
                    break;
                }
            }

            return !showit;
        } //-- end: dikteIsolatieIsHidden ------------------------------------------------------------------------//

        function dikteIsolatieShouldBeHidden(entdata) {
            //-- Conditie [F]
            let showit = false;
            let bepaling = entdata.PropertyDatas['LIBCONSTRD_BEPALING'].Value;
            if (bepaling) {
                let bepalingOptie = _tableDichteConstructies.find(function (x) { return x.Id === bepaling; });
                showit = bepalingOptie.key4 === 'riet_tb';
            }

            return !showit;
        } //-- end: dikteIsolatieShouldBeHidden ------------------------------------------------------------------------//

        function dikteRietpakketIsHidden() {
            //-- Conditie [G]
            let showit = false; //-- moet ik de propertie in de header van de dichte constructietabel laten zien
            for (const closedConst of libClosedConstructions()) {
                showit = !dikteRietpakketShouldBeHidden(closedConst);
                //-- als er sowieso één dichte constructie een rietpakket moet dan de header laten zien
                if (showit) {
                    break;
                }
            }

            return !showit;
        } //-- end: dikteRietpakketIsHidden ------------------------------------------------------------------------//

        function dikteRietpakketShouldBeHidden(entdata) {
            //-- Conditie [G]
            let showit = false;
            let bepaling = entdata.PropertyDatas['LIBCONSTRD_BEPALING'].Value;
            if (bepaling) {
                let bepalingOptie = _tableDichteConstructies.find(function (x) { return x.Id === bepaling; });
                showit = bepalingOptie.key4 === 'riet_tb' || bepalingOptie.key4 === 'riet_ntb';
            }

            return !showit;
        } //-- end: dikteRietpakketShouldBeHidden ------------------------------------------------------------------------//

        function kozijnIsHidden() {
            //-- Conditie [J]
            let showit = false; //-- moet ik de propertie in de header van de transparente constructietabel laten zien
            for (const transConst of libTranspConstructions()) {
                showit = !kozijnShouldBeHidden(transConst);
                //-- als er sowieso één transparante constructie het beslisschema heeft (TRANS_BESLISS) dan header voor kozijntype al tonen
                if (showit) {
                    break;
                }
            }

            return !showit;
        } //-- end: kozijnIsHidden ------------------------------------------------------------------------//

        function kozijnShouldBeHidden(entdata) {
            //-- Conditie [I]
            const typeTransparanteConstructie = entdata.PropertyDatas['LIBCONSTRT_TYPE'].Value;

            const propMethodiek = _libtranspconstructionsproperties['LIBCONSTRT_METH'];
            const methodiek = propMethodiek.getValue(entdata);

            const showit = (typeTransparanteConstructie === 'TRANSTYPE_RAAM' || typeTransparanteConstructie === 'PNL_KOZ') && !isHidden(propMethodiek, entdata) && methodiek === 'TRANS_BESLISS';

            return !showit;
        } //-- end: kozijnShouldBeHidden ------------------------------------------------------------------------//

        function bepalingTransIsHidden() {
            //-- Conditie [J]
            let showit = false; //-- moet ik de propertie in de header van de transparente constructietabel laten zien
            for (const transConst of libTranspConstructions()) {
                showit = !bepalingTransShouldBeHidden(transConst);
                //-- als er sowieso één transparante constructie het beslisschema heeft (TRANS_BESLISS) dan header al tonen
                if (showit) {
                    break;
                }
            }

            return !showit;
        } //-- end: bepalingTransIsHidden ------------------------------------------------------------------------//

        function bepalingTransShouldBeHidden(entdata) {
            //-- Conditie [J]
            let propMethodiek = _libtranspconstructionsproperties['LIBCONSTRT_METH'];
            let methodiek = propMethodiek.getValue(entdata);
            let showit = !isHidden(propMethodiek, entdata) && methodiek === 'TRANS_BESLISS';

            return !showit;
        } //-- end: bepalingTransShouldBeHidden ------------------------------------------------------------------------//

        function bepalingLinIsHidden() {
            //-- Conditie [K]
            let showit = false; //-- moet ik de propertie in de header van de Lineaire tabel laten zien
            for (const linConst of libLinConstructions()) {
                showit = !bepalingLinShouldBeHidden(linConst);
                //-- als er sowieso één lineaire constructie de NTA8800 bijlage 1 methode heeft (LIN_BESLISS) dan header al tonen
                if (showit) {
                    break;
                }
            }

            return !showit;
        } //-- end: bepalingLinIsHidden ------------------------------------------------------------------------//

        function bepalingLinShouldBeHidden(entdata) {
            //-- Conditie [K]
            let propMethodiek = _liblinconstructionsproperties['LIBCONSTRL_METH'];
            let methodiek = propMethodiek.getValue(entdata);
            let showit = !isHidden(propMethodiek, entdata) && methodiek === 'LIN_BESLISS';

            return !showit;
        } //-- end: bepalingLinShouldBeHidden ------------------------------------------------------------------------//

        function checkConditionD() {
            //-- conditie [D] show table if dropdown 'instellingen' selection 'lineaire warmteverliezen forfaitair' is OFF
            let show = settingsMenuData.getSetting('SETTINGS_THBRUG').Value === "False";

            ntaEntityData.setEntityRelevancy(libThermbruggen(), show);
            ntaEntityData.setEntityRelevancy(libLinConstructions(), show);
            ntaEntityData.setEntityVisibility(libThermbruggen(), show);
            ntaEntityData.setEntityVisibility(libLinConstructions(), show);

            return show;
        } //-- end: checkConditionD ------------------------------------------------------------------------//

        function createLibClosedConstruction(entdata) {
            const methodiek = ntaSharedLogic.isNieuwbouw() ? 'VRIJE_INV' : 'BESLISS';

            ntaEntityData.create('LIBCONSTRD', entdata ? entdata.Order : -1, [], [], [{ "PropertyId": 'LIBCONSTRD_METH', "Value": methodiek }]);
        } //-- end: createLibClosedConstruction ------------------------------------------------------------------------//

        function createLibTranspConstruction(entdata) {
            const methodiek = ntaSharedLogic.isNieuwbouw() ? 'TRANS_VRIJE_INV' : 'TRANS_BESLISS';

            ntaEntityData.create('LIBCONSTRT', entdata ? entdata.Order : -1, [], [], [{ "PropertyId": 'LIBCONSTRT_METH', "Value": methodiek }]);
        } //-- end: createLibTranspConstruction ------------------------------------------------------------------------//

        function createLibLinConstruction(entdata) {
            ntaEntityData.create('LIBCONSTRL', entdata ? entdata.Order : -1, [], [], []);
        } //-- end: createLibLinConstruction ------------------------------------------------------------------------//

        function createLibThermBrug(entdata) {
            ntaEntityData.create('LIBTHBRUG', entdata ? entdata.Order : -1, [], [], []);
        } //-- end: createLibThermBrug ------------------------------------------------------------------------//

        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            switch (prop.Id) {
                case 'LIBCONSTRD_METH':
                case 'LIBCONSTRD_BEPALING':
                case 'LIBCONSTRD_TYPE':
                case 'LIBCONSTRD_DIKTE_RIET':
                case 'LIBCONSTRD_DIKTE_ISO':
                    {
                        //-- wanneer isodikte en rietdikte niet relevant zijn moet de waarde op n.v.t. gezet worden
                        let bIsoRietRelevant = false;
                        const nvt = "n.v.t.";
                        const propDikteIso = _libclosedconstructionsproperties["LIBCONSTRD_DIKTE_ISO"];
                        const propDikteRiet = _libclosedconstructionsproperties["LIBCONSTRD_DIKTE_RIET"];

                        //-- bepaal de  Rc van dichte constructie als het veld readonly is
                        let methodiek = entdata.PropertyDatas['LIBCONSTRD_METH'].Value;
                        if (methodiek === 'BESLISS') {
                            let bepaling = entdata.PropertyDatas['LIBCONSTRD_BEPALING'].Value;
                            if (bepaling) {
                                let bepalingOptie = _tableDichteConstructies.find(function (x) { return x.Id === bepaling; });
                                let vlakType = entdata.PropertyDatas['LIBCONSTRD_TYPE'].Value;

                                let propRc = _libclosedconstructionsproperties['LIBCONSTRD_RC'];

                                let newValue = "";
                                switch (vlakType) {
                                    case 'LIBVLAK_VLOER_BOVBUI':
                                        {
                                            newValue = bepalingOptie.Rc_vb;
                                            break;
                                        }
                                    case 'LIBVLAK_VLOER':
                                    case 'LIBVLAK_KELDERW':
                                        {
                                            newValue = bepalingOptie.Rc_v;
                                            break;
                                        }
                                    case 'LIBVLAK_GEVEL':
                                        {
                                            newValue = bepalingOptie.Rc_gpk;
                                            break;
                                        }
                                    case 'LIBVLAK_DAK':
                                        {
                                            if (bepaling === 'LIBCONSTRD_BEPALING_97' || bepaling === 'LIBCONSTRD_BEPALING_98') {
                                                const propdataDikteIsoValue = entdata.PropertyDatas[propDikteIso.Id].Value;
                                                const propdataDikteRietValue = entdata.PropertyDatas[propDikteRiet.Id].Value;

                                                if (propdataDikteRietValue !== nvt) {
                                                    let rietDikte = ntaSharedLogic.parseFloat(propdataDikteRietValue, 0);
                                                    let dRc = 0.04 / 0.045 + rietDikte / 1000 / 0.2;
                                                    newValue = dRc.toString();
                                                } else {
                                                    //--als deze bepaling is gekozen en er staat nog n.v.t. dan de waarde leeg maken
                                                    saveValue(propDikteRiet, entdata, "");
                                                }
                                                //-- voor LIBCONSTRD_BEPALING_98 ook de dikte van de isolatie berekenen of initializeren
                                                if (bepaling === 'LIBCONSTRD_BEPALING_98') {
                                                    if (propdataDikteIsoValue !== nvt) {
                                                        let rietDikte = ntaSharedLogic.parseFloat(propdataDikteRietValue, 0);
                                                        let isolDikte = ntaSharedLogic.parseFloat(propdataDikteIsoValue, 0);
                                                        let dRc = isolDikte / 1000 / 0.045 + rietDikte / 1000 / 0.2;
                                                        newValue = dRc.toString();
                                                    } else {
                                                        //--als deze bepaling is gekozen en er staat nog n.v.t. dan de waarde leeg maken
                                                        saveValue(propDikteIso, entdata, "");
                                                    }
                                                }
                                                bIsoRietRelevant = true;
                                            } else {
                                                newValue = bepalingOptie.Rc_d;
                                            }
                                            break;
                                        }
                                    case 'LIBVLAK_BODEM':
                                        {
                                            newValue = bepalingOptie.Rc_bf;
                                        }
                                }

                                if (newValue !== "") {
                                    saveValue(propRc, entdata, newValue);
                                }
                            }
                        }
                        if (!bIsoRietRelevant) {
                            //-- als ik geen rieten dak ben, moet de waarde voor iso en riet op n.v.t. staan, zodat je dat ziet
                            //-- in de tabel.
                            saveValue(propDikteRiet, entdata, nvt);
                            saveValue(propDikteIso, entdata, nvt);
                        }
                        break;
                    }
                case 'LIBCONSTRT_METH':
                case 'LIBCONSTRT_TYPE':
                case 'LIBCONSTRT_KOZ':
                case 'LIBCONSTRT_BEPALING':
                    {
                        //-- Stel U en ggl van transparante constructie in als het veld readonly is
                        let propMethodiek = _libtranspconstructionsproperties['LIBCONSTRT_METH'];
                        let methodiek = propMethodiek.getValue(entdata);
                        if (!isHidden(propMethodiek, entdata) && methodiek === 'TRANS_BESLISS') {

                            const typeTransparanteConstructie = entdata.PropertyDatas['LIBCONSTRT_TYPE'].Value;
                            const bepaling = entdata.PropertyDatas['LIBCONSTRT_BEPALING'].Value;
                            if (bepaling) {
                                let bepalingOptie = _tableTransparanteConstructies.find(function (x) { return x.Id === bepaling; });

                                let propU = _libtranspconstructionsproperties['LIBCONSTRT_U'];
                                let propGgl = _libtranspconstructionsproperties['LIBCONSTRT_G'];

                                let newU = bepalingOptie.U;
                                let newGl = bepalingOptie.ggl;
                                if (bepalingOptie.key1 === "paneel") {
                                    newGl = "0";
                                }

                                saveValue(propU, entdata, newU);
                                saveValue(propGgl, entdata, newGl);
                            }
                        }
                        break;
                    }
                case 'LIBCONSTRL_METH':
                case 'LIBCONSTRL_BEPALING':
                case 'LIBCONSTRL_POS':
                    {
                        //-- Stel Psi van lineaire constructie in als het veld readonly is
                        let methodiek = entdata.PropertyDatas['LIBCONSTRL_METH'].Value;
                        if (methodiek === 'LIN_BESLISS') {

                            let bepaling = entdata.PropertyDatas['LIBCONSTRL_BEPALING'].Value;
                            if (bepaling) {
                                let bepalingOptie = _tableLineaireConstructies.find(function (x) { return x.Id === bepaling; });

                                let propPsi = _liblinconstructionsproperties['LIBCONSTRL_PSI'];

                                let newPsi = bepalingOptie.psi;
                                saveValue(propPsi, entdata, newPsi);
                            }
                        }
                        break;
                    }
            }
        } //-- end: validateDependencies ------------------------------------------------------------------------//

        function startFormValidation() {
            ntaSharedLogic.startFormValidation(getAllEntDatas(), libraryLogic);
        } //-- end:  ------------------------------------------------------------------------//

        function endFormValidation() {
            ntaSharedLogic.endFormValidation(getAllEntDatas(), libraryLogic);
        } //-- end: endFormValidation ------------------------------------------------------------------------//

        function getAllEntDatas() { // Geeft alle entdatas in één array terug
            return []
                .concat(_libformulier)
                .concat(libClosedConstructions())
                .concat(libTranspConstructions())
                .concat(libLinConstructions())
                .concat(libThermbruggen())
                .filter(entdata => entdata) // filter alle nulls en undefineds eruit
                ;
        } //-- end: getAllEntDatas ------------------------------------------------------------------------//


        function createTableDichteConstructies() {
            //-- Rc en U waarde
            const dichteConstructies = [
                { "Id": "LIBCONSTRD_BEPALING_99",   "key1": "", "key2": "",                                 "key3": "g,k,v,vb,d", "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "0,19", "Rc_v": "0,15", "Rc_vb": "0,22",    "Rc_d": "0,22", "Rc_bf":"", "Value": "geen isolatie; geen spouw/onbekend" },
                { "Id": "LIBCONSTRD_BEPALING_100",  "key1": "", "key2": "",                                 "key3": "g,k,v,vb,d", "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "0,35", "Rc_v": "0,33", "Rc_vb": "0,35",    "Rc_d": "0,35", "Rc_bf":"", "Value": "geen isolatie; met spouw" },
                { "Id": "LIBCONSTRD_BEPALING_1",    "key1": "", "key2": "bouwjaar 1000 tm 1964",            "key3": "g,k,v,vb,d", "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "0,19", "Rc_v": "0,15", "Rc_vb": "0,22",    "Rc_d": "0,22", "Rc_bf":"", "Value": "isolatie onbekend; geen spouw/onbekend; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRD_BEPALING_2",    "key1": "", "key2": "bouwjaar 1000 tm 1964",            "key3": "g,k,v,vb,d", "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "0,35", "Rc_v": "0,33", "Rc_vb": "0,35",    "Rc_d": "0,35", "Rc_bf":"", "Value": "isolatie onbekend; met spouw; bouwjaarklasse < 1965" },

                { "Id": "LIBCONSTRD_BEPALING_3",    "key1": "woonwagen", "key2": "bouwjaar 1000 tm 1982",   "key3": "g,v,vb,d", "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "0,19",   "Rc_v": "0,17", "Rc_vb": "0,22",    "Rc_d": "0,22", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1965 - 1982" },
                { "Id": "LIBCONSTRD_BEPALING_4",    "key1": "woonwagen", "key2": "bouwjaar 1000 tm 1991",   "key3": "g,v,vb,d", "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "1,30",   "Rc_v": "1,30", "Rc_vb": "1,30",    "Rc_d": "1,30", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1983 - 1991" },
                { "Id": "LIBCONSTRD_BEPALING_5",    "key1": "woonwagen", "key2": "bouwjaar 1000 tm 2013",   "key3": "g,v,vb,d", "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "2,00",   "Rc_v": "2,00", "Rc_vb": "2,00",    "Rc_d": "2,00", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1992 - 2013" },
                { "Id": "LIBCONSTRD_BEPALING_6",    "key1": "woonwagen", "key2": "bouwjaar 1000 tm 2020",   "key3": "g,v,vb,d", "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "2,50",   "Rc_v": "2,50", "Rc_vb": "2,50",    "Rc_d": "2,50", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 2014 - 2020" },
                { "Id": "LIBCONSTRD_BEPALING_7",    "key1": "woonwagen", "key2": "",                        "key3": "g,v,vb,d", "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "2,60",   "Rc_v": "2,60", "Rc_vb": "2,60",    "Rc_d": "2,60", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse vanaf 2021" },

                { "Id": "LIBCONSTRD_BEPALING_8",    "key1": "woonboot",                                     "key2": "bouwjaar 1000 tm 1982", "key3": "g",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "0,43",    "Rc_v": "",     "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1965 - 1982; niet grenzend aan water" },
                { "Id": "LIBCONSTRD_BEPALING_9",    "key1": "woonboot",                                     "key2": "bouwjaar 1000 tm 1987", "key3": "g",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "1,30",    "Rc_v": "",     "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1983 - 1987; niet grenzend aan water" },
                { "Id": "LIBCONSTRD_BEPALING_10",   "key1": "woonboot",                                     "key2": "bouwjaar 1000 tm 1991", "key3": "g",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "2,00",    "Rc_v": "",     "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1988 - 1991; niet grenzend aan water" },
                { "Id": "LIBCONSTRD_BEPALING_11",   "key1": "woonboot",                                     "key2": "bouwjaar 1000 tm 2013", "key3": "g",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "2,50",    "Rc_v": "",     "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1992 - 2013; niet grenzend aan water" },
                { "Id": "LIBCONSTRD_BEPALING_12",   "key1": "woonboot",                                     "key2": "bouwjaar 1000 tm 2014", "key3": "g",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "3,50",    "Rc_v": "",     "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 2014; niet grenzend aan water" },
                { "Id": "LIBCONSTRD_BEPALING_13",   "key1": "woonboot",                                     "key2": "bouwjaar 1000 tm 2017", "key3": "g",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "4,50",    "Rc_v": "",     "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 2015 -2017; niet grenzend aan water" },
                { "Id": "LIBCONSTRD_BEPALING_14",   "key1": "woonboot - ligplaats voor 1 januari 2018",     "key2": "bouwjaar 1000 tm 2020", "key3": "g",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "3,50",    "Rc_v": "",     "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 2018 - 2020; niet grenzend aan water" },
                { "Id": "LIBCONSTRD_BEPALING_15",   "key1": "woonboot - ligplaats vanaf 1 januari 2018",    "key2": "bouwjaar 1000 tm 2020", "key3": "g",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "4,50",    "Rc_v": "",     "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 2018 - 2020; niet grenzend aan water" },
                { "Id": "LIBCONSTRD_BEPALING_16",   "key1": "woonboot - ligplaats voor 1 januari 2018",     "key2": "",                      "key3": "g",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "3,70",    "Rc_v": "",     "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse vanaf 2021; niet grenzend aan water" },
                { "Id": "LIBCONSTRD_BEPALING_17",   "key1": "woonboot - ligplaats vanaf 1 januari 2018",    "key2": "",                      "key3": "g",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "4,70",    "Rc_v": "",     "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse vanaf 2021; niet grenzend aan water" },
                { "Id": "LIBCONSTRD_BEPALING_18",   "key1": "woonboot",                                     "key2": "bouwjaar 1000 tm 1991", "key3": "g,v", "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "0,17",    "Rc_v": "0,17", "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1965 - 1991; grenzend aan water" },
                { "Id": "LIBCONSTRD_BEPALING_19",   "key1": "woonboot",                                     "key2": "bouwjaar 1000 tm 2014", "key3": "g,v", "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "2,50",    "Rc_v": "2,50", "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1992 - 2014; grenzend aan water" },
                { "Id": "LIBCONSTRD_BEPALING_20",   "key1": "woonboot",                                     "key2": "bouwjaar 1000 tm 2017", "key3": "g,v", "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "3,50",    "Rc_v": "3,50", "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 2015 - 2017; grenzend aan water" },
                { "Id": "LIBCONSTRD_BEPALING_21",   "key1": "woonboot - ligplaats voor 1 januari 2018",     "key2": "bouwjaar 1000 tm 2020", "key3": "g,v", "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "2,50",    "Rc_v": "2,50", "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 2018 - 2020; grenzend aan water" },
                { "Id": "LIBCONSTRD_BEPALING_22",   "key1": "woonboot - ligplaats vanaf 1 januari 2018",    "key2": "bouwjaar 1000 tm 2020", "key3": "g,v", "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "3,50",    "Rc_v": "3,50", "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 2018 - 2020; grenzend aan water" },
                { "Id": "LIBCONSTRD_BEPALING_23",   "key1": "woonboot - ligplaats voor 1 januari 2018",     "key2": "",                      "key3": "g,v", "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "2,60",    "Rc_v": "2,60", "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse vanaf 2021; grenzend aan water" },
                { "Id": "LIBCONSTRD_BEPALING_24",   "key1": "woonboot - ligplaats vanaf 1 januari 2018",    "key2": "",                      "key3": "g,v", "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "3,70",    "Rc_v": "3,70", "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse vanaf 2021; grenzend aan water" },
                { "Id": "LIBCONSTRD_BEPALING_25",   "key1": "woonboot",                                     "key2": "bouwjaar 1000 tm 1982", "key3": "vb,d",  "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "",     "Rc_vb": "0,35", "Rc_d": "0,35", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1965 - 1982" },
                { "Id": "LIBCONSTRD_BEPALING_26",   "key1": "woonboot",                                     "key2": "bouwjaar 1000 tm 1987", "key3": "vb,d",  "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "",     "Rc_vb": "1,30", "Rc_d": "1,30", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1983 - 1987" },
                { "Id": "LIBCONSTRD_BEPALING_27",   "key1": "woonboot",                                     "key2": "bouwjaar 1000 tm 1991", "key3": "vb,d",  "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "",     "Rc_vb": "2,00", "Rc_d": "2,00", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1988 - 1991" },
                { "Id": "LIBCONSTRD_BEPALING_28",   "key1": "woonboot",                                     "key2": "bouwjaar 1000 tm 2013", "key3": "vb,d",  "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "",     "Rc_vb": "2,50", "Rc_d": "2,50", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1992 - 2013" },
                { "Id": "LIBCONSTRD_BEPALING_29",   "key1": "woonboot",                                     "key2": "bouwjaar 1000 tm 2014", "key3": "vb,d",  "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "",     "Rc_vb": "3,50", "Rc_d": "3,50", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 2014" },
                { "Id": "LIBCONSTRD_BEPALING_30",   "key1": "woonboot",                                     "key2": "bouwjaar 1000 tm 2017", "key3": "vb,d",  "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "",     "Rc_vb": "6,00", "Rc_d": "6,00", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 2015 - 2017" },
                { "Id": "LIBCONSTRD_BEPALING_31",   "key1": "woonboot - ligplaats voor 1 januari 2018",     "key2": "bouwjaar 1000 tm 2020", "key3": "vb,d",  "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "",     "Rc_vb": "4,50", "Rc_d": "4,50", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 2018 - 2020" },
                { "Id": "LIBCONSTRD_BEPALING_32",   "key1": "woonboot - ligplaats vanaf 1 januari 2018",    "key2": "bouwjaar 1000 tm 2020", "key3": "vb,d",  "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "",     "Rc_vb": "6,00", "Rc_d": "6,00", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 2018 - 2020" },
                { "Id": "LIBCONSTRD_BEPALING_33",   "key1": "woonboot - ligplaats voor 1 januari 2018",     "key2": "",                      "key3": "vb,d",  "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "",     "Rc_vb": "4,70", "Rc_d": "4,70", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse vanaf 2021" },
                { "Id": "LIBCONSTRD_BEPALING_34",   "key1": "woonboot - ligplaats vanaf 1 januari 2018",    "key2": "",                      "key3": "vb,d",  "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "",     "Rc_vb": "6,30", "Rc_d": "6,30", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse vanaf 2021" },
                { "Id": "LIBCONSTRD_BEPALING_35",   "key1": "≠ woonboot of woonwagen",                      "key2": "bouwjaar 1000 tm 1974", "key3": "g",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "0,43",    "Rc_v": "",     "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1965 - 1974" },
                { "Id": "LIBCONSTRD_BEPALING_36",   "key1": "≠ woonboot of woonwagen",                      "key2": "bouwjaar 1000 tm 1987", "key3": "g",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "1,3",     "Rc_v": "",     "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1975 - 1987" },
                { "Id": "LIBCONSTRD_BEPALING_37",   "key1": "≠ woonboot of woonwagen",                      "key2": "bouwjaar 1000 tm 1991", "key3": "g",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "2",       "Rc_v": "",     "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1988 - 1991" },
                { "Id": "LIBCONSTRD_BEPALING_38",   "key1": "≠ woonboot of woonwagen",                      "key2": "bouwjaar 1000 tm 2013", "key3": "g",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "2,5",     "Rc_v": "",     "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1992 - 2013" },
                { "Id": "LIBCONSTRD_BEPALING_39",   "key1": "≠ woonboot of woonwagen",                      "key2": "bouwjaar 1000 tm 2014", "key3": "g",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "3,5",     "Rc_v": "",     "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 2014" },
                { "Id": "LIBCONSTRD_BEPALING_40",   "key1": "≠ woonboot of woonwagen",                      "key2": "bouwjaar 1000 tm 2020", "key3": "g",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "4,5",     "Rc_v": "",     "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 2015 - 2020" },
                { "Id": "LIBCONSTRD_BEPALING_41",   "key1": "≠ woonboot of woonwagen",                      "key2": "",                      "key3": "g",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "4,7",     "Rc_v": "",     "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse vanaf 2021" },
                { "Id": "LIBCONSTRD_BEPALING_42",   "key1": "≠ woonboot of woonwagen",                      "key2": "bouwjaar 1000 tm 1974", "key3": "v,k",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "0,17", "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1965 - 1974" },
                { "Id": "LIBCONSTRD_BEPALING_43",   "key1": "≠ woonboot of woonwagen",                      "key2": "bouwjaar 1000 tm 1982", "key3": "v,k",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "0,52", "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1975 - 1982" },
                { "Id": "LIBCONSTRD_BEPALING_44",   "key1": "≠ woonboot of woonwagen",                      "key2": "bouwjaar 1000 tm 1991", "key3": "v,k",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "1,3",  "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1983 - 1991" },
                { "Id": "LIBCONSTRD_BEPALING_45",   "key1": "≠ woonboot of woonwagen",                      "key2": "bouwjaar 1000 tm 2013", "key3": "v,k",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "2,5",  "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1992 - 2013" },
                { "Id": "LIBCONSTRD_BEPALING_46",   "key1": "≠ woonboot of woonwagen",                      "key2": "bouwjaar 1000 tm 2020", "key3": "v,k",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "3,5",  "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 2014 - 2020" },
                { "Id": "LIBCONSTRD_BEPALING_47",   "key1": "≠ woonboot of woonwagen",                      "key2": "",                      "key3": "v,k",   "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "3,7",  "Rc_vb": "",     "Rc_d": "",     "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse vanaf 2021" },
                { "Id": "LIBCONSTRD_BEPALING_48",   "key1": "≠ woonboot of woonwagen",                      "key2": "bouwjaar 1000 tm 1974", "key3": "vb,d",  "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "",     "Rc_vb": "0,86", "Rc_d": "0,86", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1965 - 1974" },
                { "Id": "LIBCONSTRD_BEPALING_49",   "key1": "≠ woonboot of woonwagen",                      "key2": "bouwjaar 1000 tm 1987", "key3": "vb,d",  "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "",     "Rc_vb": "1,30", "Rc_d": "1,30", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1975 - 1987" },
                { "Id": "LIBCONSTRD_BEPALING_50",   "key1": "≠ woonboot of woonwagen",                      "key2": "bouwjaar 1000 tm 1991", "key3": "vb,d",  "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "",     "Rc_vb": "2,00", "Rc_d": "2,00", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1988 - 1991" },
                { "Id": "LIBCONSTRD_BEPALING_51",   "key1": "≠ woonboot of woonwagen",                      "key2": "bouwjaar 1000 tm 2013", "key3": "vb,d",  "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "",     "Rc_vb": "2,50", "Rc_d": "2,50", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 1992 - 2013" },
                { "Id": "LIBCONSTRD_BEPALING_52",   "key1": "≠ woonboot of woonwagen",                      "key2": "bouwjaar 1000 tm 2014", "key3": "vb,d",  "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "",     "Rc_vb": "3,50", "Rc_d": "3,50", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 2014" },
                { "Id": "LIBCONSTRD_BEPALING_53",   "key1": "≠ woonboot of woonwagen",                      "key2": "bouwjaar 1000 tm 2020", "key3": "vb,d",  "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "",     "Rc_vb": "6,00", "Rc_d": "6,00", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse 2015 - 2020" },
                { "Id": "LIBCONSTRD_BEPALING_54",   "key1": "≠ woonboot of woonwagen",                      "key2": "",                      "key3": "vb,d",  "key4": "", "isolatiedikte_disolatie": "", "rietpakket_driet": "", "Rc_gpk": "",        "Rc_v": "",     "Rc_vb": "6,30", "Rc_d": "6,30", "Rc_bf":"", "Value": "isolatie onbekend; bouwjaarklasse vanaf 2021" },

                { "Id": "LIBCONSTRD_BEPALING_55",   "key1": "", "key2": "bouwjaar 1000 tm 1964", "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "0,69", "Rc_v": "0,65", "Rc_vb": "0,72", "Rc_d": "0,72", "Rc_bf":"", "Value": "(na)geïsoleerd; geen spouw/onbekend; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRD_BEPALING_56",   "key1": "", "key2": "bouwjaar 1000 tm 1964", "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "0,85", "Rc_v": "0,83", "Rc_vb": "0,85", "Rc_d": "0,85", "Rc_bf":"", "Value": "(na)geïsoleerd; met spouw; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRD_BEPALING_57",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "0,58", "Rc_v": "0,37", "Rc_vb": "0,44", "Rc_d": "0,44", "Rc_bf":"", "Value": "10 mm isolatiedikte; geen spouw" },
                { "Id": "LIBCONSTRD_BEPALING_58",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "0,74", "Rc_v": "0,55", "Rc_vb": "0,57", "Rc_d": "0,57", "Rc_bf":"", "Value": "10 mm isolatiedikte; met spouw" },
                { "Id": "LIBCONSTRD_BEPALING_59",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "0,80", "Rc_v": "0,59", "Rc_vb": "0,66", "Rc_d": "0,66", "Rc_bf":"", "Value": "20 mm isolatiedikte; geen spouw" },
                { "Id": "LIBCONSTRD_BEPALING_60",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "0,96", "Rc_v": "0,77", "Rc_vb": "0,79", "Rc_d": "0,79", "Rc_bf":"", "Value": "20 mm isolatiedikte; met spouw" },
                { "Id": "LIBCONSTRD_BEPALING_61",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "1,03", "Rc_v": "0,82", "Rc_vb": "0,89", "Rc_d": "0,89", "Rc_bf":"", "Value": "30 mm isolatiedikte; geen spouw" },
                { "Id": "LIBCONSTRD_BEPALING_62",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "1,19", "Rc_v": "1,00", "Rc_vb": "1,02", "Rc_d": "1,02", "Rc_bf":"", "Value": "30 mm isolatiedikte; met spouw" },
                { "Id": "LIBCONSTRD_BEPALING_63",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "1,25", "Rc_v": "1,04", "Rc_vb": "1,11", "Rc_d": "1,11", "Rc_bf":"", "Value": "40 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_64",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "1,47", "Rc_v": "1,26", "Rc_vb": "1,33", "Rc_d": "1,33", "Rc_bf":"", "Value": "50 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_65",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "1,69", "Rc_v": "1,48", "Rc_vb": "1,55", "Rc_d": "1,55", "Rc_bf":"", "Value": "60 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_66",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "1,92", "Rc_v": "1,71", "Rc_vb": "1,78", "Rc_d": "1,78", "Rc_bf":"", "Value": "70 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_67",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "2,14", "Rc_v": "1,93", "Rc_vb": "2,00", "Rc_d": "2,00", "Rc_bf":"", "Value": "80 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_68",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "2,36", "Rc_v": "2,15", "Rc_vb": "2,22", "Rc_d": "2,22", "Rc_bf":"", "Value": "90 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_69",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "2,58", "Rc_v": "2,37", "Rc_vb": "2,44", "Rc_d": "2,44", "Rc_bf":"", "Value": "100 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_70",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "2,80", "Rc_v": "2,59", "Rc_vb": "2,66", "Rc_d": "2,66", "Rc_bf":"", "Value": "110 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_71",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "3,03", "Rc_v": "2,82", "Rc_vb": "2,89", "Rc_d": "2,89", "Rc_bf":"", "Value": "120 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_72",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "3,25", "Rc_v": "3,04", "Rc_vb": "3,11", "Rc_d": "3,11", "Rc_bf":"", "Value": "130 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_73",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "3,47", "Rc_v": "3,26", "Rc_vb": "3,33", "Rc_d": "3,33", "Rc_bf":"", "Value": "140 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_74",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "3,69", "Rc_v": "3,48", "Rc_vb": "3,55", "Rc_d": "3,55", "Rc_bf":"", "Value": "150 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_75",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "3,92", "Rc_v": "3,71", "Rc_vb": "3,78", "Rc_d": "3,78", "Rc_bf":"", "Value": "160 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_76",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "4,14", "Rc_v": "3,93", "Rc_vb": "4,00", "Rc_d": "4,00", "Rc_bf":"", "Value": "170 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_77",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "4,36", "Rc_v": "4,15", "Rc_vb": "4,22", "Rc_d": "4,22", "Rc_bf":"", "Value": "180 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_78",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "4,58", "Rc_v": "4,37", "Rc_vb": "4,44", "Rc_d": "4,44", "Rc_bf":"", "Value": "190 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_79",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "4,80", "Rc_v": "4,59", "Rc_vb": "4,66", "Rc_d": "4,66", "Rc_bf":"", "Value": "200 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_80",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "5,03", "Rc_v": "4,82", "Rc_vb": "4,89", "Rc_d": "4,89", "Rc_bf":"", "Value": "210 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_81",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "5,25", "Rc_v": "5,04", "Rc_vb": "5,11", "Rc_d": "5,11", "Rc_bf":"", "Value": "220 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_82",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "5,47", "Rc_v": "5,26", "Rc_vb": "5,33", "Rc_d": "5,33", "Rc_bf":"", "Value": "230 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_83",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "5,69", "Rc_v": "5,48", "Rc_vb": "5,55", "Rc_d": "5,55", "Rc_bf":"", "Value": "240 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_84",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "5,92", "Rc_v": "5,71", "Rc_vb": "5,78", "Rc_d": "5,78", "Rc_bf":"", "Value": "250 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_85",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "6,14", "Rc_v": "5,93", "Rc_vb": "6,00", "Rc_d": "6,00", "Rc_bf":"", "Value": "260 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_86",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "6,36", "Rc_v": "6,15", "Rc_vb": "6,22", "Rc_d": "6,22", "Rc_bf":"", "Value": "270 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_87",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "6,58", "Rc_v": "6,37", "Rc_vb": "6,44", "Rc_d": "6,44", "Rc_bf":"", "Value": "280 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_88",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "6,80", "Rc_v": "6,59", "Rc_vb": "6,66", "Rc_d": "6,66", "Rc_bf":"", "Value": "290 mm isolatiedikte" },
                { "Id": "LIBCONSTRD_BEPALING_89",   "key1": "", "key2": "",                      "key3": "g,k,v,vb,d",  "key4": "",  "isolatiedikte_disolatie": "", "rietpakket_driet": "",  "Rc_gpk": "7,03", "Rc_v": "6,82", "Rc_vb": "6,89", "Rc_d": "6,89", "Rc_bf":"", "Value": "300 mm isolatiedikte" },

                { "Id": "LIBCONSTRD_BEPALING_90",   "key1": "", "key2": "",  "key3": "d",  "key4": "",         "isolatiedikte_disolatie": "",       "rietpakket_driet": "",         "Rc_gpk": "",  "Rc_v": "",     "Rc_vb": "",    "Rc_d": "0,50",               "Rc_bf":"", "Value": "rieten dak; geen isolatie/onbekend; 100 mm rietpakket" },
                { "Id": "LIBCONSTRD_BEPALING_91",   "key1": "", "key2": "",  "key3": "d",  "key4": "",         "isolatiedikte_disolatie": "",       "rietpakket_driet": "",         "Rc_gpk": "",  "Rc_v": "",     "Rc_vb": "",    "Rc_d": "0,75",               "Rc_bf":"", "Value": "rieten dak; geen isolatie/onbekend; 150 mm rietpakket" },
                { "Id": "LIBCONSTRD_BEPALING_92",   "key1": "", "key2": "",  "key3": "d",  "key4": "",         "isolatiedikte_disolatie": "",       "rietpakket_driet": "",         "Rc_gpk": "",  "Rc_v": "",     "Rc_vb": "",    "Rc_d": "1,00",               "Rc_bf":"", "Value": "rieten dak; geen isolatie/onbekend; 200 mm rietpakket" },
                { "Id": "LIBCONSTRD_BEPALING_93",   "key1": "", "key2": "",  "key3": "d",  "key4": "",         "isolatiedikte_disolatie": "",       "rietpakket_driet": "",         "Rc_gpk": "",  "Rc_v": "",     "Rc_vb": "",    "Rc_d": "1,25",               "Rc_bf":"", "Value": "rieten dak; geen isolatie/onbekend; 250 mm rietpakket" },
                { "Id": "LIBCONSTRD_BEPALING_94",   "key1": "", "key2": "",  "key3": "d",  "key4": "",         "isolatiedikte_disolatie": "",       "rietpakket_driet": "",         "Rc_gpk": "",  "Rc_v": "",     "Rc_vb": "",    "Rc_d": "1,50",               "Rc_bf":"", "Value": "rieten dak; geen isolatie/onbekend; 300 mm rietpakket" },
                { "Id": "LIBCONSTRD_BEPALING_95",   "key1": "", "key2": "",  "key3": "d",  "key4": "",         "isolatiedikte_disolatie": "",       "rietpakket_driet": "",         "Rc_gpk": "",  "Rc_v": "",     "Rc_vb": "",    "Rc_d": "1,75",               "Rc_bf":"", "Value": "rieten dak; geen isolatie/onbekend; 350 mm rietpakket" },
                { "Id": "LIBCONSTRD_BEPALING_96",   "key1": "", "key2": "",  "key3": "d",  "key4": "",         "isolatiedikte_disolatie": "",       "rietpakket_driet": "",         "Rc_gpk": "",  "Rc_v": "",     "Rc_vb": "",    "Rc_d": "2,00",               "Rc_bf":"", "Value": "rieten dak; geen isolatie/onbekend; 400 mm rietpakket" },
                { "Id": "LIBCONSTRD_BEPALING_97",   "key1": "", "key2": "",  "key3": "d",  "key4": "riet_ntb", "isolatiedikte_disolatie": "",       "rietpakket_driet": "invoer",   "Rc_gpk": "",  "Rc_v": "",     "Rc_vb": "",    "Rc_d": "berekenen: Rc = 0,04/0,045 + driet / 1000 / 0,2",              "Rc_bf":"",     "Value": "rietendak met isolatie en isolatiedikte niet te bepalen" },
                { "Id": "LIBCONSTRD_BEPALING_98",   "key1": "", "key2": "",  "key3": "d",  "key4": "riet_tb",  "isolatiedikte_disolatie": "invoer", "rietpakket_driet": "invoer",   "Rc_gpk": "",  "Rc_v": "",     "Rc_vb": "",    "Rc_d": "berekenen: Rc = disolatie / 1000 /0,045 + driet / 1000 /0,2",  "Rc_bf":"",     "Value": "rietendak met isolatie en isolatiedikte te bepalen" },
                { "Id": "LIBCONSTRD_BEPALING_101",  "key1": "", "key2": "",  "key3": "b",  "key4": "",         "isolatiedikte_disolatie": "",       "rietpakket_driet": "",         "Rc_gpk": "",  "Rc_v": "",     "Rc_vb": "",    "Rc_d": "",                                                             "Rc_bf":"0.50", "Value": "geïsoleerde bodem" },
                { "Id": "LIBCONSTRD_BEPALING_102",  "key1": "", "key2": "",  "key3": "v",  "key4": "",         "isolatiedikte_disolatie": "",       "rietpakket_driet": "",         "Rc_gpk": "",  "Rc_v": "1,95", "Rc_vb": "",    "Rc_d": "",                                                             "Rc_bf":"",     "Value": "thermokussens" },
                { "Id": "LIBCONSTRD_BEPALING_NVT",  "key1": "", "key2": "",  "key3": "",     "key4": "",         "isolatiedikte_disolatie": "",       "rietpakket_driet": "",         "Rc_gpk": "",  "Rc_v": "",     "Rc_vb": "",    "Rc_d": "",                                                             "Rc_bf":"",     "Value": 'n.v.t.' }
            ];

            const bepalingCodedValues = ntabuilding.properties["LIBCONSTRD_BEPALING"].Domain.Codes;

            dichteConstructies.forEach(function (row, index) {
                const codedValue = bepalingCodedValues.find(function (x) { return x.Id === row.Id; });
                if (codedValue) {
                    row.Value = codedValue.Value;
                    row.Order = codedValue.Order;
                } else {
                    row.Delete = true;
                }
            });

            return dichteConstructies.filter(row => !row.Delete);

        } //-- end: createTable13_0 ------------------------------------------------------------------------//

        function createTableTransparanteConstructies() {

            const transparanteConstructies = [
                { "Id": "LIBCONSTRT_BEPALING_1", "key1": "raam", "key2": "hout / kunststof; grenzend aan buiten", "U": "1,4", "ggl": "0,5", "Value": "drievoudig HR glas" },
                { "Id": "LIBCONSTRT_BEPALING_2", "key1": "raam", "key2": "hout / kunststof; grenzend aan buiten", "U": "1,8", "ggl": "0,6", "Value": "HR++ glas" },
                { "Id": "LIBCONSTRT_BEPALING_3", "key1": "raam", "key2": "hout / kunststof; grenzend aan buiten", "U": "2", "ggl": "0,6", "Value": "HR+ glas" },
                { "Id": "LIBCONSTRT_BEPALING_4", "key1": "raam", "key2": "hout / kunststof; grenzend aan buiten", "U": "2,3", "ggl": "0,6", "Value": "HR (dubbelglas met coating)" },
                { "Id": "LIBCONSTRT_BEPALING_5", "key1": "raam", "key2": "hout / kunststof; grenzend aan buiten", "U": "2,9", "ggl": "0,75", "Value": "dubbelglas" },
                { "Id": "LIBCONSTRT_BEPALING_6", "key1": "raam", "key2": "hout / kunststof; grenzend aan buiten", "U": "2,9", "ggl": "0,75", "Value": "voorzetraam" },
                { "Id": "LIBCONSTRT_BEPALING_7", "key1": "raam", "key2": "hout / kunststof; grenzend aan buiten", "U": "5,1", "ggl": "0,85", "Value": "enkelglas" },
                { "Id": "LIBCONSTRT_BEPALING_8", "key1": "raam", "key2": "hout / kunststof; niet grenzend aan buiten", "U": "1,3", "ggl": "0", "Value": "drievoudig HR glas" },
                { "Id": "LIBCONSTRT_BEPALING_9", "key1": "raam", "key2": "hout / kunststof; niet grenzend aan buiten", "U": "1,5", "ggl": "0", "Value": "HR++ glas" },
                { "Id": "LIBCONSTRT_BEPALING_10", "key1": "raam", "key2": "hout / kunststof; niet grenzend aan buiten", "U": "1,7", "ggl": "0", "Value": "HR+ glas" },
                { "Id": "LIBCONSTRT_BEPALING_11", "key1": "raam", "key2": "hout / kunststof; niet grenzend aan buiten", "U": "1,9", "ggl": "0", "Value": "HR (dubbelglas met coating)" },
                { "Id": "LIBCONSTRT_BEPALING_12", "key1": "raam", "key2": "hout / kunststof; niet grenzend aan buiten", "U": "2,3", "ggl": "0", "Value": "dubbelglas" },
                { "Id": "LIBCONSTRT_BEPALING_13", "key1": "raam", "key2": "hout / kunststof; niet grenzend aan buiten", "U": "2,3", "ggl": "0", "Value": "voorzetraam" },
                { "Id": "LIBCONSTRT_BEPALING_14", "key1": "raam", "key2": "hout / kunststof; niet grenzend aan buiten", "U": "3,5", "ggl": "0", "Value": "enkelglas" },
                { "Id": "LIBCONSTRT_BEPALING_15", "key1": "raam", "key2": "metaal ther. onderbroken; grenzend aan buiten", "U": "1,9", "ggl": "0,5", "Value": "drievoudig HR glas" },
                { "Id": "LIBCONSTRT_BEPALING_16", "key1": "raam", "key2": "metaal ther. onderbroken; grenzend aan buiten", "U": "2,3", "ggl": "0,6", "Value": "HR++ glas" },
                { "Id": "LIBCONSTRT_BEPALING_17", "key1": "raam", "key2": "metaal ther. onderbroken; grenzend aan buiten", "U": "2,5", "ggl": "0,6", "Value": "HR+ glas" },
                { "Id": "LIBCONSTRT_BEPALING_18", "key1": "raam", "key2": "metaal ther. onderbroken; grenzend aan buiten", "U": "2,8", "ggl": "0,6", "Value": "HR (dubbelglas met coating)" },
                { "Id": "LIBCONSTRT_BEPALING_19", "key1": "raam", "key2": "metaal ther. onderbroken; grenzend aan buiten", "U": "3,3", "ggl": "0,75", "Value": "dubbelglas" },
                { "Id": "LIBCONSTRT_BEPALING_20", "key1": "raam", "key2": "metaal ther. onderbroken; grenzend aan buiten", "U": "3,3", "ggl": "0,75", "Value": "voorzetraam" },
                { "Id": "LIBCONSTRT_BEPALING_21", "key1": "raam", "key2": "metaal ther. onderbroken; grenzend aan buiten", "U": "5,4", "ggl": "0,85", "Value": "enkelglas" },
                { "Id": "LIBCONSTRT_BEPALING_22", "key1": "raam", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "U": "1,6", "ggl": "0", "Value": "drievoudig HR glas" },
                { "Id": "LIBCONSTRT_BEPALING_23", "key1": "raam", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "U": "1,9", "ggl": "0", "Value": "HR++ glas" },
                { "Id": "LIBCONSTRT_BEPALING_24", "key1": "raam", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "U": "2,1", "ggl": "0", "Value": "HR+ glas" },
                { "Id": "LIBCONSTRT_BEPALING_25", "key1": "raam", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "U": "2,2", "ggl": "0", "Value": "HR (dubbelglas met coating)" },
                { "Id": "LIBCONSTRT_BEPALING_26", "key1": "raam", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "U": "2,5", "ggl": "0", "Value": "dubbelglas" },
                { "Id": "LIBCONSTRT_BEPALING_27", "key1": "raam", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "U": "2,5", "ggl": "0", "Value": "voorzetraam" },
                { "Id": "LIBCONSTRT_BEPALING_28", "key1": "raam", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "U": "3,6", "ggl": "0", "Value": "enkelglas" },
                { "Id": "LIBCONSTRT_BEPALING_29", "key1": "raam", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "U": "2,7", "ggl": "0,5", "Value": "drievoudig HR glas" },
                { "Id": "LIBCONSTRT_BEPALING_30", "key1": "raam", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "U": "3,1", "ggl": "0,6", "Value": "HR++ glas" },
                { "Id": "LIBCONSTRT_BEPALING_31", "key1": "raam", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "U": "3,3", "ggl": "0,6", "Value": "HR+ glas" },
                { "Id": "LIBCONSTRT_BEPALING_32", "key1": "raam", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "U": "3,6", "ggl": "0,6", "Value": "HR (dubbelglas met coating)" },
                { "Id": "LIBCONSTRT_BEPALING_33", "key1": "raam", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "U": "4,1", "ggl": "0,75", "Value": "dubbelglas" },
                { "Id": "LIBCONSTRT_BEPALING_34", "key1": "raam", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "U": "4,1", "ggl": "0,75", "Value": "voorzetraam" },
                { "Id": "LIBCONSTRT_BEPALING_35", "key1": "raam", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "U": "6,2", "ggl": "0,85", "Value": "enkelglas" },
                { "Id": "LIBCONSTRT_BEPALING_36", "key1": "raam", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "U": "2,2", "ggl": "0", "Value": "drievoudig HR glas" },
                { "Id": "LIBCONSTRT_BEPALING_37", "key1": "raam", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "U": "2,4", "ggl": "0", "Value": "HR++ glas" },
                { "Id": "LIBCONSTRT_BEPALING_38", "key1": "raam", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "U": "2,6", "ggl": "0", "Value": "HR+ glas" },
                { "Id": "LIBCONSTRT_BEPALING_39", "key1": "raam", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "U": "2,7", "ggl": "0", "Value": "HR (dubbelglas met coating)" },
                { "Id": "LIBCONSTRT_BEPALING_40", "key1": "raam", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "U": "3", "ggl": "0", "Value": "dubbelglas" },
                { "Id": "LIBCONSTRT_BEPALING_41", "key1": "raam", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "U": "3", "ggl": "0", "Value": "voorzetraam" },
                { "Id": "LIBCONSTRT_BEPALING_42", "key1": "raam", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "U": "4", "ggl": "0", "Value": "enkelglas" },
                { "Id": "LIBCONSTRT_BEPALING_43", "key1": "deur", "key2": "", "U": "2", "ggl": "0", "Value": "geïsoleerde deur; grenzend aan buiten" },
                { "Id": "LIBCONSTRT_BEPALING_44", "key1": "deur", "key2": "", "U": "1,7", "ggl": "0", "Value": "geïsoleerde deur; niet grenzend aan buiten" },
                { "Id": "LIBCONSTRT_BEPALING_45", "key1": "deur", "key2": "", "U": "3,4", "ggl": "0", "Value": "niet geïsoleerde deur; grenzend aan buiten" },
                { "Id": "LIBCONSTRT_BEPALING_46", "key1": "deur", "key2": "", "U": "2,7", "ggl": "0", "Value": "niet geïsoleerde deur; niet grenzend aan buiten" },

                { "Id": "LIBCONSTRT_BEPALING_47", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": 1964, "U": "3.7", "Value": "isolatie onbekend of afwezig; geen spouw; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRT_BEPALING_48", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": 1964, "U": "2.5", "Value": "isolatie onbekend of afwezig; met spouw; bouwjaarklasse < 1965 " },
                { "Id": "LIBCONSTRT_BEPALING_49", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": 1964, "U": "4.1", "Value": "isolatie onbekend of afwezig; geen spouw; bouwjaarklasse < 1965 " },
                { "Id": "LIBCONSTRT_BEPALING_50", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": 1964, "U": "2.8", "Value": "isolatie onbekend of afwezig; met spouw; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRT_BEPALING_51", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": 1964, "U": "4.9", "Value": "isolatie onbekend of afwezig; geen spouw; bouwjaarklasse < 1965 " },
                { "Id": "LIBCONSTRT_BEPALING_52", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": 1964, "U": "3.6", "Value": "isolatie onbekend of afwezig; met spouw; bouwjaarklasse < 1965 " },
                { "Id": "LIBCONSTRT_BEPALING_53", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": 1964, "U": "1.5", "Value": "(na)geïsoleerd; geen spouw; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRT_BEPALING_54", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": 1964, "U": "1.4", "Value": "(na)geïsoleerd; met spouw; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRT_BEPALING_55", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": 1964, "U": "1.9", "Value": "(na)geïsoleerd; geen spouw; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRT_BEPALING_56", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": 1964, "U": "1.7", "Value": "(na)geïsoleerd; met spouw; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRT_BEPALING_57", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": 1964, "U": "2.7", "Value": "(na)geïsoleerd; geen spouw; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRT_BEPALING_58", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": 1964, "U": "2.5", "Value": "(na)geïsoleerd; met spouw; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRT_BEPALING_59", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": 1964, "U": "2.8", "Value": "isolatie onbekend of afwezig; geen spouw; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRT_BEPALING_60", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": 1964, "U": "2", "Value": "isolatie onbekend of afwezig; met spouw; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRT_BEPALING_61", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": 1964, "U": "3", "Value": "isolatie onbekend of afwezig; geen spouw; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRT_BEPALING_62", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": 1964, "U": "2.3", "Value": "isolatie onbekend of afwezig; met spouw; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRT_BEPALING_63", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": 1964, "U": "3.4", "Value": "isolatie onbekend of afwezig; geen spouw; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRT_BEPALING_64", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": 1964, "U": "2.7", "Value": "isolatie onbekend of afwezig; met spouw; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRT_BEPALING_65", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": 1964, "U": "1.3", "Value": "(na)geïsoleerd; geen spouw; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRT_BEPALING_66", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": 1964, "U": "1.2", "Value": "(na)geïsoleerd; met spouw; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRT_BEPALING_67", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": 1964, "U": "1.6", "Value": "(na)geïsoleerd; geen spouw; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRT_BEPALING_68", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": 1964, "U": "1.5", "Value": "(na)geïsoleerd; met spouw; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRT_BEPALING_69", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": 1964, "U": "2.2", "Value": "(na)geïsoleerd; geen spouw; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRT_BEPALING_70", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": 1964, "U": "2.1", "Value": "(na)geïsoleerd; met spouw; bouwjaarklasse < 1965" },
                { "Id": "LIBCONSTRT_BEPALING_71", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": 1991, "U": "3.7", "Value": "isolatie onbekend; bouwjaarklasse 1965 - 1991" },
                { "Id": "LIBCONSTRT_BEPALING_72", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": 1991, "U": "4.1", "Value": "isolatie onbekend; bouwjaarklasse 1965 - 1991" },
                { "Id": "LIBCONSTRT_BEPALING_73", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": 1991, "U": "4.9", "Value": "isolatie onbekend; bouwjaarklasse 1965 - 1991" },
                { "Id": "LIBCONSTRT_BEPALING_74", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": 2012, "U": "3.7", "Value": "isolatie onbekend; bouwjaarklasse 1992 - 2012" },
                { "Id": "LIBCONSTRT_BEPALING_75", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": 2012, "U": "4.1", "Value": "isolatie onbekend; bouwjaarklasse 1992 - 2012" },
                { "Id": "LIBCONSTRT_BEPALING_76", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": 2012, "U": "4.2", "Value": "isolatie onbekend; bouwjaarklasse 1992 - 2012" },
                { "Id": "LIBCONSTRT_BEPALING_77", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "1.65", "Value": "isolatie onbekend; bouwjaarklasse vanaf 2013" },
                { "Id": "LIBCONSTRT_BEPALING_78", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.65", "Value": "isolatie onbekend; bouwjaarklasse vanaf 2013" },
                { "Id": "LIBCONSTRT_BEPALING_79", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.65", "Value": "isolatie onbekend; bouwjaarklasse vanaf 2013" },
                { "Id": "LIBCONSTRT_BEPALING_80", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": 1991, "U": "2.8", "Value": "isolatie onbekend; bouwjaarklasse 1965 - 1991" },
                { "Id": "LIBCONSTRT_BEPALING_81", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": 1991, "U": "3", "Value": "isolatie onbekend; bouwjaarklasse 1965 - 1991" },
                { "Id": "LIBCONSTRT_BEPALING_82", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": 1991, "U": "3.4", "Value": "isolatie onbekend; bouwjaarklasse 1965 - 1991" },
                { "Id": "LIBCONSTRT_BEPALING_83", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": 2012, "U": "2.8", "Value": "isolatie onbekend; bouwjaarklasse 1992 - 2012" },
                { "Id": "LIBCONSTRT_BEPALING_84", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": 2012, "U": "3", "Value": "isolatie onbekend; bouwjaarklasse 1992 - 2012" },
                { "Id": "LIBCONSTRT_BEPALING_85", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": 2012, "U": "3", "Value": "isolatie onbekend; bouwjaarklasse 1992 - 2012" },
                { "Id": "LIBCONSTRT_BEPALING_86", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "1.4", "Value": "isolatie onbekend; bouwjaarklasse vanaf 2013" },
                { "Id": "LIBCONSTRT_BEPALING_87", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.4", "Value": "isolatie onbekend; bouwjaarklasse vanaf 2013" },
                { "Id": "LIBCONSTRT_BEPALING_88", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.4", "Value": "isolatie onbekend; bouwjaarklasse vanaf 2013" },
                { "Id": "LIBCONSTRT_BEPALING_89", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "2", "Value": "10 mm isolatiedikte; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_90", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "1.7", "Value": "10 mm isolatiedikte; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_91", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "1.5", "Value": "20 mm isolatiedikte; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_92", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "1.4", "Value": "20 mm isolatiedikte; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_93", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "1.3", "Value": "30 mm isolatiedikte; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_94", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "1.2", "Value": "30 mm isolatiedikte; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_95", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "1.1", "Value": "40 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_96", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "1", "Value": "50 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_97", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.98", "Value": "60 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_98", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.93", "Value": "70 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_99", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.90", "Value": "80 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_100", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.87", "Value": "90 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_101", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.84", "Value": "100 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_102", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.82", "Value": "110 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_103", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.80", "Value": "120 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_104", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.79", "Value": "130 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_105", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.78", "Value": "140 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_106", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.77", "Value": "150 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_107", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.76", "Value": "160 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_108", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.75", "Value": "170 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_109", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.74", "Value": "180 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_110", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.73", "Value": "190 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_111", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.73", "Value": "200 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_112", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.72", "Value": "210 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_113", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.71", "Value": "220 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_114", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.71", "Value": "230 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_115", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.71", "Value": "240 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_116", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.70", "Value": "250 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_117", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.70", "Value": "260 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_118", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.69", "Value": "270 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_119", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.69", "Value": "280 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_120", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.69", "Value": "290 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_121", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "0.69", "Value": "300 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_122", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "2.4", "Value": "10 mm isolatiedikte; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_123", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "2", "Value": "10 mm isolatiedikte; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_124", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.9", "Value": "20 mm isolatiedikte; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_125", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.7", "Value": "20 mm isolatiedikte; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_126", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.6", "Value": "30 mm isolatiedikte; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_127", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.5", "Value": "30 mm isolatiedikte; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_128", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.5", "Value": "40 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_129", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.4", "Value": "50 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_130", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.3", "Value": "60 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_131", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.3", "Value": "70 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_132", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.2", "Value": "80 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_133", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.2", "Value": "90 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_134", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.2", "Value": "100 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_135", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.2", "Value": "110 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_136", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.2", "Value": "120 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_137", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.1", "Value": "130 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_138", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.1", "Value": "140 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_139", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.1", "Value": "150 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_140", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.1", "Value": "160 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_141", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.1", "Value": "170 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_142", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.1", "Value": "180 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_143", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.1", "Value": "190 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_144", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.1", "Value": "200 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_145", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.1", "Value": "210 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_146", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.1", "Value": "220 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_147", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.1", "Value": "230 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_148", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.1", "Value": "240 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_149", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.1", "Value": "250 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_150", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1", "Value": "260 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_151", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1", "Value": "270 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_152", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1", "Value": "280 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_153", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1", "Value": "290 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_154", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1", "Value": "300 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_155", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "3.2", "Value": "10 mm isolatiedikte; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_156", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "2.8", "Value": "10 mm isolatiedikte; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_157", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "2.7", "Value": "20 mm isolatiedikte; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_158", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "2.5", "Value": "20 mm isolatiedikte; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_159", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "2.4", "Value": "30 mm isolatiedikte; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_160", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "2.3", "Value": "30 mm isolatiedikte; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_161", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "2.3", "Value": "40 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_162", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "2.2", "Value": "50 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_163", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "2.1", "Value": "60 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_164", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "2.1", "Value": "70 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_165", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "2", "Value": "80 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_166", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "2", "Value": "90 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_167", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "2", "Value": "100 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_168", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "2", "Value": "110 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_169", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "2", "Value": "120 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_170", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.9", "Value": "130 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_171", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.9", "Value": "140 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_172", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.9", "Value": "150 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_173", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.9", "Value": "160 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_174", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.9", "Value": "170 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_175", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.9", "Value": "180 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_176", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.9", "Value": "190 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_177", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.9", "Value": "200 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_178", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.9", "Value": "210 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_179", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.9", "Value": "220 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_180", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.9", "Value": "230 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_181", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.9", "Value": "240 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_182", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.9", "Value": "250 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_183", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.8", "Value": "260 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_184", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.8", "Value": "270 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_185", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.8", "Value": "280 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_186", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.8", "Value": "290 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_187", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1.8", "Value": "300 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_188", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "1.7140020499998", "Value": "10 mm isolatiedikte; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_189", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "1.4697109387711", "Value": "10 mm isolatiedikte; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_190", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "1.3404095231313", "Value": "20 mm isolatiedikte; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_191", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "1.2212521434088", "Value": "20 mm isolatiedikte; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_192", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "1.1506649765035", "Value": "30 mm isolatiedikte; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_193", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "1.0802548293444", "Value": "30 mm isolatiedikte; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_194", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "1.0358568722705", "Value": "40 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_195", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.96", "Value": "50 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_196", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.90", "Value": "60 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_197", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.86", "Value": "70 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_198", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.83", "Value": "80 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_199", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.80", "Value": "90 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_200", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.78", "Value": "100 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_201", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.77", "Value": "110 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_202", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.75", "Value": "120 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_203", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.74", "Value": "130 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_204", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.73", "Value": "140 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_205", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.72", "Value": "150 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_206", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.71", "Value": "160 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_207", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.70", "Value": "170 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_208", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.69", "Value": "180 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_209", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.69", "Value": "190 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_210", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.68", "Value": "200 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_211", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.68", "Value": "210 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_212", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.67", "Value": "220 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_213", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.67", "Value": "230 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_214", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.66", "Value": "240 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_215", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.66", "Value": "250 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_216", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.66", "Value": "260 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_217", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.65", "Value": "270 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_218", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.65", "Value": "280 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_219", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.65", "Value": "290 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_220", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "0.65", "Value": "300 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_221", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.9578522275998", "Value": "10 mm isolatiedikte; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_222", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.7262313255556", "Value": "10 mm isolatiedikte; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_223", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.6037624997364", "Value": "20 mm isolatiedikte; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_224", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.4909790040617", "Value": "20 mm isolatiedikte; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_225", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.4242025895831", "Value": "30 mm isolatiedikte; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_226", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.3576195273768", "Value": "30 mm isolatiedikte; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_227", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.315648070061", "Value": "40 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_228", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.2429340726355", "Value": "50 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_229", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.1908258245911", "Value": "60 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_230", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.1516514473674", "Value": "70 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_231", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.1211270871749", "Value": "80 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_232", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.0966726112129", "Value": "90 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_233", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.076641225953", "Value": "100 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_234", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.0599324448737", "Value": "110 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_235", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.0457828979256", "Value": "120 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_236", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.033646426472", "Value": "130 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_237", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.0231219566511", "Value": "140 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_238", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.0139083231357", "Value": "150 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_239", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.0057749835683", "Value": "160 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_240", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "0.99854246369041", "Value": "170 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_241", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "0.99", "Value": "180 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_242", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "0.99", "Value": "190 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_243", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "0.98", "Value": "200 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_244", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "0.98", "Value": "210 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_245", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "0.97", "Value": "220 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_246", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "0.97", "Value": "230 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_247", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "0.96", "Value": "240 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_248", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "0.96", "Value": "250 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_249", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "0.96", "Value": "260 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_250", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "0.95", "Value": "270 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_251", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "0.95", "Value": "280 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_252", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "0.95", "Value": "290 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_253", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "0.95", "Value": "300 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_254", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "2.4703619957313", "Value": "10 mm isolatiedikte; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_255", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "2.2642448370241", "Value": "10 mm isolatiedikte; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_256", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "2.1554965872487", "Value": "20 mm isolatiedikte; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_257", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "2.0554922993947", "Value": "20 mm isolatiedikte; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_258", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.9963469734354", "Value": "30 mm isolatiedikte; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_259", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.937420837829", "Value": "30 mm isolatiedikte; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_260", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.900300588331", "Value": "40 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_261", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.8360360489133", "Value": "50 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_262", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.7900178449878", "Value": "60 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_263", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.7554411156636", "Value": "70 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_264", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.728510634233", "Value": "80 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_265", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.706942598733", "Value": "90 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_266", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.6892803642181", "Value": "100 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_267", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.6745510560539", "Value": "110 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_268", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.6620801335228", "Value": "120 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_269", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.6513851765958", "Value": "130 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_270", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.6421120334452", "Value": "140 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_271", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.633994846818", "Value": "150 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_272", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.6268301522413", "Value": "160 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_273", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.6204595890559", "Value": "170 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_274", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.6147580584797", "Value": "180 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_275", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.6096254264354", "Value": "190 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_276", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.6049805920303", "Value": "200 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_277", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.6007571704868", "Value": "210 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_278", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.59690030011", "Value": "220 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_279", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.5933642460489", "Value": "230 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_280", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.5901105781421", "Value": "240 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_281", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.5871067685485", "Value": "250 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_282", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.5843251005078", "Value": "260 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_283", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.5817418105699", "Value": "270 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_284", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.5793364080247", "Value": "280 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_285", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.5770911302462", "Value": "290 mm isolatiedikte" },
                { "Id": "LIBCONSTRT_BEPALING_286", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1.574990503304", "Value": "300 mm isolatiedikte" },

                { "Id": "LIBCONSTRT_BEPALING_287", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "3,7", "Value": "isolatie onbekend of afwezig; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_288", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "2,5", "Value": "isolatie onbekend of afwezig; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_289", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "4,1", "Value": "isolatie onbekend of afwezig; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_290", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "2,8", "Value": "isolatie onbekend of afwezig; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_291", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "4,9", "Value": "isolatie onbekend of afwezig; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_292", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "3,6", "Value": "isolatie onbekend of afwezig; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_293", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "1,5", "Value": "(na)geïsoleerd; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_294", "key1": "paneel", "key2": "hout / kunststof; grenzend aan buiten", "key3": "", "U": "1,4", "Value": "(na)geïsoleerd; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_295", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1,9", "Value": "(na)geïsoleerd; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_296", "key1": "paneel", "key2": "metaal ther. onderbroken; grenzend aan buiten", "key3": "", "U": "1,7", "Value": "(na)geïsoleerd; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_297", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "2,7", "Value": "(na)geïsoleerd; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_298", "key1": "paneel", "key2": "metaal niet ther. onderbroken; grenzend aan buiten", "key3": "", "U": "2,5", "Value": "(na)geïsoleerd; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_299", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "2,8", "Value": "isolatie onbekend of afwezig; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_300", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "2,0", "Value": "isolatie onbekend of afwezig; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_301", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "3,0", "Value": "isolatie onbekend of afwezig; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_302", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "2,3", "Value": "isolatie onbekend of afwezig; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_303", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "3,4", "Value": "isolatie onbekend of afwezig; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_304", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "2,7", "Value": "isolatie onbekend of afwezig; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_305", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "1,3", "Value": "(na)geïsoleerd; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_306", "key1": "paneel", "key2": "hout / kunststof; niet grenzend aan buiten", "key3": "", "U": "1,2", "Value": "(na)geïsoleerd; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_307", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1,6", "Value": "(na)geïsoleerd; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_308", "key1": "paneel", "key2": "metaal ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "1,5", "Value": "(na)geïsoleerd; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_309", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "2,2", "Value": "(na)geïsoleerd; geen spouw" },
                { "Id": "LIBCONSTRT_BEPALING_310", "key1": "paneel", "key2": "metaal niet ther. onderbroken; niet grenzend aan buiten", "key3": "", "U": "2,1", "Value": "(na)geïsoleerd; met spouw" },
                { "Id": "LIBCONSTRT_BEPALING_NVT", "key1": "", "key2": "", "U": "", "ggl": "", "Value": "n.v.t." },


            ];

            const bepalingCodedValues = ntabuilding.properties["LIBCONSTRT_BEPALING"].Domain.Codes;

            transparanteConstructies.forEach(function (row, index) {
                const codedValue = bepalingCodedValues.find(function (x) { return x.Id === row.Id; });
                if (codedValue) {
                    row.Value = codedValue.Value;
                    row.Order = codedValue.Order;
                    row.version = codedValue.FilterValue1;
                } else {
                    row.Delete = true;
                }
            });

            return transparanteConstructies.filter(row => !row.Delete);

        } //-- end: createTableTransparanteConstructies ------------------------------------------------------------------------//

        function createTableLineaireConstructies() {

            const lineaireConstructies = [
                { "Id": "LIBCONSTRL_BEPALING_55", "key1": "f", "psi": "0,41", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_1", "key1": "f", "psi": "0,27", "Value": "01. fundering - langsgevel" },
                { "Id": "LIBCONSTRL_BEPALING_56", "key1": "f", "psi": "0,68", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_2", "key1": "f", "psi": "0,45", "Value": "02. fundering - deur" },
                { "Id": "LIBCONSTRL_BEPALING_57", "key1": "f", "psi": "0,9", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_3", "key1": "f", "psi": "0,6", "Value": "03. fundering - kopgevel (grondgebonden gebouw)" },
                { "Id": "LIBCONSTRL_BEPALING_4", "key1": "f", "psi": "0", "Value": "04. fundering - woningscheidende wand" },
                { "Id": "LIBCONSTRL_BEPALING_59", "key1": "vo", "psi": "0,25", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_5", "key1": "vo", "psi": "0,15", "Value": "05. langsgevel - onderdorpel raam" },
                { "Id": "LIBCONSTRL_BEPALING_60", "key1": "vo", "psi": "0,19", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_6", "key1": "vo", "psi": "0,09", "Value": "06. langsgevel - zijstijl raam" },
                { "Id": "LIBCONSTRL_BEPALING_61", "key1": "vo", "psi": "0,2", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_7", "key1": "vo", "psi": "0,1", "Value": "07. langsgevel - bovendorpel raam" },
                { "Id": "LIBCONSTRL_BEPALING_62", "key1": "vo", "psi": "0,2", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_8", "key1": "vo", "psi": "0,1", "Value": "08. langsgevel - woningscheidende wand" },
                { "Id": "LIBCONSTRL_BEPALING_63", "key1": "vo", "psi": "0,24", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_9", "key1": "vo", "psi": "0,14", "Value": "09. langsgevel - kopgevel (uitwendige hoek)" },
                { "Id": "LIBCONSTRL_BEPALING_64", "key1": "vo,vl", "psi": "0,19", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_10", "key1": "vo,vl", "psi": "0,09", "Value": "10. langsgevel - verdiepingsvloer" },
                { "Id": "LIBCONSTRL_BEPALING_65", "key1": "vo", "psi": "0,25", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_11", "key1": "vo", "psi": "0,15", "Value": "11. langsgevel - bovendorpel raam met rooster" },
                { "Id": "LIBCONSTRL_BEPALING_12", "key1": "vo", "psi": "0", "Value": "12. langsgevel - kopgevel (inwendige hoek)" },
                { "Id": "LIBCONSTRL_BEPALING_67", "key1": "d,vo", "psi": "0,26", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_13", "key1": "d, vo", "psi": "0,16", "Value": "13. hellend dak - langsgevel" },
                { "Id": "LIBCONSTRL_BEPALING_68", "key1": "d", "psi": "0,13", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_14", "key1": "d", "psi": "0,03", "Value": "14. hellend dak - woningscheidende wand" },
                { "Id": "LIBCONSTRL_BEPALING_69", "key1": "d,vo", "psi": "0,23", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_15", "key1": "d, vo", "psi": "0,13", "Value": "15. hellend dak - kopgevel" },
                { "Id": "LIBCONSTRL_BEPALING_70", "key1": "d", "psi": "0,15", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_16", "key1": "d", "psi": "0,05", "Value": "16. hellend dak - nok" },
                { "Id": "LIBCONSTRL_BEPALING_71", "key1": "d", "psi": "0,9", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_17", "key1": "d", "psi": "0,6", "Value": "17. hellend dak - kozijn dakkapel" },
                { "Id": "LIBCONSTRL_BEPALING_72", "key1": "d", "psi": "0,75", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_18", "key1": "d", "psi": "0,5", "Value": "18. hellend dak - plat dak dakkapel" },
                { "Id": "LIBCONSTRL_BEPALING_73", "key1": "d", "psi": "0,23", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_19", "key1": "d", "psi": "0,13", "Value": "19. hellend dak - zijwang dakkapel" },
                { "Id": "LIBCONSTRL_BEPALING_74", "key1": "d", "psi": "0,22", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_20", "key1": "d", "psi": "0,12", "Value": "20. hellend dak - onderzijde dakraam" },
                { "Id": "LIBCONSTRL_BEPALING_75", "key1": "d", "psi": "0,24", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_21", "key1": "d", "psi": "0,14", "Value": "21. hellend dak - zijaansluiting dakraam" },
                { "Id": "LIBCONSTRL_BEPALING_76", "key1": "d", "psi": "0,22", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_22", "key1": "d", "psi": "0,12", "Value": "22. hellend dak - bovenzijde dakraam" },
                { "Id": "LIBCONSTRL_BEPALING_77", "key1": "d,vl", "psi": "0,36", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_23", "key1": "d, vl", "psi": "0,24", "Value": "23. hellend dak - zakgoot" },
                { "Id": "LIBCONSTRL_BEPALING_78", "key1": "d,vo", "psi": "0,23", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_24", "key1": "d, vo", "psi": "0,13", "Value": "24. hellend dak - opgaand werk kopgevel (houten hulpconstructies)" },
                { "Id": "LIBCONSTRL_BEPALING_79", "key1": "d,vo", "psi": "0,62", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_25", "key1": "d, vo", "psi": "0,41", "Value": "24. hellend dak - opgaand werk kopgevel (RVS metselwerk drager)" },
                { "Id": "LIBCONSTRL_BEPALING_80", "key1": "f", "psi": "0,92", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_26", "key1": "f", "psi": "0,61", "Value": "50. fundering - kopgevel (niet-grondgebonden gebouw)" },
                { "Id": "LIBCONSTRL_BEPALING_81", "key1": "vo,vl", "psi": "0,96", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_27", "key1": "vo,vl", "psi": "0,64", "Value": "51. doorlopende vloer boven AOR - opgaande langsgevel" },
                { "Id": "LIBCONSTRL_BEPALING_82", "key1": "vo,vl", "psi": "0,96", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_28", "key1": "vo,vl", "psi": "0,64", "Value": "52. doorlopende vloer boven AOR - kozijn in opgaande langsgevel" },
                { "Id": "LIBCONSTRL_BEPALING_29", "key1": "vo", "psi": "0", "Value": "53. loggia - gevel - gevel (inwendige hoek)" },
                { "Id": "LIBCONSTRL_BEPALING_84", "key1": "vo", "psi": "0,25", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_30", "key1": "vo", "psi": "0,15", "Value": "54. kopgevel - onderdorpel raam" },
                { "Id": "LIBCONSTRL_BEPALING_85", "key1": "vo", "psi": "0,19", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_31", "key1": "vo", "psi": "0,09", "Value": "55. kopgevel - zijstijl raam" },
                { "Id": "LIBCONSTRL_BEPALING_86", "key1": "vo", "psi": "0,2", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_32", "key1": "vo", "psi": "0,1", "Value": "56. kopgevel - bovendorpel raam" },
                { "Id": "LIBCONSTRL_BEPALING_33", "key1": "vo", "psi": "0", "Value": "57. loggia - gevel - kopgevel (inwendige hoek)" },
                { "Id": "LIBCONSTRL_BEPALING_88", "key1": "vo,vl", "psi": "1,05", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_34", "key1": "vo,vl", "psi": "0,7", "Value": "58. verdiepingsvloer - langsgevel - galerij of balkon (aanstortnokken)" },
                { "Id": "LIBCONSTRL_BEPALING_89", "key1": "vo,vl", "psi": "0,23", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_35", "key1": "vo,vl", "psi": "0,13", "Value": "58. verdiepingsvloer - langsgevel - galerij of balkon (geen doorbreking)" },
                { "Id": "LIBCONSTRL_BEPALING_90", "key1": "vo,vl", "psi": "1,05", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_36", "key1": "vo,vl", "psi": "0,7", "Value": "59. verdiepingsvloer - langsgevel met kozijn - galerij of balkon (aanstortnokken)" },
                { "Id": "LIBCONSTRL_BEPALING_91", "key1": "vo,vl", "psi": "0,53", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_37", "key1": "vo,vl", "psi": "0,35", "Value": "59. verdiepingsvloer - langsgevel met kozijn - galerij of balkon (geen doorbreking)" },
                { "Id": "LIBCONSTRL_BEPALING_92", "key1": "d,vl,vo", "psi": "0,26", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_38", "key1": "d, vl, vo", "psi": "0,16", "Value": "60. dakvloer - opgaande langsgevel" },
                { "Id": "LIBCONSTRL_BEPALING_93", "key1": "d,vl,vo", "psi": "0,26", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_39", "key1": "d, vl, vo", "psi": "0,16", "Value": "61. dakvloer - kozijn in opgaande langsgevel" },
                { "Id": "LIBCONSTRL_BEPALING_94", "key1": "d,vo", "psi": "0,59", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_40", "key1": "d, vo", "psi": "0,39", "Value": "62. dakvloer - langsgevel - borstwering" },
                { "Id": "LIBCONSTRL_BEPALING_95", "key1": "vl,vo", "psi": "0,47", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_41", "key1": "vl, vo", "psi": "0,31", "Value": "63. overkragende vloer - langsgevel (uitwendige hoek)" },
                { "Id": "LIBCONSTRL_BEPALING_42", "key1": "vl, vo", "psi": "0", "Value": "64. overkragende vloer - langsgevel (inwendige hoek)" },
                { "Id": "LIBCONSTRL_BEPALING_97", "key1": "vl,vo", "psi": "0,54", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_43", "key1": "vl, vo", "psi": "0,36", "Value": "65. vloer boven AOR - langsgevel" },
                { "Id": "LIBCONSTRL_BEPALING_98", "key1": "vl,vo", "psi": "0,5", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_44", "key1": "vl, vo", "psi": "0,33", "Value": "66. overkragende vloer - kopgevel (uitwendige hoek)" },
                { "Id": "LIBCONSTRL_BEPALING_99", "key1": "vl,vo", "psi": "1,17", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_45", "key1": "vl, vo", "psi": "0,78", "Value": "67. vloer boven AOR - kopgevel" },
                { "Id": "LIBCONSTRL_BEPALING_100", "key1": "d,vo", "psi": "0,26", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_46", "key1": "d, vo", "psi": "0,16", "Value": "68. plat dak - langsgevel (dakrand)" },
                { "Id": "LIBCONSTRL_BEPALING_101", "key1": "vl", "psi": "0,5", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_54", "key1": "vl", "psi": "0,33", "Value": "69. kopgevel - verdiepingsvloer" },
                { "Id": "LIBCONSTRL_BEPALING_102", "key1": "d,vo", "psi": "0,29", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_47", "key1": "d, vo", "psi": "0,19", "Value": "70. plat dak - kopgevel (dakrand)" },
                { "Id": "LIBCONSTRL_BEPALING_103", "key1": "d,vl,vo", "psi": "0,29", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_48", "key1": "d, vl, vo", "psi": "0,19", "Value": "71. dakvloer - opgaande kopgevel" },
                { "Id": "LIBCONSTRL_BEPALING_104", "key1": "d,vo", "psi": "0,66", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_49", "key1": "d, vo", "psi": "0,44", "Value": "72. plat dak uitkraging - langsgevel" },
                { "Id": "LIBCONSTRL_BEPALING_105", "key1": "vl,vo", "psi": "1,26", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_50", "key1": "vl, vo", "psi": "0,84", "Value": "73. vloer boven AOR - langsgevel - galerij of balkon (aanstortnokken)" },
                { "Id": "LIBCONSTRL_BEPALING_106", "key1": "vl,vo", "psi": "0,41", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_51", "key1": "vl, vo", "psi": "0,27", "Value": "73. vloer boven AOR - langsgevel - galerij of balkon (geen doorbreking)" },
                { "Id": "LIBCONSTRL_BEPALING_107", "key1": "vl,vo", "psi": "1,26", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_52", "key1": "vl, vo", "psi": "0,84", "Value": "74. vloer boven AOR - langsgevel met kozijn - galerij of balkon (aanstortnokken)" },
                { "Id": "LIBCONSTRL_BEPALING_108", "key1": "vl,vo", "psi": "0,57", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_53", "key1": "vl, vo", "psi": "0,38", "Value": "74. vloer boven AOR - langsgevel met kozijn - galerij of balkon (geen doorbreking)" },
                { "Id": "LIBCONSTRL_BEPALING_109", "key1": "f,vo,vl,d", "psi": "0,5", "Value": "" },
                { "Id": "LIBCONSTRL_BEPALING_NVT", "key1": "", "psi": "", "Value": "n.v.t." }
            ];

            const bepalingCodedValues = ntabuilding.properties["LIBCONSTRL_BEPALING"].Domain.Codes;

            lineaireConstructies.forEach(function (row, index) {
                const codedValue = bepalingCodedValues.find(function (x) { return x.Id === row.Id; });
                if (codedValue) {
                    row.Value = codedValue.Value;
                    row.Order = codedValue.Order;
                } else {
                    row.Delete = true;
                }
            });

            return lineaireConstructies.filter(row => !row.Delete);

        } //-- end: createTableLineaireConstructies ------------------------------------------------------------------------//

    }

    return LibraryLogic;
}]);