﻿angular.module('projectModule')
    .controller("InstallationWindturbineController",
        ['$scope', '$controller', 'ntaValidation', 'ntaSharedLogic', '$routeParams', 'ntaDependencyValidation', 'ntaMeasure', 'WindturbineFactory',
function ($scope,   $controller,   ntaValidation,   ntaSharedLogic,   $routeParams,   ntaDependencyValidation,   ntaMeasure,   WindturbineFactory) {
    const vm = this;
    const base = $controller('NTABaseController', { $scope: $scope })
    angular.extend(vm, base);

    vm.preventBackspace = ntaSharedLogic.preventBackspace;

    //Data
    vm.installId = $routeParams.id3;
    vm.ntaValidation = ntaValidation;

    const windenergieLogic = new WindturbineFactory(vm.installId, ntaDependencyValidation);

    vm.installId = windenergieLogic.installId; // in een maatregel was installId undefined; de logic heeft ’m intussen opgespoord (of aangemaakt)

    vm.getWindenergieLogic = () => windenergieLogic;

    vm.properties = windenergieLogic.properties;
    vm.winddata = () => windenergieLogic.winddata;

    vm.getTitle = () => ntaSharedLogic.isEditingMeasure() ? 'Maatregel: Windenergie' : 'Windenergie';


    //Form
    vm.getForm = () => windenergieLogic.form_installationwindturbine;

    $scope.endFormValidation = windenergieLogic.endFormValidation();

    ntaMeasure.setBtwPropIds(vm);

    angular.element(function () { //alle touched properties valideren na het laden van form
        windenergieLogic.startFormValidation();
        base.initializeUnitSpacing();
        setTimeout(base.initializeUnitSpacing, 500);
    });

    // Properties
    vm.saveValue = function (prop, entdata = windenergieLogic.winddata) {
        windenergieLogic.saveValue(prop, entdata);
        base.initializeUnitSpacing();
    };

    vm.saveValueAutocomplete = windenergieLogic.saveValueAutocomplete;

    vm.getPropertyType = function (prop) {
        if (!prop) {
            return;
        }
        return prop.PropertyType;
    };

    vm.isHidden = windenergieLogic.isHidden;

    vm.isReadOnly = () => false;

    vm.getPropData = function (prop, entdata = vm.winddata()) {
        if (!prop) {
            return;
        }
        return base.getPropData(prop, entdata);
    };

    vm.getPropDataAutocomplete = function (prop, entdata = vm.winddata()) {
        if (!prop) {
            return;
        }
        if (entdata) {
            const propData = entdata.PropertyDatas[prop.Id];
            if (propData.Value) {
                var cvs = ntaValidation.codedValues(prop);
                if (cvs && cvs.length > 2) { // is coded value
                    const cvData = cvs.find(function (x) { return x.Id === propData.Value; });
                    const aRes = { Value: cvData };
                    return aRes;
                }
            }
            return;
        } else {
            return prop.DefaultValue;
        }
    };

    windenergieLogic.setGeopend();
}]);