﻿angular.module('projectModule')
    .controller('VariantController',
        ['$scope', '$controller', '$routeParams', 'ntaValidation', 'VariantFactory', 'BuildingResultsFactory', 'ntaEntityDataOrg', 'ntaDependencyValidation', 'ntaSharedLogic',
function ($scope,   $controller,   $routeParams,   ntaValidation,   VariantFactory,   BuildingResultsFactory,   ntaEntityDataOrg,   ntaDependencyValidation,   ntaSharedLogic) {
    'use strict';
    const ctrl = this;
    const base = $controller('NTABaseController', { $scope: $scope })
    angular.extend(ctrl, base);

    //== Imports ==================================================================================
    ctrl.ntaValidation = ntaValidation;


    //== Instance data ============================================================================
    ctrl.variantId = $routeParams.shadowId;
    const logic = new VariantFactory(ctrl.variantId, ntaDependencyValidation);

    const resultsLogic = new BuildingResultsFactory(ctrl.variantId, ntaSharedLogic.showCosts());
    ctrl.resultsLogic = resultsLogic;

    //== Exports ==================================================================================
    ctrl.logic = logic;
    ctrl.properties = logic.properties;
    ctrl.entdata = logic.entdataVariant;
    ctrl.isReadOnly = logic.isReadOnly;
    ctrl.getCodedValues = logic.getCodedValues;
    ctrl.getForm = () => logic.form;
    ctrl.getPropertyType = prop => prop && prop.PropertyType;
    ctrl.getAvailableMeasureItems = logic.getAvailableMeasureItems;
    ctrl.getVariantMeasures = logic.getVariantMeasures;
    ctrl.getMeasureName = logic.getMeasureName;

    Object.assign(ctrl, {
        saveValue,
        getName,
        addVariantMeasure,
        deleteVariantMeasure
    });

    $scope.endFormValidation = logic.endFormValidation;

    $scope.$on('settingsChanged', function (event, args) {
        resultsLogic.setExtraResultParents(ctrl.variantId, ntaSharedLogic.showCosts());
        resultsLogic.setEnergieResultaten(resultsLogic.getActiveBerekeningId());
    });

    //== Initialization ===========================================================================
    ctrl.variantTitle = ctrl.entdata.PropertyDatas['VARIANT_NAAM'].Value;

    ctrl.measureVariantSortList = [];
    updateMeasureVariantSortList();

    angular.element(function () { //alle touched properties valideren na het laden van form
        logic.startFormValidation();
        logic.setGeopend();
    });

    ctrl.sortableMeasures = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            ntaEntityDataOrg.SaveReOrder(ctrl.measureVariantSortList);
        }
    };


    //== Implementation ===========================================================================

    function saveValue(prop) {
        logic.saveValue(prop, logic.entdataMeasureRc);
        base.initializeUnitSpacing();
    } //-- end: saveValue -------------------------------------------------------------------------

    function getName(prop) {
        return base.getName(prop);
    }

    function addVariantMeasure(item) {
        logic.addVariantMeasure(item);
        updateMeasureVariantSortList();
    }; 

    function deleteVariantMeasure(entdata) {
        logic.deleteVariantMeasure(entdata);
        updateMeasureVariantSortList();
    }; 

    function updateMeasureVariantSortList() {
        ctrl.measureVariantSortList = logic.getVariantMeasures()
            .slice();
    } //-- end: updateVlakkenList ------------------------------------------------------------------------//


}]);
