﻿angular.module('ntaModule')
    .service('ntaBuildingNotification',
        ['$log', '$mdDialog', 'ntaStorage', 'ntaData',
function ($log,   $mdDialog,   ntaStorage,   ntaData) {
    'use strict';
    const self = this;

    /// -- Instance variables ---------------------------------------------------------------------

    const _waitTimeouts = new Map();
    const _openedBuildingIds = new Set();


    /// -- Imports --------------------------------------------------------------------------------

    // signalR staat in libman.json, en wordt geladen in Views\Shared\__Layout.cshtml


    /// -- Exports --------------------------------------------------------------------------------

    self.enabled = !ntaData.suppressNotifications;

    Object.assign(self, {
        notifyBuildingOpened: notifyBuildingsOpened,
        notifyBuildingsOpened,
        isBuildingOpened,
    });


    /// -- Initialization -------------------------------------------------------------------------

    // Dit seintje krijgen we elke keer dat er een berekening geopend wordt.
    ntaStorage.on("BuildingOpened", onBuildingOpenedElsewhere);
    // Dit seintje krijgen we als wij een berekening geopend hebben dat nog opgeslagen werd; dit wordt gevolgd door een BuildingUpdated als dat opslaan klaar is.
    ntaStorage.on("BuildingOutOfDate", onBuildingOutOfDate);
    // Dit seintje krijgen we als een berekening die wij geopend hebben klaar is met opslaan; we moeten dan de gegevens opnieuw ophalen.
    ntaStorage.on("BuildingUpdated", onBuildingUpdated);


    /// -- Implementation -------------------------------------------------------------------------

    async function notify(eventName, buildingId, ...args) {
        if (!self.enabled) return;

        try {
            const connection = await ntaStorage.whenConnected();
            connection.send('Notify' + eventName, buildingId, ...args);
        } catch (err) {
            $log.error(err);
        }
    }

    async function notifyBuildingsOpened(buildingIds) {
        if (!self.enabled) return;

        if (!Array.isArray(buildingIds)) buildingIds = [buildingIds];
        const userName = getCurrentUserName();

        _openedBuildingIds.clear();
        buildingIds.forEach(buildingId => _openedBuildingIds.add(buildingId));

        await Promise.all(buildingIds
            .filter(buildingId => ntaData.canSaveBuilding(buildingId)) // geen verwittiging uitsturen als we toch geen wijzigingen kunnen opslaan.
            .map(buildingId => notify("BuildingOpened", buildingId, userName))
        );
    }

    function getCurrentUserName() {
        const userNameElement = document.getElementById('userName');
        const userName = userNameElement && userNameElement.textContent || '';
        return userName;
    }

    function onBuildingOpenedElsewhere(buildingId, openedBy) {
        if (!self.enabled)
            return;
        if (!isBuildingOpened(buildingId))
            return; // niet reageren als we de betreffende berekening niet geopend hadden.
        if (!ntaData.canSaveBuilding(buildingId))
            return; // niet reageren als we toch al geen wijzigingen konden opslaan.

        try {
            const userName = getCurrentUserName();

            const alert = $mdDialog.alert()
                .ok('OK');
            if (openedBy === userName) {
                alert.title('Berekening 2e keer geopend');
                alert.htmlContent(`
                    <p>U heeft deze berekening ook op een andere plek geopend. Dat kan zijn in een
                        ander tabblad, in een andere internetbrowser of op een ander apparaat (PC,
                        laptop, tablet, telefoon). Om beschadiging van de berekening te voorkomen,
                        is deze berekening hier vergrendeld.</p>
                    <p>Als u deze berekening hier opnieuw opent of ververst (druk F5) krijgt u hier
                        toegang tot de berekening en wordt de berekening die elders open staat vergrendeld.</p>
                `);
            } else {
                alert.title('Berekening geopend door ' + openedBy);
                alert.htmlContent(`
                    <p>Deze berekening is zojuist ook geopend door ${openedBy}. Om beschadiging
                        van de berekening te voorkomen, wordt deze hier vergrendeld. Overleg met
                        uw collega wie in de berekening gaat werken.</p>
                    <p>Als u de berekening opnieuw opent of ververst (druk F5) krijgt u opnieuw
                        toegang tot de berekening en wordt de berekening bij uw collega vergrendeld.</p>
                `);
            }
            $mdDialog.show(alert);

            const hadUnsavedData = ntaStorage.hasUnsavedData();
            if (hadUnsavedData) notify("BuildingOutOfDate", buildingId);

            ntaStorage.whenDoneSaving(() => {
                ntaData.lockBuilding(buildingId);
                if (hadUnsavedData) notify("BuildingUpdated", buildingId);
            }, {
                title: 'berekening wordt vergrendeld...',
                timeoutMS: 29000, // maximaal 29 secondes wachten
                waitAfterMS: 0,
            });

        } catch (err) {
            $log.error(err);
        }
    }

    function onBuildingOutOfDate(buildingId) {
        if (!self.enabled)
            return;
        if (!isBuildingOpened(buildingId))
            return; // niet reageren als we de betreffende berekening niet geopend hadden.
        if (!ntaData.canSaveBuilding(buildingId))
            return; // niet reageren als we toch al geen wijzigingen konden opslaan.

        // dan moeten we even wachten tot we weer bij zijn
        progressCircle.setShowProgressValue(true, 'wachten op nagekomen wijzigingen...');
        _waitTimeouts.set(buildingId, setTimeout(() => onBuildingUpdated(buildingId), 30000)); // maximaal 30 secondes wachten
    }

    async function onBuildingUpdated(buildingId) {
        if (!self.enabled)
            return;
        if (!isBuildingOpened(buildingId))
            return; // niet reageren als we de betreffende berekening niet geopend hadden.

        clearTimeout(_waitTimeouts.get(buildingId));
        _waitTimeouts.delete(buildingId);
        if (_waitTimeouts.size === 0) {
            // alle data opnieuw van de server ophalen
            location.reload();
        }
    }

    function isBuildingOpened(buildingId) {
        return _openedBuildingIds.has(buildingId);
    }

}]);