﻿angular.module('projectModule')
    .factory("VariantsFactory",
        ['ntabuilding', 'ntaData', 'ntaEntityData', 'ntaValidation', '$log', '$mdDialog', 'progressCircle', 'ntaSharedLogic', 'ntaVariant',
function (ntabuilding,   ntaData,   ntaEntityData,   ntaValidation,   $log,   $mdDialog,   progressCircle,   ntaSharedLogic,   ntaVariant) {
    'use strict';

    function VariantsLogic(ntaDependencyValidation) {
        const variantsLogic = this;

        variantsLogic.form_variants = null;

        variantsLogic.dependencyValidator = ntaDependencyValidation;

        variantsLogic.properties = ntabuilding.properties['VARIANT'];
        variantsLogic.variants = () => ntaVariant.getList().filter(x => x.Relevant);


        /////////////////////////////////////////////////////////////////////////////////////////// CRUD akties /////////////////////////////////////////////////////////////////////////////////////////////////////
        variantsLogic.addVariant = function () {
            progressCircle.setShowProgressValue(true, 'aanmaken nieuwe variant');
            try {

                return ntaVariant.create();

            } finally {
                progressCircle.setShowProgressValue(false);
            }
        }; //-- end: addVariant --------------------------------------------------------------------------------//

        variantsLogic.copyClick = function (id) {
            if (!id) {
                return;
            }

            progressCircle.setShowProgressValue(true, 'kopiëren variant');
            const newEntdatas = ntaVariant.copy(id);

            //-- naam van de variant uitbreiden met " - kopie"
            const variant = newEntdatas.find(e => e.EntityId === 'VARIANT');
            const propdataNaam = variant && variant.PropertyDatas["VARIANT_NAAM"];
            if (propdataNaam) {
                const newName = propdataNaam.Value + " - kopie";
                ntaEntityData.saveprop(propdataNaam, newName);
            }
            progressCircle.setShowProgressValue(false);
        };

        variantsLogic.deleteClick = function (id) {
            if (!id) {
                return;
            }
            const entdata = ntaEntityData.get(id);
            const variantName = ntaVariant.getName(entdata);
            const confirm = $mdDialog.confirm()
                .title('Verwijderen variant')
                .htmlContent('U staat op het punt om variant "' + variantName + '" te verwijderen. Weet u het zeker?')
                .ariaLabel('Verwijderen')
                .targetEvent(event)
                .ok('Ja')
                .cancel('Nee');

            $mdDialog.show(confirm).then(function () {
                ntaVariant.deleteVariant(id);
            });
        };

        variantsLogic.saveValue = function (prop, entdata, newValue) {
            if (typeof prop === 'string') {
                prop = ntaData.properties[prop];
            } else if (prop && prop.PropertyId) {
                prop = ntaData.properties[prop.PropertyId];
            }
            return ntaSharedLogic.saveValue(prop, entdata, newValue, variantsLogic);
        }; //-- end: saveValue --------------------------------------------------------------------

        variantsLogic.isHidden = function (prop, entdata) {
            if (typeof prop === 'string') {
                prop = ntaData.properties[prop];
            }
            if (!prop || !entdata) return true;

            const propdata = prop.getData(entdata);

            let visible = true;
            let relevant = null; // null => gelijk aan visible

            switch (prop.Id) {
                case 'VARIANT_ERROR':
                    visible = false;
                    break;

                case 'VARIANT_NAAM':
                    visible = true;
                    break;

                case 'VARIANT_KOSTEN_TOT':
                    visible = ntaVariant.conditieOVC();
                    break;

                case 'VARIANT_NCW':
                    visible = false;
                    break;

                case 'VARIANT_LEVENSDUUR':
                case 'VARIANT_TVT':
                    visible = ntaVariant.conditieOVC();
                    break;
            }

            if (relevant === null) relevant = visible;

            ntaEntityData.setPropdataStatus(propdata, relevant, visible);

            return !visible;
        }; //-- end: isHidden ---------------------------------------------------------------------

        variantsLogic.isReadOnly = function (prop, entdata) {
            if (typeof prop === 'string') {
                prop = ntaData.properties[prop];
            }
            if (!prop) return true;

            switch (prop.Id) {
                case 'VARIANT_LEVENSDUUR':
                    return false;

                case 'VARIANT_ERROR':
                case 'VARIANT_KOSTEN_TOT':
                case 'VARIANT_TVT':
                    return true;
            }
        }; //-- end: isHidden ---------------------------------------------------------------------

        variantsLogic.validate = function (prop, propdata, entdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return false;
            }
            const hidden = variantsLogic.isHidden(prop, entdata);
            const readOnly = variantsLogic.isReadOnly(prop, entdata);
            return ntaValidation.IsValid(variantsLogic.form_variants, prop, propdata, hidden, readOnly);  //Client Validatie
        };

        variantsLogic.validateDependencies = function (prop, entdata) {
            // er zijn geen dependencies om te valideren
        }; //-- end: validateDependencies ---------------------------------------------------------

        variantsLogic.startFormValidation = function () {
            return ntaSharedLogic.startFormValidation(getAllEntDatas(), variantsLogic);
        };

        variantsLogic.endFormValidation = function () {
            if (ntaData.current.shadowId !== null) return [];

            return ntaSharedLogic.endFormValidation(getAllEntDatas(), variantsLogic);
        };

        function getAllEntDatas() {
            return variantsLogic.variants()
                .filter(ed => ed); // null en undefined wegfilteren
        }

    }

    return VariantsLogic;
}]);