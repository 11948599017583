﻿import './src/site';
import './src/app/nta-module';
import './src/app/index-module';
import './src/app/services/nta-building-notification-service';
import './src/app/project-module';
import './src/app/progress-module';
import './src/app/settings-module';
import './src/app/results-module';
import './src/app/afmeld-module';
import './src/app/belemmering-module';
import './src/app/navigatie-controller';
import './src/app/ntabase-controller';
import './src/app/berekeningen/buildingresults-controller';
import './src/app/berekeningen/buildingresultstojuli-controller';
import './src/app/berekeningen/buildingresultsoverzicht-controller';
import './src/app/berekeningen/niet-gebgeb-elektr-controller';
import './src/app/berekeningen/niet-gebgeb-elektr-factory';
import './src/app/building/buildingattr-controller';
import './src/app/building/buildingcalczones-controller';
import './src/app/building/buildinglibrary-controller';
import './src/app/building/buildingluchtdoorlaten-controller';
import './src/app/building/buildingconstructies-controller';
import './src/app/building/buildingbegrenzing-controller';
import './src/app/components/nta-components';
import './src/app/installations/installationpv-controller';
import './src/app/installations/installationpv-controller_v2';
import './src/app/installations/installationsdefine-controller';
import './src/app/installations/installationwindturbine-controller';
import './src/app/installations/installationventilatie-controller';
import './src/app/installations/installationverwarming-controller';
import './src/app/installations/installationkoeling-controller';
import './src/app/installations/installationzonnb-controller';
import './src/app/installations/installationzonnb-controller_v2';
import './src/app/installations/installationverlichting-controller';
import './src/app/installations/installationtapwater-controller';
import './src/app/installations/installationbevochtiging-controller';
import './src/app/maatwerk/gebruikersprofiel-controller';
import './src/app/maatwerk/gebruikersprofiel-factory';
import './src/app/maatwerk/energierekening-controller';
import './src/app/maatwerk/energierekening-factory';
import './src/app/maatwerk/fitgrafiek-controller'
import './src/app/maatwerk/measures-controller';
import './src/app/maatwerk/measures-factory';
import './src/app/maatwerk/measure-hvac-factory';
import './src/app/maatwerk/measure-infiltratie-factory';
import './src/app/maatwerk/measure-rc-controller';
import './src/app/maatwerk/measure-rc-factory';
import './src/app/maatwerk/measure-u-controller';
import './src/app/maatwerk/measure-u-factory';
import './src/app/maatwerk/measure-verl-factory';
import './src/app/maatwerk/measure-vert-leidingen-factory';
import './src/app/maatwerk/measure-pv-factory';
import './src/app/maatwerk/measure-windt-factory';
import './src/app/maatwerk/measure-zonwering-controller';
import './src/app/maatwerk/measure-zonwering-factory';
import './src/app/maatwerk/variants-controller';
import './src/app/maatwerk/variants-factory';
import './src/app/maatwerk/variant-controller';
import './src/app/maatwerk/variant-factory';
import './src/app/project/indexberekeningen-controller';
import './src/app/project/indextree-controller';
import './src/app/project/indexmove-controller';
import './src/app/project/projectattr-controller';
import './src/app/project/projectbuildings-controller';
import './src/app/project/projecttree-controller';
import './src/app/project/afmeld-controller';
import './src/app/project/register-controller';
import './src/app/services/formversion-controller';
import './src/app/services/nta-alert-service';
import './src/app/components/search-controller';
import './src/app/services/buildingdata-factory';
import './src/app/services/event-source-factory';
import './src/app/services/folder-service';
import './src/app/services/hub-connection-factory';
import './src/app/services/list-cache-factory';
import './src/app/services/nta-local-service';
import './src/app/services/nta-calc-validation-service';
import './src/app/services/nta-data-factories';
import './src/app/services/nta-delta-service';
import './src/app/services/nta-diagram-service';
import './src/app/services/nta-entitydata-factory';
import './src/app/services/nta-entitydata-service';
import './src/app/services/nta-validation-service';
import './src/app/services/nta-logger-service';
import './src/app/services/nta-measure-service';
import './src/app/services/nta-meldingen-service';
import './src/app/services/nta-orientation-service';
import './src/app/services/nta-find-and-replace-service';
import './src/app/services/nta-results-service';
import './src/app/services/nta-rounding-service';
import './src/app/services/nta-selectiontable-service';
import './src/app/services/nta-shared-logic-factory';
import './src/app/services/nta-shared-logic-service';
import './src/app/services/nta-storage-service';
import './src/app/services/nta-dependency-validation-service';
import './src/app/services/nta-variant-service';
import './src/app/services/nta-versioning-service';
import './src/app/services/pager-factory';
import './src/app/services/search-service';
import './src/app/services/time-service';
import './src/app/services/active-building-service';
import './src/app/factories/installationpv-factory';
import './src/app/factories/installationbevochtiging-factory';
import './src/app/factories/installationverwarming-factory';
import './src/app/factories/installationkoeling-factory';
import './src/app/factories/installationtapwater-factory';
import './src/app/factories/installationventilatie-factory';
import './src/app/factories/installationzonnb-factory';
import './src/app/factories/installationverlichting-factory';
import './src/app/factories/installationwindturbine-factory';
import './src/app/factories/installationsdefine-factory';
import './src/app/factories/buildingluchtdoorlaten-factory';
import './src/app/factories/buildingbegrenzing-factory';
import './src/app/factories/buildingconstructies-factory';
import './src/app/factories/buildinglibrary-factory';
import './src/app/factories/buildingcalczones-factory';
import './src/app/factories/buildingattributes-factory';
import './src/app/factories/buildingresults-factory';
import './src/app/factories/buildingresultsoverzicht-factory';
import './src/app/angular-material_edited';
import './src/app/adminlte_edited';
// Onderstaande debug-scripts worden uitsluitend in de betreffende pagina geladen; het is niet
//  nodig om die altijd te laden.
//import './src/app/debug/resultaten';
//import './src/app/debug/debug-buildings';
//import './src/app/debug/debug-results-controller';
