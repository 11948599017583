﻿angular.module('projectModule')
    .controller('NietGebouwGebondenElektriciteitController',
        ['$scope', '$controller', '$mdDialog', 'ntaValidation', 'ntaDependencyValidation', 'NietGebouwGebondenElektriciteitFactory',
function ($scope,   $controller,   $mdDialog,   ntaValidation,   ntaDependencyValidation,   NietGebouwGebondenElektriciteitFactory) {
    'use strict';
    const ctrl = this;
    const base = $controller('NTABaseController', { $scope: $scope })
    angular.extend(ctrl, base);

    //== Imports ==================================================================================
    ctrl.ntaValidation = ntaValidation;


    //== Instance data ============================================================================
    const logic = new NietGebouwGebondenElektriciteitFactory(ntaDependencyValidation);

    const _eigenInvoerPropIds = new Map(Object.entries({
        'NGEBGEB-E_VAST':   new Set(['NGEBGEB-E_VAST']),
        'NGEBGEB-E_PER_M2': new Set(['NGEBGEB-E_PER_M2', 'NGEBGEB-E_MIN', 'NGEBGEB-E_MAX']),
    }));
    const _eigenInvoerProperties = [..._eigenInvoerPropIds]
        .flatMap(([key, propIds]) => [...propIds])
        .map(propId => logic.properties[propId]);

    //== Exports ==================================================================================
    ctrl.logic = logic;

    ctrl.entdata = logic.entdata;
    ctrl.properties = logic.properties;
    ctrl.eigenInvoerProperties = _eigenInvoerProperties;

    ctrl.isReadOnly = logic.isReadOnly;
    ctrl.getCodedValues = logic.getCodedValues;
    ctrl.isItemChecked = logic.isItemChecked;
    ctrl.toggleItemChecked = logic.toggleItemChecked;

    ctrl.getForm = () => logic.form;
    ctrl.getPropData = prop => base.getPropData(prop, logic.entdata);
    ctrl.getPropertyType = prop => prop && prop.PropertyType;

    Object.assign(ctrl, {
        isHidden,
        saveValue,
        close,
    });

    $scope.endFormValidation = logic.endFormValidation;
    $scope.$on('settingsChanged', function (event, args) {
        setTimeout(base.initializeUnitSpacing, 100);
    });


    //== Initialization ===========================================================================

    angular.element(function () { //alle touched properties valideren na het laden van form
        setTimeout(() => {
            logic.startFormValidation();
            base.initializeUnitSpacing();
        }, 100);
        logic.setGeopend();
    });

    //== Implementation ===========================================================================

    function isHidden(prop, entdata, option) {
        const visible = (!option || _eigenInvoerPropIds.get(option.Id).has(prop.Id))
            && !logic.isHidden(prop, entdata);

        return !visible;
    } //-- end: isHidden --------------------------------------------------------------------------

    function saveValue(prop) {
        logic.saveValue(prop, logic.entdata);
        base.initializeUnitSpacing();
    } //-- end: saveValue -------------------------------------------------------------------------

    function close() {
        $mdDialog.hide();
    } //-- end: close -----------------------------------------------------------------------------

}]);
