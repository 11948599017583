﻿angular.module('projectModule')
    .factory('EnergierekeningFactory',
        ['$log', 'ntaData', 'ntabuilding', 'ntaValidation', 'ntaEntityDataOrg', 'ntaSharedLogic', 'ntaRounding', 'time',
function ($log,   ntaData,   ntabuilding,   ntaValidation,   ntaEntityDataOrg,   ntaSharedLogic,   ntaRounding,   time ) {
    'use strict';

    //== Static data ==============================================================================
    const _postcodeStations = [
        { from: 1000, till: 1129, id: 'KNMI_SCHIPHOL' },
        { from: 1130, till: 1159, id: 'KNMI_BERKHOUT' },
        { from: 1160, till: 1199, id: 'KNMI_SCHIPHOL' },
        { from: 1200, till: 1299, id: 'KNMI_DE_BILT' },
        { from: 1300, till: 1379, id: 'KNMI_LELYSTAD' },
        { from: 1380, till: 1399, id: 'KNMI_SCHIPHOL' },
        { from: 1400, till: 1419, id: 'KNMI_DE_BILT' },
        { from: 1420, till: 1469, id: 'KNMI_SCHIPHOL' },
        { from: 1470, till: 1479, id: 'KNMI_BERKHOUT' },
        { from: 1480, till: 1599, id: 'KNMI_SCHIPHOL' },
        { from: 1600, till: 1699, id: 'KNMI_BERKHOUT' },
        { from: 1700, till: 1749, id: 'KNMI_SCHIPHOL' },
        { from: 1750, till: 1759, id: 'KNMI_DE_KOOY' },
        { from: 1760, till: 1779, id: 'KNMI_BERKHOUT' },
        { from: 1780, till: 1789, id: 'KNMI_DE_KOOY' },
        { from: 1790, till: 1799, id: 'KNMI_HOORN_TERSCHELLING' },
        { from: 1800, till: 1859, id: 'KNMI_SCHIPHOL' },
        { from: 1860, till: 1909, id: 'KNMI_DE_KOOY' },
        { from: 1910, till: 1929, id: 'KNMI_SCHIPHOL' },
        { from: 1930, till: 1989, id: 'KNMI_DE_KOOY' },
        { from: 1990, till: 2039, id: 'KNMI_SCHIPHOL' },
        { from: 2040, till: 2062, id: 'KNMI_VOORSCHOTEN' },
        { from: 2063, till: 2069, id: 'KNMI_SCHIPHOL' },
        { from: 2070, till: 2099, id: 'KNMI_VOORSCHOTEN' },
        { from: 2100, till: 2109, id: 'KNMI_SCHIPHOL' },
        { from: 2110, till: 2119, id: 'KNMI_VOORSCHOTEN' },
        { from: 2120, till: 2189, id: 'KNMI_SCHIPHOL' },
        { from: 2190, till: 2299, id: 'KNMI_VOORSCHOTEN' },
        { from: 2300, till: 2409, id: 'KNMI_SCHIPHOL' },
        { from: 2410, till: 2419, id: 'KNMI_CABAUW' },
        { from: 2420, till: 2499, id: 'KNMI_SCHIPHOL' },
        { from: 2500, till: 2599, id: 'KNMI_VOORSCHOTEN' },
        { from: 2600, till: 2679, id: 'KNMI_ROTTERDAM' },
        { from: 2680, till: 2689, id: 'KNMI_VOORSCHOTEN' },
        { from: 2690, till: 2799, id: 'KNMI_ROTTERDAM' },
        { from: 2800, till: 2899, id: 'KNMI_CABAUW' },
        { from: 2900, till: 2939, id: 'KNMI_ROTTERDAM' },
        { from: 2940, till: 2979, id: 'KNMI_CABAUW' },
        { from: 2980, till: 3149, id: 'KNMI_ROTTERDAM' },
        { from: 3150, till: 3151, id: 'KNMI_HOEK_VAN_HOLLAND' },
        { from: 3152, till: 3239, id: 'KNMI_ROTTERDAM' },
        { from: 3240, till: 3259, id: 'KNMI_VLISSINGEN' },
        { from: 3260, till: 3299, id: 'KNMI_ROTTERDAM' },
        { from: 3300, till: 3329, id: 'KNMI_HERWIJNEN' },
        { from: 3330, till: 3349, id: 'KNMI_ROTTERDAM' },
        { from: 3350, till: 3399, id: 'KNMI_HERWIJNEN' },
        { from: 3400, till: 3429, id: 'KNMI_CABAUW' },
        { from: 3430, till: 3439, id: 'KNMI_DE_BILT' },
        { from: 3440, till: 3449, id: 'KNMI_CABAUW' },
        { from: 3450, till: 3459, id: 'KNMI_DE_BILT' },
        { from: 3460, till: 3499, id: 'KNMI_CABAUW' },
        { from: 3500, till: 3639, id: 'KNMI_DE_BILT' },
        { from: 3640, till: 3699, id: 'KNMI_SCHIPHOL' },
        { from: 3700, till: 3769, id: 'KNMI_DE_BILT' },
        { from: 3770, till: 3789, id: 'KNMI_DEELEN' },
        { from: 3790, till: 3889, id: 'KNMI_DE_BILT' },
        { from: 3890, till: 3899, id: 'KNMI_LELYSTAD' },
        { from: 3900, till: 3999, id: 'KNMI_DE_BILT' },
        { from: 4000, till: 4299, id: 'KNMI_HERWIJNEN' },
        { from: 4300, till: 4399, id: 'KNMI_VLISSINGEN' },
        { from: 4400, till: 4419, id: 'KNMI_VLISSINGEN' },
        { from: 4420, till: 4515, id: 'KNMI_VLISSINGEN' },
        { from: 4516, till: 4599, id: 'KNMI_WESTDORPE' },
        { from: 4600, till: 4699, id: 'KNMI_VLISSINGEN' },
        { from: 4700, till: 4799, id: 'KNMI_GILZE-RIJEN' },
        { from: 4800, till: 5059, id: 'KNMI_GILZE-RIJEN' },
        { from: 5060, till: 5069, id: 'KNMI_EINDHOVEN' },
        { from: 5070, till: 5079, id: 'KNMI_GILZE-RIJEN' },
        { from: 5080, till: 5099, id: 'KNMI_EINDHOVEN' },
        { from: 5100, till: 5199, id: 'KNMI_GILZE-RIJEN' },
        { from: 5200, till: 5249, id: 'KNMI_VOLKEL' },
        { from: 5250, till: 5257, id: 'KNMI_GILZE-RIJEN' },
        { from: 5258, till: 5259, id: 'KNMI_VOLKEL' },
        { from: 5260, till: 5269, id: 'KNMI_GILZE-RIJEN' },
        { from: 5270, till: 5279, id: 'KNMI_VOLKEL' },
        { from: 5280, till: 5299, id: 'KNMI_EINDHOVEN' },
        { from: 5300, till: 5339, id: 'KNMI_HERWIJNEN' },
        { from: 5340, till: 5499, id: 'KNMI_VOLKEL' },
        { from: 5500, till: 5739, id: 'KNMI_EINDHOVEN' },
        { from: 5740, till: 5799, id: 'KNMI_VOLKEL' },
        { from: 5800, till: 5829, id: 'KNMI_ARCEN' },
        { from: 5830, till: 5850, id: 'KNMI_VOLKEL' },
        { from: 5851, till: 5999, id: 'KNMI_ARCEN' },
        { from: 6000, till: 6119, id: 'KNMI_ELL' },
        { from: 6120, till: 6499, id: 'KNMI_MAASTRICHT' },
        { from: 6500, till: 6659, id: 'KNMI_VOLKEL' },
        { from: 6660, till: 6699, id: 'KNMI_HERWIJNEN' },
        { from: 6700, till: 6829, id: 'KNMI_DEELEN' },
        { from: 6830, till: 6859, id: 'KNMI_HERWIJNEN' },
        { from: 6860, till: 6999, id: 'KNMI_DEELEN' },
        { from: 7000, till: 7219, id: 'KNMI_HUPSEL' },
        { from: 7220, till: 7229, id: 'KNMI_DEELEN' },
        { from: 7230, till: 7299, id: 'KNMI_HUPSEL' },
        { from: 7300, till: 7399, id: 'KNMI_DEELEN' },
        { from: 7400, till: 7459, id: 'KNMI_HEINO' },
        { from: 7460, till: 7689, id: 'KNMI_TWENTE' },
        { from: 7690, till: 7709, id: 'KNMI_HOOGEVEEN' },
        { from: 7710, till: 7739, id: 'KNMI_HEINO' },
        { from: 7740, till: 7759, id: 'KNMI_HOOGEVEEN' },
        { from: 7760, till: 7769, id: 'KNMI_NIEUW_BEERTA' },
        { from: 7770, till: 7799, id: 'KNMI_HOOGEVEEN' },
        { from: 7800, till: 7849, id: 'KNMI_NIEUW_BEERTA' },
        { from: 7850, till: 7857, id: 'KNMI_HOOGEVEEN' },
        { from: 7858, till: 7859, id: 'KNMI_NIEUW_BEERTA' },
        { from: 7860, till: 7869, id: 'KNMI_HOOGEVEEN' },
        { from: 7870, till: 7899, id: 'KNMI_NIEUW_BEERTA' },
        { from: 7900, till: 7999, id: 'KNMI_HOOGEVEEN' },
        { from: 8000, till: 8059, id: 'KNMI_HEINO' },
        { from: 8060, till: 8069, id: 'KNMI_MARKNESSE' },
        { from: 8070, till: 8199, id: 'KNMI_HEINO' },
        { from: 8200, till: 8259, id: 'KNMI_LELYSTAD' },
        { from: 8260, till: 8269, id: 'KNMI_MARKNESSE' },
        { from: 8270, till: 8279, id: 'KNMI_HEINO' },
        { from: 8280, till: 8329, id: 'KNMI_MARKNESSE' },
        { from: 8330, till: 8354, id: 'KNMI_HOOGEVEEN' },
        { from: 8355, till: 8379, id: 'KNMI_MARKNESSE' },
        { from: 8380, till: 8399, id: 'KNMI_HOOGEVEEN' },
        { from: 8400, till: 8459, id: 'KNMI_EELDE' },
        { from: 8460, till: 8469, id: 'KNMI_LEEUWARDEN' },
        { from: 8470, till: 8488, id: 'KNMI_EELDE' },
        { from: 8489, till: 8879, id: 'KNMI_LEEUWARDEN' },
        { from: 8880, till: 8899, id: 'KNMI_HOORN_TERSCHELLING' },
        { from: 8900, till: 9099, id: 'KNMI_LEEUWARDEN' },
        { from: 9100, till: 9159, id: 'KNMI_LAUWERSOOG' },
        { from: 9160, till: 9169, id: 'KNMI_HOORN_TERSCHELLING' },
        { from: 9170, till: 9199, id: 'KNMI_LAUWERSOOG' },
        { from: 9200, till: 9239, id: 'KNMI_LEEUWARDEN' },
        { from: 9240, till: 9249, id: 'KNMI_EELDE' },
        { from: 9250, till: 9299, id: 'KNMI_LEEUWARDEN' },
        { from: 9300, till: 9409, id: 'KNMI_EELDE' },
        { from: 9410, till: 9419, id: 'KNMI_HOOGEVEEN' },
        { from: 9420, till: 9429, id: 'KNMI_EELDE' },
        { from: 9430, till: 9449, id: 'KNMI_HOOGEVEEN' },
        { from: 9450, till: 9499, id: 'KNMI_EELDE' },
        { from: 9500, till: 9599, id: 'KNMI_NIEUW_BEERTA' },
        { from: 9600, till: 9639, id: 'KNMI_EELDE' },
        { from: 9640, till: 9699, id: 'KNMI_NIEUW_BEERTA' },
        { from: 9700, till: 9899, id: 'KNMI_EELDE' },
        { from: 9900, till: 9942, id: 'KNMI_LAUWERSOOG' },
        { from: 9943, till: 9949, id: 'KNMI_NIEUW_BEERTA' },
        { from: 9950, till: 9999, id: 'KNMI_LAUWERSOOG' },
    ];

    return function EnergierekeningLogic(ntaDependencyValidation) {
        const self = this;

        //== Imports ==============================================================================
        self.ntaValidation = ntaValidation;
        self.dependencyValidator = ntaDependencyValidation;

        //== Instance data ========================================================================
        const _entdataEnergierekening = ntaEntityDataOrg.getFirstWithEntityId('ENER-REK')
            || ntaSharedLogic.showCosts() && ntaEntityDataOrg.get(ntaEntityDataOrg.create('ENER-REK')) || null;
        const _entdataVerbruikOverig = ntaEntityDataOrg.getFirstWithEntityId('VERBR-OVERIG')
            || ntaSharedLogic.showCosts() && ntaEntityDataOrg.get(ntaEntityDataOrg.create('VERBR-OVERIG')) || null;
        const _entdataTarieven = ntaEntityDataOrg.getFirstWithEntityId('TARV')
            || ntaSharedLogic.showCosts() && ntaEntityDataOrg.get(ntaEntityDataOrg.create('TARV')) || null;
        const _entdataTarievenOverig = ntaEntityDataOrg.getFirstWithEntityId('TARV-OVERIG')
            || ntaSharedLogic.showCosts() && ntaEntityDataOrg.get(ntaEntityDataOrg.create('TARV-OVERIG')) || null;
        const _entdataClimate = ntaEntityDataOrg.getFirstWithEntityId('CLIMATE')
            || ntaSharedLogic.showCosts() && ntaEntityDataOrg.get(ntaEntityDataOrg.create('CLIMATE')) || null;

        // de energiedragers zijn alleen nodig in de basisberekening [2q8gkDYc].
        const _dragerAanwezig = !ntaData.current.shadowId ? ntaSharedLogic.getDragerAanwezigMap() : new Map();

        let _verbruiken = ntaEntityDataOrg.getListWithEntityId('VERBR');
        let _energietarieven = ntaEntityDataOrg.getListWithEntityId('ENER-TARV');

        let _minStartDate = new Date(2020, 0, 1);
        let _maxStartDateByStationId = new Map();

        //== Exports ==============================================================================
        self.entdataEnergierekening = _entdataEnergierekening;
        self.entdataVerbruikOverig = _entdataVerbruikOverig;
        self.entdataTarieven = _entdataTarieven;
        self.entdataTarievenOverig = _entdataTarievenOverig;
        self.entdataClimate = _entdataClimate;

        const propertiesEnergierekening = ntaData.properties['ENER-REK'] || [];
        self.propertiesEnergierekeningTop = propertiesEnergierekening.slice(0, 2);
        self.propertiesEnergierekeningBottom = propertiesEnergierekening.slice(2);
        self.propertiesVerbruik = ntaData.properties['VERBR'];
        self.propertiesVerbruikOverig = ntaData.properties['VERBR-OVERIG'];
        self.propertiesTarieven = ntaData.properties['TARV'];
        self.propertiesEnergietarieven = ntaData.properties['ENER-TARV'];
        self.propertiesTarievenOverig = ntaData.properties['TARV-OVERIG'];
        self.propertiesClimate = ntaData.properties['CLIMATE'];

        // al deze methods exporteren zodat ze publiek beschikbaar zijn
        Object.assign(self, {
            getMinStartDate,
            getMaxStartDate,
            getVerbruiken,
            getEnergietarieven,
            isItemChecked,
            toggleItemChecked,
            isChecked,
            getColCount,
            showTableVerbruik,
            getSumOfVerbruik,
            isTotalVerbruikVisible,
            getPropData,
            isHidden,
            isReadOnly,
            hasCodedValues,
            getCodedValues,
            saveValue,
            validate,
            validateDependencies,
            startFormValidation,
            endFormValidation,
            setGeopend,
            conditionB,
        });

        //== Initialization =======================================================================

        //check of entiteiten meer dan 1x vorkomen (en evt. overbodige verwijderen)
        for (const entiteit of [_entdataEnergierekening, _entdataVerbruikOverig, _entdataTarieven, _entdataTarievenOverig, _entdataClimate]) {
            if (entiteit) {
                const entityList = ntaEntityDataOrg.getListWithEntityId(entiteit.EntityId);
                if (entityList.length > 1) {
                    for (const entdata of entityList.filter(ed => ed !== entiteit)) {
                        ntaEntityDataOrg.delete(entdata.EntityDataId);
                    }
                }
            }
        }

        // ER05 checkboxes default aan
        if (_entdataEnergierekening) {
            const propVerbrAanw = ntaData.properties['ENER-REK_VERBRAANW'];
            const propdataEnerRekVerbrAanw = propVerbrAanw.getData(_entdataEnergierekening);
            if (!propdataEnerRekVerbrAanw.Touched && !propdataEnerRekVerbrAanw.Value) {
                saveValue('ENER-REK_VERBRAANW', _entdataEnergierekening, propVerbrAanw.Domain.Codes.map(x => x.Id).join('|') + '|');
            }
        }

        //verbruiken
        if (_verbruiken.length > 0 && _verbruiken.length !== 13) { //niet volledig -> verwijder alle verbruiken
            for (const verbruik of _verbruiken) {
                ntaEntityDataOrg.delete(verbruik.EntityDataId);
            }
            _verbruiken = ntaEntityDataOrg.getListWithEntityId('VERBR');
        }
        if (_verbruiken.length === 0 && ntaSharedLogic.showCosts()) { // als er nog geen verbruiken zijn, maak er dan 13 aan, 1 voor elke maand en 1 voor het jaar
            const propVerbrType = ntaData.properties['VERBR_VERBR_MJ'];
            if (propVerbrType) {
                for (const code of propVerbrType.Domain.Codes) {
                    const id = ntaEntityDataOrg.create('VERBR');
                    const entdata = ntaEntityDataOrg.get(id);
                    saveValue(propVerbrType, entdata, code.Id);
                }
            }
            _verbruiken = ntaEntityDataOrg.getListWithEntityId('VERBR');
        }

        //tarieven
        if (_energietarieven.length > 0 && _energietarieven.length !== 2) { //niet volledig -> verwijder alle verbruiken
            for (const energietarief of _energietarieven) {
                ntaEntityDataOrg.delete(energietarief.EntityDataId);
            }
            _energietarieven = ntaEntityDataOrg.getListWithEntityId('ENER-TARV');
        }
        if (_energietarieven.length === 0 && ntaSharedLogic.showCosts()) { // als er nog geen energietarieven zijn, maak er dan 2 aan, 1 voor 'prijs per eenheid' en 1 voor 'vaste kosten per jaar (vastrecht e.d.)'
            const propEnerTarvType = ntaData.properties['ENER-TARV_TYPE'];
            const propEnerTarvETlev = ntaData.properties['ENER-TARV_E_TLEV'];
            if (propEnerTarvType && propEnerTarvETlev) {
                for (const code of propEnerTarvType.Domain.Codes) {
                    const id = ntaEntityDataOrg.create('ENER-TARV');
                    const entdata = ntaEntityDataOrg.get(id);
                    saveValue(propEnerTarvType, entdata, code.Id);

                    //bij vaste kosten per jaar (vastrecht e.d.) mag e-teruglevering op 'n.v.t.' gezet worden want daarvoor is geen vastrecht tarief
                    if (propEnerTarvType.getValue(entdata) === 'ENER-TARV_TYPE_VK') {
                        saveValue(propEnerTarvETlev, entdata, 'n.v.t.');
                    }
                }
            }
            _energietarieven = ntaEntityDataOrg.getListWithEntityId('ENER-TARV');
        }


        //== Implementation =======================================================================

        function getMinStartDate(stationId = null) {
            // [D025] MIN januari 2020
            return _minStartDate;
        } //-- end: getMinDate --------------------------------------------------------------------

        function getMaxStartDate(stationId = null) {
            stationId = stationId || _entdataClimate.PropertyDatas['CLIMATE_KNMI_STATION'].Value;

            let maxDate = _maxStartDateByStationId.get(stationId);
            if (!maxDate) {
                // [D025] MAX is meest recente maand van het weerstation gekozen in ER31 in tabel
                //  'klimaatgegevens alle weerstations vanaf 2020' minus 12 maanden.
                //  (vb: als oktober 2023 in de tabel staat mag september 2022 als nieuwste maand worden gekozen)
                let maxTime = Date.parse(ntaData.maxClimateDateByStationId[stationId]);
                if (isNaN(maxTime)) {
                    maxTime = Object.values(ntaData.maxClimateDateByStationId)
                        .map(mcd => Date.parse(mcd))
                        .filter(time => !isNaN(time))
                        .reduce((max, time) => max < time ? time : max, 0);
                    if (maxTime === 0) {
                        const date = new Date();
                        date.setMonth(date.getMonth() - 14); // terugvallen op 14 maanden geleden.
                        maxTime = date.getTime();
                    }
                }
                maxDate = new Date(maxTime);
                maxDate.setDate(27); // De 27e is de laatste dag die 100% gegarandeerd binnen deze maand valt (welke maand dat ook is), rekening houdend met time zone offsets.

                _maxStartDateByStationId.set(stationId, maxDate);
            }
            return maxDate;
        } //-- end: getMaxDate --------------------------------------------------------------------

        function getVerbruiken() {
            return _verbruiken.filter(x => conditionCD(x));
        } //-- end: getVerbruiken -----------------------------------------------------------------

        function getEnergietarieven() {
            return _energietarieven;
        } //-- end: getEnergietarieven -----------------------------------------------------------------

        function isItemChecked(prop, entdata, item) {
            if (!prop || !entdata || !item)
                return false;
            const valuesSet = new Set(String(prop.getValue(entdata)).split('|'));
            return valuesSet.has(item.Id);
        } //-- end: isItemChecked -----------------------------------------------------------------

        function toggleItemChecked(prop, entdata, item) {
            let value = prop.getValue(entdata);
            let newValue = isItemChecked(prop, entdata, item) ? value.replace(item.Id + '|', '') : value ? value + item.Id + '|' : item.Id + '|';

            // Values opzoeken die niet in de CodesValues staan en deze verwijderen.
            //-> Gaat meestal om values die zonder pipe '|' zijn opgenomen. Dit komt waarschijnlijk omdat bij het initialiseren er geen pipe '|' is toegevoegd achter aan de defaultwaarde...
            const newValueCheck = newValue.split("|");
            const wrongItems = newValueCheck.filter(x => !prop.Domain.Codes.map(code => code.Id).includes(x)).filter(x => x);
            for (const wrongItem of wrongItems) {
                newValue = newValue.replace(wrongItem + '|', '');
            }

            // [ER-S]
            // Indien e-teruglevering zichtbaar is en e-levering is aangevinkt dan moet e-teruglevering ook aan; wordt e-teruglevering uitgevinkt dan moet ook e-levering uitgevinkt worden (koppel de vinkjes).
            // Indien e-opwekking zichtbaar is en e-opwekking is aangevinkt dan moet e-teruglevering ook aan; wordt e-teruglevering uitgevinkt dan moet ook e-opwekking uitgevinkt worden (koppel de vinkjes).
            // e-levering	    aan	->	e-teruglevering	aan
            // e-teruglevering  aan	->	e-levering	    aan
            // e-opwekking	    aan	->	e-teruglevering	aan
            //		                ->	e-levering	    aan

            // e-levering	    uit	->	e-teruglevering	uit
            //                      ->	e-opwekking	    uit
            // e-teruglevering	uit	->	e-levering	    uit
            //                      ->	e-opwekking	    uit
            //e-opwekking	    uit	->	doe niks

            const newValueSet = new Set(newValue.split("|"));
            const lev = 'ENER-REK_VERBRAANW_E_LEV';
            const tlev = 'ENER-REK_VERBRAANW_E_TLEV';
            const opwek = 'ENER-REK_VERBRAANW_E_OPWEK';
            if (item.Id === lev && newValueSet.has(lev) && !newValueSet.has(tlev)) newValue = newValue + tlev + '|';
            if (item.Id === tlev && newValueSet.has(tlev) && !newValueSet.has(lev)) newValue = newValue + lev + '|';
            if (item.Id === opwek && newValueSet.has(opwek) && !newValueSet.has(lev)) newValue = newValue + lev + '|';
            if (item.Id === opwek && newValueSet.has(opwek) && !newValueSet.has(tlev)) newValue = newValue + tlev + '|';

            if (item.Id === lev && !newValueSet.has(lev) && newValueSet.has(tlev)) newValue = newValue.replace(tlev + '|', '');
            if (item.Id === lev && !newValueSet.has(lev) && newValueSet.has(opwek)) newValue = newValue.replace(opwek + '|', '');
            if (item.Id === tlev && !newValueSet.has(tlev) && newValueSet.has(lev)) newValue = newValue.replace(lev + '|', '');
            if (item.Id === tlev && !newValueSet.has(tlev) && newValueSet.has(opwek)) newValue = newValue.replace(opwek + '|', '');

            let propdata = prop.getData(entdata);
            if (propdata.Value !== newValue) {
                propdata.Value = newValue;
                saveValue(prop, entdata);
            }
        } //-- end: toggleItemChecked -------------------------------------------------------------

        function isChecked(drager) {
            const prop = ntaData.properties['ENER-REK_VERBRAANW'];
            const valuesSet = new Set(String(prop.getValue(_entdataEnergierekening)).split('|'));
            return valuesSet.has('ENER-REK_VERBRAANW_' + drager);
        } //-- end: isChecked -----------------------------------------------------------------

        function getColCount() {
            const prop = ntaData.properties['ENER-REK_VERBRAANW'];
            const valuesSet = new Set(String(prop.getValue(_entdataEnergierekening)).split('|'));
            return valuesSet.size + 1;
        } //-- end: getColCount -----------------------------------------------------------------

        function showTableVerbruik() {
            return conditionM();
        } //-- end: showTableVerbruik -----------------------------------------------------------

        function isTotalVerbruikVisible() {
            return _entdataEnergierekening.PropertyDatas['ENER-REK_DOEL'].Value === 'ENER-REK_DOEL1';
        } //-- end: isTotalVerbruikVisible -----------------------------------------------------------

        function getSumOfVerbruik(prop) {
            if (prop.Id === 'VERBR_VERBR_MJ') {
                return ' totaal verbruik';
            }
            const totaal = ntaRounding.roundAndAddZerosNewValue(prop, getVerbruiken().reduce((totaal, verbr) => totaal + (ntaSharedLogic.parseFloat(prop.getValue(verbr)) || 0), 0));
            return totaal || 0;
        } //-- end: getSumOfVerbruik -----------------------------------------------------------

        function sortVerbrTypes(startIndex) {
            const propVerbrType = ntaData.properties['VERBR_VERBR_MJ'];
            const codes = propVerbrType.Domain.Codes.filter(x => x.Id !== 'VERBR_MJ_JR'); //jaar er uit filteren
            let months = codes.sort((a, b) => a.CalcValue - b.CalcValue ).map(x => x.Id);
            const sortedMonths = months.slice(startIndex).concat(months.slice(0, startIndex));
            _verbruiken.sort((a, b) => sortedMonths.indexOf(propVerbrType.getValue(a)) - sortedMonths.indexOf(propVerbrType.getValue(b)));
            ntaEntityDataOrg.SaveReOrder(_verbruiken);
        } //-- end: sortMonths ------------------------------------------------------------------

        function getPropData(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            switch (prop.Id) {
                case 'VERBR_VERBR_MJ': {
                    return ntaSharedLogic.getVerbruikMonthAndYear(entdata, _entdataEnergierekening);
               }
            }

            const propdata = ntaSharedLogic.getPropData(prop, entdata);
            return propdata;
        } //-- end: getPropData -------------------------------------------------------------------

        function isReadOnly(prop, entdata) {
            if (!prop)
                return true;

            switch (prop.Id) {
                case 'CLIMATE_KNMI_STATION': {
                    return conditionAL();
                }
                case 'ENER-TARV_TYPE': {
                    return true;
                }
                case 'ENER-TARV_E_TLEV': {
                    return entdata.PropertyDatas['ENER-TARV_TYPE'].Value === 'ENER-TARV_TYPE_VK';
                }
                default:
                    if (prop.Id.endsWith('_NON')) {
                        return true;
                    }
                    break;
            }

            return false;
        } //-- end: isReadOnly --------------------------------------------------------------------

        function isHidden(prop, entdata) {
            if (typeof prop === 'string') prop = ntaData.properties[prop];
            if (!prop) {
                return true;
            }

            // LET OP: als entdata === null, dan gaat het om de hele kolom
            const propdata = entdata && getPropData(prop, entdata);
            if (entdata && !propdata) {
                return true;
            }

            let visible = true;
            let relevant = null; // null = zelfde als visible

            if (!ntaSharedLogic.showCosts()) {
                visible = false;
            } else {
                switch (prop.Id) {
                    case 'ENER-REK_VERBRAANW':
                    case 'ENER-REK_EERSTEMAAND': {
                        visible = conditionM();
                        break;
                    }
                    case 'ENER-REK_OPEN': {
                        visible = false;
                        break;
                    }
                    case 'VERBR_GAS':
                    case 'VERBR_E_LEV':
                    case 'VERBR_E_TLEV':
                    case 'VERBR_E_OPWEK':
                    case 'VERBR_E_VERBR_BTSCHIL':
                    case 'VERBR_OLIE':
                    case 'VERBR_BIOM':
                    case 'VERBR_W':
                    case 'VERBR_K': {
                        visible = conditionA(prop.Id) && conditionM() && isChecked(prop.Id.split("VERBR_").pop());
                        break;
                    }
                    case 'VERBR_E_OPWEK_GELIJK_NON': {
                        visible = conditionA(prop.Id) && conditionG() && conditionM();
                        break;
                    }
                    case 'VERBR_E_VERBR_BTSCHIL': {
                        visible = conditionA(prop.Id) && conditionE() && conditionM();
                        break;
                    }
                    case 'VERBR-OVERIG_E_OPW_NON': {
                        visible = conditionG();
                        break;
                    }
                    case 'VERBR-OVERIG_E_OPW': {
                        visible = conditionH();
                        //als VERBR-OVERIG_E_OPW_NON niet visible is, moet VERBR-OVERIG_E_OPW relevant zijn en de defaultwaarde doorgeven aan de rekenkern
                        relevant = visible || !conditionG();
                        break;
                    }
                    case 'VERBR-OVERIG_KOKEN_GAS': {
                        visible = !ntaSharedLogic.isUtiliteit() && conditionF();
                        break;
                    }
                    case 'ENER-TARV_GAS':
                    case 'ENER-TARV_E_LEV':
                    case 'ENER-TARV_E_TLEV':
                    case 'ENER-TARV_E_TLEVK':
                    case 'ENER-TARV_OLIE':
                    case 'ENER-TARV_BIOM':
                    case 'ENER-TARV_W':
                    case 'ENER-TARV_K': {
                        visible = conditionA(prop.Id);
                        break;
                    }
                    case 'ENER-TARV_E_OPWEK': { // formulieren-maatwerk (cell G37): De energiedrager 'e-opwekking omvormer' uit [ER-A] worden niet getoond.
                        visible = false;
                        break;
                    }
                    case 'CLIMATE_POSTCODE': {         // ER29
                        visible = conditionAL();
                        break;
                    }
                    case 'TARV_SALDERING': {
                        //[ER-Q] toon alleen als er in de basisberekening of variantberekenning sprake is van electriciteitsopwekking.
                        //Zie voor aanwezigheid van e-opwekking conditie[ER-A] beschrijiving 'e-opwekking (omvormer)'
                        visible = conditionA('ENER-TARV_E_OPWEK');
                        break;
                    }
                    case 'VERBR_E_OPGEWEKT_NON':
                    case 'VERBR_WARMTE_NON': {
                        visible = false;
                        break;
                    }
                }

                if (prop.Id.startsWith('VERBR_NIET_REPRES') || prop.Id.startsWith('VERBR_IN_STOOKSEIZOEN') || prop.Id.startsWith('VERBR_BTN_STOOKSEIZOEN') || prop.Id.startsWith('VERBR_FITTED') ) {
                    visible = false;
                }
            }

            if (propdata) {
                if (relevant === null) relevant = visible;
                ntaEntityDataOrg.setPropdataStatus(propdata, relevant, visible, false);
            }
            return !visible;
        } //-- end: isHidden ----------------------------------------------------------------------

        function conditionA(propId) {
            let drager;
            switch (propId) {
                case 'ENER-TARV_GAS':
                case 'VERBR_GAS':
                case 'ENER-REK_VERBRAANW_GAS': {
                    drager = 'GAS';
                    break;
                }
                case 'ENER-TARV_E_OPWEK':
                case 'ENER-TARV_E_TLEV':
                case 'ENER-TARV_E_TLEVK':
                case 'VERBR_E_OPWEK':
                case 'VERBR_E_TLEV':
                case 'ENER-REK_VERBRAANW_E_OPWEK':
                case 'ENER-REK_VERBRAANW_E_TLEV':
                case 'VERBR-OVERIG_E_OPW': {
                    drager = 'E';
                    break;
                }
                case 'ENER-TARV_OLIE':
                case 'VERBR_OLIE':
                case 'ENER-REK_VERBRAANW_OLIE': {
                    drager = 'OLIE';
                    break;
                }
                case 'ENER-TARV_BIOM':
                case 'VERBR_BIOM':
                case 'ENER-REK_VERBRAANW_BIOM': {
                    drager = 'BIOM';
                    break;
                }
                case 'ENER-TARV_W':
                case 'VERBR_W':
                case 'ENER-REK_VERBRAANW_W': {
                    drager = 'W';
                    break;
                }
                case 'ENER-TARV_K':
                case 'VERBR_K':
                case 'ENER-REK_VERBRAANW_K': {
                    drager = 'K';
                    break;
                }
                default:
                    return true;
            }

            const aanwezig = _dragerAanwezig.get(drager);
            if (aanwezig) {
                return propId.startsWith('ENER-TARV')
                    ? aanwezig.tarieven
                    : aanwezig.verbruiken;
            }

            return true;
        } //-- end: conditionA ----------------------------------------------------------------------

        function conditionAL() {
            // [MW-AL] toon indien MW80 = weerstation a.d.h.v. postcode opzoeken
            return _entdataClimate.PropertyDatas['CLIMATE_KNMI_INV'].Value === 'KNMI_INV_POSTCODE'; // MW80
        } //-- end: conditionAL -------------------------------------------------------------------

        function conditionB() {
            // [ER-B] indien berekening WB/WN toon 'inclusief BTW'; indien berekening UB/UN toon 'exclusief BTW'
            return ntaSharedLogic.isUtiliteit() ? 'exclusief BTW' : 'inclusief BTW';
        } //-- end: conditionB -------------------------------------------------------------------

        function conditionCD(entdata) {
            //[ER-C] toon als ER02 = energierekening per maand gebruiken voor fitten
            //[ER-D] toon als ER02 = energierekening per jaar gebruiken voor fitten
            if (!entdata) return true;

            const doelProp = ntaData.properties['ENER-REK_DOEL'];
            const verbrMJProp = ntaData.properties['VERBR_VERBR_MJ'];
            const doelValue = doelProp.getValue(_entdataEnergierekening);
            const mjValue = verbrMJProp.getValue(entdata);
            const visible = doelValue === 'ENER-REK_DOEL1' && mjValue !== 'VERBR_MJ_JR' ||
                doelValue === 'ENER-REK_DOEL2' && mjValue === 'VERBR_MJ_JR';

            ntaEntityDataOrg.setEntityRelevancy(entdata, visible);
            return visible;
        } //-- end: conditionCD -------------------------------------------------------------------

        function conditionE() {
            //[ER-E] toon als MW08 (GEBR-PROFIEL_INTWARMTE) / MW10 (GEBR-PROFIEL_INTWARMTE_APP) = warmtelast o.b.v. energiegebruik (PROFIEL_WARMTE_OBV_GEBR)
            const gebruikersprofiel = ntaEntityDataOrg.getFirstWithEntityId("GEBR-PROFIEL");
            const propIntW = ntaData.properties['GEBR-PROFIEL_INTWARMTE'];
            const propIntWApp = ntaData.properties['GEBR-PROFIEL_INTWARMTE_APP'];
            const propdataIntW = propIntW.getData(gebruikersprofiel);
            const propdataIntWApp = propIntWApp.getData(gebruikersprofiel);
            return (propdataIntW.Relevant && propdataIntW.Value === 'PROFIEL_WARMTE_OBV_GEBR') ||
                (propdataIntWApp.Relevant && propdataIntWApp.Value === 'PROFIEL_WARMTE_OBV_GEBR');
        } //-- end: conditionE -------------------------------------------------------------------

        function conditionF() {
            //[ER-F]  toon alleen als ER06 voor de energiedrager gas is aangevinkt EN ER06 is zichtbaar
            return conditionM() && conditionA('VERBR_GAS') && isChecked('GAS');
        } //-- end: conditionF -------------------------------------------------------------------

        function conditionG() {
            //[ER-G] toon indien in tabel ER04 zowel de energiedrager e-levering ALS e-teruglevering ALS e-opwekking (omvormer) zijn aangevinkt.
            const prop = ntaData.properties['RESULT_KARAKT_OPGEW_E'];
            return conditionM() && isChecked('E_LEV') && conditionA('VERBR_E_TLEV') && isChecked('E_TLEV') && conditionA('VERBR_E_OPWEK') && isChecked('E_OPWEK');
        } //-- end: conditionG -------------------------------------------------------------------

        function conditionH() {
            //[ER-H] toon indien formulier energieprestatie SA40 > 0 EN ER24 verborgen
            return conditionM() && conditionA('VERBR-OVERIG_E_OPW') && !conditionG();
        } //-- end: conditionH -------------------------------------------------------------------

        function conditionM() {
            //[ER-M] toon tabel/veld als ER02 = energierekening per maand gebruiken voor fitten / energierekening per jaar gebruiken voor fitten
            const doelProp = ntaData.properties['ENER-REK_DOEL'];
            return doelProp.getValue(_entdataEnergierekening) !== 'ENER-REK_DOEL3';
        } //-- end: conditionM -------------------------------------------------------------------

        function conditionP(entdata) {
            //[ER-P] Als zowel de kolom e-teruglevering als de kolom e-opwekking getoond wordt,
            //controleer dan of de ingevoerde waarde in e-opwekking ≥ e-teruglevering.
            //Controle per regel op het moment dat beide velden op deze regel zijn ingevoerd.
            //Indien niet aan de voorwaarde wordt voldaan toon bij de desbetreffende rij[E121] in kolom 'e-opwekking'.
            const propdataTLEV = entdata.PropertyDatas['VERBR_E_TLEV'];
            const propdataOPWEK = entdata.PropertyDatas['VERBR_E_OPWEK'];
            const valueTLEV = ntaSharedLogic.parseFloat(propdataTLEV.Value);
            const valueOPWEK = ntaSharedLogic.parseFloat(propdataOPWEK.Value);

            const valid = (!propdataTLEV.Relevant || !propdataOPWEK.Relevant || isNaN(valueTLEV) || isNaN(valueOPWEK)) || valueOPWEK >= valueTLEV;
            ntaSharedLogic.setMelding('[E121]', propdataOPWEK, self.form, valid);
            return valid;
        } //-- end: conditionP -------------------------------------------------------------------

        function hasCodedValues(prop) {
            return ntaValidation.hasCodedValues(prop);
        } //-- end: hasCodedValues ----------------------------------------------------------------

        function getCodedValues(prop) {
            if (!prop ) {
                return [];
            }

            let codedValues = ntaValidation.codedValues(prop);

            switch (prop.Id) {
                case 'ENER-REK_DOEL': {
                    //[ER-L]  verbergen totdat we detailfit implementeren - deze conditie hebben we nodig voor productie, niet in test implementeren
                    //codedValues = codedValues.filter(cv => cv.Id !== 'ENER-REK_DOEL1');
                    break;
                }
                case 'ENER-REK_VERBRAANW': {
                    //[ER-A]
                    codedValues = codedValues.filter(cv => conditionA(cv.Id));
                    break;
                }
            }

            return codedValues;
        } //-- end: getCodedValues ----------------------------------------------------------------

        function saveValue(propOrId, entdata, newValue) {
            const prop = typeof propOrId === 'string' ? ntaData.properties[propOrId] : propOrId;
            return ntaSharedLogic.saveValue(prop, entdata, newValue, self, ntaEntityDataOrg);
        } //-- end: saveValue ---------------------------------------------------------------------

        function validate(prop, propdata, entdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            const hidden = isHidden(prop, entdata);
            const readOnly = isReadOnly(prop, entdata);

            let valid = ntaValidation.IsValid(self.form, prop, propdata, hidden, readOnly);

            switch (prop.Id) {
                case 'ENER-REK_EERSTEMAAND': {
                    if (valid && !hidden) {
                        const date = new Date(propdata.Value);
                        valid = !isNaN(date.getTime())
                            && getMinStartDate() <= date
                            && date < getMaxStartDate();
                        ntaSharedLogic.setMelding('[D009]', propdata, self.form, valid);
                    }
                    break;
                }
                case 'VERBR-OVERIG_E_OPW': {
                    //als er geen energiedragers e-teruglevering of e-opwekking (omvormer) zijn, of als de propdata nog nooit is ingevuld, moet VERBR-OVERIG_E_OPW de defaultwaarde doorgeven aan de rekenkern
                    if (!conditionA(prop.Id) || (!propdata.Touched && !propdata.Value)) {
                        saveValue('VERBR-OVERIG_E_OPW', entdata, ntaSharedLogic.isUtiliteit() ? '0,50' : '0,30');
                    }
                    break;
                }
                case 'VERBR_E_TLEV':
                case 'VERBR_E_OPWEK': {
                    valid = valid && conditionP(entdata);
                    break;
                }
            }

            return valid;
        } //-- end: validate ----------------------------------------------------------------------

        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            switch (prop.Id) {
                case 'ENER-REK_EERSTEMAAND': {
                    const value = prop.getValue(entdata);
                    const date = new Date(value);
                    sortVerbrTypes(date.getMonth());
                    break;
                }
                case 'ENER-REK_VERBRAANW': {
                    const verbruiken = getVerbruiken();
                    for (const checkedProp of prop.Domain.Codes.map(x => x.FilterValue3)) {
                        for (const verbruik of verbruiken) {
                            isHidden('VERBR_' + checkedProp, verbruik); // set relevancy
                        }
                    }
                    time.delayActions(() => {
                        const opwekProp = ntaData.properties['VERBR_E_OPWEK'];
                        for (const verbruik of verbruiken) {
                            const propdata = opwekProp.getData(verbruik);
                            if (propdata.Touched) {
                                validate(opwekProp, propdata, verbruik);
                            }
                        }
                    }, 100);
                    break;
                }
                case 'VERBR_E_OPWEK':
                case 'VERBR_E_TLEV': {
                    updateOpwekGelijk(entdata);
                    break;
                }
                case 'ENER-REK_DOEL':
                case 'VERBR_E_OPWEK_GELIJK_NON':{
                    updatefractieOpwekVerbr();
                    break;
                }
                case 'CLIMATE_POSTCODE': {
                    let value = prop.DefaultValue;

                    const postcode = parseInt(prop.getValue(entdata));
                    if (!isNaN(postcode)) {
                        const range = _postcodeStations.find(x => x.from <= postcode && postcode <= x.till);
                        value = range && range.id || value;
                    }

                    saveValue('CLIMATE_KNMI_STATION', entdata, value); // MW73
                    break;
                }
                case 'CLIMATE_KNMI_STATION': {
                    const propFirstMonth = ntaData.properties['ENER-REK_EERSTEMAAND'];
                    validate(propFirstMonth, propFirstMonth.getData(self.entdataEnergierekening), self.entdataEnergierekening);
                    break;
                }
            }
        } //-- end: validateDependencies ----------------------------------------------------------

        function updateOpwekGelijk(entdata) {
            //e-opwekking (omvormer) - e-teruglevering
            const opwekProp = ntaData.properties['VERBR_E_OPWEK'];
            const tlevProp = ntaData.properties['VERBR_E_TLEV'];
            const value = ntaSharedLogic.parseFloat(opwekProp.getValue(entdata)) - ntaSharedLogic.parseFloat(tlevProp.getValue(entdata));
            saveValue('VERBR_E_OPWEK_GELIJK_NON', entdata, value);
        } //-- end: updateOpwekGelijk -------------------------------------------------------------

        function updatefractieOpwekVerbr() {
            //non-editable field; ∑mi 'e-opwekking gelijk verbruikt' (ER26) / ∑mi 'e-opwekking'
            const opwekProp = ntaData.properties['VERBR_E_OPWEK'];
            const opwekGelijkProp = ntaData.properties['VERBR_E_OPWEK_GELIJK_NON'];
            const verbruiken = getVerbruiken();
            const opwekValues = verbruiken.map(verbr => opwekProp.getValue(verbr));
            const opwekGelijkValues = verbruiken.map(verbr => opwekGelijkProp.getValue(verbr));
            const opwekSum = opwekValues.reduce((sum, value) => sum + ntaSharedLogic.parseFloat(value, 0), 0);
            const opwekGelijkSum = opwekGelijkValues.reduce((sum, value) => sum + ntaSharedLogic.parseFloat(value, 0), 0);
            const value = opwekSum > 0 ? opwekGelijkSum / opwekSum : 0;
            saveValue('VERBR-OVERIG_E_OPW_NON', _entdataVerbruikOverig, value);
        } //-- end: updatefractieOpwekVerbr -------------------------------------------------------

        function startFormValidation() {
            return ntaSharedLogic.startFormValidation(getAllEntDatas(), self);
        } //-- end: startFormValidation -----------------------------------------------------------

        function endFormValidation() {
            return ntaSharedLogic.endFormValidation(getAllEntDatas(), self);
        } //-- end: endFormValidation -------------------------------------------------------------

        function getAllEntDatas() {
            // de entiteiten hoeven alleen gevalideerd te worden in de basisberekening [2q8gkDYc]
            if (!_entdataEnergierekening || ntaData.current.shadowId) {
                return [];
            } else {
                return [
                    _entdataEnergierekening,
                    _entdataVerbruikOverig,
                    _entdataTarieven,
                    _entdataTarievenOverig,
                    _entdataClimate,
                ]
                    .concat(getVerbruiken())
                    .concat(getEnergietarieven())
                    .filter(ed => ed);
            }
        } //-- end: getAllEntDatas ----------------------------------------------------------------

        function setGeopend() {
            const propdataOpen = _entdataEnergierekening.PropertyDatas['ENER-REK_OPEN'];
            ntaEntityDataOrg.saveprop(propdataOpen, 'true');
        } //-- end: setGeopend --------------------------------------------------------------------

    };
}]);
