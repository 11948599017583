﻿angular.module('projectModule')
    .controller("InstallationKoelingController",
        ['$scope', '$controller', 'ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaSharedLogic', '$routeParams', '$mdDialog', 'progressCircle', 'ntaMeldingen', 'ntaDependencyValidation', 'ntaMeasure', 'ListCache', 'KoelingFactory',
function ($scope,   $controller,   ntabuilding,   ntaValidation,   ntaEntityData,   ntaSharedLogic,   $routeParams,   $mdDialog,   progressCircle,   ntaMeldingen,   ntaDependencyValidation,   ntaMeasure,   ListCache,   KoelingFactory) {
    'use strict';
    const vm = this;
    const base = $controller('NTABaseController', { $scope: $scope })
    angular.extend(vm, base);

    vm.ntaValidation = ntaValidation;
    vm.hasRelation = ntaSharedLogic.hasRelation;
    vm.installId = $routeParams.id3.toLowerCase();
    vm.preventBackspace = ntaSharedLogic.preventBackspace;

    const koelingLogic = new KoelingFactory(vm.installId, ntaDependencyValidation);
    vm.koelingLogic = koelingLogic;

    // Properties
    vm.propOpmerkingen = koelingLogic.propOpmerkingen;
    vm.koelOpwekkerProperties = koelingLogic.koelOpwekkerProperties;
    vm.koelDistributieProperties = koelingLogic.koelDistributieProperties;
    vm.koelDistributiePompProperties = koelingLogic.koelDistributiePompProperties;
    vm.koelAfgifteProperties = koelingLogic.koelAfgifteProperties;

    vm.getKoelAfgifteVentilatorenProperties = function (afgifte) {
        return koelingLogic.koelAfgifteVentilatorenProperties.filter(prop => !vm.propertyHeaderIsHidden(prop, afgifte));
    };

    // Table10_0
    vm.opwekkerTypes = koelingLogic.opwekkerTypes;

    // Table10_2
    vm.ruimteTemperatuurRegelingTypes = koelingLogic.ruimteTemperatuurRegelingTypes;


    // Koeling id
    vm.koelDataId = koelingLogic.koelDataId;
    vm.koelData = koelingLogic.koelData;

    var setKoelOpwekkers = koelingLogic.setKoelOpwekkers;

    // Opwekking properties en entiteiten
    vm.koelOpwekkersData = function () {
        return koelingLogic.koelOpwekkersData;
    };

    vm.opwekkerNr = koelingLogic.opwekkerNr;

    vm.opwekkerNaam = function (opwekker) {
        let nummer = vm.opwekkerNr(opwekker);
        let naam = 'Opwekker ' + nummer;
        return naam;
    };

    // Distributie
    vm.koelDistributieData = function () {
        return koelingLogic.koelDistributieData;
    };

    // Distributie binnen
    vm.koelDistributieBinnenProperties = koelingLogic.koelDistributieBinnenProperties;
    vm.koelDistributieBinnenData = function () {
        return koelingLogic.koelDistributieBinnenData;
    };

    // Distributie buiten
    vm.koelDistributieBuitenProperties = koelingLogic.koelDistributieBuitenProperties;
    vm.koelDistributieBuitenData = function () {
        return koelingLogic.koelDistributieBuitenData;
    };

    // Distributie leidingen
    vm.koelDistributieEigenschappenLeidingenProperties = koelingLogic.koelDistributieEigenschappenLeidingenProperties;
    vm.koelDistributieEigenschappenLeidingenData = function () {
        return koelingLogic.koelDistributieEigenschappenLeidingenData;
    };

    // Distributie pomp
    vm.koelDistributiePompData = function () {
        return koelingLogic.koelDistributiePompData;
    };

    // Type afgifteystemen
    vm.afgifteTypes = koelingLogic.afgifteTypes;

    // Koeling-afgifte relaties
    var setKoelAfgiftes = koelingLogic.setKoelAfgiftes;

    // Afgifte
    vm.koelAfgifteData = function () {
        return koelingLogic.koelAfgifteData;
    };

    vm.rekenzones = function () {
        return koelingLogic.rekenzones;
    };

    vm.getAfgifteRekenzones = koelingLogic.getAfgifteRekenzones;
    vm.showAfgifteRekenzones = showAfgifteRekenzones;
    vm.getAfgifteVentilatoren = koelingLogic.getAfgifteVentilatoren;
    vm.getAfgifteRekenzoneVentilatoren = getAfgifteRekenzoneVentilatoren;
    vm.conditieKDAN = koelingLogic.conditieKDAN;

    const _listCache = new ListCache();

    function getAfgifteRekenzoneVentilatoren(afgifte) {
        const ventModels = [];
        for (const rekenzone of vm.getAfgifteRekenzones(afgifte)) {
            const ventilatoren = vm.getAfgifteVentilatoren(afgifte, rekenzone);
            for (let i = 0; i < ventilatoren.length; i++) {
                ventModels.push({
                    rekenzone,
                    ventilator: ventilatoren[i],
                    ventilatorIndex: i,
                    ventilatorCount: ventilatoren.length,
                });
            }
        }

        return _listCache.useCacheIfUnchanged(afgifte.EntityDataId, ventModels, (a, b) => a.ventilator === b.ventilator && a.rekenzone === b.rekenzone);
    } //-- end: getAfgifteRekenzoneVentilatoren ------------------------------------------------------------//



    // Afgiftesysteem nummer
    vm.afgifteNr = koelingLogic.afgifteNr;

    vm.afgifteNaam = function (afgifte) {
        let nummer = vm.afgifteNr(afgifte);
        let naam = 'Afgiftesysteem ' + nummer;

        return naam;
    };

    vm.getTitle = () => ntaSharedLogic.isEditingMeasure() ? 'Maatregel: wijzigen koeling' : 'Koeling';

    /////////////////////////////////SELECTIONTABLE//////////////////////////////////////////////////////////////////////////////////////////////
    vm.getSelectionTable = koelingLogic.getSelectionTable;
    vm.saveSelectionTableValue = koelingLogic.saveSelectionTableValue;

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// FORM ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    vm.getForm = function () {
        return koelingLogic.form_installationkoeling;
    };

    var startFormValidation = function () {
        koelingLogic.startFormValidation();

    } //-- end: startFormValidation ------------------------------------------------------------------------//

    $scope.endFormValidation = function () {
        koelingLogic.endFormValidation();
    };

    ntaMeasure.setBtwPropIds(vm);

    angular.element(function () { //alle touched properties valideren na het laden van form
        startFormValidation();
        base.initializeUnitSpacing();
    }); //-- end: endFormValidation ------------------------------------------------------------------------//



    /////////////////////////////////////////////////////////////////////////////////////////////// PROPERTIES /////////////////////////////////////////////////////////////////////////////////////////////////////////
    vm.saveValue = function (prop, entdata) {
        koelingLogic.saveValue(prop, entdata);
        base.initializeUnitSpacing();
    };
    var validate = koelingLogic.validate;

    vm.isSelectionTableDisabled = function (prop, entdata) {
        if (!prop || !entdata) {
            return true;
        }

        // de selectie tabel mag niet gekozen worden als er maar 1 item in de filterlijst zit tenzij propdata.Value == null
        let propdataValue = entdata.PropertyDatas[prop.Id];
        if (propdataValue) {
            if (prop.Id === 'KOEL-OPWEK_TOEW') {
                return entdata.filteredItems.Singleresult;
            } else if (prop.Id === 'KOEL-OPWEK_REGIO') {
                return entdata.filteredItemsRegios.Singleresult;
            }
        }

        return false;
    } //-- end: isSelectionTableDisabled ------------------------------------------------------------------------//

    vm.isReadOnly = koelingLogic.isReadOnly;
    vm.getCodedValues = koelingLogic.getCodedValues;

    vm.getName = function (prop) {
        let name = base.getName(prop);

        // Conditie [KDAC]
        if (prop.Id === 'KOEL-DISTR_POMP_INV') {
            const propAanvullendePomp = koelingLogic.koelDistributieProperties['KOEL-DISTR_AANV_POMP'];
            const propdataAanvullendePomp = propAanvullendePomp.getData(koelingLogic.koelDistributieData);
            if (!koelingLogic.isHidden(propAanvullendePomp, koelingLogic.koelDistributieData) && propdataAanvullendePomp.Value === 'KOEL-DISTR_AANV_POMP_WEL') {
                name = 'aanvullende ' + name;
            }
        }

        if (name && prop.EntityId === "KOEL-DISTR-EIG") {
            const index = name.lastIndexOf('[');
            if (index > -1) {
                name = name.substring(0, index) + '<br>' + name.substring(index);
            }
        }

        return name || '';
    };

    vm.getCodedValueName = function (item, entdata = null) {
        // Conditie [KOAP]
        if (item && entdata && entdata.EntityId === 'KOEL-OPWEK') {
            const opwekkerTypeValue = entdata.PropertyDatas['KOEL-OPWEK_TYPE'].Value;
            if (opwekkerTypeValue === 'KOEL-OPWEK_TYPE_7') {
                if (item.Id === 'KOEL-OPWEK_GEM_NIET') {
                    return 'installatie met individuele aflevering';
                } else if (item.Id === 'KOEL-OPWEK_GEM_WEL') {
                    return 'installatie met centrale aflevering';
                }
            }
        }

        return base.getCodedValueName(item, entdata);
    };

    vm.getPropData = koelingLogic.getPropData;

    vm.getProduct = koelingLogic.getProduct;

    vm.getPropertyType = function (prop) {
        if (!prop) {
            return;
        }
        return prop.PropertyType;
    } //-- end: getPropertyType ------------------------------------------------------------------------//

    vm.propertyHeaderIsHidden = koelingLogic.propertyHeaderIsHidden;
    vm.isHidden = koelingLogic.isHidden;
    vm.leidingenIsHidden = koelingLogic.leidingenIsHidden;
    vm.eigenschappenLeidingenRowIsHidden = koelingLogic.eigenschappenLeidingenRowIsHidden;
    vm.eigenschappenDistributieLeidingenTabelIsHidden = koelingLogic.eigenschappenDistributieLeidingenTabelIsHidden;

    vm.distributiepompTabelIsHidden = koelingLogic.distributiepompTabelIsHidden;
    vm.distributiepompTabelNaam = koelingLogic.distributiepompTabelNaam;
    vm.allowMultiplePumps = koelingLogic.allowMultiplePumps;
    vm.pompIsHidden = koelingLogic.pompIsHidden;
    vm.distributiepompTabelWidth = koelingLogic.distributiepompTabelWidth;

    vm.getRekenzoneName = function (rz) {
        var name = rz.PropertyDatas["RZ_OMSCHR"].Value; //omschrijving
        return name;
    } //-- end: getRekenzoneName ------------------------------------------------------------------------//

    vm.opwekkerButtonIsHidden = function () { // Conditie KOZ
        //let showIt = true;

        let eersteOpwekkerData = vm.koelOpwekkersData()[0];
        let eersteOpwekkerType = eersteOpwekkerData.PropertyDatas['KOEL-OPWEK_TYPE'].Value;
        let eersteDistribuVerdamp = vm.koelDistributieData().PropertyDatas['KOEL-DISTR_VERDAMP'].Value;

        if (eersteOpwekkerType && eersteDistribuVerdamp) {
            let eesteOpwekker = vm.opwekkerTypes.find(function (x) { return x.Id === eersteOpwekkerType; });

            if (eesteOpwekker.key2 === 'ONB' || eersteDistribuVerdamp !== 'KOEL-DISTR_VERDAMP_3') {
                deleteOpwekkersOverOne();
                return true;
                //showIt = false;
            }

            if (vm.koelOpwekkersData().length >= 2) {
                let eersteOpwekkerInvoer = eersteOpwekkerData.PropertyDatas['KOEL-OPWEK_INVOER'].Value;
                if (eersteOpwekkerInvoer && (eersteOpwekkerInvoer === 'VERW-OPWEK_INVOER_EIG_B' || eersteOpwekkerInvoer === 'VERW-OPWEK_INVOER_EIG_C')) {
                    deleteOpwekkersOverTwo();
                    return true;
                    //showIt = false;
                } //OR
                let eersteOpwekkerProductId = parseInt(eersteOpwekkerData.PropertyDatas['KOEL-OPWEK_TOEW'].Value);
                let eersteOpwekkerProduct = koelingLogic.selectionTable.find(function (x) { return x.Id === eersteOpwekkerProductId; });
                if (eersteOpwekkerProduct && eersteOpwekkerProduct.fCgen0) {
                    deleteOpwekkersOverTwo();
                    return true;
                    //showIt = false;
                }
            }
        } else {
            return true;
            //showIt = false;
        }

        return false;
        //return !showIt;
    } //-- end: opwekkerButtonIsHidden ------------------------------------------------------------------------//

    function deleteOpwekkersOver(maxCount) {
        let deleted = false;
        for (const opwekker of vm.koelOpwekkersData().slice(maxCount)) {
            ntaEntityData.delete(opwekker.EntityDataId);
            deleted = true;
        }
        if (deleted) setKoelOpwekkers();
    }

    var deleteOpwekkersOverOne = function () {
        deleteOpwekkersOver(1);
    };

    var deleteOpwekkersOverTwo = function () {
        deleteOpwekkersOver(2);
    };

    vm.getOpwekkerTypes = function () {
        let typesList = vm.opwekkerTypes;

        // Conditie KOB
        if (vm.opwekkerTypes.length > 1) {
            typesList = typesList.filter(function (x) { return x.key2 !== 'ONB'; });
        }

        return typesList;
    };

    vm.copyOpwekkerIsHidden = function () {
        return vm.opwekkerButtonIsHidden();
    };

    // Conditie KAG
    vm.afgifteButtonIsHidden = function () {

        if (vm.rekenzones().length > 1 && vm.koelAfgifteData().length < vm.rekenzones().length) {
            return false;
        }

        return true;
    };

    vm.copyAfgifteIsHidden = function () {
        return vm.afgifteButtonIsHidden();
    };

    // Conditie KAH
    vm.afgifteTabelIsHidden = function () {
        if (vm.koelAfgifteData().length > 1) {
            return false;
        }

        return true;
    };

    // Conditie KAM
    vm.alleZonesGekoppeldAanAfgiftes = koelingLogic.alleZonesGekoppeldAanAfgiftes;

    // Conditie KAB
    vm.alleAfgiftesGekoppeldAanZone = koelingLogic.alleAfgiftesGekoppeldAanZone;

    vm.isAddAfgifteVentilatorVisible = function (afgifte) {
        // Ventilatoren KA15 controleren op 'geen ventilatoren aanwezig', conditie [KAJ]
        const rekenzones = koelingLogic.getAfgifteRekenzones(afgifte);
        let showit = rekenzones.length === 1;
        if (showit) {
            const afgifteventilator1 = vm.getAfgifteVentilatoren(afgifte)[0];
            showit = afgifteventilator1 && !geenVentilator(afgifteventilator1);
        }
        return showit;
    };

    vm.isVentilatorMenuVisible = function (afgifte, rekenzone = null) {
        const rekenzones = rekenzone && [rekenzone] || koelingLogic.getAfgifteRekenzones(afgifte);
        return rekenzones.some(rekenzone => {
            const afgifteventilator1 = vm.getAfgifteVentilatoren(afgifte, rekenzone)[0];
            return !afgifteventilator1 || !geenVentilator(afgifteventilator1);
        });
    } //-- end: isVentilatorMenuVisible ----------------------------------------//

    function geenVentilator(ventilator) {
        return [null, '', 'VERW-AFG-VENT_INV_GEEN'].includes(ventilator.PropertyDatas['KOEL-AFG-VENT_INV'].Value);
    }

    vm.addAfgifteVentilatorIsHidden = function (afgifte, rekenzone = null) {
        const afgifteventilatoren = vm.getAfgifteVentilatoren(afgifte, rekenzone);
        // Ventilatoren KA15 controleren op 'geen ventilatoren aanwezig', conditie [KAJ]
        const showit = afgifteventilatoren.some(ventilator => ![null, 'VERW-AFG-VENT_INV_GEEN'].includes(ventilator.PropertyDatas['KOEL-AFG-VENT_INV'].Value));
        return !showit;
    };

    // Conditie [KAJ]
    vm.ventilatorIsHidden = function (ventilator, afgifte, rekenzone) {
        [afgifte, rekenzone, ventilator] = unpackAfgifteVentilatorArgs(afgifte, rekenzone, ventilator);
        const firstVentilator = vm.getAfgifteVentilatoren(afgifte, rekenzone)[0];
        const showit = ventilator === firstVentilator
            || firstVentilator.PropertyDatas['KOEL-AFG-VENT_INV'].Value !== 'VERW-AFG-VENT_INV_GEEN';
        ntaEntityData.setEntityRelevancy(ventilator, showit);
        ntaEntityData.setEntityVisibility(ventilator, showit);
        return !showit;
    };

    //////////////////////////////////////////////////////////////////// CRUD akties ////////////////////////////////////////////////////////////////////////////////////////////////////
    vm.openMenu = function ($mdMenu, ev) {
        $mdMenu.open(ev);

        ev.stopImmediatePropagation();
        ev.preventDefault();
    } //-- end: openMenu ------------------------------------------------------------------------//

    vm.addOpwekker = function (typeId) {
        const newId = ntaEntityData.create("KOEL-OPWEK", -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": vm.koelDataId, "ParentEntityId": "KOEL" }], []);
        const opwekker = ntaEntityData.get(newId);
        if (typeId) {
            const propdata = opwekker.PropertyDatas["KOEL-OPWEK_TYPE"];
            ntaEntityData.saveprop(propdata, typeId);
        }

        setKoelOpwekkers();

        koelingLogic.setDefaultValuesOpwekker(opwekker);
        koelingLogic.checkPrioOpwekker(opwekker);
        koelingLogic.checkGemeenschappelijkOpwekker(opwekker);
        koelingLogic.checkFractiePrefOpwekker();
    } //-- end: addOpwekker ------------------------------------------------------------------------//

    vm.copyClick = function (Id) {
        if (!Id) {
            return;
        }

        progressCircle.setShowProgressValue(true, 'kopiëren opwekker');
        const newEntdatas = ntaEntityData.copy(Id);

        const newOpwekker = newEntdatas.find(function (x) { return x.EntityId === 'KOEL-OPWEK'; });
        progressCircle.setShowProgressValue(false);
        setKoelOpwekkers();

        koelingLogic.setDefaultValuesOpwekker(newOpwekker);

        const propProduct = vm.koelOpwekkerProperties['KOEL-OPWEK_TOEW'];
        const propRegio = vm.koelOpwekkerProperties['KOEL-OPWEK_REGIO'];

        koelingLogic.getSelectionTable(propProduct, newOpwekker);
        koelingLogic.getSelectionTable(propRegio, newOpwekker);

        koelingLogic._setAutocompleteSelectedItem(propProduct, newOpwekker);
        koelingLogic._setAutocompleteSelectedItem(propRegio, newOpwekker);
        koelingLogic._setSelectionTableValues(newOpwekker, 'C');

        koelingLogic.checkPrioOpwekker(newOpwekker);
        koelingLogic.checkFractiePrefOpwekker();
    } //-- end: copyClick ------------------------------------------------------------------------//

    vm.deleteClick = function (Id) {
        if (!Id) {
            return;
        }

        if (koelingLogic.koelOpwekkersData.length <= 1) {
            ntaMeldingen.warning("[W018]", 'Verwijderen niet toegestaan');
            return;
        }

        var confirm = $mdDialog.confirm()
            .title('Verwijderen opwekker')
            .textContent('U staat op het punt om deze opwekker te verwijderen. Weet u het zeker?')
            .ariaLabel('Verwijderen')
            .targetEvent(event)
            .ok('Ja')
            .cancel('Nee');

        $mdDialog.show(confirm).then(function () {
            ntaEntityData.delete(Id);

            setKoelOpwekkers();

            base.initializeUnitSpacing();
        });
    } //-- end: deleteClick ------------------------------------------------------------------------//

    vm.addAfgifte = koelingLogic.addAfgifte;

    vm.copyAfgifteClick = function (afgifteId) {
        if (!afgifteId) {
            return;
        }

        progressCircle.setShowProgressValue(true, 'kopiëren afgiftesysteem');
        const newEntdatas = ntaEntityData.copy(afgifteId);
        //-- ook de javascript objecten die in de client zijn toegevoegd zoals selectedItem enz. van het product mee kopieren
        const afgifte = ntaEntityData.get(afgifteId);
        for (const newEntdata of newEntdatas) {
            if (afgifte.EntityId === newEntdata.EntityId) {
                for (const member in afgifte) {
                    if (!newEntdata.hasOwnProperty(member) && member !== "$$hashKey") {
                        newEntdata[member] = afgifte[member];
                    }
                }
            }
        }

        progressCircle.setShowProgressValue(false);
        setKoelAfgiftes();

        // Controles
        vm.alleZonesGekoppeldAanAfgiftes();
        vm.alleAfgiftesGekoppeldAanZone();
    } //-- end: copyAfgifteClick ------------------------------------------------------------------------//

    vm.deleteAfgifteClick = function (Id) {
        if (!Id) {
            return;
        }

        if (koelingLogic.koelAfgifteData.length <= 1) {
            ntaMeldingen.warning("[W019]", 'Verwijderen niet toegestaan');
            return;
        }

        var confirm = $mdDialog.confirm()
            .title('Verwijderen systeem')
            .textContent('U staat op het punt om deze afgifte te verwijderen. Weet u het zeker?')
            .ariaLabel('Verwijderen')
            .targetEvent(event)
            .ok('Ja')
            .cancel('Nee');

        $mdDialog.show(confirm).then(function () {
            ntaEntityData.delete(Id);

            setKoelAfgiftes();
            base.initializeUnitSpacing();

            // Controles
            koelingLogic.koppelRekenzonesAanLaatsteAfgifte();
        });
    } //-- end: deleteAfgifteClick ------------------------------------------------------------------------//

    function showAfgifteRekenzones(afgifte) {
        return koelingLogic.getAfgifteRekenzones(afgifte).length > 1;
    } //-- end: showAfgifteRekenzone ------------------------------------------------------------//

    vm.saveRekenzoneKoppeling = function (afgifte, rz, ev) {

        // koppeling zoeken met deze rekenzone
        const relations = ntaEntityData.getRelationsBetween(rz, afgifte);

        if (relations.length > 0) {
            // koppeling verwijderen
            for (const oldRelation of relations) {
                ntaEntityData.deleteRelation(oldRelation.EntityRelationDataId);
            }
        } else {
            // oude koppeling verwijderen, conditie [KAA]
            const oldRelations = ntaEntityData.getChildRelations(rz, 'KOEL-AFG');
            for (const oldRelation of oldRelations) {
                ntaEntityData.deleteRelation(oldRelation.EntityRelationDataId);
            }

            // koppeling toevoegen
            ntaEntityData.createRelation(rz.EntityDataId, afgifte.EntityDataId);
        }

        // Controles
        vm.alleZonesGekoppeldAanAfgiftes();
        vm.alleAfgiftesGekoppeldAanZone();

        if (ev) {
            ev.stopImmediatePropagation();
            ev.preventDefault();
        }
    } //-- end: saveRekenzoneKoppeling ------------------------------------------------------------------------//

    vm.sortableOptionsVentilatoren = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            const rekenzone = ui.item.sortable.model.rekenzone;
            const ventilatoren = ui.item.sortable.droptargetModel
                .filter(vm => vm.rekenzone === rekenzone)
                .map(vm => vm.ventilator);
            ntaEntityData.SaveReOrder(ventilatoren); // Reorder ventilatoren
        }
    } //-- end: sortableOptionsVentilatoren ------------------------------------------------------------------------//

    vm.sortablePompen = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            let pompen = vm.koelDistributiePompData();
            ntaEntityData.SaveReOrder(pompen); // Reorder pompen
        }
    };

    vm.addVentilatorProgress = false;
    vm.addVentilator = function (afgifte) {
        if (!afgifte) {
            return;
        }
        vm.addVentilatorProgress = true;
        vm.addAfgifteVentilator(afgifte, vm.rekenzones()[0]);
        vm.addVentilatorProgress = false;
    };

    vm.addAfgifteVentilator = function (afgifte, rekenzone, ventilator) {
        [afgifte, rekenzone, ventilator] = unpackAfgifteVentilatorArgs(afgifte, rekenzone, ventilator);
        if (!afgifte || !rekenzone) return;

        const parentRels = [
            { "OnCopy": 1, "OnDelete": 1, "Parent": afgifte.EntityDataId, "ParentEntityId": afgifte.EntityId },
            { "OnCopy": 1, "OnDelete": 1, "Parent": rekenzone.EntityDataId, "ParentEntityId": rekenzone.EntityId },
        ];
        ntaEntityData.create('KOEL-AFG-VENT', ventilator ? ventilator.Order : -1, parentRels, [], null);
    };

    vm.copyAfgifteVentilator = function (afgifte, rekenzone, ventilator) {
        [afgifte, rekenzone, ventilator] = unpackAfgifteVentilatorArgs(afgifte, rekenzone, ventilator);
        if (!ventilator) return;

        ntaEntityData.copy(ventilator.EntityDataId);
    };

    vm.deleteAfgifteVentilator = function (afgifte, rekenzone, ventilator) {
        [afgifte, rekenzone, ventilator] = unpackAfgifteVentilatorArgs(afgifte, rekenzone, ventilator);
        if (!ventilator || !afgifte) return;

        switch (vm.getAfgifteVentilatoren(afgifte, rekenzone).length) {
            case 0:
                return;
            case 1:
                ventilator.PropertyDatas['KOEL-AFG-VENT_INV'].Value = 'VERW-AFG-VENT_INV_GEEN';
                vm.saveValue(ntabuilding.properties['KOEL-AFG-VENT_INV'], ventilator);
                break;
            default:
                ntaEntityData.delete(ventilator.EntityDataId);
                break;
        }
    };

    function unpackAfgifteVentilatorArgs(afgifte, rekenzone, ventilator) {
        if (!afgifte && ventilator) afgifte = ntaEntityData.getFirstParent(ventilator, 'KOEL-AFG');
        if (!rekenzone && ventilator) rekenzone = ntaEntityData.getFirstParent(ventilator, 'RZ');
        if (!rekenzone && vm.rekenzones.length === 1) rekenzone = vm.rekenzones()[0];
        return [afgifte, rekenzone, ventilator];
    }

    vm.addPompProgress = function () {
        return koelingLogic.addPompProgress;
    };
    vm.addPomp = koelingLogic.addPomp;
    vm.copyPomp = koelingLogic.copyPomp;
    vm.deletePomp = koelingLogic.deletePomp;

    // Dynamic styling
    vm.center = function (prop) {
        if (prop.Id === 'VENTILATOREIG_DEB') {
            return '';
        }

        return 'columnCenter';
    };

    koelingLogic.initWarnings();
    koelingLogic.setGeopend();

}]);