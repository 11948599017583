﻿angular.module('projectModule')
    .controller("VariantsController",
        ['$scope', '$controller', 'ntaEntityData', 'ntaValidation', 'ntaVariant', 'projecttree', '$location', '$routeParams', 'ntaDependencyValidation', 'VariantsFactory',
function ($scope,   $controller,   ntaEntityData,   ntaValidation,   ntaVariant,   projecttree,   $location,   $routeParams,   ntaDependencyValidation,   VariantsFactory) {
    'use strict';
    const vm = this;
    const base = $controller('NTABaseController', { $scope: $scope })
    angular.extend(vm, base);

    const variantsLogic = new VariantsFactory(ntaDependencyValidation);
    vm.getVariantsLogic = function () {
        return variantsLogic;
    };

    vm.ntaValidation = ntaValidation;

    vm.properties = variantsLogic.properties;
    vm.variants = variantsLogic.variants;
    vm.saveValue = variantsLogic.saveValue;

    vm.getVariantName = ntaVariant.getName;
    vm.getTileDetails = ntaVariant.getTileDetails;
    vm.getEnergielabel = ntaVariant.getEnergielabel;
    vm.getTotaleKosten = ntaVariant.getTotaleKosten;
    vm.getTerugverdientijd = ntaVariant.getTerugverdientijd;
    vm.conditieOVB = ntaVariant.conditieOVB;
    vm.conditieOVC = ntaVariant.conditieOVC;

    vm.getMeasureCount = (variant) => ntaVariant.getMeasures(variant).length;
    vm.isTreeMenuOpen = function () {
        return projecttree.instmenuopen;
    };

    vm.editVariantNaam = editVariantNaam;
    vm.saveVariantNaam = saveVariantNaam;


    /////////////////////////////////////////////////////////////////////////////////////////// CRUD akties /////////////////////////////////////////////////////////////////////////////////////////////////////

    vm.addVariant = variantsLogic.addVariant;
    vm.copyClick = variantsLogic.copyClick;
    vm.deleteClick = variantsLogic.deleteClick;

    function editVariantNaam(variantId, event) {
        if (!variantId) {
            return;
        }
        $("#variantname" + variantId).removeClass("showname");
        $("#variantnameinput" + variantId).addClass("editname");
        $("#variantnameinput" + variantId).focus();
        $("#variantnameinput" + variantId).select();

        event.stopImmediatePropagation();
        event.preventDefault();
    } //-- end: editInstallatieNaam --------------------------------------------------------------------------------//

    function saveVariantNaam(entdata, event) {
        const propdata = entdata.PropertyDatas["VARIANT_NAAM"];
        ntaEntityData.saveprop(propdata);

        $("#variantname" + entdata.EntityDataId).addClass("showname");
        $("#variantnameinput" + entdata.EntityDataId).removeClass("editname");

        /// sorteer nu de varianten op naam.
        ntaVariant.sortVariantsByName();

        event.stopImmediatePropagation();
        event.preventDefault();
    } //-- end: saveVariantNaam -------------------------------------------------------------//

    ///////////////////////////////////////////////////////////////////////////////////////// Overig ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    vm.variantIconImg = function (entdataId) {
        let iconImg = "images/MA_Varianten.svg";
        return iconImg;
    }; //-- variantIconImg

    vm.openMenu = function ($mdMenu, ev) {
        $mdMenu.open(ev);

        ev.stopImmediatePropagation();
        ev.preventDefault();
    } //-- end: openMenu ------------------------------------------------------------------------//

    vm.showMenu = function (entdata) {
        return true;
    };

    vm.navToVariantInTree = function (variant) {
        if (!$("#variantname" + variant.EntityDataId).hasClass("showname")) {
            return;
        }
        const path = `/Project/${$routeParams.id}/Berekening/${variant.BuildingId}/Varianten/${variant.EntityDataId}`;
        $location.path(path);
    } //-- end: navToVariantInTree ------------------------------------------------------------------------//


    ///////////////////////////////////////////////////////////////////////////////////////////////////////// FORM ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    vm.getForm = function () {
        return variantsLogic.form_variants;
    };

    function startFormValidation() {
        return variantsLogic.startFormValidation();
    } //-- end: startFormValidation ------------------------------------------------------------------------//

    $scope.endFormValidation = function () {
        return variantsLogic.endFormValidation();
    };

    angular.element(function () { //alle touched properties valideren na het laden van form
        startFormValidation();
        base.initializeUnitSpacing();
    }); //-- end: endFormValidation ------------------------------------------------------------------------//



}]);