﻿angular.module('projectModule')
    .factory("Pager",
        ['$log', 'EventSource',
function ($log,   EventSource) {
    'use strict';

    const defaultOptions = {
        rowsPerPage: 25,
        storageName: '',
        visible: true,
        validationTimeoutMS: 100,
    };

    return function Pager(options = defaultOptions) {
        const self = this;

        // options aanvullen met defaultOptions
        options = Object.assign({}, defaultOptions, options);

        const _eventSource = new EventSource(self);

        const _pages = [];
        let _allRows = [];

        let _visible = options.visible;
        let _rowsPerPage = _visible ? determineDefaultRowsPerPage() : Number.MAX_SAFE_INTEGER;
        let _pageNr = 0;
        let _rowCount = _allRows.length;
        let _distributionRequestCount = 0;

        // Deze Map gebruiken we om te voorkomen dat we meerdere keren achter elkaar ‘validationRequested’ triggeren.
        // Elke key is een Set met al de te valideren paginanummers; de bijbehorende value is de timeout-id (zodat deze gecleared kan worden).
        // Zie ook function requestValidation(...) verderop.
        const _validationTimeouts = new Map();

        // De properties van elke pagina
        const pagePropertyDefinitions = {
            nr: {
                configurable: true,
                enumerable: true,
                writable: true,
            },
            isValid: {
                enumerable: true,
                get: function () {
                    return this._isValid;
                },
                set: function (value) {
                    setPageIsValid(this, value);
                },
            },
            _isValid: {
                enumerable: false, // ‘private’ property
                writable: true,
                value: null, // null wil zeggen dat deze nog niet gevalideerd is
            },
            firstRowNr: {
                enumerable: true,
                get: function () {
                    return this.nr * _rowsPerPage;
                },
            },
            lastRowNr: {
                enumerable: true,
                get: function () {
                    return this.firstRowNr + _rowsPerPage;
                },
            },
            rows: {
                get: function () {
                    return this._rows;
                },
            },
            _rows: {
                enumerable: false,
                writable: true,
                value: [],
            },
        };

        // Exports
        Object.defineProperties(self, {
            //-- properties
            rowsPerPage: {
                enumerable: true,
                get: () => _rowsPerPage,
                set: setRowsPerPage,
            },
            rowCount: {
                enumerable: true,
                get: () => _rowCount,
                set: setRowCount,
            },
            pageCount: {
                enumerable: true,
                get: () => _pages.length,
            },
            pageNr: {
                enumerable: true,
                get: () => _pageNr,
                set: pageNr => setPageNr(pageNr),
            },
            pages: {
                enumerable: true,
                get: () => _pages,
            },
            currentPage: {
                enumerable: false,
                get: () => _pages[_pageNr],
                set: value => setPageNr(_pages.indexOf(value)),
            },
            firstRowNr: {
                get: () => self.currentPage.firstRowNr,
            },
            lastRowNr: {
                get: () => self.currentPage.lastRowNr,
            },
            pageRows: {
                get: () => self.getPageRows(),
            },
            //-- methods
            on: {
                value: _eventSource.on,
            },
            off: {
                value: _eventSource.off,
            },
            shouldShow: {
                value: rows => {
                    setAllRows(rows);
                    return _visible;
                },
            },
            getPageRows: {
                value: rows => {
                    setAllRows(rows);
                    return self.currentPage && self.currentPage.rows || [];
                },
            },
            getRowPage: {
                value: getRowPage,
            },
            getRowPageNr: {
                value: getRowPageNr,
            },
            visible: {
                get: () => _visible,
                set: visible => setVisible(visible),
            },

        });

        //-- Implementation ---------------------------------------------------------------------//

        function beginDistribution() {
            _distributionRequestCount++;
        } //-- end: beginDistribution -----------------------------------------------------------//

        function endDistribution() {
            const triggered = --_distributionRequestCount <= 0;
            if (triggered) {
                distributeRows();
            }
            return triggered;
        } //-- end: endDistribution -------------------------------------------------------------//

        function distributeRows() {
            for (const page of _pages) {
                page._rows = _allRows.slice(page.firstRowNr, page.lastRowNr);
            }
        } //-- end: distributeRows --------------------------------------------------------------//

        function getStorageKey(itemName, storageName = options.storageName || '') {
            return `pager:${storageName}:${itemName}`;
        } //-- end: getStorageKey ---------------------------------------------------------------//

        function determineDefaultRowsPerPage() {
            if (typeof options.storageName === 'string') {
                const stored = parseInt(localStorage.getItem(getStorageKey('rowsPerPage')));
                if (!isNaN(stored)) {
                    return Math.max(5, stored);
                }
            }
            return options.rowsPerPage || defaultOptions.rowsPerPage;
        } //-- end: determineDefaultRowsPerPage -------------------------------------------------//

        function setRowsPerPage(value) {
            const oldValue = _rowsPerPage;

            if (typeof value !== 'number') {
                value = parseInt(value);
            }

            if (value === oldValue) return;

            // controleren dat we niet minder dan 5 regels hebben.
            if (isNaN(value) || value < 5) {
                throw new RangeError(`Unable to set less than 5 rows per page (${value}).`);
            }

            _eventSource.trigger('rowsPerPageChanging', { value, oldValue });

            // Onthouden wat de huidige eerste rij is
            const firstRowNr = this.firstRowNr;
            const oldPageNr = _pageNr;

            _rowsPerPage = value;

            if (_visible && typeof options.storageName === 'string') {
                localStorage.setItem(getStorageKey('rowsPerPage'), _rowsPerPage);
            }

            beginDistribution();
            try {
                setPageCount(Math.ceil(_rowCount / _rowsPerPage));
            } finally {
                endDistribution();
            }

            _eventSource.trigger('rowsPerPageChanged', { value, oldValue });

            // Zorgen dat bovenste rij in beeld blijft
            const pageNr = this.getRowPageNr(firstRowNr);
            if (pageNr !== oldPageNr) {
                setPageNr(pageNr);
            }

            // de isValid van de pages klopt potentieel niet meer, en moet dus opnieuw bepaald worden.
            requestValidation();
        } //-- end: setRowsPerPage --------------------------------------------------------------//

        function setAllRows(rows) {
            if (!rows) return false;
            if (rows.length === _allRows.length && rows.every((row, index) => row === _allRows[index])) return false;

            _allRows = rows;

            beginDistribution();
            try {
                setRowCount(rows.length);
            } finally {
                endDistribution();
            }

            return true;
        } //-- end: setAllRows ------------------------------------------------------------------//

        function setRowCount(value) {
            const oldValue = _rowCount;

            if (value === oldValue) return;

            _eventSource.trigger('rowCountChanging', { value, oldValue });

            _rowCount = value;

            beginDistribution();
            try {
                setPageCount(Math.ceil(_rowCount / _rowsPerPage));
            } finally {
                endDistribution();
            }

            _eventSource.trigger('rowCountChanged', { value, oldValue });

            requestValidation();
        } //-- end: setRowCount -----------------------------------------------------------------//

        function setPageCount(value) {
            const oldValue = _pages.length;

            if (typeof value !== 'number') {
                value = parseInt(value);
            }

            if (value === oldValue) return;

            if (isNaN(value) || value < 1) {
                throw new RangeError(`Unable to set page count to ${value}. The page count should be a non-zero positive integer.`);
            }

            _eventSource.trigger('pageCountChanging', { value, oldValue });

            beginDistribution();
            try {
                // controleer of de huidige pagina niet voorbij het nieuwe aantal is
                if (_pageNr >= value) {
                    setPageNr(value - 1, true);
                }

                _pages.length = value;

                // nieuwe pagina’s toevoegen
                for (let i = oldValue; i < value; i++) {
                    _pages[i] = Object.create(null, pagePropertyDefinitions);
                    Object.defineProperty(_pages[i], 'nr', {
                        configurable: false,
                        enumerable: true,
                        writable: false,
                        value: i,
                    });
                }
            } finally {
                endDistribution();
            }

            _eventSource.trigger('pageCountChanged', { value, oldValue });

            requestValidation();
        } //-- end: setPageCount ----------------------------------------------------------------//

        function setPageNr(value, force = false) {
            const oldValue = _pageNr;

            if (typeof value !== 'number') {
                value = parseInt(value);
            }

            if (value === oldValue) return;

            // controleren of we niet te ver gaan
            if (value >= _pages.length) {
                $log.warn(`Unable to go to page ${value}. Page numbers should be set between 0 and ${_pages.length - 1} inclusive.`);
                value = _pages.length - 1;
            }
            if (value < 0) {
                $log.warn(`Unable to go to page ${value}. Page numbers should be set between 0 and ${_pages.length - 1} inclusive.`);
                value = 0;
            }

            // valideer nog ff de oude pagina
            requestValidation(oldValue, oldValue);

            // notificatie uitzenden dat we van pagina GAAN veranderen.
            const acknowledgments = _eventSource.trigger('pageNrChanging', { value, oldValue });
            if (!force && acknowledgments.some(ok => ok === false)) {
                return false;
            }

            _pageNr = value;

            // Notificatie uitzenden dat we van pagina zijn veranderd.
            _eventSource.trigger('pageNrChanged', { value, oldValue });

            // valideer nu de nieuwe pagina
            requestValidation(value, value);

            return true;
        } //-- end: setPageNr -------------------------------------------------------------------//

        function getRowPage(rowNr) {
            return _pages[getRowPageNr(rowNr)];
        } //-- end: getRowPage ------------------------------------------------------------------//

        function getRowPageNr(rowNr) {
            if (typeof rowNr !== 'number') {
                rowNr = _allRows.indexOf(rowNr);
            }
            return Math.floor(rowNr / _rowsPerPage);
        } //-- end: getRowPageNr ----------------------------------------------------------------//

        function setPageIsValid(page, value) {
            const oldValue = page.isValid;

            if (value === oldValue) return;

            page._isValid = value;

            _eventSource.trigger('pageValidChanged', { page, value, oldValue });
        } //-- end: setPageIsValid --------------------------------------------------------------//

        function setVisible(visible) {
            if (visible !== _visible) {
                _visible = visible;
                requestValidation();
            }
        } //-- end: setVisible -----------------------------------------------------------------//


        function requestValidation(firstPageNr = 0, lastPageNr = self.pageCount - 1) {
            // Om te voorkomen dat we kort op elkaar meerdere keren validatie aanvragen,
            //  houden we een timeout van 100ms aan. Evt. eerdere validatieverzoeken
            //  worden samengevoegd.
            const pageNrsToValidate = new Set();
            for (let i = firstPageNr; i <= lastPageNr; i++) {
                pageNrsToValidate.add(i);
            }
            for (const [pageNrs, timeout] of _validationTimeouts) {
                clearTimeout(timeout);
                _validationTimeouts.delete(pageNrs);
                for (const pageNr of pageNrs) {
                    pageNrsToValidate.add(pageNr);
                }
            }
            _validationTimeouts.set(pageNrsToValidate, setTimeout(() => {
                _validationTimeouts.delete(pageNrsToValidate);
                const pages = [...pageNrsToValidate]
                    .sort()
                    .map(pageNr => self.pages[pageNr])
                    .filter(page => page);
                _eventSource.trigger('validationRequested', { pages });
            }, options.validationTimeoutMS || defaultOptions.validationTimeoutMS));
        } //-- end: requestValidation -----------------------------------------------------------//
    };
}]);
