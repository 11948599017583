angular.module('indexModule')
    .service('folderService',
        ['indextree',
function (indextree) {
    'use strict';
    const vm = this;

    vm.getActiveFolder = function () {
        return indextree.foldersById.get(indextree.activeFolderId);
    };

    vm.setActiveFolder = function(folderId) {
        if (!folderId)
            return;

        const folder = indextree.foldersById.get(folderId);
        folder.Active = true;
        folder.Open = true;

        vm.openParentFolders(folder.ParentId);
        vm.setFolderActive(folderId);
        indextree.activeFolderId = folderId;
    };


    vm.getFolderById = async function(folderId) {
        const response = await fetch('/Projects/GetFolder?folderId=' + folderId);
        const reply = await response.json();
        if (reply.error) {
            throw new Error(reply.error);
        } else {
            return reply;
        }
    };

    vm.setFolderActive = function (folderId) {
        const folder = indextree.foldersById.get(folderId);
        folder.Active = true;
        let currentlyActiveFolder = vm.getActiveFolder();
        if (indextree.activeFolderId != folderId && currentlyActiveFolder) {
            currentlyActiveFolder.Active = false;
        }
        indextree.activeFolderId = folder.FolderId;
    };

    vm.openParentFolders = function(parentId) {
        if (parentId === -2) {
            indextree.isPrullenbakOpen = true;
        }

        let parentFolder = indextree.foldersById.get(parentId);
        while (parentFolder) {
            parentFolder.Open = true;

            parentId = parentFolder.ParentId;
            if (parentId === -2) {
                indextree.isPrullenbakOpen = true;
            }
            parentFolder = indextree.foldersById.get(parentId);
        }
    };
}]);