﻿angular.module('afmeldModule')
    .controller("RegisterController",
        ['$log', '$mdToast', 'ntaAlert',
function ($log,   $mdToast,   ntaAlert) {
    'use strict';
    const vm = this;

    ///-- Instance variables ----------------------------------------------------------------------

    let _afmeldId;

    const _eventHandlers = { // dit zijn de notificaties die vanuit EpcHub verstuurd worden, en de functie waarmee elk hier afgehandeld wordt.
        'Error': onError,
        'NotFound': onNotFound,
        'Progress': onProgress,
        'Success': onSuccess,
    };

    const _toastConnection = $mdToast.simple()
        .textContent('De verbinding is verbroken. We proberen 5 minuten lang deze te herstellen.')
        .action('×').actionKey('h').actionHint('druk op Ctrl-H om te sluiten')
        .hideDelay(0)
        .capsule(false);


    ///-- Exports ---------------------------------------------------------------------------------

    vm.startRegistration = startRegistration;


    ///-- Initialization --------------------------------------------------------------------------

    const epcHubConn = new signalR.HubConnectionBuilder()
        .withUrl("/hubs/epc")
        .configureLogging({
            log: (logLevel, message) => {
                let level = 'debug';
                switch (logLevel) {
                    case signalR.LogLevel.Critical: level = 'error'; break;
                    case signalR.LogLevel.Error: level = 'error'; break;
                    case signalR.LogLevel.Warning: level = 'warn'; break;
                    case signalR.LogLevel.Information: level = 'info'; break;
                }
                $log[level]('SignalR/hubs/epc:', message);
            }
        })
        // blijf 45 minuten lang proberen de verbinding te herstellen: onmiddellijk, na 1, 2, 6, 10, 20 en 30 secondes, en daarna om de 30 secondes
        .withAutomaticReconnect([0, 1000, 1000, 4000, 4000, 10000, 10000].concat(Array.from({ length: 45 * 60 / 30 - 1 }).map(x => 30000)))
        .build();
    let _connectionToastTimeout;

    for (const [method, handler] of Object.entries(_eventHandlers)) {
        epcHubConn.on(method, handler);
    }

    epcHubConn.onreconnecting((error) => {
        _connectionToastTimeout = setTimeout(() => $mdToast.show(_toastConnection), 5000); // wacht tot de verbinding minstens 5 secondes is weggebleven alvorens het aan de gebruiker te tonen
    });

    epcHubConn.onreconnected(async (connectionId) => {
        clearTimeout(_connectionToastTimeout);
        _connectionToastTimeout = null;
        $mdToast.hide();

        // Vraag de meest recente notificatie op...
        const args = await epcHubConn.invoke("RequestRegistrationStatus", _afmeldId);
        const method = args.shift();

        // ...en herhaal deze
        const handler = _eventHandlers[method];
        if (handler) {
            handler.apply(epcHubConn, args);
        } else {
            $log.error('Geen handler gevonden voor', method, args);
        }
    });

    epcHubConn.onclose((error) => {
        $mdToast.hide();
        // We geven het op. Geef een popup dat er netwerkproblemen zijn, en doe daarna een redirect naar de projectpagina
        ntaAlert.showNetworkError('/Projects');
    });



    ///-- Implementation --------------------------------------------------------------------------

    async function startRegistration(afmeldId, epToken) {
        try {
            _afmeldId = afmeldId;

            await epcHubConn.start();
            await epcHubConn.invoke("Register", afmeldId, epToken);
        } catch (err) {
            $log.error('AfmeldId: ', afmeldId, ':', err);
        }
    }

    function onError(message) {
        $("#content").append(`<p class="alert alert-danger">Afmelden mislukt: ${message}. Als dit probleem zich blijft voordoen, neem dan contact op met de helpdesk.</p>`);
        $(".progress").hide();
    }

    function onNotFound(message) {
        $("#content").append(`<p class="alert">Kan de afmeldgegevens bij het project niet vinden. Mogelijk is de afmelding al geslaagd. Mocht dit niet zo zijn, neem dan contact op met de helpdesk.</p>`);
        $log.error(message);
        $(".progress").hide();
    }

    function onProgress(progressCount, totalCount) {
        const progressBar = $("#progress");
        progressBar.text(`(${progressCount}/${totalCount}) Bezig met afmelden...`);
        const width = Math.min(5 + progressCount * 95 / totalCount, 100);
        progressBar.width(`${width}%`);
    }

    function onSuccess() {
        window.location = `/epc/registrationFinished/${encodeURI(_afmeldId)}`;
    }
}]);
