﻿angular.module('projectModule')
    .controller("InstallationVerwarmingController",
        ['$scope', '$controller', 'ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaSharedLogic', '$routeParams', '$mdDialog', 'progressCircle', 'ntaMeldingen', 'ntaDependencyValidation', 'ntaMeasure', 'ListCache', 'VerwarmingFactory',
function ($scope,   $controller,   ntabuilding,   ntaValidation,   ntaEntityData,   ntaSharedLogic,   $routeParams,   $mdDialog,   progressCircle,   ntaMeldingen,   ntaDependencyValidation,   ntaMeasure,   ListCache,   VerwarmingFactory) {

    const base = $controller('NTABaseController', { $scope: $scope })
    const vm = angular.extend(this, base);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    vm.ntaValidation = ntaValidation;
    vm.installId = $routeParams.id3;

    const verwarmingLogic = new VerwarmingFactory(vm.installId, ntaDependencyValidation);
    vm.getVerwarmingLogic = verwarmingLogic;

    const _listCache = new ListCache();

    /* Properties *************************************************************************************************************************************************************/
    vm.propOpmerkingen = verwarmingLogic.propOpmerkingen;
    vm.verwOpwekkerProperties = verwarmingLogic.verwOpwekkerProperties;
    vm.verwDistributieProperties = verwarmingLogic.verwDistributieProperties;
    vm.verwDistributiePompProperties = verwarmingLogic.verwDistributiePompProperties;
    vm.verwDistributieBinnenProperties = verwarmingLogic.verwDistributieBinnenProperties;
    vm.verwDistributieBuitenProperties = verwarmingLogic.verwDistributieBuitenProperties;
    vm.verwDistributieEigenschappenLeidingenProperties = verwarmingLogic.verwDistributieEigenschappenLeidingenProperties;
    vm.verwAfgifteProperties = verwarmingLogic.verwAfgifteProperties;
    vm.verwVatProperties = verwarmingLogic.verwVatProperties;
    vm.propVatAanwezig = verwarmingLogic.propVatAanwezig;

    vm.getVerwAfgifteVentilatorenProperties = function (afgifte) {
        return verwarmingLogic.verwAfgifteVentilatorenProperties.filter(prop => !vm.propertyHeaderIsHidden(prop, afgifte));
    };

    /* Data *************************************************************************************************************************************************************/
    vm.getProduct = verwarmingLogic.getListItem;
    vm.verwData = verwarmingLogic.verwData;
    vm.verwOpwekkersData = verwarmingLogic.verwOpwekkersData;
    vm.verwDistributieData = verwarmingLogic.verwDistributieData;
    vm.verwDistributiePompData = verwarmingLogic.verwDistributiePompData;
    vm.verwDistributieBinnenData = verwarmingLogic.verwDistributieBinnenData;
    vm.verwDistributieBuitenData = verwarmingLogic.verwDistributieBuitenData;
    vm.verwDistributieEigenschappenLeidingenData = verwarmingLogic.verwDistributieEigenschappenLeidingenData;
    vm.verwAfgifteData = verwarmingLogic.verwAfgifteData;
    vm.rekenzones = verwarmingLogic.rekenzones;
    vm.getAfgifteRekenzones = verwarmingLogic.getAfgifteRekenzones;
    vm.showAfgifteRekenzones = showAfgifteRekenzones;
    vm.getAfgifteVentilatoren = verwarmingLogic.getAfgifteVentilatoren;
    vm.getAfgifteRekenzoneVentilatoren = getAfgifteRekenzoneVentilatoren;
    vm.verwVatData = verwarmingLogic.verwVatData;

    vm.afgifteTypes = verwarmingLogic.afgifteTypes;
    vm.buffervatTypes = verwarmingLogic.buffervatTypes;

    vm.isHidden = verwarmingLogic.isHidden;
    vm.isReadOnly = verwarmingLogic.isReadOnly;
    vm.isDisabled = verwarmingLogic.isReadOnly;
    vm.getCodedValues = verwarmingLogic.getCodedValues;
    vm.hasRelation = ntaSharedLogic.hasRelation;

    vm.getSelectionTable = verwarmingLogic.getSelectionTable;
    vm.saveValueSelectionTable = verwarmingLogic.saveValueSelectionTable;

    vm.pompIsHidden = verwarmingLogic.pompIsHidden;
    vm.leidingenIsHidden = verwarmingLogic.leidingenIsHidden;
    vm.ventilatorIsHidden = verwarmingLogic.ventilatorIsHidden;
    vm.propertyHeaderIsHidden = verwarmingLogic.propertyHeaderIsHidden;
    vm.distributiepompTabelIsHidden = verwarmingLogic.distributiepompTabelIsHidden;
    vm.eigenschappenLeidingenRowIsHidden = verwarmingLogic.eigenschappenLeidingenRowIsHidden;
    vm.eigenschappenDistributieleidingenIsHidden = verwarmingLogic.eigenschappenDistributieleidingenIsHidden;
    vm.afgifteTabelIsHidden = verwarmingLogic.afgifteTabelIsHidden;
    vm.conditieOCY = verwarmingLogic.conditieOCY;
    vm.buffervatenIsHidden = verwarmingLogic.buffervatenIsHidden;
    vm.buffervatenAanwezig = verwarmingLogic.buffervatenAanwezig;

    vm.getTitle = () => ntaSharedLogic.isEditingMeasure() ? 'Maatregel: wijzigen verwarming' : 'Verwarming';

    vm.allowMultiplePumps = verwarmingLogic.allowMultiplePumps;
    vm.distributiepompTabelWidth = distributiepompTabelWidth;
    vm.isSelectionTableDisabled = isSelectionTableDisabled;

    vm.getName = getName;
    vm.getForm = getForm;
    vm.saveValue = saveValue;
    vm.opwekkerNaam = opwekkerNaam;
    vm.afgifteNaam = afgifteNaam;
    vm.getCodedValueName = getCodedValueName;
    vm.getPompOmschrijving = getPompOmschrijving;
    vm.distributiepompTabelNaam = distributiepompTabelNaam;
    vm.buffervatNaam = buffervatNaam;

    vm.opwekkerButtonIsHidden = opwekkerButtonIsHidden;
    vm.copyOpwekkerIsHidden = opwekkerButtonIsHidden;
    vm.opwekkerTypesAllowed = opwekkerTypesAllowed;
    vm.afgifteButtonIsHidden = afgifteButtonIsHidden;
    vm.copyAfgifteIsHidden = afgifteButtonIsHidden
    vm.isAddAfgifteVentilatorVisible = isAddAfgifteVentilatorVisible;
    vm.isVentilatorMenuVisible = isVentilatorMenuVisible;

    vm.addPompProgress = false;
    vm.addVentilatorProgress = false;

    vm.addOpwekker = addOpwekker;
    vm.copyOpwekkerClick = copyOpwekkerClick;
    vm.deleteOpwekkerClick = deleteOpwekkerClick;

    vm.addPomp = addPomp;
    vm.copyPomp = copyPomp;
    vm.deletePomp = deletePomp;

    vm.addVentilator = addVentilator;

    vm.addAfgiteVentilator = addAfgifteVentilator;
    vm.copyAfgiteVentilator = copyAfgifteVentilator;
    vm.deleteAfgiteVentilator = deleteAfgifteVentilator;

    vm.addBuffervat = addBuffervat;
    vm.copyBuffervat = copyBuffervat;
    vm.deleteBuffervat = deleteBuffervat;

    vm.addAfgifte = addAfgifte;
    vm.copyAfgifteClick = copyAfgifteClick;
    vm.deleteAfgifteClick = deleteAfgifteClick;

    vm.saveRekenzoneKoppeling = saveRekenzoneKoppeling;

    vm.center = center;

    vm.preventBackspace = ntaSharedLogic.preventBackspace;


    $scope.endFormValidation = function () {
        verwarmingLogic.endFormValidation();
    } //-- end: endFormValidation ------------------------------------------------------------------------//

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INITIALIZATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ntaMeasure.setBtwPropIds(vm);

    angular.element(function () { //alle touched properties valideren na het laden van form
        verwarmingLogic.startFormValidation();
        vm.initializeUnitSpacing();
    });

    vm.setGeopend('VERW_OPEN', vm.verwData);


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function saveValue(prop, entdata) {
        verwarmingLogic.saveValue(prop, entdata);
        vm.initializeUnitSpacing();
    } //-- end: saveValue ------------------------------------------------------------------------//

    function getForm() {
        return verwarmingLogic.form_installationverwarming;
    } //-- end: getForm ------------------------------------------------------------------------//

    function getName(prop) {
        let str = base.getName(prop);

        //-- Conditie [DAG]
        if (prop.Id === 'VERW-DISTR_POMP_INV') {
            const propAanvullendePomp = ntabuilding.properties['VERW-DISTR_AANV_POMP'];
            const visible = !verwarmingLogic.isHidden(propAanvullendePomp, verwarmingLogic.verwDistributieData);
            if (visible) {
                const aanvullendePompAanwezig = propAanvullendePomp.getValue(verwarmingLogic.verwDistributieData);
                if (aanvullendePompAanwezig === 'VERW-DISTR_AANV_POMP_WEL') {
                    str = 'aanvullende ' + str;
                }
            }
        }

        //-- conditie [AAC]
        if (prop.Id === "VERW-AFG_PL") {
            if (vm.verwAfgifteData().length > 0) {
                const typeAfgiftesysteem = vm.verwAfgifteData()[0].PropertyDatas['VERW-AFG_TYPE_AFG'].Value;
                const typeAfgifteCodedValues = verwarmingLogic.verwAfgifteProperties["VERW-AFG_TYPE_AFG"].Domain.Codes;
                const afgifteSysteemKey1 = typeAfgifteCodedValues.find(x => x.Id === typeAfgiftesysteem).FilterValue1;
                if (afgifteSysteemKey1 === "str") {
                    return "type afgiftelichaam";
                }
            }
        }

        if (str && prop.EntityId === "VERW-DISTR-EIG") {
            //-- de unit is er al aangeplakt in de base controller; daar moet nu ff een <br> tussen geknutseld worden
            const index = str.lastIndexOf('[');
            if (index > -1) {
                str = str.substring(0, index) + '<br>' + str.substring(index);
            }
        }

        //-- conditie [AAE]
        if (prop.Id === "VERW-AFG_TYPE_AFG") {
            if (ntabuilding.ntaVersionId >= 300) {
                str = str + ' in hoofdvertrek';
            }
        }

        return str;
    } //-- end: getName ------------------------------------------------------------------------//

    function getCodedValueName(item, entdata = null) {
        // Conditie [OBQ]
        if (item && entdata && entdata.EntityId === 'VERW-OPWEK') {
            const opwekkerTypeValue = entdata.PropertyDatas['VERW-OPWEK_TYPE'].Value;
            if (opwekkerTypeValue === 'VERW-OPWEK_TYPE_C') {
                if (item.Id === 'VERW-OPWEK_GEM_NIET') {
                    return 'installatie met individuele aflevering';
                } else if (item.Id === 'VERW-OPWEK_GEM_WEL') {
                    return 'installatie met centrale aflevering';
                }
            }
        }

        return base.getCodedValueName(item, entdata);
    } //-- end: getCodedValueName ------------------------------------------------------------------------//

    function opwekkerNaam(opwekker) {
        let nummer = verwarmingLogic.opwekkerNr(opwekker);
        let naam = 'Opwekker ' + nummer;

        if (nummer > 1) {
            naam += ' (bijstook)';
        }

        return naam;
    } //-- end: opwekkerNaam ------------------------------------------------------------------------//

    function afgifteNaam(afgifte) {
        let nummer = verwarmingLogic.afgifteNr(afgifte);
        let naam = 'Afgiftesysteem ' + nummer;

        return naam;
    } //-- end: afgifteNaam ------------------------------------------------------------------------//

    function distributiepompTabelNaam() {
        //-- Conditie [DAH]
        let name = 'distributiepompen';

        const propAanvullendePomp = ntabuilding.properties['VERW-DISTR_AANV_POMP'];
        const visible = !verwarmingLogic.isHidden(propAanvullendePomp, verwarmingLogic.verwDistributieData);
        if (visible && propAanvullendePomp.getValue(verwarmingLogic.verwDistributieData) === 'VERW-DISTR_AANV_POMP_WEL') {
            name = 'aanvullende ' + name;
        }

        return name;
    } //-- end: distributiepompTabelNaam ------------------------------------------------------------------------//

    function buffervatNaam(buffervat) {
        const nummer = verwarmingLogic.vatNr(buffervat);
        const naam = 'Buffervat ' + nummer;

        return naam;
    } //-- end: voorraadvatNaam ------------------------------------------------------------------------//


    function getPompOmschrijving(entdata) {
        let nummer = verwarmingLogic.pompNr(entdata);
        let naam = 'pomp ' + nummer;

        let prop = ntabuilding.properties['VERW-DISTR_POMP_OMSCHR'];
        let propdata = prop.getData(entdata);
        if (propdata.Value !== naam) {
            verwarmingLogic.saveValue(prop, entdata, naam);
        }

        return naam;
    } //-- end: getPompOmschrijving ------------------------------------------------------------------------//

    function opwekkerButtonIsHidden() {
        //-- Conditie [OAL]
        let showIt = true;

        let eersteOpwekkerData = vm.verwOpwekkersData()[0];
        if (eersteOpwekkerData) {
            let eersteOpwekkerType = eersteOpwekkerData.PropertyDatas['VERW-OPWEK_TYPE'].Value;
            if (eersteOpwekkerType) {
                let eesteOpwekker = verwarmingLogic.opwekkerTypes.find(function (x) { return x.Id === eersteOpwekkerType; });

                if (eesteOpwekker.key6 === 'lokaal') {
                    showIt = false;
                }

                if (eesteOpwekker.key2 === 'ONB') {
                    showIt = false;
                }

                //als er een tweede opwekker is en voor de eeste opwekker geldt dat er een factie is of er is bij VERW-OPWEK_INVOER gekozen voor eigenwaarde fractie met of zonder hulpenergie dan mag er geen derde toestel komen
                if (vm.verwOpwekkersData().length === 2) {
                    let eersteOpwekkerInvoer = eersteOpwekkerData.PropertyDatas['VERW-OPWEK_INVOER'].Value;
                    if (eersteOpwekkerInvoer && (eersteOpwekkerInvoer === 'VERW-OPWEK_INVOER_EIG_B' || eersteOpwekkerInvoer === 'VERW-OPWEK_INVOER_EIG_C')) {
                        showIt = false;
                    } //OR
                    let propEersteOpwekker = ntabuilding.properties['VERW-OPWEK_TOEW'];
                    let eersteOpwekkerProduct = vm.getProduct(propEersteOpwekker, eersteOpwekkerData);
                    //let eersteOpwekkerProductId = parseInt(eersteOpwekkerData.PropertyDatas['VERW-OPWEK_TOEW'].Value);
                    //let eersteOpwekkerProduct = vm.getProduct(.find(function (x) { return x.Id === eersteOpwekkerProductId; });
                    if (eersteOpwekkerProduct) {
                        const subValue = verwarmingLogic.subValuesProducts.find(function (x) { return x.H_Id === eersteOpwekkerProduct.H_valueId; });
                        if (subValue && subValue.Fractie) {
                            showIt = false;
                        }
                    }
                }
            }
        }
        return !showIt;
    } //-- end: opwekkerButtonIsHidden ------------------------------------------------------------------------//

    function distributiepompTabelWidth() {

        let width = verwarmingLogic.verwDistributiePompProperties.length + 2;

        let propVermogenNon = verwarmingLogic.verwDistributiePompProperties['VERW-DISTR_POMP_VER_NON'];
        let propVermogen = verwarmingLogic.verwDistributiePompProperties['VERW-DISTR_POMP_VER'];
        let propEEINon = verwarmingLogic.verwDistributiePompProperties['VERW-DISTR_POMP_EEI_NON'];
        let propEEI = verwarmingLogic.verwDistributiePompProperties['VERW-DISTR_POMP_EEI'];

        if (verwarmingLogic.propertyHeaderIsHidden(propVermogenNon, verwarmingLogic.verwDistributieData)) {
            width--;
        }
        if (verwarmingLogic.propertyHeaderIsHidden(propVermogen, verwarmingLogic.verwDistributieData)) {
            width--;
        }
        if (verwarmingLogic.propertyHeaderIsHidden(propEEINon, verwarmingLogic.verwDistributieData)) {
            width--;
        }
        if (verwarmingLogic.propertyHeaderIsHidden(propEEI, verwarmingLogic.verwDistributieData)) {
            width--;
        }

        if (!verwarmingLogic.allowMultiplePumps()) {
            width -= 2;
        }

        return width;
    } //-- end: distributiepompTabelWidth ------------------------------------------------------------------------//

    function opwekkerTypesAllowed() {
        let result = verwarmingLogic.opwekkerTypes;
        //-- als er meer dan 1 toestel is, mag ik niet meer kiezen voor ONB
        if (vm.verwOpwekkersData().length > 0) {
            result = result.filter(function (x) { return x.key2 !== 'ONB'; }); //-- Conditie [OAM]
            result = result.filter(function (x) { return x.key6 === 'centraal' || x.key6 === ''; }); //-- Conditie [OAK]

            //-- Conditie [OCJ]
            //-- Controleer of er al een opwekker van het type externe warmtelevering is
            for (const verwOpwekker of vm.verwOpwekkersData()) {
                const typeOpwekkerValue = verwOpwekker.PropertyDatas['VERW-OPWEK_TYPE'].Value;
                const opwekType = verwarmingLogic.opwekkerTypes.find(x => x.Id === typeOpwekkerValue) || null;
                if (!opwekType) { continue; }

                if (opwekType.key2 === 'EW') {
                    result = result.filter(function (x) { return x.key2 !== 'EW'; });
                    break;
                }
            }
        }

        return result;
    } //-- end: opwekkerTypesAllowed ------------------------------------------------------------------------//

    function isAddAfgifteVentilatorVisible(afgifte) {
        //-- Ventilatoren HA23 controleren op 'geen ventilatoren aanwezig', conditie [AV] Conditie begint met Hide... Ook Hide als er nog geen invoer is
        const rekenzones = verwarmingLogic.getAfgifteRekenzones(afgifte);
        let showit = rekenzones.length === 1;
        if (showit) {
            const afgifteventilator1 = vm.getAfgifteVentilatoren(afgifte)[0];
            showit = afgifteventilator1 && !geenVentilator(afgifteventilator1);
        }
        return showit;
    } //-- end: isAddAfgifteVentilatorVisible ----------------------------------------//

    function isVentilatorMenuVisible(afgifte, rekenzone = null) {
        const rekenzones = rekenzone && [rekenzone] || verwarmingLogic.getAfgifteRekenzones(afgifte);
        return rekenzones.some(rekenzone => {
            const afgifteventilator1 = vm.getAfgifteVentilatoren(afgifte, rekenzone)[0];
            return !afgifteventilator1 || !geenVentilator(afgifteventilator1);
        });
    } //-- end: isVentilatorMenuVisible ----------------------------------------//

    function geenVentilator(ventilator) {
        return [null, '', 'VERW-AFG-VENT_INV_GEEN'].includes(ventilator.PropertyDatas['VERW-AFG-VENT_INV'].Value);
    }

    function afgifteButtonIsHidden() {
        //-- Conditie [AQ]
        let showit = vm.rekenzones.length > 1 && (vm.verwAfgifteData().length < vm.rekenzones.length);

        return !showit;
    } //-- end: afgifteButtonIsHidden ------------------------------------------------------------------------//

    function isSelectionTableDisabled(prop, entdata) {
        if (!prop || !entdata) {
            return true;
        }

        //-- de selectie tabel mag niet gekozen worden als er maar 1 item in de filterlijst zit tenzij propdata.Value == null
        const propdataValue = entdata.PropertyDatas[prop.Id].Value;
        if (propdataValue) {
            let items = [];
            if (prop.Id === 'VERW-OPWEK_TOEW' || prop.Id === 'VERW-VAT_TYPE') {
                items = entdata.filteredItems;
            } else if (prop.Id === 'VERW-OPWEK_REGIO') {
                items = entdata.filteredItemsRegios;
            } else if (prop.Id === 'VERW-OPWEK_POMP') {
                items = entdata.filteredItemsBron;
            } else if (prop.Id === 'VERW-OPWEK_HT_LOC') {
                items = entdata.filteredItemsLoc;
            }
            return !!items && items.length === 1;
        }

        return false;
    } //-- end: isSelectionTableDisabled ------------------------------------------------------------------------//


    ///******************************************* CRUD akties ******************************************************************///
    function addOpwekker(typeId) {
        let newId = ntaEntityData.create("VERW-OPWEK", -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": verwarmingLogic.verwDataId, "ParentEntityId": "VERW" }], [], [{ "PropertyId": "VERW-OPWEK_TYPE", "Value": typeId }]);

        ntaEntityData.create('BELEMMERING', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newId, "ParentEntityId": "VERW-OPWEK" }], []);

        let opwekker = vm.verwOpwekkersData().find(function (x) { return x.EntityDataId === newId; });
        verwarmingLogic.checkPrioOpwekker(opwekker);
        verwarmingLogic.checkGemeenschappelijkOpwekker(opwekker);

        verwarmingLogic.setEnergiefracties();
        verwarmingLogic.startValidationEnergiefractie();
    } //-- end: addOpwekker ------------------------------------------------------------------------//

    function copyOpwekkerClick(Id) {
        if (!Id) {
            return;
        }

        progressCircle.setShowProgressValue(true, 'kopiëren opwekker');
        const newEntdatas = ntaEntityData.copy(Id);

        let newOpwekker = newEntdatas.find(function (x) { return x.EntityId === 'VERW-OPWEK'; });
        //-- ook de javascript objecten die in de client zijn toegevoegd zoals selectedItem enz. van het product mee kopieren
        const entdata = ntaEntityData.get(Id);
        for (const newEntdata of newEntdatas) {
            if (entdata.EntityId === newEntdata.EntityId) {
                for (const member in entdata) {
                    if (!newEntdata.hasOwnProperty(member) && member !== "$$hashKey") {
                        newEntdata[member] = entdata[member];
                    }
                }
            }
        }
        progressCircle.setShowProgressValue(false);

        verwarmingLogic.checkPrioOpwekker(newOpwekker);

        verwarmingLogic.setEnergiefracties();
        verwarmingLogic.startValidationEnergiefractie();
    } //-- end: copyOpwekkerClick ------------------------------------------------------------------------//

    function deleteOpwekkerClick(Id) {
        if (!Id) {
            return;
        }

        if (vm.verwOpwekkersData().length <= 1) {
            ntaMeldingen.warning("[W018]", 'Verwijderen niet toegestaan');
            return;
        }

        var confirm = $mdDialog.confirm()
            .title('Verwijderen opwekker')
            .textContent('U staat op het punt om deze opwekker te verwijderen. Weet u het zeker?')
            .ariaLabel('Verwijderen')
            .targetEvent(event)
            .ok('Ja')
            .cancel('Nee');

        $mdDialog.show(confirm).then(function () {
            ntaEntityData.delete(Id);

            verwarmingLogic.setEnergiefracties();
            verwarmingLogic.startValidationEnergiefractie();

            vm.initializeUnitSpacing();
        });
    } //-- end: deleteOpwekkerClick ------------------------------------------------------------------------//

    function addBuffervat(typeId) {

        const propValues = [{ "PropertyId": "VERW-VAT_INV", "Value": typeId }, { "PropertyId": "VERW-VAT_AANT", "Value": "1" }];
        ntaEntityData.create("VERW-VAT", -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": verwarmingLogic.verwDataId, "ParentEntityId": "VERW" }], [], propValues);

    } //-- end: addBuffervat ------------------------------------------------------------------------//

    function copyBuffervat(buffervatId) {
        if (!buffervatId) {
            return;
        }

        progressCircle.setShowProgressValue(true, 'kopiëren buffervat');
        const data = ntaEntityData.copy(buffervatId)

        // Haal buffervat gegevens op
        const buffervat = data.find(ed => ed.EntityId === 'VERW-VAT');

        // Verwijder gekopieerde relaties met rekenzones
        for (const rel of ntaEntityData.getParentRelations(buffervat, 'RZ')) {
            ntaEntityData.deleteRelation(rel.EntityRelationDataId);
        }

        progressCircle.setShowProgressValue(false);
    } //-- end: copyBuffervat ------------------------------------------------------------------------//

    function deleteBuffervat(buffervatId) {
        if (!buffervatId) {
            return;
        }

        if (vm.verwVatData().length <= 1) {
            ntaMeldingen.warning("[W141]", 'Verwijderen niet toegestaan');
            return;
        }

        var confirm = $mdDialog.confirm()
            .title('Verwijderen buffervat')
            .textContent('U staat op het punt om dit buffervat te verwijderen. Weet u het zeker?')
            .ariaLabel('Verwijderen')
            .targetEvent(event)
            .ok('Ja')
            .cancel('Nee');

        $mdDialog.show(confirm).then(function () {
            ntaEntityData.delete(buffervatId);

            vm.initializeUnitSpacing();

        });
    } //-- end: deleteBuffervat ------------------------------------------------------------------------//


    function addAfgifte(typeId) {
        progressCircle.setShowProgressValue(true, 'toevoegen afgiftesysteem');

        const propValues = typeId ? [{ "PropertyId": "VERW-AFG_TYPE_AFG", "Value": typeId }] : [];
        const newId = ntaEntityData.create("VERW-AFG", -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": verwarmingLogic.verwDataId, "ParentEntityId": "VERW" }], [], propValues);
        for (const rekenzone of vm.rekenzones) {
            const parentRels = [
                { "OnCopy": 1, "OnDelete": 1, "Parent": newId, "ParentEntityId": "VERW-AFG" },
                { "OnCopy": 1, "OnDelete": 1, "Parent": rekenzone.EntityDataId, "ParentEntityId": rekenzone.EntityId },
            ];
            ntaEntityData.create('VERW-AFG-VENT', -1, parentRels, [], []);
        }

        verwarmingLogic.alleZonesGekoppeldAanAfgiftes();
        verwarmingLogic.alleAfgiftesGekoppeldAanZone();

        progressCircle.setShowProgressValue(false);
    } //-- end: addAfgifte ------------------------------------------------------------------------//

    function copyAfgifteClick(afgifteId) {
        if (!afgifteId) {
            return;
        }

        progressCircle.setShowProgressValue(true, 'kopiëren afgiftesysteem');
        const data = ntaEntityData.copy(afgifteId)

        // Haal afgifte gegevens op
        const afgifte = data.find(ed => ed.EntityId === 'VERW-AFG');

        // Verwijder gekopieerde relaties met rekenzones
        for (const rel of ntaEntityData.getParentRelations(afgifte, 'RZ')) {
            ntaEntityData.deleteRelation(rel.EntityRelationDataId);
        }

        // Controles
        verwarmingLogic.alleZonesGekoppeldAanAfgiftes();
        verwarmingLogic.alleAfgiftesGekoppeldAanZone();
        progressCircle.setShowProgressValue(false);
    } //-- end: copyAfgifteClick ------------------------------------------------------------------------//

    function deleteAfgifteClick(afgifteId) {
        if (!afgifteId) {
            return;
        }

        if (vm.verwAfgifteData().length <= 1) {
            ntaMeldingen.warning("[W019]", 'Verwijderen niet toegestaan');
            return;
        }

        var confirm = $mdDialog.confirm()
            .title('Verwijderen afgiftesysteem')
            .textContent('U staat op het punt om deze afgifte te verwijderen. Weet u het zeker?')
            .ariaLabel('Verwijderen')
            .targetEvent(event)
            .ok('Ja')
            .cancel('Nee');

        $mdDialog.show(confirm).then(function () {
            ntaEntityData.delete(afgifteId);

            vm.initializeUnitSpacing();

            // Controles
            verwarmingLogic.koppelAlleZonesAanLaatsteAfgifte();
        });
    } //-- end: deleteAfgifteClick ------------------------------------------------------------------------//

    function showAfgifteRekenzones(afgifte) {
        return verwarmingLogic.getAfgifteRekenzones(afgifte).length > 1;
    } //-- end: showAfgifteRekenzone ------------------------------------------------------------//

    function getAfgifteRekenzoneVentilatoren(afgifte) {
        const ventModels = [];
        for (const rekenzone of vm.getAfgifteRekenzones(afgifte)) {
            const ventilatoren = vm.getAfgifteVentilatoren(afgifte, rekenzone);
            for (let i = 0; i < ventilatoren.length; i++) {
                ventModels.push({
                    rekenzone,
                    ventilator: ventilatoren[i],
                    ventilatorIndex: i,
                    ventilatorCount: ventilatoren.length,
                });
            }
        }

        return _listCache.useCacheIfUnchanged(afgifte.EntityDataId, ventModels, (a, b) => a.ventilator === b.ventilator && a.rekenzone === b.rekenzone);
    } //-- end: getAfgifteRekenzoneVentilatoren ------------------------------------------------------------//


    function saveRekenzoneKoppeling(afgifte, rz, ev) {
        // koppeling zoeken met deze rekenzone
        const relations = ntaEntityData.getRelationsBetween(rz, afgifte);

        if (relations.length > 0) {
            // koppeling verwijderen
            for (const oldRelation of relations) {
                ntaEntityData.deleteRelation(oldRelation.EntityRelationDataId);
            }
        } else {
            // oude koppeling verwijderen
            const oldRelations = ntaEntityData.getChildRelations(rz, 'VERW-AFG');
            for (const oldRelation of oldRelations) {
                ntaEntityData.deleteRelation(oldRelation.EntityRelationDataId);
            }

            // koppeling toevoegen
            ntaEntityData.createRelation(rz.EntityDataId, afgifte.EntityDataId, false, false);
        }

        verwarmingLogic.alleZonesGekoppeldAanAfgiftes();
        verwarmingLogic.alleAfgiftesGekoppeldAanZone();

        ev.stopImmediatePropagation();
        ev.preventDefault();
    } //-- end: saveRekenzoneKoppeling ------------------------------------------------------------------------//

    function addVentilator(afgifte) {
        if (!afgifte) {
            return;
        }

        vm.addVentilatorProgress = true;
        addAfgifteVentilator(afgifte, vm.getAfgifteRekenzones(afgifte)[0]);
        vm.addVentilatorProgress = false;
    } //-- end: addVentilator ------------------------------------------------------------------------//

    function addAfgifteVentilator(afgifte, rekenzone, ventilator) {
        [afgifte, rekenzone, ventilator] = unpackAfgifteVentilatorArgs(afgifte, rekenzone, ventilator);
        if (!afgifte || !rekenzone) return;

        const parentRels = [
            { "OnCopy": 1, "OnDelete": 1, "Parent": afgifte.EntityDataId, "ParentEntityId": afgifte.EntityId },
            { "OnCopy": 1, "OnDelete": 1, "Parent": rekenzone.EntityDataId, "ParentEntityId": rekenzone.EntityId },
        ];
        ntaEntityData.create('VERW-AFG-VENT', ventilator ? ventilator.Order : -1, parentRels, [], [])
    } //-- end: addAfgiteVentilator ------------------------------------------------------------------------//

    function copyAfgifteVentilator(afgifte, rekenzone, ventilator) {
        [afgifte, rekenzone, ventilator] = unpackAfgifteVentilatorArgs(afgifte, rekenzone, ventilator);
        if (!ventilator) return;

        ntaEntityData.copy(ventilator.EntityDataId);
    } //-- end: copyAfgiteVentilator ------------------------------------------------------------------------//

    function deleteAfgifteVentilator(afgifte, rekenzone, ventilator) {
        [afgifte, rekenzone, ventilator] = unpackAfgifteVentilatorArgs(afgifte, rekenzone, ventilator);
        if (!ventilator || !afgifte) return;

        switch (vm.getAfgifteVentilatoren(afgifte, rekenzone).length) {
            case 0:
                return;
            case 1:
                ventilator.PropertyDatas['VERW-AFG-VENT_INV'].Value = 'VERW-AFG-VENT_INV_GEEN';
                vm.saveValue(ntabuilding.properties['VERW-AFG-VENT_INV'], ventilator);
                return;
            default:
                ntaEntityData.delete(ventilator.EntityDataId);
                break;
        }
    } //-- end: deleteAfgiteVentilator ------------------------------------------------------------------------//

    function unpackAfgifteVentilatorArgs(afgifte, rekenzone, ventilator) {
        if (!afgifte && ventilator) afgifte = ntaEntityData.getFirstParent(ventilator, 'VERW-AFG');
        if (!rekenzone && ventilator) rekenzone = ntaEntityData.getFirstParent(ventilator, 'RZ');
        if (!rekenzone && vm.rekenzones.length === 1) rekenzone = vm.rekenzones[0];
        return [afgifte, rekenzone, ventilator];
    }

    function addPomp(pomp) {
        vm.addPompProgress = true;
        let order = -1;
        if (pomp) {
            order = pomp.Order;
        }
        ntaEntityData.create('VERW-DISTR-POMP', order, [{ "OnCopy": 1, "OnDelete": 1, "Parent": vm.verwDistributieData.EntityDataId, "ParentEntityId": "VERW-DISTR" }], [], []);
        vm.addPompProgress = false;
    } //-- end: addPomp ------------------------------------------------------------------------//

    function copyPomp(pomp) {
        if (!pomp) {
            return;
        }
        ntaEntityData.copy(pomp.EntityDataId);
    } //-- end: copyPomp ------------------------------------------------------------------------//

    function deletePomp(pomp) {
        if (!pomp) {
            return;
        }

        if (vm.verwDistributiePompData().length <= 1) {
            return;
        }

        ntaEntityData.delete(pomp.EntityDataId, true);
    } //-- end: deletePomp ------------------------------------------------------------------------//

    function center(prop) {
        if (prop.Id === 'VENTILATOREIG_DEB') {
            return '';
        }

        return 'columnCenter';
    } //-- end: center ------------------------------------------------------------------------//

    vm.sortableOptionsVentilatoren = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            const rekenzone = ui.item.sortable.model.rekenzone;
            const ventilatoren = ui.item.sortable.droptargetModel
                .filter(vm => vm.rekenzone === rekenzone)
                .map(vm => vm.ventilator);
            ntaEntityData.SaveReOrder(ventilatoren); // Reorder ventilatoren
        }
    } //-- end: sortableOptionsVentilatoren ------------------------------------------------------------------------//

    vm.sortablePompen = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            let pompen = vm.verwDistributiePompData();
            ntaEntityData.SaveReOrder(pompen); // Reorder pompen
        }
    } //-- end: sortablePompen ------------------------------------------------------------------------//



}]);