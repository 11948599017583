﻿angular.module('projectModule')
    .factory('MeasureInfilFactory',
        ['$log', 'ntaData', 'ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaSharedLogic', 'ListCache', 'ntaDeltas',
function ($log,   ntaData,   ntabuilding,   ntaValidation,   ntaEntityData,   ntaSharedLogic,   ListCache,   ntaDeltas) {
    'use strict';

    return function MeasureInfilLogic(measureId, ntaDependencyValidation) {
        const self = this;

        //== Imports ==============================================================================
        self.ntaValidation = ntaValidation;
        self.dependencyValidator = ntaDependencyValidation;

        //== Instance data ========================================================================

        const _entdataMeasure = ntaEntityData.get(measureId);
        const _entdataMeasureInfil = ntaEntityData.getFirstChild(_entdataMeasure, 'MEASURE-INFIL');
        const _listCache = new ListCache();

        const _measureType = ntaData.properties['MEASURE_TYPE'].getCode(_entdataMeasure);

        //== Exports ==============================================================================
        self.measureId = _entdataMeasure.EntityDataId;
        self.entdataMeasureInfil = _entdataMeasureInfil;
        self.properties = ntaData.properties['MEASURE-INFIL'];
        self.measureType = _measureType;

        // al deze methods exporteren zodat ze publiek beschikbaar zijn
        Object.assign(self, {
            // specifieke methods voor maatregelen
            getTileDetails,
            getTotaleKosten,

            // standaard methods tbv validatie
            isReadOnly,
            hasCodedValues,
            getCodedValues,
            saveValue,
            validate,
            validateDependencies,
            startFormValidation,
            endFormValidation,
            setGeopend,
        });

        //== Initialization =======================================================================

        // (geen initialisatie nodig)

        //== Implementation =======================================================================

        function getTileDetails(render) {
            const lines = [];

            const buildingData = getShadowBuildingData(['INFIL', 'INFILUNIT', 'UNIT']);
            const entdataInfil = buildingData.getFirstWithEntityId('INFIL');

            if (conditionB(entdataInfil)) {
                lines.push(render.detail('INFIL_REN_JAAR', entdataInfil));
            }
            if (conditionF()) {
                // [INF-F] verberg indien Z23 = voor projectwoningen; indien Z23 = per gebouw en per appartement toon de waarde van het gebouw uit tabel I05 (bovenste rij)
                const infilUnitGeb = buildingData.getListWithEntityId('INFILUNIT')
                    .find(ed => !ntaEntityData.getFirstParent(ed, 'UNIT'));
                const propdataQv = infilUnitGeb && ['INFILUNIT_QV', 'INFILUNIT_QV_NON', 'INFILUNIT_QV_DEFAULT']
                    .map(propId => infilUnitGeb.PropertyDatas[propId])
                    .find(pd => pd.Relevant);
                if (propdataQv) {
                    lines.push({ name: 'qv;10', value: render.propValue(propdataQv.PropertyId, propdataQv.Value) });
                }
            }
            if (ntaSharedLogic.showCosts()) {
                lines.push({});
                lines.push(render.detail('INFIL_KOSTEN_TOT', entdataInfil));
            }
            return lines;
        } //-- end: getTileDetails ----------------------------------------------------------------

        function getTotaleKosten() {
            const buildingData = getShadowBuildingData(['INFIL']);
            const entdataInfil = buildingData.getFirstWithEntityId('INFIL');
            return ntaSharedLogic.parseFloat(entdataInfil.PropertyDatas['INFIL_KOSTEN_TOT'].Value, 0);
        } //-- end: getTotaleKosten ----------------------------------------------------------------

        function getShadowBuildingData(entityIds = null) {
            return ntaDeltas.getShadowBuildingData(_entdataMeasure.EntityDataId, entityIds);
        } //-- end: getShadowBuildingData ---------------------------------------------------------

        function isReadOnly(prop) {
            if (!prop)
                return true;

            switch (prop.Id) {
                case 'MEASURE_OPEN':
                    return true;
            }

            return false;
        } //-- end: isReadOnly --------------------------------------------------------------------

        function conditionB(entdataInfil) {
            // [INF-B] toon indien INFIL02 = forfaitair op basis van renovatiejaar
            return entdataInfil.PropertyDatas['INFIL_VERV_METHODE'].Value === 'INFIL_VERV_METHODE_FORF';
        } //-- end: conditionB --------------------------------------------------------------------

        function conditionF() {
            // [INF-F] verberg indien Z23 = voor projectwoningen; ...
            return !ntaSharedLogic.voorProjectwoningen();
        } //-- end: conditionE --------------------------------------------------------------------

        function hasCodedValues(prop) {
            return ntaValidation.hasCodedValues(prop);
        } //-- end: hasCodedValues ----------------------------------------------------------------

        function getCodedValues(prop) {
            if (!prop) {
                return [];
            }

            let codedValues = [];
            switch (prop.Id) {
                default: {
                    codedValues = ntaValidation.codedValues(prop);
                    break;
                }
            }

            // Zorg dat we alleen een nieuwe lijst teruggeven als deze gewijzigde waardes heeft,
            //  anders denkt AngularJS steeds dat het om een compleet nieuwe lijst gaat, en triggert
            //  deze oneindige digests.
            return _listCache.useCacheIfUnchanged(prop.Id, codedValues, (a, b) => a.Id === b.Id && a.Value === b.Value);
        } //-- end: getCodedValues ----------------------------------------------------------------

        function saveValue(propOrId, entdata, newValue) {
            const prop = typeof propOrId === 'string' ? ntaData.properties[propOrId] : propOrId;
            switch (prop && prop.Id) {
                default: {
                    break;
                }
            }

            return ntaSharedLogic.saveValue(prop, entdata, newValue, self);
        } //-- end: saveValue ---------------------------------------------------------------------

        function validate(prop, propdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            const hidden = true; //isHidden(prop);
            const readOnly = true; //isReadOnly(prop);

            let valid = ntaValidation.IsValid(self.form, prop, propdata, hidden, readOnly);

            switch (prop.Id) {
                default: {
                    break;
                }
            }

            return valid;
        } //-- end: validate ---------------------------------------------------------------------

        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = prop.getData(entdata);
            const checkValue = propdata.Value;

            let performDefaultChecks = false;

            switch (prop.Id) {
                default: {
                    break;
                }
            }

            if (performDefaultChecks) {
                isHidden(prop, entdata);
                if (ntaValidation.hasCodedValues(prop)) {
                    getCodedValues(prop);
                }
                if (propdata.Value !== checkValue) {
                    saveValue(prop, entdata);
                }
            }
        } //-- end: validateDependencies ----------------------------------------------------------

        function startFormValidation() {
            return ntaSharedLogic.startFormValidation(getAllEntDatas(), self);
        } //-- end: startFormValidation -----------------------------------------------------------

        function endFormValidation() {
            if (![null, _entdataMeasure.EntityDataId].includes(ntaData.current.shadowId)) return [];

            return ntaSharedLogic.endFormValidation(getAllEntDatas(), self);
        } //-- end: endFormValidation -------------------------------------------------------------

        function getAllEntDatas() {
            return []
                .concat(_entdataMeasureInfil)

                .filter(ed => ed);
        } //-- end: getAllEntDatas ----------------------------------------------------------------

        function setGeopend() {
            const propdataOpen = _entdataMeasure.PropertyDatas['MEASURE_OPEN'];
            ntaEntityData.saveprop(propdataOpen, 'true');
        } //-- end: setGeopend --------------------------------------------------------------------

    };
}]);
