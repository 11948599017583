angular.module('projectModule')
    .service('ntaMeldingen',
        ['$log', '$mdDialog', '$mdToast', 'ntaData', 'projecttree', 'ntaDeltas', 'NtaEntityDataFactory',
function ($log,   $mdDialog,   $mdToast,   ntaData,   projecttree,   ntaDeltas,   NtaEntityDataFactory) {
    'use strict';
    const self = this;

    const ntaEntityData = new NtaEntityDataFactory();

    self.meldingen = [];

    const _visibleWarnings = new Set(); // de warningCodes die momenteel weergegeven worden
    let _warningPromise;                // de promise van de $mdDialog, of undefined als er geen dialog weergegeven wordt.

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Interface
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    self.melding = function (errorId, inputId, valid, msg = "", shadowId = ntaData.current.shadowId) {
        // msg is optioneel -> indien geen msg dan wordt de standaard error msg getoond

        const result = {
            newId: null,
            existing: null,
            deleted: [],
        };

        if (Array.isArray(inputId)) {
            inputId = inputId.join('|');
        }

        // bestaande melding(en) -> check op errorId en InputId
        const meldingen = self.getMeldingen(true)
            .filter(melding => melding.PropertyDatas['MELD_ERRORID'].Value === errorId
                            && melding.PropertyDatas['MELD_INPUTID'].Value === inputId
                            && (!melding.PropertyDatas['MELD_SHADOWID'] || melding.PropertyDatas['MELD_SHADOWID'].Value === shadowId)
            );

        if (valid) {
            for (const melding of meldingen) {
                ntaEntityData.delete(melding.EntityDataId);
            }
            result.deleted = meldingen;
        } else if (meldingen.length === 0) { // melding bestaat nog niet. -> aanmaken
            //check of entityData van inputId bestaat, anders niet toevoegen
            if (self.isExistingInput(inputId, shadowId)) {
                const [url, formId] = self.getInputUrlAndFormId(inputId, shadowId);
                const data = [
                    { PropertyId: 'MELD_OMSCHR',    Value: msg },
                    { PropertyId: 'MELD_ERRORID',   Value: errorId },
                    { PropertyId: 'MELD_INPUTID',   Value: inputId },
                    { PropertyId: 'MELD_SHADOWID',  Value: shadowId || null },
                    { PropertyId: 'MELD_URL',       Value: url },
                    { PropertyId: 'MELD_FORMID',    Value: formId },
                ];
                result.newId = ntaEntityData.create(ntaData.buildingId, 'MELDING', -1, [], [], data);
            }
        } else if (meldingen.length > 1) { //check of er per ongeluk meerdere meldingen zijn -> Zo ja, verwijderen !
            for (const melding of meldingen.slice(1)) {
                ntaEntityData.delete(melding.EntityDataId);
            }
            result.existing = meldingen[0];
            result.deleted = meldingen.slice(1);
        }

        return result;
    };

    self.warning = async function (warningCodes, title = '', replaces = []) {
        //-- VO 2021-09-27: Warning krijgt nu een code of een array van codes mee.
        if (!Array.isArray(warningCodes)) warningCodes = [warningCodes];
        if (warningCodes.length <= 0) return;

        // FilterValue1 = de titel
        // FilterValue2 = de tekst van de ‘OK’ button
        // FilterValue3 = de tekst van de ‘cancel’ button
        // (Als FilterValue3 niet opgegeven is, wordt `alert` gebruikt, anders `confirm`).
        const warnings = ntaData.warnings.filter(w => warningCodes.includes(w.Id));
        const warning = {};
        if (warnings.length === 0) {
            $log.error(`Warning(s) ${warningCodes.join()} niet bekend!`);
            warning.Value = 'Door een technisch probleem kan de inhoud van deze waarschuwing niet weergegeven worden. Neem contact op met de helpdesk. ' + warningCodes.join();
            warning.FilterValue1 = warningCodes.join();
        } else {
            warning.FilterValue1 = warnings[0].FilterValue1;
            warning.FilterValue2 = warnings[0].FilterValue2;
            warning.FilterValue3 = warnings[0].FilterValue3;
        }

        const alert = warning.FilterValue3 ? $mdDialog.confirm() : $mdDialog.alert();
        alert.title(title || warning.FilterValue1 || 'waarschuwing');
        warning.Value = warnings.map(w => w.Value).join('<br><br>');

        for (const replace of replaces) {
            warning.Value = warning.Value.replace(replace.from, replace.to);
        }

        alert.htmlContent(warning.Value);
        alert.ok(warning.FilterValue2 || 'sluiten');
        if (warning.FilterValue3) {
            alert.cancel(warning.FilterValue3);
        }
        try {
            // Voorkom knipperlicht-weergave van dezelfde warning
            let result;
            if (_warningPromise) {
                if (warningCodes.every(code => _visibleWarnings.has(code))) {
                    return await _warningPromise;
                }
                try {
                    result = await _warningPromise;
                } catch (err) {
                    result = false;
                }
            }
            _visibleWarnings.clear();
            warningCodes.forEach(code => _visibleWarnings.add(code));
            _warningPromise = $mdDialog.show(alert);
            try {
                result = (await _warningPromise) || result;
            } finally {
                _warningPromise = undefined;
                _visibleWarnings.clear();
            }
            return result;
        } catch (err) {
            return false;
        }
    };

    self.toast = function (errorId, msg = "") {
        if (!msg) {
            const error = ntaData.errors[errorId];
            if (error && error.Value) {
                msg = error.Value;
            }
        }

        $mdToast.show({
            templateUrl: '/src/app/templates/toast-template.html?v=' + ntaData.commit,
            controller: 'ToastController',
            locals: { errorStr: msg },
            hideDelay: 3000,
            position: 'top right'
        });
    };

    self.confirm_before_save_dialog = function (title, content, form, propdata, value, savefunc) {
        if (propdata.Value !== value && propdata.Value) { // verschilt -> bevestiging vragen
            const oldValue = propdata.Value; //is nodig want value wordt op undefined gezet, na return false;
            const confirm = $mdDialog.confirm({
                    onComplete: function afterShowAnimation() {
                        const dialog = angular.element(document.querySelector('md-dialog'));
                        const actionsSection = dialog.find('md-dialog-actions');
                        const [cancelButton, confirmButton] = actionsSection.children();
                        angular.element(confirmButton).removeClass('md-focused');
                        angular.element(cancelButton).addClass('md-focused');
                        cancelButton.focus();
                    }
                })
                .title(title)
                .textContent(content)
                .ariaLabel("bevestig")
                .clickOutsideToClose(true)
                .escapeToClose(true)
                .ok('Ja')
                .cancel('Nee');

            const contrl = form['ntainput' + propdata.PropertyDataId];
            $mdDialog.show(confirm).then(function () {
                contrl.$error = {};
                propdata.Value = value;
                savefunc();
            }, function () {
                contrl.$error = {};
                //-- als we niet willen wisselen van value, moet de saveprop aangeroepen worden, zodat niet de
                //-- depedencyvalidation af gaat. En alle data blijft zoals het was en niet eventueel opnieuw geinitialiseerd.
                ntaEntityData.saveprop(propdata, oldValue);
            });
            return false; // eerst invoer invalid maken, zodat het niet wordt opgeslagen. Pas na keuze dialoog weer valid maken
        }
        return true;
    };

    self.isExistingInput = function (inputId, shadowId) {
        if (!inputId) return false;

        const inputInfos = splitInputIds(inputId);

        return inputInfos.every(inputInfo => {
            const { entdataId, propId } = inputInfo;

            let buildingData = ntaData.original;

            if (shadowId) {
                if (ntaData.shadow && shadowId === ntaData.shadow.shadowId) {
                    // dan kunnen we gewoon kijken in de schaduwomgeving
                    buildingData = ntaData.shadow;
                } else {
                    // dan zit inputId in een schaduwomgeving die we niet bij de hand hebben,
                    // en zullen we deze moeten opzoeken in de delta’s
                    const deltasByKey = ntaData.deltas.get(shadowId);
                    if (deltasByKey) {
                        const delta = deltasByKey.get(`NTAEntityData_${entdataId}`);
                        if (delta) {
                            if (delta.Action === 'Delete') {
                                // Als de entiteit verwijderd is, dan bestaat-ie sowieso niet.
                                return false;
                            } else if (!propId) {
                                // We hebben een delta voor de entiteit, en het is geen propdata: hij bestaat.
                                return true;
                            } else {
                                // Het is een propdata; dan moeten we daarvan de delta controleren.
                                const propDelta = deltasByKey.get(`NTAPropertyData_${entdataId}:${propId}`);
                                if (propDelta) {
                                    return propDelta.Action !== 'Delete';
                                }
                            }
                        }
                    }

                    // als er geen delta voor is, dan moeten we in de basisberekening kijken of we ’m kunnen vinden
                    buildingData = ntaData.original;
                }
            }

            if (!buildingData) return false;

            const entdata = buildingData.get(entdataId);
            return !!entdata && (!propId || !!entdata.PropertyDatas[propId]);
        });
    }; //-- end: isExistingInput ------------------------------------------------------------------

    self.getMeldingInputs = function (inputId, shadowId = null, buildingData = shadowId ? ntaData.shadow : ntaData.original) {
        if (!buildingData || buildingData.getShadowId() !== shadowId) {
            return [];
        }

        return splitInputIds(inputId)
            .map(info => buildingData.get(info.entdataId))
            .filter(entdata => entdata);
    }; //-- end: getMeldingInputs -----------------------------------------------------------------

    self.getMeldingen = function (includingHidden = false) {
        let meldingen = ntaEntityData.getListWithEntityId('MELDING', ntaData.buildingId, true);

        if (!includingHidden) {
            const showVariants = projecttree.showVariants();
            const isTailoredAdvice = projecttree.showCosts();
            const tailoredAdviceEntityIds = ['MEASURE-COSTS', 'GEBR-PROFIEL', 'GEBR-RZ', 'GEBR-RZ-VERW', 'GEBR-RZ-KOEL', 'GEBR-RZ-TAPW', 'GEBR-RZ-BEZF', 'GEBR-RZ-WARMTEPROD', 'GEBR-RZ-VERL'];
            const tailoredAdvicePropIds = new Set(tailoredAdviceEntityIds.flatMap(entityId => (ntaData.properties[entityId] || [])).map(prop => prop.Id));

            const visibleMeldingen = [], hiddenMeldingen = [], deletedMeldingen = [];
            for (const melding of meldingen) {
                shouldShowMelding(melding).push(melding);
            }
            ntaEntityData.setEntityVisibility(visibleMeldingen, true);
            ntaEntityData.setEntityVisibility(hiddenMeldingen, false);
            for (const melding of deletedMeldingen) {
                self.delete(melding);
            }

            meldingen = visibleMeldingen;

            // Onderstaande hulpfunctie bepaalt of een melding weergegeven moet worden, verborgen of verwijderd,
            //  en geeft voor elke melding de lijst terug waaraan deze moet worden toegevoegd.
            // Het is een subfunctie omdat anders bovenstaande waardes iedere keer opnieuw uitgevogeld moeten worden.
            function shouldShowMelding(melding) {
                const propdataShadowId = melding.PropertyDatas['MELD_SHADOWID'];
                const shadowId = propdataShadowId && propdataShadowId.Value || null;
                if (shadowId && !showVariants) {
                    return hiddenMeldingen;
                }

                const inputId = melding.PropertyDatas['MELD_INPUTID'].Value;
                if (!inputId) {
                    return deletedMeldingen; // een melding zonder INPUTID kan verwijderd worden
                }

                const inputExists = self.isExistingInput(inputId, shadowId);
                if (!inputExists) {
                    return deletedMeldingen; // een melding die nergens over gaat kan ook weg.
                }

                if (shadowId && !isTailoredAdvice) {
                    // verberg dan meldingen over kostengerelateerde velden
                    if (splitInputIds(inputId).some(inputInfo => tailoredAdvicePropIds.has(inputInfo.propId))) {
                        return hiddenMeldingen;
                    }
                }

                const inputs = self.getMeldingInputs(inputId, shadowId);
                if (inputs.length) {
                    const propIds = splitInputIds(inputId).map(info => info.propId).filter(propId => propId);
                    for (const input of inputs) {
                        if (!input.Visible || !input.Relevant) {
                            return deletedMeldingen; // een melding over een onzichtbare entiteit kan ook weg.
                        }
                        for (const propId of propIds) {
                            const propdata = input.PropertyDatas[propId];
                            if (propdata) {
                                // als het veld niet zichtbaar is, dan de melding ook niet
                                if (!propdata.Visible) {
                                    return hiddenMeldingen;
                                }
                                // als het veld niet relevant is, dan de melding wel tonen als het een resultaatveld is
                                if (!propdata.Relevant && !ntaData.properties[propId].CalcResult) {
                                    return hiddenMeldingen;
                                }
                            }
                        }
                    }
                }

                return visibleMeldingen;
            } //-- end: shouldShowMelding ---------------------------------------------------------
        }

        return meldingen;
    }; //-- end: getMeldingen ---------------------------------------------------------------------

    self.delete = function (melding) {
        melding && ntaEntityData.delete(melding.EntityDataId);
    }; //-- end: delete ---------------------------------------------------------------------------

    self.deleteObsolete = function (buildingData = ntaData.current) {
        const currentShadowId = buildingData.getShadowId();

        const buildingDataByShadowId = new Map();

        const meldingen = self.getMeldingen(true);
        let deletedCount = 0;
        for (const melding of meldingen) {
            let deleteMelding = false;

            // Verwijder meldingen waarvan de shadowId niet (meer) bestaat
            const propdataShadowId = melding.PropertyDatas['MELD_SHADOWID'];
            const meldingShadowId = propdataShadowId && propdataShadowId.Value || null;
            deleteMelding = meldingShadowId && !ntaData.original.get(meldingShadowId);

            // Verwijder meldingen die uit de rekenkern of uit de koppellaag komen
            if (!deleteMelding) {
                const propdataErrorId = melding.PropertyDatas['MELD_ERRORID'];
                const errorId = propdataErrorId && propdataErrorId.Value;
                const firstChar = errorId && errorId[0];
                deleteMelding = !firstChar || firstChar === 'R';
            }

            if (!deleteMelding) {
                // Verwijder meldingen waarvan de inputId niet (meer) bestaat, of niet relevant is.
                // Dit alleen doen voor de huidige (schaduw)omgeving.
                const propdataInputId = melding.PropertyDatas['MELD_INPUTID'];
                const inputId = propdataInputId && propdataInputId.Value;
                if (!inputId) {
                    deleteMelding = true;
                } else {
                    let meldingBD = buildingDataByShadowId.get(meldingShadowId);
                    if (!meldingBD) {
                        meldingBD = meldingShadowId === currentShadowId
                            ? buildingData
                            : ntaDeltas.getShadowBuildingData(meldingShadowId); // TODO: in geval van een VARIANT moeten de delta’s hier ook mee...
                        buildingDataByShadowId.set(meldingShadowId, meldingBD);
                    }
                    for (const inputInfo of splitInputIds(inputId)) {
                        const { entdataId, propId } = inputInfo;
                        const input = meldingBD.get(entdataId);
                        const propdata = input && input.PropertyDatas[propId];
                        deleteMelding = !input || !input.Relevant || (propdata && !propdata.Relevant) || (propId && !propdata);
                        if (deleteMelding) {
                            break; // als één input niet bestaat, dan hoeven we niet verder te zoeken
                        }
                    }
                }
            }

            if (deleteMelding) {
                self.delete(melding);
                deletedCount++;
            }
        }

        return deletedCount;
    }; //-- end: deleteObsolete -------------------------------------------------------------------

    const _begrenzingChildEntityIds = new Set([
        "CONSTRD", "CONSTRT", "CONSTRL", "CONSTRP", "CONSTRWG", "CONSTRKENMW", "CONSTRKENMV", "CONSTRKENMKR", "CONSTRERROR", "CONSTRWWGVL", "CONSTRWWKLDR", "CONSTRZOMNAC", "BELEMMERING"
    ]);
    const _installationChildEntityIds = new Set([
        'PV', 'PV-PVT', 'PV-VELD', 'ZONNBSYS', 'ZONNB', 'ZONNECOL', 'BOILERVAT',
        'VENTDIS', 'VENTCAP', 'VENTDEB', 'VENTZBR', 'WARMTETERUG', 'VENTAAN', 'VOORWARM', 'VENTILATOR', 'VENTILATOREIG', 'WINDT', 'VENT', 'WARMTE-TOEV-KAN', 'VENT-VERB',
        'VERW', 'VERW-OPWEK', 'VERW-DISTR', 'VERW-DISTR-BIN', 'VERW-DISTR-BUI', 'VERW-DISTR-EIG', 'VERW-AFG', 'VERW-AFG-VENT', 'VERW-DISTR-POMP',
        'KOEL', 'KOEL-OPWEK', 'KOEL-DISTR', 'KOEL-DISTR-BIN', 'KOEL-DISTR-BUI', 'KOEL-DISTR-EIG', 'KOEL-AFG', 'KOEL-AFG-VENT', 'KOEL-DISTR-POMP',
        'TAPW', 'TAPW-OPWEK', 'TAPW-DISTR', 'TAPW-DISTR-BIN', 'TAPW-DISTR-BUI', 'TAPW-DISTR-EIG', 'TAPW-AFG', 'TAPW-AFG-VENT', 'TAPW-DOUCHE', 'TAPW-DOUCHE-AANG', 'TAPW-AFG-LEI', 'TAPW-VAT', 'TAPW-DISTR-POMP',
        'BELEMMERING'
    ]);

    self.getInputUrlAndFormId = function (inputId, shadowId = null, buildingData = shadowId ? ntaData.shadow : ntaData.original) {
        if (!buildingData || buildingData.getShadowId() !== shadowId) {
            return [];
        }

        // Alle meldingen van een VARIANT moeten naar het formulier van die variant leiden, ongeacht waar de melding over gaat.
        const shadowEntdata = shadowId && ntaData.original.get(shadowId);
        if (shadowEntdata && shadowEntdata.EntityId === 'VARIANT') {
            return self.getInputUrlAndFormId(shadowId);
        }

        let formId = null;
        let url = '';

        let entdatas = self.getMeldingInputs(inputId, shadowId, buildingData);
        let entdata = entdatas[0]; // We kijken vooralsnog alleen maar naar de eerste input
        if (entdata) {
            if (entdata.EntityId === 'MEASURE-COSTS') {
                // Deze entiteit bestaat in principe alleen in de schaduwomgeving.
                // Deze zit onder de INSTALLATIE, maar hier moeten we het formulier van het systeem hebben.
                const installation = buildingData.getFirstParent(entdata, 'INSTALLATIE');
                entdata = buildingData.getFirstChild(installation, installation.PropertyDatas['INSTALL_TYPE'].Value.substring(5));
            }

            formId = ntaData.entities[entdata.EntityId].FormId;
            if (!formId || formId.length === 0) { // Indien geen formId dan formId van parent -> bijvoorbeeld bij Belemmering
                const parent = buildingData.getFirstParent(entdata);
                formId = ntaData.entities[parent.EntityId].FormId;
            }
            const form = ntaData.forms[formId];
            if (!form) {
                $log.error(`Geen form gevonden voor ${inputId}/${shadowId}!`);
                return [];
            }

            let formEntdataId;
            if (entdata.EntityId === "BEGR") { //Begrenzing kent één parent. Kan wel van twee verschillende Entiteiten zijn (RZ of UNIT-RZ)!
                //-- controleren of het echt een parent is met onCopy en onDelete = true;
                const begrenzingParentRel = buildingData.getParentRelations(entdata)
                    .find(rel => rel.OnDelete && ['RZ', 'UNIT-RZ', 'GRUIMTE'].includes(rel.ParentEntityId));
                formEntdataId = begrenzingParentRel && begrenzingParentRel.Parent || null;
            }
            if (!formEntdataId && _begrenzingChildEntityIds.has(entdata.EntityId)) {
                const begrEnt = buildingData.getFirstParent(entdata, "BEGR", true); //crawl totdat je BEGR hebt gevonden
                if (begrEnt) {
                    formEntdataId = begrEnt.EntityDataId;
                }
            }
            if (!formEntdataId && _installationChildEntityIds.has(entdata.EntityId)) {
                const installEnt = buildingData.getFirstParent(entdata, "INSTALLATIE", true, "RZ"); //crawl totdat je INSTALLATIE hebt gevonden -> FirstParent kan soms RZ zijn, die tak is niet goed !
                if (installEnt) {
                    formEntdataId = installEnt.EntityDataId;
                }
            }
            if (!formEntdataId && entdata.EntityId.startsWith('MEASURE')) {
                const measureEntdata = entdata.EntityId === 'MEASURE'
                    ? entdata
                    : buildingData.getFirstParent(entdata, 'MEASURE', true);
                if (measureEntdata) {
                    formEntdataId = measureEntdata.EntityDataId;
                }
            }

            url = form.Url;
            if (formEntdataId) {
                url += "/" + formEntdataId;
            }
        }

        if (shadowId) {
            // Zet de URL van de maatregel vooraan
            const [shadowUrl] = self.getInputUrlAndFormId(shadowId);
            // bij maatregelen wordt /Installaties gewoon de maatregelpagina zelf, en valt het stukje /Installatie weg.
            // bij windturbinemaatregel moet heel het stuk windturbine weg
            url = url.replace(/^(Installaties?(\/inst_windt\/[0-9a-f-]{32,36}|\/inst_verl)?|Luchtdoorlaten)\/?/, '');
            if (url) {
                url = shadowUrl + '/' + url;
            } else {
                url = shadowUrl;
            }
        }

        return [url, formId];
    } //-- end: getInputUrlAndFormId --------------------------------------------------------------

    function splitInputIds(inputId) {
        return inputId.split('|')
            .map(inputId => {
                const index = inputId.indexOf(':');
                let [entdataId, propId] = (index > -1)
                    ? [inputId.substring(0, index), inputId.substring(index + 1)]
                    : [inputId];
                return {
                    entdataId,
                    propId,
                };
            });
    } //-- end: splitInputId ----------------------------------------------------------------------

}]);

//Controllers
angular.module('projectModule').controller('ToastController', ['$scope', 'errorStr', function ($scope, errorStr) {
    $scope.erroStr = errorStr;
}]);

angular.module('projectModule').controller('MessagesController', ['$mdSidenav', '$log', 'ntaMeldingen', 'time', function ($mdSidenav, $log, ntaMeldingen, time) {
    'use strict';
    const vm = this;

    vm.toggleRight = () => $mdSidenav('right').toggle();

    vm.openRight = () => $mdSidenav('right').open();

    vm.GetMessageCount = () => ntaMeldingen.meldingen.length;

    vm.MessageCount = vm.GetMessageCount();

    vm.IsNew = function () {
        if (ntaMeldingen.meldingen.length > vm.MessageCount) {
            if (vm.NewTimer === 0) {
                vm.NewTimer = 1;
                time.delay(2000).then(() => {
                    vm.MessageCount = vm.GetMessageCount();
                    vm.NewTimer = 0;
                });
            }
            return true;
        } else {
            vm.MessageCount = vm.GetMessageCount();
            vm.NewTimer = 0;
            return false;
        }
    };

    vm.NewTimer = 0;

}]);

angular.module('projectModule')
    .controller('MessagesRightController',
        ['$mdSidenav', '$log', '$scope', '$timeout', '$mdDialog', 'ntabuilding', 'ntaData', 'NtaEntityDataFactory', 'projecttree', 'ntaMeldingen', 'ntaDeltas',
function ($mdSidenav,   $log,   $scope,   $timeout,   $mdDialog,   ntabuilding,   ntaData,   NtaEntityDataFactory,   projecttree,   ntaMeldingen,   ntaDeltas) {
    'use strict';
    const vm = this;

    const ntaEntityData = new NtaEntityDataFactory();

    vm.showmeldingen = function () {
        return ntaMeldingen.meldingen;
    };

    vm.close = function () {
        // Component lookup should always be available since we are not using `ng-if`
        $mdSidenav('right').close()
    };

    function getEntMeldingen() {
        return ntaMeldingen.getMeldingen();
    }

    function getPropertyValues(entityData, ...propertyIds) {
        return entityData && propertyIds.map(function (propId) {
            const propdata = entityData.PropertyDatas[propId];
            return propdata && propdata.Value;
        }) || [];
    }

    $scope.$watchCollection(getEntMeldingen, function (newVal, oldVal, scope) {
        const oldSet = new Set(oldVal);
        const newSet = new Set(newVal);

        const diffAdded = newVal.filter(x => !oldSet.has(x));
        if (diffAdded.length > 0) {
            addMeldingen(diffAdded);
        }

        const diffDeleted = oldVal.filter(x => !newSet.has(x));
        if (diffDeleted.length > 0) {
            removeMeldingen(diffDeleted);
        }

        const propdata = ntaEntityData.getFirstWithEntityId('GEB').PropertyDatas['GEB_HASMELD'];
        if (propdata) {
            ntaEntityData.saveprop(propdata, ntaMeldingen.meldingen.length > 0 ? "True" : "False");
        }
    });

    function addMeldingen(entMeldingen) {
        //doorloop meldingen, kijk of er eenzelfde melding in meldingen is, kijk of deze melding nog niet in ntaMeldingen.meldingen zit -> melding aanmaken.
        //entMelding toevoegen aan ntaMeldingen.meldingen
        const buildingDataByShadowId = new Map();

        for (const entMelding of entMeldingen) {
            let [omschr, errorId, inputId, shadowId, url, formId] = getPropertyValues(entMelding, 'MELD_OMSCHR', 'MELD_ERRORID', 'MELD_INPUTID', 'MELD_SHADOWID', 'MELD_URL', 'MELD_FORMID');

            // Oude MELDINGen hebben nog geen MELD_FORMID of MELD_URL, dus die moeten we nu achterhalen.
            if (!url) {
                let buildingData = ntaData.original;
                if (shadowId) {
                    // Het is potentieel mogelijk dat een MELDING is aangemaakt op de server, en dat die geen URL of INPUTID heeft gekregen. Die moeten we nu dus achterhalen.
                    buildingData = buildingDataByShadowId.get(shadowId);
                    if (!buildingData) {
                        if (ntaData.shadow && ntaData.shadow.shadowId === shadowId) {
                            buildingData = ntaData.shadow;
                        } else {
                            buildingData = ntaDeltas.getShadowBuildingData(shadowId);
                        }
                        buildingDataByShadowId.set(shadowId, buildingData);
                    }
                }
                [url, formId] = ntaMeldingen.getInputUrlAndFormId(inputId, shadowId, buildingData);
                if (!url) {
                    ntaMeldingen.delete(entMelding);
                    $log.warn('Melding bevatte geen MELD_URL, en deze kon ook niet achterhaald worden.', entMelding);
                    continue;
                }

            // Maar controleer ook meldingen met een MELD_URL of de INPUTID nog wel bestaat
            } else if (!ntaMeldingen.isExistingInput(inputId, shadowId)) {
                ntaMeldingen.delete(entMelding);
                continue;
            }

            const message = omschr || ntaData.errors[errorId] && ntaData.errors[errorId].Value || '';

            const existingMelding = ntaMeldingen.meldingen.find(m => m.origMessage === message && m.url === url);
            if (!existingMelding) { // Melding bestond nog niet
                const shadowEntdata = shadowId && ntaEntityData.get(shadowId);
                const shadowTitle = shadowEntdata && ntaData.entities[shadowEntdata.EntityId].Name + ' ' + (getPropertyValues(shadowEntdata, 'MEASURE_NAAM', 'VARIANT_NAAM').find(omschr => omschr) || '');
                const form = ntaData.forms[formId];
                const formTitle = form && form.Description || '';
                const title = formTitle && shadowTitle
                    ? formTitle + ' - ' + shadowTitle
                    : formTitle || shadowTitle;

                ntaMeldingen.meldingen.push({
                    id: uuid.v4(),
                    buildingId: entMelding.BuildingId,
                    title,
                    message,
                    origMessage: message,
                    url,
                    entMeldingen: new Set([entMelding]),
                });
            } else { // Melding bestond al wel
                if (existingMelding.entMeldingen.size === 1) {
                    existingMelding.message = "Meerdere meldingen voor: " + message;
                }
                existingMelding.entMeldingen.add(entMelding);
            }
        }
    } //-- end: addMeldingen ----------------------------------------------------------------------

    function removeMeldingen(entMeldingen) {
        const entMeldingenSet = new Set(entMeldingen);
        // meldingenlijst van eind naar begin doorlopen zodat we indexes onderweg kunnen verwijderen
        for (let index = ntaMeldingen.meldingen.length - 1; index >= 0; index--) {
            const melding = ntaMeldingen.meldingen[index];
            for (const entMelding of entMeldingenSet) {
                if (melding.entMeldingen.delete(entMelding)) { // entMelding verwijderen
                    if (melding.entMeldingen.size === 0) { // geen entMeldingen meer => melding verwijderen
                        ntaMeldingen.meldingen.splice(index, 1);
                    } else if (melding.entMeldingen.size === 1) {
                        melding.message = melding.origMessage; // niet langer ‘meerdere meldingen voor’
                    }
                    entMeldingenSet.delete(entMelding);
                }
            }
            if (entMeldingenSet.size === 0) break; // als ze allemaal verwijderd zijn, stoppen
        }
    } //-- end: removeMeldingen -------------------------------------------------------------------

    function initializeMeldingen() {
        ntaMeldingen.meldingen = [];
        const entMeldingen = getEntMeldingen();
        addMeldingen(entMeldingen);
    } //-- end: initializeMeldingen ---------------------------------------------------------------


    vm.goTo = function (melding) {
        const origin = document.getElementById(`melding-${melding.id}`);
        vm.close();

        const dialogPrefix = 'dialog:';
        if (melding.url?.startsWith(dialogPrefix)) {
            $mdDialog.show({
                templateUrl: melding.url.substring(dialogPrefix.length),
                parent: angular.element(document.body),
                openFrom: origin || '#messages-bell',
                closeTo: '#messages-bell',
                clickOutsideToClose: true,
            });
        } else {
            const path = `/Project/${ntabuilding.projectId}/Berekening/${melding.buildingId}/${melding.url}`;

            let link = document.querySelector("a[href='" + path + "']");
            if (!link) {
                link = document.querySelector("a[href^='" + path + "']")
                    || document.querySelector("a[href='" + path.substring(1) + "']");
                if (link) {
                    $log.warn(`Menu-item met path '${path}' niet gevonden, wel '${link.pathname}' (voor deze melding:`, melding, `).`);
                }
            }
            if (link) {
                setTimeout(() => link.click());
                // als we de boom hebben moeten uitklappen, dan herstellen we deze nu
                if (melding.origExpanded) {
                    Object.assign(projecttree.expanded, melding.origExpanded);
                    projecttree.expanded[melding.expandedLast] = true; // dit is waar we de melding hebben gevonden; die moet open blijven
                    delete melding.origExpanded;
                    delete melding.expandedLast;
                }
            } else {
                const name = Object.keys(projecttree.expanded)
                    .find(key => !projecttree.expanded[key]);
                if (name) {
                    if (!melding.origExpanded) {
                        // Voordat we de boom uitklappen, onthouden we hoe de boom oorspronkelijk uitgeklapt was
                        melding.origExpanded = Object.assign({}, projecttree.expanded);
                    }
                    melding.expandedLast = name;
                    projecttree.expanded[name] = true;
                    $timeout(() => vm.goTo(melding));
                } else {
                    $log.error(`Menu-item met path '${path}' niet gevonden (voor deze melding: `, melding, `).`);
                    // als we de boom hebben moeten uitklappen, dan herstellen we deze nu
                    if (melding.origExpanded) {
                        Object.assign(projecttree.expanded, melding.origExpanded);
                        delete melding.origExpanded;
                        delete melding.expandedLast;
                    }
                }
            }
        }
    };

    initializeMeldingen();
}]);