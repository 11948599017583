﻿angular.module('projectModule')
    .factory('MeasureWindtFactory',
        ['$log', 'ntaData', 'ntabuilding', 'ntaValidation', 'ntaEntityDataOrg', 'ntaSharedLogic', 'ntaDeltas',
function ($log,   ntaData,   ntabuilding,   ntaValidation,   ntaEntityDataOrg,   ntaSharedLogic,   ntaDeltas) {
    'use strict';

    return function MeasureWindtLogic(measureId, ntaDependencyValidation) {
        const self = this;

        //== Imports ==============================================================================
        self.ntaValidation = ntaValidation;
        self.dependencyValidator = ntaDependencyValidation;

        //== Instance data ========================================================================
        const _measureId = measureId;
        const _entdataMeasure = ntaEntityDataOrg.get(_measureId);
        const _entdataMeasureWindt = ntaEntityDataOrg.getFirstChild(_entdataMeasure, 'MEASURE-WINDT');

        const _measureType = ntaData.properties['MEASURE_TYPE'].getCode(_entdataMeasure);

        //== Exports ==============================================================================
        self.entdataMeasureWindt = _entdataMeasureWindt;
        self.properties = ntaData.properties[_entdataMeasureWindt.EntityId];
        self.measureType = _measureType;
                                                                                                
        // al deze methods exporteren zodat ze publiek beschikbaar zijn
        Object.assign(self, {
            canHaveSubmeasures: false,

            // specifieke methods voor maatregelen
            getTileDetails,
            getTotaleKosten,

            // standaard methods tbv validatie
            saveValue,
            validate,
            validateDependencies,
            startFormValidation,
            endFormValidation,
            setGeopend,
        });

        //== Initialization =======================================================================

        // (geen initialisatie nodig)


        //== Implementation =======================================================================

        function getTileDetails(render) {
            const lines = [];

            if (ntaSharedLogic.showCosts()) {
                lines.push({ name: 'totale kosten', value: render.propValue('MEASURE-COSTS_TOTAAL', getTotaleKosten()) });
            }

            return lines;
        } //-- end: getTileDetails ----------------------------------------------------------------

        function getTotaleKosten() {
            const buildingData = ntaDeltas.getShadowBuildingData(_measureId, ['INSTALLATIE', 'MEASURE-COSTS']);
            const installation = buildingData.getListWithEntityId('INSTALLATIE')
                .find(ed => ed.PropertyDatas['INSTALL_TYPE'].Value === 'INST_WINDT');
            const entdataCosts = buildingData.getFirstChild(installation, 'MEASURE-COSTS');
            const propdataTotal = entdataCosts && entdataCosts.PropertyDatas['MEASURE-COSTS_TOTAAL'];
            const amount = ntaSharedLogic.parseFloat(propdataTotal && propdataTotal.Value, 0);
            return amount;
        } //-- end: getTotaleKosten ---------------------------------------------------------------

        function saveValue(prop, entdata, newValue) {
            return ntaSharedLogic.saveValue(prop, entdata, newValue, self, ntaEntityDataOrg);
        } //-- end: saveValue ---------------------------------------------------------------------

        function validate(prop, propdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            const hidden = true; //isHidden(prop);
            const readOnly = true; //isReadOnly(prop);

            let valid = ntaValidation.IsValid(self.form, prop, propdata, hidden, readOnly);

            const contrl = self.form && self.form['ntainput' + propdata.PropertyDataId];

            switch (prop.Id) {
                default: {
                    break;
                }
            }

            return valid;
        } //-- end: validate ---------------------------------------------------------------------

        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = prop.getData(entdata);
            const checkValue = propdata.Value;

            let performDefaultChecks = false;

            switch (prop.Id) {
                default:
                    break;
            }

            if (performDefaultChecks) {
                isHidden(prop, entdata);
                if (ntaValidation.hasCodedValues(prop)) {
                    getCodedValues(prop);
                }
                if (propdata.Value !== checkValue) {
                    saveValue(prop, entdata);
                }
            }
        } //-- end: validateDependencies ----------------------------------------------------------

        function startFormValidation() {
            return ntaSharedLogic.startFormValidation(getAllEntDatas(), self);
        } //-- end: startFormValidation -----------------------------------------------------------

        function endFormValidation() {
            if (![null, _entdataMeasure.EntityDataId].includes(ntaData.current.shadowId)) return [];

            return ntaSharedLogic.endFormValidation(getAllEntDatas(), self);
        } //-- end: endFormValidation -------------------------------------------------------------

        function getAllEntDatas() {
            return [_entdataMeasureWindt];
        } //-- end: getAllEntDatas ----------------------------------------------------------------

        function setGeopend() {
            const propdataOpen = _entdataMeasure.PropertyDatas['MEASURE_OPEN'];
            ntaEntityDataOrg.saveprop(propdataOpen, 'true');
        } //-- end: setGeopend --------------------------------------------------------------------

    };
}]);
