﻿angular.module('projectModule')
    .controller("InstallationZonnbControllerV2",
        ['$scope', '$controller', 'ntabuilding', 'ntaValidation', 'ntaEntityData', '$routeParams', 'ntaDependencyValidation', 'ntaMeasure', 'ZonneboilerFactory', 'ntaSharedLogic', 'Pager',
function ($scope,   $controller,   ntabuilding,   ntaValidation,   ntaEntityData,   $routeParams,   ntaDependencyValidation,   ntaMeasure,   ZonneboilerFactory,   ntaSharedLogic,   Pager) {
    'use strict';
    const base = $controller('NTABaseController', { $scope: $scope })
    const vm = angular.extend(this, base);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    vm.ntaValidation = ntaValidation;
    vm.installId = $routeParams.id3;

    const zonneboilerLogic = new ZonneboilerFactory(vm.installId, ntaDependencyValidation);
    vm.getZonneboilerLogic = zonneboilerLogic;

    vm.propOpmerkingen = zonneboilerLogic.propOpmerkingen;
    vm.propertiesZB = zonneboilerLogic.propertiesZB;
    vm.propertiesBV = zonneboilerLogic.propertiesBV;
    vm.propertiesZC = zonneboilerLogic.propertiesZC;
    vm.propertiesUZC = zonneboilerLogic.propertiesUZC;

    vm.zonnbData = zonneboilerLogic.zonnbData;
    vm.systeem = zonneboilerLogic.systeem;
    vm.boilervat = zonneboilerLogic.boilervat;
    vm.collector = zonneboilerLogic.collector;

    vm.isHidden = zonneboilerLogic.isHidden;
    vm.isReadOnly = zonneboilerLogic.isReadOnly;
    vm.isDisabled = zonneboilerLogic.isReadOnly;

    vm.getProduct = zonneboilerLogic.getListItem;
    vm.getCodedValues = zonneboilerLogic.getCodedValues;
    vm.getPropData = zonneboilerLogic.getPropData;

    vm.getSelectionTable = zonneboilerLogic.getSelectionTable;
    vm.saveValueSelectionTable = zonneboilerLogic.saveValueSelectionTable;

    vm.getForm = getForm;
    vm.saveValue = saveValue;

    vm.isSelectionTableDisabled = isSelectionTableDisabled;

    vm.preventBackspace = ntaSharedLogic.preventBackspace;

    vm.isCollectorTableVisible = zonneboilerLogic.isCollectorTableVisible;
    vm.getCollectorsByUnits = zonneboilerLogic.getCollectorsByUnits;

    vm.hasBelemmering = zonneboilerLogic.hasBelemmering;
    vm.belemmeringenIcon = belemmeringenIcon;
    vm.belemmeringenVisible = belemmeringenVisible;
    vm.toggleBelemmeringen = toggleBelemmeringen;

    vm.getTitle = () => ntaSharedLogic.isEditingMeasure() ? 'Maatregel: wijzigen zonneboiler' : 'Zonneboiler';

    $scope.endFormValidation = zonneboilerLogic.endFormValidation;

    //-- paging
    vm.pager = new Pager({ storageName: 'zonneboiler2', visible: true });
    vm.pager.on('validationRequested', validatePages);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INITIALIZATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const _belemmeringenVisibleByParent = new Map(); //-- per unitCollector, of de bijbehorende belemmeringen zichtbaar zijn

    ntaMeasure.setBtwPropIds(vm);

    angular.element(function () { //alle touched properties valideren na het laden van form
        zonneboilerLogic.startFormValidation();
        vm.initializeUnitSpacing();
    });

    vm.setGeopend('ZONNB_OPEN', vm.zonnbData);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function saveValue(prop, entdata) {
        zonneboilerLogic.saveValue(prop, entdata);

        if (prop.Id === 'UNIT-ZONNC_BESCH') {
            if (vm.hasBelemmering(entdata)) {
                toggleBelemmeringen(entdata, true);
            }
        }

        vm.initializeUnitSpacing();
    }

    function validatePages(event) {
        const invalidPropdatas = zonneboilerLogic.startFormValidation();
        const invalidEntdatas = invalidPropdatas
            .map(propdata => propdata && ntaEntityData.get(propdata.EntityDataId))
            .filter(entdata => entdata)
            .distinct();
        for (const page of event.pages) {
            page.isValid = !page.rows.some(row => invalidEntdatas.some(entdata => entdata === row.entdata));
        }
    } //-- end: validatePages -------------------------------------------------------------------//

    function getForm() {
        return zonneboilerLogic.form_zonnb;
    }

    function isSelectionTableDisabled(prop, entdata) {
        if (!prop || !entdata) {
            return true;
        }

        //-- de selectie tabel mag niet gekozen worden als er maar 1 item in de filterlijst zit tenzij propdata.Value == null
        const propdataValue = entdata.PropertyDatas[prop.Id].Value;
        if (propdataValue) {
            let items = [];
            if (prop.Id === 'ZONNC_PVT') {
                items = entdata.filteredItemsPvt;
            } else {
                items = entdata.filteredItems;
            }
            return !!items && items.length === 1;
        }

        return false;
    } //-- end: isSelectionTableDisabled ------------------------------------------------------------------------//

    function belemmeringenIcon(parent) {
        return belemmeringenVisible(parent)
            ? 'expand_less'
            : 'expand_more';
    } //-- end: belemmeringenIcon ---------------------------------------------------------------//

    function belemmeringenVisible(parent) {
        return !!_belemmeringenVisibleByParent.get(parent)
            || !!hasBelemmeringenError(parent);
    } //-- end: belemmeringenVisible ---------------------------------------------------------------//

    function toggleBelemmeringen(entdata, show = null) {
        if (!entdata) return;

        const wasVisible = belemmeringenVisible(entdata);

        const errorPropdata = hasBelemmeringenError(entdata);
        if (errorPropdata) {
            show = true;
        } else if (show === null) {
            show = !wasVisible;
        } else {
            show = !!show;
        }
        _belemmeringenVisibleByParent.set(entdata, show);

        if (errorPropdata) {
            const form = getForm();
            const control = form && form['ntainput' + errorPropdata.PropertyDataId];
            if (control && control.$$element) {
                control.$$element.focus();
            }
        }
        if (show !== wasVisible && show) {
            setTimeout(() => base.initializeUnitSpacing(), 500);
        }
    } //-- end: toggleBelemmeringen ------------------------------------------------------------------------//

    function hasBelemmeringenError(entdata) {
        const belemmering = ntaEntityData.getFirstChild(entdata, 'BELEMMERING');
        if (!belemmering) return true;

        const errorPropdata =
            belemmering && belemmering.PropertyDatas.find(propdata => {
                const prop = ntabuilding.properties[propdata.PropertyId];
                return !zonneboilerLogic.isReadOnly(prop, belemmering)
                    && !zonneboilerLogic.validate(prop, propdata, belemmering)
                    && propdata;
            });

        return errorPropdata || false;
    } //-- end: hasBelemmeringenError ------------------------------------------------------------------------//



}]);
