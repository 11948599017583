﻿angular.module('projectModule').controller("InstallationPvControllerV1", ['$scope','$controller','ntabuilding','ntaValidation','ntaEntityData','$routeParams','$mdDialog','progressCircle','$timeout','ntaDependencyValidation','PvFactory', 'ntaSharedLogic',
function                                                                ($scope,  $controller,  ntabuilding,  ntaValidation,  ntaEntityData,  $routeParams,  $mdDialog,  progressCircle,  $timeout,  ntaDependencyValidation,  PvFactory, ntaSharedLogic) {

    const base = $controller('NTABaseController', { $scope: $scope })
    const vm = angular.extend(this, base);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    vm.ntaValidation = ntaValidation;
    vm.installId = $routeParams.id3;

    const pvLogic = new PvFactory(vm.installId, ntaDependencyValidation);

    vm.getPvLogic = pvLogic;
    vm.properties = pvLogic.properties;
    vm.getPropData = getPropData;
    vm.pvData = pvLogic.pvData
    vm.systemen = pvLogic.systemen;
    vm.systeemNr = pvLogic.systeemNr;
    vm.systeemTypes = ntabuilding.properties["PV-PVT_TYPESYS"].Domain.Codes;

    vm.activeSystem = pvLogic.activeSystem;
    vm.setSysteemActive = pvLogic.setActiveSystem;
    vm.getCodedValues = pvLogic.getCodedValues;

    vm.isHidden = pvLogic.isHidden;
    vm.isReadOnly = pvLogic.isReadOnly;
    vm.isDisabled = pvLogic.isReadOnly;

    vm.getSelectionTable = pvLogic.getSelectionTable;
    vm.saveValueSelectionTable = pvLogic.saveValueSelectionTable;
    vm.saveValueAutocomplete = pvLogic.saveValueAutocomplete;

    vm.getForm = getForm;
    vm.saveValue = saveValue;
    vm.addSysteem = addSysteem;
    vm.copyClick = copyClick;
    vm.deleteClick = deleteClick;

    vm.preventBackspace = ntaSharedLogic.preventBackspace;

    $scope.endFormValidation = function () {
        pvLogic.endFormValidation();
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INITALIZIATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    angular.element(function () { //alle touched properties valideren na het laden van form
        pvLogic.startFormValidation();
        vm.initializeUnitSpacing();

        // Reset layout Masonry
        $timeout(function () {
            callMasonryReLayout();
        });
    });

    angular.element(document).ready(function () {
        $timeout(function () {
            callMasonryReLayout();
            $timeout(function () {
                callMasonryReLayout();
            }, 1000);
        }, 1000);
    });

    vm.setGeopend('PVSYS_OPEN', vm.pvData);


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function getForm() {
        return pvLogic.form_pvsys;
    };

    function saveValue(prop, entdata) {
        pvLogic.saveValue(prop, entdata);
        vm.initializeUnitSpacing();

        // Reset layout Masonry
        $timeout(function () {
            callMasonryReLayout();
        });
    };

    function getPropData(prop, entdata) {
        //-- VO 2020-10-07: Code voor trello kaartje https://trello.com/c/eGcoEgLf zolang dit nog niet verwerkt is
        //-- moet deze getPropdata in de controller om vooor de prop met een relatie enititeit de juiste value op te halen
        const propdata = ntaSharedLogic.getPropData(prop, entdata);
        if (prop.Id === 'PV-PVT_ZONNBSYS') {
            //-- nu relatie ophalen met verwarmingsysteem.
            const zonnbSys = ntaEntityData.getFirstParent(entdata, 'ZONNBSYS');
            propdata.Value = zonnbSys && zonnbSys.EntityDataId || null;
        }
        return propdata;
    } //-- end: getPropData ------------------------------------------------------------------------//

    function addSysteem(typeId) {
        const newId = ntaEntityData.create("PV-PVT", -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": pvLogic.pvData.EntityDataId, "ParentEntityId": "PVSYS" }], []);

        if (typeId) {
            var propdata = ntaEntityData.get(newId).
                PropertyDatas.find(function (x) { return x.PropertyId === "PV-PVT_TYPESYS"; });
            ntaEntityData.saveprop(propdata, typeId);
        }
        //elke PV-PVT heeft een BELEMMERING entity, relatie is met cascade delete en copy
        ntaEntityData.create('BELEMMERING', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newId, "ParentEntityId": "PV-PVT" }], []);
    }

    function copyClick(Id) {
        if (!Id) {
            return;
        }

        progressCircle.setShowProgressValue(true, 'kopiëren pv(t)-systeem');
        const newEntdatas = ntaEntityData.copy(Id)

        //-- ook de javascript objecten die in de client zijn toegevoegd zoals selectedItem enz. van het product mee kopieren
        const entdata = ntaEntityData.get(Id);
        for (const newEntdata of newEntdatas) {
            if (entdata.EntityId === newEntdata.EntityId) {
                for (const member in entdata) {
                    if (!newEntdata.hasOwnProperty(member) && member !== "$$hashKey") {
                        newEntdata[member] = entdata[member];
                    }
                }
            }
        }

        progressCircle.setShowProgressValue(false);

        $timeout(function () {
            callMasonryInitialize();


            //pvLogic.loadProductsFromServer(); // nodig omdat selectedItem van het product niet wordt gezet bij kopieren
        });
    }

    function deleteClick(Id) {
        if (!Id) {
            return;
        }

        var confirm = $mdDialog.confirm()
            .title('Verwijderen pv(t)-systeem')
            .textContent('U staat op het punt om dit systeem te verwijderen. Weet u het zeker?')
            .ariaLabel('Verwijderen')
            .targetEvent(event)
            .ok('Ja')
            .cancel('Nee');

        $mdDialog.show(confirm).then(function () {
            ntaEntityData.delete(Id);

            $timeout(function () {
                callMasonryReLayout();
            });
        });
    };

    function callMasonryInitialize() {
        $scope.$broadcast('callInitialize');
    };

     function callMasonryReLayout() {
        $scope.$broadcast('callReLayout');
    };

}]);
