﻿angular.module('projectModule')
    .controller("InstallationVentilatieController",
        ['$scope', '$controller', 'ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaSharedLogic', '$routeParams', 'ntaDependencyValidation', 'ntaMeasure', 'Pager', 'VentilatieFactory',
function ($scope,   $controller,   ntabuilding,   ntaValidation,   ntaEntityData,   ntaSharedLogic,   $routeParams,   ntaDependencyValidation,   ntaMeasure,   Pager,   VentilatieFactory) {
    'use strict';
    const base = $controller('NTABaseController', { $scope: $scope })
    const vm = angular.extend(this, base);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    vm.ntaValidation = ntaValidation;
    vm.installId = $routeParams.id3;

    const ventilatieLogic = new VentilatieFactory(vm.installId, ntaDependencyValidation);
    vm.getVentilatieLogic = ventilatieLogic;

    /* Properties *************************************************************************************************************************************************************/
    vm.ventProperties = ventilatieLogic.ventProperties;
    vm.ventaanvullendProperties = ventilatieLogic.ventaanvullendProperties;
    vm.warmteterugwinningProperties = ventilatieLogic.warmteterugwinningProperties;
    vm.voorverwarmingProperties = ventilatieLogic.voorverwarmingProperties;
    vm.ventilatorenProperties = ventilatieLogic.ventilatorenProperties;
    vm.ventilatiedebietProperties = ventilatieLogic.ventilatiedebietProperties;
    vm.ventcapaciteitenProperties = ventilatieLogic.ventcapaciteitenProperties;
    vm.ventzwembadruimteProperties = ventilatieLogic.ventzwembadruimteProperties;
    vm.ventVerblijfgebiedProperties = ventilatieLogic.ventVerblijfgebiedProperties;
    vm.ventilatiedistributieProperties = ventilatieLogic.ventilatiedistributieProperties;
    vm.ventilatoreigenschappenProperties = ventilatieLogic.ventilatoreigenschappenProperties;
    vm.ventToevoerkanaalNaarWTWProperties = ventilatieLogic.ventToevoerkanaalNaarWTWProperties;

    /* Data *************************************************************************************************************************************************************/
    vm.getProduct = ventilatieLogic.getListItem;
    vm.ventData = ventilatieLogic.ventData;
    vm.ventaanvullendData = ventilatieLogic.ventAanvullendData;
    vm.voorverwarmingData = ventilatieLogic.voorverwarmingData;
    vm.ventilatiedebietData = ventilatieLogic.ventilatiedebietData;
    vm.ventilatiedistributieData = ventilatieLogic.ventilatiedistributieData;
    vm.units = ventilatieLogic.units;
    vm.zbrRekenzones = ventilatieLogic.zbrRekenzones;

    vm.ventilatiekolommen = ventilatieLogic.ventilatiekolommen;

    vm.isHidden = ventilatieLogic.isHidden;
    vm.isReadOnly = ventilatieLogic.isReadOnly;
    vm.isDisabled = ventilatieLogic.isReadOnly;
    vm.propertyHeaderIsHidden = ventilatieLogic.propertyHeaderIsHidden;

    vm.getCodedValues = ventilatieLogic.getCodedValues;

    vm.getSelectionTable = ventilatieLogic.getSelectionTable;
    vm.saveValueSelectionTable = ventilatieLogic.saveValueSelectionTable;

    vm.kolomIsHidden = ventilatieLogic.kolomIsHidden;
    vm.verblijfsgebiedTabelIsHidden = ventilatieLogic.verblijfsgebiedTabelIsHidden;
    vm.toevoerkanaalTabelIsHidden = ventilatieLogic.toevoerkanaalTabelIsHidden;
    vm.voorverwarmingIsHidden = ventilatieLogic.voorverwarmingIsHidden;
    vm.warmteterugwinningIsHidden = ventilatieLogic.warmteterugwinningIsHidden;
    vm.ventilatorenIsHidden = ventilatieLogic.ventilatorenIsHidden;
    vm.ventilatorEigenschappenIsHidden = ventilatieLogic.ventilatorEigenschappenIsHidden;
    vm.ventilatorEigenschappenUnitIsHidden = ventilatieLogic.ventilatorEigenschappenUnitIsHidden;
    vm.ventilatiedebietenIsHidden = ventilatieLogic.ventilatiedebietenIsHidden;
    vm.distributieIsHidden = ventilatieLogic.distributieIsHidden;

    vm.getName = getName;
    vm.getForm = getForm;
    vm.saveValue = saveValue;

    vm.addVentilatorEigenschappen = addVentilatorEigenschappen;
    vm.copyVentilatorEigenschappen = copyVentilatorEigenschappen;
    vm.deleteVentilatorEigenschappen = deleteVentilatorEigenschappen;

    vm.getUnitRekenzones = ventilatieLogic.getUnitRekenzones;
    vm.getVentilatiecapaciteit = ventilatieLogic.getVentilatiecapaciteit;
    vm.getVentilatiezwembadruimte = ventilatieLogic.getVentilatiezwembadruimte;

    vm.getUnitOmschrijving = getUnitOmschrijving;
    vm.getRekenzoneOmschrijving = getRekenzoneOmschrijving;
    vm.getOmschrijvingVentilatiecapaciteiten = getOmschrijvingVentilatiecapaciteiten;
    vm.getOmschrijvingEigenschappenVentilatoren = getOmschrijvingEigenschappenVentilatoren;

    vm.getTitle = () => ntaSharedLogic.isEditingMeasure() ? 'Maatregel: wijzigen ventilatie' : 'Ventilatie';


    // Dynamic styling
    vm.center = center;
    vm.dropdownCenter = dropdownCenter;

    vm.preventBackspace = ntaSharedLogic.preventBackspace;

    $scope.endFormValidation = function () {
        ventilatieLogic.endFormValidation();
    };

    // Paging
    vm.pagers = {};
    for (const pagerName of ['verblijf', 'verblijfAanv', 'wtw', 'wtwAan', 'vent', 'ventAan', 'ventDebiet', 'ventZBR']) {
        const options = {
            storageName: pagerName,
            visible: ntaSharedLogic.perGebouwEnAppartementOfUnit() || ntaSharedLogic.voorProjectwoningen(),
        };
        const pager = vm.pagers[pagerName] = new Pager(options);
        pager.name = pagerName;
        pager.on('validationRequested', validatePages);
    }
    vm.getVisibleVentilatoreigenschappenUnit = getVisibleVentilatoreigenschappenUnit;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         INITALIZIATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ntaMeasure.setBtwPropIds(vm);

    angular.element(function () { //alle touched properties valideren na het laden van form
        ventilatieLogic.startFormValidation();
        vm.initializeUnitSpacing();
    });

    vm.setGeopend('VENT_OPEN', vm.ventData);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function saveValue(prop, entdata) {
        ventilatieLogic.saveValue(prop, entdata);
        vm.initializeUnitSpacing();
    } //-- end: saveValue ------------------------------------------------------------------------//

    function validatePages(event) {
        const pager = this;
        const rowProperties = {
            verblijf: vm.ventVerblijfgebiedProperties,
            verblijfAanv: vm.ventVerblijfgebiedProperties,
            wtw: vm.ventToevoerkanaalNaarWTWProperties,
            wtwAan: vm.ventToevoerkanaalNaarWTWProperties,
            vent: vm.ventilatoreigenschappenProperties,
            ventAan: vm.ventilatoreigenschappenProperties,
            ventDebiet: vm.ventcapaciteitenProperties,
            ventZBR: vm.ventzwembadruimteProperties,
        }[pager.name] || [];
        const entityIds = new Set(rowProperties.map(prop => prop.EntityId).distinct());

        const invalidPropdatas = ventilatieLogic.startFormValidation();
        const invalidEntdatas = invalidPropdatas
            .map(propdata => propdata && ntaEntityData.get(propdata.EntityDataId))
            .filter(entdata => entdata && entityIds.has(entdata.EntityId))
            .distinct();
        for (const page of event.pages) {
            const tempUnitsOnPage = page.rows;
            page.isValid = !tempUnitsOnPage.some(tempUnit => invalidEntdatas.some(entdata => entdataBelongsToUnit(entdata, tempUnit)));
        }
    } //-- end: validatePages -------------------------------------------------------------------//

    function entdataBelongsToUnit(entdata, tempUnit) {
        const unit = ntaEntityData.get(tempUnit.EntityDataId); // nodig omdat er met tempUnits gewerkt wordt
        if (entdata === unit) return true;
        if (ntaEntityData.getFirstParent(entdata, 'UNIT', true, 'RZ') === unit) return true;
        if (tempUnit.verblijfsgebied && tempUnit.verblijfsgebied.EntityDataId === entdata.EntityDataId) return true;
        if (tempUnit.toevoerkanaal && tempUnit.toevoerkanaal.EntityDataId === entdata.EntityDataId) return true;
        if (tempUnit.ventilatorEigenschappen && tempUnit.ventilatorEigenschappen.some(x => x.EntityDataId === entdata.EntityDataId)) return true;

        // TODO: welke andere entiteiten komen hier zoal terug op het formulier?

        return false;
    } //-- end: entdataBelongsToUnit ------------------------------------------------------------//

    function getForm() {
        return ventilatieLogic.form_installationventilatie;
    } //-- end: getForm ------------------------------------------------------------------------//

    function getOmschrijvingEigenschappenVentilatoren(unitEntdata, kolom = null) {
        // V82
        if (kolom && unitEntdata && unitEntdata.ventilatorEigenschappen && (!ntaSharedLogic.perGebouwEnAppartementOfUnit() && !ntaSharedLogic.voorProjectwoningen() || ventilatieLogic.isLbkAanwezig())) {
            return 'per identiek systeem';
        }
        return getUnitOmschrijving(unitEntdata);
    } //-- end: getOmschrijvingEigenschappenVentilatoren ----------------------------------------//

    function getOmschrijvingVentilatiecapaciteiten(unitEntdata) {
        // V57
        if (ntaSharedLogic.perGebouwEnAppartementOfUnit() || ntaSharedLogic.voorProjectwoningen()) {
            return getUnitOmschrijving(unitEntdata);
        }
        return 'per identiek systeem';
    } //-- end: getOmschrijvingVentilatiecapaciteiten -------------------------------------------//

    function getUnitOmschrijving(unitEntdata) {
        return unitEntdata && unitEntdata.PropertyDatas["UNIT_OMSCHR"].Value || '';
    } //-- end: getUnitOmschrijving -------------------------------------------------------------//

    function getRekenzoneOmschrijving(entdata) {
        if (entdata.EntityId === "UNIT-RZ") {
            entdata = ntaEntityData.getFirstParent(entdata, "RZ");
        }
        return entdata ? entdata.PropertyDatas['RZ_OMSCHR'].Value : "";
    } //-- end: getRekenzoneOmschrijving --------------------------------------------------------//

    function getName(prop) {
        let str = base.getName(prop);

        // Conditie [WE]
        if (prop.Id === 'WARMTETERUG_FABR' || prop.Id === 'VENTILATOR_FABR') {

            const ventilatiesysteem = vm.ventData.PropertyDatas['VENT_SYS'].Value;
            if (ventilatiesysteem === 'VENTSYS_MECHC' && ventilatieLogic.isLbkAanwezig()) {
                str = 'fabricagejaar luchtbehandelingskast';
            } else {
                str = 'fabricagejaar ventilatie-unit';
            }
        } else if (prop.Id === 'VENTILATOR_AAN') {
            if (ntaSharedLogic.isUtiliteit()) {
                str = 'aantal ventilatie-units per unit';
            }
            else {
                str = 'aantal ventilatie-units per woonfunctie';
            }
        } else if (prop.Id === 'VENTDEB_CAPTAB') {
            // conditie [DM]
            const propdataPassieveKoelregeling = vm.ventData.PropertyDatas['VENT_PKOEL'];
            if (ntabuilding.appVersionId >= 100 && propdataPassieveKoelregeling.Visible && propdataPassieveKoelregeling.Value === 'VENTDIS_PKOEL_AUTO') {
                str += ' bij passieve koeling';
            }
        }

        return str;
    } //-- end: getName ------------------------------------------------------------------------//


    vm.clickCheckBox = function (prop, ventzwembadruimte) {
        const propdata = prop.getData(ventzwembadruimte)
        propdata.Value === "True" ? propdata.Value = "False" : propdata.Value = "True";
        vm.saveValue(prop, ventzwembadruimte);
    };


    vm.isSelectionTableDisabled = function (prop, entdata) {
        if (!prop || !entdata) {
            return true;
        }

        // de selectie tabel mag niet gekozen worden als er maar 1 item in de filterlijst zit tenzij propdata.Value == null
        let propdataValue = entdata.PropertyDatas[prop.Id];
        if (propdataValue) {
            switch (prop.Id) {
                case "VENT_SYSVAR":
                case "VENTAAN_SYSVAR":
                case "VOORWARM_ROO":
                    {
                        return entdata.filteredItems && entdata.filteredItems.Singleresult;
                    }
                default:
            }
        }

        return false;
    } //-- end: isSelectionTableDisabled ------------------------------------------------------------------------//

    function getVisibleVentilatoreigenschappenUnit(ventilatiekolom) {
        return ventilatiekolom.ventilatoren.tempUnits
            .filter(tempUnit => !vm.ventilatorEigenschappenUnitIsHidden(tempUnit, ventilatiekolom));
    } //-- end: getVisibleVentilatoreigenschappenUnit -------------------------------------------//

    // CRUD acties
    function addVentilatorEigenschappen(ventilatoreigenschappen, tempUnit, ventilator) {
        if (!ventilatoreigenschappen || !ventilator) {
            return;
        }
        ntaEntityData.create('VENTILATOREIG', ventilatoreigenschappen ? ventilatoreigenschappen.Order : -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": tempUnit.EntityDataId, "ParentEntityId": "UNIT" },
                                                                                                             { "OnCopy": 1, "OnDelete": 1, "Parent": ventilator.EntityDataId, "ParentEntityId": ventilator.EntityId }], []);
        ventilatieLogic.setVentilatorenEigenschappenPerUnit(ventilator);
    } //-- end: addVentilatorEigenschappen ------------------------------------------------------------------------//

    function copyVentilatorEigenschappen(ventilatoreigenschappen, tempUnit, ventilator) {
        if (!ventilatoreigenschappen || !ventilator) {
            return;
        }
        ntaEntityData.copy(ventilatoreigenschappen.EntityDataId);
        ventilatieLogic.setVentilatorenEigenschappenPerUnit(ventilator);
    } //-- end: copyVentilatorEigenschappen ------------------------------------------------------------------------//

    function deleteVentilatorEigenschappen(ventilatoreigenschappen, tempUnit, ventilator) {
        if (!ventilatoreigenschappen || !ventilator) {
            return;
        }

        if (tempUnit.ventilatorEigenschappen.length <= 1) { //kan de laatste niet weggooien
            return;
        }

        ntaEntityData.delete(ventilatoreigenschappen.EntityDataId);
        ventilatieLogic.setVentilatorenEigenschappenPerUnit(ventilator);
    } //-- end: deleteVentilatorEigenschappen ------------------------------------------------------------------------//

    function center(prop, header = false) {
        switch (prop.Id) {
            case 'VENTILATOREIG_DEB': {
                return '';
            }
            case 'WARMTE-TOEV-KAN_LEN': {
                return header ? 'numberProp2' : 'columnRight';
            }
            case 'VENT-VERB_GEBIED': {
                return 'columnRight';
            }
            default: {
                return 'columnCenter';
            }
        }
    } //-- end: center ------------------------------------------------------------------------//

    function dropdownCenter(prop) {
        if (!prop || prop.Id === 'VENTILATOREIG_DEB') {
            return '';
        }

        return 'margin-left: 24px;';
    } //-- end: dropdownCenter ------------------------------------------------------------------------//

    vm.sortableOptionsVentilatoren = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            let unit = ntaEntityData.getFirstParent(ui.item.sortable.model, "UNIT");
            let ventilator = ntaEntityData.getFirstParent(ui.item.sortable.model, "VENTILATOR");

            let tempUnit = ventilator.tempUnits.find(function (x) { return x.EntityDataId === unit.EntityDataId; });
            ntaEntityData.SaveReOrder(tempUnit.ventilatorEigenschappen);
        }
    } //-- end: sortableOptionsVentilatoren ------------------------------------------------------------------------//

}]);