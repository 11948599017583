﻿'use strict';
angular.module('projectModule').controller("ProjectBuildingsController",['projecttree', 'gebruikstypes', 'positietypes', 'berekeningstypes', '$routeParams', '$http', '$timeout', function (projecttree, gebruikstypes, positietypes, berekeningstypes, $routeParams, $http, $timeout) {

    var vm = this;
    var selectedFolderId = $routeParams.id2;

    vm.tree = projecttree.folders;
    vm.folder = function () {
        return projecttree.folders.filter(function (x) { return x.FolderId == selectedFolderId; })[0];
    };
    vm.folderIcon = vm.folder().Type.BrkIconImg;
    vm.gebouwen = function () {
        var fld = vm.folder();
        return fld.Gebouwen;
    };

    //selectielijsten
    vm.gebruikstypes = gebruikstypes.types.filter(function (x) { return x.FilterId == vm.folder().BerekeningsTypeId; });
    vm.gebruikstypes.sort((a, b) => (a.Order > b.Order) ? 1 : ((b.Order > a.Order) ? -1 : 0));
    vm.positietypes = positietypes.types.filter(function (x) { return x.FilterId == vm.folder().BerekeningsTypeId; });
    vm.positietypes.sort((a, b) => (a.Order > b.Order) ? 1 : ((b.Order > a.Order) ? -1 : 0));
    vm.nieuwbouwtypes = [
        { Id: -1, Value: "Maak uw keuze"},
        { Id: 0, Value: "Bestaande bouw"},
        { Id: 1, Value: "Nieuwbouw"}];
    vm.statustypes = [
        { Id: "Studieberekening", Value: "Studieberekening" },
        { Id: "Omgevingsvergunning", Value: "Aanvraag omgevingsvergunning" },
        { Id: "Oplevering_N", Value: "Oplevering nieuw gebouw" },
        { Id: "Oplevering_B", Value: "Oplevering bestaand gebouw" },
        { Id: "Verkoop_Verhuur", Value: "Verkoop/Verhuur" },
        { Id: "Woningwaarderingsstelsel", Value: "Woningwaarderingsstelsel" }];

    vm.berekeningstype = berekeningstypes.types.filter(function (x) { return x.Id == vm.folder().BerekeningsTypeId })[0].Id;
    switch (vm.berekeningstype) {
        case "W":
            vm.GebouwenHeader = "woning(en)";
            vm.PostitieHeader = "Woningtype";
            vm.AantalHeader = "Type aantal";
            vm.statustypes.pop();
            break;
        case "WO":
            vm.GebouwenHeader = "woonobject(en)";
            vm.PostitieHeader = "Woningtype";
            vm.GebruiksHeader = "Gebruiksfunctie";
            vm.AantalHeader = "Type aantal";
            break;
        case "U":
            vm.GebouwenHeader = "utiliteitsgebouw(en)";
            vm.AantalHeader = "Type aantal";
            vm.statustypes.pop();
            break;
        case "WALL":
            vm.WG_Omschrijving = vm.folder().Name;
            vm.WG_AantalApp = _calcAantalApp();
            vm.WG_Nieuwbouw = vm.gebouwen()[0].Nieuwbouw;
            vm.WG_Status = vm.gebouwen()[0].Status;
            vm.nieuwbouwtypes.shift();
            vm.nieuwbouwtypes.shift();
            for (var i = 1; i < 5; i++) {
                vm.statustypes.pop(); //laatste vier statussen verwijderen
            }
            break;
        case "WLAY": 
            vm.GebouwenHeader = "appertementen per bouwlaag";
            vm.PostitieHeader = "Positie in gebouw";
            vm.AantalHeader = "Aantal app/laag";

            vm.WG_Omschrijving = vm.folder().Name;
            vm.WG_AantalApp = _calcAantalApp();
            vm.WG_Nieuwbouw = vm.gebouwen()[0].Nieuwbouw;
            vm.WG_Status = vm.gebouwen()[0].Status;
            vm.nieuwbouwtypes.shift();
            vm.nieuwbouwtypes.shift();
            for (var i = 1; i < 5; i++) {
                vm.statustypes.pop(); //laatste vier statussen verwijderen
            };

            break;
        case "WAPP":
            vm.WG_Omschrijving = vm.folder().Name;
            vm.WG_AantalApp = _calcAantalApp();
            vm.WG_Nieuwbouw = vm.gebouwen()[0].Nieuwbouw;
            vm.WG_Status = vm.gebouwen()[0].Status;
            vm.GebouwenHeader = "appartementen";
            vm.PostitieHeader = "Positie in gebouw";
            vm.GebruiksHeader = "Woningtype";
            vm.AantalHeader = "Type aantal";
            break;
        default:
            break;
    };

    vm.geenNieuwbouwKeuze = function () {
        var b = false; // alle gebouwen in de folder zijn nieuwbouw of bestaand ofwel 0 of 1
        var fld = vm.folder();
        for (var i = 0; i < fld.Gebouwen.length; i++) {
            if (fld.Gebouwen[i].Nieuwbouw == -1) {
                b = true;
                break;
            };
        };

        return b;
    };

    vm.saveGebruiksType = function (id) {
        vm.saveGebouw(id);
        var gbrt = vm.gebouwen().filter(function (x) { return x.GebouwId === id; })[0].GebruiksTypeId;
        // nu het icoontje van het type veranderen als het berekeningstype WoningbouwOverig is
        if (vm.folder().BerekeningsTypeId == "WO" || vm.folder().BerekeningsTypeId == "WAPP") {
            vm.gebouwen().filter(function (x) { return x.GebouwId === id; })[0].GebouwIcon = vm.gebruikstypes.filter(function (x) { return x.Id == gbrt; })[0].ImageUrl;        
        }
    };

    vm.savePositieType = function (id) {
        vm.saveGebouw(id);
        var post = vm.gebouwen().filter(function (x) { return x.GebouwId === id; })[0].PositieTypeId;
        // nu het icoontje van het type veranderen als het berekeningstype WoningbouwOverig is
        if (vm.folder().BerekeningsTypeId != "WO" && vm.folder().BerekeningsTypeId != "WAPP") {
            vm.gebouwen().filter(function (x) { return x.GebouwId === id; })[0].GebouwIcon = vm.positietypes.filter(function (x) { return x.Id == post; })[0].ImageUrl;
        }
    };

    vm.saveGebouw = function (id) {
        var gebouwdata = vm.gebouwen().filter(function (x) { return x.GebouwId === id; })[0];
        var notValidText = $http({
            method: "post",
            url: "Projects/SaveGebouwData",
            data: JSON.stringify(gebouwdata),
            dataType: "json"
        });
        vm.WG_AantalApp = _calcAantalApp();
    };

    vm.addGebouw = function (gemeenschappelijk) {
        $http.post("Projects/AddGebouw?FolderId=" + selectedFolderId + "&Gem=" + gemeenschappelijk)
            .then(function succes(response) {
                if (response.data !== null && response.data !== "" && typeof response.data !== "undefined") {
                    projecttree.folders.filter(function (x) { return x.FolderId == selectedFolderId; })[0].Gebouwen.push(response.data);
                    vm.WG_AantalApp = _calcAantalApp();
                    $timeout(function () {
                        $("#gebouw" + response.data.GebouwId).focus();
                        $("#gebouw" + response.data.GebouwId).select();
                    }, 100);
                }
            }, function error() {
                return null;
            });
    };

    vm.deleteGebouw = function (id) {
        if (id == null) {
            return;
        }
        $http.post("Projects/DeleteGebouw?Id=" + id);

        //-- check of selectedBuilding gelijk is aan het gebouw dat verwijderd moet worden, zoja dan selected building op -1 zetten
        if (projecttree.buildingid == id) {
            projecttree.buildingid = -1;
            SetBuildingInfoBENGbalk("");
        }
        projecttree.deleteGebouwInTree(vm.folder().FolderId, id)

    };

    vm.saveWGOmschrijving = function ()
    {
        var folderdata = vm.folder();
        folderdata.Name = vm.WG_Omschrijving;
        var notValidText = $http({
            method: "post",
            url: "Projects/SaveFolderData",
            data: JSON.stringify(folderdata),
            dataType: "json"
        });
    }

    vm.saveWGAantal = function ()
    {
        //hier kom ik alleen als ik WALL ben en moet ik het aantal appartementen wegschrijven in het gebouw met type WALLAPP
        if (vm.gebouwen().filter(function (x) { return x.GebruiksTypeId === "WALLAPP"; })[0]) {
            if (parseInt(vm.WG_AantalApp) != NaN) {
                vm.gebouwen().filter(function (x) { return x.GebruiksTypeId === "WALLAPP"; })[0].Aantal = parseInt(vm.WG_AantalApp);
                vm.saveGebouw(vm.gebouwen().filter(function (x) { return x.GebruiksTypeId === "WALLAPP"; })[0].GebouwId);
            }
        };
    }

    vm.saveWoongebouw = function ()
    {
        // alle gebouwen in de folder af om status en nieuwbouw te zetten
        for (var i = 0; i < vm.gebouwen().length; i++) {
            vm.gebouwen()[i].Nieuwbouw = vm.WG_Nieuwbouw;
            vm.gebouwen()[i].Status = vm.WG_Status;
            vm.saveGebouw(vm.gebouwen()[i].GebouwId);
        }
    }


    function _calcAantalApp() {
        //alle gebouwen af die van gebruikstypen ongelijk aan gemeenschappelijk zijn
        var cnt = 0;
        var fld = vm.folder();
        for (var i = 0; i < fld.Gebouwen.length; i++) {
            var bld = fld.Gebouwen[i];
            if (bld.GebruiksTypeId != "WALLGEM" && bld.GebruiksTypeId != "WLAYGEM" && bld.GebruiksTypeId != "WAPPGEM") {
                cnt += parseInt(bld.Aantal);
            }
        }

        return cnt;
    } 

}]);