﻿angular.module('projectModule')
    .service('ntaCalcValidation',
        ['$log', 'ntaData', 'baseNtaEntityData',
function ($log,   ntaData,   ntaEntityData) {
    'use strict';

    Object.assign(this, {
        validateVariant,
        getFPracVentForfaitair,
    });

    function validateVariant() {
        // Controleer dat er een GEBR-VENT is voor elke VENT-entdata; als er een ontbreekt,
        //  deze alsnog aanmaken, met de defaultwaardes.
        const gebruikersprofiel = ntaEntityData.getFirstWithEntityId('GEBR-PROFIEL');
        if (!gebruikersprofiel) return;

        const ventEDsWithoutProfielVentED = ntaEntityData.getListWithEntityId('VENT')
            .filter(ed => ntaEntityData.getChildRelations(ed, 'GEBR-VENT').length === 0);
        if (ventEDsWithoutProfielVentED.length) {
            const parentRels = [
                { OnDelete: true, OnCopy: true, Parent: gebruikersprofiel.EntityDataId, ParentEntityId: gebruikersprofiel.EntityId },
            ];
            const defaultProps = [
                { PropertyId: 'GEBR-VENT_FPRAC_NON', Value: null, Relevant: true, Visible: true }, // <-- deze moeten we straks vullen voor elk VENT-systeem
                { PropertyId: 'GEBR-VENT_FPRAC', Value: null, Relevant: false, Visible: false },
                { PropertyId: 'GEBR-VENT_INBLAASTEMP', Value: 'INBLAASTEMP_MIDDEN', Relevant: true, Visible: true },
            ];
            for (const ventED of ventEDsWithoutProfielVentED) {
                parentRels[1] = { OnDelete: true, OnCopy: true, Parent: ventED.EntityDataId, ParentEntityId: ventED.EntityId };

                const systeemtype = ventED.PropertyDatas['VENT_SYS'].Value;
                const fPrac = getFPracVentForfaitair(systeemtype);
                defaultProps[0].Value = fPrac; // we vullen de juiste forfaitaire fPrac in GEBR-VENT_FPRAC_NON

                // Omdat we in de schaduwomgeving van deze variant zitten, worden deze wijzigingen opgeslagen als delta’s;
                //  die gaan we straks uitlezen in deltasById.
                ntaEntityData.create(gebruikersprofiel.BuildingId, 'GEBR-VENT', -1, parentRels, [], defaultProps);
            }
        }

        // [MW77] voor variantberekeningen met een ander ventilatiesysteem dan in de basisberekening (als er ook maar iets anders is)
        //  altijd de waarde uit tabel 11.x doorgeven.
        // Als het ventilatiesysteem hetzelfde is als de basisberekening met de ingevoerde eigen waarde rekenen.
        const deltasById = ntaData.deltas.get(ntaData.current.shadowId);
        if (!deltasById) return;

        for (const gebrVent of ntaEntityData.getListWithEntityId('GEBR-VENT')) {
            const propdataFprac = gebrVent.PropertyDatas['GEBR-VENT_FPRAC'];
            const propdataFpracNon = gebrVent.PropertyDatas['GEBR-VENT_FPRAC_NON'];
            const propdataInblaastemp = gebrVent.PropertyDatas['GEBR-VENT_INBLAASTEMP'];

            if (propdataFprac && propdataFprac.Relevant || propdataInblaastemp && (!propdataInblaastemp.Relevant || propdataInblaastemp.Value !== 'INBLAASTEMP_MIDDEN')) {
                // Er zou met een eigen waarde gerekend worden, dus moeten we nagaan of er een wijziging is ten opzichte van de basisberekening.
                const vent = ntaEntityData.getFirstParent(gebrVent, 'VENT');
                const ventDescendants = getDescendants(vent, ed => ed.EntityId.startsWith('VENT'), ntaEntityData);
                const changed = ventDescendants.some(ed => deltasById.has('NTAEntityData_' + ed.EntityDataId)
                    || ed.PropertyDatas.some(pd => deltasById.has('NTAPropertyData_' + pd.PropertyDataId)));

                if (changed) {
                    // Zo ja, dan moeten we de forfaitaire fPrac gebruiken.
                    const systeemtype = vent.PropertyDatas['VENT_SYS'].Value;
                    const fPrac = getFPracVentForfaitair(systeemtype);
                    propdataFpracNon.Value = fPrac;
                    propdataFpracNon.Visible = true;
                    propdataFpracNon.Relevant = true;

                    propdataFprac.Visible = false;
                    propdataFprac.Relevant = false;

                    propdataInblaastemp.Value = 'INBLAASTEMP_MIDDEN'; // dit is de forfaitaire waarde
                    propdataInblaastemp.Visible = true;
                    propdataInblaastemp.Relevant = true;

                    ntaEntityData.saveprops([propdataFpracNon, propdataFprac, propdataInblaastemp]);
                }
            }
        }
    } //-- end: validateVariant -------------------------------------------------------------------

    function getFPracVentForfaitair(ventType) {
        // MW55: waarde tonen afhankelijk van systeemtype in V03
        return {
            VENTSYS_NAT:     0.25,   // A. natuurlijke toe- en afvoer
            VENTSYS_MECH:    1,      // B. mechanische toevoer en natuurlijke afvoer
            VENTSYS_NATMECH: 0.5,    // C. natuurlijke toevoer en mechanische afvoer
            VENTSYS_MECHC:   0.75,   // Dc. mechanische toe - en afvoer - centraal
            VENTSYS_MECHD:   0.75,   // Dd. mechanische toe - en afvoer - decentraal
            VENTSYS_GEC:     1,      // E. gecombineerde systemen
        }[ventType];
    } //-- end: getFPracVentForfaitair ------------------------------------------------------------

    function getDescendants(entdata, predicate = descendant => true, buildingData = ntaData.original, set = new Set()) {
        const descendants = [entdata];
        set.add(entdata);
        for (const child of buildingData.getChildren(entdata)) {
            if (!set.has(child) && predicate(child)) {
                descendants.push(...getDescendants(child, predicate, buildingData, set));
            }
        }
        return descendants;
    } //-- end: getDescendants --------------------------------------------------------------------


}]);