﻿angular.module('projectModule').factory("ConstructiesFactory", ['ntabuilding', 'ntaValidation', 'ntaEntityData', 'settingsMenuData', 'ntaRounding', 'ntaSharedLogic', 'ListCache',
function                                                        (ntabuilding,   ntaValidation,   ntaEntityData,   settingsMenuData,   ntaRounding,   ntaSharedLogic,   ListCache) {
    'use strict';
    function ConstructiesLogic(vlakId, ntaDependencyValidation) {
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        const conLogic = this; // Object dat aan de controller gegeven zal worden
        conLogic.vlakId = vlakId;

        let _vlakName = null;
        let _vlakA = null;
        let _vlakH = null;
        let _vlakBegr = null;
        let _vlakBegrOmschr = null;
        let _vlakType = null;

        const _vlakId = vlakId;
        const _vlak = ntaEntityData.get(_vlakId);
        const _constrForm = ntaEntityData.getFirstChild(_vlak, 'CONSTRERROR');

        const _constrKenmW = ntaEntityData.getFirstChild(_vlak, 'CONSTRKENMW');
        const _constrKenmV = ntaEntityData.getFirstChild(_vlak, 'CONSTRKENMV');
        const _constrKenmKRVENT = ntaEntityData.getFirstChild(_vlak, 'CONSTRKRVENT');
        const _constrKenmWWGVL = ntaEntityData.getFirstChild(_vlak, 'CONSTRWWGVL');
        const _constrKenmWWKLDR = ntaEntityData.getFirstChild(_vlak, 'CONSTRWWKLDR');

        const _isBasisOpname = ntaSharedLogic.isBasisOpname();
        const _listCache = new ListCache();
        const _isKozijnOppPerMerk = isKozijnOppPerMerk();

        initVlakValues();

        const _isGrondwand = _vlakType === 'VLAK_KELDERW';
        const _isOnverwKelder = _vlakBegr === 'VL_MV_ONVK' || _vlakBegr === 'VL_MV_ONVK_FVL' || _vlakBegr === 'VL_OMV_ONVK' || _vlakBegr === 'VL_OMV_ONVK_FVL';
        const _isKruipruimte = _vlakBegr === 'VL_MV_KR' || _vlakBegr === 'VL_MV_KR_FVL' || _vlakBegr === 'VL_OMV_KR' || _vlakBegr === 'VL_OMV_KR_FVL';
        const _isOnderMaaiveld = _vlakBegr === 'VL_OMV_KR' || _vlakBegr === 'VL_OMV_KR_FVL' || _vlakBegr === 'VL_OMV_GRSP' || _vlakBegr === 'VL_OMV_ONVK' || _vlakBegr === 'VL_OMV_ONVK_FVL';
        const _isBovenMaaiveld = _vlakBegr === 'VL_MV_KR' || _vlakBegr === 'VL_MV_KR_FVL' || _vlakBegr === 'VL_MV_GRSP' || _vlakBegr === 'VL_MV_ONVK' || _vlakBegr === 'VL_MV_ONVK_FVL';
        const _isHiddenKenmKR = !(_isKruipruimte || _isOnverwKelder); //-- Conditie [V]

        /* Properties **********************************************************************************************/
        const _propError = ntabuilding.properties['CONSTRERROR_OPEN'];
        const _propOpmerkingen = ntabuilding.properties['CONSTRERROR_OPM'];
        const _propertiesD = ntabuilding.properties['CONSTRD'];
        const _propertiesT = ntabuilding.properties['CONSTRT'];
        const _propertiesL = ntabuilding.properties['CONSTRL'];
        const _propertiesP = ntabuilding.properties['CONSTRP'];
        const _propertiesWG = ntabuilding.properties['CONSTRWG'];
        const _propertiesKenmW = ntabuilding.properties['CONSTRKENMW'];
        const _propertiesKenmV = ntabuilding.properties['CONSTRKENMV'];
        const _propertiesKenmKRVENT = ntabuilding.properties['CONSTRKRVENT'];
        const _propertiesKenmWWGVL = ntabuilding.properties['CONSTRWWGVL'];
        const _propertiesKenmWWKLDR = ntabuilding.properties['CONSTRWWKLDR'];
        const _propertiesKenmZOMNAC = ntabuilding.properties['CONSTRZOMNAC'];
        const _propertiesKenmBELEMMERING = ntabuilding.properties['BELEMMERING'];

        conLogic.propError = _propError;
        conLogic.propOpmerkingen = _propOpmerkingen;
        conLogic.propertiesD = _propertiesD;
        conLogic.propertiesT = _propertiesT;
        conLogic.propertiesL = _propertiesL;
        conLogic.propertiesP = _propertiesP;
        conLogic.propertiesWG = _propertiesWG;
        conLogic.propertiesKenmW = _propertiesKenmW;
        conLogic.propertiesKenmV = _propertiesKenmV;
        conLogic.propertiesKenmKRVENT = _propertiesKenmKRVENT;
        conLogic.propertiesKenmWWGVL = _propertiesKenmWWGVL;
        conLogic.propertiesKenmWWKLDR = _propertiesKenmWWKLDR;
        conLogic.propertiesKenmZOMNAC = _propertiesKenmZOMNAC;
        conLogic.propertiesKenmBELEMMERING = _propertiesKenmBELEMMERING;

    /* Data *************************************************************************************************************************************************************/
        conLogic.vlak = _vlak;
        conLogic.constructieError = _constrForm;
        conLogic.constrDs = constrDs;
        conLogic.constrTs = constrTs;
        conLogic.constrLs = constrLs;
        conLogic.constrPs = constrPs;
        conLogic.constrWGs = constrWGs;
        conLogic.constrKenmW = _constrKenmW;
        conLogic.constrKenmV = _constrKenmV;
        conLogic.constrKenmKRVENT = _constrKenmKRVENT;
        conLogic.constrKenmWWGVL = _constrKenmWWGVL;
        conLogic.constrKenmWWKLDR = _constrKenmWWKLDR;
        conLogic.constrKenmZOMNAC = constrKenmZOMNAC;
        conLogic.constrKenmBELEMM = constrKenmBELEMM;

        conLogic.constrKenmZOMNACbyConstr = constrKenmZOMNACbyConstr;
        conLogic.constrKenmBELEMMERINGbyConstr = constrKenmBELEMMERINGbyConstr;

        //-- Vlak/Begrenzing
        conLogic.vlakName = _vlakName;
        conLogic.vlakType = _vlakType;
        conLogic.vlakA = _vlakA;
        conLogic.vlakH = _vlakH;
        conLogic.vlakBegr = _vlakBegr;
        conLogic.vlakBegrOmschr = _vlakBegrOmschr;
        conLogic.isOnverwKelder = _isOnverwKelder;
        conLogic.isKruipruimte = _isKruipruimte;
        conLogic.isOnderMaaiveld = _isOnderMaaiveld;
        conLogic.isBovenMaaiveld = _isBovenMaaiveld;

        conLogic.createConstr = createConstr;
        conLogic.isConstrVloer = isConstrVloer;

        conLogic.isHidden = isHidden;
        conLogic.isHiddenKenmKR = _isHiddenKenmKR;
        conLogic.isReadOnly = isReadOnly;

        conLogic.validate = validate;
        conLogic.validateDependencies = validateDependencies;
        conLogic.validLineaireConstructies = validLineaireConstructies;

        conLogic.startFormValidation = startFormValidation;
        conLogic.endFormValidation = endFormValidation;

        conLogic.saveValue = saveValue;
        conLogic.getPropData = getPropData;
        conLogic.getCodedValues = getCodedValues;

        conLogic.getListItem = getListItem;
        conLogic.dependencyValidator = ntaDependencyValidation;

        conLogic.checkConditionD = checkConditionD;
        conLogic.checkConditionO = checkConditionO;
        conLogic.checkConditionQ = checkConditionQ;
        conLogic.checkConditionR = checkConditionR;
        conLogic.checkConditionT = checkConditionT;
        conLogic.checkConditionG = checkConditionG;

        conLogic.hasBelemmering = hasBelemmering;
        conLogic.hasZomerNachtVent = hasZomerNachtVent;


        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         INITALIZIATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        initConstructies();
        initZomernachtVentilatie();
        checkConditionG();
        checkVisibilityEntiteiten();
        if (_isKozijnOppPerMerk) {
            setAllOppervlakteTransparanteConstructies();
        }

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        // vlakdelen (dicht, transp en paneel) met als parent het vlak
        function constrDs() {
            return ntaEntityData.getChildren(_vlak, 'CONSTRD');
        } //-- end: constrDs ------------------------------------------------------------------------//

        function constrTs() {
            return ntaEntityData.getChildren(_vlak, 'CONSTRT');
        } //-- end: constrTs ------------------------------------------------------------------------//

        function constrLs() {
            return ntaEntityData.getChildren(_vlak, 'CONSTRL');
        } //-- end: constrLs ------------------------------------------------------------------------//

        function constrPs() {
            return ntaEntityData.getChildren(_vlak, 'CONSTRP');
        } //-- end: constrPs ------------------------------------------------------------------------//

        function constrWGs() {
            return ntaEntityData.getChildren(_vlak, 'CONSTRWG');
        } //-- end: constrWGs ------------------------------------------------------------------------//

        function constrKenmZOMNAC() {
            return constrTs().flatMap(constrT => constrKenmZOMNACbyConstr(constrT));
        } //-- end: constrKenmZOMNAC ------------------------------------------------------------------------//

        function constrKenmBELEMM() {
            return constrTs().flatMap(constrT => constrKenmBELEMMERINGbyConstr(constrT));
        } //-- end: constrKenmBELEMM ------------------------------------------------------------------------//

        function constrKenmZOMNACbyConstr(constrT) {
            return ntaEntityData.getFirstChild(constrT, 'CONSTRZOMNAC');
        } //-- end: constrKenmZOMNACbyConstr ------------------------------------------------------------------------//

        function constrKenmBELEMMERINGbyConstr(constrT) {
            return ntaEntityData.getFirstChild(constrT, 'BELEMMERING');
        } //-- end: constrKenmBELEMMERINGbyConstr ------------------------------------------------------------------------//

        function getSelectedAORofAOS() {
            //-- Alleen als het vlak een UNIT-RZ of een GRUIMTE als parent heeft, kan het een AOS of AOR geselecteerd hebben
            const zone = ntaEntityData.getFirstParent(_vlakId, ['UNIT-RZ', 'GRUIMTE']);
            if (zone) {
                return ntaEntityData.getFirstParent(_vlakId, 'RZ') || null;
            }
            return null;
        } //-- end: getSelectedAORofAOS ------------------------------------------------------------------------//

        function initVlakValues() {
            if (_vlak) {
                _vlakName = _vlak.PropertyDatas["BEGR_OMSCHR"].Value;
                _vlakA = _vlak.PropertyDatas["BEGR_A"].Value;
                _vlakH = _vlak.PropertyDatas["BEGR_HEL"].Value;
                _vlakType = _vlak.PropertyDatas["BEGR_VLAK"].Value;

                if (_vlakType === 'VLAK_KELDERW') {
                    _vlakBegr = _vlak.PropertyDatas["BEGR_KWAND"].Value;
                    if (_vlakBegr) {
                        //-- kelderwanden kunnen alleen gekoppeld zijn aan een Vloer. Opzoek naar een relatie tussen BEGR en BEGR
                        const vloer = ntaEntityData.getFirstParent(_vlak, 'BEGR');

                        //-- Begrenzingnaam samenstellen
                        if (vloer) {
                            _vlakBegrOmschr = 'grond; ' + vloer.PropertyDatas['BEGR_OMSCHR'].Value;
                        }
                    }
                } else {
                    let prop = null;
                    switch (_vlakType) {
                        case 'VLAK_VLOER_BOVBUI': prop = ntabuilding.properties['BEGR_VLOER_BOVBUI']; break;
                        case 'VLAK_VLOER': prop = ntabuilding.properties['BEGR_VLOER']; break;
                        case 'VLAK_GEVEL': prop = ntabuilding.properties['BEGR_GEVEL']; break;
                        case 'VLAK_DAK': prop = ntabuilding.properties['BEGR_DAK']; break;
                    }
                    if (prop) {
                        _vlakBegr = prop.getValue(_vlak);
                        if (_vlakBegr) {
                            const aorAosZone = getSelectedAORofAOS();
                            if (aorAosZone) {
                                //-- Als er een AOS of AOR is geselecteerd, moet de naam hiervan weergegeven worden
                                let typeZone = aorAosZone.PropertyDatas['RZ_TYPEZ'].Value;

                                //-- Omschrijving van de AOS of AOR ophalen
                                let omschr = aorAosZone.PropertyDatas['RZ_OMSCHR'].Value;

                                //-- Begrenzingnaam van het vlak samenstellen
                                let begrName = typeZone + '; ' + omschr;

                                if (_vlakBegr !== 'n.v.t.') {
                                    begrName = begrName + '; ' + _vlakBegr;
                                }
                                _vlakBegrOmschr = begrName;
                            } else {
                                //-- Als er geen AOS of AOR is geselecteerd, moet er alleen een codedvalue opgehaald worden
                                const findCodedValue = prop.Domain.Codes.find(function (x) { return x.Id === _vlakBegr; });
                                if (findCodedValue) {
                                    _vlakBegrOmschr = findCodedValue.Value;
                                }
                            }
                        }
                    }
                }
            }
        } //-- end: initVlakValues ------------------------------------------------------------------------//

        function initConstructies() {
            if (!ntabuilding.canSave()) return;
            if (_vlak) {
                //-- een nieuwe dichte constructie is handig maar niet noodzakelijk. Als gebruiker geen dichte delen heeft wil je niet dat er steeds één aangemaakt wordt,
                //-- dus eigenlijk bij het aanmaken van een begrenzing moet er standaard een dichtdeel aangemaakt worden. En anders niet.
                if (constrWGs().length === 1) {
                    //-- zet de waarde voor de oppvlakte gelijk aan het vlak opp als er nog 1 vlak over is. Dit wordt ook aangepast als het het opp van de begrenzing (het vlak) wijzigt in begrenzingsfactory.
                    const propOpp = ntabuilding.properties['CONSTRWG_OPP'];
                    saveValue(propOpp, constrWGs()[0], _vlakA);
                }
            }
        } //-- end: initConstructies ------------------------------------------------------------------------//

        function initZomernachtVentilatie() {
            if (!ntabuilding.canSave()) return;
             // -- Conditie [A5]
            let prop = ntabuilding.properties['CONSTRT_ZNVENT'];

            if (!checkConditionN() && checkConditionA5()) {
                //-- wanneer er sprake is <> buitenlucht Conditie [N] en basisopname OF constructie van AOR of AOS Conditie [A5] dan voor alle T constructies zomernacht 'niet aanwezig'
                for (const constrT of constrTs()) {
                    saveValue(prop, constrT, 'ZOMERNVENT_NAANW');
                }
            }
        } //-- end: initZomernachtVentilatie ------------------------------------------------------------------------//

        function getPropData(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = ntaSharedLogic.getPropData(prop, entdata);

            switch (prop.Id) {
                case 'KENMKR_WW_GVL':
                case 'KENMKR_WW_KR': {
                    const libconstr = ntaEntityData.getFirstParent(entdata, 'LIBCONSTRD');
                    if (libconstr) {
                        propdata.Value = libconstr.EntityDataId;
                    }
                    break;
                }
            }

            return propdata;
        } //-- end: getPropData ------------------------------------------------------------------------//

        function checkVisibilityEntiteiten() {
            if (!ntabuilding.canSave()) return;

            //voorkom dat igv verliesoppervlakken bij rekenezones, de entiteit op relevant wordt gezet.
            if (vlakIsGruimteMetVerliesoppervlakkenBijRekenzones()) return;

            const transpVisible = checkConditionD();
            ntaEntityData.setEntityRelevancy(constrTs(), transpVisible);
            ntaEntityData.setEntityVisibility(constrTs(), transpVisible);

            const kenmVloerVisible = checkConditionT();
            ntaEntityData.setEntityRelevancy(_constrKenmV, true); //-- deze entity moet altijd naar de rekenkern omdat hier of de omtrek van het veld in zit of de perimeter van CONSTRL
            ntaEntityData.setEntityVisibility(_constrKenmV, kenmVloerVisible);

            checkVisibilityKoudebruggen();

            const grondWandVisible = _isGrondwand;
            ntaEntityData.setEntityRelevancy(constrWGs(), grondWandVisible);
            ntaEntityData.setEntityVisibility(constrWGs(), grondWandVisible);
            ntaEntityData.setEntityRelevancy(_constrKenmW, grondWandVisible);
            ntaEntityData.setEntityVisibility(_constrKenmW, grondWandVisible);
            //-- als grondwand (vlaktype VLAK_KELDERW) zichtbaar is zijn de dichte delen niet zichtbaar en andersom
            ntaEntityData.setEntityRelevancy(constrDs(), !grondWandVisible);
            ntaEntityData.setEntityVisibility(constrDs(), !grondWandVisible);

            const ondergronds = _isKruipruimte || _isOnverwKelder;
            ntaEntityData.setEntityRelevancy(_constrKenmKRVENT, ondergronds);
            ntaEntityData.setEntityVisibility(_constrKenmKRVENT, ondergronds);
            ntaEntityData.setEntityRelevancy(_constrKenmWWGVL, true);
            ntaEntityData.setEntityVisibility(_constrKenmWWGVL, ondergronds);
            ntaEntityData.setEntityRelevancy(_constrKenmWWKLDR, ondergronds);
            ntaEntityData.setEntityVisibility(_constrKenmWWKLDR, ondergronds);
        } //-- end: checkVisibilityEntiteiten ------------------------------------------------------------------------//

        function checkVisibilityKoudebruggen() {
            const koudebruggenVisible = settingsMenuData.getSetting('SETTINGS_THBRUG').Value === "False";
            ntaEntityData.setEntityRelevancy(constrLs(), koudebruggenVisible);
            ntaEntityData.setEntityRelevancy(constrPs(), koudebruggenVisible);
            ntaEntityData.setEntityVisibility(constrLs(), koudebruggenVisible);
            ntaEntityData.setEntityVisibility(constrPs(), koudebruggenVisible);
        } //-- end: checkVisibilityKoudebruggen ------------------------------------------------------------------------//


        function vlakIsGruimteMetVerliesoppervlakkenBijRekenzones() {
            const vlakParent = ntaEntityData.getFirstParent(_vlak);
            return vlakParent && vlakParent.EntityId === 'GRUIMTE' && vlakParent.PropertyDatas['GRUIMTE_AV_INVOER'].Value === 'GRUIMTE_AV_INVOER_RZ';
        }

        function getListItem(prop, entdata, list = null) {
            let listItem = null;

            if (prop && prop.Domain && prop.Domain.DomainType > 1 && entdata) {
                var tmpList = null;
                var tmpId;
                var propData = entdata.PropertyDatas[prop.Id];
                switch (prop.Id) {
                    default:
                        tmpId = propData.Value;
                        tmpList = list; // de gefilterde getCodedValue list. Op deze list moet ik checken
                        if (!tmpList) {
                            //-- anders ongefilterde codedValue list gebruiken
                            tmpList = prop.Domain.Codes;
                        }
                        break;
                }

                if (propData.Value && tmpList && tmpList.length > 0) {
                    listItem = tmpList.find(function (x) { return x.Id === tmpId; });
                }
            }

            return listItem;
        } //-- end: getListItem ------------------------------------------------------------------------//

        function saveValue(prop, entdata, newValue) {
            if (prop.Id === 'CONSTRT_REGEL') {
                const valueToSave = newValue === undefined ? prop.getValue(entdata) : newValue;
                if (valueToSave === undefined) {
                    return; // read-only waarde voor regeling niet opslaan
                }
            }

            ntaSharedLogic.saveValue(prop, entdata, newValue, conLogic);
        } //-- end: saveValue ------------------------------------------------------------------------//

        function validate(prop, propdata, entdata) {
            if (!prop || !entdata || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            let hidden = isHidden(prop, entdata);
            let readonly = isReadOnly(prop, entdata);

            if (!hidden) {
                //-- voor de constructie met een relatie tot de lib moet gecontroleerd worden of ze één relatie hebben,
                //-- dan is het goed en moet value van propdata op de EntityDataId van de lib gezet worden zodat ze door de validatie komen.
                let libEntiteit = '';
                switch (prop.Id) {
                    case 'CONSTRD_LIB': libEntiteit = 'LIBCONSTRD'; break;
                    case 'CONSTRWG_LIB': libEntiteit = 'LIBCONSTRD'; break;
                    case 'CONSTRT_LIB': libEntiteit = 'LIBCONSTRT'; break;
                    case 'CONSTRL_LIB': libEntiteit = 'LIBCONSTRL'; break;
                    case 'CONSTRP_LIB': libEntiteit = 'LIBTHBRUG'; break;
                    case 'KENMKR_WW_GVL': libEntiteit = 'LIBCONSTRD'; break;
                    case 'KENMKR_WW_KR': libEntiteit = 'LIBCONSTRD'; break;
                }
                if (libEntiteit !== '') {
                    // BM 2021-04-23: ook checken of propdata.Value === "n.v.t" bij oudere berekeningen
                    // MCO 2021-05-07: ook controleren of propdata.Value wel een bestaande EntityDataId beschrijft (na import of kopiëren berekening is die veranderd)
                    // TFO 2021-05-19: als de value gelijk is aan KENMKR_WW_KR_NIETGEISOLEERD hoort er geen relatie te bestaan
                    if (!propdata.Value || propdata.Value === "n.v.t" || (!ntaEntityData.get(propdata.Value) && !['KENMKR_WW_KR_NIETGEISOLEERD', 'KENMKR_WW_KR_GEISOLEERD', 'KENMKR_WW_KR_ISOLATIEONBEKEND'].includes(propdata.Value))) {
                        const parent = ntaEntityData.getFirstParent(entdata, libEntiteit);
                        if (parent) {
                            ntaEntityData.saveprop(propdata, parent.EntityDataId);
                        }
                    }

                    //check ook op meerdere relaties met bieb items
                    const relations = ntaEntityData.getParentRelations(entdata, libEntiteit);
                    if (relations.length > 1) { // Klopt niet! Constructie heeft meerdere relaties met bieb items.
                        relations.forEach(function (relation, index) {
                            if (index > 0) { // Eerste bewaren, rest verwijderen.
                                ntaEntityData.deleteRelation(relation.EntityRelationDataId);
                            }
                        });
                    }
                    //BM: 2022-08-09: check ook of er geen relatie is. Indien geen relatie dan aanmaken.
                    if (relations.length < 1 && propdata.Value) {
                        ntaEntityData.createRelation(propdata.Value, entdata.EntityDataId, false, false);
                    }
                }
            }

            let valid = ntaValidation.IsValid(conLogic.form_bldconstr, prop, propdata, hidden, readonly);

            //-- in de switch specifieke foutmeldingen genereren. Standaard foutmeldingen zijn al in ntaValidation afgevangen
            switch (prop.Id) {
                case 'CONSTRD_OPP':
                case 'CONSTRT_OPP': {
                    valid = valid && checkConditionG();
                    break;
                }
                case 'CONSTRD_LIB':
                case 'CONSTRWG_LIB':
                case 'CONSTRT_LIB':
                case 'CONSTRL_LIB':
                case 'CONSTRP_LIB':
                case 'KENMKR_WW_GVL':
                case 'KENMKR_WW_KR': {
                    let error;
                    switch (prop.Id) {
                        case 'CONSTRT_LIB': error = '[E050]'; break;
                        case 'CONSTRL_LIB': error = '[E051]'; break;
                        case 'CONSTRP_LIB': error = '[E052]'; break;
                        default: error = '[E049]'; break;
                    }

                    let validLib = true;
                    if (propdata.Touched && !hidden && !readonly) {
                        //-- als er geen lib constructies zijn voor dit type dan een melding geven dat je ze moet aanmaken in de bouw bibliotheek
                        //-- alleen melding geven wanneer prop zichtbaar is en niet readonly, anders kan de gebruiker het niet aanpassen
                        const libs = getLibCodedValues(prop);
                        if (libs.length <= 0) {
                            validLib = false;
                        } else if (valid && !libs.some(item => item.Id === propdata.Value)) {
                            //-- als er wel lib-constructies zijn voor dit type, maar de huidige
                            //--  waarde komt er niet in voor, dan moet die koppeling verwijderd
                            //--  worden, en de waarde op null gezet. Daarna roepen we checkPropdataInList
                            //--  aan, die zorgt ervoor dat er een valide waarde gekozen wordt, indien mogelijk.

                            //console.log({
                            //    propdataId: propdata.PropertyDataId,
                            //    propdataValue: propdata.Value,
                            //    begrenzing: ntaEntityData.getFirstParent(entdata, 'BEGR').PropertyDatas.BEGR_VLAK.Value,
                            //    oudLibType: ntaEntityData.get(propdata.Value).PropertyDatas.filter(pd => pd.PropertyId.endsWith('_TYPE')).map(pd => pd.Value).join(', '),
                            //});

                            // waarde leeghalen
                            propdata.Value = prop.DefaultValue;
                            // zorgen dat de koppeling verwijderd wordt (anders komen we in een oneindige lus)
                            validateDependencies(prop, entdata);
                            // als er bv. maar één relevante libconstructie is, zorgen dat die gekoppeld wordt
                            ntaSharedLogic.checkPropdataInList(prop, entdata, libs, conLogic);
                            // opnieuw standaardvalidatie uitvoeren, zodat een leeg veld als zodanig gemarkeerd wordt.
                            valid = ntaValidation.IsValid(conLogic.form_bldconstr, prop, propdata, hidden, readonly);
                        }
                    }
                    ntaSharedLogic.setMelding(error, propdata, conLogic.form_bldconstr, validLib);

                    valid = valid && validLib;
                    break;
                }
                default: {
                    if (valid && !hidden && !readonly && ntaValidation.hasCodedValues(prop)) {
                        getCodedValues(prop, entdata);
                    }
                    break;
                }
            }
            return valid;
        } //-- end: validate ------------------------------------------------------------------------//

        function saveKoppelingConstrEnLibrary(propdata, child, parentEntityId) {
            //-- koppelingen zoeken tussen dit constructiedeel en library-constructies
            const relations = ntaEntityData.getParentRelations(child, parentEntityId);
            //-- controleer of de gewenste relatie niet al bestaat
            const existingRelation = relations.find(rel => rel.Parent === propdata.Value);

            //-- oude en evt. overtollige koppelingen verwijderen; maar als de juiste relatie al bestond, moet die behouden blijven.
            for (const oldRelation of relations.filter(rel => rel !== existingRelation)) {
                ntaEntityData.deleteRelation(oldRelation.EntityRelationDataId);
            }

            //-- koppeling toevoegen als deze nog niet bestond
            if (!existingRelation) {
                ntaEntityData.createRelation(propdata.Value, child.EntityDataId, false, false);
            }
        } //-- end: saveKoppelingConstrEnLibrary ------------------------------------------------------------------------//

        function deleteKoppelingConstrEnLibrary(propdata, child, parentEntityId) {
            //-- koppeling(en) zoeken tussen dit constructiedeel en library-constructies
            const relations = ntaEntityData.getParentRelations(child, parentEntityId);

            //-- koppeling(en) verwijderen;
            for (const relation of relations) {
                ntaEntityData.deleteRelation(relation.EntityRelationDataId);
            }

            //-- value van de propdata leegmaken en opslaan
            ntaEntityData.saveprop(propdata, "");

        } //-- end: deleteKoppelingConstrEnLibrary ------------------------------------------------------------------------//

        function getLibCodedValues(prop) {
            let libCodedValues = [];
            switch (prop.Id) {
                case 'CONSTRD_LIB':
                case 'CONSTRWG_LIB': {
                    const libConstructions = ntaEntityData.getListWithEntityId('LIBCONSTRD')
                        .filter(ed => {
                            const libtype = ed.PropertyDatas["LIBCONSTRD_TYPE"].Value;
                            return (_vlakType === "VLAK_VLOER" && libtype === "LIBVLAK_VLOER") ||
                                (_vlakType === "VLAK_VLOER_BOVBUI" && libtype === "LIBVLAK_VLOER_BOVBUI") ||
                                (_vlakType === "VLAK_GEVEL" && (libtype === "LIBVLAK_GEVEL")) ||
                                (_vlakType === "VLAK_DAK" && libtype === "LIBVLAK_DAK") ||
                                (_vlakType === "VLAK_KELDERW" && libtype === "LIBVLAK_KELDERW");
                        });
                    libCodedValues = libConstructions
                        .map(lib => ({
                            "Id": lib.EntityDataId,
                            "Value": lib.PropertyDatas['LIBCONSTRD_OMSCHR'].Value + ' (R<sub>c</sub> = ' + lib.PropertyDatas['LIBCONSTRD_RC'].Value + ')',
                        }));
                    break;
                }
                case 'CONSTRT_LIB': {
                    const libConstructions = ntaEntityData.getListWithEntityId('LIBCONSTRT');
                    libCodedValues = libConstructions
                        .map(lib => ({
                            "Id": lib.EntityDataId,
                            "Value": lib.PropertyDatas['LIBCONSTRT_OMSCHR'].Value + ' (U =  ' + lib.PropertyDatas['LIBCONSTRT_U'].Value + ' / g<sub>gl</sub> = ' + lib.PropertyDatas['LIBCONSTRT_G'].Value + ')',
                        }));
                    break;
                }
                case 'CONSTRL_LIB': {
                    //-- Conditie [Z]
                    const libConstructions = ntaEntityData.getListWithEntityId('LIBCONSTRL');
                    const positions = new Set();
                    switch (_vlakType) {
                        case 'VLAK_VLOER': {
                            positions.add('LCPOS_FUND'); // vloer = fundering of vloer.
                            positions.add('LCPOS_VLOER'); // vloer = fundering of vloer.
                            break;
                        }
                        case 'VLAK_VLOER_BOVBUI': {
                            positions.add('LCPOS_VLOER'); // vloer = fundering of vloer.
                            break;
                        }
                        case 'VLAK_DAK': {
                            positions.add("LCPOS_DAK"); // dak = dak of gevel.
                            positions.add("LCPOS_VLOERONGEB"); // dak = dak of gevel.
                            break;
                        }
                        case 'VLAK_GEVEL': {
                            positions.add("LCPOS_VLOER"); // gevel = vloer ongeb of vloer of dak.
                            positions.add("LCPOS_VLOERONGEB"); // gevel = vloer ongeb of vloer of dak.
                            positions.add("LCPOS_DAK"); // gevel = vloer ongeb of vloer of dak.
                            break;
                        }
                        case 'VLAK_KELDERW': {
                            positions.add("LCPOS_FUND"); // kelderwand = fundering.
                            break;
                        }
                    }
                    libCodedValues = libConstructions
                        .filter(libConstr => positions.has(libConstr.PropertyDatas['LIBCONSTRL_POS'].Value))
                        .map(lib => ({
                            "Id": lib.EntityDataId,
                            "Value": lib.PropertyDatas['LIBCONSTRL_OMSCHR'].Value + ' (Ψ =  ' + lib.PropertyDatas['LIBCONSTRL_PSI'].Value + ')',
                        }));

                    break;
                }
                case 'CONSTRP_LIB': {
                    const libConstructions = ntaEntityData.getListWithEntityId('LIBTHBRUG');
                    libCodedValues = libConstructions
                        .map(lib => ({
                            "Id": lib.EntityDataId,
                            "Value": lib.PropertyDatas['LIBTHBRUG_OMSCHR'].Value + ' (X = ' + lib.PropertyDatas['LIBTHBRUG_CHI'].Value + ')',
                        }));
                    break;
                }
                case 'KENMKR_WW_GVL': {
                    const libConstructions = ntaEntityData.getListWithEntityId('LIBCONSTRD')
                        .filter(ed => { //-- conditie [J] en [A1] -> alleen gevel en paneel (paneel bestaat alleen niet...)
                            const libtype = ed.PropertyDatas["LIBCONSTRD_TYPE"].Value;
                            return libtype === "LIBVLAK_GEVEL";
                        });
                    libCodedValues = libConstructions
                        .map(lib => ({
                            "Id": lib.EntityDataId,
                            "Value": lib.PropertyDatas['LIBCONSTRD_OMSCHR'].Value + ' (R<sub>bw</sub> = ' + lib.PropertyDatas['LIBCONSTRD_RC'].Value + ')',
                        }));
                    break;
                }
                case 'KENMKR_WW_KR': {
                    const libConstructions = ntaEntityData.getListWithEntityId('LIBCONSTRD')
                        .filter(ed => {
                            const libtype = ed.PropertyDatas["LIBCONSTRD_TYPE"].Value;
                            return libtype === "LIBVLAK_BODEM";
                        }); //-- conditie [A1] -> alleen bodem
                    libCodedValues = libConstructions
                        .map(lib => ({
                            "Id": lib.EntityDataId,
                            "Value": lib.PropertyDatas['LIBCONSTRD_OMSCHR'].Value + '(R<sub>bf</sub> = ' + lib.PropertyDatas['LIBCONSTRD_RC'].Value + ')',
                        }));

                    libCodedValues.unshift(...ntaValidation.codedValues(prop)); //-- deze prop heeft heeft ook nog drie standaard values voor niet, wel en onbekend geisoleerd
                    break;
                }
            }

            //-- Haal een evt. gecachete versie van de lijst voor deze opwekker op. Als de huidige lijst niet gewijzigd is, dan geven we de oude
            //-- lijst terug, zodat Angular kan zien dat er niets gewijzigd is.

            return _listCache.useCacheIfUnchanged(prop.Id, libCodedValues, (a, b) => a.Id === b.Id);
        } //-- end: getLibCodedValues ------------------------------------------------------------------------//

        function getCodedValues(prop, entdata) {
            let typesList = [];

            if (!entdata || !prop || entdata.BuildingId !== ntabuilding.buildingId) {
                return typesList;
            }

            switch (prop.Id) {
                case 'CONSTRT_BESCH': // C14
                    {
                        typesList = ntaValidation.codedValues(prop);

                        if (_vlakH && _vlakH !== "90") { //[X] hide option in dropdown if field B08≠90 (inclination)
                            typesList = typesList.filter(function (x) { return x.FilterValue2 !== "INCL90"; });
                        }

                        //-- Conditie [A16]
                        if (_isBasisOpname) {
                            typesList = typesList.filter(function (x) { return x.FilterValue4 === 'B'; });

                            //-- Conditie [A3]
                            const koelingsystemen = ntaEntityData.getListWithEntityId('KOEL');
                            if (koelingsystemen.length <= 0) {
                                typesList = typesList.filter(function (x) { return x.FilterValue3 === 'GK'; });
                            }
                        }


                        if (ntabuilding.ntaVersionId < 100) {
                            typesList = typesList.filter(function (x) { return x.Id !== 'BELEMTYPE_CO_EN_ZIJ'; });

                        }
                        break;
                    }
                case 'CONSTRT_REGEL': { // C16

                    if (checkConditionL(entdata)) return []; // read-only "n.v.t." weergeven

                    const filterValue = ntaSharedLogic.isUtiliteit() ? 'U' : 'W';
                    typesList = ntaValidation.codedValues(prop)
                        .filter(x => x.FilterValue1 === filterValue);

                    //-- [A21] verberg ZONWREG_H_ZLW, ZONWREG_H_MLW en ZONWREG_H, indien in C15 is gekozen voor een zonwering waarbij in table7-5-6 kolom key=auto (ZONW_GMW)
                    if (entdata.PropertyDatas['CONSTRT_ZONW'].Value === "ZONW_GMW") {
                        const regelingList = new Set(['ZONWREG_H_ZLW', 'ZONWREG_H_MLW', 'ZONWREG_H']); 
                        typesList = typesList.filter(x => !regelingList.has(x.Id));
                    }

                    break;
                }
                case 'CONSTRD_LIB':
                case 'CONSTRT_LIB':
                case 'CONSTRL_LIB':
                case 'CONSTRP_LIB':
                case 'CONSTRWG_LIB':
                case 'KENMKR_WW_GVL':
                case 'KENMKR_WW_KR':
                    {
                        typesList = getLibCodedValues(prop);
                        break;
                    }
                case 'CONSTRT_ZONW':
                    {
                        typesList = ntaValidation.codedValues(prop);
                        if (ntabuilding.ntaVersionId < 200) {
                            typesList = typesList.filter(function (x) { return x.FilterValue1 !== 'ge3-2'; });
                        }
                        break;
                    }
                default:
                    typesList = ntaValidation.codedValues(prop);
                    break;
            }

            ntaSharedLogic.checkPropdataInList(prop, entdata, typesList, conLogic);
            return typesList;
        } //-- end: getCodedValues ------------------------------------------------------------------------//

        function isReadOnly(prop, entdata) {
            let bReadOnly = false;

            if (!prop || entdata && entdata.BuildingId !== ntabuilding.buildingId) {
                return bReadOnly;
            }

            switch (prop.Id) {
                case 'CONSTRT_REGEL':
                    bReadOnly = checkConditionL(entdata);
                    break;
                case 'CONSTRD_OPP':
                    bReadOnly = constrDs().length === 1;
                    break;
                case 'CONSTRT_OPP':
                    bReadOnly = _isKozijnOppPerMerk;
                    break;
                case 'CONSTRWG_OPP':
                    bReadOnly = true;
                    break;
                case 'CONSTRT_GGL_ALT':
                case 'CONSTRT_GGL_DIF':
                    bReadOnly = checkConditionY(entdata);
                    break;
                case 'CONSTRT_BESCH':
                case 'CONSTRT_ZONW':
                    bReadOnly = checkConditionE_K(entdata);
                    break;
                case 'CONSTRZOMNAC_DOORLV':
                    bReadOnly = true;
                    break;
            }

            return bReadOnly;
        } //-- end: isReadOnly ------------------------------------------------------------------------//

        function isHidden(prop, entdata = null) {
            //-- VO 2020-10-01: deze isHidden wordt ook gebruikt voor de tabelheader. In andere factory's heet dit propertyHeaderIsHidden. Voor
            //-- bouwkundig doen we dit in een, vandaar anders checken op entdata.
            if (!prop || (entdata && entdata.BuildingId !== ntabuilding.buildingId)) {
                return false;
            }

            const propdata = entdata && prop.getData(entdata);

            let showit = true;
            let relevant = null;

            //-- nu per property kijken wat de conditie is
            switch (prop.Id) {
                case 'CONSTRERROR_OPEN':
                    {
                        showit = false;
                        break;
                    }
                case 'CONSTRERROR_LINCONSTR':
                    {
                        showit = false;
                        break;
                    }
                case "CONSTRD_LIB":
                    {
                        showit = !vlakIsGruimteMetVerliesoppervlakkenBijRekenzones();
                        break;
                    }
                case "CONSTRT_BESCH":
                    {
                        //-- C14
                        showit = checkConditionN();
                        if (isReadOnly(prop, entdata)) {
                            relevant = false;
                        }
                        break;
                    }
                case "CONSTRT_ZONW":
                    {
                        //-- C15
                        showit = checkConditionN() && !checkConditionA4();
                        break;
                    }
                case "CONSTRT_REGEL":
                    {
                        //-- C16
                        // [A23] als WN/WB toon veld alleen als versie is gt3.2
                        showit = checkConditionN() && !checkConditionL() && (ntaSharedLogic.isUtiliteit() || ntabuilding.ntaVersionId >= 300);
                        if (isReadOnly(prop, entdata)) {
                            relevant = false;
                        }
                        break;
                    }
                case 'CONSTRT_ZNVENT':
                    {
                        //-- C17
                        showit = checkConditionN() && !checkConditionA5();
                        break;
                    }
                case "CONSTRT_AANT":
                    {
                        //-- C12
                        showit = checkConditionI();
                        break;
                    }
                case "KENMW_AFSTMV_VL":
                    {
                        showit = checkConditionR();
                        break;
                    }
                case "KENMV_HVL_MV":
                    {
                        //-- C30 - conditie [U]
                        showit = checkConditionU();
                        break;
                    }
                case "KENMV_OMTR_VL":
                    {
                        //-- C31
                        showit = checkConditionA2();
                        relevant = true;
                        if (!showit) { // vul in: de som van de lengte van de lineaire constructies
                            let perimeter = 0;
                            constrLs().forEach(function (lconstr, index) {
                                const clPropData = lconstr.PropertyDatas["CONSTRL_LEN"];
                                perimeter += ntaSharedLogic.parseFloat(clPropData && clPropData.Value, 0);
                            });
                            const newValue = ntaRounding.roundAndAddZerosNewValue(prop, perimeter);
                            saveValue(prop, entdata, newValue);
                        }
                        break;
                    }
                case "KENMKR_VENT":
                    {
                        //-- C34
                        //-- Conditie [A12]
                        showit = false;
                        if (_isKruipruimte) {
                            //-- Conditie [S] show row if table8 key1 = kruipruimte
                            showit = !_isBasisOpname;
                            if (_isBasisOpname && propdata) {
                                //-- als er een propdata is en basisopname moet de waarde 0.0012 meegegeven worden voor dit veld. Dit moet eigen lijknar validate dependecie
                                const newValue = ntaRounding.roundAndAddZerosNewValue(prop, 0.0012);
                                saveValue(prop, entdata, newValue);
                            }
                        }
                        break;
                    }
                case "KENMKR_OPM":
                    //-- deze opmerking alleen laten zien als WW_GVL en WW_KR niet zichtbaar zijn
                    showit = !(_isKruipruimte || _isOnverwKelder);
                    break;
                case "KENMKR_WW_GVL":
                    {
                        showit = _isKruipruimte || _isOnverwKelder;
                        relevant = true;
                        break;
                    }
                case "KENMKR_WW_GVL_OPM":
                    //-- deze opmerking nooit laten zien omdat we die van WW_KR al laten zien.
                    showit = false;
                    break;
                case "KENMKR_WW_KR":
                    {
                        showit = (_isKruipruimte || _isOnverwKelder);
                        break;
                    }
                case "KENMKR_WW_KR_OPM":
                    //-- deze opmerking nooit laten zien omdat we die van WW_KR al laten zien.
                    showit = (_isKruipruimte || _isOnverwKelder);
                    break;
                case "CONSTRT_GGL_ALT": // C05
                case "CONSTRT_GGL_DIF": // C06
                    {
                        showit = !checkConditionY();
                        if (isReadOnly(prop, entdata)) {
                            relevant = false;
                        }
                        break;
                    }
                case "CONSTRWG_OPP":
                case "CONSTRWG_LIB":
                    {
                        showit = checkConditionQ();
                        break;
                    }
                case "CONSTRD_L":
                case "CONSTRD_B":
                    {
                        //-- conditie [A19]
                        showit = constrDs().length > 1;
                        break;
                    }
                case "CONSTRT_L":
                case "CONSTRT_B":
                    {
                        //-- conditie [A17] alleen als gekozen voor "geen kozijnmerken invoeren" (omgekeerde van conditie [I])
                        showit = !checkConditionI();
                        break;
                    }
                case "CONSTRWG_L":
                case "CONSTRWG_B":
                    {
                        //-- zijn in principe nooit zichtbaar omdat CONSTRWG maar max 1x voor mag komen,
                        //-- deze props zijn noodzakelijk voor de printuitvoer om een gelijke behandeling
                        //-- te krijgen met CONSTRD waarmee ze in 1 tabel zitten
                        showit = false;
                        break;
                    }
                case 'CONSTRZOMNAC_DOORLF': //--Z04
                    {
                        //-- conditie [VKB]
                        showit = checkConditionVKB(entdata);

                        break;
                    }
                case 'CONSTRZOMNAC_OHOEKV': //--Z06
                case 'CONSTRZOMNAC_DOORLV': //--Z05
                    {
                        //-- conditie [VKB]
                        showit = checkConditionVKB(entdata);

                        break;
                    }
                case 'CONSTRZOMNAC_INV': //--Z08
                    {
                        //-- conditie [VKC] toon alleen als versie gt3.2
                        showit = ntabuilding.ntaVersionId >= 300;
                        break;
                    }
                case 'CONSTRZOMNAC_COEFF_E': //--Z11
                case 'CONSTRZOMNAC_COEFF_D': //--Z10
                    {
                        //-- conditie [VKA] toon als Z08 = 'voorziening gemeten volgens NEN-EN 13030'. Als Z08 verborgen dan veld verbergen
                        showit = ntabuilding.ntaVersionId >= 300 && entdata.PropertyDatas['CONSTRZOMNAC_INV'].Value === "CONSTRZOMNAC_INV_Y";
                        break;
                    }
            }

            if (_propertiesL.includes(prop)) { //constrL lineaire constructies
                if (entdata && entdata.EntityId === "CONSTRL") {
                    showit = checkConditionO();
                }
            }

            if (_propertiesP.includes(prop)) { //constrL lineaire constructies
                if (entdata && entdata.EntityId === "CONSTRP") {
                    showit = checkConditionO();
                }
            }

            if (_propertiesKenmBELEMMERING.includes(prop)) { //belemmering
                if (entdata) {
                    if (entdata.EntityId !== "BELEMMERING") {
                        showit = hasBelemmering(entdata);
                    } else {
                        const parent = ntaEntityData.getFirstParent(entdata, 'CONSTRT');
                        if (parent) {
                            const belemmeringtype = parent.PropertyDatas['CONSTRT_BESCH'].Value;
                            showit = ntaSharedLogic.showBelemmeringsProperty(prop, entdata, belemmeringtype);
                        }
                    }
                }
            }

            // standaard is relevant gelijk aan visible
            if (relevant === null) relevant = showit;

            if (propdata) {
                //--- voor de isHidden van de header van tabellen is de relevantie zetten niet nodig.
                ntaEntityData.setPropdataRelevancy(propdata, relevant, conLogic.form_bldconstr);
                ntaEntityData.setPropdataVisibility(propdata, showit && entdata.Visible);
            }
            return !showit;
        } //-- end: isHidden ------------------------------------------------------------------------//


        function hasBelemmering(entdata) {
            let result = false;

            if (!entdata) {
                return result;
            }

            const belemmering = ntaEntityData.getFirstChild(entdata, 'BELEMMERING');
            if (belemmering) {
                const belemmeringtype = entdata.PropertyDatas['CONSTRT_BESCH'].Value;
                result = !['BELEMTYPE_MIN', 'BELEMTYPE_VOLLEDIG', 'BELEMTYPE_OVERIG', 'n.v.t.'].includes(belemmeringtype);

                ntaEntityData.setEntityRelevancy(belemmering, result);
                ntaEntityData.setEntityVisibility(belemmering, result);
            }

            return result;
        } //-- end: hasBelemmering ------------------------------------------------------------------------//

        function hasZomerNachtVent(entdata) {
            let result = false;
            if (!entdata) {
                return result;
            }
            const zomerNachtVent = ntaEntityData.getFirstChild(entdata, 'CONSTRZOMNAC');
            if (zomerNachtVent) {
                const zomernachtventilatie = entdata.PropertyDatas['CONSTRT_ZNVENT'].Value;
                result = zomernachtventilatie === "ZOMERNVENT_AANW";

                ntaEntityData.setEntityRelevancy(zomerNachtVent, result);
                ntaEntityData.setEntityVisibility(zomerNachtVent, result);
            }

            return result;
        } //-- end: hasZomerNachtVent ------------------------------------------------------------------------//


        function checkConditionD() {
            //-- Conditie [D] show table if selection at field B04 has table8 key1=buitenlucht, sterk geventileerd, aor, aor_for, aos, aos_for or avr
            let show = true;

            //van vloer alles, behalve VL_BTNL_ONDER (buitenlucht, HOR); sterk geventileerd (VL_ST_GEVENT); AOR,HOR; AOS, HOR
            if (_vlakType === 'VLAK_VLOER' && _vlakBegr && _vlakBegr.slice(-3) !== "HOR" && _vlakBegr !== 'VL_AOR_FOR' && _vlakBegr !== 'VL_AOS_FOR') {
                show = false;
            }
            //van gevel GVL_WATER (water)
            else if (_vlakType === 'VLAK_GEVEL' && _vlakBegr === "GVL_WATER") {
                show = false;
            }
            //van dak DAK_WATER (water)
            else if (_vlakType === 'VLAK_DAK' && _vlakBegr === "DAK_WATER") {
                show = false;
            }
            //van kederwand alles
            else if (_isGrondwand) {
                show = false;
            }

            return show;
        } //-- end: checkConditionD ------------------------------------------------------------------------//

        function checkConditionE_K(entdata) {
            //-- Conditie [E] if selected construction in the row has a ggl=0 (field L15) hide dropdown
            //-- Conditie [K] if selected construction in the row has a ggl=0 (field L15) hide dropdown and calculate with id 1 table7-5-6
            const constrTLib = entdata && ntaEntityData.getFirstParent(entdata, "LIBCONSTRT");
            if (constrTLib) {
                const constrTLibGgl = constrTLib.PropertyDatas["LIBCONSTRT_G"].Value;
                return ntaSharedLogic.parseFloat(constrTLibGgl, 0) === 0;
            }

            return false;
        } //-- end: checkConditionEK ------------------------------------------------------------------------//

        function checkConditionI() {
            //-- Conditie [I] toon kolom als L09=oppervlakte per kozijnmerk invoeren
            let showit = true;
            const libkozmerk = ntaEntityData.getFirstWithEntityId('LIBCONSTRFORM');
            if (libkozmerk) {
                const koz = libkozmerk.PropertyDatas["LIBCONSTRFORM_KOZ"];
                showit = koz.Value === "KOZKENM_OPP";
            }
            return showit;
        } //-- end: checkConditionI ------------------------------------------------------------------------//


        function checkConditionN() {
            //-- Conditie [N] show column only if selection at field B04 table8 key1=buitenlucht
            let buitenluchtbegrenzingen = [];
            switch (_vlakType) {
                case 'VLAK_VLOER_BOVBUI':
                    buitenluchtbegrenzingen = ["VL_BTNL_ONDER"];
                    break;
                case 'VLAK_GEVEL':
                    buitenluchtbegrenzingen = ["GVL_BTNL_N", "GVL_BTNL_NO", "GVL_BTNL_NW", "GVL_BTNL_O", "GVL_BTNL_W", "GVL_BTNL_Z", "GVL_BTNL_ZO", "GVL_BTNL_ZW"];
                    break;
                case 'VLAK_DAK':
                    buitenluchtbegrenzingen = ["DAK_BTNL_HOR", "DAK_BTNL_N", "DAK_BTNL_NO", "DAK_BTNL_NW", "DAK_BTNL_O", "DAK_BTNL_W", "DAK_BTNL_Z", "DAK_BTNL_ZO", "DAK_BTNL_ZW"];
                    break;
            }
            return buitenluchtbegrenzingen.includes(_vlakBegr);
        } //-- end: checkConditionN ------------------------------------------------------------------------//

        function checkConditionO() {
            //-- Conditie [O] show table if dropdown 'instellingen' selection 'lineaire warmteverliezen forfaitair' is OFF
            const show = settingsMenuData.getSetting('SETTINGS_THBRUG').Value === "False";

            return show;
        } //-- end: checkConditionO ------------------------------------------------------------------------//

        function checkConditionQ() {
            //-- Conditie [Q] show table only if selection at field B04 has table8 key1=grondwand
            const showit = _isGrondwand;
            return showit;
        } //-- end: checkConditionQ ------------------------------------------------------------------------//

        function checkConditionR() {
            //-- Conditie [R] show only if selection at field B04 has table8 key1=grondwand
            const showit = _isGrondwand;
            return showit;
        } //-- end: checkConditionR ------------------------------------------------------------------------//

        function checkConditionT() {
            //-- Conditie [T] show subheading only if row C30 (conditieU) and/or C31 (conditieA2) are shown
            const show = checkConditionU() || checkConditionA2();
            return show;
        } //-- end: checkConditionT ------------------------------------------------------------------------//

        function checkConditionU() {
            //-- Conditie [U] show row only if selection at field B04 has table8 key2=opBovenMv
            const showit = _isBovenMaaiveld;
            return showit;
        } //-- end: checkConditionU ------------------------------------------------------------------------//

        function checkConditionA2() {
            //-- Conditie [A2] show if dropdown 'instellingen' selection 'thermische bruggen forfaitair' is ON AND selection at field B04 has table8 key2='opBovenMv'or 'onderMv'
            const showit = settingsMenuData.getSetting('SETTINGS_THBRUG').Value === "True" && (_isBovenMaaiveld || _isOnderMaaiveld);
            return showit;
        } //-- end: checkConditionA2 ------------------------------------------------------------------------//

        function checkConditionA4() {
            //-- Conditie [A4] verberg kolom als G12=’basisopname’ EN op het formulier 'installaties' is geen koelsysteem aanwezig
            //-- EN versie le3.2
            return _isBasisOpname && !ntaEntityData.getFirstWithEntityId('KOEL') && ntabuilding.ntaVersionId < 300;
        } //-- end: checkConditionA4 ------------------------------------------------------------------------//

        function checkConditionA5() {
            //-- Conditie [A5] verberg indien G12=basisopname OF verberg als Z03='AOR' or 'AOS' (als constructie formulier is onderdeel van een zone AOR of AOS);
            //-- indien veld verborgen rekenen met 'niet aanwezig'. wordt geregeld in de initZomerNachtVentilatie.
            let hide = _isBasisOpname;

            const zone = ntaEntityData.getFirstParent(_vlakId, 'RZ');
            if (zone) {
                const zoneType = zone.PropertyDatas['RZ_TYPEZ'].Value;

                hide = hide || (zoneType === 'AOR' || zoneType === 'AOS');
            }

            return hide;
        } //-- end: checkConditionA5 ------------------------------------------------------------------------//

        function executeConditionG() {
            //-- dichte contructies heeft maar één constructie.Het opp daarvan is Atot - som van Atransparant berekent in calcFormula1
            const constrD = constrDs()[0];
            if (constrD) {
                const propDOpp = ntabuilding.properties['CONSTRD_OPP'];
                const newValueDOpp = ntaRounding.roundAndAddZerosNewValue(propDOpp, calcFormulaF1());
                saveValue(propDOpp, constrD, newValueDOpp);

                const propdataDOpp = propDOpp.getData(constrD);
                return propdataDOpp.Value;
            }
            return null;
        } //-- end: executeConditionG ------------------------------------------------------------------------//

        function checkConditionVKB(entdata) {
            //-- conditie [VKB] toon in volgende situatie: 
            //-- 1. als gt3.2 EN Z08 = 'voorziening niet gemeten volgens NEN-EN 13030'. 
            //-- 2. versie le3.2 
            return ntabuilding.ntaVersionId < 300 || entdata.PropertyDatas['CONSTRZOMNAC_INV'].Value === "CONSTRZOMNAC_INV_N";
        } //-- end: checkConditionVKB ------------------------------------------------------------------------//

        function isConstrVloer() {
            //-- Conditie [H] if table8 key3=vloer kunnen er geen rijen toegevoegd worden (altijd 1 rij) en drag, add, coppy and delete knoppen zijn verborgen.
            let result = false;
            if (_vlak) {
                if (_vlakType === 'VLAK_KELDERW' || _vlakType === "VLAK_VLOER") {
                    if (_vlakType === 'VLAK_VLOER') {
                        if (_vlakBegr !== "VL_BTNL_ONDER" && _vlakBegr !== "VL_WATER" && _vlakBegr !== "VL_AOR_FOR" && _vlakBegr !== "VL_AOS_FOR") { //
                            result = true;
                        }
                    } else {
                        result = true;
                    }
                }
            }
            return result;
        } //-- end: isConstrVloer ------------------------------------------------------------------------//

        function createConstr(entdata, type = null) {
            let propdatas = [];
            let entityType = entdata ? entdata.EntityId : type;
            if (entityType === 'CONSTRWG') {
                propdatas = [{ "PropertyId": "CONSTRWG_OPP", "Value": _vlakA }];
            }
            if (entityType === 'CONSTRT') {
                const libForm = ntaEntityData.getFirstWithEntityId('LIBCONSTRFORM');
                if (libForm) {
                    const kozInvoer = libForm.PropertyDatas['LIBCONSTRFORM_KOZ'].Value;
                    if (kozInvoer === 'KOZKENM_OPP') {
                        const propOpp = ntabuilding.properties['CONSTRT_OPP'];
                        const newValue = ntaRounding.roundAndAddZerosNewValue(propOpp, 0);
                        propdatas = [{ "PropertyId": "CONSTRT_OPP", "Value": newValue }];
                    }
                }
            }

            const newId = ntaEntityData.create(entityType, entdata ? entdata.Order : -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": _vlakId, "ParentEntityId": "BEGR" }], [], propdatas);
            if (entityType === "CONSTRT") {
                //-- elke CONSTRT heeft een BELEMMERING en een CONSTRZOMNAC (zomernachtventilatie) entity, relatie is met cascade delete en copy
                ntaEntityData.create('BELEMMERING', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newId, "ParentEntityId": "CONSTRT" }], [], []);
                ntaEntityData.create('CONSTRZOMNAC', -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": newId, "ParentEntityId": "CONSTRT" }], [], [{ "PropertyId": "CONSTRZOMNAC_DOORLF", "Value": ntabuilding.ntaVersionId < 300 ? '0,5' : '0,3' }]);
            }

            if (entityType === "CONSTRL") {
                //-- VO 2021-01-21: check of de aangemaakt CONSTRL relevant moet zijn of niet ivm thermische bruggen forfaitair. Dit gebeurd in de checkVisibilityKoudebruggen
                //-- in principe kom je hier niet vanuit het constructie formulier, maar je komt hier wel vanuit de dependencie validator als er een begrenzing in buildingbegrenzing-factory is gewijzigd.
                checkVisibilityKoudebruggen();
            }
        } //-- end: createConstr ------------------------------------------------------------------------//

        function checkConditionY(entdata = null) {
            //-- Conditie[Y] toon kolom als in veld C15 table7-5-6 key=vast
            const constructiesT = entdata && [entdata] || constrTs();
            return constructiesT.every(constrT => constrT.PropertyDatas['CONSTRT_ZONW'].Value !== 'ZONW_VAST');
        } //-- end: checkConditionY ------------------------------------------------------------------------//

        function checkConditionL(entdata = null) {
            //-- Conditie [L] hide dropdown if in field C15 the selection from table7-5-6 has key=geen or vast of als C15 verborgen
            const constructiesT = entdata && [entdata] || constrTs(); //-- isHidden geeft voor header niets mee; als een van de constrTs een waarde ZONW_VAST heeft is de prop zichtbaar
            return constructiesT.every(constrT => ['ZONW_VAST', 'ZONW_GEEN'].includes(constrT.PropertyDatas['CONSTRT_ZONW'].Value) || !constrT.PropertyDatas['CONSTRT_ZONW'].Visible);
        } //-- end: checkConditionL ------------------------------------------------------------------------//

        function checkConditionG() {
            if (!ntabuilding.canSave()) return;
            //-- Conditie [G] afhankelijk van het aantal regels in tabel C02 gelden de volgende voorwaarden:
            let newVal = 0;
            let validE012 = true;
            let validE067 = true;
            let validE068 = true;
            const propdataC01 = _constrForm && _constrForm.PropertyDatas['CONSTRERROR_OPEN'];

            const lengthD = constrDs().length;

            if (lengthD === 1) {
                newVal = ntaSharedLogic.parseFloat(executeConditionG(), 0);
                validE067 = newVal >= 0;
            } else {
                //-- bij geen regel of > 1 regel de volgende controle op dit formulier uitvoeren: als Atot (veld B07) ≠ Atotaal ingevoerde oppervlakten (formula F3) fout [E012] weergeven
                let resF3 = calcFormulaF3();
                newVal = resF3 - ntaSharedLogic.parseFloat(_vlakA, 0);

                if (ntaSharedLogic.parseFloat(_vlakA, 0) < resF3) {
                    validE012 = false;
                }
                if (ntaSharedLogic.parseFloat(_vlakA, 0) > resF3) {
                    validE068 = false;
                }
                ntaSharedLogic.updateErrorReplaceObject(propdataC01, "som", newVal < 0 ? newVal.toFixed(2).substring(1) : newVal.toFixed(2));
            }

            if (lengthD > 0) {
                let propdataC04 = constrDs()[0].PropertyDatas['CONSTRD_OPP'];
                ntaSharedLogic.updateErrorReplaceObject(propdataC04, "som", newVal < 0 ? Math.abs(ntaSharedLogic.parseFloat(propdataC04.Value)).toString() : propdataC04.Value);
                ntaSharedLogic.setMelding('[E067]', propdataC04, conLogic.form_bldconstr, validE067);
            }


            ntaSharedLogic.setMelding('[E012]', propdataC01, conLogic.form_bldconstr, validE012);
            ntaSharedLogic.setMelding('[E068]', propdataC01, conLogic.form_bldconstr, validE068);

            return validE012 && validE067 && validE068;
        } //-- end: checkConditionG ------------------------------------------------------------------------//

        function setOppervlakteTransparanteConstructie(entdata) {
            let propT_Opp = ntabuilding.properties['CONSTRT_OPP'];
            let propValueT_Opp = propT_Opp.getValue(entdata);
            let aValT_Opp = ntaSharedLogic.parseFloat(propValueT_Opp, 0);
            let aVal = calcFormulaF2(entdata);
            if (aValT_Opp.toFixed(2) !== aVal.toFixed(2)) { //--floats vergelijken
                if (ntabuilding.canSave()) {
                    saveValue(propT_Opp, entdata, aVal);
                }
            }
        } //-- end: setOppervlakteTransparanteConstructie ------------------------------------------------------------------------//

        function setAllOppervlakteTransparanteConstructies() {
            for (const constrT of constrTs()) {
                setOppervlakteTransparanteConstructie(constrT);
            }
        } //-- end: setAllOppervlakteTransparanteConstructies ------------------------------------------------------------------------//

        function isKozijnOppPerMerk() {
            //-- Omschrijving C13
            let libkozmerk = ntaEntityData.getFirstWithEntityId('LIBCONSTRFORM');
            if (libkozmerk) {
                let koz = libkozmerk.PropertyDatas["LIBCONSTRFORM_KOZ"];
                if (koz.Value === "KOZKENM_OPP") {
                    return true;
                }
            }
            return false;
        } //-- end: isKozijnOppPerMerk ------------------------------------------------------------------------//

        function calcFormulaF1() {
            if (_vlakA) {
                let somAConstrTs = 0;
                //-- alleen als je gevel of dak bent of vloer grenzend ongelijk aan grond, dan dit doorlopen. -> relaties blijven behouden als je switch van vlaktype, dus de vloer kan relaties hebben met ConstrT's.
                //if (_vlakType === "VLAK_GEVEL" || _vlakType === "VLAK_DAK") {
                for (const constrT of constrTs()) {
                    if (constrT.Relevant) {
                        const opp = constrT.PropertyDatas['CONSTRT_OPP'].Value;
                        somAConstrTs += ntaSharedLogic.parseFloat(opp, 0);
                    }
                }
                //}
                return ntaSharedLogic.parseFloat(_vlakA, 0) - somAConstrTs;
            }
            return 0;
        } //-- end: calcFormulaF1 ------------------------------------------------------------------------//

        function calcFormulaF2(entdata = null) {
            if (!entdata) {
                return 0;
            }

            const constrTLib = ntaEntityData.getFirstParent(entdata, "LIBCONSTRT");
            if (constrTLib) {
                const constrTLibA = constrTLib.PropertyDatas["LIBCONSTRT_AC"];
                const constrTAant = entdata.PropertyDatas["CONSTRT_AANT"];
                if (constrTLibA && constrTLibA.Value && constrTAant && constrTAant.Value) {
                    let libAVal = ntaSharedLogic.parseFloat(constrTLibA.Value, 0);
                    let aantVal = ntaSharedLogic.parseFloat(constrTAant.Value, 0);
                    return libAVal * aantVal;
                }
            }
            return 0;
        } //-- end: calcFormulaF2 ------------------------------------------------------------------------//

        function calcFormulaF3() {
            let somAConstrDs = 0;
            for (const constrD of constrDs()) {
                const opp = constrD.PropertyDatas['CONSTRD_OPP'].Value;
                somAConstrDs += ntaSharedLogic.parseFloat(opp, 0);
            }

            let somAConstrTs = 0;
            for (const constrT of constrTs()) {
                const opp = constrT.PropertyDatas['CONSTRT_OPP'].Value;
                somAConstrTs += ntaSharedLogic.parseFloat(opp, 0);
            }

            let resultaat = somAConstrDs + somAConstrTs;
            return ntaSharedLogic.parseFloat(resultaat.toFixed(2), 0);
        } //-- end: calcFormulaF3 ------------------------------------------------------------------------//

        function validLineaireConstructies() {
            let aantal = 0;
            const koudebruggenVisible = settingsMenuData.getSetting('SETTINGS_THBRUG').Value === "False";
            if (koudebruggenVisible) {
                //-- alleen nu een check doen anders
                aantal = constrLs().length;
            }

            return aantal;
        } //-- end: validLineaireConstructies ------------------------------------------------------------------------//

        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            switch (prop.Id) {
                case 'CONSTRD_L':
                case 'CONSTRD_B':
                case 'CONSTRT_L':
                case 'CONSTRT_B': {
                    let propIdL, propIdB, propIdA;
                    switch (entdata.EntityId) {
                        case 'CONSTRD':
                            propIdL = 'CONSTRD_L';
                            propIdB = 'CONSTRD_B';
                            propIdA = 'CONSTRD_OPP';
                            break;
                        case 'CONSTRT':
                            propIdL = 'CONSTRT_L';
                            propIdB = 'CONSTRT_B';
                            propIdA = 'CONSTRT_OPP';
                            break;
                        default:
                    }

                    if (propIdL && propIdB && propIdA) {
                        const propLengte = ntabuilding.properties[propIdL];
                        const propBreedte = ntabuilding.properties[propIdB];
                        const lengte = ntaSharedLogic.parseFloat(propLengte.getValue(entdata));
                        const breedte = ntaSharedLogic.parseFloat(propBreedte.getValue(entdata));
                        if (!isNaN(lengte) && !isNaN(breedte)) {
                            //-- Bereken Oppervlakte
                            const propConstrA = ntabuilding.properties[propIdA];
                            const newAvalue = ntaRounding.roundAndAddZerosNewValue(propConstrA, lengte * breedte);
                            saveValue(propConstrA, entdata, newAvalue);
                        }
                    }
                    break;
                }
                case 'CONSTRT_OPP':
                case 'CONSTRD_OPP': {
                    //-- conditie [A20] maak L en B leeg als A niet gelijk is aan L * B
                    let propIdL, propIdB, propIdA;
                    switch (entdata.EntityId) {
                        case 'CONSTRD':
                            propIdL = 'CONSTRD_L';
                            propIdB = 'CONSTRD_B';
                            propIdA = 'CONSTRD_OPP';
                            break;
                        case 'CONSTRT':
                            propIdL = 'CONSTRT_L';
                            propIdB = 'CONSTRT_B';
                            propIdA = 'CONSTRT_OPP';
                            break;
                        default:
                    }

                    if (propIdL && propIdB && propIdA) {
                        const propLengte = ntabuilding.properties[propIdL];
                        const propBreedte = ntabuilding.properties[propIdB];
                        const lengte = ntaSharedLogic.parseFloat(propLengte.getValue(entdata));
                        const breedte = ntaSharedLogic.parseFloat(propBreedte.getValue(entdata));
                        const propValueConstrA = entdata.PropertyDatas[propIdA].Value;
                        const oppervlakLxB = ntaSharedLogic.parseFloat(ntaRounding.roundAndAddZerosNewValue(prop, lengte * breedte));
                        if (oppervlakLxB !== ntaSharedLogic.parseFloat(propValueConstrA)) {
                            const emptyValue = '';
                            saveValue(propLengte, entdata, emptyValue);
                            saveValue(propBreedte, entdata, emptyValue);
                        }
                    }

                    if (entdata.EntityId === "CONSTRT") {
                        checkConditionG();
                    }

                    break;
                }
                case 'CONSTRD_LIB':
                case 'CONSTRWG_LIB':
                case 'KENMKR_WW_GVL':
                case 'KENMKR_WW_KR': {
                    const hidden = isHidden(prop, entdata);
                    const propdata = prop.getData(entdata);
                    if (!hidden) {
                        saveKoppelingConstrEnLibrary(propdata, entdata, 'LIBCONSTRD');
                    } else {
                        deleteKoppelingConstrEnLibrary(propdata, entdata, 'LIBCONSTRD');
                    }

                    break;
                }
                case 'CONSTRT_LIB': {
                    const hidden = isHidden(prop, entdata);
                    if (!hidden) {
                        const propdata = prop.getData(entdata);
                        saveKoppelingConstrEnLibrary(propdata, entdata, 'LIBCONSTRT');
                    }
                    if (_isKozijnOppPerMerk) { // Omschrijving C13
                        setOppervlakteTransparanteConstructie(entdata);
                    }

                    const libConstructieTransparant = ntaEntityData.getFirstParent(entdata, 'LIBCONSTRT');
                    if (libConstructieTransparant) {
                        const propGgl = ntabuilding.properties['LIBCONSTRT_G'];
                        const propBeschaduwing = ntabuilding.properties['CONSTRT_BESCH'];
                        const propZonwering = ntabuilding.properties['CONSTRT_ZONW'];
                        const gGlValue = propGgl.getValue(libConstructieTransparant);
                        if (ntaSharedLogic.parseFloat(gGlValue, 0) === 0) {
                            saveValue(propBeschaduwing, entdata, 'n.v.t.');
                            saveValue(propZonwering, entdata, 'ZONW_GEEN');
                        } else {
                            //-- Reset als niet langer g = 0. alleen voor beschaduwing, zonwering mag op geen blijven staan
                            let propValueBeschaduwing = propBeschaduwing.getValue(entdata);
                            if (propValueBeschaduwing === "n.v.t.") {
                                saveValue(propBeschaduwing, entdata, propBeschaduwing.DefaultValue);
                            }
                        }
                    }
                    break;
                }
                case 'CONSTRL_LIB': {
                    const hidden = isHidden(prop, entdata);
                    if (!hidden) {
                        const propdata = prop.getData(entdata);
                        saveKoppelingConstrEnLibrary(propdata, entdata, 'LIBCONSTRL');
                    }
                    break;
                }
                case 'CONSTRP_LIB': {
                    const hidden = isHidden(prop, entdata);
                    if (!hidden) {
                        const propdata = prop.getData(entdata);
                        saveKoppelingConstrEnLibrary(propdata, entdata, 'LIBTHBRUG');
                    }
                    break;
                }
                case 'CONSTRZOMNAC_DOORLF':
                case 'CONSTRZOMNAC_BRDOORLV': {
                    const propVolume = ntabuilding.properties['CONSTRZOMNAC_BRDOORLV'];
                    const propFactor = ntabuilding.properties['CONSTRZOMNAC_DOORLF'];
                    const propVoorziening = ntabuilding.properties['CONSTRZOMNAC_DOORLV'];
                    const propValueVolume = propVolume.getValue(entdata);
                    const propValueFactor = propFactor.getValue(entdata);
                    const newValue = (ntaSharedLogic.parseFloat(propValueVolume, 0) * ntaSharedLogic.parseFloat(propValueFactor, 0)).toString();
                    saveValue(propVoorziening, entdata, newValue);

                    break;
                }
                case 'CONSTRT_AANT': {
                    if (_isKozijnOppPerMerk) { // Omschrijving C13
                        setOppervlakteTransparanteConstructie(entdata);
                    }
                    break;
                }
                case 'CONSTRT_ZONW': {
                    const propGglAlt = ntabuilding.properties['CONSTRT_GGL_ALT'];
                    const propGglDif = ntabuilding.properties['CONSTRT_GGL_DIF'];
                    const propRegeling = ntabuilding.properties['CONSTRT_REGEL'];

                    const gglColumnsVisible = !checkConditionY();
                    const regelingColumnVisible = !checkConditionL();

                    if (gglColumnsVisible || regelingColumnVisible) {
                        //-- Deze controle voeren we uit voor alle rijen, omdat waardes niet gecorrigeerd worden als een kolom verborgen is.
                        for (const constrT of constrTs()) {
                            //-- Als de ggl-kolommen zichtbaar zijn, controleer dan de ggl-waardes
                            if (gglColumnsVisible) {
                                let gglAlt = propGglAlt.getValue(constrT);
                                let gglDif = propGglDif.getValue(constrT);
                                const gglValuesRelevant = !checkConditionY(constrT);
                                if (!gglValuesRelevant) {
                                    gglAlt = 'n.v.t.';
                                    gglDif = 'n.v.t.';
                                } else {
                                    if (gglAlt === 'n.v.t.') {
                                        gglAlt = propGglAlt.DefaultValue || '';
                                    }
                                    if (gglDif === 'n.v.t.') {
                                        gglDif = propGglDif.DefaultValue || '';
                                    }
                                }
                                saveValue(propGglAlt, constrT, gglAlt);
                                saveValue(propGglDif, constrT, gglDif);
                            }

                            //-- Als de regeling-kolom zichtbaar is, controleer dan de waarde
                            if (regelingColumnVisible) {
                                let regeling = propRegeling.getValue(constrT);
                                const regelingRelevant = !checkConditionL(constrT);
                                if (regelingRelevant && !regeling) {
                                    if (ntaSharedLogic.isUtiliteit()) {
                                        regeling = 'ZONWREG_H_ZLW'; // handbediend zonder lichtwering
                                    } else {
                                        regeling = 'ZONWREG_H'; // handbediend
                                    }
                                    saveValue(propRegeling, constrT, regeling);
                                }
                            }
                        }
                    }
                    break;
                }
            }
        } //-- end: validateDependencies ------------------------------------------------------------------------//

        function startFormValidation() {
            ntaSharedLogic.startFormValidation(getAllEntDatas(), conLogic);
        } //-- end: startFormValidation ------------------------------------------------------------------------//

        function endFormValidation() {
            ntaSharedLogic.endFormValidation(getAllEntDatas(), conLogic);
        } //-- end: endFormValidation ------------------------------------------------------------------------//

        function getAllEntDatas() { // Geeft alle entdatas in één array terug
            return []
                .concat(constrDs())
                .concat(constrTs())
                .concat(constrLs())
                .concat(constrPs())
                .concat(constrWGs())
                .concat(_constrKenmW)
                .concat(_constrKenmV)
                .concat(_constrKenmKRVENT)
                .concat(_constrKenmWWGVL)
                .concat(_constrKenmWWKLDR)
                .concat(constrKenmZOMNAC())
                .concat(constrKenmBELEMM())
                .filter(entdata => entdata) // filter alle nulls en undefineds eruit
                ;
        } //-- end: getAllEntDatas ------------------------------------------------------------------------//



    }

    return ConstructiesLogic;
}]);
