﻿angular.module('projectModule')
    .service('ntaDiagram',
        ['$rootScope', '$log', '$http', 'ntabuilding', 'ntaEntityDataOrg', 'ntaData', 'ntaSharedLogic', 'ntaRounding', 'ntaMeldingen',
function ($rootScope,   $log,   $http,   ntabuilding,   ntaEntityDataOrg,   ntaData,   ntaSharedLogic,   ntaRounding,   ntaMeldingen) {
    'use strict';
    const self = this;

    ntabuilding.ntaDiagram = self; //-- ntaDiagram aan ntabuilding geven, omdat deze nodig is voor het sluiten van de DiagramWindow als er naar het projectenoverzicht wordt gegaan.

    /// == Imports ================================================================================

    /// == Instance variables =====================================================================
    let _diagramWindow;
    let _climateData = [];
    let _verbruikData = [];
    let _entdataEnergierekening = ntaEntityDataOrg.getFirstWithEntityId('ENER-REK');
    let _entdataClimate = ntaEntityDataOrg.getFirstWithEntityId('CLIMATE');
    let _entdataVerbruikOverig = ntaEntityDataOrg.getFirstWithEntityId('VERBR-OVERIG');

    /// == Exports ================================================================================

    Object.assign(self, {
        // methods
        canShowDiagramWindow,
        showDiagramWindow,
        closeWindow,
        updateAllDataDiagramWindow,
        updateVerbruikDiagramWindow,
    });

    /// == Initialization =========================================================================

    $rootScope.$on('updateResults', function (event, results) {
        if (!results.VariantId) {
            updateAllDataDiagramWindow({ refreshVerbruik: true, refreshClimate: false });
        }
    });


    /// == Implementation =========================================================================
    window.addEventListener("beforeunload", event => {
        closeWindow(); //evt. openstaand diagramWindow sluiten.
    });

    function createMessage() {
        return { buildingId: ntabuilding.buildingId, action: '', data: [] };
    } //-- end: createMessage ---------------------------------------------------------------------

    function canShowDiagramWindow() {
        // gebruik [ER-L] om te bepalen of de knop ‘Grafiek fitten’ weergegeven moet worden.
        const propDoelEnergierekening = ntaData.properties['ENER-REK_DOEL'];
        return !!propDoelEnergierekening && propDoelEnergierekening.Domain.Codes.some(cv => cv.Id === "ENER-REK_DOEL1");
    } //-- end: canShowDiagramWindow --------------------------------------------------------------

    async function showDiagramWindow() {
        //conditie [MW-AN] als op de knop gedrukt wordt EN
        // - bij ER02 is gekozen voor alleen terugverdientijd berekenen geef[W123]
        // - als berekening Calcneeded = true dan geef[W122]
        if (_entdataEnergierekening && _entdataEnergierekening.PropertyDatas['ENER-REK_DOEL'].Value === 'ENER-REK_DOEL3') {
            await ntaMeldingen.warning('[W123]');
            return;
        }
        if (ntaEntityDataOrg.getCalculationNeeded(ntabuilding.buildingId)) {
            await ntaMeldingen.warning('[W122]');
            return;
        }

        //opnieuw ophalen -> er kan een andere berekening zijn geopend.
        _entdataEnergierekening = ntaEntityDataOrg.getFirstWithEntityId('ENER-REK');
        _entdataClimate = ntaEntityDataOrg.getFirstWithEntityId('CLIMATE');
        _entdataVerbruikOverig = ntaEntityDataOrg.getFirstWithEntityId('VERBR-OVERIG');

        if (!_diagramWindow) {
            window.addEventListener('message', onDiagramMessage);
            window.onunload = closeWindow();
        }
        if (!_diagramWindow || _diagramWindow.closed) {
            const projectId = ntaData.projectdata.ProjectId;
            const buildingId = ntabuilding.buildingId;
            const url = `/Project/${projectId}/Berekening/${buildingId}/Grafiek_Fitten`;

            const width = window.outerWidth / 1.25;
            const left = window.screenLeft + width;
            const height = window.outerHeight / 1.75;
            const top = window.screenTop + height;

            _diagramWindow = window.open(url, '_blank', `popup,left=${left},width=${width},top=${top},height=${height}`);
            // als het goed is, stuurt dat venster een ‘requestData’ om de gegevens op te halen
        }
        _diagramWindow.focus();
    } //-- end: showDiagramWindow -----------------------------------------------------------------

    function closeWindow() {
        if (_diagramWindow) {
            const message = createMessage();
            message.action = 'closeWindow';
            _diagramWindow.postMessage(message, window.origin);
        }
    } //-- end: closeWindow -----------------------------------------------------------------------

    async function updateAllDataDiagramWindow(refresh) {
        if (_diagramWindow && !_diagramWindow.closed) {
            if (refresh.refreshVerbruik) {
                getVerbruikData();
            }
            if (refresh.refreshClimate) {
                await getClimateData();
            }

            const message = createMessage();
            message.action = 'allData';
            message.data = {
                projectName: ntaData.projectdata.Name,
                buildingName: ntaEntityDataOrg.getFirstWithEntityId('GEB').PropertyDatas['GEB_OMSCHR'].Value,
                verbruikdata: _verbruikData,
                klimaatdata: _climateData
            };
            _diagramWindow.postMessage(message, window.origin);
        }
    } //-- end: updateAllDataDiagramWindow --------------------------------------------------------

    function updateVerbruikDiagramWindow(prop, verbruik) {
        if (_diagramWindow && !_diagramWindow.closed) {
            const message = createMessage();
            message.action = 'updateData';
            let carrier = (prop.Id === 'VERBR_E_TLEV' || prop.Id === 'VERBR_E_OPWEK') ? 'E_LEV' : prop.Id.split('VERBR_').pop();
            message.data = createCarrierVerbruikData(verbruik, carrier);

            _diagramWindow.postMessage(message, window.origin);
        }
    } //-- end: updateVerbruikDiagramWindow -----------------------------------------------------------------------

    function onDiagramMessage(event) {
        if (!_diagramWindow) {
            $log.warn('SECURITY: received message while diagram window is disconnected!', event);
            return;
        }
        if (event.origin !== window.origin || event.data.buildingId !== ntabuilding.buildingId) {
            $log.warn('SECURITY: message from unexpected origin', event.origin, '; expected origin:', window.origin);
            return;
        }
        switch (event.data.action) {
            case 'requestData':
                updateAllDataDiagramWindow({ refreshVerbruik: true, refreshClimate: true });
                break;

            case 'choiceUpdated':
                const row = event.data.data;
                const props = new Map([['verbrNietRepres', 'VERBR_NIET_REPRES'], ['verbrInStookseizoen', 'VERBR_IN_STOOKSEIZOEN'], ['verbrBtnStookseizoen', 'VERBR_BTN_STOOKSEIZOEN']]);
                props.forEach(function (value, key) {
                    const propId = value + '_' + row['typeCarrier'];
                    const verbruik = ntaData.original.get(row['verbrId']);
                    const newValue = row[key];
                    saveValue(propId, verbruik, newValue)
                });
                break;
        }
    } //-- end: onDiagramMessage ------------------------------------------------------------------

    function getVerbruikData() {
        // VERBR_ID | TYPE_CARRIER | VERBR_VERBR_MJ (maand) | VERBR | VERBR_FITTED (resultaat FG03) | VERBR_NIET_REPRES | VERBR_IN_STOOKSEIZOEN | VERBR_BTN_STOOKSEIZOEN
        // verbrId  | typeCarrier  | VerbrMJ (maand)        | verbr | verbrFitted (resultaat FG03)  | verbrNietRepres   | verbrInStookseizoen   | verbrBtnStookseizoen
        _verbruikData = [];
        const isFittenPerJaar = _entdataEnergierekening && _entdataEnergierekening.PropertyDatas['ENER-REK_DOEL'].Value === 'ENER-REK_DOEL2';
        const verbruiken = isFittenPerJaar ?
            ntaEntityDataOrg.getListWithEntityId('VERBR').filter(x => x.PropertyDatas['VERBR_VERBR_MJ'].Value === 'VERBR_MJ_JR') :
            ntaEntityDataOrg.getListWithEntityId('VERBR').filter(x => x.PropertyDatas['VERBR_VERBR_MJ'].Value !== 'VERBR_MJ_JR');
        const carriers = ["E_LEV", ...[...ntaSharedLogic.getDragerAanwezigMap()].filter(([k, v]) => v.verbruiken).map(([key]) => key)];
        for (const verbruik of verbruiken) {
            for (const carrier of carriers) {
                if (isChecked(carrier) && carrier !== 'E') { //check of carrier is aangevinkt en mee mag
                    _verbruikData.push(createCarrierVerbruikData(verbruik, carrier));
                }
            }
        }
    } //-- end: getVerbruikData ------------------------------------------------------------------

    function createCarrierVerbruikData(verbruik, carrier) {
        //-> Guid, GAS, januari, 64, 67, true, false, false
        const propMJ = ntaData.properties['VERBR_VERBR_MJ'];
        const month = propMJ.getCode(verbruik).Order;
        const isFittenPerMaand = _entdataEnergierekening && _entdataEnergierekening.PropertyDatas['ENER-REK_DOEL'].Value === 'ENER-REK_DOEL1';

        //default value stookseizoen
        if (!verbruik.PropertyDatas['VERBR_IN_STOOKSEIZOEN_' + carrier].Value && !verbruik.PropertyDatas['VERBR_BTN_STOOKSEIZOEN_' + carrier].Value) {
            const propWarmteBehoefte = ntaData.properties['VERBR_WARMTE_NON'];
            if (ntaSharedLogic.parseFloat(propWarmteBehoefte.getValue(verbruik), 0) > 0) {
                //trendlijn winter (VERBR_IN_STOOKSEIZOEN) zet maanden v.d. gefitte berekening aan waarvoor geldt: ∑siQhnodin;mi (f9.5) > 0
                saveValue('VERBR_IN_STOOKSEIZOEN_' + carrier, verbruik, "1");
                saveValue('VERBR_BTN_STOOKSEIZOEN_' + carrier, verbruik, "0");
            } else {
                //trendlijn zomer (VERBR_BTN_STOOKSEIZOEN) zet maanden v.d. gefitte berekening aan waarvoor geldt: ∑siQhnodin;mi (f9.5) = 0
                saveValue('VERBR_IN_STOOKSEIZOEN_' + carrier, verbruik, "0");
                saveValue('VERBR_BTN_STOOKSEIZOEN_' + carrier, verbruik, "1");
            }
        }

        const carrierVerbruikData = {
            'verbrId': verbruik.EntityDataId,
            'typeCarrier': carrier,
            'verbrMJ': ntaSharedLogic.getVerbruikMonthAndYear(verbruik, _entdataEnergierekening).Value,
            'verbrMaand': month,
            'verbr': verbruik.PropertyDatas['VERBR_' + carrier].Value,
            'verbrFitted': verbruik.PropertyDatas['VERBR_FITTED_' + carrier].Value,
            'afw': 0,
            'verbrNietRepres': verbruik.PropertyDatas['VERBR_NIET_REPRES_' + carrier].Value,
            'verbrInStookseizoen': verbruik.PropertyDatas['VERBR_IN_STOOKSEIZOEN_' + carrier].Value,
            'verbrBtnStookseizoen': verbruik.PropertyDatas['VERBR_BTN_STOOKSEIZOEN_' + carrier].Value
        };
        switch (carrier) {
            case 'E_LEV': {
                const propVerbr = ntaData.properties['VERBR_E_LEV'];
                const propdataTLEV = verbruik.PropertyDatas['VERBR_E_TLEV'];
                const propdataOPW = verbruik.PropertyDatas['VERBR_E_OPWEK'];
                const propdataOpgewekt = verbruik.PropertyDatas['VERBR_E_OPGEWEKT_NON'];
                if (propdataOPW.Relevant) {
                    //f.fg1a: e-verbruik = e-levering + e-opwekking (omvormer) - e-teruglevering 
                    carrierVerbruikData.verbr = ntaRounding.roundAndAddZerosNewValue(propVerbr, ntaSharedLogic.parseFloat(carrierVerbruikData.verbr, 0) + ntaSharedLogic.parseFloat(propdataOPW.Value, 0) - ntaSharedLogic.parseFloat(propdataTLEV.Value, 0));
                } else if (propdataTLEV.Relevant){ 
                    //f.fg1b: e-verbruik = e-levering + VERBR_E_OPGEWEKT_NON (SumEprelgi (uit rekenhart)) - e-teruglevering
                    carrierVerbruikData.verbr = ntaRounding.roundAndAddZerosNewValue(propVerbr, ntaSharedLogic.parseFloat(carrierVerbruikData.verbr, 0) + ntaSharedLogic.parseFloat(propdataOpgewekt.Value, 0) - ntaSharedLogic.parseFloat(propdataTLEV.Value, 0));
                }
                break;
            }
            case 'GAS': {
                if (_entdataVerbruikOverig) {
                    const propGas = ntaData.properties['VERBR_FITTED_GAS'];
                    const propDataKokenOpGas = _entdataVerbruikOverig.PropertyDatas['VERBR-OVERIG_KOKEN_GAS'];
                    const kokenOpGas = propDataKokenOpGas.Relevant && propDataKokenOpGas.Value === 'VERBR-OVERIG_KOKEN_GAS_Y' ? 37 : 0;
                    carrierVerbruikData.verbrFitted = ntaRounding.roundAndAddZerosNewValue(propGas, (ntaSharedLogic.parseFloat(carrierVerbruikData.verbrFitted, 0)  / 35.17 * 3.6) + (kokenOpGas / 12));
                }
                break;
            }
            case 'BIOM': {
                const propBiom = ntaData.properties['VERBR_FITTED_BIOM'];
                carrierVerbruikData.verbrFitted = ntaRounding.roundAndAddZerosNewValue(propBiom, ntaSharedLogic.parseFloat(carrierVerbruikData.verbrFitted, 0));
                break;
            }
            case 'OLIE': {
                const propOlie = ntaData.properties['VERBR_FITTED_OLIE'];
                carrierVerbruikData.verbrFitted = ntaRounding.roundAndAddZerosNewValue(propOlie, ntaSharedLogic.parseFloat(carrierVerbruikData.verbrFitted, 0) * 0.1);
                break;
            }
            case 'W': {
                const propW = ntaData.properties['VERBR_FITTED_W'];
                if (isFittenPerMaand) {
                     //van GJ naar MJ
                    carrierVerbruikData.verbr = ntaRounding.roundAndAddZerosNewValue(null, ntaSharedLogic.parseFloat(carrierVerbruikData.verbr, 0) * 1000, 'R014');
                    carrierVerbruikData.verbrFitted = ntaRounding.roundAndAddZerosNewValue(propW, ntaSharedLogic.parseFloat(carrierVerbruikData.verbrFitted, 0) * 3.6);
                } else {
                    carrierVerbruikData.verbrFitted = ntaRounding.roundAndAddZerosNewValue(propW, ntaSharedLogic.parseFloat(carrierVerbruikData.verbrFitted, 0) * 3.6 / 1000);
                }
                break;
            }
            case 'K': {
                const propK = ntaData.properties['VERBR_FITTED_K'];
                if (isFittenPerMaand) {
                    //van GJ naar MJ
                    carrierVerbruikData.verbr = ntaRounding.roundAndAddZerosNewValue(null, ntaSharedLogic.parseFloat(carrierVerbruikData.verbr, 0) * 1000, 'R014');
                    carrierVerbruikData.verbrFitted = ntaRounding.roundAndAddZerosNewValue(propK, ntaSharedLogic.parseFloat(carrierVerbruikData.verbrFitted, 0) * 3.6);
                } else {
                    carrierVerbruikData.verbrFitted = ntaRounding.roundAndAddZerosNewValue(propK, ntaSharedLogic.parseFloat(carrierVerbruikData.verbrFitted, 0) * 3.6 / 1000);
                }
                break;
            }
            default:
        }

        // Bij fittten per maand: deel bij alle formules de uitkomst door het aantal dagen van de maand (februari altijd 28)
        if (isFittenPerMaand) {
            const daysInMonth = moment("2023-" + month.toString(), "YYYY-MM").daysInMonth(); // In 2023 heeft februari 28 dagen. Bij isFittenPerJaar delen door 1.
            carrierVerbruikData.verbr = ntaRounding.roundAndAddZerosNewValue(null, ntaSharedLogic.parseFloat(carrierVerbruikData.verbr, 0) / daysInMonth, 'R014');
            carrierVerbruikData.verbrFitted = ntaRounding.roundAndAddZerosNewValue(null, ntaSharedLogic.parseFloat(carrierVerbruikData.verbrFitted, 0) / daysInMonth, 'R014');
        }

        //afwijking berekenen
        const afwijking = ntaRounding.roundAndAddZerosNewValue(null, ((ntaSharedLogic.parseFloat(carrierVerbruikData.verbrFitted, 0) - ntaSharedLogic.parseFloat(carrierVerbruikData.verbr, 0)) / ntaSharedLogic.parseFloat(carrierVerbruikData.verbr, 0)) * 100, 'R016');
        carrierVerbruikData.afw = Number.isFinite(ntaSharedLogic.parseFloat(afwijking)) ? afwijking : "--";

        return carrierVerbruikData;
    } //-- end: createCarrierVerbruikData -------------------------------------------------------------

    async function getClimateData() {
        const propStation = ntaData.properties['CLIMATE_KNMI_STATION']
        const propEersteMaand = ntaData.properties['ENER-REK_EERSTEMAAND'];
        const propHitteEiland = ntaData.properties['CLIMATE_HEAT_ISLAND'];

        const station = propStation.getValue(_entdataClimate);
        const hitteEiland = propHitteEiland.getValue(_entdataClimate);
        const eersteMaand = propEersteMaand.getValue(_entdataEnergierekening);
        const start = moment(eersteMaand);
        const eind = moment(eersteMaand).add(12, 'months');

        let url = `/api/climate?station=${station}&fromYear=${start.year()}&fromMonth=${start.month() + 1}&tillYear=${eind.year()}&tillMonth=${eind.month() + 1}`;
        try {
            // Wacht op het antwoord van de server
            const response = await $http.get(url);
            _climateData = response.data;

            for (const klimaatMaand of _climateData) {
                klimaatMaand.Te = ntaRounding.ntaround(klimaatMaand.Te + ntaSharedLogic.parseFloat(hitteEiland, 0), 'R015');
            }
        } catch (response) {
            console.error(url, response);
        }
    } //-- end: getClimateData -----------------------------------------------------------------

    function saveValue(propOrId, entdata, newValue) {
        const prop = typeof propOrId === 'string' ? ntaData.properties[propOrId] : propOrId;
        return ntaEntityDataOrg.saveprop(prop.getData(entdata), newValue);
    } //-- end: saveValue ---------------------------------------------------------------------

    function isChecked(drager) {
        const prop = ntaData.properties['ENER-REK_VERBRAANW'];
        const valuesSet = new Set(String(prop.getValue(_entdataEnergierekening)).split('|'));
        return valuesSet.has('ENER-REK_VERBRAANW_' + drager);
    } //-- end: isChecked -----------------------------------------------------------------

}]);
