﻿angular.module('projectModule')
    .factory('NtaEntityDataFactory',
        ['$log', 'ntaData', 'ntaLocal', 'ntaStorage', 'ntaAlert', 'EventSource', 'EntityData', 'RelationData', 'ntaDeltas',
function ($log,   ntaData,   ntaLocal,   ntaStorage,   ntaAlert,   EventSource,   EntityData,   RelationData,   ntaDeltas) {
    'use strict';

    if (!Object.hasOwn) {
        // polyfill voor Object.hasOwn, zodat we deze functie ook op oudere browsers kunnen gebruiken.
        // https://caniuse.com/mdn-javascript_builtins_object_hasown
        Object.hasOwn = function (obj, propName) {
            return !!obj
                && typeof obj === 'object'
                && typeof obj.hasOwnProperty === 'function'
                && obj.hasOwnProperty(propName)
                || false;
        };
    }

    return function NtaEntityDataFactory(getData = () => ntaData.original, getBuildingId = null) {
        const vm = this;

        ///////////////////////////////////////////////////////////////////////////////////////////
        // Interface
        ///////////////////////////////////////////////////////////////////////////////////////////

        // Neem (bijna) alle methods van BuildingData over, maar hou er rekening mee dat getData() soms een ander object kan teruggeven.
        for (const [name, method] of Object.entries(getData())) {
            if (!vm[name] && typeof method === 'function' && (name.startsWith('get') || name.startsWith('is') || name.startsWith('has') || name.startsWith('find'))) {
                vm[name] = (...args) => getData()[name](...args);
            }
        }

        vm.distinct = distinct;

        const _orderInterval = 100; // de standaard ruimte tussen de ‘Order’ van twee opeenvolgende entiteiten.

        const orderByBuildingEntityIdAndOrder = ntaData.orderByBuildingEntityIdAndOrder;

        const _eventSource = new EventSource(vm);
        vm.on = _eventSource.on;
        vm.off = _eventSource.off;

        let _clientId;
        ntaLocal.getClientId().then(clientId => _clientId = clientId); // localforage is asynchroon; we kunnen de clientId dus alleen zo opvragen

        vm.getCalculationNeeded = getCalculationNeeded;
        function getCalculationNeeded(buildingId) {
            const geb = vm.getFirstWithEntityId('GEB', buildingId, true);
            const propdataCalcNeeded = geb && geb.PropertyDatas["GEB_CALCNEEDED"];
            return !!propdataCalcNeeded && String(propdataCalcNeeded.Value).toLowerCase() === "true";
        }

        vm.setCalculationNeeded = setCalculationNeeded;
        function setCalculationNeeded(buildingId, newValue) {
            const ntaEntityData = vm.getShadowId() ? new NtaEntityDataFactory() : vm;
            const geb = ntaEntityData.getFirstWithEntityId('GEB', buildingId, true);
            const propdataCalcNeeded = geb && geb.PropertyDatas["GEB_CALCNEEDED"];
            if (propdataCalcNeeded) {
                ntaEntityData.saveprop(propdataCalcNeeded, (!!newValue).toString());
            }
        }

        vm.getShadowId = () => getData().shadowId;

        const updateAction = {
            "addEntityData": '',
            "savePropertyData": '',
            "deletePropertyData": '',
            "addRelationData": '',
            "deleteEntityData": '',
            "deleteRelationData": '',
            "saveReOrder": '',
            "entityDataRelevant": '',
            "calculate": '',
            "entityDataVisible": '',
            "saveDeltas": '',
            "deleteDeltas": '',
            "message": '',
        };
        Object.keys(updateAction).forEach(action => updateAction[action] = action); // zorg dat elke property de propertynaam als waarde heeft.

        vm.saveprop = function (propdata, newValue = undefined) {
            return vm.saveprops([propdata], newValue);
        };

        vm.saveprops = function (propdatas, newValue = undefined) {
            const propdatasToSave = propdatas.filter(propdata => {
                let shouldSave = !!propdata && vm.get(propdata.EntityDataId);
                if (shouldSave && newValue !== undefined) {
                    shouldSave = newValue !== propdata.Value;
                    propdata.Value = newValue;
                }
                return shouldSave;
            });
            const shouldSave = propdatasToSave.length > 0;
            if (shouldSave) {
                const propdatasPerBuildingId = propdatasToSave
                    .reduce((map, propdata) => map.set(propdata.BuildingId, (map.get(propdata.BuildingId) || []).concat(propdata)), new Map());
                for (const [buildingId, propdatas] of propdatasPerBuildingId) {
                    vm.updateData(buildingId, updateAction.savePropertyData, propdatas); //server
                }
            }
            return shouldSave;
        };


        //Add en copy op client doen. Ids vervangen door guids ofzo en meesturen naar server.
        //op server tijdelijke id zoeken en met nieuwe aangemaakte id vervangen -> gebruiker kan tijdelijk id hebben gekozen ergens in app en kan zijn opgeslagen
        //nieuwe id terug naar client
        //op client tijdelijk ids zoeken en met nieuwe vervangen
        //daarna nogmaals naar server voor zoeken en vervangen, omdat tussen het vervangen op de server en de client een gebruiker het tijdelijke id kan hebben gekozen...
        //vervangen id -> zoeken in Entitydata naar EntityDataId, in PropertyDatas naar PropertyDataId en Value, in Relations naar Child en Parent
        vm.create = function (bldId, entityId, order, parentRels, childRels, propdatas) {  //parentRels & childRels -> [{ "OnCopy": 1, "OnDelete": 1, "Parent": newId, "ParentEntityId": "RZ" }]
            if (typeof bldId !== 'number') throw new SyntaxError(`bldId moet een number zijn, geen ${typeof bldId} (${bldId})! Wordt baseNtaEntityData.create aangeroepen in plaats van ntaEntityData.create?`);

            const entity = ntaData.entities[entityId];
            if (!entity) { //onbekende entity
                $log.warn(`ntaData.entities[${entityId}] === ‘${entity}’ [${typeof entity}].`);
                return;
            }

            const buildingData = getData();

            //create new entdata
            let shouldRenumber = false;
            [order, shouldRenumber] = calculateNextOrder(bldId, entityId, order);
            const guid = GetUniqueId(); //uniek nummer

            const newEntdata = new EntityData(entityId, { BuildingId: bldId, Order: order, });
            if (propdatas) { //toevoegen evt al opgegeven propdata
                for (const propdata of propdatas) {
                    const newPropdata = newEntdata.PropertyDatas[propdata.PropertyId];
                    if (newPropdata) {
                        newPropdata.Value = propdata.Value;
                        for (const propName of ['Touched', 'Relevant', 'Visible']) {
                            if (Object.hasOwn(propdata, propName)) {
                                newPropdata[propName] = propdata[propName];
                            }
                        }
                    } else {
                        $log.warn(`Geen nieuwe property met Id ${propdata.PropertyId} gevonden voor ${entityId} ${guid}. (Value zou '${propdata.Value}' zijn geweest).`);
                    }
                }
            }
            //add entdata
            buildingData.addEntdata(newEntdata);

            //add relations
            const newrelations = addRelations(newEntdata, parentRels, childRels);
            for (const reldata of newrelations) {
                buildingData.addReldata(reldata);
            }

            //opslaan op server
            vm.updateData(bldId, updateAction.addEntityData, { "newEntdata": [newEntdata], "newrelations": newrelations }); //server

            if (shouldRenumber) {
                vm.SaveReOrderNummering(entityId, bldId);
            }

            _eventSource.trigger('afterCreate', newEntdata);

            return newEntdata.EntityDataId;
        };

        vm.copy = function (entityDataId) {
            //recursief copyEntdata ivm childs
            // met als resultaat een zak met entdatas en bijbehorende relaties
            //daarna elke entdata uit zak opslaan via saveToServer

            const buildingData = getData();

            const copiedEntdatasByOriginalId = new Map(); // na copyEntData bevat deze alle originele entityDataIds, met de bijbehorende kopie.
            const entityIdsToRenumber = new Set(); // na copyEntData bevat deze alle EntityIds die hernummerd moeten worden na kopiëren
            const data = copyEntdata(entityDataId, null, true, copiedEntdatasByOriginalId, entityIdsToRenumber);

            // voeg nu alle gekopieerde entiteiten en relaties toe aan de BuildingData.
            // Dit doen we niet eerder, zodat we niet per ongeluk kopieën van kopieën maken. [k4qfMiG3]
            for (const entdata of data.newEntdata) {
                buildingData.addEntdata(entdata);
            }
            for (const reldata of data.newrelations) {
                buildingData.addReldata(reldata);
            }

            const bldId = data.newEntdata[0].BuildingId;

            /// nu de relaties nakijken: als de parent van een relatie gekopieerd is, dan moet die relatie verwijzen naar de kopie van de parent.
            /// (dit ging mis bij kopiëren van een [UNIT-]RZ die ergens een kelderwand op vloer onder maaiveld bevatte)
            const relsToRemove = new Set();
            for (const newRel of data.newrelations) {
                const copyParent = copiedEntdatasByOriginalId.get(newRel.Parent);
                const copyParentId = copyParent && copyParent.EntityDataId;
                if (copyParentId) { /// deze parent is ook gekopieerd; we verhangen de relatie naar de kopie
                    // Alleen de relatie aanleggen als deze niet al bestaat
                    if (!buildingData.getRelationById(copyParentId + ':' + newRel.Child)) {
                        buildingData.removeReldataFromCache(newRel);
                        newRel.Parent = copyParentId;
                        newRel.EntityRelationDataId = copyParentId + ':' + newRel.Child;
                        buildingData.addReldataToCache(newRel);
                    } else {
                        // Deze relatie bestond al, dus moeten we deze verwijderen
                        relsToRemove.add(newRel);
                        buildingData.removeReldata(newRel.EntityRelationDataId);
                    }
                }
            }
            if (relsToRemove.size) {
                for (let i = data.newrelations.length - 1; i >= 0; i--) {
                    const newRel = data.newrelations[i];
                    if (relsToRemove.has(newRel)) {
                        data.newrelations.splice(i, 1);
                    }
                }
            }

            // Alle gekopieerde entityData’s herordenen (hierbij kunnen soms ook andere entdata’s een andere Order krijgen)
            const renumberedEntdatas = [];
            if (entityIdsToRenumber.size > 0) {
                for (const entityId of entityIdsToRenumber) {
                    renumberedEntdatas.push(...reOrderNummering(entityId, bldId)); //-- de data op de client wordt gereorderd
                }
            }
            vm.updateData(bldId, updateAction.addEntityData, data); //-- alle gekopieerde entdatas en reldatas opslaan op de server

            // Van alle niet-gekopieerde entityData’s die herordend zijn, de Order opslaan.
            if (renumberedEntdatas.length > 0) {
                const copiedEntdatas = new Set(data.newEntdata);
                const extraEntdatas = renumberedEntdatas.filter(ed => !copiedEntdatas.has(ed));
                vm.updateData(bldId, updateAction.saveReOrder, extraEntdatas);
            }

            if (buildingData === ntaData.original) {
                // evt. delta’s van de gekopieerde entityData’s ook kopiëren
                for (const [oldId, newEntdata] of copiedEntdatasByOriginalId) {
                    const oldDeltas = ntaData.deltas.get(oldId);
                    if (oldDeltas) {
                        const newDeltas = new Map();
                        const newId = newEntdata.EntityDataId;
                        for (const [key, delta] of oldDeltas) {
                            newDeltas.set(key, Object.assign(Object.create(null), delta, { ShadowId: newId }));
                        }
                        ntaData.deltas.set(newId, newDeltas);
                        vm.updateData(bldId, updateAction.saveDeltas, newDeltas.values());
                    }
                }
            }

            _eventSource.trigger('afterCopy', data);

            return data.newEntdata;
        };

        vm.createRelation = function (parentId, childId, onDelete, onCopy) {
            const parent = vm.get(parentId);
            const child = vm.get(childId);
            if (!parent || !child) {
                return;
            }
            const rel = new RelationData(parent, child, onDelete, onCopy);
            //opslaan in client
            getData().addReldata(rel);

            vm.updateData(parent.BuildingId, updateAction.addRelationData, [rel]); //server

            _eventSource.trigger('afterCreateRelation', rel);

            return rel;
        };

        vm.delete = function (entityDataId) {
            const buildingData = getData();
            const data = buildingData.removeEntdata(entityDataId);
            if (data.success) { //local

                const buildingId = data.entitydatas[0].BuildingId;
                //Delete on server -> niet op te wachten
                vm.updateData(buildingId, updateAction.deleteEntityData, data); //server

                if (buildingData === ntaData.original) {
                    // verwijder nu ook evt. bijbehorende delta’s
                    for (const entdata of data.entitydatas) {
                        vm.deleteDeltas(entdata);
                    }
                }

                _eventSource.trigger('afterDelete', data);
            }
        };

        vm.deleteRelation = function (relationId) {
            const relation = getData().removeReldata(relationId);
            if (relation) { // client

                vm.updateData(relation.BuildingId, updateAction.deleteRelationData, [relation]);  //server

                _eventSource.trigger('afterDeleteRelation', relation);
            }
        };

        vm.deleteDeltas = function (entdata) {
            // voor een MEASURE of VARIANT, alle bijbehorende delta’s verwijderen
            if (['MEASURE', 'VARIANT'].includes(entdata.EntityId)) {
                const shadowId = entdata.EntityDataId;
                const shadowDeltas = ntaData.deltas.get(shadowId);
                if (shadowDeltas) {
                    const deltas = [...shadowDeltas.values()];
                    deltas[0] && vm.updateData(deltas[0].BuildingId, updateAction.deleteDeltas, deltas);
                    ntaData.deltas.delete(shadowId);
                }
            } else {
                // anders, nagaan of er delta’s zijn die naar deze entiteit verwijzen; die kunnen ook weg.
                const allDeltasToDelete = [];

                for (const deltasByKey of ntaData.deltas.values()) {
                    const deltasToDeleteByKey = new Map();

                    for (const [key, delta] of deltasByKey) {
                        if (new Set([delta.Id, delta.EntityDataId, delta.Parent, delta.Child]).has(entdata.EntityDataId)) {
                            deltasToDeleteByKey.set(key, delta);
                        }
                    }

                    for (const [key, delta] of deltasToDeleteByKey) {
                        deltasByKey.delete(key);
                        allDeltasToDelete.push(delta);
                    }
                }

                if (allDeltasToDelete.length) {
                    const buildingId = allDeltasToDelete[0].BuildingId;
                    vm.updateData(buildingId, updateAction.deleteDeltas, allDeltasToDelete)
                }
            }
        };

        vm.SaveReOrder = function (entdatas) {
            //-- deze funtie wordt vanuit de controllers van de formulier aangeroepen om het wisselen van order nummer te regelen.
            //-- ordernummers in batch entdatas blijven gelijk (nummering wordt niet aangepast, maar 'verhuist' alleen van 'eigenaar')
            const changedEntdatas = reOrder(entdatas); //local
            if (changedEntdatas.length > 0) {
                vm.updateData(changedEntdatas[0].BuildingId, updateAction.saveReOrder, changedEntdatas);  //server
            }
            return changedEntdatas;
        };

        vm.SaveReOrderNummering = function (entityId, buildingId) {
            //-- in deze functie worden de entiteiten opnieuw genummerd als er bv een entiteit wordt toegevoegd of gecopieerd.
            const changedEntdatas = reOrderNummering(entityId, buildingId); //local
            if (changedEntdatas.length > 0) {
                vm.updateData(buildingId, updateAction.saveReOrder, changedEntdatas);  //server
            }
            return changedEntdatas;
        };

        // validatePropdatas wordt overschreven voor ntaValidation *als die geladen wordt*.
        vm.validatePropdatas = propdatas => { };

        vm.setEntityRelevancy = function (entdatas, isRelevant) {
            if (!entdatas) return;
            if (!Array.isArray(entdatas)) entdatas = typeof entdatas[Symbol.iterator] === 'function' ? [...entdatas] : [entdatas];
            if (entdatas.length <= 0) return;

            const changedRelevancies = [];
            for (const ed of entdatas) {
                if (ed.Relevant !== isRelevant) {
                    //console.trace(`relevancy = ${isRelevant} for ${ed.EntityId} ${ed.EntityDataId}`, ed);
                    ed.Relevant = isRelevant;
                    changedRelevancies.push(ed);


                    // Als de entiteit niet meer relevant is, moeten alle propertydatas worden gevalideerd om foutmeldingen te laten verdwijnen.
                    // (Dit niet doen als we intussen van gebouw gewisseld zijn!)
                    if (!isRelevant) {
                        vm.validatePropdatas(ed.PropertyDatas);
                    }
                }
            }
            if (changedRelevancies.length > 0) {
                // Groepeer entdatas op buildingId
                const entdatasByBuildingId = new Map();
                for (const entdata of changedRelevancies) {
                    const entdatasOfBuilding = entdatasByBuildingId.get(entdata.BuildingId) || [];
                    entdatasOfBuilding.push(entdata);
                    entdatasByBuildingId.set(entdata.BuildingId, entdatasOfBuilding);
                }
                for (const [buildingId, entdatas] of entdatasByBuildingId.entries()) {
                    vm.updateData(buildingId, updateAction.entityDataRelevant, entdatas); // server
                }
            }
        }

        vm.setEntityVisibility = function (entdatas, isVisible) {
            if (!entdatas) return;
            if (!Array.isArray(entdatas)) entdatas = typeof entdatas[Symbol.iterator] === 'function' ? [...entdatas] : [entdatas];
            if (entdatas.length <= 0) return;

            const changedVisibilities = [];
            for (const ed of entdatas) {
                if (ed.Visible !== isVisible) {
                    //console.trace(`visibility = ${isVisible} for ${ed.EntityId} ${ed.EntityDataId}`, ed);
                    ed.Visible = isVisible;
                    changedVisibilities.push(ed);
                }
            }
            if (changedVisibilities.length > 0) {
                // Groepeer entdatas op buildingId
                const entdatasByBuildingId = new Map();
                for (const entdata of changedVisibilities) {
                    const entdatasOfBuilding = entdatasByBuildingId.get(entdata.BuildingId) || [];
                    entdatasOfBuilding.push(entdata);
                    entdatasByBuildingId.set(entdata.BuildingId, entdatasOfBuilding);
                }
                for (const [buildingId, entdatas] of entdatasByBuildingId.entries()) {
                    vm.updateData(buildingId, updateAction.entityDataVisible, entdatas); // server
                }
            }
        };

        vm.setPropdataVisibility = function (propdata, isVisible) {
            return vm.setPropdataStatus(propdata, null, !!isVisible);
        };

        vm.setPropdataRelevancy = function (propdata, isRelevant) {
            return vm.setPropdataStatus(propdata, !!isRelevant);
        };

        vm.setPropdataStatus = function (propdatas, isRelevant = null, isVisible = null, validate = true) {
            if (!propdatas) return;
            if (!Array.isArray(propdatas)) propdatas = [propdatas];

            const changedPropdatas = propdatas.filter(propdata => {
                let changed = false;

                if (propdata) {
                    if (typeof isRelevant === 'boolean') {
                        changed = propdata.Relevant !== isRelevant;
                        propdata.Relevant = isRelevant;
                    }

                    if (typeof isVisible === 'boolean') {
                        // als entdata niet visible is, dan propertydatas ook niet.
                        const entdata = vm.get(propdata.EntityDataId);
                        const isPropdataVisible = (entdata && !entdata.Visible) ? false : isVisible;
                        changed = changed || propdata.Visible !== isPropdataVisible;
                        propdata.Visible = isPropdataVisible;
                    }
                }

                return changed;
            });

            if (validate)
                vm.validatePropdatas(changedPropdatas);

            return vm.saveprops(changedPropdatas);
        };


        ///////////////////////////////////////////////////////////////////////////////////////////
        // Functies
        ///////////////////////////////////////////////////////////////////////////////////////////

        //-- Geeft de array ‘items’ terug, met alle dubbelen weggefilterd. ‘getKey’ is een optionele callback om voor elk item te bepalen welke sleutel uniek moet zijn.
        function distinct(items, getKey = item => item) {
            const result = [];
            const set = new Set();
            for (const item of items) {
                const key = getKey(item);
                if (!set.has(key)) {
                    result.push(item);
                    set.add(key);
                }
            }
            return result;
        } //-- end: distinct ----------------------------------------------------------------------

        function calculateNextOrder(bldId, entityId, order) {
            let shouldRenumber = false;
            const entityIdList = vm.getListWithEntityId(entityId, bldId); // <- deze lijst is al gesorteerd op Order
            const orderSpecified = order !== undefined && order >= 0;
            if (orderSpecified) {
                const entdatasFromOrder = entityIdList.filter(ed => ed.Order >= order);
                const currentOrderED = entdatasFromOrder.find(ed => ed.Order === order);
                const nextOrderED = entdatasFromOrder.find(ed => ed.Order > order);
                if (!currentOrderED) {
                    // dan is er geen EntityData met deze entityId en de voorgestelde Order; gebruik dus de order ongewijzigd
                } else if (!nextOrderED) {
                    order += _orderInterval;
                } else {
                    const requestedOrder = order;
                    const orderDiff = nextOrderED.Order - order;
                    order += Math.round(orderDiff / 2);
                    if (order <= currentOrderED.Order || order >= nextOrderED.Order) {
                        order = requestedOrder + orderDiff / 2; // dan zonder afronding; het hernummeren zorgt ervoor dat dat weer goed komt
                        shouldRenumber = true;
                    }
                }
            } else {
                // zoek de hoogste Order op van deze entityId (of 0 als er nog geen is).
                const maxOrder = entityIdList.length === 0 ? 0 : entityIdList[entityIdList.length - 1].Order;
                order = maxOrder + _orderInterval;
            }
            return [order, shouldRenumber];
        } //-- end: calculateNextOrder ------------------------------------------------------------

        function copyEntdata(entityDataId, parentRel, adjustName, copiedEntdatasByOrgId, entityIdsToRenumber) { //parentRel = { "OnCopy": 1, "OnDelete": 1, "Parent": unitrz.EntityDataId, "ParentEntityId": "UNIT-RZ" }
            const buildingData = getData();

            const res = {
                newEntdata: [],
                newrelations: [],
            };

            // Als deze entityData al eerder gekopieerd is, dan dit niet nogmaals doen.
            if (copiedEntdatasByOrgId.has(entityDataId)) {
                // Maar wel een relatie leggen tussen een evt. meegegeven parentRel en de eerder gekopieerde entiteit
                if (parentRel) {
                    const newEntdata = copiedEntdatasByOrgId.get(entityDataId);
                    res.newrelations = addRelations(newEntdata, [parentRel], [], copiedEntdatasByOrgId);
                }
                return res;
            }

            const entdata = buildingData.get(entityDataId);
            const guid = GetUniqueId();
            const [newOrder, shouldRenumber] = calculateNextOrder(entdata.BuildingId, entdata.EntityId, entdata.Order);

            const newEntdata = new EntityData(entdata.EntityId, Object.assign({}, entdata, { EntityDataId: guid, Order: newOrder }), entdata.PropertyDatas);

            if (adjustName) {
                const propdataOmschr = newEntdata.PropertyDatas.find(propdata => propdata.PropertyId.endsWith('_OMSCHR'));
                if (propdataOmschr) propdataOmschr.Value += ' - kopie';
            }

            // OPEN property op false zetten. Gekopieerde data moet nog gevalideerd worden.
            const propdataOpen = newEntdata.PropertyDatas.find(propdata => propdata.PropertyId.endsWith('_OPEN'));
            if (propdataOpen) propdataOpen.Value = 'false';

            // registreer de oude en nieuwe EntityDataId
            copiedEntdatasByOrgId.set(entityDataId, newEntdata);
            if (shouldRenumber) entityIdsToRenumber.add(entdata.EntityId); // hou bij dat we straks moeten hernummeren

            const parentRels = [];
            //relatie met parent toevoegen
            if (parentRel) {
                parentRels.push(parentRel);
            }

            //toevoegen evt. parent relaties van entdata naar de gekopieerde entiteit
            //alle relaties niet alleen Cascaded, behalve de relatie van een evt. hiervoor aangemaakte parent
            const otherparents = buildingData.getParentRelations(entityDataId)
                .filter(rel => !parentRel || rel.ParentEntityId !== parentRel.ParentEntityId);
            parentRels.push(...otherparents);

            //add parents relations met nieuwe entiteiten (parentRels)
            const newrelations = addRelations(newEntdata, parentRels, [], copiedEntdatasByOrgId);

            res.newEntdata = [newEntdata];
            res.newrelations = newrelations;

            //voor elke child relatie met OnCopy Cascade -> kopieer child met relatie naar nieuwe parent
            const childRels = buildingData.getChildRelations(entityDataId).filter(rel => rel.OnCopy);
            for (const rel of childRels) {
                const childRel = {
                    "OnCopy": rel.OnCopy,
                    "OnDelete": rel.OnDelete,
                    "Parent": newEntdata.EntityDataId,
                    "ParentEntityId": newEntdata.EntityId
                };
                const newres = copyEntdata(rel.Child, childRel, false, copiedEntdatasByOrgId, entityIdsToRenumber);
                res.newEntdata.push(...newres.newEntdata);
                res.newrelations.push(...newres.newrelations);
            }

            return res;
        } //-- end: copyEntdata -------------------------------------------------------------------

        function addRelations(entdata, parentRels, childRels, copiedEntdatasByOrgId = new Map()) {
            const buildingData = getData();
            const relations = [];
            const newEntdatasById = new Map([...copiedEntdatasByOrgId.values()].map(ed => [ed.EntityDataId, ed]));
            if (parentRels && parentRels.length > 0) {
                for (const item of parentRels) {
                    const parent = buildingData.get(item.Parent) || newEntdatasById.get(item.Parent);
                    const newRelationData = new RelationData(parent, entdata, item.OnDelete, item.OnCopy);
                    relations.push(newRelationData);
                }
            }
            if (childRels && childRels.length > 0) {
                for (const item of childRels) {
                    const child = buildingData.get(item.Child) || newEntdatasById.get(item.Child);
                    const newRelationData = new RelationData(entdata, child, item.OnDelete, item.OnCopy);
                    relations.push(newRelationData);
                }
            }
            return relations;
        } //-- end: addRelations ------------------------------------------------------------------

        function reOrder(entdatas) {
            // AANNAME: alle entdatas hebben dezelfde BuildingId en EntityId!
            //-- ik krijg een lijst binnen met waarbij de entiteiten een andere volgorde hebben dan de Order aangeeft; die Orders moeten herschikt worden zodat dat weer klopt.
            //-- maak een lijst met de orders van zelfde entiteiten als de entdatas, maar nog met het originele ordernummer.
            let orderNumbers = entdatas.map(ed => ed.Order).sort((a, b) => a - b);

            const changedEntdatas = [];

            const hasDuplicates = orderNumbers.some((order, index) => order === orderNumbers[index - 1]);
            if (hasDuplicates) {
                const renumberedEntdatas = reOrderNummering(entdatas[0].EntityId, entdatas[0].BuildingId);
                changedEntdatas.push(...renumberedEntdatas);

                // maak opnieuw de lijst van de orders (die nu wel uniek zijn).
                orderNumbers = entdatas.map(ed => ed.Order).sort((a, b) => a - b);
            }

            for (let i = 0; i < entdatas.length; i++) {
                const entdata = entdatas[i];
                const wantedOrder = orderNumbers[i];

                if (entdata.Order !== wantedOrder) {
                    //-- geeft de entdata de order van de originele entdata en voeg deze toe aan lijst voor server
                    entdata.Order = wantedOrder;
                    changedEntdatas.push(entdata);
                }
            }

            // Zorg dat de getData().entdatas altijd goed gesorteerd blijft
            if (changedEntdatas.length > 0) {
                getData().entdatas.sort(orderByBuildingEntityIdAndOrder);
            }
            return changedEntdatas.distinct();
        } //-- end: reOrder -----

        function reOrderNummering(entityId, buildingId) {
            if (!entityId) throw new Error('EntityId is required!');
            if (!buildingId) throw new Error('BuildingId is required!');

            const entdatas = vm.getListWithEntityId(entityId, buildingId);

            const minOrder = Math.floor(entdatas.reduce((minValue, entdata) => Math.min(minValue, entdata.Order), Number.MAX_SAFE_INTEGER));

            const changedEntdatas = entdatas.filter((entdata, index) => {
                const newOrder = minOrder + (index * _orderInterval);
                const change = newOrder !== entdata.Order;
                if (change) {
                    entdata.Order = newOrder;
                }
                return change;
            });

            // Zorg dat de getData().entdatas altijd goed gesorteerd blijft
            if (changedEntdatas.length > 0) {
                getData().entdatas.sort(orderByBuildingEntityIdAndOrder);
            }
            return changedEntdatas;
        } //-- end: reOrderNummering -----

        //var uuidv4 = require('uuid/v4');
        function GetUniqueId() {
            return uuid.v4();
        }


        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Server communicatie
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        vm.updateData = function (buildingId, action, data) {
            //NTAUpdateBuildingData
            const updateData = {
                "bldId": buildingId,
                "action": action,
                "propertyDatas": [],
                "entityDatas": [],
                "relationdatas": [],
                "deltas": [],
                "data": null,
                "id": GetUniqueId(),
                "time": new Date().toISOString(), // (UTC)
                "attempt": 0,
                "connectionId": ntaStorage.getConnectionId(),
                "clientId": _clientId,
                //"stack": new UpdateOrigin().stack,
            };
            switch (action) {
                case updateAction.addEntityData:
                    updateData.entityDatas = data.newEntdata;
                    updateData.relationdatas = data.newrelations;
                    break;
                case updateAction.savePropertyData:
                    updateData.propertyDatas = data;
                    break;
                case updateAction.deletePropertyData:
                    updateData.propertyDatas = data;
                    break;
                case updateAction.addRelationData:
                    updateData.relationdatas = data;
                    break;
                case updateAction.deleteEntityData:
                    updateData.entityDatas = data.entitydatas;
                    updateData.relationdatas = data.relationdatas;
                    break;
                case updateAction.deleteRelationData:
                    updateData.relationdatas = data;
                    break;
                case updateAction.saveReOrder:
                    updateData.entityDatas = data;
                    break;
                case updateAction.entityDataRelevant:
                    updateData.entityDatas = data;
                    break;
                case updateAction.calculate:
                    updateData.deltas = data;
                    ntaData.setBuildingCalculationId(buildingId, updateData.id);
                    break;
                case updateAction.entityDataVisible:
                    updateData.entityDatas = data;
                    break;
                case updateAction.saveDeltas:
                case updateAction.deleteDeltas:
                    updateData.deltas = [...data];
                    break;
                case updateAction.message:
                    updateData.data = data;
                    break;

                default:
                    $log.error("Unexpected action '" + action + "' in updateData!", data);
                    break;
            }

            //const wasCalculationNeeded = getCalculationNeeded(buildingId);
            let calculationNeeded = null;
            if (ntaData.apptype !== 2) { //Geen Demo -> Demoversie kent geen berekening
                if (action === updateAction.calculate) {
                    calculationNeeded = false;
                } else {
                    if (updateData.propertyDatas) {
                        // Maak de resultaten niet grijs voor het opslaan van velden die geen effect hebben op de berekening.
                        if (updateData.propertyDatas.some(propdata => !/_OMSCHR$|_OPEN$|_OPM$|^GEB_(DATE|HASMELD|CALCNEEDED|OPM)$|^MELD_|^RESULT-ELEKTR|^RESULT-TOJULI|^RESULT-OVERZICHT_|^EP_|^AFM|^SETTINGS_(VAR|MAAT)|^MEASURE|^VERBR_NIET_REPRES_|^VERBR_IN_STOOKSEIZOEN_|^VERBR_BTN_STOOKSEIZOEN_/.test(propdata.PropertyId))) {
                            calculationNeeded = true;
                        }
                    }
                    if (updateData.entityDatas) {
                        // Maak de resultaten niet grijs voor het toevoegen of verwijderen van een melding, afmeldentiteiten, of resultaatoverzicht.
                        if (updateData.entityDatas.some(entdata => !/^MELDING$|^AFM|^MEASURE|^RESULT-OVERZICHT/.test(entdata.EntityId))) {
                            calculationNeeded = true;
                        }
                    }
                    if (updateData.relationdatas) {
                        // Maak de resultaten niet grijs voor het toevoegen of verwijderen van relaties van afmeld-entiteiten.
                        if (updateData.relationdatas.some(rel => !/^AFM|^MEASURE/.test(rel.ChildEntityId))) {
                            calculationNeeded = true;
                        }
                    }
                }
                if (calculationNeeded !== null) {
                    setCalculationNeeded(buildingId, calculationNeeded);
                }
            }

            const shadowId = vm.getShadowId();
            const unshadowableActions = new Set([updateAction.calculate, updateAction.saveDeltas, updateAction.deleteDeltas, updateAction.message]);
            if (shadowId && !unshadowableActions.has(action)) {
                // dan zitten we nu in een maatregel of een variant

                // Als we tijdelijk geen delta’s mogen opslaan (bv. tijdens validatie van maatregelen), dan stappen we er nu uit.
                if (ntaData.suspendSavingDeltas) return;

                // We gaan nu controleren of er misschien delta’s gemaakt gaan worden voor installaties die helemaal niet
                //  bij de huidige maatregel horen.
                checkIfUpdatesBelongWithMeasure(updateData, shadowId);

                // nu moeten de wijzigingen omgezet worden naar deltas
                const newDeltas = ntaDeltas.createFromUpdate(updateData, shadowId);

                const deltasToSave = newDeltas.filter(delta => delta.Action);
                const deltasToDelete = newDeltas.filter(delta => !delta.Action);
                deltasToDelete.forEach(delta => delta.Action = 0); // Voorkom foutmelding ‘Cannot convert null value to NTA8800.Models.DeltaAction’ op de server.

                if (deltasToSave.length) {
                    updateData.deltas = deltasToSave;
                    updateData.action = updateAction.saveDeltas;

                    if (deltasToDelete.length) {
                        vm.updateData(buildingId, updateAction.deleteDeltas, deltasToDelete);
                    }
                } else if (deltasToDelete.length) {
                    updateData.deltas = deltasToDelete;
                    updateData.action = updateAction.deleteDeltas;
                } else {
                    // niets te doen; dan zijn we meteen klaar
                    return;
                }

                updateData.entityDatas = [];
                updateData.relationdatas = [];
                updateData.propertyDatas = [];
            }

            //check of berekening is afgemeld. Zo ja, dan melding geven dat er niets wordt opgeslagen
            const isLocked = !ntaData.canSaveBuilding(buildingId);
            if (isLocked) {
                setCalculationNeeded(buildingId, false);
                ntaData.setBuildingCalculationId(buildingId, null);
                let shouldShowAlert = true; // standaard altijd de melding geven als de berekening vergrendeld is
                let deleteMelding = false; // Meldingen wel verwijderen ondanks dat berekening vergrendeld is.
                switch (action) {
                    case updateAction.addEntityData:
                        shouldShowAlert = updateData.entityDatas.some(entdata => entdata.EntityId !== 'MELDING');
                        break;
                    case updateAction.deleteEntityData:
                        shouldShowAlert = updateData.entityDatas.some(entdata => entdata.EntityId !== 'MELDING');
                        deleteMelding = updateData.entityDatas.some(entdata => entdata.EntityId === 'MELDING');
                        break;
                    case updateAction.savePropertyData:
                        shouldShowAlert = updateData.propertyDatas.some(propdata => !propdata.PropertyId.endsWith('_OPEN'));
                        break;
                }
                if (shouldShowAlert) {
                    ntaAlert.showNoEdit();
                }
                if (deleteMelding) {
                    updateData.entityDatas = updateData.entityDatas.filter(entdata => entdata.EntityId === 'MELDING');
                    updateData.relationdatas = [];
                } else {
                    return; // niets opslaan dus
                }
            }

            // onthoud de wijziging in de client (geen await; we hoeven daar niet op te wachten)
            ntaLocal.rememberUpdate(updateData);

            // zet de wijziging klaar voor de server
            ntaStorage.addUpdate(updateData);

            //--- verwijderen resultaten na update voorlopig uitzetten, omdat er nog spookmeldingen zijn. Als deze zijn opgelost kan dit weer aan worden gezet.
            // na update, resultaten verwijderen indien er opnieuw gerekend moet worden.
            //if (getCalculationNeeded(buildingId) && !wasCalculationNeeded) {
            //    const resultEntityIds = ['PRESTATIE', 'RESULT-ENERGIEFUNCTIE', 'RESULT-ENERGIEGEBRUIK', 'RESULT-TOJULI', 'RESULT-GTO'];
            //    const dontReset = new Set([
            //        'RESULT-ENERGIEFUNCTIE_CAT', 'RESULT-ENERGIEFUNCTIE_CODE', 'RESULT-ENERGIEFUNCTIE_EENHEID', 'RESULT-ENERGIEFUNCTIE_GROOTHEID', 'RESULT-ENERGIEFUNCTIE_NAAM', 'RESULT-ENERGIEFUNCTIE_RESULTAAT',
            //        'RESULT-ELEKTR_NIETGEBGEB_GEBR', 'RESULT-ELEKTR_NIETGEBGEB'
            //    ]);
            //    const propdatas = [];
            //    resultEntityIds.forEach(function (resultEntityId, index) {
            //        vm.getListWithEntityId(resultEntityId, buildingId).forEach(function (item, index) {
            //            item.PropertyDatas.forEach(function (propdata) {
            //                if (!dontReset.has(propdata.PropertyId)) {
            //                    if (ntaData.properties[propdata.PropertyId].DefaultValue !== propdata.Value) {
            //                        propdata.Value = ntaData.properties[propdata.PropertyId].DefaultValue;
            //                        propdatas.push(propdata);
            //                    }
            //                }
            //            });
            //        });
            //    });
            //    if (propdatas.length > 0) {
            //        const updateResultData = { "bldId": buildingId, "action": updateAction.savePropertyData, "propertyDatas": propdatas, "entityDatas": [], "relationdatas": [] };
            //        ntaStorage.addUpdate(updateResultData);
            //    }
            //}

            // Eventuele foutmeldingen van de server verwijderen als de resultaten uitgegrijsd (zouden) worden.
            if (calculationNeeded) {
                // Deze error-ids zijn foutmeldingen die vanaf de server aangemaakt worden, en die verwijderd moeten worden zodra er doorgerekend wordt.
                const ntaEntityData = shadowId ? new NtaEntityDataFactory() : vm;
                const serverErrorIds = new Set(
                       ["[E040]", "[E054]", "[E057]", "[E079]", "[E080]",
                        "[E081]", "[E090]", "[E091]", "[E092]", "[E093]",
                        "[E102]", "[E103]", "[E107]", "[E108]", "[E112]"]);
                const serverErrorMeldingen = ntaEntityData.getListWithEntityId('MELDING', buildingId, true).filter(m => serverErrorIds.has(m.PropertyDatas["MELD_ERRORID"].Value));
                for (const melding of serverErrorMeldingen) {
                    ntaEntityData.delete(melding.EntityDataId);
                }
            }

            // begin met aftellen tot ze naar de server gestuurd moeten worden (standaard 1 seconde)
            if (action === updateAction.calculate) {
                ntaStorage.saveDataToServer();
            } else {
                ntaStorage.startCountdownToSave();
            }
        }; //-- end: vm.updateData

        function checkIfUpdatesBelongWithMeasure(updateData, shadowId) {
            // MCO 2023-12-22 https://trello.com/c/tto37Ii4/1940-resultaten-variant-onjuist
            // We gaan nu controleren of er misschien delta’s gemaakt gaan worden voor installaties die helemaal niet
            //  bij de huidige maatregel horen.
            if (updateData.action === 'deleteEntityData')
                return; //bij action deleteEntityData is de pvInstallatie al verwijderd, waardoor INSTALLATIE onterecht wordt verwijderd uit de updateData. Zie trello https://trello.com/c/UBJNzMA5/1351-maatregel-pv-fout

            const measure = ntaData.original.get(shadowId);
            if (!measure || measure.EntityId !== 'MEASURE')
                return; // we controleren alleen binnen maatregelen

            if (measure.PropertyDatas['MEASURE_TYPE'].Value !== 'MEASURE-PV')
                return; // voorlopig controleren we alleen nog maar PV-maatregelen

            const installationPropIds = new Set(ntaData.properties['INSTALLATIE'].map(p => p.Id));

            const pvInstallationIds = new Set(vm.getListWithEntityId('INSTALLATIE', updateData.buildingId)
                .filter(ed => ed.PropertyDatas['INSTALL_TYPE'].Value === 'INST_PV')
                .map(ed => ed.EntityDataId));

            const omittedData = [];
            for (let i = (updateData.entityDatas || []).length - 1; i >= 0; i--) {
                const entdata = updateData.entityDatas[i];
                const shouldRemove = entdata.EntityId === 'INSTALLATIE' && !pvInstallationIds.has(entdata.EntityDataId);
                if (shouldRemove) {
                    omittedData.push(...updateData.entityDatas.splice(i, 1));
                }
            }

            for (let i = (updateData.relationdatas || []).length - 1; i >= 0; i--) {
                const reldata = updateData.relationdatas[i];
                const shouldRemove = !reldata; // ...
                if (shouldRemove) {
                    omittedData.push(...updateData.relationdatas.splice(i, 1));
                }
            }

            for (let i = (updateData.propertyDatas || []).length - 1; i >= 0; i--) {
                const propdata = updateData.propertyDatas[i];
                const shouldRemove = (installationPropIds.has(propdata.PropertyId) && !pvInstallationIds.has(propdata.EntityDataId))
                    || propdata.PropertyId.startsWith('VERW-')
                    || propdata.PropertyId.startsWith('KOEL-');
                if (shouldRemove) {
                    omittedData.push(...updateData.propertyDatas.splice(i, 1));
                }
            }

            if (omittedData.length) {
                $log.warn('LET OP: poging verijdeld om onterechte wijzigingen als delta op te slaan!', omittedData, ' in ', updateData, new UpdateOrigin('ShadowId: ' + shadowId));
            }
        } //-- end: checkIfUpdatesBelongWithMeasure -----------------------------------------------

        class UpdateOrigin extends Error {
            constructor(...args) {
                super(...args);
                this.name = UpdateOrigin.name;
            }
        }

        vm.processLocalUpdates = async function processLocalUpdates(buildingId) {
            $log.warn(`Building ${buildingId} has updates.`);
            // Er staan nog niet-opgeslagen wijzigingen lokaal; die moeten eerst verwerkt worden.
            const [pendingUpdates, expiredUpdates] = await ntaLocal.getUpdatesForNextAttempt(buildingId);
            if (ntaData.canSaveBuilding(buildingId)) {
                const updateActionsReport = [...pendingUpdates.map(ud => ud.action)
                    .reduce((prev, curr) => prev.set(curr, (prev.get(curr) || 0) + 1), new Map())]
                    .map(([action, count]) => `${count}x ${action}`)
                    .join(', ');
                $log.warn(`Processing ${pendingUpdates.length} pending updates: ${updateActionsReport}...`);
                if (pendingUpdates.length > 0) {
                    // Stuur de openstaande wijzigingen alsnog naar de server, behalve de ‘calculate’.
                    const updatesToRetry = pendingUpdates.filter(updateData => updateData.action !== 'calculate');
                    ntaStorage.whenConnected(connection => {
                        ntaStorage.insertUpdates(updatesToRetry);
                        ntaStorage.saveDataToServer(); // <-- geen await, we verwerken de wijzigingen toch alvast in de client
                    });
                    // en verwerk intussen de wijzigingen ook alvast in het geheugen
                    updateDataLocal(pendingUpdates);
                }
                if (expiredUpdates.length > 0) {
                    $log.warn('Verlopen wijzigingen worden weggegooid:', expiredUpdates);
                    await ntaLocal.forgetUpdates(buildingId, expiredUpdates.map(updateData => updateData.id));
                    //await ntaAlert.showError();
                }
            } else {
                // Alle client-updates verwijderen, want de berekening is vergrendeld.
                const storedUpdates = pendingUpdates.concat(expiredUpdates);
                $log.warn('Achterstallige wijzigingen worden weggegooid:', storedUpdates);
                await ntaLocal.forgetUpdates(buildingId, storedUpdates.map(updateData => updateData.id));
            }
        };

        function updateDataLocal(buildingUpdates) {
            const buildingData = ntaData.original;

            // Verwerk elke opgegeven update (nogmaals) lokaal in de ntaData, zonder deze naar de server te sturen.
            const updatesToForget = [];
            for (const updateData of buildingUpdates) {
                // Controleer dat deze niet reeds verwerkt was door de server
                if (ntaData.processedUpdateIds.has(updateData.id)) {
                    updatesToForget.push(updateData);
                    continue;
                }

                switch (updateData.action) {
                    case updateAction.addEntityData: {
                        saveEntdatas(updateData.entityDatas);
                        saveReldatas(updateData.relationdatas);
                        break;
                    }

                    case updateAction.addRelationData: {
                        saveReldatas(updateData.relationdatas);
                        break;
                    }

                    case updateAction.calculate: {
                        // Als er nog een rekenactie openstond, dan moeten de resultaten uitgegrijsd worden.
                        setCalculationNeeded(updateData.bldId, true);
                        ntaLocal.forgetUpdates(updateData.bldId, [updateData.id]);
                        break;
                    }

                    case updateAction.deleteEntityData: {
                        removeEntdatas(updateData.entityDatas);
                        removeReldatas(updateData.relationdatas);
                        break;
                    }

                    case updateAction.deletePropertyData: {
                        removePropdatas(updateData.propertyDatas);
                        break;
                    }

                    case updateAction.deleteRelationData: {
                        removeReldatas(updateData.relationdatas);
                        break;
                    }

                    case updateAction.entityDataRelevant: {
                        copyPropertyEntdatas(updateData.entityDatas, 'Relevant');
                        break;
                    }
                    case updateAction.entityDataVisible: {
                        copyPropertyEntdatas(updateData.entityDatas, 'Visible');
                        break;
                    }
                    case updateAction.saveReOrder: {
                        copyPropertyEntdatas(updateData.entityDatas, 'Order');
                        break;
                    }

                    case updateAction.savePropertyData: {
                        savePropdatas(updateData.propertyDatas);
                        break;
                    }

                    case updateAction.saveDeltas: {
                        saveDeltas(updateData.deltas);
                        break;
                    }

                    case updateAction.deleteDeltas: {
                        removeDeltas(updateData.deltas);
                        break;
                    }

                    case updateAction.message: {
                        // daar hoeven we niets mee
                        break;
                    }

                    default: {
                        $log.error('Onverwachte action in updateData:', updateData);
                        break;
                    }
                }
            }
            if (updatesToForget.length > 0) {
                const updateIds = updatesToForget.map(updateData => updateData.id);
                ntaLocal.forgetUpdates(updatesToForget[0].bldId, updateIds);
            }


            function saveEntdatas(entdatas) {
                for (const entdata of entdatas) {
                    const existingEntdata = buildingData.get(entdata.EntityDataId);
                    if (!existingEntdata) {
                        buildingData.addEntdata(entdata);
                    }
                }
            }

            function saveReldatas(reldatas) {
                for (const reldata of reldatas) {
                    const existingReldata = buildingData.getRelation(reldata.Parent, reldata.Child);
                    if (!existingReldata) {
                        buildingData.addReldata(reldata);
                    }
                }
            }

            function removeEntdatas(entdatas) {
                for (const entdata of entdatas) {
                    const existingEntdata = buildingData.get(entdata.EntityDataId);
                    if (existingEntdata) {
                        // verwijderen uit ntaData
                        buildingData.removeEntdata(entdata.EntityDataId);
                    }
                }
            }

            function removeReldatas(reldatas) {
                for (const reldata of reldatas) {
                    buildingData.removeReldata(reldata.EntityRelationDataId);
                }
            }

            function savePropdatas(propdatas) {
                for (const propdata of propdatas) {
                    const entdata = buildingData.get(propdata.EntityDataId);
                    const existingPropdata = entdata && entdata.PropertyDatas[propdata.PropertyId];
                    if (existingPropdata) {
                        for (const key of Object.keys(propdata)) {
                            existingPropdata[key] = propdata[key];
                        }
                    }
                }
            }

            function removePropdatas(propdatas) {
                for (const propdata of propdatas) {
                    const entdata = buildingData.get(propdata.EntityDataId);
                    if (entdata) {
                        const index = entdata.PropertyDatas.findIndex(pd => pd.PropertyId === propdata.PropertyId);
                        if (index >= 0) {
                            entdata.PropertyDatas.splice(index);
                        }
                        delete entdata.PropertyDatas[propdata.PropertyId];
                    }
                }
            }

            function copyPropertyEntdatas(entdatas, propertyName) {
                for (const entdata of entdatas) {
                    const existingEntdata = buildingData.get(entdata.EntityDataId);
                    if (existingEntdata && entdata !== existingEntdata) {
                        existingEntdata[propertyName] = entdata[propertyName];
                    }
                }
            }

            function saveDeltas(deltas) {
                const deltasByShadowId = new Map();
                for (const delta of deltas) {
                    const shadowDeltas = deltasByShadowId.get(delta.ShadowId) || [];
                    if (shadowDeltas.length === 0) {
                        deltasByShadowId.set(delta.ShadowId, shadowDeltas);
                    }
                    shadowDeltas.push(delta);
                }
                for (const shadowId of deltasByShadowId.keys()) {
                    ntaData.mergeDeltas(shadowId, deltasByShadowId.get(shadowId));
                }
            }

            function removeDeltas(deltas) {
                for (const delta of deltas) {
                    const shadowDeltas = ntaData.deltas.get(delta.ShadowId);
                    if (shadowDeltas) {
                        const key = `${delta.Table}_${delta.Id}`;
                        shadowDeltas.delete(key);
                    }
                }
            }
        } //-- end: updateDataLocal

        const _originalMethods = {
            create: vm.create,
            getFirstWithEntityId: vm.getFirstWithEntityId,
            getListWithEntityId: vm.getListWithEntityId,
        };

        /// Om precies dezelfde ‘signature’ te krijgen als de ntaEntityData-service, kan aan deze
        ///  method een functie ‘getBuildingId’ meegegeven worden. Die functie wordt dan
        ///  aangeroepen om in onderstaande functies de (default) buildingId te bepalen.
        vm.configureForSingleBuilding = function (getBuildingId = () => ntaData.buildingId) {

            // baseNtaEntityData verwacht de buildingId als eerste parameter; ntaEntityData gebruikt altijd die van ntabuilding.
            this.create = function (entityId, order, parentRels, childRels, propdatas) {
                return _originalMethods.create.call(this, getBuildingId(), entityId, order, parentRels, childRels, propdatas);
            };

            // baseNtaEntityData heeft een expliciete buildingId nodig; ntaEntityData heeft de ntabuilding.buildingid als default.
            this.getFirstWithEntityId = function (entityId, buildingId = getBuildingId(), skipSorting = false) {
                return _originalMethods.getFirstWithEntityId.call(this, entityId, buildingId, skipSorting);
            };

            // baseNtaEntityData heeft een expliciete buildingId nodig; ntaEntityData heeft de ntabuilding.buildingid als default.
            this.getListWithEntityId = function (entityId, buildingId = getBuildingId(), skipSorting = false) {
                return _originalMethods.getListWithEntityId.call(this, entityId, buildingId, skipSorting);
            };
        };

        if (typeof getBuildingId === 'function') {
            vm.configureForSingleBuilding(getBuildingId);
        }

    }; //-- end: NtaEntityDataFactory -------------------------------------------------------------
}]);
