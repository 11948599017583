﻿const progressModule = angular.module("progressModule", ["ngAnimate", "ngMaterial"])
    .config(['$mdThemingProvider', function ($mdThemingProvider) {
        $mdThemingProvider.theme('default')
            .primaryPalette('light-green')
            .accentPalette('orange');
    }]);

progressModule.factory('progressCircle', ['$log', 'time', function ($log, time) {
    const self = {};

    self.showProgress = false;
    self.progressDescr = "";
    self.showProgressValue = false;
    self.progressValue = 0;

    self.getShowProgress = function () {
        return self.showProgress;
    };

    self.getProgressDescription = function () {
        return self.progressDescr;
    };

    self.getShowProgressValue = function () {
        return self.showProgressValue;
    };

    self.getProgressValue = function () {
        return self.progressValue;
    };

    self.setShowProgressValue = function (showProgress, descr, showValue = false) {
        if (descr !== "") { descr = descr + "..."; }

        self.showProgress = showProgress;
        self.showProgressValue = showProgress && showValue;
        self.progressDescr = descr;
        if (!showProgress) {
            self.progressValue = 0;
        }

    };

    self.setProgressValue = function (value = 0) {
        self.progressValue = Math.round(value);
        if (value > 100) {
            self.progressValue = 100;
        }
        if (value < 0) {
            self.progressValue = 0;
        }
    };

    const _connection = new signalR.HubConnectionBuilder()
        .withUrl("/hubs/progress")
        .configureLogging({
            log: (logLevel, message) => {
                let method = 'debug';
                switch (logLevel) {
                    case signalR.LogLevel.Critical: method = 'error'; break;
                    case signalR.LogLevel.Error: method = 'error'; break;
                    case signalR.LogLevel.Warning: method = 'warn'; break;
                    case signalR.LogLevel.Information: method = 'info'; break;
                }
                $log[method]('SignalR/hubs/progress:', message);
            }
        })
        .withAutomaticReconnect()
        .build();
    _connection.on("ReceiveProgressValue", function (id, value) {
        if (value < 100) {
            self.setProgressValue(value);
        } else {
            self.setShowProgressValue(false);
            //_connection.stop();
        }
    });
    _connection.on("ReceiveProgressText", function (id, value) {
        if (value) {
            self.progressDescr = value;
        }
    });
    _connection.on("Progress", function (percentage, text) { // <-- via RunHub.ClientProxy
        self.setProgressValue(percentage);
        if (typeof text === 'string') {
            self.progressDescr = text;
        }
    });

    _connection.on("Error", error => $log.info("Error", error)); // <-- via RunHub.ClientProxy; wordt als het goed is ook opgevangen door HubConnection
    _connection.on("Finished", (...results) => $log.info("Finished", ...results)); // <-- via RunHub.ClientProxy; wordt als het goed is ook opgevangen door HubConnection

    _connection.onclose(error => {
        if (error) {
            $log.error(error, 'while closing progress connection');
        } else {
            $log.info('progress connection closed');
        }
    });
    self.startConnection = async function (attempt = 0) {
        try {
            if (_connection.state === 'Disconnected') {
                await _connection.start();
            }
            return _connection.connectionId;

        } catch (err) {
            if (attempt >= 3 || err && err.constructor && err.constructor.name === 'TimeoutError') {
                $log.error(err);
                return null;
            } else {
                $log.warn(err);
                await time.delay(1000);
                return await self.startConnection(attempt + 1);
            }
        }
    };

    return self;
}]);
