﻿angular.module('projectModule')
    .service('ntaDeltas',
        ['$rootScope', '$log', 'ntaData', 'projecttree', 'EntityData', 'RelationData', '$location', 'time',
function ($rootScope,   $log,   ntaData,   projecttree,   EntityData,   RelationData,   $location,   time) {
    'use strict';
    const self = this;

    /// == Description ============================================================================

    /// Deze service is bedoeld voor het aanmaken en toepassen van deltas.
    /// Een delta is een wijziging ten opzichte van de oorspronkelijke berekening, en komt
    ///  neer op een record in de TableStorage van één van de tabellen NTAEntityData,
    ///  NTAPropertyData of NTAEntityRelationData.
    /// Een Delta heeft altijd ook een Action, die kan ‘Replace’ of ‘Delete’ zijn. In het geval
    ///  van Replace is het idee dat de betreffende rij in de tabel wordt vervangen door alle
    ///  informatie die in de Delta zit. In het geval van Delete moet de rij verwijderd worden
    ///  uit de tabel.


    /// == Imports ================================================================================

    // ...


    /// == Instance variables =====================================================================

    const _deltaSpecificProperties = new Set([
        'ShadowId', 'Table', 'Id', 'Action',
    ]);
    const _shadowMeasureTypes = new Set([
        'MEASURE-INFILTRATIE', 'MEASURE-VERT_LEIDINGEN', 'MEASURE-HVAC_INSTALLATIE', 'MEASURE-PV', 'MEASURE-VERL', 'MEASURE-WINDT',
    ]);
    const _deltaTableOrder = new Map(['NTAEntityData', 'NTAPropertyData', 'NTAEntityRelationData'].map((value, index) => [value, index + 1]));
    const _collatorNL = new Intl.Collator('nl');


    /// == Class definitions ======================================================================

    /*
    PartitionKey: `${buildingId}_${shadowId}`
    RowKey: `${table}_${id},
    */
    class Delta {
        constructor(shadowId, table, id, action = 'Replace') {
            this.BuildingId = ntaData.buildingId;
            this.ShadowId = shadowId;
            this.Table = table; // NTAEntityData, NTAPropertyData, NTAEntityRelationData
            this.Id = id; // EntityDataId, PropertyDataId, RelationDataId
            this.Action = action; // -- Replace, Delete
        }
    } //-- end: Delta -----------------------------------------------------------------------------

    class DeltaPropertyData extends Delta {
        constructor(shadowId, id, propdata = {}, action = 'Replace') {
            super(shadowId, 'NTAPropertyData', id, action);
            this.EntityDataId = propdata.EntityDataId,
            this.PropertyId = propdata.PropertyId,
            this.Value = propdata.Value;
            this.Touched = propdata.Touched;
            this.Relevant = propdata.Relevant;
            this.Visible = propdata.Visible;
        }
    } //-- end: DeltaPropertyData -----------------------------------------------------------------

    class DeltaEntityData extends Delta {
        constructor(shadowId, id, entdata = {}, action = 'Replace') {
            super(shadowId, 'NTAEntityData', id, action);
            this.EntityId = entdata.EntityId,
            this.Order = entdata.Order;
            this.Relevant = entdata.Relevant;
            this.Visible = entdata.Visible;
        }
    } //-- end: DeltaEntityData -------------------------------------------------------------------

    class DeltaRelationData extends Delta {
        constructor(shadowId, id, reldata = {}, action = 'Replace') {
            super(shadowId, 'NTAEntityRelationData', id, action);
            this.Parent = reldata.Parent;
            this.ParentEntityId = reldata.ParentEntityId;
            this.Child = reldata.Child;
            this.ChildEntityId = reldata.ChildEntityId;
            this.OnCopy = reldata.OnCopy;
            this.OnDelete = reldata.OnDelete;
        }
    } //-- end: DeltaRelationData -----------------------------------------------------------------


    /// == Exports ================================================================================

    Object.assign(self, {
        // -- methods --
        createFromUpdate,
        apply,
        getShadowBuildingData,

        // -- classes --
        Delta,
        DeltaEntityData,
        DeltaPropertyData,
        DeltaRelationData,
    });


    /// == Initialization =========================================================================

    /// Hier detecteren we als er we van formulier gewisseld zijn dat een schaduwomgeving
    ///  nodig heeft.
    $rootScope.$on('$routeChangeStart', onRouteChangeStart);
    $rootScope.$on('$routeChangeSuccess', onRouteChangeSuccess);


    /// == Implementation =========================================================================

    function createFromUpdate(updateData, shadowId) {
        const newDeltas = [];

        const shouldDelete = updateData.action.startsWith('delete');

        if (updateData.entityDatas && updateData.entityDatas.length) {
            newDeltas.push(...updateData.entityDatas.map(ed => createFromEntdata(ed, shadowId, shouldDelete)));
            if (shouldDelete || updateData.action.startsWith('add')) {
                newDeltas.push(...updateData.entityDatas.flatMap(ed => ed.PropertyDatas).map(pd => createFromPropdata(pd, shadowId, shouldDelete)));
            }
        }
        if (updateData.relationdatas && updateData.relationdatas.length) {
            newDeltas.push(...updateData.relationdatas.map(rd => createFromReldata(rd, shadowId, shouldDelete)));
        }
        if (updateData.propertyDatas && updateData.propertyDatas.length) {
            newDeltas.push(...updateData.propertyDatas.map(pd => createFromPropdata(pd, shadowId, shouldDelete)));
        }

        if (newDeltas.length === 0) {
            $log.error('Er konden geen delta’s worden aangemaakt op basis van deze updateData:', updateData);
        }

        // Samenvoegen met huidige deltas
        ntaData.mergeDeltas(shadowId, newDeltas);

        return newDeltas;
    } //-- end: createFromUpdate ------------------------------------------------------------------

    function createFromEntdata(entdata, shadowId, shouldDelete = false) {
        const original = ntaData.original.get(entdata.EntityDataId);
        // isUnchanged betekent dat er geen delta (meer) nodig is, en dus dat een evt. bestaande delta verwijderd moet worden.
        // Dit geven we aan door de ‘action’ van de delta op `null` te zetten.
        const isUnchanged = shouldDelete
            ? !original
            : !!original && ['Order', 'Relevant', 'Visible'].every(member => entdata[member] === original[member]);
        const action = isUnchanged ? null : shouldDelete ? 'Delete' : 'Replace';
        return new DeltaEntityData(shadowId, entdata.EntityDataId, entdata, action);
    } //-- end: createFromEntdata -----------------------------------------------------------------

    function createFromReldata(reldata, shadowId, shouldDelete = false) {
        const original = ntaData.original.getRelationById(reldata.EntityRelationDataId);
        // isUnchanged betekent dat er geen delta (meer) nodig is, en dus dat een evt. bestaande delta verwijderd moet worden.
        // Dit geven we aan door de ‘action’ van de delta op `null` te zetten.
        const isUnchanged = shouldDelete
            ? !original
            : !!original
                && ['Parent', 'ParentEntityId', 'Child', 'ChildEntityId'].every(member => reldata[member] === original[member])
                && ['OnCopy', 'OnDelete'].every(member => !!reldata[member] === !!original[member]);
        const action = isUnchanged ? null : shouldDelete ? 'Delete' : 'Replace';
        return new DeltaRelationData(shadowId, reldata.EntityRelationDataId, reldata, action);
    } //-- end: createFromReldata -----------------------------------------------------------------

    function createFromPropdata(propdata, shadowId, shouldDelete = false) {
        const originalEntdata = ntaData.original.get(propdata.EntityDataId);
        const original = originalEntdata && originalEntdata.PropertyDatas[propdata.PropertyId];
        // isUnchanged betekent dat er geen delta (meer) nodig is, en dus dat een evt. bestaande delta verwijderd moet worden.
        // Dit geven we aan door de ‘action’ van de delta op `null` te zetten.
        const isUnchanged = shouldDelete
            ? !original
            : !!original && ['Value', 'Touched', 'Relevant', 'Visible'].every(member => propdata[member] === original[member]);
        const action = isUnchanged ? null : shouldDelete ? 'Delete' : 'Replace';
        return new DeltaPropertyData(shadowId, propdata.PropertyDataId, propdata, action);
    } //-- end: createFromPropdata ----------------------------------------------------------------

    function apply(deltas, buildingData) {
        const appliedDeltas = [];
        const skippedDeltas = [];
        const deltasToDelete = [];

        // Delta-objecten verwerken in meegegeven schaduwkopie, in de juiste volgorde (zie `orderDeltas`).
        for (const delta of [...deltas].sort(orderDeltas)) {
            let applied = false;

            switch (delta.Action) {
                case 'Replace':
                    switch (delta.Table) {
                        case 'NTAEntityData': {
                            const entdata = buildingData.get(delta.Id);
                            if (entdata) {
                                // Entdata-eigenschappen overnemen
                                const entdataMembers = new Set(Object.keys(entdata));
                                for (const [member, value] of Object.entries(delta)) {
                                    if (value !== undefined && entdataMembers.has(member) && !_deltaSpecificProperties.has(member) && member !== 'EntityDataId') {
                                        applied = applied || value !== entdata[member];
                                        entdata[member] = value;
                                    }
                                }
                            } else {
                                // Nieuwe entdata aanmaken
                                const newEntdata = new EntityData(delta.EntityId, Object.assign({}, delta, { EntityDataId: delta.Id }));
                                buildingData.addEntdata(newEntdata, true);
                                applied = true;
                            }
                            break;
                        }
                        case 'NTAPropertyData': {
                            const entdata = buildingData.get(delta.EntityDataId);
                            if (entdata) {
                                const propdata = entdata.PropertyDatas[delta.PropertyId];
                                if (propdata) {
                                    // Propdata-eigenschappen overnemen
                                    const propdataMembers = new Set(Object.keys(propdata));
                                    for (const [member, value] of Object.entries(delta)) {
                                        if (value !== undefined && propdataMembers.has(member) && !_deltaSpecificProperties.has(member)) {
                                            applied = applied || value !== propdata[member];
                                            propdata[member] = value;
                                        }
                                    }
                                } else {
                                    $log.warn(`Kan delta niet toepassen, property niet gevonden op entiteit!`, delta, entdata);
                                    deltasToDelete.push(delta);
                                }
                            } else {
                                $log.warn(`Kan delta niet toepassen, entiteit van property niet gevonden!`, delta);
                                deltasToDelete.push(delta);
                            }
                            break;
                        }
                        case 'NTAEntityRelationData': {
                            const parent = buildingData.get(delta.Parent);
                            const child = buildingData.get(delta.Child);
                            if (parent && child) {
                                const reldata = buildingData.getRelationById(delta.Id);
                                if (reldata) {
                                    // Reldata-eigenschappen overnemen
                                    const reldataMembers = new Set(Object.keys(reldata));
                                    for (const [member, value] of Object.entries(delta)) {
                                        if (value !== undefined && reldataMembers.has(member) && !_deltaSpecificProperties.has(member)) {
                                            if (applied === false) {
                                                switch (member) {
                                                    case 'OnCopy':
                                                    case 'OnDelete':
                                                        applied = !!value !== !!reldata[member];
                                                        break;
                                                    default:
                                                        applied = value !== reldata[member];
                                                        break;
                                                }
                                            }
                                            reldata[member] = value;
                                        }
                                    }
                                } else {
                                    const newReldata = new RelationData(parent, child, delta.OnDelete, delta.OnCopy);
                                    buildingData.addReldata(newReldata, true);
                                    applied = true;
                                }
                            } else if (!buildingData.partial) {
                                /// Als buildingData partial is, dan bevat deze niet alle entdatas
                                ///  en reldatas, dus dan is het normaal dat er relaties zijn waar
                                ///  de parent of child niet bestaat. Maar we maken de relatie dan
                                ///  niet aan, want de rest van de code gaat uit van alleen valide
                                ///  relaties. Maar we loggen in dat geval even geen waarschuwing.
                                $log.warn(`Kan delta niet toepassen, relationdata verwijst naar niet-bestaande parent en/of child!`, delta, 'parent: ', parent, 'child: ', child);
                                deltasToDelete.push(delta);
                            }
                            break;
                        }
                        default: {
                            $log.error(`Onverwachte delta.table '${delta.Table}'!`, delta);
                            deltasToDelete.push(delta);
                            continue;
                        }
                    }
                    break;

                case 'Delete':
                    switch (delta.Table) {
                        case 'NTAEntityData':
                            applied = buildingData.removeEntdata(delta.Id).success;
                            break;

                        case 'NTAPropertyData':
                            // properties worden niet separaat verwijderd, maar met de NTAEntityData
                            break;

                        case 'NTAEntityRelationData':
                            applied = !!buildingData.removeReldata(delta.Id);
                            break;

                        default:
                            $log.error(`Onverwachte delta.table '${delta.Table}'!`, delta);
                            deltasToDelete.push(delta);
                            continue;
                    }
                    break;

                default:
                    $log.error(`Onverwachte delta.Action '${delta.Action}'!`, delta);
                    deltasToDelete.push(delta);
            }

            (applied ? appliedDeltas : skippedDeltas).push(delta);
        }

        return [appliedDeltas, skippedDeltas, deltasToDelete];
    } //-- end: apply -----------------------------------------------------------------------------

    function orderDeltas(a, b) {
        /// Deltas moeten zo gesorteerd worden dat er geen problemen ontstaan bij het toepassen.
        /// Dus bij Replace:
        ///   1. de EntityDatas, want die zijn nodig bij zowel PropertyDatas als RelationDatas
        ///   2. de PropertyDatas, dan kunnen die in de juiste entdata bijgewerkt worden
        ///   3. de RelationDatas, dan kunnen die verwijzen naar de juiste Parent en Child entdatas.
        /// Bij Delete is de volgorde precies andersom, zodat er geen loze verwijzingen ontstaan.
        /// We voeren eerst alle verwijderingen uit, en dan pas alle toevoegingen/vervangingen.
        /// Een delta op een specifieke entdata, propdata of reldata zou maar één keer moeten
        ///  voorkomen (zie ntaData.mergeDeltas om dat zo te houden).

        // eerst Delete, dan Replace
        let result = _collatorNL.compare(a.Action, b.Action);

        if (result === 0) {
            // dan, in geval van Replace, eerst EntityDatas, dan PropertyDatas, dan RelationDatas; of andersom in geval van Delete
            result = (_deltaTableOrder.get(a.Table) || Number.MAX_SAFE_INTEGER) - (_deltaTableOrder.get(b.Table) || Number.MAX_SAFE_INTEGER);
            if (a.Action === 'Delete') {
                result = -result;
            }
        }
        return result;
    } //-- end: orderDeltas -----------------------------------------------------------------------

    async function onRouteChangeStart(event, next, current) {
        let shadowId = null;
        const shadowEntdata = ntaData.original.get(next && next.params.shadowId);
        if (shadowEntdata) {
            const hasShadowId = shadowEntdata.EntityId === 'MEASURE' && _shadowMeasureTypes.has(shadowEntdata.PropertyDatas['MEASURE_TYPE'].Value);
            if (hasShadowId) {
                shadowId = shadowEntdata.EntityDataId;
            }
        }

        // alvast switchen naar schaduwomgeving.
        if (shadowId !== ntaData.current.shadowId) {

            if (!current) {

                // Dit is de eerste route, dus kunnen we direct switchen
                switchToShadow(shadowId);

            } else {
                // Er is al een bestaande route, dus moeten we dit event annuleren, wachten tot
                //  alle eventuele validaties zijn afgerond, dan switchen, en opnieuw navigeren.
                const targetPath = $location.path();

                event.preventDefault();

                // Zorg dat het formulier blank is (evt. met voortgang) zodat er niet per ongeluk
                //  gegevens van de nieuwe omgeving in het oude formulier weergegeven wordt
                //  (en er potentieel verkeerde validaties uitgevoerd worden).
                ntaData.switching = true;

                await time.whenDelayedActionsDone();
                switchToShadow(shadowId);

                $location.path(targetPath);

                // ntaData.switching wordt pas weer op false gezet als deze routewijziging
                //  met succes is voltooid (zie onRouteChangeSuccess); anders wordt het vorige
                //  formulier nog geladen, maar dan met de $queryparams van het nieuwe formulier.
            }

        }
    } //-- end: onRouteChangeStart ----------------------------------------------------------------

    function switchToShadow(shadowId) {
        $log.debug('switching to shadow', shadowId, 'from shadow', ntaData.current.shadowId);
        if (shadowId) {
            projecttree.expanded.measures = true;
            projecttree.expanded['sm_' + shadowId] = true;
        }

        const applyDeltas = ntaData.switchToShadow(shadowId);
        if (applyDeltas) {
            const deltas = (ntaData.deltas.get(shadowId) || new Map()).values();
            apply(deltas, ntaData.shadow);
        }
    } //-- end: switchToShadow --------------------------------------------------------------------

    function onRouteChangeSuccess(event, current, previous) {

        if (current) {

            // Als de navigatie echt succesvol was, dan kan het formulier normaal weergegeven worden.
            ntaData.switching = false;

        }

    } //-- end: onRouteChangeSuccess --------------------------------------------------------------

    /// Deze functie geeft een BuildingData voor de opgegeven `shadowId`.
    /// Als de opgegeven `shadowId` overeenkomt met de actieve schaduwkopie, dan wordt die
    ///  teruggegeven; anders wordt een (tijdelijke) kloon gemaakt van de basisberekening, en
    ///  worden daarop alle deltas toegepast die horen bij de `shadowId`.
    /// Wanneer `entityIds` is opgegeven, dan worden bij het klonen alleen de overeenkomende
    ///  entdata’s en reldata’s meegenomen, en ook alleen de betreffende delta’s toegepast.
    /// Wanneer `deltas` is opgegeven, dan worden deze delta’s gebruikt in plaats van de delta’s
    ///  die aanwezig zijn in `ntaData.deltas.get(shadowId)`.
    /// LET OP: als shadowId een variantId is, dan moeten de delta’s expliciet gegenereerd worden,
    ///  en aan deze functie meegegeven! Zie (opmerkingen bij) ntaSharedLogic.getVariantBuildingData().
    function getShadowBuildingData(shadowId, entityIds = null, deltas = null) {
        if (shadowId === null && !deltas) {
            // Geen kloon nodig als de basisberekening gevraagd wordt
            return ntaData.original;
        } else if (ntaData.shadow && ntaData.shadow.shadowId === shadowId && !deltas) {
            // Geen kloon nodig als we al een volledige schaduwkopie hebben
            return ntaData.shadow;
        } else {
            // Anders moeten we een tijdelijke kloon maken

            // We filteren standaard alle resultaatentiteiten eruit; die hebben alleen betekenis in de basisberekening(!)
            const exceptEntityIds = entityIds ? null : ntaData.entities.filter(entity => entity.Id === 'PRESTATIE' || entity.Id.startsWith('RESULT-')).map(e => e.Id);
            const buildingData = ntaData.original.clone({ shadowId, entityIds, exceptEntityIds });
            let propertyIds;
            if (entityIds) {
                propertyIds = new Set(entityIds.flatMap(entityId => ntaData.properties[entityId].map(p => p.Id)));
                entityIds = new Set(entityIds);
            }
            let allDeltas = deltas;
            if (!allDeltas) {
                allDeltas = ntaData.deltas.get(shadowId);
                allDeltas = allDeltas && allDeltas.values();
            }
            if (allDeltas) {
                const filteredDeltas = Array.from(allDeltas)
                    .filter(delta => !entityIds || entityIds.has(delta.EntityId) || propertyIds.has(delta.PropertyId) || entityIds.has(delta.ParentEntityId) || entityIds.has(delta.ChildEntityId));
                apply(filteredDeltas, buildingData);
            }
            return buildingData;
        }
    } //-- end: getShadowBuildingData ---------------------------------------------------------

}]);
