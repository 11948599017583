﻿'use strict';
angular.module('projectModule').controller("ProjectAttrController", ['projectattributes', 'projecttree', 'berekeningstypes', '$http', '$timeout', function (projectattributes, projecttree, berekeningstypes, $http, $timeout) {

    var defNewName = "Nieuwe folder";
    var defNewType = "W";

    var vm = this;

    vm.tree = projecttree.folders;
    vm.types = berekeningstypes.types;
    vm.newFolderName = defNewName;
    vm.newBerekeningsTypeId = defNewType;

    vm.ProjectName = projectattributes.name;
    vm.saveProjectName = function () {
        projectattributes.name = vm.ProjectName;
        $http.post("Projects/SaveProjectName?Id=" + projectattributes.id + "&Name=" + vm.ProjectName);
        SetProjectInfoMainHeader(projectattributes.id, vm.ProjectName);
    };

    vm.ProjectCity = projectattributes.plaats;
    vm.saveProjectCity = function () {
        projectattributes.plaats = vm.ProjectCity;
        $http.post("Projects/SaveProjectCity?Id=" + projectattributes.id + "&City=" + vm.ProjectCity);
    };

    vm.ProjectDescr = projectattributes.omschrijving;
    vm.saveProjectDescr = function () {
        projectattributes.omschrijving = vm.ProjectDescr;
        $http.post("Projects/SaveProjectDescr?Id=" + projectattributes.id + "&Descr=" + vm.ProjectDescr);
    };

    vm.saveFolder = function (id) {
        var folderdata = projecttree.folders.filter(function (x) { return x.FolderId === id; })[0];
        var notValidText = $http({
            method: "post",
            url: "Projects/SaveFolderData",
            data: JSON.stringify(folderdata),
            dataType: "json"
        });
    };

    vm.addFolder = function () {
        $http.post("Projects/AddFolder?ProjectId=" + projectattributes.id + "&Name=" + vm.newFolderName + "&Type=" + vm.newBerekeningsTypeId)
           .then(function succes(response) {
                if (response.data !== null && response.data !== "" && typeof response.data !== "undefined") {
                    projecttree.folders.push(response.data);
                    $timeout(function () {
                        $("#folder" + response.data.FolderId).focus();
                        $("#folder" + response.data.FolderId).select();
                    }, 100);
                    $('#addFldModal').modal('hide');
                    // en weer de default waarden zetten voor een eventuele volgende
                    vm.newFolderName = defNewName;
                    vm.newBerekeningsTypeId = defNewType;
                }
            }, function error() {
                return null;
            });
    };

    vm.deleteFolder = function (Id) {
        if (Id == null) {
            return;
        }

        $http.post("Projects/DeleteFolder?Id=" + Id);

        //-- check of selectedBuilding in deze folder zit, zoja dan selected building op -1 zetten
        if (projecttree.folderid == Id) {
            projecttree.buildingid = -1;
            SetBuildingInfoBENGbalk("");
        }
        projecttree.deleteFolderInTree(Id);

    };

}]);