﻿angular.module('projectModule')
    .service('time',
        ['$log',
function ($log) {
    'use strict';
    const self = this;

    /// == Description ============================================================================

    /// Deze service is bedoeld voor tijdgebonden acties.


    /// == Imports ================================================================================

    // ...


    /// == Instance variables =====================================================================

    const _delayedActions = new Set();


    /// == Exports ================================================================================

    Object.assign(self, {
        // -- methods --
        delay,
        delayActions,
        whenDelayedActionsDone,
    });


    /// == Initialization =========================================================================

    // ...


    /// == Implementation =========================================================================

    /// await deze functie om een bepaald aantal millisecondes te wachten
    function delay(ms = 0) {
        return new Promise(resolve => {
            setTimeout(resolve, ms);
        });
    } //-- end: delay -----------------------------------------------------------------------------

    /// Gebruik deze functie (vergelijkbaar met setTimeout) om validaties uit te voeren
    ///  buiten de AngularJS-digest cycle, maar op dusdanige manier dat de wijziging wel afgewacht
    ///  kan worden eer we van omgeving wisselen.
    function delayActions(procedure, ms = 0) {
        const promise = new Promise((resolve, reject) => {
            setTimeout(() => {
                try {
                    const result = procedure();
                    resolve(result);
                } catch (err) {
                    reject(err);
                } finally {
                    _delayedActions.delete(promise);
                }
            }, ms);
        });
        _delayedActions.add(promise);
        return promise;
    } //-- end: delayActions ----------------------------------------------------------------------

    async function whenDelayedActionsDone(options = { stopAfterMs: 0, stopAfterIterations: 0 }) {
        const startedAt = performance.now();
        let iterations = 0;

        while (_delayedActions.size > 0) {

            if (options) {
                if (options.stopAfterMs && options.stopAfterMs <= (performance.now() - startedAt))
                    throw new Error(`${whenDelayedActionsDone.name} timed out after ${performance.now() - startedAt} ms.`);
                else if (options.stopAfterIterations && options.stopAfterIterations < iterations++) {
                    throw new Error(`${whenDelayedActionsDone.name} exceeded ${iterations - 1} iterations.`);
                }

            }

            await Promise.allSettled(_delayedActions);
        }
    } //-- end: afterDelayedActions ---------------------------------------------------------------

}]);
