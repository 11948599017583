﻿angular.module('projectModule')
    .controller('MeasureZonweringController',
        ['$scope', '$controller', '$routeParams', 'ntaValidation', 'ntaMeasure',
function ($scope,   $controller,   $routeParams,   ntaValidation,   ntaMeasure) {
    'use strict';
    const ctrl = this;
    const base = $controller('NTABaseController', { $scope: $scope })
    angular.extend(ctrl, base);

    //== Imports ==================================================================================
    ctrl.ntaValidation = ntaValidation;


    //== Instance data ============================================================================
    const logic = ntaMeasure.getLogic($routeParams.shadowId);

    const _subtitleByPropId = new Map(Object.entries({
        'MEASURE-ZONW_BEGR_IDS': 'Plaats (of vervang) zonwering bij ramen met de volgende kenmerken',
        'MEASURE-ZONW_TYPE': 'Type zonwering',
        'MEASURE-ZONW_KOSTEN_INV': 'Kosten',
    }));


    //== Exports ==================================================================================
    ctrl.logic = logic;
    ctrl.properties = logic.properties;
    ctrl.entdata = logic.entdataMeasureZonw;

    ctrl.isReadOnly = logic.isReadOnly;
    ctrl.isHidden = logic.isHidden;
    ctrl.getCodedValues = logic.getCodedValues;
    ctrl.isItemChecked = logic.isItemChecked;
    ctrl.toggleItemChecked = logic.toggleItemChecked;

    ctrl.getForm = () => logic.form;
    ctrl.getPropData = prop => base.getPropData(prop, logic.entdataMeasureZonw);
    ctrl.getPropertyType = prop => prop && prop.PropertyType;

    Object.assign(ctrl, {
        getSubtitle,
        saveValue,
    });

    $scope.endFormValidation = logic.endFormValidation;


    //== Initialization ===========================================================================

    ntaMeasure.setBtwPropIds(ctrl);

    angular.element(function () { //alle touched properties valideren na het laden van form
        logic.startFormValidation();
        logic.setGeopend();
    });

    //== Implementation ===========================================================================

    function getSubtitle(prop) {
        return _subtitleByPropId.get(prop.Id) || '';
    } //-- end: getSubtitle -----------------------------------------------------------------------

    function saveValue(prop) {
        logic.saveValue(prop, logic.entdataMeasureZonw);
        base.initializeUnitSpacing();
    } //-- end: saveValue -------------------------------------------------------------------------

}]);
