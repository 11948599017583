angular.module('indexModule')
    .controller("IndexBerekeningenController",
        ['indextree', 'progressCircle', 'settingsMenuData', "activeBuildingService", "folderService", '$scope', '$http', '$routeParams', '$filter', '$mdDialog', 'ntaRounding', '$log', 'ntaVersions', 'ntaAlert', 'HubConnection',
function (indextree,   progressCircle,   settingsMenuData,   activeBuildingService,   folderService,   $scope,   $http,   $routeParams,   $filter,   $mdDialog,   ntaRounding,   $log,   ntaVersions,   ntaAlert,  HubConnection) {
    'use strict';
    const vm = this;

    vm.calcTypes = indextree.buildingTypes
        .filter(bt => indextree.modules.includes(bt.FilterValue1))
        .map(bt => ({
            "type": bt.Id,
            "img": bt.ImageUrl,
            "text": bt.Value,
            "hasborder": bt.FilterValue4 === "border",
        }));

    vm.IsDeveloper = function () {
        return indextree.isdev;
    };

    vm.commit = function () {
        return indextree.commit;
    };

    vm.activeBuilding = indextree.activeBuilding;
    vm.hasMeldingen = indextree.hasMeldingen;
    vm.isCalcNeeded = indextree.isCalcNeeded;
    vm.isMaatwerkadvies = indextree.isMaatwerkadvies;

    vm.types = indextree.types;
    vm.newBerekeningsTypeId = -1;

    vm.folderId = parseInt($routeParams.id);

    const _folder = indextree.foldersById.get(vm.folderId) || null;

    vm.folder = function () {
        return _folder;
    };

    vm.foldernaam = function () {
        var fld = vm.folder();
        return (fld && fld.Name) || "";
    };

    vm.buildingResultClicked = async function (building) {
        vm.activeBuilding = building.BuildingId;

        await activeBuildingService.setActiveBuilding(building.BuildingId);
        let cachedFolder = indextree.foldersById.get(building.FolderId) || indextree.folders.filter(f => f.ParentId === building.FolderId);

        if (cachedFolder.Project.AantalBerekeningen > cachedFolder.Project.Berekeningen.length) {
            try {
                progressCircle.setShowProgressValue(true, 'berekeningenlijst ophalen...', false);
                try {
                    const updatedFolder = await folderService.getFolderById(building.FolderId);
                    cachedFolder.Project.Berekeningen = updatedFolder.Project.Berekeningen;
                } finally {
                    progressCircle.setShowProgressValue(false);
                }
            } catch (err) {
                const alert = $mdDialog.alert()
                    .title('Kan berekeningen niet laden')
                    .textContent('Het is niet gelukt om de lijst met berekeningen in deze map te laden. Probeer het zo nog een keer.')
                    .ok('Sluiten');
                await $mdDialog.show(alert);
            }
        }
        folderService.setActiveFolder(building.FolderId);
        vm.folderId = building.FolderId;
    }

    vm.project = function () {
        var fld = vm.folder();
        if (fld && (fld.Project.ProjectId > -1 || fld.Project.ProjectId === -2)) {
            return fld.Project;
        }
        return null;
    };

    vm.getBerekeningenOfProject = function() {
        const prj = vm.project();
        return prj && prj.Berekeningen.filter(bld => !!bld.DeleteDate === !!prj.DeleteDate) || [];
    }

    vm.projectnaam = vm.foldernaam();
    if (vm.project()) {
        vm.projectnaam = vm.project().Name;
    }

    vm.berekeningnaam = function (bldId) {
        if (vm.project()) {
            return vm.project().Berekeningen.find(function (x) { return x.BuildingId === bldId; }).Name;
        } else { return ""; }
    };

    vm.subFolders = _folder?.Subfolders || [];

    vm.projectInFolder = function () {
        const project = vm.project();
        return !!project && project.ProjectId !== -1;
    };

    vm.isProjectDeleted = function () {
        const project = vm.project();
        return !!project && !!project.DeleteDate;
    }

    vm.addBerekening = async function (type) {
        progressCircle.setShowProgressValue(true, 'aanmaken nieuwe berekening');
        try {
            const ntaVersion = await ntaVersions.getActiveVersionAsync();
            const data = await indextree.createBerekening(vm.folderId, type, ntaVersion && ntaVersion.version);
            window.location.href = `/Project/${data.projectId}/Berekening/${data.buildingId}/Algemene_gegevens`;
        } catch (ex) {
            progressCircle.setShowProgressValue(false);
            $log.error(ex);
            alert("Er heeft zich een probleem voorgedaan.");
        }
    };

    vm.openBerekening = async function (berekening, event) {
        //-- Niets doen als we de naam aan het wijzigen zijn
        if (!$("#berekeningnaam" + berekening.BuildingId).hasClass("showname")) {
            return;
        }

        //-- Voorkom dat de link te vroeg al geopend wordt
        event.stopImmediatePropagation();
        event.preventDefault();

        if (event && event.currentTarget && event.currentTarget.classList) {
            if (event.currentTarget.classList.contains('noclick')) {
                return;
            }
        }

        if (this.isBerekeningDeleted(berekening)) {
            const alert = $mdDialog.alert()
                .title('Kan verwijderde berekening niet openen')
                .textContent('Verwijderde berekening kan niet ingezien of bewerkt worden. Zet deze terug naar een actieve map om ermee te werken.')
                .ok('Sluiten');
            await $mdDialog.show(alert);
            return;
        }

        if (!vm.calcTypes.map(x => x.type).includes(berekening.Results.GEB_TYPEGEB)) { // geen geldige licentie
            if (berekening.Results.GEB_TYPEGEB === "TGEB_UTILIT" || berekening.Results.GEB_TYPEGEB === "TGEB_UTILUNIT") {
                indextree.warning('[W047]');
                return;
            } else {
                indextree.warning('[W046]');
                return;
            }
        }

        //-- Forceer blur voor projectnaam om deze op te slaan, voordat de nieuwe pagina getoond wordt.
        const projectNameElement = document.getElementById('projectnaam');
        if (projectNameElement) {
            angular.element(projectNameElement).blur();
        }

        const ntaVersionId = await ntaVersions.canOpenWithVersionIdAsync([berekening]);
        if (ntaVersionId) {
            const ntaVersion = ntaVersions.getVersionById(ntaVersionId);

            const canContinue = await upgradeBuildings([berekening.BuildingId], ntaVersion && ntaVersion.version || ntaVersionId);
            if (!canContinue) {
                return;
            }

            progressCircle.setShowProgressValue(true, 'openen berekening', false);

            const params = new URLSearchParams();
            params.set('v', ntaVersion && ntaVersion.version || ntaVersionId);
            const connectionId = await progressCircle.startConnection();
            if (connectionId) {
                progressCircle.setShowProgressValue(true, 'openen berekening...', true);
                params.set('connectionId', connectionId);
            }
            window.location.href = `/Project/${berekening.ProjectId}/Berekening/${berekening.BuildingId}/Algemene_gegevens?${params}`;
        }
    };

    async function upgradeBuildings(buildingIds, targetVersion) {
        try {
            const connection = new HubConnection('/hubs/upgrader', {
                progressText: 'openen berekening',
            });
            const results = await connection.run("UpgradeBuildings", buildingIds, targetVersion);

            const warnings = new Set(results.flatMap(x => x.warnings));
            for (const warning of warnings) {
                await indextree.warning(warning);
            }

            if (results.some(x => x.error)) {
                await ntaAlert.showError(); // TODO: alle berekeningen weergeven die niet geüpgraded konden worden
            }

            return results.some(x => x.upgraded) || results.length === 0;
        } catch (err) {
            $log.error(err, 'while upgrading', buildingIds, 'to', targetVersion);
            await ntaAlert.showError();
            return false;
        }
    }

    vm.clickBerekening = function (building, event) {
        event.stopImmediatePropagation();
        event.preventDefault();
        building.selected ? deselecteerBerekening(building) : selecteerBerekening(building);
    };

    function getSelectedBerekeningen(defaultBerekening) {
        const berekeningen = vm.getBerekeningenOfProject()
            .filter(bld => bld.selected);
        if (berekeningen.length === 0 && defaultBerekening) {
            berekeningen.push(defaultBerekening);
        }
        return berekeningen;
    };

    function selecteerBerekening(building) {
        building.selected = true;
        activeBuildingService.setActiveBuilding(building.BuildingId);
        vm.activeBuilding = building.BuildingId;
    };

    function deselecteerBerekening(building) {
        building.selected = false;
        const selectedBerekeningen = getSelectedBerekeningen();
        const activeBuilding = selectedBerekeningen[selectedBerekeningen.length - 1];
        if (activeBuilding) {
            activeBuildingService.setActiveBuilding(activeBuilding.BuildingId);
            vm.activeBuilding = activeBuilding.BuildingId;
        }
    };

    vm.selecteerBerekeningen = function () {
        for (const berekening of vm.getBerekeningenOfProject().filter(bld => !bld.selected)) {
            selecteerBerekening(berekening);
        }
    };

    vm.deselecteerBerekeningen = function () {
        for (const berekening of vm.getBerekeningenOfProject().filter(bld => bld.selected)) {
            deselecteerBerekening(berekening);
        }
    };

    // Bij openen folder alles deselecteren
    vm.deselecteerBerekeningen();

    vm.multiSelect = function () {
        return vm.getBerekeningenOfProject().some(bld => bld.selected);
    };

    vm.selectedInfo = function () {
        return getSelectedBerekeningen().length + " van " + vm.getBerekeningenOfProject().length + " geselecteerd"
    }


    vm.openMenu = function ($mdMenu, ev, bld) {
        $mdMenu.open(ev);

        ev.stopImmediatePropagation();
        ev.preventDefault();

        activeBuildingService.setActiveBuilding(bld.BuildingId);
        vm.activeBuilding = bld.BuildingId;

        if (vm.multiSelect()) {
            bld.selected = true;
        }
    };

    const _menuItems = [
        {
            text: 'naam wijzigen',
            icon: 'create',
            isVisible: building => vm.berekeningAccessible(building) && !building.Afgemeld && !building.Locked && !building.DeleteDate && !vm.multiSelect(),
            onClick: (building, event) => vm.editBerekeningNaam(building.BuildingId, event),
        },
        {
            text: 'kopiëren',
            icon: 'file_copy',
            isVisible: building => canCopy(building) && !building.DeleteDate,
            onClick: building => vm.copyClick(building.BuildingId),
        },
        {
            text: 'kopiëren voor oplevering',
            icon: 'file_copy',
            isVisible: building => canCopy(building, 'Delivery') && !building.DeleteDate,
            onClick: building => vm.copyClick(building.BuildingId, 'Delivery'),
        },
        {
            text: 'kopiëren voor vervangen',
            icon: 'file_copy',
            isVisible: building => canCopy(building, 'Replacement') && !building.DeleteDate,
            onClick: building => vm.copyClick(building.BuildingId, 'Replacement'),
        },
        {
            text: 'kopiëren voor herlabelen',
            icon: 'file_copy',
            isVisible: building => canCopy(building, 'Relabeling') && !building.DeleteDate,
            onClick: building => vm.copyClick(building.BuildingId, 'Relabeling'),
        },
        {
            text: 'verplaatsen',
            icon: 'exit_to_app',
            isVisible: building => canCopy(building),
            onClick: building => vm.moveClick(building.BuildingId),
        },
        {
            text: 'verwijderen',
            icon: 'delete_outline',
            isVisible: building => !building.Locked && !building.DeleteDate,
            onClick: building => vm.deleteClick(building),
        },
        {
            getText: building => getAfmeldText(building),
            icon: 'assignment_turned_in',
            isVisible: building => vm.berekeningAccessible(building) && indextree.licencetype !== 'TRIAL' && !building.Afgemeld && !building.Locked && !building.DeleteDate && !vm.multiSelect(),
            onClick: building => vm.afmeldenClick(building),
        },
        {
            text: 'vergrendelen',
            icon: 'lock',
            isVisible: building => vm.berekeningunlocked(building) && !building.Afgemeld && !building.DeleteDate,
            isVisible: building => vm.isUnlocked(building),
            onClick: building => vm.lockClick(building.BuildingId, true),
        },
        {
            text: 'ontgrendelen',
            icon: 'lock_open',
            isVisible: building => vm.isLocked(building) && !building.DeleteDate,
            onClick: building => vm.lockClick(building.BuildingId, false),
        },
        {
            text: 'energielabel',
            icon: 'class',
            isVisible: building => vm.hasEnergielabel(building) && !building.DeleteDate,
            onClick: building => vm.downloadLabelClick(building.BuildingId),
        },
        {
            getText: building => getExportText(building),
            icon: 'archive',
            isVisible: building => canExport(building),
            onClick: building => vm.exportClick(building.BuildingId, false),
        },
        {
            text: 'exporteren met energielabels',
            icon: 'archive',
            isVisible: building => canExport(building, 'labels'),
            onClick: building => vm.exportClick(building.BuildingId, true),
        },
        {
            text: 'rapportage maken',
            icon: 'description',
            isVisible: building => vm.berekeningAccessible(building) && indextree.licencetype !== "TRIAL" && !building.DeleteDate,
            onClick: building => vm.createPDFRapport(building),
        },
        //{   // Tijdelijk uitgekomment voor release 1 juli'24. Wordt vanaf aug'24 verder opgepakt
        //    text: 'overzicht exporteren (excel)',
        //    icon: 'filter_frames',
        //    isVisible: building => vm.berekeningAccessible(building) && indextree.licencetype !== "TRIAL" && !building.DeleteDate,
        //    onClick: building => vm.createExcelRapport(building),
        //},
        {
            text: 'resultaten exporteren',
            icon: 'backup_table',
            isVisible: building => vm.berekeningAccessible(building) && indextree.licencetype !== "TRIAL" && !vm.multiSelect() && !building.DeleteDate,
            onClick: building => vm.exportCSVClick(building.BuildingId),
        },
        {
            text: 'debugresultaten',
            icon: 'pest_control',
            isVisible: building => indextree.isdev && !vm.multiSelect() && !building.DeleteDate,
            onClick: building => {
                const link = document.createElement('a');
                link.href = `/Project/${building.ProjectId}/Berekening/${building.BuildingId}/calc`;
                link.target = '_blank';
                link.click();
            },
        },
    ];

    vm.getMenuItems = function (building) {
        return _menuItems
            .filter(item => !item.isVisible || item.isVisible(building));
    };

    vm.menuItemClick = function (item, building, event) {
        item.onClick(building, event);
    };

    vm.isBerekeningDeleted = function (berekening) {
        return !!berekening.DeleteDate;
    };

    vm.isProjectwoning = function (berekening) {
        return berekening.Results.RZFORM_CALCUNIT === "RZUNIT_PROJECT";
    }


    vm.formatDate = function (date) {
        if (date) {
            return moment(date).format('DD-MM-YYYY HH:mm');
        }
        return "";
    };

    function canCopy(berekening, purpose) {
        if (!vm.berekeningAccessible(berekening))
            return false;

        const selectedBerekeningen = getSelectedBerekeningen(berekening);

        if (purpose === 'Delivery') { // kopiëren voor oplevering
            return selectedBerekeningen.every(bld => bld.Afgemeld && !vm.isMaatwerkadvies(bld) && bld.Results.AFM_AANLEIDING === 'AFM_AANL_AANVR');
        } else if (purpose === 'Replacement') { // kopiëren voor vervangen
            return selectedBerekeningen.every(bld => bld.Afgemeld && !vm.isMaatwerkadvies(bld));
        } else if (purpose === 'Relabeling') { // kopiëren voor herlabelen
            // berekening is afgemeld en A24=oplevering nieuw gebouw / bestaande bouw EN ge3-1
            return selectedBerekeningen.every(bld => bld.Afgemeld
                && !vm.isMaatwerkadvies(bld)
                && ['AFM_AANL_OPLVNB', 'AFM_AANL_BESTB'].includes(bld.Results.AFM_AANLEIDING)
                && bld.NTAVersionId >= 100
            );
        } else {
            if (purpose) $log.warn('Onverwachte reden tot kopiëren: ‘', purpose, '’ bij het kopiëren van ', selectedBerekeningen);
            return true; // kopiëren
        }
    }

    function canExport(berekening, type) {
        const selectedBerekeningen = getSelectedBerekeningen();
        if (selectedBerekeningen.length === 0) { selectedBerekeningen.push(berekening) };

        if (selectedBerekeningen.some(building => !!building.DeleteDate || !vm.berekeningAccessible(building)) || indextree.licencetype === "TRIAL" ) {
            return false;
        }

        if (!type) { // exporteren
            return true;
        } else if (type === 'labels') { // exporteren met labels
            return selectedBerekeningen.some(building => building.Afgemeld && !vm.isMaatwerkadvies(building));
        }
    };

    function getExportText(berekening) {
        const selectedBerekeningen = getSelectedBerekeningen(berekening);
        return selectedBerekeningen.some(x => x.Afgemeld && !vm.isMaatwerkadvies(x))
            ? 'exporteren zonder energielabels'
            : 'exporteren naar bestand';
    }

    function getAfmeldText(berekening) {
        const selectedBerekeningen = getSelectedBerekeningen(berekening);
        return selectedBerekeningen.some(x => vm.isMaatwerkadvies(x))
            ? 'afmelden maatwerkadvies'
            : 'afmelden';
    }

    vm.copyClick = async function (bldId, purpose) {
        if (!bldId) {
            return;
        }

        const selectedBerekeningenIds = getSelectedBerekeningen().map(x => x.BuildingId);
        if (selectedBerekeningenIds.length === 0) { selectedBerekeningenIds.push(bldId) };

        progressCircle.setShowProgressValue(true, 'kopiëren berekening', true);
        try {
            await indextree.copyBerekening(vm.folderId, selectedBerekeningenIds, purpose);
        } finally {
            progressCircle.setShowProgressValue(false);
        }
    };

    vm.moveClick = async function (bldId) {
        if (!bldId) {
            return;
        }

        $mdDialog.show({
            controller: $scope => $scope.buildingId = bldId,
            templateUrl: '/src/app/project/moveItem.html?v=' + indextree.commit,
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        });
    };

    vm.deleteClick = async function (building) {
        const selectedBerekeningen = getSelectedBerekeningen(building);
        if (!selectedBerekeningen.length)
            return;

        const title = 'Verwijderen berekening' + (selectedBerekeningen.length === 1 ? '' : 'en');
        const description = selectedBerekeningen.length === 1
            ? `berekening "${selectedBerekeningen[0].Name}"`
            : `${selectedBerekeningen.length} berekeningen`
        const confirm = $mdDialog.confirm()
            .title(title)
            .textContent(`U staat op het punt om ${description} te verwijderen. Weet u het zeker?`)
            .ariaLabel('Verwijderen')
            .ok('Ja')
            .cancel('Nee');

        try {
            await $mdDialog.show(confirm);
        } catch (err) {
            return; // cancel
        }
        progressCircle.setShowProgressValue(true, title.toLowerCase());
        await indextree.deleteBerekeningen(vm.folderId, selectedBerekeningen);
        progressCircle.setShowProgressValue(false);
    };

    vm.isLocked = function (bld) {
        const selectedBerekeningen = getSelectedBerekeningen();
        if (selectedBerekeningen.length === 0) { selectedBerekeningen.push(bld) };
        return selectedBerekeningen.some(bld => bld.Locked) && selectedBerekeningen.every(bld => !bld.Afgemeld) ; // als bld is afgemeld is hij ook vergrendeld -> locked is dan false, anders krijg je meerdere icoontjes
    };

    vm.isUnlocked = function (bld) {
        const selectedBerekeningen = getSelectedBerekeningen();
        if (selectedBerekeningen.length === 0) { selectedBerekeningen.push(bld) };
        return selectedBerekeningen.some(bld => !bld.Locked) && selectedBerekeningen.every(bld => !bld.Afgemeld); // als bld is afgemeld is hij ook vergrendeld -> locked is dan false, anders krijg je meerdere icoontjes
    };

    vm.berekeninglocked = function (bld) {
        return bld.Locked && !bld.Afgemeld; // als bld is afgemeld is hij ook vergrendeld -> locked is dan false, anders krijg je meerdere icoontjes
    };

    vm.berekeningunlocked = function (bld) {
        return !bld.Locked || bld.Afgemeld; // als bld is afgemeld is hij ook vergrendeld -> locked is dan false, anders krijg je meerdere icoontjes
    };

    vm.berekeningAccessible = function (bld) {
        if (bld) {
            return vm.calcTypes.map(x => x.type).includes(bld.Results.GEB_TYPEGEB);
        }
        return false;
    };

    vm.getVersion = function (bld) {
        const ntaVersion = ntaVersions.getVersionById(bld.NTAVersionId);
        return ntaVersion && ntaVersion.version || '--';
    };

    vm.hasEnergielabel = function (bld) {

        const selectedBerekeningen = getSelectedBerekeningen();
        if (selectedBerekeningen.length === 0) { selectedBerekeningen.push(bld) };

        return selectedBerekeningen.some(bld => bld.Afgemeld && !vm.isMaatwerkadvies(bld) && !(bld.Results.GEB_TYPEGEB === "TGEB_APPGEB" && bld.Results.RZFORM_CALCUNIT === "RZUNIT_GEB"));
    };

    vm.lockClick = async function (bldId, lock) {
        const selectedBerekeningenIds = getSelectedBerekeningen().map(x => x.BuildingId);
        if (selectedBerekeningenIds.length === 0) {
            if (!bldId) {
                return;
            } else {
                selectedBerekeningenIds.push(bldId);
            }
        }
        try {
            await indextree.lockBerekening(vm.folderId, selectedBerekeningenIds, lock);
        } catch (err) {
            $log.error(`Oeps in lockBerekening(${selectedBerekeningenIds}, ${lock}):`, err);
            await ntaAlert.showError();
        }
    };


    vm.afmeldenClick = async function (berekening) {
        if (!berekening) {
            return;
        }
        if (!vm.projectnaam || vm.projectnaam.length === 0) { // het project heeft geen omschrijving
            const contrl = vm.form_projName['projectnaam'];
            if (contrl) {
                contrl.$setValidity("required", false);
                contrl.$touched = true;
            }
            indextree.warning('[W052]');
            return;
        }

        try {
            if (await indextree.canStartRegistration(null, berekening.BuildingId)) {
                const ntaVersionId = await ntaVersions.canOpenWithVersionIdAsync([berekening], true);
                if (ntaVersionId) {
                    if (ntaVersionId !== berekening.NTAVersionId) {
                        progressCircle.setShowProgressValue(true, 'openen berekening', false);
                        const params = new URLSearchParams();
                        const ntaVersion = ntaVersions.getVersionById(ntaVersionId);
                        params.set('v', ntaVersion && ntaVersion.version || ntaVersionId);
                        const connectionId = await progressCircle.startConnection();
                        if (connectionId) {
                            progressCircle.setShowProgressValue(true, 'openen berekening...', true);
                            params.set('connectionId', connectionId);
                        }
                        window.location.href = `/Project/${berekening.ProjectId}/Berekening/${berekening.BuildingId}/Algemene_gegevens?${params}`;
                    } else {
                        progressCircle.setShowProgressValue(true, 'afmeldgegevens laden...', false);
                        window.location.href = "/Folder/" + vm.folderId + "/Berekening/" + berekening.BuildingId + "/Afmelden";
                    }
                }
            }
        } catch (err) {
            progressCircle.setShowProgressValue(false);
            $log.error(err, 'bij controles voor afmelden BuildingId', berekening.BuildingId);

            await ntaAlert.showError();
        }
    };

    vm.downloadLabelClick = async function (bldId) {
        if (!bldId) {
            return;
        }
        const selectedBerekeningenIds = getSelectedBerekeningen().map(x => x.BuildingId);
        if (selectedBerekeningenIds.length === 0) { selectedBerekeningenIds.push(bldId) };

        progressCircle.setShowProgressValue(true, 'downloaden energielabel', false);
        try {
            await indextree.downloadLabelBuilding(selectedBerekeningenIds);
        } finally {
            progressCircle.setShowProgressValue(false);
        }
    };

    vm.exportClick = async function (bldId, includingLabels) {
        if (!bldId) {
            return;
        }

        const selectedBerekeningenIds = getSelectedBerekeningen().map(x => x.BuildingId);
        if (selectedBerekeningenIds.length === 0) { selectedBerekeningenIds.push(bldId) };

        progressCircle.setShowProgressValue(true, 'exporteren berekening(en)', selectedBerekeningenIds.length > 1 ? true : false);
        try {
            await indextree.exportBuilding(selectedBerekeningenIds, includingLabels);
        } finally {
            progressCircle.setShowProgressValue(false);
        }
    };

    vm.exportCSVClick = async function (bldId) {
        if (!bldId) {
            return;
        }
        const selectedBerekeningen = getSelectedBerekeningen();
        if (selectedBerekeningen.some(bld => vm.isCalcNeeded(bld))) {
            indextree.warning('[W064]'); // de berekening heeft geen resultaten
        } else {
            progressCircle.setShowProgressValue(true, 'exporteren resultaten', false);
            try {
                await indextree.exportBerekeningCSV(bldId);
            } finally {
                progressCircle.setShowProgressValue(false);
            }
        }
    };

    vm.createPDFRapport = async function (building) {
        if (!building) {
            return;
        }
        const selectedBerekeningen = getSelectedBerekeningen();
        if (selectedBerekeningen.length === 0) { selectedBerekeningen.push(building) };

        if (selectedBerekeningen.some(bld => vm.isCalcNeeded(bld))) {
            indextree.warning('[W050]'); // de berekening heeft geen resultaten
        } else {
            progressCircle.setShowProgressValue(true, 'rapportage', true);
            try {
                await indextree.createRapportBerekening(selectedBerekeningen.map(bld => bld.BuildingId));
            } finally {
                progressCircle.setShowProgressValue(false);
            }
        }
    };

    vm.createExcelRapport = async function (building) {
        if (!building) {
            return;
        }
        const selectedBerekeningen = getSelectedBerekeningen();
        if (selectedBerekeningen.length === 0) { selectedBerekeningen.push(building) };

        if (selectedBerekeningen.some(bld => vm.isCalcNeeded(bld))) { //TODO: reenable validations.
            indextree.warning('[W064]'); // de berekening heeft geen resultaten
        } else {

            progressCircle.setShowProgressValue(true, 'rapportage', true);
            try {
                await indextree.createMWARapportBerekening(selectedBerekeningen.map(bld => bld.BuildingId));
            } finally {
                progressCircle.setShowProgressValue(false);
            }
        }
    };


    vm.saveProjectNaam = function () {
        indextree.saveProjectNaam(vm.folderId, vm.projectnaam || '');
    };

    vm.saveBerekeningNaam = async function (bldId) {
        $("#berekeningnaaminput" + bldId).removeClass("editname");
        $("#berekeningnaam" + bldId).addClass("showname");

        try {
            await indextree.saveBerekeningNaam(vm.folderId, bldId, vm.berekeningnaam(bldId))
        } catch (err) {
            $log.error(`Oeps in saveBerekeningNaam:`, err);
            await ntaAlert.showError();
        } finally {
            indextree.orderBerekeningenBy(vm.folderId, "Name");
            setTimeout(() => {
                const tile = document.getElementById(`tile+${bldId}+${vm.folderId}`);
                tile && tile.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }, 250);
        }
    };

    vm.searchResultClicked = function (folderId, buildingId = undefined) {
        folderService.setActiveFolder(folderId);
        if (buildingId) {
            activeBuildingService.setActiveBuilding(buildingId);
        }
        setTimeout(function () {
            const element = document.getElementById(`folderitem${folderId}`);
            element.scrollIntoView();
            $('#folderitem' + folderId).first()
                .trigger('click')
                .focus();
        }, 100);
    }

    vm.editBerekeningNaam = function (bldId, event) {
        if (!bldId) {
            return;
        }
        $("#berekeningnaam" + bldId).removeClass("showname");
        $("#berekeningnaaminput" + bldId).addClass("editname");
        $("#berekeningnaaminput" + bldId).focus();
        $("#berekeningnaaminput" + bldId).select();

        event.stopImmediatePropagation();
        event.preventDefault();
    };

    // Resultaatdefinities. Hier wordt bepaald hoe de getters heten, om welke PropertyId het gaat, hoeveel decimalen weergegeven moeten worden, en wanneer hij valide is.
    const resultDefs = {
        'BENG1': {
            id: 'BENG1',
            isValid: function (value, limit) { return value <= limit; },
            decimals: 2,
        },
        'BENG2': {
            id: 'BENG2',
            isValid: function (value, limit) { return value <= limit; },
            decimals: 2,
        },
        'BENG3': {
            id: 'BENG3',
            isValid: function (value, limit) { return value >= limit; },
            decimals: 1,
        },
        'TOjuli': {
            id: 'TOJULI',
            isValid: function (value, limit) { return value <= limit; },
            decimals: 2,
        },
    };
    for (const name in resultDefs) {
        const resultDef = resultDefs[name];
        vm[name] = {
            check: function (berekening) {
                if (!vm.isNieuwbouw(berekening)) return null;
                const waarde = getResult(berekening, 'EP_' + resultDef.id);
                const eis = getResult(berekening, 'EP_' + resultDef.id + '_EIS');
                if (eis === null || waarde === null) return null;
                return resultDef.isValid(waarde, eis);
            },
            icon: function (berekening) {
                const isValid = this.check(berekening);
                if (isValid === true) {
                    return 'fa-check checkmark';
                } else if (isValid === false) {
                    return 'fa-times cross';
                } else {
                    return '';
                }
            },
            value: function (berekening) {
                return formatResult(berekening, 'EP_' + resultDef.id, resultDef.decimals);
            },
            eis: function (berekening) {
                return formatResult(berekening, 'EP_' + resultDef.id + '_EIS', resultDef.decimals);
            },
        };
    }

    vm.getEnergielabel = function (berekening) {
        return berekening.Results['EP_ENERGIELABEL'];
    };

    vm.getRisicoOververhitting = function (berekening) {
        const risico = berekening.Results['RESULT_TOJULI_RISICO'];
        return risico && risico.replace('maak EP berekening per appartement', 'bepaal per app.');
    };

    vm.isNieuwbouw = function (berekening) {
        const soortBouw = berekening.Results['GEB_SRTBW'];
        return soortBouw === 'NIEUWB';
    };

    vm.isUnitInUtiliteitsgebouw = function (berekening) {
        const gebouwType = berekening.Results['GEB_TYPEGEB'];
        return gebouwType === 'TGEB_UTILUNIT';
    };

    vm.showEis = function (berekening) {
        return vm.isNieuwbouw(berekening) && !vm.isUnitInUtiliteitsgebouw(berekening);
    };

    vm.energielabelIsHidden = function (berekening) {
        const gebouwtype = berekening.Results['GEB_TYPEGEB'];
        const calcunit = berekening.Results['RZFORM_CALCUNIT'];
        return gebouwtype === 'TGEB_APPGEB'
            || gebouwtype === 'TGEB_UTILIT' && calcunit === 'RZUNIT_GEBUNIT'
            || calcunit === 'RZUNIT_PROJECT';
    };

    vm.isTOjuli = function (berekening) {
        //BENG Indicator condition[B] toon rij indien:
        //* versie le3.2 EN G04='grondgebonden woning' / appartement
        //* versie ge3.3 EN WB EN G04='grondgebonden woning' / appartement EN geen actieve koeling
        const gebouwtype = berekening.Results['GEB_TYPEGEB'];
        const actieveKoeling = berekening.Results['RESULT_TOJULI_TYPE_KOEL'] === 'RESULT-TOJULI_TYPE_KOEL_ACTIVE';
        return (gebouwtype === 'TGEB_GRWON' || gebouwtype === 'TGEB_APP')
            && (berekening.NTAVersionId < 300 || !vm.isNieuwbouw(berekening) && !actieveKoeling);
    };

    vm.isRisicoOververhitting = function(berekening) {
        //BENG Indicator condition [Y] -> toon als versie ge3.3 EN WN EN:
        //- G04 = grondgebonden woning / appartement OF
        //- G04 = appartementengebouw EN Z23 = per gebouw
        const gebouwtype = berekening.Results['GEB_TYPEGEB'];
        const calcunit = berekening.Results['RZFORM_CALCUNIT'];

        return berekening.NTAVersionId >= 300 && vm.isNieuwbouw(berekening) &&
            ((gebouwtype === 'TGEB_GRWON' || gebouwtype === 'TGEB_APP') ||
                (gebouwtype === 'TGEB_APPGEB' && calcunit === 'RZUNIT_GEB'));
    }

    function getResult(berekening, propertyId) {
        const text = berekening.Results[propertyId]
        if (!text) return null;
        const value = parseFloat(text.replace(',', '.'));
        return isNaN(value) ? null : value;
    }

    function formatResult(berekening, propertyId, decimals) {
        return ntaRounding.formatNumber(getResult(berekening, propertyId), decimals);
    }

}]);
