﻿var ntaModule = angular.module("ntaModule", ["settingsModule", "resultsModule", "progressModule", "indexModule", "projectModule", "afmeldModule", "ngAnimate", "ngMaterial", "ngMasonry", 'ngSanitize', 'ntaAppInfoModule']);

ntaModule.factory("searchFactory", [ function () {
    const factory = {};
    factory.searchResults = { searchResultCount: 0, projectResults: {}, buildingResults: {}, folderResults: {} };
    factory.searchQuery = "";
    return factory;
}]);

ntaModule.controller("ntaController", ['progressCircle', '$mdSidenav', '$http', '$mdDialog', '$sce', 'ntaAppInfo', 'HubConnection', function (progressCircle, $mdSidenav, $http, $mdDialog, $sce, ntaAppInfo, HubConnection) {
    var vm = this;

    vm.showProjects = function () {
        progressCircle.setShowProgressValue(true, 'projectenoverzicht laden');
    };

    vm.getDebugUrl = function () {
        const match = /^(http.+Project\/\d+\/Berekening\/\d+)\/?/i.exec(location.href);
        if (match) {
            return match[1] + '/calc';
        }
    };

    vm.importSelectiontable = function () {
        $mdDialog.show({
            controller: ImportDialogController,
            templateUrl: '/src/app/project/importfile.html' + '?v=' + encodeURIComponent(ntaAppInfo.commit),
            parent: angular.element(document.body),
            targetEvent: event,
            clickOutsideToClose: true,
        });
    };

    function ImportDialogController($scope, $mdDialog) {
        $scope.hide = function () {
            $mdDialog.hide();
        };
        $scope.cancel = function () {
            $mdDialog.cancel();
        };
        $scope.uploadFile = async function () {
            $mdDialog.hide();

            const files = document.querySelector("#filesToImport").files;
            if (files) {
                await importSelectionTableOnServer(files[0]);
            }
        };
    }

    async function importSelectionTableOnServer(file) {
        const progressText = 'importeren selectietabel';
        progressCircle.setProgressValue(0);
        progressCircle.setShowProgressValue(true, progressText, true);

        try {
            const connection = new HubConnection('/hubs/progress', {
                progressText,
            });
            await connection.whenConnected();
            const runId = uuid.v4();
            const runPromise = connection.runWithId('RepeatLastEvent', runId);

            const formData = new FormData();
            formData.append("runId", runId);
            formData.append("file", file);

            const response = await fetch('/api/selectiontable/import/', {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                $log.error('Failed to upload files to folder', folderId, ':', response);
                throw response.statusText;
            }

            // Import in de SelectiontableController gaat de daadwerkelijke events triggeren op de server waardoor de runPromise afgerond wordt.
            const data = await runPromise;
            const productCount = data && data.products;
            alert(`Het importeren is gelukt!\n\n${productCount} productrijen geïmporteerd.`);
        } catch (data) {
            const error = data && (typeof data === 'string' ? data : data.error || data.message) || 'Er is iets niet goed gegaan.';
            $mdDialog.show($mdDialog.alert().title("Het importeren is mislukt").htmlContent(error.replaceAll("\r\n", "<br>")).ok("sluiten"));
        } finally {
            progressCircle.setShowProgressValue(false);
        }
    }

    vm.importClimate = function (event) {
        $mdDialog.show({
            controller: ClimateImportDialogController,
            templateUrl: '/src/app/project/importfile.html' + '?v=' + encodeURIComponent(ntaAppInfo.commit),
            parent: angular.element(document.body),
            targetEvent: event,
            clickOutsideToClose: true,
        });
    };

    function ClimateImportDialogController($scope, $mdDialog) {
        $scope.hide = function () {
            $mdDialog.hide();
        };
        $scope.cancel = function () {
            $mdDialog.cancel();
        };
        $scope.uploadFile = async function () {
            $mdDialog.hide();

            const files = document.querySelector("#filesToImport").files;
            if (files) {
                await importClimateOnServer(files[0]);
            }
        };
    }

    async function importClimateOnServer(file) {
        const progressText = 'importeren klimaatgegevens';
        progressCircle.setProgressValue(0);
        progressCircle.setShowProgressValue(true, progressText, true);

        try {
            const connection = new HubConnection('/hubs/progress', {
                progressText,
            });
            await connection.whenConnected();
            const runId = uuid.v4();
            const runPromise = connection.runWithId('RepeatLastEvent', runId);

            const formData = new FormData();
            formData.append("runId", runId);
            formData.append("file", file);

            const response = await fetch('/api/climate/import/', {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                $log.error('Failed to upload climate data:', response);
                throw response.statusText;
            }

            // Import in de ClimateController gaat de daadwerkelijke events triggeren op de server waardoor de runPromise afgerond wordt.
            const data = await runPromise;
            const { read, added, updated, deleted, warnings } = data || {};
            let message = `Het importeren is gelukt!\n\n${read} rijen uitgelezen; ${added} toegevoegd, ${updated} bijgewerkt, en ${deleted} verwijderd.`;
            if (warnings && warnings.length) {
                message += '\n\nWaarschuwingen:\n' + warnings.join('\n* ');
            }
            alert(message);
        } catch (data) {
            const error = data && (typeof data === 'string' ? data : data.error || data.message) || 'Er is iets niet goed gegaan.';
            alert('Het importeren is mislukt!\n\n' + error);
        } finally {
            progressCircle.setShowProgressValue(false);
        }
    }

    vm.disclaimer = function () {
        const titel = document.getElementById("disclaimer-title").textContent;
        const html = document.getElementById("disclaimer-body").innerHTML;
        var confirm = $mdDialog.alert()
            .title(titel)
            .htmlContent(html)
            .targetEvent(event)
            .clickOutsideToClose(true)
            .escapeToClose(true)
            .ok('Begrepen');
        $mdDialog.show(confirm).then(
            async function () {

                progressCircle.setShowProgressValue(true, 'projectenoverzicht laden', false);
                const connectionId = await progressCircle.startConnection();
                if (connectionId) {
                    progressCircle.setShowProgressValue(true, 'projectenoverzicht laden...', true);
                    window.top.location.href = "/Projects?connectionId=" + connectionId;
                } else {
                    window.top.location.href = "/Projects";
                }

            }, function () {
                window.top.location.href = "/Account/Logoff";
            });
    };
}]);

ntaModule.directive('ngEnter', function () {
    return function (scope, elem, attrs) {
        elem.bind("keydown keypress", function (event) {
            // 13 represents enter button
            if (event.which === 13) {
                scope.$apply(function () {
                    scope.$eval(attrs.ngEnter);
                });

                event.stopImmediatePropagation();
                event.preventDefault();
            }
        });
    };
});

